/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { savePatientForm } from "../../../Redux/actions/staffing";
import {
  canSubmitForm,
  staffFormTypesConstants,
  totalVisitNumber,
  visitStatus,
} from "../../../utils/helper";
import { currentRole } from "../../../utils/auth";
import { getStaffFormdataById } from "../../../Redux/actions/patientGetDocTypes";
import { getFrequencySuggestion } from "../../../Redux/actions/common";
import { debounce } from "lodash";
import SignatureCanvas from "../../../components/common/SignatureCanvas";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import {
  formOptions,
  reactSelectOptionCreate,
} from "../../../utils/common/options";
import CreatableSelect from "react-select/creatable";

function EvaluationForm(props) {
  const {
    closeFormModal,
    isAutoFill,
    visitDetail,
    IsUpdated,
    formId,
    statusHandler,
    dataAvailable,
    formDetails,
    toSave,
    init,
    isPrint,
    setToUpdate,
    confirmDate,
  } = props;
  const totalDefaultGoals = 8;
  let defaultGoalObj = {
    goal: "",
    effectiveDate: "",
    currentStatus: "",
  };
  const [isCanvasShow, setIsCanvasShow] = useState(false);
  const [signatureAdded, setSignatureAdded] = useState(null);
  const [displaySignature, setDisplaySignature] = useState(null);
  const [show, setShow] = useState();

  const saveForm = (image) => {
    const decodedImage = atob(image.split(",")[1]);
    const blob = new Blob([decodedImage], { type: "image/jpeg" });
    const imageUrl = URL.createObjectURL(blob);
    setDisplaySignature(image);
    toast.success("Signature added.");
  };

  const prior_level_data = [
    { label: "Community Gait", type: "radio", col: 1 },
    { label: "Limited Community Gait", type: "radio", col: 1 },
    { label: "Household Gait", type: "radio", col: 1 },
    { label: "Limited Household Gait", type: "radio", col: 1 },
    { label: "No Assistive Device", type: "checkbox", col: 2 },
    { label: "Used Walker", type: "checkbox", col: 2 },
    { label: "Used Cane", type: "checkbox", col: 2 },
    { label: "Used Wheelchair", type: "checkbox", col: 2 },
    { label: "I IADL and ADL", type: "checkbox", col: 3 },
    { label: "I ADL only", type: "checkbox", col: 3 },
    { label: "Dependent ADL", type: "checkbox", col: 3 },
    { label: "Other Limitations", type: "checkbox", col: 3 },
  ];
  const functional_limitation_data = [
    { label: "Amputation", type: "checkbox", col: 1 },
    { label: "Dyspnea w/ Minimal Exertion", type: "checkbox", col: 1 },
    { label: "Legally Blind", type: "checkbox", col: 1 },
    { label: "Hearing", type: "checkbox", col: 1 },
    { label: "Contracture", type: "checkbox", col: 1 },
    { label: "Speech", type: "checkbox", col: 2 },
    { label: "Ambulation", type: "checkbox", col: 2 },
    { label: "Bowel/Bladder", type: "checkbox", col: 2 },
    { label: "Endurance", type: "checkbox", col: 2 },
    { label: "Paralysis", type: "checkbox", col: 2 },
    { label: "Strength", type: "checkbox", col: 3 },
    { label: "ROM", type: "checkbox", col: 3 },
    { label: "None", type: "checkbox", col: 3 },
  ];
  const precautions_data = [
    { label: "WB Status", type: "checkbox", col: 1 },
    { label: " THR Precautions", type: "checkbox", col: 1 },
    {
      label: "ROM Limit-see Additional for details",
      type: "checkbox",
      col: 1,
    },
    { label: "No Bending, Lifting, or Twisting", type: "checkbox", col: 1 },
    { label: "Use Gait Belt", type: "checkbox", col: 2 },
    { label: "Fall Precautions", type: "checkbox", col: 2 },
    { label: "None", type: "checkbox", col: 2 },
  ];
  const home_env_data = [
    { label: "Appropriate", type: "radio" },
    { label: "Inappropriate", type: "radio" },
  ];
  const equipment_data = [
    { label: "FWW", type: "checkbox", col: 1 },
    { label: "4WW/Rollator", type: "checkbox", col: 1 },
    { label: "Grab Bars", type: "checkbox", col: 1 },
    { label: "Cane", type: "checkbox", col: 1 },
    { label: "3-in-1 Commode", type: "checkbox", col: 2 },
    { label: "Wheelchair", type: "checkbox", col: 2 },
    { label: "Raised Toilet Seat", type: "checkbox", col: 2 },
    { label: "Bath/Shower Seat", type: "checkbox", col: 2 },
    { label: "None", type: "checkbox", col: 3 },
    { label: "Other", type: "checkbox", col: 3 },
  ];

  const mental_status_data = [
    { label: "Person", type: "checkbox", col: 2 },
    { label: "Place", type: "checkbox", col: 2 },
    { label: "Time ", type: "checkbox", col: 2 },
  ];
  const rom_data = [
    { label: "RUE", type: "checkbox", col: 1 },
    { label: "LUE", type: "checkbox", col: 1 },
    { label: "RLE", type: "checkbox", col: 1 },
    { label: "LLE", type: "checkbox", col: 1 },
  ];

  const posture = [
    "No Abnormality",
    "Kyphosis",
    "Scoliosis",
    "Forward Head",
    "Protracted Shoulders",
    "Other",
  ];
  const array = [
    "I",
    "Supv",
    "SBA",
    "CGA",
    "Min A",
    "Mod A",
    "Max A",
    "Not Tested",
  ];

  const Assistance = ["I", "Supv", "SBA", "CGA", "Min A", "Mod A", "Max A"];
  const array1 = [
    "Poor-",
    "Poor",
    "Poor+",
    "Fair-",
    "Fair",
    "Fair+",
    "Good-",
    "Good",
    "Good+",
  ];
  const balanceArray = [
    "Poor-",
    "Poor",
    "Poor+",
    "Fair-",
    "Fair",
    "Fair+",
    "Good-",
    "Good",
    "Good+",
    "Not Tested",
  ];
  const gaitWB = ["WBAT", "NWB", "TTWB", "PWB", "FWB"];
  const gaitAssistive = [
    "Walker",
    "Quad Cane",
    "Hemi Walker",
    "St. Cane",
    "Crutches",
    "Other",
    "None",
  ];
  const Deviations = [
    "Decrease heel toe gait",
    "Decreased reciprocal arm swing",
    "Decreased base of support(BOS)",
    "Decreased step length",
    "Antalgic gait",
    "Shuffing gait",
    "Waddling",
    "Decreased Cadence",
    "Festinating",
    "Other",
  ];

  const vitalSignsThreshold = {
    vital_signs_name1: {
      min: 85,
      max: 160,
    },
    vital_signs_name2: {
      min: 60,
      max: 90,
    },
    vital_signs_name3: {
      min: 60,
      max: 100,
    },
    vital_signs_name4: {
      min: 12,
      max: 20,
    },
    vital_signs_name5: {
      min: 96,
      max: 100,
    },
  };

  const vitalSignsValidationList = [
    "vital_signs_name1",
    "vital_signs_name2",
    "vital_signs_name3",
    "vital_signs_name4",
    "vital_signs_name5",
  ];

  const staffDetails = useSelector((state) => state.staffingSlice.staffDetail);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const UserRole = currentUser?.Role?.supervisingtherapist;
  const Role = currentUser && currentUser.Role && currentUser.Role.key;
  const leftVisit = useSelector((e) => e.commonSlice.leftVisit);

  const staffDocument = useSelector((e) => e.commonSlice.staffDocument);
  const staffFormTypes = useSelector((e) => e.therapistSigunpSlice.masterData);
  const staffFormData = useSelector((e) => e.commonSlice.staffForm);
  const frequencySuggestion = useSelector(
    (e) => e.commonSlice.frequencySuggestion
  );

  const visitDetailById = useSelector(
    (data) => data?.patientGetDocTypesSlice?.visitDetail
  );

  const dispatch = useDispatch();

  const handleShow = () => {
    if (
      currentUser &&
      currentUser.Role &&
      (currentUser.Role.key !== currentRole.admin ||
        currentUser.Role.key !== currentRole.superadmin)
    ) {
      setShow(true);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const effectiveDate = () => {
    if (staffDocument && staffDocument.length > 0) {
      const date =
        staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
      return date;
    }
    return "";
  };

  const frequencyHandler = (number) => {
    const factorPairs = [];
    for (let i = 1; i <= number; i++) {
      if (number % i === 0) {
        const factorPair = [i, number / i];
        factorPairs.push(factorPair);
      }
    }

    const finalResult = factorPairs.filter(
      (pair) => pair[0] <= 3 && pair[1] <= 8
    );

    if (finalResult.length < 1) {
      return frequencyHandler(number + 1);
    }

    const options = finalResult.map((pair) => {
      const optionValue = `${pair[0]}W${pair[1]}`;
      return <option value={`${optionValue}`}>{`${optionValue}`}</option>;
    });

    return options;
  };

  const vitalSignsValidation = (data) => {
    let val = false;
    Object.keys(data).map((e) => {
      if (
        data[e] &&
        data[e].length > 0 &&
        (Number(data[e]) < Number(vitalSignsThreshold[e]["min"]) ||
          Number(data[e]) > Number(vitalSignsThreshold[e]["max"]))
      ) {
        val = true;
      }
    });
    return val;
  };

  const [error, setError] = useState(null);
  const [TherapistsignatureError, setTherapistsignatureError] = useState(null);

  const onSubmit = (values, form, status) => {
    if (
      visitDetailById &&
      !canSubmitForm.includes(visitDetailById.Status.key)
    ) {
      return;
    }
    if (!displaySignature || !values.Therapistsignature) {
      setTherapistsignatureError("Required");
    } else {
      setTherapistsignatureError(null);
    }
    let finalFormValues = {
      ...values,
      Therapistsignature: !displaySignature
        ? values.Therapistsignature
        : displaySignature,
    };
    let formType;
    if (isAutoFill) {
      formType = staffFormTypesConstants.ReAssessment;
    } else {
      formType = staffFormTypesConstants.Evaluation;
    }
    if (!vitalSignsValidation(values?.Vital_Signs[0])) {
      delete finalFormValues.autodate;
      delete finalFormValues.vitalreportedAgency;
      delete finalFormValues.vitalreportedMD;
    }
    if (
      finalFormValues &&
      finalFormValues.goals &&
      status &&
      typeof status === "string"
    ) {
      finalFormValues.goals = values.goals.filter((item) => item.goal);
    }
    if (values.noPain) {
      delete finalFormValues.Pain;
    }
    const apiObject = {
      status: typeof status === "string" ? status : visitStatus.incompletedoc,
      staffId: staffDetails?.id,
      formData: finalFormValues,
      formType: formType,
      id: finalFormValues.id,
      episodeDocumentFormId: formId,
      episodeDocumentId: visitDetail?.id,
    };
    dispatch(savePatientForm(apiObject)).then((res) => {
      if (res.payload.success) {
        closeFormModal();
        IsUpdated();
        if (res.payload.isVisitUpdated) props.setToUpdate && setToUpdate(true);
        if (
          visitDetailById &&
          visitDetailById.Status &&
          visitDetailById.Status.key === visitStatus.scheduled
        ) {
          statusHandler(visitStatus.pendingnotes, visitDetail);
        }
        // else if (res.payload.isVisitUpdated) {
        //   statusHandler(visitStatus.needreview, visitDetail);
        // }
      }
    });
  };
  const validate = (values) => {
    let error = {};

    if (vitalSignsValidation(values?.Vital_Signs[0])) {
      if (!values.autodate) {
        error["autodate"] = "Required";
      }
      if (!("vitalreportedAgency" in values)) {
        error["vitalreportedAgency"] = "Required";
      } else if (!values.vitalreportedAgency) {
        error["vitalreportedAgency"] =
          "Please call the Agency to report the abnormal vital sign(s).";
      }
      if (!("vitalreportedMD" in values)) {
        error["vitalreportedMD"] = "Required";
      }
    }

    let vitalSignArray = [];
    values.Vital_Signs.map((item) => {
      let vitalSignErrorObject = {};
      vitalSignsValidationList.map((itemKey) => {
        if (!item[itemKey]) {
          vitalSignErrorObject[itemKey] = "Required";
        } else if (item[itemKey] && item[itemKey].trim() === "") {
          vitalSignErrorObject[itemKey] = "Required";
        } else if (
          item[itemKey] &&
          (Number(item[itemKey]) <
            Number(vitalSignsThreshold[itemKey]["min"]) ||
            Number(item[itemKey]) > Number(vitalSignsThreshold[itemKey]["max"]))
        ) {
          vitalSignErrorObject[itemKey] = "Out of range";
        }
      });
      vitalSignArray.push(vitalSignErrorObject);
    });
    error["Vital_Signs"] = vitalSignArray;

    if (
      vitalSignsValidation(values?.Vital_Signs[0]) &&
      "vitalreportedAgency" in values &&
      "vitalreportedMD" in values
    ) {
      error["Vital_Signs"] = [];
    }
    if (!values.time_in) {
      error["time_in"] = "Required";
    }
    if (!values.time_out) {
      error["time_out"] = "Required";
    }

    if (values.time_in && values.time_out) {
      const timeIn = new Date(`2000-01-01T${values.time_in}`);
      const timeOut = new Date(`2000-01-01T${values.time_out}`);
      const difference = Math.abs(timeOut - timeIn) / (1000 * 60);

      if (difference < 45) {
        error.time_out = "Time Out must be at least 45 minutes after Time In";
      }
    }

    if (!values.visit_date) {
      error["visit_date"] = "Required";
    }
    if (!values.subjective) {
      error["subjective"] = "Required";
    }
    if (!values.reason_for_home_health) {
      error["reason_for_home_health"] = "Required";
    }
    if (!values.past_medical_history) {
      error["past_medical_history"] = "Required";
    }
    let Prior_Level_of_FunctionArray = [];
    values.Prior_Level_of_Function.map((item) => {
      const priorItemError = {};
      if (!item.prior_Level_of_Function_name1) {
        priorItemError["prior_Level_of_Function_name1"] = "Required";
      }
      if (item.prior_Level_of_Function_name2.length === 0) {
        priorItemError["prior_Level_of_Function_name2"] = "Required";
      }
      if (item.prior_Level_of_Function_name3.length === 0) {
        priorItemError["prior_Level_of_Function_name3"] = "Required";
      }
      if (
        item.prior_Level_of_Function_name3.includes("Other Limitations") &&
        !item.prior_Level_of_Function_name4
      ) {
        priorItemError["prior_Level_of_Function_name4"] = "Required";
      }
      Prior_Level_of_FunctionArray.push(priorItemError);
    });

    error["Prior_Level_of_Function"] = Prior_Level_of_FunctionArray;

    let PrecautionsArray = [];
    values.Precautions.map((item) => {
      const precaution_error = {};
      if (
        item.precautions_name1?.length === 0 &&
        item.precautions_name2?.length === 0
      ) {
        precaution_error["precautions_name1"] = "Required";
      }
      if (item.precautions_name2.includes("useWith") && !item.UseWithText) {
        precaution_error.UseWithText = "Required";
      }
      PrecautionsArray.push(precaution_error);
    });
    error["Precautions"] = PrecautionsArray;

    if (
      values.Precautions[0].precautions_name1.includes("WB Status") &&
      !values.wbStatusFreeText
    ) {
      error.wbStatusFreeText = "Required";
    }
    if (
      values.Precautions[0].precautions_name1.includes(
        "ROM Limit-see Additional for details"
      ) &&
      !values.precautions_additional
    ) {
      error.precautions_additional = "Required";
    }

    let Functional_Limitation_Array = [];
    values.Functional_Limitation.map((item) => {
      const Functional_Limitation_Error = {};
      if (
        item.functional_limitation_name1?.length === 0 &&
        item.functional_limitation_name2?.length === 0 &&
        item.functional_limitation_name3?.length === 0
      ) {
        Functional_Limitation_Error["functional_limitation_name1"] = "Required";
      }
      Functional_Limitation_Array.push(Functional_Limitation_Error);
    });
    error["Functional_Limitation"] = Functional_Limitation_Array;

    let mental_status_array = [];
    values.Mental_Status.map((item) => {
      const mental_status_error = {};
      if (item?.mental_status_Other && !item?.mental_status_Additional) {
        mental_status_error["mental_status_Additional"] = "Required";
      }
      if (
        !item?.mental_status_Alert &&
        item?.mental_status_OrientedTo?.length === 0 &&
        !item?.mental_status_Other
      ) {
        mental_status_error["mental_status_Alert"] = "Required";
      }
      mental_status_array.push(mental_status_error);
    });
    error["Mental_Status"] = mental_status_array;

    let living_situations_array = [];
    values.Living_Situations.map((item) => {
      const living_situations_error = {};
      if (
        !item.living_situations_name1 ||
        item.living_situations_name1?.length === 0
      ) {
        living_situations_error["living_situations_name1"] = "Required";
      }

      living_situations_array.push(living_situations_error);
    });
    error["Living_Situations"] = living_situations_array;

    let home_environment_array = [];
    values.Home_Environment.map((item) => {
      const home_environment_error = {};
      if (!item.home_environment_name1) {
        home_environment_error["home_environment_name1"] = "Required";
      } else if (
        item.home_environment_name1 === "Inappropriate" &&
        !item.home_environment_name2
      ) {
        home_environment_error["home_environment_name2"] = "Required";
      }
      home_environment_array.push(home_environment_error);
    });
    error["Home_Environment"] = home_environment_array;

    let equipment_home_array = [];
    values.Equipment_in_Home.map((item) => {
      const equipment_home_error = {};
      if (
        item.equipment_in_home_name1?.length === 0 &&
        item.equipment_in_home_name2?.length === 0 &&
        item.equipment_in_home_name3?.length === 0
      ) {
        equipment_home_error["equipment_in_home_name1"] = "Required";
      }

      if (
        item.equipment_in_home_name3.includes("Other") &&
        !values.equipment_in_home_name4
      ) {
        error["equipment_in_home_name4"] = "Required";
      }

      equipment_home_array.push(equipment_home_error);
    });
    error["Equipment_in_Home"] = equipment_home_array;
    if (!values.noPain) {
      let PainArray = [];
      values?.Pain?.map((item) => {
        let errorObj = {};
        if (!item.intensity) {
          errorObj.intensity = "Required";
        }
        if (!item.location || item.location.trim() === "") {
          errorObj.location = "Required";
        }
        if (!item.description || item.description.trim() === "") {
          errorObj.description = "Required";
        }
        if (!item.increasedWith || item.increasedWith.trim() === "") {
          errorObj.increasedWith = "Required";
        }
        if (!item.decreasedWith || item.decreasedWith.trim() === "") {
          errorObj.decreasedWith = "Required";
        }
        PainArray.push(errorObj);
      });
      error.Pain = PainArray;
    }
    if (!values.noPain && isPainHigh(values.Pain)) {
      if (!values.highPaindate) {
        error["highPaindate"] = "Required";
      }
      if (!("highPainReportedToAgency" in values)) {
        error["highPainReportedToAgency"] = "Required";
      } else if (!values.highPainReportedToAgency) {
        error["highPainReportedToAgency"] =
          "Please call the Agency to report the abnormal vital sign(s).";
      }
      if (!("highPainReportedToMD" in values)) {
        error["highPainReportedToMD"] = "Required";
      }
    }

    let rom_array = [];
    values.Rom.map((item) => {
      const rom_error = {};

      if (
        (!item.rom_check1 || item?.rom_check1?.length === 0) &&
        (!values.rom_name || values.rom_name.trim() === "")
      ) {
        error["rom_name"] = "Required";
      }

      rom_array.push(rom_error);
    });
    error["Rom"] = rom_array;

    let strength_array = [];
    values.Strength.map((item) => {
      const strength_error = {};

      if (
        (!item.strength_check1 || item?.strength_check1?.length === 0) &&
        (!values.strength_name || values.strength_name.trim() === "")
      ) {
        error["strength_name"] = "Required";
      }

      strength_array.push(strength_error);
    });
    error["Strength"] = strength_array;

    const array = [];
    if (
      values.posture.length === 0 &&
      (!values.postureTextBox || values.postureTextBox === "")
    ) {
      error["postureTextBox"] = "Required";
    }

    // else if (
    //   values.posture.includes("Others") &&
    //   (!values.postureTextBox || values.postureTextBox === "")
    // ) {
    //   error["postureTextBox"] = "Required";
    // }
    // if (values.postureTextBox) {
    //   delete error["posture"];
    // }

    if (!values.deficits && !values.deficitsTextBox) {
      error["deficitsTextBox"] = "Required";
    }
    values.functional_mobility.map((item) => {
      const itemObject = {};
      Object.keys(item).map((itemkey) => {
        if (item[itemkey].length === 0) {
          itemObject[`${itemkey}`] = "Required";
        }
      });
      array.push(itemObject);
    });

    error.functional_mobility = array;

    const array1 = [];
    values.transfers.map((item) => {
      const itemObject = {};
      Object.keys(item).map((itemkey) => {
        if (item[itemkey].length === 0) {
          itemObject[`${itemkey}`] = "Required";
        }
      });
      array1.push(itemObject);
    });
    error.transfers = array1;

    const array2 = [];
    values.Sitting.map((item) => {
      let errorObj = {};
      Object.keys(item).map((itemkey) => {
        if (item[itemkey].length === 0) {
          errorObj[`${itemkey}`] = "Required";
        }
      });
      array2.push(errorObj);
    });
    error.Sitting = array2;

    const StandingErrorArray = [];
    values.Standing.map((item) => {
      let errorObj = {};
      Object.keys(item).map((itemkey) => {
        if (item[itemkey].length === 0) {
          errorObj[`${itemkey}`] = "Required";
        }
      });
      StandingErrorArray.push(errorObj);
    });
    error.Standing = StandingErrorArray;

    if (!values.endurance) {
      error["endurance"] = "Required";
    }

    if (!values.treatmentProvided || values.treatmentProvided === " ") {
      error["treatmentProvided"] = "Required";
    }

    if (!values.gait) {
      error["gait"] = "Required";
    }
    const ambutaryGait = [];
    let notInclude = ["assistance", "deviations"];
    let ambutaryGaitKey = [
      "wb",
      "assistiveDevices",
      "assistance",
      "levelSurfaces",
      "unevenSurfaces",
      "deviations",
      "deviationsTextBox",
    ];
    if (values.gait === "ambulatory") {
      values.ambutaryGait.map((item) => {
        const itemObject = {};
        ambutaryGaitKey.map((itemkey) => {
          if (
            !notInclude.includes(itemkey) &&
            Array.isArray(item[itemkey]) &&
            item[itemkey].length === 0 &&
            !item[`${itemkey}TextBox`]
          ) {
            itemObject[`${itemkey}TextBox`] = "Required";
          }
          if (
            itemkey === "levelSurfaces" &&
            (!item[itemkey] || item[itemkey].trim() === "")
          ) {
            itemObject[itemkey] = "Required";
          }
          if (
            itemkey === "assistance" &&
            Array.isArray(item[itemkey]) &&
            item[itemkey].length === 0
          ) {
            itemObject[`assistance`] = "Required";
          }
          if (
            itemkey === "assistiveDevices" &&
            item[itemkey].includes("Other") &&
            (!item["assistiveDevicesTextBox"] ||
              !item["assistiveDevicesTextBox"]?.trim() === "")
          ) {
            itemObject["assistiveDevicesTextBox"] = "Required";
          }
          if (
            itemkey === "deviations" &&
            item[itemkey].includes("Other") &&
            (!item["deviationsTextBox"] ||
              !item["deviationsTextBox"]?.trim() === "")
          ) {
            itemObject["deviationsTextBox"] = "Required";
          }
        });
        ambutaryGait.push(itemObject);
      });
    } else {
      values.ambutaryGait.map((item) => {
        const itemObject = {};
        ambutaryGaitKey.map((itemkey) => {
          if (
            itemkey === "levelSurfaces" &&
            values.gait !== "nonAmbulatory1" &&
            (!item[itemkey] || item[itemkey] === "")
          ) {
            itemObject[itemkey] = "Required";
          }
        });
        ambutaryGait.push(itemObject);
      });
    }
    error.ambutaryGait = ambutaryGait;

    if (
      !values?.HomeboundEligibility ||
      values?.HomeboundEligibility?.length == 0
    ) {
      error["HomeboundEligibility"] = "Required";
    }
    if (!values.RehabPotential || values?.RehabPotential?.length == 0) {
      error["RehabPotential"] = "Required";
    }
    if (!values.Interventions || values?.Interventions?.length == 0) {
      error["Interventions"] = "Required";
    }
    if (!values.Frequency1) {
      error["Frequency1"] = "Required";
    } else if (
      Number(values.Frequency1) > 16 ||
      Number(values.Frequency1) < 1
    ) {
      error["Frequency1"] = "Should Be Between 1 to 16";
    }
    if (!values.DMERequests || values?.DMERequests?.length == 0) {
      error["DMERequests"] = "Required";
    }
    if (!values.PlanningRequests || values?.PlanningRequests?.length == 0) {
      error["PlanningRequests"] = "Required";
    }
    if (!values.Frequency2) {
      error["Frequency2"] = "Required";
    }
    if (!values.Justification) {
      error["Justification"] = "Required";
    }

    const goalArray = [];
    let goalscount = 0;
    values.goals.map((goalItem) => {
      let errobject = {};
      if (goalItem.goal && !goalItem.effectiveDate) {
        errobject.effectiveDate = "Required date";
      }
      if (goalItem.goal && !goalItem.currentStatus) {
        errobject.currentStatus = "Status Required";
      }
      if (isAutoFill && goalItem.goal && !goalItem.reAssementStatus) {
        errobject.reAssementStatus = "Status Required";
      }
      if (goalItem.currentStatus && goalItem.effectiveDate && !goalItem.goal) {
        errobject.goal = "Required";
      }
      if (goalItem.goal && goalItem.currentStatus && goalItem.effectiveDate) {
        goalscount += 1;
      }
      goalArray.push(errobject);
    });

    if (goalscount < 3) {
      setError(true);
    } else {
      setError(false);
    }

    if (!values.Therapistsignature && !displaySignature) {
      error.Therapistsignature = "Req";
    }

    error.goals = goalArray;
    return error;
  };

  const initialValues = () => {
    if (
      isAutoFill &&
      staffFormData &&
      staffFormData.formData &&
      staffFormData.MasterDatas.name === staffFormTypesConstants.ReAssessment
    ) {
      return {
        ...staffFormData.formData,
        Pain: staffFormData.formData.noPain
          ? [{}, {}, {}]
          : [...staffFormData.formData.Pain],
        id: staffFormData.id,
      };
    }

    let initialValues = {
      Prior_Level_of_Function: [
        {
          prior_Level_of_Function_name1: "",
          prior_Level_of_Function_name2: "",
          prior_Level_of_Function_name3: "",
          prior_Level_of_Function_name4: "",
        },
      ],
      Precautions: [
        {
          precautions_name1: "",
          precautions_name2: "",
          precautions_name3: "",
        },
      ],
      Functional_Limitation: [
        {
          functional_limitation_name1: "",
          functional_limitation_name2: "",
          functional_limitation_name3: "",
          functional_limitation_name4: "",
        },
      ],

      Living_Situations: [
        {
          living_situations_name1: "",
          living_situations_name2: "",
        },
      ],
      Home_Environment: [
        {
          home_environment_name1: "",
          home_environment_name2: "",
        },
      ],
      Equipment_in_Home: [
        {
          equipment_in_home_name1: "",
          equipment_in_home_name2: "",
          equipment_in_home_name3: "",
        },
      ],
      Vital_Signs: [
        {
          vital_signs_name1: "",
          vital_signs_name2: "",
          vital_signs_name3: "",
          vital_signs_name4: "",
          vital_signs_name5: "",
        },
      ],
      Mental_Status: [
        {
          mental_status_Alert: "",
          mental_status_OrientedTo: "",
          mental_status_Other: "",
          mental_status_Additional: "",
        },
      ],
      Pain: [
        {
          location: "",
          description: "",
          increasedWith: "",
          decreasedWith: "",
          itensity: "",
        },
        {
          location: "",
          description: "",
          increasedWith: "",
          decreasedWith: "",
          itensity: "",
        },
        {
          location: "",
          description: "",
          increasedWith: "",
          decreasedWith: "",
          itensity: "",
        },
      ],
      Rom: [
        {
          rom_check1: [],
        },
      ],
      Strength: [
        {
          strength_check1: "",
        },
      ],
      heading: [{ AUTOPOPULATE: [], Patient: [] }],
      posture: [],
      functional_mobility: [
        {
          supineToSit: [],
          Scooting: [],
          Rolling: [],
          bedMobility: [],
        },
      ],
      transfers: [
        {
          Sit: [],
          Bed: [],
          Toilet: [],
          Shower: [],
          Chair: [],
        },
      ],

      "functionalMobility-additional": "",

      Sitting: [{ Static: [], Dynamic: [] }],
      Standing: [{ Static: [], Dynamic: [] }],

      balance_additional: "",

      Deviations: [{}],
      ambutaryGait: [
        {
          wb: [],
          assistiveDevices: [],
          assistance: [],
          levelSurfaces: "",
          unevenSurfaces: "",
          deviations: [],
          deviationsTextBox: "",
        },
      ],

      VisitDate: visitDetail && visitDetail.date.split("T")[0],
      HomeboundEligibility: "",
      Justification: "",
      Interventions: "",
      DMERequests: "",
      PlanningRequests: "",
      Frequency1: "",
      Frequency2: "",
      Patientsignature: "",
      Therapistsignature: "",
      Physiciansignature: "",
      date: "",
      precautions_additional: "",
      functional_limitation_Fieldname4: "",
      vital_signs_name6: "",
      equipment_in_home_name4: "",
      living_situations_name2: "",
      rom_name: "",
      strength_name: "",
      SupervisingTherapist: ",",
    };
    if (staffDetails) {
      initialValues.PatientName = `${staffDetails.lastName}, ${staffDetails.firstName}`;
      initialValues.mrNo =
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.mrn;
      initialValues.dob =
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.dateOfBirth.split("T")[0];
      initialValues.agency =
        staffDetails &&
        staffDetails.createdByUser &&
        staffDetails.createdByUser.name;
      initialValues.diagnosis =
        staffDetails &&
        staffDetails.staffingDiagnosisdetails.length > 0 &&
        staffDetails.staffingDiagnosisdetails.map((item) => ({
          value: item.masterDataId,
          label: item.masterData.displayName,
        }));
    }
    if (currentUser) {
      initialValues.therapist = currentUser.lastName
        ? `${currentUser.lastName + ", " + currentUser?.firstName} ${
            currentUser.userDetail && currentUser.userDetail.discipline
              ? `(${currentUser.userDetail.discipline.toUpperCase()})`
              : ""
          }  `
        : "";
    }
    console.log(currentUser, "currentUser");
    if (staffDocument && staffDocument.length > 0) {
      initialValues["visit_date"] = confirmDate
        ? confirmDate
        : staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
      initialValues["autodate"] = confirmDate ? confirmDate : "";
    }
    let goals = [];
    for (let index = 0; index < totalDefaultGoals; index++) {
      let goalObj = JSON.parse(JSON.stringify(defaultGoalObj));
      if (staffDocument && staffDocument.length > 0) {
        goalObj["effectiveDate"] =
          staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
      }
      goals.push(goalObj);
    }
    initialValues.goals = goals;
    if (toSave && init) {
      return { ...init };
    }
    initialValues.visit_date = confirmDate
      ? confirmDate
      : staffDocument &&
        staffDocument.length > 0 &&
        staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
    initialValues.autodate = confirmDate ? confirmDate : "";
    if (
      formDetails &&
      formDetails.Status &&
      formDetails.Status.key &&
      formDetails.Status.key === visitStatus.incompletedoc
    ) {
      initialValues.date = "";
    }

    if (
      staffFormData &&
      staffFormData.formData &&
      staffFormData.MasterDatas.name === staffFormTypesConstants.Evaluation
    ) {
      let ReAssessmentInitialValues = {};
      let notInclude = [
        "time_in",
        "time_out",
        "Vital_Signs",
        "vital_signs_name6",
        "noPain",
        "Mental_Status",
        "goals",
        "vitalreportedMD",
        "vitalreportedAgency",
        "Pain",
        "treatmentProvided",
        "autodate",
        "vitalSign_name2",
        "Frequency1",
        "Frequency2",
        "Justification",
        "VisitDate",
      ];
      Object.keys(staffFormData.formData).map((formKey) => {
        if (
          formKey === "goals" &&
          Array.isArray(staffFormData.formData[formKey]) &&
          notInclude.includes(formKey)
        ) {
          // ReAssessmentInitialValues[formKey] = [
          //   ...staffFormData.formData[formKey],
          // ];
          // let goalCount = 8 - staffFormData.formData.goals.length;
          // for (let i = 0; i < goalCount; i++) {
          //   ReAssessmentInitialValues[formKey].push({});
          // }

          let goals = [];

          for (let index = 0; index < totalDefaultGoals; index++) {
            let goalObj = JSON.parse(JSON.stringify(defaultGoalObj));
            if (staffDocument && staffDocument.length > 0) {
              goalObj["effectiveDate"] =
                staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
            }
            if (
              staffFormData.formData &&
              staffFormData.formData.goals &&
              staffFormData.formData.goals.length > 0 &&
              staffFormData.formData.goals[index]
            ) {
              goalObj = staffFormData.formData.goals[index];
            }
            goals.push(goalObj);
          }
          ReAssessmentInitialValues[formKey] = goals;
        } else if (!notInclude.includes(formKey)) {
          ReAssessmentInitialValues[formKey] = staffFormData.formData[formKey];
        } else if (
          Array.isArray(staffFormData.formData[formKey]) &&
          notInclude.includes(formKey)
        ) {
          ReAssessmentInitialValues[formKey] = [{}];
        }
      });
      let formData = JSON.parse(JSON.stringify(staffFormData));
      let date = "";
      if (visitDetail && visitDetail.date) {
        date = visitDetail.date.split("T")[0];

        if (
          staffDetails &&
          staffDetails.staffInitialVisit &&
          staffDetails.staffInitialVisit.length > 0 &&
          staffDetails.staffInitialVisit[0].anticipatedSocDate
        ) {
          date =
            staffDetails.staffInitialVisit[0].anticipatedSocDate.split("T")[0];
        }
        if (confirmDate) {
          date = confirmDate;
        }
        formData.formData.visit_date = date;
        formData.formData.autodate = date;
        let goals = [];

        for (let index = 0; index < totalDefaultGoals; index++) {
          let goalObj = JSON.parse(JSON.stringify(defaultGoalObj));
          if (staffDocument && staffDocument.length > 0) {
            goalObj["effectiveDate"] =
              staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
          }
          if (
            staffFormData.formData &&
            staffFormData.formData.goals &&
            staffFormData.formData.goals.length > 0 &&
            staffFormData.formData.goals[index]
          ) {
            goalObj = staffFormData.formData.goals[index];
          }
          goals.push(goalObj);
        }
        formData.formData.goals = goals;
      }

      if (isAutoFill) {
        return {
          ...ReAssessmentInitialValues,
          Pain: [{}, {}, {}],
          visit_date: date,
          autodate: date,
          Vital_Signs: [
            {
              vital_signs_name1: "",
              vital_signs_name2: "",
              vital_signs_name3: "",
              vital_signs_name4: "",
              vital_signs_name5: "",
            },
          ],
          id: isAutoFill ? undefined : staffFormData.id,
        };
      } else {
        let init = { ...formData.formData, id: formData.id };
        Object.keys(initialValues).forEach((key) => {
          if (Array.isArray(initialValues[key]) && !init[key]) {
            init[key] = initialValues[key];
          }
        });
        return init;
      }
    }

    return initialValues;
  };

  const optionsByName = (name, startFrom, length) => {
    const optionArray = Array.from(
      { length: length ? length : 4 },
      (_, index) => {
        return {
          value: `${name} ${index + startFrom}`,
          label: `${name} ${index + startFrom}`,
        };
      }
    );
    return optionArray;
  };
  const isPainHigh = (painArray) => {
    let highPain = painArray.find((painItem) => painItem.intensity > 6);
    return highPain ? true : false;
  };
  const getEvaluationForm = () => {
    let documentObject =
      staffDocument &&
      staffDocument.length > 0 &&
      staffDocument[0].episodeDocumentations.find(
        (item) =>
          item.episodeVisit.displayName === staffFormTypesConstants.Evaluation
      );
    dispatch(
      getStaffFormdataById({
        episodeDocumentId: Number(documentObject.id),
        formTypeName: staffFormTypesConstants.Evaluation,
      })
    ).then((res) => {
      if (res && res.payload && !res.payload.success) {
      }
    });
  };
  const getReAssesmentForm = () => {
    let documentObject =
      staffDocument &&
      staffDocument.length > 0 &&
      staffDocument[0].episodeDocumentations.find(
        (item) =>
          item.episodeVisit.displayName === staffFormTypesConstants.ReAssessment
      );
    if (documentObject) {
      dispatch(
        getStaffFormdataById({
          episodeDocumentId: Number(documentObject.id),
          formTypeName: staffFormTypesConstants.ReAssessment,
        })
      ).then((res) => {
        if (res && res.payload && !res.payload.success) {
          getEvaluationForm();
        }
      });
    } else {
      getEvaluationForm();
    }
  };
  useEffect(() => {
    if (isAutoFill && !dataAvailable) {
      getReAssesmentForm();
    }
  }, [isAutoFill, dataAvailable]);
  const getFrequency = debounce((inputValue, episodeId) => {
    const {
      target: { value },
    } = inputValue;
    if (value)
      dispatch(
        getFrequencySuggestion({
          episodeId: episodeId,
          userId: staffDetails.id,
          visit: Number(value),
        })
      );
  }, 500);

  const disableByVisitStatus = () => {
    let disabled = true;
    const isCurrentUserAdminOrSupAdminOrAgency =
      currentUser?.Role?.key === currentRole.admin ||
      currentUser?.Role?.key === currentRole.superadmin ||
      currentUser?.Role?.key === currentRole.agency;

    const isCurrentUserSupervisingOrAssistant =
      currentUser?.Role?.key === currentRole.supervisingtherapist ||
      currentUser?.Role?.key === currentRole.assistanttherapist;

    const isVisitInReviewOrCompleted =
      visitDetailById?.Status?.key === "needreview" ||
      visitDetailById?.Status?.key === "completed";
    if (isCurrentUserAdminOrSupAdminOrAgency) {
      disabled = true;
    } else if (
      isCurrentUserSupervisingOrAssistant &&
      isVisitInReviewOrCompleted
    ) {
      disabled = true;
    } else {
      disabled = false;
    }
    if (toSave) {
      disabled = false;
    }
    return disabled;
  };

  const getFormStatus = () => {
    let status = "";
    if (visitDetailById) {
      status = visitDetailById.Status.key;
      if (
        visitDetailById.patientFormList &&
        visitDetailById.patientFormList.length > 0
      ) {
        let form = visitDetailById.patientFormList.find(
          (item) => item.name == staffFormTypesConstants.Evaluation
        );
        if (form) {
          status = form.Status.key;
        }
      }
    }
    return status;
  };

  const disableUseWithText = (values) => {
    let disable = true;
    values.Precautions.map((item) => {
      if (item.precautions_name2.includes("useWith")) {
        disable = false;
      }
    });

    return disable;
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={useMemo(
          (e) => initialValues(e),
          [staffDetails, staffFormData, init, currentUser]
        )}
        keepDirtyOnReinitialize
        validate={validate}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          submitFailed,
          handleSubmit,
          values,
          errors,
          valid,
          touched,
          initialValues,
          form,
        }) => (
          <form
            onSubmit={(e) => {
              if (!valid) toast.error("Please complete all required fields.");
              handleSubmit(e);
            }}
          >
            <div
              className={`${!isPrint ? "container" : "row"}`}
              id="evaluation-form"
            >
              <div
                className={`evaluation-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
                <div className="row mein-white-from-background">
                  <div className="col-md-6 border-right-1">
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "8px",
                        marginBottom: "16px",
                        alignItems: "center",
                      }}
                    >
                      <label
                        style={{
                          margin: "0",
                          lineHeight: "auto",
                          verticalAlign: "middle",
                        }}
                      >
                        MR# :
                      </label>
                      <Field
                        name="mrNo"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              maxlength="45"
                              className="form-control bg-white"
                              {...input}
                              type="text"
                              placeholder=""
                              disabled
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>

                    <div className="fileds-from">
                      <label>Patient :</label>
                      <Field
                        name="PatientName"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              maxlength="100"
                              className="form-control bg-white"
                              {...input}
                              type="text"
                              disabled
                              placeholder=""
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="fileds-from">
                      <label>DOB :</label>
                      <Field
                        name="dob"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              maxlength="45"
                              className="form-control bg-white"
                              {...input}
                              type="date"
                              placeholder=""
                              disabled
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="fileds-from">
                      <label>Agency :</label>
                      <Field
                        name="agency"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              maxlength="45"
                              className="form-control bg-white"
                              {...input}
                              type="text"
                              disabled
                              placeholder=""
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="col-md-6 ">
                    <div className="fileds-from">
                      <label>Visit Date :</label>
                      <Field
                        name="visit_date"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              className="form-control "
                              {...input}
                              min={props.visitDetail && props.visitDetail.id}
                              type="date"
                              placeholder=""
                              disabled={true}
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="fileds-from">
                      <label>Time In :</label>
                      <Field
                        name="time_in"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              maxlength="45"
                              className="form-control"
                              {...input}
                              type="time"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="fileds-from">
                      <label>Time Out :</label>
                      <Field
                        name="time_out"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              className="form-control"
                              maxlength="45"
                              {...input}
                              type="time"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                            {meta.error && meta.touched && (
                              <p
                                className="alert alert-danger w-100 "
                                role="alert"
                              >
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "8px",
                        marginBottom: "16px",
                        alignItems: "center",
                      }}
                    >
                      <label
                        style={{
                          margin: "0",
                          lineHeight: "auto",
                          verticalAlign: "middle",
                        }}
                      >
                        Therapist :
                      </label>
                      <Field
                        name="therapist"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              className="form-control bg-white"
                              maxlength="45"
                              {...input}
                              type="text"
                              disabled
                              placeholder=""
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>

                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    Diagnosis :
                  </h5>
                  <div className="col-md-12 mb-3">
                    <div>
                      {toSave ? (
                        <div>
                          {values.diagnosis &&
                            values.diagnosis.map((item) => {
                              return <span>{`${item.label},`}</span>;
                            })}
                        </div>
                      ) : (
                        <Field
                          name="diagnosis"
                          component="input"
                          type="text"
                          placeholder=""
                          className="input-textSize"
                        >
                          {({ input, meta }) => (
                            <div>
                              <Select
                                {...input}
                                isMulti
                                className="bg-white"
                                options={optionsByName("ICD CODE", 10, 7)}
                                isDisabled={true}
                              />
                              {meta.error && meta.touched && (
                                <p className="alert alert-danger " role="alert">
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          )}
                        </Field>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    Vital Signs :
                  </h5>
                  <FieldArray name="Vital_Signs">
                    {({ fields }) => (
                      <>
                        {fields.map((name, index) => (
                          <>
                            <div className="col-md-3 mb-4 mt-3">
                              <label>BP </label>
                              <div className="d-flex align-items-center">
                                <Field
                                  name={`${name}.vital_signs_name1`}
                                  className="w-50 form-control mr-3"
                                  component="input"
                                  value=""
                                >
                                  {({ input, meta }) => (
                                    <div className="w-50">
                                      <input
                                        disabled={disableByVisitStatus()}
                                        maxlength="3"
                                        className="form-control"
                                        {...input}
                                        type="text"
                                        placeholder=""
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger "
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>
                                <span className="mr-3 primery_span_vital_signs ml-3">
                                  /
                                </span>
                                <Field
                                  name={`${name}.vital_signs_name2`}
                                  component="input"
                                  className="w-50 form-control"
                                  value=""
                                >
                                  {({ input, meta }) => (
                                    <div className="w-50">
                                      <input
                                        maxlength="3"
                                        className="form-control"
                                        {...input}
                                        type="text"
                                        placeholder=""
                                        disabled={disableByVisitStatus()}
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger "
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className="col-md-3 mb-4 mt-3">
                              <>
                                <div className="d-flex justify-content-end flex-column align-items-end">
                                  <label className="text-align-left w-75">
                                    Heart Rate{" "}
                                  </label>
                                  <Field
                                    name={`${name}.vital_signs_name3`}
                                    component="input"
                                    className="form-control ptinput"
                                    type="number"
                                    value=""
                                  >
                                    {({ input, meta }) => (
                                      <div className="w-75">
                                        <input
                                          maxlength="3"
                                          className="form-control ptinput"
                                          {...input}
                                          type="text"
                                          placeholder=""
                                          disabled={disableByVisitStatus()}
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                        />
                                        {meta.error && meta.touched && (
                                          <p
                                            className="alert alert-danger "
                                            role="alert"
                                          >
                                            {meta.error}
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              </>
                            </div>
                            <div className="col-md-3 mb-4 mt-3">
                              <>
                                <div className="d-flex justify-content-end flex-column align-items-end">
                                  <label className="text-align-left w-75">
                                    Respiration{" "}
                                  </label>
                                  <Field
                                    name={`${name}.vital_signs_name4`}
                                    component="input"
                                    type="number"
                                    value=""
                                    className="pteval-input"
                                  >
                                    {({ input, meta }) => (
                                      <div className="w-75">
                                        <input
                                          maxlength="2"
                                          className="form-control"
                                          {...input}
                                          type="text"
                                          placeholder=""
                                          disabled={disableByVisitStatus()}
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                        />
                                        {meta.error && meta.touched && (
                                          <p
                                            className="alert alert-danger "
                                            role="alert"
                                          >
                                            {meta.error}
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              </>
                            </div>
                            <div className="col-md-3 mt-3 ">
                              <>
                                <div className="d-flex justify-content-end flex-column align-items-end">
                                  <label className="text-align-left w-75">
                                    Temperature{" "}
                                  </label>
                                  <Field
                                    name={`${name}.vital_signs_name5`}
                                    component="input"
                                    type="number"
                                    value=""
                                    className="pteval-input"
                                  >
                                    {({ input, meta }) => (
                                      <div className="w-75">
                                        <input
                                          maxlength="3"
                                          className="form-control"
                                          {...input}
                                          type="text"
                                          placeholder=""
                                          disabled={disableByVisitStatus()}
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                        />
                                        {meta.error && meta.touched && (
                                          <p
                                            className="alert alert-danger "
                                            role="alert"
                                          >
                                            {meta.error}
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              </>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </FieldArray>
                  <div className="col-md-12">
                    <div>
                      <Field
                        name={`vital_signs_name6`}
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <>
                            <label>
                              <span className="text-ordered-list mr-1">
                                Additional :
                              </span>
                            </label>
                            {toSave ? (
                              <p>{values.vital_signs_name6}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                className="Additional_text  w-100"
                                {...input}
                                placeholder=""
                                disabled={disableByVisitStatus()}
                              />
                            )}

                            {meta.error && meta.touched && (
                              <p className="meta_err alert alert-danger">
                                {meta.error}
                              </p>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="row mt-5">
                    {vitalSignsValidation(values.Vital_Signs[0]) && (
                      <>
                        <div className="d-flex align-items-center justify-content-between col-md-8">
                          <label>Date</label>
                          <Field
                            name="autodate"
                            component="input"
                            className="input-textSize"
                          >
                            {({ input, meta }) => (
                              <div className="w-40">
                                <input
                                  className="form-control"
                                  {...input}
                                  type="date"
                                  disabled={disableByVisitStatus()}
                                />
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                          <label htmlFor="">
                            Was the abnormal vital(s) reported to the Agency ?
                          </label>
                          <div className="d-flex align-items-center justify-content-between w-40 px-1">
                            <div className="radio-of-forms mr-5">
                              <Field
                                name={`vitalreportedAgency`}
                                type="radio"
                                component="input"
                                value={true}
                              >
                                {({ input, meta }) => (
                                  <>
                                    <input
                                      type="radio"
                                      {...input}
                                      onChange={() => input.onChange(true)}
                                      value={true}
                                      disabled={disableByVisitStatus()}
                                    />
                                  </>
                                )}
                              </Field>
                              <label> Yes </label>
                            </div>
                            <div className="radio-of-forms">
                              <Field
                                name={`vitalreportedAgency`}
                                type="radio"
                                component="input"
                                value={false}
                              >
                                {({ input, meta }) => (
                                  <>
                                    <input
                                      type="radio"
                                      {...input}
                                      onChange={() => input.onChange(false)}
                                      value={false}
                                      disabled={disableByVisitStatus()}
                                    />
                                  </>
                                )}
                              </Field>
                              <label>No </label>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-md-4">
                            <div className="d-flex gap-3">
                              {" "}
                              <p className="fw-bold">Agency Name :</p>
                              <p>
                                {staffDetails &&
                                  staffDetails.createdByUser &&
                                  staffDetails.createdByUser.name}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="d-flex gap-3">
                              <p className="fw-bold">Agency Phone :</p>
                              <p className="text-nowrap">
                                {staffDetails &&
                                  staffDetails.createdByUser &&
                                  staffDetails.createdByUser.userDetail &&
                                  staffDetails.createdByUser.userDetail.phone}
                              </p>
                            </div>
                          </div>
                        </div>

                        {errors.vitalreportedAgency &&
                          touched.vitalreportedAgency && (
                            <p className="alert alert-danger " role="alert">
                              {errors.vitalreportedAgency}
                            </p>
                          )}
                        <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                          <label htmlFor="">
                            Was the abnormal vital(s) reported to the MD ?
                          </label>
                          <div className="d-flex align-items-center justify-content-between w-40 px-1">
                            <div className="radio-of-forms mr-5">
                              <Field
                                name={`vitalreportedMD`}
                                type="radio"
                                component="input"
                                value={true}
                              >
                                {({ input, meta }) => (
                                  <>
                                    <input
                                      type="radio"
                                      {...input}
                                      onChange={() => input.onChange(true)}
                                      value={true}
                                      disabled={disableByVisitStatus()}
                                    />
                                  </>
                                )}
                              </Field>
                              <label> Yes </label>
                            </div>

                            <div className="radio-of-forms">
                              <Field
                                name={`vitalreportedMD`}
                                type="radio"
                                component="input"
                                value={false}
                              >
                                {({ input, meta }) => (
                                  <>
                                    <input
                                      type="radio"
                                      {...input}
                                      onChange={() => input.onChange(false)}
                                      value={false}
                                      disabled={disableByVisitStatus()}
                                    />
                                  </>
                                )}
                              </Field>
                              <label>No </label>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-md-4">
                            <div className="d-flex gap-3 ">
                              <p className=" fw-bold">Physician Name :</p>
                              <p>
                                {staffDetails &&
                                  staffDetails.staffingDetails &&
                                  staffDetails.staffingDetails.physicianName}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-4">
                            {" "}
                            <div className="d-flex  gap-3 ">
                              <p className=" fw-bold">Physician Phone :</p>
                              <p>
                                {staffDetails &&
                                  staffDetails.staffingDetails &&
                                  staffDetails.staffingDetails
                                    .physicianPhoneNumber}
                              </p>
                            </div>
                          </div>
                        </div>

                        {errors.vitalreportedMD && touched.vitalreportedMD && (
                          <p className="alert alert-danger " role="alert">
                            {errors.vitalreportedMD}
                          </p>
                        )}
                        <div className="col-md-12 mt-4 mb-3">
                          <div>
                            <Field
                              name="vitalSign_name2"
                              component="input"
                              type="text"
                              placeholder=""
                              className="input-textSize"
                              disabled={
                                visitDetailById?.Status?.key === "needreview" &&
                                Role ===
                                  (currentRole?.supervisingtherapist ||
                                    currentRole?.assistanttherapist)
                              }
                            >
                              {({ input, meta }) => (
                                <>
                                  <label>
                                    <span className="text-ordered-list mr-1">
                                      Please document who you spoke with at the
                                      Agency and any instructions you were
                                      given.
                                    </span>
                                  </label>
                                  {toSave ? (
                                    <p>{values.vitalSign_name2}</p>
                                  ) : (
                                    <textarea
                                      maxlength="320"
                                      className="Additional_text  w-100"
                                      {...input}
                                      placeholder=""
                                      disabled={disableByVisitStatus()}
                                    />
                                  )}

                                  {meta.error && meta.touched && (
                                    <span className="meta_err">
                                      {meta.error}
                                    </span>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    {" "}
                    Subjective :
                  </h5>
                  <div className="col-md-12 mb-3">
                    <div>
                      <Field
                        name="subjective"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div>
                            {toSave ? (
                              <p>{values.subjective}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                {...input}
                                type="text"
                                placeholder=""
                                disabled={disableByVisitStatus()}
                                className="Additional_text"
                              ></textarea>
                            )}

                            {meta.error && meta.touched && (
                              <p className="alert alert-danger w-100 meta_err">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`evaluation-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
                <div className="row mein-white-from-background h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Reason For Home Health :
                  </h5>
                  <div className="col-md-12 mb-3">
                    <div>
                      <Field
                        name="reason_for_home_health"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div>
                            {toSave ? (
                              <p>{values.reason_for_home_health}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                {...input}
                                type="text"
                                placeholder=""
                                disabled={disableByVisitStatus()}
                                className="Additional_text"
                              ></textarea>
                            )}

                            {meta.error && meta.touched && (
                              <p className="alert alert-danger w-100 meta_err">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="row mein-white-from-background h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Past Medical History :
                  </h5>
                  <div className="col-md-12">
                    <div>
                      <Field
                        name="past_medical_history"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div>
                            {toSave ? (
                              <p>{values.past_medical_history}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                {...input}
                                type="text"
                                placeholder=""
                                disabled={disableByVisitStatus()}
                                className="Additional_text"
                              ></textarea>
                            )}

                            {meta.error && meta.touched && (
                              <p className="alert alert-danger w-100 meta_err">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="row mein-white-from-background h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline ">
                    Prior Level of Function :
                  </h5>
                  <FieldArray name="Prior_Level_of_Function">
                    {({ fields, meta }) => {
                      return (
                        <>
                          {fields.map((name, index) => (
                            <>
                              <div className="col-md-4 mt-3 mb-3">
                                {prior_level_data.map((item, index) => {
                                  if (item.col === 1) {
                                    return (
                                      <>
                                        <div
                                          className="radio-of-forms  gap-3"
                                          key={`priorLevelData_Col1_${index}`}
                                        >
                                          <>
                                            <Field
                                              name={`${name}.prior_Level_of_Function_name1`}
                                              component="input"
                                              type={item?.type}
                                              value={item?.label}
                                              disabled={disableByVisitStatus()}
                                            />
                                            <label>{item.label} </label>
                                          </>
                                        </div>
                                      </>
                                    );
                                  }
                                })}
                                {meta.error &&
                                  meta.touched &&
                                  errors?.Prior_Level_of_Function &&
                                  errors.Prior_Level_of_Function[index] &&
                                  errors.Prior_Level_of_Function[index]
                                    ?.prior_Level_of_Function_name1 && (
                                    <p className="meta_err alert alert-danger">
                                      {
                                        errors.Prior_Level_of_Function[index][
                                          "prior_Level_of_Function_name1"
                                        ]
                                      }
                                    </p>
                                  )}
                              </div>
                              <div className="col-md-4 mt-3 mb-3">
                                {prior_level_data.map((item, index) => {
                                  if (item.col === 2) {
                                    return (
                                      <>
                                        <div
                                          className="radio-of-forms  gap-3"
                                          key={`priorLevelData_Col2_${index}`}
                                        >
                                          <>
                                            <Field
                                              name={`${name}.prior_Level_of_Function_name2`}
                                              component="input"
                                              type={item.type}
                                              value={item.label}
                                            >
                                              {({ input, meta }) => (
                                                <div className="d-flex">
                                                  <input
                                                    type={item.type}
                                                    {...input}
                                                    value={item.label}
                                                    placeholder="FREE TEXT"
                                                    disabled={disableByVisitStatus()}
                                                  />
                                                </div>
                                              )}
                                            </Field>

                                            <label>{item.label} </label>
                                          </>
                                        </div>
                                      </>
                                    );
                                  }
                                })}
                                {meta.error &&
                                  meta.touched &&
                                  errors?.Prior_Level_of_Function &&
                                  errors.Prior_Level_of_Function[index] &&
                                  errors.Prior_Level_of_Function[index]
                                    ?.prior_Level_of_Function_name2 && (
                                    <p className="meta_err alert alert-danger">
                                      {
                                        errors.Prior_Level_of_Function[index][
                                          "prior_Level_of_Function_name2"
                                        ]
                                      }
                                    </p>
                                  )}
                              </div>
                              <div className="col-md-4 mt-3 mb-3">
                                {prior_level_data.map((item, index) => {
                                  if (item.col === 3) {
                                    return (
                                      <>
                                        <div
                                          className="radio-of-forms  gap-3"
                                          key={`priorLevelData_Col3_${index}`}
                                        >
                                          <>
                                            <Field
                                              name={`${name}.prior_Level_of_Function_name3`}
                                              component="input"
                                              type={item.type}
                                              value={item.label}
                                            >
                                              {({ input, meta }) => (
                                                <div className="d-flex">
                                                  <input
                                                    type={item.type}
                                                    {...input}
                                                    value={item.label}
                                                    placeholder="FREE TEXT"
                                                    disabled={disableByVisitStatus()}
                                                  />
                                                </div>
                                              )}
                                            </Field>

                                            <label>{item.label} </label>
                                          </>
                                        </div>
                                      </>
                                    );
                                  }
                                })}
                                {meta.error &&
                                  meta.touched &&
                                  errors?.Prior_Level_of_Function &&
                                  errors.Prior_Level_of_Function[index] &&
                                  errors.Prior_Level_of_Function[index]
                                    ?.prior_Level_of_Function_name3 && (
                                    <p className="meta_err alert alert-danger">
                                      {
                                        errors.Prior_Level_of_Function[index][
                                          "prior_Level_of_Function_name3"
                                        ]
                                      }
                                    </p>
                                  )}
                              </div>
                              <div div className="col-md-12">
                                <Field
                                  name={`${name}.prior_Level_of_Function_name4`}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <label>
                                        <span className="text-ordered-list mr-1">
                                          Additional :
                                        </span>
                                      </label>
                                      {toSave ? (
                                        <p>
                                          {values["Prior_Level_of_Function"] &&
                                            values["Prior_Level_of_Function"]
                                              .length > 0 &&
                                            values["Prior_Level_of_Function"][
                                              index
                                            ]["prior_Level_of_Function_name4"]}
                                        </p>
                                      ) : (
                                        <textarea
                                          maxlength="320"
                                          {...input}
                                          type="text"
                                          placeholder=""
                                          className="Additional_text"
                                          disabled={disableByVisitStatus()}
                                        ></textarea>
                                      )}
                                    </div>
                                  )}
                                </Field>
                                {meta.error &&
                                  meta.touched &&
                                  errors?.Prior_Level_of_Function &&
                                  errors.Prior_Level_of_Function[index] &&
                                  errors.Prior_Level_of_Function[index]
                                    ?.prior_Level_of_Function_name4 && (
                                    <p className="meta_err alert alert-danger">
                                      {
                                        errors.Prior_Level_of_Function[index][
                                          "prior_Level_of_Function_name4"
                                        ]
                                      }
                                    </p>
                                  )}
                              </div>
                            </>
                          ))}
                        </>
                      );
                    }}
                  </FieldArray>
                </div>
                <div className="row mein-white-from-background h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Precautions :
                  </h5>

                  <FieldArray name="Precautions">
                    {({ fields, meta }) => {
                      return (
                        <>
                          {fields.map((name, index) => (
                            <>
                              <div className="col-md-4 mt-3 mb-3">
                                {precautions_data.map((item, index) => {
                                  if (item.col === 1) {
                                    return (
                                      <div
                                        className="radio-of-forms  gap-3"
                                        key={`precautions_data_Col1_${index}`}
                                      >
                                        <Field
                                          name={`${name}.precautions_name1`}
                                          component="input"
                                          type={item.type}
                                          value={item.label}
                                          disabled={disableByVisitStatus()}
                                        />
                                        <label>{item.label} </label>
                                      </div>
                                    );
                                  }
                                })}
                                {meta.error &&
                                  meta.touched &&
                                  errors?.Precautions &&
                                  errors.Precautions[index] &&
                                  errors.Precautions[index]
                                    .precautions_name1 && (
                                    <p className="meta_err alert alert-danger">
                                      {" "}
                                      {
                                        errors.Precautions[index][
                                          "precautions_name1"
                                        ]
                                      }
                                    </p>
                                  )}
                              </div>

                              <div className="col-md-2 mt-3 mb-3">
                                {values.Precautions[0].precautions_name1.includes(
                                  "WB Status"
                                ) && (
                                  <Field name={`wbStatusFreeText`}>
                                    {({ input, meta }) => (
                                      <div className="mb-3">
                                        <input
                                          type="text"
                                          maxlength="10"
                                          {...input}
                                          className="form-control"
                                          disabled={disableByVisitStatus()}
                                        />
                                        {meta.error && meta.touched && (
                                          <p className=" alert alert-danger meta_err">
                                            {meta.error}
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                )}
                              </div>

                              <div className="col-md-4 mt-3 mb-3">
                                <div className="radio-of-forms d-flex gap-3">
                                  <Field
                                    name={`${name}.precautions_name2`}
                                    component="input"
                                    type={"checkbox"}
                                    value={"useWith"}
                                    disabled={disableByVisitStatus()}
                                  >
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          type="checkbox"
                                          {...input}
                                          onChange={(event) => {
                                            input.onChange(event);
                                            if (!event.target.checked) {
                                              form.change(
                                                `${name}.UseWithText`,
                                                ""
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Field>
                                  <label className="d-flex gap-2">
                                    {" "}
                                    Use
                                    <Field name={`${name}.UseWithText`}>
                                      {({ input, meta }) => (
                                        <div>
                                          <input
                                            type="text"
                                            {...input}
                                            disabled={disableUseWithText(
                                              values
                                            )}
                                          />
                                          {meta.error && meta.touched && (
                                            <p className=" alert alert-danger meta_err">
                                              {meta.error}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                    with Gait
                                  </label>
                                </div>
                                {precautions_data.map((item, index) => {
                                  if (item.col === 2) {
                                    return (
                                      <>
                                        <div
                                          className="radio-of-forms  gap-3"
                                          key={`precautions_data_Col2_${index}`}
                                        >
                                          <Field
                                            name={`${name}.precautions_name2`}
                                            component="input"
                                            type={item.type}
                                            value={item.label}
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>{item.label} </label>
                                        </div>
                                      </>
                                    );
                                  }
                                })}
                                {meta.error && meta.touched && (
                                  <p>{meta.error.col2}</p>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      );
                    }}
                  </FieldArray>

                  <div className="col-md-12 mt-3 mb-3">
                    <Field name={`precautions_additional`}>
                      {({ input, meta }) => (
                        <div>
                          <label>
                            <span className="text-ordered-list mr-1">
                              Additional :
                            </span>
                          </label>
                          {toSave ? (
                            <p>{values.precautions_additional}</p>
                          ) : (
                            <textarea
                              maxlength="320"
                              {...input}
                              type="text"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                              className="Additional_text"
                            ></textarea>
                          )}

                          {meta.touched && meta.error && (
                            <p className="meta_err alert alert-danger">
                              {" "}
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              </div>

              <div
                className={`evaluation-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
                <div className="row mein-white-from-background h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Functional Limitations :
                  </h5>
                  <FieldArray name="Functional_Limitation">
                    {({ fields, meta }) => {
                      return (
                        <>
                          {fields.map((name, index) => (
                            <>
                              <div className="col-md-3 mb-3 mt-3">
                                {functional_limitation_data.map(
                                  (item, index) => {
                                    if (item.col === 1) {
                                      return (
                                        <>
                                          <div
                                            className="radio-of-forms  gap-3"
                                            key={`functional_limitation_data_Col1_${index}`}
                                          >
                                            <>
                                              <Field
                                                name={`${name}.functional_limitation_name1`}
                                                component="input"
                                                type={item.type}
                                                value={item.label}
                                                disabled={disableByVisitStatus()}
                                              />
                                              <label>{item.label} </label>
                                            </>
                                          </div>
                                        </>
                                      );
                                    }
                                  }
                                )}
                                {meta.error &&
                                  meta.touched &&
                                  errors.Functional_Limitation &&
                                  errors.Functional_Limitation[index][
                                    "functional_limitation_name1"
                                  ] && (
                                    <p className="meta_err alert alert-danger">
                                      {" "}
                                      {
                                        errors.Functional_Limitation[index][
                                          "functional_limitation_name1"
                                        ]
                                      }
                                    </p>
                                  )}
                              </div>
                              <div className="col-md-3  mb-3 mt-3">
                                {functional_limitation_data.map(
                                  (item, index) => {
                                    if (item.col === 2) {
                                      return (
                                        <>
                                          <div
                                            className="radio-of-forms  gap-3"
                                            key={`functional_limitation_data_Col2_${index}`}
                                          >
                                            <>
                                              <Field
                                                name={`${name}.functional_limitation_name2`}
                                                component="input"
                                                type={item.type}
                                                value={item.label}
                                                disabled={disableByVisitStatus()}
                                              />
                                              <label>{item.label} </label>
                                            </>
                                          </div>
                                        </>
                                      );
                                    }
                                  }
                                )}
                              </div>
                              <div className="col-md-3  mb-3 mt-3">
                                {" "}
                                {functional_limitation_data.map(
                                  (item, index) => {
                                    if (item.col === 3) {
                                      return (
                                        <>
                                          <div
                                            className="radio-of-forms  gap-3"
                                            key={`functional_limitation_data_Col3_${index}`}
                                          >
                                            <>
                                              <Field
                                                name={`${name}.functional_limitation_name3`}
                                                component="input"
                                                type={item.type}
                                                value={item.label}
                                                disabled={disableByVisitStatus()}
                                              />
                                              <label>{item.label} </label>
                                            </>
                                          </div>
                                        </>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      );
                    }}
                  </FieldArray>
                  <div className="col-md-12  mb-3 mt-3">
                    <Field
                      name={`functional_limitation_Fieldname4`}
                      component="textarea"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div>
                          <label className="w-100">
                            <span className="text-ordered-list mr-1">
                              Additional :
                            </span>
                          </label>
                          {toSave ? (
                            <p>{values.functional_limitation_Fieldname4}</p>
                          ) : (
                            <textarea
                              maxlength="320"
                              className="Additional_text"
                              {...input}
                              type="text"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                          )}

                          {meta.touched && meta.error && (
                            <p className="meta_err alert alert-danger">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Living Situation :
                  </h5>
                  <h5 className="py-4 ">Caregiver :</h5>

                  <FieldArray name="Living_Situations">
                    {({ fields, meta }) => (
                      <>
                        {fields.map((name, index) => (
                          <>
                            <div
                              className="col-md-12 mt-3 mb-3"
                              key={`Living_Situations_Col1_${index}`}
                            >
                              <div className="d-flex align-items-center">
                                <div className="radio-of-forms mr-5  gap-3">
                                  <Field
                                    name={`${name}.living_situations_name1`}
                                    component="input"
                                    type="radio"
                                    value="yes"
                                    disabled={disableByVisitStatus()}
                                  />
                                  <label> Yes </label>
                                </div>
                                <div className="radio-of-forms gap-3">
                                  <Field
                                    name={`${name}.living_situations_name1`}
                                    component="input"
                                    type="radio"
                                    value="no"
                                    disabled={disableByVisitStatus()}
                                  />
                                  <label>No </label>
                                </div>
                              </div>
                              {meta.error &&
                                meta.touched &&
                                errors.Living_Situations &&
                                errors.Living_Situations[index] &&
                                errors.Living_Situations[index][
                                  "living_situations_name1"
                                ] && (
                                  <p className="meta_err alert alert-danger">
                                    {" "}
                                    {
                                      errors.Living_Situations[index][
                                        "living_situations_name1"
                                      ]
                                    }
                                  </p>
                                )}
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </FieldArray>
                  <div className="col-md-12 mt-3 mb-3">
                    <div>
                      <Field
                        name="living_situations_name2"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <>
                            <label>
                              <span className="text-ordered-list mr-1">
                                Additional :
                              </span>
                            </label>
                            {toSave ? (
                              <p>{values.living_situations_name2}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                className="Additional_text  w-100"
                                {...input}
                                type="text"
                                placeholder=""
                                disabled={disableByVisitStatus()}
                              />
                            )}

                            {meta.error && meta.touched && (
                              <p className="meta_err alert alert-danger">
                                {meta.error}
                              </p>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Home Environment :
                  </h5>

                  <FieldArray name="Home_Environment">
                    {({ fields, meta }) => (
                      <>
                        {fields.map((name, index) => (
                          <>
                            <div className="col-md-12 mt-3 mb-3">
                              <div className="radio-of-forms mr-5">
                                {home_env_data.map((item, index) => (
                                  <div
                                    className="d-flex align-items-center"
                                    key={`Home_Environment_Col1_${index}`}
                                  >
                                    <Field
                                      className="mr-3"
                                      name={`${name}.home_environment_name1`}
                                      component="input"
                                      type="radio"
                                      value={item.label}
                                      disabled={disableByVisitStatus()}
                                    />
                                    <label> {item.label} </label>
                                  </div>
                                ))}
                              </div>
                              {meta.error &&
                                meta.touched &&
                                errors?.Home_Environment &&
                                errors.Home_Environment[index] &&
                                errors.Home_Environment[index]
                                  ?.home_environment_name1 && (
                                  <p className="meta_err alert alert-danger">
                                    {
                                      errors.Home_Environment[index][
                                        "home_environment_name1"
                                      ]
                                    }
                                  </p>
                                )}
                            </div>
                            <div className="col-md-12  mt-3 mb-3">
                              <Field name={`${name}.home_environment_name2`}>
                                {({ input, meta }) => (
                                  <div>
                                    <label>
                                      <span className="text-ordered-list mr-1">
                                        Additional :
                                      </span>
                                    </label>

                                    {toSave ? (
                                      <p>
                                        {values["Home_Environment"] &&
                                          values["Home_Environment"].length >
                                            0 &&
                                          values["Home_Environment"][index][
                                            "home_environment_name2"
                                          ]}
                                      </p>
                                    ) : (
                                      <textarea
                                        maxlength="320"
                                        {...input}
                                        type="text"
                                        placeholder=""
                                        disabled={disableByVisitStatus()}
                                        className="Additional_text"
                                      ></textarea>
                                    )}

                                    {meta.error && meta.touched && (
                                      <p className="meta_err alert alert-danger">
                                        {meta.error}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Equipment in Home :
                  </h5>

                  <FieldArray name="Equipment_in_Home">
                    {({ fields, meta }) => (
                      <>
                        {fields.map((name, index) => (
                          <>
                            <>
                              <div className="col-md-4 mt-3 " key={index}>
                                {equipment_data.map((item, index) => {
                                  if (item.col === 1) {
                                    return (
                                      <div
                                        className="radio-of-forms  gap-3"
                                        key={`Equipment_in_Home_Col1_${index}`}
                                      >
                                        <Field
                                          name={`${name}.equipment_in_home_name1`}
                                          component="input"
                                          type={item.type}
                                          value={item.label}
                                          disabled={disableByVisitStatus()}
                                        />

                                        <label>{item.label} </label>
                                      </div>
                                    );
                                  }
                                })}
                                {meta.error &&
                                  meta.touched &&
                                  errors?.Equipment_in_Home &&
                                  errors.Equipment_in_Home[index] &&
                                  errors.Equipment_in_Home[index]
                                    .equipment_in_home_name1 && (
                                    <p className="meta_err alert alert-danger mb-3">
                                      {
                                        errors.Equipment_in_Home[index][
                                          "equipment_in_home_name1"
                                        ]
                                      }
                                    </p>
                                  )}
                              </div>
                              <div className="col-md-4  mt-3" key={index}>
                                {equipment_data.map((item, index) => {
                                  if (item.col === 2) {
                                    return (
                                      <>
                                        <div
                                          className="radio-of-forms  gap-3"
                                          key={`Equipment_in_Home_Col2_${index}`}
                                        >
                                          <Field
                                            name={`${name}.equipment_in_home_name2`}
                                            component="input"
                                            type={item.type}
                                            value={item.label}
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>{item.label} </label>
                                        </div>
                                      </>
                                    );
                                  }
                                })}
                              </div>
                              <div className="col-md-4  mt-3" key={index}>
                                {equipment_data.map((item, index) => {
                                  if (item.col === 3) {
                                    return (
                                      <div
                                        className="radio-of-forms  gap-3"
                                        key={`Equipment_in_Home_Col3_${index}`}
                                      >
                                        <Field
                                          name={`${name}.equipment_in_home_name3`}
                                          component="input"
                                          type={item.type}
                                          value={item.label}
                                          disabled={disableByVisitStatus()}
                                        />
                                        <Col>
                                          <label>{item.label} </label>
                                        </Col>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </>
                          </>
                        ))}
                      </>
                    )}
                  </FieldArray>

                  <div className="col-md-12">
                    <div>
                      <Field
                        name={`equipment_in_home_name4`}
                        component="textarea"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div>
                            <label>
                              <span className="text-ordered-list mr-1">
                                Additional :
                              </span>
                            </label>
                            {toSave ? (
                              <p>{values.equipment_in_home_name4}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                className="Additional_text"
                                {...input}
                                type="text"
                                placeholder=""
                                disabled={disableByVisitStatus()}
                              ></textarea>
                            )}

                            {meta.error && meta.touched && (
                              <p className="meta_err alert alert-danger">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`evaluation-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Mental Status :
                  </h5>
                  <FieldArray name="Mental_Status">
                    {({ fields, meta }) => (
                      <>
                        {fields.map((name, index) => (
                          <>
                            <div className="col-md-4">
                              <div className="radio-of-forms">
                                <Field
                                  name={`${name}.mental_status_Alert`}
                                  component="input"
                                  type={"checkbox"}
                                  disabled={disableByVisitStatus()}
                                />
                                <label>{"Alert"} </label>
                              </div>
                            </div>
                            <div className="col-md-4 d-flex gap-2">
                              <div>
                                <p className="">Oriented To:</p>
                              </div>
                              <div>
                                {mental_status_data.map((item, index) => {
                                  return (
                                    <div className="radio-of-forms  gap-3">
                                      <Field
                                        name={`${name}.mental_status_OrientedTo`}
                                        component="input"
                                        type={item.type}
                                        value={item.label}
                                        disabled={disableByVisitStatus()}
                                      />
                                      <label>{item.label} </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="radio-of-forms">
                                <Field
                                  name={`${name}.mental_status_Other`}
                                  component="input"
                                  type={"checkbox"}
                                  disabled={disableByVisitStatus()}
                                />
                                <label>{"Other"} </label>
                              </div>
                            </div>
                            <div className="col-md-12">
                              {touched[
                                "Mental_Status[0].mental_status_Alert"
                              ] &&
                                errors?.Mental_Status &&
                                errors.Mental_Status[index] &&
                                errors.Mental_Status[index]
                                  .mental_status_Alert && (
                                  <p className="meta_err alert alert-danger">
                                    {
                                      errors.Mental_Status[index][
                                        "mental_status_Alert"
                                      ]
                                    }
                                  </p>
                                )}
                            </div>
                            <div className="col-md-12">
                              <label>Additional :</label>
                              <Field
                                name={`${name}.mental_status_Additional`}
                                component="input"
                                type="text"
                                placeholder=""
                                className="input-textSize"
                              >
                                {({ input, meta }) => (
                                  <div>
                                    {toSave ? (
                                      <p>
                                        {values["Mental_Status"] &&
                                          values["Mental_Status"].length > 0 &&
                                          values["Mental_Status"][index][
                                            "mental_status_Additional"
                                          ]}
                                      </p>
                                    ) : (
                                      <textarea
                                        maxlength="320"
                                        className=" Additional_text"
                                        {...input}
                                        type="text"
                                        placeholder=""
                                        disabled={disableByVisitStatus()}
                                      />
                                    )}

                                    {meta.error && meta.touched && (
                                      <p
                                        className="alert alert-danger "
                                        role="alert"
                                      >
                                        {meta.error}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline"> Pain :</h5>

                  <div className="col-md-10">
                    <div className="radio-of-forms gap-3  mt-3">
                      <label className="pl-0"> No Pain </label>
                      <Field
                        name={`noPain`}
                        component="input"
                        type="checkbox"
                        disabled={disableByVisitStatus()}
                      />
                    </div>
                  </div>
                  {!values.noPain && (
                    <>
                      <div className="row my-1">
                        <div className="col-md-3 h-none">
                          <p className="my-2">Intensity :</p>
                        </div>
                        <FieldArray name="Pain">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <>
                                <div className="col-md-3 h-none">
                                  {toSave ? (
                                    <p>
                                      {values.Pain &&
                                        values.Pain[index]["intensity"] &&
                                        values.Pain[index]["intensity"]}{" "}
                                    </p>
                                  ) : (
                                    <Field
                                      name={`${name}.intensity`}
                                      component="select"
                                      className="paitiant-chart-input form-select"
                                    >
                                      {({ input, meta }) => (
                                        <>
                                          <select
                                            {...input}
                                            className="form-control"
                                            disabled={disableByVisitStatus()}
                                          >
                                            {Array.from(
                                              { length: 11 },
                                              (_, index) => (
                                                <option
                                                  key={index}
                                                  value={index}
                                                >
                                                  {index}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          {meta.error && meta.touched && (
                                            <p
                                              className="alert alert-danger "
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  )}
                                </div>
                              </>
                            ))
                          }
                        </FieldArray>
                      </div>
                      <div className="row my-1">
                        <div className="col-md-3 h-none">
                          <p className="my-2">Location :</p>
                        </div>
                        <FieldArray name="Pain">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <div className="col-md-3 h-none">
                                <Field
                                  name={`${name}.location`}
                                  component="input"
                                  type="text"
                                  className="form-control"
                                >
                                  {({ input, meta }) => (
                                    <>
                                      <input
                                        className="form-control"
                                        {...input}
                                        type="text"
                                        disabled={disableByVisitStatus()}
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger "
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </div>
                            ))
                          }
                        </FieldArray>
                      </div>
                      <div className="row my-1">
                        <div className="col-md-3 h-none">
                          <p className="my-2">Description :</p>
                        </div>
                        <FieldArray name="Pain">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <div className="col-md-3 h-none">
                                <Field
                                  name={`${name}.description`}
                                  component="input"
                                  type="text"
                                  className="form-control"
                                >
                                  {({ input, meta }) => (
                                    <>
                                      <input
                                        className="form-control"
                                        {...input}
                                        type="text"
                                        disabled={disableByVisitStatus()}
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger "
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </div>
                            ))
                          }
                        </FieldArray>
                      </div>
                      <div className="row my-1">
                        <div className="col-md-3 h-none">
                          <p className="my-2">Increases With:</p>
                        </div>
                        <FieldArray name="Pain">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <div className="col-md-3 h-none">
                                <Field
                                  name={`${name}.increasedWith`}
                                  component="input"
                                  type="text"
                                  className="form-control"
                                >
                                  {({ input, meta }) => (
                                    <>
                                      <input
                                        className="form-control"
                                        {...input}
                                        type="text"
                                        disabled={disableByVisitStatus()}
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger "
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </div>
                            ))
                          }
                        </FieldArray>
                      </div>
                      <div className="row my-1">
                        <div className="col-md-3 h-none">
                          <p className="my-2">Decrease With:</p>
                        </div>
                        <FieldArray name="Pain">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <div className="col-md-3 h-none">
                                <Field
                                  name={`${name}.decreasedWith`}
                                  component="input"
                                  type="text"
                                  className="form-control"
                                >
                                  {({ input, meta }) => (
                                    <>
                                      <input
                                        className="form-control"
                                        {...input}
                                        type="text"
                                        disabled={disableByVisitStatus()}
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger "
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </div>
                            ))
                          }
                        </FieldArray>
                      </div>

                      <div className="row mt-5">
                        {isPainHigh(values.Pain) && (
                          <>
                            <div className="d-flex align-items-center justify-content-between col-md-8">
                              <label>Date</label>
                              <Field
                                name={`highPaindate`}
                                component="input"
                                className="input-textSize"
                              >
                                {({ input, meta }) => (
                                  <div className="w-40">
                                    <input
                                      className="form-control"
                                      {...input}
                                      type="date"
                                      disabled={disableByVisitStatus()}
                                    />
                                    {meta.error && meta.touched && (
                                      <p
                                        className="alert alert-danger "
                                        role="alert"
                                      >
                                        {meta.error}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </div>
                            <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                              <label htmlFor="">
                                Was the abnormal Pain reported to the Agency ?
                              </label>
                              <div className="d-flex align-items-center justify-content-between w-40 px-1">
                                <div className="radio-of-forms mr-5">
                                  <Field
                                    name={`highPainReportedToAgency`}
                                    type="radio"
                                    component="input"
                                    value={true}
                                  >
                                    {({ input, meta }) => (
                                      <>
                                        <input
                                          type="radio"
                                          {...input}
                                          onChange={() => input.onChange(true)}
                                          value={true}
                                          disabled={disableByVisitStatus()}
                                        />
                                      </>
                                    )}
                                  </Field>
                                  <label> Yes </label>
                                </div>
                                <div className="radio-of-forms">
                                  <Field
                                    name={`highPainReportedToAgency`}
                                    type="radio"
                                    component="input"
                                    value={false}
                                  >
                                    {({ input, meta }) => (
                                      <>
                                        <input
                                          type="radio"
                                          {...input}
                                          onChange={() => input.onChange(false)}
                                          value={false}
                                          disabled={disableByVisitStatus()}
                                        />
                                        {}
                                      </>
                                    )}
                                  </Field>
                                  <label>No </label>
                                </div>
                              </div>
                            </div>
                            {values.highPainReportedToAgency && (
                              <div className="d-flex align-items-center  gap-5 col-md-8 mt-4">
                                <div className="d-flex gap-3 ">
                                  <p className="w-100 fw-bold">Agency Name :</p>
                                  <p>
                                    {staffDetails &&
                                      staffDetails.createdByUser &&
                                      staffDetails.createdByUser.name}
                                  </p>
                                </div>
                                <div className="d-flex  gap-3 ">
                                  <p className="w-100 fw-bold">
                                    Agency Phone :
                                  </p>
                                  <p>
                                    {staffDetails &&
                                      staffDetails.createdByUser &&
                                      staffDetails.createdByUser.userDetail &&
                                      staffDetails.createdByUser.userDetail
                                        .phone}
                                  </p>
                                </div>
                              </div>
                            )}
                            {errors.highPainReportedToAgency &&
                              touched.highPainReportedToAgency && (
                                <p className="alert alert-danger " role="alert">
                                  {errors.highPainReportedToAgency}
                                </p>
                              )}
                            <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                              <label htmlFor="">
                                Was the abnormal Pain reported to the MD ?
                              </label>
                              <div className="d-flex align-items-center justify-content-between w-40 px-1">
                                <div className="radio-of-forms mr-5">
                                  <Field
                                    name={`highPainReportedToMD`}
                                    type="radio"
                                    component="input"
                                    value={true}
                                  >
                                    {({ input, meta }) => (
                                      <>
                                        <input
                                          type="radio"
                                          {...input}
                                          onChange={() => input.onChange(true)}
                                          value={true}
                                          disabled={disableByVisitStatus()}
                                        />
                                      </>
                                    )}
                                  </Field>
                                  <label> Yes </label>
                                </div>

                                <div className="radio-of-forms">
                                  <Field
                                    name={`highPainReportedToMD`}
                                    type="radio"
                                    component="input"
                                    value={false}
                                  >
                                    {({ input, meta }) => (
                                      <>
                                        <input
                                          type="radio"
                                          {...input}
                                          onChange={() => input.onChange(false)}
                                          value={false}
                                          disabled={disableByVisitStatus()}
                                        />
                                      </>
                                    )}
                                  </Field>
                                  <label>No </label>
                                </div>
                              </div>
                            </div>
                            {values.highPainReportedToMD && (
                              <div className="d-flex align-items-center  gap-5 col-md-8 mt-4">
                                <div className="d-flex gap-3 ">
                                  <p className="w-100 fw-bold">
                                    Physician Name :
                                  </p>
                                  <p>
                                    {staffDetails &&
                                      staffDetails.staffingDetails &&
                                      staffDetails.staffingDetails
                                        .physicianName}
                                  </p>
                                </div>
                                <div className="d-flex  gap-3 ">
                                  <p className="w-100 fw-bold">
                                    Physician Phone :
                                  </p>
                                  <p>
                                    {staffDetails &&
                                      staffDetails.staffingDetails &&
                                      staffDetails.staffingDetails
                                        .physicianPhoneNumber}
                                  </p>
                                </div>
                              </div>
                            )}
                            {errors.highPainReportedToMD &&
                              touched.highPainReportedToMD && (
                                <p className="alert alert-danger " role="alert">
                                  {errors.highPainReportedToMD}
                                </p>
                              )}
                            <div className="col-md-12 mt-4 mb-3">
                              <div>
                                <Field
                                  name="vitalSign_name2"
                                  component="input"
                                  type="text"
                                  placeholder=""
                                  className="input-textSize"
                                >
                                  {({ input, meta }) => (
                                    <>
                                      <label>
                                        <span className="text-ordered-list mr-1">
                                          Please document who you spoke with at
                                          the Agency and any instructions you
                                          were given.
                                        </span>
                                      </label>
                                      {toSave ? (
                                        <p>{values.vitalSign_name2}</p>
                                      ) : (
                                        <textarea
                                          maxlength="320"
                                          className="Additional_text  w-100"
                                          {...input}
                                          placeholder=""
                                          disabled={disableByVisitStatus()}
                                        />
                                      )}

                                      {meta.error && meta.touched && (
                                        <span className="meta_err">
                                          {meta.error}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">ROM :</h5>

                  <FieldArray name="Rom">
                    {({ fields, meta }) => (
                      <>
                        {fields.map((name, index) => (
                          <>
                            <div className="col-md-5 mt-3">
                              <div className="d-flex gap-4">
                                <h5>WFL:</h5>
                                {rom_data.map((item, index) => (
                                  <div className="radio-of-forms gap-3 ">
                                    <Field
                                      name={`${name}.rom_check1`}
                                      component="input"
                                      type={item.type}
                                      placeholder=""
                                      value={item.label}
                                      disabled={disableByVisitStatus()}
                                    />
                                    <label> {item.label} </label>
                                  </div>
                                ))}
                              </div>

                              {meta.error &&
                                meta.touched &&
                                errors?.Rom[index] &&
                                errors.Rom[index]["rom_check1"] && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {errors.Rom[index]["rom_check1"]}
                                  </p>
                                )}
                            </div>

                            <div className="col-md-7"></div>
                          </>
                        ))}
                      </>
                    )}
                  </FieldArray>

                  <div className="col-md-12 mt-3 mb-3">
                    <Field
                      name={`rom_name`}
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div>
                          <label>
                            <span className="text-ordered-list mr-1">
                              Limitations :
                            </span>
                          </label>
                          {toSave ? (
                            <p>{values.rom_name}</p>
                          ) : (
                            <textarea
                              maxlength="320"
                              className=" Additional_text"
                              {...input}
                              type="text"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                          )}

                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">Strength :</h5>

                  <FieldArray name="Strength">
                    {({ fields, meta }) => (
                      <>
                        {fields.map((name, index) => (
                          <>
                            <div className="col-md-5 mt-3">
                              <div className="d-flex gap-4">
                                <h5>WFL:</h5>
                                {rom_data.map((item, index) => (
                                  <div className="radio-of-forms gap-3 ">
                                    <Field
                                      name={`${name}.strength_check1`}
                                      component="input"
                                      type={item.type}
                                      placeholder=""
                                      value={item.label}
                                      disabled={disableByVisitStatus()}
                                    />
                                    <label> {item.label} </label>
                                  </div>
                                ))}
                              </div>
                              {meta.error &&
                                meta.touched &&
                                errors?.Strength[index] &&
                                errors.Strength[index]["strength_check1"] && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {errors.Strength[index]["strength_check1"]}
                                  </p>
                                )}
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </FieldArray>

                  <div className="col-md-12 mt-3 mb-3">
                    <Field
                      name={`strength_name`}
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div>
                          <label>
                            <span className="text-ordered-list mr-1">
                              Limitations :
                            </span>
                          </label>
                          {toSave ? (
                            <p>{values.strength_name}</p>
                          ) : (
                            <textarea
                              maxlength="320"
                              className="Additional_text"
                              {...input}
                              type="text"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                          )}

                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  {/* <h6 className="py-4">Neurological:</h6> */}
                  <h5 className="py-4 text-decoration-underline">
                    Neurological :
                  </h5>

                  <div className="col-md-6 mt-3">
                    <div className="radio-of-forms gap-3 ">
                      <Field
                        name="deficits"
                        component="input"
                        type="checkbox"
                        placeholder="First Name"
                        disabled={disableByVisitStatus()}
                      />
                      <label>No deficits</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <Field
                      name="deficitsTextBox"
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div>
                          <label>
                            <span className="text-ordered-list mr-1">
                              Deficits:
                            </span>
                          </label>
                          {toSave ? (
                            <p>{values.deficitsTextBox}</p>
                          ) : (
                            <textarea
                              maxlength="320"
                              className=" Additional_text"
                              {...input}
                              type="text"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                          )}

                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              </div>

              <div
                className={`evaluation-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">Posture :</h5>

                  {posture.map((checkboxItem) => (
                    <div className="col-md-4 mt-3">
                      <div className="radio-of-forms gap-3">
                        <Field
                          name={`posture`}
                          component="input"
                          type="checkbox"
                          value={checkboxItem}
                          disabled={disableByVisitStatus()}
                        />
                        {checkboxItem}
                      </div>
                    </div>
                  ))}
                  {touched.posture && errors.posture && (
                    <p className="alert alert-danger " role="alert">
                      {errors.posture}
                    </p>
                  )}

                  <div className="col-md-12">
                    <Field
                      name="postureTextBox"
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div>
                          <label>
                            <span className="text-ordered-list mr-1">
                              Additional :
                            </span>
                          </label>
                          {toSave ? (
                            <p>{values.postureTextBox}</p>
                          ) : (
                            <textarea
                              maxlength="320"
                              className=" Additional_text"
                              {...input}
                              type="text"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                          )}

                          {meta.touched && meta.error && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Functional Mobility :
                  </h5>
                  <div className="col-md-12">
                    <div className="form-pt-table overflow-hidden Functional_Mobility_table">
                      <table className="table table-responsive w-100 rounded">
                        <thead>
                          <tr>
                            <th>Functional Mobility</th>
                            {array.map((item) => (
                              <th> {item}</th>
                            ))}
                          </tr>
                        </thead>
                        <FieldArray name="functional_mobility">
                          {({ fields }) =>
                            fields.map((item) => (
                              <>
                                <tbody>
                                  <tr className="boder-form-pt-b">
                                    <td>Bed Mobility</td>
                                    {array.map((checkboxItem) => (
                                      <td className="align-items-center px-0">
                                        <Field
                                          name={`${item}.bedMobility`}
                                          component="input"
                                          type="checkbox"
                                          value={checkboxItem}
                                        >
                                          {({ input, meta }) => (
                                            <div
                                              className={`d-flex justify-content-center w-100 h-100 ${
                                                meta.error && meta.touched
                                                  ? "border-table-danger"
                                                  : "border-0 "
                                              }`}
                                            >
                                              <input
                                                maxlength="5"
                                                type="text"
                                                {...input}
                                                value={checkboxItem}
                                                disabled={disableByVisitStatus()}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </td>
                                    ))}
                                  </tr>

                                  <tr className="boder-form-pt-b">
                                    <td>Rolling</td>

                                    {array.map((checkboxItem) => (
                                      <td className="align-items-center px-0">
                                        <Field
                                          name={`${item}.Rolling`}
                                          component="input"
                                          type="checkbox"
                                          value={checkboxItem}
                                          placeholder="FREE TEXT"
                                        >
                                          {({ input, meta }) => (
                                            <div
                                              className={`d-flex justify-content-center w-100 h-100 ${
                                                meta.error && meta.touched
                                                  ? "border-table-danger"
                                                  : "border-0 "
                                              }`}
                                            >
                                              <input
                                                type="text"
                                                {...input}
                                                value={checkboxItem}
                                                disabled={disableByVisitStatus()}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </td>
                                    ))}
                                  </tr>
                                  <tr className="boder-form-pt-b">
                                    <td>Scooting</td>

                                    {array.map((checkboxItem) => (
                                      <td className="align-items-center px-0">
                                        <Field
                                          name={`${item}.Scooting`}
                                          component="input"
                                          type="checkbox"
                                          value={checkboxItem}
                                          placeholder="FREE TEXT"
                                        >
                                          {({ input, meta }) => (
                                            <div
                                              className={`d-flex justify-content-center w-100 h-100 ${
                                                meta.error && meta.touched
                                                  ? "border-table-danger"
                                                  : "border-0 "
                                              }`}
                                            >
                                              <input
                                                type="text"
                                                {...input}
                                                value={checkboxItem}
                                                disabled={disableByVisitStatus()}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </td>
                                    ))}
                                  </tr>
                                  <tr className="boder-form-pt-b">
                                    <td>Supine to Sit</td>

                                    {array.map((checkboxItem) => (
                                      <td className="align-items-center px-0">
                                        <Field
                                          name={`${item}.supineToSit`}
                                          component="input"
                                          type="checkbox"
                                          value={checkboxItem}
                                          placeholder="FREE TEXT"
                                        >
                                          {({ input, meta }) => (
                                            <div
                                              className={`d-flex justify-content-center w-100 h-100 ${
                                                meta.error && meta.touched
                                                  ? "border-table-danger"
                                                  : "border-0 "
                                              }`}
                                            >
                                              <input
                                                type="text"
                                                {...input}
                                                value={checkboxItem}
                                                disabled={disableByVisitStatus()}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </td>
                                    ))}
                                  </tr>
                                </tbody>
                              </>
                            ))
                          }
                        </FieldArray>
                        <thead className="thead-2">
                          <tr>
                            <th>Transfers</th>
                            {array.map((item) => (
                              <th> {item}</th>
                            ))}
                          </tr>
                        </thead>
                        <FieldArray name="transfers">
                          {({ fields }) =>
                            fields.map((item) => (
                              <tbody>
                                <tr className="boder-form-pt-b">
                                  <td>Sit to Stand</td>

                                  {array.map((checkboxItem) => (
                                    <td className="align-items-center px-0">
                                      <Field
                                        name={`${item}.Sit`}
                                        component="input"
                                        type="checkbox"
                                        value={checkboxItem}
                                        placeholder="FREE TEXT"
                                      >
                                        {({ input, meta }) => (
                                          <div
                                            className={`d-flex justify-content-center w-100 h-100 ${
                                              meta.error && meta.touched
                                                ? "border-table-danger"
                                                : "border-0 "
                                            }`}
                                          >
                                            <input
                                              type="text"
                                              {...input}
                                              value={checkboxItem}
                                              disabled={disableByVisitStatus()}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </td>
                                  ))}
                                </tr>

                                <tr className="boder-form-pt-b">
                                  <td>Bed to Chair</td>

                                  {array.map((checkboxItem) => (
                                    <td className="align-items-center px-0">
                                      <Field
                                        name={`${item}.Bed`}
                                        component="input"
                                        type="checkbox"
                                        value={checkboxItem}
                                        placeholder="FREE TEXT"
                                      >
                                        {({ input, meta }) => (
                                          <div
                                            className={`d-flex justify-content-center w-100 h-100 ${
                                              meta.error && meta.touched
                                                ? "border-table-danger"
                                                : "border-0 "
                                            }`}
                                          >
                                            <input
                                              type="text"
                                              {...input}
                                              value={checkboxItem}
                                              disabled={disableByVisitStatus()}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </td>
                                  ))}
                                </tr>
                                <tr className="boder-form-pt-b">
                                  <td>Toilet</td>

                                  {array.map((checkboxItem) => (
                                    <td className="align-items-center px-0">
                                      <Field
                                        name={`${item}.Toilet`}
                                        component="input"
                                        type="checkbox"
                                        value={checkboxItem}
                                        placeholder="FREE TEXT"
                                      >
                                        {({ input, meta }) => (
                                          <div
                                            className={`d-flex justify-content-center w-100 h-100 ${
                                              meta.error && meta.touched
                                                ? "border-table-danger"
                                                : "border-0 "
                                            }`}
                                          >
                                            <input
                                              type="text"
                                              {...input}
                                              value={checkboxItem}
                                              disabled={disableByVisitStatus()}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </td>
                                  ))}
                                </tr>
                                <tr className="boder-form-pt-b">
                                  <td>Shower</td>

                                  {array.map((checkboxItem) => (
                                    <td className="align-items-center px-0">
                                      <Field
                                        name={`${item}.Shower`}
                                        component="input"
                                        type="checkbox"
                                        value={checkboxItem}
                                        placeholder="FREE TEXT"
                                      >
                                        {({ input, meta }) => (
                                          <div
                                            className={`d-flex justify-content-center w-100 h-100 ${
                                              meta.error && meta.touched
                                                ? "border-table-danger"
                                                : "border-0 "
                                            }`}
                                          >
                                            <input
                                              type="text"
                                              {...input}
                                              value={checkboxItem}
                                              disabled={disableByVisitStatus()}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </td>
                                  ))}
                                  <td className="px-0"></td>
                                </tr>
                                {/* <tr className="boder-form-pt-b">
                                <td>Other</td>

                                {array.map((checkboxItem) => (
                                  <td className="px-0 align-items-center">
                                    <Field
                                      name={`${item}.Other`}
                                      component="input"
                                      type="checkbox"
                                      value={checkboxItem}
                                    >
                                      {({ input, meta }) => (
                                        <div
                                          className={`d-flex justify-content-center w-100 h-100 ${
                                            meta.error && meta.touched
                                              ? "border-table-danger"
                                              : "border-0 "
                                          }`}
                                        >
                                          <input
                                            type="text"
                                            {...input}
                                            value={checkboxItem}
                                          />
                                        </div>
                                      )}
                                    </Field>
                                  </td>
                                ))}
                              </tr> */}
                                <tr className="boder-form-pt-b">
                                  <td>Wheel Chair Mobility</td>

                                  {array.map((checkboxItem) => (
                                    <td className="align-items-center px-0">
                                      <Field
                                        name={`${item}.Chair`}
                                        component="input"
                                        type="checkbox"
                                        value={checkboxItem}
                                        placeholder="FREE TEXT"
                                      >
                                        {({ input, meta }) => (
                                          <div
                                            className={`d-flex justify-content-center w-100 h-100 ${
                                              meta.error && meta.touched
                                                ? "border-table-danger"
                                                : "border-0 "
                                            }`}
                                          >
                                            <input
                                              type="text"
                                              {...input}
                                              value={checkboxItem}
                                              disabled={disableByVisitStatus()}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </td>
                                  ))}
                                </tr>
                              </tbody>
                            ))
                          }
                        </FieldArray>
                      </table>

                      <div className="col-md-12 mt-3 mb-3">
                        <Field
                          name={"functionalMobility-additional"}
                          component="input"
                          type="text"
                          placeholder=""
                          className="input-textSize"
                        >
                          {({ input, meta }) => (
                            <div>
                              <label>
                                <span className="text-ordered-list mr-1">
                                  Additional :
                                </span>
                              </label>
                              {toSave ? (
                                <p>{values["functionalMobility-additional"]}</p>
                              ) : (
                                <textarea
                                  maxlength="320"
                                  className="Additional_text"
                                  {...input}
                                  type="text"
                                  placeholder=""
                                  disabled={disableByVisitStatus()}
                                />
                              )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">Balance :</h5>
                  <div className="col-md-12">
                    <div className="form-pt-table overflow-hidden Functional_Mobility_table">
                      <table className="table table-responsive w-100 rounded">
                        <thead>
                          <tr>
                            <th>Balance</th>
                            {balanceArray.map((item) => (
                              <th> {item}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray name="Sitting">
                            {({ fields }) =>
                              fields.map((item) => (
                                <>
                                  <tr className="boder-form-pt-b">
                                    <td className="px-2">Sitting</td>
                                    <td className="px-2 border-right-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-left-0"></td>
                                  </tr>

                                  <tr className="boder-form-pt-b">
                                    <td>Static</td>

                                    {balanceArray.map((checkboxItem) => (
                                      <td className="align-items-center px-0">
                                        <Field
                                          name={`${item}.Static`}
                                          component="input"
                                          type="checkbox"
                                          value={checkboxItem}
                                          placeholder="FREE TEXT"
                                        >
                                          {({ input, meta }) => (
                                            <div
                                              className={`d-flex justify-content-center w-100 h-100 ${
                                                meta.error && meta.touched
                                                  ? "border-table-danger"
                                                  : "border-0 "
                                              }`}
                                            >
                                              <input
                                                type="text"
                                                {...input}
                                                value={checkboxItem}
                                                disabled={disableByVisitStatus()}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </td>
                                    ))}
                                  </tr>
                                  <tr className="boder-form-pt-b">
                                    <td>Dynamic</td>

                                    {balanceArray.map((checkboxItem) => (
                                      <td className="align-items-center px-0">
                                        <Field
                                          name={`${item}.Dynamic`}
                                          component="input"
                                          type="checkbox"
                                          value={checkboxItem}
                                          placeholder="FREE TEXT"
                                        >
                                          {({ input, meta }) => (
                                            <div
                                              className={`d-flex justify-content-center w-100 h-100 ${
                                                meta.error && meta.touched
                                                  ? "border-table-danger"
                                                  : "border-0 "
                                              }`}
                                            >
                                              <input
                                                type="text"
                                                {...input}
                                                value={checkboxItem}
                                                disabled={disableByVisitStatus()}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </td>
                                    ))}
                                  </tr>
                                </>
                              ))
                            }
                          </FieldArray>
                        </tbody>
                        <tbody>
                          <FieldArray name="Standing">
                            {({ fields }) =>
                              fields.map((item) => (
                                <>
                                  <tr className="boder-form-pt-b">
                                    <td className="px-2">Standing</td>
                                    <td className="px-2 border-right-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-right-0 border-left-0"></td>
                                    <td className="px-2 border-left-0"></td>
                                  </tr>

                                  <tr className="boder-form-pt-b">
                                    <td>Static</td>

                                    {balanceArray.map((checkboxItem) => (
                                      <td className="align-items-center px-0">
                                        <Field
                                          name={`${item}.Static`}
                                          component="input"
                                          type="checkbox"
                                          value={checkboxItem}
                                          placeholder="FREE TEXT"
                                        >
                                          {({ input, meta }) => (
                                            <div
                                              className={`d-flex justify-content-center w-100 h-100 ${
                                                meta.error && meta.touched
                                                  ? "border-table-danger"
                                                  : "border-0 "
                                              }`}
                                            >
                                              <input
                                                type="text"
                                                {...input}
                                                value={checkboxItem}
                                                disabled={disableByVisitStatus()}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </td>
                                    ))}
                                  </tr>
                                  <tr className="boder-form-pt-b">
                                    <td>Dynamic</td>

                                    {balanceArray.map((checkboxItem) => (
                                      <td className="align-items-center px-0">
                                        <Field
                                          name={`${item}.Dynamic`}
                                          component="input"
                                          type="checkbox"
                                          value={checkboxItem}
                                          placeholder="FREE TEXT"
                                        >
                                          {({ input, meta }) => (
                                            <div
                                              className={`d-flex justify-content-center w-100 h-100 ${
                                                meta.error && meta.touched
                                                  ? "border-table-danger"
                                                  : "border-0 "
                                              }`}
                                            >
                                              <input
                                                type="text"
                                                {...input}
                                                value={checkboxItem}
                                                disabled={disableByVisitStatus()}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </td>
                                    ))}
                                  </tr>
                                </>
                              ))
                            }
                          </FieldArray>
                        </tbody>
                      </table>

                      <div className="col-md-12 mt-3 mb-3">
                        <Field
                          name={"balance_additional"}
                          component="input"
                          type="text"
                          placeholder=""
                          className="input-textSize"
                        >
                          {({ input, meta }) => (
                            <div>
                              <label>
                                <span className="text-ordered-list mr-1">
                                  Additional :
                                </span>
                              </label>
                              {toSave ? (
                                <p>{values["balance_additional"]}</p>
                              ) : (
                                <textarea
                                  maxlength="320"
                                  className="Additional_text"
                                  {...input}
                                  type="text"
                                  placeholder=""
                                  disabled={disableByVisitStatus()}
                                />
                              )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`evaluation-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
                <div className="row mein-white-from-background mt-3 ">
                  <div className="col-md-2">
                    {" "}
                    <h5 style={{ textDecoration: "underline" }}>Gait :</h5>
                  </div>

                  <div className="col-md-4">
                    <div className="radio-of-forms  gap-3">
                      <Field
                        name="gait"
                        value={`ambulatory`}
                        component="input"
                        type="radio"
                        disabled={disableByVisitStatus()}
                      />
                      <label>Ambulatory</label>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="radio-of-forms  gap-3">
                      <Field
                        name="gait"
                        value={`nonAmbulatory1`}
                        component="input"
                        type="radio"
                        disabled={disableByVisitStatus()}
                      />
                      <label>Non-ambulatory</label>
                    </div>
                  </div>
                  {touched.gait && errors.gait && (
                    <p className=" alert alert-danger meta_err">
                      {errors.gait}
                    </p>
                  )}
                  <div className="col-md-2"> </div>

                  <FieldArray name="ambutaryGait">
                    {({ fields, meta }) =>
                      fields.map((item, fieldIndex) => (
                        <div className="row mt-4">
                          <div className="col-md-2">
                            <h6 className="mb-3">WB</h6>
                            {gaitWB.map((checkboxItem) => (
                              <div className="radio-of-forms gap-3">
                                <Field
                                  name={`${item}.wb`}
                                  component="input"
                                  type="checkbox"
                                  value={checkboxItem}
                                  disabled={disableByVisitStatus()}
                                />
                                {checkboxItem}
                              </div>
                            ))}

                            <Field name={`${item}.wbTextBox`}>
                              {({ input, meta }) => (
                                <>
                                  <input
                                    type="textarea"
                                    maxlength="10"
                                    {...input}
                                    className="pteval-input"
                                    disabled={disableByVisitStatus()}
                                  />
                                  {meta.error && meta.touched && (
                                    <p className=" alert alert-danger meta_err">
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="col-md-2">
                            <h6 className="mb-3">Assistive Devices</h6>
                            {gaitAssistive.map((checkboxItem) => (
                              <div className="radio-of-forms gap-3">
                                <Field
                                  name={`${item}.assistiveDevices`}
                                  component="input"
                                  type="checkbox"
                                  value={checkboxItem}
                                  disabled={disableByVisitStatus()}
                                />
                                {checkboxItem}
                              </div>
                            ))}
                            <Field name={`${item}.assistiveDevicesTextBox`}>
                              {({ input, meta }) => (
                                <>
                                  <input
                                    type="text"
                                    maxlength="10"
                                    {...input}
                                    className="pteval-input"
                                    disabled={disableByVisitStatus()}
                                  />
                                  {meta.error && meta.touched && (
                                    <p className=" alert alert-danger meta_err">
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="col-md-2">
                            <h6 className="mb-3">Assistance</h6>
                            {Assistance.map((checkboxItem) => (
                              <div className="radio-of-forms gap-3">
                                <Field
                                  name={`${item}.assistance`}
                                  component="input"
                                  type="checkbox"
                                  value={checkboxItem}
                                  disabled={disableByVisitStatus()}
                                />
                                {checkboxItem}
                              </div>
                            ))}
                            {errors.ambutaryGait &&
                              errors.ambutaryGait[fieldIndex]["assistance"] &&
                              touched[
                                `ambutaryGait[${fieldIndex}].assistance`
                              ] && (
                                <p className=" alert alert-danger meta_err">
                                  {
                                    errors.ambutaryGait[fieldIndex][
                                      "assistance"
                                    ]
                                  }
                                </p>
                              )}
                          </div>
                          <div className="col-md-2">
                            <h6 className="mb-3">Distance</h6>
                            <Field name={`${item}.levelSurfaces`}>
                              {({ input, meta }) => (
                                <div className="mb-3">
                                  <label>Level Surface</label>
                                  <input
                                    type="text"
                                    maxlength="10"
                                    {...input}
                                    className="form-control"
                                    disabled={disableByVisitStatus()}
                                  />

                                  {meta.error && meta.touched && (
                                    <p className=" alert alert-danger meta_err">
                                      {meta.error}
                                    </p>
                                  )}
                                </div>
                              )}
                            </Field>
                            <Field name={`${item}.unevenSurfaces`}>
                              {({ input, meta }) => (
                                <div className="mb-3">
                                  <label>Uneven Surfaces</label>
                                  <input
                                    type="text"
                                    {...input}
                                    maxlength="10"
                                    className="form-control"
                                    disabled={disableByVisitStatus()}
                                  />

                                  {meta.error && meta.touched && (
                                    <p>{meta.error}</p>
                                  )}
                                </div>
                              )}
                            </Field>
                          </div>
                          <div className="col-md-4">
                            <h6 className="mb-3"> Deviations</h6>
                            {Deviations.map((checkboxItem) => (
                              <div className="radio-of-forms gap-3">
                                <Field
                                  name={`${item}.deviations`}
                                  component="input"
                                  type="checkbox"
                                  value={checkboxItem}
                                  disabled={disableByVisitStatus()}
                                />
                                {checkboxItem}
                              </div>
                            ))}
                            <Field name={`${item}.deviationsTextBox`}>
                              {({ input, meta }) => (
                                <>
                                  <input
                                    type="text"
                                    {...input}
                                    maxlength="30"
                                    className="pteval-input"
                                    disabled={disableByVisitStatus()}
                                  />
                                  {meta.error && meta.touched && (
                                    <p className=" alert alert-danger meta_err">
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                        </div>
                      ))
                    }
                  </FieldArray>
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <>
                    <h5 className="py-4 text-decoration-underline">
                      Endurance :
                    </h5>

                    {array1?.map((checkboxItem, ij) => (
                      <div className="col-md-4 mt-3 ">
                        <div className="radio-of-forms gap-3 d-flex align-items-baseline">
                          <Field
                            name={`endurance`}
                            type="radio"
                            component="input"
                            // placeholder="FREE"
                            value={checkboxItem}
                            disabled={disableByVisitStatus()}
                          />
                          {/* {({ input, meta }) => (
                            <>
                              <input
                                type="radio"
                                value={checkboxItem}
                                {...input}
                                placeholder="FREE"
                              />
                            </>
                          )} */}
                          <p>{checkboxItem}</p>
                          {/* </Field> */}
                        </div>
                      </div>
                    ))}

                    {submitFailed && errors.endurance ? (
                      <p className="alert alert-danger " role="alert">
                        {errors.endurance}
                      </p>
                    ) : (
                      ""
                    )}
                  </>
                </div>
                <div className="row mein-white-from-background h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">Other :</h5>
                  <Field
                    name="Name1"
                    component="input"
                    type="text"
                    placeholder=""
                    className="input-textSize"
                    disabled={disableByVisitStatus()}
                  >
                    {({ input, meta }) => (
                      <div>
                        {toSave ? (
                          <p>{values["Name1"]}</p>
                        ) : (
                          <textarea
                            maxlength="320"
                            className="Additional_text"
                            {...input}
                            type="text"
                            disabled={disableByVisitStatus()}
                          />
                        )}

                        {meta.error && meta.touched && (
                          <p className="alert alert-danger " role="alert">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="row mein-white-from-background h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Treatment Provided This Visit :
                  </h5>
                  <Field
                    name="treatmentProvided"
                    component="input"
                    type="text"
                    className="input-textSize"
                    disabled={disableByVisitStatus()}
                  >
                    {({ input, meta }) => (
                      <div>
                        <label>
                          <span className="text-ordered-list mr-1"></span>
                        </label>
                        {toSave ? (
                          <p>{values["treatmentProvided"]}</p>
                        ) : (
                          <textarea
                            maxlength="320"
                            className="Additional_text"
                            {...input}
                            type="text"
                            disabled={disableByVisitStatus()}
                          />
                        )}

                        {meta.error && meta.touched && (
                          <p className="alert alert-danger " role="alert">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>

                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Homebound Eligibility :
                  </h5>
                  {toSave ? (
                    <div>
                      {values.HomeboundEligibility &&
                        values.HomeboundEligibility.map((item) => {
                          return <span>{`${item.label},`}</span>;
                        })}
                    </div>
                  ) : (
                    <Field name="HomeboundEligibility">
                      {({ input, meta }) => (
                        <div>
                          <CreatableSelect
                            {...input}
                            isMulti
                            options={reactSelectOptionCreate(
                              formOptions.HomeboundEligibility,
                              "name",
                              "name",
                              meta.initial
                            )}
                            isDisabled={disableByVisitStatus()}
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  )}
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Rehab Potential :
                  </h5>
                  {toSave ? (
                    <div>
                      {values.RehabPotential &&
                        values.RehabPotential.map((item) => {
                          return <span>{`${item.label},`}</span>;
                        })}
                    </div>
                  ) : (
                    <Field name="RehabPotential">
                      {({ input, meta }) => (
                        <div>
                          <CreatableSelect
                            {...input}
                            isMulti
                            options={reactSelectOptionCreate(
                              formOptions.RehabPotential,
                              "name",
                              "name",
                              meta.initial
                            )}
                            isDisabled={disableByVisitStatus()}
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  )}
                </div>
              </div>

              <div
                className={`evaluation-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  {/* <h5 className="py-4">Goals</h5> */}
                  <h5 className="py-4 text-decoration-underline">Goals :</h5>
                  <div
                    className={` ${
                      isAutoFill ? "col-md-3 mb-3  mt-3" : "col-md-4 mb-3  mt-3"
                    }`}
                  >
                    <h6>Goal :</h6>
                  </div>
                  <div
                    className={` ${
                      isAutoFill ? "col-md-3 mb-3  mt-3" : "col-md-4 mb-3  mt-3"
                    }`}
                  >
                    <h6>Start Effective Date :</h6>
                  </div>
                  <div
                    className={` ${
                      isAutoFill ? "col-md-3 mb-3  mt-3" : "col-md-4 mb-3  mt-3"
                    }`}
                  >
                    <h6>Current Status :</h6>
                  </div>
                  {isAutoFill && (
                    <div
                      className={` ${
                        isAutoFill
                          ? "col-md-3 mb-3  mt-3"
                          : "col-md-4 mb-3  mt-3"
                      }`}
                    >
                      <h6>Status from last Eval/RA</h6>
                    </div>
                  )}
                  <FieldArray name="goals">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <>
                          <div
                            className={` ${
                              isAutoFill ? "col-md-3 mb-3" : "col-md-4 mb-3"
                            }`}
                            key={`goals_${index + 1}`}
                          >
                            <Field name={`${name}.goal`}>
                              {({ input, meta }) => (
                                <>
                                  <input
                                    {...input}
                                    maxlength="30"
                                    className="pteval-input"
                                    type="text"
                                    onChange={(e) => {
                                      if (!e.target.value)
                                        form.change(
                                          `goals[${index}].currentStatus`,
                                          ""
                                        );
                                      input.onChange(e);
                                    }}
                                    disabled={disableByVisitStatus()}
                                  />
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger w-100"
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                          <div
                            className={` ${
                              isAutoFill ? "col-md-3 mb-3" : "col-md-4 mb-3"
                            }`}
                            key={index}
                          >
                            <Field name={`${name}.effectiveDate`}>
                              {({ input, meta }) => (
                                <>
                                  <input
                                    {...input}
                                    className="pteval-input"
                                    type="date"
                                    min={effectiveDate()}
                                    // disabled={!values.goals[index].goal}
                                    disabled={
                                      !values.goals[index].goal ||
                                      disableByVisitStatus()
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger w-100"
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                          <div
                            className={` ${
                              isAutoFill ? "col-md-3 mb-3" : "col-md-4 mb-3"
                            }`}
                            key={index}
                          >
                            <Field name={`${name}.currentStatus`}>
                              {({ input, meta }) => (
                                <>
                                  <input
                                    maxlength="30"
                                    {...input}
                                    className="pteval-input"
                                    type="text"
                                    // disabled={!values.goals[index].goal}

                                    disabled={
                                      !values.goals[index].goal ||
                                      disableByVisitStatus()
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger w-100"
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                          {isAutoFill && (
                            <div
                              className={` ${
                                isAutoFill ? "col-md-3 mb-3" : "col-md-4 mb-3"
                              }`}
                              key={index}
                            >
                              <Field name={`${name}.reAssementStatus`}>
                                {({ input, meta }) => (
                                  <>
                                    <input
                                      maxlength="30"
                                      {...input}
                                      className="pteval-input"
                                      type="text"
                                      // disabled={!values.goals[index].goal}

                                      disabled={
                                        !values.goals[index].goal ||
                                        disableByVisitStatus()
                                      }
                                    />
                                    {meta.error && meta.touched && (
                                      <p
                                        className="alert alert-danger w-100"
                                        role="alert"
                                      >
                                        {meta.error}
                                      </p>
                                    )}
                                  </>
                                )}
                              </Field>
                            </div>
                          )}
                        </>
                      ))
                    }
                  </FieldArray>
                  {submitFailed && error && (
                    <p className="alert alert-danger " role="alert">
                      Enter at least 3 goals
                    </p>
                  )}
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Interventions :
                  </h5>
                  <div className="col-md-12 mb-3 mt-3">
                    {toSave ? (
                      <div>
                        {values.Interventions &&
                          values.Interventions.map((item) => {
                            return <span>{`${item.label},`}</span>;
                          })}
                      </div>
                    ) : (
                      <Field name="Interventions">
                        {({ input, meta }) => (
                          <div>
                            <CreatableSelect
                              {...input}
                              isMulti
                              options={reactSelectOptionCreate(
                                formOptions.interventations_PT,
                                "name",
                                "name",
                                meta.initial
                              )}
                              isDisabled={disableByVisitStatus()}
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    )}
                  </div>
                </div>
                <div className="row mein-white-from-background h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Frequency :
                  </h5>

                  <div
                    className="col-md-4 mb-3"
                    id="FrequencySelect"
                    style={{
                      display: "block",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className="d-flex">
                      <div className="col-md-3">
                        <label
                          className="fw-bold"
                          style={{ textWrap: "nowrap" }}
                        >
                          Total Visits :{" "}
                        </label>
                      </div>
                      <div className="col-md-9">
                        <Field name="Frequency1">
                          {({ input, meta }) => (
                            <>
                              <select
                                {...input}
                                disabled={disableByVisitStatus()}
                                className="pteval-input"
                                onChange={(e) => {
                                  input.onChange(e);
                                  form.change("Frequency2", "");
                                  getFrequency(e, visitDetail.episodeId);
                                }}
                              >
                                <option value="">
                                  Please choose one option
                                </option>
                                {[
                                  ...Array(
                                    Number(
                                      leftVisit ? leftVisit : totalVisitNumber
                                    )
                                  ),
                                ].map((option, index) => {
                                  return (
                                    <option key={index}>{index + 1}</option>
                                  );
                                })}
                              </select>
                              {/* <input
                          {...input}
                          className="pteval-input"
                          type="text"
                          maxLength="2"
                          onChange={(e) => {
                            input.onChange(e);
                            form.change("Frequency2", "");
                            getFrequency(e, visitDetail.episodeId);
                          }}
                          disabled={disableByVisitStatus()}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder=""
                        /> */}

                              {meta.error && meta.touched && (
                                <p
                                  className="alert alert-danger  "
                                  role="alert"
                                >
                                  {meta.error}
                                </p>
                              )}
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-4 mb-3"
                    id="FrequencyInput"
                    style={{ display: "none", gap: "10px" }}
                  >
                    <label className="fw-bold" style={{ textWrap: "nowrap" }}>
                      Total Visits :{" "}
                    </label>
                    <Field name="Frequency1">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            className="pteval-input"
                            placeholder=" free text"
                            isDisabled={disableByVisitStatus()}
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </>
                      )}
                    </Field>
                  </div>

                  <div
                    className="col-md-4 mb-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <label className="fw-bold" style={{ textWrap: "nowrap" }}>
                      Frequency :
                    </label>
                    {toSave ? (
                      <input
                        className="pteval-input"
                        type="text"
                        value={values.Frequency2 && values.Frequency2}
                        placeholder=""
                      />
                    ) : (
                      <Field name="Frequency2">
                        {({ input, meta }) => (
                          <div>
                            <select
                              {...input}
                              disabled={disableByVisitStatus()}
                              onClick={() => {
                                if (values.Frequency1)
                                  dispatch(
                                    getFrequencySuggestion({
                                      episodeId: visitDetail.episodeId,
                                      userId: staffDetails.id,
                                      visit: Number(values.Frequency1),
                                    })
                                  );
                              }}
                              className="pteval-input"
                            >
                              <option value="">Visit Frequency</option>
                              {frequencySuggestion &&
                              frequencySuggestion.initialEvaluationFreq &&
                              frequencySuggestion.initialEvaluationFreq.length >
                                0 &&
                              frequencySuggestion.initialEvaluationFreq.find(
                                (item) => item.frequency === meta.initial
                              )
                                ? null
                                : initialValues.Frequency1 ===
                                    values.Frequency1 && (
                                    <option value={meta.initial}>
                                      {meta.initial}
                                    </option>
                                  )}
                              {values.Frequency1 &&
                                frequencySuggestion &&
                                frequencySuggestion.initialEvaluationFreq &&
                                frequencySuggestion.initialEvaluationFreq
                                  .length > 0 &&
                                frequencySuggestion.initialEvaluationFreq.map(
                                  (freq, index) => (
                                    <option
                                      key={`frequency-${freq.frequency}`}
                                      value={freq.frequency}
                                    >
                                      {freq.frequency}
                                    </option>
                                  )
                                )}
                            </select>
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    <label className="fw-bold w-100 mt-4">
                      Justification :
                    </label>
                    {toSave ? (
                      <p>{values["Justification"]}</p>
                    ) : (
                      <Field name="Justification">
                        {({ input, meta }) => (
                          <>
                            <textarea
                              maxlength="320"
                              {...input}
                              className="Additional_text"
                              type="text"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </>
                        )}
                      </Field>
                    )}
                  </div>
                </div>
              </div>

              <div
                className={`evaluation-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    DME Requests :
                  </h5>

                  {toSave ? (
                    <div>
                      {values.DMERequests &&
                        values.DMERequests.map((item) => {
                          return <span>{`${item.label},`}</span>;
                        })}
                    </div>
                  ) : (
                    <Field name="DMERequests">
                      {({ input, meta }) => (
                        <div>
                          <CreatableSelect
                            {...input}
                            isMulti
                            options={reactSelectOptionCreate(
                              formOptions.dmeRequests,
                              "name",
                              "name",
                              meta.initial
                            )}
                            isDisabled={disableByVisitStatus()}
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  )}
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Discharge Planning :
                  </h5>
                  {toSave ? (
                    <div>
                      {values.PlanningRequests &&
                        values.PlanningRequests.map((item) => {
                          return <span>{`${item.label},`}</span>;
                        })}
                    </div>
                  ) : (
                    <Field name="PlanningRequests">
                      {({ input, meta }) => (
                        <div>
                          <CreatableSelect
                            {...input}
                            isMulti
                            //  isOptionDisabled={(option) => option.disabled}
                            options={reactSelectOptionCreate(
                              formOptions.DischargePlanning,
                              "name",
                              "name",
                              meta.initial
                            )}
                            isDisabled={disableByVisitStatus()}
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  )}
                </div>

                <div className="row mein-white-from-background justify-content-between ">
                  <div className="col-md-12">
                    <div style={{ marginTop: "30px" }}>
                      <Field
                        name="Patientsignature"
                        className="form-control mb-3 w-25 "
                        component="input"
                        placeholder="Patient signature"
                        disabled={disableByVisitStatus()}
                      />
                    </div>
                    <div>
                      <div className="">
                        <div className="d-flex gap-2 align-items-center my-3">
                          <span> Therapist Signature: </span>
                          {signatureAdded || values.Therapistsignature ? (
                            <img
                              src={
                                displaySignature
                                  ? displaySignature
                                  : values.Therapistsignature
                              }
                              height={"100px"}
                              width={"300px"}
                              crossOrigin="anonymous"
                            />
                          ) : (
                            <input
                              type="text"
                              placeholder="Therapist Signature"
                              disabled
                              className=" pl-0 border-0 border-right-2 border-left-2 border-bottom-2"
                            />
                          )}
                        </div>
                        {!toSave &&
                          currentUser &&
                          currentUser.Role &&
                          currentUser.Role.key &&
                          (currentUser.Role.key ===
                            currentRole.supervisingtherapist ||
                            currentUser.Role.key ===
                              currentRole.assistanttherapist) && (
                            <button
                              className="Approve-btns ml-0"
                              onClick={(e) => {
                                e.preventDefault();
                                setIsCanvasShow(true);
                              }}
                              disabled={
                                visitDetailById &&
                                !canSubmitForm.includes(getFormStatus())
                              }
                            >
                              {signatureAdded || values.Therapistsignature
                                ? "Update Signature"
                                : "Add Signature"}{" "}
                            </button>
                          )}
                      </div>
                      {submitFailed &&
                        !values.Therapistsignature &&
                        !displaySignature && (
                          <p className="alert alert-danger my-2 " role="alert">
                            Signature is required.
                          </p>
                        )}
                    </div>
                  </div>

                  <div className="col-md-12 mt-4">
                    <p className="my-2">
                      Physician Name :
                      <span className="ml-5">
                        {staffDetails &&
                        staffDetails.staffingDetails &&
                        staffDetails.staffingDetails.physicianName
                          ? staffDetails.staffingDetails.physicianName
                          : "--"}
                      </span>
                    </p>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="d-flex align-items-start">
                          <label className="no-wrap-white-space mb-0">
                            Physician Signature :
                          </label>
                          <Field
                            name="Physiciansignature"
                            className="Additional_text ml-3 "
                            component="textarea"
                            placeholder=""
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="d-flex align-items-start mb-0">
                          <label className="mb-0 text-nowrap form-date-width">
                            Date :
                          </label>
                          <Field
                            name="date"
                            className="Additional_text ml-3 "
                            component="textarea"
                            placeholder=""
                            disabled={true}
                          />
                          {/* <Field
                          name="date"
                          className="Additional_text ml-3 "
                          component="textarea"
                          disabled={disableByVisitStatus()}
                        >
                          {({ input, meta }) => (
                            <div>
                              <input {...input} type="date" readOnly />
                              {meta.error && meta.touched && (
                                <p className="alert alert-danger mb-3 ">
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          )}
                        </Field> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {currentUser &&
              currentUser.Role &&
              currentUser.Role.key &&
              (currentUser.Role.key === currentRole.supervisingtherapist ||
                currentUser.Role.key === currentRole.assistanttherapist) && (
                <div className="container">
                  <div className="py-5 ">
                    <button
                      className="Approve-btns h-auto"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!valid) handleSubmit();
                        if (valid) setShow(true);
                        else
                          toast.error("Please complete all required fields.");
                      }}
                      disabled={
                        visitDetailById &&
                        !canSubmitForm.includes(getFormStatus())
                      }
                    >
                      Sign & Submit
                    </button>
                    <button
                      className="Approve-btns  mx-2 h-auto"
                      onClick={(e) => {
                        e.preventDefault();
                        onSubmit(values);
                      }}
                      disabled={
                        visitDetailById &&
                        !canSubmitForm.includes(getFormStatus())
                      }
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            {show && (
              <Modal
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                centered
              >
                <Modal.Header className="pl-4 pr-4 pt-4 pb-3" closeButton>
                  <Modal.Title>Complete</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pl-4 pr-4 pt-0 pb-4">
                  By confirming, you are agreeing to electronically sign the
                  document and mark it as completed. Are you sure?
                  <div className="mt-4">
                    <Button
                      className="Discard-danger"
                      variant="outline-danger"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      className="primary-confirm"
                      onClick={(e) => {
                        e.preventDefault();
                        if (valid)
                          onSubmit(values, form, visitStatus.needreview);
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </form>
        )}
      />

      {isCanvasShow && (
        <SignatureCanvas
          loadDoc={saveForm}
          isShow={isCanvasShow}
          setSignatureAdded={setSignatureAdded}
          handleClose={() => setIsCanvasShow(false)}
        />
      )}
    </>
  );
}

export default EvaluationForm;
