import { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Modal from "react-bootstrap/Modal";
import { CgSearch } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonTable from "../../components/common/CommonTable";
import InfiniteScroll from "react-infinite-scroll-component";
import userImage from "../../images/usertable03.svg";
import { infiniteScrollGetCardsByRoleStatus } from "../../Redux/actions/auth";
import { currentRole, defaultStatus, encodedData } from "../../utils/auth";
import { debounce } from "lodash";
import Loader from "../../components/Loader";
import { resetUserList } from "../../Redux/slices/adminSlice";
import { apiBasePath } from "../../Redux/config/config";

export const DailyTherapist = ({ handleClose, show, count }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((e) => e.adminSlice.isLoading);
  const [hasMore, sethasMore] = useState(true);
  const [pageNo, setPageNo] = useState(2);
  const [pageSize] = useState(10);
  const [searchValue, setsearchValue] = useState("");

  const infiniteScrollCardList = useSelector(
    (e) => e.adminSlice.infiniteScrollCardList
  );
  const infiniteScrollCardsCount = useSelector(
    (e) => e.adminSlice.infiniteScrollCardsCount
  );

  let roles = [currentRole.supervisingtherapist];
  let status = [defaultStatus.approved];

  const setUserData = async () => {
    dispatch(
      infiniteScrollGetCardsByRoleStatus({
        pageSize: pageSize,
        isOnline: true,
        pageNo: pageNo,
        agencyName: searchValue && searchValue,
        roles,
        status,
      })
    );
    if (infiniteScrollCardsCount === infiniteScrollCardList.length) {
      sethasMore(false);
    }
    setPageNo((page) => page + 1);
  };

  useEffect(() => {
    dispatch(
      infiniteScrollGetCardsByRoleStatus({
        pageSize: pageSize,
        isOnline: true,
        pageNo: 1,
        roles,
        status,
      })
    );
    return () => {
      dispatch(resetUserList());
    };
  }, []);

  const debounceCall = debounce((ele) => {
    setsearchValue(ele);
    dispatch(
      infiniteScrollGetCardsByRoleStatus({
        pageSize: pageSize,
        pageNo: 1,
        isOnline: true,
        search: ele,
        roles,
        status,
      })
    );
    setPageNo(1);
  }, 400);

  const columns = ["firstName", "userRates"];
  const columnsToHide = ["image"];

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "firstName":
        return "NAME";
      case "userRates":
        return "DISCIPLINE";
      case "email":
        return "Email";

      default:
        return colName;
    }
  };

  const actions = [
    {
      action: (rowData) => (
        <td className="">
          <button
            className="view-btn tr-btn ml-auto  mt-3 mb-3 "
            onClick={() => {
              navigate(`/newusers/therapist/${encodedData({ id: rowData.id, onbordingPartCompleted: rowData.userDetail.onbordingPartCompleted })}`);
            }}
          >
            View
          </button>
        </td>
      ),
    },
  ];

  const renderRowData = (result, col, item) => {
    if (col === "firstName") {
      return (
        <>
          {" "}
          <p className="f-color">
            <img
              className="table-img"
              src={
                result && result.image
                  ? `${apiBasePath}documents/therapist/${result?.image}`
                  : userImage
              }
              crossOrigin="anonymous"
              style={{ borderRadius: "50%", objectFit: "cover" }}
              alt="img"
            />
            {result["lastName"]}
            {", "}
            {result["firstName"]}
          </p>
        </>
      );
    }
    if (col === "userRates") {
      return (
        <>
          <p className="f-color">
            {result && result["userRates"].length > 0
              ? result["userRates"][0]["rateTab"]["name"]
              : "---"}
          </p>
        </>
      );
    }

    return <p className="f-color2 mt-3 mb-3">{result[item]}</p>;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header
          className="closeButton border-0"
          closeButton
        ></Modal.Header>
        <div className="col-md-9 modal_title align-items-center mb-4 d-flex justify-content-between pl-5 pr-1 gap-3 zindex">
          <h5 className="Daily-therapist-h5">
            {" "}
            Daily Active Therapist {count}
          </h5>
          <div className="search-bar d-flex" style={{ width: "44%" }}>
            <CgSearch size={"25px"} />
            <input
              placeholder="Search by therapist name"
              className="pl-2 input-width"
              onChange={(ele) => debounceCall(ele.target.value)}
              style={{ border: "none", fontSize: "18px", outline: "none" }}
            />
          </div>
        </div>

        <div
          id="scrollableDiv"
          style={{
            height: 700,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isLoading && (
            <div className="loader-1" style={{ height: "700" }}>
              <Loader />
            </div>
          )}
          <InfiniteScroll
            dataLength={infiniteScrollCardList && infiniteScrollCardList.length}
            next={setUserData}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            <Col lg={11} className="m-auto pb-1">
              <div className="table-responsive">
                <CommonTable
                  data={infiniteScrollCardList && infiniteScrollCardList}
                  columns={columns}
                  columnsToHide={columnsToHide}
                  overrideColumnName={overrideColumnName}
                  actions={actions}
                  noData={"No Data Available"}
                  renderRowData={renderRowData}
                  isLoading={isLoading}
                />
              </div>
            </Col>
          </InfiniteScroll>
        </div>
      </Modal>
    </>
  );
};
