import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance, withoutAuth } from "../config/axiosInstance";

export const signInCall = createAsyncThunk("sginInCall", async (payload) => {
  const result = await withoutAuth.post("auth/login", payload);
  return result.data;
});

export const createAdmin = createAsyncThunk("createAdmin", async (data) => {
  const data1 = await axiosInstance.post("auth/create/admin", data);
  return data1.data;
});

export const getAdminUsersList = createAsyncThunk(
  "getAdminUsersList ",
  async (data) => {
    const data1 = await axiosInstance.post("/auth/admin-users", data);
    return data1.data;
  }
);

export const checkDuplicateEmail = createAsyncThunk(
  "checkDuplicateEmail ",
  async (data) => {
    const data1 = await axiosInstance.post("auth/check-dublicate-email", data);
    return data1.data;
  }
);
export const updateAdminDetails = createAsyncThunk(
  "updateAdminDetails",
  async (data) => {
    const data1 = await axiosInstance.post("auth/userUpdate", data);
    return data1.data;
  }
);

export const getUserDetailsById = createAsyncThunk(
  "getUserDetailsById/auth/user-detail",
  async (id) => {
    const data1 = await axiosInstance.get(`auth/user-detail/${id}`);
    return data1.data;
  }
);

export const getCurrentActiveUser = createAsyncThunk(
  "getCurrentActiveUser/auth/user-detail",
  async (id) => {
    const data1 = await axiosInstance.get(`auth/user-detail/${id}`);

    return data1.data;
  }
);

export const setTherapistDataGetStep = createAsyncThunk(
  "setTherapistDataGetStep",
  async (step) => {
    return step;
  }
);

export const getCurrentAdminInfo = createAsyncThunk(
  "getActiveAdminDetails",
  async (id) => {
    const data1 = await axiosInstance.get(`auth/user-detail/${id}`);

    return data1.data;
  }
);

export const getUsersByRoleStatus = createAsyncThunk(
  "getUsersByRoleStatus/userby-role-status",
  async (payload) => {
    const data1 = await axiosInstance.post(`auth/userby-role-status`, payload);
    return data1.data;
  }
);

export const getCardsByRoleStatus = createAsyncThunk(
  "getCardsByRoleStatus/auth/userby-role-status",
  async (payload) => {
    const data1 = await axiosInstance.post(`auth/userby-role-status`, payload);
    return data1.data;
  }
);

export const infiniteScrollGetCardsByRoleStatus = createAsyncThunk(
  "getCardsByRoleStatus/auth/userby-role-status",
  async (payload) => {
    const data1 = await axiosInstance.post(`auth/userby-role-status`, payload);
    return { data: data1.data, pageNo: payload.pageNo };
  }
);

export const forgetPassword = createAsyncThunk(
  "forgetPassword",
  async (payload) => {
    const result = await axiosInstance.post("auth/forgot-password", payload);
    return result.data;
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (payload) => {
    const result = await axiosInstance.post("/auth/update-password", payload);
    return result.data;
  }
);

export const updateProfileStatus = createAsyncThunk(
  "updateProfileStatus",
  async (data) => {
    const data1 = await axiosInstance.post("/auth/update-profile-status", data);
    return data1.data;
  }
);

export const getStatusList = createAsyncThunk(
  "updateProfileStatus/auth/getStatusList",
  async (data) => {
    const data1 = await axiosInstance.post("/auth/getStatusList", data);
    return data1.data;
  }
);

export const getUserDropDownData = createAsyncThunk(
  "updateProfileStatus/auth/getUserForDropdown",
  async (data) => {
    const data1 = await axiosInstance.post("/auth/getUserForDropdown", data);
    return data1.data;
  }
);

// staffing agency drop down list action
export const getUserDropDownAgencyData = createAsyncThunk(
  "getUserDropDownAgencyData/auth/getAllAgencyByAdmin",
  async (data) => {
    const data1 = await axiosInstance.get("auth/getAllAgencyByAdmin", data);
    return data1.data;
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async (payload) => {
    const result = await axiosInstance.post("/auth/change-password", payload);
    return result.data;
  }
);

export const getAdminHomeTabCount = createAsyncThunk(
  "admin/getAdminHomeTabCount",
  async () => {
    const data1 = await axiosInstance.get(`/auth/getAdminHomeTabeCount`);
    return data1.data;
  }
);

export const getContactSupportMail = createAsyncThunk(
  "getContactSupportMail//user/contact-enquiry",
  async (payload) => {
    const result = await axiosInstance.post("/user/contact-enquiry", payload);
    return result.data;
  }
);

export const getBackgroundCheck = createAsyncThunk(
  "Background-Check/ByStatus",
  async (payload) => {
    const result = await axiosInstance.post(
      "/auth/threrapistFind-Background",
      payload
    );
    return result.data;
  }
);

export const getOtpVerified = createAsyncThunk(
  "getOtpVerified//auth/otpVerify",
  async (data) => {
    const result = await axiosInstance.post("/auth/otpVerify", data);
    return result.data;
  }
);

export const TwoFactorVerify = createAsyncThunk(
  "TwoFactorVerify//auth/twoFactorAuthentication",
  async (data) => {
    const result = await axiosInstance.post(
      "/auth/twoFactorAuthentication",
      data
    );
    return result.data;
  }
);
export const getResendOtp = createAsyncThunk(
  "getResendOtp/auth/resendOtp",
  async (data) => {
    const result = await axiosInstance.post("/auth/resendOtp", data);
    return result.data;
  }
);

export const updateConversationAttributes = createAsyncThunk(
  "getResendOtp/auth/update-conversation-attributes",
  async (data) => {
    const result = await axiosInstance.post(
      "/twilio/update-conversation-attributes",
      data
    );
    return result.data;
  }
);
