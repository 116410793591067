import React, { useEffect, useState } from "react";
import { Row, Col } from "bootstrap-4-react";
import { useNavigate, useParams } from "react-router-dom";
import CommonTable from "./common/CommonTable";
import Patient from "../images/usertable02.svg";
import { useDispatch, useSelector } from "react-redux";
import { currentRole, defaultStatus, encodedData } from "../utils/auth";
import { CommonHead2 } from "./common/CommonHead2";
import { setLoader } from "../Redux/slices/authSlice";
import { apiBasePath } from "../Redux/config/config";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { debounce } from "lodash";
import {
  getCaseManagementStatus,
  getCaseManagementTabCount,
} from "../Redux/actions/common";
import ReactPaginate from "react-paginate";
import {
  caseManagementTabs,
  statusBaseColorChange,
  viewCaseStatueName,
  visitStatus,
} from "../utils/helper";

const AuthorizationAgency = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const caseListData = useSelector((e) => e.commonSlice.caseList);

  let myTabStatus = {
    awaitingsocroc: defaultStatus.awaitingsocroc,
    patientneedscheduling: defaultStatus.patientneedscheduling,
    newfrequencies: defaultStatus.newfrequencies,
    pendingDMEs: defaultStatus.pendingDMEs,
    authorization: defaultStatus.authorization,
    authorization: defaultStatus.authorization,
    pendingauthorization: defaultStatus.authorization,
    authorizedauthorization: defaultStatus.authorization,
    lastvisittypeapproval: defaultStatus.lastvisittypeapproval,
  };
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const caseCount = useSelector((e) => e.commonSlice.casemgmttabcount);

  const isLoading = useSelector((e) => e.commonSlice.isLoading);
  useEffect(() => {
    if (params.tab && caseManagementTabs[params.tab]) {
      setMenuItem(params?.tab);
    } else if (params.tab && !caseManagementTabs[params.tab]) {
      navigate("/casemanagement");
    } else {
      dispatch(setLoader({ loading: true }));
    }
    dispatch(
      getCaseManagementStatus({
        pageSize,
        pageNo: 1,
        tab: myTabStatus[params.tab ? params.tab : menu[0].key],
      })
    );
    dispatch(getCaseManagementTabCount());
  }, [currentUser]);

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "firstName":
        return "Patient";
      case "staffingDetails.address":
        return "Address";
      case "disciplines":
        return "Disciplines";
      case "name":
        return "Agency Name";
      case "staffingDetails.disciplinesNeeded":
        return "DISCIPLINES";
      case "staffingDetails.createdByUser.name":
        return "Agency Name";
      case "createdByUser.name":
        return "Agency Name";
      case "AuthorizedApproved":
        return "Authorized";
      default:
        return colName;
    }
  };

  const handlePageClick = (event) => {
    dispatch(setLoader({ loading: true }));
    dispatch(
      getCaseManagementStatus({
        pageSize,
        pageNo: event.selected + 1,
        tab: myTabStatus[menuItem],
      })
    );
    setPageNo(event.selected + 1);
  };

  const renderRowData = (result, col, item) => {
    if (col === "PATIENT") {
      if (
        currentUser?.Role?.key === currentRole?.supervisingtherapist ||
        currentUser?.Role?.key === currentRole?.admin ||
        currentUser?.Role?.key === currentRole?.superadmin
      ) {
        if (menuItem == "authorization") {
          return (
            <>
              <div className="d-flex align-items-center">
                <img
                  src={
                    result.image
                      ? `${apiBasePath}documents/therapist/${result.image}`
                      : Patient
                  }
                  crossOrigin="anonymous"
                  className="table-img mr-0"
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                  alt="img"
                />
                <div>
                  <h1 className="title-22 text-nowrap margin-left-35 caseColor">
                    {result && `${result.lastName}, ${result.firstName}`}
                  </h1>
                </div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="d-flex align-items-center">
                <img
                  src={
                    result.image
                      ? `${apiBasePath}documents/therapist/${result.image}`
                      : Patient
                  }
                  crossOrigin="anonymous"
                  className="table-img  "
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                  alt="img"
                />
                <div>
                  <h1 className="title-22 m-0 caseColor">
                    {result && `${result.lastName}, ${result.firstName}`}
                  </h1>
                  {result?.staffingDetails?.mrn && (
                    <p className="title-24 m-0">
                      MR #{result?.staffingDetails?.mrn}
                    </p>
                  )}
                </div>
              </div>
            </>
          );
        }
      } else {
        return (
          <>
            <div className="d-flex align-items-center">
              <img
                src={
                  result.image
                    ? `${apiBasePath}documents/therapist/${result.image}`
                    : Patient
                }
                crossOrigin="anonymous"
                className="table-img  "
                style={{ borderRadius: "50%", objectFit: "cover" }}
                alt="img"
              />
              <div>
                <h1 className="title-22 m-0 caseColor">
                  {result["lastName"]}
                  {", "}
                  {result["firstName"]}
                </h1>
                {result?.staffingDetails?.mrn && (
                  <p className="title-24 m-0">
                    MR #{result?.staffingDetails?.mrn}
                  </p>
                )}
              </div>
            </div>
          </>
        );
      }
    }
    if (col === "MR#") {
      if (
        currentUser?.Role?.key === currentRole?.supervisingtherapist ||
        currentUser?.Role?.key === currentRole?.admin ||
        currentUser?.Role?.key === currentRole?.superadmin
      ) {
        if (menuItem === "authorization") {
          return (
            <>
              <p className="m-0 addres-col caseColor">
                {result?.staffUser?.staffingDetails?.mrn}
              </p>
            </>
          );
        } else {
          return (
            <>
              <p className="m-0 addres-col caseColor">
                {result?.staffUser?.staffingDetails?.mrn}
              </p>
            </>
          );
        }
      } else if (currentUser?.Role?.key === currentRole?.assistanttherapist) {
        return (
          <>
            <p className="m-0 addres-col caseColor">
              {result && result?.staffUser?.staffingDetails?.mrn}
            </p>
          </>
        );
      } else {
        return (
          <>
            <p className="m-0 addres-col caseColor">
              {result?.staffingDetails?.mrn}
            </p>
          </>
        );
      }
    }

    if (col === "ADDRESS") {
      if (
        currentUser?.Role?.key === currentRole?.supervisingtherapist ||
        currentUser?.Role?.key === currentRole?.admin ||
        currentUser?.Role?.key === currentRole?.superadmin
      ) {
        if (menuItem === "authorization") {
          return (
            <div>
              <p className="m-0 addres-col caseColor">
                {result?.staffingDetails?.address}
              </p>
              <p className="m-0 addres-col caseColor">
                {result?.staffingDetails?.city}
                {", "}
                {result?.staffingDetails?.state}
                {", "}
                {result?.staffingDetails?.zip}
              </p>
            </div>
          );
        } else {
          return (
            <div>
              <p className="m-0 addres-col caseColor">
                {result?.staffingDetails?.address}
              </p>
              <p className="m-0 addres-col caseColor">
                {result?.staffingDetails?.city}
                {", "}
                {result?.staffingDetails?.state}
                {", "}
                {result?.staffingDetails?.zip}
              </p>
            </div>
          );
        }
      } else if (currentUser?.Role?.key === currentRole?.assistanttherapist) {
        return (
          <div>
            <p className="m-0 addres-col caseColor">
              {result?.staffingDetails?.address}
            </p>
            <p className="m-0 addres-col caseColor">
              {result?.staffingDetails?.city}
              {", "}
              {result?.staffingDetails?.state}
              {", "}
              {result?.staffingDetails?.zip}
            </p>
          </div>
        );
      } else {
        return (
          <div>
            <p className="m-0 addres-col caseColor">
              {result?.staffingDetails?.address}
            </p>
            <p className="m-0 addres-col caseColor">
              {result?.staffingDetails?.city}
              {", "}
              {result?.staffingDetails?.state}
              {", "}
              {result?.staffingDetails?.zip}
            </p>
          </div>
        );
      }
    }
    if (col === "DISCIPLINES") {
      if (menuItem === "authorization") {
        return (
          <>
            {currentUser && currentUser.Role.key !== currentRole.agency ? (
              <div>
                {result?.staffingDetails?.disciplinesNeeded &&
                  Object.keys(result.staffingDetails.disciplinesNeeded).map(
                    (e) => {
                      return (
                        <div className="d-flex gap-2">
                          <p className="m-0 spanp caseColor">
                            {e.toUpperCase()}
                          </p>
                          <span className="spanp caseColor">
                            <BsFillCheckCircleFill className="progress-check-after" />
                          </span>
                        </div>
                      );
                    }
                  )}
              </div>
            ) : (
              <div className="d-flex gap-1">
                {result?.staffingDetails?.disciplinesNeeded &&
                  Object.keys(result.staffingDetails.disciplinesNeeded).map(
                    (e) => {
                      return (
                        <div className="d-flex gap-2">
                          <p className="m-0 spanp caseColor">
                            {e.toUpperCase()}
                          </p>
                          <span className="spanp caseColor">
                            <BsFillCheckCircleFill className="progress-check-after" />
                          </span>
                        </div>
                      );
                    }
                  )}
              </div>
            )}
          </>
        );
      } else {
        return (
          <>
            {currentUser && currentUser.Role.key !== currentRole.agency ? (
              <div className="d-flex gap-2">
                {result?.staffingDetails?.disciplinesNeeded &&
                  Object.keys(result.staffingDetails.disciplinesNeeded).map(
                    (e) => {
                      return (
                        <div className="d-flex gap-2">
                          <p className="m-0 spanp">{e.toUpperCase()}</p>
                          <span className="spanp">
                            <BsFillCheckCircleFill className="progress-check-after" />
                          </span>
                        </div>
                      );
                    }
                  )}
              </div>
            ) : (
              <div className="d-flex gap-1">
                {result?.staffingDetails?.disciplinesNeeded &&
                  Object.keys(result.staffingDetails.disciplinesNeeded).map(
                    (e) => {
                      return (
                        <div className="d-flex gap-2">
                          <p className="m-0 spanp">{e.toUpperCase()}</p>
                          <span className="spanp">
                            <BsFillCheckCircleFill className="progress-check-after" />
                          </span>
                        </div>
                      );
                    }
                  )}
              </div>
            )}
          </>
        );
      }
    }

    if (col === "CASE STATUS") {
      if (
        currentUser?.Role?.key === currentRole?.supervisingtherapist ||
        currentUser?.Role?.key === currentRole?.admin ||
        currentUser?.Role?.key === currentRole?.superadmin
      ) {
        return (
          <>
            {result?.staffInitialVisit[0]?.Status ? (
              <p
                className={`m-0 mt-3 mb-3 status-td ${statusBaseColorChange(
                  result &&
                    result.staffInitialVisit &&
                    result.staffInitialVisit.length > 0 &&
                    result.staffInitialVisit[0].Status &&
                    result.staffInitialVisit[0].Status.key
                )}`}
              >
                {viewCaseStatueName(
                  result &&
                    result.staffInitialVisit &&
                    result.staffInitialVisit.length > 0 &&
                    result.staffInitialVisit[0].Status &&
                    result.staffInitialVisit[0].Status.key
                )}
              </p>
            ) : (
              "N/A"
            )}
          </>
        );
      } else if (currentUser?.Role?.key === currentRole?.agency) {
        return (
          <>
            {result?.staffInitialVisit[0]?.Status ? (
              <p
                className={`m-0 mt-3 mb-3 status-td ${statusBaseColorChange(
                  result &&
                    result.staffInitialVisit &&
                    result.staffInitialVisit.length > 0 &&
                    result.staffInitialVisit[0].Status &&
                    result.staffInitialVisit[0].Status.key
                )}`}
              >
                {viewCaseStatueName(
                  result &&
                    result.staffInitialVisit &&
                    result.staffInitialVisit.length > 0 &&
                    result.staffInitialVisit[0].Status &&
                    result.staffInitialVisit[0].Status.key
                )}
              </p>
            ) : (
              "N/A"
            )}
          </>
        );
      }
    }

    if (col === "Authorized" || col === "Pending") {
      let value = 0;
      if (
        result &&
        result.staffEpisodes &&
        result.staffEpisodes.length > 0 &&
        result.staffEpisodes[0].episodeDocumentations &&
        result.staffEpisodes[0].episodeDocumentations.length > 0
      ) {
        let total = result.staffEpisodes[0].episodeDocumentations.length;
        let pending = result.staffEpisodes[0].episodeDocumentations.filter(
          (item) => item.Status && item.Status.key === visitStatus.awaitingauth
        ).length;
        value = pending;
        if (col === "Authorized") {
          value = total - pending;
        }
      }

      return <p className={`m-0 mt-3 mb-3`}>{value}</p>;
    }

    if (col === "AuthorizedApproved") {
      let value = 0;
      if (
        result &&
        result.staffAuthorization &&
        result.staffAuthorization.length > 0
      ) {
        let total = result.staffAuthorization[0].visit;

        value = total;
      }

      return <p className={`m-0 mt-3 mb-3`}>{value}</p>;
    }

    return <h1 className=" title-24  mt-3 mb-3">{result[item]}</h1>;
  };

  const setTabMenus = (tab1) => {
    document.getElementById("searchField").value = "";
    dispatch(setLoader({ loading: true }));
    setMenuItem(tab1);
    setPageNo(1);
    dispatch(
      getCaseManagementStatus({
        pageSize,
        pageNo: 1,
        tab: myTabStatus[tab1],
      })
    );
    dispatch(getCaseManagementTabCount());
  };
  const menu = [
    {
      label: "Pending",
      key: "pendingauthorization",
      data: caseListData.pendingData,
      count: caseCount && caseCount?.pendingAuthorization,
      columns: [
        "PATIENT",
        "ADDRESS",
        "DISCIPLINES",
        "Authorized",
        "Pending",
        "CASE STATUS",
      ],
      columnsToHide: ["image"],
      modalClass: "Main_modal_1",
      for: [currentRole.supervisingtherapist, currentRole.assistanttherapist],
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <button
                  className=" update-btn justify-content-center ml-auto  mt-3 mb-3"
                  onClick={() => {
                    handleClick(rowData);
                  }}
                >
                  View
                </button>
              </td>
            </>
          ),
        },
      ],
    },
    {
      label: "Authorized",
      key: "authorizedauthorization",
      data: caseListData.approvedData,
      count: caseCount && caseCount?.approvedauthorization,
      columns: [
        "PATIENT",
        "ADDRESS",
        "DISCIPLINES",
        "AuthorizedApproved",
        "CASE STATUS",
      ],
      columnsToHide: ["image"],
      modalClass: "Main_modal_1",
      for: [currentRole.supervisingtherapist, currentRole.assistanttherapist],
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <button
                  className=" update-btn justify-content-center ml-auto  mt-3 mb-3"
                  onClick={() => {
                    handleClick(rowData);
                  }}
                >
                  View
                </button>
              </td>
            </>
          ),
        },
      ],
    },
  ];
  const [menuItem, setMenuItem] = useState(menu[0]["key"]);
  const myActions = menu.find((ele) => ele.key === menuItem);

  function handleClick(rowData) {
    if (rowData?.staffUserId) {
      navigate(`/patientdetails/${encodedData(rowData?.staffUserId)}/episodes`);
    } else if (rowData?.userId) {
      navigate(`/patientdetails/${encodedData(rowData?.userId)}/episodes`);
    } else if (rowData?.id) {
      navigate(`/patientdetails/${encodedData(rowData?.id)}/episodes`);
    }
  }
  const debounceCall = debounce((ele) => {
    setPageNo(1);
    dispatch(
      getCaseManagementStatus({
        pageSize,
        pageNo: 1,
        tab: myTabStatus[menuItem],
        search: ele,
      })
    );
  }, 700);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="user-type-main tabs_user_type">
            <div className="col-sm-12 pr-5 pl-5">
              <CommonHead2
                data={menu}
                setMenu={setTabMenus}
                menuItem={menuItem}
                css={"col1 "}
                // countClass={"casemanage"}
                // this class is for the new design
              />
            </div>
          </div>
          <div className="container-fluid ">
            <Row>
              <div className="inside_table_tabs justify-content-start table_left_padding">
                <Col lg={11}>
                  <div className="table-responsive mt-4">
                    {myActions &&
                    myActions.data &&
                    myActions.data.length > 0 ? (
                      <CommonTable
                        data={myActions?.data}
                        columns={myActions?.columns}
                        columnsToHide={myActions?.columnsToHide}
                        overrideColumnName={overrideColumnName}
                        actions={myActions?.actions}
                        renderRowData={renderRowData}
                      />
                    ) : (
                      <div className="d-flex align-items-center justify-content-center mt-5">
                        {!isLoading && (
                          <h2 className="fs-5 mt-5">No Data available</h2>
                        )}
                      </div>
                    )}
                  </div>
                </Col>
              </div>
            </Row>
            {myActions?.count > pageSize && myActions?.data?.length > 0 && (
              <div id="container ">
                <div className="row justify-content-start mt-3  pl-5 ">
                  <div className=" pl-5 pr-5">
                    <ReactPaginate
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      nextLabel="next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={Math.ceil(myActions?.count / pageSize)}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                      forcePage={
                        Math.ceil(myActions?.count / pageSize) === 0
                          ? -1
                          : pageNo - 1
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorizationAgency;
