import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { getUserByRejectToken } from "../../Redux/actions/therapist";
import { currentRole, decodeData } from "../../utils/auth";

function Rejected() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((e) => e.therapistSigunpSlice.isLoading);
  useEffect(() => {
    if (params?.id) {
      dispatch(getUserByRejectToken(params?.id)).then((res) => {
        if (res && res?.payload?.data) {
          if (
            res?.payload?.data?.existingUser?.Role?.key ===
            currentRole.agency &&
            res?.payload?.data?.token
          ) {
            navigate(`/agencysignup/?auth=${res?.payload?.data?.token}`);
          } else if (
            res?.payload?.data?.existingUser?.Role?.key ===
            currentRole.supervisingtherapist &&
            res?.payload?.data?.token
          ) {
            navigate(
              `/supervisingtherapist/?auth=${res?.payload?.data?.token}`
            );
          } else if (
            res?.payload?.data?.existingUser?.Role?.key ===
            currentRole.assistanttherapist &&
            res?.payload?.data?.token
          ) {
            navigate(`/assistanttherapist/?auth=${res?.payload?.data?.token}`);
          }
        } else {
          navigate(`/UserUnderReview`);
        }
      });
    }
  }, [dispatch, params]);

  return <Container>{isLoading && <Loader />}</Container>;
}

export default Rejected;
