import React from "react";
import { updateProfileStatus } from "../../Redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { defaultStatus } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

const BackgroundCheck = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileDetail = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );

  const isLoading = useSelector((e) => e.therapistSigunpSlice.isLoading);
  const backgroundReport = useSelector((state) => state?.therapistSigunpSlice?.backgroundReport)

  function handleSubmit() {
    dispatch(
      updateProfileStatus({
        status: defaultStatus.approved,
        userId: profileDetail && profileDetail.id,
        onbordingPartCompleted: 3,
        pendingSteps: "completed",
      })
    ).then((response) => {
      toast.success("Therapist has been approved.");
      setTimeout(() => {
        navigate("/");
      }, 500);
    });
  }
  return (
    <>
      {isLoading && <Loader />}
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className=" col-md-8 top-bar-backhground">
            <h4 className="text-center cs-heading set-head-th px-0 m-0 font-32 p-0">
              Background Check
            </h4>
          </div>
          <p className="text-center background-text">
            Read and accept our Terms & Conditions
          </p>
        </div>
        <div className="">

          <ul className="progressbar item_center p-0 mt-4 mb-5">
            <li className="active">Therapist Consent</li>
            <li className={(backgroundReport?.status === "P" || backgroundReport?.status === "C") ? "active" : ""}>Background check {backgroundReport?.status === "C" ? "Completed" : "Initiated"}</li>
            {/* <li>Completed</li> */}
            <li>{backgroundReport?.status === "C" ? "Approved" : "To be reviewed"} by Admin</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default BackgroundCheck;
