import React, { useEffect, useState } from "react";
import ActivityLog from "./ChatScreenComponents/ActivityLog";
import CommNotes from "./ChatScreenComponents/CommNotes";
import arrowImg from "../../../images/arrow-right.svg";
import crossIcon from "../../../images/x.svg";
import crossIconBlack from "../../../images/x-black.svg";
import { IoMdArrowDropdownCircle } from "react-icons/io";
import { Col, InputGroup, Row } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import {
  messageTypesForAgency,
  messageTypesForTherapist,
} from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { currentRole } from "../../../utils/auth";
import { ImDownload3 } from "react-icons/im";
import { toast } from "react-toastify";
import {
  getStaffingDetailsById,
  urgentMessageUpdate,
} from "../../../Redux/actions/staffing";
import {
  getStaffAssignments,
  getStaffDocumentation,
} from "../../../Redux/actions/common";
import { updateConversationAttributes } from "../../../Redux/actions/auth";

export default function ChatScreen(props) {
  const {
    dataValue,
    downloadChat,
    setDownloadChat,
    addItemToDownload,
    selectedMessages,
    setSelectedMessages,
    handleClose,
    saveToPdf,
  } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectIcons, setSelectIcons] = useState(false);
  const activeConversation = useSelector(
    (state) => state.chatSlice.activeConversation
  );
  const currentUserDetail = useSelector(
    (state) => state.adminSlice.currentActiveUserDetails
  );
  const staffassignments = useSelector((e) => e.commonSlice.staffassignments);
  const staffDocument = useSelector((e) => e.commonSlice.staffDocument);
  const currentUserRole = currentUserDetail.Role;
  const dispatch = useDispatch();
  const setActive = (index) => {
    const activeDiv = document.getElementById(`ActiveMessage_${index}`);
    if (activeDiv)
      activeDiv.scrollIntoView({
        block: "center",
        behavior: "instant",
        inline: "nearest",
      });
  };

  const isDisable = () => {
    let disable = false;
    if (currentUserRole.key === currentRole.admin) disable = true;
    return disable;
  };

  const sendEmail = async () => {
    dispatch(getStaffingDetailsById(activeConversation?.attributes?.id)).then(
      (res) => {
        if (currentUserDetail.Role.key === "supervisingtherapist") {
          dispatch(
            urgentMessageUpdate({
              userId: staffDocument[0].episodeDocumentations[0].agencyId,
              staffUserId: res?.payload?.data?.staffUser?.staffUserId,
              email: res?.payload?.data?.createdByUser?.email,
              mrn: activeConversation?.attributes?.mrn,
              name: res?.payload?.data?.createdByUser?.name,
            })
          );
        }
      }
    );
    if (currentUserDetail.Role.key === "agency") {
      staffassignments.map((singlestaffassignments) => {
        dispatch(
          urgentMessageUpdate({
            userId: singlestaffassignments.therapistId,
            staffUserId: singlestaffassignments.staffUserId,
            email: singlestaffassignments.therapist.email,
            mrn: activeConversation?.attributes?.mrn,
            name:
              singlestaffassignments.therapist.firstName +
              " " +
              singlestaffassignments.therapist.lastName,
          })
        );
      });
    }
  };
  useEffect(() => {
    dispatch(
      getStaffAssignments({
        staffUserId: activeConversation?.attributes?.id,
      })
    );
    dispatch(
      getStaffDocumentation({ staffUserId: activeConversation?.attributes?.id })
    );
  }, []);

  const sendMessage = (values, form) => {
    if (!downloadChat && !isDisable()) {
      const AuthorDetail = {
        name: currentUserDetail.name
          ? currentUserDetail.name
          : currentUserDetail.lastName + ", " + currentUserDetail.firstName,
        image: currentUserDetail.image,
        discipline: currentUserDetail.userDetail.discipline,
        id: currentUserDetail.id,
        Role: currentUserDetail.Role,
      };
      if (values.message.trim() === "") {
        toast.warn("Enter something to send.");
      } else if (!values.subjectType) {
        toast.warn("Please select subject type");
      } else {
        form.change("message", "");
        activeConversation
          .prepareMessage()
          .setBody(values.message)
          .setAttributes({ subjectType: values.subjectType, ...AuthorDetail })
          .build()
          .send()
          .then((messageIndex) => {
            activeConversation.advanceLastReadMessageIndex(messageIndex);
            activeConversation.updateLastReadMessageIndex(messageIndex);
            setActiveIndex(messageIndex);

            dispatch(
              updateConversationAttributes({
                sid: activeConversation.sid,
                userId: activeConversation.attributes.id,
              })
            );
          });
        form.change("subjectType", "");
      }
    }
    if (values.subjectType !== "other" && values.message.trim() !== "") {
      sendEmail();
    }
  };

  const IconRotate = () => {
    setSelectIcons(!selectIcons);
  };

  const CancelSave = () => {
    setDownloadChat(false);
    setSelectedMessages([]);
  };
  useEffect(() => {
    if (activeIndex) setActive(activeIndex);
  }, [activeIndex]);
  return (
    <>
      {dataValue === 0 && (
        <CommNotes
          downloadChat={downloadChat}
          setDownloadChat={setDownloadChat}
          addItemToDownload={addItemToDownload}
          selectedMessages={selectedMessages}
          setSelectedMessages={setSelectedMessages}
          handleClose={handleClose}
          setActiveIndex={setActiveIndex}
        />
      )}
      {dataValue === 1 && <ActivityLog handleClose={handleClose} />}

      <Form
        onSubmit={sendMessage}
        initialValues={{ message: "" }}
        render={({ handleSubmit, values, pristine, form }) => (
          <form onSubmit={handleSubmit}>
            <div className="chat__header_padding">
              <Row className="footer align-items-center pb-3 ">
                <Col md={11}>
                  {!downloadChat ? (
                    dataValue === 0 &&
                    !isDisable() && (
                      <InputGroup className="p-0 chat__header_padding p-0">
                        <Field name={`subjectType`} type="select" className="">
                          {({ input, meta }) => (
                            <div
                              className={`select-container-box ${
                                selectIcons === true ? "addActive" : ""
                              } `}
                              addActive
                              onClick={() => IconRotate()}
                            >
                              <select
                                style={{
                                  outline: "0",
                                  borderRadius: "10px 0px 0px 10px",
                                  padding: "17px 16px",
                                  paddingRight: "54px",
                                  maxWidth: "266px",
                                  height: "53px",
                                  color: "#fff",
                                  cursor: "pointer",
                                }}
                                className="for-btnc text-white"
                                {...input}
                              >
                                <option className="subject_option" value="">
                                  Select Subject{" "}
                                  <IoMdArrowDropdownCircle
                                    color="#fff"
                                    size={20}
                                  />
                                </option>
                                {currentUserRole.key === "agency" &&
                                  messageTypesForAgency &&
                                  messageTypesForAgency.map(
                                    (typeItem, typeIndex) => (
                                      <option
                                        className="subject_option"
                                        key={`Type${typeIndex}`}
                                        value={`${typeItem.value}`}
                                      >{`${typeItem.label}`}</option>
                                    )
                                  )}
                                {currentUserRole.key ===
                                  "supervisingtherapist" &&
                                  messageTypesForTherapist &&
                                  messageTypesForTherapist.map(
                                    (typeItem, typeIndex) => (
                                      <option
                                        className="subject_option"
                                        key={`Type${typeIndex}`}
                                        value={`${typeItem.value}`}
                                      >{`${typeItem.label}`}</option>
                                    )
                                  )}
                              </select>
                            </div>
                          )}
                        </Field>
                        <Field name={`message`}>
                          {({ input, meta }) => (
                            <textarea
                              {...input}
                              style={{
                                width: "68%",
                                resize: "none",
                                height: "53px",
                              }}
                              className=" sac  text-area-scroll position-relative"
                              placeholder="Type a note here"
                              rows={1}
                              disabled={isDisable()}
                              onKeyDown={(e) => {
                                if (e.key === "Enter" && !e.shiftKey) {
                                  e.preventDefault();
                                  sendMessage(
                                    { ...values, message: input.value },
                                    form
                                  );
                                }
                              }}
                            />
                          )}
                        </Field>
                        <button
                          type="submit"
                          style={{ cursor: "pointer", border: 0 }}
                          id="arrow2"
                          disabled={isDisable()}
                        >
                          <img src={arrowImg} alt="" />
                        </button>
                      </InputGroup>
                    )
                  ) : (
                    <InputGroup className="align-items-center">
                      <input
                        name="totalMessages"
                        type="text"
                        className=" chat-box-download rounded-3"
                        value={`${selectedMessages.length} Messages Selected`}
                        disabled={isDisable()}
                      />
                      <div className="d-flex gap-2 ml-3">
                        <button
                          className="cancel-download-btn"
                          onClick={() => CancelSave()}
                        >
                          <span>
                            {" "}
                            <img src={crossIconBlack} /> Cancel{" "}
                          </span>
                        </button>
                        <button
                          className="download-btn"
                          onClick={() => saveToPdf()}
                        >
                          <span>
                            {" "}
                            <ImDownload3 size={20} /> Download{" "}
                          </span>
                        </button>
                      </div>
                    </InputGroup>
                  )}
                </Col>
                <Col md={1}>
                  <div id="times2" onClick={() => handleClose()}>
                    <img src={crossIcon} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
          </form>
        )}
      />
    </>
  );
}
