import React from "react";
import { Form, Field } from "react-final-form";
import { useSelector } from "react-redux";

const VisitConfirmationModal = (props) => {
  const { visitDetail, updateConfirmDate, priorVisitDate } = props;
  const staffDetails = useSelector((state) => state.staffingSlice.staffDetail);

  const validateForm = (values) => {
    let name =
      visitDetail &&
      visitDetail.episodeVisit &&
      visitDetail.episodeVisit.displayName
        ? visitDetail.episodeVisit.displayName
        : "Evaluation";
    let error = {};
    if (!values.evaluationVisitDate)
      error.evaluationVisitDate = name + " visit date is required.";
    return error;
  };

  const handleSubmit = (values) => {
    updateConfirmDate(
      {
        evaluationVisitDate: values.evaluationVisitDate,
        mrnNumber: staffDetails.staffingDetails.mrn,
        agencyEmailId: staffDetails.createdByUser.email,
        name: staffDetails.createdByUser.name,
        staffUserId: staffDetails.id,
        staffVisitType:
          staffDetails.staffInitialVisit[0].initialVisitType.displayName,
      },
      "datemodal"
    );
  };
  const dateFormat = (data) => {
    const today = new Date();
    const givendate = new Date(data);
    if (today < givendate) {
      return givendate.toISOString().split("T")[0];
    } else if (today > givendate) {
      return today.toISOString().split("T")[0];
    }

    return today;
  };

  function getFormattedDate() {
    if (!visitDetail || !visitDetail.date) {
      return;
    }
    let date = new Date(visitDetail?.date);
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;
    return year + "-" + month + "-" + day;
  }

  const myInitialValues = {
    evaluationVisitDate: getFormattedDate(),
  };
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={myInitialValues}
        validate={(e) => validateForm(e)}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="AddAuthorisationBody">
              <h1 className="Heading">Please Confirm Date of Visit</h1>
              <div>
                <div className="mb-3 d-flex flex-column">
                  <Field name="evaluationVisitDate">
                    {({ input, meta }) => (
                      <div>
                        <label>
                          {" "}
                          {visitDetail &&
                          visitDetail.episodeVisit &&
                          visitDetail.episodeVisit.displayName
                            ? visitDetail.episodeVisit.displayName
                            : "Evaluation"}{" "}
                          Visit Date{" "}
                        </label>
                        <input
                          {...input}
                          type="date"
                          className="AuthorizationsTabs-inputs w-100"
                          min={priorVisitDate(visitDetail.id)}
                        />
                        {meta.error && meta.touched && (
                          <p className="alert alert-danger " role="alert">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="common-btns-div">
                  <button
                    type="button"
                    className="cancel-btns"
                    onClick={() => props.modalClose()}
                  >
                    Discard
                  </button>
                  <button type="submit" className="Approve-btns">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default VisitConfirmationModal;
