import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { setDocuSignEnvelope } from "../../../Redux/actions/therapist";
import { currentRole, docuSignDocs, isUserLogined } from "../../../utils/auth";

const DocuSignCompetency = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (searchParams && searchParams.get("email") && searchParams.get("env")) {
      dispatch(
        setDocuSignEnvelope({
          email: searchParams.get("email"),
          envelopeId: searchParams.get("env"),
          types: docuSignDocs.competency,
        })
      ).then((response) => {
        setLoading(false);
        if (
          response?.payload?.data.user.Role.key ===
          currentRole.supervisingtherapist
        ) {
          if (isUserLogined() && response?.payload?.data?.token) {
            navigate(`/myprofile/5`);
          } else {
            navigate(
              `/supervisingtherapist/?auth=${response?.payload?.data?.token}`
            );
          }
        } else if (
          response?.payload?.data.user.Role.key ===
          currentRole.assistanttherapist
        ) {
          if (isUserLogined() && response?.payload?.data?.token) {
            navigate(`/myprofile/5`);
          } else {
            navigate(
              `/assistanttherapist/?auth=${response?.payload?.data?.token}`
            );
          }
        } else {
          navigate(`/`);
        }
      });
    }
  }, []);

  return <div> {loading && <Loader />}</div>;
};

export default DocuSignCompetency;
