import React from "react";
import Modal from "react-bootstrap/Modal";
import { Field, Form } from "react-final-form";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";

const AgencyAcceptModal = (props) => {
  const isModalLoading = useSelector((e) => e.staffingSlice.isModalLoading);
  const handleSubmit = (value) => {
    props.acceptTerm();
  };

  return (
    <Modal
      className={props.modalClass}
      show={props.show}
      onHide={() => props.handleClose()}
      aria-labelledby="contained-modal-title-vcenter"
    >
      {isModalLoading && <Loader />}
      <div className="d-flex justify-content-between">
        <h1 className="th-sttafhead mb-0">Assign the Therapist</h1>
        <Modal.Header
          className="closeButton border-0"
          closeButton
        ></Modal.Header>
      </div>

      <Form
        validate={(values) => {
          let error = {};
          if (!values.termsandconditions) {
            error["termsandconditions"] =
              "You must accept the terms and conditions.";
          }
          return error;
        }}
        onSubmit={handleSubmit}
        initialValues={{
          isWillComplete: "yes",
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="mt-3 mb-5">
            <h2>Contract Of Service</h2>
            {/* <div className="access_agreement_main"> */}
            <p className="text-justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
              <br /> Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            {/* </div> */}

            <div className=" d-flex align-item-center gap-2 mb-5">
              <Field
                type="checkbox"
                name="termsandconditions"
                className="form-select select-box-1"
              >
                {({ input, meta }) => (
                  <div className="d-flex flex-column">
                    <div className=" d-flex align-items-center gap-3 mb-3">
                      <input type="checkbox" {...input} className="tcch" />

                      <label className="tclb m-0">
                        I agree to Terms and Conditions
                      </label>
                    </div>

                    {meta.touched && meta.error && (
                      <span className="alert alert-danger">{meta.error}</span>
                    )}
                  </div>
                )}
              </Field>
            </div>

            <div style={{ display: "flex" }}>
              <button className="th-sttafing-btn ">Submit</button>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

export default AgencyAcceptModal;
