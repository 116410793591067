/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PatientChart from "../../../components/PatientChart";
import { getAgencyPatientChart } from "../../../Redux/actions/common";

const Chart = () => {
  let tableColumns = [
    "firstName",
    "staffingDetails.address",
    "staffingDetails.disciplinesNeeded",
    "staffingDetails.caseStatus.name",
  ];
  return (
    <PatientChart getList={getAgencyPatientChart} tableColumns={tableColumns} />
  );
};

export default Chart;
