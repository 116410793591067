import React from "react";

const CommonTable = (props) => {
  const { formValues } = props;
  const addTableRow = (result, itemIndex) => {
    let row = [];
    props.columns.forEach((col) => {
      if (!props.columnsToHide.includes(col)) {
        row.push(
          props.columns.map((item, index) => {
            if (
              (result[item] && item === col) ||
              (typeof result[item] === "object" &&
                result[item] &&
                item === props.objectKey &&
                Object.keys(result[item]).includes(col))
            ) {
              return (
                <React.Fragment key={`${item}_${index}`}>
                  {props.renderRowData(
                    result,
                    col,
                    item,
                    itemIndex,
                    formValues
                  )}
                </React.Fragment>
              );
            } else if (item === col) {
              return (
                <React.Fragment key={`${item}_${index}`}>
                  {props.renderRowData(
                    result,
                    col,
                    item,
                    itemIndex,
                    formValues
                  )}
                </React.Fragment>
              );
            }
          })
        );
        row = filterDeepUndefinedValues(row);
      }
    });
    return row.map((item, index) => {
      return (
        <>
          <td
            key={`${item}_${index}`}
            className=" whitespace-nowrap text-sm text-gray-500 text-left ml-3 pl-2"
          >
            <div className="staff_addres">{item}</div>
          </td>
        </>
      );
    });
  };

  const filterDeepUndefinedValues = (arr) => {
    return arr
      .map((val) =>
        val.map((deepVal) => deepVal).filter((deeperVal) => deeperVal)
      )
      .map((val) => {
        if (val.length < 1) {
          val = ["-"];
          return val;
        }
        return val;
      });
  };

  const mapTableColumns = () => {
    return props.columns.map((col, index) => {
      if (!props.columnsToHide.includes(col)) {
        const overridedColumnName = props.overrideColumnName(col);
        return (
          <th
            scope="col"
            className="th-child text-leftfont-medium"
            key={`action_${col}_${index}`}
          >
            <div className={`th-${index}`}>{overridedColumnName}</div>
          </th>
        );
      }
    });
  };

  const createTable = (results) => {
    return (
      <table className={props.css ? `${props.css} table` : "table"}>
        <thead>
          <tr>{mapTableColumns()}</tr>
        </thead>
        <tbody>
          {results.map((result, index) => {
            return (
              <tr className="border-bottom" key={`row_${index}`}>
                {addTableRow(result, index)}
                {props.actions &&
                  props.actions.map((item, ind) => (
                    <React.Fragment key={`action_${index}_${ind}`}>
                      {item.action(result)}
                    </React.Fragment>
                  ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="overflow-auto">
      {props.component && props.component}

      {props.data && props.data.length > 0 ? (
        <div className={props.css ? `${props.css}` : ""}>
          {createTable(props.data)}
        </div>
      ) : (
        !props.isLoading && (
          <div className="d-flex align-items-center justify-content-center mt-5">
            <h2 className="fs-5 mt-5"> {props.noData}</h2>
          </div>
        )
      )}
    </div>
  );
};

export default CommonTable;
