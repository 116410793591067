import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import abc from "../../images/user.png";
import { MyModal2 } from "../modals/MyModal2";
import PdfModal from "./PdfModal";
import { HiDocumentText } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import {
  getPatientDocs,
  getPatientDocsDetails,
  getStaffFormdataById,
  updateDocumentStatus,
} from "../../Redux/actions/patientGetDocTypes";
import {
  arrayOrder,
  dateFormatChange,
  displayVisitStatus,
  formSortingArr,
  staffFormStatus,
  statusBaseColorChange,
  visitStatus,
} from "../../utils/helper";
import Loader from "../../components/Loader";
import {
  getStaffDocumentation,
  updateDocumenationStatus,
} from "../../Redux/actions/common";
import { currentRole, decodeData, defaultStatus } from "../../utils/auth";
import { useParams } from "react-router-dom";
import ConfirmDocument from "../modals/ConfirmDocument";
import { toast } from "react-toastify";
import { apiBasePath } from "../../Redux/config/config";
import { getStaffingDetailsById } from "../../Redux/actions/staffing";
import PrintModal from "../modals/PrintModal";

function EpisodeModal(props) {
  const {
    visitDetail,
    episodeModalClose,
    updateConfirmDate,
    priorVisitDate,
    statusHandler,
    staffUserId,
    setToUpdate,
  } = props;
  const dispatch = useDispatch();
  const params = useParams();

  const [formId, setFormId] = useState();
  const [formModal, setFormModal] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [formToShow, setFormToShow] = useState(null);
  const [confirmDate, setConfirmDate] = useState("");
  const [printModal, setPrintModal] = useState(false);
  const [formDetails, setFormDetails] = useState(null);
  const [dataAvailable, setDataAvailable] = useState(true);
  const [allCompleteModal, setAllCompleteModal] = useState();
  const closeAllCompleteModal = () => setAllCompleteModal(false);

  const fetchingForms = useSelector((data) => data?.commonSlice?.isLoading);
  const staffDetails = useSelector((state) => state.staffingSlice.staffDetail);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const fetchingDocuments = useSelector(
    (data) => data?.patientGetDocTypesSlice?.fetchingDocuments
  );
  const visitDetailById = useSelector(
    (data) => data?.patientGetDocTypesSlice?.visitDetail
  );

  const handleAllCompleteModal = () => {
    if (
      visitDetailById &&
      visitDetailById.patientFormList &&
      visitDetailById.patientFormList.length > 0
    ) {
      let isPendingDoc = visitDetailById.patientFormList.find(
        (doc) => doc.Status.key === visitStatus.incompletedoc
      );
      if (isPendingDoc) {
        toast.info("You have pending doc.");
      } else {
        setAllCompleteModal(true);
      }
    }
  };

  const closeFormModal = () => {
    setFormModal(false);
  };

  const handleForm = (formId, formName, formObject) => {
    if (
      currentUser &&
      currentUser.Role &&
      currentUser.Role.key &&
      (currentUser.Role.key === currentRole.admin ||
        currentUser.Role.key === currentRole.superadmin) &&
      formObject.Status.key === visitStatus.tobescheduled
    ) {
      return;
    } else if (
      currentUser &&
      currentUser.Role &&
      currentUser.Role.key &&
      currentUser.Role.key === currentRole.agency &&
      formObject.Status.key === visitStatus.incompletedoc
    ) {
      return;
    }

    if (
      currentUser &&
      currentUser.Role &&
      currentUser.Role.key &&
      (currentUser.Role.key === currentRole.agency ||
        currentUser.Role.key === currentRole.admin ||
        currentUser.Role.key === currentRole.superadmin) &&
      formObject.Status.key !== visitStatus.incompletedoc
    ) {
      dispatch(
        getStaffFormdataById({ episodeDocumentFormId: Number(formId) })
      ).then((res) => !res.payload.success && setDataAvailable(false));
      setFormToShow(formName);
      setFormDetails(formObject);
      setFormId(formId);
      setFormModal(true);
    } else {
      dispatch(
        getStaffFormdataById({ episodeDocumentFormId: Number(formId) })
      ).then((res) => !res.payload.success && setDataAvailable(false));
      setFormToShow(formName);
      setFormDetails(formObject);
      setFormId(formId);
      setFormModal(true);
    }
  };

  const classNameByStatus = (status) => {
    switch (status) {
      case staffFormStatus.pending:
        return "need_review";
      case staffFormStatus.needReview:
        return "Sent_to_HHA";
      case staffFormStatus.completed:
        return "Completed_status";
      default:
        return "need_review";
    }
  };
  useEffect(() => {
    dispatch(getPatientDocs({ episodeDocumentId: visitDetail?.id })).then(
      (res) => {
        if (res.payload.success) {
          setConfirmDate(res.payload.data.date.split("T")[0]);
          dispatch(
            getStaffDocumentation({
              staffUserId: staffUserId
                ? Number(staffUserId)
                : decodeData(params.id),
            })
          );
        }
      }
    );
  }, [dispatch, isUpdated, visitDetail]);

  const changeStatus = (status, isconfirm) => {
    let completeAll = false;
    if (
      currentUser &&
      currentUser.Role &&
      currentUser.Role.key &&
      (currentUser.Role.key === currentRole.agency ||
        currentUser.Role.key === currentRole.admin)
    ) {
      completeAll = true;
    }
    let temp = {};
    let price = 0;
    if (staffDetails) {
      price = staffDetails.userRates.find((item) =>
        item.rateTabDetail.displayName
          .toLowerCase()
          .includes(visitDetail.episodeVisit.displayName.toLowerCase())
      )?.price;
    }

    temp.id = visitDetail.id;
    temp.episodeId = Number(visitDetail.episodeId);
    temp.status = status;
    temp.staffUserId = staffUserId ? staffUserId : decodeData(params?.id);
    temp.episodeVisit = visitDetail.episodeVisit.name;
    if (status === defaultStatus.completed) temp.price = Number(price);
    dispatch(updateDocumenationStatus(temp)).then((e) => {
      if (e.payload.success) {
        if (completeAll && isconfirm)
          dispatch(
            updateDocumentStatus({
              isAllCompleted: true,
              episodeDocumentId: visitDetail.id,
            })
          );
        dispatch(
          getStaffDocumentation({
            staffUserId: staffUserId ? staffUserId : decodeData(params?.id),
          })
        );
        closeAllCompleteModal();
        episodeModalClose();
      }
    });
  };
  const saveConfirmDate = () => {
    if (confirmDate !== visitDetail.date.split("T")[0])
      updateConfirmDate({
        evaluationVisitDate: confirmDate,
        staffUserId: staffDetails.id,
        staffVisitType:
          staffDetails.staffInitialVisit[0].initialVisitType.displayName,
      });
  };

  const handlePrintModal = (formDetail) => {
    dispatch(getStaffingDetailsById(staffDetails.id));
    setFormToShow(formDetail.name);
    dispatch(getStaffDocumentation({ staffUserId: staffDetails.id }));
    dispatch(
      getPatientDocsDetails({
        episodeDocumentId: formDetail.episodeDocumentId,
      })
    ).then((res) => {
      if (res.payload.success) {
        toast.info("Document is downloading.");
        setPrintModal(true);
      }
    });
  };

  return (
    <>
      {fetchingForms && <Loader />}
      <div class="container-fluid">
        <div class="row justify-content-center  user-row-bar mb-5">
          <div class="col-lg-2 mb-3">
            <div className="patient-doc-img">
              <img
                className="userimge1 one"
                crossOrigin="anonymous"
                src={
                  staffDetails && staffDetails.image
                    ? `${apiBasePath}documents/therapist/${staffDetails.image}`
                    : abc
                }
                alt="img"
              />
            </div>
          </div>
          <div class="col-lg-6 mb-3">
            <div className="patient-details next-modal">
              <div className="patient-genreal">
                <h4 className="pt-3">
                  {staffDetails?.lastName + ", " + staffDetails?.firstName}
                </h4>
                <p>
                  MR #
                  {staffDetails &&
                    staffDetails.staffingDetails &&
                    staffDetails.staffingDetails.mrn}
                </p>
              </div>
              <div className="patient-report">
                <div className="patient-report-details">
                  <p>VISIT DATE</p>
                  <p>
                    {confirmDate
                      ? dateFormatChange(confirmDate)
                      : staffDetails &&
                        staffDetails.staffInitialVisit &&
                        staffDetails.staffInitialVisit.length > 0 &&
                        staffDetails.staffInitialVisit[0].anticipatedSocDate &&
                        dateFormatChange(
                          staffDetails.staffInitialVisit[0].anticipatedSocDate.split(
                            "T"
                          )[0]
                        )}
                  </p>
                </div>
                <div className="patient-report-details">
                  <p>VISIT TYPE</p>
                  <p>
                    {visitDetail &&
                      visitDetail.episodeVisit &&
                      visitDetail.episodeVisit.displayName}
                  </p>
                </div>
                <div className="patient-report-details">
                  <p>CONFIRMATION DATE </p>
                  <div>
                    <input
                      className="form-control ptinput"
                      type="date"
                      value={confirmDate}
                      onChange={(e) => {
                        setConfirmDate(e.target.value);
                      }}
                      min={priorVisitDate(visitDetail.id)}
                      disabled={
                        (currentUser &&
                          (currentUser?.Role?.key === currentRole.admin ||
                            currentUser?.Role?.key ===
                              currentRole.superadmin)) ||
                        (visitDetailById?.Status &&
                          !(
                            visitDetailById.Status.key ===
                              visitStatus.pendingnotes ||
                            visitDetailById.Status.key ===
                              visitStatus.tobescheduled ||
                            visitDetailById.Status.key === visitStatus.scheduled
                          ))
                      }
                    />
                    {visitDetailById &&
                      visitDetailById.date &&
                      confirmDate !== visitDetailById.date.split("T")[0] && (
                        <div className="profile_tabs-update my-3">
                          <button
                            className="AddStaff-save justify-content-center"
                            onClick={() => saveConfirmDate()}
                          >
                            Save
                          </button>
                        </div>
                      )}
                  </div>
                </div>
                <div className="patient-report-details">
                  {currentUser &&
                  currentUser.Role &&
                  currentUser.Role.key &&
                  currentRole.supervisingtherapist === currentUser.Role.key ? (
                    <>
                      <p>AGENCY NAME</p>
                      <p>
                        {visitDetailById &&
                          visitDetailById.createdByStafff &&
                          visitDetailById.createdByStafff?.name}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>THERAPIST NAME</p>
                      <p>
                        {visitDetailById &&
                          visitDetailById.userData &&
                          visitDetailById.userData.lastName &&
                          visitDetailById.userData.firstName &&
                          `${visitDetailById.userData.lastName}, ${
                            visitDetailById.userData.firstName
                          } (${visitDetailById.userData.userDetail.discipline.toUpperCase()})`}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mb-3">
            <div className="left_button">
              <div className="button_prent">
                <p>VISIT STATUS</p>
                <button
                  className={`d-flex justify-content-center pe-none status-td ${statusBaseColorChange(
                    visitDetailById &&
                      visitDetailById.Status &&
                      visitDetailById.Status.key
                  )}`}
                >
                  {visitDetailById &&
                  visitDetailById.Status &&
                  visitDetailById.Status.key &&
                  visitDetailById.Status.name
                    ? displayVisitStatus[visitDetailById.Status.key]
                      ? displayVisitStatus[visitDetailById.Status.key]
                      : visitDetailById.Status.name
                    : ""}{" "}
                </button>
              </div>
              {currentUser.Role.key === currentRole.agency &&
                visitDetailById &&
                visitDetailById.Status &&
                visitDetailById.Status.key !== visitStatus.completed && (
                  <div className="button_prent">
                    <p>ACTION</p>
                    <div className="d-flex gap-2">
                      <div>
                        <button
                          onClick={handleAllCompleteModal}
                          className="justify-content-center CompleteAll-color"
                          disabled={
                            currentUser?.Role?.key === currentRole?.admin ||
                            currentUser?.Role?.key === currentRole?.superadmin
                          }
                        >
                          Complete All
                        </button>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row bg-gray-main">
          <h6 className="Document_list text-start">Document List</h6>
          {fetchingDocuments ? (
            <Loader />
          ) : (
            <div className="Doc_list_table">
              <Table>
                <thead>
                  <tr>
                    <th className="bg-transparent">Name</th>
                    <th className="bg-transparent ms-1">Status</th>
                    <th className="bg-transparent"></th>
                  </tr>
                </thead>
                <tbody>
                  {visitDetailById.patientFormList &&
                    visitDetailById.patientFormList.length > 0 &&
                    arrayOrder(
                      JSON.parse(
                        JSON.stringify(visitDetailById.patientFormList)
                      ),
                      formSortingArr,
                      "name"
                    ).map((formItem, formIndex) => {
                      return (
                        <tr
                          key={`Form_${formItem.name}`}
                          className={` ${
                            currentUser &&
                            currentUser.Role &&
                            currentUser.Role.key &&
                            currentUser.Role.key === currentRole.agency &&
                            formItem.Status.key === visitStatus.incompletedoc &&
                            "pe-none"
                          }`}
                        >
                          <td
                            onClick={() =>
                              handleForm(formItem.id, formItem.name, formItem)
                            }
                          >
                            <HiDocumentText color="#00A5FF" size={16} />
                            {formItem.name}
                          </td>
                          <td>
                            <span
                              className={`${classNameByStatus(
                                formItem?.Status?.key
                              )} opacity-100`}
                            >
                              {formItem?.Status?.name}
                            </span>
                          </td>
                          {currentUser &&
                          currentUser.Role &&
                          currentUser.Role.key &&
                          currentUser.Role.key === currentRole.agency &&
                          formItem.Status.key === visitStatus.completed ? (
                            <td onClick={() => handlePrintModal(formItem)}>
                              {" "}
                              <button className=" update-btn view-btn tr-btn">
                                Print
                              </button>
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>

      <MyModal2
        dialogClassName="modal-lg"
        modalClass="Main_modal_1"
        show={formModal}
        handleClose={closeFormModal}
        data={
          <PdfModal
            confirmDate={confirmDate}
            formDetails={formDetails}
            setToUpdate={setToUpdate}
            staffUserId={staffUserId}
            formToShow={formToShow}
            dataAvailable={dataAvailable}
            closeFormModal={closeFormModal}
            staffDetailId={staffDetails?.id}
            formId={formId}
            visitDetail={visitDetail}
            IsUpdated={() => setIsUpdated(!isUpdated)}
            statusHandler={statusHandler}
            priorVisitDate={priorVisitDate}
            changeStatus={changeStatus}
            setDataAvailable={() => setDataAvailable(true)}
          />
        }
        centered
      />
      {allCompleteModal && (
        <ConfirmDocument
          allCompleteModal={allCompleteModal}
          closeAllCompleteModal={closeAllCompleteModal}
          changeStatus={changeStatus}
        />
      )}

      <PrintModal
        show={printModal}
        handleClose={() => setPrintModal(false)}
        visitDetail={visitDetail}
        // episodeModalClose={() => setShowEpisodeModal(false)}
        updateConfirmDate={updateConfirmDate}
        formToShow={formToShow}
        staffUserId={visitDetail.staffUserId}
        statusHandler={statusHandler}
        priorVisitDate={priorVisitDate}
      />
    </>
  );
}

export default EpisodeModal;
