import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { Container, Modal } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { currentRole, defaultStatus } from "../utils/auth";
import AgencyNavbar from "./Agency/signup/AgencyNavbar";
import loginNotReviewed from "../images/login/login-not-reviewed.png";

const UserUnderReview = ({ image }) => {
  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );

  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // Check if PrefillDetails.pendingSteps is "completed" and the modal is not already shown
    if (!show) {
      setShow(true);
    }
  }, [PrefillDetails, show]);
  const handleClose = () => {
    setShow(false);
    navigate("/login");
  };

  return (
    <>
      {/* {PrefillDetails && PrefillDetails.pendingSteps === "completed" && PrefillDetails.Status.key === defaultStatus.approved ? (
        <>
          <div className="d-flex align-items-center justify-content-center div-heights">
            <div className=" flex-colmun justify-content-center">
              <BsFillPatchCheckFill
                size={300}
                color={"green"}
                style={{ margin: "0px 30%" }}
              />
              <h4 className="mt-4">
                Your Sign up process is completed. Please
                <a href="/login" style={{ cursor: "pointer" }}>
                  &nbsp; click here &nbsp;
                </a>
                to Login to your account
              </h4>
            </div>
          </div>
        </>
      ) : ( */}
      <div>
        <AgencyNavbar />
        <div className="ca-body Reason_of_rejection">
          {/* <Modal show={show} onHide={handleClose} className="AgencyModal" centered> */}
          <div className="container-fluid col-11 p-0">
            <div className="commonImage">
              <div className=" col-md-4 pt-5 px-0 col-lg-3 col-xl-3 d-flex justify-content-start mt-5"></div>
              <Container className="AgencyModal review-container pt-4">
                <div className=" padding-m-1 ">
                  <h4 className="review-heading text-left">
                    Your account is under review.
                  </h4>
                  <img src={loginNotReviewed} />
                  <p className="review-bodytext" style={{ color: "#808d98" }}>
                    Thanks for waiting while we review your account. You’ll
                    receive an email notification once the review process has
                    been completed.
                  </p>
                </div>
              </Container>
              {/* </Modal> */}
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default UserUnderReview;
