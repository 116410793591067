import React, { useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { savePatientForm } from "../../../Redux/actions/staffing";
import {
  canSubmitForm,
  dateFormatChange,
  staffFormTypesConstants,
  totalVisitNumber,
  visitStatus,
} from "../../../utils/helper";
import { currentRole, decodeData } from "../../../utils/auth";
import {
  getFrequencySuggestion,
  getStaffDocumentation,
} from "../../../Redux/actions/common";
import { getEvalForm } from "../../../Redux/actions/patientGetDocTypes";
import { useParams } from "react-router-dom";
import { Button, Modal, Toast } from "react-bootstrap";
import { toast } from "react-toastify";
import SignatureCanvas from "../../../components/common/SignatureCanvas";
import {
  formOptions,
  reactSelectOptionCreate,
} from "../../../utils/common/options";
import CreatableSelect from "react-select/creatable";

export default function PhysicianOrder(props) {
  const {
    init,
    toSave,
    closeFormModal,
    visitDetail,
    isPrint,
    formId,
    IsUpdated,
    statusHandler,
    staffUserId,
    formDetails,
  } = props;
  const staffFormData = useSelector((e) => e.commonSlice.staffForm);
  const staffDetails = useSelector((state) => state.staffingSlice.staffDetail);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const frequencySuggestion = useSelector(
    (e) => e.commonSlice.frequencySuggestion
  );
  const leftVisit = useSelector((e) => e.commonSlice.leftVisit);
  const visitDetailById = useSelector(
    (data) => data?.patientGetDocTypesSlice?.visitDetail
  );
  const evaluationFormData = useSelector(
    (e) => e.commonSlice.evaluationFormData
  );
  const params = useParams();

  const [show, setShow] = useState();
  useEffect(() => {
    if (!staffFormData && !toSave) {
      dispatch(
        getStaffDocumentation({
          staffUserId: staffUserId
            ? Number(staffUserId)
            : decodeData(params.id),
        })
      ).then((res) => {
        if (
          res &&
          res.payload &&
          res.payload.getEpisodeDocument &&
          res.payload.getEpisodeDocument.rows &&
          res.payload.getEpisodeDocument.rows.length > 0
        ) {
          let documentObject =
            res.payload.getEpisodeDocument.rows[0].episodeDocumentations.find(
              (item) =>
                item.episodeVisit.displayName ===
                staffFormTypesConstants.Evaluation
            );
          dispatch(
            getEvalForm({
              episodeDocumentId: documentObject?.id,
              formTypeName: staffFormTypesConstants.Evaluation,
            })
          );
        }
      });
    }
  }, []);

  const [isCanvasShow, setIsCanvasShow] = useState(false);
  const [signatureAdded, setSignatureAdded] = useState(null);
  const [displaySignature, setDisplaySignature] = useState(null);
  const saveForm = (image) => {
    const decodedImage = atob(image.split(",")[1]);
    const blob = new Blob([decodedImage], { type: "image/jpeg" });
    const imageUrl = URL.createObjectURL(blob);
    setDisplaySignature(image);
    toast.success("Signature added.");
  };
  const dispatch = useDispatch();
  const todayDate = new Date().toISOString().split("T")[0];

  const initValues = () => {
    if (toSave && init) return { ...init };
    let initialValue = {};
    if (staffDetails) {
      initialValue.patientName =
        staffDetails.lastName + "," + staffDetails.firstName;
      initialValue.mrNumber =
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.mrn;
      initialValue.dob =
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.dateOfBirth.split("T")[0];
      initialValue.agencyName =
        staffDetails &&
        staffDetails.createdByUser &&
        staffDetails.createdByUser.name;
      initialValue.agencyPhone =
        staffDetails &&
        staffDetails.createdByUser &&
        staffDetails.createdByUser.userDetail &&
        staffDetails.createdByUser.userDetail.phone;
      initialValue.physicianName =
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.physicianName;
      initialValue.physicianPhoneNumber =
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.physicianPhoneNumber;
      initialValue.physicianNPI =
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.npi;
      initialValue.frequency =
        evaluationFormData &&
        evaluationFormData.formData &&
        evaluationFormData.formData.Frequency2
          ? evaluationFormData.formData.Frequency2
          : "";
      initialValue.totalVisit =
        evaluationFormData &&
        evaluationFormData.formData &&
        evaluationFormData.formData.Frequency1
          ? evaluationFormData.formData.Frequency1
          : "";
      initialValue.date =
        visitDetailById && visitDetailById.date ? visitDetailById.date : "";
      initialValue.physicianCommunication = "";
      initialValue.order = "";
      initialValue.orderReceivedBy = "";
      initialValue.orderReceivedFrom = "";
      initialValue.orderReceivedOn = "";
      initialValue.orderReceivedAt = "";
      initialValue.orderVerified = "";
      initialValue.effectiveDate = "";

      initialValue.justification =
        evaluationFormData &&
        evaluationFormData.formData &&
        evaluationFormData.formData.Justification
          ? evaluationFormData.formData.Justification
          : "";
      initialValue.DMERequest =
        evaluationFormData &&
        evaluationFormData.formData &&
        evaluationFormData.formData.DMERequests &&
        evaluationFormData.formData.DMERequests.length > 0
          ? evaluationFormData.formData.DMERequests
          : "";
      initialValue.therapistSigns = "";
      initialValue.Physiciansignature = "";
      initialValue.dates = "";

      if (staffFormData && staffFormData.formData) {
        let dates = staffFormData.formData.dates;
        if (
          formDetails &&
          formDetails.Status &&
          formDetails.Status.key &&
          (formDetails.Status.key === visitStatus.incompletedoc ||
            formDetails.Status.key === visitStatus.needreview ||
            formDetails.Status.key === visitStatus.completed)
        ) {
          dates = todayDate;
          let parseData = JSON.parse(JSON.stringify(staffFormData));
          if (parseData && parseData.formData && parseData.formData.dates) {
            delete parseData.formData.dates;
          }
          let init = { ...parseData.formData, id: parseData.id };
          Object.keys(initialValue).forEach((key) => {
            if (Array.isArray(initialValue[key]) && !init[key]) {
              init[key] = initialValue[key];
            } else if (!init[key]) {
              init[key] = initialValue[key];
            }
            //  else if (key === "totalVisit") {
            //   init[key] =
            //     evaluationFormData &&
            //     evaluationFormData.formData &&
            //     evaluationFormData.formData.Frequency1
            //       ? evaluationFormData.formData.Frequency1
            //       : parseData.formData.Frequency1
            //       ? parseData.formData.Frequency1
            //       : "";
            // } else if (key === "frequency") {
            //   init[key] =
            //     evaluationFormData &&
            //     evaluationFormData.formData &&
            //     evaluationFormData.formData.Frequency2
            //       ? evaluationFormData.formData.Frequency2
            //       : parseData.formData.Frequency2
            //       ? parseData.formData.Frequency2
            //       : "";
            // }
          });
          return init;
        }
      }
      return initialValue;
    }

    return initialValue;
  };

  const validateForm = (values) => {
    let errors = {};
    if (!values.date) {
      errors["date"] = "Required";
    }
    if (!values.physicianCommunication) {
      errors["physicianCommunication"] = "Required";
    }
    if (!values.totalVisit) {
      errors["totalVisit"] = "Required";
    } else if (values.totalVisit > 16 || values.totalVisit < 1) {
      errors["totalVisit"] = "Enter Number Between 0  to 16";
    }
    if (values.totalVisit && !values.frequency) {
      errors["frequency"] = "Please Select Any One";
    }
    if (!values.DMERequest) {
      errors["DMERequest"] = "Required";
    }
    if (values.orderReceivedAt) {
      const currentDate = todayDate;
      if (values.orderReceivedOn && values.orderReceivedOn === currentDate) {
        const currentTime = new Date().toLocaleTimeString([], {
          hour12: false,
        });
        if (values.orderReceivedAt > currentTime) {
          errors["orderReceivedAt"] = "Please Enter Past Time";
        }
      }
    }
    return errors;
  };

  const onSubmit = (values, form, status) => {
    let finalFormValues = {
      ...values,
      Therapistsignature: !displaySignature
        ? values.Therapistsignature
        : displaySignature,
    };
    const apiObject = {
      status: typeof status === "string" ? status : visitStatus.incompletedoc,
      staffId: staffDetails?.id,
      staffId: staffDetails?.id,
      formData: finalFormValues,
      formType: staffFormTypesConstants.PhysicianOrder,
      id: finalFormValues.id,
      episodeDocumentFormId: formId,
      episodeDocumentId: visitDetail?.id,
    };
    dispatch(savePatientForm(apiObject)).then((res) => {
      if (res.payload.success) {
        setShow(false);
        if (res.payload.isVisitUpdated)
          props.setToUpdate && props.setToUpdate(true);
        if (
          visitDetailById &&
          visitDetailById.Status &&
          visitDetailById.Status.key === visitStatus.scheduled
        ) {
          statusHandler(visitStatus.pendingnotes, visitDetail);
        }
        // else if (res.payload.isVisitUpdated) {
        //   statusHandler(visitStatus.needreview, visitDetail);
        // }

        closeFormModal();
        IsUpdated();
      }
    });
  };

  const getFrequency = (inputValue, episodeId) => {
    const {
      target: { value },
    } = inputValue;
    if (value)
      dispatch(
        getFrequencySuggestion({
          episodeId: episodeId,
          userId: staffDetails.id,
          visit: Number(value),
        })
      );
  };
  const dateFormat = (data) => {
    const today = new Date();
    const givendate = new Date(data);
    if (today < givendate) {
      return givendate.toISOString().split("T")[0];
    } else if (today > givendate) {
      return today.toISOString().split("T")[0];
    }

    return today;
  };

  const disableByVisitStatus = () => {
    let disabled = true;
    const isCurrentUserAdminOrSupAdminOrAgency =
      currentUser?.Role?.key === currentRole.admin ||
      currentUser?.Role?.key === currentRole.superadmin ||
      currentUser?.Role?.key === currentRole.agency;

    const isCurrentUserSupervisingOrAssistant =
      currentUser?.Role?.key === currentRole.supervisingtherapist ||
      currentUser?.Role?.key === currentRole.assistanttherapist;

    const isVisitInReviewOrCompleted =
      visitDetailById?.Status?.key === "needreview" ||
      visitDetailById?.Status?.key === "completed";

    if (isCurrentUserAdminOrSupAdminOrAgency) {
      disabled = true;
    } else if (
      isCurrentUserSupervisingOrAssistant &&
      isVisitInReviewOrCompleted
    ) {
      disabled = true;
    } else {
      disabled = false;
    }
    return disabled;
  };

  const getFormStatus = () => {
    let status = "";
    if (visitDetailById) {
      status = visitDetailById.Status.key;
      if (
        visitDetailById.patientFormList &&
        visitDetailById.patientFormList.length > 0
      ) {
        let form = visitDetailById.patientFormList.find(
          (item) => item.name == staffFormTypesConstants.PhysicianOrder
        );
        if (form) {
          status = form.Status.key;
        }
      }
    }
    return status;
  };
  return (
    <>
      <Form
        keepDirtyOnReinitialize
        initialValues={useMemo(
          (e) => initValues(e),
          [evaluationFormData, staffFormData, init]
        )}
        validate={validateForm}
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          values,
          form,
          initialValues,
          valid,
          submitFailed,
        }) => (
          <div>
            <form
              onSubmit={(e) => {
                if (!valid) toast.error("Please complete all required fields.");
                handleSubmit(e);
              }}
            >
              <div className={`${!isPrint ? "container" : "row"}`}>
                <div
                  className={`physician-section ${
                    isPrint ? "bg-gray-main-form-print" : ""
                  }`}
                >
                  <div className="row mein-white-from-background">
                    <div className="col-md-6 ">
                      <div>
                        <p>
                          Patient Name :{" "}
                          {staffDetails &&
                            staffDetails.lastName +
                              ", " +
                              staffDetails.firstName}
                        </p>
                        <p>
                          MR# :{" "}
                          {staffDetails &&
                            staffDetails.staffingDetails &&
                            staffDetails.staffingDetails.mrn}
                        </p>
                        <p>
                          DOB :{" "}
                          {staffDetails &&
                            staffDetails.staffingDetails &&
                            staffDetails.staffingDetails.dateOfBirth.split(
                              "T"
                            )[0] &&
                            dateFormatChange(
                              staffDetails.staffingDetails.dateOfBirth.split(
                                "T"
                              )[0]
                            )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="d-flex align-items-center gap-3 mb-3">
                        <label className="m-0"> Date :</label>
                        <Field name="date">
                          {({ input, meta }) => (
                            <div className="w-50">
                              <input
                                className="form-control"
                                {...input}
                                type="date"
                                min={dateFormat(
                                  staffDetails &&
                                    Object.keys(staffDetails).length > 0 &&
                                    staffDetails.staffInitialVisit.length > 0 &&
                                    staffDetails.staffInitialVisit[0]
                                      .anticipatedSocDate
                                )}
                                disabled={true}
                              />
                              {meta.error && meta.touched && (
                                <p className="alert alert-danger " role="alert">
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>
                      {currentUser &&
                        currentUser.Role &&
                        currentUser.Role.key &&
                        (currentUser.Role.key ===
                          currentRole.supervisingtherapist ||
                          currentUser.Role.key ===
                            currentRole.assistanttherapist) && (
                          <p>
                            Therapist :{" "}
                            {currentUser?.lastName +
                              ", " +
                              currentUser?.firstName}{" "}
                            <span>
                              {" "}
                              {currentUser.userDetail &&
                                `(${currentUser?.userDetail?.discipline?.toUpperCase()})`}
                            </span>
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="row mein-white-from-background">
                    <div className="col-md-6 border-right-1">
                      <div className="fileds-from">
                        <label>Agency Name :</label>
                        <Field name="agencyName">
                          {({ input, meta }) => (
                            <div className="w-50">
                              <input
                                className="form-control border-0 bg-white"
                                {...input}
                                type="text"
                                disabled
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="fileds-from">
                        <label>Agency Phone :</label>
                        <Field name="agencyPhone">
                          {({ input, meta }) => (
                            <div className="w-50">
                              <input
                                maxlength="45"
                                className="form-control border-0 bg-white"
                                {...input}
                                type="text"
                                disabled
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="col-md-6 border-right-1">
                      <div className="fileds-from">
                        <label>Physician Name :</label>
                        <Field name="physicianName">
                          {({ input, meta }) => (
                            <div className="w-50">
                              <input
                                maxlength="45"
                                className="form-control border-0 bg-white"
                                {...input}
                                type="text"
                                disabled
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="fileds-from">
                        <label>Physician Phone :</label>
                        <Field name="physicianPhoneNumber">
                          {({ input, meta }) => (
                            <div className="w-50">
                              <input
                                maxlength="45"
                                className="form-control border-0 bg-white"
                                {...input}
                                type="text"
                                disabled
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="fileds-from">
                        <label>NPI :</label>
                        <Field name="physicianNPI">
                          {({ input, meta }) => (
                            <div className="w-50">
                              <input
                                maxlength="45"
                                className="form-control border-0 bg-white"
                                {...input}
                                type="text"
                                disabled
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3 mb-3">
                      <Field
                        name={"physicianCommunication"}
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div>
                            <label className="w-100">
                              Physician Communication :
                            </label>
                            {toSave ? (
                              <p>{values.physicianCommunication}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                className="Additional_text"
                                {...input}
                                placeholder=""
                                disabled={disableByVisitStatus()}
                              />
                            )}

                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-md-12 d-flex justify-content-evenly">
                      <div className="fileds-from">
                        <Field name="order" type="radio" value="verbal">
                          {({ input, meta }) => (
                            <div className="w-100 d-flex align-items-center gap-3 ">
                              <input
                                {...input}
                                type="radio"
                                value="verbal"
                                disabled={disableByVisitStatus()}
                              />
                              <label>Verbal Order</label>
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className="fileds-from">
                        <Field name="order" type="radio" value="nonVerbal">
                          {({ input, meta }) => (
                            <div className="w-100 d-flex align-items-center gap-3 ">
                              <input
                                {...input}
                                type="radio"
                                value="nonVerbal"
                                disabled={disableByVisitStatus()}
                              />
                              <label>Non-Verbal Order</label>
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="col-md-6 border-right-1">
                      <div className="fileds-from">
                        <label>Verbal Order Received By :</label>
                        <Field name="orderReceivedBy">
                          {({ input, meta }) => (
                            <div className="w-50">
                              <input
                                maxlength="45"
                                className="form-control"
                                {...input}
                                type="text"
                                placeholder="Free Text"
                                disabled={disableByVisitStatus()}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="col-md-6 ">
                      <div className="fileds-from">
                        <label>Verbal Order Received From :</label>
                        <Field name="orderReceivedFrom">
                          {({ input, meta }) => (
                            <div className="w-50">
                              <input
                                maxlength="45"
                                className="form-control"
                                {...input}
                                type="text"
                                placeholder="Free Text"
                                disabled={disableByVisitStatus()}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="col-md-6 border-right-1">
                      <div className="fileds-from">
                        <label>Date Order Received :</label>
                        <Field name="orderReceivedOn">
                          {({ input, meta }) => (
                            <div className="w-50">
                              <input
                                {...input}
                                className="form-control"
                                type="date"
                                max={todayDate}
                                disabled={disableByVisitStatus()}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="col-md-6 ">
                      <div className="fileds-from">
                        <label>Time Order Received :</label>
                        <Field name="orderReceivedAt">
                          {({ input, meta }) => (
                            <div className="w-50">
                              <input
                                className="form-control"
                                {...input}
                                type="time"
                                disabled={disableByVisitStatus()}
                              />
                              {meta.error && meta.touched && (
                                <p className="alert alert-danger" role="alert">
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="col-md-6 border-right-1">
                      <div className="fileds-from">
                        <Field name="orderVerified">
                          {({ input, meta }) => (
                            <div className="w-100 d-flex align-items-center gap-3 ">
                              <input
                                {...input}
                                type="checkbox"
                                disabled={disableByVisitStatus()}
                              />
                              <label>
                                {" "}
                                Verbal Order Read Back And Verified
                              </label>
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="col-md-6 ">
                      <div className="fileds-from">
                        <label>Effective Date :</label>
                        <Field name="effectiveDate">
                          {({ input, meta }) => (
                            <div className="w-50">
                              <input
                                className="form-control"
                                {...input}
                                type="date"
                                disabled={disableByVisitStatus()}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>

                  <div className="row mein-white-from-background pt-0">
                    <h5 className="py-4">Frequency :</h5>
                    <div
                      className="col-md-4 mt-3 mb-3"
                      style={{ alignItems: "center", gap: "10px" }}
                    >
                      <div className="fileds-from" id="FrequencySelect1">
                        <Field name="totalVisit">
                          {({ input, meta }) => (
                            <div>
                              <label className="mb-8px">Total Visits :</label>
                              {toSave ? (
                                <input
                                  {...input}
                                  type="text"
                                  disabled={true}
                                  className="pteval-input"
                                  placeholder=" free text"
                                />
                              ) : (
                                <select
                                  {...input}
                                  disabled={true}
                                  className="pteval-input"
                                  onChange={(e) => {
                                    input.onChange(e);
                                    form.change("frequency", "");
                                    getFrequency(e, visitDetail.episodeId);
                                  }}
                                >
                                  <option value="">
                                    Please choose one option
                                  </option>
                                  {[
                                    ...Array(
                                      Number(
                                        leftVisit ? leftVisit : totalVisitNumber
                                      )
                                    ),
                                  ].map((option, index) => {
                                    return (
                                      <option key={index}>{index + 1}</option>
                                    );
                                  })}
                                </select>
                              )}

                              {meta.error && meta.touched && (
                                <p className="alert alert-danger" role="alert">
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>

                      <div
                        className=""
                        id="FrequencyInput1"
                        style={{ display: "none", gap: "10px" }}
                      >
                        <label
                          className="fw-bold"
                          style={{ textWrap: "nowrap" }}
                        >
                          Total Visits :{" "}
                        </label>
                        <Field name="totalVisit">
                          {({ input, meta }) => (
                            <>
                              <input
                                {...input}
                                type="text"
                                disabled={true}
                                className="pteval-input"
                                placeholder=" free text"
                              />
                              {meta.error && meta.touched && (
                                <p className="alert alert-danger " role="alert">
                                  {meta.error}
                                </p>
                              )}
                            </>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="col-md-4  mt-3 mb-3">
                      <div className="fileds-from">
                        {toSave ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <label
                              className="mb-8px"
                              style={{ textWrap: "nowrap" }}
                            >
                              Frequency :
                            </label>
                            <input
                              className="pteval-input"
                              type="text"
                              value={values.frequency && values.frequency}
                              placeholder=""
                            />{" "}
                          </div>
                        ) : (
                          <Field name="frequency">
                            {({ input, meta }) => (
                              <div>
                                <label className="mb-8px">Frequency :</label>
                                <select
                                  {...input}
                                  className="pteval-input"
                                  disabled={true}
                                >
                                  <option value="">Visit Frequency</option>
                                  {frequencySuggestion &&
                                  frequencySuggestion.initialEvaluationFreq &&
                                  frequencySuggestion.initialEvaluationFreq
                                    .length > 0 &&
                                  frequencySuggestion.initialEvaluationFreq.find(
                                    (item) => item.frequency === meta.initial
                                  )
                                    ? null
                                    : initialValues.frequency ===
                                        values?.frequency &&
                                      meta.initial && (
                                        <option value={meta.initial}>
                                          {meta.initial}
                                        </option>
                                      )}
                                  {values.totalVisit &&
                                    frequencySuggestion &&
                                    frequencySuggestion.initialEvaluationFreq &&
                                    frequencySuggestion.initialEvaluationFreq
                                      .length > 0 &&
                                    frequencySuggestion.initialEvaluationFreq.map(
                                      (freq, index) => (
                                        <option
                                          key={`frequency-${freq.frequency}`}
                                          value={freq.frequency}
                                        >
                                          {freq.frequency}
                                        </option>
                                      )
                                    )}
                                </select>
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 mt-3 mb-3">
                      <Field name="justification" className="input-textSize">
                        {({ input, meta }) => (
                          <div>
                            <label className="w-100">Justification :</label>

                            {toSave ? (
                              <p>{values.justification}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                className="Additional_text"
                                {...input}
                                placeholder="Free Text"
                                disabled={disableByVisitStatus()}
                              />
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="row mein-white-from-background">
                    <h5 className="py-4">DME Request :</h5>

                    <div className="col-md-12 mt-3 mb-3">
                      {toSave ? (
                        <div>
                          {values.DMERequest &&
                            values.DMERequest.length > 0 &&
                            values.DMERequest.map((item) => {
                              return <span> {`${item.label}`}</span>;
                            })}
                        </div>
                      ) : (
                        <Field name="DMERequest" className="input-textSize">
                          {({ input, meta }) => (
                            <div>
                              <CreatableSelect
                                {...input}
                                isMulti
                                options={reactSelectOptionCreate(
                                  formOptions.dmeRequests,
                                  "name",
                                  "name",
                                  meta.initial
                                )}
                                isDisabled={disableByVisitStatus()}
                              />
                              {meta.error && meta.touched && (
                                <p className="alert alert-danger " role="alert">
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          )}
                        </Field>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={`physician-section ${
                    isPrint ? "bg-gray-main-form-print" : ""
                  }`}
                >
                  <div className="row mein-white-from-background justify-content-between ">
                    <div className="col-md-12">
                      <div>
                        <div className="">
                          <div className="d-flex gap-2 align-items-center my-3">
                            <span> Therapist Signature: </span>
                            {signatureAdded || values.Therapistsignature ? (
                              <img
                                src={
                                  displaySignature
                                    ? displaySignature
                                    : values.Therapistsignature
                                }
                                height={"100px"}
                                width={"300px"}
                                crossOrigin="anonymous"
                              />
                            ) : (
                              <input
                                type="text"
                                placeholder="Therapist Signature"
                                disabled
                                className=" pl-0 border-0 border-right-2 border-left-2 border-bottom-2"
                              />
                            )}
                          </div>
                          {currentUser &&
                            currentUser.Role &&
                            currentUser.Role.key &&
                            (currentUser.Role.key ===
                              currentRole.supervisingtherapist ||
                              currentUser.Role.key ===
                                currentRole.assistanttherapist) &&
                            !toSave && (
                              <button
                                className="Approve-btns ml-0"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setIsCanvasShow(true);
                                }}
                                disabled={
                                  visitDetailById &&
                                  !canSubmitForm.includes(getFormStatus())
                                }
                              >
                                {signatureAdded || values.Therapistsignature
                                  ? "Update Signature"
                                  : "Add Signature"}
                              </button>
                            )}
                        </div>

                        {submitFailed &&
                          !values.Therapistsignature &&
                          !displaySignature && (
                            <p
                              className="alert alert-danger my-2 "
                              role="alert"
                            >
                              Signature is required.
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="col-md-12 mt-4">
                      <p className="my-2">
                        Physician Name :
                        <span className="ml-5">
                          {staffDetails &&
                          staffDetails.staffingDetails &&
                          staffDetails.staffingDetails.physicianName
                            ? staffDetails.staffingDetails.physicianName
                            : "--"}
                        </span>
                      </p>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="d-flex align-items-start">
                            <label className="no-wrap-white-space mb-0">
                              Physician Signature :
                            </label>
                            <Field
                              name="Physiciansignature"
                              className="Additional_text ml-3 "
                              component="textarea"
                              placeholder=" "
                              disabled={true}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="d-flex align-items-start mb-0 form-group">
                            <label className="mb-0 text-nowrap form-date-width">
                              Date :
                            </label>
                            <Field
                              name="dates"
                              className="Additional_text ml-3 "
                              component="textarea"
                              placeholder=" "
                              disabled={true}
                            />
                            {/* <Field
                            name="dates"
                            className="Additional_text ml-3 "
                            component="textarea"
                            disabled={disableByVisitStatus()}
                          >
                            {({ input, meta }) => (
                              <div>
                                <input {...input} type="date" readOnly />
                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger mb-3 ">
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {currentUser &&
                      currentUser.Role &&
                      currentUser.Role.key &&
                      (currentUser.Role.key ===
                        currentRole.supervisingtherapist ||
                        currentUser.Role.key ===
                          currentRole.assistanttherapist) && (
                        <div className="container">
                          <div className="py-5 ">
                            <button
                              className="Approve-btns h-auto"
                              onClick={(e) => {
                                e.preventDefault();
                                if (!valid) handleSubmit();
                                if (
                                  valid &&
                                  (signatureAdded || values.Therapistsignature)
                                )
                                  setShow(true);
                                else
                                  toast.error(
                                    "Please complete all required fields."
                                  );
                              }}
                              disabled={
                                visitDetailById &&
                                !canSubmitForm.includes(getFormStatus())
                              }
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                onSubmit(values);
                              }}
                              className="Approve-btns h-auto mx-2"
                              disabled={
                                visitDetailById &&
                                !canSubmitForm.includes(getFormStatus())
                              }
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              {show && (
                <Modal
                  show={show}
                  onHide={() => setShow(false)}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Header className="pl-4 pr-4 pt-4 pb-3" closeButton>
                    <Modal.Title>Complete</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="pl-4 pr-4 pt-0 pb-4">
                    By confirming this you will mark the Physician Order
                    document as completed. Are you sure?
                    <div className="mt-4">
                      <Button
                        className="Discard-danger"
                        variant="outline-danger"
                        onClick={() => setShow(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        className="primary-confirm"
                        onClick={(e) => {
                          e.preventDefault();
                          if (valid)
                            onSubmit(values, form, visitStatus.needreview);
                        }}
                      >
                        Confirm
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </form>
          </div>
        )}
      />
      {isCanvasShow && (
        <SignatureCanvas
          loadDoc={saveForm}
          isShow={isCanvasShow}
          setSignatureAdded={setSignatureAdded}
          handleClose={() => setIsCanvasShow(false)}
        />
      )}
    </>
  );
}
