import React from "react";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  getFrequencySuggestion,
  getStaffAuth,
  getStaffDocumentation,
  getStafffFrequency,
  updateFrequency,
} from "../../Redux/actions/common";
import Loader from "../../components/Loader";

const DeclineFrequency = (props) => {
  const isLoading = useSelector((e) => e.commonSlice.isLoading);
  const episodeDetails = useSelector((e) => e.commonSlice.episodeDetails);
  const frequencySuggestion = useSelector((e) => e.commonSlice.frequencySuggestion);
  const dispatch = useDispatch();

  const frequencyCount = (number) => {
    const factorPairs = [];
    for (let i = 1; i <= number; i++) {
      if (number % i === 0) {
        const factorPair = [i, number / i];
        factorPairs.push(factorPair);
      }
    }

    const finalResult = factorPairs.filter(
      (pair) => pair[0] <= 3 && pair[1] <= 8
    );

    if (finalResult.length < 1) {
      return frequencyCount(number + 1);
    }

    const options = finalResult.map((pair) => {
      const optionValue = `${pair[0]}W${pair[1]}`;
      return <option value={`${optionValue}`}>{`${optionValue}`}</option>;
    });

    return options;
  };

  const validateForm = (values) => {
    let error = {};
    if (!values.totalVisit) {
      error["totalVisit"] = "Total Visits is required.";
    } else if (
      Number(values.totalVisit) > 16 ||
      Number(values.totalVisit) < 1
    ) {
      error["totalVisit"] = "Should be between 1 to 16.";
    }

    if (!values.frequency) {
      error["frequency"] = "Frequency is required.";
    }

    return error;
  };

  const frequencyHandler = (values) => {
    let temp = {};
    temp.totalVisit = Number(values.totalVisit);
    temp.frequency = values.frequency;

    temp.userId = Number(props.userId);
    temp.episodeId = episodeDetails.rows[0]["id"];
    temp.isDecline = true

    dispatch(updateFrequency(temp)).then((e) => {
      if (e.payload.success) {
        dispatch(
          getStaffAuth({
            userId: Number(props.userId),
            episodeId: Number(episodeDetails.rows[0]["id"]),
          })
        );
        dispatch(
          getStafffFrequency({
            episodeId: Number(episodeDetails.rows[0]["id"]),
          })
        );
        dispatch(
          getStaffDocumentation({
            staffUserId: Number(props.userId),
          })
        );

        props.handleClose();
      }
    });
  };

  const getFrequency = (inputValue, episodeId) => {
    const { target: { value, } } = inputValue
    if (value) dispatch(getFrequencySuggestion({ episodeId: episodeDetails.rows[0]["id"], userId: props.userId, visit: Number(value) }))
  }
  return (
    <>
      {isLoading && <Loader />}
      <Form
        onSubmit={frequencyHandler}
        validate={(e) => validateForm(e)}
        render={({ handleSubmit, values, form, initialValues }) => (
          <form onSubmit={handleSubmit}>
            <div className="AddAuthorisationBody">
              <h1 className="Heading">Add New Frequency</h1>
              <div>
                <div className="mb-3 d-flex flex-column">
                  <Field name="totalVisit">
                    {({ input, meta }) => (
                      <div className=" mobile_width">
                        <label> Total Visits </label>
                        <input
                          {...input}
                          className="AuthorizationsTabs-inputs w-100"
                          placeholder="Total Visits"
                          type="text"
                          maxLength="2"
                          onChange={(e) => {
                            input.onChange(e)
                            form.change("frequency", "")
                            getFrequency(e)
                          }}
                        />
                        {meta.error && meta.touched && (
                          <p className="alert alert-danger " role="alert">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>

                <div className="mb-3 d-flex flex-column">
                  <Field name="frequency">
                    {({ input, meta }) => (
                      <div>
                        <label> Visit Frequency </label>
                        <select {...input} className="pteval-input">
                          <option value="">Visit Frequency (DROP-DOWN)#</option>
                          {frequencySuggestion
                            && frequencySuggestion.initialEvaluationFreq
                            && frequencySuggestion.initialEvaluationFreq.length > 0
                            && frequencySuggestion.initialEvaluationFreq.map((freq, index) => (
                              <option key={`frequency-${freq.frequency}`} value={freq.frequency}>{freq.frequency}</option>
                            ))}
                        </select>
                        {meta.error && meta.touched && (
                          <p className="alert alert-danger " role="alert">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>

                <div className="common-btns-div">
                  <button type="submit" className="Approve-btns ml-0">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default DeclineFrequency;
