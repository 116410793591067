import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../config/axiosInstance";
import { toast } from "react-toastify";

export const getInvoiceData = createAsyncThunk("Invoice/List", async (body) => {
  const apiData = await axiosInstance.post("staffing/getInvoiceByRole", body);
  return apiData.data;
});

export const getInvoiceDetailById = createAsyncThunk(
  "Invoice/Details/ByID",
  async (body) => {
    const apiData = await axiosInstance.post("staffing/invoiceView", body);
    return apiData.data;
  }
);

export const stripeAccountLink = createAsyncThunk(
  "Stripe/ConnectedAccount",
  async (body) => {
    const apiData = await axiosInstance.post("payment/stripeAccount", body);
    return apiData.data;
  }
);
export const createCustomer = createAsyncThunk(
  "Stripe/ConnectedAccount",
  async (body) => {
    const apiData = await axiosInstance.post("payment/customerCreate", body);
    return apiData.data;
  }
);
export const createPayment = createAsyncThunk(
  "Stripe/ConnectedAccount",
  async (body) => {
    const apiData = await axiosInstance.post("payment/paymentIntent", body);
    return apiData.data;
  }
);

export const stripeCreateInstantPayment = createAsyncThunk(
  "Stripe/stripeInstantPayment",
  async (body, { rejectWithValue }) => {
    try {
      const apiData = await axiosInstance.post(
        "payment/stripe-instant-payment",
        body
      );
      return apiData.data;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const messageArray = error.response.data.message.split(".");
        let message = error.response.data.message;
        if (messageArray && messageArray.length > 0) {
          message = messageArray[0];
        }
        toast.error(message);
        return rejectWithValue(message);
      }
    }
  }
);

export const stripeRetrieveInstantPayment = createAsyncThunk(
  "Stripe/stripeRetrieveInstantPayment",
  async (body, { rejectWithValue }) => {
    try {
      const apiData = await axiosInstance.get(
        "payment/stripe-retrieve-instant-payment",
        body
      );
      return apiData.data;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const messageArray = error.response.data.message.split(".");
        let message = error.response.data.message;
        if (messageArray && messageArray.length > 0) {
          message = messageArray[0];
        }
        toast.error(message);
        return rejectWithValue(message);
      }
    }
  }
);
