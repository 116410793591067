import React, { useEffect, useState } from "react";
import { Row, Col } from "bootstrap-4-react";
import { MyModal2 } from "../pages/modals/MyModal2";
import PendingModal from "../pages/modals/PendingModal";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CommonTable from "./common/CommonTable";
import Patient from "../images/usertable02.svg";
import Searchbar from "../images/search_grey_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  getUserDropDownAgencyData,
  getUserDropDownData,
} from "../Redux/actions/auth";
import {
  currentRole,
  decodeData,
  defaultStatus,
  encodedData,
  getUserId,
} from "../utils/auth";
import { CommonHead2 } from "./common/CommonHead2";
import { setLoader } from "../Redux/slices/authSlice";
import Loader from "./Loader";
import { apiBasePath } from "../Redux/config/config";
import {
  getStaffingByStatus,
  getStaffingDetailsById,
  updateStaffingStatus,
} from "../Redux/actions/staffing";
import { getDefaultRates } from "../Redux/actions/therapist";
import { CancelReferralModal } from "../pages/modals/CancelReferralModal";
import CancelApprovalModal from "../pages/modals/CancelApprovalModal";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiFillPlusCircle } from "react-icons/ai";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { BiCircle } from "react-icons/bi";
import PatientDetails from "../pages/Patients/PatientDetails";
import { UnfinishModal } from "../pages/modals/unifinishModal";
import { staffViewFromAdmin } from "../utils/helper";

const Staffing = () => {
  const [menuItem, setMenuItem] = useState("pending");
  const params = useParams();
  const [show, setshow] = useState(false);
  const [isUnifinishedModal, setIsUnfinishedModal] = useState(false);
  const [showAgencyAcceptModal, setAgencyAcceptModal] = useState(false);
  const [staffUserId, setStaffUserId] = useState("");
  let myTabStatus = {
    pending: defaultStatus.pending,
    approval: defaultStatus.awaitingagencyapproval,
    unfinishedprofiles: defaultStatus.unfinishedprofile,
    staffedreferral: defaultStatus.staffedreferral,
    declinedreferral: defaultStatus.rejected,
  };
  const [isRefresh, setIsRefresh] = useState(false);
  const handleClose = (update) => {
    setshow(false);
    if (isRefresh || update) {
      dispatch(setLoader({ loading: true }));
      getStaffingList({
        pageSize: pageSize,
        pageNo: 1,
        status: [myTabStatus[menuItem]],
        agencyId: agencyId ? agencyId : "",
      });
      setPageNo(1);
      setIsRefresh(false);
    }
  };

  const listRefresh = () => {
    dispatch(setLoader({ loading: true }));
    getStaffingList({
      pageSize: pageSize,
      pageNo: 1,
      status: [defaultStatus.unfinishedprofile],
      agencyId: agencyId ? agencyId : "",
    });
    setPageNo(1);
  };
  const handleShow = () => setshow(true);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchval, setSearchval] = useState(null);
  const [acceptModal, setAcceptModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);

  const [modalShow, setModalShow] = useState(false);
  const showApprovalModal = () => setModalShow(true);
  const [isLoadData, setLoadData] = useState(false);

  useEffect(() => {
    if (params && params.tab) {
      setTabMenus(params.tab);
    } else {
      setTabMenus("pending");
    }
  }, [params]);

  const hideApprovalModal = () => {
    closeModal();
    setModalShow(false);
  };
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const userDropDownData = useSelector((e) => e.staffingSlice.agencyList);

  const userList = useSelector((e) => e.staffingSlice.staffingList);
  const isLoading = useSelector((e) => e.staffingSlice.isLoading);
  const isModalLoading = useSelector((e) => e.staffingSlice.isModalLoading);
  const userDropDownAgencyData = useSelector(
    (e) => e.staffingSlice.agencyListNew
  );

  const [agencyId, setAgencyId] = useState(null);
  let decoded = getUserId();
  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);

  let status = [defaultStatus.pending];

  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const staffingCount = useSelector((e) => e.staffingSlice.staffingCount);

  const userRoleStatusCount = useSelector(
    (e) => e.staffingSlice.staffRoleStatusCount
  );

  useEffect(() => {
    if (
      currentUser?.Role?.key === "admin" ||
      currentUser?.Role?.key === "superadmin"
    ) {
      dispatch(getUserDropDownAgencyData());
    }
  }, [currentUser]);

  useEffect(() => {
    let rowKey = searchParams.get("rowkey");
    if (rowKey && document.getElementById(`row_${decodeData(rowKey)}`)) {
      document.getElementById(`row_${decodeData(rowKey)}`).click();
    }
  }, [userList]);

  function handleFilter(e) {
    setAgencyId(Number(e.target.value));
    let temp = {
      pageSize: pageSize,
      pageNo: 1,
      roles: [currentRole.patient],
      status: [myTabStatus[menuItem]],
    };

    if (Number(e.target.value) > 0) {
      temp["agencyId"] = Number(e.target.value);
    }
    if (searchval) {
      temp = { ...temp, search: searchval };
    }

    getStaffingList(temp);
  }
  const [columnsToView, setColumnsToView] = useState(staffViewFromAdmin);
  function handleClick(id) {
    let query = "therapist=true";
    setStaffUserId(id);
    dispatch(getStaffingDetailsById(`${id}?${query}`)).then((res) => {
      if (
        res.payload &&
        res.payload.data &&
        res.payload.data.staffInitialVisit &&
        res.payload.data.staffInitialVisit.length > 0 &&
        !res.payload.data.staffInitialVisit[0]["initialVisitDate"]
      ) {
        let stringArray = staffViewFromAdmin.toString();
        let staffColumns = stringArray
          .replace("initialvisitdate", "anticipateddate")
          .split(",");
        setColumnsToView(staffColumns);
      } else {
        setColumnsToView(staffViewFromAdmin);
      }
    });
    dispatch(getDefaultRates(currentRole.agency));
  }
  const debounceCall = debounce((ele) => {
    setPageNo(1);
    getStaffingList({
      pageSize: pageSize,
      pageNo: 1,
      search: ele,
      roles: [currentRole.patient],
      agencyId: agencyId,
      status: [myTabStatus[menuItem]],
    });
  }, 700);

  const hanleSearchVal = debounce((ele) => {
    setSearchval(ele.target.value);
  }, 700);

  const getRoleDetailCount = (role, status) => {
    let count = 0;
    if (userRoleStatusCount && userRoleStatusCount.length > 0) {
      let user = userRoleStatusCount.find(
        (item) =>
          item.Role.key === role && item.Status.key === defaultStatus[status]
      );
      if (user && user.userCount) {
        count = user.userCount;
      }
    }

    return count;
  };

  const setTabMenus = (tab) => {
    document.getElementById("searchField").value = "";
    setSearchval("");
    dispatch(setLoader({ loading: true }));
    setMenuItem(tab);
    setPageNo(1);
    getStaffingList({
      pageSize: pageSize,
      pageNo: 1,
      status: [myTabStatus[tab]],
      agencyId: agencyId ? agencyId : "",
    });
  };

  const getStaffingList = (obj) => {
    dispatch(
      getStaffingByStatus({
        pageSize: obj.pageSize,
        pageNo: obj.pageNo,
        status: obj.status,
        search: obj.search ? obj.search : "",
        roles: obj.roles ? obj.roles : [],
        agencyId: obj.agencyId ? obj.agencyId : null,
      })
    ).then((e) => init(e.payload.data.rows));
  };

  const cancelModalHandler = (staffInfo) => {
    dispatch(getStaffingDetailsById(staffInfo.id)).then((res) => {
      setModalOpen(true);
    });
  };

  const handleSubmit = (values) => {
    const obj = {
      status: defaultStatus.unfinishedprofile,
      therapistId: values.therapistId,
      staffUserId: staffDetail.id,
    };
    dispatch(updateStaffingStatus(obj)).then((response) => {
      if (response.payload.success) {
        toast.success("Therapist assigned.");
        setLoadData(true);
        if (response.payload.allAccepted) {
          if (values?.isProfileUpdate === true) {
            setAgencyAcceptModal(false);
            setAcceptModal(true);
          } else {
            setTabMenus("unfinishedprofiles");
          }
        }
        setTimeout(() => {
          setLoadData(false);
        }, 2000);
      }
    });
  };

  function handlePatientRoute(rowData) {
    if (rowData?.Role?.key === currentRole.patient) {
      navigate(`/patientdetails/${encodedData(rowData?.id)}`);
    } else {
      navigate(`/patientdetails/${encodedData(rowData?.staffUserId)}`);
    }
  }
  const handleUnfinishModal = (showState) => {
    setIsUnfinishedModal(showState);
  };
  const menu = [
    {
      key: "pending",
      label: "Pending",
      count: Number(
        getRoleDetailCount(currentRole.patient, defaultStatus.pending)
      ),
      data: userList,
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <pre className="d-flex gap-3 flex-wrap mb-0">
                  <button
                    className=" view-btn tr-btn ml-auto  mt-3 mb-3"
                    onClick={() => {
                      handleShow();
                      handleClick(rowData.id);
                    }}
                  >
                    View
                  </button>
                  {decoded && decoded.role === currentRole.agency && (
                    <button
                      className=" view-btn tr-btn mt-3 mb-3 Cancel-red"
                      onClick={() => cancelModalHandler(rowData)}
                    >
                      Cancel
                    </button>
                  )}
                </pre>
              </td>
            </>
          ),
        },
      ],
      modals: (
        <PendingModal
          columnsToView={columnsToView}
          handleClose={handleClose}
          from={myTabStatus[menuItem]}
          cancelModalHandler={cancelModalHandler}
        />
      ),
      columns:
        currentUser &&
        currentUser.Role &&
        currentUser.Role.key === currentRole.agency
          ? [
              "firstName",
              "staffingDetails.address",
              "staffingDetails.disciplinesNeeded",
            ]
          : [
              "firstName",
              "staffingDetails.address",
              "staffingDetails.disciplinesNeeded",
              "createdByUser.name",
            ],
      objectKey: ["staffingDetails"],
      columnsToHide: ["image"],
      modalClass: "PenddingM",
    },
    {
      label: "Approval Needed",
      count: Number(
        getRoleDetailCount(
          currentRole.patient,
          defaultStatus.awaitingagencyapproval
        )
      ),
      key: "approval",
      data: userList,
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <pre className="d-flex gap-3 flex-wrap mb-0">
                  <button
                    className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                    id={`row_${rowData && rowData.staffUserData.staffUserId}`}
                    onClick={() => {
                      handleShow();
                      handleClick(rowData.id);
                    }}
                  >
                    View
                  </button>
                  {decoded && decoded.role === currentRole.agency && (
                    <button
                      className=" view-btn tr-btn mt-3 mb-3 Cancel-red"
                      onClick={() => cancelModalHandler(rowData)}
                    >
                      Cancel
                    </button>
                  )}
                </pre>
              </td>
            </>
          ),
        },
      ],
      modals: (
        <PendingModal
          columnsToView={columnsToView}
          handleClose={handleClose}
          showTherapist={true}
          handleSubmit={handleSubmit}
          from={myTabStatus[menuItem]}
          isLoadData={isLoadData}
          cancelModalHandler={cancelModalHandler}
          acceptModal={acceptModal}
          setAcceptModal={setAcceptModal}
          setTabMenus={setTabMenus}
          handlePatientRoute={handlePatientRoute}
          handleUnfinishModal={handleUnfinishModal}
          setIsRefresh={setIsRefresh}
          setAgencyAcceptModal={setAgencyAcceptModal}
          showAgencyAcceptModal={showAgencyAcceptModal}
        />
      ),

      columns:
        currentUser &&
        currentUser.Role &&
        currentUser.Role.key === currentRole.agency
          ? [
              "firstName",
              "staffingDetails.address",
              "staffingDetails.disciplinesNeeded",
            ]
          : [
              "firstName",
              "staffingDetails.address",
              "staffingDetails.disciplinesNeeded",
              "createdByUser.name",
            ],
      objectKey: ["staffingDetails"],
      columnsToHide: ["image"],
      modalClass: "ApprovM",
    },
    {
      label: "Unfinished Profiles",
      key: "unfinishedprofiles",
      data: userList,
      count: Number(
        getRoleDetailCount(currentRole.patient, defaultStatus.unfinishedprofile)
      ),
      modals: (
        <PatientDetails
          handleClose={handleClose}
          staffUserId={staffUserId}
          listRefresh={listRefresh}
        />
      ),
      columns:
        currentUser &&
        currentUser.Role &&
        currentUser.Role.key === currentRole.agency
          ? [
              "firstName",
              "staffingDetails.address",
              "staffingDetails.disciplinesNeeded",
            ]
          : [
              "firstName",
              "staffingDetails.address",
              "staffingDetails.disciplinesNeeded",
              "createdByUser.name",
            ],
      columnsToHide: ["image"],
      modalClass: "ApprovM",
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <pre className="d-flex gap-3  mb-0">
                  <button
                    className=" view-btn tr-btn ml-auto  mt-3 mb-3"
                    onClick={() => {
                      handleShow();
                      handleClick(rowData.id);
                      // handlePatientRoute(rowData)
                    }}
                  >
                    View
                  </button>
                  {decoded && decoded.role === currentRole.agency && (
                    <button
                      className=" view-btn tr-btn mt-3 mb-3 Cancel-red"
                      onClick={() => cancelModalHandler(rowData)}
                    >
                      Cancel
                    </button>
                  )}
                </pre>
              </td>
            </>
          ),
        },
      ],
    },
    {
      label: "Staffed Referrals",
      count: Number(
        getRoleDetailCount(currentRole.patient, defaultStatus.staffedreferral)
      ),
      key: "staffedreferral",
      data: userList,
      modals: (
        <PendingModal
          columnsToView={columnsToView}
          handleClose={handleClose}
          from={myTabStatus[menuItem]}
          cancelModalHandler={cancelModalHandler}
        />
      ),
      modalClass: "ApprovM",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <pre className="d-flex gap-3  mb-0">
                <button
                  className=" view-btn tr-btn ml-auto  mt-3 mb-3"
                  onClick={() => {
                    navigate(`/patientdetails/${encodedData(rowData.id)}`);
                  }}
                >
                  View
                </button>
                {decoded && decoded.role === currentRole.agency && (
                  <button
                    className=" view-btn tr-btn mt-3 mb-3 Cancel-red"
                    onClick={() => cancelModalHandler(rowData)}
                  >
                    Cancel
                  </button>
                )}
              </pre>
            </td>
          ),
        },
      ],
      columns:
        currentUser &&
        currentUser.Role &&
        currentUser.Role.key === currentRole.agency
          ? [
              "firstName",
              "staffingDetails.address",
              "staffingDetails.disciplinesNeeded",
            ]
          : [
              "firstName",
              "staffingDetails.address",
              "staffingDetails.disciplinesNeeded",
              "createdByUser.name",
            ],
      columnsToHide: ["image"],
    },
    {
      label: "Declined Referrals",
      key: "declinedreferral",
      count: Number(
        getRoleDetailCount(currentRole.patient, defaultStatus.rejected)
      ),
      data: userList,
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <button
                  className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                  onClick={() => {
                    handleClick(rowData.id);
                    handleShow();
                  }}
                >
                  View
                </button>
              </td>
            </>
          ),
        },
      ],
      modals: (
        <PendingModal
          columnsToView={columnsToView}
          handleClose={handleClose}
          from={myTabStatus[menuItem]}
          showTherapist={true}
          notShowCall={true}
        />
      ),
      columns:
        currentUser &&
        currentUser.Role &&
        currentUser.Role.key === currentRole.agency
          ? [
              "firstName",
              "staffingDetails.address",
              "staffingDetails.disciplinesNeeded",
            ]
          : [
              "firstName",
              "staffingDetails.address",
              "staffingDetails.disciplinesNeeded",
              "createdByUser.name",
            ],
      objectKey: ["staffingDetails"],
      columnsToHide: ["image"],
      modalClass: "ApprovM",
    },
  ];

  const myActions = menu.find((ele) => ele.key === menuItem);

  function init(data, latLng) {
    let locations = [];
    data.map((e) => {
      locations.push({
        latlng: {
          lat: Number(e?.staffingDetails?.latitude),
          lng: Number(e?.staffingDetails?.longitude),
        },
        staffUserId: e.id,
      });
    });

    let map =
      document.getElementById("map") &&
      new window.google.maps.Map(document.getElementById("map"), {
        zoom: 8,
        center:
          locations && locations.length > 0 ? locations[0].latlng : latLng,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        styles: [
          {
            featureType: "poi",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
        ],
      });

    if (locations && locations.length > 0) {
      var marker, i;
      for (i = 0; i < locations.length; i++) {
        marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            locations[i].latlng.lat,
            locations[i].latlng.lng
          ),
          customInfo: locations[i].staffUserId,
          map: map && map,
        });

        window.google.maps.event.addListener(marker, "click", function (e) {
          mapMarkerClick(Number(this.customInfo));
        });
      }
    }
  }

  const mapMarkerClick = (staffUserId) => {
    dispatch(getStaffingDetailsById(staffUserId)).then((e) => {
      handleShow();
    });
  };

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "firstName":
        return "Patient";
      case "staffingDetails.address":
        return "Address";
      case "disciplines":
        return "Disciplines";
      case "name":
        return "Agency Name";
      case "staffingDetails.disciplinesNeeded":
        return "DISCIPLINES";
      case "staffingDetails.createdByUser.name":
        return "Agency Name";
      case "createdByUser.name":
        return "Agency Name";

      default:
        return colName;
    }
  };

  const handlePageClick = (event) => {
    dispatch(setLoader({ loading: true }));
    dispatch(
      getStaffingByStatus({
        pageSize,
        pageNo: event.selected + 1,
        status: [myTabStatus[menuItem]],
        search: searchval ? searchval : "",
        agencyId: agencyId ? agencyId : "",
      })
    );
    setPageNo(event.selected + 1);
  };

  const renderRowData = (result, col, item) => {
    if (col === "firstName") {
      return (
        <>
          <div className="d-flex align-items-center">
            <img
              src={
                result.image
                  ? `${apiBasePath}documents/therapist/${result.image}`
                  : Patient
              }
              crossOrigin="anonymous"
              className="table-img  "
              style={{ borderRadius: "50%", objectFit: "cover" }}
              alt="img"
            />
            <div>
              <h1 className="title-22 m-0">
                {result["lastName"]}
                {", "}
                {result["firstName"]}
              </h1>
              {result?.staffingDetails?.mrn && (
                <p className="title-24 m-0">
                  MR #{result?.staffingDetails?.mrn}
                </p>
              )}
            </div>
          </div>
        </>
      );
    }
    if (col === "staffingDetails.address") {
      return (
        <>
          <p className="m-0 addres-col">{result?.staffingDetails?.address}</p>
          <p className="m-0 addres-col">
            {result?.staffingDetails?.city}
            {", "}
            {result?.staffingDetails?.state}
            {", "}
            {result?.staffingDetails?.zip}
          </p>
        </>
      );
    }
    if (col === "createdByUser.name") {
      return (
        <>
          <p className="displine-list">
            {result && result.createdByUser && result.createdByUser["name"]}
          </p>
        </>
      );
    }

    if (col === "staffingDetails.disciplinesNeeded") {
      return (
        <>
          {currentUser && currentUser.Role.key !== currentRole.agency ? (
            <div>
              {result?.staffingDetails?.disciplinesNeeded &&
              Object.keys(result.staffingDetails.disciplinesNeeded).length >
                0 ? (
                <p className="displine-list">
                  {Object.keys(result?.staffingDetails?.disciplinesNeeded)
                    .join(" , ")
                    .toUpperCase()}
                </p>
              ) : (
                <p>--</p>
              )}
            </div>
          ) : (
            <div className="d-flex gap-1">
              {result?.staffingDetails?.disciplinesNeeded &&
                Object.keys(result.staffingDetails.disciplinesNeeded).map(
                  (e) => {
                    return (
                      <>
                        <p className="m-0 spanp">{e.toUpperCase()}</p>
                        <span className="spanp">
                          {(result &&
                            result.Status &&
                            result.Status.key ===
                              defaultStatus.awaitingagencyapproval) ||
                          (result &&
                            result.Status &&
                            result.Status.key === defaultStatus.pending) ? (
                            <BiCircle
                              color="#A1AEB9"
                              className="progress-check"
                            />
                          ) : (
                            <BsFillCheckCircleFill className="progress-check-after" />
                          )}
                        </span>
                      </>
                    );
                  }
                )}

              {/* <p className="m-0 spanp">OT</p>
              <span className="spanp">
                {result &&
                result.staffingDetails &&
                (result.staffingDetails["disciplinesNeeded"].ot ||
                  result.staffingDetails["disciplinesNeeded"].OT) ? (
                  <BsFillCheckCircleFill className="progress-check-after" />
                ) : (
                  <BiCircle color="#A1AEB9" className="progress-check" />
                )}
              </span>

              <p className="m-0 spanp">SLP</p>
              <span className="spanp">
                {result &&
                result.staffingDetails &&
                (result.staffingDetails["disciplinesNeeded"].slp ||
                  result.staffingDetails["disciplinesNeeded"].SLP) ? (
                  <BsFillCheckCircleFill className="progress-check-after" />
                ) : (
                  <BiCircle color="#A1AEB9" className="progress-check" />
                )}
              </span> */}
            </div>
          )}
        </>
      );
    }
    return <h1 className=" title-24  mt-3 mb-3">{result[item]}</h1>;
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12  pl-5 pr-5">
            <div className="patient_main_parent gap-3 mobile_justify justify-content-between">
              <div className="search_bar_frm" style={{ width: "400px" }}>
                <img style={{ marginRight: "15px" }} src={Searchbar} alt="" />
                <input
                  placeholder="Search by patient name."
                  id="searchField"
                  className="pl-2 searching"
                  style={{
                    border: "none",
                    fontSize: "18px",
                    outline: "none",
                    width: "100%",
                  }}
                  onChange={(ele) => {
                    debounceCall(ele.target.value);
                    hanleSearchVal(ele);
                  }}
                />
              </div>

              {decoded && decoded.role === currentRole.agency ? (
                <div className="patient_right_form mobile_justify">
                  <div className="inner_content_form">
                    <button
                      className=" new-staffing-btn d-flex align-items-center pl-1 "
                      onClick={() => navigate("/staffing/staff-new-referral")}
                    >
                      <AiFillPlusCircle
                        style={{
                          width: "2.1875rem",
                          height: "2.1875rem",
                          marginRight: "0.5rem",
                        }}
                        color="white"
                      />
                      STAFF NEW REFERRAL
                    </button>
                  </div>
                </div>
              ) : (
                <div className="patient_right_form">
                  <div className="inner_content_form gap-1">
                    <p className="head1"> Filter by Agency</p>
                    <select
                      className="form-select"
                      onChange={(e) => handleFilter(e)}
                    >
                      <option value="">All</option>
                      {userDropDownAgencyData &&
                        userDropDownAgencyData?.map((e) => (
                          <option value={e.id} key={e.id}>
                            {e.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="user-type-main tabs_user_type">
            <div className="col-sm-12 pr-5 pl-5">
              <CommonHead2
                data={menu}
                setMenu={setTabMenus}
                menuItem={menuItem}
                css={"col1 "}
              />
            </div>
          </div>

          <div className="container-fluid ">
            <Row>
              {myActions && myActions.key === "declinedreferral" && (
                <div className="col-sm-12  pl-5 pr-5">
                  <div className="patient_main_parent">
                    <div
                      id="map"
                      style={{ width: "100%", height: "30vh" }}
                    ></div>
                  </div>
                </div>
              )}

              <div className="inside_table_tabs table_left_padding justify-content-start">
                <Col lg={11}>
                  <div className="table-responsive mt-4">
                    {myActions &&
                    myActions.data &&
                    myActions.data.length > 0 ? (
                      <CommonTable
                        data={myActions?.data}
                        columns={myActions?.columns}
                        columnsToHide={myActions?.columnsToHide}
                        objectKey={myActions?.objectKey}
                        overrideColumnName={overrideColumnName}
                        actions={myActions?.actions}
                        renderRowData={renderRowData}
                        component={myActions?.component}
                        isLoading={isLoading}
                      />
                    ) : (
                      <div className="d-flex align-items-center justify-content-center mt-5">
                        {!isLoading && (
                          <h2 className="fs-5 mt-5">No Data available</h2>
                        )}
                      </div>
                    )}
                  </div>
                </Col>
              </div>
            </Row>
            {myActions?.count > pageSize &&
              staffingCount > pageSize &&
              myActions?.data?.length > 0 && (
                <div id="container ">
                  <div className="row justify-content-start mt-3  pl-5 ">
                    <div className=" pl-5 pr-5">
                      <ReactPaginate
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={Math.ceil(staffingCount / pageSize)}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        forcePage={
                          Math.ceil(staffingCount / pageSize) === 0
                            ? -1
                            : pageNo - 1
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>

      {(isLoading || isModalLoading) && <Loader />}

      {show && (
        <MyModal2
          data={myActions.modals}
          show={show}
          handleClose={handleClose}
          modalClass={myActions.modalClass}
          setLoadData={setLoadData}
        />
      )}

      {modalOpen && (
        <CancelReferralModal
          show={modalOpen}
          show2={showApprovalModal}
          handleClose={closeModal}
          handlePendingClose={handleClose}
        />
      )}
      {modalShow && (
        <CancelApprovalModal
          show={modalShow}
          handleClose={hideApprovalModal}
          modalClass="cancel-madal"
          isAllDeclined={true}
          from={myTabStatus[menuItem]}
          handlePendingClose={handleClose}
        />
      )}
      {isUnifinishedModal && (
        <UnfinishModal
          staffUserId={staffUserId}
          handleClose={() => {
            setIsUnfinishedModal(false);
            setTabMenus("unfinishedprofiles");
            // handleClose()
          }}
          isUnifinishedModal={isUnifinishedModal}
          mainhandleClose={handleClose}
        />
      )}
    </>
  );
};

export default Staffing;
