import React, { useState } from "react";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { CommonHead2 } from "../../../components/common/CommonHead2";
import { Row, Col } from "bootstrap-4-react";
import Active from "../../../images/AdminDashboard/active_pending_referrals_icon.svg";
import Staffed from "../../../images/AdminDashboard/referrals_staffed_this_week_icon.svg";
import Referrals from "../../../images/AdminDashboard/total_referrals_this_week_icon.svg";
import { defaultStatus, encodedData } from "../../../utils/auth";
import { MyModal2 } from "../../modals/MyModal2";
import HomeList from "./List";
import PendingDocumentation from "./pendingDocumentation";
import DocumentationCorrected from "./documentationCorrected";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCaseManagementStatus,
  getCaseManagementTabCount,
  getDocList,
  getHomeCountData,
} from "../../../Redux/actions/common";
import IncompletePendingNotes from "../../../components/IncompletePendingNotes";
import PendingModal from "../../modals/PendingModal";
import EpisodeModal from "../../Patients/EpisodeModal";
import NeedCorrection from "../../../components/NeedCorrection";
import { setLoader } from "../../../Redux/slices/authSlice";
import Loader from "../../../components/Loader";
const AssistantTherapistDashboard = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [menuItem, setMenuItem] = useState("patientneedscheduling");
  const [show, setshow] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(10);

  const userData = useSelector((e) => e.staffingSlice.therapistStaffList);
  const userRoleStatusCount = useSelector((e) => e.commonSlice.totaldocCount);
  const params = useParams();
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const isLoading = useSelector((e) => e.commonSlice.isLoading);
  const isModalLoading = useSelector((e) => e.staffingSlice.isModalLoading);

  const homeTabCardData = useSelector((e) => e.commonSlice.homeCount);
  const totalBillableAmount = homeTabCardData?.invoiceCount?.reduce(
    (Sum, item) => {
      return Sum + (item.billableAmount || 0);
    },
    0
  );

  const setMenu = (key) => {
    setMenuItem(key);
  };
  const userList = useSelector((e) => e.commonSlice.docList);
  const caseCount = useSelector((e) => e.commonSlice.casemgmttabcount);
  const caseListData = useSelector((e) => e.commonSlice.caseList);

  let myTabStatus = {
    patientneedscheduling: defaultStatus.patientneedscheduling,
    // staffneedscheduling: defaultStatus.staffneedscheduling,
    incomplete: defaultStatus.pendingnotes,
    // needcorrection: defaultStatus.visitnotes,
    needcorrection: defaultStatus.needcorrection,

    missvisitneeded: defaultStatus.missvisitneeded,
    frequencyupdateneeded: defaultStatus.frequencyupdateneeded,
  };
  useEffect(() => {
    if (params?.tab) {
      setMenuItem(params?.tab);
    } else if (currentUser && currentUser?.Role) {
      setMenuItem(menu[0].key);
    } else {
      dispatch(setLoader({ loading: true }));
    }
    dispatch(
      getCaseManagementStatus({
        pageSize,
        pageNo: 1,
        tab: myTabStatus[params.tab ? params.tab : menuItem],
      })
    );
    dispatch(getCaseManagementTabCount());
    tabActions[menuItem]();
    dispatch(getHomeCountData());
    dispatch(getDocList());
  }, [currentUser]);
  const statusBasedOnTab = (tabKey) => {
    let status = defaultStatus.patientneedingscheduling;
    switch (tabKey) {
      case "pendingdocumentation":
        status = defaultStatus.pendingdocumentation;
        break;
      case "documentationtobecorrected":
        status = defaultStatus.documentationtobecorrected;
        break;
      case "missvisitneeded":
        status = defaultStatus.missvisitneeded;
        break;
      case "frequencyupdateneeded":
        status = defaultStatus.frequencyupdateneeded;
        break;
      default:
        status = defaultStatus.patientneedingscheduling;
        break;
    }
    return status;
  };
  const setTabMenus = (tab1) => {
    // document.getElementById("searchField").value = "";
    // setSearchval("");
    dispatch(setLoader({ loading: true }));
    setMenuItem(tab1);
    setPageNo(1);
    dispatch(
      getCaseManagementStatus({
        pageSize,
        pageNo: 1,
        tab: myTabStatus[tab1],
      })
    );
    dispatch(getCaseManagementTabCount());
    tabActions[tab1]();
  };
  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);

  const getRoleDetailCount = (status) => {
    let count = 0;
    if (userRoleStatusCount && userRoleStatusCount?.length > 0) {
      let user = userRoleStatusCount.find(
        (item) => item?.Status?.key === status
      );
      if (user && user?.userCount) {
        count = user?.userCount;
      }
    }
    return Number(count);
  };

  const tabActions = {
    patientneedscheduling: (event) => {
      event && setPageNo(event.selected + 1);
      dispatch(
        getCaseManagementStatus({
          pageSize,
          pageNo: event ? event.selected + 1 : 1,
          tab: defaultStatus.patientneedscheduling,
        })
      );
    },
    incomplete: (event) => {
      event ? setPageNo(event.selected + 1) : setPageNo(1);
      dispatch(
        getDocList({
          pageSize: pageSize,
          pageNo: event ? event.selected + 1 : 1,
          status: defaultStatus.pendingnotes,
        })
      );
    },
    needcorrection: (event) => {
      event ? setPageNo(event.selected + 1) : setPageNo(1);
      dispatch(
        getDocList({
          pageSize: pageSize,
          pageNo: event ? event.selected + 1 : 1,
          status: defaultStatus.visitnotes,
        })
      );
    },
    missvisitneeded: (event) => {
      event ? setPageNo(event.selected + 1) : setPageNo(1);
      dispatch(
        getDocList({
          pageSize: pageSize,
          pageNo: event ? event.selected + 1 : 1,
          status: defaultStatus.missvisitneeded,
        })
      );
    },
    frequencyupdateneeded: () => {},
  };

  useEffect(() => {
    dispatch(getHomeCountData());
  }, []);
  const getHomeCardCount = (type, role, status) => {
    let count = 0;
    if (
      homeTabCardData &&
      homeTabCardData[type] &&
      homeTabCardData[type].length > 0
    ) {
      homeTabCardData[type].map((e) => {
        if (
          status &&
          e.Status.key === status &&
          (!role || e.Role.key === role)
        ) {
          count += Number(e.userCount);
        } else if (!status && (!role || e.Role.key === role)) {
          count += Number(e.userCount);
        }
      });
    }
    return count;
  };
  const menu = [
    {
      key: "patientneedscheduling",
      // key: "staffneedscheduling",
      label: "Patients Needing Scheduling",
      data: caseListData,
      noData: "No data available",

      count:
        caseCount && caseCount.patientneedscheduling
          ? caseCount.patientneedscheduling
          : "00",
      // count: caseCount && caseCount?.staffneedscheduling,
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <pre className="d-flex gap-3 flex-wrap mb-0">
                  <button
                    className=" view-btn tr-btn ml-auto  mt-3 mb-3"
                    onClick={handleShow}
                  >
                    Review
                  </button>
                </pre>
              </td>
            </>
          ),
        },
      ],

      modals: <EpisodeModal />,
      dialogClassName: "modal-lg",
      modalClass: "Main_modal_1",
      columns: ["PATIENT", "MR#", "ADDRESS", "DISCIPLINES", "CASE STATUS"],
      // objectKey: ["staffingDetails"],
      columnsToHide: ["image"],
      // modalClass: "PenddingM",
    },
    {
      key: "incomplete",

      label: "Pending Documentation",
      count:
        getRoleDetailCount(defaultStatus.pendingnotes) +
        getRoleDetailCount(defaultStatus.needcosigned),
      data: userList,

      // component: (
      //   <IncompletePendingNotes
      //     getRoleDetailCount={getRoleDetailCount}
      //     // latLng={latLng}
      //     // mainTableClass="pl-5"
      //     handleShow={handleShow}
      //     // handleClick={handleClick}
      //   />
      // ),
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleShow();
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
      modals: <PendingModal />,
      // columns: ["firstName", "discipline", "status"],
      columns: ["PATIENT NAME", "VISIT TYPE", "DATE OF VISIT"],

      columnsToHide: ["image"],
      modalClass: "ApprovM",
    },
    {
      label: "Documentation to be Corrected",
      key: "needcorrection",
      // count:
      //   getRoleDetailCount(defaultStatus?.visitnotes) +
      //   getRoleDetailCount(defaultStatus?.freqnphysicianorder),
      count: getRoleDetailCount(defaultStatus.needcorrection),

      data: userList,

      // component: (
      //   <NeedCorrection
      //     getRoleDetailCount={getRoleDetailCount}
      //     // latLng={latLng}
      //     mainTableClass="pl-5"
      //     handleShow={handleShow}
      //     // handleClick={handleClick}
      //   />
      // ),
      isTableShow: true,
      // noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleShow();
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
      modals: <PendingModal />,
      // columns: ["firstName", "discipline", "status"],
      columns: ["PATIENT NAME", "VISIT TYPE", "DATE OF VISIT"],

      columnsToHide: ["image"],
      modalClass: "ApprovM",
    },
    {
      key: "missvisitneeded",
      label: "Missed Visits Needed",
      count: getRoleDetailCount(defaultStatus.missvisitneeded),

      data: userList,
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  // handleClick(rowData);
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
      // modals: <ViewModal updateStatus={updateStatus} />,
      columns: ["PATIENT NAME", "VISIT TYPE", "WEEK OF VISIT"],
      columnsToHide: ["image", "lastName"],
      modalClass: "PenddingM modalstart",
    },
    {
      label: "Frequency Update Needed",
      count: "0",
      key: "frequencyupdateneeded",
      noData: "No data available",

      // component: (
      //   <PendingDocumentation getRoleDetailCount={getRoleDetailCount} />
      // ),
      isTableShow: false,
    },
  ];

  const myActions = menu.find((ele) => ele.key === menuItem);
  const handlePageClick = (event) => {
    dispatch(setLoader({ loading: true }));
    tabActions[menuItem](event);
  };
  return (
    <>
      <div className="home-lists  home-lists-1 ">
        <div className="col-lg-12">
          <div className="title_home_list d-flex align-items-center justify-content-between pr-5 mb-4 ">
            <h3 className="dash-head">Virtual Assistant</h3>
          </div>

          <Row className="row1">
            {getHomeCardCount("staffCount", "", defaultStatus.pending) !==
              0 && (
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={6}
                className="padding-bottom therapist-d-1"
              >
                <div
                  className="home-list list1 list-2 h-auto padding-therapist-tabs"
                  onClick={() =>
                    navigate("/assistent/findpatients/availablereferrals")
                  }
                >
                  <div className="home-list-icon">
                    <img src={Active} className="top-img-admin" alt="" />
                  </div>
                  <div className="home-list-content">
                    <h4 className="mb-5">Available Referrals</h4>
                    <span className="mt-3 d-flex align-content-end">
                      {getHomeCardCount(
                        "staffCount",
                        "",
                        defaultStatus.pending
                      ) || 0}
                    </span>
                  </div>
                </div>
              </Col>
            )}

            {getHomeCardCount("docCount", "", defaultStatus.scheduled) !==
              0 && (
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={6}
                className="padding-bottom therapist-d-1"
              >
                <div
                  className="home-list list2 list-2 h-auto padding-therapist-tabs"
                  onClick={() => navigate("/documentation/scheduled")}
                >
                  {" "}
                  <div className="home-list-icon">
                    <img src={Staffed} className="top-img-admin" alt="" />
                  </div>
                  <div className="home-list-content">
                    <h4>Visits Remaining This week</h4>
                    <span className="mt-3 d-flex align-content-end">
                      {getHomeCardCount(
                        "docCount",
                        "",
                        defaultStatus.scheduled
                      ) || 0}
                    </span>
                  </div>
                </div>
              </Col>
            )}

            {totalBillableAmount
              ? totalBillableAmount !== 0 && (
                  <Col
                    xl={4}
                    lg={4}
                    md={6}
                    sm={6}
                    className="padding-bottom therapist-d-1"
                  >
                    <div
                      className="home-list list3 list-2 padding-agency-toptabs"
                      onClick={() => navigate("/accounting/invoice")}
                    >
                      {" "}
                      <div className="home-list-icon">
                        <img src={Referrals} className="top-img-admin" alt="" />
                      </div>
                      <div className="home-list-content">
                        <h4>Anticipated Pay</h4>

                        <p className="m-0 flex-wrap d-flex rate-p align-content-center gap-3">
                          ${totalBillableAmount || 0}
                          <span className="m-0">04/18-4/24/2022</span>
                        </p>
                      </div>
                    </div>
                  </Col>
                )
              : ""}
          </Row>
        </div>
      </div>
      <div className="pl-5 pr-5 mt-5">
        <CommonHead2
          css="col1"
          data={menu}
          setMenu={setTabMenus}
          menuItem={menuItem}
        />
      </div>
      <div className="container-fluid pl-5 pr-5 table-container-1">
        <HomeList
          pageNo={pageNo}
          data={menu}
          setPageNo={setPageNo}
          myActions={myActions}
          tabStatus={statusBasedOnTab}
          isLoading={isLoading}
          handlePageClick={handlePageClick}
        />
      </div>
      {(isLoading || isModalLoading) && <Loader />}

      {show && (
        <MyModal2
          data={myActions.modals}
          show={show}
          handleClose={handleClose}
          modalClass={myActions.modalClass}
        />
      )}
    </>
  );
};

export default AssistantTherapistDashboard;
