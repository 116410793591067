import React from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../images/nav.png";
import LogoRight from "../../images/logo_hologram_img@2x.png";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form } from "bootstrap-4-react";
import { Form as Forms, Field } from "react-final-form";
import { forgetPassword } from "../../Redux/actions/auth";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((e) => e.authSlice.isLoading);

  function handleSubmit(values) {
    dispatch(forgetPassword(values)).then((response) => {
      if (response && response.payload && response.payload.success) {
        toast.success(`${response.payload.message}`);
      } else {
        toast.error(`${response.payload.message}`);
      }
    });
  }

  const validate = (values) => {
    var regex = /^\S+@\S+\.\S+$/;
    let errors = {};
    if (!values.email) {
      errors["email"] = "Email is required.";
    }

    if (values.email) {
      if (!regex.test(values.email)) {
        errors["email"] = "Please enter a valid email address.";
      }
    }

    return errors;
  };

  return (
    <div className="main-area ">
      <div className="navbar-main">
        <img
          src={logo}
          className="logo-header"
          onClick={() => navigate("/")}
          alt="img"
        />
        <button className="login-button" onClick={() => navigate("/")}>
          Login
        </button>
      </div>
      <Container>
        <Row className="forget-password justify-content-center">
          <Col md={6}>
            <Forms
              onSubmit={handleSubmit}
              validate={validate}
              render={({ handleSubmit, reset }) => (
                <Form onSubmit={handleSubmit} className="form-main">
                  <h2>Forgot Password</h2>
                  <p>
                    Please enter your username or email address below and we
                    will send a link to set your new password
                  </p>
                  <div className="form-group">
                    <Field name="email">
                      {({ input, meta }) => (
                        <>
                          <label>Username / Email Address</label>
                          <input
                            type="email"
                            placeholder="Enter your username or registered email"
                            {...input}
                          />
                          {meta.error && meta.touched && (
                            <p className="input-error w-100">{meta.error}</p>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                  <button className="save-btn" type="submit">
                    Submit
                  </button>
                </Form>
              )}
            />
          </Col>
          <Col md={4}>
            <div className="d-flex justify-content-center ">
              <img src={LogoRight} className="img-fluid" width={330} alt="" />
            </div>
          </Col>
        </Row>
        {isLoading && <Loader />}
      </Container>
    </div>
  );
};

export default ForgotPassword;
