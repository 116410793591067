/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import { Row, Col } from "bootstrap-4-react";
import Chart from "../chart/Chart";
import { useNavigate } from "react-router-dom";
import { CommonHead } from "../../components/common/CommonHead";
import BlockModal from "../modals/BlockModal";
import { defaultStatus, encodedData } from "../../utils/auth";
import CommonTable from "../../components/common/CommonTable";
import { DailyAgency } from "../modals/DailyAgency";
import { DailyTherapist } from "../modals/DailyTherapist";
import { DailyActivePatients } from "../modals/DailyActivePatients";
import { currentRole } from "../../utils/auth";
import {
  getAdminHomeTabCount,
  getUsersByRoleStatus,
} from "../../Redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../Redux/slices/authSlice";
import Active from "../../images/AdminDashboard/active_pending_referrals_icon.svg";
import Staffed from "../../images/AdminDashboard/referrals_staffed_this_week_icon.svg";
import Referrals from "../../images/AdminDashboard/total_referrals_this_week_icon.svg";
import Invoices from "../../images/AdminDashboard/past_due_invoices_icon.svg";
import Therapists from "../../images/AdminDashboard/daily_active_therapist_icon.svg";
import Agencies from "../../images/AdminDashboard/daily_active_agencies_icon.svg";
import Patients from "../../images/AdminDashboard/active_pending_referrals_icon.svg";
import assistant from "../../images/AdminDashboard/assistant_therapist_icon.png";
import assistant2 from "../../images/usertable02.svg";
import { apiBasePath } from "../../Redux/config/config";
import Loader from "../../components/Loader";
import Agencypng from "../../images/AdminDashboard/agency_icon.png";
import supervising from "../../images/AdminDashboard/supervising_therapist_icon.png";
import Supervisingpng2 from "../../images/usertable03.svg";
import Agencypng1 from "../../images/usertable01.svg";
import {
  getAdminHomeCardData,
  getBlockListForAdmin,
} from "../../Redux/actions/admin";
import { adminDashboardTabs } from "../../utils/helper";
import block_icon from "../../images/block_icon.svg";

const DashboardContainer = styled.div`
  display: "flex";
  border: "2px solid red";
`;

const Main = styled.div`
  width: 100%;
  height: 90vh;
`;

const Dashboard = () => {
  const navigate = useNavigate();
  const [show, setshow] = useState(false);
  const [Mod, setMod] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const handleClose = () => {
    setBlockRowData();
    setshow(false);
  };
  const handleShow = () => setshow(true);
  const dispatch = useDispatch();
  const [menuItem, setMenuItem] = useState("reviewnewusers");
  const [blockRowData, setBlockRowData] = useState();
  const userList = useSelector((e) => e.authSlice.userDetails);
  // const isLoading = useSelector((e) => e.authSlice.isLoading);
  const isLoading = useSelector((e) => e.authSlice.isHomeLoader);
  const cardData = useSelector((e) => e.adminSlice.adminHomeCardData);
  const homeCount = useSelector((e) => e.adminSlice.homeCount);
  const listData = useSelector((e) => e.adminSlice.blockedList);
  const isBlockLoading = useSelector((e) => e.adminSlice.isListLoading);
  const tabCount = useSelector((e) => e.authSlice?.tabCount);
  const [updateTab, setUpdateTab] = useState(false);
  const [pageSize] = useState(10);

  let roles = [
    currentRole.agency,
    currentRole.supervisingtherapist,
    currentRole.assistanttherapist,
  ];
  let status = [defaultStatus.pending];

  const handlePageClick = (event) => {
    dispatch(setLoader({ loading: true }));
    tabActions[menuItem](event);
  };

  const getRoleDetailCount = (tab) => {
    let count = 0;
    if (tabCount && tabCount[tab]) {
      count = tabCount[tab];
    }
    return count;
  };

  const getCardCount = (role, checkStatus) => {
    let count = 0;
    if (!checkStatus && cardData && cardData.length > 0) {
      cardData &&
        cardData.map((item) => {
          if (
            item.Role.key === role &&
            item.Status.key === defaultStatus.pending
          ) {
            count = count + Number(item.userCount);
          }
        });
    }

    if (checkStatus && cardData && cardData.length > 0) {
      cardData &&
        cardData.find((item) => {
          if (item.Role.key === role && item.Status.key === checkStatus) {
            count = count + Number(item.userCount);
          }
        });
    }

    return count;
  };

  const getActiveCount = (role) => {
    let count = 0;
    if (
      role &&
      homeCount &&
      homeCount.activeUsers &&
      homeCount.activeUsers.length > 0
    ) {
      let activeCount = homeCount.activeUsers.find(
        (item) => item.Role.key === role
      );
      count = activeCount ? activeCount.roleCount : 0;
    }

    return count;
  };

  let myModal = {
    agency: (
      <DailyAgency
        show={show}
        handleClose={handleClose}
        count={getActiveCount(currentRole.agency, defaultStatus.approved)}
      />
    ),
    supervisingtherapist: (
      <DailyTherapist
        show={show}
        handleClose={handleClose}
        count={getActiveCount(
          currentRole.supervisingtherapist,
          defaultStatus.approved
        )}
      />
    ),
    patient: (
      <DailyActivePatients
        show={show}
        handleClose={handleClose}
        count={getActiveCount(currentRole.patient, defaultStatus.approved)}
      />
    ),
    block: (
      <BlockModal
        show={show}
        handleClose={handleClose}
        rowData={blockRowData}
        setBlockRowData={setBlockRowData}
        setUpdateTab={() => setUpdateTab(!updateTab)}
      />
    ),
  };

  const tabActions = {
    reviewnewusers: (event) => {
      event && setPageNo(event.selected + 1);
      dispatch(
        getUsersByRoleStatus({
          pageSize: pageSize,
          pageNo: event ? event.selected + 1 : 1,
          roles,
          status,
        })
      );
    },
    verifynewcredentials: () => {},
    reviewbackgroundchecks: () => {},
    reviewblocklist: (event) => {
      event ? setPageNo(event.selected + 1) : setPageNo(1);
      dispatch(
        getBlockListForAdmin({
          pageNo: event ? event.selected + 1 : 1,
          pageSize: pageSize,
          status: defaultStatus.blockedpending,
          tab: "all",
        })
      );
    },
  };
  const menu = [
    {
      label: "Review New Users",
      key: "reviewnewusers",
      count: Number(getRoleDetailCount(adminDashboardTabs.reviewnewusers)),
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  redirectForView(rowData);
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
      columns: ["name", "Role.name"],
      columnsToHide: ["image"],
      data: userList,
    },
    {
      label: "Verify New Credentials",
      count: Number(
        getRoleDetailCount(adminDashboardTabs.verifynewcredentials)
      ),
      key: "verifynewcredentials",
      noData: "No Data Available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleShow();
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
      columns: ["discplines", "name"],
      columnsToHide: ["image", "scount"],
    },
    {
      label: "Review Background Checks",
      key: "reviewbackgroundchecks",
      count: Number(
        getRoleDetailCount(adminDashboardTabs.reviewbackgroundchecks)
      ),
      columns: ["name", "discipline"],
      columnsToHide: ["image"],
      noData: "No Data Available",

      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleShow();
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
    },
    {
      label: "Review Block-list",
      count: Number(getRoleDetailCount(adminDashboardTabs.reviewblocklist)),
      key: "reviewblocklist",
      noData: "No Data Available",
      data: listData,
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                // className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                className="update-btn justify-content-center ml-auto "
                onClick={() => {
                  setFun("block");
                  setBlockRowData(rowData);
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
      columns: ["name", "usertype", "blockedby"],
      columnsToHide: ["image", "scount"],
    },
  ];

  const myActions = menu.find((ele) => ele.key === menuItem);

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "name":
        return "Name";
      case "discipline":
        return "DISCIPLINES";
      case "blockedby":
        return "BLOCKED BY";
      case "Role.name":
        return "User Type";
      default:
        return colName;
    }
  };

  const redirectForView = (rowData) => {
    let userRole = rowData.Role && rowData.Role.key;

    if (userRole === currentRole.agency) {
      navigate(`/newusers/agency/${encodedData(rowData.id)}`);
    }
    if (
      userRole === currentRole.supervisingtherapist ||
      userRole === currentRole.assistanttherapist
    ) {
      navigate(
        `/newusers/therapist/${encodedData({
          id: rowData.id,
          onbordingPartCompleted: rowData.userDetail.onbordingPartCompleted,
        })}`
      );
    }
  };

  let myIcons = {
    supervisingtherapist: {
      icon: <img src={supervising} alt="supervisingImage" />,
    },
    agency: { icon: <img src={Agencypng} alt="agencyImage" /> },
    assistanttherapist: { icon: <img src={assistant} alt="assistantImage" /> },
    patient: { icon: <img src={assistant} alt="assistantImage" /> },
  };

  let myImage = {
    supervisingtherapist: Supervisingpng2,
    agency: Agencypng1,
    assistanttherapist: assistant2,
    patient: assistant2,
  };

  const renderRowData = (result, col, item) => {
    if (menuItem === "reviewblocklist") {
      const userRole = result && result.blockedUsers.Role.key;
      if (col === "name") {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="position-relative" style={{ marginRight: "5%" }}>
                <img
                  alt="user data"
                  src={
                    result.blockedUsers && result.blockedUsers.image
                      ? `${apiBasePath}documents/therapist/${result.blockedUsers.image}`
                      : myImage[userRole]
                  }
                  crossOrigin="anonymous"
                  className="table-img"
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                />
                <img
                  className="buuble_botom block_sub_icon "
                  src={block_icon}
                />
              </div>
              <h1 className=" title-22 ms-1">
                {userRole === currentRole.agency
                  ? result &&
                    result.blockedUsers &&
                    `${result.blockedUsers.name}`
                  : result &&
                    result.blockedUsers &&
                    `${result.blockedUsers.lastName}, ${result.blockedUsers.firstName}`}
              </h1>
            </div>
          </>
        );
      }

      if (col === "usertype") {
        return (
          <>
            <div className="d-flex gap-2 align-items-center">
              {myIcons[result.blockedUsers.Role.key]?.icon}
              <p className="m-0 displine-list">
                {result &&
                  result.blockedUsers &&
                  `${result.blockedUsers.Role.title}`}
              </p>
            </div>
          </>
        );
      }

      if (col === "blockedby") {
        return (
          <>
            <div className="d-flex gap-2 align-items-center">
              <p className="m-0 displine-list">
                {result.blockedByUsers.name
                  ? result &&
                    result.blockedByUsers && (
                      <a
                        href={`/newusers/agency/${encodedData(
                          result.blockedByUsers.id
                        )}`}
                        className="blockedByLink"
                      >
                        {`${result.blockedByUsers.name}`}
                      </a>
                    )
                  : result &&
                    result.blockedByUsers && (
                      <a
                        href={`/newusers/therapist/${encodedData({
                          id: result.blockedByUsers.id,
                          onbordingPartCompleted: 1,
                        })}`}
                        className="blockedByLink"
                      >
                        {`${result.blockedByUsers.lastName}, ${result.blockedByUsers.firstName} `}
                      </a>
                    )}
              </p>
            </div>
          </>
        );
      }
      return <h1 className="displine-list title-24">{result[item]}</h1>;
    } else {
      let userRole = result.Role && result.Role.key;
      if (col === "name") {
        return (
          <>
            <div className="d-flex align-items-center">
              <img
                src={
                  result.image
                    ? `${apiBasePath}documents/therapist/${result.image}`
                    : myImage[result["Role"]["key"]]
                }
                crossOrigin="acountnymous"
                className="table-img"
                style={{ borderRadius: "50%", objectFit: "cover" }}
                alt="img"
              />

              <h1 className="title-22">
                {userRole === currentRole.agency
                  ? result[item]
                  : userRole === currentRole.supervisingtherapist ||
                    userRole === currentRole.assistanttherapist
                  ? `${result["lastName"]}, ${result["firstName"]}
                  `
                  : "--"}
              </h1>
            </div>
          </>
        );
      }

      if (col === "Role.name") {
        return (
          <>
            <div className="d-flex gap-2 align-items-center">
              {myIcons[result["Role"]?.key]?.icon}
              <p className="m-0 displine-list">{result["Role"].title}</p>
            </div>
          </>
        );
      }
      return <h1 className=" title-24 mt-3 mb-3"> {result[item]}</h1>;
    }
  };

  function setFun(e) {
    setMod(e);
    handleShow();
  }
  const setTabMenus = (tab) => {
    dispatch(setLoader({ loading: true }));
    setMenuItem(tab);
    setPageNo(1);
    dispatch(getAdminHomeCardData());
    dispatch(getAdminHomeTabCount());
    tabActions[tab]();
  };
  useEffect(() => {
    dispatch(setLoader({ loading: true }));
    dispatch(getAdminHomeCardData());
    dispatch(getAdminHomeTabCount()).then(() => {
      tabActions[menuItem]();
    });
  }, [updateTab]);

  return (
    <DashboardContainer>
      {(isLoading || isBlockLoading) && <Loader />}
      <Main>
        <div className="home-lists  home-lists-1 ">
          <div className="col-lg-12 p-0">
            <div className="title_home_list pt-4">
              <h3 className="dash-head">Virtual Assistant</h3>
            </div>
            <Row className="row1">
              {homeCount &&
                homeCount.pendingStaffCurrentWeek &&
                homeCount.pendingStaffCurrentWeek?.count !== 0 && (
                  <Col xl={3} lg={4} md={6} sm={6} className="padding-bottom">
                    <div
                      className="home-list list1 list-2"
                      onClick={() => navigate("/staffing")}
                    >
                      <div className="home-list-icon">
                        <img src={Active} className="top-img-admin" alt="" />
                      </div>
                      <div className="home-list-content">
                        <h4>
                          Active Pending <br /> Referrals
                        </h4>
                        <span className=" mt-3 d-flex align-content-end">
                          {" "}
                          {homeCount.pendingStaffCurrentWeek.count
                            ? homeCount.pendingStaffCurrentWeek.count
                            : 0}
                        </span>
                      </div>
                    </div>
                  </Col>
                )}
              {homeCount &&
                homeCount.currentWeeklyStaffReferrals &&
                homeCount.currentWeeklyStaffReferrals?.count !== 0 && (
                  <Col xl={3} lg={4} md={6} sm={6} className="padding-bottom ">
                    <div
                      className="home-list list2 list-2"
                      onClick={() => navigate("/staffing/staffedreferral")}
                    >
                      <div className="home-list-icon">
                        <img src={Staffed} className="top-img-admin" alt="" />
                      </div>
                      <div className="home-list-content">
                        <h4>
                          Referrals Staffed <br />
                          this Week
                        </h4>
                        <span className=" mt-3 d-flex align-content-end">
                          {homeCount.currentWeeklyStaffReferrals.count
                            ? homeCount.currentWeeklyStaffReferrals.count
                            : 0}
                        </span>
                      </div>
                    </div>
                  </Col>
                )}

              {homeCount &&
                homeCount.currentWeeklyStaff &&
                homeCount.currentWeeklyStaff?.count !== 0 && (
                  <Col xl={3} lg={4} md={6} sm={6} className="padding-bottom">
                    <div className="home-list list3 list-2 pe-none">
                      <div className="home-list-icon">
                        <img src={Referrals} className="top-img-admin" alt="" />
                      </div>
                      <div className="home-list-content">
                        <h4>
                          Total Referrals <br />
                          this Week
                        </h4>
                        <span className=" mt-3 d-flex align-content-end">
                          {homeCount.currentWeeklyStaff.count
                            ? homeCount.currentWeeklyStaff.count
                            : 0}
                        </span>
                      </div>
                    </div>
                  </Col>
                )}
              {homeCount &&
                homeCount.invoiceCount &&
                homeCount.invoiceCount?.count !== 0 && (
                  <Col
                    xl={3}
                    lg={4}
                    md={6}
                    sm={6}
                    className="padding-bottom"
                    onClick={() => navigate("/accounting")}
                  >
                    <div className="home-list list4 list-2">
                      <div className="home-list-icon">
                        <img src={Invoices} className="top-img-admin" alt="" />
                      </div>
                      <div className="home-list-content">
                        <h4>
                          Past Due <br /> Invoices
                        </h4>
                        <span className=" mt-3 d-flex align-content-end">
                          {homeCount.invoiceCount.count
                            ? homeCount.invoiceCount.count
                            : 0}
                        </span>
                      </div>
                    </div>
                  </Col>
                )}
              {getActiveCount(
                currentRole.supervisingtherapist,
                defaultStatus.approved
              ) > 0 && (
                <Col xl={3} lg={4} md={6} sm={6} className="padding-bottom">
                  <div
                    className="home-list list5 list-2"
                    onClick={() => setFun(currentRole.supervisingtherapist)}
                  >
                    <div className="home-list-icon">
                      <img src={Therapists} className="top-img-admin" alt="" />
                    </div>
                    <div className="home-list-content">
                      <h4>
                        Daily Active <br /> Therapists
                      </h4>
                      <span className=" mt-3 d-flex align-content-end">
                        {getActiveCount(
                          currentRole.supervisingtherapist,
                          defaultStatus.approved
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
              )}
              {getActiveCount(currentRole.agency, defaultStatus.approved) >
                0 && (
                <Col xl={3} lg={4} md={6} sm={6} className="padding-bottom">
                  <div
                    className="home-list list6 list-2"
                    onClick={() => setFun(currentRole.agency)}
                  >
                    <div className="home-list-icon">
                      <img src={Agencies} className="top-img-admin" alt="" />
                    </div>
                    <div className="home-list-content">
                      <h4>
                        Daily Active <br /> Agencies
                      </h4>
                      <span className=" mt-3 d-flex align-content-end">
                        {getActiveCount(
                          currentRole.agency,
                          defaultStatus.approved
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
              )}
              {getActiveCount(currentRole.patient, defaultStatus.approved) >
                0 && (
                <Col xl={3} lg={4} md={6} sm={6} className="padding-bottom ">
                  <div
                    className="home-list list7 list-2"
                    onClick={() => setFun(currentRole.patient)}
                  >
                    <div className="home-list-icon">
                      <img src={Patients} className="top-img-admin" alt="" />
                    </div>
                    <div className="home-list-content">
                      <h4>
                        Daily Active <br /> Patients
                      </h4>
                      <span className=" mt-3 d-flex align-content-end">
                        {getActiveCount(
                          currentRole.patient,
                          defaultStatus.approved
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </div>

        <div className="chart_content main_top_chart">
          <Chart />
        </div>
        <div className="pl-5 pr-5 mt-5">
          <CommonHead
            data={menu}
            setMenu={setTabMenus}
            menuItem={menuItem}
            setPageNo={setPageNo}
          />
        </div>
        <div className="container-fluid ">
          <div className="table-container-1">
            <Row>
              <div className="inside_table_tabs">
                <Col lg={12}>
                  <div className="table-responsive table-width-1">
                    {myActions &&
                    myActions.data &&
                    myActions.data.length > 0 ? (
                      <CommonTable
                        data={myActions?.data}
                        columns={myActions.columns}
                        columnsToHide={myActions.columnsToHide}
                        overrideColumnName={overrideColumnName}
                        actions={myActions.actions}
                        component={myActions.component}
                        renderRowData={renderRowData}
                        isLoading={isLoading}
                      />
                    ) : (
                      <div className="d-flex align-items-center justify-content-center mt-5">
                        <h2 className="fs-5 mt-5 mb-5">No Data available</h2>
                      </div>
                    )}
                  </div>
                  {typeof myActions.count !== "string" &&
                    myActions?.count > pageSize &&
                    myActions?.data?.length > 0 && (
                      <div id="container ">
                        <div className="row justify-content-start mt-3   ">
                          <div className="col-sm-4  pr-5">
                            <ReactPaginate
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                              nextLabel="next >"
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={5}
                              pageCount={Math.ceil(myActions?.count / pageSize)}
                              previousLabel="< previous"
                              renderOnZeroPageCount={null}
                              forcePage={
                                Math.ceil(myActions?.count / pageSize) === 0
                                  ? -1
                                  : pageNo - 1
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                </Col>
                {show && myModal[Mod]}
              </div>
            </Row>
          </div>
        </div>
      </Main>
    </DashboardContainer>
  );
};

export default Dashboard;
