import React from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import { ViewVisitRate } from "../../../components/common/ViewVisitRates";
import userImage from "../../../images/loginpage.png";
import asstimage from "../../../images/patient/physician_black_icon.svg";
import { apiBasePath } from "../../../Redux/config/config";
import { currentRole, professionaltype } from "../../../utils/auth";
import CancelApprovalModal from "../../modals/CancelApprovalModal";
import discipline_n from "../../../images/discipline_needed_icon.svg";
import date_requested_icon from "../../../images/date_requested_icon.svg";
import Language from "../../../images/newtherapist/language_icon.svg";
import Preferred from "../../../images/newtherapist/preferred_specialities_icon.svg";
import visit from "../../../images/visit_type_icon.svg";
import { useState } from "react";
import Loader from "../../../components/Loader";
import OfferModal from "../../Therapist/Staffing/OfferModal";
import specialRequest from "../../../images/patient/emergency_contact_name_icon.svg";

const TherapistRatesModal = (props) => {
  const [cancelModalState, setCancelModalState] = useState(false);
  const [showRatesModal, setShowRatesModal] = useState(false);
  const [makeOffer, setMakeOfferData] = useState(null);
  const therapistRoles = props?.therapistData?.therapist.Role;
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);

  const isRateLoading = useSelector(
    (e) => e.therapistSigunpSlice.isRateLoading
  );

  const therapistDetails = (data) => {
    let results = [];
    props.therapistData?.therapist?.userProfessionalData.map((e) => {
      if (e.value === "true" && e.masterData?.types === data) {
        results.push(e.masterData.displayName);
      }
    });

    results.length < 1 && results.push("NA");

    return results;
  };
  const getExperience = () => {
    if (
      props.therapistData?.therapist &&
      props.therapistData?.therapist?.userDetail &&
      props.therapistData?.therapist?.userDetail?.experienceDate &&
      props.therapistData?.therapist?.userDetail?.experienceMonth &&
      props.therapistData?.therapist?.userDetail?.experienceYear
    ) {
      const day = props.therapistData?.therapist?.userDetail?.experienceDate;
      const month = props.therapistData?.therapist?.userDetail?.experienceMonth;
      const year = props.therapistData?.therapist?.userDetail?.experienceYear;
      const currentDate = new Date();
      const endDate = new Date(year, month, day);
      const dateDifference =
        parseInt(currentDate.getTime()) - parseInt(endDate.getTime());
      const expYear = parseInt(dateDifference / (1000 * 3600 * 24 * 365));
      return Number(expYear);
    }
  };

  const modalHandler2 = () => {
    setCancelModalState(true);
  };

  const handleClose2 = () => {
    setCancelModalState(false);
    props.handleClose();
  };

  const handleShowCounteredRate = (value, offer) => {
    if (offer) {
      setShowRatesModal(value);
      setMakeOfferData(offer);
    }
  };

  return (
    <>
      <Modal
        className={props.modalClass}
        show={props.show}
        onHide={() => props.handleClose("acceptModal")}
        aria-labelledby="contained-modal-title-vcenter"
      >
        {isRateLoading && <Loader />}
        <Modal.Header closeButton></Modal.Header>
        <div className="d-flex">
          <div className="container-fluid">
            <div className="row bg-white-user d-flex justify-content-between pt-0">
              <div className="col-md-4 d-flex gap-4  align-items-center">
                <img
                  className="userimge1"
                  crossOrigin="anonymous"
                  src={
                    props.therapistData?.therapist &&
                    props.therapistData?.therapist.image
                      ? `${apiBasePath}documents/therapist/${props.therapistData?.therapist.image}`
                      : userImage
                  }
                  alt="img"
                />
                <div className="mt-3 ">
                  <h4 className="username-1 mt-3 mb-4">
                    {props.therapistData?.therapist?.lastName}
                    {", "}
                    {props.therapistData?.therapist?.firstName}
                  </h4>
                  <div className="d-flex gap-3">
                    <div className="d-flex align-items-start gap-3 nr-2">
                      <img
                        src={discipline_n}
                        className="Common-referral-icons"
                        alt=""
                      />
                      <div className="flex-column align-items-center">
                        <h6 className="heading-uppercase1 mb-1">DISCIPLINE</h6>
                        <p className="details-p mb-0">
                          {
                            props.therapistData?.therapist?.userRates[0]
                              ?.rateTab?.name
                          }
                        </p>
                      </div>
                    </div>
                    {therapistRoles.key ===
                      currentRole.supervisingtherapist && (
                      <div
                        className="d-flex align-items-start gap-3 nr-2"
                        style={{ minWidth: "53%" }}
                      >
                        <img
                          src={specialRequest}
                          className="Common-referral-icons"
                          alt=""
                        />
                        <div className="flex-column align-items-center">
                          <h6 className="heading-uppercase1 mb-1">
                            ASSISTANT REQUESTED
                          </h6>
                          <p className="details-p mb-0">
                            {props.therapistData?.isAssistentNeeded
                              ? "Yes"
                              : "No"}
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="d-flex align-items-start gap-3">
                      <img
                        src={date_requested_icon}
                        className="Common-referral-icons"
                        style={{ width: "30px" }}
                        alt=""
                      />

                      <div className="flex-column align-items-center">
                        <h6 className="heading-uppercase1 mb-1">Experience</h6>
                        <p className="details-p mb-0">
                          {getExperience()} years
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {currentUser &&
                currentUser.Role &&
                currentUser.Role.key &&
                currentUser.Role.key === currentRole.agency && (
                  <div className="col-md-3">
                    <div className="justify-content-end d-flex flex-column gap-3 mt-4">
                      <button
                        className="accept-btn  ml-auto"
                        onClick={() =>
                          props.acceptModalHandler(
                            props?.disabled,
                            props?.therapistData
                          )
                        }
                        disabled={props?.disabled}
                      >
                        {props?.disabled ? "Accepted" : "Accept"}
                      </button>
                      {!props?.disabled && (
                        <button
                          className="decline-btn btn2 ml-auto"
                          onClick={modalHandler2}
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>

        <div className="container-fluid mt-5 pl-5 pr-5">
          <div className="row">
            <div>
              <p className="page-titele-agency">Therapist Details</p>
              <div className="d-flex justify-content-between mb-5 mt-4">
                <div className="d-flex align-items-start gap-3">
                  <img
                    src={Language}
                    className="Common-referral-icons"
                    alt=""
                    style={{ width: "30px" }}
                  />
                  <div>
                    <p className="sub-title1 mb-1">LANGUAGES SPOKEN</p>

                    <div className="d-flex flex-wrap ">
                      {therapistDetails(professionaltype.languagespoken).map(
                        (e, index) => {
                          return (
                            <p className="sub-title1-p" key={`${e}_${index}`}>
                              {e}
                              {therapistDetails(professionaltype.languagespoken)
                                .length -
                                1 !==
                                index && ","}
                              &nbsp;
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start gap-3">
                  <img
                    src={Preferred}
                    className="Common-referral-icons"
                    alt=""
                    style={{ width: "30px" }}
                  />
                  <div>
                    <p className="sub-title1 mb-1">PREFERRED SPECIALITIES </p>
                    <div className="d-flex flex-wrap ">
                      {therapistDetails(
                        professionaltype.specialtiespreferred
                      ).map((e, index) => {
                        return (
                          <p className="sub-title1-p" key={`${e}_${index}`}>
                            {e}
                            {therapistDetails(
                              professionaltype.specialtiespreferred
                            ).length -
                              1 !==
                              index && ","}{" "}
                            &nbsp;
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start gap-3">
                  <img
                    src={visit}
                    className="Common-referral-icons"
                    alt=""
                    style={{ width: "30px" }}
                  />
                  <div>
                    <p className="sub-title1 mb-1">AVAILIBILITY FOR VISIT</p>
                    <div className="d-flex flex-wrap ">
                      <p className="sub-title1-p">
                        {props?.therapistData.acceptedDate
                          ? props?.therapistData.acceptedDate
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <Accordion defaultActiveKey={"2"} flush alwaysOpen>
              <Accordion.Item className="border-0 mb-3" eventKey="2">
                <div className="d-flex self-align-center">
                  <h1 className=" pl-0 bg-white a-button">Rates</h1>
                  {props && props?.therapistData && (
                    <span
                      className={`${props?.therapistData?.makeOffer
                        ? "Accepted-red"
                        : "Accepted-green"
                        } mt-1 ml-3`}
                      onClick={() =>
                        handleShowCounteredRate(
                          true,
                          props?.therapistData?.makeOffer
                        )
                      }
                    >
                      {props?.therapistData?.makeOffer
                        ? "Countered"
                        : "Accepted"}
                    </span>
                  )}
                </div>

                <Accordion.Body className=" p-0">
                  <ViewVisitRate
                    myClass={true}
                    defaultRates={props?.defaultRates}
                    userRates={props?.userRates}
                    disciplinesNeeded={props?.disciplinesNeeded}
                    therapist={
                      props?.therapistData && props?.therapistData?.therapist
                    }
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
          </div>
        </div>
      </Modal>
      {cancelModalState && (
        <CancelApprovalModal
          show={cancelModalState}
          handleClose={handleClose2}
          modalClass="cancel-madal"
          therapistData={props.therapistData}
          isAllDeclined={false}
          from={"therapist"}
        />
      )}
      {showRatesModal && (
        <OfferModal
          show={showRatesModal}
          makeOffer={makeOffer}
          handleClose={() => {
            setShowRatesModal(false);
          }}
          modalClass="th-staffaccept accept-modal"
          onlyShowOffer={true}
          therapistAcceptedReferralList={
            props &&
            props?.therapistAcceptedReferralList &&
            props?.therapistAcceptedReferralList
          }
        />
      )}
    </>
  );
};

export default TherapistRatesModal;
