import {
  AccountingIcon,
  AdminUsericon,
  BlockIcon,
  CaseManagementIcon,
  ContactSupportIcon,
  DocumentationIcon,
  FindPatientIcon,
  HomeIcon,
  LogoutIcon,
  MyScheduleIcon,
  NewUsericon,
  PatientChartIcon,
  StaffingIcon,
  UserSettingIcon,
  TherapistCredentialsIcon,
} from "../images/SidebarIcons/AllSidebarIcons";

export const menuArray = {
  admin: {
    home: [
      {
        icon: <HomeIcon />,
        name: "Home",
        id: 1,
        route: "/home",
        other: ["/home", "/"],
      },
      {
        icon: <PatientChartIcon />,
        name: "Patient Charts",
        id: 2,
        route: "/patientcharts",
        other: ["/patientcharts", "/patientdetails"],
      },
      {
        icon: <StaffingIcon />,
        name: "Staffing",
        id: 3,
        route: "/staffing",
        other: ["/staffing"],
      },
      {
        icon: <NewUsericon />,
        name: "New Users",
        id: 4,
        route: "/newusers",
        other: [
          "/newusers",
          "/newusers/agency",
          "/newusers/agency/rejected",
          "/newusers/therapist",
          "/newusers/therapist/rejected",
        ],
      },

      {
        icon: <AdminUsericon />,
        name: "Admin User",
        id: 5,
        route: "/adminuser",
        other: ["/adminuser"],
      },
      {
        icon: <TherapistCredentialsIcon />,
        name: "Therapist Credentials",
        id: 6,
        route: "/therapistcredential",
        other: ["/therapistcredential"],
      },
      {
        icon: <BlockIcon />,
        name: "Block-List",
        id: 7,
        route: "/blocklist",
        other: ["/blocklist"],
      },
      {
        icon: <CaseManagementIcon />,
        name: "Case Management",
        id: 8,
        route: "/casemanagement",
        other: ["/casemanagement"],
      },
      {
        icon: <DocumentationIcon />,
        name: "Documentation",
        id: 9,
        route: "/documentation",
        other: ["/documentation"],
      },
      {
        icon: <AccountingIcon />,
        name: "Accounting",
        id: 10,
        route: "/accounting",
        other: ["/accounting"],
      },
    ],
    account: [
      {
        icon: <UserSettingIcon />,
        name: "User Profile",
        id: 4,
        route: "/myprofile",
        other: ["/myprofile"],
      },
      {
        icon: <ContactSupportIcon />,
        name: "Contact Support",
        id: 5,
        route: "/contactus",
        other: ["/contactus"],
      },
      {
        icon: <LogoutIcon />,
        name: "Logout",
        id: 6,
        route: "/Logout",
        other: ["/Logout"],
      },
    ],
  },
  superadmin: {
    home: [
      {
        icon: <HomeIcon />,
        name: "Home",
        id: 1,
        route: "/home",
        other: ["/home", "/"],
      },
      {
        icon: <PatientChartIcon />,
        name: "Patient Charts",
        id: 2,
        route: "/patientcharts",
        other: ["/patientcharts", "/patientdetails"],
      },
      {
        icon: <StaffingIcon />,
        name: "Staffing",
        id: 3,

        route: "/staffing",
        other: ["/staffing"],
      },
      {
        icon: <NewUsericon />,
        name: "New Users",
        id: 4,
        route: "/newusers",
        other: [
          "/newusers",
          "/newusers/agency",
          "/newusers/agency/rejected",
          "/newusers/therapist",
          "/newusers/therapist/rejected",
        ],
      },
      {
        icon: <AdminUsericon />,
        name: "Admin User",
        id: 5,
        route: "/adminuser",
        other: ["/adminuser"],
      },
      {
        icon: <BlockIcon />,
        name: "Block-List",
        id: 6,
        route: "/blocklist",
        other: ["/blocklist"],
      },
      {
        icon: <DocumentationIcon />,
        name: "Documentation",
        id: 8,
        route: "/documentation",
        other: ["/documentation"],
      },
      {
        icon: <CaseManagementIcon />,
        name: "Case Management",
        id: 7,
        route: "/casemanagement",
        other: ["/casemanagement"],
      },
      {
        icon: <AccountingIcon />,
        name: "Accounting",
        id: 9,
        route: "/accounting",
        other: ["/accounting"],
      },
    ],
    account: [
      {
        icon: <UserSettingIcon />,
        name: "User Profile",
        id: 4,
        route: "/myprofile",
        other: ["/myprofile"],
      },
      {
        icon: <ContactSupportIcon />,
        name: "Contact Support",
        id: 5,
        route: "/contactus",
        other: ["/contactus"],
      },
      {
        icon: <LogoutIcon />,
        name: "Logout",
        id: 6,
        route: "/Logout",
        other: ["/Logout"],
      },
    ],
  },
  agency: {
    home: [
      {
        icon: <HomeIcon />,
        name: "Home",
        id: 1,
        route: "/home",
        other: ["/home", "/"],
      },
      {
        icon: <StaffingIcon />,
        name: "Staffing",
        id: 3,

        route: "/staffing",
        other: ["/staffing"],
      },
      {
        icon: <PatientChartIcon />,

        name: "Patient Charts",
        id: 2,
        route: "/patientcharts",
        other: ["/patientcharts", "/patientdetails"],
      },
      {
        icon: <CaseManagementIcon />,
        name: "Case Management",
        id: 8,
        route: "/casemanagement",
        other: ["/casemanagement"],
      },
      {
        icon: <DocumentationIcon />,
        name: "Documentation",
        id: 7,
        route: "/documentation",
        other: ["/documentation"],
      },
      {
        icon: <AccountingIcon />,
        name: "Accounting",
        id: 9,
        route: "/accounting",
        other: ["/accounting"],
      },
      {
        icon: <TherapistCredentialsIcon />,
        name: "Therapist Credentials",
        id: 6,
        route: "/therapistcredential",
        other: ["/therapistcredential"],
      },
    ],
    account: [
      {
        icon: <UserSettingIcon />,
        name: "User Profile",
        id: 4,
        route: "/myprofile",
        other: ["/myprofile"],
      },
      {
        icon: <ContactSupportIcon />,
        name: "Contact Support",
        id: 5,
        route: "/contactus",
        other: ["/contactus"],
      },
      {
        icon: <LogoutIcon />,
        name: "Logout",
        id: 6,
        route: "/Logout",
        other: ["/Logout"],
      },
    ],
  },
  supervisingtherapist: {
    home: [
      {
        icon: <HomeIcon />,
        name: "Home",
        id: 1,
        route: "/",
        other: ["/home", "/"],
      },
      {
        icon: <MyScheduleIcon />,
        name: "My Schedule",
        id: 2,
        route: "/staffing",
        other: ["/staffing"],
      },
      {
        icon: <FindPatientIcon />,
        name: "Find Patients",
        id: 3,
        route: "/findpatients",
        other: [
          "/patientqweqeqwecharts",
          "/findpatients",
          "/patqweqweientdetails",
        ],
      },
      {
        icon: <PatientChartIcon />,
        name: "Patient Charts",
        id: 4,
        route: "/patientcharts",
        other: ["/patientcharts", "/patientdetails"],
      },
      {
        icon: <CaseManagementIcon />,
        name: "Case Management",
        id: 8,
        route: "/casemanagement",
        other: ["/casemanagement"],
      },
      {
        icon: <DocumentationIcon />,
        name: "Documentation",
        id: 7,
        route: "/documentation",
        other: ["/documentation"],
      },
      {
        icon: <AccountingIcon />,
        name: "Accounting",
        id: 9,
        route: "/accounting",
        other: ["/accounting"],
      },
    ],
    account: [
      {
        icon: <UserSettingIcon />,
        name: "User Profile",
        id: 4,
        route: "/myprofile",
        other: ["/myprofile"],
      },
      {
        icon: <ContactSupportIcon />,
        name: "Contact Support",
        id: 5,
        route: "/contactus",
        other: ["/contactus"],
      },
      {
        icon: <LogoutIcon />,
        name: "Logout",
        id: 6,
        route: "/Logout",
        other: ["/Logout"],
      },
    ],
  },
  assistanttherapist: {
    home: [
      {
        icon: <HomeIcon />,
        name: "Home",
        id: 1,
        route: "/home",
        other: ["/home"],
      },
      {
        icon: <FindPatientIcon />,
        name: "Find Patients",
        id: 3,
        route: "/assistent/findpatients",
        other: [
          "/assistent/patientqweqeqwecharts",
          "/assistent/findpatients",
          "/assistent/patqweqweientdetails",
        ],
      },
      {
        icon: <PatientChartIcon />,
        name: "Patient Charts",
        id: 2,
        route: "/patientcharts",
        other: ["/patientcharts", "/patientdetails"],
      },
      {
        icon: <CaseManagementIcon />,
        name: "Case Management",
        id: 8,
        route: "/casemanagement",
        other: ["/casemanagement"],
      },
      {
        icon: <DocumentationIcon />,
        name: "Documentation",
        id: 7,
        route: "/documentation",
        other: ["/documentation"],
      },
      {
        icon: <AccountingIcon />,
        name: "Accounting",
        id: 9,
        route: "/accounting",
        other: ["/accounting"],
      },
    ],
    account: [
      {
        icon: <UserSettingIcon />,
        name: "User Profile",
        id: 4,
        route: "/myprofile",
        other: ["/myprofile"],
      },
      {
        icon: <ContactSupportIcon />,
        name: "Contact Support",
        id: 5,
        route: "/contactus",
        other: ["/contactus"],
      },
      {
        icon: <LogoutIcon />,
        name: "Logout",
        id: 6,
        route: "/Logout",
        other: ["/Logout"],
      },
    ],
  },
};
