import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../config/axiosInstance";


export const getPatientList = createAsyncThunk(
    "Chat/Patient/List",
    async () => {
        const apiData = await axiosInstance.post(`twilio/getAllPatient`)
        return apiData.data
    }
)

export const createConversation = createAsyncThunk(
    "Chat/Conversation/Create",
    async (body) => {
        const apiData = await axiosInstance.post(`twilio/createConversations`, body)
        return apiData.data
    }
)

export const getAccessToken = createAsyncThunk(
    "Chat/Token/Access-Token",
    async () => {
        const apiData = await axiosInstance.get(`twilio/createTwilioToken`)
        return apiData.data
    }
)



export const conversationPaginator = createAsyncThunk(
    "Chat/Conversation/Paginator",
    async (body) => {
        return body
    }
)

export const conversationNextPage = createAsyncThunk(
    "Chat/Conversation/Paginator-NextPage",
    async (body) => {
        return body
    }
)

export const conversationAdded = createAsyncThunk(
    "Chat/Conversation/Add-New",
    async (body) => {
        return body
    }
)

export const setActiveConversation = createAsyncThunk(
    "Chat/Conversation/SetActive",
    async (body) => {
        return body
    }
)
export const getActivityLog = createAsyncThunk(
    "Chat/Activity-Log/get",
    async (userId) => {
        const apiData = await axiosInstance.get(`twilio/getUser-Activity/${userId}`)
        return apiData.data
    }
)

export const messagePaginator = createAsyncThunk(
    "Chat/Message/Paginator",
    async (body) => {
        return body
    }
)

export const AddNewMessage = createAsyncThunk(
    "Chat/Message/Add-New",
    async (body) => {
        return body
    }
)

export const messagePrevPage = createAsyncThunk(
    "Chat/Message/Paginator-PreviousPage",
    async (body) => {
        return body
    }
)
