import React from "react";

const Loader = () => {
  return (
    <div className="wrap">
      <div className="loader spin sticky">
        <div className="spin__blocker"></div>
        <div className="spin__bottom-left"></div>
        <div className="spin__bottom-right"></div>
        <div className="spin__top-left"></div>
      </div>
    </div>
  );
};

export default Loader;
