import React from "react";
import Modal from "react-bootstrap/Modal";
import { Field, Form } from "react-final-form";
import { useSelector } from "react-redux";
import { defaultStatus, currentRole } from "../../../utils/auth";
import ContractorServiceAgreement from "../../../documents/ServiceAgreement/contractorServiceAgreement";

const AcceptModal = (props) => {
  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);

  const handleSubmit = (value) => {
    let obj = {
      status: defaultStatus.awaitingagencyapproval,
      therapistId: staffDetail?.staffUser?.therapistId,
      isAssistentNeeded: value.isWillComplete === "no" ? true : false,
      isWillComplete: value.isWillComplete === "yes" ? true : false,
      staffUserId: staffDetail?.id,
      acceptedDate: props.acceptedDate ? props.acceptedDate : null,
    };
    props.updateStatus(obj);
  };

  const isShowAssistantNeededRadio = () => {
    let isShow = false;
    if (
      staffDetail &&
      staffDetail.staffingDetails &&
      currentUser.Role.key === currentRole.supervisingtherapist
    ) {
      if (
        currentUser &&
        currentUser.userDetail &&
        currentUser.userDetail.discipline === "pt" &&
        staffDetail.staffingDetails.ptaAllowed
      ) {
        isShow = true;
      } else if (
        currentUser.userDetail.discipline === "ot" &&
        staffDetail.staffingDetails.cotaAllowed
      ) {
        isShow = true;
      } else if (currentUser.userDetail.discipline === "slp") {
        isShow = false;
      }
    }

    return isShow;
  };

  return (
    <Modal
      className={props.modalClass}
      show={props.show}
      onHide={() => props.handleClose("acceptModal")}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <div className="d-flex justify-content-between">
        <h1 className="th-sttafhead mb-0">Accept the Referral</h1>
        <Modal.Header
          className="closeButton border-0"
          closeButton
        ></Modal.Header>
      </div>

      <Form
        validate={(values) => {
          let error = {};
          if (!values.termsandconditions) {
            error["termsandconditions"] =
              "You must accept the terms and conditions.";
          }
          return error;
        }}
        onSubmit={handleSubmit}
        initialValues={{
          isWillComplete: "yes",
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="mt-3 mb-5">
            {isShowAssistantNeededRadio() && (
              <>
                <h6>Please select one option below</h6>
                <div className=" d-flex align-item-center gap-2 mb-4 asis_btn">
                  <label className="container1">
                    <Field
                      name="isWillComplete"
                      type="radio"
                      component="input"
                      value="no"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="tclb m-0 ml-4">
                    Assistant Therapist needed for Follow-up visits
                  </label>
                </div>
                {currentUser &&
                  (currentUser?.Role?.key ===
                    currentRole.supervisingtherapist ||
                    currentUser?.Role?.key ===
                      currentRole.assistanttherapist) && (
                    <div className=" d-flex align-item-center gap-2 mb-4 asis_btn">
                      <label className="container1">
                        <Field
                          name="isWillComplete"
                          type="radio"
                          component="input"
                          value="yes"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="tclb m-0 ml-4">
                        I will complete all visits
                      </label>
                    </div>
                  )}
              </>
            )}
            <ContractorServiceAgreement
              formData={{ ...staffDetail, ...currentUser }}
            />
            {console.log(currentUser, "currentUser")}
            {/* <h2>Contract Of Service</h2>

            
            <p className="text-justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
              <br /> Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p> */}
            {/* </div> */}

            <div className=" d-flex align-item-center gap-2 mb-5">
              <Field
                type="checkbox"
                name="termsandconditions"
                className="form-select select-box-1"
              >
                {({ input, meta }) => (
                  <div className="d-flex flex-column">
                    <div className=" d-flex align-items-center gap-3 mb-3">
                      <input type="checkbox" {...input} className="tcch" />

                      <label className="tclb m-0">
                        I agree to Terms and Conditions
                      </label>
                    </div>

                    {meta.touched && meta.error && (
                      <span className="alert alert-danger">{meta.error}</span>
                    )}
                  </div>
                )}
              </Field>
            </div>

            <div style={{ display: "flex" }}>
              <button className="th-sttafing-btn ">Submit</button>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

export default AcceptModal;
