import fluvaccination from './pdfForms/fluvaccination.docx.pdf'
import hepatitisB from './pdfForms/hepatitisdeclination.docx.pdf'
import covidrecord from './pdfForms/covidrecord.docx.pdf'
import confidentiality from './pdfForms/confidentiality.docx.pdf'
import elderAbuseReporting from './pdfForms/elderAbuseReporting.docx.pdf'
import harassment from './pdfForms/harassment.docx.pdf'
import infectioncontrol from './pdfForms/infectionControl.docx.pdf'
import compliance from './pdfForms/compliance.docx.pdf'

export const pdfFormImport = {
    confidentiality: confidentiality,
    elderAbuseReporting: elderAbuseReporting,
    harassment: harassment,
    hepatitisB: hepatitisB,
    fluvaccination: fluvaccination,
    covidrecord: covidrecord,
    infectioncontrol: infectioncontrol,
    compliance: compliance
}

export const onboardingFormTypes = {
    confidentiality: 'confidentiality',
    compliance: 'compliance',
    elderAbuseReporting: 'elderAbuseReporting',
    harassment: 'harassment',
    hepatitisB: 'hepatitisB',
    fluvaccination: 'fluvaccination',
    covidrecord: 'covidrecord',
    infectioncontrol: "infectioncontrol"
}