import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import {
  removeLocation,
  therapistLocationState,
} from "../../Redux/slices/therapistSignupSlice";
import CoverageAreas from "../Therapist/signup/CoverageAreas";
import { currentRole, defaultStatus } from "../../utils/auth";
import { HiPlus } from "react-icons/hi";
import { setLoader } from "../../Redux/slices/authSlice";
import { Field } from "react-final-form";

const CoverageAreaView = (props) => {
  const rejectedCoverArea = useSelector(
    (e) => e.therapistSigunpSlice.rejectedCoverArea
  );
  const { handleStep, fromArea, setRejArea } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const [open, setOpen] = useState(false);
  const [selectedMap, setSelectedMap] = useState(null);

  const profileDetail = useSelector(
    (e) => e.therapistSigunpSlice.therapistLocationData
  );
  const userLocationData = useSelector(
    (e) => e.therapistSigunpSlice.coverageArea
  );

  // const therapistLocationData = useSelector(
  //   (e) => e.therapistSigunpSlice.therapistLocationData
  // );
  // let userLocationData = therapistLocationData?.coverageAreas
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);

  useEffect(() => {
    initialMap();
  }, [profileDetail, userLocationData]);

  function findCenter(coordinates) {
    if (coordinates.length === 0) {
      return null;
    }

    // Initialize variables for highest and lowest latitude and longitude
    let minLat = coordinates[0].latitude;
    let maxLat = coordinates[0].latitude;
    let minLng = coordinates[0].longitude;
    let maxLng = coordinates[0].longitude;

    // Iterate through the coordinates array and find the highest and lowest latitude and longitude
    for (let i = 1; i < coordinates.length; i++) {
      const coord = coordinates[i];
      if (coord.latitude < minLat) {
        minLat = coord.latitude;
      } else if (coord.latitude > maxLat) {
        maxLat = coord.latitude;
      }

      if (coord.longitude < minLng) {
        minLng = coord.longitude;
      } else if (coord.longitude > maxLng) {
        maxLng = coord.longitude;
      }
    }

    // Calculate the average latitude and longitude of highest and lowest points
    const avgLat = (minLat + maxLat) / 2;
    const avgLng = (minLng + maxLng) / 2;

    // Return the center coordinates
    return { lat: avgLat, lng: avgLng };
  }

  var polyOptions = {
    strokeWeight: 1,
    strokeColor: "#eb3d3d",
    fillOpacity: 0.45,
    fillColor: "#e47576",
  };
  function createAndAppendElement(containerId, id) {
    // Create label element
    const label = document.createElement("label");
    label.className = "container-card";
    label.style.height = "100%";

    // Create Field wrapper (simulated, as we're not using a form library here)
    const fieldWrapper = document.createElement("div");

    // Create input element
    const input = document.createElement("input");
    input.type = "checkbox";
    input.name = `projectZipCode_${id}`;
    input.className = "input-therepist2 mapcheckbox";

    // Add event listener for onChange
    input.addEventListener("change", (e) => {
      // Handle the change event
      // Call your handleStep function here if needed
      // handleStep(e, 'area');
    });

    // Append input to the field wrapper
    fieldWrapper.appendChild(input);

    // Create span element
    const span = document.createElement("span");
    span.className = "checkmark1";

    // Append elements to the label
    label.appendChild(fieldWrapper);
    label.appendChild(span);

    // Append label to the container
    const container = document.getElementById(containerId);
    container.appendChild(label);
  }

  function createCheckboxElement(data, index, container) {
    // parentEl = document.createElement("div");
    // parentElFirstChildContainer = document.createElement("div");
    // parentElFirstChildContainer.setAttribute("class", `childContainer`);
    // parentElFirstChildContainer.setAttribute("id", `childContainers${index}`);
    parentEl = document.createElement("label");
    parentEl.style.marginRight = "50px";
    // Create Field wrapper (simulated, as we're not using a form library here)
    const fieldWrapper = document.createElement("div");

    // Create input element
    const input = document.createElement("input");
    input.type = "checkbox";
    input.name = `projectZipCode_${data.id}`;
    input.className = "input-therepist2 mapcheckbox";
    input.checked =
      rejectedCoverArea && rejectedCoverArea.indexOf(data.id) > -1
        ? true
        : false;

    // Add event listener for onChange
    input.addEventListener("change", (e) => {
      handleStep(data, "area");
      setRejArea({
        name: `projectZipCode_${data.id}`,
        value: e.target.checked,
        id: data.id,
      });
    });

    // Append input to the field wrapper
    fieldWrapper.appendChild(input);

    // Create span element
    const span = document.createElement("span");
    span.className = "checkmark1";

    // Append elements to the label
    parentEl.appendChild(fieldWrapper);
    parentEl.appendChild(span);

    // parentElFirstChildContainer.append(parentEl);
    // parentEl.append(parentElFirstChildContainer);

    // const el = document.createElement("div");
    // el.setAttribute("id", `map${index}`);
    // el.setAttribute("class", "map-box");
    // parentEl.append(el);
    container.appendChild(parentEl);
  }
  const initialMap = () => {
    if (userLocationData?.length) {
      function removeAllChildNodes(parent) {
        if (parent.firstChild) {
          while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
          }
        }
      }

      let container = document.getElementById("map_parent");
      removeAllChildNodes(container);

      userLocationData &&
        userLocationData &&
        userLocationData.length > 0 &&
        userLocationData.map((e, i) => {
          locationEditContainer(e, i, container);
          if (fromArea === "rejecttherapist") {
            createCheckboxElement(e, i, container);
          }
          locationDeleteConatiner(e, i, container);
          // createAndAppendElement("myContainer", container);
          const el = document.createElement("div");
          el.setAttribute("id", `map${i}`);
          el.setAttribute("class", "map-box m-0 p-1 mt-3");
          el.onclick = function () {
            setSelectedMap(e);
          };

          const map = new window.google.maps.Map(
            document.getElementById(`map${i}`),
            {
              zoom: Number(e.mapZoomlevel) ? Number(e.mapZoomlevel) - 1 : 15,
              // center: e.coverageAddress[0],
              center: findCenter(e.coverageAddress),
              mapTypeId: window.google.maps.MapTypeId.ROADMAP,
              disableDefaultUI: true,
              zoomControl: true,
            }
          );

          let apiCordsData;
          apiCordsData = new window.google.maps.Polyline({
            path: e.coverageAddress,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            editable: false,
          });
          apiCordsData.setMap(map);
          var path = apiCordsData.getPath();
          apiCordsData = new window.google.maps.Polygon({
            map: map,
            path: path,
          });

          apiCordsData.setOptions(polyOptions);
          if (value === false) {
            dispatch(therapistLocationState(true));
            var bounds = new window.google.maps.LatLngBounds();
            for (var j = 0; j < e.coverageAddress.length; j++) {
              bounds.extend(e.coverageAddress[j]);
            }
            map.fitBounds(bounds);
            dispatch(setLoader({ loading: false }));
          }
        });
    }
  };

  let parentEl;
  let parentElFirstChildContainer;
  let deleteButton;
  function locationEditContainer(data, index, container) {
    if (
      currentUser?.Role?.key === currentRole.supervisingtherapist ||
      currentUser?.Role?.key === currentRole.assistanttherapist
    ) {
      parentEl = document.createElement("div");
      parentElFirstChildContainer = document.createElement("div");
      parentElFirstChildContainer.setAttribute("class", `childContainer`);
      parentElFirstChildContainer.setAttribute("id", `childContainers${index}`);
      deleteButton = document.createElement("p");

      const location = document.createElement("p");
      const editButton = document.createElement("p");
      editButton.addEventListener("click", () => handleOpen(data));
      location.innerHTML = data.name;
      editButton.innerHTML = "Edit";
      deleteButton.innerHTML = "Delete";
      editButton.classList.add("cursor-pointer");
      parentElFirstChildContainer.append(location);
      parentElFirstChildContainer.append(editButton);
      parentElFirstChildContainer.append(deleteButton);
      parentEl.append(parentElFirstChildContainer);
      editButton.style.marginLeft = "auto";
      editButton.style.paddingRight = "10px";
      const el = document.createElement("div");
      el.setAttribute("id", `map${index}`);
      el.setAttribute("class", "map-box");
      parentEl.append(el);
      container.appendChild(parentEl);
    } else {
      const el = document.createElement("div");
      el.setAttribute("id", `map${index}`);
      el.setAttribute("class", "map-box");
      container.appendChild(el);
    }
  }

  function deleteMap(index, data) {
    const mapElement = document.getElementById(`map${index}`);
    const childContainer = document.getElementById(`childContainers${index}`);

    if (childContainer) {
      dispatch(therapistLocationState(true));
      childContainer.remove();
    }
    if (mapElement) {
      mapElement.remove();
    }

    if (userLocationData) {
      const payload = userLocationData?.filter((e) => {
        return e?.id !== data?.id;
      });
      dispatch(removeLocation(payload));
    }
  }

  function locationDeleteConatiner(data, index, container) {
    if (
      currentUser?.Role?.key === currentRole.supervisingtherapist ||
      currentUser?.Role?.key === currentRole.assistanttherapist
    ) {
      deleteButton.addEventListener("click", () => deleteMap(index, data));
      deleteButton.classList.add("cursor-pointer");
      // container.appendChild(deleteButton);
    }
  }

  function handleClose() {
    setOpen(false);
  }

  function handleOpen(e, type) {
    if (type === "add") {
      setValue(false);
      setOpen(true);
    } else {
      setValue(e);
      setOpen(true);
    }
  }

  window.initMap = initialMap;

  const deleteSelectedMap = () => {
    const payload = userLocationData.filter((e) => {
      return (
        JSON.stringify(e.coverageAddress) !==
        JSON.stringify(selectedMap.coverageAddress)
      );
    });

    dispatch(removeLocation(payload));
    setSelectedMap(null);
  };

  return (
    <>
      {props.loader && <h3>Fetching your coverage Areas...</h3>}
      <div id="map_parent" className="map-flex p-0"></div>

      {userLocationData &&
        userLocationData?.length > 0 &&
        currentUser?.Role?.key !== "admin" &&
        currentUser?.Role?.key !== "superadmin" && (
          <div className="box-icon">
            <HiPlus className="Hiplus" onClick={(e) => handleOpen(e, "add")} />
          </div>
        )}

      {selectedMap && (
        <button className="ad-btn-back" onClick={deleteSelectedMap}>
          Delete
        </button>
      )}

      {open && (
        <CoverageAreas open={open} onClose={handleClose} value={value} />
      )}
    </>
  );
};

export default CoverageAreaView;
