import React, { useState } from "react";
import { CommonHead2 } from "../../../components/common/CommonHead2";
import { Row, Col } from "bootstrap-4-react";
import Active from "../../../images/AdminDashboard/active_pending_referrals_icon.svg";
import Staffed from "../../../images/AdminDashboard/referrals_staffed_this_week_icon.svg";
import Referrals from "../../../images/AdminDashboard/total_referrals_this_week_icon.svg";
import { currentRole, defaultStatus, encodedData } from "../../../utils/auth";
import { MyModal2 } from "../../modals/MyModal2";
import HomeList from "./List";
import PendingDocumentation from "./pendingDocumentation";
import DocumentationCorrected from "./documentationCorrected";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getStaffList,
  getStaffingDetailsById,
} from "../../../Redux/actions/staffing";
import { AiOutlineDollar } from "react-icons/ai";
import { BiCalendarCheck } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCaseManagementStatus,
  getCaseManagementTabCount,
  getDocList,
  getHomeCountData,
  getStaffDocumentation,
  updateDocumenationStatus,
} from "../../../Redux/actions/common";
import IncompletePendingNotes from "../../../components/IncompletePendingNotes";
import PendingModal from "../../modals/PendingModal";
import EpisodeModal from "../../Patients/EpisodeModal";
import NeedCorrection from "../../../components/NeedCorrection";
import { setLoader } from "../../../Redux/slices/authSlice";
import Loader from "../../../components/Loader";
import {
  getPatientDocs,
  getPatientDocsDetails,
  updateDocumentDate,
} from "../../../Redux/actions/patientGetDocTypes";
import {
  minimumDate,
  staffFormTypesConstants,
  visitStatus,
} from "../../../utils/helper";

const TherapistDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const userRoleStatusCount = useSelector(
  //   (e) => e.staffingSlice.therapistStaffRoleStatusCount
  // );
  const userRoleStatusCount = useSelector((e) => e.commonSlice.totaldocCount);

  const [menuItem, setMenuItem] = useState("patientneedscheduling");
  const [show, setshow] = useState(false);
  const params = useParams();
  const isLoading = useSelector((e) => e.commonSlice.isLoading);
  const isModalLoading = useSelector((e) => e.staffingSlice.isModalLoading);
  const [documentDetail, setDocumentDetail] = useState({});
  const [staffUserId, setStaffUserId] = useState(null);
  const [showEpisodeModal, setShowEpisodeModal] = useState(false);
  const [confirmationModal, setconfirmationModal] = useState(false);
  const [assessmentVisitModal, setAssessmentVisitModal] = useState(false);
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const userData = useSelector((e) => e.staffingSlice.therapistStaffList);
  const homeTabCardData = useSelector((e) => e.commonSlice.homeCount);
  const userList = useSelector((e) => e.commonSlice.docList);
  const caseCount = useSelector((e) => e.commonSlice.casemgmttabcount);
  const caseListData = useSelector((e) => e.commonSlice.caseList);
  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);
  const staffDocument = useSelector((e) => e.commonSlice.staffDocument);

  const totalBillableAmount = homeTabCardData?.invoiceCount?.reduce(
    (Sum, item) => {
      return Sum + (item.billableAmount || 0);
    },
    0
  );
  let myTabStatus = {
    patientneedscheduling: defaultStatus.patientneedscheduling,
    // staffneedscheduling: defaultStatus.staffneedscheduling,
    incomplete: defaultStatus.pendingnotes,
    needcorrection: defaultStatus.needcorrection,
    // missvisitneeded: defaultStatus.missvisitneeded,
    missvisitneeded: defaultStatus.missvisitneeded,
    frequencyupdateneeded: defaultStatus.frequencyupdateneeded,
  };
  useEffect(() => {
    if (params.tab) {
      setMenuItem(params?.tab);
    } else if (currentUser && currentUser.Role) {
      setMenuItem(menu[0].key);
    } else {
      dispatch(setLoader({ loading: true }));
    }
    // dispatch(
    //   getCaseManagementStatus({
    //     pageSize,
    //     pageNo: 1,
    //     tab: myTabStatus[params.tab ? params.tab : menuItem],
    //   })
    // );
    dispatch(getCaseManagementTabCount());
    tabActions[menuItem]();
    dispatch(getHomeCountData());
    dispatch(getDocList());
  }, [currentUser]);

  const statusBasedOnTab = (tabKey) => {
    let status = defaultStatus.staffedreferral;
    switch (tabKey) {
      case "pendingdocumentation":
        status = defaultStatus.pendingdocumentation;
        break;
      case "documentationtobecorrected":
        status = defaultStatus.documentationtobecorrected;
        break;
      case "missvisitneeded":
        status = defaultStatus.missvisitneeded;
        break;
      case "frequencyupdateneeded":
        status = defaultStatus.frequencyupdateneeded;
        break;
      case "patientneedingscheduling":
        status = defaultStatus.staffedreferral;
        break;
      default:
        status = defaultStatus.staffedreferral;
        break;
    }
    return status;
  };
  const setTabMenus = (tab1) => {
    // document.getElementById("searchField").value = "";
    // setSearchval("");
    dispatch(setLoader({ loading: true }));
    setMenuItem(tab1);
    setPageNo(1);
    // dispatch(
    //   getCaseManagementStatus({
    //     pageSize,
    //     pageNo: 1,
    //     tab: myTabStatus[tab1],
    //   })
    // );
    dispatch(getCaseManagementTabCount());
    tabActions[tab1]();
  };
  const handleClose = () => setshow(false);
  // const handleShow = (row) => { setshow(true) };
  const handleClick = (rowData) => {
    if (rowData?.staffUserId) {
      navigate(`/patientdetails/${encodedData(rowData?.staffUserId)}`);
    } else if (rowData?.userId) {
      navigate(`/patientdetails/${encodedData(rowData?.userId)}`);
    } else if (rowData?.id) {
      navigate(`/patientdetails/${encodedData(rowData?.id)}`);
    }
  };
  const updateConfirmDate = (values, openModal) => {
    let payload = { date: values?.evaluationVisitDate, id: documentDetail?.id };
    dispatch(updateDocumentDate(payload)).then((res) => {
      if (res && res.payload && res.payload.success) {
        dispatch(
          getStaffDocumentation({
            staffUserId: documentDetail.staffUserId,
          })
        );
        dispatch(getPatientDocs({ episodeDocumentId: documentDetail?.id }));
        statusHandler(visitStatus.scheduled, documentDetail);
        if (confirmationModal) setconfirmationModal(false);
        setShowEpisodeModal(true);
      }
      return { documentDetail };
    });
  };
  const statusHandler = (status, row) => {
    let temp = {};
    if (row.date) {
      let price = 0;
      if (staffDetail) {
        price = staffDetail.userRates.find((item) =>
          item.rateTabDetail.displayName
            .toLowerCase()
            .includes(row.episodeVisit.displayName.toLowerCase())
        )?.price;
      }
      temp.id = row.id;
      temp.episodeId = Number(row.episodeId);
      temp.status = status;
      temp.staffUserId = documentDetail.staffUserId;
      if (status === defaultStatus.completed) temp.price = Number(price);
      dispatch(updateDocumenationStatus(temp)).then((e) => {
        if (e.payload.success) {
          dispatch(
            getStaffDocumentation({
              staffUserId: documentDetail.staffUserId,
            })
          );
        }
      });
    }
  };
  function handleShow(rowData) {
    setStaffUserId(rowData.staffUserId);
    dispatch(getStaffingDetailsById(rowData.episodeStaff.staffUserId));
    dispatch(
      getStaffDocumentation({ staffUserId: rowData.episodeStaff.staffUserId })
    );
    setDocumentDetail({
      ...rowData,
      episodeId: rowData.episodeId,
      staffUserId: rowData.episodeStaff.staffUserId,
    });
    dispatch(
      getPatientDocsDetails({
        episodeDocumentId: rowData?.id,
      })
    ).then((res) => {
      if (res.payload.success) {
        if (rowData?.userData.firstName == staffFormTypesConstants.Assessment) {
          setshow(true);
        } else if (res.payload.data) {
          setshow(true);
        } else {
          setshow(true);
        }
      }
    });
  }
  const getRoleDetailCount = (status) => {
    let count = 0;
    if (userRoleStatusCount && userRoleStatusCount.length > 0) {
      let user = userRoleStatusCount.find((item) => item.Status.key === status);
      if (user && user.userCount) {
        count = user.userCount;
      }
    }
    return Number(count);
  };

  const tabActions = {
    patientneedscheduling: (event) => {
      event && setPageNo(event.selected + 1);
      dispatch(
        getCaseManagementStatus({
          pageSize,
          pageNo: event ? event.selected + 1 : 1,
          tab: defaultStatus.patientneedscheduling,
        })
      );
    },
    incomplete: (event) => {
      event ? setPageNo(event.selected + 1) : setPageNo(1);
      dispatch(
        getDocList({
          pageSize: pageSize,
          pageNo: event ? event.selected + 1 : 1,
          status: defaultStatus.pendingnotes,
        })
      );
    },
    needcorrection: (event) => {
      event ? setPageNo(event.selected + 1) : setPageNo(1);
      dispatch(
        getDocList({
          pageSize: pageSize,
          pageNo: event ? event.selected + 1 : 1,
          status: defaultStatus.needcorrection,
        })
      );
    },
    missvisitneeded: (event) => {
      event ? setPageNo(event.selected + 1) : setPageNo(1);
      dispatch(
        getDocList({
          pageSize: pageSize,
          pageNo: event ? event.selected + 1 : 1,
          status: defaultStatus.missvisitneeded,
        })
      );
    },
    frequencyupdateneeded: () => {},
  };
  useEffect(() => {
    dispatch(getHomeCountData());
  }, []);
  const getHomeCardCount = (type, role, status) => {
    let count = 0;
    if (
      homeTabCardData &&
      homeTabCardData[type] &&
      homeTabCardData[type].length > 0
    ) {
      homeTabCardData[type].map((e) => {
        if (
          status &&
          e.Status.key === status &&
          (!role || e.Role.key === role)
        ) {
          count += Number(e.userCount);
        } else if (!status && (!role || e.Role.key === role)) {
          count += Number(e.userCount);
        }
      });
    }
    return count;
  };
  const priorVisitDate = (id) => {
    const currentDate = new Date();
    let priorVisit = 0;
    let date = currentDate.toISOString().split("T")[0];
    if (
      staffDocument &&
      staffDocument?.length > 0 &&
      staffDocument[0]?.episodeDocumentations.length > 0
    ) {
      const index = staffDocument[0]?.episodeDocumentations.findIndex(
        (element) => element.id === id
      );
      if (index === -1) {
        priorVisit = 0;
      } else if (index === 0) {
        priorVisit = 0;
      } else {
        priorVisit = index - 1;
      }
      if (index === 0) {
        date = minimumDate(
          staffDetail &&
            staffDetail.staffInitialVisit.length > 0 &&
            staffDetail.staffInitialVisit[0].anticipatedSocDate
        );
      } else {
        date = minimumDate(
          staffDocument[0]?.episodeDocumentations[priorVisit]["date"]
        );
      }
    }
    return date;
  };
  const menu = [
    {
      key: "patientneedscheduling",
      label: "Patients Needing Scheduling",
      data: caseListData,
      count:
        caseCount && caseCount.patientneedscheduling
          ? caseCount?.patientneedscheduling
          : "00",
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <pre className="d-flex gap-3 flex-wrap mb-0">
                  <button
                    className=" update-btn view-btn tr-btn ml-auto  mt-3 mb-3"
                    onClick={() => {
                      handleClick(rowData);
                    }}
                  >
                    Review
                  </button>
                </pre>
              </td>
            </>
          ),
        },
      ],
      modals: (
        <EpisodeModal
          visitDetail={documentDetail}
          episodeModalClose={() => setShowEpisodeModal(false)}
          updateConfirmDate={updateConfirmDate}
          staffUserId={documentDetail.staffUserId}
          statusHandler={statusHandler}
          priorVisitDate={priorVisitDate}
        />
      ),
      dialogClassName: "modal-lg",
      modalClass: "Main_modal_1",
      columns: ["PATIENT", "MR#", "ADDRESS", "DISCIPLINES", "CASE STATUS"],
      columnsToHide: ["image"],
    },
    {
      key: "incomplete",
      label: "Pending Documentation",
      count:
        getRoleDetailCount(defaultStatus.pendingnotes) +
        getRoleDetailCount(defaultStatus.needcosigned),
      data: userList,
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className=" update-btn view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleShow(rowData);
                }}
              >
                Review
              </button>
            </td>
          ),
        },
      ],
      modals: (
        <EpisodeModal
          visitDetail={documentDetail}
          episodeModalClose={() => setShowEpisodeModal(false)}
          updateConfirmDate={updateConfirmDate}
          staffUserId={documentDetail.staffUserId}
          statusHandler={statusHandler}
          priorVisitDate={priorVisitDate}
        />
      ),
      columns: ["PATIENT NAME", "VISIT TYPE", "DATE OF VISIT"],
      columnsToHide: ["image"],
      modalClass: "ApprovM",
    },
    {
      label: "Documentation to be Corrected",
      key: "needcorrection",
      count: getRoleDetailCount(defaultStatus.needcorrection),
      data: userList,
      noData: "No data available",
      isTableShow: true,
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className="update-btn view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleShow(rowData);
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
      modals: <PendingModal />,
      columns: ["PATIENT NAME", "VISIT TYPE", "DATE OF VISIT"],
      columnsToHide: ["image"],
      modalClass: "ApprovM",
    },
    {
      key: "missvisitneeded",
      label: "Missed Visits Needed",
      count: getRoleDetailCount(defaultStatus.missvisitneeded),
      data: userList,
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className=" update-btn view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  // handleClick(rowData);
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
      columns: ["PATIENT NAME", "VISIT TYPE", "WEEK OF VISIT"],
      columnsToHide: ["image", "lastName"],
      modalClass: "PenddingM modalstart",
    },
    {
      label: "Frequency Update Needed",
      count: "0",
      key: "frequencyupdateneeded",
      noData: "No data available",
      isTableShow: false,
    },
  ];

  const myActions = menu.find((ele) => ele.key === menuItem);
  const handlePageClick = (event) => {
    dispatch(setLoader({ loading: true }));
    tabActions[menuItem](event);
  };

  const getvisitCount = () => {
    let count = 0;
    let notinclude = ["completed", "tobescheduled"];
    if (
      homeTabCardData &&
      homeTabCardData.StaffVisitByTherapist &&
      homeTabCardData.StaffVisitByTherapist.length > 0
    ) {
      count = homeTabCardData.StaffVisitByTherapist.reduce((sum, item) => {
        if (!notinclude.includes(item.Status.key)) {
          return sum + parseInt(item.userCount, 10);
        }
        return sum;
      }, 0);
    }
    return count;
  };
  return (
    <>
      {isLoading && <Loader />}

      <div c ssName="home-lists  home-lists-1 ">
        <div className="col-lg-12">
          <div className="title_home_list d-flex align-items-center justify-content-between pr-5 mb-4 ">
            <h3 className="dash-head">Virtual Assistant</h3>
          </div>

          <Row className="row1">
            {getHomeCardCount("staffCount", "", defaultStatus.pending) !==
              0 && (
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={6}
                className="padding-bottom therapist-d-1 d-flex"
              >
                <div
                  className="home-list list1 list-2 h-auto padding-therapist-tabs"
                  onClick={() => navigate("/findpatients/availablereferrals")}
                >
                  <div className="home-list-icon">
                    <img src={Active} className="top-img-admin" alt="" />
                  </div>
                  <div className="home-list-content">
                    <h4 className="mb-5">Available Referrals</h4>
                    <span className="mt-3 d-flex align-content-end">
                      {getHomeCardCount(
                        "staffCount",
                        "",
                        defaultStatus.pending
                      ) || 0}
                    </span>
                  </div>
                </div>
              </Col>
            )}
            {getHomeCardCount("docCount", "", defaultStatus.scheduled) !==
              0 && (
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={6}
                className="padding-bottom therapist-d-1 d-flex"
              >
                <div
                  className="home-list list2 list-2 h-auto padding-therapist-tabs"
                  onClick={() => navigate("/documentation/scheduled")}
                >
                  <div className="home-list-icon">
                    <BiCalendarCheck fill="#da9008" />
                  </div>
                  <div className="home-list-content">
                    <h4 className=""> Visits Remaining This week</h4>
                    <span className="mt-3 d-flex align-content-end">
                      {getvisitCount() || 0}
                    </span>
                  </div>
                </div>
              </Col>
            )}

            {/* {totalBillableAmount !== 0 && ( */}
            {totalBillableAmount
              ? totalBillableAmount !== 0 && (
                  <Col
                    xl={4}
                    lg={4}
                    md={12}
                    className="padding-bottom therapist-d-1 d-flex"
                  >
                    <div
                      className="home-list list3 list-2 padding-agency-toptabs"
                      onClick={() => navigate("/accounting/invoice")}
                    >
                      <div className="home-list-icon">
                        <AiOutlineDollar fill="#3ab746" />
                      </div>
                      <div className="home-list-content">
                        <h4 className="">Anticipated Pay</h4>
                        <p className="m-0 flex-wrap d-flex rate-p align-content-center gap-3">
                          ${totalBillableAmount || 0}
                          <span className="m-0">04/18-4/24/2022</span>
                        </p>
                      </div>
                    </div>
                  </Col>
                )
              : ""}
            {/* )} */}
          </Row>
        </div>
      </div>
      <div className="pl-5 pr-5 mt-5">
        <CommonHead2
          css="col1"
          data={menu}
          setMenu={setTabMenus}
          menuItem={menuItem}
        />
      </div>
      <div className="container-fluid pl-5 pr-5 table-container-1">
        <HomeList
          pageNo={pageNo}
          data={menu}
          setPageNo={setPageNo}
          myActions={myActions}
          tabStatus={statusBasedOnTab}
          isLoading={isLoading}
          handlePageClick={handlePageClick}
        />
      </div>

      {show && (
        <MyModal2
          data={myActions.modals}
          show={show}
          handleClose={handleClose}
          modalClass={myActions.modalClass}
        />
      )}
    </>
  );
};

export default TherapistDashboard;
