import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { locationData } from "../../../Redux/slices/therapistSignupSlice";
import {
  getTherapistLocationData,
  updateCoverageArea,
} from "../../../Redux/actions/therapist";
import { toast } from "react-toastify";
import { getUserDetailParams } from "../../../utils/helper";
import Loader from "../../../components/Loader";

let userCordinates = [];
var map;
const CoverageAreas = (props) => {
  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const dispatch = useDispatch();
  let selectedShape;
  const [loading, setLoading] = useState(true);
  const [nameError, setNameError] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(15);
  const [isShape, setIsShape] = useState(false);
  const coverageAreas = useSelector((e) => e.therapistSigunpSlice.coverageArea);
  const locationLoader = useSelector(
    (e) => e.therapistSigunpSlice.locationLoader
  );
  const userLocationData = useSelector(
    (e) => e.therapistSigunpSlice.coverageArea
  );
  const [locationValue, setLocationValue] = useState(
    props?.value && props?.value?.name ? props.value.name : ""
  );

  useEffect(() => {
    if (coverageAreas && coverageAreas.length > 0) {
      setZoomLevel(coverageAreas[0]?.mapZoomlevel);
    }
  }, [coverageAreas]);

  useEffect(() => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      function (position) {
        initMap(position.coords.latitude, position.coords.longitude);
      },
      function errorCallback(error) {
        if (error && error.code && error.code === 1) {
          initMap(
            PrefillDetails?.userDetail?.latitude,
            PrefillDetails?.userDetail?.longitude
          );
        }
      },
      { enableHighAccuracy: true }
    );
  }, [PrefillDetails, coverageAreas]);

  function setSelection(shape) {
    selectedShape = shape;
    setIsShape(true);
  }
  function deleteSelectedShape() {
    if (selectedShape) {
      selectedShape.setMap(null);
      userCordinates = [];
      selectedShape = null;
      setIsShape(false);
    }
  }

  function disable() {
    map.setOptions({
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: false,
    });
  }

  function enable() {
    map.setOptions({
      draggable: true,
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true,
    });
  }

  var polyOptions = {
    strokeWeight: 1,
    strokeColor: "#eb3d3d",
    fillOpacity: 0.45,
    fillColor: "#e47576",
  };

  function drawFreeHand() {
    if (!selectedShape) {
      var poly = new window.google.maps.Polyline({
        map: map,
        clickable: false,
      });
      poly.setOptions(polyOptions);
      var move = window.google.maps.event.addListener(
        map,
        "mousemove",
        function (e) {
          poly.getPath().push(e.latLng);
        }
      );

      window.google.maps.event.addListenerOnce(map, "mouseup", function (e) {
        window.google.maps.event.removeListener(move);
        var path = poly.getPath();
        poly.setMap(null);
        poly = new window.google.maps.Polygon({
          map: map,
          path: path,
        });

        poly.setOptions(polyOptions);

        let latLng;
        const cordinateValues = [];
        for (var i = 0; i < poly.getPath().getLength(); i++) {
          var coordStr = "";
          coordStr += poly.getPath().getAt(i).toUrlValue(10) + ";";
          latLng = coordStr + latLng;
        }

        if (latLng) {
          latLng
            .split(";")
            .slice(0, -1)
            .map((f) => {
              const obj = {};
              obj.lat = Number(f.split(",")[0]);
              obj.lng = Number(f.split(",")[1]);
              cordinateValues.push(obj);
            });

          userCordinates = cordinateValues;
        }

        setSelection(poly);
        window.google.maps.event.addListener(poly, "click", function () {
          setSelection(poly);
        });

        window.google.maps.event.clearListeners(map.getDiv(), "mousedown");
        enable();
      });
    }
  }

  function findCenter(coordinates) {
    if (coordinates.length === 0) {
      return null;
    }

    // Initialize variables for highest and lowest latitude and longitude
    let minLat = coordinates[0].latitude;
    let maxLat = coordinates[0].latitude;
    let minLng = coordinates[0].longitude;
    let maxLng = coordinates[0].longitude;

    // Iterate through the coordinates array and find the highest and lowest latitude and longitude
    for (let i = 1; i < coordinates.length; i++) {
      const coord = coordinates[i];
      if (coord.latitude < minLat) {
        minLat = coord.latitude;
      } else if (coord.latitude > maxLat) {
        maxLat = coord.latitude;
      }

      if (coord.longitude < minLng) {
        minLng = coord.longitude;
      } else if (coord.longitude > maxLng) {
        maxLng = coord.longitude;
      }
    }

    // Calculate the average latitude and longitude of highest and lowest points
    const avgLat = (minLat + maxLat) / 2;
    const avgLng = (minLng + maxLng) / 2;

    // Return the center coordinates
    return { lat: avgLat, lng: avgLng };
  }

  function initMap(lat, lng) {
    const latlng = lat && lng && { lat: lat, lng: lng };
    if (props?.value && props.value.coverageAddress) {
      map = new window.google.maps.Map(document.getElementById("map"), {
        zoom: props.value.mapZoomlevel ? Number(props.value.mapZoomlevel) : 15,
        // center: props?.value?.coverageAddress[0],
        center: findCenter(props?.value?.coverageAddress),
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        zoomControl: true,
      });

      let apiCordsData;
      apiCordsData = new window.google.maps.Polygon({
        paths: props?.value?.coverageAddress,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        editable: false,
      });
      apiCordsData.setMap(map);
      setSelection(apiCordsData);
    } else {
      map = new window.google.maps.Map(document.getElementById("map"), {
        zoom: zoomLevel,
        center: latlng && latlng,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        zoomControl: true,
      });
    }

    window.google.maps.event.addListenerOnce(map, "tilesloaded", function () {
      setLoading(false);
    });

    map.addListener("zoom_changed", () => {
      setZoomLevel(map.getZoom());
    });

    document.getElementById("add").addEventListener("click", (e) => {
      e.preventDefault();

      disable();
      e.target.disabled = true;
      window.google.maps.event.addDomListener(
        map.getDiv(),
        "mousedown",
        function (e) {
          drawFreeHand();
        }
      );
    });

    window.google.maps.event.addDomListener(
      document.getElementById("delete-button"),
      "click",
      deleteSelectedShape
    );
  }

  const checkNameAvailibility = (name) => {
    let isNamed = false;
    if (userLocationData && userLocationData.length > 0) {
      isNamed = userLocationData.find(
        (item) =>
          item.name.replace(/\s/g, "").toLowerCase().trim() ==
          name.replace(/\s/g, "").toLowerCase().trim()
      );
    }
    return isNamed;
  };

  const setNewName = (name) => {};
  const handleSubmit = () => {
    const payload = {};
    if (!isShape) {
      toast.info("Please select your coverage area.");
      return;
    }

    payload["name"] = locationValue
      ? locationValue
      : `Location ${coverageAreas.length + 1}`;

    if (props?.value) {
      if (
        userCordinates &&
        userCordinates.length === 0 &&
        props?.value.name === locationValue
      ) {
        toast.info("Delete current location and add new one to continue.");
      } else if (
        userCordinates &&
        userCordinates.length === 0 &&
        props?.value.name !== locationValue
      ) {
        payload["coverageAddress"] = props?.value.coverageAddress;
        payload.userId = props?.value?.userId;
        payload.id = props?.value?.id;
        payload.mapZoomlevel = zoomLevel;
        dispatch(updateCoverageArea(payload)).then((e) => {
          if (e.payload.data.success) {
            userCordinates = [];
            dispatch(
              getTherapistLocationData(
                `${props?.value?.userId}?${getUserDetailParams.coverageAreas}=true`
              )
            );
            props.onClose();
          }
        });
      } else {
        payload.userId = props?.value?.userId;
        payload.id = props?.value?.id;
        payload["coverageAddress"] = userCordinates;
        payload.mapZoomlevel = zoomLevel;

        dispatch(updateCoverageArea(payload)).then((e) => {
          if (e.payload.data.success) {
            userCordinates = [];
            dispatch(
              getTherapistLocationData(
                `${props?.value?.userId}?${getUserDetailParams.coverageAreas}=true`
              )
            );
            props.onClose();
          }
        });
      }
    } else {
      let isNamed = false;
      if (payload["name"]) {
        isNamed = checkNameAvailibility(payload["name"]);
        if (!locationValue && payload["name"] == isNamed?.name) {
          payload["name"] = `Location ${coverageAreas.length + 2}`;
          isNamed = checkNameAvailibility(payload["name"]);
        }
      } else {
        isNamed = false;
      }
      payload["coverageAddress"] = userCordinates;
      payload.mapZoomlevel = zoomLevel;
      if (!isNamed) {
        dispatch(locationData(payload));
        props.onClose();
      } else {
        toast.error("Please enter another name.");
      }
    }
  };

  return (
    <>
      <Modal
        show={props.open}
        onHide={props.onClose}
        className="modal_parent w-100 customModalparent"
      >
        {loading && <Loader />}
        {loading && (
          <div className="coverage-area-loading">
            <h3>Fetching...</h3>
          </div>
        )}
        <div className="container-fluid">
          <div className="col-lg-12 loc-1st pb-5 pt-4">
            <div className="col-lg-12  d-flex  justify-content-start align-items-center">
              <h2 className="cs-heading set-head-th px-0 m-0 p-0 mb-3">
                Coverage Area
              </h2>
            </div>
            <div className="col-md-12 me-4">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column w-100 justify-content-between">
                  <input
                    className="location-input cs-input profile-details-input "
                    onChange={(e) => setLocationValue(e.target.value)}
                    placeholder="Coverage Area Name"
                    value={locationValue}
                  />
                  {nameError &&
                    (!locationValue || locationValue.trim() === "") && (
                      <p
                        className="alert alert-danger mb-2"
                        style={{ width: "30%" }}
                      >
                        Coverage Area Name is required
                      </p>
                    )}
                </div>
                <button
                  id="add"
                  className="btn-add-location coverage-btn mb-4 me-4"
                  disabled={isShape ? true : false}
                >
                  Draw Coverage Area
                </button>
              </div>
            </div>
            <div id="map" className="mb-5 ml-3 map map-width"></div>
            <title>Drawing Tools</title>
            <div className=" col-md-12 gap-3 d-flex justify-content-end">
              <button
                className="delete-btn1 "
                id="delete-button"
                style={{
                  display: isShape ? "block" : "none",
                }}
              >
                Delete Selected Shape
              </button>

              <button
                className="save-btn1"
                onClick={() => handleSubmit()}
                // disabled={!isShape ? true : false}
              >
                Save
              </button>
            </div>
            <button
              className="closeButton border-0 closebtn-modal"
              closeButton
              onClick={props.onClose}
            >
              <AiOutlineClose size={22} />
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CoverageAreas;
