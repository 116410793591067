import { Container } from "bootstrap-4-react/lib/components/layout";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { GrClose } from "react-icons/gr";
import { currentRole } from "../../../utils/auth";

const SuccessModal = ({ open, onClose, image, role }) => {
  return (
    <>
      <Modal show={open} onHide={onClose} className="AgencyModal">
        <Container>
          <div className="closeicon_div">
            <GrClose onClick={onClose} size={20} className="closeicon" />
          </div>
          <div className=" padding-m-1 ">
            <h4 className="ss-h4">
              {role && role.key && (role.key === currentRole.supervisingtherapist || role.key === currentRole.assistanttherapist) ?
                "Part 1 of your application was submitted successfully and your account is being reviewed. You will receive an email with instructions for the next step." :
                "Your application was submitted successfully. You will receive an email once we have reviewed your information."
              }
            </h4>
            <img
              className="img-modal mt-3"
              src={image}
              alt="application submitted"
            />

            {/* <p style={{ color: '#808d98' }}>Lorem ipsum dolor sit amet, consectetur adipx`iscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> */}
          </div>
        </Container>
      </Modal>
    </>
  );
};

export default SuccessModal;
