import { useSelector } from "react-redux";

export const AccountingIcon = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const menu = "Accounting";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#6a40d4" />
          <stop offset="1" stop-color="#0f7ad4" />
        </linearGradient>
      </defs>
      <g
        id="Group_1040"
        data-name="Group 1040"
        transform="translate(-46 -335.5)"
      >
        <rect
          id="Rectangle_870"
          data-name="Rectangle 870"
          width="30"
          height="30"
          transform="translate(46 335.5)"
          opacity="0"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
        <path
          id="file-alt-solid"
          d="M6,3V27H24.462V9.087L24.2,8.8,18.663,3.26,18.375,3ZM7.846,4.846h9.231v5.538h5.538V25.154H7.846ZM18.923,6.173l2.365,2.365H18.923Zm-8.308,6.058v1.846h9.231V12.231Zm0,3.692v1.846h9.231V15.923Zm0,3.692v1.846h9.231V19.615Z"
          transform="translate(45.926 335.5)"
          stroke="rgba(0,0,0,0)"
          stroke-width="1"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
      </g>
    </svg>
  );
};
export const CaseManagementIcon = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const menu = "Case Management";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#6a40d4" />
          <stop offset="1" stop-color="#0f7ad4" />
        </linearGradient>
      </defs>
      <g
        id="Group_1039"
        data-name="Group 1039"
        transform="translate(-46 -278.5)"
      >
        <rect
          id="Rectangle_870"
          data-name="Rectangle 870"
          width="30"
          height="30"
          transform="translate(46 278.5)"
          opacity="0"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
        <path
          id="noun-users-1806286"
          d="M133.958,53.4a8.841,8.841,0,0,1,4.641,7.73q0,.158-.006.315a.833.833,0,0,1-.422.7,16.869,16.869,0,0,1-5.278,1.881,6.888,6.888,0,0,1,.494,2.557q0,.146-.006.292a.834.834,0,0,1-.472.716,22.664,22.664,0,0,1-18.431,0,.834.834,0,0,1-.472-.716q-.006-.145-.006-.292a8.013,8.013,0,0,1,5.139-7.139,10.6,10.6,0,0,1-.918-4.279c0-3.682,2.251-5.993,5.472-5.993a6.307,6.307,0,0,1,.892.062A4.763,4.763,0,0,1,129.427,45c2.917,0,4.951,2.153,4.951,5.559a10.027,10.027,0,0,1-.42,2.843Zm-.62,1.566c-.905,1.793-2.32,3.06-3.912,3.06a3.074,3.074,0,0,1-.646-.069,10.429,10.429,0,0,1-.532,1.481,9.16,9.16,0,0,1,3.8,3.032,15.576,15.576,0,0,0,4.879-1.553,7.172,7.172,0,0,0-3.59-5.951Zm-4.251,1.458a1.517,1.517,0,0,0,.34.039c1.653,0,3.387-2.933,3.387-5.906,0-2.562-1.355-4-3.387-4a3.241,3.241,0,0,0-3.328,3.108c1.881.869,3.067,2.819,3.067,5.491a10.231,10.231,0,0,1-.079,1.264ZM119.96,60.9a6.488,6.488,0,0,0-4.285,5.4,21.195,21.195,0,0,0,16.037,0,6.487,6.487,0,0,0-4.285-5.4,4.151,4.151,0,0,1-7.467,0Zm3.733.774c1.935,0,3.909-3.238,3.909-6.514,0-2.828-1.561-4.43-3.909-4.43s-3.909,1.6-3.909,4.43C119.785,58.44,121.759,61.678,123.694,61.678Z"
          transform="translate(-65.3 236.226)"
          stroke="rgba(0,0,0,0)"
          stroke-width="1"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
      </g>
    </svg>
  );
};
export const ContactSupportIcon = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const menu = "Contact Support";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#6a40d4" />
          <stop offset="1" stop-color="#0f7ad4" />
        </linearGradient>
      </defs>
      <g
        id="Group_1042"
        data-name="Group 1042"
        transform="translate(-46 -515.5)"
      >
        <rect
          id="Rectangle_870"
          data-name="Rectangle 870"
          width="30"
          height="30"
          transform="translate(46 515.5)"
          opacity="0"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
        <path
          id="headphones-alt-solid"
          d="M16,5A11.016,11.016,0,0,0,5,16v8a3.016,3.016,0,0,0,3,3h3V18H7V16a9,9,0,0,1,18,0v2H21v9h3a3.016,3.016,0,0,0,3-3V16A11.016,11.016,0,0,0,16,5ZM7,20H9v5H8a.982.982,0,0,1-1-1Zm16,0h2v4a.982.982,0,0,1-1,1H23Z"
          transform="translate(45 514.5)"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
      </g>
    </svg>
  );
};
export const DocumentationIcon = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const menu = "Documentation";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#6a40d4" />
          <stop offset="1" stop-color="#0f7ad4" />
        </linearGradient>
      </defs>
      <g
        id="Group_1040"
        data-name="Group 1040"
        transform="translate(-46 -335.5)"
      >
        <rect
          id="Rectangle_870"
          data-name="Rectangle 870"
          width="30"
          height="30"
          transform="translate(46 335.5)"
          opacity="0"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
        <path
          id="file-alt-solid"
          d="M6,3V27H24.462V9.087L24.2,8.8,18.663,3.26,18.375,3ZM7.846,4.846h9.231v5.538h5.538V25.154H7.846ZM18.923,6.173l2.365,2.365H18.923Zm-8.308,6.058v1.846h9.231V12.231Zm0,3.692v1.846h9.231V15.923Zm0,3.692v1.846h9.231V19.615Z"
          transform="translate(45.926 335.5)"
          stroke="rgba(0,0,0,0)"
          stroke-width="1"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
      </g>
    </svg>
  );
};
export const FindPatientIcon = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const menu = "Find Patients";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#6a40d4" />
          <stop offset="1" stop-color="#0f7ad4" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="0.813"
          y1="0.985"
          x2="0.296"
          y2="0.067"
        />
      </defs>
      <g id="Group_1143" data-name="Group 1143" transform="translate(-46 -221)">
        <rect
          id="Rectangle_870"
          data-name="Rectangle 870"
          width="30"
          height="30"
          transform="translate(46 221)"
          opacity="0"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
        <path
          id="noun-search-860389"
          d="M114,42.6l4.944,4.944a.99.99,0,0,1-1.4,1.4L112.6,44a7.916,7.916,0,1,1,1.4-1.4Zm-2.056-.656a5.937,5.937,0,1,0-8.4,0A5.937,5.937,0,0,0,111.942,41.945Z"
          transform="translate(-48.828 196.17)"
          fill-rule="evenodd"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
      </g>
    </svg>
  );
};
export const HomeIcon = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const menu = "Home";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#6a40d4" />
          <stop offset="1" stop-color="#0f7ad4" />
        </linearGradient>
      </defs>
      <g id="Group_1146" data-name="Group 1146" transform="translate(-43 -107)">
        <rect
          id="Rectangle_869"
          data-name="Rectangle 869"
          width="30"
          height="30"
          transform="translate(43 107)"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
          opacity="0"
        />
        <path
          id="Path_25"
          data-name="Path 25"
          d="M169.988,78.4a1.045,1.045,0,0,0-.563.191l-9.472,7.333a.932.932,0,0,0-.353.726V99.485a.953.953,0,0,0,.917.917h6.417a.953.953,0,0,0,.917-.917V94.6a2.139,2.139,0,1,1,4.278,0v4.889a.953.953,0,0,0,.917.917h6.417a.953.953,0,0,0,.917-.917V86.652a.933.933,0,0,0-.353-.726l-9.472-7.333a.793.793,0,0,0-.563-.191Zm0,2.072,8.556,6.617V98.568H173.96V94.6a3.972,3.972,0,1,0-7.945,0v3.972h-4.583V87.091Z"
          transform="translate(-111.599 32.598)"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
      </g>
    </svg>
  );
};
export const LogoutIcon = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const menu = "Logout";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#6a40d4" />
          <stop offset="1" stop-color="#0f7ad4" />
        </linearGradient>
      </defs>
      <g id="Group_1043" data-name="Group 1043" transform="translate(-46 -573)">
        <rect
          id="Rectangle_870"
          data-name="Rectangle 870"
          width="30"
          height="30"
          transform="translate(46 573)"
          opacity="0"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
        <path
          id="_"
          data-name=""
          d="M1.625-18.75h2v12h-2Zm-3,.688v2.188A9.538,9.538,0,0,0-5.719-12.25a9.751,9.751,0,0,0-1.656,5.5A9.629,9.629,0,0,0-4.437.313,9.629,9.629,0,0,0,2.625,3.25,9.629,9.629,0,0,0,9.688.313,9.629,9.629,0,0,0,12.625-6.75a9.751,9.751,0,0,0-1.656-5.5,9.538,9.538,0,0,0-4.344-3.625v-2.187a11.746,11.746,0,0,1,5.781,4.344A11.668,11.668,0,0,1,14.625-6.75a11.551,11.551,0,0,1-3.531,8.469A11.551,11.551,0,0,1,2.625,5.25,11.551,11.551,0,0,1-5.844,1.719,11.551,11.551,0,0,1-9.375-6.75a11.668,11.668,0,0,1,2.219-6.969A11.746,11.746,0,0,1-1.375-18.062Z"
          transform="translate(58.375 594.75)"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
      </g>
    </svg>
  );
};
export const MyScheduleIcon = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const menu = "My Schedule";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#6a40d4" />
          <stop offset="1" stop-color="#0f7ad4" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="0.813"
          y1="0.985"
          x2="0.296"
          y2="0.067"
        />
      </defs>
      <g id="Group_1144" data-name="Group 1144" transform="translate(-46 -164)">
        <rect
          id="Rectangle_899"
          data-name="Rectangle 899"
          width="30"
          height="30"
          transform="translate(46 164)"
          opacity="0"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
        <g id="noun-schedule-2979227" transform="translate(-47.996 138.951)">
          <path
            id="Path_97"
            data-name="Path 97"
            d="M119.464,40.923v-7a3.387,3.387,0,0,0-3.227-3.378v-.113a2.39,2.39,0,0,0-4.78,0v.1H106v-.1a2.39,2.39,0,0,0-4.78,0v.113A3.387,3.387,0,0,0,98,33.926v12.2a3.39,3.39,0,0,0,3.386,3.386h9.491a6.248,6.248,0,1,0,8.59-8.59Zm-6.376-10.488a.759.759,0,0,1,1.519,0v1.84a.759.759,0,0,1-1.519,0Zm-10.235,0h0a.76.76,0,0,1,1.519,0v1.84a.76.76,0,0,1-1.519,0Zm-1.631,1.743v.1a2.39,2.39,0,0,0,4.78,0V32.17h5.455v.106a2.39,2.39,0,0,0,4.78,0v-.1a1.757,1.757,0,0,1,1.6,1.747v1.913H99.627V33.926a1.757,1.757,0,0,1,1.6-1.747Zm.159,15.7a1.757,1.757,0,0,1-1.755-1.755V37.469h18.207v2.75a6.248,6.248,0,0,0-7.664,7.664ZM116.2,50.873a4.625,4.625,0,1,1,3.265-1.355A4.62,4.62,0,0,1,116.2,50.873Zm2.682-7.158,1.153,1.153-4.369,4.369L113.4,46.969l1.153-1.153,1.114,1.114Z"
            transform="translate(0 0)"
            fill={listname === menu ? "white" : "url(#linear-gradient)"}
          />
        </g>
      </g>
    </svg>
  );
};
export const PatientChartIcon = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const menu = "Patient Charts";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#6a40d4" />
          <stop offset="1" stop-color="#0f7ad4" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="0.813"
          y1="0.985"
          x2="0.296"
          y2="0.067"
        />
      </defs>
      <g id="Group_1038" data-name="Group 1038" transform="translate(-46 -221)">
        <rect
          id="Rectangle_870"
          data-name="Rectangle 870"
          width="30"
          height="30"
          transform="translate(46 221)"
          opacity="0"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
        <g id="noun-chart-1186570" transform="translate(-71.889 223.214)">
          <path
            id="Path_26"
            data-name="Path 26"
            d="M143.277,25.572H122.5V0h13.345l7.432,7.432Zm-19.179-1.6h17.581V8.071L135.206,1.6H124.1Z"
            fill={listname === menu ? "white" : "url(#linear-gradient)"}
          />
          <path
            id="Path_27"
            data-name="Path 27"
            d="M208.25,70h6.073v1.6H208.25Z"
            transform="translate(-81.834 -66.803)"
            fill={listname === menu ? "white" : "url(#linear-gradient)"}
          />
          <path
            id="Path_28"
            data-name="Path 28"
            d="M208.25,140h6.073v1.6H208.25Z"
            transform="translate(-81.834 -133.607)"
            fill={listname === menu ? "white" : "url(#linear-gradient)"}
          />
          <path
            id="Path_29"
            data-name="Path 29"
            d="M398,25.252H390.25V17.5h1.6v6.153H398Z"
            transform="translate(-255.523 -16.701)"
            fill={listname === menu ? "white" : "url(#linear-gradient)"}
          />
          <path
            id="Path_30"
            data-name="Path 30"
            d="M213.133,223.266a6.633,6.633,0,1,1,6.633-6.633A6.616,6.616,0,0,1,213.133,223.266Zm0-11.587a5.035,5.035,0,1,0,5.034,5.035A5.065,5.065,0,0,0,213.133,211.678Z"
            transform="translate(-80.164 -200.41)"
            fill={listname === menu ? "white" : "url(#linear-gradient)"}
          />
          <path
            id="Path_31"
            data-name="Path 31"
            d="M249.7,239.719l-1.2-1.119,3.916-3.916V229.25h1.6V235.4Z"
            transform="translate(-120.246 -218.781)"
            fill={listname === menu ? "white" : "url(#linear-gradient)"}
          />
          <path
            id="Path_32"
            data-name="Path 32"
            d="M351.75,339.5h5.834v1.6H351.75Z"
            transform="translate(-218.781 -323.997)"
            fill={listname === menu ? "white" : "url(#linear-gradient)"}
          />
        </g>
      </g>
    </svg>
  );
};
export const StaffingIcon = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const menu = "Staffing";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#6a40d4" />
          <stop offset="1" stop-color="#0f7ad4" />
        </linearGradient>
      </defs>
      <g
        id="Group_1039"
        data-name="Group 1039"
        transform="translate(-46 -278.5)"
      >
        <rect
          id="Rectangle_870"
          data-name="Rectangle 870"
          width="30"
          height="30"
          transform="translate(46 278.5)"
          opacity="0"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
        <path
          id="noun-users-1806286"
          d="M133.958,53.4a8.841,8.841,0,0,1,4.641,7.73q0,.158-.006.315a.833.833,0,0,1-.422.7,16.869,16.869,0,0,1-5.278,1.881,6.888,6.888,0,0,1,.494,2.557q0,.146-.006.292a.834.834,0,0,1-.472.716,22.664,22.664,0,0,1-18.431,0,.834.834,0,0,1-.472-.716q-.006-.145-.006-.292a8.013,8.013,0,0,1,5.139-7.139,10.6,10.6,0,0,1-.918-4.279c0-3.682,2.251-5.993,5.472-5.993a6.307,6.307,0,0,1,.892.062A4.763,4.763,0,0,1,129.427,45c2.917,0,4.951,2.153,4.951,5.559a10.027,10.027,0,0,1-.42,2.843Zm-.62,1.566c-.905,1.793-2.32,3.06-3.912,3.06a3.074,3.074,0,0,1-.646-.069,10.429,10.429,0,0,1-.532,1.481,9.16,9.16,0,0,1,3.8,3.032,15.576,15.576,0,0,0,4.879-1.553,7.172,7.172,0,0,0-3.59-5.951Zm-4.251,1.458a1.517,1.517,0,0,0,.34.039c1.653,0,3.387-2.933,3.387-5.906,0-2.562-1.355-4-3.387-4a3.241,3.241,0,0,0-3.328,3.108c1.881.869,3.067,2.819,3.067,5.491a10.231,10.231,0,0,1-.079,1.264ZM119.96,60.9a6.488,6.488,0,0,0-4.285,5.4,21.195,21.195,0,0,0,16.037,0,6.487,6.487,0,0,0-4.285-5.4,4.151,4.151,0,0,1-7.467,0Zm3.733.774c1.935,0,3.909-3.238,3.909-6.514,0-2.828-1.561-4.43-3.909-4.43s-3.909,1.6-3.909,4.43C119.785,58.44,121.759,61.678,123.694,61.678Z"
          transform="translate(-65.3 236.226)"
          stroke="rgba(0,0,0,0)"
          stroke-width="1"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
      </g>
    </svg>
  );
};
export const UserSettingIcon = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const menu = "User Profile";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#6a40d4" />
          <stop offset="1" stop-color="#0f7ad4" />
        </linearGradient>
      </defs>
      <g
        id="Group_1041"
        data-name="Group 1041"
        transform="translate(-46 -458.5)"
      >
        <rect
          id="Rectangle_870"
          data-name="Rectangle 870"
          width="30"
          height="30"
          transform="translate(46 458.5)"
          opacity="0"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
        <path
          id="_"
          data-name=""
          d="M-.806-19.531H4.387l.173.75.519,2.712a8.877,8.877,0,0,1,2.365,1.442l2.712-.923.75-.231.346.635L13.1-11.916l.346.635-.519.519L10.848-8.974a10.427,10.427,0,0,1,.173,1.442,10.427,10.427,0,0,1-.173,1.442L12.925-4.3l.519.519-.346.635L11.252.084l-.346.635-.75-.231L7.444-.435A8.877,8.877,0,0,1,5.079,1.007L4.56,3.719l-.173.75H-.806l-.173-.75L-1.5,1.007A8.877,8.877,0,0,1-3.863-.435L-6.575.488l-.75.231L-7.671.084-9.517-3.147l-.346-.635.519-.519,2.077-1.788A10.427,10.427,0,0,1-7.44-7.531a10.427,10.427,0,0,1,.173-1.442l-2.077-1.788-.519-.519.346-.635,1.846-3.231.346-.635.75.231,2.712.923A8.877,8.877,0,0,1-1.5-16.07l.519-2.712Zm1.5,1.846L.233-15.262l-.115.519L-.4-14.57a7.723,7.723,0,0,0-2.769,1.615l-.462.346-.462-.115-2.365-.808-1.1,1.846,1.846,1.615.462.346-.173.577a7.174,7.174,0,0,0-.173,1.615,7.174,7.174,0,0,0,.173,1.615l.173.577-.462.346L-7.556-3.377l1.1,1.846,2.365-.808.462-.115.462.346A7.723,7.723,0,0,0-.4-.493L.117-.32.233.2.694,2.623H2.887L3.348.2,3.464-.32l.519-.173A7.723,7.723,0,0,0,6.752-2.108l.462-.346.462.115,2.365.808,1.1-1.846L9.291-4.993l-.4-.346L9-5.916a7.174,7.174,0,0,0,.173-1.615A7.174,7.174,0,0,0,9-9.147l-.173-.577.462-.346,1.846-1.615-1.1-1.846-2.365.808-.462.115-.462-.346A7.723,7.723,0,0,0,3.983-14.57l-.519-.173-.115-.519-.462-2.423ZM-1.5-10.762a4.492,4.492,0,0,1,3.288-1.385,4.444,4.444,0,0,1,3.26,1.356,4.444,4.444,0,0,1,1.356,3.26A4.444,4.444,0,0,1,5.05-4.272a4.444,4.444,0,0,1-3.26,1.356,4.444,4.444,0,0,1-3.26-1.356,4.444,4.444,0,0,1-1.356-3.26A4.4,4.4,0,0,1-1.5-10.762Zm5.25,1.269A2.671,2.671,0,0,0,1.791-10.3a2.671,2.671,0,0,0-1.962.808A2.671,2.671,0,0,0-.979-7.531,2.671,2.671,0,0,0-.171-5.57a2.671,2.671,0,0,0,1.962.808A2.671,2.671,0,0,0,3.752-5.57,2.671,2.671,0,0,0,4.56-7.531,2.671,2.671,0,0,0,3.752-9.493Z"
          transform="translate(59.209 481.031)"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
      </g>
    </svg>
  );
};
export const AdminUsericon = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const menu = "Admin User";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      viewBox="0 0 24.143 26.315"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.813"
          y1="0.985"
          x2="0.296"
          y2="0.067"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#6a40d4" />
          <stop offset="1" stopColor="#0f7ad4" />
        </linearGradient>
      </defs>
      <g
        id="adminusers_tab_icon_deselect"
        transform="translate(-35.919 -145.527)"
      >
        <path
          id="Path_554"
          data-name="Path 554"
          d="M115.919,43.767V45.76h0a3.177,3.177,0,0,0,3.177,3.177h11.119a8.461,8.461,0,0,0,2.368,1.531l.722.318.347.058.347-.058.837-.346h0a8.462,8.462,0,0,0,5.227-7.827V39.753a2.887,2.887,0,0,0-1.531-2.541l-3.581-1.935a2.6,2.6,0,0,0-2.484,0l-2.137,1.184a9.041,9.041,0,0,0-1.964-1.1,5.949,5.949,0,1,0-6.787,0,9.068,9.068,0,0,0-5.66,8.4Zm17.386-6.96a.867.867,0,0,1,.838,0l3.61,1.935a1.155,1.155,0,0,1,.606,1.011v2.859a6.729,6.729,0,0,1-4.188,6.238l-.52.2-.4-.173h0A6.758,6.758,0,0,1,129.2,42.7V39.724a1.155,1.155,0,0,1,.607-1.011Zm-12.563-6.325a4.217,4.217,0,1,1,1.245,2.992,4.216,4.216,0,0,1-1.245-2.992Zm4.245,5.949a7.337,7.337,0,0,1,3.639.982,2.888,2.888,0,0,0-1.155,2.31V42.7a8.46,8.46,0,0,0,1.3,4.505H119.1a1.444,1.444,0,0,1-1.444-1.444V43.767a7.335,7.335,0,0,1,7.336-7.336Z"
          transform="translate(-80 121)"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
      </g>
    </svg>
  );
};
export const NewUsericon = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const menu = "New Users";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      viewBox="0 0 25.547 20.319"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.813"
          y1="0.985"
          x2="0.296"
          y2="0.067"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#6a40d4" />
          <stop offset="1" stopColor="#0f7ad4" />
        </linearGradient>
      </defs>
      <g id="newuser_tab_icon_deselect" transform="translate(-98 -79.566)">
        <path
          id="Path_109"
          data-name="Path 109"
          d="M174.63,89.064a4.749,4.749,0,1,0-4.749-4.749A4.755,4.755,0,0,0,174.63,89.064Zm0-7.883a3.134,3.134,0,1,1-3.134,3.134A3.137,3.137,0,0,1,174.63,81.181Z"
          transform="translate(-68.238)"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
        <path
          id="Path_110"
          data-name="Path 110"
          d="M114.785,281.831v-3.866a6.157,6.157,0,0,0-4.065-5.772,1.989,1.989,0,0,0-2.071.465l-2,2a.365.365,0,0,1-.515,0l-2-2a1.989,1.989,0,0,0-2.072-.465A6.158,6.158,0,0,0,98,277.966v3.866a.808.808,0,0,0,.808.808h15.171a.807.807,0,0,0,.807-.808Zm-1.615-.808H99.615v-3.058a4.538,4.538,0,0,1,3-4.252.366.366,0,0,1,.382.087l2,2a1.982,1.982,0,0,0,2.8,0l2-2a.365.365,0,0,1,.382-.087,4.538,4.538,0,0,1,3,4.252v3.058Z"
          transform="translate(0 -182.754)"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
        <path
          id="Path_111"
          data-name="Path 111"
          d="M406.554,159.656a.808.808,0,1,0,0,1.615h3.358v3.358a.808.808,0,0,0,1.615,0v-3.358h3.359a.808.808,0,0,0,0-1.615h-3.359V156.3a.808.808,0,0,0-1.615,0v3.359Z"
          transform="translate(-292.147 -72.076)"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
      </g>
    </svg>
  );
};
export const BlockIcon = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const menu = "Block-List";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      viewBox="0 0 20.777 20.769"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.813"
          y1="0.985"
          x2="0.296"
          y2="0.067"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#6a40d4" />
          <stop offset="1" stopColor="#0f7ad4" />
        </linearGradient>
      </defs>
      <g
        id="blocklist_tab_icon_deselect"
        transform="translate(-113.729 -43.84)"
      >
        <path
          id="Path_154"
          data-name="Path 154"
          d="M130.1,48.239a9.875,9.875,0,0,1,.813.923l-.152-.195a9.622,9.622,0,0,1,1.214,2.085c-.031-.077-.065-.154-.1-.231a9.481,9.481,0,0,1,.652,2.4c-.012-.084-.024-.171-.034-.255a9.711,9.711,0,0,1,0,2.506c.012-.084.024-.171.034-.255a9.483,9.483,0,0,1-.652,2.4c.031-.077.065-.154.1-.231a9.467,9.467,0,0,1-1.214,2.085l.152-.195a9.726,9.726,0,0,1-1.736,1.736l.195-.151a9.62,9.62,0,0,1-2.085,1.214l.231-.1a9.483,9.483,0,0,1-2.4.652c.084-.012.171-.024.255-.034a9.711,9.711,0,0,1-2.506,0c.084.012.171.024.255.034a9.484,9.484,0,0,1-2.4-.652l.231.1a9.467,9.467,0,0,1-2.085-1.214l.195.151a9.725,9.725,0,0,1-1.736-1.736l.151.195a9.621,9.621,0,0,1-1.214-2.085c.031.077.065.154.1.231a9.482,9.482,0,0,1-.652-2.4c.012.084.024.171.034.255a9.71,9.71,0,0,1,0-2.506c-.012.084-.024.171-.034.255a9.483,9.483,0,0,1,.652-2.4c-.031.077-.065.154-.1.231a9.467,9.467,0,0,1,1.214-2.085l-.151.195a9.725,9.725,0,0,1,1.736-1.736l-.195.151a9.62,9.62,0,0,1,2.085-1.214l-.231.1a9.483,9.483,0,0,1,2.4-.652c-.084.012-.171.024-.255.034a9.71,9.71,0,0,1,2.506,0c-.084-.012-.171-.024-.255-.034a9.484,9.484,0,0,1,2.4.652l-.231-.1a9.467,9.467,0,0,1,2.085,1.214l-.195-.151a9.877,9.877,0,0,1,.923.813.962.962,0,0,0,1.361-1.361,10.381,10.381,0,0,0-8.6-2.96,10.713,10.713,0,0,0-1.65.329,11.123,11.123,0,0,0-1.546.589,10.361,10.361,0,0,0-5.894,10.311,10.417,10.417,0,0,0,2.494,5.877,10.372,10.372,0,0,0,11.451,2.941,10.872,10.872,0,0,0,1.51-.7,11.028,11.028,0,0,0,1.407-.964,10.374,10.374,0,0,0,1.914-14.142,11.317,11.317,0,0,0-1.087-1.284.962.962,0,1,0-1.361,1.361Z"
          transform="translate(0)"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
        <path
          id="Path_155"
          data-name="Path 155"
          d="M190.3,106.967l-.358.358-.967.967-1.436,1.436-1.746,1.746-1.921,1.921-1.938,1.938-1.8,1.8-1.527,1.527-1.094,1.094c-.173.173-.349.346-.522.522l-.022.022a.962.962,0,1,0,1.361,1.361l.358-.358.967-.967,1.436-1.436,1.746-1.746,1.921-1.921,1.938-1.938,1.8-1.8,1.527-1.527,1.094-1.094c.173-.173.349-.346.522-.522l.022-.022a.962.962,0,0,0-1.361-1.361Z"
          transform="translate(-60.193 -60.09)"
          fill={listname === menu ? "white" : "url(#linear-gradient)"}
        />
      </g>
    </svg>
  );
};

export const TherapistCredentialsIcon = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const menu = "Therapist Credentials";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.813"
          y1="0.985"
          x2="0.296"
          y2="0.067"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#6a40d4" />
          <stop offset="1" stopColor="#0f7ad4" />
        </linearGradient>
      </defs>
      <path
        d="M11.5714 17.142C12.2495 17.142 12.9124 16.9409 13.4762 16.5642C14.04 16.1875 14.4795 15.652 14.739 15.0255C14.9985 14.399 15.0664 13.7096 14.9341 13.0446C14.8018 12.3795 14.4753 11.7686 13.9958 11.2891C13.5163 10.8096 12.9054 10.483 12.2403 10.3508C11.5752 10.2185 10.8858 10.2864 10.2593 10.5459C9.63285 10.8054 9.09738 11.2448 8.72064 11.8086C8.3439 12.3725 8.14282 13.0353 8.14282 13.7134C8.14282 14.6228 8.50405 15.4948 9.14703 16.1378C9.79001 16.7808 10.6621 17.142 11.5714 17.142ZM11.5714 11.9992C11.9104 11.9992 12.2419 12.0997 12.5238 12.2881C12.8057 12.4764 13.0254 12.7442 13.1552 13.0574C13.2849 13.3707 13.3189 13.7154 13.2527 14.0479C13.1866 14.3804 13.0233 14.6859 12.7836 14.9256C12.5438 15.1654 12.2384 15.3286 11.9058 15.3948C11.5733 15.4609 11.2286 15.427 10.9154 15.2972C10.6021 15.1675 10.3344 14.9478 10.146 14.6659C9.95765 14.3839 9.85711 14.0525 9.85711 13.7134C9.85711 13.2588 10.0377 12.8228 10.3592 12.5013C10.6807 12.1798 11.1167 11.9992 11.5714 11.9992ZM9.85711 3.42773H13.2857V5.14202H9.85711V3.42773Z"
        fill={listname === menu ? "white" : "url(#linear-gradient)"}
      />
      <path
        d="M18.4286 0H4.71429C4.25977 0.000453837 3.824 0.181211 3.5026 0.502604C3.18121 0.823996 3.00045 1.25977 3 1.71429V22.2857C3.00045 22.7402 3.18121 23.176 3.5026 23.4974C3.824 23.8188 4.25977 23.9995 4.71429 24H18.4286C18.883 23.9993 19.3187 23.8185 19.64 23.4971C19.9613 23.1758 20.1422 22.7402 20.1429 22.2857V1.71429C20.1424 1.25977 19.9616 0.823996 19.6403 0.502604C19.3189 0.181211 18.8831 0.000453837 18.4286 0ZM15 22.2857H8.14286V20.5714C8.14286 20.3441 8.23316 20.1261 8.39391 19.9653C8.55465 19.8046 8.77267 19.7143 9 19.7143H14.1429C14.3702 19.7143 14.5882 19.8046 14.7489 19.9653C14.9097 20.1261 15 20.3441 15 20.5714V22.2857ZM16.7143 22.2857V20.5714C16.7143 19.8894 16.4434 19.2354 15.9611 18.7532C15.4789 18.2709 14.8248 18 14.1429 18H9C8.31801 18 7.66396 18.2709 7.18173 18.7532C6.69949 19.2354 6.42857 19.8894 6.42857 20.5714V22.2857H4.71429V1.71429H18.4286V22.2857H16.7143Z"
        fill={listname === menu ? "white" : "url(#linear-gradient)"}
      />
    </svg>
  );
};
