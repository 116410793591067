import React, { useEffect } from "react";
import { getInvoiceDetailById } from "../../Redux/actions/payment";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { Table } from "react-bootstrap";

export default function InvoiceView(props) {
  const { data } = props;

  const dispatch = useDispatch();
  const isfetching = useSelector((state) => state.paymentSlice.isfetching);
  const invoiceDetails = useSelector(
    (state) => state.paymentSlice.invoiceDetail
  );

  useEffect(() => {
    if (data && data.id) {
      let object = {
        id: data.id,
      };
      dispatch(getInvoiceDetailById(object));
    }
  }, [data]);

  const getVisitPrice = (episodeDocumentId) => {
    let price = `$0`;
    if (
      invoiceDetails &&
      invoiceDetails.staffInvoice &&
      invoiceDetails.staffInvoice.episodeDocuments &&
      invoiceDetails.staffInvoice.episodeDocuments.length > 0
    ) {
      let findItem = invoiceDetails.staffInvoice.episodeDocuments.find(
        (item) => item.episodeDocumentId === episodeDocumentId
      );
      if (findItem && findItem.amount) {
        price = `$${findItem.amount}`;
      }
    }
    return price;
  };
  const totalAmount = () => {
    let total = 0;
    if (
      invoiceDetails &&
      invoiceDetails.staffInvoice &&
      invoiceDetails.staffInvoice.episodeDocuments &&
      invoiceDetails.staffInvoice.episodeDocuments.length > 0
    ) {
      invoiceDetails.staffInvoice.episodeDocuments.map((item) => {
        total += item.amount;
      });
    }
    return total;
  };
  return (
    <div className="p-4">
      {isfetching && <Loader />}
      <div className="row">
        <div className="col-md-12">
          <p className="fw-bold text-center" style={{ fontSize: "30px" }}>
            {" "}
            The Care Connexion
          </p>
          <p className="fw-bold text-center mb-4"> Invoice Details</p>
        </div>

        <div className="col-md-6">
          <h5>Bill To</h5>
          <p className="mb-1">
            Agency :{" "}
            {invoiceDetails &&
              invoiceDetails.staffInvoice &&
              invoiceDetails.staffInvoice.agencyInvoiceData &&
              invoiceDetails.staffInvoice.agencyInvoiceData.name}
          </p>
          <p>
            Email :{" "}
            {invoiceDetails &&
              invoiceDetails.staffInvoice &&
              invoiceDetails.staffInvoice.agencyInvoiceData &&
              invoiceDetails.staffInvoice.agencyInvoiceData.email}
          </p>
        </div>
        <div className="col-md-6 float-right">
          <div>
            <h5>Date Due: </h5>
          </div>
          <div>
            <h5>Therapist Name & Discipline: </h5>
            <p>
              Agency :{" "}
              {invoiceDetails &&
                invoiceDetails.staffInvoice &&
                invoiceDetails.staffInvoice.therapistInvoiceData &&
                `${invoiceDetails.staffInvoice.therapistInvoiceData.lastName}, ${invoiceDetails.staffInvoice.therapistInvoiceData.firstName}`}
            </p>
          </div>
        </div>
        <div className="col-md-12 my-2">
          {!isfetching && (
            <Table responsive="sm">
              <thead>
                <tr>
                  <th className="text-center">Serial No.</th>
                  <th className="text-center">Visit Type</th>
                  {data.type == "invoice" ? (
                    <th className="text-center">Price</th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody className="invoice-tbody">
                {invoiceDetails &&
                  invoiceDetails.visits &&
                  invoiceDetails.visits.rows &&
                  invoiceDetails.visits.rows.length > 0 &&
                  invoiceDetails.visits.rows.map((item, index) => (
                    <tr key={`Visit_${index}`}>
                      <td className="text-center  " width="33%">
                        {index + 1}
                      </td>
                      <td className="text-center" width="33%">
                        {item && item.masterType && item.masterType.displayName
                          ? item.masterType.displayName
                          : " --- "}
                      </td>
                      {data.type == "invoice" ? (
                        <td className="text-center" width="33%">
                          {item.visitPrice
                            ? `$${item.visitPrice}`
                            : `${getVisitPrice(item.id)}`}
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
        </div>

        <div className="col-md-11 my-3">
          <h5 className="text-right mr-4 pr-4">
            Total Value : $
            {invoiceDetails &&
            invoiceDetails.staffInvoice &&
            invoiceDetails.staffInvoice.billableAmount
              ? invoiceDetails.staffInvoice.billableAmount
              : totalAmount()}
          </h5>
        </div>

        <div className="col-md-12 mt-4">
          <h3 className="text-center">Thank You !</h3>
        </div>
      </div>
    </div>
  );
}
