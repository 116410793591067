import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Row, Col } from "bootstrap-4-react";

export default function Calender() {
  const [date, setDate] = useState(new Date());
  const [show, setShow] = useState(false);

  function change(e) {
    setShow(false);
    setDate(e);
  }
  return (
    <>
      <div className="visit-lists chart_main_top">
        <Row>
          <Col className="p-0" xl={5} lg={5} md={12} sm={12}>
            <div className="chart_main_parent">
              <h3>Projected Visits this Week</h3>
              <p>
                Number of projected visits for the week correlating to estimated
              </p>
            </div>
          </Col>
          <Col lg={7}>
            <div className="right_side_from_chart">
              <ul className="mt-4">
                <li>
                  <div className="date_text">
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => setShow(true)}
                    >
                      Jun 20 - Jun 26{" "}
                      <IoIosArrowDropleft size={"30px"} className="mr-3" />
                      <IoIosArrowDropright size={"30px"} />
                    </p>
                  </div>
                </li>

                <li>
                  <div className="d-flex gap-3">
                    <button className=" btn-dob">Day</button>
                    <button className=" btn-dob">week</button>
                    <button className=" btn-dob">Month</button>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        {show && (
          <Calendar
            onChange={(e) => change(e)}
            value={date}
            defaultView="month"
            closeCalendar={true}
          />
        )}
      </div>
    </>
  );
}
