import React, { useEffect, useMemo, useState } from "react";

import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getLastGoalsValue,
  getStaffFormData,
} from "../../../Redux/actions/common";
import {
  canSubmitForm,
  staffFormTypesConstants,
  visitStatus,
} from "../../../utils/helper";
import { currentRole } from "../../../utils/auth";
import { savePatientForm } from "../../../Redux/actions/staffing";
import SignatureCanvas from "../../../components/common/SignatureCanvas";
import { toast } from "react-toastify";
import {
  formOptions,
  reactSelectOptionCreate,
} from "../../../utils/common/options";
import { getStaffFormdataById } from "../../../Redux/actions/patientGetDocTypes";

function Discharge(props) {
  const {
    closeFormModal,
    visitDetail,
    formId,
    confirmDate,
    isPrint,
    IsUpdated,
    statusHandler,
    dataAvailable,
    toSave,
  } = props;

  const totalDefaultGoals = 8;
  let defaultGoalObj = {
    goals: "",
    effectiveDate: "",
    currentStatus: "",
    percentageGoalMet: "",
  };

  const [error, setError] = useState(null);
  const [isCanvasShow, setIsCanvasShow] = useState(false);
  const [signatureAdded, setSignatureAdded] = useState(null);
  const [displaySignature, setDisplaySignature] = useState(null);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [planError, setPlansError] = useState(null);
  const lastGoals = useSelector((e) => e.commonSlice.lastGoals);

  const vitalSignPulseType = [
    { label: "Apical", type: "radio" },
    { label: "Radial", type: "radio" },
    { label: "Regular", type: "radio" },
    { label: "Irregular", type: "radio" },
  ];
  const vitalSignBP = [
    { label: "Left", type: "radio" },
    { label: "Right", type: "radio" },
  ];
  const vitalSignTemperature = [
    { label: "Fahrenheit", type: "radio" },
    { label: "Celsius", type: "radio" },
  ];

  const vitalSignRespiration = [
    { label: "Regular", type: "radio", col: 4 },
    { label: "Irregular", type: "radio", col: 4 },
  ];
  const selfMonitoring = [
    { label: "Temp", type: "checkbox" },
    { label: "Pulse", type: "checkbox" },
    { label: "BP", type: "checkbox" },
    { label: "SPO2", type: "checkbox" },
    { label: "Patient/Caregiver NOT self monitoring", type: "checkbox" },
  ];
  const relieves_pain_data = [
    "Medications",
    "Heat",
    "Ice",
    "Rest",
    "Relaxation",
    "Other",
  ];
  const patientNotCommunicate_Options_data = [
    { label: "Diaphoresis", type: "checkbox", col: 5, value: "Diaphoresis" },
    { label: "Grimacing", type: "checkbox", col: 5, value: "Grimacing" },
    {
      label: "Moaning/crying",
      type: "checkbox",
      col: 5,
      value: "Moaning/crying",
    },
    { label: "Guarding", type: "checkbox", col: 5, value: "Guarding" },
    { label: "Irritability", type: "checkbox", col: 5, value: "Irritability" },
    { label: "Anger", type: "checkbox", col: 5, value: "Anger" },
    { label: "Tense", type: "checkbox", col: 5, value: "Tense" },
    { label: "Restlessness", type: "checkbox", col: 5, value: "Restlessness" },
    {
      label: "Change in vital signs",
      type: "checkbox",
      col: 5,
      value: "Change in vital signs",
    },
    { label: "Other", type: "checkbox", col: 5, value: "Other" },
  ];
  const scale_Used_data = ["Faces", "Numeric", "Other"];
  const description_data = [
    "Sharp",
    "Dull",
    "Cramping",
    "Aching",
    "Burning",
    "Tingling",
    "Throbbing",
    "Shooting",
    "Pinching",
  ];
  const cause_pain_data = ["Movement", "Time of day", "Posture", "Other"];

  const reasondischarge = [
    "Goals met",
    "Plateau in progress",
    "Patient refused",
    "Patient Relocation",
    "Transfer to outpatient therapy",
    "Transfer to in-patient rehab or alternative care program",
    "Transfer to hospice",
    "No further insurance  authorization",
    "Lack of funds",
    "Other",
  ];
  const care_coordination = [
    "Physician",
    "Case Manager",
    "Clinical Manager",
    "SN",
    "PT",
    "PTA",
    "OT",
    "COTA",
    "ST",
    "Aide",
    "MSW",
    "Other",
  ];
  const vitalSignsThreshold = {
    bpSystolic: {
      min: 85,
      max: 160,
    },
    bpDiastolic: {
      min: 60,
      max: 90,
    },
    pulse: {
      min: 60,
      max: 100,
    },
    respiration: {
      min: 12,
      max: 20,
    },
    temperature: {
      min: 96,
      max: 100,
    },
  };
  const vitalSignRequired = [
    "pulse",
    "bpSystolic",
    "bpDiastolic",
    "respiration",
    "temperature",
  ];

  const staffDetails = useSelector((state) => state.staffingSlice.staffDetail);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const staffDocument = useSelector((e) => e.commonSlice.staffDocument);
  const staffFormData = useSelector((e) => e.commonSlice.staffForm);
  const visitDetailById = useSelector(
    (data) => data?.patientGetDocTypesSlice?.visitDetail
  );

  const vitalSignsValidation = (data) => {
    let val = false;
    Object.keys(data).map((e) => {
      if (
        e !== "selfMonitoring" &&
        e !== "additonalText" &&
        vitalSignsThreshold[e] &&
        data[e] &&
        Number(data[e]) > 0 &&
        (Number(data[e]) < Number(vitalSignsThreshold[e]["min"]) ||
          Number(data[e]) > Number(vitalSignsThreshold[e]["max"]))
      ) {
        val = true;
      }
    });
    return val;
  };

  const isPainHigh = (painArray) => {
    let highPain =
      painArray && painArray.find((painItem) => painItem.rating > 6);
    return highPain ? true : false;
  };
  const effectiveDate = () => {
    if (staffDocument && staffDocument.length > 0) {
      const date =
        staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
      return date;
    }
    return "";
  };
  const [TherapistsignatureError, setTherapistsignatureError] = useState(null);
  const onSubmit = (values, form, status) => {
    if (!displaySignature || !values.Therapistsignature) {
      setTherapistsignatureError("Required");
    } else {
      setTherapistsignatureError(null);
    }
    let finalFormValues = {
      ...values,
      Therapistsignature: !displaySignature
        ? values.Therapistsignature
        : displaySignature,
    };

    const apiObject = {
      status: typeof status === "string" ? status : visitStatus.incompletedoc,
      staffId: staffDetails?.id,
      formData: finalFormValues,
      formType: staffFormTypesConstants.RegularDischarge,
      id: finalFormValues.id,
      episodeDocumentFormId: formId,
      episodeDocumentId: visitDetail?.id,
    };
    dispatch(savePatientForm(apiObject)).then((res) => {
      if (res.payload.success) {
        closeFormModal();
        IsUpdated();
        if (
          visitDetailById &&
          visitDetailById.Status &&
          visitDetailById.Status.key === visitStatus.scheduled
        ) {
          statusHandler(visitStatus.pendingnotes, visitDetail);
        }
      }
    });
  };
  const validate = (values) => {
    let error = {};
    if (
      values.Vital_Signs &&
      values.Vital_Signs.length > 0 &&
      vitalSignsValidation(values?.Vital_Signs[0])
    ) {
      if (!values.autodate) {
        error["autodate"] = "Required";
      }
      if (!("vitalreportedAgency" in values)) {
        error["vitalreportedAgency"] = "Required";
      } else if (!values.vitalreportedAgency) {
        error["vitalreportedAgency"] =
          "Please call the Agency to report the abnormal vital sign(s).";
      }
      if (!("vitalreportedMD" in values)) {
        error["vitalreportedMD"] = "Required";
      }
    }

    let vitalSignArray = [];

    values.Vital_Signs &&
      values.Vital_Signs.map((item) => {
        let vitalSignErrorObject = {};
        let objectKeys = Object.keys(item);
        vitalSignRequired.map((itemKey) => {
          if (objectKeys.indexOf(itemKey) === -1) {
            objectKeys.push(itemKey);
          }
        });
        objectKeys.map((itemKey) => {
          if (
            itemKey === "selfMonitoring" &&
            !vitalSignRequired.includes(itemKey) &&
            (!item[itemKey] || item[itemKey].length === 0)
          ) {
            vitalSignErrorObject["selfMonitoring"] =
              "Please Check At least one.";
          } else if (
            vitalSignRequired.includes(itemKey) &&
            itemKey !== "selfMonitoring" &&
            (!item[itemKey] || item[itemKey].trim() === "")
          ) {
            vitalSignErrorObject[itemKey] = "Required.";
          } else if (
            item[itemKey] &&
            itemKey !== "selfMonitoring" &&
            vitalSignsThreshold[itemKey] &&
            (Number(item[itemKey]) <
              Number(vitalSignsThreshold[itemKey]["min"]) ||
              Number(item[itemKey]) >
                Number(vitalSignsThreshold[itemKey]["max"]))
          ) {
            vitalSignErrorObject[itemKey] = "Out of range";
          }
        });
        vitalSignArray.push(vitalSignErrorObject);
      });
    error["Vital_Signs"] = vitalSignArray;
    if (
      values?.Vital_Signs &&
      values?.Vital_Signs.length > 0 &&
      vitalSignsValidation(values?.Vital_Signs[0]) &&
      "vitalreportedAgency" in values &&
      "vitalreportedMD" in values
    ) {
      error["Vital_Signs"] = [];
    }
    if (!values.visit_date) {
      error["visit_date"] = "Required.";
    }
    if (!values.subjective) {
      error["subjective"] = "Required.";
    }
    if (!values.Interventions || values.Interventions.length === 0) {
      error["Interventions"] = "Required.";
    }
    if (!values.Interventions_Educations_input) {
      error["Interventions_Educations_input"] = "Required.";
    }
    if (!values.Patient_Response_input) {
      error["Patient_Response_input"] = "Required.";
    }
    if (!values.home_reason) {
      error["home_reason"] = "required";
    }
    if (
      !values.HomeboundEligibility ||
      values.HomeboundEligibility?.length === 0
    ) {
      error["HomeboundEligibility"] = "Required.";
    }
    if (!values.Patient_Response_input) {
      error["Patient_Response_input"] = "required";
    }

    if (!values.time_in) {
      error["time_in"] = "Required.";
    }
    if (!values.time_out) {
      error["time_out"] = "Required.";
    }

    if (values.time_in && values.time_out) {
      const timeIn = new Date(`2000-01-01T${values.time_in}`);
      const timeOut = new Date(`2000-01-01T${values.time_out}`);
      const difference = Math.abs(timeOut - timeIn) / (1000 * 60);

      if (difference < 45) {
        error.time_out = "Time Out must be at least 45 minutes after time in.";
      }
    }

    let Pain_array = [];
    if (
      values.painStatus &&
      values.painStatus.length > 0 &&
      values.painStatus.includes("noPain")
    ) {
    }
    values.Pain &&
      values.Pain.map((item, index) => {
        let ErrorObject = {};
        if (!values.painStatus.includes("noPain") || !values.painStatus) {
          if (item.rating) {
            if (item.scale.length === 0) {
              ErrorObject["scale"] = "Required.";
            } else if (
              (item.scale.includes("Other") && !item.scaleInput) ||
              item.scaleInput?.trim() === ""
            ) {
              ErrorObject["scaleInput"] = "Required.";
            }
            if (!item.location) {
              ErrorObject["location"] = "Required.";
            }

            if (!item?.description || item?.description.length == 0) {
              ErrorObject["description"] = "Required";
            }
            if (
              (item.worseReason.includes("Other") && !item.worseReasonInput) ||
              item.worseReasonInput?.trim() === ""
            ) {
              ErrorObject["worseReasonInput"] = "Required.";
            }
            if (
              (item.painRelieve.includes("Other") && !item.painRelieveInput) ||
              item.painRelieveInput?.trim() === ""
            ) {
              ErrorObject["painRelieveInput"] = "Required.";
            }
          } else {
            ErrorObject["rating"] = "Required.";
          }
        }
        if (
          values.painStatus &&
          values.painStatus.length > 0 &&
          values.painStatus.includes("patientNotCommunicable")
        ) {
          ErrorObject = {};
          if (item.patientNotCommunicableReason.length === 0) {
            ErrorObject["patientNotCommunicableReason"] = "Required.";
          }
          if (
            item.patientNotCommunicableReason.includes("Other") &&
            (!item.patientNotCommunicableInput ||
              item.patientNotCommunicableInput.trim() === "")
          ) {
            ErrorObject["patientNotCommunicableInput"] = "Required.";
          }
        }
        Pain_array.push(ErrorObject);
      });
    error["Pain"] = Pain_array;
    if (
      values.painStatus &&
      values.painStatus.length > 0 &&
      (values.painStatus.includes("noPain") ||
        values.painStatus.includes("patientNotCommunicable"))
    ) {
      error["Pain"] = [];
    }

    if (isPainHigh(values.Pain)) {
      if (!values.highPaindate) {
        error["highPaindate"] = "Required.";
      }
      if (!("highPainReportedToAgency" in values)) {
        error["highPainReportedToAgency"] = "Required.";
      } else if (!values.highPainReportedToAgency) {
        error["highPainReportedToAgency"] =
          "Please call the Agency to report the abnormal vital sign(s).";
      }
      if (!("highPainReportedToMD" in values)) {
        error["highPainReportedToMD"] = "Required.";
      }
    }
    const goalArray = [];
    let goalscount = 0;
    values.goals &&
      values.goals.map((goalItem) => {
        let errobject = {};
        if (goalItem.goal && !goalItem.effectiveDate) {
          errobject.effectiveDate = "Date is required.";
        }
        if (goalItem.goal && !goalItem.currentStatus) {
          errobject.currentStatus = "Status is required.";
        }
        if (goalItem.goal && !goalItem.percentageGoalMet) {
          errobject.percentageGoalMet = "Required.";
        }
        if (
          goalItem.currentStatus &&
          goalItem.effectiveDate &&
          !goalItem.goal
        ) {
          errobject.goal = "Required.";
        }
        if (goalItem.goal && goalItem.currentStatus && goalItem.effectiveDate) {
          goalscount += 1;
        }
        goalArray.push(errobject);
      });

    if (goalscount < 3) {
      setError(true);
    } else {
      setError(false);
    }

    error.goals = goalArray;
    let CHHASupervisioryArray = [];
    let Plans_for_next_visit_Array = [];

    if (Array.isArray(values.CHHA_Supervisory_Visits)) {
      values.CHHA_Supervisory_Visits.map((item) => {
        let ErrorObject = {};
        if (item.Supervision_performed_this_visit === "yes") {
          Object.keys(item).map((key) => {
            if (
              key === "Present" &&
              item[key] === "yes" &&
              (!item.Present_input || item.Present_input.trim() === "")
            ) {
              ErrorObject["Present_input"] = "Required.";
            }
            if (!item[key]) {
              ErrorObject[key] = "Required.";
            } else if (
              key === "POC_changed" &&
              item[key] === "yes" &&
              (!item.specifyDetails || item.specifyDetails?.trim() === "")
            ) {
              ErrorObject["specifyDetails"] = "Required";
            } else if (
              key !== "Present" &&
              key !== "POC_changed" &&
              item[key] === "no" &&
              (!item.specifyDetails || item.specifyDetails?.trim() === "")
            ) {
              ErrorObject["specifyDetails"] = "Required.";
            }
          });
        }
        CHHASupervisioryArray.push(ErrorObject);
      });
    }

    if (Array.isArray(values.Plans_for_next_visit)) {
      values.Plans_for_next_visit.map((item) => {
        let ErrorObject = {};
        let isValid = false;
        Object.keys(item).map((key) => {
          if (Array.isArray(item[key]) && item[key].length > 0) {
            isValid = true;
          }
          if (
            (Array.isArray(item[key]) &&
              item[key].length > 0 &&
              !item[`${key}_input`]) ||
            !item[`${key}_input`]?.trim() === ""
          ) {
            ErrorObject[`${key}_input`] = "required";
          }
        });
        if (!isValid) {
          setPlansError(true);
        } else {
          setPlansError(false);
        }
        Plans_for_next_visit_Array.push(ErrorObject);
      });
    }
    error["CHHA_Supervisory_Visits"] = CHHASupervisioryArray;
    error["Plans_for_next_visit"] = Plans_for_next_visit_Array;

    if (!values.dischargeReason || values.dischargeReason.length === 0) {
      error["dischargeReason"] = "Required.";
    }

    if (
      values.dischargeReason &&
      values.dischargeReason.indexOf("Other") > -1 &&
      !values.dischargeReason_other
    ) {
      error["dischargeReason_other"] = "Required.";
    }

    // if (values.dischargeReason_other) {
    //   delete error["dischargeReason"];
    // }

    if (!values.instructionGivenTo || values.instructionGivenTo.length === 0) {
      error["instructionGivenTo"] = "Required.";
    } else if (
      values.instructionGivenTo &&
      values.instructionGivenTo.length > 0 &&
      values.instructionGivenTo.includes("caregiver") &&
      (!values.caregiverName || values.caregiverName.trim() === "")
    ) {
      error["caregiverName"] = "Required.";
    }
    if (
      !values.dischargeInstruction ||
      values.dischargeInstruction.trim() === ""
    ) {
      error["dischargeInstruction"] = "Required.";
    }
    if (
      values.instructionNotUnderstood &&
      (!values.caregiverDueTo || values.caregiverDueTo.trim() === "")
    ) {
      error["caregiverDueTo"] = "Required.";
    }

    if (
      values.care_coordination &&
      values.care_coordination.includes("Others") &&
      (!values.care_coordinationTextBox || values.care_coordinationTextBox.text)
    ) {
      error["care_coordinationTextBox"] = "Required.";
    }
    if (values.care_coordination && values.care_coordination.length > 0) {
      if (!values.Coordination_date) {
        error["Coordination_date"] = "Required.";
      }
      if (!values.time) {
        error["time"] = "Required.";
      }
      if (!values.Coordination_Details_input) {
        error["Coordination_Details_input"] = "Required.";
      }
    }
    return error;
  };
  const initialValues = () => {
    let initialValues = {
      Vital_Signs: [
        {
          pulse: "",
          bpSystolic: "",
          bpDiastolic: "",
          respiration: "",
          temperature: "",
          selfMonitoring: "",
        },
      ],
      patientNotCommunicate_Options_data: [],
      Pain: [
        {
          painRelieve: [],
          worseReason: [],
          description: [],
          cause: "",
          location: "",
          scale: [],
          rating: "",
          patientNotCommunicableReason: [],
          patientNotCommunicableInput: "",
        },
      ],
      CHHA_Supervisory_Visits: [
        {
          Supervision_performed_this_visit: "",
          Present: "",
          Following_POC: "",
          POC_changed: "",
          Patient_Responsible_Party: "",
          Communicates_openly: "",
          Reports_changes: "",
          Demonstrates_competency: "",
          Honors_patient: "",
          Complies_with_infection_prevention: "",
          Patient_Staff_compatible: "",
        },
      ],

      goals: [
        {
          goals: "",
          effectiveDate: "",
          currentStatus: "",
          percentageGoalMet: "",
        },
        {
          goals: "",
          effectiveDate: "",
          currentStatus: "",
          percentageGoalMet: "",
        },
        {
          goals: "",
          effectiveDate: "",
          currentStatus: "",
          percentageGoalMet: "",
        },
        {
          goals: "",
          effectiveDate: "",
          currentStatus: "",
          percentageGoalMet: "",
        },
        {
          goals: "",
          effectiveDate: "",
          currentStatus: "",
          percentageGoalMet: "",
        },
        {
          goals: "",
          effectiveDate: "",
          currentStatus: "",
          percentageGoalMet: "",
        },
      ],
      painStatus: [],
      care_coordination: [],
      scale_Used_data: [],
      description_data: [],
      cause_pain_data: [],
      relieves_pain_data: [],
      balance_additional: "",
      Interventions: [],
      Interventions_Educations_input: "",
    };

    let goals = [];

    for (let index = 0; index < totalDefaultGoals; index++) {
      let goalObj = JSON.parse(JSON.stringify(defaultGoalObj));

      goals.push(goalObj);
    }
    initialValues.goals = goals;

    if (staffFormData && staffFormData.formData) {
      let goals = [];

      for (let index = 0; index < totalDefaultGoals; index++) {
        let goalObj = JSON.parse(JSON.stringify(defaultGoalObj));
        if (staffDocument && staffDocument.length > 0) {
          goalObj["effectiveDate"] =
            staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
        }
        if (
          staffFormData.formData &&
          staffFormData.formData.goals &&
          staffFormData.formData.goals.length > 0 &&
          staffFormData.formData.goals[index]
        ) {
          goalObj = staffFormData.formData.goals[index];
        }
        goals.push(goalObj);
      }

      let formData = JSON.parse(JSON.stringify(staffFormData));
      formData.formData.goals = goals;
      return { ...formData.formData, id: formData.id };
    }
    if (
      staffFormData &&
      staffFormData.formData &&
      staffFormData.formData.goals
    ) {
      initialValues.goals = staffFormData.formData.goals;
    }
    if (staffDetails) {
      initialValues.PatientName =
        staffDetails.lastName + "," + staffDetails.firstName;
      initialValues.mrNo =
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.mrn;
      initialValues.phoneNumber =
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.phoneNo;
      initialValues.physicianNPI =
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.npi;

      initialValues.dob =
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.dateOfBirth.split("T")[0];
      initialValues.agency =
        staffDetails &&
        staffDetails.createdByUser &&
        staffDetails.createdByUser.name;
      initialValues.diagnosis =
        staffDetails &&
        staffDetails.staffingDiagnosisdetails.length > 0 &&
        staffDetails.staffingDiagnosisdetails.map((item) => ({
          value: item.masterDataId,
          label: item.masterData.displayName,
        }));
    }
    if (currentUser) {
      initialValues.therapist = currentUser.lastName
        ? `${currentUser.lastName + ", " + currentUser?.firstName} ${
            currentUser.userDetail && currentUser.userDetail.discipline
              ? `(${currentUser.userDetail.discipline.toUpperCase()})`
              : ""
          }  `
        : "";
    }
    if (staffDocument && staffDocument.length > 0) {
      initialValues.visit_date =
        staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
      initialValues.highPaindate =
        staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
      initialValues.autodate =
        staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
    }
    let date = "";
    if (visitDetail && visitDetail.date) {
      date = visitDetail.date.split("T")[0];
    }
    goals = [];
    for (let index = 0; index < totalDefaultGoals; index++) {
      let goalObj = JSON.parse(JSON.stringify(defaultGoalObj));
      if (staffDocument && staffDocument.length > 0) {
        goalObj["effectiveDate"] = date;
      }

      if (
        lastGoals &&
        lastGoals.goals &&
        lastGoals.goals.length > 0 &&
        lastGoals.goals[index]
      ) {
        goalObj = lastGoals.goals[index];
      }
      goals.push(goalObj);
    }
    if (lastGoals && lastGoals.homeboundEligibility) {
      initialValues.HomeboundEligibility = lastGoals.homeboundEligibility;
    }
    if (lastGoals && lastGoals.interventions) {
      initialValues.Interventions = lastGoals.interventions;
    }

    initialValues.goals = goals;

    if (visitDetail && visitDetail.date) {
      date = visitDetail.date.split("T")[0];

      if (
        staffDetails &&
        staffDetails.staffInitialVisit &&
        staffDetails.staffInitialVisit.length > 0 &&
        staffDetails.staffInitialVisit[0].anticipatedSocDate
      ) {
        date =
          staffDetails.staffInitialVisit[0].anticipatedSocDate.split("T")[0];
      }
      if (confirmDate) {
        date = confirmDate;
      }
      initialValues.visit_date = date;
      initialValues.highPaindate = date;
      initialValues.autodate = date;
    }
    return initialValues;
  };

  // useEffect(() => {
  //   dispatch(
  //     getStaffFormData({
  //       staffId: staffDetails?.id,
  //       formTypeName: staffFormTypesConstants.ReAssessment,
  //     })
  //   ).then((res) => {
  //     if (!res.payload.data) {
  //       dispatch(
  //         getStaffFormData({
  //           staffId: staffDetails?.id,
  //           formTypeName: staffFormTypesConstants.Discharge,
  //         })
  //       );
  //     }
  //   });
  // }, []);
  const getDischargeForm = () => {
    let documentObject =
      staffDocument &&
      staffDocument.length > 0 &&
      staffDocument[0].episodeDocumentations.find(
        (item) =>
          item.episodeVisit.name === staffFormTypesConstants.RegularDischarge
      );
    if (documentObject) {
      dispatch(
        getStaffFormdataById({
          episodeDocumentId: Number(documentObject.id),
          formTypeName: staffFormTypesConstants.RegularDischarge,
        })
      );
    }
  };
  useEffect(() => {
    if (!dataAvailable && staffDocument) {
      getDischargeForm();
    }
  }, [staffDocument, dataAvailable]);

  useEffect(() => {
    if (staffDocument && staffDocument.length > 0) {
      const episodeId = staffDocument[0].id;
      dispatch(getLastGoalsValue({ episodeId }));
    }
  }, [staffDocument]);

  const saveForm = (image) => {
    const decodedImage = atob(image.split(",")[1]);
    const blob = new Blob([decodedImage], { type: "image/jpeg" });
    const imageUrl = URL.createObjectURL(blob);
    setDisplaySignature(image);
    toast.success("Signature added.");
  };

  const getTotalVisitGroupStatus = () => {
    let statusGroup = {};
    if (
      staffDocument &&
      staffDocument.length > 0 &&
      staffDocument[0].episodeDocumentations &&
      staffDocument[0].episodeDocumentations.length > 0
    ) {
      staffDocument[0].episodeDocumentations.map((item) => {
        if (item.Status) {
          if (statusGroup[item.Status.name]) {
            statusGroup[item.Status.name] = statusGroup[item.Status.name] + 1;
          }
          statusGroup[item.Status.name] = 1;
        }
      });
    }
    return statusGroup;
  };

  const getFormStatus = () => {
    let status = "";
    if (visitDetailById) {
      status = visitDetailById.Status.key;
      if (
        visitDetailById.patientFormList &&
        visitDetailById.patientFormList.length > 0
      ) {
        let form = visitDetailById.patientFormList.find(
          (item) =>
            item.name == staffFormTypesConstants.RegularDischarge ||
            item.name == staffFormTypesConstants.OASISDC
        );
        if (form) {
          status = form.Status.key;
        }
      }
    }
    return status;
  };

  const getGoalPercentage = () => {
    let ranges = [];
    for (let i = 0; i < 100; i += 10) {
      let start = i;
      let end = i + 10;
      ranges.push(`${start === 0 ? start : start + 1} - ${end}%`);
    }
    return ranges;
  };

  const checkPreviousGoal = (value, index) => {
    let disable = false;

    if (
      lastGoals &&
      lastGoals.goals &&
      lastGoals.goals[index] &&
      lastGoals.goals[index].goal &&
      lastGoals.goals[index].goal.toLowerCase() === value.goal &&
      value.goal.toLowerCase()
    ) {
      disable = true;
    } else {
      disable = false;
    }

    return disable;
  };

  const disableByVisitStatus = () => {
    let disabled = true;
    const isCurrentUserAdminOrSupAdminOrAgency =
      currentUser?.Role?.key === currentRole.admin ||
      currentUser?.Role?.key === currentRole.superadmin ||
      currentUser?.Role?.key === currentRole.agency;

    const isCurrentUserSupervisingOrAssistant =
      currentUser?.Role?.key === currentRole.supervisingtherapist ||
      currentUser?.Role?.key === currentRole.assistanttherapist;

    const isVisitInReviewOrCompleted =
      visitDetailById?.Status?.key === "needreview" ||
      visitDetailById?.Status?.key === "completed";

    if (isCurrentUserAdminOrSupAdminOrAgency) {
      disabled = true;
    } else if (
      isCurrentUserSupervisingOrAssistant &&
      isVisitInReviewOrCompleted
    ) {
      disabled = true;
    } else {
      disabled = false;
    }
    if (toSave) disabled = false;

    return disabled;
  };

  const optionsByName = (name, startFrom, length) => {
    const optionArray = Array.from(
      { length: length ? length : 4 },
      (_, index) => {
        return {
          value: `${name} ${index + startFrom}`,
          label: `${name} ${index + startFrom}`,
        };
      }
    );
    return optionArray;
  };
  return (
    <Container>
      <Row>
        <Col>
          {" "}
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={useMemo(
              (e) => initialValues(e),
              [staffFormData, staffDetails, lastGoals]
            )}
            mutators={{
              ...arrayMutators,
            }}
            render={({
              handleSubmit,
              values,
              touched,
              submitFailed,
              errors,
              valid,
              initialValues,
              form,
            }) => (
              <form
                onSubmit={(e) => {
                  if (!valid)
                    toast.error("Please complete all required fields.");
                  handleSubmit(e);
                }}
              >
                <div className={`${!isPrint ? "container" : "row"}`}>
                  <div
                    className={`discharge-section ${
                      isPrint ? "bg-gray-main-form-print" : ""
                    }`}
                  >
                    <div className="row mein-white-from-background">
                      <div className="col-md-6 border-right-1">
                        <div className="fileds-from">
                          <label>MR# :</label>
                          <Field
                            name="mrNo"
                            component="input"
                            type="text"
                            placeholder=""
                            className="input-textSize"
                          >
                            {({ input, meta }) => (
                              <div className="w-50">
                                <input
                                  maxlength="45"
                                  className="form-control bg-white"
                                  {...input}
                                  type="text"
                                  placeholder=""
                                  disabled
                                />
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="fileds-from">
                          <label>Patient :</label>
                          <Field
                            name="PatientName"
                            component="input"
                            type="text"
                            placeholder=""
                            className="input-textSize"
                          >
                            {({ input, meta }) => (
                              <div className="w-50">
                                <input
                                  maxlength="45"
                                  className="form-control bg-white"
                                  {...input}
                                  type="text"
                                  disabled
                                  placeholder=""
                                />
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="fileds-from">
                          <label>DOB :</label>
                          <Field
                            name="dob"
                            component="input"
                            type="text"
                            placeholder=""
                            className="input-textSize"
                          >
                            {({ input, meta }) => (
                              <div className="w-50">
                                <input
                                  maxlength="45"
                                  className="form-control bg-white"
                                  {...input}
                                  type="date"
                                  placeholder=""
                                  disabled
                                />
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="fileds-from">
                          <label>Agency :</label>
                          <Field
                            name="agency"
                            component="input"
                            type="text"
                            placeholder=""
                            className="input-textSize"
                          >
                            {({ input, meta }) => (
                              <div className="w-50">
                                <input
                                  maxlength="45"
                                  className="form-control bg-white"
                                  {...input}
                                  type="text"
                                  disabled
                                  placeholder=""
                                />
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="col-md-6 ">
                        <div className="fileds-from">
                          <label>Visit Date :</label>
                          <Field
                            name="visit_date"
                            component="input"
                            type="text"
                            placeholder=""
                            className="input-textSize"
                          >
                            {({ input, meta }) => (
                              <div className="w-50">
                                <input
                                  className="form-control "
                                  {...input}
                                  maxlength="45"
                                  type="date"
                                  placeholder=""
                                />
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="fileds-from">
                          <label>Time In :</label>
                          <Field
                            name="time_in"
                            component="input"
                            type="text"
                            placeholder=""
                            className="input-textSize"
                          >
                            {({ input, meta }) => (
                              <div className="w-50">
                                <input
                                  maxlength="45"
                                  className="form-control"
                                  {...input}
                                  type="time"
                                  placeholder=""
                                />
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="fileds-from">
                          <label>Time Out :</label>
                          <Field
                            name="time_out"
                            component="input"
                            type="text"
                            placeholder=""
                            className="input-textSize"
                          >
                            {({ input, meta }) => (
                              <div className="w-50">
                                <input
                                  className="form-control"
                                  maxlength="45"
                                  {...input}
                                  type="time"
                                  placeholder=""
                                />
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger w-100 "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="fileds-from">
                          <label>Therapist :</label>
                          <Field
                            name="therapist"
                            component="input"
                            type="text"
                            placeholder=""
                            className="input-textSize"
                          >
                            {({ input, meta }) => (
                              <div className="w-50">
                                <input
                                  className="form-control bg-white"
                                  maxlength="45"
                                  {...input}
                                  type="text"
                                  disabled
                                  placeholder=""
                                />
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>

                    <div className="row mein-white-from-background h5_line pt-0">
                      <h5 className="py-4 text-decoration-underline">
                        Diagnosis :
                      </h5>
                      <div className="col-md-12 mb-3">
                        <div>
                          {toSave ? (
                            <div>
                              {values.diagnosis &&
                                values.diagnosis.map((item) => {
                                  return <span>{`${item.label},`}</span>;
                                })}
                            </div>
                          ) : (
                            <Field
                              name="diagnosis"
                              component="input"
                              type="text"
                              placeholder=""
                              className="input-textSize"
                            >
                              {({ input, meta }) => (
                                <div>
                                  <Select
                                    {...input}
                                    isMulti
                                    className="bg-white"
                                    options={optionsByName("ICD CODE", 10, 7)}
                                    isDisabled={true}
                                  />
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger "
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </div>
                              )}
                            </Field>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mein-white-from-background h5_line pt-0">
                      <h5 className="py-4 text-decoration-underline">
                        {" "}
                        Subjective :
                      </h5>
                      <div className="col-md-12 mb-3">
                        <div>
                          <Field
                            name="subjective"
                            component="input"
                            type="text"
                            placeholder=""
                            className="input-textSize"
                          >
                            {({ input, meta }) => (
                              <div>
                                {toSave ? (
                                  <p>{values.subjective}</p>
                                ) : (
                                  <textarea
                                    maxlength="320"
                                    {...input}
                                    type="text"
                                    placeholder=""
                                    className="Additional_text"
                                  ></textarea>
                                )}

                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger w-100 meta_err">
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className="row mein-white-from-background h5_line pt-0">
                      <h5 className="py-4 text-decoration-underline">
                        Vital Signs :
                      </h5>
                      <FieldArray name="Vital_Signs">
                        {({ fields, meta }) => (
                          <>
                            {fields.map((name, index) => (
                              <>
                                <div className="row align-items-center Vital_Signs_row_gap">
                                  <div className="col-md-6">
                                    <div className="d-flex justify-content-between">
                                      <h6 className="text-align-left w-75">
                                        Pulse :
                                      </h6>
                                      <Field
                                        name={`${name}.pulse`}
                                        component="input"
                                        className="form-control ptinput"
                                        type="number"
                                      >
                                        {({ input, meta }) => (
                                          <div className="w-75">
                                            <input
                                              maxlength="3"
                                              className="form-control ptinput"
                                              {...input}
                                              type="text"
                                              placeholder=""
                                              onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                            />
                                            {meta.error && meta.touched && (
                                              <p
                                                className="alert alert-danger "
                                                role="alert"
                                              >
                                                {meta.error}
                                              </p>
                                            )}
                                          </div>
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                  <div className="col-md-6 d-flex gap-3">
                                    <div className="radio-of-forms gap-3">
                                      {vitalSignPulseType.map((item, index) => (
                                        <div key={`Pulse_${index}`}>
                                          <Field
                                            name={`${name}.pulseType`}
                                            component="input"
                                            type={item?.type}
                                            value={item?.label}
                                          />
                                          <label className="mx-2">
                                            {item.label}{" "}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="row align-items-center Vital_Signs_row_gap">
                                  <div className="col-md-6">
                                    <div className="d-flex justify-content-between">
                                      <h6>BP :</h6>
                                      <div className="d-flex w-50">
                                        <Field
                                          name={`${name}.bpSystolic`}
                                          component="input"
                                          value=""
                                        >
                                          {({ input, meta }) => (
                                            <div className="w-50">
                                              <input
                                                maxlength="3"
                                                className="form-control ptinput"
                                                {...input}
                                                type="text"
                                                placeholder=""
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[0-9]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                              />
                                              {meta.error && meta.touched && (
                                                <p
                                                  className="alert alert-danger "
                                                  role="alert"
                                                >
                                                  {meta.error}
                                                </p>
                                              )}
                                            </div>
                                          )}
                                        </Field>
                                        <span className="mr-3 primery_span_vital_signs ml-3">
                                          /
                                        </span>
                                        <Field
                                          name={`${name}.bpDiastolic`}
                                          component="input"
                                          value=""
                                        >
                                          {({ input, meta }) => (
                                            <div className="w-50">
                                              <input
                                                maxlength="3"
                                                className="form-control ptinput"
                                                {...input}
                                                type="text"
                                                placeholder=""
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[0-9]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                              />
                                              {meta.error && meta.touched && (
                                                <p
                                                  className="alert alert-danger "
                                                  role="alert"
                                                >
                                                  {meta.error}
                                                </p>
                                              )}
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="d-flex">
                                      <label className="me-2">Position :</label>
                                      <div className="radio-of-forms gap-3">
                                        {vitalSignBP.map((item, index) => (
                                          <div key={`BPPosition_${index}`}>
                                            <Field
                                              name={`${name}.BPType`}
                                              component="input"
                                              type={item?.type}
                                              value={item?.label}
                                            ></Field>
                                            <label className="mx-2">
                                              {item.label}{" "}
                                            </label>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row align-items-center Vital_Signs_row_gap">
                                  <div className="col-md-6">
                                    <div className="d-flex justify-content-between">
                                      <h6 className="text-align-left w-75">
                                        Temperature :
                                      </h6>

                                      <Field
                                        name={`${name}.temperature`}
                                        component="input"
                                        type="number"
                                        value=""
                                      >
                                        {({ input, meta }) => (
                                          <div className="w-75">
                                            <input
                                              maxlength="3"
                                              className="form-control ptinput"
                                              {...input}
                                              type="text"
                                              placeholder=""
                                              onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                            />
                                            {meta.error && meta.touched && (
                                              <p
                                                className="alert alert-danger "
                                                role="alert"
                                              >
                                                {meta.error}
                                              </p>
                                            )}
                                          </div>
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="d-flex align-items-center">
                                      <label className=" margin-bottom-18 me-2">
                                        Degrees Method :
                                      </label>
                                      <div className="radio-of-forms gap-3">
                                        {vitalSignTemperature.map(
                                          (item, index) => (
                                            <div key={`Temperaute_${index}`}>
                                              <Field
                                                name={`${name}.temperatureUnit`}
                                                component="input"
                                                type={item?.type}
                                                value={item?.label}
                                              ></Field>
                                              <label className="mx-2">
                                                {item.label}{" "}
                                              </label>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row Vital_Signs_row_gap">
                                  <div className="col-md-6">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <h6 className="text-align-left">
                                        Respiration :
                                      </h6>
                                      <Field
                                        name={`${name}.respiration`}
                                        component="input"
                                        type="number"
                                      >
                                        {({ input, meta }) => (
                                          <div className="w-50">
                                            <input
                                              maxlength="2"
                                              className="form-control ptinput"
                                              {...input}
                                              type="text"
                                              placeholder=""
                                              onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                            />
                                            {meta.error && meta.touched && (
                                              <p
                                                className="alert alert-danger "
                                                role="alert"
                                              >
                                                {meta.error}
                                              </p>
                                            )}
                                          </div>
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                  <div className="col-md-6 d-flex">
                                    <div className="radio-of-forms  gap-3">
                                      {vitalSignRespiration.map(
                                        (item, index) => (
                                          <div key={`respiration_${index}`}>
                                            <Field
                                              name={`${name}.respirationType`}
                                              component="input"
                                              type={item?.type}
                                              value={item?.label}
                                            />
                                            <label className="mx-2">
                                              {item.label}{" "}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row align-items-center Vital_Signs_row_gap">
                                  <div className="col-md-4">
                                    <h6>Patient/Caregiver self monitoring :</h6>
                                  </div>
                                  <div className="col-md-8 d-flex gap-3 align-items-center">
                                    <div className="radio-of-forms">
                                      {selfMonitoring.map((item, index) => (
                                        <div key={`selfMonitorign_${index}`}>
                                          <Field
                                            name={`${name}.selfMonitoring`}
                                            component="input"
                                            type={item?.type}
                                            value={item?.label}
                                          ></Field>
                                          <label className="mx-2">
                                            {item.label}{" "}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  {touched[
                                    `Vital_Signs[${index}].selfMonitoring`
                                  ] &&
                                    errors.Vital_Signs[index] &&
                                    errors.Vital_Signs[index]
                                      ?.selfMonitoring && (
                                      <p
                                        className="alert alert-danger "
                                        role="alert"
                                      >
                                        {
                                          errors.Vital_Signs[index]
                                            .selfMonitoring
                                        }
                                      </p>
                                    )}
                                </div>
                                <div className="row align-items-center">
                                  <div className="col-md-12">
                                    <h6 className="no-wrap-white-space mb-2">
                                      Additional Vital Sign Details :
                                    </h6>

                                    <Field name={`${name}.additonalText`}>
                                      {({ input, meta }) => (
                                        <div>
                                          {toSave ? (
                                            <p>
                                              {values["Vital_Signs"] &&
                                                values["Vital_Signs"].length >
                                                  0 &&
                                                values["Vital_Signs"][index][
                                                  "additonalText"
                                                ]}
                                            </p>
                                          ) : (
                                            <textarea
                                              maxlength="320"
                                              {...input}
                                              type="text"
                                              placeholder=""
                                              className="Additional_text"
                                            ></textarea>
                                          )}
                                        </div>
                                      )}
                                    </Field>

                                    {/* <Field
                                      name={`${name}.additonalText`}
                                      className="Additional_text "
                                      component="textarea"
                                      placeholder="Free text"
                                    /> */}
                                  </div>
                                </div>
                              </>
                            ))}
                          </>
                        )}
                      </FieldArray>

                      <div className="row mt-5">
                        {values.Vital_Signs &&
                          values.Vital_Signs.length > 0 &&
                          vitalSignsValidation(values.Vital_Signs[0]) && (
                            <>
                              <div className="d-flex align-items-center justify-content-between col-md-8">
                                <label>Date</label>
                                <Field
                                  name="autodate"
                                  component="input"
                                  className="input-textSize"
                                >
                                  {({ input, meta }) => (
                                    <div className="w-40">
                                      <input
                                        className="form-control"
                                        {...input}
                                        type="date"
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger "
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </div>
                              <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                                <label htmlFor="">
                                  Was the abnormal vital(s) reported to the
                                  Agency ?
                                </label>
                                <div className="d-flex align-items-center justify-content-between w-40 px-1">
                                  <div className="radio-of-forms mr-5">
                                    <Field
                                      name={`vitalreportedAgency`}
                                      type="radio"
                                      component="input"
                                      value={true}
                                    >
                                      {({ input, meta }) => (
                                        <>
                                          <input
                                            type="radio"
                                            {...input}
                                            onChange={() =>
                                              input.onChange(true)
                                            }
                                            value={true}
                                          />
                                        </>
                                      )}
                                    </Field>
                                    <label> Yes </label>
                                  </div>
                                  <div className="radio-of-forms">
                                    <Field
                                      name={`vitalreportedAgency`}
                                      type="radio"
                                      component="input"
                                      value={false}
                                    >
                                      {({ input, meta }) => (
                                        <>
                                          <input
                                            type="radio"
                                            {...input}
                                            onChange={() =>
                                              input.onChange(false)
                                            }
                                            value={false}
                                          />
                                          {}
                                        </>
                                      )}
                                    </Field>
                                    <label>No </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-4">
                                <div className="col-md-4">
                                  <div className="d-flex gap-3">
                                    {" "}
                                    <p className="fw-bold">Agency Name :</p>
                                    <p>
                                      {staffDetails &&
                                        staffDetails.createdByUser &&
                                        staffDetails.createdByUser.name}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="d-flex gap-3">
                                    <p className="fw-bold">Agency Phone :</p>
                                    <p className="text-nowrap">
                                      {staffDetails &&
                                        staffDetails.createdByUser &&
                                        staffDetails.createdByUser.userDetail &&
                                        staffDetails.createdByUser.userDetail
                                          .phone}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {errors.vitalreportedAgency &&
                                touched.vitalreportedAgency && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {errors.vitalreportedAgency}
                                  </p>
                                )}
                              <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                                <label htmlFor="">
                                  Was the abnormal vital(s) reported to the MD ?
                                </label>
                                <div className="d-flex align-items-center justify-content-between w-40 px-1">
                                  <div className="radio-of-forms mr-5">
                                    <Field
                                      name={`vitalreportedMD`}
                                      type="radio"
                                      component="input"
                                      value={true}
                                    >
                                      {({ input, meta }) => (
                                        <>
                                          <input
                                            type="radio"
                                            {...input}
                                            onChange={() =>
                                              input.onChange(true)
                                            }
                                            value={true}
                                          />
                                        </>
                                      )}
                                    </Field>
                                    <label> Yes </label>
                                  </div>

                                  <div className="radio-of-forms">
                                    <Field
                                      name={`vitalreportedMD`}
                                      type="radio"
                                      component="input"
                                      value={false}
                                    >
                                      {({ input, meta }) => (
                                        <>
                                          <input
                                            type="radio"
                                            {...input}
                                            onChange={() =>
                                              input.onChange(false)
                                            }
                                            value={false}
                                          />
                                        </>
                                      )}
                                    </Field>
                                    <label>No </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-4">
                                <div className="col-md-4">
                                  <div className="d-flex gap-3 ">
                                    <p className=" fw-bold">Physician Name :</p>
                                    <p>
                                      {staffDetails &&
                                        staffDetails.staffingDetails &&
                                        staffDetails.staffingDetails
                                          .physicianName}
                                    </p>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  {" "}
                                  <div className="d-flex  gap-3 ">
                                    <p className=" fw-bold">
                                      Physician Phone :
                                    </p>
                                    <p>
                                      {staffDetails &&
                                        staffDetails.staffingDetails &&
                                        staffDetails.staffingDetails
                                          .physicianPhoneNumber}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {errors.vitalreportedMD &&
                                touched.vitalreportedMD && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {errors.vitalreportedMD}
                                  </p>
                                )}
                              <div className="col-md-12 mt-4 mb-3">
                                <div>
                                  <Field
                                    name="vitalSign_name2"
                                    component="input"
                                    type="text"
                                    placeholder=""
                                    className="input-textSize"
                                  >
                                    {({ input, meta }) => (
                                      <>
                                        <label>
                                          <span className="text-ordered-list mr-1">
                                            Please document who you spoke with
                                            at the Agency and any instructions
                                            you were given.
                                          </span>
                                        </label>
                                        {toSave ? (
                                          <p>
                                            {values["vitalSign_name2"]
                                              ? values["vitalSign_name2"]
                                              : ""}
                                          </p>
                                        ) : (
                                          <textarea
                                            maxlength="320"
                                            className="Additional_text  w-100"
                                            {...input}
                                            placeholder=""
                                          />
                                        )}

                                        {meta.error && meta.touched && (
                                          <span className="meta_err">
                                            {meta.error}
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`discharge-section ${
                      isPrint ? "bg-gray-main-form-print" : ""
                    }`}
                  >
                    <div className="row mein-white-from-background h5_line pt-0">
                      <h5 className="py-4 text-decoration-underline">Pain :</h5>
                      <FieldArray name="Pain">
                        {({ fields, meta }) =>
                          fields.map((painItem, painIndex) => (
                            <div className="row">
                              {!values.painStatus.includes(
                                "patientNotCommunicable"
                              ) && (
                                <div className="col-md-6">
                                  <div className="radio-of-forms gap-3  mt-3">
                                    <Field
                                      name={`painStatus`}
                                      component="input"
                                      type="checkbox"
                                      value={"noPain"}
                                    />
                                    <label className="pl-0"> No Pain </label>
                                  </div>
                                </div>
                              )}
                              {!values.painStatus.includes("noPain") && (
                                <div className="col-md-6">
                                  <div className="radio-of-forms gap-3  mt-3">
                                    <Field
                                      name={`painStatus`}
                                      component="input"
                                      type="checkbox"
                                      value={"patientNotCommunicable"}
                                    />
                                    <label className="pl-0">
                                      {" "}
                                      Patient unable to communicate , non-verbal
                                      indicators demonstrated :
                                    </label>
                                  </div>
                                </div>
                              )}

                              {!values.painStatus.includes("noPain") &&
                                !values.painStatus.includes(
                                  "patientNotCommunicable"
                                ) && (
                                  <>
                                    <div className="col-md-6">
                                      <div className="my-3">
                                        <Field
                                          name={`${painItem}.rating`}
                                          component="select"
                                          className="paitiant-chart-input form-select"
                                        >
                                          {({ input, meta }) => (
                                            <>
                                              <select
                                                {...input}
                                                className="form-control"
                                              >
                                                <option hidden>Choose</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                              </select>
                                              {meta.error && meta.touched && (
                                                <p
                                                  className="alert alert-danger "
                                                  role="alert"
                                                >
                                                  {meta.error}
                                                </p>
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </div>
                                      <div className="my-3">
                                        <label className="">
                                          Scale Used : &nbsp;
                                        </label>
                                        {scale_Used_data.map((item) => (
                                          <>
                                            <Field
                                              name={`${painItem}.scale`}
                                              component="input"
                                              type="checkbox"
                                              value={item}
                                            />
                                            <label className="mx-2">
                                              {item}{" "}
                                            </label>
                                          </>
                                        ))}
                                        <div>
                                          {meta.error &&
                                            meta.touched &&
                                            errors?.Pain[painIndex] &&
                                            errors?.Pain[painIndex][
                                              "scale"
                                            ] && (
                                              <p
                                                className="alert alert-danger "
                                                role="alert"
                                              >
                                                {
                                                  errors.Pain[painIndex][
                                                    "scale"
                                                  ]
                                                }
                                              </p>
                                            )}
                                        </div>
                                        <Field name={`${painItem}.scaleInput`}>
                                          {({ input, meta }) => (
                                            <>
                                              <input
                                                {...input}
                                                className="form-control"
                                                type="text"
                                              />
                                              {meta.error && meta.touched && (
                                                <p
                                                  className="alert alert-danger "
                                                  role="alert"
                                                >
                                                  {meta.error}
                                                </p>
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </div>
                                      <div className="my-3 d-flex align-items-center justify-content-between">
                                        <label className="">
                                          {" "}
                                          Pain Location :
                                        </label>
                                        <Field name={`${painItem}.location`}>
                                          {({ input, meta }) => (
                                            <div className="w-75">
                                              <input
                                                {...input}
                                                className="form-control"
                                                type="text"
                                              />
                                              {meta.error && meta.touched && (
                                                <p
                                                  className="alert alert-danger "
                                                  role="alert"
                                                >
                                                  {meta.error}
                                                </p>
                                              )}
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                      <div className="my-3 d-flex align-items-center justify-content-between">
                                        <label className="">
                                          {" "}
                                          Cause Of Pain :
                                        </label>
                                        <Field name={`${painItem}.cause`}>
                                          {({ input, meta }) => (
                                            <div className="w-75">
                                              <input
                                                {...input}
                                                className="form-control"
                                                type="text"
                                              />
                                              {meta.error && meta.touched && (
                                                <p
                                                  className="alert alert-danger "
                                                  role="alert"
                                                >
                                                  {meta.error}
                                                </p>
                                              )}
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                      <div className="my-3">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <label className="">
                                              {" "}
                                              Description :
                                            </label>
                                          </div>
                                          {description_data.map((item) => (
                                            <div className="col-md-3">
                                              <Field
                                                name={`${painItem}.description`}
                                                component="input"
                                                type="checkbox"
                                                value={item}
                                              />
                                              <label className="mx-2">
                                                {item}
                                              </label>
                                            </div>
                                          ))}
                                          {meta.error &&
                                            meta.touched &&
                                            errors?.Pain[painIndex] &&
                                            errors?.Pain[painIndex][
                                              "description"
                                            ] && (
                                              <p
                                                className="alert alert-danger my-1"
                                                role="alert"
                                              >
                                                {
                                                  errors.Pain[painIndex][
                                                    "description"
                                                  ]
                                                }
                                              </p>
                                            )}
                                        </div>
                                      </div>
                                      <div className="my-3">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <label className="">
                                              {" "}
                                              What makes pain worse :{" "}
                                            </label>
                                          </div>
                                          {cause_pain_data.map((item) => (
                                            <div className="col-md-3">
                                              <Field
                                                name={`${painItem}.worseReason`}
                                                component="input"
                                                type="checkbox"
                                                value={item}
                                              />
                                              <label className="mx-2">
                                                {item}
                                              </label>
                                            </div>
                                          ))}
                                          <Field
                                            name={`${painItem}.worseReasonInput`}
                                          >
                                            {({ input, meta }) => (
                                              <>
                                                <div className="col-md-12">
                                                  <input
                                                    {...input}
                                                    type="text"
                                                    className="form-control"
                                                  />
                                                  {meta.error &&
                                                    meta.touched && (
                                                      <p
                                                        className="alert alert-danger "
                                                        role="alert"
                                                      >
                                                        {meta.error}
                                                      </p>
                                                    )}
                                                </div>
                                              </>
                                            )}
                                          </Field>
                                        </div>
                                      </div>
                                      <div className="my-3">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <label className="mx-2">
                                              What relieves pain :
                                            </label>
                                          </div>
                                          {relieves_pain_data.map((item) => (
                                            <div className="col-md-3">
                                              <Field
                                                name={`${painItem}.painRelieve`}
                                                component="input"
                                                type="checkbox"
                                                value={item}
                                              />
                                              <label className="mx-2">
                                                {item}
                                              </label>
                                            </div>
                                          ))}
                                          <Field
                                            name={`${painItem}.painRelieveInput`}
                                          >
                                            {({ input, meta }) => (
                                              <>
                                                <div className="col-md-12">
                                                  <input
                                                    {...input}
                                                    type="text"
                                                    className="form-control"
                                                  />
                                                  {meta.error &&
                                                    meta.touched && (
                                                      <p
                                                        className="alert alert-danger "
                                                        role="alert"
                                                      >
                                                        {meta.error}
                                                      </p>
                                                    )}
                                                </div>
                                              </>
                                            )}
                                          </Field>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              {!values.painStatus.includes("noPain") && (
                                <div
                                  className={`${
                                    values.painStatus.includes(
                                      "patientNotCommunicable"
                                    )
                                      ? "col-md-12"
                                      : "col-md-6"
                                  }`}
                                >
                                  <div className="d-flex flex-wrap gap-4">
                                    {patientNotCommunicate_Options_data.map(
                                      (item) => (
                                        <>
                                          <div>
                                            <Field
                                              name={`${painItem}.patientNotCommunicableReason`}
                                              component="input"
                                              type="checkbox"
                                              value={item.value}
                                              // disabled={!values.painStatus.includes('patientNotCommunicable')}
                                            />
                                            <label className="mx-2">
                                              {" "}
                                              {item.label}
                                            </label>
                                          </div>
                                        </>
                                      )
                                    )}
                                    {touched[
                                      `Pain[${painIndex}].patientNotCommunicableReason`
                                    ] &&
                                      errors.Pain[painIndex] &&
                                      errors.Pain[painIndex]
                                        .patientNotCommunicableReason && (
                                        <p
                                          className="alert alert-danger"
                                          role="alert"
                                        >
                                          {
                                            errors.Pain[painIndex]
                                              .patientNotCommunicableReason
                                          }
                                        </p>
                                      )}

                                    <Field
                                      name={`${painItem}.patientNotCommunicableInput`}
                                    >
                                      {({ input, meta }) => (
                                        <>
                                          <input
                                            type="text"
                                            {...input}
                                            className="form-control w-100 "
                                          />
                                          {meta.error && meta.touched && (
                                            <p
                                              className="alert alert-danger "
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              )}
                              {!values.painStatus.includes("noPain") && (
                                <div className="row mt-5">
                                  {isPainHigh(values.Pain) && (
                                    <>
                                      <div className="d-flex align-items-center justify-content-between col-md-8">
                                        <label>Date</label>
                                        <Field
                                          name={`highPaindate`}
                                          component="input"
                                          className="input-textSize"
                                        >
                                          {({ input, meta }) => (
                                            <div className="w-40">
                                              <input
                                                className="form-control"
                                                {...input}
                                                type="date"
                                              />
                                              {meta.error && meta.touched && (
                                                <p
                                                  className="alert alert-danger "
                                                  role="alert"
                                                >
                                                  {meta.error}
                                                </p>
                                              )}
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                                        <label htmlFor="">
                                          Was the abnormal Pain reported to the
                                          Agency ?
                                        </label>
                                        <div className="d-flex align-items-center justify-content-between w-40 px-1">
                                          <div className="radio-of-forms mr-5">
                                            <Field
                                              name={`highPainReportedToAgency`}
                                              type="radio"
                                              component="input"
                                              value={true}
                                            >
                                              {({ input, meta }) => (
                                                <>
                                                  <input
                                                    type="radio"
                                                    {...input}
                                                    onChange={() =>
                                                      input.onChange(true)
                                                    }
                                                    value={true}
                                                  />
                                                </>
                                              )}
                                            </Field>
                                            <label> Yes </label>
                                          </div>
                                          <div className="radio-of-forms">
                                            <Field
                                              name={`highPainReportedToAgency`}
                                              type="radio"
                                              component="input"
                                              value={false}
                                            >
                                              {({ input, meta }) => (
                                                <>
                                                  <input
                                                    type="radio"
                                                    {...input}
                                                    onChange={() =>
                                                      input.onChange(false)
                                                    }
                                                    value={false}
                                                  />
                                                  {}
                                                </>
                                              )}
                                            </Field>
                                            <label>No </label>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row mt-4">
                                        <div className="col-md-4">
                                          <div className="d-flex gap-3">
                                            {" "}
                                            <p className="fw-bold">
                                              Agency Name :
                                            </p>
                                            <p>
                                              {staffDetails &&
                                                staffDetails.createdByUser &&
                                                staffDetails.createdByUser.name}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="d-flex gap-3">
                                            <p className="fw-bold">
                                              Agency Phone :
                                            </p>
                                            <p className="text-nowrap">
                                              {staffDetails &&
                                                staffDetails.createdByUser &&
                                                staffDetails.createdByUser
                                                  .userDetail &&
                                                staffDetails.createdByUser
                                                  .userDetail.phone}
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      {errors.highPainReportedToAgency &&
                                        touched.highPainReportedToAgency && (
                                          <p
                                            className="alert alert-danger "
                                            role="alert"
                                          >
                                            {errors.highPainReportedToAgency}
                                          </p>
                                        )}
                                      <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                                        <label htmlFor="">
                                          Was the abnormal Pain reported to the
                                          MD ?
                                        </label>
                                        <div className="d-flex align-items-center justify-content-between w-40 px-1">
                                          <div className="radio-of-forms mr-5">
                                            <Field
                                              name={`highPainReportedToMD`}
                                              type="radio"
                                              component="input"
                                              value={true}
                                            >
                                              {({ input, meta }) => (
                                                <>
                                                  <input
                                                    type="radio"
                                                    {...input}
                                                    onChange={() =>
                                                      input.onChange(true)
                                                    }
                                                    value={true}
                                                  />
                                                </>
                                              )}
                                            </Field>
                                            <label> Yes </label>
                                          </div>

                                          <div className="radio-of-forms">
                                            <Field
                                              name={`highPainReportedToMD`}
                                              type="radio"
                                              component="input"
                                              value={false}
                                            >
                                              {({ input, meta }) => (
                                                <>
                                                  <input
                                                    type="radio"
                                                    {...input}
                                                    onChange={() =>
                                                      input.onChange(false)
                                                    }
                                                    value={false}
                                                  />
                                                </>
                                              )}
                                            </Field>
                                            <label>No </label>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row mt-4">
                                        <div className="col-md-4">
                                          <div className="d-flex gap-3 ">
                                            <p className=" fw-bold">
                                              Physician Name :
                                            </p>
                                            <p>
                                              {staffDetails &&
                                                staffDetails.staffingDetails &&
                                                staffDetails.staffingDetails
                                                  .physicianName}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          {" "}
                                          <div className="d-flex  gap-3 ">
                                            <p className=" fw-bold">
                                              Physician Phone :
                                            </p>
                                            <p>
                                              {staffDetails &&
                                                staffDetails.staffingDetails &&
                                                staffDetails.staffingDetails
                                                  .physicianPhoneNumber}
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      {errors.highPainReportedToMD &&
                                        touched.highPainReportedToMD && (
                                          <p
                                            className="alert alert-danger "
                                            role="alert"
                                          >
                                            {errors.highPainReportedToMD}
                                          </p>
                                        )}
                                      <div className="col-md-12 mt-4 mb-3">
                                        <div>
                                          <Field
                                            name="vitalSign_name2"
                                            component="input"
                                            type="text"
                                            placeholder=""
                                            className="input-textSize"
                                          >
                                            {({ input, meta }) => (
                                              <>
                                                <label>
                                                  <span className="text-ordered-list mr-1">
                                                    Please document who you
                                                    spoke with at the Agency and
                                                    any instructions you were
                                                    given.
                                                  </span>
                                                </label>
                                                {toSave ? (
                                                  <p>
                                                    {values.vitalSign_name2}
                                                  </p>
                                                ) : (
                                                  <textarea
                                                    maxlength="320"
                                                    className="Additional_text  w-100"
                                                    {...input}
                                                    placeholder=""
                                                  />
                                                )}

                                                {meta.error && meta.touched && (
                                                  <span className="meta_err">
                                                    {meta.error}
                                                  </span>
                                                )}
                                              </>
                                            )}
                                          </Field>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          ))
                        }
                      </FieldArray>
                      <h5 className="py-4 text-decoration-underline mb-3">
                        Comments :
                      </h5>
                      <FieldArray name="Pain">
                        {({ fields }) =>
                          fields.map((name, index) => (
                            <>
                              <Field
                                name={`${name}.comment`}
                                component="input"
                                type="text"
                                placeholder=""
                                className="input-textSize"
                              >
                                {({ input, meta }) => (
                                  <div>
                                    {toSave ? (
                                      <p>
                                        {values["Pain"] &&
                                          values["Pain"].length > 0 &&
                                          values["Pain"][index]["comment"]}
                                      </p>
                                    ) : (
                                      <textarea
                                        maxlength="500"
                                        className="Additional_text"
                                        placeholder="FREE TEXT"
                                        {...input}
                                      />
                                    )}

                                    {meta.error && meta.touched && (
                                      <p
                                        className="alert alert-danger "
                                        role="alert"
                                      >
                                        {meta.error}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </>
                          ))
                        }
                      </FieldArray>
                    </div>

                    <div className="row mein-white-from-background justify-content-between h5_line pt-0">
                      <h5 className="py-4 text-decoration-underline mb-3">
                        Homebound Eligibility :
                      </h5>
                      {toSave ? (
                        <div>
                          {values.HomeboundEligibility &&
                            values.HomeboundEligibility.map((item) => {
                              return <span>{`${item.label},`}</span>;
                            })}
                        </div>
                      ) : (
                        <Field name="HomeboundEligibility">
                          {({ input, meta }) => (
                            <div>
                              <Select
                                {...input}
                                isMulti
                                options={reactSelectOptionCreate(
                                  formOptions.HomeboundEligibility,
                                  "name",
                                  "name",
                                  meta.initial
                                )}
                              />
                              {meta.error && meta.touched && (
                                <p className="alert alert-danger " role="alert">
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          )}
                        </Field>
                      )}
                      <h6 className="my-2">Comments :</h6>
                      <Field
                        name="Comments_homebound"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div>
                            {toSave ? (
                              <p>{values.Comments_homebound}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                className="Additional_text"
                                {...input}
                                type="text"
                              />
                            )}

                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div
                    className={`discharge-section ${
                      isPrint ? "bg-gray-main-form-print" : ""
                    }`}
                  >
                    <div className="row mein-white-from-background h5_line pt-0">
                      <h5 className="py-4 text-decoration-underline mb-3">
                        Interventions :
                      </h5>
                      {toSave ? (
                        <div>
                          {values.Interventions &&
                            values.Interventions.map((item) => {
                              return <span>{`${item.label},`}</span>;
                            })}
                        </div>
                      ) : (
                        <Field name="Interventions">
                          {({ input, meta }) => (
                            <div>
                              <Select
                                {...input}
                                isMulti
                                options={reactSelectOptionCreate(
                                  formOptions.interventations_PT,
                                  "name",
                                  "name",
                                  meta.initial
                                )}
                              />
                              {meta.error && meta.touched && (
                                <p className="alert alert-danger " role="alert">
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          )}
                        </Field>
                      )}
                      <h6 className="my-2">
                        Interventions/Education Provided:
                      </h6>
                      <Field
                        name="Interventions_Educations_input"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div>
                            {toSave ? (
                              <p>{values.Interventions_Educations_input}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                className="Additional_text"
                                {...input}
                                type="textarea"
                              />
                            )}

                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>

                      <h6>Patient Response :</h6>
                      <Field
                        name="Patient_Response_input"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div>
                            {toSave ? (
                              <p>{values.Patient_Response_input}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                className="Additional_text"
                                {...input}
                                type="textarea"
                              />
                            )}

                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="row mein-white-from-background h5_line pt-0">
                      <FieldArray name="CHHA_Supervisory_Visits">
                        {({ fields, meta }) => (
                          <>
                            {fields.map((name, index) => (
                              <>
                                <div>
                                  <h5 className="py-4 text-decoration-underline mb-3">
                                    CHHA Supervisory Visit (if applicable) :
                                  </h5>
                                  <div className="row">
                                    <div className="d-flex">
                                      <h6 className="me-2">
                                        Supervision performed this visit:
                                      </h6>
                                      <div className="radio-of-forms gap-3">
                                        <Field
                                          name={`${name}.Supervision_performed_this_visit`}
                                          component="input"
                                          type="radio"
                                          value="yes"
                                        />
                                        <label>Yes </label>
                                        <Field
                                          name={`${name}.Supervision_performed_this_visit`}
                                          component="input"
                                          type="radio"
                                          value="no"
                                        />
                                        <label>No </label>
                                      </div>
                                    </div>

                                    {values.CHHA_Supervisory_Visits[0]
                                      .Supervision_performed_this_visit ===
                                      "yes" && (
                                      <>
                                        <div className="d-flex gap-3">
                                          <h6>Present:</h6>
                                          <div className="radio-of-forms gap-3">
                                            <Field
                                              name={`${name}.Present`}
                                              component="input"
                                              type="radio"
                                              value="yes"
                                            />
                                            <label>Yes </label>
                                            <Field
                                              name={`${name}.Present`}
                                              component="input"
                                              type="radio"
                                              value="no"
                                            />
                                            <label>No </label>
                                          </div>
                                          <div>
                                            {touched[
                                              "CHHA_Supervisory_Visits[0].Present"
                                            ] &&
                                              meta.error[index] &&
                                              meta.error[index]["Present"] && (
                                                <p className="alert alert-danger">
                                                  {meta.error[index]["Present"]}
                                                </p>
                                              )}
                                          </div>

                                          <div className="present_input_chha">
                                            <label className=" fw-bold mx-1">
                                              Name{" "}
                                            </label>
                                            <Field
                                              name={`${name}.Present_input`}
                                            >
                                              {({ input, meta }) => (
                                                <>
                                                  <input
                                                    {...input}
                                                    type="text"
                                                  />
                                                  {meta.error &&
                                                    meta.touched && (
                                                      <p className="alert alert-danger">
                                                        {meta.error}
                                                      </p>
                                                    )}
                                                </>
                                              )}
                                            </Field>
                                            {}
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-3">
                                            <h6>Following POC: </h6>
                                            <div className="radio-of-forms gap-3">
                                              <Field
                                                name={`${name}.Following_POC`}
                                                component="input"
                                                type="radio"
                                                value="yes"
                                              />
                                              <label>Yes </label>
                                              <Field
                                                name={`${name}.Following_POC`}
                                                component="input"
                                                type="radio"
                                                value="no"
                                              />
                                              <label>No </label>
                                            </div>
                                          </div>
                                          {touched[
                                            "CHHA_Supervisory_Visits[0].Following_POC"
                                          ] &&
                                            meta.error[index] &&
                                            meta.error[index][
                                              "Following_POC"
                                            ] && (
                                              <p className="alert alert-danger">
                                                {
                                                  meta.error[index][
                                                    "Following_POC"
                                                  ]
                                                }
                                              </p>
                                            )}
                                          <div className="col-md-3">
                                            <h6>POC changed: </h6>
                                            <div className="radio-of-forms gap-3">
                                              <Field
                                                name={`${name}.POC_changed`}
                                                component="input"
                                                type="radio"
                                                value="yes"
                                              />
                                              <label>Yes </label>
                                              <Field
                                                name={`${name}.POC_changed`}
                                                component="input"
                                                type="radio"
                                                value="no"
                                              />
                                              <label>No </label>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <h6>
                                              Patient/responsible
                                              party/caregiver notified of
                                              change:{" "}
                                            </h6>
                                            <div className="radio-of-forms gap-3">
                                              <Field
                                                name={`${name}.Patient_Responsible_Party`}
                                                component="input"
                                                type="radio"
                                                value={"NA"}
                                              />
                                              <label>NA </label>
                                              <Field
                                                name={`${name}.Patient_Responsible_Party`}
                                                component="input"
                                                type="radio"
                                                value="yes"
                                              />
                                              <label>Yes </label>
                                              <Field
                                                name={`${name}.Patient_Responsible_Party`}
                                                component="input"
                                                type="radio"
                                                value="no"
                                              />
                                              <label>No </label>
                                            </div>
                                          </div>
                                          {touched[
                                            "CHHA_Supervisory_Visits[0].Patient_Responsible_Party"
                                          ] &&
                                            meta.error[index] &&
                                            meta.error[index][
                                              "Patient_Responsible_Party"
                                            ] && (
                                              <p className="alert alert-danger">
                                                {
                                                  meta.error[index][
                                                    "Patient_Responsible_Party"
                                                  ]
                                                }
                                              </p>
                                            )}
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <h6>
                                              Communicates openly with pt, rep
                                              (if any), cg and family:{" "}
                                            </h6>
                                            <div className="radio-of-forms gap-3">
                                              <Field
                                                name={`${name}.Communicates_openly`}
                                                component="input"
                                                type="radio"
                                                value="yes"
                                              />
                                              <label>Yes </label>
                                              <Field
                                                name={`${name}.Communicates_openly`}
                                                component="input"
                                                type="radio"
                                                value="no"
                                              />
                                              <label>No </label>
                                            </div>
                                            {touched[
                                              "CHHA_Supervisory_Visits[0].Communicates_openly"
                                            ] &&
                                              meta.error[index] &&
                                              meta.error[index][
                                                "Communicates_openly"
                                              ] && (
                                                <p className="alert alert-danger">
                                                  {
                                                    meta.error[index][
                                                      "Communicates_openly"
                                                    ]
                                                  }
                                                </p>
                                              )}
                                          </div>
                                          <div className="col-md-6">
                                            <h6>
                                              Reports changes in patient
                                              condition:{" "}
                                            </h6>
                                            <div className="radio-of-forms gap-3">
                                              <Field
                                                name={`${name}.Reports_changes`}
                                                component="input"
                                                type="radio"
                                                value="yes"
                                              />
                                              <label>Yes </label>
                                              <Field
                                                name={`${name}.Reports_changes`}
                                                component="input"
                                                type="radio"
                                                value="no"
                                              />
                                              <label>No </label>
                                            </div>
                                            {touched[
                                              "CHHA_Supervisory_Visits[0].Reports_changes"
                                            ] &&
                                              meta.error[index] &&
                                              meta.error[index][
                                                "Reports_changes"
                                              ] && (
                                                <p className="alert alert-danger">
                                                  {
                                                    meta.error[index][
                                                      "Reports_changes"
                                                    ]
                                                  }
                                                </p>
                                              )}
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <h6>
                                              Demonstrates competency with
                                              assigned tasks:
                                            </h6>
                                            <div className="radio-of-forms gap-3">
                                              <Field
                                                name={`${name}.Demonstrates_competency`}
                                                component="input"
                                                type="radio"
                                                value="yes"
                                              />
                                              <label>Yes </label>
                                              <Field
                                                name={`${name}.Demonstrates_competency`}
                                                component="input"
                                                type="radio"
                                                value="no"
                                              />
                                              <label>No </label>
                                            </div>
                                            {touched[
                                              "CHHA_Supervisory_Visits[0].Demonstrates_competency"
                                            ] &&
                                              meta.error[index] &&
                                              meta.error[index][
                                                "Demonstrates_competency"
                                              ] && (
                                                <p className="alert alert-danger">
                                                  {
                                                    meta.error[index][
                                                      "Demonstrates_competency"
                                                    ]
                                                  }
                                                </p>
                                              )}
                                          </div>
                                          <div className="col-md-6">
                                            <h6>Honors patient rights:</h6>
                                            <div className="radio-of-forms gap-3">
                                              <Field
                                                name={`${name}.Honors_patient`}
                                                component="input"
                                                type="radio"
                                                value="yes"
                                              />
                                              <label>Yes </label>
                                              <Field
                                                name={`${name}.Honors_patient`}
                                                component="input"
                                                type="radio"
                                                value="no"
                                              />
                                              <label>No </label>
                                            </div>
                                            {touched[
                                              "CHHA_Supervisory_Visits[0].Honors_patient"
                                            ] &&
                                              meta.error[index] &&
                                              meta.error[index][
                                                "Honors_patient"
                                              ] && (
                                                <p className="alert alert-danger">
                                                  {
                                                    meta.error[index][
                                                      "Honors_patient"
                                                    ]
                                                  }
                                                </p>
                                              )}
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <h6>
                                              Complies with infection
                                              prevention/control
                                              policies/procedures:
                                            </h6>
                                            <div className="radio-of-forms gap-3">
                                              <Field
                                                name={`${name}.Complies_with_infection_prevention`}
                                                component="input"
                                                type="radio"
                                                value="yes"
                                              />
                                              <label>Yes </label>
                                              <Field
                                                name={`${name}.Complies_with_infection_prevention`}
                                                component="input"
                                                type="radio"
                                                value="no"
                                              />
                                              <label>No </label>
                                            </div>
                                            {touched[
                                              "CHHA_Supervisory_Visits[0].Complies_with_infection_prevention"
                                            ] &&
                                              meta.error[index] &&
                                              meta.error[index][
                                                "Complies_with_infection_prevention"
                                              ] && (
                                                <p className="alert alert-danger">
                                                  {
                                                    meta.error[index][
                                                      "Complies_with_infection_prevention"
                                                    ]
                                                  }
                                                </p>
                                              )}
                                          </div>
                                          <div className="col-md-6">
                                            <h6>Patient/Staff compatible:</h6>
                                            <div className="radio-of-forms gap-3">
                                              <Field
                                                name={`${name}.Patient_Staff_compatible`}
                                                component="input"
                                                type="radio"
                                                value="yes"
                                              />
                                              <label>Yes </label>
                                              <Field
                                                name={`${name}.Patient_Staff_compatible`}
                                                component="input"
                                                type="radio"
                                                value="no"
                                              />
                                              <label>No </label>
                                            </div>
                                            {touched[
                                              "CHHA_Supervisory_Visits[0].Patient_Staff_compatible"
                                            ] &&
                                              meta.error[index] &&
                                              meta.error[index][
                                                "Patient_Staff_compatible"
                                              ] && (
                                                <p className="alert alert-danger">
                                                  {
                                                    meta.error[index][
                                                      "Patient_Staff_compatible"
                                                    ]
                                                  }
                                                </p>
                                              )}
                                          </div>
                                        </div>
                                        <div className="row">
                                          <h6 className="">Specify details:</h6>
                                          <Field
                                            name={`${name}.specifyDetails`}
                                            component="input"
                                            type="text"
                                            placeholder=""
                                            className="input-textSize"
                                          >
                                            {({ input, meta }) => (
                                              <div>
                                                {toSave ? (
                                                  <p>
                                                    {values[
                                                      "CHHA_Supervisory_Visits"
                                                    ] &&
                                                      values[
                                                        "CHHA_Supervisory_Visits"
                                                      ].length > 0 &&
                                                      values[
                                                        "CHHA_Supervisory_Visits"
                                                      ][index][
                                                        "specifyDetails"
                                                      ]}
                                                  </p>
                                                ) : (
                                                  <textarea
                                                    maxlength="320"
                                                    className="Additional_text"
                                                    {...input}
                                                    type="text"
                                                  />
                                                )}
                                                {meta.error && meta.touched && (
                                                  <p
                                                    className="alert alert-danger "
                                                    role="alert"
                                                  >
                                                    {meta.error}
                                                  </p>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            ))}
                          </>
                        )}
                      </FieldArray>
                    </div>
                    <div className="row mein-white-from-background h5_line pt-0">
                      <h5 className="py-4 text-decoration-underline">
                        Reason for home health :
                      </h5>
                      <Field
                        name="home_reason"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div>
                            {toSave ? (
                              <p>{values.home_reason}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                className="Additional_text"
                                {...input}
                                type="textarea"
                              />
                            )}

                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="row mein-white-from-background h5_line pt-0">
                      <h5 className="py-4 text-decoration-underline mb-3">
                        Therapy Provided :
                      </h5>
                      <div className="row">
                        <div className="col-md-12">
                          Total number of Visits Completed :{" "}
                          {Object.keys(getTotalVisitGroupStatus()).map(
                            (item) => (
                              <>
                                {item} : {getTotalVisitGroupStatus()[item]}{" "}
                                &nbsp;
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`discharge-section ${
                      isPrint ? "bg-gray-main-form-print" : ""
                    }`}
                  >
                    <div className="row mein-white-from-background h5_line pt-0">
                      <h5 className="py-4 text-decoration-underline">
                        {" "}
                        Reason(s) for Discharge :
                      </h5>
                      {reasondischarge.map((name, index) => (
                        <div className="row">
                          <div className="col-md-1"></div>
                          <div className="col-md-1">
                            <Field
                              name={`dischargeReason`}
                              component="input"
                              type="checkbox"
                              value={name}
                            />
                          </div>
                          <div className="col-md-9">
                            <label>{name}</label>
                          </div>
                        </div>
                      ))}
                      {errors.dischargeReason && touched.dischargeReason && (
                        <p className="alert alert-danger">
                          {errors.dischargeReason}
                        </p>
                      )}

                      <h6>Other :</h6>
                      <Field
                        name="dischargeReason_other"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div>
                            {toSave ? (
                              <p>{values.dischargeReason_other}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                className="Additional_text"
                                {...input}
                                type="textarea"
                              />
                            )}

                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="row mein-white-from-background justify-content-between h5_line pt-0">
                      <h5 className="py-4 text-decoration-underline">
                        {" "}
                        Outcome of Patient Goals from Plan of Care :
                      </h5>
                      <div className="col-md-3 mb-3  mt-3">
                        <h6>Goals :</h6>
                      </div>
                      <div className="col-md-3 mb-3  mt-3">
                        <h6>Start Effective Date :</h6>
                      </div>
                      <div className="col-md-3 mb-3  mt-3">
                        <h6>Current Status :</h6>
                      </div>
                      <div className="col-md-3 mb-3  mt-3">
                        <h6>Percentage of goal met :</h6>
                      </div>
                      <FieldArray name="goals">
                        {({ fields }) =>
                          fields.map((name, index) => (
                            <>
                              <div className="col-md-3 mb-3 " key={index}>
                                <Field name={`${name}.goal`}>
                                  {({ input, meta }) => (
                                    <>
                                      <input
                                        {...input}
                                        maxlength="30"
                                        className="pteval-input"
                                        type="text"
                                        disabled={
                                          checkPreviousGoal(
                                            values.goals[index],
                                            index
                                          ) || disableByVisitStatus()
                                        }
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger w-100"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="col-md-3 mb-3   " key={index}>
                                <Field name={`${name}.effectiveDate`}>
                                  {({ input, meta }) => (
                                    <>
                                      <input
                                        {...input}
                                        className="pteval-input"
                                        type="date"
                                        min={effectiveDate()}
                                        disabled={
                                          checkPreviousGoal(
                                            values.goals[index],
                                            index
                                          ) || disableByVisitStatus()
                                        }
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger w-100"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="col-md-3 mb-3 " key={index}>
                                <Field name={`${name}.currentStatus`}>
                                  {({ input, meta }) => (
                                    <>
                                      <input
                                        maxlength="30"
                                        {...input}
                                        className="pteval-input"
                                        type="text"
                                        disabled={!values.goals[index].goal}
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger w-100"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="col-md-3 mb-3 " key={index}>
                                <Field
                                  name={`${name}.percentageGoalMet`}
                                  component="select"
                                  disabled={!values.goals[index].goal}
                                  className="paitiant-chart-input form-select"
                                >
                                  {({ input, meta }) => (
                                    <>
                                      <select
                                        {...input}
                                        className="form-control"
                                      >
                                        <option hidden>Choose</option>
                                        {getGoalPercentage().map((item) => (
                                          <option value={item}>{item}</option>
                                        ))}

                                        {/* <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option> */}
                                      </select>
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger "
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </Field>

                                {/* <Field name={`${name}.percentageGoalMet`}>
                                  {({ input, meta }) => (
                                    <>
                                      <input
                                        {...input}
                                        maxlength="3"
                                        className="form-control ptinput"
                                        type="text"
                                        disabled={!values.goals[index].goal}
                                        placeholder="0 - 100%"
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger w-100"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </Field> */}
                              </div>
                            </>
                          ))
                        }
                      </FieldArray>
                      {submitFailed && error && (
                        <p className="alert alert-danger " role="alert">
                          Enter Atleast 3 Fields
                        </p>
                      )}
                    </div>
                    <div className="row mein-white-from-background justify-content-between h5_line pt-0">
                      <h5 className="py-4 text-decoration-underline">
                        {" "}
                        Discharge Instructions :
                      </h5>
                      <div className="row  ">
                        <div className="col-md-12 d-flex gap-4 align-items-center">
                          <h6>Instructions Provided to :</h6>
                          <div>
                            <Field
                              name={`instructionGivenTo`}
                              component="input"
                              type="checkbox"
                              value={"patient"}
                            />
                            <label className="m-1">Patient</label>
                          </div>
                          <div>
                            <Field
                              name={`instructionGivenTo`}
                              component="input"
                              type="checkbox"
                              value={"caregiver"}
                            />
                            <label className="m-1">Caregiver/Family</label>
                          </div>
                          <div>
                            <Field name={`caregiverName`}>
                              {({ input, meta }) => (
                                <>
                                  <input
                                    type="text"
                                    {...input}
                                    className="form-control"
                                  />
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                        </div>
                        {errors.instructionGivenTo &&
                          touched.instructionGivenTo && (
                            <p className="alert alert-danger">
                              {errors.instructionGivenTo}
                            </p>
                          )}
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <label className="no-wrap-white-space mb-2">
                            Instruction Provided :
                          </label>
                          <Field name="dischargeInstruction">
                            {({ input, meta }) => (
                              <>
                                {toSave ? (
                                  <p>{values.dischargeInstruction}</p>
                                ) : (
                                  <textarea
                                    maxlength="320"
                                    {...input}
                                    className="Additional_text"
                                    type="text"
                                    placeholder=" free text"
                                  />
                                )}

                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="my-2">
                            <Field
                              name={`instructionNotUnderstood`}
                              type="radio"
                              component="input"
                              value={false}
                            >
                              {({ input, meta }) => (
                                <>
                                  <input
                                    type="radio"
                                    {...input}
                                    onChange={() => input.onChange(false)}
                                    value={false}
                                  />
                                  {}
                                </>
                              )}
                            </Field>
                            <label className="mx-2">
                              Patient/caregiver/Family understood discharge
                              instructions
                            </label>
                          </div>
                          <div>
                            <Field
                              name={`instructionNotUnderstood`}
                              type="radio"
                              component="input"
                              value={true}
                            >
                              {({ input, meta }) => (
                                <>
                                  <input
                                    type="radio"
                                    {...input}
                                    onChange={() => input.onChange(true)}
                                    value={true}
                                  />
                                </>
                              )}
                            </Field>
                            <label className="mx-2">
                              Patient/caregiver/Family were unable to understood
                              discharge instructions due to:
                            </label>
                            <Field name={`caregiverDueTo`}>
                              {({ input, meta }) => (
                                <>
                                  <input
                                    type="text"
                                    {...input}
                                    className="form-control my-3"
                                  />
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                          {errors.instructionNotUnderstood &&
                            touched.instructionNotUnderstood && (
                              <p className="alert alert-danger">
                                {errors.instructionNotUnderstood}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`discharge-section ${
                      isPrint ? "bg-gray-main-form-print" : ""
                    }`}
                  >
                    <div className="row mein-white-from-background justify-content-between h5_line pt-0">
                      <h5 className="py-4 text-decoration-underline">
                        Care Coordination(if any) :
                      </h5>
                      <label>Care Coordination with :</label>
                      {care_coordination.map((checkboxItem) => (
                        <div className="col-md-4 mt-3">
                          <div className="radio-of-forms gap-3">
                            <Field
                              name={`care_coordination`}
                              component="input"
                              type="checkbox"
                              value={checkboxItem}
                            />
                            {checkboxItem}
                          </div>
                        </div>
                      ))}

                      <div className="col-md-12">
                        <Field
                          name="care_coordinationTextBox"
                          component="input"
                          type="text"
                          placeholder=""
                          className="input-textSize"
                        >
                          {({ input, meta }) => (
                            <div>
                              <label>
                                <span className="text-ordered-list mr-1">
                                  Additional :
                                </span>
                              </label>
                              <div>
                                {toSave ? (
                                  <p>{values.care_coordinationTextBox}</p>
                                ) : (
                                  <textarea
                                    maxlength="320"
                                    className="Additional_text"
                                    {...input}
                                    type="text"
                                  />
                                )}

                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className="row my-3">
                        <div className="col-md-2">
                          <label>Coordination Date :</label>
                        </div>
                        <div className="col-md-3">
                          <Field name={`Coordination_date`}>
                            {({ input, meta }) => (
                              <>
                                <input
                                  {...input}
                                  className="pteval-input"
                                  type="date"
                                  min={effectiveDate()}
                                />
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger w-100"
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="col-md-1">
                          <label>Time :</label>
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="time"
                            component="input"
                            type="text"
                            placeholder=""
                            className="input-textSize"
                          >
                            {({ input, meta }) => (
                              <div className="w-50">
                                <input
                                  maxlength="45"
                                  className="form-control"
                                  {...input}
                                  type="time"
                                  placeholder=""
                                />
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                      <h5 className="py-4 text-decoration-underline mb-3">
                        Coordination Details :
                      </h5>
                      <Field
                        name="Coordination_Details_input"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div>
                            {toSave ? (
                              <p>{values.Coordination_Details_input}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                className="Additional_text"
                                {...input}
                                type="text"
                              />
                            )}

                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="row mein-white-from-background h5_line pt-0">
                      <h5 className="py-4 text-decoration-underline">
                        Summary Sent To :
                      </h5>
                      <div className=" col-md-4 fileds-from">
                        <label>Physician :</label>
                        <Field
                          name="mrNo"
                          component="input"
                          type="text"
                          placeholder=""
                          className="input-textSize"
                        >
                          {({ input, meta }) => (
                            <div className="w-50">
                              <input
                                maxlength="45"
                                className="form-control bg-white"
                                {...input}
                                type="text"
                                placeholder=""
                                disabled
                              />
                              {meta.error && meta.touched && (
                                <p className="alert alert-danger " role="alert">
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className=" col-md-4 fileds-from">
                        <label>NPI :</label>
                        <Field
                          name="physicianNPI"
                          component="input"
                          type="text"
                          placeholder=""
                          className="input-textSize"
                        >
                          {({ input, meta }) => (
                            <div className="w-50">
                              <input
                                maxlength="45"
                                className="form-control bg-white"
                                {...input}
                                type="text"
                                placeholder=""
                                disabled
                              />
                              {meta.error && meta.touched && (
                                <p className="alert alert-danger " role="alert">
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className=" col-md-4 fileds-from">
                        <label>Phone :</label>
                        <Field
                          name="phoneNumber"
                          component="input"
                          type="text"
                          placeholder=""
                          className="input-textSize"
                        >
                          {({ input, meta }) => (
                            <div className="w-50">
                              <input
                                maxlength="45"
                                className="form-control bg-white"
                                {...input}
                                type="text"
                                placeholder=""
                                disabled
                              />
                              {meta.error && meta.touched && (
                                <p className="alert alert-danger " role="alert">
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className="col-md-12">
                        <label> Additional Details :</label>
                        <Field name={`suummarySentToAdditional`}>
                          {({ input, meta }) => (
                            <div>
                              <label>
                                <span className="text-ordered-list mr-1">
                                  Additional :
                                </span>
                              </label>
                              {toSave ? (
                                <p>
                                  {values["suummarySentToAdditional"]
                                    ? values["suummarySentToAdditional"]
                                    : ""}
                                </p>
                              ) : (
                                <textarea
                                  maxlength="320"
                                  {...input}
                                  type="text"
                                  placeholder=""
                                  className="Additional_text"
                                ></textarea>
                              )}
                            </div>
                          )}
                        </Field>

                        {/* <Field
                          name="suummarySentToAdditional"
                          className="Additional_text "
                          component="textarea"
                          placeholder="Free text"
                        /> */}
                        {/* {errors[`travelledStateText`] &&
                                                touched[`travelledStateText`] && (
                                                    <p className="alert alert-danger " role="alert">
                                                        {errors["travelledStateText"]}
                                                    </p>
                                                )} */}
                      </div>
                    </div>
                    <div className="row mein-white-from-background justify-content-between ">
                      <div className="col-md-4">
                        <div>
                          <Field
                            name="Patientsignature"
                            className="form-control mb-3 "
                            component="input"
                            placeholder="Patient signature"
                          />
                        </div>
                        <div>
                          <div className="">
                            <div className="d-flex gap-2 align-items-center my-3">
                              <span> Therapist Signature: </span>
                              {signatureAdded || values.Therapistsignature ? (
                                <img
                                  src={
                                    displaySignature
                                      ? displaySignature
                                      : values.Therapistsignature
                                  }
                                  height={"100px"}
                                  width={"300px"}
                                  crossOrigin="anonymous"
                                />
                              ) : (
                                <input
                                  type="text"
                                  placeholder="Therapist Signature"
                                  disabled
                                  className=" pl-0 border-0 border-right-2 border-left-2 border-bottom-2"
                                />
                              )}
                            </div>

                            {currentUser &&
                              currentUser.Role &&
                              currentUser.Role.key &&
                              (currentUser.Role.key ===
                                currentRole.supervisingtherapist ||
                                currentUser.Role.key ===
                                  currentRole.assistanttherapist) &&
                              !toSave && (
                                <button
                                  className="Approve-btns ml-0"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsCanvasShow(true);
                                  }}
                                  disabled={
                                    visitDetailById &&
                                    !canSubmitForm.includes(
                                      visitDetailById.Status.key
                                    )
                                  }
                                >
                                  {signatureAdded || values.Therapistsignature
                                    ? "Update Signature"
                                    : "Add Signature"}
                                </button>
                              )}
                          </div>

                          {submitFailed &&
                            !values.Therapistsignature &&
                            !displaySignature && (
                              <p
                                className="alert alert-danger my-2 "
                                role="alert"
                              >
                                Signature is required.
                              </p>
                            )}
                        </div>
                      </div>

                      <div className="col-md-12 mt-4">
                        <p>
                          Physician Name :
                          <span className="ml-5">
                            {staffDetails &&
                            staffDetails.staffingDetails &&
                            staffDetails.staffingDetails.physicianName
                              ? staffDetails.staffingDetails.physicianName
                              : "--"}
                          </span>
                        </p>

                        <div className="row d-flex  align-items-center  ">
                          <div className="col-md-6">
                            <div className="d-flex align-items-start">
                              <label className="no-wrap-white-space mb-0">
                                Physician Signature :
                              </label>
                              <Field
                                name="Physiciansignature"
                                className="Additional_text ml-3 "
                                component="textarea"
                                placeholder=""
                                disabled={true}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="d-flex align-items-start mb-0">
                              <label className="mb-0 text-nowrap">Date:</label>
                              <Field
                                name="date"
                                className="Additional_text ml-3 "
                                component="textarea"
                                placeholder=""
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {currentUser &&
                    currentUser.Role &&
                    currentUser.Role.key &&
                    (currentUser.Role.key ===
                      currentRole.supervisingtherapist ||
                      currentUser.Role.key ===
                        currentRole.assistanttherapist) && (
                      <div className="container">
                        <div className="py-5 ">
                          <button
                            type="submit"
                            className="Approve-btns  mx-2 h-auto"
                            onClick={(e) => {
                              e.preventDefault();
                              if (!valid) handleSubmit();
                              if (
                                valid &&
                                (displaySignature || values.Therapistsignature)
                              )
                                setShow(true);
                              else
                                toast.error(
                                  "Please complete all required fields."
                                );
                            }}
                            disabled={
                              visitDetailById &&
                              !canSubmitForm.includes(getFormStatus())
                            }
                          >
                            Sign & Submit
                          </button>
                          <button
                            className="Approve-btns  mx-2 h-auto"
                            onClick={(e) => {
                              e.preventDefault();
                              onSubmit(values);
                            }}
                            disabled={
                              visitDetailById &&
                              !canSubmitForm.includes(getFormStatus())
                            }
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    )}
                  {show && (
                    <Modal
                      show={show}
                      onHide={() => setShow(false)}
                      backdrop="static"
                      keyboard={false}
                      centered
                    >
                      <Modal.Header className="pl-4 pr-4 pt-4 pb-3" closeButton>
                        <Modal.Title>Complete</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="pl-4 pr-4 pt-0 pb-4">
                        {/* By confirming this you will mark the {activeForm && activeForm.name}  document as complete. Are you sure? */}
                        By confirming this you will mark the Discharge document
                        as completed. Are you sure?
                        <div className="mt-4">
                          <Button
                            className="Discard-danger"
                            variant="outline-danger"
                            onClick={() => setShow(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            className="primary-confirm"
                            onClick={(e) => {
                              e.preventDefault();
                              if (valid)
                                onSubmit(values, form, visitStatus.needreview);
                            }}
                          >
                            Confirm
                          </Button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  )}
                </div>
              </form>
            )}
          />
        </Col>
      </Row>
      {isCanvasShow && (
        <SignatureCanvas
          loadDoc={saveForm}
          isShow={isCanvasShow}
          setSignatureAdded={setSignatureAdded}
          handleClose={() => setIsCanvasShow(false)}
        />
      )}
    </Container>
  );
}

export default Discharge;
