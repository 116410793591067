import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../../components/common/CommonTable";
import { currentRole, messageFormatting } from "../../../utils/auth";
import ReactPaginate from "react-paginate";
import { Col, Modal, Row } from "react-bootstrap";
import { Button } from "bootstrap-4-react/lib/components";
import { GrClose } from "react-icons/gr";
import Loader from "../../../components/Loader";
import { CommonHead } from "../../../components/common/CommonHead";
import {
  getAllListToBlock,
  setBlockedUser,
} from "../../../Redux/actions/therapist";
import { CgSearch } from "react-icons/cg";
import { debounce } from "lodash";
import { blockTabs } from "../../../utils/helper";
import { AiFillPlusCircle } from "react-icons/ai";
import assistantimg from "../../../images/usertable02.svg";
import agencyimg from "../../../images/usertable01.svg";
import { setLoader } from "../../../Redux/slices/authSlice";
import { toast } from "react-toastify";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { IoSyncCircleOutline } from "react-icons/io5";
import block_icon from "../../../images/block_icon.svg";
import agency_icon from "../../../images/AdminDashboard/agency_icon.svg";
import assistant_therapist_icon from "../../../images/AdminDashboard/assistant_therapist_icon.svg";
import supervising_therapist_icon from "../../../images/AdminDashboard/supervising_therapist_icon.svg";
import { apiBasePath } from "../../../Redux/config/config";

export default function AddToBlockList(props) {
  const dispatch = useDispatch();
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [menuItem, setMenuItem] = useState("agency");
  const [searchValue, setSearchValue] = useState("");
  const userList = useSelector((e) => e.therapistSigunpSlice.listToBlock);
  const currentUserRole = useSelector(
    (e) => e.adminSlice.currentActiveUserDetails?.Role
  );
  const isLoading = useSelector((e) => e.therapistSigunpSlice.isLoading);
  const agencyCount = useSelector((e) => e.therapistSigunpSlice.agencyCount);
  const therapistCount = useSelector(
    (e) => e.therapistSigunpSlice.therapistCount
  );
  const listToBlockCount = useSelector(
    (e) => e.therapistSigunpSlice.listToBlockCount
  );

  useEffect(() => {
    dispatch(
      getAllListToBlock({
        pageSize: pageSize,
        pageNo: 1,
        tab: blockTabs[menuItem],
      })
    );
  }, []);

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "select":
        return "Select";
      case "name":
        return "Name";
      case "Role.name":
        return "User Type";
      case "reason":
        return "Reason";
      default:
        return colName;
    }
  };

  let myImage = {
    supervisingtherapist: assistantimg,
    assistanttherapist: assistantimg,
    agency: agencyimg,
  };
  let roleIcons = {
    supervisingtherapist: supervising_therapist_icon,
    assistanttherapist: assistant_therapist_icon,
    agency: agency_icon,
  };

  let userType = {
    agency: "Agency",
    supervisingtherapist: "Supervising Therapist",
    assistanttherapist: "Assistant Therapist",
  };

  const handlePageClick = (event) => {
    setPageNo(event.selected + 1);
    dispatch(
      getAllListToBlock({
        pageSize: pageSize,
        pageNo: event.selected + 1,
        tab: blockTabs[menuItem],
        search: searchValue ? searchValue : "",
      })
    );
  };

  const renderRowData = (result, col, item, index, values) => {
    let userRole = menuItem;
    return (
      <>
        {col === "select" && (
          <div className="d-flex align-items-center statusBlockCheckBox">
            <label class="container-card">
              <Field
                key={item?.id}
                name={`blockList[${index}].select`}
                type="checkbox"
                component="input"
              />
              <span class=" checkmark1"></span>
            </label>
          </div>
        )}
        {col === "name" && (
          <>
            <div className="d-flex align-items-center">
              <img
                src={
                  result?.image
                    ? `${apiBasePath}documents/therapist/${result.image}`
                    : myImage[userRole]
                }
                crossOrigin="acountnymous"
                className="table-img p-1"
                style={{
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: "5%",
                }}
                alt="img"
              />
              <h1 className="title-22">
                {userRole === currentRole.agency &&
                  result &&
                  result.name &&
                  `${result.name}`}
                {(userRole === currentRole.assistanttherapist ||
                  userRole === currentRole.supervisingtherapist) &&
                  result.firstName &&
                  `${result.lastName}, ${result.firstName}`}
              </h1>
            </div>
          </>
        )}
        {col === "Role.name" && (
          <div className="d-flex gap-2 align-items-center">
            <img
              src={
                roleIcons[
                  result?.Role && result?.Role?.key
                    ? result?.Role?.key
                    : menuItem
                ]
              }
              crossOrigin="acountnymous"
              className="p-1"
              alt="roleIcons"
            />
            <p className="m-0 displine-list">{userType[menuItem]}</p>
          </div>
        )}
        {col === "reason" && (
          <>
            <Field name={`blockList[${index}].reason`} component="input">
              {({ input, meta }) => {
                return (
                  <>
                    <textarea
                      className="adblock-textarea"
                      {...input}
                      disabled={
                        values &&
                        values?.blockList &&
                        values.blockList[index]?.select
                          ? false
                          : true
                      }
                      placeholder="Add Reason"
                    ></textarea>
                    {meta.error && meta.touched && (
                      <div className="alert alert-danger" role="alert">
                        {meta.error}
                      </div>
                    )}
                  </>
                );
              }}
            </Field>
          </>
        )}
      </>
    );
  };

  const setTabMenus = (tab) => {
    document.getElementById("searchField").value = "";
    setSearchValue("");
    dispatch(setLoader({ loading: true }));
    setMenuItem(tab);
    setPageNo(1);
    dispatch(
      getAllListToBlock({
        pageSize: pageSize,
        pageNo: 1,
        tab: blockTabs[tab],
      })
    );
  };

  const debounceCall = debounce((ele) => {
    setSearchValue(ele);
    setPageNo(1);
    dispatch(
      getAllListToBlock({
        pageSize: pageSize,
        pageNo: 1,
        tab: blockTabs[menuItem],
        search: ele,
      })
    );
  }, 1000);

  const getRoleDetailCount = (role, status) => {
    let count = 0;
    if (role === currentRole.agency) {
      count = agencyCount;
    } else if (
      role === currentRole.assistanttherapist ||
      role === currentRole.supervisingtherapist
    ) {
      count = therapistCount;
    }
    return count;
  };

  const allMenu = [
    {
      label: "Agency ",
      count: Number(getRoleDetailCount(currentRole.agency)),
      key: "agency",

      columns: ["select", "name", "Role.name", "reason"],
      columnsToHide: [""],
      noData: "No data available",
      data: userList,
    },
    {
      label: "Assistant Therapist",
      count: Number(getRoleDetailCount(currentRole.assistanttherapist)),
      key: "assistanttherapist",

      columns: ["select", "name", "Role.name", "reason"],
      columnsToHide: ["image"],
      noData: "No data available",
      data: userList,
    },
    {
      label: "Supervising Therapist ",
      count: Number(getRoleDetailCount(currentRole.supervisingtherapist)),
      key: "supervisingtherapist",

      columns: ["select", "name", "Role.name", "reason"],
      columnsToHide: ["image"],
      noData: "No data available",
      data: userList,
    },
  ];

  const menu = allMenu.filter((item) => item.key !== currentUserRole?.key);
  const myActions = menu.find((ele) => ele.key === menuItem);

  const initialvalues = {
    blockList:
      userList &&
      userList.length > 0 &&
      userList.map((listItem, listIndex) => {
        return {
          userId: listItem.id,
          reason: "",
          select: false,
        };
      }),
  };

  const onSubmit = (values) => {
    const finalValues = values.blockList.filter((item) => item.select);
    if (finalValues && finalValues.length > 0) {
      dispatch(setBlockedUser(finalValues)).then((res) => {
        if (
          res &&
          res.payload &&
          res.payload.data &&
          res.payload.data.success
        ) {
          toast.success(messageFormatting(res.payload.data.message));
          props.setUpdateTab(true);
          props.handleAddModal();
          props.setTabMenus(menuItem);
        }
      });
    }
  };

  const validate = (values) => {
    let errors = {};
    let itemErrors = [];
    const blockListArray = values.blockList;
    if (values && values.blockList && values.blockList.length > 0) {
      blockListArray.map((item) => {
        let error = {};
        if (item.select && !item.reason) {
          error["reason"] = "Reason is required.";
        }
        itemErrors.push(error);
      });
    }
    errors["blockList"] = itemErrors;
    return errors;
  };

  return (
    <div>
      <Modal
        show={true}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        className="BlockList-modal"
      >
        {isLoading && <Loader />}
        <div className="closeicon_div">
          <GrClose
            size={20}
            className="closeicon"
            onClick={() => props.handleCloseAddToBlockModal()}
          />
        </div>
        <div className="d-flex mobile_wrap justify-content-between gap-3 pl-5 pr-5">
          <h5 className="Block-title h-auto mobile_width  mobile_text-center">
            Add to Block-List
          </h5>
          <div
            className="search-bar d-flex align-items-center mx-5"
            style={{ width: "21%" }}
          >
            <CgSearch size={"25px"} />
            <input
              placeholder={`${
                menuItem === "agency"
                  ? "Search by agency name..."
                  : "Search by therapist name..."
              }`}
              id="searchField"
              className="pl-2 input-width"
              onChange={(ele) => debounceCall(ele?.target?.value)}
              style={{
                border: "none",
                fontSize: "18px",
                outline: "none",
                borderBottom: "10px",
              }}
            />
          </div>
        </div>

        <div className="user-type-main tabs_user_type">
          <div className="col-sm-12 pr-5 pl-5">
            <CommonHead
              data={menu}
              setMenu={setTabMenus}
              menuItem={menuItem}
              css={"col-md-3"}
              setPageNo={setPageNo}
            />
          </div>
        </div>
        <div className="container-fluid">
          {userList && userList.length > 0 ? (
            <Row>
              <div className="inside_table_tabs addblock-table ">
                <Col lg={11}>
                  <Form
                    validate={validate}
                    onSubmit={onSubmit}
                    initialValues={initialvalues}
                    mutators={{
                      ...arrayMutators,
                    }}
                    render={({ values, handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="table-responsive">
                          <CommonTable
                            data={myActions?.data}
                            columns={myActions?.columns}
                            columnsToHide={myActions?.columnsToHide}
                            overrideColumnName={overrideColumnName}
                            actions={myActions?.actions}
                            renderRowData={renderRowData}
                            isLoading={isLoading}
                            formValues={values}
                          />
                        </div>
                        <div className=" mt-3 mb-5 mt-4 qdf">
                          <button className="Add-BlockList-btn">
                            {" "}
                            <IoSyncCircleOutline size={35} /> Add to Block-List
                          </button>
                        </div>
                      </form>
                    )}
                  />
                </Col>
              </div>
            </Row>
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-center mt-5">
                <h2 className="fs-5 mt-5 mb-5">No Data Available</h2>
              </div>
            </>
          )}

          {myActions.count > pageSize && listToBlockCount > pageSize && (
            <div id="container ">
              <div className="row justify-content-start mt-3  pl-5 ">
                <div className="pl-3 pr-5">
                  <ReactPaginate
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={Math.ceil(listToBlockCount / pageSize)}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                    forcePage={
                      Math.ceil(listToBlockCount / pageSize) === 0
                        ? -1
                        : pageNo - 1
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
