import { Col } from "bootstrap-4-react";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { CgSearch } from "react-icons/cg";
import CommonTable from "../../components/common/CommonTable";
import userImage from "../../images/usertable02.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { currentRole, defaultStatus } from "../../utils/auth";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { infiniteScrollGetCardsByRoleStatus } from "../../Redux/actions/auth";
import { apiBasePath } from "../../Redux/config/config";
import Loader from "../../components/Loader";
import { resetUserList } from "../../Redux/slices/adminSlice";

const colors = {
  Open: { background: "#D9F8DC", color: "#17A325" },
  Completed: { background: "#D9F8DC", color: "#17A325" },
  Incomplete: { background: "#FDEDDC", color: "#CA7718" },
  Scheduled: { background: "#E8E6FF", color: "#4A40B4" },
};

export const DailyActivePatients = ({ handleClose, show, count }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((e) => e.adminSlice.isLoading);
  const [hasMore, sethasMore] = useState(true);
  const [pageNo, setPageNo] = useState(2);
  const [pageSize] = useState(10);
  const [searchValue, setsearchValue] = useState("");

  const infiniteScrollCardList = useSelector(
    (e) => e.adminSlice.infiniteScrollCardList
  );
  const infiniteScrollCardsCount = useSelector(
    (e) => e.adminSlice.infiniteScrollCardsCount
  );

  let roles = [currentRole.patient];
  let status = [defaultStatus.approved];

  const setUserData = async () => {
    dispatch(
      infiniteScrollGetCardsByRoleStatus({
        pageSize: pageSize,
        pageNo: pageNo,
        agencyName: searchValue && searchValue,
        isOnline: true,
        roles,
        status,
      })
    );
    if (infiniteScrollCardsCount === infiniteScrollCardList.length) {
      sethasMore(false);
    }
    setPageNo((page) => page + 1);
  };

  useEffect(() => {
    dispatch(
      infiniteScrollGetCardsByRoleStatus({
        pageSize: pageSize,
        pageNo: 1,
        isOnline: true,
        roles,
        status,
      })
    );
    return () => {
      dispatch(resetUserList());
    };
  }, []);

  const debounceCall = debounce((ele) => {
    setsearchValue(ele);
    dispatch(
      infiniteScrollGetCardsByRoleStatus({
        pageSize: pageSize,
        isOnline: true,
        pageNo: 1,
        search: ele,
        roles,
        status,
      })
    );
    setPageNo(1);
  }, 400);

  function handleClick(id) {
    navigate(`/patientdetails/${id}`);
  }

  const columns = ["firstName", "createdByUser.name", "status"];
  const columnsToHide = ["image"];

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "firstName":
        return "NAME";
      case "createdByUser.name":
        return "Agency";
      case "status":
        return "CASE STATUS";

      default:
        return colName;
    }
  };

  const actions = [
    {
      action: (rowData) => (
        <td className="">
          <button
            className="view-btn tr-btn ml-auto  mt-3 mb-3 "
            onClick={() => {
              handleClick(rowData.id);
            }}
          >
            View
          </button>
        </td>
      ),
    },
  ];

  const renderRowData = (result, col, item) => {
    if (col === "firstName") {
      return (
        <>
          <p className="f-color">
            <img
              className="table-img"
              src={
                result.image
                  ? `${apiBasePath}documents/therapist/${result.image}`
                  : userImage
              }
              crossOrigin="anonymous"
              style={{ borderRadius: "50%", objectFit: "cover" }}
              alt="img"
            />
            {result["lastName"]} {" , "} {result["firstName"]}
          </p>
        </>
      );
    }
    if (col === "status") {
      return (
        <>
          <p
            className="mt-3 mb-3"
            style={{
              background: `${colors["Completed"].background}`,
              color: `${colors["Completed"].color}`,
              width: "178px",
              height: "39px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "40px",
            }}
          >
            Approved
          </p>
        </>
      );
    }

    if (col === "createdByUser.name") {
      return (
        <>
          <p className="mt-3 mb-3">
            {result &&
              result["createdByUser"] &&
              result["createdByUser"]["name"]}
          </p>
        </>
      );
    }
    return <p className="f-color2 mt-3 mb-3">{result[item]}</p>;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="dailyPatients">
        <Modal.Header
          className="closeButton border-0"
          closeButton
        ></Modal.Header>

        <div className=" modal_title align-items-center mb-4 d-flex justify-content-between pl-5 pr-5 zindex">
          <h5 className="Daily-therapist-h5"> Daily Active Patients {count}</h5>

          <div className="search-bar d-flex" style={{ width: "44%" }}>
            <CgSearch size={"25px"} />
            <input
              placeholder="Search by therapist name..."
              className="pl-2 input-width"
              onChange={(ele) => debounceCall(ele.target.value)}
              style={{ border: "none", fontSize: "18px", outline: "none" }}
            />
          </div>
        </div>
        <div
          id="scrollableDiv"
          style={{
            height: 700,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isLoading && (
            <div className="loader-1" style={{ height: "700" }}>
              <Loader />
            </div>
          )}
          <InfiniteScroll
            dataLength={infiniteScrollCardList && infiniteScrollCardList.length}
            next={setUserData}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            <Col lg={11} className="m-auto pb-1">
              <div className="table-responsive">
                <CommonTable
                  data={infiniteScrollCardList && infiniteScrollCardList}
                  columns={columns}
                  columnsToHide={columnsToHide}
                  overrideColumnName={overrideColumnName}
                  actions={actions}
                  renderRowData={renderRowData}
                  noData={"No Data Available"}
                  isLoading={isLoading}
                />
              </div>
            </Col>
          </InfiniteScroll>
        </div>
      </Modal>
    </>
  );
};
