import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { axiosInstance } from "../config/axiosInstance";

export const getAdminHomeCardData = createAsyncThunk(
  "admin/getAdminHomeCardData",
  async () => {
    const data1 = await axiosInstance.get(`admin/home-card-data`);
    return data1.data;
  }
);

export const getBlockListForAdmin = createAsyncThunk(
  "Admin/BlockList",
  async (payload) => {
    const data1 = await axiosInstance.post(
      `staffing/getAll-Block-list`,
      payload
    );
    return data1.data;
  }
);

export const addToBlockByAdmin = createAsyncThunk(
  "Admin/Add-to-block",
  async (payload) => {
    const data1 = await axiosInstance.post(`staffing/addto-block`, payload);
    return data1.data;
  }
);

export const updateSignature = createAsyncThunk(
  "User/Signautre/Add-Update",
  async (payload) => {
    const apiData = await axiosInstance.post(
      "/user/updateUserSingature",
      payload
    );
    return apiData.data;
  }
);

export const getAllUserDetails = createAsyncThunk(
  "getAllUserDetails ",
  async (data) => {
    const data1 = await axiosInstance.post("user/therapistUserDetails", data);
    return data1.data;
  }
);

export const getUserCredential = createAsyncThunk(
  "admin/adminUserCredential",
  async (userId) => {
    const data1 = await axiosInstance.get(
      `user/therapistUserCredential/${userId}`
    );
    return data1.data;
  }
);
export const userCredentialUpdate = createAsyncThunk(
  "admin/adminUserCredentialUpdate",
  async (payload) => {
    const data1 = await axiosInstance.post(
      `user/therapistUserCredentialUpdate`,
      payload
    );
    return data1.data;
  }
);

export const getAgencyAllUserDetails = createAsyncThunk(
  "getAgencyAllUserDetails ",
  async (data) => {
    const data1 = await axiosInstance.post("user/agencyUserDetails", data);
    return data1.data;
  }
);

export const getAgencyUserCredential = createAsyncThunk(
  "admin/adminAgencyUserCredential",
  async (id) => {
    const data1 = await axiosInstance.get(`user/agencyUserCredential/${id}`);
    return data1.data;
  }
);
