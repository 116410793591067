import React, { useEffect, useState } from "react";
import { Row, Col } from "bootstrap-4-react";
import { useNavigate, useParams } from "react-router-dom";
import CommonTable from "./common/CommonTable";
import Patient from "../images/usertable02.svg";
import Searchbar from "../images/search_grey_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  currentRole,
  defaultStatus,
  encodedData,
  getUserId,
} from "../utils/auth";
import { CommonHead2 } from "./common/CommonHead2";
import { setLoader } from "../Redux/slices/authSlice";
import Loader from "./Loader";
import { apiBasePath } from "../Redux/config/config";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { debounce } from "lodash";
import { BiCircle } from "react-icons/bi";
import EpisodeModal from "../pages/Patients/EpisodeModal";
import {
  getCaseManagementStatus,
  getCaseManagementTabCount,
} from "../Redux/actions/common";
import ReactPaginate from "react-paginate";
import {
  caseManagementTabs,
  statusBaseColorChange,
  viewCaseStatueName,
} from "../utils/helper";
import AuthorizationAgency from "./AuthorizationAgency";

const CaseManagement = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const isModalLoading = useSelector((e) => e.staffingSlice.isModalLoading);
  const caseListData = useSelector((e) => e.commonSlice.caseList);

  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const caseCount = useSelector((e) => e.commonSlice.casemgmttabcount);
  const userRoleStatusCount = useSelector(
    (e) => e.staffingSlice.staffRoleStatusCount
  );
  const isLoading = useSelector((e) => e.commonSlice.isLoading);

  let myTabStatus = {
    awaitingsocroc: defaultStatus.awaitingsocroc,
    patientneedscheduling: defaultStatus.patientneedscheduling,
    newfrequencies: defaultStatus.newfrequencies,
    pendingDMEs: defaultStatus.pendingDMEs,
    authorization: defaultStatus.authorization,
    lastvisittypeapproval: defaultStatus.lastvisittypeapproval,
  };

  const statusShow = (key) => {
    let status;
    switch (key) {
      case defaultStatus.staffedreferral:
        status = defaultStatus.awaitinghhasoc;
        break;

      default:
        break;
    }
    return status;
  };

  useEffect(() => {
    if (params.tab && caseManagementTabs[params.tab]) {
      setMenuItem(params?.tab);
    } else if (params.tab && !caseManagementTabs[params.tab]) {
      navigate("/casemanagement");
    } else if (currentUser && currentUser.Role) {
      setMenuItem(finalMenu.length > 0 ? finalMenu[0].key : menu[0].key);
    } else {
      dispatch(setLoader({ loading: true }));
    }
    dispatch(
      getCaseManagementStatus({
        pageSize,
        pageNo: 1,
        tab: myTabStatus[
          params.tab
            ? params.tab
            : finalMenu.length > 0
            ? finalMenu[0].key
            : menu[0].key
        ],
      })
    );
    dispatch(getCaseManagementTabCount());
  }, [currentUser]);

  const debounceCall = debounce((ele) => {
    setPageNo(1);
    dispatch(
      getCaseManagementStatus({
        pageSize,
        pageNo: 1,
        tab: myTabStatus[menuItem],
        search: ele,
      })
    );
  }, 700);

  const getRoleDetailCount = (role, status) => {
    let count = 0;
    if (userRoleStatusCount && userRoleStatusCount.length > 0) {
      let user = userRoleStatusCount.find(
        (item) =>
          item.Role.key === role && item.Status.key === defaultStatus[status]
      );
      if (user && user.userCount) {
        count = user.userCount;
      }
    }

    return count;
  };

  const setTabMenus = (tab1) => {
    document.getElementById("searchField").value = "";
    dispatch(setLoader({ loading: true }));
    setMenuItem(tab1);
    setPageNo(1);
    dispatch(
      getCaseManagementStatus({
        pageSize,
        pageNo: 1,
        tab: myTabStatus[tab1],
      })
    );
    dispatch(getCaseManagementTabCount());
  };

  function handleClick(rowData, tab) {
    let url = "";
    tab = "episodes";
    if (rowData?.staffUserId) {
      url = `/patientdetails/${encodedData(rowData?.staffUserId)}`;
    } else if (rowData?.userId) {
      url = `/patientdetails/${encodedData(rowData?.userId)}`;
    } else if (rowData?.id) {
      url = `/patientdetails/${encodedData(rowData?.id)}`;
    }
    if (tab) {
      url = `${url}/${tab}`;
    }
    navigate(url);
  }

  const menu = [
    {
      key: "awaitingsocroc",
      label: "Awaiting SOC/ROC",
      count: caseCount && caseCount?.awaiting,
      data: caseListData,
      for: [currentRole.agency, currentRole.admin, currentRole.superadmin],
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <button
                  className="update-btn justify-content-center ml-auto  mt-3 mb-3"
                  onClick={() => {
                    handleClick(rowData);
                  }}
                >
                  View
                </button>
              </td>
            </>
          ),
        },
      ],

      modals: <EpisodeModal />,
      dialogClassName: "modal-lg",
      modalClass: "Main_modal_1",
      columns: ["PATIENT", "ADDRESS", "DISCIPLINES", "CASE STATUS"],
      columnsToHide: ["image"],
    },
    {
      key: "patientneedscheduling",
      label: "Patients Needing Scheduling",
      data: caseListData,
      count:
        caseCount && caseCount.patientneedscheduling
          ? caseCount?.patientneedscheduling
          : "00",
      for: [
        currentRole.agency,
        currentRole.admin,
        currentRole.superadmin,
        currentRole.supervisingtherapist,
        currentRole.assistanttherapist,
      ],
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                {/* <pre className="d-flex gap-3 flex-wrap mb-0"> */}
                <button
                  className=" update-btn justify-content-center  ml-auto  mt-3 mb-3"
                  onClick={() => {
                    handleClick(rowData);
                  }}
                >
                  View
                </button>
                {/* </pre> */}
              </td>
            </>
          ),
        },
      ],

      modals: <EpisodeModal />,
      dialogClassName: "modal-lg",
      modalClass: "Main_modal_1",
      columns: ["PATIENT", "ADDRESS", "DISCIPLINES", "CASE STATUS"],
      columnsToHide: ["image"],
    },
    {
      key: "awaitingsocroc",
      label: "Patient Awaiting SOC/ROC",
      count: caseCount && caseCount?.awaiting,
      data: caseListData,
      for: [currentRole.supervisingtherapist, currentRole.assistanttherapist],
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <button
                  className="update-btn justify-content-center ml-auto  mt-3 mb-3"
                  onClick={() => {
                    handleClick(rowData);
                  }}
                >
                  View
                </button>
              </td>
            </>
          ),
        },
      ],

      modals: <EpisodeModal />,
      dialogClassName: "modal-lg",
      modalClass: "Main_modal_1",
      columns: ["PATIENT", "ADDRESS", "DISCIPLINES", "CASE STATUS"],
      columnsToHide: ["image"],
    },
    {
      label: "New Frequencies",
      key: "newfrequencies",
      data: caseListData,
      count: caseCount && caseCount?.frequencyCount,
      columns: ["PATIENT", "ADDRESS", "DISCIPLINES", "CASE STATUS"],
      columnsToHide: ["image"],
      modalClass: "Main_modal_1",
      for: [currentRole.agency, currentRole.admin, currentRole.superadmin],
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <button
                  className=" update-btn justify-content-center ml-auto  mt-3 mb-3"
                  onClick={() => {
                    handleClick(rowData, "episodes");
                  }}
                >
                  View
                </button>
              </td>
            </>
          ),
        },
      ],
    },
    {
      label: "Pending DMEs",
      key: "pendingDMEs",
      count: 0,
      data: caseListData,
      for: [currentRole.agency, currentRole.admin, currentRole.superadmin],
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <button
                  className="update-btn justify-content-center ml-auto  mt-3 mb-3 "
                  onClick={() => {
                    handleClick(rowData);
                  }}
                >
                  View
                </button>
              </td>
            </>
          ),
        },
      ],
      modals: <EpisodeModal />,
      columns: ["PATIENT", "ADDRESS", "DISCIPLINES", "CASE STATUS"],
      modalClass: "Main_modal_1",
      columnsToHide: ["image"],
    },
    {
      label: "Upcoming Assessment Visit",
      key: "lastvisittypeapproval",
      data: caseListData,
      count: 0,
      columns: ["PATIENT", "ADDRESS", "CASE STATUS"],
      columnsToHide: ["image"],
      for: [currentRole.supervisingtherapist, currentRole.assistanttherapist],
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <pre className="d-flex gap-3 flex-wrap mb-0">
                  <button
                    className="update-btn justify-content-center ml-auto  mt-3 mb-3 "
                    onClick={() => {
                      navigate(
                        `/patientdetails/${encodedData(
                          rowData?.episodeStaff.staffUserId
                        )}/episodes `
                      );
                    }}
                  >
                    View
                  </button>
                </pre>
              </td>
            </>
          ),
        },
      ],
      modals: <EpisodeModal />,
      modalClass: "Main_modal_1",
    },
    {
      label: "Pending Authorization",
      key: "authorization",
      data: caseListData.pendingData,
      count: caseCount && caseCount?.pendingAuthorization,
      columns: ["PATIENT", "ADDRESS", "DISCIPLINES", "CASE STATUS"],
      columnsToHide: ["image"],
      modalClass: "Main_modal_1",
      for: [currentRole.supervisingtherapist, currentRole.assistanttherapist],
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <button
                  className=" update-btn justify-content-center ml-auto  mt-3 mb-3"
                  onClick={() => {
                    handleClick(rowData, "episodes");
                  }}
                >
                  View
                </button>
              </td>
            </>
          ),
        },
      ],
    },
    {
      label: "Authorizations",
      key: "authorization",
      data: caseListData,
      count:
        caseCount &&
        Number(caseCount?.pendingAuthorization) +
          Number(caseCount.approvedauthorization),
      columns: ["PATIENT", "ADDRESS", "DISCIPLINES", "CASE STATUS"],
      columnsToHide: ["image"],
      modalClass: "Main_modal_1",
      for: [currentRole.agency, currentRole.admin, currentRole.superadmin],
      component: <AuthorizationAgency />,
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <button
                  className=" update-btn justify-content-center ml-auto  mt-3 mb-3"
                  onClick={() => {
                    handleClick(rowData);
                  }}
                >
                  View
                </button>
              </td>
            </>
          ),
        },
      ],
    },
    {
      label: "Last Visit Type Approval",
      key: "lastvisittypeapproval",
      data: caseListData,
      count: caseCount && Number(caseCount?.lastVistApprovel),
      columns: [
        "episodeStaff.staffData.firstName",
        "episodeStaff.staffData.staffingDetails.address",
        "episodeStaff.staffData.staffingDetails.disciplinesNeeded",
        "episodeStaff.staffData.staffInitialVisitss.Status.name",
      ],
      columnsToHide: ["image"],
      for: [currentRole.agency, currentRole.admin, currentRole.superadmin],
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <pre className="d-flex gap-3 flex-wrap mb-0">
                  <button
                    className="update-btn justify-content-center ml-auto  mt-3 mb-3 "
                    onClick={() =>
                      navigate(
                        `/patientdetails/${encodedData(
                          rowData?.episodeStaff.staffUserId
                        )}/episodes `
                      )
                    }
                  >
                    View
                  </button>
                </pre>
              </td>
            </>
          ),
        },
      ],
      modals: <EpisodeModal />,
      modalClass: "Main_modal_1",
    },
  ];

  const finalMenu = menu.filter(
    (ele) => currentUser && ele.for.includes(currentUser.Role.key)
  );
  const [menuItem, setMenuItem] = useState(
    finalMenu.length > 0 ? finalMenu[0]["key"] : "patientneedscheduling"
  );
  const myActions = finalMenu.find((ele) => ele.key === menuItem);
  const overrideColumnName = (colName) => {
    switch (colName) {
      case "firstName":
        return "Patient";
      case "staffingDetails.address":
        return "Address";
      case "disciplines":
        return "Disciplines";
      case "name":
        return "Agency Name";
      case "staffingDetails.disciplinesNeeded":
        return "DISCIPLINES";
      case "staffingDetails.createdByUser.name":
        return "Agency Name";
      case "createdByUser.name":
        return "Agency Name";

      case "episodeStaff.staffData.firstName":
        return "Patient";
      case "episodeStaff.staffData.staffingDetails.disciplinesNeeded":
        return "DISCIPLINES";
      case "episodeStaff.staffData.staffingDetails.address":
        return "Address";
      case "episodeStaff.staffData.staffInitialVisitss.Status.name":
        return "Case Status";

      default:
        return colName;
    }
  };

  const handlePageClick = (event) => {
    dispatch(setLoader({ loading: true }));
    dispatch(
      getCaseManagementStatus({
        pageSize,
        pageNo: event.selected + 1,
        tab: myTabStatus[menuItem],
      })
    );
    setPageNo(event.selected + 1);
  };

  const renderRowData = (result, col, item) => {
    if (col === "PATIENT") {
      if (
        currentUser?.Role?.key === currentRole?.supervisingtherapist ||
        currentUser?.Role?.key === currentRole?.admin ||
        currentUser?.Role?.key === currentRole?.superadmin
      ) {
        if (menuItem == "authorization") {
          return (
            <>
              <div className="d-flex align-items-center">
                <img
                  src={
                    result.image
                      ? `${apiBasePath}documents/therapist/${result.image}`
                      : Patient
                  }
                  crossOrigin="anonymous"
                  className="table-img mr-0"
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                  alt="img"
                />
                <div>
                  <h1 className="title-22 text-nowrap margin-left-35 caseColor">
                    {result && `${result.lastName}, ${result.firstName}`}
                  </h1>
                </div>
              </div>
            </>
          );
        } else if (menuItem == "lastvisittypeapproval") {
          return (
            <>
              <div className="d-flex align-items-center">
                <img
                  src={
                    result.image
                      ? `${apiBasePath}documents/therapist/${result.image}`
                      : Patient
                  }
                  crossOrigin="anonymous"
                  className="table-img mr-0"
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                  alt="img"
                />
                <div>
                  <h1 className="title-22 text-nowrap margin-left-35 caseColor">
                    {result &&
                      result.episodeStaff &&
                      result.episodeStaff.staffData &&
                      `${result.episodeStaff.staffData.lastName}, ${result.episodeStaff.staffData.firstName}`}
                  </h1>
                </div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="d-flex align-items-center">
                <img
                  src={
                    result.image
                      ? `${apiBasePath}documents/therapist/${result.image}`
                      : Patient
                  }
                  crossOrigin="anonymous"
                  className="table-img  "
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                  alt="img"
                />
                <div>
                  <h1 className="title-22 m-0 caseColor">
                    {result && `${result.lastName}, ${result.firstName}`}
                  </h1>
                  {result?.staffingDetails?.mrn && (
                    <p className="title-24 m-0">
                      MR #{result?.staffingDetails?.mrn}
                    </p>
                  )}
                </div>
              </div>
            </>
          );
        }
      } else {
        return (
          <>
            <div className="d-flex align-items-center">
              <img
                src={
                  result.image
                    ? `${apiBasePath}documents/therapist/${result.image}`
                    : Patient
                }
                crossOrigin="anonymous"
                className="table-img  "
                style={{ borderRadius: "50%", objectFit: "cover" }}
                alt="img"
              />
              <div>
                <h1 className="title-22 m-0 caseColor">
                  {result["lastName"]}
                  {", "}
                  {result["firstName"]}
                </h1>
                {result?.staffingDetails?.mrn && (
                  <p className="title-24 m-0">
                    MR #{result?.staffingDetails?.mrn}
                  </p>
                )}
              </div>
            </div>
          </>
        );
      }
    }
    if (col === "MR#") {
      if (
        currentUser?.Role?.key === currentRole?.supervisingtherapist ||
        currentUser?.Role?.key === currentRole?.admin ||
        currentUser?.Role?.key === currentRole?.superadmin
      ) {
        if (menuItem === "authorization") {
          return (
            <>
              <p className="m-0 addres-col caseColor">
                {result?.staffUser?.staffingDetails?.mrn}
              </p>
            </>
          );
        } else if (menuItem == "lastvisittypeapproval") {
          return (
            <>
              <p className="m-0 addres-col caseColor">
                {result?.episodeStaff &&
                  result?.episodeStaff.staffData.staffingDetails &&
                  result?.episodeStaff.staffData.staffingDetails.mrn}
              </p>
            </>
          );
        } else {
          return (
            <>
              <p className="m-0 addres-col caseColor">
                {result?.staffUser?.staffingDetails?.mrn}
              </p>
            </>
          );
        }
      } else if (currentUser?.Role?.key === currentRole?.assistanttherapist) {
        return (
          <>
            <p className="m-0 addres-col caseColor">
              {result && result?.staffUser?.staffingDetails?.mrn}
            </p>
          </>
        );
      } else {
        return (
          <>
            <p className="m-0 addres-col caseColor">
              {result?.staffingDetails?.mrn}
            </p>
          </>
        );
      }
    }
    if (col === "ADDRESS") {
      if (
        currentUser?.Role?.key === currentRole?.supervisingtherapist ||
        currentUser?.Role?.key === currentRole?.admin ||
        currentUser?.Role?.key === currentRole?.superadmin
      ) {
        if (menuItem === "authorization") {
          return (
            <div>
              <p className="m-0 addres-col caseColor">
                {result?.staffingDetails?.address}
              </p>
              <p className="m-0 addres-col caseColor">
                {result?.staffingDetails?.city}
                {", "}
                {result?.staffingDetails?.state}
                {", "}
                {result?.staffingDetails?.zip}
              </p>
            </div>
          );
        } else if (menuItem == "lastvisittypeapproval") {
          return (
            <div>
              <p className="m-0 addres-col caseColor">
                {result?.episodeStaff.staffData.staffingDetails.address}
              </p>
              <p className="m-0 addres-col caseColor">
                {result?.episodeStaff.staffData.staffingDetails?.city}
                {", "}
                {result?.episodeStaff.staffData.staffingDetails?.state}
                {", "}
                {result?.episodeStaff.staffData.staffingDetails?.zip}
              </p>
            </div>
          );
        } else {
          return (
            <div>
              <p className="m-0 addres-col caseColor">
                {result?.staffingDetails?.address}
              </p>
              <p className="m-0 addres-col caseColor">
                {result?.staffingDetails?.city}
                {", "}
                {result?.staffingDetails?.state}
                {", "}
                {result?.staffingDetails?.zip}
              </p>
            </div>
          );
        }
      } else if (currentUser?.Role?.key === currentRole?.assistanttherapist) {
        return (
          <div>
            <p className="m-0 addres-col caseColor">
              {result?.staffingDetails?.address}
            </p>
            <p className="m-0 addres-col caseColor">
              {result?.staffingDetails?.city}
              {", "}
              {result?.staffingDetails?.state}
              {", "}
              {result?.staffingDetails?.zip}
            </p>
          </div>
        );
      } else {
        return (
          <div>
            <p className="m-0 addres-col caseColor">
              {result?.staffingDetails?.address}
            </p>
            <p className="m-0 addres-col caseColor">
              {result?.staffingDetails?.city}
              {", "}
              {result?.staffingDetails?.state}
              {", "}
              {result?.staffingDetails?.zip}
            </p>
          </div>
        );
      }
    }
    if (col === "DISCIPLINES") {
      if (menuItem === "authorization") {
        return (
          <>
            {currentUser && currentUser.Role.key !== currentRole.agency ? (
              <div>
                {result?.staffingDetails?.disciplinesNeeded &&
                  Object.keys(result?.staffingDetails?.disciplinesNeeded).map(
                    (e) => {
                      return (
                        <div className="d-flex gap-2">
                          <p className="m-0 spanp caseColor">
                            {e.toUpperCase()}
                          </p>
                          <span className="spanp caseColor">
                            <BsFillCheckCircleFill className="progress-check-after" />
                          </span>
                        </div>
                      );
                    }
                  )}
              </div>
            ) : (
              <div className="d-flex gap-1">
                {result?.staffingDetails?.disciplinesNeeded &&
                  Object.keys(result?.staffingDetails?.disciplinesNeeded).map(
                    (e) => {
                      return (
                        <div className="d-flex gap-2">
                          <p className="m-0 spanp caseColor">
                            {e.toUpperCase()}
                          </p>
                          <span className="spanp caseColor">
                            <BsFillCheckCircleFill className="progress-check-after" />
                          </span>
                        </div>
                      );
                    }
                  )}
              </div>
            )}
          </>
        );
      } else {
        return (
          <>
            {currentUser && currentUser.Role.key !== currentRole.agency ? (
              <div className="d-flex gap-2">
                {result?.staffingDetails?.disciplinesNeeded &&
                  Object.keys(result?.staffingDetails?.disciplinesNeeded).map(
                    (e) => {
                      return (
                        <div className="d-flex gap-2">
                          <p className="m-0 spanp">{e.toUpperCase()}</p>
                          <span className="spanp">
                            <BsFillCheckCircleFill className="progress-check-after" />
                          </span>
                        </div>
                      );
                    }
                  )}
              </div>
            ) : (
              <div className="d-flex gap-1">
                {result?.staffingDetails?.disciplinesNeeded &&
                  Object.keys(result?.staffingDetails?.disciplinesNeeded).map(
                    (e) => {
                      return (
                        <div className="d-flex gap-2">
                          <p className="m-0 spanp">{e.toUpperCase()}</p>
                          <span className="spanp">
                            <BsFillCheckCircleFill className="progress-check-after" />
                          </span>
                        </div>
                      );
                    }
                  )}
              </div>
            )}
          </>
        );
      }
    }

    if (col === "CASE STATUS") {
      if (
        currentUser?.Role?.key === currentRole?.supervisingtherapist ||
        currentUser?.Role?.key === currentRole?.admin ||
        currentUser?.Role?.key === currentRole?.superadmin
      ) {
        if (menuItem == "lastvisittypeapproval") {
          return (
            <>
              {result?.episodeStaff.staffData.staffInitialVisitss ? (
                <p
                  className={`m-0 mt-3 mb-3 status-td ${statusBaseColorChange(
                    result &&
                      result.episodeStaff.staffData.staffInitialVisitss &&
                      result.episodeStaff.staffData.staffInitialVisitss.Status
                        .key
                  )}`}
                >
                  {viewCaseStatueName(
                    result &&
                      result.episodeStaff.staffData.staffInitialVisitss &&
                      result.episodeStaff.staffData.staffInitialVisitss
                        .Status &&
                      result.episodeStaff.staffData.staffInitialVisitss.Status
                        .key
                  )}
                </p>
              ) : (
                "N/A"
              )}
            </>
          );
        } else {
          return (
            <>
              {result?.staffInitialVisit &&
              result?.staffInitialVisit.length > 0 &&
              result?.staffInitialVisit[0]?.Status ? (
                <p
                  className={`m-0 mt-3 mb-3 status-td ${statusBaseColorChange(
                    result &&
                      result.staffInitialVisit &&
                      result.staffInitialVisit.length > 0 &&
                      result.staffInitialVisit[0].Status &&
                      result.staffInitialVisit[0].Status.key
                  )}`}
                >
                  {viewCaseStatueName(
                    result &&
                      result.staffInitialVisit &&
                      result.staffInitialVisit.length > 0 &&
                      result.staffInitialVisit[0].Status &&
                      result.staffInitialVisit[0].Status.key
                  )}
                </p>
              ) : (
                "N/A"
              )}
            </>
          );
        }
      } else if (currentUser?.Role?.key === currentRole?.agency) {
        return (
          <>
            {result &&
            result.staffInitialVisit &&
            result.staffInitialVisit.length > 0 &&
            result?.staffInitialVisit[0]?.Status ? (
              <p
                className={`m-0 mt-3 mb-3 status-td ${statusBaseColorChange(
                  result &&
                    result.staffInitialVisit &&
                    result.staffInitialVisit.length > 0 &&
                    result.staffInitialVisit[0].Status &&
                    result.staffInitialVisit[0].Status.key
                )}`}
              >
                {viewCaseStatueName(
                  result &&
                    result.staffInitialVisit &&
                    result.staffInitialVisit.length > 0 &&
                    result.staffInitialVisit[0].Status &&
                    result.staffInitialVisit[0].Status.key
                )}
              </p>
            ) : (
              "N/A"
            )}
          </>
        );
      }
    }

    if (col === "episodeStaff.staffData.firstName") {
      const user = result.episodeStaff?.staffData;
      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={
                user && user.image
                  ? `${apiBasePath}documents/therapist/${user.image}`
                  : Patient
              }
              crossOrigin="anonymous"
              className="table-img  "
              style={{ borderRadius: "50%", objectFit: "cover" }}
              alt="img"
            />
            <div>
              <p className="  title-22 m-0">
                {user?.lastName + ", " + user?.firstName}
              </p>
              {user?.staffingDetails?.mrn && (
                <p className="  title-24">MR #{user?.staffingDetails?.mrn}</p>
              )}
            </div>
          </div>
        </>
      );
    }

    if (col === "episodeStaff.staffData.staffingDetails.disciplinesNeeded") {
      const user = result.episodeStaff?.staffData;
      return (
        <div>
          {user &&
          user.staffingDetails &&
          user.staffingDetails["disciplinesNeeded"] &&
          Object.keys(
            user &&
              user.staffingDetails &&
              user.staffingDetails["disciplinesNeeded"]
          ).length > 0 ? (
            <p className="m-0  displine-list">
              {Object.keys(user.staffingDetails["disciplinesNeeded"])
                .join(",")
                .toUpperCase()}
            </p>
          ) : (
            <p>--</p>
          )}
        </div>
      );
    }
    if (col === "episodeStaff.staffData.staffingDetails.address") {
      const user = result.episodeStaff?.staffData;
      return (
        <>
          <p className="m-0 addres-col">
            {user && user.staffingDetails && user.staffingDetails["address"]}
          </p>
          <p className="m-0 addres-col">
            {user && user.staffingDetails && user.staffingDetails["city"]}
            {", "}
            {user && user.staffingDetails && user.staffingDetails["state"]}
            {", "}
            {user && user.staffingDetails && user.staffingDetails["zip"]}
          </p>
        </>
      );
    }

    if (col === "episodeStaff.staffData.staffInitialVisitss.Status.name") {
      const user = result.episodeStaff?.staffData;
      return (
        <>
          <p
            className={`m-0 mt-3 mb-3 status-td ${
              user &&
              user.staffInitialVisitss &&
              user.staffInitialVisitss.Status &&
              statusBaseColorChange(user.staffInitialVisitss.Status.key)
            }`}
          >
            {user && user.staffInitialVisitss && user.staffInitialVisitss.Status
              ? user.staffInitialVisitss.Status.name
              : "Incomplete"}
          </p>
        </>
      );
    }
    return <h1 className=" title-24  mt-3 mb-3">{result[item]}</h1>;
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12  pl-5 pr-5">
            <div className="patient_main_parent  gap-3 mobile_justify justify-content-between">
              <div
                className="search_bar_frm pl-3 searching_spacing"
                style={{ width: "400px" }}
              >
                <img style={{ marginRight: "15px" }} src={Searchbar} alt="" />
                <input
                  placeholder="Search by patient name."
                  id="searchField"
                  className="pl-2 searching"
                  style={{
                    border: "none",
                    fontSize: "18px",
                    outline: "none",
                    width: "100%",
                  }}
                  onChange={(ele) => {
                    debounceCall(ele.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="user-type-main tabs_user_type">
            <div className="col-sm-12 pr-5 pl-5">
              <CommonHead2
                data={finalMenu}
                setMenu={setTabMenus}
                menuItem={menuItem}
                css={"col1 "}
                // countClass={"casemanage"}
                // this class is for the new design
              />
            </div>
          </div>
          {myActions && myActions.component ? (
            myActions.component
          ) : (
            <div className="container-fluid ">
              <Row>
                <div className="inside_table_tabs justify-content-start table_left_padding">
                  <Col lg={11}>
                    <div className="table-responsive mt-4">
                      {myActions &&
                      myActions.data &&
                      myActions.data.length > 0 ? (
                        <CommonTable
                          data={myActions?.data}
                          columns={myActions?.columns}
                          columnsToHide={myActions?.columnsToHide}
                          overrideColumnName={overrideColumnName}
                          actions={myActions?.actions}
                          renderRowData={renderRowData}
                        />
                      ) : (
                        <div className="d-flex align-items-center justify-content-center mt-5">
                          {!isLoading && (
                            <h2 className="fs-5 mt-5">No Data available</h2>
                          )}
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </Row>
              {myActions?.count > pageSize && myActions?.data?.length > 0 && (
                <div id="container ">
                  <div className="row justify-content-start mt-3  pl-5 ">
                    <div className=" pl-5 pr-5">
                      <ReactPaginate
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={Math.ceil(myActions?.count / pageSize)}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        forcePage={
                          Math.ceil(myActions?.count / pageSize) === 0
                            ? -1
                            : pageNo - 1
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {(isLoading || isModalLoading) && <Loader />}
    </>
  );
};

export default CaseManagement;
