import React from "react";
import { VscFolder } from "react-icons/vsc";

const ResgistrationRejected = () => {
  return (
    <div
      className="registrationRejection"
      style={{ margin: "30px 0px 0px 30px" }}
    >
      <div
        className="errorRegistration"
        style={{
          background: " rgb(251 238 238)",
          border: "1px solid red",
          borderRadius: "7px",
          width: "40%",
          marginBottom: "20px",
          padding: "10px 10px 0 10px",
        }}
      >
        <p
          style={{
            fontSize: "14px",
            color: "red",
            fontWeight: "bold",
            marginBottom: "0",
          }}
        >
          Something went wrong
        </p>
        <p style={{ fontSize: "12px", color: "red" }}>
          Your registration has been flagged. Please correct the information
          below and re-submit.
        </p>
      </div>
      <div className="registrationError" style={{ marginBottom: "20px" }}>
        <h2>Registration Rejected</h2>
        <p style={{ fontSize: "14px", fontWeight: "bold" }}>
          Reason for rejection
        </p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            className="registrationErrorReason"
            style={{
              background: " rgb(251 238 238)",
              border: "1px solid red",
              borderRadius: "7px",
              width: "50%",
              padding: "10px 10px 0 10px",
            }}
          >
            <p style={{ fontSize: "13px" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elt, sed do
              elusmod tempor incididunt ut labore et dolore magnd enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
              ex ea commodo consequat. Duis aute irure dolor ir
            </p>
          </div>
          <div style={{ paddingTop: "10px" }}>
            <button type="submit" style={{ margin: "auto 20px auto 0" }}>
              Submit
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="">
          <h6>Specify Errors</h6>
          <p></p>
          <div className="row error-row" style={{ paddingTop: "10px" }}>
            <div className="col-md-3">
              <p
                className="errorFormHeading"
                style={{
                  fontSize: "13px",
                  textTransform: "uppercase",
                  marginBottom: "0px",
                }}
              >
                Name
              </p>
              <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                Assisted HHA
              </p>
            </div>
            <div className="col-md-3">
              <p
                className="errorFormHeading"
                style={{
                  fontSize: "13px",
                  textTransform: "uppercase",
                  marginBottom: "0px",
                }}
              >
                Discipline
              </p>
              <p style={{ fontWeight: "bold", fontSize: "12px" }}>PT, OT</p>
            </div>
            <div className="col-md-3">
              <p
                className="errorFormHeading"
                style={{
                  fontSize: "13px",
                  textTransform: "uppercase",
                  marginBottom: "0px",
                }}
              >
                Address
              </p>
              <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                Los Angeles
              </p>
            </div>
          </div>
          <div className="row error-row" style={{ paddingTop: "10px" }}>
            <div className="col-md-3">
              <p
                className="errorFormHeading"
                style={{
                  fontSize: "13px",
                  textTransform: "uppercase",
                  marginBottom: "0px",
                }}
              >
                Phone
              </p>
              <p style={{ fontWeight: "bold", fontSize: "12px" }}>1234567789</p>
            </div>
            <div className="col-md-3">
              <p
                className="errorFormHeading"
                style={{
                  fontSize: "13px",
                  textTransform: "uppercase",
                  marginBottom: "0px",
                }}
              >
                Email Address
              </p>
              <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                xyz@gmail.com
              </p>
            </div>
            <div className="col-md-3">
              <p
                className="errorFormHeading"
                style={{
                  fontSize: "13px",
                  textTransform: "uppercase",
                  marginBottom: "0px",
                }}
              >
                Password
              </p>
              <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                ************
              </p>
            </div>
          </div>
          <div
            className="row error-row"
            style={{ paddingTop: "10px", marginBottom: "0px" }}
          >
            <div className="col-md-3">
              <p
                className="errorFormHeading"
                style={{
                  fontSize: "13px",
                  textTransform: "uppercase",
                  marginBottom: "0px",
                }}
              >
                Languages Spoken
              </p>
              <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                English, Spanish
              </p>
            </div>
            <div className="col-md-3">
              <p
                className="errorFormHeading"
                style={{
                  fontSize: "13px",
                  textTransform: "uppercase",
                  marginBottom: "0px",
                }}
              >
                Preferred Specialities
              </p>
              <p style={{ fontWeight: "bold", fontSize: "12px" }}>orthopedic</p>
            </div>
            <div className="col-md-3">
              <p
                className="errorFormHeading"
                style={{
                  fontSize: "13px",
                  textTransform: "uppercase",
                  marginBottom: "0px",
                }}
              >
                Experience
              </p>
              <p style={{ fontWeight: "bold", fontSize: "12px" }}>3 Years</p>
            </div>
          </div>
        </div>
      </div>
      <div className="preferredZipCode" style={{ marginTop: "15px" }}>
        <h6>Preferred Zip Code</h6>
        <div className="preferredZipCode-map" style={{ display: "flex" }}>
          <div className="iframeBox">
            <iframe
              title="Gmap1"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d198740.08541261766!2d-77.15466285891779!3d38.89393804317764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c6de5af6e45b%3A0xc2524522d4885d2a!2sWashington%2C%20D.C.%2C%20DC%2C%20USA!5e0!3m2!1sen!2sin!4v1661245600942!5m2!1sen!2sin"
              width="350"
              height="180"
            ></iframe>
          </div>
          <div className="iframeBox">
            <iframe
              title="Gmap2"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d198740.08541261766!2d-77.15466285891779!3d38.89393804317764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c6de5af6e45b%3A0xc2524522d4885d2a!2sWashington%2C%20D.C.%2C%20DC%2C%20USA!5e0!3m2!1sen!2sin!4v1661245600942!5m2!1sen!2sin"
              width="350"
              height="180"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="registrationRejectedDocument">
        <div style={{ margin: "0" }}>
          <h5
            style={{
              fontSize: "14px",
              color: "#0A365B",
              marginBottom: "22px",
              fontWeight: "bolder",
              marginTop: "35px",
            }}
          >
            Documents
          </h5>
          <div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "400px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",

                  justifyContent: "center",
                }}
              >
                <VscFolder fill={"#0F7AD4"} size={"20px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0" style={{ fontSize: "13px" }}>
                  PT License
                </p>
                <p style={{ fontSize: "12px", marginBottom: "0" }}>
                  Exp. Date xx/xx/xx
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "400px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <VscFolder fill={"#0F7AD4"} size={"20px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0" style={{ fontSize: "13px" }}>
                  Driver's License
                </p>
                <p style={{ fontSize: "12px", marginBottom: "0" }}>
                  Exp. Date xx/xx/xx
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "400px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <VscFolder fill={"#0F7AD4"} size={"20px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0" style={{ fontSize: "13px" }}>
                  Auto Insurance
                </p>
                <p style={{ fontSize: "12px", marginBottom: "0" }}>
                  Exp. Date xx/xx/xx
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "400px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",

                  justifyContent: "center",
                }}
              >
                <VscFolder fill={"#0F7AD4"} size={"20px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0" style={{ fontSize: "13px" }}>
                  Professional Liability Insurance
                </p>
                <p style={{ fontSize: "12px", marginBottom: "0" }}>
                  Exp. Date xx/xx/xx
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "400px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",

                  justifyContent: "center",
                }}
              >
                <VscFolder fill={"#0F7AD4"} size={"20px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0" style={{ fontSize: "13px" }}>
                  CPR Certification
                </p>
                <p style={{ fontSize: "12px", marginBottom: "0" }}>
                  Exp. Date xx/xx/xx
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "400px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",

                  justifyContent: "center",
                }}
              >
                <VscFolder fill={"#0F7AD4"} size={"20px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0" style={{ fontSize: "13px" }}>
                  Physical
                </p>
                <p style={{ fontSize: "12px", marginBottom: "0" }}>
                  Exp. Date xx/xx/xx
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "400px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",

                  justifyContent: "center",
                }}
              >
                <VscFolder fill={"#0F7AD4"} size={"20px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0" style={{ fontSize: "13px" }}>
                  TB Test
                </p>
                <p style={{ fontSize: "12px", marginBottom: "0" }}>
                  Exp. Date xx/xx/xx
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "400px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <VscFolder fill={"#0F7AD4"} size={"20px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0" style={{ fontSize: "13px" }}>
                  Covid-19
                </p>
                <p style={{ fontSize: "12px", marginBottom: "0" }}>
                  Exp. Date xx/xx/xx
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "400px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",

                  justifyContent: "center",
                }}
              >
                <VscFolder fill={"#0F7AD4"} size={"20px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0" style={{ fontSize: "13px" }}>
                  Flu Vaccination Record
                </p>
                <p style={{ fontSize: "12px", marginBottom: "0" }}>
                  Exp. Date xx/xx/xx
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "400px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",

                  justifyContent: "center",
                }}
              >
                <VscFolder fill={"#0F7AD4"} size={"20px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0" style={{ fontSize: "13px" }}>
                  Competency Assessment
                </p>
                <p style={{ fontSize: "12px", marginBottom: "0" }}>
                  Exp. Date xx/xx/xx
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "400px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",

                  justifyContent: "center",
                }}
              >
                <VscFolder fill={"#0F7AD4"} size={"20px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0" style={{ fontSize: "13px" }}>
                  Compliance Documents
                </p>
                <p style={{ fontSize: "12px", marginBottom: "0" }}>
                  Exp. Date xx/xx/xx
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ margin: "0" }}>
          <h5
            style={{
              fontSize: "14px",
              color: "#0A365B",
              marginBottom: "22px",
              fontWeight: "bolder",
              marginTop: "35px",
            }}
          >
            Business Credentials
          </h5>
          <div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "400px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",

                  justifyContent: "center",
                }}
              >
                <VscFolder fill={"#0F7AD4"} size={"20px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0" style={{ fontSize: "13px" }}>
                  w9
                </p>
                <p style={{ fontSize: "12px", marginBottom: "0" }}>
                  Exp. Date xx/xx/xx
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "400px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <VscFolder fill={"#0F7AD4"} size={"20px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0" style={{ fontSize: "13px" }}>
                  Business License
                </p>
                <p style={{ fontSize: "12px", marginBottom: "0" }}>
                  Exp. Date xx/xx/xx
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ width: "50%" }}>
        <h4 style={{ fontSize: "14px", fontWeight: "bold" }}>
          Default Rates(PT)
        </h4>

        <div className="parentdiv  parent-div-space d-flex">
          <div>
            <p className="default-rates-paragraph" style={{ fontSize: "13px" }}>
              OASIS Starts of care / Recertification / Resumption of care /
              Discharge
            </p>
            <p className="default-rates-paragraph" style={{ fontSize: "13px" }}>
              OAISIS Non-visit discharge/Transfer
            </p>
            <p className="default-rates-paragraph" style={{ fontSize: "13px" }}>
              NON-OAISIS Start of Care
            </p>
            <p className="default-rates-paragraph" style={{ fontSize: "13px" }}>
              Evaluation / Re-assessment / Discharge
            </p>
            <p className="default-rates-paragraph" style={{ fontSize: "13px" }}>
              Follow-up
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "35%",
            }}
          >
            <input
              className="rate-box"
              placeholder="$"
              style={{ marginBottom: "12px" }}
            />
            <input
              className="rate-box"
              placeholder="$"
              style={{ marginBottom: "12px" }}
            />
            <input
              className="rate-box"
              placeholder="$"
              style={{ marginBottom: "12px" }}
            />
            <input
              className="rate-box"
              placeholder="$"
              style={{ marginBottom: "12px" }}
            />
            <input
              className="rate-box"
              placeholder="$"
              style={{ marginBottom: "12px" }}
            />
          </div>
        </div>
      </div>

      <div style={{ paddingTop: "10px" }}>
        <button type="submit" style={{ margin: "auto 20px auto 0" }}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default ResgistrationRejected;
