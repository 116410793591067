/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PatientChart from "../../../components/PatientChart";
import { getAdminPatientChart } from "../../../Redux/actions/common";

const Chart = () => {
  let tableColumns = [
    "firstName",
    "createdByUser.name",
    "staffingDetails.caseStatus.name",
  ];
  return (
    <PatientChart getList={getAdminPatientChart} tableColumns={tableColumns} />
  );
};

export default Chart;
