import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance, backgroundInstance } from "../config/axiosInstance";
import { backgroundApiToken } from "../../Redux/config/config";
export const newBackgroundReportOrder = createAsyncThunk(
  "newBackgroundReportOrder/orders/new",
  async (body) => {
    const data1 = await backgroundInstance.post(`orders/new`, body, {
      params: {
        api_token: backgroundApiToken,
      },
    });

    return data1.data.applicants[0];
  }
);

export const backgroundReport = createAsyncThunk(
  "backgroundReport/orders/new",
  async (body) => {
    const data1 = await axiosInstance.post(`staffing/backgroundProcess`, body);

    return data1.data;
  }
);

export const getBackgroundReportStatus = createAsyncThunk(
  "BackgroundCheck/Status",
  async (body) => {
    const data1 = await backgroundInstance.get(`reports/${body}/status`, {
      params: {
        api_token: backgroundApiToken,
      },
    });
    return data1.data;
  }
);

export const getBackgroundReportPDF = createAsyncThunk(
  "BackgroundCheck/PDF/byReportKey",
  async (body) => {
    const data1 = await backgroundInstance.get(`report/${body.reportKey}/pdf`, {
      params: {
        api_token: backgroundApiToken,
      },
    });
    return data1.data;
  }
);
