/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { menuArray } from "../validation/sidebarMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  showListName,
  setSidebarFalse,
  setSidebarTrue,
  sidebarHandlerr,
} from "../Redux/slices/authSlice";
import "../../src/index.css";
import logoImg from "../../src/images/care-connection-logo.png";
import toggel from "../../src/images/AdminDashboard/menu_toggle_icon.svg";
import { getCurrentActiveUser } from "../Redux/actions/auth";
import { currentRole, getUserId, openToNewWindow } from "../utils/auth";
import DirectMessageModal from "../pages/modals/DirectMessageModal";
import { initializeTwilioClient } from "../Redux/slices/chatSlice";
import PaymentPromptModal from "../pages/modals/PaymentPromptModal";
import { io } from "socket.io-client";
import { toast } from "react-toastify";
import {
  ProfileImagePristine,
  agencyImageUpload,
  triggerUpdateButton,
} from "../Redux/slices/therapistSignupSlice";
import WarningPromptModal from "../pages/modals/ConfirmTabChange";
import { MyModal2 } from "../pages/modals/MyModal2";
import { socketBasePath } from "../Redux/config/config";
import { readNotification } from "../Redux/actions/notification";
import { setNewNotification } from "../Redux/slices/notificationSlice";

let socket;
const TopBar = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: ${({ sidebar }) => (sidebar ? "center" : "")};
  transition: 0.4s all ease-in-out;
  gap: 5%;
`;

const Logo = styled.img`
  transition: 0.4s all ease-in-out;
  visibility: ${({ sidebar }) => (sidebar ? "hidden" : "visible")};
  width: ${({ sidebar }) => (sidebar ? "0%" : " 130px")};
`;

const Ham = styled.div`
  display: flex;
  justify-content: center;
  transition: 0.4s all ease-in-out;
  cursor: pointer;
  transform: ${({ sidebar }) => (sidebar ? "rotate(180deg)" : "")};
`;

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  cursor: pointer;
  margin-bottom: 1%;
  display: flex;
  align-items: center;
`;

const Sidebar = () => {
  const listname = useSelector((e) => e.authSlice.listname);
  const sidebar = useSelector((e) => e.authSlice.sidebar);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let userId = getUserId();

  const [show, setShow] = useState(false);
  const [promptModalShow, setPromptModalShow] = useState(false);
  const [warningPrompt, setWarningPrompt] = useState(false);

  const [conatctKey, setContactKey] = useState(false);
  const PrefillDetails = useSelector(
    (e) => e.adminSlice.currentActiveUserDetails
  );

  const sidebarHandler = () => {
    dispatch(sidebarHandlerr());
  };

  const pristineSlice = useSelector((e) => e.therapistSigunpSlice.pristine);
  const therapistMapAdded = useSelector(
    (e) => e.therapistSigunpSlice.isMapLocationAdded
  );

  const agencyCheck = useSelector(
    (e) => e.therapistSigunpSlice.checkAgencyValue
  );
  const profileImageCheck = useSelector(
    (e) => e.therapistSigunpSlice.checkProfilePath
  );

  const therapistValidation = useSelector(
    (e) => e.therapistSigunpSlice.isAnyErrorMess
  );
  const profileValidation = useSelector((e) => e.agencySlice.isAnyErrorMessage);

  const profileDefaultRateValidation = useSelector(
    (e) => e.agencySlice.isAnyErrorMessageDefaultRate
  );

  const agencyImage = useSelector(
    (state) => state.therapistSigunpSlice.isImageAgency
  );

  const isProfileImageSame = () => {
    if (
      profileImageCheck &&
      profileImageCheck.payload &&
      profileImageCheck.payload.changedImg
    ) {
      return true;
    } else {
      return false;
    }
  };

  const clickHandler = (e) => {
    setContactKey(false);
    if (e.route === "/Logout") {
      logout();
    } else {
      if (e.name === "Contact Support") {
        setContactKey(true);
      }
      if (
        (location.pathname === "/myprofile" ||
          location.pathname === "/myprofile/") &&
        (agencyCheck ||
          profileValidation ||
          profileDefaultRateValidation ||
          therapistMapAdded ||
          agencyImage ||
          isProfileImageSame())
      ) {
        // dispatch(triggerUpdateButton(true));
        // navigate(e.route);
        setWarningPrompt(e);
        return;
      } else {
        if (e.name !== "Contact Support") {
          navigate(e.route);
        }
      }
    }
    if (e.name === "Contact Support") {
      setContactKey(true);
      handleShow();
    }
  };

  function logout() {
    localStorage.removeItem("token");
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path:/;";
    window.location.href = "/";
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  function initSocketClient(roleKey, userId) {
    socket = io(socketBasePath, {
      transports: ["websocket"],
      query: { roles: roleKey, userId: userId },
      reconnection: true,
      reconnectionAttempts: 3,
    });
    socket.on("connect", () => {});
    socket.on("receive_message", (data) => {
      toast.info(data.message);
    });
    socket.on("message_return", function (data) {
      // showToastify(data)
    });
    socket.on("connectedClients", (data) => {
      toast.info(`Connected Clients ${data}`);
    });

    socket.on("notification", function (forUser, eventType, notification) {
      if (userId == forUser?.userId) dispatch(setNewNotification(true));
      // if (userId == forUser?.userId) toast.info(`${notification.notificationTitle}`, {
      //   onClick: () => {
      //     let apiObject = { id: notification.id, userId: userId }
      //     dispatch(readNotification(apiObject))
      //     if (notification.redirecturl) openToNewWindow(window.location.origin + notification.redirecturl)
      //   },
      // })
    });
  }

  useEffect(() => {
    checkRoute();
    if (
      PrefillDetails &&
      (PrefillDetails.Role.key === currentRole.agency ||
        PrefillDetails.Role.key === currentRole.supervisingtherapist ||
        PrefillDetails.Role.key === currentRole.assistanttherapist) &&
      !PrefillDetails.stripeAccountId
    ) {
      if (
        location.pathname === "/myprofile/paymentinfo" ||
        location.pathname === "/contactus"
      ) {
        setPromptModalShow(false);
      } else {
        // setPromptModalShow(true);
      }
    }
  }, [navigate]);

  useEffect(() => {
    dispatch(getCurrentActiveUser(userId.userId)).then((res) => {
      if (
        res &&
        res?.payload &&
        res?.payload?.success &&
        !res?.payload?.data?.isActive &&
        res?.payload?.data?.Role?.key === currentRole.admin
      ) {
        logout();
      } else {
        if (
          res &&
          res?.payload &&
          res?.payload?.data &&
          (res?.payload?.data.Role.key === currentRole.agency ||
            res?.payload?.data.Role.key === currentRole.supervisingtherapist ||
            res?.payload?.data.Role.key === currentRole.assistanttherapist) &&
          (!res?.payload?.data.stripeAccountId ||
            !res?.payload?.data.stripeDetailSubmitted ||
            !res?.payload?.data.stripeTransferEnabled)
        ) {
          setPromptModalShow(true);
        }
        initSocketClient(res?.payload?.data.Role.key, res?.payload?.data.id);
        dispatch(initializeTwilioClient(true));
      }
    });
    return () => {
      if (socket) socket.disconnect();
    };
  }, []);

  function checkRoute() {
    Object.keys(menuArray[userId.role]).map((f) => {
      menuArray[userId.role][f].map((e) => {
        e.other.map((ele) => {
          if (location.pathname.includes(ele)) {
            dispatch(showListName(e.name));
          }
        });
      });
    });
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    if (windowDimensions.width < 1279) {
      dispatch(setSidebarTrue());
    }
    if (windowDimensions.width > 1280) {
      dispatch(setSidebarFalse());
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const handleClose = () => {
    navigate("/home");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  function sidebarClass(e) {
    if (e.name === listname && !sidebar) {
      return "d-flex align-items-center w-60 menuItemss-1 activeee";
    }
    if (e.name === listname && sidebar) {
      return "d-flex align-items-center w-60 menuItemss-1 activeee justify-content-center  activeel ml-0";
    }
    if (!(e.name === listname) && !sidebar) {
      return "d-flex align-items-center w-60 menuItemss-1 ";
    }
    if (sidebar && !(e.name === listname)) {
      return "d-flex align-items-center w-60 menuItemss-1  activeel";
    }
  }

  const triggerSaveChanges = () => {
    dispatch(triggerUpdateButton(true));
    dispatch(ProfileImagePristine(null));
    // therapistValidation is for therapist professional experience
    if (
      !profileValidation &&
      !profileDefaultRateValidation &&
      !therapistValidation
    ) {
      setTimeout(() => {
        if (conatctKey) {
          handleShow();
          setContactKey(false);
        } else {
          navigate(warningPrompt.route);
        }
      }, 1000);
    }
    setWarningPrompt(false);
  };

  const handleTab = () => {
    navigate(warningPrompt.route);
    dispatch(ProfileImagePristine(null));
    dispatch(agencyImageUpload(false));
    setWarningPrompt(false);
  };

  return (
    <div
      className={
        !sidebar ? "sidebar_normal p-25" : " pr-0 sidebar_collapse p-0"
      }
    >
      <TopBar sidebar={sidebar} className={!sidebar ? "mt-0" : "mt-3"}>
        <Logo src={logoImg} sidebar={sidebar} />
        <Ham
          onClick={sidebarHandler}
          sidebar={sidebar}
          className={
            !sidebar ? "menu_icon_slidein  " : "menu_icon_slidein mt-3"
          }
        >
          <img src={toggel} style={{ width: "80%" }} alt="img" />
        </Ham>
      </TopBar>
      <div className="inner_content_leftbar">
        <div className="mainBarr">
          <div
            className={
              !sidebar
                ? `menuContainerr ${
                    userId.role === currentRole.supervisingtherapist
                      ? " therepist-sidebar"
                      : userId.role === currentRole.assistanttherapist
                      ? " asstherepist-sidebar"
                      : userId.role === currentRole.agency
                      ? " agency-sidebar"
                      : ""
                  }`
                : `menuContainerr mt-0 ${
                    userId.role === currentRole.supervisingtherapist
                      ? " therepist-sidebar"
                      : userId.role === currentRole.assistanttherapist
                      ? " asstherepist-sidebar"
                      : userId.role === currentRole.agency
                      ? " agency-sidebar"
                      : ""
                  }`
            }
          >
            {menuArray[userId.role]["home"].map((e) => {
              return (
                <div
                  className={
                    listname === e.name
                      ? "menuItemss-1tab activeee mb-3"
                      : "menuItemss-1tab mb-3"
                  }
                >
                  <div
                    onClick={() => {
                      clickHandler(e);
                    }}
                    key={e.id}
                    sidebar={sidebar}
                    className={sidebarClass(e)}
                  >
                    <p className={!sidebar ? "menuIcons" : "menuIcons_coll"}>
                      {e.icon}
                    </p>
                    <p
                      className={sidebar ? "m-activee d-none" : " menu-name"}
                      sidebar={sidebar}
                      style={{
                        color: listname === e.name ? "white" : "black",
                        marginTop: "5px",
                        marginLeft: "-6px",
                      }}
                    >
                      {e.name}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <Profile className="" style={{ paddingBottom: "2%" }}>
            <label
              className={!sidebar ? "acc-lable-sidebar" : "acc-lable-close"}
            >
              Account
            </label>
            {menuArray[userId.role]["account"].map((e) => {
              return (
                <div
                  className={
                    listname === e.name
                      ? "menuItemss-1tab activeee mb-3"
                      : "menuItemss-1tab mb-3"
                  }
                >
                  <div
                    onClick={() => {
                      clickHandler(e);
                    }}
                    key={e.id}
                    sidebar={sidebar}
                    className={sidebarClass(e)}
                  >
                    <p className={!sidebar ? "menuIcons" : "menuIcons_coll"}>
                      {e.icon}
                    </p>
                    <p
                      className={sidebar ? "m-activee d-none" : " menu-name"}
                      sidebar={sidebar}
                      style={{
                        color: listname === e.name ? "white" : "black",
                        marginTop: "5px",
                        marginLeft: "-6px",
                      }}
                    >
                      {e.name}
                    </p>
                  </div>
                </div>
              );
            })}
          </Profile>
          {show && <DirectMessageModal show={show} handleClose={handleClose} />}
          <PaymentPromptModal
            show={promptModalShow}
            handleClose={() => {
              logout();
              setPromptModalShow(false);
            }}
          />
          {warningPrompt && (
            <MyModal2
              size="lg"
              data={
                <WarningPromptModal
                  handleTab={handleTab}
                  triggerSaveChanges={triggerSaveChanges}
                />
              }
              cross={true}
              outerClick={true}
              show={warningPrompt}
              handleClose={() => setWarningPrompt(false)}
              modalClass={"warning_prompt cross_warning"}
              centered={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
