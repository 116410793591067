import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { useSelector } from "react-redux";
import InputGroup from "react-bootstrap/InputGroup";
import BootStrapForm from "react-bootstrap/Form";
import { currentRole, defaultStatus, floatRegex } from "../../../utils/auth";
import { blockInvalidChar, rateDiscipline } from "../../../utils/helper";
import ContractorServiceAgreement from "../../../documents/ServiceAgreement/contractorServiceAgreement";

const OfferModal = (props) => {
  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const [ratesError, setRatesError] = useState("");
  const [termsError, setTermsError] = useState("");
  const [currentUserDis, setCurrentUserDis] = useState();

  useEffect(() => {
    const disArr = [];
    if (
      currentUser &&
      currentUser?.Role &&
      (currentUser?.Role?.key === currentRole.assistanttherapist ||
        currentUser?.Role?.key === currentRole.supervisingtherapist)
    ) {
      currentUser?.userRates?.map((item) => {
        if (item?.price > 0) {
          disArr.push(item?.rateTab?.name?.toLowerCase());
        }
      });
    } else {
      if (props?.therapistAcceptedReferralList) {
        props?.therapistAcceptedReferralList?.map((item) => {
          item?.therapist?.userRates?.map((rate) => {
            if (rate?.price > 0) {
              disArr.push(rate?.rateTab?.name?.toLowerCase());
            }
          });
        });
      }
    }
    setCurrentUserDis(disArr);
  }, [currentUser, props?.therapistAcceptedReferralList]);

  function handleSubmit(values) {
    setRatesError("");
    setTermsError("");
    let makeOffers = [];
    let isValidRate = true;

    if (!values.termsandconditions) {
      setTermsError("You must accept the terms and conditions");
      isValidRate = false;
      setTimeout(() => {
        setTermsError("");
      }, 2000);
    }
    Promise.all(
      values.makeOffers.map((e) => {
        if (!e.myprice) {
          setRatesError("Please add offer values");
          setTimeout(() => {
            setRatesError("");
          }, 2000);
          isValidRate = false;
        }
        let obj = {};
        obj["myprice"] = e.myprice;
        obj["rateTabid"] = e.rateTabId;
        obj["rateTabDetailId"] = e.rateTabDetailId;
        obj["userId"] = e.userId;

        makeOffers.push(obj);
      })
    );

    let finalObj = {
      isAssistentNeeded: values.isWillComplete === "no" ? true : false,
      isWillComplete: values.isWillComplete === "yes" ? true : false,
      makeOffer: makeOffers,
      role: currentRole[currentUser.Role.key],
      status: defaultStatus.awaitingagencyapproval,
      therapistId: staffDetail?.staffUser?.therapistId,
      staffUserId: staffDetail.id,
      acceptedDate: props.acceptedDate ? props.acceptedDate : null,
    };
    if (isValidRate) {
      props.updateStatus(finalObj);
    }
  }

  const [page, setPage] = useState(false);

  useEffect(() => {
    setPage(true);
  }, [page]);

  const getOfferLabel = (rateTabDetailId) => {
    let label = "";
    if (props?.therapistAcceptedReferralList) {
      props?.therapistAcceptedReferralList?.map((item) => {
        let findLabel = item?.therapist?.userRates?.find(
          (item) => rateTabDetailId === item.rateTabDetailId
        );
        if (findLabel && findLabel.rateTabDetail) {
          label = findLabel.rateTabDetail.displayName;
        }
      });
    }
    if (props.makeOffer && staffDetail && staffDetail.userRates) {
      let findLabel = staffDetail.userRates.find(
        (item) => rateTabDetailId === item.rateTabDetailId
      );
      if (findLabel && findLabel.rateTabDetail) {
        label = findLabel.rateTabDetail.displayName;
      }
    }
    return label;
  };

  const setInitialValue = (event) => {
    if (event && Object.keys(event).length > 0) {
      return event;
    }
    let currentOffers = [];
    let makeOffers = [];

    let filterRate;
    if (props?.therapistAcceptedReferralList) {
      props?.therapistAcceptedReferralList?.map((item) => {
        if (
          item?.therapist?.userDetail?.discipline === rateDiscipline.pta ||
          item?.therapist?.userDetail?.discipline === rateDiscipline.cota
        ) {
          filterRate = item?.therapist?.userRates?.filter((item) => {
            if (
              item?.rateTab?.name?.toLowerCase() === rateDiscipline.pta &&
              currentUserDis?.includes(rateDiscipline.pta)
            ) {
              return item;
            } else if (
              item?.rateTab?.name?.toLowerCase() === rateDiscipline.cota &&
              currentUserDis?.includes(rateDiscipline.cota)
            ) {
              return item;
            }
          });
        } else {
          filterRate =
            staffDetail &&
            staffDetail?.userRates?.filter((item) => {
              if (
                item?.rateTabId === 1 &&
                currentUserDis?.includes(rateDiscipline.pt)
              ) {
                if (item?.rateTabDetail?.name !== rateDiscipline.ptaRateLabel) {
                  return item;
                }
              } else if (
                item?.rateTabId === 2 &&
                currentUserDis?.includes(rateDiscipline.ot)
              ) {
                if (
                  item?.rateTabDetail?.name !== rateDiscipline.cotaRateLabel
                ) {
                  return item;
                }
              } else if (
                item?.rateTabId === 3 &&
                currentUserDis?.includes(rateDiscipline.slp)
              ) {
                return item;
              }
            });
        }
      });
    }

    if (
      currentUser &&
      currentUser?.Role &&
      currentUser?.Role?.key &&
      currentUser?.Role?.key === currentRole.assistanttherapist
    ) {
      filterRate = currentUser?.userRates?.filter((item) => {
        if (
          item?.rateTab?.name?.toLowerCase() === rateDiscipline.pta &&
          currentUserDis?.includes(rateDiscipline.pta)
        ) {
          return item;
        } else if (
          item?.rateTab?.name?.toLowerCase() === rateDiscipline.cota &&
          currentUserDis?.includes(rateDiscipline.cota)
        ) {
          return item;
        }
      });
    }

    if (
      currentUser &&
      currentUser?.Role &&
      currentUser?.Role?.key &&
      currentUser?.Role?.key === currentRole.supervisingtherapist
    ) {
      filterRate =
        staffDetail &&
        staffDetail?.userRates?.filter((item) => {
          if (
            item?.rateTabId === 1 &&
            currentUserDis?.includes(rateDiscipline.pt)
          ) {
            if (item?.rateTabDetail?.name !== rateDiscipline.ptaRateLabel) {
              return item;
            }
          } else if (
            item?.rateTabId === 2 &&
            currentUserDis?.includes(rateDiscipline.ot)
          ) {
            if (item?.rateTabDetail?.name !== rateDiscipline.cotaRateLabel) {
              return item;
            }
          } else if (
            item?.rateTabId === 3 &&
            currentUserDis?.includes(rateDiscipline.slp)
          ) {
            return item;
          }
        });
    }
    currentOffers = JSON.parse(JSON.stringify(filterRate));
    if (props.onlyShowOffer && props.makeOffer) {
      makeOffers = JSON.parse(JSON.stringify(props.makeOffer));
    } else {
      makeOffers = JSON.parse(JSON.stringify(filterRate));
    }
    return { currentOffers, makeOffers, isWillComplete: "yes" };
  };

  const validateForm = (values) => {
    let errors = {};
    let arr = Object.keys(values);

    arr &&
      arr.length > 0 &&
      arr.map((item) => {
        let itemErrors = [];
        if (values[item] && values[item].length > 0 && item === "makeOffers") {
          values[item].map((ele) => {
            let error = {};
            if (ele && ele.myprice) {
              if (!floatRegex.test(ele.myprice))
                error["myprice"] = "Please enter a USD rate.";
            }
            itemErrors.push(error);
          });
          errors[item] = itemErrors;
        } else {
          delete errors[item];
        }
      });

    return errors;
  };

  const isShowAssistantNeededRadio = () => {
    let isShow = false;
    if (
      staffDetail &&
      staffDetail.staffingDetails &&
      currentUser.Role.key === currentRole.supervisingtherapist
    ) {
      if (
        currentUser &&
        currentUser.userDetail &&
        currentUser.userDetail.discipline === rateDiscipline.pt &&
        staffDetail.staffingDetails.ptaAllowed
      ) {
        isShow = true;
      } else if (
        currentUser.userDetail.discipline === rateDiscipline.ot &&
        staffDetail.staffingDetails.cotaAllowed
      ) {
        isShow = true;
      } else if (currentUser.userDetail.discipline === rateDiscipline.slp) {
        isShow = false;
      }
    }

    return isShow;
  };

  return (
    <Modal
      className={props.modalClass}
      show={props.show}
      onHide={() => props.handleClose("offerModal")}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <div className="d-flex justify-content-between">
        <h1 className="th-sttafhead">Make an Offer</h1>
        <Modal.Header
          className="closeButton border-0"
          closeButton
        ></Modal.Header>
      </div>

      <Form
        validate={validateForm}
        onSubmit={handleSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={(e) => setInitialValue(e)}
        render={({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div style={{ display: "flex" }} className="gap-4 mt-4">
              {values &&
                Object.keys(values).map(
                  (item, ind) =>
                    (item === "currentOffers" || item === "makeOffers") && (
                      <React.Fragment key={ind}>
                        <div
                          className={
                            item === "checkbox"
                              ? "d-flex flex-column offer-bg-white"
                              : item === "makeOffers"
                              ? "d-flex flex-column offer-bg-white"
                              : " d-flex flex-column  offer-bg"
                          }
                        >
                          {item === "currentOffers" && (
                            <h4
                              className="offer-lable mb-4"
                              style={
                                item === "checkbox"
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                            >
                              {currentUser &&
                              currentUser.Role &&
                              currentUser?.Role?.key === currentRole.agency
                                ? "Your Offer"
                                : "Current Offer"}
                            </h4>
                          )}
                          {item === "makeOffers" && (
                            <h4
                              className="offer-lable mb-4"
                              style={
                                item === "checkbox"
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                            >
                              {currentUser &&
                              currentUser.Role &&
                              currentUser?.Role?.key === currentRole.agency
                                ? "Counter Offer"
                                : "Your Offer"}
                            </h4>
                          )}

                          <div className="row">
                            <div className="col-md-12">
                              <div className="">
                                <FieldArray name={item} key={ind}>
                                  {({ fields }) =>
                                    fields.map((name, index) => (
                                      <Field
                                        name={`${
                                          item === "currentOffers"
                                            ? `${name}.price`
                                            : `${name}.myprice`
                                        }`}
                                        key={index}
                                      >
                                        {({ input, meta }) => (
                                          <>
                                            <div className="d-flex justify-content-between mb-3">
                                              <label className="offer-lable-rate col-9">
                                                {!props.onlyShowOffer
                                                  ? values[item][index]
                                                      .rateTabDetail &&
                                                    values[item][index]
                                                      .rateTabDetail.name
                                                  : getOfferLabel(
                                                      values[item][index]
                                                        .rateTabDetailId &&
                                                        values[item][index]
                                                          .rateTabDetailId
                                                    )}
                                              </label>
                                              <div className="flex-column">
                                                <InputGroup className="mb-3 inputWidth">
                                                  <InputGroup.Text
                                                    id="basic-addon1"
                                                    className="pr-0 pt-0 pb-0 border-end-0 "
                                                    style={{
                                                      background: "#fff",
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    $
                                                  </InputGroup.Text>
                                                  <BootStrapForm.Control
                                                    className="offer-input ad-input input-1440-2 form-control border-start-0 pl-1 input-rate-visit"
                                                    type="number"
                                                    step="0.01"
                                                    min="0"
                                                    onKeyDown={(e) =>
                                                      blockInvalidChar(e)
                                                    }
                                                    {...input}
                                                    disabled={
                                                      props.onlyShowOffer
                                                        ? true
                                                        : item ===
                                                          "currentOffers"
                                                        ? true
                                                        : false
                                                    }
                                                    style={{
                                                      background: "#fff",
                                                      paddingLeft: "10px",
                                                    }}
                                                  />
                                                </InputGroup>
                                              </div>
                                            </div>
                                            {meta.error && meta.touched && (
                                              <p
                                                className="alert alert-danger top-11 input-1440-2"
                                                role="alert"
                                              >
                                                {meta.error}
                                              </p>
                                            )}
                                          </>
                                        )}
                                      </Field>
                                    ))
                                  }
                                </FieldArray>
                              </div>
                              {item === "makeOffers" && ratesError && (
                                <p
                                  className="alert alert-danger top-11 input-1440-2"
                                  role="alert"
                                >
                                  {ratesError}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                )}
            </div>
            {!props.onlyShowOffer && (
              <>
                <ContractorServiceAgreement
                  formData={{ ...staffDetail, ...currentUser }}
                />
                {/* <h2 className="mt-5">Contract Of Service</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  ea commodo consequat. Duis aute irure dolor in reprehenderit
                  in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                  in culpa qui officia deserunt mollit anim id est laborum.
                  <br /> Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                  sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </p> */}

                <div className="radio_btns new-reff-radio radio_btns-margin ref-height ">
                  <label className="container-card align-items-center d-flex padding-left-ref ref-height ">
                    <Field
                      // key={item?.id}
                      name="termsandconditions"
                      component="input"
                      type="checkbox"
                    >
                      {({ input, meta }) => (
                        <>
                          <input {...input} type="checkbox" />
                        </>
                      )}
                    </Field>
                    <span className=" checkmark1"></span>
                    <div> I agree to Terms and Conditions</div>
                  </label>
                </div>
                {termsError && (
                  <p
                    className="alert alert-danger top-11 input-1440-2 w-50"
                    role="alert"
                  >
                    {termsError}
                  </p>
                )}

                {/* <div className=" d-flex align-item-center gap-2 mb-5">
                  <Field
                    type="checkbox"
                    name=""
                    className="form-select select-box-1"
                  >
                    {({ input, meta }) => (
                      <div className="d-flex flex-column">
                        <div className=" d-flex align-items-center gap-3 mb-3">
                          <input type="checkbox" {...input} className="tcch" />

                          <label className="tclb m-0">
                            I agree to Terms and Conditions
                          </label>
                        </div>
                        {meta.touched && meta.error && (
                          <span className="alert alert-danger">
                            {meta.error}
                          </span>
                        )}
                      </div>
                    )}
                  </Field>
                </div> */}

                <div className="d-flex flex-column justify-content-center align-items-start mt-5">
                  {isShowAssistantNeededRadio() && (
                    <>
                      <h6>Please Select one option below</h6>
                      <div className=" d-flex align-item-center gap-2 mb-4 asis_btn">
                        <label className="container1">
                          <Field
                            name="isWillComplete"
                            type="radio"
                            component="input"
                            value="no"
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="tclb m-0 ml-4">
                          Assistant Therapist needed for Follow-up visits
                        </label>
                      </div>

                      {currentUser &&
                        (currentUser?.Role?.key ===
                          currentRole.supervisingtherapist ||
                          currentUser?.Role?.key ===
                            currentRole.assistanttherapist) && (
                          <div className=" d-flex align-item-center gap-2 mb-5 asis_btn">
                            <label className="container1">
                              <Field
                                name="isWillComplete"
                                type="radio"
                                component="input"
                                value="yes"
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="tclb m-0 ml-4">
                              I will complete all visits
                            </label>
                          </div>
                        )}
                    </>
                  )}
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                  <button className="th-sttafing-btn ">Submit</button>
                </div>
              </>
            )}
          </form>
        )}
      />
    </Modal>
  );
};

export default OfferModal;
