import React, { useState } from "react";
import { AiFillFile } from "react-icons/ai";
import { MdCall, MdLocationOn, MdEmail } from "react-icons/md";
import { IoLanguageSharp } from "react-icons/io5";
import userImage from "../../images/loginpage.png";
import { AiOutlineUser, AiOutlineLock } from "react-icons/ai";

export const FinalTherapist = () => {
  const [status, setStatus] = useState("Physical Therapy (PT)");

  const mydata = [
    { name: "Address", value: "USA", icon: <MdLocationOn size={"30px"} /> },
    { name: "Phone", value: "MARIA SHARAPOVA", icon: <MdCall size={"30px"} /> },
    {
      name: "Email_Address",
      value: "abc@gmail.com",
      icon: <MdEmail size={"30px"} />,
    },
    {
      name: "Languages Spoken",
      value: "Hindi, English, German",
      icon: <IoLanguageSharp size={"30px"} />,
    },
    {
      name: "Specialities",
      value: "Orthopedic",
      icon: <MdLocationOn size={"30px"} />,
    },
    {
      name: "Experience",
      value: "3 years",
      icon: <MdLocationOn size={"30px"} />,
    },
  ];

  const labels = [
    "Physical Therapy (PT)",
    "Occasional Therapy (OT)",
    "Speech Therapy (ST)",
  ];

  function myFun(e) {
    setStatus(e.target.innerText);
  }

  return (
    <>
      <div
        className="div1"
        style={{
          padding: "40px 50px",
          display: "flex",
          justifyContent: "space-between",
          boxShadow: "0px 3px 10px #00000029",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "3%",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              overflow: "hidden",
              marginRight: "45px",
            }}
          >
            <img src={userImage} width="100%" height="100%" alt="user data" />
          </div>

          <div style={{ width: "70%" }}>
            <div>
              <h4 className="title">Kaminshka, Maria</h4>
              <p className="heading-uppercase profile-heading">Business Name</p>
              <p>N/A</p>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: "30px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "33%",
                }}
              >
                <AiOutlineUser size={40} className="icon-center" />
                <div className="div-block-assistant">
                  <h6 className="heading-uppercase">Discipline</h6>
                  <p className="details">PT</p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "33%",
                }}
              >
                <AiOutlineUser size={40} className="icon-center" />
                <div className="div-block-assistant">
                  <h6 className="heading-uppercase">USERNAME</h6>
                  <p className="details">Assisted HHA</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "33%",
                }}
              >
                <AiOutlineLock size={40} className="icon-center" />
                <div className="div-block-assistant">
                  <h6 className="heading-uppercase">PASSWORD</h6>
                  <p className="details">**********</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex">
        <div style={{ width: "70%" }}>
          <div>
            <h4 className="healthVisit" style={{ color: "#0A365B" }}>
              Therapist Details
            </h4>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                paddingBottom: "50px",
                padding: "0 43px",
                fontSize: "17px",
              }}
            >
              {mydata.map((e) => {
                return (
                  <>
                    <div className="Referral-info">
                      <div
                        className="referral_icon"
                        style={{ color: "#0A365B" }}
                      >
                        {e.icon}
                      </div>
                      <div className="referral_info">
                        <p className="referral_name">{e.name}</p>
                        <p className="referral_value">{e.value}</p>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div style={{ padding: "0 50px", marginTop: "50px" }}>
            <h4>Coverage Area</h4>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="iframeBox">
                <iframe
                  title="Map1"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d198740.08541261766!2d-77.15466285891779!3d38.89393804317764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c6de5af6e45b%3A0xc2524522d4885d2a!2sWashington%2C%20D.C.%2C%20DC%2C%20USA!5e0!3m2!1sen!2sin!4v1661245600942!5m2!1sen!2sin"
                  width="500"
                  height="350"
                ></iframe>
              </div>
              <div className="iframeBox">
                <iframe
                  title="Map2"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d198740.08541261766!2d-77.15466285891779!3d38.89393804317764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c6de5af6e45b%3A0xc2524522d4885d2a!2sWashington%2C%20D.C.%2C%20DC%2C%20USA!5e0!3m2!1sen!2sin!4v1661245600942!5m2!1sen!2sin"
                  width="500"
                  height="350"
                ></iframe>
              </div>
            </div>
          </div>

          <div style={{ padding: "30px", width: "85%" }}>
            <h4 style={{ paddingLeft: "20px" }}>
              Default Rates for Physical Therapy (PT)
            </h4>
            <div className="d-flex labels customRates">
              {labels.map((e) => {
                return (
                  <>
                    <p
                      className={
                        status === e
                          ? "agency-user-type label-heading active"
                          : "agency-user-type label-heading"
                      }
                      onClick={(e) => myFun(e)}
                    >
                      {e}
                    </p>
                  </>
                );
              })}
            </div>
            <div className="parentdiv  parent-div-space d-flex">
              <div style={{ paddingLeft: "20px" }}>
                <p className="rates-physical-therapy">
                  OASIS Starts of care / Recertification / Resumption Rof care /
                  Discharge
                </p>
                <p className="rates-physical-therapy">
                  OAISIS Non-visit discharge/Transfer
                </p>
                <p className="rates-physical-therapy">
                  NON-OAISIS Start of Care
                </p>
                <p className="rates-physical-therapy">
                  Evaluation / Re-assessment / Discharge
                </p>
                <p className="rates-physical-therapy"> PT-follow Up</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "35%",
                }}
              >
                <input className="rates-box" placeholder="$" />
                <input className="rates-box" placeholder="$" />
                <input className="rates-box" placeholder="$" />
                <input className="rates-box" placeholder="$" />
                <input className="rates-box" placeholder="$" />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "30%",
          }}
        >
          <div>
            <h5
              style={{
                fontSize: "22px",
                color: "#0A365B",
                marginBottom: "22px",
                fontWeight: "bolder",
              }}
            >
              Documents
            </h5>
            <div>
              <div
                style={{
                  display: "flex",
                  border: " 1px solid #D5E3EE",
                  borderRadius: " 8px",
                  justifyContent: "flex-start",
                  gap: "5%",
                  width: "330px",
                  marginBottom: "20px",
                  padding: "8px 10px 8px 15px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "68px",
                    height: "68px",
                    borderRadius: "50%",
                    backgroundColor: "#DFEFFC ",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AiFillFile size={"28px"} />
                </div>
                <div className="patientDoc">
                  <p className="m-0">Therapy License</p>

                  <p style={{ color: "#0F7AD4", fontSize: "14px" }}>
                    Exp. Date: XX/XX/XX
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  border: " 1px solid #D5E3EE",
                  borderRadius: " 8px",
                  justifyContent: "flex-start",
                  gap: "5%",
                  width: "330px",
                  marginBottom: "20px",
                  padding: "8px 10px 8px 15px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "68px",
                    height: "68px",
                    borderRadius: "50%",
                    backgroundColor: "#DFEFFC ",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AiFillFile size={"28px"} />
                </div>
                <div className="patientDoc">
                  <p className="m-0">Professional Liability Insurance</p>
                  <p style={{ color: "#0F7AD4", fontSize: "14px" }}>
                    Exp. Date: XX/XX/XX
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  border: " 1px solid #D5E3EE",
                  borderRadius: " 8px",
                  justifyContent: "flex-start",
                  gap: "5%",
                  width: "330px",
                  marginBottom: "20px",
                  padding: "8px 10px 8px 15px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "68px",
                    height: "68px",
                    borderRadius: "50%",
                    backgroundColor: "#DFEFFC ",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AiFillFile size={"28px"} />
                </div>
                <div className="patientDoc">
                  <p className="m-0">Driver's License</p>
                  <p style={{ color: "#0F7AD4", fontSize: "14px" }}>
                    Exp. Date: XX/XX/XX
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  border: " 1px solid #D5E3EE",
                  borderRadius: " 8px",
                  justifyContent: "flex-start",
                  gap: "5%",
                  width: "330px",
                  marginBottom: "20px",
                  padding: "8px 10px 8px 15px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "68px",
                    height: "68px",
                    borderRadius: "50%",
                    backgroundColor: "#DFEFFC ",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AiFillFile size={"28px"} />
                </div>
                <div className="patientDoc">
                  <p className="m-0">Auto Insurance</p>
                  <p style={{ color: "#0F7AD4", fontSize: "14px" }}>
                    Exp. Date: XX/XX/XX
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  border: " 1px solid #D5E3EE",
                  borderRadius: " 8px",
                  justifyContent: "flex-start",
                  gap: "5%",
                  width: "330px",
                  marginBottom: "20px",
                  padding: "8px 10px 8px 15px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "68px",
                    height: "68px",
                    borderRadius: "50%",
                    backgroundColor: "#DFEFFC ",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AiFillFile size={"28px"} />
                </div>
                <div className="patientDoc">
                  <p className="m-0">CPR Certification</p>
                  <p style={{ color: "#0F7AD4", fontSize: "14px" }}>
                    Exp. Date: XX/XX/XX
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "330px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "68px",
                  height: "68px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AiFillFile size={"28px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0">Physical</p>
                <p style={{ color: "#0F7AD4", fontSize: "14px" }}>
                  Exp. Date: XX/XX/XX
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "330px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "68px",
                  height: "68px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AiFillFile size={"28px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0">TB Test</p>
                <p style={{ color: "#0F7AD4", fontSize: "14px" }}>
                  Exp. Date: XX/XX/XX
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "330px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "68px",
                  height: "68px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AiFillFile size={"28px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0">Vaccination Report (Covid-19)</p>

                <p style={{ color: "#0F7AD4", fontSize: "14px" }}>
                  Exp. Date: XX/XX/XX
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "330px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "68px",
                  height: "68px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AiFillFile size={"28px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0">Vaccination Record (Flu)</p>
                <p style={{ color: "#0F7AD4", fontSize: "14px" }}>
                  Exp. Date: XX/XX/XX
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "330px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "68px",
                  height: "68px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AiFillFile size={"28px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0">Competency Assessment</p>
                <p style={{ color: "#0F7AD4", fontSize: "14px" }}>
                  Exp. Date: XX/XX/XX
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                border: " 1px solid #D5E3EE",
                borderRadius: " 8px",
                justifyContent: "flex-start",
                gap: "5%",
                width: "330px",
                marginBottom: "20px",
                padding: "8px 10px 8px 15px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "68px",
                  height: "68px",
                  borderRadius: "50%",
                  backgroundColor: "#DFEFFC ",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AiFillFile size={"28px"} />
              </div>
              <div className="patientDoc">
                <p className="m-0">Compliance Documents</p>
                <p style={{ color: "#0F7AD4", fontSize: "14px" }}>
                  Exp. Date: XX/XX/XX
                </p>
              </div>
            </div>
          </div>

          <div>
            <h5
              style={{
                fontSize: "22px",
                color: "#0A365B",
                fontWeight: "bolder",
              }}
            >
              Business Credentials
            </h5>
            <p
              style={{ color: "#0F7AD4", fontSize: "14px", fontWeight: "500" }}
            >
              View Expired Credentials
            </p>
            <div>
              <div
                style={{
                  display: "flex",
                  border: " 1px solid #D5E3EE",
                  borderRadius: " 8px",
                  justifyContent: "flex-start",
                  gap: "5%",
                  width: "330px",
                  marginBottom: "20px",
                  alignItems: "center",
                  padding: "20px 15px",
                }}
              >
                <div
                  style={{
                    width: "68px",
                    height: "68px",
                    borderRadius: "50%",
                    backgroundColor: "#DFEFFC ",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AiFillFile size={"28px"} />
                </div>
                <div className="patientDoc">
                  <p
                    className="m-0"
                    style={{
                      fontWeight: "bold",
                      color: "#0A365B",
                      fontSize: "18px",
                    }}
                  >
                    W9
                  </p>
                  <p style={{ color: "#0F7AD4", fontSize: "14px" }}>
                    Exp. Date: XX/XX/XX
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  border: " 1px solid #D5E3EE",
                  borderRadius: " 8px",
                  justifyContent: "flex-start",
                  gap: "5%",
                  width: "330px",
                  marginBottom: "20px",
                  alignItems: "center",
                  padding: "20px 15px",
                }}
              >
                <div
                  style={{
                    width: "68px",
                    height: "68px",
                    borderRadius: "50%",
                    backgroundColor: "#DFEFFC ",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AiFillFile size={"28px"} />
                </div>
                <div className="patientDoc">
                  <p
                    className="m-0"
                    style={{
                      fontWeight: "bold",
                      color: "#0A365B",
                      fontSize: "18px",
                    }}
                  >
                    Business License
                  </p>
                  <p style={{ color: "#0F7AD4", fontSize: "14px" }}>
                    Exp. Date: XX/XX/XX
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
