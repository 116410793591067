import { createSlice } from "@reduxjs/toolkit";
import {
  getUserDropDownAgencyData,
  getUserDropDownData,
} from "../actions/auth";
import {
  addStaffing,
  getStaffingByStatus,
  getStaffingDetailsById,
  updateStaffingUser,
  getStaffList,
  filterStaffingUsers,
  updateStaffDetail,
  therapistAcceptedReferral,
  updateStaffingStatus,
  checkMrn,
  updateStaffingDetailsById,
  lastVisitTypeApprovalList,
  getInvoicePeriod,
} from "../actions/staffing";
import { updateStaffingSOC } from "../actions/common";

const initialState = {
  isLoading: false,
  staffingList: [],
  staffDetail: null,
  staffingCount: 0,
  staffRoleStatusCount: null,
  therapistStaffList: [],
  therapistStaffCount: 0,
  istherapistStaffLoading: false,
  therapistStaffRoleStatusCount: null,
  agencyList: null,
  isModalLoading: false,
  therapistAcceptedReferralList: [],
  isUpdateStatueLoading: false,
  isMrnAvailable: false,
  mrnChecking: false,
  isListRefresh: false,
  agencyListNew: null,
  invoicePeriods: [],
};

const staffingSlice = createSlice({
  name: "staffingSlice",
  initialState,
  reducers: {
    setMrnState(state, action) {
      state.isMrnAvailable = action.payload.state;
      state.mrnChecking = action.payload.state;
    },
    setListRefresh(state, action) {
      state.isListRefresh = action.payload;
    },
  },

  extraReducers: {
    [updateStaffingSOC.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [updateStaffingSOC.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [updateStaffingSOC.rejected]: (state, { error, payload }) => {
      state.isLoading = false;
    },

    [addStaffing.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [addStaffing.rejected]: (state, { error, payload }) => {
      state.isLoading = false;
    },
    [addStaffing.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [updateStaffDetail.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [updateStaffDetail.rejected]: (state, { error, payload }) => {
      state.isLoading = false;
    },
    [updateStaffDetail.pending]: (state, { payload }) => {
      state.isLoading = true;
    },

    [getStaffList.fulfilled]: (state, { payload }) => {
      state.therapistStaffList = payload.data.rows;
      state.therapistStaffCount = payload.data.count;
      state.therapistStaffRoleStatusCount = payload.countDetail;
      state.istherapistStaffLoading = false;
    },
    [getStaffList.rejected]: (state, { error, payload }) => {
      state.therapistStaffList = [];
      state.therapistStaffCount = 0;
      state.therapistStaffRoleStatusCount = null;
      state.istherapistStaffLoading = false;
    },
    [getStaffList.pending]: (state, { payload }) => {
      state.therapistStaffList = [];
      state.therapistStaffCount = 0;
      state.therapistStaffRoleStatusCount = null;
      state.istherapistStaffLoading = true;
    },

    [getStaffingByStatus.fulfilled]: (state, { payload }) => {
      if (payload && payload.data) {
        state.staffingList = payload.data.rows;
        state.staffingCount = payload.data.count;
        state.staffRoleStatusCount = payload.countDetail;
      }
      state.isLoading = false;
    },
    [getStaffingByStatus.rejected]: (state, { error, payload }) => {
      state.staffingList = [];
      state.staffingCount = 0;
      state.staffRoleStatusCount = null;
      state.isLoading = false;
    },
    [getStaffingByStatus.pending]: (state, { payload }) => {
      state.isLoading = true;
      state.staffingList = [];
    },

    [updateStaffingDetailsById.fulfilled]: (state, { error, payload }) => {
      state.staffDetail = payload.data;
      state.isModalLoading = false;
    },
    [getStaffingDetailsById.fulfilled]: (state, { error, payload }) => {
      state.staffDetail = payload.data;
      state.isModalLoading = false;
    },
    [getStaffingDetailsById.pending]: (state, { payload }) => {
      state.isModalLoading = true;
      state.staffDetail = null;
    },

    [getStaffingDetailsById.rejected]: (state, { payload }) => {
      state.isModalLoading = false;
    },
    [updateStaffingUser.fulfilled]: (state, { error, payload }) => {
      state.isLoading = false;
      state.isModalLoading = false;
    },
    [updateStaffingUser.pending]: (state, { error, payload }) => {
      state.isModalLoading = true;
    },
    [getUserDropDownData.fulfilled]: (state, { payload }) => {
      state.agencyList = payload.data;
    },
    [getUserDropDownData.pending]: (state, { payload }) => {},

    // staffing agency list
    [getUserDropDownAgencyData.fulfilled]: (state, { payload }) => {
      state.agencyListNew = payload.data;
    },
    [getUserDropDownAgencyData.pending]: (state, { payload }) => {},

    [filterStaffingUsers.fulfilled]: (state, { payload }) => {
      state.staffingList = payload.data.rows;
      state.isLoading = false;
    },

    [filterStaffingUsers.pending]: (state, { payload }) => {
      state.staffingList = [];
      state.isLoading = true;
    },
    [filterStaffingUsers.rejected]: (state, { payload }) => {
      state.staffingList = [];
      state.isLoading = false;
    },
    [therapistAcceptedReferral.fulfilled]: (state, { payload }) => {
      state.therapistAcceptedReferralList = payload.data;
      state.isModalLoading = false;
    },
    [therapistAcceptedReferral.pending]: (state, { payload }) => {
      state.therapistAcceptedReferralList = [];
      state.isModalLoading = true;
    },
    [therapistAcceptedReferral.rejected]: (state, { payload }) => {
      state.therapistAcceptedReferralList = [];
      state.isModalLoading = false;
    },
    [updateStaffingStatus.fulfilled]: (state, { payload }) => {
      state.isUpdateStatueLoading = false;
      state.isModalLoading = false;
    },
    [updateStaffingStatus.pending]: (state, { payload }) => {
      state.isModalLoading = true;
      state.isUpdateStatueLoading = true;
    },
    [updateStaffingStatus.rejected]: (state, { payload }) => {
      state.isUpdateStatueLoading = false;
      state.isModalLoading = false;
    },
    [checkMrn.fulfilled]: (state, { payload }) => {
      state.isMrnAvailable = payload.success;
      state.mrnChecking = false;
    },
    [checkMrn.pending]: (state, { payload }) => {
      state.isMrnAvailable = null;
      state.mrnChecking = true;
    },
    [checkMrn.rejected]: (state, { payload }) => {
      state.isMrnAvailable = null;
      state.mrnChecking = false;
    },

    [lastVisitTypeApprovalList.fulfilled]: (state, { payload }) => {
      state.staffingList = payload.data.rows;
      state.isLoading = false;
    },
    [lastVisitTypeApprovalList.pending]: (state, { payload }) => {
      state.staffingList = [];
      state.isLoading = true;
    },
    [lastVisitTypeApprovalList.rejected]: (state, { payload }) => {
      state.staffingList = [];
      state.isLoading = true;
    },

    [getInvoicePeriod.fulfilled]: (state, { payload }) => {
      state.invoicePeriods = payload.result;
    },
    [getInvoicePeriod.pending]: (state, { payload }) => {
      state.invoicePeriods = [];
    },
    [getInvoicePeriod.rejected]: (state, { payload }) => {
      state.invoicePeriods = [];
    },
  },
});

export const { setMrnState, setListRefresh } = staffingSlice.actions;

export default staffingSlice.reducer;
