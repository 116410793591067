import React, { useEffect, useState } from 'react'
import { PDFDocument } from 'pdf-lib'
import { pdfFormImport } from '../utils/formImport';
import { useSelector } from 'react-redux';
import { therapistCredentialRoute, } from '../utils/directoryroute';
import SignatureCanvas from './common/SignatureCanvas';
import { dateFormatChange } from '../utils/helper';

export default function OnboardingForms(props) {
    const { uploadedFileBytes, setUploadedFileBytes, form, onboardingDocType, handleChange, values, handleClose } = props

    const [isUploaded, setIsUploaded] = useState(false)
    const [pdfLink, setPdfLink] = useState();
    const [pdfBytes, setPDfBytes] = useState(null)
    const [isCanvasShow, setIsCanvasShow] = useState(false)
    const [signatureAdded, setSignatureAdded] = useState(false)

    const PrefillDetails = useSelector((e) => e.therapistSigunpSlice.PrefillDetails);

    async function loadDoc(signature) {
        const dateObject = new Date()
        let isUploaded = false
        if (PrefillDetails && PrefillDetails.userCredentials && PrefillDetails.userCredentials.length > 0) {
            let detail = PrefillDetails.userCredentials.find((e) => e.types === onboardingDocType);
            if (detail && detail.isDeclination) {
                isUploaded = true
                const bytesData = await fetch(`${therapistCredentialRoute}${detail.file}`).then(res => res.arrayBuffer())
                const loadDoc = await PDFDocument.load(bytesData)
                const pdfBytes = await loadDoc.save()
                let blob = new Blob([pdfBytes], { type: 'application/pdf' })
                let docLink = window.URL.createObjectURL(blob)
                setIsUploaded(true)
                setPDfBytes(pdfBytes)
                setPdfLink(docLink)
                return;
            }
        }

        if (!isUploaded && uploadedFileBytes && uploadedFileBytes[onboardingDocType]) {
            let blob = new Blob([uploadedFileBytes[onboardingDocType]], { type: 'application/pdf' })
            let docLink = window.URL.createObjectURL(blob)
            setPdfLink(docLink)
            return;
        }
        // PDF path 
        const document = pdfFormImport[onboardingDocType]
        // converting and loading the pdf for using form and setting text 
        const bytesData = await fetch(document).then(res => res.arrayBuffer())
        const loadDoc = await PDFDocument.load(bytesData)
        // Form initializing 
        const DocForm = loadDoc.getForm()
        // For disabling The fields 
        DocForm.getFields().map(field => field.enableReadOnly())

        // Fetching the Fields based on the name
        let dateField = DocForm.getField('Date')
        let therapistNameField = DocForm.getField('therapistName')
        let signatureField = DocForm.getButton('Signature_af_image')

        // Setting Value to the field 
        // dateField.setText(dateObject.toISOString().split('T')[0])
        dateField.setText(dateFormatChange(dateObject))

        therapistNameField.setText(PrefillDetails.lastName + ", " + PrefillDetails.firstName)
        if (signature) {
            let signatureImage = await loadDoc.embedPng(signature)
            signatureField.setImage(signatureImage)
        }

        // saving the pdf with the latest changes 
        const pdfBytes = await loadDoc.save()

        // creating the blob object to create link 
        let blob = new Blob([pdfBytes], { type: 'application/pdf' })
        let docLink = window.URL.createObjectURL(blob)

        setPDfBytes(pdfBytes)
        setPdfLink(docLink)
    }

    useEffect(() => {
        if (PrefillDetails) loadDoc();
    }, [PrefillDetails]);

    const saveDocument = () => {
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        let files = new File([blob], `${onboardingDocType}doc.pdf`)
        setUploadedFileBytes({ ...uploadedFileBytes, [onboardingDocType]: pdfBytes })
        let signformDAta = new FormData()
        signformDAta.append("signatureFile", files)
        const event = {
            target: {
                files: [files],
            }
        }
        handleChange(event, onboardingDocType, values, form)
        handleClose()
    }

    return (
        <>
            <iframe title="careConeexionOnboarding" src={pdfLink} style={{ width: "100%", height: "90vh" }}></iframe>
            {!isUploaded ? uploadedFileBytes[onboardingDocType] ?
                <div className="common-btns-div d-flex justify-content-center">
                    <button
                        type="submit"
                        className="Approve-btns"
                        onClick={handleClose}
                    >
                        Close
                    </button>
                </div>
                :
                <div className="common-btns-div d-flex justify-content-center p-2">
                    <button
                        type="button"
                        className="Approve-btns"
                        onClick={() => setIsCanvasShow(true)}
                    >
                        {signatureAdded ? "Update Signature" : "Add Signature"}
                        
                    </button>
                    <button
                        type="submit"
                        className="Approve-btns"
                        disabled={!signatureAdded}
                        onClick={saveDocument}
                    >
                        Save Document
                    </button>
                </div> : <div className="common-btns-div d-flex justify-content-center p-2">
                <button
                    type="submit"
                    className="Approve-btns"
                    onClick={handleClose}
                >
                    Close
                </button>
            </div>}

            {isCanvasShow &&
                <SignatureCanvas
                    loadDoc={loadDoc}
                    isShow={isCanvasShow}
                    setSignatureAdded={setSignatureAdded}
                    handleClose={() => setIsCanvasShow(false)}
                />}
        </>
    );
}
