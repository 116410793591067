import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { Form, Field } from "react-final-form";
import { validateForm } from "../../../validation/validateForm";
import { useDispatch, useSelector } from "react-redux";
import { paymentTypes, therapistSignupSteps } from "../../../utils/auth";
import AddressAutocomplete from "../../../components/AddressAutoComplete";
import { updatePendingSteps, userPaymentDetail } from "../../../Redux/actions/therapist";
import { getUserDetailsById, setTherapistDataGetStep } from "../../../Redux/actions/auth";
import Select from "react-select";
import { Col, Row } from "bootstrap-4-react/lib/components/layout";
import { IoSyncCircleOutline } from "react-icons/io5";
import { BsChatLeftText } from "react-icons/bs";
import { stripeAccountLink } from "../../../Redux/actions/payment";
import { toast } from "react-toastify";
import PaymentMethod from "../../modals/PaymentMethod";
import { BiPencil } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import accordian_collapse_icon from "../../../images/accordian_collapse_icon.svg"
import { triggerUpdateButton } from "../../../Redux/slices/therapistSignupSlice";

export const paymentDetailsSchemaa = yup.object().shape({
  types: yup.string().nullable(),
  cardHolder: yup.string().required("Please enter your name").nullable(),
  cardNumber: yup
    .number()
    .required("Please enter card number")
    .nullable()
    .typeError("Card Number can only have digits"),
  expirationDate: yup
    .string()
    .max(5, "Maximum 5 digits allowed")
    .matches(/([0-9]{2})\/([0-9]{2})/, "Please use a valid MM/YY format")
    .required("enter date")
    .nullable(),
  address: yup.string().required().nullable(),
  city: yup.string().required().nullable(),
  state: yup.string().required().nullable(),
  zip: yup.string().required().nullable(),
  suiteUnit: yup.string().required().nullable(),

  cvv: yup.string().label("CVV").min(3).max(4).required().max(4).nullable(),
});

function TherapistPayment(props) {
  const dispatch = useDispatch();
  const location = useLocation()

  const typeOptions = [
    { value: "saving", label: "Saving" },
    { value: "current", label: "Current" },
  ];
  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const [paymentMethodAdd, setPaymentMethod] = useState(false)

  function handleSubmit(values) {
    if (PrefillDetails && (!PrefillDetails.stripeAccountId || !PrefillDetails.stripeTransferEnabled || !PrefillDetails.stripeDetailSubmitted)) {
      return;
    }
    if (PrefillDetails?.id) {
      values.userId = PrefillDetails.id;
    }
    if (props?.isProfile) {
      values.isProfile = props?.isProfile;
    }
    values.user = props.usercurrentRole;
    values.accountType = values.accountType.value;
    dispatch(userPaymentDetail(values)).then((res) => {
      dispatch(setTherapistDataGetStep(therapistSignupSteps[7]));
      if (res) {
        props.setTabUpdateCheker && props.setTabUpdateCheker();
        if (props.isProfile) {
          props.triggerTabChanges()
        }
      }
    });
  }

  const validate = (values) => {
    const errors = {};
    if (!values.cardHolder) {
      errors["cardHolder"] = "Please enter account holder name";
    }
    if (!values.cardNumber) {
      errors["cardNumber"] = " Please enter accountNumber";
    }
    if (values.cardNumber && values.cardNumber.match(/[a-zA-Z]/)) {
      errors["cardNumber"] = "Please enter numbers only";
    }
    if (!values.routingNumber) {
      errors["routingNumber"] = "Please enter rounting number";
    }
    if (values.routingNumber && values.routingNumber.match(/[a-zA-Z]/)) {
      errors["routingNumber"] = "Please enter numbers only";
    }
    if (!values.accountType) {
      errors["accountType"] = "Please select your account type";
    }
    return errors;
  };

  let defalutPayment =
    PrefillDetails && PrefillDetails["paymentDetail"]
      ? PrefillDetails["paymentDetail"]
      : [];
  const myInitialValues = {
    cardHolder: defalutPayment.length > 0 ? defalutPayment[0].cardHolder : "",
    cardNumber: defalutPayment.length > 0 ? defalutPayment[0].cardNumber : "",
    routingNumber:
      defalutPayment.length > 0 ? defalutPayment[0].routingNumber : "",
    accountType: defalutPayment.length > 0 &&
      defalutPayment[0]?.accountType && {
      value: defalutPayment[0]?.accountType,
      label: defalutPayment[0]?.accountType,
    },
    types:
      defalutPayment.length > 0
        ? defalutPayment[0].types
        : paymentTypes.bankAccount,
  };

  const handleStripeLogin = () => {
    const apiObject = {
      stripeAccountId: PrefillDetails && PrefillDetails.stripeAccountId,
      userId: PrefillDetails.id,
      email: PrefillDetails.email,
      stripeDetailSubmitted: PrefillDetails.stripeDetailSubmitted,
      returnLink: location.pathname + location.search
    }
    dispatch(stripeAccountLink(apiObject))
      .then(response => {
        if (response && response.payload && response.payload.success && response.payload.accountLink) {
          if (!response.payload.accountExist) window.location.href = response.payload.accountLink
        } else {
          toast.error("Error with stripe.")
        }
      })
  }

  const buttonRef = useRef();
  const triggerUpdateState = useSelector((state) => state.therapistSigunpSlice.triggerUpdateFunction);

  useEffect(() => {
    if (triggerUpdateState) {
      buttonRef.current.click();
    }
  }, [triggerUpdateState])

  const validHandleSubmit = () => {
    dispatch(triggerUpdateButton(false))
  }

  return (
    <div>
      <Form
        onSubmit={handleSubmit}
        // validate={validate}
        initialValues={myInitialValues}
        render={({ handleSubmit, submitting, form, values, valid, pristine }) => (
          <form onSubmit={(e) => {
            if (PrefillDetails && (!PrefillDetails.stripeAccountId || !PrefillDetails.stripeTransferEnabled || !PrefillDetails.stripeDetailSubmitted)) {
              toast.error("Please connect to Stripe or Skip this step for now to proceed.")
            }
            handleSubmit(e)
          }}>
            {props.isProfile && !valid && validHandleSubmit()}
            {props.isProfile && props.pristineCheck(pristine)}
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8 p-2">
                  {!props.isProfile && (
                    <>
                      <div className=" col-sm-9 d-flex align-items-center gap-5  p-0 my-3  my-head">
                        <h4 className="cs-heading set-head-th px-0  p-0">
                          Connect With Stripe
                        </h4>
                        <h4 className="cs-date m-0">
                          Step 07/<span className="pd-dt">08</span>
                        </h4>
                      </div>
                    </>
                  )}
                  <div className=" col-md-12 p-0">
                    <div className="connect_main_strip ">
                      <p className="stripe-text">
                        {PrefillDetails && PrefillDetails.stripeTransferEnabled && PrefillDetails.stripeDetailSubmitted ? "You are now connected with Stripe." :
                          "Please connect your Stripe account or create a new one for seamless payment processing. You may skip this step for now and complete it once your account is approved. Please note that you will not be able to use the platform until a Stripe account has been connected. "}
                        <br></br>
                        {/* {PrefillDetails && PrefillDetails.stripeAccountId ? "" :
                          " Please note that you will not be able to use the platform until a Stripe account has been connected."} */}
                      </p>
                      {PrefillDetails && PrefillDetails.stripeTransferEnabled && PrefillDetails.stripeDetailSubmitted ?
                        <div className="strip_connectes_inner d-flex align-items-center justify-content-between">
                          <div className="col-9 d-flex align-items-center">
                            <img src="/image/strip_img.svg" />
                            <div className="ml-4 txt_connect_ d-block">
                              <img src="/image/strip_txt.svg" />
                              <p className="mb-0 p-0">Connected</p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <img src="/image/check-icon.svg" />
                          </div>
                        </div> :
                        <div class="strip_connectes_inner cursor-pointer strip_connectes_main d-flex align-items-center justify-content-between flex-wrap" onClick={() => handleStripeLogin()}>
                          <div class="col-md-3 col-12">
                            <img src="/image/strip_img.svg" />
                          </div>
                          <div class="col-md-7 col-12">
                            <p class="mb-0">Connect With <span className="stripe_connent_text">Stripe</span></p>
                          </div>
                          <div class="col-md-2 col-12 pl-0">
                            <img src={accordian_collapse_icon} />
                          </div>
                        </div>}
                    </div>
                  </div>

                </div>
                {!props.isProfile ? (
                  <div className="row">
                    <div className="col-md-8 p-0 mt-5">
                      <div className=" col-md-12  mt-3  d-flex justify-content-between  gap-5 flex-wrap">
                        <div
                          style={{ display: "flex" }}
                          className="btfn gap-3"
                        >
                          <button
                            type="submit"
                            disabled={submitting}
                            className="coverage-btn"
                          >
                            Save & Continue
                          </button>
                          <button
                            type="button"
                            className="ad-btn-back"
                            onClick={() =>
                              props.goBackFunction(therapistSignupSteps[6])
                            }
                          >
                            Back
                          </button>
                        </div>
                        <div
                          style={{ display: "flex" }}
                          className="btfn gap-5"
                        >
                          {PrefillDetails && (!PrefillDetails.stripeAccountId || !PrefillDetails.stripeTransferEnabled || !PrefillDetails.stripeDetailSubmitted) &&
                            <button
                              type="button"
                              className="ad-btn-back"
                              onClick={() => {
                                // toast.info('Please complete this step or skip to move to the next step.')
                                dispatch(
                                  updatePendingSteps({
                                    isSkip: true,
                                    userId: PrefillDetails.id,
                                    user: PrefillDetails.Role.key
                                  })
                                ).then((res) => {
                                  dispatch(getUserDetailsById(`${PrefillDetails.id}`));
                                })
                              }
                              }>Skip</button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Row>
                    <Col md={12}>
                      <div className="d-flex justify-content-between align-items-center" style={{ marginTop: "calc(40px - 24px)" }}>
                        <button
                          ref={buttonRef}
                          className={`update-btn  mb-5 ${pristine ? "opacity-50" : ""}`}
                          onClick={handleSubmit}
                          disabled={submitting || pristine}
                        >
                          <IoSyncCircleOutline size={35} />
                          Update
                        </button>
                        <div>
                          {/* <BsChatLeftText size={35} /> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default TherapistPayment;
