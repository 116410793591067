import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userTermsCondition } from "../../../Redux/actions/therapist";
import {
  agencySignupSteps,
  currentRole,
  therapistSignupSteps,
} from "../../../utils/auth";
import SuccessModal from "../../Agency/signup/SuccessModal";
import { HiOutlineDownload } from "react-icons/hi";
import termsandcondition from "../../../documents/Terms of Use- TCC.pdf";
import { toast } from "react-toastify";
import PlatformAccessAgreement from "../../../documents/ServiceAgreement/platformAccessAgreement";
import TherapyAgreement from "../../../documents/ServiceAgreement/therapyAgreement";
import HhaAgreement from "../../../documents/ServiceAgreement/hha-Agreement";
import { Field, Form } from "react-final-form";
import jsPDF from "jspdf";
import { dateFormatChange, todayDate } from "../../../utils/helper";

const TermsAndConditions = (props) => {
  const [checked, setChecked] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [agreeError, setAgreeError] = useState(false);
  const divRef = useRef(null);

  const dispatch = useDispatch();
  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  function handleClose() {
    if (
      props.user &&
      (props.user === currentRole.supervisingtherapist ||
        props.user === currentRole.assistanttherapist)
    ) {
      props.goBackFunction(therapistSignupSteps[8]);
    }
    setOpen(false);
    navigate("/login");
  }
  const [isAtBottom, setIsAtBottom] = useState(false);

  const handleDivScroll = () => {
    const div = divRef.current;
    const { scrollTop, offsetHeight, scrollHeight } = div;
    const scrollBottom = scrollHeight - scrollTop - offsetHeight;
    setIsAtBottom(scrollBottom <= 2);
  };

  useEffect(() => {
    const div = divRef.current;
    div.addEventListener("scroll", handleDivScroll);

    return () => {
      div.removeEventListener("scroll", handleDivScroll);
    };
  }, []);

  function handleSubmit(values) {
    let obj = {
      userId:
        props.user === currentRole.supervisingtherapist ||
          props.user === currentRole.assistanttherapist
          ? PrefillDetails.id
          : PrefillDetails.id,
      isAccept: values.isAgreed,
      user: props.user,
    };
    if (!isAtBottom) {
      // toast.info("Go to bottom of page.");
    } else {
      if (values.isAgreed) {
        dispatch(userTermsCondition(obj)).then((e) => {
          if (e?.payload?.success) {
            setOpen(true);
          }
        });
      } else {
        // toast.error("Please complete all required fields. eeeeeeeeee");
        setAgreeError(true);
        // setTimeout(() => setAgreeError(false), 2000);
      }
    }
  }

  function goBack() {
    if (
      props.user === currentRole.supervisingtherapist ||
      props.user === currentRole.assistanttherapist
    ) {
      props.goBackFunction(therapistSignupSteps[7]);
    } else {
      props.goBackFunction(agencySignupSteps[3]);
    }
  }
  const validate = (values) => {
    const errors = {}
    if (props.user == currentRole.supervisingtherapist || props.user == currentRole.assistanttherapist) {
      return {}
    }
    // if (!values.isAgreed) {
    //   errors.isAgreed = "Please select"
    // }
    if (!values.name) {
      errors.name = "Please select"
    }
    if (!values.primaryAddress) {
      errors.primaryAddress = "Please select"
    }
    if (!values.secondaryAddress) {
      errors.secondaryAddress = "Please select"
    }
    if (!values.date) {
      errors.date = "Please select"
    }
    return errors
  }

  const downloadTermsAndCondition = () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    // const htmlContent = contentRef.current;
    const htmlContent = document.getElementById("contentToSave")
    let submitButton = htmlContent.querySelector('.Approve-btns');
    if (submitButton && checked) {
      submitButton.remove();
    }
    pdf.html(htmlContent, {
      callback: (pdf) => {
        pdf.save("Terms&Conditions.pdf");
      },
      x: 0,
      y: 0,
      width: 200,
      margin: [1, 1, 1, 1],
      windowWidth: 1536,
    });
  }

  const handleSignatureValue = (data) => {
    setChecked(data)
  }

  const setInitialValue = (event) => {
    if (event && Object.keys(event).length > 0) {
      return event;
    }
    const initialvalues = {
      isAgreed: false,
      agencyname: PrefillDetails?.userDetail?.representative,
      name: PrefillDetails?.name,
      primaryAddress: PrefillDetails?.userDetail?.address,
      date: dateFormatChange(todayDate),
      secondaryAddress: PrefillDetails?.userDetail?.state
    }
    return initialvalues;
  };

  const initValue = useMemo((e) => setInitialValue(e), [PrefillDetails]);

  return (
    <>
      <Form
        initialValues={initValue}
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit, values, errors, valid, touched }) => (
          <form onSubmit={(e) => {
            if (!isAtBottom) {
              toast.error("You must scroll down to the bottom to review the Terms & Conditions and then Agree to the terms to proceed.");
            } else if (isAtBottom && !valid) {
              toast.error("Please complete all required fields.");
            } else if (isAtBottom && !values.isAgreed) {
              toast.error("You must Agree to the Terms & Conditions to proceed.");
            }
            handleSubmit(e);
          }}>
            <div className="row py-3 px-0">
              <div className="col-md-9">
                <div className="d-flex term-heading align-items-center">
                  <h4 className="cs-heading set-head-th px-0 m-0 p-0">
                    Terms and Conditions
                  </h4>
                  {props.user !== currentRole.agency && (
                    <h4 className="cs-date mb-0">
                      Step 08/<span className="pd-dt">08</span>
                    </h4>
                  )}
                  <a className=""
                    onClick={() => downloadTermsAndCondition()}
                    download
                  >
                    {/* <button onClick={() => downloadTermsAndCondition()} type="button"> */}
                    <HiOutlineDownload size={25} className="mr-2" />
                    Download Terms and Conditions
                    {/* </button> */}

                  </a>
                </div>
                <p className="tcp mt-3 mb-4">
                  {!isAtBottom && (
                    <span className="fw-bold tclb m-0" style={{ color: "red" }}>
                      * You must review the Terms and Conditions. Please scroll down
                      to complete this step.
                    </span>
                  )}
                </p>
                {(props.user === currentRole.supervisingtherapist || props.user === currentRole.assistanttherapist) ? (
                  <div className="terms mb-5 px-3" ref={divRef}>
                    <PlatformAccessAgreement />
                  </div>
                ) : (
                  <div className="terms mb-5 px-3" ref={divRef}>
                    <HhaAgreement errors={errors} touched={touched} handleSignatureValue={handleSignatureValue} />
                  </div>
                )}
                <div className=" d-flex align-item-center flex-column gap-2 mb-5">
                  <label className="container-card align-items-center d-flex padding-left-ref ref-height ">
                    <Field name="isAgreed">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            className="tcch"
                            type="checkbox"
                            onClick={(e) => {
                              input.onChange(e)
                              setAgreeError(e.target.checked);
                            }}
                            disabled={!isAtBottom}
                          />
                          <span
                            className={`checkmark1 ${!isAtBottom ? "bg-term-gray" : ""}`}
                          ></span>
                        </>
                      )}
                    </Field>
                    <div className="ml-3">
                      <label className="tclb m-0">
                        I agree to Terms and Conditions.
                      </label>
                    </div>
                  </label>
                </div>
                {touched.isAgreed && errors.isAgreed && (
                  <p className=" alert alert-danger w-550  top-26" role="alert">
                    Please Agree to Terms and Conditions.
                  </p>
                )}
              </div>
              <div className="d-flex gap-3">
                <button type="submit" className="coverage-btn">
                  Save & Continue
                </button>
                <button className="ad-btn-back" type="submit" onClick={(e) => {
                  e.preventDefault()
                  goBack()
                }}>
                  Back
                </button>
              </div>

              <SuccessModal
                open={open}
                onClose={handleClose}
                image={props.image}
                role={PrefillDetails?.Role}
              />
            </div>
          </form>)}
      />
    </>
  );
};
export default TermsAndConditions;
