import { createSlice } from "@reduxjs/toolkit";
import {
  getInvoiceData,
  getInvoiceDetailById,
  stripeAccountLink,
  stripeCreateInstantPayment,
  stripeRetrieveInstantPayment,
} from "../actions/payment";

const initialState = {
  invoiceList: [],
  invoiceCount: 0,
  isLoading: false,
  isfetching: false,
  stripeInstantPayment: null,
  stripeInstantPaymentError: null,
  isPaymentSecretLoad: false,
};
const paymentSlice = createSlice({
  name: "paymentSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [getInvoiceData.fulfilled]: (state, { payload }) => {
      state.invoiceList = payload.data.rows;
      state.invoiceCount = payload.invoiceCount;
      state.isLoading = false;
    },
    [getInvoiceData.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getInvoiceData.rejected]: (stete, { payload }) => {
      stete.isLoading = false;
    },
    [getInvoiceDetailById.fulfilled]: (state, { payload }) => {
      state.invoiceDetail = payload.invoiceDetails;
      state.isfetching = false;
    },
    [getInvoiceDetailById.pending]: (state, { payload }) => {
      state.isfetching = true;
    },
    [getInvoiceDetailById.rejected]: (stete, { payload }) => {
      stete.isfetching = false;
    },
    [stripeAccountLink.fulfilled]: (state, { payload }) => {
      state.isfetching = false;
    },
    [stripeAccountLink.pending]: (state, { payload }) => {
      state.isfetching = true;
    },
    [stripeAccountLink.rejected]: (stete, { payload }) => {
      stete.isfetching = false;
    },

    [stripeCreateInstantPayment.fulfilled]: (state, { payload }) => {
      state.stripeInstantPayment = payload;
      state.isPaymentSecretLoad = false;
      state.stripeInstantPaymentError = null;
    },
    [stripeCreateInstantPayment.pending]: (state, { payload }) => {
      state.stripeInstantPayment = null;
      state.isPaymentSecretLoad = true;
      state.stripeInstantPaymentError = null;
    },
    [stripeCreateInstantPayment.rejected]: (state, { payload }) => {
      state.stripeInstantPayment = null;
      state.isPaymentSecretLoad = false;
      state.stripeInstantPaymentError = payload;
    },

    [stripeRetrieveInstantPayment.fulfilled]: (state, { payload }) => {
      state.stripeInstantPayment = payload;
      state.isPaymentSecretLoad = false;
      state.stripeInstantPaymentError = null;
    },
    [stripeRetrieveInstantPayment.pending]: (state, { payload }) => {
      state.stripeInstantPayment = null;
      state.isPaymentSecretLoad = true;
      state.stripeInstantPaymentError = null;
    },
    [stripeRetrieveInstantPayment.rejected]: (state, { payload }) => {
      state.stripeInstantPayment = null;
      state.isPaymentSecretLoad = false;
      state.stripeInstantPaymentError = payload;
    },
  },
});

export default paymentSlice.reducer;
