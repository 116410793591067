import React from "react";
import { useNavigate } from "react-router-dom";
import navImg from "../../../images/nav.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const AgencyNavbar = () => {
  const navigate = useNavigate();

  function logout() {
    localStorage.removeItem("token");
    sessionStorage.removeItem("keepMeSignedInData");

    navigate("/login");
  }
  return (
    <>
      <Navbar bg="f1f5f8" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#">
            <img src={navImg} className="navImg" alt="user Data" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ml-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link href="#action1" className=" active-ca  mr-5">
                About Us
              </Nav.Link>
              <Nav.Link
                href="#action2"
                className=" active-ca  mr-5"
                aria-current="page"
              >
                How It Works?
              </Nav.Link>
            </Nav>
            <button
              type="submit"
              className="ca-loginBtn  ca-li"
              onClick={logout}
            >
              Login
            </button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AgencyNavbar;
