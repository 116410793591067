import React from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Field, useForm } from "react-final-form";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";

export const AcceptUser = (props) => {
  const isUpdateStatueLoading = useSelector(
    (e) => e.staffingSlice.isUpdateStatueLoading
  );

  const handleSubmit = (values) => {
    let isRefresh = false;
    if (values["referral-status"] === "no") {
      isRefresh = true;
      props?.setTabMenus("approval");
    } else {
      props.setIsRefresh && props?.setIsRefresh(true);
      props.handleUnfinishModal(true);
    }
    props.setAcceptModal(false);
    props?.handleClose(isRefresh);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        validate={(values) => {
          let error = {};
          if (!values["referral-status"]) {
            error["referral-status"] = "Please select at least one option.";
          }
          return error;
        }}
        render={({ handleSubmit, values }) => (
          <Modal
            size="lg"
            className="accept-test-modal"
            show={props.show}
            onHide={() => {
              if (values["referral-status"] === "no") {
                props?.setTabMenus("unfinishedprofiles");
                props?.handleClose();
              }
              props.setIsRefresh && props?.setIsRefresh(true);
              props.setAcceptModal(false);
            }}
            aria-labelledby="contained-modal-title-vcenter"
          >
            {isUpdateStatueLoading && <Loader />}
            <Modal.Header
              className="closeButton border-0"
              closeButton
            ></Modal.Header>
            {props.data}
            <form onSubmit={handleSubmit} className="m-5">
              <div className="cancel_modal">
                <p className="accept-modal-p1">
                  {`I want to finish the patient profile.`}
                </p>
                <div className="check_btns">
                  <Field
                    type="radio"
                    name="referral-status"
                    value="yes"
                    className="custom-control-input"
                  >
                    {({ input, meta }) => (
                      <div className="radio_btns">
                        <input className="agen-reject-check" {...input} />
                        <label htmlFor="html">Yes</label>
                      </div>
                    )}
                  </Field>

                  <Field
                    name="referral-status"
                    value="no"
                    className="custom-control-input"
                    type="radio"
                  >
                    {({ input, meta }) => (
                      <>
                        <div className="radio_btns">
                          <input className="agen-reject-check" {...input} />
                          <label htmlFor="html">No</label>
                        </div>
                        {meta.touched && meta.error && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </>
                    )}
                  </Field>
                </div>
                <div className="row">
                  <div>
                    <div className="mt-5">
                      <button className="cancel-btn-1" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
        )}
      />
    </>
  );
};
