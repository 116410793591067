import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../config/axiosInstance";

export const getNotificationList = createAsyncThunk("In-App/Notification/List", async (payload) => {
  const res = await axiosInstance.get("auth/find-notification");
  return res.data;
});

export const readNotification = createAsyncThunk("In-App/Notification/Read/Single", async (payload) => {
  const result = await axiosInstance.post("auth/notificationRead", payload);
  return result.data;
  // return payload;
});

export const readNotificationAll = createAsyncThunk("In-App/Notification/Read/All", async (payload) => {
  const result = await axiosInstance.post("auth/notificationRead", payload);
  return result.data;
});
