import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Paymentdetails from "../Agency/signup/Paymentdetails";

const PaymentPromptModal = (props) => {
  const navigate = useNavigate();
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      size="lg"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="payment-popup"
    >
      <Modal.Header closeButton />

      <Modal.Body>
        <div className="align-items-center d-flex px-4 text-center  flex-column">
          <h2 className="cs-heading set-head px-0 connect_title mb-4">
            Connect With Stripe
          </h2>
          <h4 className="mb-5 payment_cls">
            Please connect your Stripe account or create a new one for seamless
            payment processing. Please note that you will not be able to use the
            platform until a Stripe account has been connected.
          </h4>
          <Paymentdetails
            noHheading={true}
            popUpModal={true}
            isProfile={true}
            handleClose={props.handleClose}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentPromptModal;
