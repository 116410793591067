import React, { useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { useSelector } from "react-redux";

const AddNewEpisode = (props) => {
  const episodeDetails = useSelector((e) => e.commonSlice.episodeDetails);
  const getEpisodeName = () => {
    let name = ''
    if (episodeDetails && episodeDetails?.rows?.length > 0) {
      name = `Episode ${episodeDetails.rows.length + 1}`
    }
    return name
  }
  const initValues = () => {
    let initialValues = {}
    if (episodeDetails && episodeDetails?.rows?.length > 0) {
      initialValues.episodeStartDate = episodeDetails.rows[episodeDetails.rows.length - 1].endDate.split("T")[0]
      initialValues.episodeEndDate = setEndDate(episodeDetails.rows[episodeDetails.rows.length - 1].endDate.split("T")[0])
    }
    return initialValues
  }

  const setEndDate = (startDate) => {
    const initialDate = new Date(startDate);
    initialDate.setDate(initialDate.getDate() + 60);
    return initialDate.toISOString().split('T')[0];
  }
  return (
    <Modal
      size="lg"
      className="accept-test-modal"
      show={props.showAddNewEpisodeModal}
      onHide={props.handleAddNewEpisodeModalClose}
    >
      <Modal.Header className="pl-4 pr-4 pt-4 pb-3" closeButton>
        <Modal.Title>Add New Episode</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pl-4 pr-4 pt-0 pb-4">
        <p>{getEpisodeName()}</p>
        <Form
          keepDirtyOnReinitialize
          onSubmit={props.addStaffEpisode}
          initialValues={useMemo(initValues, [episodeDetails])}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <Field name="episodeStartDate">
                  {({ input, meta }) => (
                    <>
                      <label>Start Date</label>
                      <input
                        {...input}
                        class="form-control"
                        type="date"
                        min={meta.initial}
                        onChange={(e) => {
                          input.onChange(e)
                          form.change('episodeEndDate', setEndDate(e.target.value))
                        }}
                      />
                    </>
                  )}
                </Field>
              </div>
              <div>
                <Field name="episodeEndDate">
                  {({ input, meta }) => (
                    <>
                      <label>End Date</label>
                      <input
                        {...input}
                        class="form-control"
                        type="date"
                        disabled
                      />
                    </>
                  )}
                </Field>
              </div>
              <div className="mt-4">
                <Button
                  className="Discard-danger"
                  variant="outline-danger"
                  onClick={props.handleAddNewEpisodeModalClose}
                >
                  Discard
                </Button>
                <Button type="submit" variant="primary" className="primary-confirm">
                  Create Episode
                </Button>
              </div>
            </form>
          )}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AddNewEpisode;
