import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { stripeCreateInstantPayment } from "../Redux/actions/payment";
import Loader from "./Loader";

const CheckoutForm = (props) => {
  const { show } = props;
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const stripeInstantPayment = useSelector(
    (e) => e.paymentSlice.stripeInstantPayment
  );

  const isPaymentSecretLoad = useSelector(
    (e) => e.paymentSlice.isPaymentSecretLoad
  );

  useEffect(() => {
    dispatch(stripeCreateInstantPayment(show));
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // @ts-ignore
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // @ts-ignore
      setMessage(submitError?.message);
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: stripeInstantPayment.client_secret,
      confirmParams: {
        return_url: `${window.location.origin}/accounting`,
      },
    });

    if (error) {
      setMessage(`Payment failed: ${error.message}`);
    } else {
      setMessage("Payment successful!");
    }
  };

  return (
    <div>
      {isPaymentSecretLoad && <Loader />}
      <form onSubmit={handleSubmit}>
        {stripeInstantPayment && (
          <>
            <PaymentElement />
            <div
              style={{
                marginTop: "24px",
                textAlign: "center",
              }}
            >
              <button
                className="Approve-btns ms-auto"
                onClick={handleSubmit}
                disabled={!stripe}
              >
                Submit
              </button>
            </div>
          </>
        )}

        {message}
      </form>
    </div>
  );
};

export default CheckoutForm;
