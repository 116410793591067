import React, { useEffect, useState } from "react";
import { Row, Col } from "bootstrap-4-react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import CommonTable from "../../../components/common/CommonTable";
import { getUserId, messageFormatting } from "../../../utils/auth";
import { apiBasePath } from "../../../Redux/config/config";
import Loader from "../../../components/Loader";
import { Button, Modal } from "react-bootstrap";
import agencyimg from "../../../images/usertable01.svg";
import assistantimg from "../../../images/usertable02.svg";
import supervisingtherapistimg from "../../../images/usertable03.svg";
import agency_icon from "../../../images/AdminDashboard/agency_icon.svg";
import assistant_therapist_icon from "../../../images/AdminDashboard/assistant_therapist_icon.svg";
import supervising_therapist_icon from "../../../images/AdminDashboard/supervising_therapist_icon.svg";
import block_icon from "../../../images/block_icon.svg";
import { GrClose } from "react-icons/gr";
import { CgSearch } from "react-icons/cg";
import { FiRefreshCw } from "react-icons/fi";
import { debounce } from "lodash";
import {
  blockTherapist,
  getTherapistByAgencyId,
} from "../../../Redux/actions/agency";
import { toast } from "react-toastify";
import arrayMutators from "final-form-arrays";
import { AiFillPlusCircle } from "react-icons/ai";
import { Field, Form } from "react-final-form";

const AddToBlockList = (props) => {
  const { showAddToBlockModal, handleCloseAddToBlockModal } = props;
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const userList = useSelector((e) => e.agencySlice.therapistDetails);
  const isLoading = useSelector((e) => e.agencySlice.isLoading);
  const userCount = useSelector((e) => e.agencySlice.userCount);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getTherapistByAgencyId({
        pageSize: pageSize,
        pageNo: 1,
      })
    );
  }, []);

  const handlePageClick = (event) => {
    setPageNo(event.selected + 1);
    dispatch(
      getTherapistByAgencyId({
        pageSize: pageSize,
        pageNo: event.selected + 1,
      })
    );
  };

  const columns = ["select", "name", "Role.name", "reason"];
  const columnsToHide = [];

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "select":
        return "Select";
      case "name":
        return "Name";
      case "Role.name":
        return "User Type";
      case "reason":
        return "Reason";
      default:
        return colName;
    }
  };
  const onSubmit = (values) => {
    const finalValues = values.blockList.filter((item) => item.select);
    if (finalValues && finalValues.length > 0) {
      dispatch(blockTherapist(finalValues)).then((res) => {
        if (res.payload.success) {
          toast.success(messageFormatting(res.payload.message));
          props.handleCloseAddToBlockModal();
          props.setUpdateTab(true);
        }
      });
    } else {
      props.handleCloseAddToBlockModal();
    }
  };
  const debounceCall = debounce((ele) => {
    setPageNo(1);
    dispatch(
      getTherapistByAgencyId({
        pageSize: pageSize,
        pageNo: 1,
        search: ele,
      })
    );
  }, 1000);

  let myImage = {
    supervisingtherapist: supervisingtherapistimg,
    assistanttherapist: assistantimg,
    agency: agencyimg,
  };

  let roleIcons = {
    supervisingtherapist: supervising_therapist_icon,
    assistanttherapist: assistant_therapist_icon,
    agency: agency_icon,
  };

  const renderRowData = (result, col, item, index, values) => {
    return (
      <>
        {col === "select" && (
          <>
            <label className="container-card">
              <Field
                key={item?.id}
                name={`blockList[${index}].select`}
                type="checkbox"
                component="input"
              />
              <span className=" checkmark1"></span>
            </label>
          </>
        )}
        {col === "name" && (
          <>
            <div className="d-flex align-items-center">
              <img
                src={
                  result?.image
                    ? `${apiBasePath}documents/therapist/${result?.image}`
                    : myImage[result.Role.key]
                }
                crossOrigin="acountnymous"
                className="table-img p-1"
                style={{ borderRadius: "50%", objectFit: "cover", marginRight: "5%" }}
                alt="img"
              />
              {result && result.firstName && (
                <h1 className="title-22 ms-1">
                  {`${result.lastName}, ${result.firstName}`}
                </h1>
              )}
            </div>
          </>
        )}
        {col === "Role.name" && (
          <>
            <div className="d-flex gap-2 align-items-center">
              <img
                src={
                  roleIcons[result.Role.key]
                }
                crossOrigin="acountnymous"
                className="p-1"
                alt="roleIcons"
              />
              <p className="m-0 displine-list">
                {result && result.Role && result.Role.title}
              </p>
            </div>
          </>
        )}
        {col === "reason" && (
          <>
            <Field name={`blockList[${index}].reason`} component="input">
              {({ input, meta }) => {
                return (
                  <>
                    <textarea
                      className="adblock-textarea"
                      {...input}
                      disabled={
                        values &&
                          values?.blockList &&
                          values.blockList[index].select
                          ? false
                          : true
                      }
                      placeholder="Add Reason"
                    ></textarea>
                    {meta.error && meta.touched && (
                      <div
                        className="alert alert-danger block-err0r"
                        role="alert"
                      >
                        {meta.error}
                      </div>
                    )}
                  </>
                );
              }}
            </Field>
          </>
        )}
      </>
    );
  };

  const initialvalues = {
    blockList:
      userList &&
      userList.length > 0 &&
      userList.map((listItem, listIndex) => {
        return {
          userId: listItem.id,
          reason: "",
          select: false,
        };
      }),
  };

  const validate = (values) => {
    let errors = {};
    let itemErrors = [];
    const blockListArray = values.blockList;
    if (values && values.blockList && values.blockList.length > 0) {
      blockListArray.map((item) => {
        let error = {};
        if (item.select && !item.reason) {
          error["reason"] = "Reason is required.";
        }
        itemErrors.push(error);
      });
    }
    errors["blockList"] = itemErrors;
    return errors;
  };

  return (
    <Modal
      show={showAddToBlockModal}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="BlockList-modal "
    >
      <div className="closeicon_div">
        <GrClose
          size={20}
          className="closeicon"
          onClick={() => {
            handleCloseAddToBlockModal();
          }}
        />
      </div>

      <div className="container-fluid">
        <div className="d-flex mobile_wrap justify-content-between gap-3 pl-5 pr-5">
          <h5 className="Block-title h-auto mobile_width  mobile_text-center">
            Add to Block-List
          </h5>
          <div
            className="search-bar d-flex align-items-center mobile_margin-bottom"
            style={{ width: "21%" }}
          >
            <CgSearch size={"25px"} />
            <input
              placeholder="Search by therapist name..."
              id="searchField"
              className="pl-2 searching"
              style={{
                border: "none",
                fontSize: "18px",
                outline: "none",
                width: "100%",
              }}
              onChange={(ele) => debounceCall(ele?.target?.value)}
            />
          </div>
        </div>

        {userList && userList.length > 0 ? (
          <Row className="justify-content-center">
            <Col lg={11} className="">
              <Form
                initialValues={initialvalues}
                validate={validate}
                onSubmit={onSubmit}
                mutators={{
                  ...arrayMutators,
                }}
                render={({ values, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <CommonTable
                      css="block-list-table  mobile_table-responsive"
                      data={userList && userList}
                      columns={columns}
                      columnsToHide={columnsToHide}
                      overrideColumnName={overrideColumnName}
                      renderRowData={renderRowData}
                      isLoading={isLoading}
                      formValues={values}
                    />
                    <div className="addToBlockListBtn  mt-3 mb-3 ">
                      <Button
                        type="submit"
                        className="btn btn-primary save-btnn text-center"
                      >
                        <FiRefreshCw
                          style={{
                            marginRight: "8px",
                            padding: "5px",
                            background: "#FFF",
                            borderRadius: "100%",
                            color: "#0F7AD4",
                          }}
                          size={35}
                        />
                        Add to Block-List
                      </Button>
                    </div>
                  </form>
                )}
              />
            </Col>
          </Row>
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-5">
            <h2 className="fs-5 mt-5 mb-5">No Data Available</h2>
          </div>
        )}
        {userCount > pageSize && userList?.length > 0 && (
          <div id="container ">
            <div className="row justify-content-start mt-3  pl-5 ">
              <div className="pl-3 pr-5">
                <ReactPaginate
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={Math.ceil(userCount / pageSize)}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                  forcePage={
                    Math.ceil(userCount / pageSize) === 0 ? -1 : pageNo - 1
                  }
                />
              </div>
            </div>
          </div>
        )}
        {isLoading && <Loader />}
      </div >
    </Modal >
  );
};

export default AddToBlockList;
