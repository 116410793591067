import React, { useEffect, useState } from "react";
import { Row, Col } from "bootstrap-4-react";
import CommonTable from "../../../components/common/CommonTable";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { currentRole, defaultStatus } from "../../../utils/auth";
import { apiBasePath } from "../../../Redux/config/config";
import { getStaffList } from "../../../Redux/actions/staffing";
import Loader from "../../../components/Loader";
import Patient from "../../../images/usertable02.svg";
import {
  getCaseManagementStatus,
  getCaseManagementTabCount,
  getDocList,
} from "../../../Redux/actions/common";
import { useParams } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { BiCircle } from "react-icons/bi";
import { dateFormatter, statusBaseColorChange, viewCaseStatueName } from "../../../utils/helper";

const HomeList = (props) => {
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const userCount = useSelector((e) => e.staffingSlice.therapistStaffCount);
  const dispatch = useDispatch();
  const isLoading = useSelector((e) => e.commonSlice.isLoading);
  const [pageSize] = useState(10);
  const [menuItem, setMenuItem] = useState("patientneedscheduling");
  const params = useParams();

  // const [pageNo, setPageNo] = useState(props.pageNo);

  let status = defaultStatus.patientneedscheduling;

  const getStaffingList = (obj) => {
    dispatch(
      getStaffList({
        pageNo: obj.pageNo,
        pageSize: obj.pageSize,
        // therapistId: currentUser?.id,
        status: obj.status,
      })
    );
  };

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "firstName":
        return "Patient";
      case "staffingDetails.address":
        return "Address";
      case "disciplines":
        return "Disciplines";
      case "name":
        return "Agency Name";
      case "staffingDetails.disciplinesNeeded":
        return "DISCIPLINES";
      case "staffingDetails.createdByUser.name":
        return "Agency Name";
      case "createdByUser.name":
        return "Agency Name";

      default:
        return colName;
    }
  };

  const renderRowData = (result, col, item) => {
    if (col === "PATIENT") {
      if (currentUser?.Role?.key === currentRole?.supervisingtherapist || currentUser?.Role?.key === currentRole?.admin || currentUser?.Role?.key === currentRole?.superadmin) {
        if (menuItem == "authorization") {
          return (
            <>
              <div className="d-flex align-items-center">
                <img
                  src={
                    result.image
                      ? `${apiBasePath}documents/therapist/${result?.staffAuth?.image}`
                      : Patient
                  }
                  crossOrigin="anonymous"
                  className="table-img mr-0"
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                  alt="img"
                />
                <div>
                  <h1 className="title-22 text-nowrap margin-left-35">
                    {result?.staffAuth?.lastName + ", " + result?.staffAuth?.firstName}
                  </h1>
                </div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="d-flex align-items-center">
                <img
                  src={
                    result.image
                      ? `${apiBasePath}documents/therapist/${result?.image}`
                      : Patient
                  }
                  crossOrigin="anonymous"
                  className="table-img mr-0"
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                  alt="img"
                />
                <div>
                  <h1 className="title-22 text-nowrap margin-left-35">
                    {result?.lastName + ", " + result?.firstName}
                  </h1>
                </div>
              </div>
            </>
          );
        }
      } else {
        return (
          <>
            <div className="d-flex align-items-center">
              <img
                src={
                  result.image
                    ? `${apiBasePath}documents/therapist/${result.image}`
                    : Patient
                }
                crossOrigin="anonymous"
                className="table-img mr-0"
                style={{ borderRadius: "50%", objectFit: "cover" }}
                alt="img"
              />
              <div>
                <h1 className="title-22 text-nowrap margin-left-35">
                  {result.lastName + " " + result.firstName}
                </h1>
              </div>
            </div>
          </>
        );
      }
    }
    if (col === "MR#") {
      if (currentUser?.Role?.key === currentRole?.supervisingtherapist || currentUser?.Role?.key === currentRole?.admin || currentUser?.Role?.key === currentRole?.superadmin) {
        return (
          <>
            <p className="m-0 addres-col">{result?.staffingDetails?.mrn}</p>
          </>
        );
      }
      else if (currentUser?.Role?.key === currentRole?.assistanttherapist) {
        // return (
        //   <>
        //     <p className="m-0 addres-col">{result?.staffUser?.staffingDetails?.mrn}</p>
        //   </>
        // );
      } else {
        return (
          <>
            <p className="m-0 addres-col">{result?.staffingDetails?.mrn}</p>
          </>
        );


      }
    }
    if (col === "ADDRESS") {
      if (currentUser?.Role.key === currentRole.supervisingtherapist || currentUser?.Role?.key === currentRole?.admin || currentUser?.Role?.key === currentRole?.superadmin) {

        return (
          <div>
            <p className="m-0 addres-col">
              {result?.staffingDetails?.address}
            </p>
            <p className="m-0 addres-col">
              {`${result?.staffingDetails?.city}`}
              {", "}
              {result?.staffingDetails?.state}
              {", "}
              {result?.staffingDetails?.zip}
            </p>
          </div>
        );
      }
      else if (currentUser?.Role.key === currentRole.assistanttherapist) {

        return (
          <div>
            <p className="m-0 addres-col">
              {result?.staffingDetails?.address}
            </p>
            <p className="m-0 addres-col">
              {result?.staffingDetails?.city}
              {", "}
              {result?.staffingDetails?.state}
              {", "}
              {result?.staffingDetails?.zip}
            </p>
          </div>
        );
      }
      else {

        return (
          <div>
            <p className="m-0 addres-col">
              {result?.staffingDetails?.address}
            </p>
            <p className="m-0 addres-col">
              {result?.staffingDetails?.city}
              {", "}
              {result?.staffingDetails?.state}
              {", "}
              {result?.staffingDetails?.zip}
            </p>
          </div>
        );
      }
    }
    if (col === "DISCIPLINES") {
      return (
        <>
          {currentUser && currentUser?.Role.key !== currentRole.agency ? (
            <div>
              {result?.staffingDetails?.disciplinesNeeded &&
                Object.keys(result.staffingDetails.disciplinesNeeded).map(
                  (e) => {
                    return (
                      <div className="d-flex gap-2">
                        <p className="m-0 spanp">{e.toUpperCase()}</p>
                        <span className="spanp">
                          {(result &&
                            result.Status &&
                            result.Status.key ===
                            defaultStatus.awaitingagencyapproval) ||
                            (result &&
                              result.Status &&
                              result.Status.key === defaultStatus.pending) ? (
                            <BiCircle
                              color="#A1AEB9"
                              className="progress-check"
                            />
                          ) : (
                            <BsFillCheckCircleFill className="progress-check-after" />
                          )}
                        </span>
                      </div >
                    );
                  }
                )}
            </div>
          ) : (
            <div className="d-flex gap-1">
              {result?.staffingDetails?.disciplinesNeeded &&
                Object.keys(result.staffingDetails.disciplinesNeeded).map(
                  (e) => {
                    return (
                      <>
                        <p className="m-0 spanp">{e.toUpperCase()}</p>
                        <span className="spanp">
                          {(result &&
                            result.Status &&
                            result.Status.key ===
                            defaultStatus.awaitingagencyapproval) ||
                            (result &&
                              result.Status &&
                              result.Status.key === defaultStatus.pending) ? (
                            <BiCircle
                              color="#A1AEB9"
                              className="progress-check"
                            />
                          ) : (
                            <BsFillCheckCircleFill className="progress-check-after" />
                          )}
                        </span>
                      </>
                    );
                  }
                )}


            </div>
          )}
        </>
      );
    }

    if (col === "CASE STATUS") {
      return <p
        className={`m-0 mt-3 mb-3 status-td ${statusBaseColorChange(
          result &&
          result.staffInitialVisit &&
          result.staffInitialVisit.length > 0 &&
          result.staffInitialVisit[0].Status &&
          result.staffInitialVisit[0].Status.key
        )}`}
      >
        {viewCaseStatueName(
          result &&
          result.staffInitialVisit &&
          result.staffInitialVisit.length > 0 &&
          result.staffInitialVisit[0].Status &&
          result.staffInitialVisit[0].Status.key
        )}
      </p>;
    }
    if (col === "PATIENT NAME") {
      return (
        <>
          <div className="d-flex align-items-center">
            <img
              src={
                result.episodeStaff &&
                  result.episodeStaff?.staffData &&
                  result.episodeStaff?.staffData?.image
                  ? `${apiBasePath}documents/therapist/${result?.episodeStaff?.staffData?.image}`
                  : Patient
              }
              crossOrigin="anonymous"
              className="table-img mr-0"
              style={{ borderRadius: "50%", objectFit: "cover" }}
              alt="img"
            />
            <div>
              <h1 className="title-22 text-nowrap margin-left-35">
                {result?.episodeStaff?.staffData?.lastName + ", " + result?.episodeStaff?.staffData?.firstName}
              </h1>
              <p className="title-24  text-nowrap margin-left-35">
                {result?.staffingDetails?.mrn}
              </p>
            </div>
          </div>
        </>
      );
    }
    if (col === "VISIT TYPE") {
      return (
        <>
          <p className="m-0 addres-col cursor-pointer" >
            {
              result?.episodeVisit && result?.episodeVisit?.displayName
            }
          </p>
        </>
      );
    }
    if (col === "DATE OF VISIT") {
      return (
        <>
          <p className="displine-list m-0">
            {dateFormatter(
              result?.date
            )}
          </p>
        </>
      );
    }

    return <h1 className=" title-24  mt-3 mb-3">{result[item]}</h1>;
  };
  // const handlePageClick = (event) => {
  //   props.setPageNo(event.selected + 1);
  //   // getDocList({
  //   //   pageNo: event.selected + 1,
  //   //   pageSize,
  //   //   status: props.tabStatus(props.myActions.key),
  //   // });
  //    dispatch(
  //     getCaseManagementStatus({
  //       pageSize,
  //       pageNo: 1,
  //       tab: props?.tabStatus[props?.myActions.key],
  //     })
  //   );
  // };



  return (
    <Row>
      <div className="inside_table_tabs p-0">
        <Col lg={12} className="p-0">
          <div className="table-responsive table-width-1">
            <CommonTable
              css="pl-5 table-responsive-md"
              data={props?.myActions?.data}
              columns={props?.myActions?.columns}
              columnsToHide={props?.myActions?.columnsToHide}
              overrideColumnName={overrideColumnName}
              actions={props?.myActions?.actions}
              component={props?.myActions?.component}
              renderRowData={renderRowData}
              noData={props?.myActions?.noData}
              isLoading={props?.isLoading}
            />
          </div>
          {/* {props?.myActions?.data?.length > 0 &&
            props?.myActions?.count > pageSize && (
              <div id="container ">
                <div className="row justify-content-start mt-3  pl-5 ">
                  <div className="col-sm-12 pl-5 pr-5">
                    <ReactPaginate
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      nextLabel="next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={userCount / pageSize}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                      forcePage={
                        Math.ceil(userCount / pageSize) === 0
                          ? -1
                          : props.pageNo - 1
                      }
                    />
                  </div>
                </div>
              </div>
            )} */}
          {props?.myActions?.count > pageSize &&
            props?.myActions?.data?.length > 0 && (
              <div id="container ">
                <div className="row justify-content-start mt-3  pl-5 ">
                  <div className=" pl-5 pr-5">
                    <ReactPaginate
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      nextLabel="next >"
                      onPageChange={props.handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={Math.ceil(props?.myActions?.count / pageSize)}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                      forcePage={
                        Math.ceil(props?.myActions?.count / pageSize) === 0
                          ? -1
                          : props?.pageNo - 1
                      }
                    />
                  </div>
                </div>
              </div>
            )}
        </Col>
      </div>
    </Row>
  );
};

export default HomeList;
