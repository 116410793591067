import { createSlice } from "@reduxjs/toolkit";
import {
  getUsersByRoleStatus,
  signInCall,
  resetPassword,
  forgetPassword,
  changePassword,
  getAdminHomeTabCount,
  getContactSupportMail,
  getBackgroundCheck,
  getOtpVerified,
  TwoFactorVerify,
  getResendOtp,
} from "../actions/auth";

const initialState = {
  listname: null,
  status: null,
  sidebar: 0,
  message: null,
  loginedUser: null,
  forgotPasswordData: null,
  userDetails: [],
  userCount: 0,
  isLoading: false,
  userRoleStatusCount: null,
  userReviewCount: null,
  isHomeLoader: null,
  cardsList: [],
  countDetail: null,
  tabCount: null,
  supportMailTkt: [],
  otpDataList: [],
  resendOtpData: [],
  socketId: null,
  socket: null,
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    showListName(state, action) {
      state.listname = action.payload;
    },
    sidebarHandlerr(state) {
      state.sidebar = state.sidebar === 0 ? 1 : 0;
    },
    setSidebarFalse(state) {
      state.sidebar = 0;
    },
    setSidebarTrue(state) {
      state.sidebar = 1;
    },
    clearAuthMessage(state) {
      state.message = null;
    },
    setLoader(state, { payload }) {
      state.isLoading = payload.loading;
    },
    setSocketId(state, { payload }) {
      state.socketId = payload.id;
      state.socket = payload;
    },
  },

  extraReducers: {
    [signInCall.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.loginedUser = payload.data.user;
        state.message = null;
      } else {
        state.message = payload.message
          ? payload.message
          : "Something went wrong.";
      }
      state.isLoading = false;
    },
    [signInCall.rejected]: (state, { error, payload }) => {
      state.message = {
        default:
          error && error.message ? error.message : "Something went wrong.",
      };
      state.isLoading = false;
    },
    [signInCall.pending]: (state) => {
      state.isLoading = true;
    },

    [getUsersByRoleStatus.fulfilled]: (state, { payload }) => {
      state.userDetails = payload.data.rows;
      state.isHomeLoader = false;
      state.userCount = payload.data.count;
      state.userRoleStatusCount = payload.countDetail;
      state.userReviewCount = payload.reviewCount;
    },
    [getUsersByRoleStatus.pending]: (state, { payload }) => {
      state.userDetails = [];
      state.userCount = 0;
      state.userRoleStatusCount = [];
      state.isHomeLoader = true;
      state.userReviewCount = null;
    },
    [getUsersByRoleStatus.rejected]: (state, { payload }) => {
      state.isHomeLoader = false;
      state.userDetails = [];
      state.userCount = 0;
      state.userRoleStatusCount = [];
      state.userReviewCount = null;
    },

    [forgetPassword.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.forgotPasswordData = payload;
    },
    [forgetPassword.rejected]: (state) => {
      state.isLoading = false;
    },
    [forgetPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [resetPassword.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [resetPassword.rejected]: (state) => {
      state.isLoading = false;
    },
    [resetPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [changePassword.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [changePassword.rejected]: (state) => {
      state.isLoading = false;
    },
    [changePassword.pending]: (state) => {
      state.isLoading = true;
    },
    [getAdminHomeTabCount.fulfilled]: (state, { payload }) => {
      state.tabCount = payload.data;
      state.isLoading = false;
    },
    [getAdminHomeTabCount.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAdminHomeTabCount.pending]: (state) => {
      state.isLoading = true;
    },
    [getContactSupportMail.fulfilled]: (state, { payload }) => {
      state.supportMailTkt = payload;
      state.isLoading = false;
    },
    [getContactSupportMail.rejected]: (state, { error, payload }) => {
      state.isLoading = false;
      // state.supportMailTkt=null;
    },
    [getContactSupportMail.pending]: (state) => {
      state.isLoading = true;
    },
    [getBackgroundCheck.fulfilled]: (state, { payload }) => {
      state.userDetails = payload.allTherapist.rows;
      state.isHomeLoader = false;
      state.userCount = payload.allTherapist.count;
      state.userRoleStatusCount = payload.data;
      state.userReviewCount = payload.reviewCount;
    },
    [getBackgroundCheck.pending]: (state, { payload }) => {
      state.userDetails = [];
      state.userCount = 0;
      state.userRoleStatusCount = [];
      state.userReviewCount = null;
      state.isHomeLoader = true;
    },
    [getBackgroundCheck.rejected]: (state, { payload }) => {
      state.isHomeLoader = false;
      state.userDetails = [];
      state.userCount = 0;
      state.userRoleStatusCount = [];
      state.userReviewCount = null;
    },
    [getOtpVerified.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.otpDataList = payload;
    },
    [getOtpVerified.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getOtpVerified.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [TwoFactorVerify.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.message = null;
      } else {
        state.message = payload.message
          ? payload.message
          : "Something went wrong.";
      }
      state.isLoading = false;
      state.otpDataList = payload;
    },
    [TwoFactorVerify.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [TwoFactorVerify.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getResendOtp.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.resendOtpData = payload;
    },
    [getResendOtp.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getResendOtp.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

export const {
  showListName,
  sidebarHandlerr,
  setSidebarFalse,
  setSidebarTrue,
  clearAuthMessage,
  setSocketId,
  setLoader,
} = authSlice.actions;
export default authSlice.reducer;
