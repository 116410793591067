/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { CommonHead } from "../components/common/CommonHead";

import userImage from "../images/loginpage.png";
import BackgroundCheck2 from "./BackgroundCheck2";
import { useNavigate } from "react-router-dom";
import CommonTable from "../components/common/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getBackgroundCheck,
  getUsersByRoleStatus,
} from "../Redux/actions/auth";
import { currentRole, defaultStatus, encodedData } from "../utils/auth";
import ReactPaginate from "react-paginate";
import Loader from "./Loader";
import { setLoader } from "../Redux/slices/authSlice";
import { apiBasePath, backgroundApiToken } from "../Redux/config/config";
import { BsFillCheckCircleFill } from "react-icons/bs";
import Supervisingpng from "../images/usertable03.svg";
import Agencypng from "../images/usertable01.svg";
import CreateAdminUser from "../pages/Admin/CreateAdminUser";
import { toast } from "react-toastify";
import { getBackgroundReportPDF } from "../Redux/actions/background";
import { MyModal2 } from "../pages/modals/MyModal2";

const NewUser = () => {
  const [menuItem, setMenuItem] = useState("newagency");
  const [backgroundReportShow, setbackgroundReportShow] = useState(false);
  const [backgroundReportkey, setbackgroundReportkey] = useState(null);
  const [iframeLoad, setIframeLoad] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userList = useSelector((e) => e.authSlice.userDetails);
  const userCount = useSelector((e) => e.authSlice.userCount);
  const userRoleStatusCount = useSelector(
    (e) => e.authSlice.userRoleStatusCount
  );
  const userReviewCount = useSelector((e) => e.authSlice.userReviewCount);
  const isLoading = useSelector((e) => e.authSlice.isHomeLoader);
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  let role =
    menuItem === "newagency"
      ? [currentRole.agency]
      : menuItem === "newtherapist"
      ? [currentRole.supervisingtherapist, currentRole.assistanttherapist]
      : "";

  let status = [`${defaultStatus.pending}`];

  const handlePageClick = (event) => {
    let role =
      menuItem === "newagency"
        ? [currentRole.agency]
        : menuItem === "newtherapist"
        ? [currentRole.supervisingtherapist, currentRole.assistanttherapist]
        : "";
    dispatch(setLoader({ loading: true }));

    dispatch(
      getUsersByRoleStatus({
        pageSize,
        pageNo: event.selected + 1,
        roles: role,
        status,
      })
    );
    setPageNo(event.selected + 1);
  };

  const setTabMenus = (tab) => {
    let role =
      tab === "newagency"
        ? [currentRole.agency]
        : tab === "newtherapist"
        ? [currentRole.supervisingtherapist, currentRole.assistanttherapist]
        : "";
    if (tab !== "backgroundchecks") dispatch(setLoader({ loading: true }));
    setMenuItem(tab);
    setPageNo(1);
    if (tab !== "backgroundchecks")
      dispatch(
        getUsersByRoleStatus({
          pageSize: 10,
          pageNo: 1,
          roles: role,
          status,
        })
      );

    if (tab === "backgroundchecks") {
      dispatch(
        getBackgroundCheck({
          status: ["pending", "approved"],
          pageSize: 10,
          pageNo: 1,
        })
      );
    }
  };

  const getRoleDetailCount = (key) => {
    let count = 0;
    if (userReviewCount && Object.keys(userReviewCount).length > 0) {
      count = userReviewCount[key];
    }
    return count;
  };
  const getBackgroundPDF = (reportKey) => {
    if (!reportKey) {
      toast.error("Report key not found");
      return;
    }
    setbackgroundReportShow(true);
    setbackgroundReportkey(reportKey);
    setIframeLoad(true);
    dispatch(getBackgroundReportPDF({ reportKey: reportKey }));
  };
  const menu = [
    {
      label: "Review New Agency",
      key: "newagency",
      count: getRoleDetailCount("pendingAgencyCount"),
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  navigate(`/newusers/agency/${encodedData(rowData.id)}`);
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
      columns: ["name", "email", "phone", "address"],
      columnsToHide: ["image"],
      data: userList,
      noData: "No Data Available",
      isTableShow: true,
    },
    {
      label: "Review New Therapist",
      key: "newtherapist",
      count: getRoleDetailCount("pendingTherapistCount"),
      columns: ["firstName", "discipline", "onbordingPartCompleted"],
      columnsToHide: ["image"],
      noData: "No Data Available",
      data: userList,
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  navigate(
                    `/newusers/therapist/${encodedData({
                      id: rowData.id,
                      onbordingPartCompleted:
                        rowData.userDetail.onbordingPartCompleted,
                    })}`
                  );
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],

      isTableShow: true,
    },
    {
      key: "backgroundchecks",
      label: "Review Background Checks",
      count:
        getRoleDetailCount("approvedTherapistCount") +
        getRoleDetailCount("pendingReviewCount"),
      // component: <BackgroundCheck2 />,
      isTableShow: true,
      columns: ["name", "discipline", "status", "backGroundStatus"],
      columnsToHide: ["image"],
      noData: "No Data Available",
      data: userList,
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <button
                  className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                  onClick={() => {
                    if (rowData.Status.key === defaultStatus.approved) {
                      navigate(
                        `/newusers/therapist/${encodedData({
                          id: rowData.id,
                        })}`
                      );
                    } else {
                      navigate(
                        `/newusers/therapist/${encodedData({
                          id: rowData.id,
                          onbordingPartCompleted:
                            rowData.userDetail.onbordingPartCompleted,
                        })}`
                      );
                    }
                  }}
                >
                  View
                </button>
              </td>

              <td className="">
                {rowData.userDetail.reportKey && (
                  <button
                    className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                    onClick={() => {
                      getBackgroundPDF(rowData.userDetail.reportKey);
                    }}
                  >
                    Pdf
                  </button>
                )}
              </td>
            </>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    if (menuItem === "backgroundchecks") {
      dispatch(
        getBackgroundCheck({
          status: ["pending", "approved"],
          pageSize: 10,
          pageNo: 1,
        })
      );
    } else {
      dispatch(
        getUsersByRoleStatus({
          pageSize: 10,
          pageNo: 1,
          roles: role,
          status,
        })
      );
    }

    return () => {};
  }, []);

  const myActions = menu.find((ele) => ele.key === menuItem);

  const overrideColumnName = (colName) => {
    switch (colName) {
      case myActions?.key === "newagency" && "name":
        return "Agency";
      case myActions?.key === "newtherapist" && "firstName":
        return "Name";
      case "usertype":
        return "USER TYPE";
      case "discipline":
        return "DISCIPLINE";
      case "onbordingPartCompleted":
        return "Status";

      default:
        return colName;
    }
  };
  const handleLoad = () => {
    setIframeLoad(false);
  };
  const ReportShow = () => {
    return (
      <iframe
        title="bgreport"
        onLoad={handleLoad}
        style={{ width: "95vw", height: "95vh" }}
        src={`https://sandbox.clearchecks.com/api/report/${backgroundReportkey}/pdf?api_token=${backgroundApiToken}`}
      />
    );
  };
  const renderRowData = (result, col, item) => {
    if (myActions?.key === "newagency") {
      if (col === "name") {
        return (
          <>
            <div className="d-flex align-items-center">
              <img
                src={
                  result.image
                    ? `${apiBasePath}documents/therapist/${result.image}`
                    : Agencypng
                }
                crossOrigin="anonymous"
                className="table-img  ml-1"
                style={{ borderRadius: "50%", objectFit: "cover" }}
                alt=""
              />
              <h1 className="title-22 m-0"> {result[item]}</h1>
            </div>
          </>
        );
      } else if (col === "address") {
        return result.userDetail && result.userDetail.address ? (
          <div className="mt-3">
            {" "}
            <p className="m-0">{result.userDetail.address}</p>
            <p className="m-0">
              {result.userDetail["city"]}
              {", "}
              {result.userDetail["state"]}
              {", "}
              {result.userDetail["zip"]}
            </p>
          </div>
        ) : (
          "---"
        );
      } else if (col === "phone") {
        return result.userDetail && result.userDetail.phone
          ? result.userDetail.phone
          : "---";
      }
      return result[item];
    } else if (myActions?.key === "newtherapist") {
      if (col === "discipline") {
        return (
          <>
            <h1 style={{ color: "#0A365B" }} className=" title-24">
              {result.userDetail &&
                result.userDetail.discipline &&
                result.userDetail.discipline.toUpperCase()}
              <BsFillCheckCircleFill
                style={{ marginLeft: "10px" }}
                size={20}
                color={"#3B9A3D"}
              />{" "}
            </h1>
          </>
        );
      }
      if (col === "onbordingPartCompleted") {
        return (
          <h1 style={{ color: "#0A365B" }} className=" title-24 d-flex gap-2">
            {" "}
            <span>
              {(result.userDetail &&
                result.userDetail.onbordingPartCompleted === 1 && (
                  <BsFillCheckCircleFill size={20} color={"#ffa500"} />
                )) ||
                (result.userDetail.onbordingPartCompleted > 1 ? (
                  <BsFillCheckCircleFill size={20} color={"#008000"} />
                ) : (
                  <BsFillCheckCircleFill size={20} color={"#BFBFBF"} />
                ))}{" "}
              Step 1
            </span>
            &nbsp;
            <span>
              {(result.userDetail &&
                result.userDetail.onbordingPartCompleted === 2 && (
                  <BsFillCheckCircleFill size={20} color={"#ffa500"} />
                )) ||
                (result.userDetail.onbordingPartCompleted > 2 ? (
                  <BsFillCheckCircleFill size={20} color={"#008000"} />
                ) : (
                  <BsFillCheckCircleFill size={20} color={"#BFBFBF"} />
                ))}{" "}
              Step 2
            </span>
            &nbsp;
            <span>
              {" "}
              {(result.userDetail &&
                result.userDetail.onbordingPartCompleted === 3 && (
                  <BsFillCheckCircleFill size={20} color={"#ffa500"} />
                )) ||
                (result.userDetail.onbordingPartCompleted > 3 ? (
                  <BsFillCheckCircleFill size={20} color={"#008000"} />
                ) : (
                  <BsFillCheckCircleFill size={20} color={"#BFBFBF"} />
                ))}{" "}
              Step 3
            </span>
            &nbsp;
          </h1>
        );
      }
      if (col === "firstName") {
        return (
          <>
            <div className="d-flex align-items-center">
              <img
                src={
                  result.image
                    ? `${apiBasePath}documents/therapist/${result.image}`
                    : Supervisingpng
                }
                crossOrigin="anonymous"
                className="table-img ml-1 "
                style={{ borderRadius: "50%", objectFit: "cover" }}
                alt=""
              />

              <h1 className=" title-22">
                {" "}
                {result["lastName"]}
                {", "} {result["firstName"]}
              </h1>
            </div>
          </>
        );
      }
      return <h1 className=" title-24"> {result[item]}</h1>;
    } else if (myActions.key === "backgroundchecks") {
      if (col === "agencyName") {
        return (
          <>
            <div className="d-flex align-items-center">
              <img
                alt="ImageData"
                src={
                  result.image
                    ? `${apiBasePath}documents/therapist/${result.image}`
                    : userImage
                }
                crossOrigin="anonymous"
                className="table-img "
                style={{ borderRadius: "50%", objectFit: "cover" }}
              />
              <h1 className=" title-22"> {result[item]} </h1>
            </div>
          </>
        );
      } else if (col === "name") {
        return (
          <>
            <div className="d-flex align-items-center">
              <img
                alt="user Data"
                src={
                  result.image
                    ? `${apiBasePath}documents/therapist/${result.image}`
                    : Supervisingpng
                }
                crossOrigin="anonymous"
                className="table-img  "
                style={{ borderRadius: "50%", objectFit: "cover" }}
              />
              <h1 className=" title-22">
                {result.lastName + ", " + result.firstName}
              </h1>
            </div>
          </>
        );
      }
      if (col === "discipline") {
        return (
          <>
            <h1 style={{ color: "#0A365B" }} className=" title-24">
              {result.userDetail &&
                result.userDetail.discipline &&
                result.userDetail.discipline.toUpperCase()}
              <BsFillCheckCircleFill
                style={{ marginLeft: "10px" }}
                size={20}
                color={"#3B9A3D"}
              />{" "}
            </h1>
          </>
        );
      }
      if (col === "status") {
        return (
          <>
            <h1 style={{ color: "#0A365B" }} className=" title-24">
              {result && result.Status && result.Status.name}
            </h1>
          </>
        );
      }
      if (col === "backGroundStatus") {
        return (
          <>
            <h1 style={{ color: "#0A365B" }} className=" title-24">
              {result && result.backGroundStatus ? result.backGroundStatus : ""}
            </h1>
          </>
        );
      }
      <h1 className=" title-24"> {result[item]} </h1>;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div
        style={{
          padding: "36px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <CreateAdminUser />
      </div>
      <div className="container-fluid pl-5 pr-5  margin-new">
        <CommonHead data={menu} setMenu={setTabMenus} menuItem={menuItem} />

        <div
          className={!myActions.component ? "newusertable" : "container-fluid "}
        >
          <div className={!myActions.component ? "row pl-5 pr-5" : "row "}>
            <div
              className={
                menuItem === "newtherapist"
                  ? "table-newuser col-12 col-lg-12 p-0"
                  : "col-12 col-lg-12 p-0"
              }
            >
              {myActions.isTableShow ? (
                <>
                  <CommonTable
                    data={myActions?.data}
                    columns={myActions?.columns}
                    columnsToHide={myActions?.columnsToHide}
                    overrideColumnName={overrideColumnName}
                    actions={myActions?.actions}
                    renderRowData={renderRowData}
                    component={myActions?.component}
                    keysFromResult={myActions?.keysFromResult}
                    noData={myActions?.noData}
                    isLoading={isLoading}
                  />
                  {myActions?.count > 10 && myActions?.data?.length > 0 && (
                    <div id="container ">
                      <div className="row justify-content-start mt-3">
                        <div className="">
                          <ReactPaginate
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={Math.ceil(userCount / pageSize)}
                            previousLabel="< previous"
                            renderOnZeroPageCount={null}
                            forcePage={
                              Math.ceil(userCount / pageSize) === 0
                                ? -1
                                : pageNo - 1
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                myActions?.component
              )}
            </div>
          </div>
        </div>
      </div>

      {backgroundReportShow && (
        <>
          <MyModal2
            modalClass="full-width-modal"
            // dialogClassName="full-width-modal"
            show={backgroundReportShow}
            handleClose={() => setbackgroundReportShow(false)}
            iframeLoad={iframeLoad}
            data={<ReportShow />}
            size={"lg"}
          />
        </>
      )}
    </>
  );
};

export default NewUser;
