import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { saveLocationData } from "../../../Redux/actions/therapist";
import { setTherapistDataGetStep } from "../../../Redux/actions/auth";
import { removeLocation } from "../../../Redux/slices/therapistSignupSlice";
import { defaultStatus, therapistSignupSteps } from "../../../utils/auth";
import Location from "./CoverageAreas";
import { HiPlus } from "react-icons/hi";
import Loader from "../../../components/Loader";
import { setLoader } from "../../../Redux/slices/authSlice";

const LocationModal = (props) => {
  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );

  const rejectedData = PrefillDetails?.userDetail.rejectedFields;

  const [open, setOpen] = useState(false);
  const [selectedMap, setSelectedMap] = useState(null);
  const dispatch = useDispatch();
  const userLocationData = useSelector(
    (e) => e.therapistSigunpSlice.coverageArea
  );
  const loading = useSelector((state) => state?.authSlice?.isLoading);

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  const handleSubmit = () => {
    let payload = {};
    payload["userId"] = PrefillDetails.id;

    payload["locations"] = userLocationData;
    if (props?.isProfile) {
      payload["isProfile"] = props.isProfile;
    }
    if (userLocationData && userLocationData.length === 0) {
      toast.info("Please add at least one coverage area.");
      return;
    }
    dispatch(saveLocationData(payload)).then((res) => {
      dispatch(setTherapistDataGetStep(therapistSignupSteps[3]));
      if (res) {
        props.setTabUpdateCheker && props.setTabUpdateCheker();
      }
    });
  };
  const deleteSelectedMap = () => {
    const payload = userLocationData.filter((e) => {
      return (
        JSON.stringify(e.coverageAddress) !==
        JSON.stringify(selectedMap.coverageAddress)
      );
    });

    dispatch(removeLocation(payload));
    setSelectedMap(null);
  };

  useEffect(() => {
    initialMap();
  }, [userLocationData]);

  var polyOptions = {
    strokeWeight: 1,
    strokeColor: "#eb3d3d",
    fillOpacity: 0.45,
    fillColor: "#e47576",
  };

  const handleSeletedMap = (e, index) => {
    setSelectedMap(e);
  };

  const initialMap = () => {
    function removeAllChildNodes(parent) {
      while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
      }
    }
    let container = document.getElementById("map_parent");
    removeAllChildNodes(container);

    userLocationData &&
      userLocationData.length > 0 &&
      userLocationData.map((e, i) => {
        const el = document.createElement("div");
        el.setAttribute("id", `map${i}`);
        el.setAttribute("class", "map-box m-0 p-1 mt-3");
        el.onclick = function () {
          handleSeletedMap(e, i);
        };
        const locationName = document.createElement("p");
        const locName = e?.id && `projectZipCode_${e?.id}`;
        const locationRejected = document.createElement("h6");

        locationName.innerHTML = `${e.name}`;
        locationName.setAttribute("class", "location_name p-0 m-0 mt-4");
        container.appendChild(locationName);

        if (
          locName &&
          PrefillDetails &&
          PrefillDetails?.Status?.key === defaultStatus.rejected &&
          PrefillDetails?.userDetail?.rejectedFields &&
          PrefillDetails?.userDetail?.rejectedFields[locName]
        ) {
          const locationRejected = document.createElement("h6");
          locationRejected.innerHTML = `Rejected`;
          locationRejected.setAttribute("style", "color: #c52525");
          container.appendChild(locationRejected);
          el.style.border = "2px solid red";
        } else {
          container.appendChild(locationRejected);
        }

        container.appendChild(el);

        const map = new window.google.maps.Map(
          document.getElementById(`map${i}`),
          {
            zoom: e.mapZoomlevel ? Number(e.mapZoomlevel) : 15,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            zoomControl: true,
          }
        );

        let apiCordsData;
        apiCordsData = new window.google.maps.Polyline({
          path: e.coverageAddress,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          editable: false,
        });

        apiCordsData.setMap(map);
        var path = apiCordsData.getPath();
        apiCordsData = new window.google.maps.Polygon({
          map: map,
          path: path,
        });

        apiCordsData.setOptions(polyOptions);

        var bounds = new window.google.maps.LatLngBounds();
        for (var j = 0; j < e.coverageAddress.length; j++) {
          bounds.extend(e.coverageAddress[j]);
        }
        map.fitBounds(bounds);
        dispatch(setLoader({ loading: false }));
      });
  };

  window.initMap = initialMap;

  return (
    <>
      <div className="contianer-fluid mt-5 ml-4 ">
        {!props.isProfile && (
          <div className=" col-sm-6 d-flex align-items-center gap-5 mt-5 p-0  my-head">
            <h4 className="cs-heading set-head-th px-0 m-0 p-0">
              Set Your Coverage Areas
            </h4>
            <h4 className="cs-date m-0 setp-1440">
              Step 03/<span className="pd-dt">08</span>
            </h4>
          </div>
        )}
        {!selectedMap && userLocationData?.length == 0 && (
          <button className="coverage-btn mt-5" onClick={handleOpen}>
            Add your coverage area{" "}
          </button>
        )}

        <div id="map_parent" className="map-flex"></div>
        {!props.isProfile ? (
          <div style={{ display: "flex" }} className="mt-5 mb-3 gap-4 d-flex">
            <button className="coverage-btn " onClick={handleSubmit}>
              save and continue
            </button>
            <button
              className="ad-btn-back"
              type="button"
              onClick={() => props.goBackFunction(therapistSignupSteps[2])}
            >
              Back
            </button>
            {userLocationData && userLocationData.length > 0 && (
              <div className="box-icon">
                <HiPlus className="Hiplus" onClick={handleOpen} />
              </div>
            )}
            {selectedMap && (
              <button className="ad-btn-back" onClick={deleteSelectedMap}>
                Delete
              </button>
            )}
          </div>
        ) : (
          <div style={{ display: "flex" }} className="mt-5 mb-3 gap-4 d-flex">
            <button
              className="ad-btnn justify-content-center"
              onClick={handleSubmit}
            >
              Update
            </button>
          </div>
        )}

        {open && <Location open={open} onClose={handleClose} />}
      </div>
    </>
  );
};

export default LocationModal;
