import React from "react";
import { Modal } from "react-bootstrap";
import ContactSupportForm from "../Agency/ContactSupport/ContactSupport";

const DirectMessageModal = (props) => {
  return (
    <>
    { props.show && <Modal
        show={props.show}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title className="p-4 text-center w-100 fw-semibold">Contact Support</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pl-5 pr-5 pb-5">
          <ContactSupportForm onClose={props.handleClose} />
        </Modal.Body>
    
      </Modal>}
    </>
  );
};

export default DirectMessageModal;
