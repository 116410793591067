import React, { useState } from "react";
import { useEffect } from "react";
import { BsCheck2 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  credentials,
  currentRole,
  decodeData,
  professionaltype,
  userCredentials,
} from "../../utils/auth";
import { Form, Field } from "react-final-form";
import { validateForm } from "../../validation/validateForm";
import { formSchema } from "../../validation/schemas";
import { toast } from "react-toastify";
import { VscFolder } from "react-icons/vsc";
import docouments from "../../images/AdminDashboard/document_icon.svg";
import { getUserDetailParams } from "../../utils/helper";
import { getUserDetailsById } from "../../Redux/actions/auth";
import {
  getDefaultRates,
  getTherapistLocationData,
  userRejected,
} from "../../Redux/actions/therapist";
import Loader from "../../components/Loader";
import { apiBasePath } from "../../Redux/config/config";
import CoverageAreaView from "./CoverageAreaView";
import {
  clearRejectedCoverArea,
  updateRejectedCoverArea,
} from "../../Redux/slices/therapistSignupSlice";
let mapInputValue = {};

const TherapistReject = () => {
  const validate = validateForm(formSchema);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rejStep, setRejStep] = useState([]);
  const [validateRej, setValidateRej] = useState(false);

  const profileDetail = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const isLoading = useSelector((e) => e.therapistSigunpSlice.isLoading);
  const rejectedCoverArea = useSelector(
    (e) => e.therapistSigunpSlice.rejectedCoverArea
  );
  useEffect(() => {
    dispatch(getDefaultRates(currentRole.supervisingtherapist));
    if (params && params.id)
      dispatch(
        getUserDetailsById(
          `${decodeData(params.id).id}?${
            getUserDetailParams.userCredentials
          }=true&${getUserDetailParams.userProfessionalData}=true`
        )
      ).then((res) => {
        dispatch(getTherapistLocationData(`${decodeData(params.id).id}`));
      });
  }, [dispatch, params]);

  useEffect(() => {
    return () => {
      dispatch(clearRejectedCoverArea());
    };
  }, []);

  const getValues = (key, subkey) => {
    if (!profileDetail) {
      return;
    }
    if (profileDetail[key]) {
      if (typeof profileDetail[key] === "object") {
        if (profileDetail[key][subkey]) {
          if (subkey === "address") {
            return `${profileDetail?.userDetail?.address}, ${profileDetail?.userDetail?.city}, ${profileDetail?.userDetail?.state}, ${profileDetail?.userDetail?.zip}`;
          } else {
            return profileDetail[key][subkey];
          }
        } else if (subkey === "expirence") {
          const experienceYears = getExperience();
          // Now you can use experienceYears as needed, for example:
          return `${experienceYears} years`;
        } else {
          return "---";
        }
      }
      return profileDetail[key];
    } else {
      return "---";
    }
  };
  const getExperience = () => {
    if (
      profileDetail &&
      profileDetail?.userDetail &&
      profileDetail?.userDetail.experienceDate &&
      profileDetail?.userDetail.experienceMonth &&
      profileDetail?.userDetail.experienceYear
    ) {
      const day = profileDetail?.userDetail.experienceDate;
      const month = profileDetail?.userDetail.experienceMonth;
      const year = profileDetail?.userDetail.experienceYear;
      const currentDate = new Date();
      const endDate = new Date(year, month, day);
      const dateDifference =
        parseInt(currentDate.getTime()) - parseInt(endDate.getTime());
      const expYear = parseInt(dateDifference / (1000 * 3600 * 24 * 365));
      return Number(expYear);
    }
  };

  const handleSubmit = (values) => {
    const pattern = /^projectZipCode_/;

    Object.keys(values).forEach((key) => {
      if (pattern.test(key)) {
        delete values[key];
      }
    });
    if (rejectedCoverArea && rejectedCoverArea.length > 0) {
      rejectedCoverArea.map(
        (item) => (values[`projectZipCode_${item}`] = true)
      );
    }

    let rejectedStep = {};
    if (rejStep?.length > 0) {
      const filData = rejStep?.reduce(function (a, b) {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
      }, []);
      filData?.forEach((item) => {
        rejectedStep[item] = true;
      });
    }
    let tempValues = {};
    let stepSelected = false;
    Object.keys(values).map((key) => {
      if (key !== "reasonforRejection") {
        if (Array.isArray(values[key]) && values[key].length > 0) {
          stepSelected = true;
        } else if (!Array.isArray(values[key]) && values[key]) {
          stepSelected = true;
        }
      }
      if (key === "expirence") {
        tempValues.experienceDate = profileDetail.userDetail.experienceDate;
        tempValues.experienceMonth = profileDetail.userDetail.experienceMonth;
        tempValues.experienceYear = profileDetail.userDetail.experienceYear;
        tempValues.experienceState = profileDetail.userDetail.experienceState;
      } else if (
        values[key] &&
        Array.isArray(values[key]) &&
        values[key].length > 0
      ) {
        tempValues[key] = values[key][0];
      } else if (!Array.isArray(values[key])) {
        tempValues[key] = values[key];
      }
    });
    tempValues.reasonforRejection = values.reasonforRejection;
    const data = {
      userId: decodeData(params?.id).id,
      rejectedFields: { ...mapInputValue, ...tempValues },
      rejectedStep,
      step: 1,
    };
    if (!stepSelected) setValidateRej(true);

    if (
      stepSelected &&
      data?.rejectedFields &&
      Object?.keys(data?.rejectedFields)?.length > 1
    ) {
      dispatch(userRejected(data)).then((res) => {
        if (res && res?.payload?.data) {
          toast.success("Therapist rejected successfully.");
          setRejStep();
          setValidateRej(false);
          setTimeout(() => {
            navigate("/newusers");
          }, 500);
        }
      });
    } else {
      setValidateRej(true);
    }
  };

  const detailArr = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email", key: "email" },
    { label: "Password", key: "password" },
    { label: "Address", key: "userDetail", subKey: "address" },
    { label: "Phone", key: "userDetail", subKey: "phone" },
    { label: "Discipline", key: "userDetail", subKey: "discipline" },
    { label: "EXPERIENCE", key: "userDetail", subKey: "expirence" },
  ];

  if (isLoading) {
    return <Loader />;
  }

  const handleDocumentShow = (type) => {
    const allType = [];
    if (profileDetail && profileDetail?.userCredentials) {
      profileDetail?.userCredentials?.map((item) => {
        if (item?.types === type) {
          if (item && item?.file) {
            const url = `${apiBasePath}documents/therapist/${item.file}`;
            window.open(url, "_blank").focus();
          } else {
            toast.warn("There is no document to show!");
          }
        }
        allType.push(item?.types);
      });
    }
    if (!allType.includes(type)) {
      toast.warn("There is no document to show!");
    }
  };

  const changeDateFormat = (type) => {
    if (profileDetail && profileDetail?.userCredentials) {
      const expireDateFind = profileDetail?.userCredentials?.find(
        (item) => item.types === type
      );
      if (
        expireDateFind &&
        expireDateFind?.types !== userCredentials.businesslicense &&
        expireDateFind?.types !== userCredentials.w9
      ) {
        const splitDate = expireDateFind?.expirationDate?.split("T")[0];
        const newDate = new Date(splitDate);
        return newDate.toLocaleDateString();
      }
      if (
        expireDateFind &&
        (expireDateFind?.types === userCredentials.businesslicense ||
          expireDateFind?.types === userCredentials.w9)
      ) {
        const splitDate = expireDateFind?.expirationDate?.split("T")[0];
        const newDate = new Date(splitDate);
        return newDate.toLocaleDateString();
      }
    }
  };

  const handleStep = (e, step) => {
    if (e?.target?.checked) {
      setValidateRej(false);
    }
    setRejStep([...rejStep, step]);
  };

  const getCredentialsName = (type) => {
    let filedetail = profileDetail?.userCredentials?.find(
      (item) => item.types === type
    );
    return filedetail?.name;
  };

  const handleRejectedArea = (area) => {
    dispatch(updateRejectedCoverArea(area));
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      // initialValues={
      //   profileDetail?.userDetail && profileDetail?.userDetail?.rejectedFields
      //     ? {
      //         ...profileDetail?.userDetail?.rejectedFields,
      //       }
      //     : {}
      // }
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <div className="">
            <div style={{ margin: "20px 0 0 30px" }}>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "97%",
                  }}
                >
                  <h4 className="agency-reject-heading agen-reject-head agen-reject-head-main">
                    Reason for Rejection
                  </h4>
                  <button className="btn1 agen-btn-first" type="submit">
                    <BsCheck2 className="check-icon mr-3" />
                    Submit
                  </button>
                </div>
                <Field name="reasonforRejection">
                  {({ input, meta }) => (
                    <>
                      <div style={{ width: "60%" }}>
                        <textarea
                          placeholder={!meta.active && "Need more info"}
                          className="agen-reject-input-1 w-100"
                          type="text"
                          {...input}
                        />
                        {meta.error && meta.touched && (
                          <p className="alert alert-danger w-100">
                            {meta.error}
                          </p>
                        )}
                        {validateRej && (
                          <p className="alert alert-danger w-100">
                            Please select at least one field to reject
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </Field>
              </div>

              <div className="specify-errors" style={{ marginTop: "20px" }}>
                <h4 className="agency-reject-heading agen-reject-head">
                  Specify Errors
                </h4>
                <h4 className="agen-reject-lbl">Form</h4>

                <div className="container-fluid">
                  <div className="row agen-reject-form-row ">
                    {detailArr?.map((item, index) => (
                      <div
                        className="col-md-3 p-0"
                        key={`${item?.key}_${index}`}
                      >
                        {profileDetail &&
                        Array.isArray(profileDetail[item.key]) &&
                        profileDetail[item.key].length > 0 ? (
                          profileDetail[item.key].map((value, index) => (
                            <div
                              className="custom-control marging-bottom-thera p-0  d-flex"
                              key={`${item.key}_${index}`}
                            >
                              <Field
                                name={`${item.key}_${value.id}`}
                                component="input"
                                type="checkbox"
                                value={getValues(item?.key, item?.subKey)}
                              >
                                {({ input: { onChange, ...input } }) => (
                                  <input
                                    className="agen-reject-check agen-reject-check-1 "
                                    {...input}
                                    onChange={(e) => {
                                      onChange(e);
                                      handleStep(e, "account");
                                    }}
                                    value={getValues(item?.key, item?.subKey)}
                                  />
                                )}
                              </Field>

                              <div
                                className="form-details"
                                style={{ marginLeft: "13px" }}
                              >
                                <p className="agency-reject-para1 mb-0 agen-reject-body">
                                  {item?.label}{" "}
                                  {item.key === "secondaryEmails" && index + 1}
                                </p>
                                <p className="agency-reject-para2 mb-0">
                                  Assisted HHA{value.email}
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div
                            className={`custom-control align-items-center  marging-bottom-thera p-0 d-flex ${
                              (item.key === "password" ||
                                item.key === "email") &&
                              "opacity-50"
                            }`}
                          >
                            <label
                              className="container-card"
                              style={{ height: "100%", margin: "0" }}
                            >
                              <Field
                                name={item.subKey ? item.subKey : item.key}
                                component="input"
                                type="checkbox"
                                value={getValues(item?.key, item?.subKey)}
                              >
                                {({ input: { onChange, ...input } }) => (
                                  <input
                                    className={`checkBox `}
                                    {...input}
                                    disabled={
                                      item.key === "password" ||
                                      item.key === "email"
                                    }
                                    onChange={(e) => {
                                      onChange(e);
                                      handleStep(e, "account");
                                    }}
                                    value={getValues(item?.key, item?.subKey)}
                                  />
                                )}
                              </Field>
                              <span
                                className={`checkmark1 ${
                                  (item.key === "password" ||
                                    item.key === "email") &&
                                  "cursor-not-allowed"
                                }`}
                              ></span>
                            </label>
                            <div className="form-details">
                              <p className="agency-reject-para1 mb-0 agen-reject-body">
                                {item?.label}
                              </p>
                              <p className="agency-reject-para2 mb-0 agen-reject-body2">
                                {getValues(item?.key, item?.subKey)}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="specify-errors" style={{ marginTop: "20px" }}>
                <h4 className="agen-reject-lbl">Languages Spoken</h4>
                <div className="container-fluid">
                  <div className="row agen-reject-form-row ">
                    {profileDetail &&
                      profileDetail?.userProfessionalData?.map(
                        (item, index) => {
                          return (
                            <React.Fragment
                              key={`${item?.masterData?.name}_${index}`}
                            >
                              {item?.masterData?.types ===
                                professionaltype.languagespoken &&
                                item?.value === "true" && (
                                  <div
                                    className="col-md-3 p-0 "
                                    key={`languagespoken_${index}`}
                                  >
                                    <div className="custom-control align-items-center  marging-bottom-thera p-0 d-flex">
                                      <label
                                        className="container-card"
                                        style={{ height: "100%", margin: "0" }}
                                      >
                                        <Field
                                          name={`${item?.masterData?.name}_${item?.masterData?.id}`}
                                          component="input"
                                          type="checkbox"
                                        >
                                          {({
                                            input: { onChange, ...input },
                                          }) => (
                                            <input
                                              className="checkBox"
                                              {...input}
                                              onChange={(e) => {
                                                onChange(e);
                                                handleStep(
                                                  e,
                                                  "professionalData"
                                                );
                                              }}
                                            />
                                          )}
                                        </Field>
                                        <span className=" checkmark1"></span>
                                      </label>
                                      <div className="form-details">
                                        <p className="agency-reject-para2 mb-0 agen-reject-body2">
                                          {item?.masterData?.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </React.Fragment>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
              <div className="specify-errors" style={{ marginTop: "20px" }}>
                <h4 className="agen-reject-lbl">Preferred Specialities</h4>
                <div className="container-fluid">
                  <div className="row agen-reject-form-row ">
                    {profileDetail &&
                      profileDetail?.userProfessionalData &&
                      profileDetail?.userProfessionalData?.map(
                        (item, index) => {
                          return (
                            <React.Fragment
                              key={`specialtiespreferred_${index}`}
                            >
                              {item?.masterData?.types ===
                                professionaltype.specialtiespreferred &&
                                item?.value === "true" && (
                                  <div
                                    className="col-md-3 p-0"
                                    key={`specialtiespreferred_${index}`}
                                  >
                                    <div className="custom-control align-items-center  marging-bottom-thera p-0 d-flex">
                                      <label
                                        className="container-card"
                                        style={{ height: "100%", margin: "0" }}
                                      >
                                        <Field
                                          name={`${item?.masterData?.name}_${item?.masterData?.id}`}
                                          component="input"
                                          type="checkbox"
                                        >
                                          {({
                                            input: { onChange, ...input },
                                          }) => (
                                            <input
                                              className="checkBox"
                                              {...input}
                                              onChange={(e) => {
                                                onChange(e);
                                                handleStep(
                                                  e,
                                                  "professionalData"
                                                );
                                              }}
                                            />
                                          )}
                                        </Field>
                                        <span className=" checkmark1"></span>
                                      </label>
                                      <div className="form-details">
                                        <p className="agency-reject-para2 mb-0 agen-reject-body2">
                                          {item?.masterData?.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </React.Fragment>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>

              <div className="container-fluid">
                <div className="row justify-content-start mt-3  marging-bottom-thera">
                  <h4 className="input-text2 mt-3 mb-3 p-0">COVERAGE AREA</h4>
                  <div className="d-flex reject-map gap-3 align-items-start p-0 ">
                    {/* {therapistLocationData &&
                      therapistLocationData.coverageAreas &&
                      therapistLocationData.coverageAreas.length > 0 &&
                      therapistLocationData.coverageAreas.map((e, i) => {
                        return (
                          <>
                            <label
                              className="container-card"
                              style={{ height: "100%" }}
                            >
                              <Field
                                name={`projectZipCode_${e.id}`}
                                component="input"
                                type="checkbox"
                              >
                                {({ input: { onChange, ...input } }) => (
                                  <input
                                    className="input-therepist2 mapcheckbox"
                                    {...input}
                                    onChange={(e) => {
                                      onChange(e);
                                      handleStep(e, "area");
                                    }}
                                  />
                                )}
                              </Field>
                              <span className=" checkmark1"></span>
                            </label>
                            <div
                              id="map_parent"
                              className="map-flex2 p-0 gap-3"
                            ></div>
                          </>
                        );
                      })} */}
                    <div id="map_parent" className="map-flex2 p-0 gap-3">
                      <CoverageAreaView
                        handleStep={handleStep}
                        fromArea="rejecttherapist"
                        setRejArea={handleRejectedArea}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="registrationRejectedDocument">
                <div style={{ margin: "0" }}>
                  <h5 className="heading_1 heading_1-a mt-3 f-10">Documents</h5>
                  {credentials.map((type, index) => (
                    <React.Fragment key={`${type}_${index}`}>
                      {type !== userCredentials.businesslicense &&
                        type !== userCredentials.w9 &&
                        type !== userCredentials.compliance &&
                        type !== userCredentials.competencies && (
                          <div className="O-div" key={`${type}_${index}`}>
                            <div className="d-flex dfile-margin-1 align-items-center">
                              <label
                                className="container-card"
                                style={{ height: "100%" }}
                              >
                                <Field
                                  name={type}
                                  component="input"
                                  type="checkbox"
                                  value={getCredentialsName(type)}
                                >
                                  {({ input: { onChange, ...input } }) => (
                                    <input
                                      className="checkBox"
                                      {...input}
                                      value={getCredentialsName(type)}
                                      onChange={(e) => {
                                        onChange(e);
                                        handleStep(e, "credentials");
                                      }}
                                    />
                                  )}
                                </Field>
                                <span className=" checkmark1"></span>
                              </label>
                              <div
                                className="dfile-box"
                                onClick={() => handleDocumentShow(type)}
                                style={{ cursor: "pointer" }}
                              >
                                <div className="dfile-img">
                                  <img
                                    src={docouments}
                                    alt=""
                                    className="therapist-image-doc"
                                  />
                                </div>
                                <div className="patientDoc">
                                  <p className="m-0">
                                    {type === userCredentials.therapylicense &&
                                      "Therapy License"}
                                    {type === userCredentials.driverslicense &&
                                      "Driver's License"}

                                    {type === userCredentials.autoinsurance &&
                                      "Auto Insurance"}
                                    {type ===
                                      userCredentials.professionallaibilityinsurance &&
                                      "Professional Liability Insurance"}
                                    {type ===
                                      userCredentials.cprcertification &&
                                      "CPR Certification"}

                                    {type === userCredentials.physical &&
                                      "Physical"}
                                    {type === userCredentials.tbtest &&
                                      "TB Test "}

                                    {type === userCredentials.fluvaccination &&
                                      "FLU Vaccination/Declination form"}

                                    {type === userCredentials.hepatitisB &&
                                      "Hepatitis B Vaccination/Declination Record"}
                                    {type === userCredentials.covidrecord &&
                                      "Covid-19 Vaccination/Declination Record"}
                                  </p>
                                  {type !== userCredentials.covidrecord &&
                                    type !== userCredentials.hepatitisB && (
                                      <p className="patientDoc-p m-0">
                                        Exp. Date:{" "}
                                        {changeDateFormat(type)
                                          ? changeDateFormat(type)
                                          : "XX/XX/XX"}
                                      </p>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </React.Fragment>
                  ))}

                  <h5 className="heading_1 heading_1-a mt-3 f-10">
                    Business Credentials
                  </h5>
                  {credentials.map((type, index) => (
                    <React.Fragment key={`${type}_${index}`}>
                      {(type === userCredentials.businesslicense ||
                        type === userCredentials.w9) && (
                        <div className="O-div" key={`${type}_${index}`}>
                          <div className="d-flex dfile-margin-1 align-items-center  ">
                            <label
                              className="container-card"
                              style={{ height: "100%" }}
                            >
                              <Field
                                name={type}
                                component="input"
                                type="checkbox"
                                value={getCredentialsName(type)}
                              >
                                {({ input: { onChange, ...input } }) => (
                                  <input
                                    className="checkBox"
                                    {...input}
                                    value={getCredentialsName(type)}
                                    onChange={(e) => {
                                      onChange(e);
                                      handleStep(e, "credentials");
                                    }}
                                  />
                                )}
                              </Field>
                              <span className=" checkmark1"></span>
                            </label>
                            <div
                              className="dfile-box"
                              onClick={() => handleDocumentShow(type)}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="dfile-img">
                                <VscFolder fill={"#0F7AD4"} size={"20px"} />
                              </div>
                              <div className="patientDoc">
                                <p className="m-0">
                                  {type === userCredentials.businesslicense &&
                                    "Business License"}

                                  {type === userCredentials.w9 && "w9"}
                                </p>
                                {type !== userCredentials.w9 && (
                                  <p
                                    className="patientDoc-p"
                                    style={{
                                      fontSize: "12px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    Exp. Date:{" "}
                                    {changeDateFormat(type)
                                      ? changeDateFormat(type)
                                      : "XX/XX/XX"}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div style={{ width: "50%" }}>
                {profileDetail &&
                  profileDetail.userRates &&
                  profileDetail.userRates.length > 0 &&
                  profileDetail.userRates.map((item, index) => (
                    <React.Fragment key={`rate_${index}`}>
                      {index === 0 && (
                        <h6 className=" margin-head-1 heading_1 heading_1-a mt-3 f-10">
                          Default Rates ({item.rateTab.name})
                        </h6>
                      )}

                      <div className="row">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <div className="agen-reject-check2 mr-3">
                            <label
                              className="container-card"
                              style={{ height: "100%", margin: "0" }}
                            >
                              <Field
                                name={`rate_${item?.rateTabDetail?.id}_${item?.rateTabId}`}
                                component="input"
                                type="checkbox"
                                value={item.price}
                              >
                                {({ input: { onChange, ...input } }) => (
                                  <input
                                    {...input}
                                    onChange={(e) => {
                                      onChange(e);
                                      handleStep(e, "rates");
                                    }}
                                    value={item.price}
                                  />
                                )}
                              </Field>
                              <span className=" checkmark1"></span>
                            </label>
                          </div>
                          <p
                            className=" ad-labels New-rate-l m-0"
                            style={{ width: "1100px" }}
                          >
                            {item.rateTabDetail.name}
                          </p>

                          <p className=" m-0 ad-input add-input">
                            ${item.price}
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}

                <div></div>
                <button className="btn1 agency-btn mb-4" type="submit">
                  <BsCheck2 className="check-icon mr-3 " />
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default TherapistReject;
