import React, { useEffect } from "react";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { useDispatch, useSelector } from "react-redux";
import { setTherapistDataGetStep } from "../../../Redux/actions/auth";
import {
  masterData,
  therapistNotifications,
} from "../../../Redux/actions/therapist";
import { therapistSignupSteps } from "../../../utils/auth";
import { toast } from "react-toastify";

const NotificationPreferences = (props) => {
  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );

  const notificationData = useSelector(
    (e) => e.therapistSigunpSlice.masterData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(masterData({ types: ["preferences"] }));
  }, [dispatch]);

  const init = {};
  notificationData &&
    notificationData.data.map((e) => {
      PrefillDetails && PrefillDetails.userNotifications?.length > 0
        ? (init[e.name] = PrefillDetails.userNotifications.filter(
          (item) => item.masterDataId === e.id
        ))
        : (init[e.name] = [
          { isTextMessage: false, isEmail: false, masterDataId: e.id },
        ]);
    });

  const requiredArray = (value) => {
    if (value) {
      if (
        value &&
        value[0] &&
        value[0].isTextMessage === false &&
        value[0].isEmail === false
      ) {
        return "Please select at least one option.";
      } else return undefined;
    }
  };

  function handleSubmit(values) {
    let payload = [];
    const notificationPreferenceData = {};
    notificationPreferenceData["userId"] = PrefillDetails.id;
    if (props?.isProfile) {
      notificationPreferenceData["isProfile"] = props.isProfile;
    }
    Object.keys(values).map((item) => {
      let obj = {};

      values &&
        values[item]?.map((e) => {
          obj["isTextMessage"] = e.isTextMessage;
          obj["isEmail"] = e.isEmail;
          obj["masterDataId"] = e.masterDataId;
          obj["id"] = e.id;
          payload.push(obj);
        });
    });
    notificationPreferenceData["payload"] = payload;

    dispatch(therapistNotifications(notificationPreferenceData)).then((res) => {
      dispatch(setTherapistDataGetStep(therapistSignupSteps[6]));
      if (res) {
        props.setTabkey && props.setTabkey(1);
      }
    });
  }

  function formatter(name) {
    switch (name) {
      case "NewReferrals":
        return "New Referral";
      case "CaseManagement":
        return "Case Management";
      case "Documentation":
        return "Documentation";
      case "PatiantCommunications/Activity":
        return "Patient Communications/Activity";
      case "DirectMessages":
        return "Direct Messages";
      case "CredentialExpirations":
        return "Credential Expirations";
      case "TextMessages":
        return "Text Messages";

      default:
        break;
    }
  }

  return (
    <div className="container-fluid pl-2 pt-4">
      {!props.isProfile && (
        <div className="col-sm-12  mb-3  align-items-center">
          <h1
            className="cs-heading set-head-th px-0 m-0 p-0"
            style={{ height: "47px" }}
          >
            Notification Preferences{" "}
            <span className="cs-date ml-5 mt-1 align-center">
              Step 06/<span className="pd-dt">08</span>
            </span>
          </h1>
          <p className="np-p m-0">Select at least 1 per notification type.</p>
        </div>
      )}

      <Form
        keepDirtyOnReinitialize
        onSubmit={handleSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={init}
        render={({ values, handleSubmit, submitting, valid }) => (
          <form onSubmit={(e) => {
            if (!valid) toast.error("Please complete all required fields.")
            handleSubmit(e)
          }}>
            {values &&
              Object.keys(values).map((item, index) => (
                <FieldArray
                  name={item}
                  key={`${item}_${index}`}
                  validate={requiredArray}
                >
                  {({ fields, meta }) =>
                    fields.map((name) => (
                      <div className="col-sm-12 mt-3 p-0 d-flex align-items-center">


                        <div
                          className="col-sm-4 mt-auto mb-auto
                    "
                        >
                          <h6 className="np-it-h6 mb-0">{formatter(item)}</h6>
                        </div>


                        {/* <div className="col-sm-4">
                          <h6 className="np-it-h6 mb-0">{formatter(item)}</h6>
                        </div>*/}
                        <div className="  col-sm-8 mobileWindow d-flex gap-60">
                          <label className="container-card align-items-center d-flex padding-left-ref ref-height ">
                            <Field name={`${name}.isTextMessage`} type="checkbox">


                              {({ input }) => (
                                <input   {...input} />
                              )}



                            </Field>
                            <span className=" checkmark1"></span>
                            <div className="ml-3">
                              Text message
                            </div>
                          </label>
                          <label className="container-card align-items-center d-flex padding-left-ref ref-height ">
                            <Field name={`${name}.isEmail`} type="checkbox">


                              {({ input }) => (
                                <input   {...input} />
                              )}


                            </Field>

                            <span className=" checkmark1"></span>
                            <div className="ml-3">
                              Email
                            </div>
                          </label>
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger  mobile_block-display" role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      </div>
                    ))
                  }
                </FieldArray>
              ))}
            {!props.isProfile ? (
              <div className="mt-4 mb-4 ml-2" style={{ display: "flex" }}>
                <button className="coverage-btn">Save & Continue</button>

                <button
                  className="ad-btn-back"
                  type="button"
                  onClick={() => props.goBackFunction(therapistSignupSteps[5])}
                >
                  Back
                </button>
              </div>
            ) : (
              <div className="mt-4 ml-2" style={{ display: "flex" }}>
                <button type="submit" disabled={submitting} className="ad-btnn">
                  Update
                </button>
              </div>
            )}
          </form>
        )}
      />
    </div>
  );
};
export default NotificationPreferences;
