import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export const CancelReasonModal = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);
  const handleClose = () => {
    window.location.href = "/home";
  };

  useEffect(() => {
    let cancelKey = searchParams.get("cancelkey");
    if (cancelKey) {
      navigate(location.pathname);
    }
  }, []);
  return (
    <>
      <Modal
        size="lg"
        className={props.modalClass}
        show={props.show}
        onHide={() => handleClose()}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header
          className="closeButton border-0"
          closeButton
        ></Modal.Header>
        <>
          <div className="cancel_modal pb-5">
            <p>
              Agency cancelled referral <br />
              {` ${staffDetail?.lastName && staffDetail?.lastName}, ${
                staffDetail?.firstName && staffDetail.firstName
              }`}
            </p>
            <p className="mrn">
              mrn#
              {staffDetail &&
                staffDetail.staffingDetails &&
                staffDetail.staffingDetails.mrn}
            </p>
            {staffDetail &&
            staffDetail.staffUser &&
            staffDetail.staffUser.cancelReason ? (
              <>
                {staffDetail.staffUser.cancelReason.options &&
                staffDetail.staffUser.cancelReason.options.length > 0
                  ? staffDetail.staffUser.cancelReason.options.join(", ")
                  : ""}
                <br />
                <span>{staffDetail.staffUser.cancelReason.reason}</span>
              </>
            ) : (
              ""
            )}
          </div>
        </>
      </Modal>
    </>
  );
};
