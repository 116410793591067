import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getEnvelopDetail } from "../../Redux/actions/therapist";
import { apiBasePath } from "../../Redux/config/config";
import Loader from "../../components/Loader";

const CompianceEnvalop = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((e) => e.therapistSigunpSlice.isLoading);
  useEffect(() => {
    if (searchParams && searchParams.get("code")) {
      let obj = {
        code: searchParams.get("code"),
      };
      let envelope = localStorage.getItem("envelope");
      if (envelope) {
        envelope = JSON.parse(envelope);
        obj = { ...obj, ...envelope };
      }
      dispatch(getEnvelopDetail(obj)).then((response) => {
        localStorage.removeItem("envelope");
        setTimeout(() => {
          window.open(
            `${apiBasePath}documents/docusign/${response.payload.data.file}`
          );
        }, 500);
        navigate(`/newusers/therapist/${response.payload.data.userId}`);
      });
    }
  }, []);

  return <div>{isLoading && <Loader />}</div>;
};

export default CompianceEnvalop;
