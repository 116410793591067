import Modal from "react-bootstrap/Modal";
import React from "react";
import PatientDetails from "../Patients/PatientDetails";

export const UnfinishModal = (props) => {
    const { isUnifinishedModal, handleClose, staffUserId, mainhandleClose } = props
    return (
        <>
            <Modal
                size={'lg'}
                className={'ApprovM'}
                show={isUnifinishedModal}
                onHide={handleClose}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header
                    className="closeButton border-0"
                    closeButton
                ></Modal.Header>
                <Modal.Body className="p-0">
                    <PatientDetails
                        staffUserId={staffUserId} 
                        handleClose={mainhandleClose}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};
