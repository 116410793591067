import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MdEditCalendar, MdNotificationsNone } from "react-icons/md";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Hometitle from "../images/AdminDashboard/home_title_icon.svg";
import Patienttitle from "../images/AdminDashboard/patient_charts_title_icon.svg";
import Staffing from "../images/staffing_title_icon.svg";
import NewUser from "../images/newusers_tab_icon_selected.svg";
import AdminUser from "../images/adminusers_title_icon.svg";
import ChatModal from "../pages/modals/ChatModal";
import {
  setChatScreenState,
  setUrgentList,
  unreadMessageCheck,
} from "../Redux/slices/chatSlice";
import { urgentTypeArray } from "../utils/helper";
import Notification from "./common/Notification";
import { getNotificationList } from "../Redux/actions/notification";
import { Manager, io } from "socket.io-client";
import { apiBasePath } from "../Redux/config/config";
import { toast } from "react-toastify";
import {
  AgencyProfilePristine,
  therapistLocationState,
  triggerUpdateButton,
} from "../Redux/slices/therapistSignupSlice";

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 45px;
  box-shadow: 0px 3px 10px #00000029;
  margin-bottom: 10px;
`;

const List = styled.span`
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
`;

const navIcons = {
  Home: { icon: <img src={Hometitle} className="nav-icon-w" alt="" /> },
  "Patient Charts": {
    icon: <img src={Patienttitle} className="nav-icon-w" alt="" />,
  },
  Staffing: { icon: <img src={Staffing} className="nav-icon-w" alt="" /> },
  "Block-List": { icon: <img src={Staffing} className="nav-icon-w" alt="" /> },
  "Home ": { icon: <img src={Staffing} className="nav-icon-w" alt="" /> },
  "New Users": { icon: <img src={NewUser} className="nav-icon-w" alt="" /> },
  "Admin User": { icon: <img src={AdminUser} className="nav-icon-w" alt="" /> },
  "Therapist Credentials": {
    icon: <img src={AdminUser} className="nav-icon-w" alt="" />,
  },
  "My Schedule": { icon: <MdEditCalendar /> },
  "Find Patients": {
    icon: <img src={AdminUser} className="nav-icon-w" alt="" />,
  },
  "User Profile": {
    icon: <img src={AdminUser} className="nav-icon-w" alt="" />,
  },
  "Contact Support": {
    icon: <img src={AdminUser} className="nav-icon-w" alt="" />,
  },
  Logout: {
    icon: <img src={AdminUser} className="nav-icon-w" alt="" />,
  },
  Documentation: {
    icon: <img src={AdminUser} className="nav-icon-w" alt="" />,
  },
  "Case Management": {
    icon: <img src={AdminUser} className="nav-icon-w" alt="" />,
  },
  Accounting: {
    icon: <img src={AdminUser} className="nav-icon-w" alt="" />,
  },
};

const Navbar = (props) => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [crumbList, setCrumbList] = useState(null);
  const [chatModalShow, setChatModalShow] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const listname = useSelector((e) => e.authSlice.listname);
  const hasUnreadMessage = useSelector(
    (state) => state.chatSlice.hasUnreadMessage
  );
  const ConversationItems = useSelector(
    (state) => state.chatSlice.conversationPaginator?.items
  );
  const pristine = useSelector((state) => state.therapistSigunpSlice.pristine);
  const notificationCount = useSelector(
    (state) => state.notificationSlice.count
  );
  const isNewNotification = useSelector(
    (state) => state.notificationSlice.isNewNotification
  );

  const agencyCheck = useSelector(
    (e) => e.therapistSigunpSlice.checkAgencyValue
  );
  const therapistMapAdded = useSelector(
    (e) => e.therapistSigunpSlice.isMapLocationAdded
  );
  const profileDetail = useSelector(
    (e) => e.therapistSigunpSlice.therapistLocationData
  );
  const userLocationData = useSelector(
    (e) => e.therapistSigunpSlice.coverageArea
  );

  const agencyImage = useSelector((state) => state.therapistSigunpSlice.isImageAgency)

  let breadCrumbs = [
    {
      route: `/newusers/agency/${params.id}`,
      crumbs: [
        { label: <p className="m-0 mr-3">Home</p>, link: "/home" },
        { label: <p className="m-0 mr-3">/</p> },
        { label: <p className="m-0 mr-3">New User</p>, link: "/newusers" },
        { label: <p className="m-0 mr-3">/</p> },
        { label: <p className="m-0 mr-3">Agency</p>, link: null },
      ],
    },
    {
      route: `/patientdetails`,
      crumbs: [
        { label: "Patient Charts", br: "/", link: "/patientcharts" },
        { label: "Patient Details", br: "", link: null },
      ],
    },
    {
      route: `/newusers/agency/rejected/${params.id}`,
      crumbs: [
        { label: <p className="m-0 mr-3">Home</p>, link: "/home" },
        { label: <p className="m-0 mr-3">/</p> },
        { label: <p className="m-0 mr-3">New User</p>, link: "/newusers" },
        { label: <p className="m-0 mr-3">/</p> },
        {
          label: <p className="m-0 mr-3">Agency</p>,
          link: `/newusers/agency/${params.id}`,
        },
        { label: <p className="m-0 mr-3">/</p> },
        { label: <p className="m-0">Rejected</p>, link: null },
      ],
    },
    {
      route: `/newusers/therapist/${params.id}`,
      crumbs: [
        { label: <p className="m-0 mr-3">Home</p>, link: "/home" },
        { label: <p className="m-0 mr-3">/</p> },
        { label: <p className="m-0 mr-3">New User</p>, link: "/newusers" },
        { label: <p className="m-0 mr-3">/</p> },
        { label: <p className="m-0">Therapist</p>, link: null },
      ],
    },

    {
      route: `/newusers/therapist/rejected/${params.id}`,
      crumbs: [
        { label: <p className="m-0 mr-3">Home</p>, link: "/home" },
        { label: <p className="m-0 mr-3">/</p> },
        { label: <p className="m-0 mr-3">New User</p>, link: "/newusers" },
        { label: <p className="m-0 mr-3">/</p> },
        {
          label: <p className="m-0 mr-3">Therapist</p>,
          link: `/newusers/therapist/${params.id}`,
        },
        { label: <p className="m-0 mr-3">/</p> },
        { label: <p className="m-0">Rejected</p>, link: null },
      ],
    },
    {
      route: `/staffing/staff-new-referral`,
      crumbs: [
        { label: <p className="m-0 mr-3">Staffing</p>, link: "/staffing" },
        { label: <p className="m-0 mr-3">/</p> },
        { label: <p className="m-0 mr-3">Staff New Referral</p>, link: null },
      ],
    },
  ];

  useEffect(() => {
    let temp = breadCrumbs.find((ele) => ele.route === location.pathname);
    setCrumbList(temp);
  }, [navigate]);

  const UnreadCount = (conversation) => {
    let count = 0;
    const lastReadMessageIndex = conversation.lastReadMessageIndex;
    const lastMessageIndex = conversation.lastMessage?.index;
    if (lastMessageIndex > lastReadMessageIndex) {
      count = lastMessageIndex - lastReadMessageIndex;
    }
    return count;
  };

  useEffect(() => {
    const SortList = async (value) => {
      const UrgentConversations = [];
      const UnreadConversations = [];
      if (ConversationItems && ConversationItems.length > 0) {
        for (const ele of ConversationItems) {
          const unreadCount = UnreadCount(ele);
          if (unreadCount > 0) {
            let isUrgent = false;
            const { items } = await ele.getMessages(unreadCount + 1);
            items.forEach(({ attributes }) => {
              if (
                attributes &&
                attributes.subjectType &&
                urgentTypeArray.includes(attributes?.subjectType)
              ) {
                isUrgent = true;
              }
            });
            if (isUrgent) {
              UrgentConversations.push(ele);
            } else {
              UnreadConversations.push(ele);
            }
          }
        }
        if (UrgentConversations.length > 0 || UnreadConversations.length > 0) {
          dispatch(unreadMessageCheck(true));
        }
        UrgentConversations.sort((a, b) => {
          if (a.lastMessage && b.lastMessage) {
            return (
              a.lastMessage?.dateCreated.getTime() -
              b.lastMessage?.dateCreated.getTime()
            );
          } else {
            return a.dateUpdated.getTime() - b.dateUpdated.getTime();
          }
        });
        if (UrgentConversations.length > 0) {
          dispatch(setUrgentList(UrgentConversations));
        } else {
          dispatch(setUrgentList([]));
        }
      }
    };
    SortList();
  }, [ConversationItems, chatModalShow]);

  function showToastify(data) {
    toast[data.type](data.message);
  }

  useEffect(() => {
    dispatch(getNotificationList());

    if (location.search === "?showModal=true") {
      setChatModalShow(true);
    }
  }, []);


  const handleTriggerUpdate = () => {
    if (
      agencyCheck || agencyImage ||
      profileDetail?.coverageAreas.length !== userLocationData?.length
    ) {
      dispatch(triggerUpdateButton(true));
    }
  };

  useEffect(() => {
    if (profileDetail?.coverageAreas.length === userLocationData?.length) {
      // dispatch(AgencyProfilePristine(therapistMapAdded));
      dispatch(therapistLocationState(false));
    }
  }, [
    userLocationData,
    profileDetail?.coverageAreas.length === userLocationData?.length,
    therapistMapAdded,
  ]);


  return (
    <Nav className="sticky-top bg-white nav-bar-class ">
      <div className="right_main_top_nav">
        {crumbList ? (
          crumbList.crumbs.map((item, index) => {
            return (
              <React.Fragment key={`${item.label}_${index}`}>
                <div
                  onClick={() => navigate(item.link)}
                  className="font-titel gap-5"
                  style={{
                    fontWeight: item.link ? "900" : "500",
                    cursor: item.link ? "pointer" : "auto",
                  }}
                >
                  {item.label}
                </div>
                <h5>{item.br}</h5>
              </React.Fragment>
            );
          })
        ) : (
          <List>
            <div className="d-flex gap-3 align-items-center">
              {listname && navIcons[listname].icon}
              <p className="m-0 top-nav-p">{listname}</p>
            </div>
          </List>
        )}
      </div>

      <span className="d-flex  align-items-center gap-3 ">
        {listname === "User Profile" && (
          <button
            className={`update-btn mt-5 mb-5 ${!agencyCheck && !therapistMapAdded && !agencyImage ? "opacity-50" : ""
              }`}
            disabled={!agencyCheck && !therapistMapAdded && !agencyImage}
            onClick={() => {
              // dispatch(triggerUpdateButton(true))
              handleTriggerUpdate();
            }}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="35"
              width="35"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="none"
                stroke-miterlimit="10"
                stroke-width="32"
                d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
              ></path>
              <path
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="32"
                d="M351.82 271.87v-16A96.15 96.15 0 00184.09 192m-24.2 48.17v16A96.22 96.22 0 00327.81 320"
              ></path>
              <path
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="32"
                d="M135.87 256l23.59-23.6 24.67 23.6m192 0l-23.59 23.6-24.67-23.6"
              ></path>
            </svg>
            Update
          </button>
        )}

        <i
          className={`icon_email ${notificationCount > 0 || isNewNotification ? " Bubble" : ""
            } top`}
        >
          <MdNotificationsNone
            color={"#0f7ad4"}
            size={30}
            onClick={() => {
              setShowNotification(!showNotification);
            }}
          />
        </i>
        <i className={`icon_email ${hasUnreadMessage && "Bubble top"}`}>
          <BiMessageRoundedDetail
            size={30}
            fill="#0f7ad4"
            style={{ cursor: "pointer" }}
            onClick={() => setChatModalShow(true)}
          />
        </i>
      </span>
      <ChatModal
        show={chatModalShow}
        handleClose={() => {
          dispatch(setChatScreenState(false));
          dispatch(unreadMessageCheck(false));
          setChatModalShow(false);
        }}
      />
      {showNotification && (
        <Notification setShowNotification={setShowNotification} />
      )}
    </Nav>
  );
};

export default Navbar;
