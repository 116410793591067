import React, { useEffect, useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import {
  addAdditionalForms,
  getStaffAssignments,
  getStaffDocumentation,
} from "../../Redux/actions/common";
import { decodeData } from "../../utils/auth";
import { masterData } from "../../Redux/actions/therapist";
import { staffFormStatus, staffFormTypesConstants } from "../../utils/helper";
import { toast } from "react-toastify";

const AdditionalForms = (props) => {
  const { staffDocument } = props;
  const staffassignments = useSelector((e) => e.commonSlice.staffassignments);
  const masterDataDetail = useSelector(
    (e) => e.therapistSigunpSlice.masterData
  );
  const dispatch = useDispatch();

  const episodeId = props?.staffDocument && props?.staffDocument[0]?.episodeId;
  const agencyId = props?.staffDocument && props?.staffDocument[0]?.agencyId;
  const discipline =
    props?.staffDocument && props?.staffDocument[0]?.discipline;

  const params = useParams();
  const { staffUserId } = props;
  useEffect(() => {
    dispatch(
      getStaffAssignments({
        staffUserId: staffUserId ? staffUserId : decodeData(params.id),
      })
    );
    dispatch(masterData({ types: ["additionalFormTypes"] }));
  }, [dispatch]);

  const checkEvaluationComplete = () => {
    let isComplete = false;
    if (staffDocument && staffDocument.length > 0) {
      staffDocument.find((item) => {
        let formData =
          item.episodeVisit.name === staffFormTypesConstants.Evaluation &&
          item.Status.key == staffFormStatus.completed;
        if (formData) {
          isComplete = true;
        }
      });

      return isComplete;
    }
  };

  const onSubmit = (values) => {
    const payload = {
      episodeId: episodeId, //v
      therapistId: values?.therapist?.value,
      visitTypeId: values?.formType?.value,
      agencyId: agencyId,
      discipline: discipline,
      date: values?.startDate,
    };
    // Include the user's token in the request headers
    const headers = {
      Authorization: `Bearer ${decodeData(params.id)}`,
    };
    dispatch(addAdditionalForms(payload, headers)).then((res) => {
      if (res?.payload?.success) {
        if (res?.payload.message) {
          toast.info(res?.payload.message);
        }
        props.handleClose();
        dispatch(
          getStaffDocumentation({
            staffUserId: staffUserId
              ? Number(staffUserId)
              : decodeData(params.id),
          })
        );
      }
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.formType) {
      errors.formType = "Form Type is required.";
    }
    if (!values.startDate) {
      errors.startDate = "Start Date is required.";
    }
    if (!values.therapist) {
      errors.therapist = "Therapist is required.";
    }
    return errors;
  };
  const constructOption = (value, label) => ({
    value: value || label,
    label: label ?? value,
  });

  const therapistListing = () => {
    const therapistList = [];
    staffassignments?.length > 0 &&
      staffassignments?.map(({ therapistId, therapist }) => {
        therapistList.push(
          constructOption(
            therapistId,
            `${(therapist?.lasrName, therapist?.firstName)}`
          )
        );
      });
    return therapistList;
  };
  const masterDataDropdown = () => {
    const masterValues = [];
    masterDataDetail?.data?.length > 0 &&
      masterDataDetail?.data?.map((item) => {
        if (!checkEvaluationComplete()) {
          if (
            item.name.toLowerCase() ==
              staffFormTypesConstants.NVDC.toLowerCase() ||
            item.name.toLowerCase() ==
              staffFormTypesConstants.NVDCOASIS.toLowerCase()
          ) {
            return;
          }
        }
        masterValues.push(constructOption(item?.id, item?.displayName));
      });
    return masterValues;
  };
  // Get the current date in "YYYY-MM-DD" format
  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <Modal
      size="lg"
      className="accept-test-modal"
      show={props.showAdditonalModal}
      onHide={props.handleClose}
    >
      <Modal.Header className="pl-4 pr-4 pt-4 pb-3" closeButton>
        <Modal.Title>Additional Forms</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pl-4 pr-4 pt-0 pb-4">
        <Form
          keepDirtyOnReinitialize
          onSubmit={onSubmit}
          validate={validate}
          //   initialValues={useMemo(initValues, [episodeDetails])}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <Field name="formType">
                    {({ input, meta }) => (
                      <>
                        <label>Form Type</label>
                        <Select
                          {...input}
                          class="form-control"
                          options={masterDataDropdown()}
                        />
                        {meta.error && meta.touched && (
                          <p className="alert alert-danger">{meta.error}</p>
                        )}
                      </>
                    )}
                  </Field>
                </div>
                <div className="col-md-6 mb-3">
                  <Field name="startDate">
                    {({ input, meta }) => (
                      <>
                        <label>Start Date</label>
                        <input
                          {...input}
                          class="form-control"
                          type="date"
                          onChange={(e) => {
                            input.onChange(e);
                            // form.change('episodeEndDate', setEndDate(e.target.value))
                          }}
                          min={currentDate}
                        />
                        {meta.error && meta.touched && (
                          <p className="alert alert-danger">{meta.error}</p>
                        )}
                      </>
                    )}
                  </Field>
                </div>
                <div className="col-md-6 mb-3">
                  <Field name="therapist">
                    {({ input, meta }) => (
                      <>
                        {" "}
                        <label>Therapist</label>
                        <Select
                          {...input}
                          class="form-control"
                          type="input"
                          options={therapistListing()}
                          // disabled
                        />
                        {meta.error && meta.touched && (
                          <p className="alert alert-danger">{meta.error}</p>
                        )}
                      </>
                    )}
                  </Field>
                </div>
                <div className="mt-4">
                  <Button
                    type="submit"
                    variant="primary"
                    className="primary-confirm"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          )}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AdditionalForms;
