import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { ImAttachment } from "react-icons/im";
import Agencypng from "../../../images/usertable01.svg";
import { Button, Col, Row } from "react-bootstrap";
import documentImgs from "../../../images/document_icon.svg";
import { IoSyncCircleOutline } from "react-icons/io5";
import { HiMinus, HiPlus } from "react-icons/hi";
import Paymentdetails from "../signup/Paymentdetails";
import { useDispatch, useSelector } from "react-redux";
import { currentRole, getUserId } from "../../../utils/auth";
import { toast } from "react-toastify";
import {
  getCurrentActiveUser,
  getUserDetailsById,
} from "../../../Redux/actions/auth";
import Rswitch from "../../../components/Rswitch";
import arrayMutators from "final-form-arrays";
import AddressAutocomplete from "../../../components/AddressAutoComplete";
import { getDefaultRates, userSignup } from "../../../Redux/actions/therapist";
import AgencyDefaultRates from "../signup/AgencyDefaultRates";
import {
  formatPinCode,
  formatPinCodeValue,
  getUserDetailParams,
  passwordRegex,
  phoneNumberToUSFormat,
  profileTabs,
} from "../../../utils/helper";
import { apiBasePath } from "../../../Redux/config/config";
import Loader from "../../../components/Loader";
import { FieldArray } from "react-final-form-arrays";
import SetNewPassword from "../signup/SetNewPassword";
import BlockList from "./blockList";
import UserProfilesIcon from "../../../images/UserProfilesIcon/agencyinfo.svg";
import UserProfilesIconActive from "../../../images/UserProfilesIcon/agencyinfo_active.svg";
import PaymentProfileIcon from "../../../images/UserProfilesIcon/paymentinfo_icon.svg";
import PaymentInfoActive from "../../../images/UserProfilesIcon/paymentinfo_active.svg";
import DefaultRateIcon from "../../../images/UserProfilesIcon/defaultrate_icon.svg";
import DefaultRateIconActive from "../../../images/UserProfilesIcon/defaultrateicon_active.svg";
import DocumentsIcon from "../../../images/UserProfilesIcon/documents_icon.svg";
import DocumentsActive from "../../../images/UserProfilesIcon/documents_active.svg";
import BlockIcon from "../../../images/UserProfilesIcon/block_icon.svg";
import BlockIconActive from "../../../images/UserProfilesIcon/block_active.svg";
import editIcon from "../../../images/edit-profile-icon.svg";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useNavigate, useParams } from "react-router-dom";
import {
  AgencyProfilePristine,
  agencyImageUpload,
  setPristine,
  triggerUpdateButton,
} from "../../../Redux/slices/therapistSignupSlice";
import WarningPromptModal from "../../modals/ConfirmTabChange";
import { MyModal2 } from "../../modals/MyModal2";
import {
  ProfileValidState,
  agencyDetailForPic,
  agencyInitialValueDetail,
} from "../../../Redux/slices/agencySignupSlice";

const FieldLabel = styled.label`
  font-weight: bold;
  font-size: 14px;
  padding: 3px 0px;
`;

function AgencyProfile() {
  const buttonRef = useRef();
  const docbuttonRef = useRef();
  const calledOnceRef = useRef(false);

  const params = useParams();
  const [data, setData] = useState();
  const [file, setFile] = useState(Agencypng);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedFile, setuploadedFile] = useState(null);
  const [fieldValue, setFieldValue] = useState(null);

  const [passwordType, setPasswordType] = useState("password");
  const [Tabkey, setTabkey] = useState(profileTabs.personalinfo);
  const [tabUpdateCheker, setTabUpdateCheker] = useState(false);
  const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
  const [selectedTabState, setSelectedTabState] = useState(null);
  const [warningPrompt, setWarningPrompt] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const pristineSlice = useSelector((e) => e.therapistSigunpSlice.pristine);
  const agencyCheck = useSelector(
    (e) => e.therapistSigunpSlice.checkAgencyValue
  );

  const InitialAgencyProfileValue = useSelector(
    (e) => e.agencySlice.agencyDetailProfile
  );
  const isLoading = useSelector((e) => e.therapistSigunpSlice.isLoading);
  const agencyImage = useSelector(
    (state) => state.therapistSigunpSlice.isImageAgency
  );
  const triggerUpdateState = useSelector(
    (state) => state.therapistSigunpSlice.triggerUpdateFunction
  );

  const dispatch = useDispatch();
  var isProfile = true;

  useEffect(() => {
    if (PrefillDetails?.image) {
      setFile(`${apiBasePath}documents/therapist/${PrefillDetails?.image}`);
    }
  }, [PrefillDetails]);

  function handleDeleteFile(formValues) {
    let values = JSON.parse(JSON.stringify(formValues));
    let formData = new FormData();

    values.isProfile = true;
    let id = null;
    if (PrefillDetails?.id) {
      values.id = PrefillDetails.id;
      id = PrefillDetails.id;
    }

    values.user = "agency";
    let emails = [];

    if (values.email) {
      emails.push({ email: values.email, id });
    }
    if (!values.aptUnit) {
      values.aptUnit = null;
    }

    if (
      values?.representativeEmail &&
      values?.representativeEmail?.length > 0
    ) {
      values?.representativeEmail?.forEach((item) => {
        if (values?.email) {
          item.email = values?.email;
        }
      });
    }
    let missingNotification = [];

    if (values && values.secondryEmail && values.secondryEmail?.length > 0) {
      let accounting = 0,
        intakeStaffing = 0,
        caseManagement = 0;
      values?.secondryEmail.forEach((item) => {
        if (!values?.representativeEmail[0]?.accounting && !item?.accounting) {
          accounting++;
        }
        if (
          !values?.representativeEmail[0]?.intakeStaffing &&
          !item?.intakeStaffing
        ) {
          intakeStaffing++;
        }
        if (
          !values?.representativeEmail[0]?.caseManagement &&
          !item?.caseManagement
        ) {
          caseManagement++;
        }
      });
      let emailslength = values?.secondryEmail?.length;
      if (
        accounting === emailslength ||
        intakeStaffing === emailslength ||
        caseManagement === emailslength
      ) {
        if (accounting === emailslength) {
          missingNotification.push("accounting");
        }
        if (intakeStaffing === emailslength) {
          missingNotification.push("intakeStaffing");
        }
        if (caseManagement === emailslength) {
          missingNotification.push("caseManagement");
        }
      }

      values.secondryEmail.map((obj) => {
        if (obj.email) {
          emails.push({ email: obj.email, id: obj.id });
        }
        return obj;
      });
    }

    var isDuplicate = emails.some(function (item, idx) {
      return (
        emails.findIndex((object) => {
          return object.email === item.email;
        }) !== idx
      );
    });
    if (isDuplicate) {
      toast.error("You can not add duplicate email.");
      return;
    }
    if (uploadedImage) {
      formData.append("files", uploadedImage);
    }
    // delete values.userConsentName

    values.isConsent = false;

    if (values && values?.userConsentName) {
      values.isConsent = false;

      formData.append("profileData", JSON.stringify(values));
      setFieldValue("No file selected");
      dispatch(userSignup(formData)).then((res) => {
        // setSelectedTabState(null);
        // setWarningPrompt(false);
        dispatch(agencyInitialValueDetail(null));
        dispatch(AgencyProfilePristine(false));
        dispatch(
          getUserDetailsById(
            `${getUserId()?.userId}?${
              getUserDetailParams.secondaryEmails
            }=true&${getUserDetailParams.representativeEmails}=true`
          )
        ).then((res) => {
          dispatch(getCurrentActiveUser(getUserId()?.userId));
        });
      });
    }
  }

  function handleSubmit(formValue) {
    let formData = new FormData();
    let values = JSON.parse(JSON.stringify(formValue));
    let emptyEmail = [];
    if (values.secondryEmail && values.secondryEmail.length > 0) {
      emptyEmail = values.secondryEmail.filter((item) => !item.email);
    }
    if (emptyEmail.length > 0 && values.secondryEmail.length > 1) {
      toast.warn(
        `You cannot empty the secondary email input; your only option is to remove it.`
      );
      dispatch(ProfileValidState(true));
      return;
    }

    values.isProfile = true;
    let id = null;
    if (PrefillDetails?.id) {
      values.id = PrefillDetails.id;
      id = PrefillDetails.id;
    }

    values.user = "agency";
    let emails = [];

    if (values.email) {
      emails.push({ email: values.email, id });
    }
    if (!values.aptUnit) {
      values.aptUnit = null;
    }

    if (values.representativeEmail && values.representativeEmail.length > 0) {
      for (let index = 0; index < values.representativeEmail.length; index++) {
        if (values.email)
          values.representativeEmail[index]["email"] = values?.email;
      }
    }
    let missingNotification = [];

    if (values && values.secondryEmail && values.secondryEmail?.length > 0) {
      let accounting = 0,
        intakeStaffing = 0,
        caseManagement = 0;
      values?.secondryEmail.forEach((item) => {
        if (!values?.representativeEmail[0]?.accounting && !item?.accounting) {
          accounting++;
        }
        if (
          !values?.representativeEmail[0]?.intakeStaffing &&
          !item?.intakeStaffing
        ) {
          intakeStaffing++;
        }
        if (
          !values?.representativeEmail[0]?.caseManagement &&
          !item?.caseManagement
        ) {
          caseManagement++;
        }
      });
      let emailslength = values?.secondryEmail?.length;
      if (
        accounting === emailslength ||
        intakeStaffing === emailslength ||
        caseManagement === emailslength
      ) {
        if (accounting === emailslength) {
          missingNotification.push("accounting");
        }
        if (intakeStaffing === emailslength) {
          missingNotification.push("intakeStaffing");
        }
        if (caseManagement === emailslength) {
          missingNotification.push("caseManagement");
        }
      }

      values.secondryEmail.map((obj) => {
        if (obj.email) {
          emails.push({ email: obj.email, id: obj.id });
        }
        return obj;
      });
    }

    var isDuplicate = emails.some(function (item, idx) {
      return (
        emails.findIndex((object) => {
          return object.email === item.email;
        }) !== idx
      );
    });
    if (isDuplicate) {
      toast.error("You can not add duplicate email.");
      return;
    }
    if (uploadedImage) {
      formData.append("files", uploadedImage);
    }
    if (Tabkey === profileTabs.credentials) {
      if (uploadedFile) {
        formData.append("userConsent", uploadedFile);
      }
      values.isConsent = true;
    }

    formData.append("profileData", JSON.stringify(values));
    // formData.append("profileData", JSON.stringify(values));
    if (
      Tabkey === profileTabs.personalinfo &&
      missingNotification?.length > 0
    ) {
      toast.warn(
        `Please enable a ${missingNotification?.toString()} notification to update the profile.`
      );
    } else {
      dispatch(userSignup(formData)).then((e) => {
        dispatch(agencyInitialValueDetail(null));
        dispatch(AgencyProfilePristine(false));
        dispatch(agencyImageUpload(false));
        setuploadedFile(null);
        if (
          !e.payload.success &&
          e.payload?.data?.duplicateEmails?.length > 0
        ) {
          toast.error(
            `You can not add duplicate emails (${e.payload.data.duplicateEmails?.toString()}).`
          );
        } else {
          triggerTabChanges();
          toast.success(`Profile successfully updated.`);
          setUploadedImage(null);
          // setuploadedFile(null);
          setTabUpdateCheker(!tabUpdateCheker);

          // .then(() => {
          //   //   dispatch(getCurrentActiveUser(getUserId()?.userId));
          // });
        }
        setTimeout(() => {
          dispatch(
            getUserDetailsById(
              `${getUserId()?.userId}?${
                getUserDetailParams.secondaryEmails
              }=true&${getUserDetailParams.representativeEmails}=true`
            )
          );
        }, 100);
      });
    }
  }

  const agencyDetail = useSelector((e) => e.agencySlice.agencyDetial);

  const imageAgencyUpload = useCallback(() => {
    if (!uploadedImage) return;

    const formData = new FormData();
    formData.append("files", uploadedImage);
    formData.append(
      "profileData",
      JSON.stringify({
        email: agencyDetail?.email,
        id: agencyDetail?.id,
        name: agencyDetail?.name,
        user: "agency",
        isProfile: true,
        secondryEmail: agencyDetail?.secondaryEmails,
        representativeEmail: agencyDetail?.representativeEmails,
      })
    );

    dispatch(userSignup(formData))
      .then((response) => {
        if (response) {
          dispatch(agencyInitialValueDetail(null));
          dispatch(AgencyProfilePristine(false));
          dispatch(agencyImageUpload(false));
          triggerTabChanges();
          toast.success("Profile successfully updated.");
          setTabUpdateCheker(!tabUpdateCheker);
          setUploadedImage(null);

          if (Tabkey === profileTabs.credentials) {
            dispatch(getUserDetailsById(getUserId()?.userId));
          }
          if (Tabkey === profileTabs.blocklist) {
            dispatch(getUserDetailsById(getUserId()?.userId));
          }
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, [uploadedImage, dispatch, selectedTabState, Tabkey, agencyDetail]);

  useEffect(() => {
    if (agencyImage && !calledOnceRef.current && triggerUpdateState) {
      imageAgencyUpload();
      calledOnceRef.current = true;
      // dispatch(triggerUpdateButton(false));
    }
  }, [calledOnceRef, agencyImage, triggerUpdateState]);

  useEffect(() => {
    calledOnceRef.current = false;
  }, [uploadedImage]);

  // useEffect(() => {
  //   if (uploadedImage && !isImageUploaded) {
  //     imageAgencyUpload();
  //     setIsImageUploaded(true);
  //   }
  // }, [uploadedImage, isImageUploaded, imageAgencyUpload]);

  // useEffect(() => {
  //   if (isImageUploaded) {
  //     setIsImageUploaded(false);
  //   }
  // }, [uploadedImage]);

  const handleChange = (e) => {
    if (e && e?.target?.files) {
      dispatch(agencyImageUpload(true));
      setUploadedImage(e.target.files[0]);
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleChangeFile = (e) => {
    const selectedFile = e.target.files[0];
    if (e && e?.target?.files) {
      setuploadedFile(e.target.files[0]);
    }
    if (selectedFile) {
      setFieldValue(selectedFile.name);
      dispatch(AgencyProfilePristine(true));
    } else {
      setFieldValue();
    }
  };

  useEffect(() => {
    if (PrefillDetails && Tabkey === profileTabs.personalinfo) {
      dispatch(agencyDetailForPic(PrefillDetails)); // for pic detail
    }
  }, [PrefillDetails, profileTabs.personalinfo]);

  useEffect(() => {
    if (Tabkey === profileTabs.personalinfo) {
      dispatch(
        getUserDetailsById(
          `${getUserId()?.userId}?${getUserDetailParams.secondaryEmails}=true&${
            getUserDetailParams.representativeEmails
          }=true`
        )
      ).then(() => {
        dispatch(getCurrentActiveUser(getUserId()?.userId));
      });
    } else if (Tabkey === profileTabs.paymentinfo) {
      dispatch(
        getUserDetailsById(
          `${getUserId()?.userId}?${getUserDetailParams.paymentDetail}=true`
        )
      );
    } else if (Tabkey === profileTabs.defaultrate) {
      dispatch(getUserDetailsById(getUserId()?.userId));
      if (currentRole.agency) {
        dispatch(getDefaultRates(currentRole.agency));
      }
    }
    if (
      params &&
      params.tabs &&
      profileTabs[params.tabs] &&
      profileTabs[params.tabs] !== "password"
    ) {
      setTabkey(params.tabs);
    }
  }, [dispatch, Tabkey, tabUpdateCheker]);

  const validateForm = (values) => {
    var regex = passwordRegex;
    // const phone = /^-?\d+\.?\d*$/;
    const emailRegex = /^\S+@\S+\.\S+$/;
    let errors = {};
    if (!values || !values.name) {
      errors["name"] = "Username is required.";
    }
    if (!values || !values.email) {
      errors["email"] = "Email is required.";
    }
    if (!values || values.email) {
      if (!emailRegex.test(values.email)) {
        errors["email"] = "Please enter a valid email address.";
      }
    }
    if (values && values.password) {
      if (!regex.test(values.password)) {
        errors["password"] =
          "Password strength/length should be at least 8 characters and maximum 64 characters and contain an uppercase, a lowercase, a special character, and a digit.";
      }
    }

    if (!values || !values.address) {
      errors["address"] = "Street is required.";
    } else if (values.address.length === 0) {
      errors["address"] = "Street is required.";
    }
    if (!values || !values.state) {
      errors["state"] = "State is required.";
    }
    if (!values || !values.city) {
      errors["city"] = "City is required.";
    }
    if (!values || !values.zip) {
      errors["zip"] = "Zip is required.";
    }
    if (!values.phone) {
      errors["phone"] = "Phone number is required.";
    } else if (values?.phone && values?.phone.match(/\d/g)?.length !== 10) {
      errors["phone"] = "Please enter a 10 digit number.";
    }
    if (!values.fax) {
      errors["fax"] = "Fax is required.";
    } else if (values?.fax && values?.fax.match(/\d/g)?.length !== 10) {
      errors["fax"] = "Please enter a 10 digit number.";
    }
    if (!values || !values.representative) {
      errors["representative"] = "Agency Representative is required.";
    }

    if (!values || !values.representativePhoneNo) {
      errors["representativePhoneNo"] =
        "Agency Representative Phone number is required.";
    } else if (
      values?.representativePhoneNo &&
      values?.representativePhoneNo.match(/\d/g)?.length !== 10
    ) {
      errors["representativePhoneNo"] = "Please enter a 10 digit number.";
    }

    let emails = [];

    if (values?.email) {
      emails?.push({ email: values.email });
    }
    let missingNotification = [];
    if (values && values.secondryEmail && values.secondryEmail.length > 0) {
      let accounting = 0,
        intakeStaffing = 0,
        caseManagement = 0;
      values?.secondryEmail.forEach((item) => {
        if (item.email) {
          if (
            !values?.representativeEmail[0]?.accounting &&
            !item?.accounting
          ) {
            accounting++;
          }
          if (
            !values?.representativeEmail[0]?.intakeStaffing &&
            !item?.intakeStaffing
          ) {
            intakeStaffing++;
          }
          if (
            !values?.representativeEmail[0]?.caseManagement &&
            !item?.caseManagement
          ) {
            caseManagement++;
          }
        } else {
          if (!values?.representativeEmail[0]?.accounting) {
            accounting++;
          }
          if (!values?.representativeEmail[0]?.intakeStaffing) {
            intakeStaffing++;
          }
          if (!values?.representativeEmail[0]?.caseManagement) {
            caseManagement++;
          }
        }
      });
      let emailslength = values?.secondryEmail?.length;
      if (
        accounting === emailslength ||
        intakeStaffing === emailslength ||
        caseManagement === emailslength
      ) {
        if (accounting === emailslength) {
          missingNotification.push("accounting");
        }
        if (intakeStaffing === emailslength) {
          missingNotification.push("intakeStaffing");
        }
        if (caseManagement === emailslength) {
          missingNotification.push("caseManagement");
        }
      }
      values?.secondryEmail?.map((obj) => {
        if (obj?.email) {
          emails?.push({ email: obj?.email, id: obj.id });
        }
        return obj;
      });
    }
    var isDuplicate = emails.some(function (item, idx) {
      return (
        emails.findIndex((object) => {
          return object.email === item.email;
        }) !== idx
      );
    });
    if (isDuplicate) {
      errors["duppreferencesError"] = "You can not add duplicate email.";
    }
    if (Tabkey === profileTabs.personalinfo && missingNotification.length > 0) {
      errors["preferencesError"] =
        "Each notification category should be assigned to at least one email address.";
    }
    dispatch(ProfileValidState(Object.keys(errors).length > 0 ? true : false));
    return errors;
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);

    if (windowWidth.width < 768) {
      setWindowWidth("100%");
    }

    if (windowWidth.width > 768) {
      setWindowWidth("33.3333333333%");
    }

    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
      width,
    };
  }

  const handleShowHidePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const initialValues = () => {
    const myInitialValues = {
      userConsentName: PrefillDetails && PrefillDetails?.agencyConsent,
      name: PrefillDetails && PrefillDetails.name,
      email: PrefillDetails && PrefillDetails.email,
      phone: PrefillDetails && PrefillDetails?.userDetail?.phone,
      fax: PrefillDetails && PrefillDetails.userDetail?.fax,
      representative:
        PrefillDetails && PrefillDetails?.userDetail?.representative,
      representativePhoneNo:
        PrefillDetails && PrefillDetails.userDetail?.representativePhoneNo,
      ext: PrefillDetails && PrefillDetails.userDetail?.ext,
      state: PrefillDetails && PrefillDetails.userDetail?.state,
      address: PrefillDetails && PrefillDetails.userDetail?.address,
      city: PrefillDetails && PrefillDetails.userDetail?.city,
      aptUnit: PrefillDetails && PrefillDetails.userDetail?.aptUnit,
      zip: PrefillDetails && PrefillDetails.userDetail?.zip,
      twoFactorEnabled: true,
      secondryEmail:
        PrefillDetails &&
        PrefillDetails.secondaryEmails &&
        PrefillDetails.secondaryEmails.length > 0
          ? PrefillDetails.secondaryEmails
          : [
              {
                email: "",
                userId: 0,
                intakeStaffing: false,
                caseManagement: false,
                accounting: false,
              },
            ],
    };
    if (
      PrefillDetails &&
      PrefillDetails.representativeEmails &&
      PrefillDetails.representativeEmails.length > 0
    ) {
      PrefillDetails?.representativeEmails?.map((item) => {
        myInitialValues.representativeEmail = [
          {
            id: item?.id,
            userId: item?.userId,
            intakeStaffing: item?.intakeStaffing,
            caseManagement: item?.caseManagement,
            accounting: item?.accounting,
            email: item?.email,
          },
        ];
      });
    } else {
      myInitialValues.representativeEmail = [
        {
          id: 0,
          email: "",
          intakeStaffing: true,
          caseManagement: true,
          accounting: true,
        },
      ];
    }
    if (Object.values(myInitialValues)[2]) {
      dispatch(agencyInitialValueDetail(myInitialValues));
    }

    return myInitialValues;
  };

  const selectResult = (result, setValue) => {
    if (result) {
      setValue("state", result.state);
      setValue("address", result.address);
      setValue("street", result.street);
      setValue("country", result.country);
      setValue("city", result.city);
      // setValue("zip", result.postal_code);
      setValue("zip", formatPinCodeValue(result.postal_code));

      setValue("latitude", result.latitude);
      setValue("longitude", result.longitude);
      setValue("addressDetails", result?.addressDetails);
    }
  };

  const handleDisableNot = (secEmail, index, onChange) => {
    if (PrefillDetails?.secondaryEmails?.length === 0) {
      const findData = secEmail?.find((item, ind) => ind === index);
      if (!findData?.email) {
        if (findData && onChange) onChange(false);
        return findData;
      }
    }
  };

  const handleDocumentShow = (item) => {
    let userConsentName = PrefillDetails && PrefillDetails?.agencyConsent;
    if (PrefillDetails && PrefillDetails?.isConsent && userConsentName) {
      const url = `${apiBasePath}documents/agencyConsent/${PrefillDetails?.agencyConsent}`;
      window.open(url, "_blank").focus();
    } else {
      toast.warn("There is no document to show!");
    }
  };

  useEffect(() => {
    if (triggerUpdateState) {
      if (Tabkey === profileTabs.personalinfo) buttonRef.current.click();
      if (Tabkey === profileTabs.credentials) {
        pristineCheck(true);
        docbuttonRef.current.click();
      }
      dispatch(triggerUpdateButton(false));
    }
  }, [triggerUpdateState]);
  const pristineCheck = (pristine) => {
    dispatch(setPristine({ state: pristine }));
  };

  const handleTab = () => {
    setuploadedFile(null);
    setFieldValue(null);
    setWarningPrompt(false);
    setTabkey(selectedTabState);
    pristineCheck(true);
    dispatch(AgencyProfilePristine(false));
    dispatch(agencyImageUpload(false));
  };

  const triggerSaveChanges = () => {
    setWarningPrompt(false);
    dispatch(triggerUpdateButton(true));
    // setTabkey(`${selectedTabState}`);
  };

  const triggerTabChanges = () => {
    if (selectedTabState) {
      setTabkey(`${selectedTabState}`);
    }
  };

  const AgencyDefaultRatesVal = (val) => {
    if (PrefillDetails && PrefillDetails.userRates && val) {
      const initialRates = PrefillDetails.userRates
        .map((item) => (item.price ? item.price : ""))
        .sort();
      const currentRates = Object.values(val)
        .flatMap((therapy) => {
          if (Array.isArray(therapy)) {
            return therapy.map((service) =>
              service.rate ? Number(service.rate) : ""
            );
          }
          return [];
        })
        .sort();
      if (JSON.stringify(initialRates) !== JSON.stringify(currentRates)) {
        dispatch(AgencyProfilePristine(true));
        return setData(true);
      }
    }
    dispatch(AgencyProfilePristine(false));
    return setData(false);
  };

  const booleanKeys = ["intakeStaffing", "caseManagement", "accounting"];

  const checkValuesChanged = (currentValues, type) => {
    if (type === "secondaryEmailLengthCheck") {
      if (
        currentValues?.secondryEmail?.length !==
        InitialAgencyProfileValue?.secondryEmail?.length
      ) {
        dispatch(AgencyProfilePristine(true));
        return true;
      }
    }

    var validCheck = false;
    if (currentValues && InitialAgencyProfileValue) {
      for (let key in InitialAgencyProfileValue) {
        if (!InitialAgencyProfileValue.hasOwnProperty(key)) continue;

        // let val1 = currentValues[key];
        // let val = InitialAgencyProfileValue[key];

        let val1 = currentValues.hasOwnProperty(key)
          ? currentValues[key]
          : null;
        let val = InitialAgencyProfileValue.hasOwnProperty(key)
          ? InitialAgencyProfileValue[key]
          : null;

        // if (!currentValues.hasOwnProperty('aptUnit') && currentValues['aptUnit'] !== InitialAgencyProfileValue['aptUnit']) {
        //   return false;
        // }

        if (Array.isArray(val)) {
          let arr1 = currentValues[key];
          let arr2 = InitialAgencyProfileValue[key];

          let result = compareArrays(arr1, arr2);
          validCheck = result;
          dispatch(AgencyProfilePristine(validCheck));
          if (validCheck) {
            break;
          }
        } else {
          if (val1 !== val) {
            validCheck = true;
            // dispatch(AgencyProfilePristine(validCheck));
            if (validCheck) {
              dispatch(AgencyProfilePristine(validCheck));
              break;
            }
          }
        }
      }
    }
    dispatch(AgencyProfilePristine(validCheck));
    return validCheck;
  };

  function compareArrays(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      dispatch(AgencyProfilePristine(true));
      return true;
    }

    for (let i = 0; i < arr1.length; i++) {
      let obj1 = arr1[i];
      let obj2 = arr2[i];
      if (obj1.email) {
        obj2 = arr2.find((item) => item.email === obj1.email);
      }
      if (!obj2) {
        dispatch(AgencyProfilePristine(true));
        return true;
      }

      for (let key2 in obj2) {
        if (booleanKeys.indexOf(key2) > -1) {
          if (obj1[key2] == undefined) {
          } else if (obj1[key2] !== obj2[key2]) {
            dispatch(AgencyProfilePristine(true));
            return true;
          }
        } else if (!obj1.hasOwnProperty(key2) && obj2[key2]) {
          // if (obj1[key2] == undefined) {
          //   dispatch(AgencyProfilePristine(false));
          //   return false;
          // } else
          if (obj1[key2] == undefined && key2 === "email") {
            if (obj2[key2] && key2 === "email") {
              dispatch(AgencyProfilePristine(true));
              return true;
            }
          } else if (obj1[key2] !== obj2[key2] && key2 === "email") {
            dispatch(AgencyProfilePristine(true));
            return true;
          }
        } else if (
          !obj1.hasOwnProperty(key2) &&
          !obj2[key2] &&
          key2 === "email"
        ) {
          dispatch(AgencyProfilePristine(false));
          return false;
        } else if (obj1[key2] !== obj2[key2] && key2 === "email") {
          if (obj1[key2] == undefined) {
          } else {
            dispatch(AgencyProfilePristine(true));
            return true;
          }
        }
      }
    }

    dispatch(AgencyProfilePristine(false));
    return false;
  }

  const handleTabSelect = (k, values, initialValues, pristine, valid) => {
    const valuesChanged = checkValuesChanged(values);
    if (!valid) {
      setWarningPrompt(true);
      setSelectedTabState(k);
    } else if (Tabkey === profileTabs.credentials && !pristineSlice) {
      setWarningPrompt(true);
      setSelectedTabState(k);
    } else if (valuesChanged) {
      setWarningPrompt(true);
      setSelectedTabState(k);
    } else if (Tabkey === profileTabs.defaultrate && data) {
      setWarningPrompt(true);
      setSelectedTabState(k);
    } else if (agencyImage) {
      setWarningPrompt(true);
      setSelectedTabState(k);
    } else {
      setSelectedTabState(k);
      setTabkey(`${k}`);
      pristineCheck(true);
    }
  };

  const isSecondaryEmail = (secondaryEmailsArray, input, onChange) => {
    if (PrefillDetails?.secondaryEmails?.length === 0) {
      let isExist = secondaryEmailsArray.find((item) => item.email);
      if (!isExist) onChange(true);
      return isExist ? false : true;
    }
  };

  let notCheck = ["secondryEmail"];
  const checkDirtyFields = (dirtyFields, values, pristine) => {
    let value = pristine;
    if (!pristine && dirtyFields && Object.keys(values).length > 0) {
      let every = Object.keys(dirtyFields).every((ele) =>
        notCheck.includes(ele)
      );
      value = every;
    }
    return value;
  };

  return (
    <div className="agemcymainBody">
      <Form
        onSubmit={handleSubmit}
        validate={validateForm}
        mutators={{
          ...arrayMutators,
        }}
        keepDirtyOnReinitialize
        initialValues={useMemo(
          (e) => initialValues(e),
          [PrefillDetails, warningPrompt, agencyCheck]
        )}
        render={({
          initialValues,
          values,
          handleSubmit,
          submitting,
          errors,
          touched,
          form: {
            mutators: { push },
          },
          pristine,
          form,
          valid,
          dirtyFields,
          submitFailed,
        }) => (
          <>
            <div className="Container-fluid">
              <div className="top-box-profil pl-5 pr-5 pt-5">
                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="my-profile1 m-0">My Profile</h1>
                </div>
                <div className="row align-items-center justify-content-center">
                  <div className=" col-xl-4 Pmargin-top-b">
                    <div className="d-flex align-items-center gap-3 mobile_wrap mobile_justify">
                      <img
                        style={{ objectFit: "cover" }}
                        className="profile-icon my-profile"
                        width={"100px"}
                        src={
                          !uploadedImage
                            ? PrefillDetails && PrefillDetails?.image
                              ? `${apiBasePath}documents/therapist/${PrefillDetails?.image}`
                              : file
                            : file
                        }
                        crossOrigin="anonymous"
                        alt="profile-icon"
                      />
                      <div className="flex-column mobile_flex">
                        {PrefillDetails && (
                          <h6 className="agency-name-p">
                            {PrefillDetails?.name}
                          </h6>
                        )}
                        <p className="upload-P m-0">Upload Photo</p>
                        <label className="browse-f">
                          Browse files...{" "}
                          <ImAttachment className="cursor-pointer" />
                          <input
                            type="file"
                            onChange={handleChange}
                            ref={calledOnceRef}
                            hidden
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className=" col-xl-8 mb-3">
                    <div className="d-flex gap-3  justify-content-end mobile_wrap">
                      <div className=" gap-3  align-items-end mobile_wrap">
                        <div>
                          <FieldLabel className="lableField">
                            Email Address
                          </FieldLabel>
                          <p>{PrefillDetails && PrefillDetails.email}</p>
                        </div>
                        <div>
                          <FieldLabel className="lableField">
                            Password
                          </FieldLabel>
                          <p className="m-0">*****************</p>
                        </div>
                      </div>
                      <div className="d-flex  mobile_width gap-3 mb-2 align-items-end">
                        <img
                          src={editIcon}
                          className="cursor-pointer"
                          height={"28px"}
                          width={"28px"}
                          onClick={() => setTabkey("password")}
                        />
                      </div>
                      <div
                        className="mobile_width d-flex align-items-end"
                        style={{ width: "33.3333333333%" }}
                      >
                        <button
                          type="submit"
                          className="update-btn cursor-not-allowed"
                          style={{ width: "200px" }}
                          disabled={true}
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="35"
                            width="35"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill="none"
                              stroke-miterlimit="10"
                              stroke-width="32"
                              d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                            ></path>
                            <path
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="32"
                              d="M351.82 271.87v-16A96.15 96.15 0 00184.09 192m-24.2 48.17v16A96.22 96.22 0 00327.81 320"
                            ></path>
                            <path
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="32"
                              d="M135.87 256l23.59-23.6 24.67 23.6m192 0l-23.59 23.6-24.67-23.6"
                            ></path>
                          </svg>{" "}
                          2FA Enabled
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-4">
              <Tabs
                id="controlled-tab-example"
                className=" tabs_unfinish tabs-Disable border-0"
                activeKey={Tabkey}
                onSelect={(k) =>
                  handleTabSelect(k, values, initialValues, pristine, valid)
                }
              >
                <Tab
                  eventKey={profileTabs.personalinfo}
                  title={
                    <span className="d-flex  align-items-center gap-3 ">
                      <i className={`icon_email `}>
                        <img
                          src={
                            Tabkey === profileTabs.personalinfo
                              ? UserProfilesIcon
                              : UserProfilesIconActive
                          }
                          alt=""
                          srcset=""
                        />
                      </i>
                      Agency Info
                    </span>
                  }
                >
                  <>
                    {isLoading && <Loader />}
                    {Tabkey === profileTabs.personalinfo && (
                      <form
                        onSubmit={(e) => {
                          if (!valid)
                            toast.error("Please complete all required fields.");
                          handleSubmit(e);
                        }}
                      >
                        {/* {isProfile && pristineCheck(pristine)} */}
                        {isProfile &&
                          pristineCheck(
                            checkDirtyFields(dirtyFields, values, pristine)
                          )}

                        <div className="container-fluid pl-5 pr-5 ">
                          <Row>
                            <Col md={4} className="mb-3 black_input_group">
                              <Field name="address">
                                {({ input, meta }) => (
                                  <div>
                                    <label className="Profile-lable ">
                                      Street
                                    </label>
                                    <AddressAutocomplete
                                      // {...input}
                                      className="cs-input Profile-text py-input py-input-ca p-0 border-0 mb-3 cs-input mb-3 street_color_black ref-height-6"
                                      selectResult={(result) =>
                                        selectResult(result, form.change)
                                      }
                                      selectedAddress={values.address}
                                    />
                                    {meta.error && meta.touched && (
                                      <p
                                        className="alert alert-danger top-11"
                                        role="alert"
                                      >
                                        {meta.error}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Col>
                            <Col md={4} className="mb-3">
                              <Field name="aptUnit">
                                {({ input, meta }) => (
                                  <div>
                                    <label className="Profile-lable">
                                      Suite/Apartment (if applicable)
                                    </label>
                                    <input
                                      className="cs-input Profile-text mb-3 ref-height-6"
                                      {...input}
                                      type="text"
                                      placeholder="Suite/Apartment (if applicable)"
                                    />
                                    {meta.error && meta.touched && (
                                      <p
                                        className="alert alert-danger"
                                        role="alert"
                                      >
                                        {meta.error}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Col>
                            <Col md={4} className="mb-3">
                              <Field name="city">
                                {({ input, meta }) => (
                                  <div>
                                    <label className="Profile-lable">
                                      City
                                    </label>
                                    <input
                                      className="cs-input Profile-text mb-3 ref-height-6"
                                      {...input}
                                      type="text"
                                      placeholder="City"
                                    />
                                    <br />
                                    {meta.error && meta.touched && (
                                      <p
                                        className="alert alert-danger top-11"
                                        role="alert"
                                      >
                                        {meta.error}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4} className="mb-3">
                              <label className="Profile-lable">State</label>
                              <Field name="state">
                                {({ input, meta }) => (
                                  <div>
                                    <input
                                      className="cs-input Profile-text mb-3 ref-height-6"
                                      {...input}
                                      type="text"
                                      placeholder="State"
                                    />
                                    {meta.error && meta.touched && (
                                      <p
                                        className="alert alert-danger top-11"
                                        role="alert"
                                      >
                                        {meta.error}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Col>
                            <Col md={4} className="mb-3">
                              <Field classNamed="d-none" name="zip">
                                {({ input, meta }) => (
                                  <div>
                                    <label className="Profile-lable">
                                      Zip Code
                                    </label>
                                    <input
                                      className="cs-input Profile-text mb-3 ref-height-6"
                                      {...input}
                                      type="text"
                                      maxLength={"11"}
                                      onInput={(e) => formatPinCode(e, input)}
                                      placeholder="Zip"
                                    />
                                    {meta.error && meta.touched && (
                                      <p
                                        className="alert alert-danger top-11"
                                        role="alert"
                                      >
                                        {meta.error}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Col>
                            <Col md={4} className="mb-3">
                              <Field classNamed="d-none" name="phone">
                                {({ input, meta }) => (
                                  <div>
                                    <label className="Profile-lable">
                                      Phone
                                    </label>
                                    <input
                                      className="cs-input Profile-text mb-3 ref-height-6"
                                      {...input}
                                      type="text"
                                      onInput={(e) =>
                                        phoneNumberToUSFormat(e, input)
                                      }
                                      placeholder="phone"
                                    />
                                    {meta.error && meta.touched && (
                                      <p
                                        className="alert alert-danger"
                                        role="alert"
                                      >
                                        {meta.error}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4} className="mb-3">
                              <Field classNamed="d-none" name="fax">
                                {({ input, meta }) => (
                                  <div>
                                    <label className="Profile-lable">FAX</label>
                                    <input
                                      className="cs-input Profile-text mb-3 ref-height-6"
                                      {...input}
                                      type="text"
                                      onInput={(e) =>
                                        phoneNumberToUSFormat(e, input)
                                      }
                                      placeholder="Fax"
                                    />
                                    {meta.error && meta.touched && (
                                      <p
                                        className="alert alert-danger"
                                        role="alert"
                                      >
                                        {meta.error}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4} className="mb-3">
                              <Field name="representative">
                                {({ input, meta }) => (
                                  <div>
                                    <label className="Profile-lable">
                                      Agency Representative
                                    </label>
                                    <input
                                      className="cs-input Profile-text mb-3 ref-height-6"
                                      {...input}
                                      type="text"
                                      placeholder="Agency Representative"
                                    />
                                    <br />
                                    {meta.error && meta.touched && (
                                      <p
                                        className="alert alert-danger"
                                        role="alert"
                                      >
                                        {meta.error}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Col>
                            <Col md={4} className="mb-3">
                              <Field name="representativePhoneNo">
                                {({ input, meta }) => (
                                  <div>
                                    <label className="Profile-lable">
                                      Agency Representative Phone
                                    </label>
                                    <input
                                      className="cs-input Profile-text mb-3 ref-height-6"
                                      {...input}
                                      type="text"
                                      onInput={(e) =>
                                        phoneNumberToUSFormat(e, input)
                                      }
                                      placeholder="Agency Representative Phone"
                                    />
                                    <br />
                                    {meta.error && meta.touched && (
                                      <p
                                        className="alert alert-danger"
                                        role="alert"
                                      >
                                        {meta.error}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Col>
                          </Row>
                          <div
                            style={{
                              width: "100%",
                              marginTop: 52,
                              display: "flex",
                              justifyContent: "center",
                              height: 1,
                              background: "lightgray",
                            }}
                          ></div>
                          <div className="superParent">
                            <div className="d-flex gap-3 px-0 align-items-center">
                              <h5
                                className="cs-subhead mt-5 mb-4 px-0"
                                style={{ fontSize: 22 }}
                              >
                                Notification Preferences
                              </h5>
                              {((submitFailed && errors.preferencesError) ||
                                (submitFailed &&
                                  errors.duppreferencesError)) && (
                                <p className="alert alert-danger">
                                  {errors.preferencesError ||
                                    errors.duppreferencesError}
                                </p>
                              )}
                            </div>

                            <div className="px-0">
                              <div className="col-xl-12 col-lg-12 col-md-12 p-0">
                                <FieldArray name="representativeEmail">
                                  {({ fields }) =>
                                    fields.map((name, index) => (
                                      <div
                                        className="d-flex align-items-center"
                                        key={name}
                                      >
                                        <div className="col-md-12 mb-3 p-0">
                                          <div className="d-flex gap-5 align-items-end">
                                            <Field name={`${name}.email`}>
                                              {({ input, meta }) => (
                                                <div style={{ width: "38%" }}>
                                                  <input
                                                    className="cs-input  "
                                                    {...input}
                                                    type="email"
                                                    placeholder="Agency Representative Email Address"
                                                    value={
                                                      values && values?.email
                                                        ? values?.email
                                                        : ""
                                                    }
                                                    disabled
                                                  />

                                                  {meta.error &&
                                                    meta.touched && (
                                                      <p
                                                        className="alert alert-danger top-11"
                                                        role="alert"
                                                      >
                                                        {meta.error}
                                                      </p>
                                                    )}
                                                </div>
                                              )}
                                            </Field>

                                            <Field
                                              name={`${name}.intakeStaffing`}
                                            >
                                              {({ input }) => (
                                                <div className="d-flex flex-column w-25">
                                                  <p className="ca-lable mb-4 ">
                                                    Intake & Staffing
                                                  </p>
                                                  <Rswitch
                                                    {...input}
                                                    disabled={isSecondaryEmail(
                                                      values?.secondryEmail,
                                                      index,
                                                      input.onChange
                                                    )}
                                                  />
                                                </div>
                                              )}
                                            </Field>

                                            <Field
                                              name={`${name}.caseManagement`}
                                            >
                                              {({ input }) => (
                                                <div className="d-flex flex-column w-25">
                                                  <p className="ca-lable mb-4  ">
                                                    Case Management
                                                  </p>
                                                  <Rswitch
                                                    {...input}
                                                    disabled={isSecondaryEmail(
                                                      values?.secondryEmail,
                                                      index,
                                                      input.onChange
                                                    )}
                                                  />
                                                </div>
                                              )}
                                            </Field>

                                            <Field name={`${name}.accounting`}>
                                              {({ input }) => (
                                                <div className="d-flex flex-column w-25">
                                                  <p className="ca-lable mb-4  ">
                                                    Accounting
                                                  </p>
                                                  <Rswitch
                                                    {...input}
                                                    disabled={isSecondaryEmail(
                                                      values?.secondryEmail,
                                                      index,
                                                      input.onChange
                                                    )}
                                                  />
                                                </div>
                                              )}
                                            </Field>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  }
                                </FieldArray>
                                {values &&
                                  checkValuesChanged(
                                    values,
                                    "secondaryEmailLengthCheck"
                                  )}

                                <FieldArray name="secondryEmail">
                                  {({ fields }) =>
                                    fields.map((name, index) => (
                                      <div
                                        className=" d-flex align-items-center"
                                        key={name}
                                      >
                                        <div className="col-md-12 mb-3 p-0 ">
                                          <div className="d-flex gap-5 align-items-end position-relative">
                                            <Field name={`${name}.email`}>
                                              {({ input, meta }) => (
                                                <div style={{ width: "38%" }}>
                                                  <input
                                                    className="cs-input  "
                                                    {...input}
                                                    type="email"
                                                    autoComplete="true"
                                                    placeholder={`Secondary Email Address ${
                                                      index + 1
                                                    }`}
                                                  />

                                                  {meta.error &&
                                                    meta.touched && (
                                                      <p
                                                        className="alert alert-danger top-11"
                                                        role="alert"
                                                      >
                                                        {meta.error}
                                                      </p>
                                                    )}
                                                </div>
                                              )}
                                            </Field>

                                            <Field
                                              name={`${name}.intakeStaffing`}
                                            >
                                              {({ input }) => (
                                                <div className="d-flex flex-column w-25">
                                                  {input.name ===
                                                    "secondryEmail[0].intakeStaffing" && (
                                                    <p className="ca-lable mb-4  ">
                                                      Intake & Staffing
                                                    </p>
                                                  )}
                                                  <div>
                                                    <Rswitch
                                                      {...input}
                                                      disabled={handleDisableNot(
                                                        values?.secondryEmail,
                                                        index,
                                                        input.onChange
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                            </Field>

                                            <Field
                                              name={`${name}.caseManagement`}
                                            >
                                              {({ input }) => (
                                                <div className="d-flex flex-column w-25">
                                                  {input.name ===
                                                    "secondryEmail[0].caseManagement" && (
                                                    <p className="ca-lable mb-4  ">
                                                      Case Management
                                                    </p>
                                                  )}

                                                  <Rswitch
                                                    {...input}
                                                    disabled={handleDisableNot(
                                                      values?.secondryEmail,
                                                      index,
                                                      input.onChange
                                                    )}
                                                  />
                                                </div>
                                              )}
                                            </Field>

                                            <Field name={`${name}.accounting`}>
                                              {({ input }) => (
                                                <div className="d-flex flex-column w-25">
                                                  {input.name ===
                                                    "secondryEmail[0].accounting" && (
                                                    <p className="ca-lable mb-4  ">
                                                      Accounting
                                                    </p>
                                                  )}

                                                  <Rswitch
                                                    {...input}
                                                    disabled={handleDisableNot(
                                                      values?.secondryEmail,
                                                      index,
                                                      input.onChange
                                                    )}
                                                  />
                                                </div>
                                              )}
                                            </Field>
                                            {index > 0 && (
                                              <div
                                                className="box-icon position-absolute delet-iconagency "
                                                onClick={() => {
                                                  fields.remove(index);
                                                }}
                                              >
                                                <HiMinus className="Hiplus" />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  }
                                </FieldArray>

                                <div className="d-flex">
                                  <div className="box-icon">
                                    <HiPlus
                                      className="Hiplus"
                                      onClick={() =>
                                        push("secondryEmail", {
                                          email: "",
                                        })
                                      }
                                    />
                                  </div>
                                  <button
                                    className="cs-addbtn"
                                    type="button"
                                    onClick={() =>
                                      push("secondryEmail", {
                                        email: "",
                                      })
                                    }
                                  >
                                    Add secondary email
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <button onClick={handlePtEvaluation}>
                        PT_Evaluation_Form
                      </button> */}
                          {/* <Row>
                        <SignatureCanvas />
                      </Row> */}

                          <Row>
                            <Col md={12}>
                              <div className="d-flex justify-content-between align-items-center">
                                <button
                                  ref={buttonRef}
                                  className={`update-btn mt-5 mb-5 ${
                                    !agencyCheck ? "opacity-50" : ""
                                  }`}
                                  // onClick={handleSubmit}
                                  type="submit"
                                  disabled={submitting || !agencyCheck}
                                >
                                  <IoSyncCircleOutline
                                    size={35}
                                    className="icons-update"
                                  />
                                  Update
                                </button>

                                <div>{/* <BsChatLeftText size={35} /> */}</div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </form>
                    )}
                  </>
                </Tab>
                <Tab
                  eventKey={profileTabs.paymentinfo}
                  title={
                    <span className="d-flex  align-items-center gap-3 ">
                      {/* <i
                      className={`icon_email ${staffTabDetails && staffTabDetails.isStaffingPending
                        ? "Bubble"
                        : null
                        }`}
                    > */}
                      <img
                        src={
                          Tabkey === profileTabs.paymentinfo
                            ? PaymentInfoActive
                            : PaymentProfileIcon
                        }
                        alt=""
                        srcset=""
                      />
                      {/* </i> */}
                      Payment Info
                    </span>
                  }
                >
                  <>
                    {isLoading && <Loader />}
                    {Tabkey === profileTabs.paymentinfo && (
                      <div className="pl-5 pr-5 mb-3">
                        <Paymentdetails
                          triggerTabChanges={triggerTabChanges}
                          pristineCheck={pristineCheck}
                          isProfile={isProfile}
                          setTabUpdateCheker={() =>
                            setTabUpdateCheker(!tabUpdateCheker)
                          }
                          imageAgencyUpload={imageAgencyUpload}
                          uploadedImage={uploadedImage}
                        />
                      </div>
                    )}
                  </>
                </Tab>
                <Tab
                  eventKey={profileTabs.defaultrate}
                  title={
                    <span className="d-flex  align-items-center gap-3 ">
                      {/* <i
                      className={`icon_email ${staffTabDetails && staffTabDetails.isStaffingPending
                        ? "Bubble"
                        : null
                        }`}
                    > */}
                      <img
                        src={
                          Tabkey === profileTabs.defaultrate
                            ? DefaultRateIconActive
                            : DefaultRateIcon
                        }
                        alt=""
                        srcset=""
                      />
                      {/* </i> */}
                      Default Rates
                    </span>
                  }
                >
                  <>
                    {isLoading && <Loader />}
                    {Tabkey === profileTabs.defaultrate && (
                      <div className="pl-5 pr-5 mb-3">
                        <AgencyDefaultRates
                          triggerTabChanges={triggerTabChanges}
                          pristineCheck={pristineCheck}
                          AgencyDefaultRatesVal={AgencyDefaultRatesVal}
                          isProfile={isProfile}
                          setTabUpdateCheker={() =>
                            setTabUpdateCheker(!tabUpdateCheker)
                          }
                          imageAgencyUpload={imageAgencyUpload}
                          uploadedImage={uploadedImage}
                        />
                      </div>
                    )}
                  </>
                </Tab>
                <Tab
                  eventKey={profileTabs.credentials}
                  title={
                    <span className="d-flex  align-items-center gap-3 ">
                      {/* <i
                      className={`icon_email ${staffTabDetails && staffTabDetails.isStaffingPending
                        ? "Bubble"
                        : null
                        }`}
                    > */}
                      <img
                        src={
                          Tabkey === profileTabs.credentials
                            ? DocumentsActive
                            : DocumentsIcon
                        }
                        alt=""
                        srcset=""
                      />
                      {/* </i> */}
                      Documents
                    </span>
                  }
                >
                  <form
                    onSubmit={(e) => {
                      if (!valid)
                        toast.error("Please complete all required fields.2");
                      handleSubmit(e);
                    }}
                  >
                    {isLoading && <Loader />}
                    {Tabkey === profileTabs.credentials && (
                      <div className="pl-5 pr-5 mb-3">
                        {pristineCheck(uploadedFile ? false : true)}
                        {
                          <>
                            <div className="w-50">
                              <div className="M_Patinet">
                                <div className="Card-ditails">
                                  <img src={documentImgs} width="28" alt="" />
                                </div>
                                <div
                                  className="patientDoc"
                                  onClick={() => handleDocumentShow()}
                                  style={{ cursor: "pointer" }}
                                >
                                  <p className="m-0 license-ex">
                                    {PrefillDetails && PrefillDetails.isConsent
                                      ? PrefillDetails.agencyConsent
                                        ? "Consent Forms"
                                        : "No Consent Forms"
                                      : "No Consent Forms"}
                                  </p>
                                </div>
                              </div>
                              <div className="patientDoc">
                                <Field name={"userConsent"}>
                                  {({
                                    input: { onChange, ...input },
                                    meta,
                                  }) => (
                                    <div
                                      className=" file-upload-wrapper cred-date-input mobile_width-990"
                                      data-text={
                                        fieldValue
                                          ? fieldValue
                                          : !values.userConsent
                                          ? values.userConsentName
                                            ? values.userConsentName
                                            : "No file selected"
                                          : values.userConsent
                                      }
                                    >
                                      <input
                                        className="input-group w-100 cred-input file-upload-field cursor-pointer"
                                        id="fileUpload"
                                        name="file-upload-field"
                                        type="file"
                                        onChange={(e) => {
                                          handleChangeFile(e);
                                        }}
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          className={`alert alert-danger top-11 top-cred  `}
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </div>

                              <Row>
                                <Col md={12}>
                                  <div className="d-flex align-items-center">
                                    <button
                                      ref={docbuttonRef}
                                      type="submit"
                                      className={`update-btn mt-5 mr-5 mb-5 ${
                                        uploadedFile ? "" : "opacity-50"
                                      }`}
                                      // onClick={handleSubmit}
                                      disabled={!uploadedFile}
                                    >
                                      <IoSyncCircleOutline
                                        size={35}
                                        className="icons-update"
                                      />
                                      {values.userConsentName
                                        ? "Update"
                                        : "Upload"}
                                    </button>

                                    {values?.userConsentName ? (
                                      <Button
                                        type="submit"
                                        variant="outline-danger"
                                        className="update-btn mt-5 mb-5 rounded-5"
                                        onClick={() => handleDeleteFile(values)}
                                      >
                                        Delete
                                      </Button>
                                    ) : (
                                      ""
                                    )}

                                    <div>
                                      {/* <BsChatLeftText size={35} /> */}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </>
                        }
                      </div>
                    )}
                  </form>
                </Tab>
                <Tab
                  eventKey={profileTabs.blocklist}
                  title={
                    <span className="d-flex  align-items-center gap-3 ">
                      <img
                        src={
                          Tabkey === profileTabs.blocklist
                            ? BlockIconActive
                            : BlockIcon
                        }
                        alt=""
                        srcset=""
                      />
                      {/* </i> */}
                      Block-List
                    </span>
                  }
                >
                  {Tabkey === profileTabs.blocklist && (
                    <>
                      {pristineCheck(true)}
                      <BlockList />
                    </>
                  )}
                </Tab>
              </Tabs>
              {Tabkey === "password" && (
                <div className="pl-5 pr-5 mb-3">
                  <SetNewPassword
                    setTabkey={(key) => setTabkey(key)}
                    id={PrefillDetails?.id}
                  />
                </div>
              )}
            </div>
          </>
        )}
      />
      {warningPrompt && (
        <MyModal2
          size="lg"
          data={
            <WarningPromptModal
              handleTab={handleTab}
              handleClose={() => setWarningPrompt(false)}
              triggerSaveChanges={triggerSaveChanges}
            />
          }
          cross={true}
          outerClick={true}
          show={warningPrompt}
          handleClose={() => setWarningPrompt(false)}
          modalClass={"warning_prompt cross_warning"}
          centered={true}
        />
      )}
    </div>
  );
}

export default AgencyProfile;
