import React from "react";
import { Nav } from "react-bootstrap";

export const CommonHead = (props) => {
  function handleMenu(e) {
    props.setMenu(e);
    if (props?.setPageNo) {
      props?.setPageNo(1);
    }
  }
  return (
    <>
      <div className="commen2 mb-4">
        <Nav className="gap-3" variant="tabs" defaultActiveKey="/home">
          {props.data.map((e, i) => {
            return (
              <Nav.Item
                key={`${e.label}_${i}`}
                onClick={() => handleMenu(e.key)}
              >
                <Nav.Link
                  className={`menu_boxing ${
                    e.key === props.menuItem ? "active" : ""
                  }`}
                >
                  <span>
                    {e.count > 99
                      ? "99+"
                      : e?.count?.toString().length < 2
                      ? `0${e?.count}`
                      : e?.count}
                  </span>
                  <p> {e.label}</p>
                </Nav.Link>
              </Nav.Item>
            );
          })}
        </Nav>
      </div>
    </>
  );
};
