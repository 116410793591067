import React, { useMemo, useRef } from "react";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { setDocuSignEnvelope, therapistCredentials } from "../../Redux/actions/therapist";
import { useDispatch, useSelector } from "react-redux";
import { credentials, decodeToken, docuSignDocs, therapistSignupSteps, } from "../../utils/auth";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { setTherapistDataGetStep } from "../../Redux/actions/auth";
import { useState } from "react";
import { useEffect } from "react";
import { IoSyncCircleOutline } from "react-icons/io5";
import { triggerUpdateButton } from "../../Redux/slices/therapistSignupSlice";
import { dateFormatChange, todayDate, credentialsLabel } from "../../utils/helper";
import Dropdown from 'react-bootstrap/Dropdown';
import { BsChevronUp, BsChevronDown, BsX } from 'react-icons/bs';
const AddNewCredentials = (props) => {
    const buttonRef = useRef();
    const triggerUpdateState = useSelector((state) => state.therapistSigunpSlice.triggerUpdateFunction);
    useEffect(() => {
        if (triggerUpdateState && props?.isProfile) {
            buttonRef.current.click();
        }
    }, [triggerUpdateState])

    const dispatch = useDispatch();
    const [pageInitilize, setPageInitilize] = useState(false);
    const [credentialFiles, setCredentialFiles] = useState([]);
    const [credentialPayload, setCredentialPayload] = useState([]);
    const [uploadedFileBytes, setUploadedFileBytes] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [taxFormValues, setTaxFormValues] = useState()
    const [selectedFile, setSelectedFile] = useState('');
    const [selectedLicense, setSelectedLicense] = useState(props.inputPropsForAddNewTest?.name || "");
    const [addNewStatus, setAddnewStatus] = useState(props.inputPropsForAddNewTest?.isDisabled || false);
    const [isSubmitVisible, setIsSubmitVisible] = useState(false);
    const selectedLicenseLabel = credentialsLabel[selectedLicense] || 'Select Document Type';
    const [isFileUploaded, setIsFileUploaded] = useState(false)
    const isSubmitDisabled = !isFileUploaded;
    const dropdownRef = useRef(null);
    const fileInputRef = useRef(null);
    const handleLicenseChange = (license) => {
        setSelectedLicense(license);
    };

    useEffect(() => {
        setIsSubmitVisible(selectedLicense);
    }, [selectedLicense, addNewStatus]);

    function handleDateFocus(event) {
        event.target.type = "date";
    }
    const handleClearFile = () => {
        setSelectedFile(null);
        const fileInput = document.getElementById(`fileUpload-${selectedLicense}`);
        if (fileInput) {
            fileInput.value = "";
            const event = new Event("change", { bubbles: true });
            fileInput.dispatchEvent(event);
        }
        setIsFileUploaded(false);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    }

    function handleDateBlur(event) {
        if (!event.target.value) {
            event.target.type = "text";
        }
    }

    const PrefillDetails = useSelector((e) => e.therapistSigunpSlice.PrefillDetails);
    const [searchParams] = useSearchParams();
    let userDetail = null;

    if (searchParams && searchParams.get("auth")) {
        userDetail = decodeToken(searchParams.get("auth"));
    }

    if (PrefillDetails && props?.isProfile) {
        userDetail = { userId: PrefillDetails?.id, email: PrefillDetails?.email, firstName: PrefillDetails?.firstName, lastName: PrefillDetails?.lastName };
    }
    let today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = "0" + dd;
    }
    if (mm < 10) {
        mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;

    function handleSubmit(event) {
        var formData = new FormData();
        if (event && PrefillDetails && PrefillDetails?.email && event?.complianceExpiration) {
            dispatch(setDocuSignEnvelope({ types: docuSignDocs.compliance, complianceExpirationDate: event?.complianceExpiration, email: PrefillDetails?.email }))
        }

        if (event && PrefillDetails && PrefillDetails?.email && event?.competencyExpiration) {
            dispatch(setDocuSignEnvelope({ types: docuSignDocs.competency, competencyExpirationDate: event?.competencyExpiration, email: PrefillDetails?.email }));
        }

        if (credentialPayload?.length > 0) {
            credentialPayload?.forEach((item) => {
                if (item?.types === "compliance") {
                    delete item?.expirationDate;
                    delete item?.types;
                }
                if (item?.types === "competency") {
                    delete item?.expirationDate;
                    delete item?.types;
                }

                if (credentialFiles?.length > 0) {
                    credentialFiles?.forEach((fil) => {
                        if (item?.types === fil?.orgFileData?.type) {
                            item["name"] = fil?.orgFileData?.name;
                        }
                    });
                }
            });
        }
        for (var i = 0; i < credentialFiles.length; i++) {
            delete credentialFiles[i].orgFileData;
            formData.append("files", credentialFiles[i]);
        }
        formData.append("therapistData",
            JSON.stringify({
                values: credentialPayload, userId: PrefillDetails && PrefillDetails.id, isProfile: props?.isProfile && props?.isProfile,
                textData: JSON.stringify(taxFormValues),
                updateStep: true,
            })
        );
        let credentials = ["fluvaccination", "covidrecord", "hepatitisB"];
        let isDeclined = credentials.filter((item) => event[`is${item}`] && item);
        let isUploaded = true;
        isDeclined.forEach((item) => {
            let detail = PrefillDetails.userCredentials.find(
                (credential) => credential.types === item
            );
            if (detail && detail.isDeclination) {
                if (!detail.file) {
                    isUploaded = false;
                }
            } else {
                if (!uploadedFileBytes[item]) {
                    isUploaded = false;
                }
            }
        });

        if (isUploaded) {
            dispatch(therapistCredentials(formData)).then((res) => {
                dispatch(setTherapistDataGetStep(therapistSignupSteps[5]));
                if (res) {
                    props.setTabUpdateCheker()
                    toast.success("Profile updated successfully.");
                    props.handleClose();
                }
            });
        } else {
            toast.error("Please upload all declination forms.");
        }
    }

    const handleChange = (event, typename, values, form) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setIsFileUploaded(!!file); 
        }
        let fileList = [...credentialFiles];
        let payloadList = [...credentialPayload];
        let filedata = {
            types: typename,
        };
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        let oneYearPlusDate = date.toISOString().split("T")[0];

        if (values[`${typename}id`]) {
            filedata["id"] = values[`${typename}id`];
        }
        filedata["isDeclination"] = values[`is${typename}`] ? true : false;

        filedata["credentialsStatus"] = 'pendingreview';

        filedata["addNew"] = addNewStatus;

        filedata["rejectReason"] = '';

        if (event.target.type === "date") {
            filedata["expirationDate"] = event.target.value;
        } else {
            let temp = `${typename}_`;
            const filename = temp + new Date().getTime();

            if (event.target.files.length > 0 && event.target.files[0]) {
                const extension = event.target.files[0].name.split(".").pop();
                var allowedExtensions = /(\.heic|\.jpeg|\.png|\.pdf)$/i;
                if (!allowedExtensions.exec(`${filename}.${extension}`)) {
                    toast.error("Upload format only (heic, jpeg, png, pdf).");
                    return false;
                }
                let file = new File(event.target.files, `${filename}.${extension}`);

                if (values[`${typename}Expiration`]) {
                    filedata["expirationDate"] = values[`${typename}Expiration`];
                }
                if (file) {
                    file["orgFileData"] = {
                        name: event?.target?.files[0]?.name,
                        type: typename,
                        isDeclination: values[`is${typename}`] ? true : false,
                        credentialsStatus: 'pendingreview',
                        addNew: addNewStatus,
                    };
                }

                if (fileList.length === 0) {
                    fileList.push(file);
                } else {
                    fileList.map((ele, ind) => {
                        if (ele.name.includes(temp)) {
                            fileList.splice(ind, 1);
                        }
                    });
                    fileList.push(file);
                }
            }
        }
        if (typename === "fluvaccination" && values[`is${typename}`]) {
            filedata["expirationDate"] = oneYearPlusDate;
        }

        if (payloadList.length === 0) {
            payloadList.push(filedata);
        } else {
            payloadList.map((ele, ind) => {
                if (ele.types.includes(typename)) {
                    payloadList.splice(ind, 1);
                }
            });

            payloadList.push(filedata);
        }
        setCredentialFiles(fileList);
        setCredentialPayload(payloadList);
    };

    function convertDate(str) {
        if (str) {
            var convertedDate = str.substr(0, str.indexOf("T00"));
            return convertedDate;
        }
    }

    const setInitialValue = (event) => {
        let initialValue = {};
        if (event && Object.keys(event).length > 0) {
            return event;
        }
        if (
            PrefillDetails &&
            PrefillDetails.userCredentials &&
            PrefillDetails.userCredentials.length > 0
        ) {
            credentials.map((item) => {
                let detail = PrefillDetails.userCredentials.find(
                    (e) => e.credentialsStatus !== 'inactive' && e.types === item
                );
                if (detail && detail?.fluanddeclination) {
                    initialValue["isfluanddeclination"] = detail?.fluanddeclination;
                }
                if (detail) {
                    initialValue[`${item}file`] = detail.file;
                    initialValue[`${item}id`] = detail.id;
                    initialValue[`${item}name`] = detail.name;
                    initialValue[`is${item}`] = detail.isDeclination;
                    initialValue[`${item}credentialsStatus`] = detail.credentialsStatus;
                    initialValue[`${item}rejectReason`] = detail.rejectReason;
                    if (detail.expirationDate)
                        initialValue[`${item}Expiration`] = convertDate(
                            detail.expirationDate
                        );
                }
            });
        }

        if (PrefillDetails && PrefillDetails?.complianceCompetency) {
            credentials?.map((item) => {
                if (item === docuSignDocs.compliance) {
                    initialValue[`${item}Expiration`] = convertDate(
                        PrefillDetails?.complianceCompetency?.complianceExpirationDate
                    );
                }
                if (item === docuSignDocs.competency) {
                    initialValue[`${item}Expiration`] = convertDate(
                        PrefillDetails?.complianceCompetency?.competencyExpirationDate
                    );
                }
            });
        }
        // setInitialState(initialValue)
        return initialValue;
    };

    useEffect(() => {
        setPageInitilize(true);
        if (PrefillDetails && PrefillDetails.userDetail && PrefillDetails.userDetail.textData) {
            setTaxFormValues(JSON.parse(PrefillDetails.userDetail.textData))
        }
    }, [PrefillDetails]);

    const validHandleSubmit = () => {
        dispatch(triggerUpdateButton(false))
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };
        if (dropdownOpen) {
            document.addEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownOpen]);

    const ExpirationDateInput = (props) => {
        const { input, onChange, typename, values, form } = props;
        const isDisabled = typename === "hepatitisB" || typename === "covidrecord";
        if (typename === "hepatitisB" || typename === "covidrecord") {
            values[`${typename}Expiration`] = null;
        }
        return (
            <OverlayTrigger
                className=""
                key={"bottom"}
                placement={"bottom"}
                overlay={
                    <Tooltip id={`tooltip-bottom`} className="CredentialsTooltip">
                        <span> Please select a value that is no earlier than {dateFormatChange(todayDate)} </span>
                    </Tooltip>
                }>
                <input
                    className={`mobile-width mr-4 p-2 d-flex border border-gray-300 ${isDisabled ? 'disable-hard' : ''}`}
                    {...input}
                    min={today}
                    style={{ height: "46px", borderRadius: '5px' }}
                    type={"date"}
                    onChange={(e) => {
                        onChange(e);
                        handleChange(e, typename, values, form);
                    }}
                    onMouseEnter={handleDateFocus}
                    onBlur={handleDateBlur}
                    placeholder="Expiration Date"
                    disabled={isDisabled}
                />
            </OverlayTrigger>
        );
    };

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={useMemo((e) => setInitialValue(e), [PrefillDetails])}
            mutators={{ ...arrayMutators }}
            render={({ handleSubmit, submitting, values, form, valid, pristine }) => (
                <form
                    onSubmit={(e) => { if (!valid) toast.error("Please complete all required fields."); handleSubmit(e) }}>
                    {props.isProfile && props.pristineCheck(pristine)}
                    {!valid && validHandleSubmit()}
                    <div className='main-header'>
                        <div className="add_new_credential_header">Add New Credential</div>

                        <div className="justify-content-center text-center d-flex">
                            <div className="mb-3">
                                {!addNewStatus ? (
                                    <div className="dropdown-disabled">
                                        <button style={{ borderRadius: '5px', border: '1px solid #ced4da' }} type="button" className="dropdown-div disabled">
                                            <p className="pl-2 mt-2">{selectedLicenseLabel}</p>
                                        </button>
                                    </div>
                                ) : (
                                    <Dropdown ref={dropdownRef} className="d-inline dropdown-stafftab position-relative" show={dropdownOpen} onClick={toggleDropdown}
                                        onSelect={(eventKey) => handleLicenseChange(eventKey)}>
                                        <Dropdown.Toggle className="dropdown-div" id="dropdown-autoclose-true" style={{ borderRadius: '5px', border: '1px solid #ced4da' }}>
                                            {selectedLicenseLabel}
                                            {dropdownOpen ? <BsChevronUp className="bsChevroDown" /> : <BsChevronDown className="bsChevroDown" />}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="custom-dropdown-menu">
                                            <Dropdown.Item eventKey="therapylicense">Therapy License</Dropdown.Item>
                                            <Dropdown.Item eventKey="driverslicense">Driver's License</Dropdown.Item>
                                            <Dropdown.Item eventKey="autoinsurance">Auto Insurance</Dropdown.Item>
                                            <Dropdown.Item eventKey="professionallaibilityinsurance">Professional Liability Insurance</Dropdown.Item>
                                            <Dropdown.Item eventKey="cprcertification">CPR Certification</Dropdown.Item>
                                            <Dropdown.Item eventKey="physical">Physical</Dropdown.Item>
                                            <Dropdown.Item eventKey="tbtest">TB Test</Dropdown.Item>
                                            <Dropdown.Item eventKey="fluvaccination">Flu Vaccination</Dropdown.Item>
                                            <Dropdown.Item eventKey="hepatitisB">Hepatitis B</Dropdown.Item>
                                            <Dropdown.Item eventKey="covidrecord">Covid-19</Dropdown.Item>
                                            <Dropdown.Item eventKey="businesslicense">Business License</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                            </div>


                            <span className="d-flex gap-3 mb-3 mobile_wrap">
                                <Field name={`${selectedLicense}Expiration`}>
                                    {({ input: { onChange, ...input } }) => (
                                        <div className="cred-date-input mobile_width-990" style={{ width: '210px' }}>
                                            <ExpirationDateInput
                                                input={input}
                                                onChange={onChange}
                                                typename={selectedLicense}
                                                values={values}
                                                form={form}
                                            />
                                        </div>
                                    )}
                                </Field>
                            </span>
                            <Field name={selectedLicense}>
                                {({ input: { onChange } }) => (
                                    <>
                                        <input
                                            style={{ display: "none" }}
                                            className="input-group w-100 cred-input cursor-pointer file-upload-field"
                                            id={`fileUpload-${selectedLicense}`}
                                            name="file-upload-field"
                                            type="file"
                                            onChange={(e) => {
                                                onChange(e);
                                                handleChange(e, selectedLicense, values, form);
                                            }}
                                            ref={fileInputRef}
                                        />
                                        <label
                                            htmlFor={`fileUpload-${selectedLicense}`}
                                            style={{
                                                backgroundColor: !isSubmitVisible ? "#B6C4CE" : "transparent",
                                                backgroundImage: !isSubmitVisible
                                                    ? "none"
                                                    : "linear-gradient(300deg, #6a40d4 0%, #0f7ad4 100%)",
                                                backgroundPosition: "0% 0%",
                                                backgroundRepeat: "no-repeat",
                                                padding: "padding-box",
                                                pointerEvents: !isSubmitVisible ? "none" : "auto"
                                            }}
                                            className="update-btn-new mobile-width"
                                            onClick={() => {
                                                fileInputRef.current.value = null;
                                                fileInputRef.current.click();
                                            }}
                                        >
                                            <IoSyncCircleOutline size={35} className="icons-update mr-2" />
                                            Upload
                                        </label>

                                    </>
                                )}
                            </Field>
                        </div>
                        <div className="text-start pl-5 ">
                            {selectedFile && (
                                <div className="flex items-center">
                                    <p className="mr-2 ml-5">{selectedFile.name}</p>
                                    <BsX className="text-red-500 cursor-pointer icon" onClick={handleClearFile} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="common-btns-div d-flex gap-3 mt-4 mb-4 justify-content-center">
                    </div>
                    <div className="common-btns-div d-flex gap-3 mt-4 mb-4 justify-content-center">
                        {isSubmitVisible &&
                            <button
                                ref={buttonRef}
                                type="submit"
                                style={{ width: '300px', borderRadius: '60px' }} className={`Approve-btns`}
                                disabled={isSubmitDisabled}                            >
                                Submit
                            </button>
                        }
                    </div>

                </form>
            )}
        />
    );
};
export default AddNewCredentials;