
import React, { useEffect, useState } from "react";
import { CommonHead } from "./common/CommonHead";
import CommonTable from "./common/CommonTable";
import { Row, Col } from "bootstrap-4-react";
import BlockModal from "../pages/modals/BlockModal";
import { useDispatch, useSelector } from "react-redux";
import { getBlockListForAdmin } from "../Redux/actions/admin";
import { currentRole, defaultStatus, encodedData } from "../utils/auth";
import Loader from "./Loader";
import { setLoader } from "../Redux/slices/authSlice";
import ReactPaginate from "react-paginate";
import agencyimg from "../images/usertable01.svg"
import assistantimg from "../images/usertable02.svg"
import supervisingimg from "../images/usertable03.svg"
import agency_icon from "../images/AdminDashboard/agency_icon.svg";
import assistant_therapist_icon from "../images/AdminDashboard/assistant_therapist_icon.svg";
import supervising_therapist_icon from "../images/AdminDashboard/supervising_therapist_icon.svg";
import { blockTabs } from "../utils/helper";
import { apiBasePath } from "../Redux/config/config";
import block_icon from "../images/block_icon.svg";

const BlockList = () => {
  const dispatch = useDispatch()
  const [menuItem, setMenuItem] = useState("pendingapproval");
  const [show, setshow] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(10)

  const [filterCriteria, setFilterCriteria] = useState("all")
  const listData = useSelector((e) => e.adminSlice.blockedList)
  const blockedListCount = useSelector((e) => e.adminSlice.blockedListCount)
  const userCount = useSelector((e) => e.adminSlice?.countDetail)
  const isLoading = useSelector((e) => e.adminSlice.isListLoading)
  const [updateTab, setUpdateTab] = useState(false)

  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);

  let myImage = {
    supervisingtherapist: supervisingimg,
    assistanttherapist: assistantimg,
    agency: agencyimg,
  };
  let roleIcons = {
    supervisingtherapist: supervising_therapist_icon,
    assistanttherapist: assistant_therapist_icon,
    agency: agency_icon,
  };
  const getStatus = (tab) => {
    switch (tab) {
      case "pendingapproval":
        return defaultStatus.blockedpending
      case "blocked":
        return defaultStatus.blocked
    }
  }

  useEffect(() => {
    if (updateTab) {
      setUpdateTab(false)
    }
    dispatch(getBlockListForAdmin({
      pageNo: pageNo,
      pageSize: pageSize,
      status: getStatus(menuItem),
      tab: blockTabs[filterCriteria],
    }))
  }, [updateTab])

  const [data, setData] = useState()
  const handleData = (data) => {
    setData(data)
  }
  const setTabMenus = (tab) => {
    dispatch(setLoader({ loading: true }));
    setMenuItem(tab);
    setPageNo(1);
    dispatch(getBlockListForAdmin({
      pageNo: 1,
      pageSize: pageSize,
      status: getStatus(tab),
      tab: blockTabs[filterCriteria]
    }))
  };

  const getRoleDetailCount = (status) => {
    let count = 0
    if (userCount) {
      let user = userCount.find((item) => item.Status.key === defaultStatus[status])
      if (user && user.userCount) {
        count = user.userCount
      }
    }
    return count
  };

  const menu = [
    {
      label: "Pending Approval",
      count: Number(
        getRoleDetailCount(defaultStatus.blockedpending)
      ),
      key: "pendingapproval",
      data: listData,
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                // className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                className="update-btn justify-content-center ml-auto "
                onClick={() => {
                  handleShow();
                  handleData(rowData);
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
      modals: <BlockModal
        handleClose={handleClose}
        show={show}
        rowData={data}
        setTabMenus={setTabMenus}
        setUpdateTab={() => setUpdateTab(true)}
      />,
      columns: ["name", "usertype", "blockedby"],
      columnsToHide: ["image"],
    },
    {
      label: "Blocked",
      count: Number(
        getRoleDetailCount(defaultStatus.blocked)
      ),
      key: "blocked",
      data: listData,
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                // className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                className="update-btn justify-content-center ml-auto "
                onClick={() => {
                  handleShow();
                  handleData(rowData);
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
      modals: <BlockModal
        handleClose={handleClose}
        setUpdateTab={() => setUpdateTab(true)}
        show={show}
        rowData={data}

      />,
      columns: ["name", "usertype", "blockedby"],
      columnsToHide: ["image"],
    },
  ];


  const myActions = menu.find((ele) => ele.key === menuItem);


  const overrideColumnName = (colName) => {
    switch (colName) {
      case "name":
        return "Name";
      case "usertype":
        return "USER TYPE";
      case "blockedby":
        return "BLOCKED BY";

      default:
        return colName;
    }
  };

  const handlePageClick = (event) => {
    setPageNo(event.selected + 1);
    dispatch(
      getBlockListForAdmin({
        pageNo: event.selected + 1,
        pageSize: pageSize,
        status: getStatus(menuItem),
        tab: blockTabs[filterCriteria],
      })
    );
  };

  const renderRowData = (result, col, item) => {
    const userRole = result && result.blockedUsers.Role.key

    if (col === "name") {
      return (
        <>
          <div className="d-flex align-items-center">
            <div className="position-relative" style={{ marginRight: "5%" }}>
              <img
                alt="user data"
                src={result.blockedUsers &&
                  result.blockedUsers.image ?
                  `${apiBasePath}documents/therapist/${result.blockedUsers.image}` :
                  myImage[userRole]
                }
                crossOrigin="anonymous"
                className="table-img"
                style={{ borderRadius: "50%", objectFit: "cover" }}
              />
              <img
                className="buuble_botom block_sub_icon"
                // onClick={() => handleUnblock(result.id)}
                src={
                  block_icon
                } />
            </div>

            <h1 className=" title-22 ms-1">
              {userRole === currentRole.agency ?
                result &&
                result.blockedUsers &&
                `${result.blockedUsers.name}`
                :
                result &&
                result.blockedUsers &&
                `${result.blockedUsers.lastName}, ${result.blockedUsers.firstName} `

              }
            </h1>
          </div>
        </>
      );
    }

    if (col === "usertype") {
      return (
        <>
          <div className="d-flex gap-2 align-items-center">
            <img
              alt="user data"
              src={result &&
                result.blockedUsers &&
                roleIcons[result.blockedUsers.Role.key]}
              crossOrigin="anonymous"
              className=""
            />
            <p className="m-0 displine-list">
              {
                result &&
                result.blockedUsers &&
                `${result.blockedUsers.Role.title}`
              }
            </p>
          </div>
        </>
      );
    }

    if (col === "blockedby") {
      return (
        <>
          <div className="d-flex gap-2 align-items-center">
            <p className="m-0 displine-list">
              {result.blockedByUsers.name ?
                result &&
                result.blockedByUsers && (
                  (
                    <a href={`/newusers/agency/${encodedData(result.blockedByUsers.id)}`} className='blockedByLink'>
                      {`${result.blockedByUsers.name}`}
                    </a>
                  )
                )
                :
                result &&
                result.blockedByUsers && (
                  <a href={`/newusers/therapist/${encodedData({ id: result.blockedByUsers.id, onbordingPartCompleted: 1 })}`} className='blockedByLink' >
                    {`${result.blockedByUsers.lastName}, ${result.blockedByUsers.firstName} `}
                  </a>
                )
              }
            </p>
          </div>
        </>
      );
    }


    return <h1 className="displine-list title-24">{result[item]}</h1>;
  };

  const filterBy = (role) => {
    setFilterCriteria(role)
    setPageNo(1)
    dispatch(getBlockListForAdmin({
      pageNo: 1,
      pageSize: pageSize,
      status: getStatus(menuItem),
      tab: blockTabs[role],
    }))
  }


  return (
    <>
      {isLoading && <Loader />}
      <div className="container-fluid">
        <div className="row ">
          <div className="patient_main_parent desktop_justify-end">
            <div className="patient_right_form pl-5 pr-5 mb-3">
              <div className="inner_content_form ">
                <p> Filter by User Type </p>
                <select style={{ appearance: "none" }} defaultChecked={filterCriteria} onChange={(e) => filterBy(e.target.value)}>
                  <option value="all">All</option>
                  <option value="agency">Agency</option>
                  <option value="supervisingtherapist">Sup. Therapist</option>
                  <option value="assistanttherapist">Asst. Therapist</option>
                </select>
              </div>
            </div>
          </div>
          <div className="pl-5 pr-5">
            <CommonHead
              data={menu}
              setMenu={setTabMenus}
              menuItem={menuItem}
              css="sdf"
            />
          </div>

          <div className="container-fluid ">
            {listData && listData.length > 0 ? (
              <Row>
                <div className="inside_table_tabs">
                  <Col lg={11}>
                    <div className="table-responsive">
                      <CommonTable
                        data={myActions.data}
                        columns={myActions.columns}
                        columnsToHide={myActions.columnsToHide}
                        overrideColumnName={overrideColumnName}
                        actions={myActions.actions}
                        renderRowData={renderRowData}
                        isLoading={false}
                      />  </div>
                  </Col>
                </div>
              </Row>
            ) :
              (
                <div className="d-flex align-items-center justify-content-center mt-5">
                  <h2 className="fs-5 mt-5 mb-5">No Data Available</h2>
                </div>
              )
            }

            {blockedListCount > pageSize && listData?.length > 0 && (
              <div id="container ">
                <div className="row justify-content-start mt-3  pl-5 ">
                  <div className="pl-3 pr-5">
                    <ReactPaginate
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      nextLabel="next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={Math.ceil(blockedListCount / pageSize)}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                      forcePage={
                        Math.ceil(blockedListCount / pageSize) === 0 ? -1 : pageNo - 1
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {show && myActions.modals}
        </div>
      </div>
    </>
  );
};

export default BlockList;