import { createSlice } from "@reduxjs/toolkit";
import {
  createAdmin,
  getAdminUsersList,
  getCardsByRoleStatus,
  getCurrentActiveUser,
  getCurrentAdminInfo,
  getStatusList,
  getUserDropDownData,
  infiniteScrollGetCardsByRoleStatus,
  updateAdminDetails,
} from "../actions/auth";

import {
  addToBlockByAdmin,
  getAdminHomeCardData,
  getAllUserDetails,
  getAgencyAllUserDetails,
  getAgencyUserCredential,
  getUserCredential,
  getBlockListForAdmin,
  userCredentialUpdate,
  updateSignature,
} from "../actions/admin";

const initialState = {
  adminUserDetails: [],
  agencyUserDetails: [],
  allAdminUserDetails: [],
  adminUserCount: null,
  agencyUserCount: null,
  allAdminUserCount: null,
  userRoleCount: null,
  createAdminMessage: null,
  isLoading: false,
  currentActiveUserDetails: null,
  currentCredentialsDetail: [],
  updateSuccess: null,
  updateMessage: null,
  currentAdminInfo: null,
  adminUserModal: null,
  patientDetails: [],
  statusList: [],
  userDropDownData: [],
  cardsList: [],
  adminHomeCardData: [],
  homeCount: {},
  infiniteScrollCardList: [],
  infiniteScrollCardsCount: null,
  blockedList: [],
  blockedListCount: null,
  listcount: null,
  isListLoading: false,
  countDetail: null,
  adminTherapistCredentials:[],
  adminAgencyTherapistCredentials:[],
  adminAgencyUserCredential:[],
  adminUserCredential:[],
};

const adminSlice = createSlice({
  name: "adminUser",
  initialState,
  reducers: {
    clearAdminMessage(state, action) {
      state.createAdminMessage = null;
    },
    clearUpdateAdminMessage(state) {
      state.updateMessage = null;
      state.updateSuccess = null;
    },
    setLoader(state, { payload }) {
      state.isLoading = payload.loading;
    },
    resetUserList(state) {
      state.cardsList = null;
    },
  },
  extraReducers: {
    [createAdmin.fulfilled]: (state, { payload }) => {
      state.createAdminMessage = null;
      state.isLoading = false;
    },
    [createAdmin.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [createAdmin.rejected]: (state, { error, payload }) => {
      state.isLoading = false;
    },
    [getStatusList.fulfilled]: (state, { payload }) => {
      state.statusList = payload.data;
    },
    [getStatusList.rejected]: (state, { payload }) => {
      state.statusList = [];
    },
    [getAdminUsersList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.adminUserDetails = payload.data.rows;
      state.adminUserCount = payload.data.count;
      state.userRoleCount = payload.countDetail;
    },
    [getAdminUsersList.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getAdminUsersList.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getCurrentActiveUser.fulfilled]: (state, { payload }) => {
      state.currentActiveUserDetails = payload.data;
      state.currentCredentialsDetail = payload.validCredentials
        ? payload.validCredentials
        : [];
      state.isLoading = false;
    },
    [getCurrentActiveUser.pending]: (state, { payload }) => {
      state.currentCredentialsDetail = [];
      state.isLoading = true;
    },
    [getCurrentActiveUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [updateAdminDetails.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [updateAdminDetails.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [updateAdminDetails.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getCurrentAdminInfo.fulfilled]: (state, { payload }) => {
      state.currentAdminInfo = payload.data;
      state.isLoading = false;
    },
    [getCurrentAdminInfo.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getCurrentAdminInfo.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getUserDropDownData.fulfilled]: (state, { payload }) => {
      state.userDropDownData = payload.data;
    },
    [getUserDropDownData.rejected]: (state, { payload }) => {},
    [getCardsByRoleStatus.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.cardsList = payload.data.rows;
    },
    [getCardsByRoleStatus.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getCardsByRoleStatus.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [infiniteScrollGetCardsByRoleStatus.fulfilled]: (state, { payload }) => {
      if (payload.pageNo === 1) {
        state.infiniteScrollCardList = [];
      }
      state.isLoading = false;
      state.infiniteScrollCardsCount = payload.data.data.count;
      state.infiniteScrollCardList = [
        ...state.infiniteScrollCardList,
        ...payload.data.data.rows,
      ];
    },
    [infiniteScrollGetCardsByRoleStatus.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [infiniteScrollGetCardsByRoleStatus.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getAdminHomeCardData.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.adminHomeCardData = payload.data.countDetail;
        state.homeCount = payload.data;
        state.isListLoading = false;
      }
    },
    [getAdminHomeCardData.pending]: (state, { payload }) => {
      state.isListLoading = true;
    },
    [getAdminHomeCardData.rejected]: (state, { payload }) => {
      state.isListLoading = false;
      state.adminHomeCardData = null;
    },

    [getAllUserDetails.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.allAdminUserDetails = payload.data;
      state.allAdminUserCount = payload.data.count;
    },
    [getAllUserDetails.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getAllUserDetails.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getAgencyAllUserDetails.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.agencyUserDetails = payload.data;
      state.agencyUserCount = payload.data.count;
    },
    [getAgencyAllUserDetails.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getAgencyAllUserDetails.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getAgencyUserCredential.fulfilled]: (state, { payload }) => {
      state.adminAgencyUserCredential = payload.data;
      state.isLoading = false;
    },
    [getAgencyUserCredential.pending]: (state, { payload }) => {
      state.adminAgencyUserCredential = [];
      state.isLoading = true;
    },
    [getAgencyUserCredential.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getUserCredential.fulfilled]: (state, { payload }) => {
      state.adminUserCredential = payload.data;
      state.isLoading = false;
    },
    [getUserCredential.pending]: (state, { payload }) => {
      state.adminUserCredential = [];
      state.isLoading = true;
    },
    [getUserCredential.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getBlockListForAdmin.fulfilled]: (state, { payload }) => {
      state.blockedList = payload.blockedUserDetail.rows;
      state.blockedListCount = payload.blockedUserDetail.count;
      state.countDetail = payload.countDetail;
      state.isListLoading = false;
    },
    [getBlockListForAdmin.pending]: (state, { payload }) => {
      state.blockedList = [];
      state.isListLoading = true;
    },
    [getBlockListForAdmin.rejected]: (state, { payload }) => {
      state.blockedList = [];
      state.isListLoading = false;
    },
    [addToBlockByAdmin.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [addToBlockByAdmin.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [addToBlockByAdmin.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [userCredentialUpdate.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [userCredentialUpdate.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [userCredentialUpdate.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [updateSignature.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [updateSignature.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [updateSignature.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

export const {
  clearAdminMessage,
  clearUpdateAdminMessage,
  setLoader,
  resetUserList,
} = adminSlice.actions;
export default adminSlice.reducer;
