import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { GrFormClose } from "react-icons/gr";
import { useSelector } from "react-redux";

function SecondaryDaignosisForm(props) {
  const {
    showSelectSecondaydignosis,
    setShowSelectSecondaydignosis,
    setSecondayDignosisData,
    daignosisId,
    setDaignosisId,
    setSecDiagErrors,
  } = props;

  let masterDatas = useSelector((e) => e.therapistSigunpSlice.masterData);

  const handleSubmit = (values) => {
    const selectedDaignos = [];
    if (values?.secondaryDiagnosis) {
      values?.secondaryDiagnosis.map((item) => {
        if (item?.selectLabel) {
          selectedDaignos.push({
            masterDataId: item?.value,
            name: item?.label,
            types: "secondarydiagnosis",
          });
        }
      });
    }
    if (selectedDaignos) {
      setSecondayDignosisData(selectedDaignos);
      setShowSelectSecondaydignosis(false);
      setDaignosisId([]);
    }
  };

  const selectMasterOptions = (type) => {
    const typeBaseData = [];
    if (masterDatas && masterDatas?.data?.length > 0) {
      masterDatas["data"].map((item) => {
        if (item && item?.types === type) {
          const valueLabel = { value: item?.id, label: item?.name };
          typeBaseData.push(valueLabel);
        }
      });
    }
    return typeBaseData;
  };

  const setInitialValue = (event) => {
    let initialvalues = {};
    if (event && Object.keys(event).length > 0) {
      initialvalues = event;
    } else {
      if (selectMasterOptions("secondarydiagnosis")?.length > 0) {
        initialvalues["secondaryDiagnosis"] =
          selectMasterOptions("secondarydiagnosis");
      }
    }
    if (daignosisId) {
      initialvalues?.secondaryDiagnosis?.forEach((item) => {
        if (daignosisId?.includes(item?.value)) {
          item.selectLabel = false;
        }
      });
    }
    return initialvalues;
  };

  const validateForm = (values) => {
    let errors = {};
    values?.secondaryDiagnosis?.forEach((item) => {
      if (item?.selectLabel) {
        setSecDiagErrors(false);
      }
    });
    return errors;
  };

  return (
    <>
      <Form
        validate={validateForm}
        onSubmit={handleSubmit}
        keepDirtyOnReinitialize
        initialValues={(e) => setInitialValue(e)}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, values, form}) => (
          <form onSubmit={handleSubmit}>
            <Modal
              show={showSelectSecondaydignosis}
              className="secondarydignosisModal"
              backdrop="static"
              keyboard={false}
            >
              <div className="secondarydignosisModalParent">
                <p>Select Secondary Diagnosis</p>
                <GrFormClose
                  size="30px"
                  onClick={() => {setShowSelectSecondaydignosis(false)
                  form.change(values, '')
                 }}
                  cursor="pointer"
                />
              </div>

              <Row>
                {values &&
                  Object.keys(values).map((item) => (
                    <FieldArray name={item} key={item}>
                      {({ fields }) =>
                        fields.map((name, ind) => {
                          return (
                            <Col md={4} key={ind}>
                              <div className="radio_btns_modal">
                                <Field
                                  name={`${name}.selectLabel`}
                                  type="checkbox"
                                >
                                  {({ input }) => (
                                    <>
                                      <input
                                        {...input}
                                        className="agen-reject-check"
                                      />
                                      <label>{values[item][ind]?.label}</label>
                                    </>
                                  )}
                                </Field>
                              </div>
                            </Col>
                          );
                        })
                      }
                    </FieldArray>
                  ))}
              </Row>
              <div className="daigSaveBtn">
                <button className="save-btnn" onClick={handleSubmit}>
                  Save
                </button>
              </div>
            </Modal>
          </form>
        )}
      />
    </>
  );
}

export default SecondaryDaignosisForm;
