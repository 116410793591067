import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import styled from "styled-components";
import { validateForm } from "../../../validation/validateForm";
import { resetPasswordSchema } from "../../../validation/schemas";
import { changePassword } from "../../../Redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import {
  allowedExtensions,
  passwordRegex,
  profileTabs,
} from "../../../utils/helper";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  margin-top: 2%;
  margin-bottom: 2%;
`;

const Button = styled.button`
  background: linear-gradient(
    90deg,
    rgba(15, 122, 212, 1) 8%,
    rgba(106, 64, 212, 1) 98%
  );
  color: white;
  outline: none;
  border: none;
  border-radius: 20px;
  padding: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 6px;
  border: 1px solid lightgrey;
  outline: none;
  margin-bottom: 3px;
  &::placeholder {
    font-size: 15px;
  }
`;

const Error = styled.div`
  color: #ee8a8a;
  background-color: #eecbcb;
  width: 100%;
  font-size: 14px;
  padding: 7px;
`;

// const validate = validateForm(resetPasswordSchema);

const SetNewPassword = (props) => {
  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const dispatch = useDispatch();
  const isLoading = useSelector((e) => e.authSlice.isLoading);
  const [isSubmit, setSubmit] = useState(false);

  function handleSubmit(values) {
    setSubmit(true);
    const newValue = { ...values };
    if (props?.id && newValue) {
      newValue.userId = props?.id;
      newValue.newPassword = newValue.Password;
      delete newValue.Password;
      dispatch(changePassword(newValue))
        .then((res) => {
          setSubmit(false);
          if (res && res?.payload && res?.payload?.success) {
            toast.success(`${res?.payload?.message?.default}.`);
            setTimeout(() => {
              props.setTabkey(profileTabs.personalinfo);
            }, 500);
          } else {
            toast.warn(`${res?.payload?.message?.password}.`);
          }
        })
        .catch((err) => {
          setSubmit(false);
        });
    }
  }
  const validate = (values) => {
    var regex = passwordRegex;
    const phone = /^-?\d+\.?\d*$/;

    const emailRegex = /^\S+@\S+\.\S+$/;
    // var zipRegex = /^\d{5}(?:-\d{4})?$/;
    // const zipCodeRegex = /^\d{5}(?:-\d{4})?$/;
    // const zipCodeRegex = /^\d{5}-\d{4}$/;
    const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;

    let errors = {};

    if (PrefillDetails && PrefillDetails.id) {
      if (!values.oldPassword) {
        errors["oldPassword"] = "Old password is required.";
      }
      if (!values.confirmPassword) {
        errors["confirmPassword"] = "Confirm password is required.";
      }

      if (!values.Password) {
        errors["Password"] =
          "Password strength/length should be at least  8 characters and maximum 64 characters and contain an uppercase, a lowercase, a special character, and a digit.";
      }

      if (values && values.confirmPassword) {
        if (values.Password !== values.confirmPassword) {
          errors["confirmPassword"] = "Both password should match.";
        }
      }

      if (values && values.Password) {
        if (values.Password.length < 8 && !regex.test(values.Password)) {
          errors["Password"] =
            "Password strength/length should be at least  8 characters and maximum 64 characters and contain an uppercase, a lowercase, a special character, and a digit.";
        } else if (!regex.test(values.Password)) {
          errors["Password"] =
            "Password strength/length should be at least  8 characters and maximum 64 characters and contain an uppercase, a lowercase, a special character, and a digit.";
        }
      }
    }

    return errors;
  };
  return (
    <>
      <div>
        <Form
          onSubmit={handleSubmit}
          validate={validate}
          render={({ handleSubmit, submitting, values, errors }) => (
            <form onSubmit={handleSubmit}>
              <Container>
                <div className="row">
                  <label className="new-password">Set New Password</label>
                  <div className="col-md-4">
                    <Field name="oldPassword">
                      {({ input, meta }) => (
                        <div className="mb-3">
                          <label className="old-password">Old Password</label>
                          <Input
                            className="new-password-input"
                            {...input}
                            type="password"
                            placeholder="Password"
                          />
                          <br />
                          {meta.error && meta.touched && (
                            <p
                              className={`alert alert-danger top-11 top-cred w-100 `}
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name="Password">
                      {({ input, meta }) => (
                        <div className="mb-3">
                          <label className="old-password">New Password</label>
                          <Input
                            {...input}
                            className="new-password-input"
                            type="password"
                            placeholder="New Password"
                          />
                          <br />
                          {meta.error && meta.touched && (
                            <p
                              className={`alert alert-danger top-11 top-cred w-100 `}
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name="confirmPassword">
                      {({ input, meta }) => (
                        <div className="mb-3">
                          <label className="old-password">
                            Confirm New Password
                          </label>
                          <Input
                            className="new-password-input"
                            {...input}
                            type="password"
                            placeholder="Confirm New Password"
                          />
                          <br />
                          {meta.error && meta.touched && (
                            <p
                              className={`alert alert-danger top-11 top-cred w-100 `}
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="row">
                    <div className="col-md-4 d-flex gap-5">
                      <Button
                        className="set-password-btn"
                        type="submit"
                        disabled={submitting}
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   handleSubmit(values);
                        // }}
                      >
                        Submit
                      </Button>
                      <Button
                        className="cancel-password-btn"
                        onClick={() => props.setTabkey(1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
                {isSubmit && <Loader />}
              </Container>
            </form>
          )}
        />
      </div>
    </>
  );
};

export default SetNewPassword;
