import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import SignaturePad from "react-signature-canvas";
import { toast } from "react-toastify";

export default function SignatureCanvas(props) {
  const { loadDoc, isShow, handleClose, setSignatureAdded } = props;
  let signatureRef = useRef(null);
  const clearCanvas = () => {
    signatureRef.clear();
  };
  const saveSignature = () => {
    if (!signatureRef.isEmpty()) {
      let trimmedSignature = signatureRef.toDataURL("image/png");
      loadDoc(trimmedSignature);
      handleClose();
      setSignatureAdded(true);
    } else {
      toast.error("Signature field is empty.");
    }
  };

  const RenderSignaturePad = () => {
    return (
      <div style={{ border: "1px solid black" }} className="mb-3 p-2">
        <SignaturePad
          maxWidth={1.5}
          penColor="#6a40d4"
          canvasProps={{ width: 720, height: 200, className: "sigCanvas" }}
          ref={(ref) => {
            signatureRef = ref;
          }}
        />
      </div>
    );
  };
  return (
    <Modal
      show={isShow}
      size={"lg"}
      onHide={handleClose}
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header className=" border-0" closeButton8>
        <div className="w-100">
          <h4 className="text-center">Add Signature</h4>
        </div>
      </Modal.Header>
      <Modal.Body className="p-3">
        <div className="AuthorizationsTabs">
          <RenderSignaturePad />
          <div className="common-btns-div d-flex justify-content-center">
            <button type="button" className="cancel-btns" onClick={clearCanvas}>
              Clear
            </button>
            <button
              type="submit"
              className="Approve-btns"
              onClick={saveSignature}
            >
              Save Signature
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
