import { configureStore } from "@reduxjs/toolkit";
import adminSlice from "../slices/adminSlice";
import agencySlice from "../slices/agencySignupSlice";
import authSlice from "../slices/authSlice";
import therapistSignupSlice from "../slices/therapistSignupSlice";
import staffingSlice from "../slices/staffingSlice";
import commonSlice from "../slices/commonSlice";
import chatSlice from "../slices/chatSlice";
import patientGetDocTypesSlice from "../slices/patientGetDocTypesSlice";
import paymentSlice from "../slices/paymentSlice";
import notificationSlice from "../slices/notificationSlice";

export const store = configureStore({
  reducer: {
    adminSlice: adminSlice,
    authSlice: authSlice,
    agencySlice: agencySlice,
    therapistSigunpSlice: therapistSignupSlice,
    staffingSlice: staffingSlice,
    commonSlice: commonSlice,
    chatSlice: chatSlice,
    patientGetDocTypesSlice: patientGetDocTypesSlice,
    paymentSlice: paymentSlice,
    notificationSlice: notificationSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
