import React, { useEffect, useState } from "react";
import { Row, Col } from "bootstrap-4-react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import CommonTable from "../../../components/common/CommonTable";
import { apiBasePath } from "../../../Redux/config/config";
import Loader from "../../../components/Loader";
import { Button } from "react-bootstrap";
import AddToBlockList from "./addToBlockList";
import { getAllBlockedTherapist, unblockUser } from "../../../Redux/actions/agency";
import { setLoader } from "../../../Redux/slices/authSlice";
import agencyimg from "../../../images/usertable01.svg"
import assistantimg from "../../../images/usertable02.svg"
import Supervisingimg from "../../../images/usertable03.svg";
import agency_icon from "../../../images/AdminDashboard/agency_icon.svg";
import assistant_therapist_icon from "../../../images/AdminDashboard/assistant_therapist_icon.svg";
import supervising_therapist_icon from "../../../images/AdminDashboard/supervising_therapist_icon.svg";
import { AiFillPlusCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import block_icon from "../../../images/block_icon.svg";
import { messageFormatting } from "../../../utils/auth";

const BlockList = () => {
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [showAddToBlockModal, setShowAddToBlockModal] = useState(false);
  const [updateTab, setUpdateTab] = useState(false)

  const blockedUsers = useSelector((e) => e.agencySlice.blocked)
  const blockedUsersCount = useSelector((e) => e.agencySlice.blockedCount)
  const isLoading = useSelector((e) => e.agencySlice.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (updateTab) {
      setUpdateTab(!updateTab)
    }
    dispatch(getAllBlockedTherapist({
      pageNo: pageNo,
      pageSize: pageSize,
    }))

  }, [dispatch, showAddToBlockModal, updateTab]);

  const handlePageClick = (event) => {
    dispatch(setLoader({ loading: true }))
    setPageNo(event.selected + 1);
    dispatch(getAllBlockedTherapist({
      pageNo: event.selected + 1,
      pageSize: pageSize,
    }))
  };

  const columns = ["name", "Role.name", "reason"];
  const columnsToHide = ["image"];

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "name":
        return "Name";
      case "Role.name":
        return "User Type";
      case "reason":
        return "Reason";
      default:
        return colName;
    }
  };
  const handleUnblock = (id) => {
    dispatch(unblockUser({
      id: id,
    })).then((res) => {
      if (res && res.payload && res.payload.data && res.payload.data.success) {
        toast.success(messageFormatting(res.payload.data.message));
        setPageNo(1)
        dispatch(
          getAllBlockedTherapist({
            pageNo: 1,
            pageSize: pageSize,
          })
        );
      }
    })

  }

  const actions = [
    {
      action: (rowData) => (
        <td>
          <button
            // className="unblock-btn tr-btn ml-auto mt-3 mb-3"
            className="update-btn justify-content-center ml-auto "
            onClick={() => {
              handleUnblock(rowData.id)
            }}
          >
            Unblock
          </button>
        </td>
      ),
    },
  ];

  let myImage = {
    supervisingtherapist: Supervisingimg,
    assistanttherapist: assistantimg,
    agency: agencyimg,
  };
  let roleIcons = {
    supervisingtherapist: supervising_therapist_icon,
    assistanttherapist: assistant_therapist_icon,
    agency: agency_icon,
  };
  const renderRowData = (result, col, item) => {
    let userRole = result.blockedUsers.Role && result.blockedUsers.Role.key;
    if (col === "name") {
      return (
        <div className="d-flex align-items-center pl-2">
          <div className="position-relative" style={{ marginRight: "5%" }}>
            <img
              src={
                result.blockedUsers.image
                  ? `${apiBasePath}documents/therapist/${result.blockedUsers.image}`
                  : myImage[result.blockedUsers["Role"]["key"]]
              }
              crossOrigin="acountnymous"
              className="table-img"
              style={{ borderRadius: "50%", objectFit: "cover" }}
              alt="img"
            />
            <img
              className="buuble_botom block_sub_icon cursor-pointer"
              onClick={() => handleUnblock(result.id)}
              src={
                block_icon
              } />
          </div>
          <h1 className="title-22 ms-1">
            {result &&
              result.blockedUsers &&
              `${result.blockedUsers.lastName}, ${result.blockedUsers.firstName}`}
          </h1>
        </div>
      );
    }

    if (col === "Role.name") {
      return (
        <div className="d-flex gap-2 align-items-center">
          <img
            src={
              roleIcons[result.blockedUsers["Role"].key]
            }
            crossOrigin="acountnymous"
            className="p-1"
            alt="roleIcons"
          />

          <p className="m-0 displine-list">
            {result && result.blockedUsers && result.blockedUsers["Role"] && result.blockedUsers["Role"].title}
          </p>
        </div>
      );
    }
    if (col === "reason") {
      return (
        <div className="d-flex align-items-center unblock-reason fw-normal ">
          <p className="fw-normal m-0"> {result && result.reason}</p>
        </div>
      );
    }
    return <h1 className=" title-24 mt-3 mb-3"> {result[item]}</h1>;
  };

  const handleShowAddToBlockModal = () => setShowAddToBlockModal(true);
  const handleCloseAddToBlockModal = () => setShowAddToBlockModal(false);

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between my-3 mx-5">
        <h5 className="Block-title">Block-List</h5>
        <Button
          className="btn btn-primary text-center blocklist-btn"
          onClick={() => handleShowAddToBlockModal()}

        >
          <AiFillPlusCircle size={35} />  Add to Block-List
        </Button>
      </div>
      {blockedUsers && blockedUsers?.length > 0 ? (
        <Row>
          <div className="inside_table_tabs">
            <Col lg={11}>
              <div className="table-responsive">
                <CommonTable
                  data={blockedUsers}
                  columns={columns}
                  columnsToHide={columnsToHide}
                  overrideColumnName={overrideColumnName}
                  actions={actions}
                  renderRowData={renderRowData}
                  isLoading={isLoading}
                />
              </div>
            </Col>
          </div>
        </Row>
      ) : (
        <div className="d-flex align-items-center justify-content-center mt-5">
          <h2 className="fs-5 mt-5 mb-5">No Data Available</h2>
        </div>
      )}
      {blockedUsersCount > pageSize && blockedUsers?.length > 0 && (
        <div id="container ">
          <div className="row justify-content-start mt-3  pl-5 ">
            <div className="pl-3 pr-5">
              <ReactPaginate
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={Math.ceil(blockedUsersCount / pageSize)}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                forcePage={
                  Math.ceil(blockedUsersCount / pageSize) === 0 ? -1 : pageNo - 1
                }
              />
            </div>
          </div>
        </div>
      )}
      {isLoading && <Loader />}
      {showAddToBlockModal && (
        <AddToBlockList
          showAddToBlockModal={showAddToBlockModal}
          handleCloseAddToBlockModal={handleCloseAddToBlockModal}
          updateTab={updateTab}
          setUpdateTab={setUpdateTab}
        />
      )}
    </div>
  );
};
export default BlockList;
