import React, { useEffect, useMemo, useState } from "react";
import { Form, Field } from "react-final-form";
import styled from "styled-components";
import AgencyNavbar from "../../Agency/signup/AgencyNavbar";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AddressAutocomplete from "../../../components/AddressAutoComplete";
import UserUnderReview from "../../UserUnderReview";
import {
  resetPendingSteps,
  setRequest,
} from "../../../Redux/slices/therapistSignupSlice";
import {
  currentRole,
  decodeToken,
  defaultStatus,
  therapistSignupSteps,
} from "../../../utils/auth";
import TherapistRates from "./TherapistRates";
import NotificationPreferences from "./NotificationPreferences";
import TherapistPayment from "./TherapistPayment";
import TermsAndConditions from "../../Agency/signup/TermsAndConditions";
import myImage from "../../../images/t&c.png";
import TherapistCredentials from "./TherapistCredentials";
import ProfessionalExperience from "./ProfessionalExperience";
import LocationModal from "./LocationModal";
import {
  getDefaultRates,
  updatePendingSteps,
  userSignup,
} from "../../../Redux/actions/therapist";
import {
  getUserDetailsById,
  setTherapistDataGetStep,
} from "../../../Redux/actions/auth";
import Loader from "../../../components/Loader";
import Compliance from "./Compliance";
import BackgroundProcess from "./BackgroundProcess";
import SignatureCanvas from "../../../components/common/SignatureCanvas";
import {
  formatPinCode,
  formatPinCodeValue,
  passwordRegex,
  phoneNumberToUSFormat,
} from "../../../utils/helper";

const Button = styled.button`
  background: linear-gradient(
    90deg,
    rgba(15, 122, 212, 1) 8%,
    rgba(106, 64, 212, 1) 98%
  );
  color: white;
  outline: none;
  border: none;
  width: 20%;
  border-radius: 20px;
  padding: 10px;
`;

const TherapistSignup = () => {
  const pendingSteps = useSelector((e) => e.therapistSigunpSlice.pendingSteps);
  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const [searchParams] = useSearchParams();
  const isLoading = useSelector((e) => e.therapistSigunpSlice.isLoading);
  const step = useSelector((e) => e.therapistSigunpSlice.step);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [toShow, setToShow] = useState(false);

  const location = useLocation();

  const usercurrentRole = [
    currentRole.assistanttherapist,
    currentRole.supervisingtherapist,
  ];

  useEffect(() => {
    if (usercurrentRole) {
      dispatch(getDefaultRates(usercurrentRole));
    }
  }, []);

  useEffect(() => {
    if (searchParams && searchParams.get("auth")) {
      let userDetail = decodeToken(searchParams.get("auth"));
      dispatch(getUserDetailsById(`${userDetail.userId}`)).then((res) => {
        if (res?.payload?.data?.Status?.key === defaultStatus.pending) {
          navigate("/UserUnderReview");
        } else if (res?.payload?.data?.Status?.key === defaultStatus.approved) {
          navigate("/login");
        } else {
          setToShow(true);
        }
      });
    }
  }, [dispatch, searchParams, step]);
  useEffect(() => {
    if (searchParams && searchParams.get("auth")) {
      setToShow(false);
      dispatch(resetPendingSteps());
    } else {
      setToShow(true);
    }
  }, [searchParams]);
  function handleSubmit(values) {
    setSubmitted(true);
    let formData = new FormData();
    let id = null;
    if (PrefillDetails?.id) {
      id = PrefillDetails.id;
      values.id = PrefillDetails.id;
      if (!values?.latitude && !values?.longitude) {
        values.latitude = PrefillDetails.userDetail.latitude;
        values.longitude = PrefillDetails.userDetail.longitude;
      }
    }
    if (!values.aptUnit) {
      values.aptUnit = null;
    }
    values.user = usercurrentRole;

    let emails = [];
    formData.append("profileData", JSON.stringify(values));
    if (values.email) {
      emails.push({ email: values.email, id });
    }

    dispatch(userSignup(formData)).then((res) => {
      setSubmitted(false);
      if (
        !res.payload.success &&
        res.payload?.data?.duplicateEmails?.length > 0
      ) {
        toast.error(
          `You can not add duplicate emails(${res.payload.data.duplicateEmails?.toString()}).`
        );
        dispatch(setRequest({ request: false }));
      } else {
        setSubmitted(true);
        if (
          location &&
          location.pathname.includes(currentRole.assistanttherapist)
        ) {
          navigate(`/assistanttherapist?auth=${res.payload.data.token}`);
        } else {
          navigate(`/supervisingtherapist?auth=${res.payload.data.token}`);
        }
      }
    });
  }

  const setInitialValue = (event) => {
    if (!searchParams.get("auth")) {
      if (event && Object.keys(event).length > 0) {
        return event;
      }
    }

    let initial = {};
    initial.firstName =
      PrefillDetails && PrefillDetails ? PrefillDetails.firstName : "";
    initial.lastName = PrefillDetails && PrefillDetails.lastName;

    initial.email = PrefillDetails && PrefillDetails.email;
    initial.phone = PrefillDetails ? PrefillDetails.userDetail?.phone : "";

    initial.state = PrefillDetails ? PrefillDetails.userDetail?.state : "";
    initial.address = PrefillDetails ? PrefillDetails.userDetail?.address : "";
    initial.city = PrefillDetails ? PrefillDetails.userDetail?.city : "";
    initial.aptUnit = PrefillDetails ? PrefillDetails.userDetail?.aptUnit : "";
    initial.zip = PrefillDetails ? PrefillDetails.userDetail?.zip : "";

    if (!PrefillDetails) {
      return event;
    }
    return initial;
  };
  const initValue = useMemo((e) => setInitialValue(e), [PrefillDetails]);

  const selectResult = (result, setValue) => {
    if (result) {
      setValue("state", result.state);
      setValue("address", result.address);
      setValue("street", result.street);
      setValue("country", result.country);
      setValue("city", result.city);
      setValue("zip", formatPinCodeValue(result.postal_code));
      setValue("latitude", result.latitude);
      setValue("longitude", result.longitude);
      setValue("addressDetails", result?.addressDetails);
    }
  };

  function goBackFunction(pendingStep) {
    let pendingSteps = JSON.parse(JSON.stringify(therapistSignupSteps));
    switch (pendingStep) {
      case "rates":
        pendingSteps = pendingSteps.toString();
        dispatch(setTherapistDataGetStep(null));

        break;
      case "area":
        pendingSteps = pendingSteps.splice(1, 10).toString();
        dispatch(setTherapistDataGetStep(null));

        break;
      case "professionalData":
        pendingSteps = pendingSteps.splice(2, 10).toString();
        dispatch(setTherapistDataGetStep("userProfessionalData"));

        break;
      case "credentials":
        pendingSteps = pendingSteps.splice(3, 10).toString();
        dispatch(setTherapistDataGetStep(null));

        break;

      case "notifications":
        pendingSteps = pendingSteps.splice(4, 10).toString();
        dispatch(setTherapistDataGetStep(null));

        break;
      case "payments":
        pendingSteps = pendingSteps.splice(5, 10).toString();
        dispatch(setTherapistDataGetStep(null));

        break;

      case "termconditions":
        pendingSteps = pendingSteps.splice(6, 10).toString();
        dispatch(setTherapistDataGetStep(null));

        break;
      case "success":
        pendingSteps = pendingSteps.splice(9, 10).toString();
        dispatch(setTherapistDataGetStep(null));
        break;

      default:
        pendingSteps = pendingSteps.toString();
        break;
    }
    dispatch(
      updatePendingSteps({
        pendingSteps: pendingSteps,
        userId: PrefillDetails.id,
      })
    ).then((res) => {
      dispatch(getUserDetailsById(`${PrefillDetails.id}`));
    });
  }
  let myObj = {
    area: <LocationModal goBackFunction={goBackFunction} />,
    rates: (
      <TherapistRates
        goBackFunction={goBackFunction}
        usercurrentRole={usercurrentRole}
      />
    ),
    professionalData: (
      <ProfessionalExperience goBackFunction={goBackFunction} />
    ),
    notifications: <NotificationPreferences goBackFunction={goBackFunction} />,
    termconditions: (
      <TermsAndConditions
        user={currentRole.supervisingtherapist}
        goBackFunction={goBackFunction}
      />
    ),
    success: (
      <TermsAndConditions
        image={myImage}
        user={currentRole.supervisingtherapist}
        goBackFunction={goBackFunction}
      />
    ),
    payments: (
      <TherapistPayment
        goBackFunction={goBackFunction}
        usercurrentRole={usercurrentRole}
      />
    ),
    credentials: <TherapistCredentials goBackFunction={goBackFunction} />,
    compliance: <Compliance />,
    backgroundcheck: <BackgroundProcess />,
    completed: <UserUnderReview />,
  };

  let fieldToCheck = {
    firstName: "firstName",
    lastName: "lastName",
    email: "email",
    phone: "phone",
    discipline: "discipline",
    // city: "city",
    // state: "state",
    // zip: "zip",
    address: "address",
  };

  const checkRejectedValue = (values, rejKey, className) => {
    let isrejected = false;
    if (values) {
      if (
        PrefillDetails &&
        PrefillDetails.userDetail &&
        PrefillDetails.userDetail.rejectedFields &&
        PrefillDetails.Status.key === defaultStatus.rejected
      ) {
        let rejectedData = PrefillDetails.userDetail.rejectedFields;
        if (
          rejKey == "address" &&
          fieldToCheck[rejKey] &&
          values[fieldToCheck[rejKey]]
        ) {
          let valueAdress = `${values.address}, ${values.city}, ${values.state}, ${values.zip}`;
          if (rejectedData[rejKey] == valueAdress) isrejected = true;
        } else if (
          fieldToCheck[rejKey] &&
          values[fieldToCheck[rejKey]] &&
          rejectedData[rejKey] == values[fieldToCheck[rejKey]]
        ) {
          isrejected = true;
        }
      }
    }
    return isrejected;
  };

  const setClassForPart = (part) => {
    let classValue = "";
    if (
      PrefillDetails &&
      PrefillDetails.Status.key !== defaultStatus.rejected &&
      PrefillDetails.userDetail
    ) {
      if (part === PrefillDetails.userDetail.onbordingPartCompleted) {
        classValue = "top-link-active-red";
      }
    } else if (!PrefillDetails && part === 0) {
      classValue = "top-link-active-red";
    }
    return classValue;
  };

  const isRejected = (values) => {
    let isRejected = false;
    if (
      PrefillDetails &&
      PrefillDetails.Status.key === defaultStatus.rejected &&
      PrefillDetails.userDetail &&
      values &&
      values.address
    ) {
      let valueAdress = `${values.address}, ${values.city}, ${values.state}, ${values.zip}`;
      let rejectedAdress = `${PrefillDetails.userDetail.address}, ${PrefillDetails.userDetail.city}, ${PrefillDetails.userDetail.state}, ${PrefillDetails.userDetail.zip}`;
      if (rejectedAdress == valueAdress) isRejected = true;
    } else {
      isRejected = false;
    }
    return isRejected;
  };

  const validateForm = (values) => {
    var regex = passwordRegex;
    const phone = /^-?\d+\.?\d*$/;

    const emailRegex = /^\S+@\S+\.\S+$/;

    let errors = {};

    if (PrefillDetails && PrefillDetails.id) {
      if (values.password?.length > 0 || values.confirmPassword?.length > 0) {
        if (!values.confirmPassword) {
          errors["confirmPassword"] = "Confirm Password is required.";
        }

        if (!values.password) {
          errors["password"] = "Password is required.";
        }

        if (values && values.confirmPassword) {
          if (values.password !== values.confirmPassword) {
            errors["confirmPassword"] = "Both Password should match.";
          }
        }

        if (values && values.password) {
          if (!regex.test(values.password)) {
            if (values?.password?.length < 8 || values?.password?.length > 64) {
              errors["password"] =
                "Password strength/length should be at least  8 characters and maximum 64 characters and contain an Uppercase, a Lowercase, a special character, and a digit.";
            } else {
              errors["password"] =
                " Password strength/length should be at least  8 characters and maximum 64 characters and contain an Uppercase, a Lowercase, a special character, and a digit.";
            }
          }
        }
      }
    } else {
      if (values && values.confirmPassword) {
        if (values.password !== values.confirmPassword) {
          errors["confirmPassword"] = "Both Password should match.";
        }
      }
      if (values && values.password) {
        if (!regex.test(values.password)) {
          if (values?.password?.length < 8 || values?.password?.length > 64) {
            errors["password"] =
              "Password strength/length should be at least  8 characters and maximum 64 characters and contain an Uppercase, a Lowercase, a special character, and a digit.";
          } else {
            errors["password"] =
              "Password strength/length should be at least  8 characters and maximum 64 characters and contain an Uppercase, a Lowercase, a special character, and a digit.";
          }
        }
      }
      if (!values.confirmPassword) {
        errors["confirmPassword"] = "Confirm Password is required.";
      }
      if (!values.password) {
        errors["password"] = "Password is required.";
      }
    }

    if (!values.firstName) {
      errors["firstName"] = "First name is required.";
    } else if (values.firstName && values.firstName.trim() === "") {
      errors["firstName"] = "First name is required.";
    }
    if (!values.lastName) {
      errors["lastName"] = "Last name is required.";
    } else if (values.lastName && values.lastName.trim() === "") {
      errors["lastName"] = "Last name is required.";
    }

    if (!values.email) {
      errors["email"] = "Email Address is required.";
    }

    if (values.email) {
      if (!emailRegex.test(values.email)) {
        errors["email"] = "Please enter a valid email address.";
      }
    }
    if (!values.state) {
      errors["state"] = "State is required.";
    }
    if (!values.address) {
      errors["address"] = "Street is required.";
    }

    if (!values.city) {
      errors["city"] = "City is required.";
    }

    if (!values.zip) {
      errors["zip"] = "Zip is required.";
    }

    // if (!values.aptUnit) {
    //   errors["aptUnit"] = "Apt Unit is required";
    // }

    if (!values.phone) {
      errors["phone"] = "Phone number is required.";
    } else if (values.phone && values.phone.match(/\d/g).length !== 10) {
      errors["phone"] = "Please enter a 10 digit number.";
    }

    if (!values.phone) {
      errors["phone"] = "Phone number is required.";
    }

    return errors;
  };

  return (
    <div className="ca-body">
      {isLoading && <Loader />}
      <AgencyNavbar />
      <div className="progress-1">
        <ul className="d-flex p-0 m-0 ">
          <li
            className={`nav-link top-link nav-border ${setClassForPart(0)}  ${
              PrefillDetails &&
              PrefillDetails.userDetail &&
              PrefillDetails.userDetail.onbordingPartCompleted > 0
                ? PrefillDetails.userDetail.onbordingPartCompleted == 1 &&
                  PrefillDetails.Status.key == defaultStatus.rejected
                  ? "top-link-active-red"
                  : "top-link-active-green"
                : ""
            }`}
          >
            THERAPIST PROFILE - PART 01/03
          </li>
          <li
            className={`nav-link top-link nav-border ${setClassForPart(1)}  ${
              PrefillDetails &&
              PrefillDetails.userDetail &&
              PrefillDetails.userDetail.onbordingPartCompleted > 1
                ? PrefillDetails.userDetail.onbordingPartCompleted == 2 &&
                  PrefillDetails.Status.key == defaultStatus.rejected
                  ? "top-link-active-red"
                  : "top-link-active-green"
                : ""
            }`}
          >
            COMPLIANCE AND COMPETENCY - PART 02/03
          </li>
          <li
            className={`nav-link top-link nav-border ${setClassForPart(2)}  ${
              PrefillDetails &&
              PrefillDetails.userDetail &&
              PrefillDetails.userDetail.onbordingPartCompleted > 2
                ? PrefillDetails.userDetail.onbordingPartCompleted == 3 &&
                  PrefillDetails.Status.key == defaultStatus.rejected
                  ? "top-link-active-red"
                  : "top-link-active-green"
                : ""
            }`}
          >
            BACKGROUND CHECK - PART 03/03
          </li>
        </ul>
      </div>

      {PrefillDetails &&
        PrefillDetails?.Status &&
        PrefillDetails?.Status?.key === defaultStatus?.rejected &&
        PrefillDetails?.userDetail &&
        PrefillDetails?.userDetail.rejectedFields &&
        PrefillDetails?.userDetail.rejectedFields !== null &&
        PrefillDetails?.userDetail.rejectedFields.reasonforRejection && (
          <div className="container-fluid col-11  mx-0   my-p-0 ">
            <div className="commonImage common_bar_bottom">
              <div className="mx-3 rejectionMessageMargin">
                <div>
                  <h6 className="cs-heading  px-0">Reason For Rejection</h6>
                  <span className=" ml-0 required_lable">
                    Please review the red highlighted fields
                  </span>
                </div>
                <textarea
                  className="discribe-input discribe_text mt-2"
                  type="textarea"
                  placeholder="Describe Other Reason"
                  disabled
                  value={
                    PrefillDetails?.userDetail.rejectedFields.reasonforRejection
                  }
                />
              </div>
            </div>
          </div>
        )}

      {toShow && (
        <div className="container-fluid col-11 m-0  my-p-0">
          <div
            className={`commonImage common_bar_bottom ${
              PrefillDetails &&
              PrefillDetails.Status &&
              PrefillDetails.Status.key === defaultStatus.rejected
                ? "pt-0"
                : ""
            }`}
          >
            {!pendingSteps ||
            pendingSteps.length === therapistSignupSteps.length ? (
              <Form
                onSubmit={handleSubmit}
                validate={validateForm}
                initialValues={initValue}
                render={({
                  handleSubmit,
                  submitting,
                  form: {
                    mutators: { push, pop },
                  },
                  values,
                  valid,
                  submitFailed,

                  form,
                }) => (
                  <form
                    onSubmit={(e) => {
                      if (!valid)
                        toast.error("Please complete all required fields.");
                      handleSubmit(e);
                    }}
                  >
                    <div className=" col-sm-4 d-flex align-items-center justify-content-between mt-5 ">
                      <h4 className="cs-heading m-0 p-0">Create Account</h4>
                      <h4 className="cs-date mb-0">
                        Step 01/<span className="pd-dt">08</span>
                      </h4>
                    </div>
                    <div className="col-md-12 ">
                      <div className="heading_Personal mt-4 mb-4">
                        <h1 className="cs-date">Personal Details</h1>
                      </div>
                      <div className="row d-flex flex-column">
                        <div className="col-md-11">
                          <div className="row ">
                            <div
                              className="col-md-6 d-flex "
                              style={{ gap: "4%" }}
                            >
                              <Field name="firstName">
                                {({ input, meta }) => (
                                  <div className="col-md-6 p-0">
                                    {/* {checkRejectedValue(values, 'firstName') && (
                                    <h6 className="rejected" style={{ color: "#c52525" }}>
                                      Rejected
                                    </h6>
                                  )} */}
                                    <input
                                      // className="cs-input  mb-4"
                                      className={
                                        checkRejectedValue(values, "firstName")
                                          ? "cs-input  mb-4 rejected_text"
                                          : "cs-input  mb-4"
                                      }
                                      {...input}
                                      type="text"
                                      placeholder={!meta.active && "First Name"}
                                    />
                                    <br />
                                    {submitFailed &&
                                      meta.error &&
                                      meta.touched && (
                                        <p
                                          className="alert alert-danger mb-2  top-21"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                  </div>
                                )}
                              </Field>
                              <Field name="lastName">
                                {({ input, meta }) => (
                                  <div className="col-md-6 p-0">
                                    {/* {checkRejectedValue(values, 'lastName') && (
                                    <h6 className="rejected" style={{ color: "#c52525" }}>
                                      Rejected
                                    </h6>
                                  )} */}
                                    <input
                                      className={
                                        checkRejectedValue(values, "lastName")
                                          ? "cs-input  mb-4 rejected_text"
                                          : "cs-input  mb-4"
                                      }
                                      {...input}
                                      type="text"
                                      placeholder={!meta.active && "Last Name"}
                                    />

                                    {submitFailed &&
                                      meta.error &&
                                      meta.touched && (
                                        <p
                                          className="alert alert-danger mb-2  top-21"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>

                          <div className="row">
                            <div
                              className="col-md-6 d-flex "
                              style={{ gap: "4%" }}
                            >
                              <Field name="email">
                                {({ input, meta }) => (
                                  <div className="col-md-6 p-0">
                                    {checkRejectedValue(values, "email") && (
                                      <h6
                                        className="rejected"
                                        style={{ color: "#c52525" }}
                                      >
                                        Rejected
                                      </h6>
                                    )}
                                    <input
                                      className="cs-input mb-4"
                                      {...input}
                                      type="text"
                                      placeholder={
                                        !meta.active && "Email Address"
                                      }
                                    />

                                    {submitFailed &&
                                      meta.error &&
                                      meta.touched && (
                                        <p
                                          className="alert alert-danger mb-2  top-21"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                  </div>
                                )}
                              </Field>
                              <Field name="password">
                                {({ input, meta }) => (
                                  <div className="col-md-6 p-0">
                                    {checkRejectedValue(values, "password") && (
                                      <h6
                                        className="rejected"
                                        style={{ color: "#c52525" }}
                                      >
                                        Rejected
                                      </h6>
                                    )}
                                    <input
                                      className="cs-input  mb-4"
                                      {...input}
                                      type="password"
                                      disabled={searchParams.get("auth")}
                                      placeholder={
                                        PrefillDetails && PrefillDetails.id
                                          ? "**************"
                                          : !meta.active && "Password"
                                      }
                                    />

                                    {submitFailed &&
                                      meta.error &&
                                      meta.touched && (
                                        <p
                                          className="alert alert-danger mb-2  top-21"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                  </div>
                                )}
                              </Field>
                              <Field name="confirmPassword">
                                {({ input, meta }) => (
                                  <div className="col-md-6 p-0">
                                    <input
                                      className="cs-input  mb-4"
                                      {...input}
                                      type="password"
                                      disabled={searchParams.get("auth")}
                                      placeholder={
                                        PrefillDetails && PrefillDetails.id
                                          ? "**************"
                                          : !meta.active && "Confirm Password"
                                      }
                                    />
                                    {submitFailed &&
                                      meta.error &&
                                      meta.touched && (
                                        <p
                                          className="alert alert-danger mb-2  top-21"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-md-6 d-flex "
                              style={{ gap: "4%" }}
                            >
                              <Field name="phone">
                                {({ input, meta }) => (
                                  <div className="col-md-6 p-0">
                                    {/* {checkRejectedValue(values, 'phone') && (
                                    <h6 className="rejected" style={{ color: "#c52525" }}>
                                      Rejected
                                    </h6>
                                  )} */}
                                    <input
                                      className={
                                        checkRejectedValue(values, "phone")
                                          ? "cs-input  mb-4 rejected_text"
                                          : "cs-input  mb-4"
                                      }
                                      {...input}
                                      type="text"
                                      onInput={(e) =>
                                        phoneNumberToUSFormat(e, input)
                                      }
                                      placeholder={!meta.active && "Phone"}
                                    />

                                    {submitFailed &&
                                      meta.error &&
                                      meta.touched && (
                                        <p
                                          className="alert alert-danger mb-2  top-26"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-11">
                          <div className="heading_Personal mt-2  mb-4">
                            <h1 className="cs-date">Address</h1>
                          </div>
                          <div className="row">
                            <div
                              className="col-md-6 d-flex "
                              style={{ gap: "4%" }}
                            >
                              <Field name="address">
                                {({ input, meta }) => (
                                  <div className="col-md-6 p-0">
                                    {/* {checkRejectedValue(values, "address") && (
                                      <h6
                                        className="rejected"
                                        style={{ color: "#c52525" }}
                                      >
                                        Rejected
                                      </h6>
                                    )} */}
                                    <AddressAutocomplete
                                      // className=" cs-input-h mb-3"
                                      className={
                                        checkRejectedValue(values, "address")
                                          ? "cs-input-h mb-3 rejected_text_border street_color_black"
                                          : "cs-input-h mb-3 street_color_black"
                                      }
                                      placeholder={!meta.active && "address"}
                                      selectResult={(result) =>
                                        selectResult(result, form.change)
                                      }
                                      selectedAddress={values?.address}
                                    />
                                    {submitFailed &&
                                      meta.error &&
                                      meta.touched && (
                                        <p
                                          className="alert alert-danger mb-2 top-11"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                  </div>
                                )}
                              </Field>

                              <Field name="aptUnit">
                                {({ input, meta }) => (
                                  <div className="col-md-6 p-0">
                                    <input
                                      className="cs-input  mb-4"
                                      {...input}
                                      type="text"
                                      placeholder={
                                        !meta.active &&
                                        "Unit/Apt # (if applicable)"
                                      }
                                    />
                                    <br />
                                    {submitFailed &&
                                      meta.error &&
                                      meta.touched && (
                                        <p
                                          className="alert alert-danger mb-2  top-21"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-md-6 d-flex"
                              style={{ gap: "4%" }}
                            >
                              <Field name="city">
                                {({ input, meta }) => (
                                  <div className="col-md-6 p-0">
                                    <input
                                      // className="cs-input  mb-4 "
                                      className={
                                        checkRejectedValue(values, "address")
                                          ? "cs-input  mb-4 rejected_text"
                                          : "cs-input  mb-4"
                                      }
                                      {...input}
                                      type="text"
                                      placeholder={!meta.active && "City"}
                                    />
                                    <br />
                                    {submitFailed &&
                                      meta.error &&
                                      meta.touched && (
                                        <p
                                          className="alert alert-danger mb-2  top-21"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                  </div>
                                )}
                              </Field>
                              <Field name="state">
                                {({ input, meta }) => (
                                  <div className="col-md-6 p-0">
                                    <input
                                      // className="cs-input  mb-3"
                                      className={
                                        checkRejectedValue(values, "address")
                                          ? "cs-input  mb-4 rejected_text"
                                          : "cs-input  mb-4"
                                      }
                                      {...input}
                                      type="text"
                                      placeholder={!meta.active && "State"}
                                    />
                                    <br />
                                    {submitFailed &&
                                      meta.error &&
                                      meta.touched && (
                                        <p className="alert alert-danger mb-2 error-b">
                                          {meta.error}
                                        </p>
                                      )}
                                  </div>
                                )}
                              </Field>
                              <Field name="zip">
                                {({ input, meta }) => (
                                  <div className="col-md-6 p-0">
                                    <input
                                      // className="cs-input  mb-3"
                                      className={
                                        checkRejectedValue(values, "address")
                                          ? "cs-input  mb-4 rejected_text"
                                          : "cs-input  mb-4"
                                      }
                                      {...input}
                                      type="text"
                                      placeholder={!meta.active && "Zip"}
                                      onInput={(e) => formatPinCode(e, input)}
                                    />
                                    <br />
                                    {submitFailed &&
                                      meta.error &&
                                      meta.touched && (
                                        <p className="alert alert-danger mb-2 error-b">
                                          {meta.error}
                                        </p>
                                      )}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                        </div>
                      </div>
                      <SignatureCanvas />
                    </div>
                    <div className="col-md-12 ">
                      <div
                        className="button   mb-4"
                        style={{ marginTop: "2%" }}
                      >
                        <Button
                          type="submit"
                          className="last-btn"
                          disabled={submitting}
                        >
                          Save & Continue
                        </Button>
                      </div>
                    </div>
                  </form>
                )}
              />
            ) : (
              myObj[pendingSteps[0]]
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default TherapistSignup;
