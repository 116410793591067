import React, { useReducer, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import userImage from "../../../images/usertable02.svg";
import { useSelector } from "react-redux";
import AcceptModal from "./AcceptModal";
import OfferModal from "./OfferModal";
import { apiBasePath } from "../../../Redux/config/config";
import {
  dateFormatChange,
  staffViewFromTherapist,
  statusBaseColorChange,
} from "../../../utils/helper";
import icon from "../../../images/AdminDashboard/agency_icon.svg";
import { currentRole, defaultStatus } from "../../../utils/auth";
import { CommonReferralInfo } from "../../../components/common/CommonReferralInfo";
import { ViewVisitRate } from "../../../components/common/ViewVisitRates";
import { toast } from "react-toastify";
import locationIcon from "../../../images/location-icon.svg";

const ViewModal = (props) => {
  const { columnsToView } = props;
  const initialState = {
    offerModal: false,
    acceptModal: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "offerModal":
        return { ...state, offerModal: action.value };
      case "acceptModal":
        return { ...state, acceptModal: action.value };
      default:
        return state;
    }
  };
  const [modal, reducerDispatch] = useReducer(reducer, initialState);
  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const [dataError, setDateError] = useState(false);
  const [acceptedDate, setAcceptedDate] = useState(null);
  let defaultRates = useSelector((e) => e.therapistSigunpSlice.defaultRates);

  const modalHandler = (modal) => {
    if (acceptedDate !== null) {
      reducerDispatch({ type: modal, value: true });
      setDateError(false);
    } else {
      setDateError(true);
    }
  };

  const handleClose = (e) => {
    reducerDispatch({ type: e, value: false });
  };
  const declineStaff = () => {
    let obj = {
      role: currentRole["supervisingtherapist"],
      status: defaultStatus.rejected,
      staffUserId: staffDetail.id,
      acceptedDate: acceptedDate,
    };
    props.updateStatus(obj);
  };

  const dateFormat = (data) => {
    const today = new Date();
    const givendate = new Date(data);
    if (today < givendate) {
      return givendate.toISOString().split("T")[0];
    } else if (today > givendate) {
      return today.toISOString().split("T")[0];
    }
    return today;
  };

  return (
    <>
      <div className="d-flex">
        <div className="container-fluid">
          <div className="row bg-white-user d-flex justify-content-between">
            <div className="col-md-5 d-flex desktop-gap gap-4 mobile_justify-start mobile_wrap  ">
              <img
                className="userimge1"
                crossOrigin="anonymous"
                src={
                  staffDetail && staffDetail.image
                    ? `${apiBasePath}documents/therapist/${staffDetail.image}`
                    : userImage
                }
                alt="img"
              />
              <div className="d-flex flex-column justify-content-start FindPatientsdesktopMarginTop">
                <div className="mb-3">
                  <h4 className="username-1 text-start">
                    {staffDetail?.lastName}, {staffDetail?.firstName}
                  </h4>
                  <p className="title-24 m-0">
                    MR #{staffDetail && staffDetail?.staffingDetails?.mrn}
                  </p>
                </div>
                <div className=" d-flex mobile_justify-start  gap-3 align-items-start">
                  <img
                    src={locationIcon}
                    className="Common-referral-icons"
                    alt=""
                  />
                  <div className="d-flex flex-column">
                    <p className="agency-p-1-h m-0">
                      {staffDetail &&
                      staffDetail.staffingDetails &&
                      staffDetail.staffingDetails.addressType === "yes"
                        ? " Facility Address "
                        : "Address"}
                    </p>
                    {staffDetail && staffDetail.staffingDetails && (
                      <>
                        <p className="m-0 font-size_16">
                          {staffDetail.staffingDetails["address"]
                            ? staffDetail.staffingDetails["address"]
                            : ""}
                        </p>
                        <p className="m-0 font-size_16">
                          {staffDetail.staffingDetails["city"]
                            ? staffDetail.staffingDetails["city"]
                            : ""}
                          {", "}
                          {staffDetail.staffingDetails["state"]
                            ? staffDetail.staffingDetails["state"]
                            : ""}
                          {", "}
                          {staffDetail.staffingDetails["zip"]
                            ? staffDetail.staffingDetails["zip"]
                            : ""}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-5 col-xl-5 col-xxl-4 mobile_margin-top FindPatientsdesktopMarginTop">
              <div className="row justify-content-end">
                <div
                  className={`col-md-12 d-flex flex-column mobile-margin-top ${
                    staffDetail?.staffUser?.Status?.key ===
                    defaultStatus.rejected
                      ? "justify-content-end align-items-end"
                      : ""
                  }`}
                >
                  {staffDetail?.staffUser?.Status?.key ===
                    defaultStatus["pending"] && (
                    <div
                      className=" mobile_justify mobile_wrap "
                      style={{
                        display: "flex",
                        gap: "5%",
                        flexDirection: "column",
                      }}
                    >
                      <div className="d-flex" style={{ gap: "5%" }}>
                        {currentUser &&
                          currentUser.Role &&
                          (currentUser.Role.key ===
                            currentRole.supervisingtherapist ||
                            currentUser.Role.key ===
                              currentRole.assistanttherapist) && (
                            <button
                              className="make-offer-btn btn-width-1 mobile_width mobile-margin-bottom "
                              onClick={() => modalHandler("offerModal")}
                            >
                              Make an Offer
                            </button>
                          )}

                        <button
                          className="accept-btn mobile_width mobile-margin-bottom "
                          onClick={() => modalHandler("acceptModal")}
                        >
                          Accept
                        </button>
                        <button
                          className="decline-btn mobile_width mobile-margin-bottom "
                          onClick={declineStaff}
                        >
                          Decline
                        </button>
                      </div>
                      <div className="form-group m-0 EarliestAvailability">
                        <>
                          <label>
                            Earliest Availability for Initial Visit:
                          </label>
                          <input
                            className="desktop-50 mobile-width"
                            style={{ height: "46px" }}
                            type="date"
                            onChange={(e) => {
                              setDateError(false);
                              setAcceptedDate(dateFormatChange(e.target.value));
                            }}
                            placeholder="DD/MM/YYYY"
                            min={dateFormat(
                              staffDetail &&
                                Object.keys(staffDetail).length > 0 &&
                                staffDetail.staffInitialVisit.length > 0 &&
                                staffDetail.staffInitialVisit[0]
                                  .anticipatedSocDate
                            )}
                          />
                          {dataError && (
                            <p className="alert desktop-50 alert-danger">
                              Please select your earliest available day to
                              complete the initial visit requested.
                            </p>
                          )}
                        </>
                      </div>
                    </div>
                  )}
                  {staffDetail?.staffUser?.Status?.key ===
                    defaultStatus.rejected && (
                    <button
                      className={`decline-btn mobile_width
                       ${statusBaseColorChange(
                         staffDetail?.staffUser?.Status?.key
                       )}`}
                    >
                      Declined
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-5 pl-5 pr-5">
        <div className="row">
          <Accordion defaultActiveKey={["1", "2"]} flush alwaysOpen>
            <Accordion.Item className="border-0 mb-3" eventKey="1">
              <Accordion.Header className="Uheader-1 ">
                Referral Info
              </Accordion.Header>
              <Accordion.Body className="Uheader-1 p-3">
                <CommonReferralInfo
                  dataColumns={
                    columnsToView ? columnsToView : staffViewFromTherapist
                  }
                  mainClass=""
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="border-0 mb-3" eventKey="2">
              <Accordion.Header className="Uheader-1 ">Rates</Accordion.Header>
              <Accordion.Body className="Uheader-1 p-3">
                <ViewVisitRate
                  defaultRates={defaultRates}
                  userRates={staffDetail?.userRates}
                  disciplinesNeeded={
                    staffDetail &&
                    staffDetail.staffingDetails &&
                    staffDetail.staffingDetails.disciplinesNeeded
                  }
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        {modal.offerModal && (
          <OfferModal
            show={modal.offerModal}
            handleClose={handleClose}
            updateStatus={props.updateStatus}
            modalClass="th-staffaccept accept-modal"
            acceptedDate={acceptedDate}
          />
        )}
        {modal.acceptModal && (
          <AcceptModal
            show={modal.acceptModal}
            handleClose={handleClose}
            updateStatus={props.updateStatus}
            modalClass="th-staffaccept accept-modal"
            acceptedDate={acceptedDate}
          />
        )}
      </div>
    </>
  );
};

export default ViewModal;
