import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNewMessage,
  messagePaginator,
  messagePrevPage,
} from "../../../../Redux/actions/chat";
import { getUserId } from "../../../../utils/auth";
import {
  MessageStatusName,
  chatIconsByRole,
  timeStamp,
  urgentTypeArray,
} from "../../../../utils/helper";
import { apiBasePath } from "../../../../Redux/config/config";
import React, { useEffect, useState } from "react";
import { setChatLoader } from "../../../../Redux/slices/chatSlice";

export default function CommNotes(props) {
  const { downloadChat, addItemToDownload, selectedMessages, setActiveIndex } =
    props;
  const userid = getUserId().userId;
  const [messageSize] = useState(10);
  const dispatch = useDispatch();
  const activeConversation = useSelector(
    (state) => state.chatSlice.activeConversation
  );

  const paginator = useSelector((state) => state.chatSlice.messagePaginator);
  const messagePaginatorItem = useSelector(
    (state) => state.chatSlice.messagePaginator.items
  );

  const handleNewMessage = (newMsg) => {
    setActiveIndex(newMsg?.index);
    dispatch(AddNewMessage(newMsg));
    if (activeConversation) {
      activeConversation.advanceLastReadMessageIndex(newMsg?.index);
      activeConversation.updateLastReadMessageIndex(newMsg?.index);
    }
  };

  const loadMessages = (TwilioConversation) => {
    const lastMessage = TwilioConversation.lastMessage;
    if (lastMessage) {
      TwilioConversation.advanceLastReadMessageIndex(lastMessage?.index);
      TwilioConversation.updateLastReadMessageIndex(lastMessage?.index);
    }
    TwilioConversation.getMessages(messageSize).then((paginator) => {
      dispatch(messagePaginator(paginator));
      setActiveIndex(lastMessage?.index);
    });

    TwilioConversation.on("messageAdded", handleNewMessage);
  };

  const isActive = (messageIndex) => {
    if (selectedMessages) {
      const isExist = selectedMessages.find(
        (item) => item.index === messageIndex
      );
      return isExist ? true : false;
    }
  };

  const addChatItemToDownload = (messageObject) => {
    const Obj = {
      index: messageObject.index,
      name: messageObject.attributes?.name,
      dateCreated: messageObject.dateCreated,
      messageBody: messageObject.body,
      attributes: messageObject.attributes,
    };
    addItemToDownload(Obj);
  };

  const messagePagination = () => {
    if (paginator && paginator.hasPrevPage) {
      dispatch(setChatLoader(true));
      paginator.prevPage().then((res) => {
        const lastIndex = res.items[res.items.length - 1]?.index;
        setActiveIndex(lastIndex);
        dispatch(messagePrevPage(res));
      });
    }
  };
  const handleScroll = (e) => {
    const element = e.target;
    if (element.scrollTop === 0) {
      messagePagination();
    }
  };

  useEffect(() => {
    if (activeConversation) {
      loadMessages(activeConversation);
    }
    return () => {
      if (activeConversation) {
        activeConversation.removeListener("messageAdded", handleNewMessage);
      }
    };
  }, [activeConversation]);

  const renderTextWithLineBreaks = (messageBody) => {
    const lines = messageBody.split("\n");
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  return (
    <div className="pl-5 pr-5 pt-0 pb-0">
      <div
        className="scroll_0"
        id="myDiv"
        style={{
          height: "60vh",
          overflowY: "auto",
          overflowAnchor: "none",
        }}
        onScroll={handleScroll}
      >
        {messagePaginatorItem && messagePaginatorItem.length > 0 ? (
          messagePaginatorItem.map((chatItem, chatIndex) =>
            Number(chatItem.author) === userid ? (
              //Right Side
              <div
                className={`d-flex ${
                  downloadChat
                    ? "justify-content-between"
                    : " justify-content-end"
                }   margin-bottom-40 gap-3 left-side_msg`}
                id={`ActiveMessage_${chatItem.index}`}
                key={`Message_${chatIndex}`}
              >
                {downloadChat && (
                  <label className="container-card check-size align-items-center d-flex padding-left-ref ref-height ">
                    <input
                      type="checkbox"
                      onChange={() => addChatItemToDownload(chatItem)}
                      checked={isActive(chatItem.index)}
                    />
                    <span className=" checkmark1 "></span>
                  </label>
                )}
                <div className={`d-flex w-50 gap-3 justify-content-end mt-3`}>
                  <Card className={`border-0`}>
                    <Card.Header className="text-end bg-white border-0 p-1">
                      <p className="text-muted small mb-0">
                        {" "}
                        {` ${timeStamp(chatItem.dateCreated)?.date} At ${
                          timeStamp(chatItem.dateCreated)?.time
                        }`}
                      </p>
                    </Card.Header>
                    <Card.Body className="pr-0 pt-0">
                      <p className="mb-0 color brdr">
                        {renderTextWithLineBreaks(chatItem.body)}
                      </p>
                    </Card.Body>
                  </Card>
                  <img
                    src={
                      chatItem.attributes && chatItem.attributes.image
                        ? `${apiBasePath}documents/therapist/${chatItem.attributes.image}`
                        : chatIconsByRole[chatItem?.Role?.key]
                    }
                    alt="avatar"
                    crossOrigin="anonymous"
                    style={{ objectFit: "cover" }}
                    className=" rounded-circle d-flex align-self-start shadow-1-strong "
                    width="40px"
                    height="40px"
                  />
                </div>
              </div>
            ) : (
              <div
                className={`d-flex justify-content-start margin-bottom-40 gap-3 right-side_msg  mt-3`}
                id={`ActiveMessage_${chatItem.index}`}
                key={`Chat_Message_${chatIndex}`}
              >
                {/* message received  --- Left Side  */}
                {downloadChat && (
                  <label className="container-card check-size align-items-center d-flex padding-left-ref ref-height ">
                    <input
                      type="checkbox"
                      onChange={() => addChatItemToDownload(chatItem)}
                      checked={isActive(chatItem.index)}
                    />
                    <span className=" checkmark1 "></span>
                  </label>
                )}
                <img
                  src={
                    chatItem.attributes && chatItem.attributes.image
                      ? `${apiBasePath}documents/therapist/${chatItem.attributes.image}`
                      : chatIconsByRole[chatItem.attributes?.Role?.key]
                  }
                  alt="avatar"
                  crossOrigin="anonymous"
                  width="40px"
                  height="40px"
                  style={{ objectFit: "cover" }}
                  className=" rounded-circle d-flex align-self-start shadow-1-strong small_chat_img"
                />
                <Card className="border-0">
                  <Card.Header className="bg-white border-0 p-1">
                    <p className="user-msg mb-0">
                      {`${chatItem.attributes.name}`} &nbsp;
                      {chatItem.attributes &&
                        chatItem.attributes?.discipline && (
                          <>
                            |{" "}
                            <span className="pt">
                              {" "}
                              {chatItem.attributes?.discipline.toUpperCase()}
                            </span>
                          </>
                        )}
                      {chatItem.attributes &&
                        chatItem.attributes.subjectType &&
                        urgentTypeArray.includes(
                          chatItem.attributes.subjectType
                        ) && <span className="urgent ml-3">URGENT</span>}
                    </p>
                    <p className="text-muted small mb-0">
                      {` ${timeStamp(chatItem.dateCreated)?.date} At ${
                        timeStamp(chatItem.dateCreated)?.time
                      }`}
                      {chatItem.attributes &&
                        chatItem.attributes?.subjectType && (
                          <span className="right_side_muted_txt ml-4">
                            {
                              MessageStatusName[
                                chatItem.attributes?.subjectType
                              ]?.displayName
                            }
                          </span>
                        )}
                    </p>
                  </Card.Header>
                  <Card.Body className={`pl-0 pt-0 `}>
                    <p
                      className={`mb-0 ${
                        chatItem.attributes &&
                        chatItem.attributes.subjectType &&
                        urgentTypeArray.includes(
                          chatItem.attributes.subjectType
                        )
                          ? "solid_border"
                          : "brdr"
                      } `}
                    >
                      {renderTextWithLineBreaks(chatItem.body)}
                    </p>
                  </Card.Body>
                </Card>
              </div>
            )
          )
        ) : (
          <div className="w-100 h-100 position-relative d-flex align-items-center justify-content-center">
            <h2>No Message To Show Start Chatting Now</h2>
          </div>
        )}
      </div>
    </div>
  );
}
