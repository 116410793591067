import Modal from "react-bootstrap/Modal";
import React, { useCallback, useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../components/PaymentCheckoutForm";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import checkTik from "../../images/check.png";
import errorIcon from "../../images/mark.png";

const stripePromise = loadStripe(
  "pk_test_51NdHDAHFIEFD9veUOPIB49SdJe6ECQc6upUlHEZeIXExpAV6cE9hrQedvUStwsnlEoH3FlBnwJz6efPN42MTu7w200jiRy0Krs"
);

export const StripePayment = (props) => {
  const { show, onHide } = props;
  const navigate = useNavigate();
  const stripeInstantPayment = useSelector(
    (e) => e.paymentSlice.stripeInstantPayment
  );
  const stripeInstantPaymentError = useSelector(
    (e) => e.paymentSlice.stripeInstantPaymentError
  );
  const handleClose = () => {
    navigate("/accounting");
    onHide({ modal: false });
  };

  const elementsOptions = {
    mode: "payment",
    amount: show.price,
    currency: "usd",
    metadata: {
      invoiceNumber: show.invoiceNumber,
      staffInvoiceId: show.staffInvoiceId,
    },
    paymentMethodCreation: "manual",
    payment_method_types: ["card", "us_bank_account", "amazon_pay"],
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  return (
    <>
      <Modal
        size="lg"
        className=""
        show={show.modal}
        onHide={() => handleClose()}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          className="closeButton border-0"
          closeButton
        ></Modal.Header>
        <Modal.Body className="p-0" style={{ minHeight: "500px" }}>
          {stripeInstantPaymentError ? (
            <div className="stripe-error">
              <div className="col-lg-12 error-img">
                {" "}
                <img src={errorIcon} alt="error" />
              </div>
              <div className="col-lg-12 msg">{stripeInstantPaymentError}</div>
            </div>
          ) : show && show.retrievePayment ? (
            stripeInstantPayment && stripeInstantPayment.status ? (
              <div className="stripe-status">
                <div className="col-lg-12 check-img">
                  {" "}
                  <img src={checkTik} alt="check" />
                </div>
                <div className="col-lg-12 status">
                  {" "}
                  {stripeInstantPayment.status}
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            <div id="checkout" className="payment-form">
              <Elements stripe={stripePromise} options={elementsOptions}>
                <CheckoutForm show={show} />
              </Elements>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
