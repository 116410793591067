import React, { useState } from "react";
import * as yup from "yup";
import { Form, Field } from "react-final-form";
import { validateForm } from "../../../validation/validateForm";
import { useDispatch, useSelector } from "react-redux";
import {
  agencySignupSteps,
  currentRole,
  getUserId,
  paymentTypes,
} from "../../../utils/auth";
import AddressAutocomplete from "../../../components/AddressAutoComplete";
import {
  updatePendingSteps,
  userPaymentDetail,
} from "../../../Redux/actions/therapist";
import Select from "react-select";
import {
  createPayment,
  stripeAccountLink,
} from "../../../Redux/actions/payment";
import { toast } from "react-toastify";
import { MyModal2 } from "../../modals/MyModal2";
import PaymentMethod from "../../modals/PaymentMethod";
import { useLocation } from "react-router-dom";
import { BiPencil } from "react-icons/bi";
import stripe from "../../../images/stripe.svg";
import accordian_collapse_icon from "../../../images/accordian_collapse_icon.svg";
import { ClearButton } from "react-bootstrap-typeahead";
import { IoSyncCircleOutline } from "react-icons/io5";
import { getUserDetailsById } from "../../../Redux/actions/auth";
import Loader from "../../../components/Loader";

function Paymentdetails(props) {
  const { popUpModal, pristine } = props;
  const dispatch = useDispatch();
  let decoded = getUserId();

  const [paymentMethodAdd, setPaymentMethod] = useState(false);
  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const isLoading = useSelector((e) => e.therapistSigunpSlice.isLoading);

  function handleSubmit(values) {
    if (PrefillDetails && PrefillDetails?.id) {
      values.userId = PrefillDetails.id;
    }
    let myobj = {};
    if (props && props.isProfile) {
      myobj.isProfile = true;
    }

    if (values && values.types === paymentTypes.creditCard) {
      myobj.cardHolder = values.cardHolder;
      myobj.cardNumber = values.cardNumber;
      myobj.expirationDate = values.expirationDate;
      myobj.cvv = values.cvv;
      myobj.suiteUnit = values.suiteUnit;
      myobj.types = values.types;
      myobj.state = values.state;
      myobj.address = values.address;
      myobj.street = values.street;
      myobj.country = values.country;
      myobj.zip = values.zip;
      myobj.latitude = values.latitude;
      myobj.longitude = values.longitude;
      myobj.addressDetails = values.addressDetails;
      myobj.userId = values.userId;
      myobj.city = values.city;
    } else if (values.types === paymentTypes.bankAccount) {
      myobj.cardHolder = values.accountHolder;
      myobj.cardNumber = values.accountNumber;
      myobj.routingNumber = values.routingNumber;
      myobj.accountType = values.accountType.value;
      myobj.userId = values.userId;
      myobj.types = values.types;
    }

    let isValid = false;
    if (!props.isProfile && !popUpModal) {
      isValid = false;
    }
    if (
      PrefillDetails &&
      (PrefillDetails.stripeAccountId ||
        PrefillDetails.stripeTransferEnabled ||
        PrefillDetails.stripeDetailSubmitted)
    ) {
      isValid = true;
    }

    if (isValid) {
      dispatch(userPaymentDetail(myobj)).then((res) => {
        if (res) {
          props.setTabUpdateCheker && props.setTabUpdateCheker();
        }
      });
    } else {
      toast.error(
        "Please connect to Stripe or Skip this step for now to proceed."
      );
    }
  }

  let defalutPayment =
    PrefillDetails && PrefillDetails["paymentDetail"]
      ? PrefillDetails["paymentDetail"]
      : [];

  const myInitialValues = {
    cardHolder: defalutPayment.length > 0 ? defalutPayment[0].cardHolder : "",
    cardNumber: defalutPayment.length > 0 ? defalutPayment[0].cardNumber : "",
    expirationDate:
      defalutPayment.length > 0 ? defalutPayment[0].expirationDate : "",
    cvv: defalutPayment.length > 0 ? defalutPayment[0].cvv : "",
    address: defalutPayment.length > 0 ? defalutPayment[0].address : "",
    city: defalutPayment.length > 0 ? defalutPayment[0].city : "",
    state: defalutPayment.length > 0 ? defalutPayment[0].state : "",
    zip: defalutPayment.length > 0 ? defalutPayment[0].zip : "",
    suiteUnit: defalutPayment.length > 0 ? defalutPayment[0].suiteUnit : "",
    types:
      defalutPayment.length > 0
        ? defalutPayment[0].types
        : paymentTypes.creditCard,
    accountHolder:
      defalutPayment.length > 0 ? defalutPayment[0].cardHolder : "",
    accountNumber:
      defalutPayment.length > 0 ? defalutPayment[0].cardNumber : "",
    routingNumber:
      defalutPayment.length > 0 ? defalutPayment[0].routingNumber : "",
    accountType: defalutPayment.length > 0 &&
      defalutPayment[0]?.accountType && {
        value: defalutPayment[0]?.accountType,
        label: defalutPayment[0]?.accountType,
      },
  };

  const selectResult = (result, setValue) => {
    if (result) {
      setValue("state", result.state);
      setValue("address", result.address);
      setValue("street", result.street);
      setValue("country", result.country);
      setValue("city", result.city);
      setValue("zip", result.postal_code);
      setValue("latitude", result.latitude);
      setValue("longitude", result.longitude);
      setValue("addressDetails", result?.addressDetails);
    }
  };
  const typeOptions = [
    { value: "saving", label: "Saving" },
    { value: "current", label: "Current" },
  ];

  const styles = {
    control: (base) => ({
      ...base,
      fontFamily: "Times New Roman",
      fontSize: "16px",
      paddingLeft: "10px",
    }),
    menu: (base) => ({
      ...base,
      fontFamily: "Times New Roman",
      fontSize: "16px",
    }),
  };

  const validationSchema = (values) => {
    let errors = {};
    if (values.types === paymentTypes.creditCard) {
      if (!values.cardHolder) {
        errors["cardHolder"] = "Please enter card holder name.";
      }
      if (!values.cardNumber) {
        errors["cardNumber"] = "Please enter your card number.";
      }
      if (values.cardNumber && values.cardNumber.match(/[a-zA-Z]/)) {
        errors["cardNumber"] = "Card number can not have alphabets.";
      }
      if (!values.expirationDate) {
        errors["expirationDate"] = "Please enter expiry date.";
      }
      if (values.expirationDate && values.expirationDate.match(/[a-zA-Z]/)) {
        errors["expirationDate"] = "Please enter numbers only.";
      }
      if (
        values.expirationDate &&
        !values.expirationDate.match(/[a-zA-Z]/) &&
        !values.expirationDate.match(/([0-9]{2})\/([0-9]{2})/)
      ) {
        errors["expirationDate"] = "Please use MM/YY format.";
      }
      if (
        values.expirationDate &&
        !values.expirationDate.match(/[a-zA-Z]/) &&
        values.expirationDate.match(/([0-9]{2})\/([0-9]{2})/) &&
        values.expirationDate.length > 5
      ) {
        errors["expirationDate"] = "Maximum 5 digits allowed.";
      }
      if (!values.cvv) {
        errors["cvv"] = "Please enter cvv.";
      }
      if (values.cvv && values.cvv.length <= 2) {
        errors["cvv"] = "Please enter a valid cvv.";
      }
      if (values.cvv && values.cvv.length > 2 && values.cvv.length >= 5) {
        errors["cvv"] = "Please enter a valid cvv.";
      }
      if (!values.address) {
        errors["address"] = "Please enter address.";
      }
      if (!values.state) {
        errors["state"] = "Please enter state.";
      }
      if (!values.city) {
        errors["city"] = "Please enter city.";
      }
      if (!values.zip) {
        errors["zip"] = "Please enter zip.";
      }
    } else if (values.types === paymentTypes.bankAccount) {
      if (!values.accountHolder) {
        errors["accountHolder"] = "Please enter account holder name.";
      }
      if (!values.accountNumber) {
        errors["accountNumber"] = " Please enter account number.";
      }
      if (values.accountNumber && values.accountNumber.match(/[a-zA-Z]/)) {
        errors["accountNumber"] = "Please enter numbers only.";
      }
      if (!values.routingNumber) {
        errors["routingNumber"] = "Please enter routing number.";
      }
      if (values.routingNumber && values.routingNumber.match(/[a-zA-Z]/)) {
        errors["routingNumber"] = "Please enter numbers only.";
      }
      if (!values.accountType) {
        errors["accountType"] = "Please select your account type.";
      }
    }
    return errors;
  };
  const location = useLocation();
  const handleStripeLogin = () => {
    const apiObject = {
      stripeAccountId: popUpModal
        ? currentUser && currentUser.stripeAccountId
        : PrefillDetails && PrefillDetails.stripeAccountId,
      userId: popUpModal ? currentUser && currentUser.id : PrefillDetails.id,
      email: popUpModal
        ? currentUser && currentUser.email
        : PrefillDetails.email,
      stripeDetailSubmitted: popUpModal
        ? currentUser && currentUser.stripeDetailSubmitted
        : PrefillDetails.stripeDetailSubmitted,
      returnLink: location.pathname + location.search,
    };
    dispatch(stripeAccountLink(apiObject)).then((response) => {
      if (
        response &&
        response.payload &&
        response.payload.success &&
        response.payload.accountLink
      ) {
        if (!response.payload.accountExist)
          window.location.href = response.payload.accountLink;
      } else {
        toast.error("Error with stripe.");
      }
    });
  };
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={myInitialValues}
        render={({
          handleSubmit,
          submitting,
          form,
          valid,
          values,
          pristine,
        }) => (
          <form
            className=" py- px-0"
            onSubmit={(e) => {
              if (!valid) {
                toast.error("Please complete all required fields.");
              }
              handleSubmit(e);
            }}
          >
            {props.isProfile &&
              props.pristineCheck &&
              props.pristineCheck(pristine)}
            <div className="container-fluid">
              <div
                className={`row ${popUpModal ? "justify-content-center" : ""}`}
              >
                {!props.noHheading && (
                  <h2 className="cs-heading set-head px-0 connect_title">
                    Connect With Stripe
                  </h2>
                )}
                <div
                  className={` p-0 ${
                    popUpModal
                      ? "col-sm-8  connect_strip_cls"
                      : "col-12 col-md-8"
                  }`}
                >
                  <div className="connect_main_strip ">
                    <p className="stripe-text">
                      {PrefillDetails &&
                      PrefillDetails.stripeAccountId &&
                      PrefillDetails.stripeTransferEnabled &&
                      PrefillDetails.stripeDetailSubmitted
                        ? "You are now connected with Stripe."
                        : "Please connect your Stripe account or create a new one for seamless payment processing."}
                      <br></br>
                      {PrefillDetails &&
                      PrefillDetails.stripeAccountId &&
                      PrefillDetails.stripeTransferEnabled &&
                      PrefillDetails.stripeDetailSubmitted
                        ? ""
                        : !popUpModal &&
                          "You may skip this step for now and complete it once your account is approved. Please note that you will not be able to use the platform until a Stripe account has been connected."}
                    </p>
                    {PrefillDetails &&
                    PrefillDetails.stripeAccountId &&
                    PrefillDetails.stripeTransferEnabled &&
                    PrefillDetails.stripeDetailSubmitted ? (
                      <div className="strip_connectes_inner d-flex align-items-center justify-content-between flex-wrap">
                        <div className="col-md-9 col-12 d-flex align-items-center">
                          <img src="/image/strip_img.svg" />
                          <div className="ml-4 txt_connect_">
                            <img src="/image/strip_txt.svg" />
                            <p className="mb-0">Connected</p>
                          </div>
                        </div>
                        <div className="col-md-3 col-12">
                          <img src="/image/check-icon.svg" />
                        </div>
                      </div>
                    ) : (
                      <div
                        class="strip_connectes_inner cursor-pointer strip_connectes_main d-flex align-items-center justify-content-between mb-4"
                        onClick={() => handleStripeLogin()}
                      >
                        <div class="col-sm-3">
                          <img src="/image/strip_img.svg" />
                        </div>
                        <div class="col-sm-7">
                          <p class="mb-0">
                            Connect With{" "}
                            <span className="stripe_connent_text">Stripe</span>
                          </p>
                        </div>
                        <div class="col-sm-2 pl-0">
                          <img src={accordian_collapse_icon} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className={
                    popUpModal ? "col-md-12 p-0 my-5" : "col-md-8 p-0 mt-5"
                  }
                >
                  {!props.isProfile && (
                    <div className=" col-md-12 d-flex justify-content-between mt-3 gap-5 flex-wrap">
                      <div style={{ display: "flex" }} className="btfn gap-5">
                        <button
                          type="submit"
                          disabled={submitting}
                          className="ad-btnn col-sm-2 justify-content-center ad_cls_btn"
                        >
                          Save & Continue
                        </button>
                        <button
                          type="button"
                          className="ad-btn-back"
                          onClick={() =>
                            props.goBackFunction(agencySignupSteps[2])
                          }
                        >
                          Back
                        </button>
                      </div>
                      <div style={{ display: "flex" }} className="btfn gap-5">
                        {PrefillDetails &&
                          (!PrefillDetails.stripeAccountId ||
                            !PrefillDetails.stripeTransferEnabled ||
                            !PrefillDetails.stripeDetailSubmitted) && (
                            <button
                              type="button"
                              className="ad-btn-back"
                              onClick={() => {
                                // toast.info('Please complete this step or skip to move to the next step.')
                                dispatch(
                                  updatePendingSteps({
                                    isSkip: true,
                                    userId: PrefillDetails.id,
                                    user: PrefillDetails.Role.key,
                                  })
                                ).then((res) => {
                                  dispatch(
                                    getUserDetailsById(`${PrefillDetails.id}`)
                                  );
                                });
                              }}
                            >
                              Skip
                            </button>
                          )}
                      </div>
                    </div>
                  )}
                  {props.isProfile && props.popUpModal && (
                    <div
                      style={{ display: "flex" }}
                      className="btfn gap-4 justify-content-center flex-wrap "
                    >
                      <button
                        type="submit"
                        disabled={submitting || pristine}
                        onClick={() => handleStripeLogin()}
                        className={`ad-btnn justify-content-center ad_cls_btn ${
                          pristine ? "opacity-50" : ""
                        }`}
                      >
                        Save & Continues
                      </button>
                      <button
                        type="button"
                        className="ad-btn-back"
                        onClick={props.handleClose}
                      >
                        Back
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      />
      <div></div>
    </>
  );
}

export default Paymentdetails;
