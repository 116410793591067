import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { BsCheck2 } from "react-icons/bs";
import { AiOutlineClose, AiFillFileText } from "react-icons/ai";
import { apiBasePath } from "../../Redux/config/config";
import { useDispatch, useSelector } from "react-redux";
import { userCredentialUpdate } from "../../Redux/actions/admin";
import { getAllUserDetails } from "../../Redux/actions/admin";
import { currentRole } from "../../utils/auth";
const colors = {
  "active": { background: "#D9F8DC", color: "#17A325" },
  "expired": { background: "#FDDCDC", color: "#D42B2B" },
  "rejected": { background: "#FDDCDC", color: "#D42B2B" },
  "pendingreview": { background: "#FDEDDC", color: "#CA7718" },
  "inactive": { background: "#cfd8dc", color: "#808D98" },
};

const TherapistCredentialsModal = ({ userList, onReject, onboardingPdf }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const isAdmin = currentUser?.Role?.key === currentRole?.admin;
  const isAgency = currentUser?.Role?.key === currentRole?.agency;
  const userListCredentials = isAdmin ? userList : userList?.userCredentials;
  const userListOnboardingPdf = isAdmin ? onboardingPdf : userList?.onboardingPdf;
  const [approvedCredentials, setApprovedCredentials] = useState({});
  const [pageSize] = useState(10);
  const [pageNo] = useState(1);
  const handleApprove = (name, id, credentialsStatus) => {
    dispatch(
      userCredentialUpdate({
        id: id,
        credentialsStatus: credentialsStatus,
        rejectReason: '',
      }))
    setApprovedCredentials(prevState => ({
      ...prevState,
      [id]: true
    }));
    onReject(false);
    // handleClose()
    setTimeout(() => {
      dispatch(getAllUserDetails({ pageSize, pageNo: pageNo}));
    }, 500);
  };

  const handleReject = (name, id, credentialsStatus, types) => {
    onReject(true, id, credentialsStatus, types);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '01-01-2025';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '01-01-2025';
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const openImageInNewTab = (fileName) => {
    window.open(`${apiBasePath}documents/therapist/${fileName}`, "_blank")
  };

  const isExpired = (expirationDate) => {
    if (!expirationDate) return false;
    const date = new Date(expirationDate);
    if (isNaN(date.getTime())) return false;
    const today = new Date();
    return date < new Date(today.getFullYear(), today.getMonth(), today.getDate());
  };

  const isUpdateAt = (updatedAt) => {
    const today = new Date();
    const updatedDate = new Date(updatedAt);
    updatedDate.setFullYear(updatedDate.getFullYear() + 1);
    return updatedDate < today;
  };

  const downloadFile = async (fileName) => {
    try {
      const response = await fetch(`${apiBasePath}documents/therapist/${fileName}`);
      const pdfBlob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(pdfBlob);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  return (
    <>
      <div className="container-fluid pr-5">
        <div className="row">
          <Accordion className="accordion-body" defaultActiveKey={["1"]} flush>
            <Accordion.Item className="border-0 mb-3" eventKey="1">
              <Accordion.Header>
                <span className="therapist-header">Current Credentials</span>
              </Accordion.Header>
              <Accordion.Body >
                <table className="mobile_table-responsive-md table pt-0 patient_details document-tab therapist-tab-1 ml-4">
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>UPLOADED ON</th>
                      <th>EXPIRATION DATE</th>
                      <th>STATUS</th>
                      <th style={{ width: '400px' }}></th>
                    </tr>
                  </thead>
                  <tbody>

                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('therapylicense') && user.credentialsStatus !== "inactive" &&
                      (<tr key={user?.name}>
                        <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user?.file)}>
                          <AiFillFileText style={{ marginRight: '5px' }} />
                          <span style={{ textDecoration: 'underline', color: '#0A365B' }}>Therapy License</span></td>
                        <td>{formatDate(user?.updatedAt)}</td>
                        <td>{formatDate(user?.expirationDate)}</td>

                        <td>
                          {isExpired(user.expirationDate) && user?.credentialsStatus !== "pendingreview" && user?.credentialsStatus !== "rejected" ? (
                            <button type="button" className="open_selectt" style={{ background: "#FDDCDC", color: "#D42B2B" }}>
                              Expired
                            </button>
                          ) : (
                            !isExpired(user.expirationDate) && !user.credentialsStatus && (
                              <button type="button" className="open_selectt" style={{ background: "#D9F8DC", color: "#17A325" }}>
                                Active
                              </button>
                            )
                          )}
                          {!isExpired(user.expirationDate) && user.credentialsStatus && (
                            <button
                              type="button"
                              className="open_selectt"
                              style={{
                                background: approvedCredentials[user.id] ? colors['active']?.background : colors[user.credentialsStatus]?.background,
                                color: approvedCredentials[user.id] ? colors['active']?.color : colors[user.credentialsStatus]?.color,
                              }}
                            >
                              {approvedCredentials[user.id] ? 'Active' : user.credentialsStatus === 'pendingreview' ? 'Pending Review' : user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                        </td>
                        {isAdmin &&
                          user?.credentialsStatus === "pendingreview" && !approvedCredentials[user.id] && (
                            <td>
                              <button className="btn1 agency-btn" type="button" onClick={() => handleApprove(user?.credentialsStatus, user?.id, 'active')}>
                                <BsCheck2 className="check-icon" />Approve
                              </button>
                              <button className="btn2 agency-btn" type="button" onClick={() => handleReject(user?.credentialsStatus, user?.id, 'rejected', user?.types)}>
                                <AiOutlineClose className="cross-icon" /> Reject
                              </button>
                            </td>
                          )}
                        <td>
                          {isAgency &&
                            <button className="btn1 agency-btn" type="button" onClick={() => downloadFile(user?.file)}>Download</button>
                          }
                        </td>
                      </tr>
                      ))}
                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('driverslicense') && user.credentialsStatus !== "inactive" && (
                        <tr key={user?.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>
                              Driver's License
                            </span>
                          </td>
                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user.expirationDate)}</td>
                          <td>
                            {isExpired(user.expirationDate) && user?.credentialsStatus !== "pendingreview" && user?.credentialsStatus !== "rejected" ? (
                              <button type="button" className="open_selectt" style={{ background: "#FDDCDC", color: "#D42B2B" }}>
                                Expired
                              </button>
                            ) : (
                              !isExpired(user.expirationDate) && !user.credentialsStatus && (
                                <button type="button" className="open_selectt" style={{ background: "#D9F8DC", color: "#17A325" }}>
                                  Active
                                </button>
                              )
                            )}
                            {!isExpired(user.expirationDate) && user.credentialsStatus && (
                              <button
                                type="button"
                                className="open_selectt"
                                style={{
                                  background: approvedCredentials[user.id] ? colors['active']?.background : colors[user.credentialsStatus]?.background,
                                  color: approvedCredentials[user.id] ? colors['active']?.color : colors[user.credentialsStatus]?.color,
                                }}
                              >
                                {approvedCredentials[user.id] ? 'Active' : user.credentialsStatus === 'pendingreview' ? 'Pending Review' : user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                              </button>
                            )}
                          </td>

                          {isAdmin &&
                            user?.credentialsStatus === "pendingreview" && !approvedCredentials[user.id] && (
                              <td>
                                <button className="btn1 agency-btn" type="button" onClick={() => handleApprove(user?.credentialsStatus, user?.id, 'active')}>
                                  <BsCheck2 className="check-icon" />Approve
                                </button>
                                <button className="btn2 agency-btn" type="button" onClick={() => handleReject(user?.credentialsStatus, user?.id, 'rejected', user?.types)}>
                                  <AiOutlineClose className="cross-icon" /> Reject
                                </button>
                              </td>
                            )}
                          <td>
                            {isAgency &&
                              <button className="btn1 agency-btn" type="button" onClick={() => downloadFile(user?.file)}>Download</button>
                            }
                          </td>
                        </tr>
                      ))}
                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('autoinsurance') && user.credentialsStatus !== "inactive" &&
                      (<tr key={user?.name}>
                        <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user?.file)}>
                          <AiFillFileText style={{ marginRight: '5px' }} />
                          <span style={{ textDecoration: 'underline', color: '#0A365B' }}>Auto Insurance</span></td>
                        <td>{formatDate(user?.updatedAt)}</td>
                        <td>{formatDate(user?.expirationDate)}</td>
                        <td>
                          {isExpired(user.expirationDate) && user?.credentialsStatus !== "pendingreview" && user?.credentialsStatus !== "rejected" ? (
                            <button type="button" className="open_selectt" style={{ background: "#FDDCDC", color: "#D42B2B" }}>
                              Expired
                            </button>
                          ) : (
                            !isExpired(user.expirationDate) && !user.credentialsStatus && (
                              <button type="button" className="open_selectt" style={{ background: "#D9F8DC", color: "#17A325" }}>
                                Active
                              </button>
                            )
                          )}
                          {!isExpired(user.expirationDate) && user.credentialsStatus && (
                            <button
                              type="button"
                              className="open_selectt"
                              style={{
                                background: approvedCredentials[user.id] ? colors['active']?.background : colors[user.credentialsStatus]?.background,
                                color: approvedCredentials[user.id] ? colors['active']?.color : colors[user.credentialsStatus]?.color,
                              }}
                            >
                              {approvedCredentials[user.id] ? 'Active' : user.credentialsStatus === 'pendingreview' ? 'Pending Review' : user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                        </td>
                        {isAdmin &&
                          user?.credentialsStatus === "pendingreview" && !approvedCredentials[user.id] && (
                            <td>
                              <button className="btn1 agency-btn" type="button" onClick={() => handleApprove(user?.credentialsStatus, user?.id, 'active')}>
                                <BsCheck2 className="check-icon" />Approve
                              </button>
                              <button className="btn2 agency-btn" type="button" onClick={() => handleReject(user?.credentialsStatus, user?.id, 'rejected', user?.types)}>
                                <AiOutlineClose className="cross-icon" /> Reject
                              </button>
                            </td>
                          )}
                        <td>
                          {isAgency &&
                            <button className="btn1 agency-btn" type="button" onClick={() => downloadFile(user?.file)}>Download</button>
                          }
                        </td>
                      </tr>
                      ))}
                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('professionallaibilityinsurance') && user.credentialsStatus !== "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>
                              Professional Liability Insurance
                            </span>
                          </td>

                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user.expirationDate)}</td>

                          <td>
                            {isExpired(user.expirationDate) && user?.credentialsStatus !== "pendingreview" && user?.credentialsStatus !== "rejected" ? (
                              <button type="button" className="open_selectt" style={{ background: "#FDDCDC", color: "#D42B2B" }}>
                                Expired
                              </button>
                            ) : (
                              !isExpired(user.expirationDate) && !user.credentialsStatus && (
                                <button type="button" className="open_selectt" style={{ background: "#D9F8DC", color: "#17A325" }}>
                                  Active
                                </button>
                              )
                            )}
                            {!isExpired(user.expirationDate) && user.credentialsStatus && (
                              <button
                                type="button"
                                className="open_selectt"
                                style={{
                                  background: approvedCredentials[user.id] ? colors['active']?.background : colors[user.credentialsStatus]?.background,
                                  color: approvedCredentials[user.id] ? colors['active']?.color : colors[user.credentialsStatus]?.color,
                                }}
                              >
                                {approvedCredentials[user.id] ? 'Active' : user.credentialsStatus === 'pendingreview' ? 'Pending Review' : user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                              </button>
                            )}
                          </td>
                          {isAdmin &&
                            user?.credentialsStatus === "pendingreview" && !approvedCredentials[user.id] && (
                              <td>
                                <button className="btn1 agency-btn" type="button" onClick={() => handleApprove(user?.credentialsStatus, user?.id, 'active')}>
                                  <BsCheck2 className="check-icon" />Approve
                                </button>
                                <button className="btn2 agency-btn" type="button" onClick={() => handleReject(user?.credentialsStatus, user?.id, 'rejected', user?.types)}>
                                  <AiOutlineClose className="cross-icon" /> Reject
                                </button>
                              </td>
                            )}
                          <td>
                            {isAgency &&
                              <button className="btn1 agency-btn" type="button" onClick={() => downloadFile(user?.file)}>Download</button>
                            }
                          </td>
                        </tr>
                      ))}
                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('cprcertification') && user.credentialsStatus !== "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>
                              CPR Certification
                            </span>
                          </td>

                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user.expirationDate)}</td>

                          <td>
                            {isExpired(user.expirationDate) && user?.credentialsStatus !== "pendingreview" && user?.credentialsStatus !== "rejected" ? (
                              <button type="button" className="open_selectt" style={{ background: "#FDDCDC", color: "#D42B2B" }}>
                                Expired
                              </button>
                            ) : (
                              !isExpired(user.expirationDate) && !user.credentialsStatus && (
                                <button type="button" className="open_selectt" style={{ background: "#D9F8DC", color: "#17A325" }}>
                                  Active
                                </button>
                              )
                            )}
                            {!isExpired(user.expirationDate) && user.credentialsStatus && (
                              <button
                                type="button"
                                className="open_selectt"
                                style={{
                                  background: approvedCredentials[user.id] ? colors['active']?.background : colors[user.credentialsStatus]?.background,
                                  color: approvedCredentials[user.id] ? colors['active']?.color : colors[user.credentialsStatus]?.color,
                                }}
                              >
                                {approvedCredentials[user.id] ? 'Active' : user.credentialsStatus === 'pendingreview' ? 'Pending Review' : user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                              </button>
                            )}
                          </td>
                          {isAdmin &&
                            user?.credentialsStatus === "pendingreview" && !approvedCredentials[user.id] && (
                              <td>
                                <button className="btn1 agency-btn" type="button" onClick={() => handleApprove(user?.credentialsStatus, user?.id, 'active')}>
                                  <BsCheck2 className="check-icon" />Approve
                                </button>
                                <button className="btn2 agency-btn" type="button" onClick={() => handleReject(user?.credentialsStatus, user?.id, 'rejected', user?.types)}>
                                  <AiOutlineClose className="cross-icon" /> Reject
                                </button>
                              </td>
                            )}
                          <td>
                            {isAgency &&
                              <button className="btn1 agency-btn" type="button" onClick={() => downloadFile(user?.file)}>Download</button>
                            }
                          </td>
                        </tr>
                      ))}
                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('physical') && user?.credentialsStatus !== "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>
                              Physical
                            </span>
                          </td>

                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user.expirationDate)}</td>
                          <td>
                            {isExpired(user.expirationDate) && user?.credentialsStatus !== "pendingreview" && user?.credentialsStatus !== "rejected" ? (
                              <button type="button" className="open_selectt" style={{ background: "#FDDCDC", color: "#D42B2B" }}>
                                Expired
                              </button>
                            ) : (
                              !isExpired(user.expirationDate) && !user.credentialsStatus && (
                                <button type="button" className="open_selectt" style={{ background: "#D9F8DC", color: "#17A325" }}>
                                  Active
                                </button>
                              )
                            )}
                            {!isExpired(user.expirationDate) && user.credentialsStatus && (
                              <button
                                type="button"
                                className="open_selectt"
                                style={{
                                  background: approvedCredentials[user.id] ? colors['active']?.background : colors[user.credentialsStatus]?.background,
                                  color: approvedCredentials[user.id] ? colors['active']?.color : colors[user.credentialsStatus]?.color,
                                }}
                              >
                                {approvedCredentials[user.id] ? 'Active' : user.credentialsStatus === 'pendingreview' ? 'Pending Review' : user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                              </button>
                            )}
                          </td>

                          {/* Render buttons only if status is "Pending Review" */}
                          {isAdmin &&
                            user?.credentialsStatus === "pendingreview" && !approvedCredentials[user.id] && (
                              <td>
                                <button className="btn1 agency-btn" type="button" onClick={() => handleApprove(user?.credentialsStatus, user?.id, 'active')}>
                                  <BsCheck2 className="check-icon" />Approve
                                </button>
                                <button className="btn2 agency-btn" type="button" onClick={() => handleReject(user?.credentialsStatus, user?.id, 'rejected', user?.types)}>
                                  <AiOutlineClose className="cross-icon" /> Reject
                                </button>
                              </td>
                            )}
                          <td>
                            {isAgency &&
                              <button className="btn1 agency-btn" type="button" onClick={() => downloadFile(user?.file)}>Download</button>
                            }
                          </td>
                        </tr>
                      ))}
                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('tbtest') && user?.credentialsStatus !== "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>
                              TB Test
                            </span>
                          </td>

                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user.expirationDate)}</td>

                          <td>
                            {isExpired(user.expirationDate) && user?.credentialsStatus !== "pendingreview" && user?.credentialsStatus !== "rejected" ? (
                              <button type="button" className="open_selectt" style={{ background: "#FDDCDC", color: "#D42B2B" }}>
                                Expired
                              </button>
                            ) : (
                              !isExpired(user.expirationDate) && !user.credentialsStatus && (
                                <button type="button" className="open_selectt" style={{ background: "#D9F8DC", color: "#17A325" }}>
                                  Active
                                </button>
                              )
                            )}
                            {!isExpired(user.expirationDate) && user.credentialsStatus && (
                              <button
                                type="button"
                                className="open_selectt"
                                style={{
                                  background: approvedCredentials[user.id] ? colors['active']?.background : colors[user.credentialsStatus]?.background,
                                  color: approvedCredentials[user.id] ? colors['active']?.color : colors[user.credentialsStatus]?.color,
                                }}
                              >
                                {approvedCredentials[user.id] ? 'Active' : user.credentialsStatus === 'pendingreview' ? 'Pending Review' : user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                              </button>
                            )}
                          </td>
                          {isAdmin &&
                            user?.credentialsStatus === "pendingreview" && !approvedCredentials[user.id] && (
                              <td>
                                <button className="btn1 agency-btn" type="button" onClick={() => handleApprove(user?.credentialsStatus, user?.id, 'active')}>
                                  <BsCheck2 className="check-icon" />Approve
                                </button>
                                <button className="btn2 agency-btn" type="button" onClick={() => handleReject(user?.credentialsStatus, user?.id, 'rejected', user?.types)}>
                                  <AiOutlineClose className="cross-icon" /> Reject
                                </button>
                              </td>
                            )}
                          <td>
                            {isAgency &&
                              <button className="btn1 agency-btn" type="button" onClick={() => downloadFile(user?.file)}>Download</button>
                            }
                          </td>
                        </tr>
                      ))}
                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('fluvaccination') && user?.credentialsStatus !== "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>
                              Flu Vaccination
                            </span>
                          </td>

                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user.expirationDate)}</td>

                          <td>
                            {isExpired(user.expirationDate) && user?.credentialsStatus !== "pendingreview" && user?.credentialsStatus !== "rejected" ? (
                              <button type="button" className="open_selectt" style={{ background: "#FDDCDC", color: "#D42B2B" }}>
                                Expired
                              </button>
                            ) : (
                              !isExpired(user.expirationDate) && !user.credentialsStatus && (
                                <button type="button" className="open_selectt" style={{ background: "#D9F8DC", color: "#17A325" }}>
                                  Active
                                </button>
                              )
                            )}
                            {!isExpired(user.expirationDate) && user.credentialsStatus && (
                              <button
                                type="button"
                                className="open_selectt"
                                style={{
                                  background: approvedCredentials[user.id] ? colors['active']?.background : colors[user.credentialsStatus]?.background,
                                  color: approvedCredentials[user.id] ? colors['active']?.color : colors[user.credentialsStatus]?.color,
                                }}
                              >
                                {approvedCredentials[user.id] ? 'Active' : user.credentialsStatus === 'pendingreview' ? 'Pending Review' : user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                              </button>
                            )}
                          </td>

                          {/* Render buttons only if status is "Pending Review" */}
                          {isAdmin &&
                            user?.credentialsStatus === "pendingreview" && !approvedCredentials[user.id] && (
                              <td>
                                <button className="btn1 agency-btn" type="button" onClick={() => handleApprove(user?.credentialsStatus, user?.id, 'active')}>
                                  <BsCheck2 className="check-icon" />Approve
                                </button>
                                <button className="btn2 agency-btn" type="button" onClick={() => handleReject(user?.credentialsStatus, user?.id, 'rejected', user?.types)}>
                                  <AiOutlineClose className="cross-icon" /> Reject
                                </button>
                              </td>
                            )}
                          <td>
                            {isAgency &&
                              <button className="btn1 agency-btn" type="button" onClick={() => downloadFile(user?.file)}>Download</button>
                            }
                          </td>
                        </tr>
                      ))}
                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('hepatitisB') && user.credentialsStatus !== "inactive" &&
                      (<tr key={user?.name}>
                        <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user?.file)}>
                          <AiFillFileText style={{ marginRight: '5px' }} />
                          <span style={{ textDecoration: 'underline', color: '#0A365B' }}>Hepatitis B</span></td>
                        <td>{formatDate(user?.updatedAt)}</td>
                        <td>
                          {
                            (() => {
                              const date = new Date(formatDate(user?.updatedAt));
                              date.setFullYear(date.getFullYear() + 1);
                              const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}-${date.getFullYear()}`;
                              return formattedDate;
                            })()
                          }
                        </td>
                        <td>
                          {isUpdateAt(user.updatedAt) && user?.credentialsStatus !== "pendingreview" && user?.credentialsStatus !== "rejected" ? (
                            <button type="button" className="open_selectt" style={{ background: "#FDDCDC", color: "#D42B2B" }}>
                              Expired
                            </button>
                          ) : (
                            !isUpdateAt(user?.updatedAt) && !user.credentialsStatus && (
                              <button type="button" className="open_selectt" style={{ background: "#D9F8DC", color: "#17A325" }}>
                                Active
                              </button>
                            )
                          )}
                          {!isUpdateAt(user.updatedAt) && user.credentialsStatus && (
                            <button
                              type="button"
                              className="open_selectt"
                              style={{
                                background: approvedCredentials[user.id] ? colors['active']?.background : colors[user.credentialsStatus]?.background,
                                color: approvedCredentials[user.id] ? colors['active']?.color : colors[user.credentialsStatus]?.color,
                              }}
                            >
                              {approvedCredentials[user.id] ? 'Active' : user.credentialsStatus === 'pendingreview' ? 'Pending Review' : user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                        </td>
                        {isAdmin &&
                          user?.credentialsStatus === "pendingreview" && !approvedCredentials[user.id] && (
                            <td>
                              <button className="btn1 agency-btn" type="button" onClick={() => handleApprove(user?.credentialsStatus, user?.id, 'active')}>
                                <BsCheck2 className="check-icon" />Approve
                              </button>
                              <button className="btn2 agency-btn" type="button" onClick={() => handleReject(user?.credentialsStatus, user?.id, 'rejected', user?.types)}>
                                <AiOutlineClose className="cross-icon" /> Reject
                              </button>
                            </td>
                          )}
                        <td>
                          {isAgency &&
                            <button className="btn1 agency-btn" type="button" onClick={() => downloadFile(user?.file)}>Download</button>
                          }
                        </td>
                      </tr>
                      ))}
                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('covidrecord') && user.credentialsStatus !== "inactive" &&
                      (<tr key={user?.name}>
                        <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user?.file)}>
                          <AiFillFileText style={{ marginRight: '5px' }} />
                          <span style={{ textDecoration: 'underline', color: '#0A365B' }}>Covid-19</span></td>
                        <td>{formatDate(user?.updatedAt)}</td>
                        <td>
                          {
                            (() => {
                              const date = new Date(formatDate(user?.updatedAt));
                              date.setFullYear(date.getFullYear() + 1);
                              const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}-${date.getFullYear()}`;
                              return formattedDate;
                            })()
                          }
                        </td>
                        <td>
                          {isUpdateAt(user.updatedAt) && user?.credentialsStatus !== "pendingreview" && user?.credentialsStatus !== "rejected" ? (
                            <button type="button" className="open_selectt" style={{ background: "#FDDCDC", color: "#D42B2B" }}>
                              Expired
                            </button>
                          ) : (
                            !isUpdateAt(user?.updatedAt) && !user.credentialsStatus && (
                              <button type="button" className="open_selectt" style={{ background: "#D9F8DC", color: "#17A325" }}>
                                Active
                              </button>
                            )
                          )}
                          {!isUpdateAt(user.updatedAt) && user.credentialsStatus && (
                            <button
                              type="button"
                              className="open_selectt"
                              style={{
                                background: approvedCredentials[user.id] ? colors['active']?.background : colors[user.credentialsStatus]?.background,
                                color: approvedCredentials[user.id] ? colors['active']?.color : colors[user.credentialsStatus]?.color,
                              }}
                            >
                              {approvedCredentials[user.id] ? 'Active' : user.credentialsStatus === 'pendingreview' ? 'Pending Review' : user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                        </td>
                        {isAdmin &&
                          user?.credentialsStatus === "pendingreview" && !approvedCredentials[user.id] && (
                            <td>
                              <button className="btn1 agency-btn" type="button" onClick={() => handleApprove(user?.credentialsStatus, user?.id, 'active')}>
                                <BsCheck2 className="check-icon" />Approve
                              </button>
                              <button className="btn2 agency-btn" type="button" onClick={() => handleReject(user?.credentialsStatus, user?.id, 'rejected', user?.types)}>
                                <AiOutlineClose className="cross-icon" /> Reject
                              </button>
                            </td>
                          )}
                        <td>
                          {isAgency &&
                            <button className="btn1 agency-btn" type="button" onClick={() => downloadFile(user?.file)}>Download</button>
                          }
                        </td>
                      </tr>
                      ))}
                    {userListOnboardingPdf && userListOnboardingPdf?.map((user) =>
                      user?.types?.includes('competencies') && (
                        <tr key={user.types}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>
                              Competency Assessments
                            </span>
                          </td>

                          <td>{formatDate(user?.updatedAt)}</td>
                          <td> {
                            (() => {
                              const date = new Date(formatDate(user?.updatedAt));
                              date.setFullYear(date.getFullYear() + 1);
                              const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}-${date.getFullYear()}`;
                              return formattedDate;
                            })()
                          }</td>

                          <td>
                            {isUpdateAt(user.updatedAt) ? (
                              <button type="button" className="open_selectt" style={{ background: "#FDDCDC", color: "#D42B2B" }}>
                                Expired
                              </button>
                            ) : (
                              !isUpdateAt(user?.updatedAt) && (
                                <button type="button" className="open_selectt" style={{ background: "#D9F8DC", color: "#17A325" }}>
                                  Active
                                </button>
                              )
                            )}
                          </td>
                          <td>
                            {isAgency &&
                              <button className="btn1 agency-btn" type="button" onClick={() => downloadFile(user?.file)}>Download</button>
                            }
                          </td>
                        </tr>
                      )
                    )}
                    {userListOnboardingPdf && userListOnboardingPdf?.map((user) =>
                      user?.types?.includes('compliance') && (
                        <tr key={user.types}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>
                              Compliance Documents
                            </span>
                          </td>

                          <td>{formatDate(user?.updatedAt)}</td>
                          <td> {
                            (() => {
                              const date = new Date(formatDate(user?.updatedAt));
                              date.setFullYear(date.getFullYear() + 1);
                              const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}-${date.getFullYear()}`;
                              return formattedDate;
                            })()
                          }</td>

                          <td>
                            {isUpdateAt(user.updatedAt) ? (
                              <button type="button" className="open_selectt" style={{ background: "#FDDCDC", color: "#D42B2B" }}>
                                Expired
                              </button>
                            ) : (
                              !isUpdateAt(user?.updatedAt) && (
                                <button type="button" className="open_selectt" style={{ background: "#D9F8DC", color: "#17A325" }}>
                                  Active
                                </button>
                              )
                            )}
                          </td>
                          <td>
                            {isAgency &&
                              <button className="btn1 agency-btn" type="button" onClick={() => downloadFile(user?.file)}>Download</button>
                            }
                          </td>
                        </tr>
                      )
                    )}
                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('w9') && user?.credentialsStatus !== "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>
                              W9
                            </span>
                          </td>

                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>
                            {
                              (() => {
                                const date = new Date(formatDate(user?.updatedAt));
                                date.setFullYear(date.getFullYear() + 1);
                                const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}-${date.getFullYear()}`;
                                return formattedDate;
                              })()
                            }
                          </td>

                          <td>
                            {isUpdateAt(user.updatedAt) && user?.credentialsStatus !== "pendingreview" && user?.credentialsStatus !== "rejected" ? (
                              <button type="button" className="open_selectt" style={{ background: "#FDDCDC", color: "#D42B2B" }}>
                                Expired
                              </button>
                            ) : (
                              !isUpdateAt(user?.updatedAt) && !user.credentialsStatus && (
                                <button type="button" className="open_selectt" style={{ background: "#D9F8DC", color: "#17A325" }}>
                                  Active
                                </button>
                              )
                            )}
                            {!isUpdateAt(user.updatedAt) && user.credentialsStatus && (
                              <button
                                type="button"
                                className="open_selectt"
                                style={{
                                  background: approvedCredentials[user.id] ? colors['active']?.background : colors[user.credentialsStatus]?.background,
                                  color: approvedCredentials[user.id] ? colors['active']?.color : colors[user.credentialsStatus]?.color,
                                }}
                              >
                                {approvedCredentials[user.id] ? 'Active' : user.credentialsStatus === 'pendingreview' ? 'Pending Review' : user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                              </button>
                            )}
                          </td>
                          {/* Render buttons only if status is "Pending Review" */}
                          {isAdmin &&
                            user?.credentialsStatus === "pendingreview" && !approvedCredentials[user.id] && (
                              <td>
                                <button className="btn1 agency-btn" type="button" onClick={() => handleApprove(user?.credentialsStatus, user?.id, 'active')}>
                                  <BsCheck2 className="check-icon" />Approve
                                </button>
                                <button className="btn2 agency-btn" type="button" onClick={() => handleReject(user?.credentialsStatus, user?.id, 'rejected', user?.types)}>
                                  <AiOutlineClose className="cross-icon" /> Reject
                                </button>
                              </td>
                            )}
                          <td>
                            {isAgency &&
                              <button className="btn1 agency-btn" type="button" onClick={() => downloadFile(user?.file)}>Download</button>
                            }
                          </td>
                        </tr>
                      ))}
                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('businesslicense') && user?.credentialsStatus !== "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>
                              Business License
                            </span>
                          </td>

                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user.expirationDate)}</td>

                          <td>
                            {isExpired(user.expirationDate) && user?.credentialsStatus !== "pendingreview" && user?.credentialsStatus !== "rejected" ? (
                              <button type="button" className="open_selectt" style={{ background: "#FDDCDC", color: "#D42B2B" }}>
                                Expired
                              </button>
                            ) : (
                              !isExpired(user.expirationDate) && !user.credentialsStatus && (
                                <button type="button" className="open_selectt" style={{ background: "#D9F8DC", color: "#17A325" }}>
                                  Active
                                </button>
                              )
                            )}
                            {!isExpired(user.expirationDate) && user.credentialsStatus && (
                              <button
                                type="button"
                                className="open_selectt"
                                style={{
                                  background: approvedCredentials[user.id] ? colors['active']?.background : colors[user.credentialsStatus]?.background,
                                  color: approvedCredentials[user.id] ? colors['active']?.color : colors[user.credentialsStatus]?.color,
                                }}
                              >
                                {approvedCredentials[user.id] ? 'Active' : user.credentialsStatus === 'pendingreview' ? 'Pending Review' : user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                              </button>
                            )}
                          </td>

                          {/* Render buttons only if status is "Pending Review" */}
                          {isAdmin &&
                            user?.credentialsStatus === "pendingreview" && !approvedCredentials[user.id] && (
                              <td>
                                <button className="btn1 agency-btn" type="button" onClick={() => handleApprove(user?.credentialsStatus, user?.id, 'active')}>
                                  <BsCheck2 className="check-icon" />Approve
                                </button>
                                <button className="btn2 agency-btn" type="button" onClick={() => handleReject(user?.credentialsStatus, user?.id, 'rejected', user?.types)}>
                                  <AiOutlineClose className="cross-icon" /> Reject
                                </button>
                              </td>
                            )}
                          <td>
                            {isAgency &&
                              <button className="btn1 agency-btn" type="button" onClick={() => downloadFile(user?.file)}>Download</button>
                            }
                          </td>
                        </tr>
                      ))}
                    {!userListCredentials && userListCredentials &&
                      !userListOnboardingPdf && userListOnboardingPdf &&(
                        <td colSpan="5">No Data Available</td>
                      )}
                  </tbody>
                </table>
              </Accordion.Body>
              {/* Previous Credentials Section  */}
            </Accordion.Item>
            <Accordion.Item className="border-0 mb-3" eventKey="2">
              <Accordion.Header>
                <span className="therapist-header">Previous Credentials</span>
              </Accordion.Header>
              <Accordion.Body >
                <table className="mobile_table-responsive-md table pt-0 patient_details document-tab therapist-tab-1 ml-4">
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>UPLOADED ON</th>
                      <th>EXPIRATION DATE</th>
                      <th>STATUS</th>
                      <th style={{ width: '400px' }}></th>
                    </tr>
                  </thead>
                  <tbody>

                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('therapylicense') && user.credentialsStatus === "inactive" &&
                      (<tr key={user?.name}>
                        <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user?.file)}>
                          <AiFillFileText style={{ marginRight: '5px' }} />
                          <span style={{ textDecoration: 'underline', color: '#0A365B' }}>Therapy License</span></td>
                        <td>{formatDate(user?.updatedAt)}</td>
                        <td>{formatDate(user?.expirationDate)}</td>
                        <td>{user.credentialsStatus && (
                          <button type="button" className="open_selectt" style={{
                            background: colors[user.credentialsStatus]?.background,
                            color: colors[user.credentialsStatus]?.color
                          }}>
                            {user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                          </button>
                        )}
                        </td>
                      </tr>
                      ))}

                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('driverslicense') && user.credentialsStatus === "inactive" && (
                        <tr key={user?.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>Driver's License</span></td>
                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user?.expirationDate)}</td>
                          <td>{user.credentialsStatus && (
                            <button type="button" className="open_selectt" style={{
                              background: colors[user.credentialsStatus]?.background,
                              color: colors[user.credentialsStatus]?.color,
                            }}>
                              {user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                          </td>
                        </tr>
                      ))}
                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('autoinsurance') && user.credentialsStatus === "inactive" && (
                        <tr key={user?.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>Auto Insurance</span></td>
                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user?.expirationDate)}</td>
                          <td>{user.credentialsStatus && (
                            <button type="button" className="open_selectt" style={{
                              background: colors[user.credentialsStatus]?.background,
                              color: colors[user.credentialsStatus]?.color,
                            }}>
                              {user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                          </td>
                        </tr>
                      ))}

                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('professionallaibilityinsurance') && user.credentialsStatus === "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>Professional Liability Insurance</span></td>
                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user?.expirationDate)}</td>
                          <td>{user.credentialsStatus && (
                            <button type="button" className="open_selectt" style={{
                              background: colors[user.credentialsStatus]?.background,
                              color: colors[user.credentialsStatus]?.color,
                            }}>
                              {user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                          </td>
                        </tr>
                      ))}

                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('cprcertification') && user.credentialsStatus === "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>CPR Certification</span></td>
                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user?.expirationDate)}</td>
                          <td>{user.credentialsStatus && (
                            <button type="button" className="open_selectt" style={{
                              background: colors[user.credentialsStatus]?.background,
                              color: colors[user.credentialsStatus]?.color,
                            }}>
                              {user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                          </td>
                        </tr>
                      ))}

                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('physical') && user?.credentialsStatus === "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>Physical</span></td>
                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user?.expirationDate)}</td>
                          <td>{user.credentialsStatus && (
                            <button type="button" className="open_selectt" style={{
                              background: colors[user.credentialsStatus]?.background,
                              color: colors[user.credentialsStatus]?.color,
                            }}>
                              {user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                          </td>
                        </tr>
                      ))}

                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('tbtest') && user?.credentialsStatus === "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>TB Test</span></td>
                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user?.expirationDate)}</td>
                          <td>{user.credentialsStatus && (
                            <button type="button" className="open_selectt" style={{
                              background: colors[user.credentialsStatus]?.background,
                              color: colors[user.credentialsStatus]?.color,
                            }}>
                              {user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                          </td>
                        </tr>
                      )
                    )}

                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('fluvaccination') && user?.credentialsStatus === "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>Flu Vaccination</span></td>
                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user?.expirationDate)}</td>
                          <td>{user.credentialsStatus && (
                            <button type="button" className="open_selectt" style={{
                              background: colors[user.credentialsStatus]?.background,
                              color: colors[user.credentialsStatus]?.color,
                            }}>
                              {user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                          </td>
                        </tr>
                      )
                    )}
                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('hepatitisB') && user?.credentialsStatus === "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>Hepatitis B</span></td>
                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user?.expirationDate)}</td>
                          <td>{user.credentialsStatus && (
                            <button type="button" className="open_selectt" style={{
                              background: colors[user.credentialsStatus]?.background,
                              color: colors[user.credentialsStatus]?.color,
                            }}>
                              {user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                          </td>
                        </tr>
                      )
                    )}
                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('covidrecord') && user?.credentialsStatus === "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>Covid-19</span></td>
                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user?.expirationDate)}</td>
                          <td>{user.credentialsStatus && (
                            <button type="button" className="open_selectt" style={{
                              background: colors[user.credentialsStatus]?.background,
                              color: colors[user.credentialsStatus]?.color,
                            }}>
                              {user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                          </td>
                        </tr>
                      )
                    )}
                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('competencyassessments') && user?.credentialsStatus === "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>Competency Assessments</span></td>
                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user?.expirationDate)}</td>
                          <td>{user.credentialsStatus && (
                            <button type="button" className="open_selectt" style={{
                              background: colors[user.credentialsStatus]?.background,
                              color: colors[user.credentialsStatus]?.color,
                            }}>
                              {user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                          </td>
                        </tr>
                      ))}

                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('compliancedocuments') && user?.credentialsStatus === "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>Compliance Documents</span></td>
                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user?.expirationDate)}</td>
                          <td>{user.credentialsStatus && (
                            <button type="button" className="open_selectt" style={{
                              background: colors[user.credentialsStatus]?.background,
                              color: colors[user.credentialsStatus]?.color,
                            }}>
                              {user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                          </td>
                        </tr>
                      ))}

                    {userListCredentials && userListCredentials?.map((user) =>
                      user?.types?.includes('businesslicense') && user?.credentialsStatus === "inactive" && (
                        <tr key={user.name}>
                          <td className="font-bold text-base leading-6 cursor-pointer" onClick={() => openImageInNewTab(user.file)}>
                            <AiFillFileText style={{ marginRight: '5px' }} />
                            <span style={{ textDecoration: 'underline', color: '#0A365B' }}>
                              Business License
                            </span>
                          </td>

                          <td>{formatDate(user?.updatedAt)}</td>
                          <td>{formatDate(user?.expirationDate)}</td>
                          <td>{user.credentialsStatus && (
                            <button type="button" className="open_selectt" style={{
                              background: colors[user.credentialsStatus]?.background,
                              color: colors[user.credentialsStatus]?.color
                            }}>
                              {user.credentialsStatus.charAt(0).toUpperCase() + user.credentialsStatus.slice(1)}
                            </button>
                          )}
                          </td>
                        </tr>
                      ))}
                    {!userListCredentials && userListCredentials &&
                     !userListOnboardingPdf && userListOnboardingPdf &&(
                        <td colSpan="5">No Data Available</td>
                      )}
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  )
};
export default TherapistCredentialsModal;
