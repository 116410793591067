import React, { useEffect, useMemo, useRef, useState } from "react";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import FifthPage from "./FifthPage/FifthPage";
import SixthPage from "./SixthPage/SixthPage";
import ThirdPage from "./ThirdPage/ThirdPage";
import SecondPage from "./SecondPage/SecondPage";
import FourthPage from "./FourthPage/FourthPage";
import jsPDF from "jspdf";
import SignatureCanvas from "../../../components/common/SignatureCanvas";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../Redux/slices/authSlice";
import Loader from "../../../components/Loader";
import { changeDateFormat, todayDate } from "../../../utils/helper";
import html2canvas from "html2canvas";
import downloadIcon from "../../../images/file-download.svg";

const TaxForm = (props) => {
  const {
    values,
    handleChange,
    handleClose,
    uploadedFileBytes,
    isAdminView,
    setUploadedFileBytes,
    taxFormValues,
    setTaxFormValues,
    viewMode,
  } = props;

  const downloadRef = useRef(null);

  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const inputRefss = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  function handleInputChange(e, i) {
    const value = e.target.value;
    if (value.length === 1 && i < inputRefs.length - 1) {
      inputRefs[i + 1].current.focus();
    }
  }
  function handleInputChanges(e, i) {
    const value = e.target.value;
    if (value.length === 1 && i < inputRefss.length - 1) {
      inputRefss[i + 1].current.focus();
    }
  }
  const isLoading = useSelector((e) => e.authSlice.isLoading);

  const fieldds = Array.from({ length: 9 }, (_, index) => index);
  const fieldsss = Array.from({ length: 9 }, (_, index) => index);
  const [isCanvasShow, setIsCanvasShow] = useState(false);
  const [signatureAdded, setSignatureAdded] = useState(false);
  const [signatureImage, setSignatureImage] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const RadioData = [
    { label: "Individual", type: "radio", col: 1 },
    { label: " C_Corporation", type: "radio", col: 1 },
    { label: "S_Corporation", type: "radio", col: 1 },
    { label: "Partnership", type: "radio", col: 1 },
    { label: "TrustEstate", type: "radio", col: 1 },
  ];

  const validation = (values) => {
    const errors = {};
    if (!values.Name) {
      errors.Name = "Name is required.";
    }
    // if (!values.BusinessName) {
    //   errors.BusinessName = "Business Name is required.";
    // }
    if (!values.Address1) {
      errors.Address1 = "Address is Required.";
    }
    if (!values.Address) {
      errors.Address = "Required.";
    }

    let exec = [];
    values.Exemptions.map((ele) => {
      let error2 = {};
      // if (!ele.payeecode) {
      //   error2['payeecode'] = 'Required'
      // }
      // if (!ele.reportingcode) {
      //   error2['reportingcode'] = 'Required'
      // }

      exec.push(error2);
    });

    let otpcode = [];
    let otpcode2 = [];
    let error3 = {};
    let error2 = {};
    if (
      !values.SocialSecurity[0].OTPd0 &&
      !values.EmployerIdentification[0].OTPs0
    ) {
      error3["OTPd0"] = "Required";
      error2["OTPs0"] = "Required";
    }
    otpcode.push(error3);
    otpcode2.push(error2);

    errors["SocialSecurity"] = otpcode;
    errors["EmployerIdentification"] = otpcode2;

    errors["Exemptions"] = exec;

    return errors;
  };

  const radioErr = (value) => {
    const radioErrInput = {};

    if (!value[0]["taxclerificationsInputs"]) {
      radioErrInput["col1"] = "Please select at least one option.";
    }

    return radioErrInput;
  };

  const initialValues = () => {
    // if (isAdminView) {
    //   let formData =
    //     PrefillDetails &&
    //     PrefillDetails.userDetail &&
    //     PrefillDetails.userDetail.textData
    //       ? JSON.parse(PrefillDetails.userDetail.textData)
    //       : null;
    //   if (formData) {
    //     if (formData.signature) {
    //       setSignatureAdded(true);
    //       setSignatureImage(formData.signature);
    //     }
    //     return { ...formData };
    //   }
    // }
    if (taxFormValues) {
      if (taxFormValues.signature) {
        setSignatureAdded(true);
        setSignatureImage(taxFormValues.signature);
      }
      return { ...taxFormValues };
    }
    if (uploadedFileBytes && uploadedFileBytes.w9) {
      if (uploadedFileBytes.w9.signatureImageData) {
        setSignatureAdded(true);
        setSignatureImage(uploadedFileBytes.w9.signatureImageData);
      }
      return uploadedFileBytes.w9;
    }
    let initialValues = {
      taxclassification: [
        {
          taxclerificationsInputs: "",
        },
      ],
      SocialSecurity: [
        {
          OTPd0: "",
          OTPd1: "",
          OTPd2: "",
          OTPd3: "",
          OTPd4: "",
          OTPd5: "",
          OTPd6: "",
          OTPd7: "",
          OTPd8: "",
          OTPd9: "",
        },
      ],
      EmployerIdentification: [
        {
          OTPs0: "",
          OTPs1: "",
          OTPs2: "",
          OTPs3: "",
          OTPs4: "",
          OTPs5: "",
          OTPs6: "",
          OTPs7: "",
          OTPs8: "",
          OTPs9: "",
        },
      ],

      Name:
        PrefillDetails && PrefillDetails.firstName && PrefillDetails.lastName
          ? `${PrefillDetails.firstName} ${PrefillDetails.lastName}`
          : "",
      BusinessName: "",
      Exemptions: [{ payeecode: "", reportingcode: "" }],
      Address1: "",
      Address: "",
      requesterNameAndAddress: "",
      accountNumber: "",
    };
    return initialValues;
  };
  const dispatch = useDispatch();
  const onSubmit = async (formvalues, e) => {
    if (!signatureAdded) {
      toast.info("Please add signature before submitting.");
      return;
    } else {
      dispatch(setLoader({ loading: true }));
    }
    let htmlContent = document.getElementById("pdfContent");
    let submitButton = htmlContent.querySelector('button[type="submit"]');
    let sideText = document.getElementById("print-div");

    let signature = document.getElementById("signature-btn");
    if (submitButton) {
      submitButton.remove();
    }
    if (signature) {
      signature.remove();
    }
    if (sideText) {
      sideText.remove();
    }

    // await savePdf(formvalues);
    setTaxFormValues({ ...formvalues, signature: signatureImage });

    let files = new File([], `w9.pdf`);
    if (signatureAdded) {
      handleChange({ target: { files: [files] } }, "w9", values);
      setUploadedFileBytes({
        ...uploadedFileBytes,
        w9: { ...formvalues, signatureImageData: signatureImage },
      });
      dispatch(setLoader({ loading: false }));
      handleClose();
    }
  };
  const saveForm = (image) => {
    setSignatureImage(image);
    toast.success("Signature added.");
  };

  const savePdf = async (formValues) => {
    const contentDiv = document.getElementById("pdfContent");
    let brokenHtmlContent = [];
    let formSections = [];
    if (contentDiv) {
      formSections = Array.from(contentDiv.querySelectorAll(".tax-section"));

      brokenHtmlContent = formSections;

      if (brokenHtmlContent.length > 0) {
        const doc = new jsPDF("pt", "mm", "a4", true);
        for (var ihtml = 0; ihtml < brokenHtmlContent.length; ihtml++) {
          let elements = brokenHtmlContent[ihtml];
          let canvas = await html2canvas(elements, {
            logging: true,
            allowTaint: false,
            useCORS: true,
            scale: 2,
          });
          const imgData = canvas.toDataURL("image/png");

          const imgWidth = 190;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          doc.addImage(imgData, "PNG", 10, 2, imgWidth, imgHeight + 25);
          if (ihtml < brokenHtmlContent.length - 1) {
            doc.addPage();
          }
        }
        //  doc.save("patientDocuments.pdf");
        setTaxFormValues({ ...formValues, signature: signatureImage });
        let blobObject = doc.output("blob");
        let files = new File([blobObject], `w9.pdf`);
        if (signatureAdded) {
          handleChange({ target: { files: [files] } }, "w9", values);
          setUploadedFileBytes({
            ...uploadedFileBytes,
            w9: { ...formValues, signatureImageData: signatureImage },
          });
          dispatch(setLoader({ loading: false }));
          handleClose();
        }
      } else {
        toast.error("Pdf Content not found");
      }
    }
  };

  const downloadPdf = async () => {
    setDownloadLoader(true);

    const contentDiv = document.getElementById("pdfContent");
    let brokenHtmlContent = [];
    let formSections = [];
    if (contentDiv) {
      formSections = Array.from(contentDiv.querySelectorAll(".tax-section"));

      brokenHtmlContent = formSections;
      if (downloadRef.current) {
        downloadRef.current.innerText = `This pdf have ${brokenHtmlContent.length} pages, its take some time`;
      }
      if (brokenHtmlContent.length > 0) {
        const doc = new jsPDF("pt", "mm", "a4", true);
        for (var ihtml = 0; ihtml < brokenHtmlContent.length; ihtml++) {
          let elements = brokenHtmlContent[ihtml];
          let canvas = await html2canvas(elements, {
            logging: true,
            allowTaint: false,
            useCORS: true,
            scale: 2,
          });
          const imgData = canvas.toDataURL("image/png");

          const imgWidth = 190;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          doc.addImage(imgData, "PNG", 10, 2, imgWidth, imgHeight + 25);
          if (downloadRef.current) {
            downloadRef.current.innerText = `${ihtml + 1} out of ${
              brokenHtmlContent.length
            } are generated`;
          }
          if (ihtml < brokenHtmlContent.length - 1) {
            doc.addPage();
          }
        }
        setDownloadLoader(false);
        if (downloadRef.current) {
          downloadRef.current.innerText = ` `;
        }
        doc.save("w9.pdf");
      } else {
        setDownloadLoader(false);
        toast.error("Pdf Content not found");
      }
    }
  };

  useEffect(() => {
    if (viewMode) {
      let htmlContent = document.getElementById("pdfContent");
      let submitButton = htmlContent.querySelector('button[type="submit"]');
      let sideText = document.getElementById("print-div");

      let signature = document.getElementById("signature-btn");
      if (submitButton) {
        submitButton.remove();
      }
      if (signature) {
        signature.remove();
      }
      if (sideText) {
        sideText.remove();
      }
      dispatch(setLoader({ loading: false }));
    }
  }, [viewMode]);

  return (
    <>
      {(isLoading || downloadLoader) && <Loader />}
      <Form
        validate={validation}
        initialValues={useMemo((e) => initialValues(e), [PrefillDetails])}
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, form, values, valid, submitFailed }) => (
          <div className="Home p-5" id="pdfContent">
            <form
              onSubmit={(e) => {
                if (!valid) toast.error("Please complete all required fields.");
                handleSubmit(e);
              }}
            >
              {viewMode && (
                <>
                  <div style={{ textAlign: "center" }} ref={downloadRef}></div>
                  <div className="tax-form-download">
                    {" "}
                    <img
                      onClick={() => downloadPdf()}
                      alt=""
                      src={downloadIcon}
                    />
                  </div>
                </>
              )}

              <div className={`tax-section`}>
                <div className="border-b-2 flex items-stretch">
                  <div className="basis-200">
                    <div>
                      <p className="from-heading">
                        {" "}
                        form{" "}
                        <span className="text-[2rem] font-extrabold whitespace-nowrap">
                          W-9
                        </span>
                      </p>
                      <div>
                        (Rev. October 2018) Department of the Treasury Internal
                        Revenue Service
                      </div>
                    </div>
                  </div>

                  <div className="text-center  border-x-2 ceneter-div   text-heading-bold grow px-4">
                    <div className="mb-1">
                      <div className="request">Request for Taxpayer</div>
                      <div className="request">
                        Identification and Certification
                      </div>
                    </div>
                    <div className="text-heading-light-bold">
                      <span className="h-2 w-2"></span>{" "}
                      <span>
                        Go to{" "}
                        <a
                          className="italic"
                          href="https://www.irs.gov/forms-pubs/about-form-w-9"
                        >
                          www.iris.gov/Form w9
                        </a>{" "}
                        for instructions and the latest information.
                      </span>
                    </div>
                  </div>
                  <div className="font-[700] pl-1  text-left text-1rem basis-200 border-r-0 ">
                    Give Form to the requester.Do not send to the IRS.
                  </div>
                </div>

                <div className="flex">
                  <div
                    id="print-div"
                    className="text-center leading-3"
                    style={{
                      borderLeft: "2px solid #000",
                      writingMode: "vertical-rl",
                      rotate: "-180deg",
                    }}
                  >
                    <div>
                      <span>Print or type</span>
                    </div>
                    <div>
                      <span>
                        See{" "}
                        <span className="font-bold">Specific Instructions</span>{" "}
                        on page 3.
                      </span>
                    </div>
                  </div>

                  <div style={{ borderLeft: "2px solid black" }}>
                    <div
                      style={{
                        paddingLeft: "1rem",
                        borderBottom: "2px solid black",
                      }}
                    >
                      <Field
                        name="Name"
                        component="input"
                        type="text"
                        placeholder=""
                        // className="input-textSize"
                        style={{ fontSize: "1rem" }}
                      >
                        {({ input, meta }) => (
                          <div>
                            <label>
                              <span
                                style={{
                                  border: "none",
                                  marginRight: "0.5rem",
                                }}
                              >
                                1
                              </span>
                              Name (as shown on your income tax return). Name is
                              required on this line; do not leave this line
                              blank.
                            </label>
                            <input
                              className={`input-textSize border-0 text-dark`}
                              style={{
                                backgroundColor: "#e7f4ff",
                                fontSize: "1rem",
                                border: "none",
                                color: input.value ? "#000000 !important" : "",
                              }}
                              disabled={isAdminView || viewMode}
                              {...input}
                              type="text"
                              placeholder=""
                            />
                            {meta.error && meta.touched && (
                              <span className="alert-danger">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>

                    <div style={{ paddingLeft: "1rem" }}>
                      <div>
                        <Field
                          name="BusinessName"
                          component="input"
                          type="text"
                          placeholder=""
                          style={{ fontSize: "1rem" }}
                        >
                          {({ input, meta }) => (
                            <div>
                              <label>
                                <span
                                  style={{
                                    border: "none",
                                    marginRight: "0.5rem",
                                  }}
                                >
                                  2
                                </span>
                                Business name/disregarded entity name, if
                                different from above
                              </label>
                              <input
                                className={`input-textSize border-0 text-dark`}
                                style={{
                                  backgroundColor: "#e7f4ff",
                                  fontSize: "1rem",
                                  border: "none",
                                  color: input.value && "#000000",
                                }}
                                disabled={isAdminView || viewMode}
                                {...input}
                                type="text"
                                placeholder=""
                              />
                              {meta.error && meta.touched && (
                                <span className="alert-danger">
                                  {meta.error}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div style={{ borderBottom: "2px solid black" }}></div>

                    <div className="flex border-y-2">
                      <div className=" p-2 w-[170%] border-r-1">
                        <label className="inline-block mb-1">
                          <span className="text-ordered-list mr-1">3</span>Check
                          appropriate box for federal tax classification of the
                          person whose name is entered on line 1. Check only one
                          of the following seven boxes.{" "}
                        </label>
                        <div>
                          <div className="d-flex gap-3">
                            <FieldArray
                              name="taxclassification"
                              validate={radioErr}
                            >
                              {({ fields, meta }) => {
                                return (
                                  <div>
                                    {fields.map((name, index) => (
                                      <>
                                        <div>
                                          <div className="d-flex">
                                            {RadioData.map((item, index) => (
                                              <>
                                                <label
                                                  key={index}
                                                  className="w-25 container-2 align-items-center d-flex padding-left-ref  margin-bottom-1"
                                                >
                                                  <Field
                                                    name={`${name}.taxclerificationsInputs`}
                                                    component="input"
                                                    type={item.type}
                                                    value={item.label}
                                                    id={`${index}`}
                                                    disabled={
                                                      isAdminView || viewMode
                                                    }
                                                  />
                                                  <span className="checkmark-5 Form"></span>
                                                  <div className="ml-3">
                                                    {item.label}
                                                  </div>
                                                </label>
                                              </>
                                            ))}
                                          </div>
                                          <div>
                                            <div>
                                              <label className="mb-0 container-2 align-items-center d-flex padding-left-ref  margin-bottom-1">
                                                <Field
                                                  name={`${name}.taxclerificationsInputs`}
                                                  component="input"
                                                  type="radio"
                                                  value={"Limited Liability"}
                                                  disabled={
                                                    isAdminView || viewMode
                                                  }
                                                  className="control-input-radio"
                                                  id="control-input-radio5"
                                                />
                                                <span className="checkmark-5 Form"></span>
                                                <div className="ml-3 flex">
                                                  <p className="limited-inut-text mb-0">
                                                    Limited liability company.
                                                    Enter the tax classification
                                                    (C=C corporation, S=S
                                                    corporation, P=Partnership){" "}
                                                  </p>
                                                  {values?.taxclassification[0]
                                                    ?.taxclerificationsInputs ===
                                                    "Limited Liability" && (
                                                    <div className="w-14">
                                                      <label className="position-relative flex ml-3">
                                                        <div>
                                                          <label className="d-flex">
                                                            <span className="inline-block h-4 w-4">
                                                              &#9658;
                                                            </span>

                                                            <span className="inline-block h-4 w-4">
                                                              &#9658;
                                                            </span>
                                                            <Field
                                                              name="federal-tax-classification"
                                                              component="input"
                                                              type="text"
                                                              disabled={
                                                                isAdminView ||
                                                                viewMode
                                                              }
                                                              placeholder=""
                                                              className="outline-none border-top-0 border-left-0 border-right-0 p-1 border-bottom-2 position-unset border-black w-[150px]"
                                                            />
                                                          </label>
                                                        </div>
                                                      </label>
                                                    </div>
                                                  )}
                                                </div>
                                              </label>
                                            </div>
                                            <div>
                                              <div className="leading-3 pl-5">
                                                <span className="fw-bold">
                                                  Note :
                                                </span>
                                                <span>
                                                  {" "}
                                                  Check the appropriate box in
                                                  the line above for the tax
                                                  classification of the
                                                  single-member owner. Do not
                                                  check LL C if the LLC is
                                                  classified as a single-member
                                                  LLC that is disregarded from
                                                  the owner unless the owner of
                                                  the LLC is another LLC that is
                                                  not disregarded from the owner
                                                  for U.S. federal tax purposes.
                                                  Otherwise, a single-member LLC
                                                  that is disregarded from the
                                                  owner should check the
                                                  appropriate box for the tax
                                                  classification of its owner.
                                                </span>
                                              </div>
                                              <label className=" mt-3 mb-0 container-2 align-items-center d-flex padding-left-ref  margin-bottom-1">
                                                <Field
                                                  name={`${name}.taxclerificationsInputs`}
                                                  component="input"
                                                  type="radio"
                                                  value={"other"}
                                                  disabled={
                                                    isAdminView || viewMode
                                                  }
                                                  className="outline-none border-b-2 border-black w-[150px]"
                                                  id="7"
                                                />
                                                <span className="checkmark-5 Form"></span>

                                                <div className="ml-3">
                                                  Other (see Instruction)
                                                </div>

                                                <span className="inline-block h-4 w-4">
                                                  &#9658;
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                          {meta.error &&
                                            meta.touched &&
                                            meta.error.col1 && (
                                              <p className="alert alert-danger mt-3">
                                                {meta.error.col1}
                                              </p>
                                            )}
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                );
                              }}
                            </FieldArray>
                          </div>
                        </div>
                      </div>
                      <div className="pl-2 pt-2 border-l-2 ">
                        <div>
                          <span className="text-ordered-list pr-1">4</span>{" "}
                          Exemptions (codes apply only to certain entities, not
                          individuals; see instructions on page 3):
                        </div>
                        <FieldArray name="Exemptions">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <div key={name} className="my-3">
                                <div className="d-flex main-exempt">
                                  <label>Exempt payee code (if any)</label>
                                  <Field
                                    name={`${name}.payeecode`}
                                    component="input"
                                    type="number"
                                    value=""
                                    className="outline-none payee-code  border-black w-[40px]"
                                  >
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          className={`input-textSize border-b-2 border-left-0 border-right-0 border-top-0 text-dark`}
                                          style={{
                                            backgroundColor: "#e7f4ff",
                                            color: "#000000",
                                          }}
                                          disabled={isAdminView || viewMode}
                                          {...input}
                                          type="number"
                                          placeholder=""
                                        />
                                        {meta.error && meta.touched && (
                                          <span className="alert-danger">
                                            {meta.error}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className="d-flex main-exempt">
                                  <label>
                                    Exemption from FATCA reporting code (if any)
                                  </label>
                                  <Field
                                    name={`${name}.reportingcode`}
                                    component="input"
                                    type="number"
                                    value=""
                                    className="outline-none payee-code  border-black w-[60px]"
                                  >
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          className={`input-textSize border-b-2 border-left-0 border-right-0 border-top-0 text-dark`}
                                          style={{
                                            backgroundColor: "#e7f4ff",
                                            color: "#000000",
                                          }}
                                          disabled={isAdminView || viewMode}
                                          {...input}
                                          type="number"
                                          placeholder=""
                                        />
                                        {meta.error && meta.touched && (
                                          <span className="alert-danger">
                                            {Array.isArray(meta.error)
                                              ? meta.error[0]
                                              : meta.error}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              </div>
                            ))
                          }
                        </FieldArray>

                        <div className="italic text-[0.6rem]">
                          (Applies to accounts maintained outside the U.S.)
                        </div>
                      </div>
                    </div>
                    <div div className="flex border-bottom-2">
                      <div className="basis-60 border-r-1">
                        <label className="pl-1 w-100 border-b-2">
                          <label>
                            <span className="text-ordered-list mr-1">5</span>
                            Address (number, street, and apt. or suite no.) See
                            instructions.
                          </label>
                          <Field
                            name="Address1"
                            component="input"
                            type="text"
                            placeholder=""
                            className="input-textSize border-0"
                          >
                            {({ input, meta }) => (
                              <div>
                                <input
                                  className={`input-textSize border-0 text-dark`}
                                  style={{
                                    backgroundColor: "#e7f4ff",
                                    color: input.value && "#000000",
                                  }}
                                  disabled={isAdminView || viewMode}
                                  {...input}
                                  type="text"
                                  placeholder=""
                                />
                                {meta.error && meta.touched && (
                                  <span className="alert-danger">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </label>
                        <label className="pl-1 w-100 border-b-2">
                          <label>
                            <span className="text-ordered-list mr-1">6</span>{" "}
                            City, state, and ZIP code
                          </label>
                          <Field
                            name="Address"
                            component="input"
                            type="text"
                            placeholder=""
                            className="input-textSize border-0"
                          >
                            {({ input, meta }) => (
                              <div>
                                <input
                                  className={`input-textSize border-0 text-dark`}
                                  style={{
                                    backgroundColor: "#e7f4ff",
                                    color: input.value && "#000000",
                                  }}
                                  {...input}
                                  disabled={isAdminView || viewMode}
                                  type="text"
                                  placeholder=""
                                />
                                {meta.error && meta.touched && (
                                  <span className="alert-danger">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </label>
                        <label className="pl-1 w-100 mb-0">
                          <label>
                            <span className="text-ordered-list mr-1">7</span>
                            List account number(s) here (optional)
                          </label>
                          <Field
                            name="accountNumber"
                            component="input"
                            type="number"
                            placeholder=""
                            className="input-textSize border-0"
                          >
                            {({ input, meta }) => (
                              <div>
                                <input
                                  className={`input-textSize border-0 text-dark`}
                                  style={{
                                    backgroundColor: input.value && "#e7f4ff",
                                    color: input.value && "#000000",
                                  }}
                                  {...input}
                                  disabled={isAdminView || viewMode}
                                  type="number"
                                  placeholder=""
                                />
                                {meta.error && meta.touched && (
                                  <span className="alert-danger">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </label>
                      </div>
                      <div className="w-40 border-l-2  grow">
                        <div>
                          <label
                            className="w-100 pl-1"
                            style={{ marginLeft: "10px" }}
                          >
                            Requester’s name and address (optional)
                          </label>

                          <Field
                            name="requesterNameAndAddress"
                            component="textarea"
                            type="text"
                            placeholder=""
                            className="input-textSize w-100 height-170 border-0 "
                          >
                            {({ input, meta }) => (
                              <div>
                                <textarea
                                  className={`input-textSize border-0 height-170 text-dark`}
                                  style={{
                                    backgroundColor: input.value && "#e7f4ff",
                                    color: input.value && "#000000",
                                  }}
                                  {...input}
                                  disabled={isAdminView || viewMode}
                                  type="text"
                                  placeholder=""
                                ></textarea>
                                {meta.error && meta.touched && (
                                  <span className="alert-danger">
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="mr-2 w-75 p-1">
                        <div className="text-paragraph-normal mb-2">
                          Enter your TIN in the appropriate box. The TIN
                          provided must match the name given on line 1 to avoid
                          backup withholding. For individuals, this is generally
                          your social security number (SSN). However, for a
                          resident alien, sole proprietor, or disregarded
                          entity, see the instructions for Part I, later. For
                          other entities, it is your employer identification
                          number (EIN). If you do not have a number, see How to
                          get a TIN, later.
                        </div>
                        <div className="text-paragraph-normal">
                          <span className="font-medium mr-2">Note:</span>
                          <span>
                            If the account is in more than one name, see the
                            instructions for line 1. Also see{" "}
                            <span className="italic">
                              What Name and Number To Give the Requester
                            </span>{" "}
                            for guidelines on whose number to enter.
                          </span>
                        </div>
                      </div>
                      <div className="w-25">
                        <div className="px-2 py-1 font-medium border-bottom-2 border-left-2 border-right-2">
                          Social Security Number
                        </div>
                        <FieldArray name="SocialSecurity">
                          {({ fields }) => {
                            return (
                              <div
                                style={{
                                  position: "relative",
                                  padding: "10px",
                                }}
                              >
                                {fields.map((name, index) => (
                                  <>
                                    {fieldsss.map((otps, index) => (
                                      <>
                                        {(index === 3 && (
                                          <>
                                            <sapn>-</sapn>
                                          </>
                                        )) ||
                                          (index === 7 && (
                                            <>
                                              <sapn>-</sapn>
                                            </>
                                          ))}
                                        <Field
                                          name={`${name}.OTPd${index}`}
                                          component="input"
                                          type="text"
                                          placeholder=""
                                        >
                                          {({ input, meta }) => (
                                            <>
                                              <input
                                                {...input}
                                                className={`chakra-pin-input text-center  otp-inputs-css border-right-2 text-dark`}
                                                style={{
                                                  backgroundColor: "#e7f4ff",
                                                  color:
                                                    input.value && "#000000",
                                                }}
                                                disabled={
                                                  isAdminView || viewMode
                                                }
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[0-9]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                maxLength="1"
                                                ref={inputRefss[index]}
                                                onChange={(e) => {
                                                  input.onChange(e);
                                                  handleInputChanges(e, index);
                                                }}
                                              />
                                              {meta.error && meta.touched && (
                                                <span
                                                  style={{
                                                    position: "absolute",
                                                    left: "0px",
                                                    width: "100%",
                                                    bottom: "-34px",
                                                  }}
                                                  className="alert-danger"
                                                >
                                                  {meta.error}
                                                </span>
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </>
                                    ))}
                                  </>
                                ))}
                              </div>
                            );
                          }}
                        </FieldArray>
                        <div className="font-medium my-1">or</div>
                        <div className=" border-1 px-2 py-1 font-medium ">
                          Employer Identification Number
                        </div>
                        <FieldArray name="EmployerIdentification">
                          {({ fields }) => {
                            return (
                              <div
                                style={{
                                  position: "relative",
                                  padding: "10px",
                                }}
                              >
                                {fields.map((name, index) => (
                                  <>
                                    {fieldds.map((otps, index) => (
                                      <>
                                        {index === 2 ? (
                                          <>
                                            <span>-</span>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <Field
                                          name={`${name}.OTPs${index}`}
                                          component="input"
                                          type="text"
                                          placeholder=""
                                        >
                                          {({ input, meta }) => (
                                            <>
                                              <input
                                                {...input}
                                                className={`chakra-pin-input text-center  otp-inputs-css border-right-2 text-dark`}
                                                style={{
                                                  backgroundColor: "#e7f4ff",
                                                  color:
                                                    input.value && "#000000",
                                                }}
                                                disabled={
                                                  isAdminView || viewMode
                                                }
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[0-9]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                maxLength="1"
                                                ref={inputRefs[index]}
                                                onChange={(e) => {
                                                  input.onChange(e);
                                                  handleInputChange(e, index);
                                                }}
                                              />
                                              {meta.error && meta.touched && (
                                                <span
                                                  style={{
                                                    position: "absolute",
                                                    left: "0px",
                                                    width: "100%",
                                                    bottom: "-34px",
                                                  }}
                                                  className="alert-danger"
                                                >
                                                  {meta.error}
                                                </span>
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </>
                                    ))}
                                  </>
                                ))}
                              </div>
                            );
                          }}
                        </FieldArray>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-y-2 flex border-tops-2 leading-none text-[1.2rem] font-medium">
                  <div className="px-8 bg-black  text-white  mr-6">Part II</div>
                  <span className="pl-3"> Certification </span>
                </div>

                <div className="text-paragraph-normal">
                  <div>Under penalties of perjury, I certify that:</div>
                  <ol>
                    <li>
                      {" "}
                      The number shown on this form is my correct taxpayer
                      identification number (or I am waiting for a number to be
                      issued to me); and
                    </li>
                    <li>
                      I am not subject to backup withholding because: (a) I am
                      exempt from backup withholding, or (b) I have not been
                      notified by the Internal Revenue Service (IRS) that I am
                      subject to backup withholding as a result of a failure to
                      report all interest or dividends, or (c) the IRS has
                      notified me that I am no longer subject to backup
                      withholding; and
                    </li>
                    <li>
                      {" "}
                      I am a U.S. citizen or other U.S. person (defined below);
                      and
                    </li>
                    <li>
                      The FATCA code(s) entered on this form (if any) indicating
                      that I am exempt from FATCA reporting is correct
                    </li>
                  </ol>
                  <div
                    className="my-1 leading-3 "
                    style={{ padding: "10px 5px" }}
                  >
                    <span className=" ">
                      Certification instructions. You must cross out item 2
                      above if you have been notified by the IRS that you are
                      currently subject to backup withholding because you have
                      failed to report all interest and dividends on your tax
                      return. For real estate transactions, item 2 does not
                      apply. For mortgage interest paid, acquisition or
                      abandonment of secured property, cancellation of debt,
                      contributions to an individual retirement arrangement
                      (IRA), and generally, payments other than interest and
                      dividends, you are not required to sign the certification,
                      but you must provide your correct TIN. See the
                      instructions for Part II, later.
                    </span>
                  </div>

                  <div
                    className="border-y-2 text-[1.2rem] font-medium border-tops-2 "
                    style={{ padding: "10px 5px", marginTop: "10px" }}
                  >
                    <div className="pr-2 d-flex  leading-none inline-block">
                      <div
                        className="signHere"
                        style={{
                          width: "10%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <p className="p-0 m-0">Sign</p>
                        <p className="p-0 m-0">Here</p>
                      </div>
                      <div
                        style={{
                          width: "70.5%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p className="p-0 m-0 w-25 pl-1 mein-fonts  d-flex justify-content-center align-items-center">
                          <strong>
                            Signature of
                            <br /> U.S. person{" "}
                          </strong>
                        </p>
                        {signatureAdded && (
                          <img
                            src={signatureImage}
                            height={"100px"}
                            width={"500px"}
                            crossOrigin="anonymous"
                          />
                        )}
                        {!isAdminView && (
                          <button
                            id="signature-btn"
                            className="Approve-btns ms-auto"
                            onClick={(e) => {
                              e.preventDefault();
                              setIsCanvasShow(true);
                            }}
                          >
                            {signatureAdded
                              ? "Update Signature"
                              : "Add Signature"}{" "}
                          </button>
                        )}
                      </div>
                      <div
                        style={{
                          width: "60.5%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p className='class="p-0 m-0 w-25 pl-1 mein-fonts  d-flex justify-content-center align-items-center'>
                          <strong>Date</strong>
                        </p>
                        {changeDateFormat(
                          todayDate,
                          "YYYY-MM-DD",
                          "MM/DD/YYYY"
                        )}
                        {/* <input
                          type="date"
                          disabled
                          className=" pl-0 border-0 border-right-2 border-left-2 border-bottom-2 pt-2 pb-2"
                          value={todayDate}
                          pattern="(0[1-9]|1[0-2])\s(0[1-9]|[12][0-9]|3[01])\s\d{4}"
                        /> */}
                        {!isAdminView && (
                          <button
                            id="print-div"
                            type="submit"
                            className="Approve-btns ms-auto"
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="column-container text-paragraph-normal">
                    <h3 className="fw-bold text-[1.3rem] pt-3">
                      General Instructions
                    </h3>
                    <div>
                      Section references are to the Internal Revenue Code unless
                      otherwise noted.
                    </div>
                    <div>
                      <span className="fw-bold">Future developments.</span>{" "}
                      <span>
                        For the latest information about developments related to
                        Form W-9 and its instructions, such as legislation
                        enacted after they were published, go to{" "}
                        <a
                          href="https://www.irs.gov/forms-pubs/about-form-w-9"
                          className="italic"
                        >
                          www.irs.gov/FormW9.
                        </a>
                      </span>
                    </div>
                    <h3 className="fw-bold text-[1.3rem] pt-3">
                      Purpose of Form
                    </h3>
                    <div>
                      An individual or entity (Form W-9 requester) who is
                      required to file an information return with the IRS must
                      obtain your correct taxpayer identification number (TIN)
                      which may be your social security number (SSN), individual
                      taxpayer identification number (ITIN), adoption taxpayer
                      identification number (ATIN), or employer identification
                      number (EIN), to report on an information return the
                      amount paid to you, or other amount reportable on an
                      information return. Examples of information returns
                      include, but are not limited to, the following
                    </div>

                    <ul className=" pt-3" style={{ paddingTop: "1rem" }}>
                      <li>Form 1099-INT (interest earned or paid)</li>
                      <li>
                        Form 1099-DIV (dividends, including those from stocks or
                        mutual funds.
                      </li>

                      <li>
                        {" "}
                        Form 1099-MISC (various types of income, prizes, awards,
                        or gross proceeds
                      </li>
                      <li>
                        {" "}
                        Form 1099-B (stock or mutual fund sales and certain
                        other transactions by brokers)
                      </li>
                      <li>
                        Form 1099-S (proceeds from real estate transactions)
                      </li>
                      <li>
                        {" "}
                        Form 1099-K (merchant card and third party network
                        transactions)
                      </li>
                      <li>
                        Form 1098 (home mortgage interest), 1098-E (student loan
                        interest), 1098-
                      </li>
                      <li>Form 1099-C (canceled debt)</li>
                      <li>
                        {" "}
                        Form 1099-A (acquisition or abandonment of secured
                        property)
                      </li>
                    </ul>
                    <div>
                      Use Form W-9 only if you are a U.S. person (including a
                      resident alien), to provide your correct TIN
                    </div>
                    <div>
                      <span>
                        If you do not return Form W-9 to the requester with a
                        TIN, you might be subject to backup withholding. See
                        What is backup withholding, later.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`tax-section`}>
                <SecondPage />
              </div>
              <div className={`tax-section`}>
                <ThirdPage />
              </div>
              <div className={`tax-section`}>
                <FourthPage />
              </div>
              <div className={`tax-section`}>
                <FifthPage />
                <SixthPage />
              </div>
            </form>
            {isCanvasShow && (
              <SignatureCanvas
                loadDoc={saveForm}
                isShow={isCanvasShow}
                setSignatureAdded={setSignatureAdded}
                handleClose={() => setIsCanvasShow(false)}
              />
            )}
          </div>
        )}
      />
    </>
  );
};

export default TaxForm;
