/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PatientChart from "../../../components/PatientChart";
import { getTherapistPatientChart } from "../../../Redux/actions/common";

const Chart = () => {
  let tableColumns = [
    "staffUser.firstName",
    "staffUser.staffingDetails.address",
    "staffUser.staffingDetails.disciplinesNeeded",
    "staffUser.staffingDetails.caseStatus.name",
  ];
  return (
    <PatientChart
      getList={getTherapistPatientChart}
      tableColumns={tableColumns}
    />
  );
};

export default Chart;
