import { createSlice } from "@reduxjs/toolkit";
import {
    getActivityLog,
    AddNewMessage,
    conversationPaginator,
    setActiveConversation,
    getAccessToken,
    messagePaginator,
    getPatientList,
    createConversation,
    messagePrevPage,
    conversationAdded,
    conversationNextPage
} from "../actions/chat";


const initialState = {
    isChatLoading: false,
    isClientReady: false,
    isConversationFetched: false,
    conversationCreated: {},
    patientList: [],
    subscribedConversations: [],
    messagePaginator: {},
    messageItemCount: 0,
    activityLogCount: 0,
    activeConversation: {},
    isConversationActive: false,
    activityLog: [],
    initializeClient: false,
    hasUnreadMessage: false,
    urgentConversationList: [],
}

const chatSlice = createSlice({
    name: 'chatSlice',
    initialState,
    reducers: {
        setChatLoader(state, action) {
            state.isChatLoading = action.payload
        },
        setClientState(state, action) {
            state.isClientReady = action.payload
        },
        setChatScreenState(state, action) {
            state.isConversationActive = action.payload
            state.activeConversation = {}
            state.messagePaginator = {}
            state.messageItemCount = 0
        },
        initializeTwilioClient(state, action) {
            state.initializeClient = action.payload
        },
        unreadMessageCheck(state, action) {
            state.hasUnreadMessage = action.payload
        },
        setUrgentList(state, action) {
            state.urgentConversationList = action.payload
        }
    },
    extraReducers: {
        [getPatientList.fulfilled]: (state, { payload }) => {
            state.patientList = payload?.rows
            state.isChatLoading = false;
        },
        [getPatientList.pending]: (state, { payload }) => {
            state.isChatLoading = true;
        },
        [getPatientList.rejected]: (state, { payload }) => {
            state.isChatLoading = false;
        },
        [createConversation.fulfilled]: (state, { payload }) => {
            state.conversationCreated = payload
            state.isChatLoading = false;
        },
        [createConversation.pending]: (state, { payload }) => {
            state.isChatLoading = true;
        },
        [createConversation.rejected]: (state, { payload }) => {
            state.isChatLoading = false;
        },
        [getAccessToken.fulfilled]: (state, { payload }) => {
            state.isChatLoading = true
        },
        [getAccessToken.pending]: (state, { payload }) => {
            state.isChatLoading = true
        },
        [conversationPaginator.fulfilled]: (state, { payload }) => {
            state.isChatLoading = false
            state.conversationPaginator = payload;
            state.isConversationFetched = true
        },
        [conversationAdded.fulfilled]: (state, { payload }) => {
            state.isChatLoading = false
            state.conversationPaginator = { ...state.conversationPaginator, items: [...state.conversationPaginator.items, payload] };
        },
        [conversationNextPage.fulfilled]: (state, { payload }) => {
            state.conversationPaginator = {
                ...payload,
                items: [...state.conversationPaginator.items, ...payload.items]
            }
        },
        [setActiveConversation.fulfilled]: (state, { payload }) => {
            state.activeConversation = payload
            state.isConversationActive = true
            state.isChatLoading = false
        },
        [getActivityLog.fulfilled]: (state, { payload }) => {
            state.activityLog = payload.data.rows;
            state.activityLogCount = payload.data.count;
            state.isChatLoading = false;
        },
        [getActivityLog.pending]: (state, { payload }) => {
            state.isChatLoading = true;
        },
        [getActivityLog.rejected]: (state, { payload }) => {
            state.activityLog = [];
            state.activityLogCount = 0;
            state.isChatLoading = false;
        },
        [messagePaginator.fulfilled]: (state, { payload }) => {
            state.messagePaginator = payload
            state.messageItemCount = payload.items.length
        },
        [AddNewMessage.fulfilled]: (state, { payload }) => {
            state.messagePaginator.items = [...state.messagePaginator.items, payload]
        },
        [messagePrevPage.fulfilled]: (state, { payload }) => {
            state.messagePaginator = {
                ...payload,
                items: [...payload.items, ...state.messagePaginator.items]
            }
            state.messageItemCount = state.messagePaginator.items.length + payload.items.length
            state.isChatLoading = false
        },
    }
})

export const {
    setChatLoader,
    setClientState,
    setChatScreenState,
    initializeTwilioClient,
    unreadMessageCheck,
    setUrgentList,
} = chatSlice.actions

export default chatSlice.reducer;