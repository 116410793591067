import React, { useMemo, useRef } from "react";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import {
  setDocuSignEnvelope,
  therapistCredentials,
} from "../../../Redux/actions/therapist";
import { useDispatch, useSelector } from "react-redux";
import { TbQuestionMark } from "react-icons/tb";
import {
  credentials,
  currentRole,
  decodeToken,
  defaultStatus,
  docuSignDocs,
  therapistSignupSteps,
  userCredentials,
} from "../../../utils/auth";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import {
  apiBasePath,
  competencyForm,
  complianceForm,
  fluVaccinationForm,
  w9Form,
} from "../../../Redux/config/config";
import { setTherapistDataGetStep } from "../../../Redux/actions/auth";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "bootstrap-4-react/lib/components/layout";
import { IoSyncCircleOutline } from "react-icons/io5";
import { BsChatLeftText } from "react-icons/bs";
import GrAttachment_ from "../../../images/attechment_crential.svg";
import { MyModal2 } from "../../modals/MyModal2";
import TaxForm from "../../modals/taxform/TaxForm";
import OnboardingForms from "../../../components/OnbaordingForms";
import { onboardingFormTypes } from "../../../utils/formImport";
import {
  onboardingPDFRoute,
  therapistCredentialRoute,
} from "../../../utils/directoryroute";
import { triggerUpdateButton } from "../../../Redux/slices/therapistSignupSlice";
import { dateFormatChange, todayDate } from "../../../utils/helper";
import { AiFillPlusCircle } from "react-icons/ai";
import AddNewCredentials from "../../../pages/modals/AddNewCredentials";
import { ProfileValidState } from "../../../Redux/slices/agencySignupSlice";

// import PTEvaluationForm from "../../Patients/PatientForms/PTEvaluationForm";

const TherapistCredentials = (props) => {
  const buttonRef = useRef();
  const triggerUpdateState = useSelector(
    (state) => state.therapistSigunpSlice.triggerUpdateFunction
  );

  // useEffect(() => {
  //   if (triggerUpdateState && props?.isProfile) {
  //     buttonRef.current.click();
  //   }
  // }, [triggerUpdateState])

  useEffect(() => {
    if (triggerUpdateState && buttonRef) {
      setTimeout(() => {
        buttonRef.current.click();
      }, 100);
    }
  }, [triggerUpdateState, buttonRef]);

  const colors = {
    active: { background: "#D9F8DC", color: "#17A325" },
    expired: { background: "#FDDCDC", color: "#D42B2B" },
    rejected: { background: "#FDDCDC", color: "#D42B2B" },
    pendingreview: { background: "#FDEDDC", color: "#CA7718" },
  };

  // let credentialFiles = [];
  // let credentialPayload = [];
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [pageInitilize, setPageInitilize] = useState(false);
  const [credentialFiles, setCredentialFiles] = useState([]);
  const [credentialPayload, setCredentialPayload] = useState([]);
  const [onboardingDocType, setOnboardingDocType] = useState(null);
  const [uploadedFileBytes, setUploadedFileBytes] = useState({});
  const [taxFormValues, setTaxFormValues] = useState();
  const [therapyLicenseRejectReason, setTherapyLicenseRejectReason] =
    useState("");
  const [drivingLicenseRejectReason, setDrivingLicenseRejectReason] =
    useState("");
  const [autoInsuranceRejectReason, setAutoInsuranceRejectReason] =
    useState("");
  const [businessLicenseRejectReason, setBusinessLicenseRejectReason] =
    useState("");
  const [
    professionalLiabilityInsuranceRejectReason,
    setProfessionallaibilityInsuranceRejectReason,
  ] = useState("");
  const [CPRCertificationRejectReason, setCPRCertificationRejectReason] =
    useState("");
  const [physicalRejectReason, setPhysicalRejectReason] = useState("");
  const [TBTestRejectReason, setTBTestRejectReason] = useState("");
  const [fluVaccinationRejectReason, setFluVaccinationRejectReason] =
    useState("");
  const [hepatitisBRejectReason, setHepatitisBRejectReason] = useState("");
  const [covidRecordRejectReason, setCovidRecordRejectReason] = useState("");
  const [resoanReject, setResoanReject] = useState("");
  const [inputPropsForAddNewTest, setInputPropsForAddNewTest] = useState({
    name: null,
    isDisabled: false,
  });
  const [addNewCred, setAddNewCred] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const [w9Validation, setW9Validation] = useState(false);
  const [placementTooltip, setPlacementTooltip] = useState(
    getWindowDimensions() < 1200 ? "auto" : "right"
  );
  const [OnplacementTooltip, setonPlacementTooltip] = useState(
    getWindowDimensions() < 1200 ? "top" : "right"
  );

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const [isPtEvaluation, setIsPtEvaluation] = useState(false);

  const handlePtEvaluation = () => {
    setIsPtEvaluation(true);
  };

  useEffect(() => {
    const reasons = [
      therapyLicenseRejectReason.trim()
        ? `Therapy License: ${therapyLicenseRejectReason}`
        : "",
      drivingLicenseRejectReason.trim()
        ? `Driver's License: ${drivingLicenseRejectReason}`
        : "",
      autoInsuranceRejectReason.trim()
        ? `Auto Insurance: ${autoInsuranceRejectReason}`
        : "",
      professionalLiabilityInsuranceRejectReason.trim()
        ? `Professional Liability Insurance: ${professionalLiabilityInsuranceRejectReason}`
        : "",
      CPRCertificationRejectReason.trim()
        ? `CPR Certification: ${CPRCertificationRejectReason}`
        : "",
      physicalRejectReason.trim() ? `Physical: ${physicalRejectReason}` : "",
      TBTestRejectReason.trim() ? `TB Test: ${TBTestRejectReason}` : "",
      fluVaccinationRejectReason.trim()
        ? `Flu Vaccination: ${fluVaccinationRejectReason}`
        : "",
      hepatitisBRejectReason.trim()
        ? `Hepatitis B: ${hepatitisBRejectReason}`
        : "",
      covidRecordRejectReason.trim()
        ? `COVID Record: ${covidRecordRejectReason}`
        : "",
      businessLicenseRejectReason.trim()
        ? `Business License: ${businessLicenseRejectReason}`
        : "",
    ]
      .filter(Boolean)
      .join(" ");
    setResoanReject(reasons);
  }, [
    therapyLicenseRejectReason,
    drivingLicenseRejectReason,
    autoInsuranceRejectReason,
    professionalLiabilityInsuranceRejectReason,
    CPRCertificationRejectReason,
    physicalRejectReason,
    TBTestRejectReason,
    fluVaccinationRejectReason,
    hepatitisBRejectReason,
    covidRecordRejectReason,
    businessLicenseRejectReason,
  ]);

  function handleDateFocus(event) {
    event.target.type = "date";
  }

  function ptEvaluationHandle() {
    setIsPtEvaluation(false);
  }
  function handleDateBlur(event) {
    if (!event.target.value) {
      event.target.type = "text";
    }
  }
  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    if (isNaN(date)) return null;
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const loggedInRole = useSelector(
    (state) => state?.adminSlice?.currentActiveUserDetails?.Role
  );

  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const agencyCheck = useSelector(
    (e) => e.therapistSigunpSlice.checkAgencyValue
  );

  const [searchParams] = useSearchParams();

  let userDetail = null;

  if (searchParams && searchParams.get("auth")) {
    userDetail = decodeToken(searchParams.get("auth"));
  }
  if (PrefillDetails && props?.isProfile) {
    userDetail = {
      userId: PrefillDetails?.id,
      email: PrefillDetails?.email,
      firstName: PrefillDetails?.firstName,
      lastName: PrefillDetails?.lastName,
    };
  }

  let today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;
  function handleSubmit(event) {
    var formData = new FormData();

    if (
      event &&
      PrefillDetails &&
      PrefillDetails?.email &&
      event?.complianceExpiration
    ) {
      dispatch(
        setDocuSignEnvelope({
          types: docuSignDocs.compliance,
          complianceExpirationDate: event?.complianceExpiration,
          email: PrefillDetails?.email,
        })
      );
    }

    if (
      event &&
      PrefillDetails &&
      PrefillDetails?.email &&
      event?.competencyExpiration
    ) {
      dispatch(
        setDocuSignEnvelope({
          types: docuSignDocs.competency,
          competencyExpirationDate: event?.competencyExpiration,
          email: PrefillDetails?.email,
        })
      );
    }

    if (credentialPayload?.length > 0) {
      credentialPayload?.forEach((item) => {
        if (item?.types === "compliance") {
          delete item?.expirationDate;
          delete item?.types;
        }
        if (item?.types === "competency") {
          delete item?.expirationDate;
          delete item?.types;
        }

        if (credentialFiles?.length > 0) {
          credentialFiles?.forEach((fil) => {
            if (item?.types === fil?.orgFileData?.type) {
              item["name"] = fil?.orgFileData?.name;
            }
          });
        }
      });
    }

    for (var i = 0; i < credentialFiles.length; i++) {
      delete credentialFiles[i].orgFileData;
      formData.append("files", credentialFiles[i]);
    }

    formData.append(
      "therapistData",
      JSON.stringify({
        values: credentialPayload,
        userId: PrefillDetails && PrefillDetails.id,
        isProfile: props?.isProfile && props?.isProfile,
        textData: JSON.stringify(taxFormValues),
        updateStep: true,
      })
    );
    let credentials = ["fluvaccination", "covidrecord", "hepatitisB"];
    let isDeclined = credentials.filter((item) => event[`is${item}`] && item);
    let isUploaded = true;
    isDeclined.forEach((item) => {
      let detail = PrefillDetails.userCredentials.find(
        (credential) => credential.types === item
      );
      if (detail && detail.isDeclination) {
        if (!detail.file) {
          isUploaded = false;
        }
      } else {
        if (!uploadedFileBytes[item]) {
          isUploaded = false;
        }
      }
    });

    if (isUploaded) {
      dispatch(therapistCredentials(formData)).then((res) => {
        dispatch(setTherapistDataGetStep(therapistSignupSteps[5]));
        if (res) {
          props.setTabUpdateCheker && props.setTabUpdateCheker();

          if (props.isProfile) {
            toast.success("Profile updated successfully.");
            props.triggerTabChanges();
          }
        }
      });
    } else {
      toast.error("Please upload all declination forms.");
    }
  }

  const isUploaded = (type) => {
    let details =
      PrefillDetails &&
      PrefillDetails?.userCredentials?.find(
        (credential) => credential.types === type
      );
    return details && details?.file && details?.isDeclination
      ? true
      : uploadedFileBytes[type]
      ? true
      : false;
  };
  const handleChange = (event, typename, values, form) => {
    let fileList = [...credentialFiles];
    let payloadList = [...credentialPayload];
    let filedata = {
      types: typename,
    };
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    let oneYearPlusDate = date.toISOString().split("T")[0];

    if (values[`${typename}id`]) {
      filedata["id"] = values[`${typename}id`];
    }
    filedata["isDeclination"] = values[`is${typename}`] ? true : false;
    if (event.target.type === "date") {
      filedata["expirationDate"] = event.target.value;
    } else {
      let temp = `${typename}_`;
      const filename = temp + new Date().getTime();

      if (event.target.files.length > 0 && event.target.files[0]) {
        const extension = event.target.files[0].name.split(".").pop();
        var allowedExtensions = /(\.heic|\.jpeg|\.png|\.pdf)$/i;
        if (!allowedExtensions.exec(`${filename}.${extension}`)) {
          toast.error("Upload format only (heic, jpeg, png, pdf).");
          return false;
        }
        let file = new File(event.target.files, `${filename}.${extension}`);

        if (values[`${typename}Expiration`]) {
          filedata["expirationDate"] = values[`${typename}Expiration`];
        }
        if (file) {
          file["orgFileData"] = {
            name: event?.target?.files[0]?.name,
            type: typename,
            isDeclination: values[`is${typename}`] ? true : false,
          };
        }

        if (fileList.length === 0) {
          fileList.push(file);
        } else {
          fileList.map((ele, ind) => {
            if (ele.name.includes(temp)) {
              fileList.splice(ind, 1);
            }
          });
          fileList.push(file);
        }
      }
    }
    if (typename === "fluvaccination" && values[`is${typename}`]) {
      filedata["expirationDate"] = oneYearPlusDate;
    }

    if (payloadList.length === 0) {
      payloadList.push(filedata);
    } else {
      payloadList.map((ele, ind) => {
        if (ele.types.includes(typename)) {
          payloadList.splice(ind, 1);
        }
      });

      payloadList.push(filedata);
    }
    setCredentialFiles(fileList);
    setCredentialPayload(payloadList);
  };

  const validateForm = (values) => {
    let errors = {};
    let testError = {};
    var allowedExtensions = /(\.heic|\.jpeg|\.png|\.pdf)$/i;
    let allowed = ["heic", "jpeg", "png", "pdf"];
    credentials.map((item, index) => {
      const fileExtension =
        (values[item] && values[item].split(".").pop()) || "";
      if (
        item == "w9" ||
        item == "covidrecord" ||
        item == "hepatitisB" ||
        item == "fluvaccination"
      ) {
        if (item === "covidrecord" || item === "hepatitisB") {
          if (!values[`${item}file`]) {
            if (values[`is${item}`]) {
              if (!uploadedFileBytes[item]) {
                testError[item] = `Add declaration form.`;
              }
            } else {
              if (!values[`${item}`]) {
                testError[`${item}`] = `Please upload proof of vaccination.`;
              } else if (values[`${item}`]) {
                const extension = values[item].split(".").pop();
                if (!allowed.includes(extension)) {
                  testError[
                    `${item}`
                  ] = `Upload format only (heic, jpeg, png, pdf).`;
                }
              }
            }
          }
        }
        if (item === "w9") {
          if (!values[`${item}file`]) {
            if (!uploadedFileBytes.w9) {
              testError[`${item}`] = `Please complete W9 form.`;
            }
          }
        }
        if (item === "fluvaccination") {
          if (!values[`${item}file`] || !values[`${item}Expiration`]) {
            if (values?.isfluvaccination) {
              if (!uploadedFileBytes[`fluvaccination`]) {
                testError[`fluvaccinationExpiration`] = `Add declaration form.`;
              }
            } else {
              if (
                !values?.fluvaccinationfile &&
                !values[`fluvaccinationExpiration`]
              ) {
                testError[
                  `fluvaccinationExpiration`
                ] = `Please upload the credential and input the expiration date.`;
              } else {
                if (!values?.fluvaccination) {
                  testError[
                    `fluvaccinationExpiration`
                  ] = `Please upload a file.`;
                } else if (values?.fluvaccination) {
                  const extension = values[item].split(".").pop();
                  if (!allowed.includes(extension)) {
                    testError[
                      `${item}Expiration`
                    ] = `Upload format only (heic, jpeg, png, pdf).`;
                  }
                }
                if (!values[`fluvaccinationExpiration`]) {
                  testError[
                    `fluvaccinationExpiration`
                  ] = `Expiration field required.`;
                }
              }
            }
          }
        }
      } else {
        if (!values[`${item}file`] || !values[`${item}Expiration`]) {
          if (!values[`${item}file`] && !values[`${item}Expiration`]) {
            testError[
              `${item}Expiration`
            ] = `Please upload the credential and input the expiration date.`;
          } else {
            if (!values[`${item}`]) {
              testError[`${item}Expiration`] = `Please upload a file.`;
            }
            if (values[`${item}`]) {
              const extension = values[item].split(".").pop();
              if (!allowed.includes(extension)) {
                testError[
                  `${item}Expiration`
                ] = `Upload format only (heic, jpeg, png, pdf).`;
              }
            }

            if (!values[`${item}Expiration`]) {
              testError[`${item}Expiration`] = `Expiration field required.`;
            }
          }
        }
      }
    });
    dispatch(
      ProfileValidState(Object.keys(testError).length > 0 ? true : false)
    );
    return testError;
  };

  function convertDate(str) {
    if (str) {
      var convertedDate = str.substr(0, str.indexOf("T00"));
      return convertedDate;
    }
  }

  const setInitialValue = (event) => {
    let initialValue = {};
    if (event && Object.keys(event).length > 0) {
      return event;
    }

    const truncateName = (name, maxLength = 30) => {
      if (name?.length > maxLength) {
        return name.substring(0, maxLength) + "....";
      }
      return name;
    };

    if (
      PrefillDetails &&
      PrefillDetails.userCredentials &&
      PrefillDetails.userCredentials.length > 0
    ) {
      credentials.map((item) => {
        let detail = PrefillDetails.userCredentials.find(
          (e) => e.credentialsStatus !== "inactive" && e.types === item
        );
        if (detail && detail?.fluanddeclination) {
          initialValue["isfluanddeclination"] = detail?.fluanddeclination;
        }
        if (detail) {
          initialValue[`${item}file`] = detail.file;
          initialValue[`${item}id`] = detail.id;
          initialValue[`${item}name`] = truncateName(detail?.name);
          initialValue[`is${item}`] = detail.isDeclination;
          initialValue[`${item}credentialsStatus`] = detail.credentialsStatus;
          initialValue[`${item}rejectReason`] = detail.rejectReason;
          initialValue[`${item}UpdatedAt`] = detail.updatedAt;
          if (detail.expirationDate)
            initialValue[`${item}Expiration`] = convertDate(
              detail.expirationDate
            );
        }
      });
    }

    if (PrefillDetails && PrefillDetails?.complianceCompetency) {
      credentials?.map((item) => {
        if (item === docuSignDocs.compliance) {
          initialValue[`${item}Expiration`] = convertDate(
            PrefillDetails?.complianceCompetency?.complianceExpirationDate
          );
        }
        if (item === docuSignDocs.competency) {
          initialValue[`${item}Expiration`] = convertDate(
            PrefillDetails?.complianceCompetency?.competencyExpirationDate
          );
        }
      });
    }
    // setInitialState(initialValue)
    return initialValue;
  };

  const renderTooltip = (tooltipProps) => (
    <Tooltip
      id="button-tooltip"
      className={`  ${
        (loggedInRole &&
          loggedInRole?.key === currentRole.assistanttherapist) ||
        loggedInRole?.key === currentRole.supervisingtherapist
          ? props.isProfile
            ? "therapist1"
            : "therapist"
          : getWindowDimensions().width > 1200
          ? "tooltip1" + PrefillDetails &&
            PrefillDetails?.userDetail &&
            PrefillDetails?.userDetail.rejectedFields &&
            PrefillDetails?.userDetail.rejectedFields !== null &&
            PrefillDetails?.userDetail.rejectedFields.reasonforRejection
            ? "tooltip1 tootlipdown1"
            : "tooltip1"
          : "onBoard-tooltip-style"
      }  `}
      {...tooltipProps}
    >
      A physical is valid for 2 years from the day it was performed.
    </Tooltip>
  );

  const toolTip2 = (tooltipProps) => (
    <Tooltip
      id="button-tooltip"
      className={`  ${
        (loggedInRole &&
          loggedInRole?.key === currentRole.assistanttherapist) ||
        loggedInRole?.key === currentRole.supervisingtherapist
          ? props.isProfile
            ? "therapist1"
            : "therapist"
          : getWindowDimensions().width > 1200
          ? "tooltip2" + PrefillDetails &&
            PrefillDetails?.userDetail &&
            PrefillDetails?.userDetail.rejectedFields &&
            PrefillDetails?.userDetail.rejectedFields !== null &&
            PrefillDetails?.userDetail.rejectedFields.reasonforRejection
            ? "tooltip2 tootlipdown2"
            : "tooltip2"
          : "onBoard-tooltip-style"
      }  `}
      {...tooltipProps}
    >
      A TB skin test is valid for 1 year from the day it was performed. A chest
      x-ray for TB and QuantiFERON blood test are both valid for 2 years from
      the day they were performed.
    </Tooltip>
  );
  const toolTip3 = (tooltipProps) => (
    <Tooltip
      id="button-tooltip"
      className={`  ${
        (loggedInRole &&
          loggedInRole?.key === currentRole.assistanttherapist) ||
        loggedInRole?.key === currentRole.supervisingtherapist
          ? props.isProfile
            ? "therapist1"
            : "therapist"
          : getWindowDimensions().width > 1200
          ? "tooltip3" + PrefillDetails &&
            PrefillDetails?.userDetail &&
            PrefillDetails?.userDetail.rejectedFields &&
            PrefillDetails?.userDetail.rejectedFields !== null &&
            PrefillDetails?.userDetail.rejectedFields.reasonforRejection
            ? "tooltip3 tootlipdown3"
            : "tooltip3"
          : "onBoard-tooltip-style"
      }  `}
      {...tooltipProps}
    >
      If you do not have a flu vaccination record to upload from within the past
      year, you will be required to complete the Flu Declination Form.You will
      be able to upload a Flu Vaccination Record at any time to replace the Flu
      Declination Form once you find your record or once you receive the
      vaccine.
    </Tooltip>
  );

  const credentialRejectHandle = (type, value) => {
    if (
      type === "w9" &&
      PrefillDetails &&
      PrefillDetails?.Status?.key === defaultStatus?.rejected &&
      PrefillDetails?.userDetail?.rejectedFields &&
      PrefillDetails?.userDetail?.rejectedFields[type] &&
      !uploadedFileBytes[type]
    ) {
      return true;
    } else if (
      type !== "w9" &&
      PrefillDetails &&
      PrefillDetails?.Status?.key === defaultStatus?.rejected &&
      PrefillDetails?.userDetail?.rejectedFields &&
      PrefillDetails?.userDetail?.rejectedFields[type] &&
      value?.includes(PrefillDetails?.userDetail?.rejectedFields[type])
    ) {
      return true;
    }
  };

  useEffect(() => {
    setPageInitilize(true);
    if (
      PrefillDetails &&
      PrefillDetails.userDetail &&
      PrefillDetails.userDetail.textData
    ) {
      setTaxFormValues(JSON.parse(PrefillDetails.userDetail.textData));
    }
  }, [PrefillDetails]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    if (windowDimensions.width <= 1200) {
      setPlacementTooltip("auto");
    }

    if (windowDimensions.width > 1200) {
      setPlacementTooltip("right");
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const handleOnboardingDocs = (key) => {
    setOnboardingDocType(key);
  };

  const handleDocument = (e, values, type, form) => {
    const {
      target: { checked },
    } = e;
    if (checked && uploadedFileBytes[type]) {
      const blob = new Blob([uploadedFileBytes[type]], {
        type: "application/pdf",
      });
      let files = new File([blob], `${type}doc.pdf`);
      const event = { target: { files: [files] } };
      let finalValues = { ...values };
      if (type === "fluvaccination") finalValues.isfluvaccination = true;
      handleChange(event, type, finalValues, form);
    } else {
      form.change(type, "");
    }
  };
  const getCredentialsDetails = (type) => {
    let isExist = null;
    if (
      PrefillDetails &&
      PrefillDetails.onboardingPdf &&
      PrefillDetails.onboardingPdf.length > 0
    ) {
      isExist = PrefillDetails.onboardingPdf.find(
        (item) => item.types === type
      );
    }
    return isExist;
  };
  const handleDocumentShow = (typename) => {
    if (PrefillDetails && PrefillDetails.onboardingPdf) {
      let formdetail = PrefillDetails.onboardingPdf.find(
        (item) => item.types === typename
      );
      const url = `${onboardingPDFRoute}${formdetail.file}`;
      window.open(url, "_blank").focus();
    } else {
      toast.warn("There is no document to show.");
    }
  };
  const handleW9Form = (typename) => {
    if (PrefillDetails && PrefillDetails.userCredentials) {
      let formdetail = PrefillDetails.userCredentials.find(
        (item) => item.types === typename
      );
      const url = `${therapistCredentialRoute}${formdetail?.file}`;
      window.open(url, "_blank").focus();
    } else {
      toast.warn("There is no document to show.");
    }
  };

  const [testUpload, setTestUpload] = useState(false);

  const checkUpload = (e, values, name, fieldName) => {
    if (testUpload !== name && values[name] && !values[fieldName]) {
      e.preventDefault();
      // alert("file exists")
      window.open(
        `${apiBasePath}documents/therapist/${values[name]}`,
        "_blank"
      );
    } else {
      // setTestUpload(false)
    }
  };

  const validHandleSubmit = () => {
    dispatch(triggerUpdateButton(false));
  };

  const ExpirationDateInput = (props) => {
    const { input, onChange, typename, values, form } = props;
    return (
      <OverlayTrigger
        className=""
        key={"bottom"}
        placement={"bottom"}
        overlay={
          <Tooltip id={`tooltip-bottom`} className=" CredentialsTooltip">
            <span>
              {" "}
              Please select a value that is no earlier then{" "}
              {dateFormatChange(todayDate)}{" "}
            </span>
          </Tooltip>
        }
      >
        <input
          className={`cred-date cursor-pointer w-75 ${
            props?.isProfile ? "mobile-100" : " mobile-100"
          }`}
          {...input}
          min={today}
          type={input.value ? "date" : "text"}
          onChange={(e) => {
            onChange(e);
            handleChange(e, typename, values, form);
          }}
          onMouseEnter={handleDateFocus}
          onBlur={handleDateBlur}
          placeholder="Expiration Date"
        />
      </OverlayTrigger>
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={useMemo((e) => setInitialValue(e), [PrefillDetails])}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        submitting,
        submitFailed,
        values,
        errors,
        form,
        valid,
        initialValues,
        dirtyFields,
        pristine,
      }) => (
        <form
          onSubmit={(e) => {
            if (!valid) toast.error("Please complete all required fields.");
            handleSubmit(e);
          }}
        >
          {props.isProfile && props.therapistCredentail(values)}
          {props.isProfile && props.pristineCheck(pristine)}
          {!valid && validHandleSubmit()}

          {props.isProfile ? (
            <div className="contianer-fluid">
              <div className="my-3">
                {resoanReject && (
                  <>
                    <div className="reason-rejection-header mb-2">
                      Reason for Rejection
                    </div>
                    <div className="reason-rejection">
                      {resoanReject.split("\n").map((reason, index) => {
                        if (reason.trim() !== "") {
                          return <p key={index}>{reason}</p>;
                        }
                        return null;
                      })}
                    </div>
                  </>
                )}
              </div>

              <div className="row p-0">
                <div
                  className={`${
                    props?.isProfile
                      ? "col-xxl-12 col-xl-12"
                      : "col-xxl-12 col-xl-12"
                  }`}
                >
                  <div className="d-flex justify-content-between my-3">
                    <h5 className="cred-header ml-0 mt-3">
                      Therapist Credentials
                    </h5>
                    <button
                      type="button"
                      className="Add-New-btn mx-3"
                      onClick={() => {
                        setInputPropsForAddNewTest({
                          name: "",
                          isDisabled: true,
                        });
                        setAddNewCred(true);
                      }}
                    >
                      <AiFillPlusCircle
                        style={{ marginRight: "1.5rem" }}
                        size={34}
                      />{" "}
                      Add New
                    </button>
                  </div>

                  <table className="mobile_table-responsive-md table pt-0 document-tab therapist-tab">
                    <thead>
                      <tr>
                        <th style={{ width: "40%" }}>DOCUMENT TYPE</th>
                        <th style={{ width: "20%" }}>EXPIRATION DATE</th>
                        <th style={{ width: "31%" }}>STATUS</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="cred-document-type">Therapy License</td>
                        <td>
                          <Field name="therapylicenserejectReason">
                            {({ input: { value } }) => {
                              setTherapyLicenseRejectReason(value + "\n");
                            }}
                          </Field>
                          <Field name="therapylicenseExpiration">
                            {({ input: { value } }) => {
                              const formattedDate = formatDate(value);
                              return formattedDate ? (
                                <div className="cred-date-input mobile_width-990">
                                  {formattedDate && (
                                    <p className="cred-document-type">
                                      {formattedDate}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="cred-date-input mobile_width-990">
                                  <p className="cred-document-type">
                                    01-01-2025
                                  </p>
                                </div>
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <Field name="therapylicenseExpiration">
                            {({ input: { value } }) => {
                              const today = new Date();
                              const isExpired =
                                new Date(value) <
                                new Date(
                                  today.getFullYear(),
                                  today.getMonth(),
                                  today.getDate()
                                );
                              return (
                                <Field name="therapylicensecredentialsStatus">
                                  {({ input: { value } }) =>
                                    value && (
                                      <button
                                        type="button"
                                        className="cred-status"
                                        style={{
                                          background: isExpired
                                            ? colors["expired"].background
                                            : colors[value]?.background,
                                          color: isExpired
                                            ? colors["expired"].color
                                            : colors[value]?.color,
                                        }}
                                      >
                                        {isExpired
                                          ? "Expired"
                                          : value
                                          ? value === "pendingreview"
                                            ? "Pending Review"
                                            : value.charAt(0).toUpperCase() +
                                              value.slice(1)
                                          : ""}
                                      </button>
                                    )
                                  }
                                </Field>
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <div className="flex">
                            <Field name="therapylicenseExpiration">
                              {({ input: { value: expirationValue } }) => {
                                const today = new Date();
                                const isExpired =
                                  new Date(expirationValue) <
                                  new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate()
                                  );
                                return (
                                  <Field name="therapylicensecredentialsStatus">
                                    {({
                                      input: { value: credentialsValue },
                                    }) => {
                                      const isCredentialExpired =
                                        isExpired ||
                                        credentialsValue === "expired" ||
                                        credentialsValue === "rejected" ||
                                        credentialsValue === "pendingreview";
                                      return (
                                        <>
                                          <Field name="therapylicense">
                                            {({ input }) => {
                                              const {
                                                onChange,
                                                ...inputProps
                                              } = input;
                                              const rejected =
                                                credentialRejectHandle(
                                                  "therapylicense",
                                                  !inputProps.value
                                                    ? values?.therapylicensename
                                                    : inputProps.value
                                                );

                                              return (
                                                <div
                                                  className={`file_cursor click_label_button ${
                                                    rejected
                                                      ? "rejected_text_div"
                                                      : ""
                                                  }`}
                                                >
                                                  {!isCredentialExpired && (
                                                    <label
                                                      type="file"
                                                      className="Approve-btns justify-center w-100"
                                                      onClick={() => {
                                                        const input =
                                                          document.getElementById(
                                                            "fileUpload-therapylicense"
                                                          );
                                                        input.click();
                                                      }}
                                                    >
                                                      View
                                                    </label>
                                                  )}
                                                  <div>
                                                    <input
                                                      style={{
                                                        display: "none",
                                                      }}
                                                      className="input-group w-100 cred-input cursor-pointer file-upload-field"
                                                      id="fileUpload-therapylicense"
                                                      name="file-upload-field"
                                                      value="driverslicense_1665821827852.pdf"
                                                      type="file"
                                                      onClick={(e) =>
                                                        checkUpload(
                                                          e,
                                                          values,
                                                          "therapylicensefile",
                                                          "therapylicense"
                                                        )
                                                      }
                                                      onChange={(e) => {
                                                        onChange(e);
                                                        handleChange(
                                                          e,
                                                          "therapylicense",
                                                          values,
                                                          form
                                                        );
                                                        setTestUpload(false);
                                                      }}
                                                      {...inputProps}
                                                    />
                                                    {isCredentialExpired && (
                                                      <label
                                                        onClick={() => {
                                                          setInputPropsForAddNewTest(
                                                            {
                                                              name: inputProps.name,
                                                              isDisabled: true,
                                                            }
                                                          );
                                                          setAddNewCred(true);
                                                        }}
                                                        className="Approve-btns justify-center w-100 whitespace-nowra"
                                                      >
                                                        Add New
                                                      </label>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }}
                                          </Field>
                                        </>
                                      );
                                    }}
                                  </Field>
                                );
                              }}
                            </Field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="cred-document-type">Driver's License</td>
                        <Field name="driverslicenserejectReason">
                          {({ input: { value } }) => {
                            setDrivingLicenseRejectReason(value + "\n");
                          }}
                        </Field>
                        <td>
                          <Field name={"driverslicenseExpiration"}>
                            {({ input: { value } }) => {
                              const formattedDate = formatDate(value);
                              return formattedDate ? (
                                <div className="cred-date-input mobile_width-990">
                                  {formattedDate && (
                                    <p className="cred-document-type">
                                      {formattedDate}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="cred-date-input mobile_width-990">
                                  <p className="cred-document-type">
                                    01-01-2025
                                  </p>
                                </div>
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <Field name="driverslicenseExpiration">
                            {({ input: { value } }) => {
                              const today = new Date();
                              const isExpired =
                                new Date(value) <
                                new Date(
                                  today.getFullYear(),
                                  today.getMonth(),
                                  today.getDate()
                                );
                              return (
                                <Field name="driverslicensecredentialsStatus">
                                  {({ input: { value } }) =>
                                    value && (
                                      <button
                                        type="button"
                                        className="cred-status"
                                        style={{
                                          background: isExpired
                                            ? colors["expired"].background
                                            : colors[value]?.background,
                                          color: isExpired
                                            ? colors["expired"].color
                                            : colors[value]?.color,
                                        }}
                                      >
                                        {isExpired
                                          ? "Expired"
                                          : value
                                          ? value === "pendingreview"
                                            ? "Pending Review"
                                            : value.charAt(0).toUpperCase() +
                                              value.slice(1)
                                          : ""}
                                      </button>
                                    )
                                  }
                                </Field>
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <div className="flex">
                            <Field name="driverslicenseExpiration">
                              {({ input: { value: expirationValue } }) => {
                                const today = new Date();
                                const isExpired =
                                  new Date(expirationValue) <
                                  new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate()
                                  );
                                return (
                                  <Field name="driverslicensecredentialsStatus">
                                    {({
                                      input: { value: credentialsValue },
                                    }) => {
                                      const isCredentialExpired =
                                        isExpired ||
                                        credentialsValue === "expired" ||
                                        credentialsValue === "rejected" ||
                                        credentialsValue === "pendingreview";
                                      return (
                                        <>
                                          <Field name="driverslicense">
                                            {({ input }) => {
                                              const {
                                                onChange,
                                                ...inputProps
                                              } = input;
                                              const rejected =
                                                credentialRejectHandle(
                                                  "driverslicense",
                                                  !inputProps.value
                                                    ? values?.driverslicensename
                                                    : inputProps.value
                                                );

                                              return (
                                                <div
                                                  className={`file_cursor click_label_button ${
                                                    rejected
                                                      ? "rejected_text_div"
                                                      : ""
                                                  }`}
                                                >
                                                  {!isCredentialExpired && (
                                                    <label
                                                      type="file"
                                                      className="Approve-btns justify-center w-100"
                                                      onClick={() => {
                                                        const input =
                                                          document.getElementById(
                                                            "fileUpload-driverslicense"
                                                          );
                                                        input.click();
                                                      }}
                                                    >
                                                      View
                                                    </label>
                                                  )}
                                                  <div>
                                                    <input
                                                      style={{
                                                        display: "none",
                                                      }}
                                                      className="input-group cred-input cursor-pointer w-100 file-upload-field"
                                                      name="file-upload-field"
                                                      id="fileUpload-driverslicense"
                                                      value={
                                                        isCredentialExpired
                                                          ? ""
                                                          : inputProps?.value
                                                      } // Set value to empty string if expired
                                                      {...input}
                                                      type="file"
                                                      onClick={(e) => {
                                                        checkUpload(
                                                          e,
                                                          values,
                                                          "driverslicensefile",
                                                          "driverslicense"
                                                        );
                                                      }}
                                                      onChange={(e) => {
                                                        onChange(e);
                                                        handleChange(
                                                          e,
                                                          "driverslicense",
                                                          values,
                                                          form
                                                        );
                                                        setTestUpload(false);
                                                      }}
                                                    />
                                                    {isCredentialExpired && (
                                                      <label
                                                        onClick={() => {
                                                          setInputPropsForAddNewTest(
                                                            {
                                                              name: inputProps.name,
                                                              isDisabled: true,
                                                            }
                                                          );
                                                          setAddNewCred(true);
                                                        }}
                                                        className="Approve-btns justify-center w-100 whitespace-nowra"
                                                      >
                                                        Add New
                                                      </label>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }}
                                          </Field>
                                        </>
                                      );
                                    }}
                                  </Field>
                                );
                              }}
                            </Field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="cred-document-type">Auto Insurance</td>
                        <Field name="autoinsurancerejectReason">
                          {({ input: { value } }) => {
                            setAutoInsuranceRejectReason(value + "\n");
                          }}
                        </Field>
                        <td>
                          <Field name={"autoinsuranceExpiration"}>
                            {({ input: { value } }) => {
                              const formattedDate = formatDate(value);
                              return formattedDate ? (
                                <div className="cred-date-input mobile_width-990">
                                  {formattedDate && (
                                    <p className="cred-document-type">
                                      {formattedDate}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="cred-date-input mobile_width-990">
                                  <p className="cred-document-type">
                                    01-01-2025
                                  </p>
                                </div>
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <Field name="autoinsuranceExpiration">
                            {({ input: { value } }) => {
                              const today = new Date();
                              const isExpired =
                                new Date(value) <
                                new Date(
                                  today.getFullYear(),
                                  today.getMonth(),
                                  today.getDate()
                                );
                              return (
                                <Field name="autoinsurancecredentialsStatus">
                                  {({ input: { value } }) =>
                                    value && (
                                      <button
                                        type="button"
                                        className="cred-status"
                                        style={{
                                          background: isExpired
                                            ? colors["expired"].background
                                            : colors[value]?.background,
                                          color: isExpired
                                            ? colors["expired"].color
                                            : colors[value]?.color,
                                        }}
                                      >
                                        {isExpired
                                          ? "Expired"
                                          : value
                                          ? value === "pendingreview"
                                            ? "Pending Review"
                                            : value.charAt(0).toUpperCase() +
                                              value.slice(1)
                                          : ""}
                                      </button>
                                    )
                                  }
                                </Field>
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <div className="flex">
                            <Field name="autoinsuranceExpiration">
                              {({ input: { value: expirationValue } }) => {
                                const today = new Date();
                                const isExpired =
                                  new Date(expirationValue) <
                                  new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate()
                                  );
                                return (
                                  <Field name="autoinsurancecredentialsStatus">
                                    {({
                                      input: { value: credentialsValue },
                                    }) => {
                                      const isCredentialExpired =
                                        isExpired ||
                                        credentialsValue === "expired" ||
                                        credentialsValue === "rejected" ||
                                        credentialsValue === "pendingreview";
                                      return (
                                        <>
                                          <Field name="autoinsurance">
                                            {({ input }) => {
                                              const {
                                                onChange,
                                                ...inputProps
                                              } = input;
                                              const rejected =
                                                credentialRejectHandle(
                                                  "autoinsurance",
                                                  !inputProps.value
                                                    ? values?.autoinsurancename
                                                    : inputProps.value
                                                );

                                              return (
                                                <div
                                                  className={`file_cursor click_label_button ${
                                                    rejected
                                                      ? "rejected_text_div"
                                                      : ""
                                                  }`}
                                                >
                                                  {!isCredentialExpired && (
                                                    <label
                                                      type="file"
                                                      className="Approve-btns justify-center w-100"
                                                      onClick={() => {
                                                        const input =
                                                          document.getElementById(
                                                            "fileUpload-autoinsurance"
                                                          );
                                                        input.click();
                                                      }}
                                                    >
                                                      View
                                                    </label>
                                                  )}
                                                  <div>
                                                    <input
                                                      style={{
                                                        display: "none",
                                                      }}
                                                      className="input-group cred-input cursor-pointer w-100 file-upload-field"
                                                      name="file-upload-field"
                                                      id="fileUpload-autoinsurance"
                                                      value={
                                                        isCredentialExpired
                                                          ? ""
                                                          : inputProps?.value
                                                      } // Set value to empty string if expired
                                                      {...input}
                                                      type="file"
                                                      onClick={(e) => {
                                                        checkUpload(
                                                          e,
                                                          values,
                                                          "autoinsurancefile",
                                                          "autoinsurance"
                                                        );
                                                      }}
                                                      onChange={(e) => {
                                                        onChange(e);
                                                        handleChange(
                                                          e,
                                                          "autoinsurance",
                                                          values,
                                                          form
                                                        );
                                                        setTestUpload(false);
                                                      }}
                                                    />
                                                    {isCredentialExpired && (
                                                      <label
                                                        onClick={() => {
                                                          setInputPropsForAddNewTest(
                                                            {
                                                              name: inputProps.name,
                                                              isDisabled: true,
                                                            }
                                                          );
                                                          setAddNewCred(true);
                                                        }}
                                                        className="Approve-btns justify-center w-100 whitespace-nowra"
                                                      >
                                                        Add New
                                                      </label>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }}
                                          </Field>
                                        </>
                                      );
                                    }}
                                  </Field>
                                );
                              }}
                            </Field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="cred-document-type">
                          Professional Liability Insurance
                        </td>
                        <td>
                          <Field name="professionallaibilityinsurancerejectReason">
                            {({ input: { value } }) => {
                              setProfessionallaibilityInsuranceRejectReason(
                                value + "\n"
                              );
                            }}
                          </Field>
                          <Field
                            name={"professionallaibilityinsuranceExpiration"}
                          >
                            {({ input: { value } }) => {
                              const formattedDate = formatDate(value);
                              return formattedDate ? (
                                <div className="cred-date-input mobile_width-990">
                                  {formattedDate && (
                                    <p className="cred-document-type">
                                      {formattedDate}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="cred-date-input mobile_width-990">
                                  <p className="cred-document-type">
                                    01-01-2025
                                  </p>
                                </div>
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <Field name="professionallaibilityinsuranceExpiration">
                            {({ input: { value } }) => {
                              const today = new Date();
                              const isExpired =
                                new Date(value) <
                                new Date(
                                  today.getFullYear(),
                                  today.getMonth(),
                                  today.getDate()
                                );
                              return (
                                <Field name="professionallaibilityinsurancecredentialsStatus">
                                  {({ input: { value } }) =>
                                    value && (
                                      <button
                                        type="button"
                                        className="cred-status"
                                        style={{
                                          background: isExpired
                                            ? colors["expired"].background
                                            : colors[value]?.background,
                                          color: isExpired
                                            ? colors["expired"].color
                                            : colors[value]?.color,
                                        }}
                                      >
                                        {isExpired
                                          ? "Expired"
                                          : value
                                          ? value === "pendingreview"
                                            ? "Pending Review"
                                            : value.charAt(0).toUpperCase() +
                                              value.slice(1)
                                          : ""}
                                      </button>
                                    )
                                  }
                                </Field>
                              );
                            }}
                          </Field>
                        </td>

                        <td>
                          <div className="flex">
                            <Field name="professionallaibilityinsuranceExpiration">
                              {({ input: { value: expirationValue } }) => {
                                const today = new Date();
                                const isExpired =
                                  new Date(expirationValue) <
                                  new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate()
                                  );
                                return (
                                  <Field name="professionallaibilityinsurancecredentialsStatus">
                                    {({
                                      input: { value: credentialsValue },
                                    }) => {
                                      const isCredentialExpired =
                                        isExpired ||
                                        credentialsValue === "expired" ||
                                        credentialsValue === "rejected" ||
                                        credentialsValue === "pendingreview";
                                      return (
                                        <>
                                          <Field name="professionallaibilityinsurance">
                                            {({ input }) => {
                                              const {
                                                onChange,
                                                ...inputProps
                                              } = input;
                                              const rejected =
                                                credentialRejectHandle(
                                                  "professionallaibilityinsurance",
                                                  !inputProps.value
                                                    ? values?.professionallaibilityinsurancename
                                                    : inputProps.value
                                                );

                                              return (
                                                <div
                                                  className={`file_cursor click_label_button ${
                                                    rejected
                                                      ? "rejected_text_div"
                                                      : ""
                                                  }`}
                                                >
                                                  {!isCredentialExpired && (
                                                    <label
                                                      type="file"
                                                      className="Approve-btns justify-center w-100"
                                                      onClick={() => {
                                                        const input =
                                                          document.getElementById(
                                                            "fileUpload-professionallaibilityinsurance"
                                                          );
                                                        input.click();
                                                      }}
                                                    >
                                                      View
                                                    </label>
                                                  )}
                                                  <div>
                                                    <input
                                                      style={{
                                                        display: "none",
                                                      }}
                                                      className="input-group w-100 cred-input cursor-pointer file-upload-field"
                                                      {...input}
                                                      type="file"
                                                      id="fileUpload-professionallaibilityinsurance"
                                                      onClick={(e) => {
                                                        checkUpload(
                                                          e,
                                                          values,
                                                          "professionallaibilityinsurancefile",
                                                          "professionallaibilityinsurance"
                                                        );
                                                      }}
                                                      onChange={(e) => {
                                                        onChange(e);
                                                        handleChange(
                                                          e,
                                                          "professionallaibilityinsurance",
                                                          values
                                                        );
                                                      }}
                                                    />
                                                    {isCredentialExpired && (
                                                      <label
                                                        onClick={() => {
                                                          setInputPropsForAddNewTest(
                                                            {
                                                              name: inputProps.name,
                                                              isDisabled: true,
                                                            }
                                                          );

                                                          setAddNewCred(true);
                                                        }}
                                                        className="Approve-btns justify-center w-100 whitespace-nowra"
                                                      >
                                                        Add New
                                                      </label>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }}
                                          </Field>
                                        </>
                                      );
                                    }}
                                  </Field>
                                );
                              }}
                            </Field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="cred-document-type">
                          CPR Certification
                        </td>
                        <td>
                          <Field name="cprcertificationrejectReason">
                            {({ input: { value } }) => {
                              setCPRCertificationRejectReason(value + "\n");
                            }}
                          </Field>
                          <Field name={"cprcertificationExpiration"}>
                            {({ input: { value } }) => {
                              const formattedDate = formatDate(value);
                              return formattedDate ? (
                                <div className="cred-date-input mobile_width-990">
                                  {formattedDate && (
                                    <p className="cred-document-type">
                                      {formattedDate}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="cred-date-input mobile_width-990">
                                  <p className="cred-document-type">
                                    01-01-2025
                                  </p>
                                </div>
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <Field name="cprcertificationExpiration">
                            {({ input: { value } }) => {
                              const today = new Date();
                              const isExpired =
                                new Date(value) <
                                new Date(
                                  today.getFullYear(),
                                  today.getMonth(),
                                  today.getDate()
                                );
                              return (
                                <Field name="cprcertificationcredentialsStatus">
                                  {({ input: { value } }) =>
                                    value && (
                                      <button
                                        type="button"
                                        className="cred-status"
                                        style={{
                                          background: isExpired
                                            ? colors["expired"].background
                                            : colors[value]?.background,
                                          color: isExpired
                                            ? colors["expired"].color
                                            : colors[value]?.color,
                                        }}
                                      >
                                        {isExpired
                                          ? "Expired"
                                          : value
                                          ? value === "pendingreview"
                                            ? "Pending Review"
                                            : value.charAt(0).toUpperCase() +
                                              value.slice(1)
                                          : ""}
                                      </button>
                                    )
                                  }
                                </Field>
                              );
                            }}
                          </Field>
                        </td>

                        <td>
                          <div className="flex">
                            <Field name="cprcertificationExpiration">
                              {({ input: { value: expirationValue } }) => {
                                const today = new Date();
                                const isExpired =
                                  new Date(expirationValue) <
                                  new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate()
                                  );
                                return (
                                  <Field name="cprcertificationcredentialsStatus">
                                    {({
                                      input: { value: credentialsValue },
                                    }) => {
                                      const isCredentialExpired =
                                        isExpired ||
                                        credentialsValue === "expired" ||
                                        credentialsValue === "rejected" ||
                                        credentialsValue === "pendingreview";
                                      return (
                                        <>
                                          <Field name="cprcertification">
                                            {({ input }) => {
                                              const {
                                                onChange,
                                                ...inputProps
                                              } = input;
                                              const rejected =
                                                credentialRejectHandle(
                                                  "cprcertification",
                                                  !inputProps.value
                                                    ? values?.cprcertificationname
                                                    : inputProps.value
                                                );

                                              return (
                                                <div
                                                  className={`file_cursor click_label_button ${
                                                    rejected
                                                      ? "rejected_text_div"
                                                      : ""
                                                  }`}
                                                >
                                                  {!isCredentialExpired && (
                                                    <label
                                                      type="file"
                                                      className="Approve-btns justify-center w-100"
                                                      onClick={() => {
                                                        const input =
                                                          document.getElementById(
                                                            "fileUpload-cprcertification"
                                                          );
                                                        input.click();
                                                      }}
                                                    >
                                                      View
                                                    </label>
                                                  )}
                                                  <div>
                                                    <input
                                                      style={{
                                                        display: "none",
                                                      }}
                                                      className="input-group w-100 cred-input cursor-pointer file-upload-field"
                                                      {...input}
                                                      type="file"
                                                      id="fileUpload-cprcertification"
                                                      onClick={(e) => {
                                                        checkUpload(
                                                          e,
                                                          values,
                                                          "cprcertificationfile",
                                                          "cprcertification"
                                                        );
                                                      }}
                                                      onChange={(e) => {
                                                        onChange(e);
                                                        handleChange(
                                                          e,
                                                          "cprcertification",
                                                          values,
                                                          form
                                                        );
                                                      }}
                                                    />
                                                    {isCredentialExpired && (
                                                      <label
                                                        onClick={() => {
                                                          setInputPropsForAddNewTest(
                                                            {
                                                              name: inputProps.name,
                                                              isDisabled: true,
                                                            }
                                                          );

                                                          setAddNewCred(true);
                                                        }}
                                                        className="Approve-btns justify-center w-100 whitespace-nowra"
                                                      >
                                                        Add New
                                                      </label>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }}
                                          </Field>
                                        </>
                                      );
                                    }}
                                  </Field>
                                );
                              }}
                            </Field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="cred-document-type">
                          <Field name="physicalrejectReason">
                            {({ input: { value } }) => {
                              setPhysicalRejectReason(value + "\n");
                            }}
                          </Field>
                          <OverlayTrigger
                            style={{ width: "160px" }}
                            placement={
                              props.isProfile
                                ? placementTooltip
                                : OnplacementTooltip
                            }
                            delay={{ show: 50, hide: 400 }}
                            overlay={renderTooltip}
                          >
                            <p
                              className="cred-document-type mt-3"
                              style={{
                                width: `${
                                  getWindowDimensions().width < 1200
                                    ? "109px"
                                    : "130px"
                                }`,
                              }}
                            >
                              Physical
                              <TbQuestionMark
                                size={28}
                                style={{
                                  background:
                                    "transparent linear-gradient(285deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box",
                                  borderRadius: "50%",
                                  color: "white",
                                  marginLeft: "20px",
                                }}
                              />
                            </p>
                          </OverlayTrigger>
                        </td>
                        <td>
                          <Field name={"physicalExpiration"}>
                            {({ input: { value } }) => {
                              const formattedDate = formatDate(value);
                              return formattedDate ? (
                                <div className="cred-date-input mobile_width-990">
                                  {formattedDate && (
                                    <p className="cred-document-type">
                                      {formattedDate}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="cred-date-input mobile_width-990">
                                  <p className="cred-document-type">
                                    01-01-2025
                                  </p>
                                </div>
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <Field name="physicalExpiration">
                            {({ input: { value } }) => {
                              const today = new Date();
                              const isExpired =
                                new Date(value) <
                                new Date(
                                  today.getFullYear(),
                                  today.getMonth(),
                                  today.getDate()
                                );
                              return (
                                <Field name="physicalcredentialsStatus">
                                  {({ input: { value } }) =>
                                    value && (
                                      <button
                                        type="button"
                                        className="cred-status"
                                        style={{
                                          background: isExpired
                                            ? colors["expired"].background
                                            : colors[value]?.background,
                                          color: isExpired
                                            ? colors["expired"].color
                                            : colors[value]?.color,
                                        }}
                                      >
                                        {isExpired
                                          ? "Expired"
                                          : value
                                          ? value === "pendingreview"
                                            ? "Pending Review"
                                            : value.charAt(0).toUpperCase() +
                                              value.slice(1)
                                          : ""}
                                      </button>
                                    )
                                  }
                                </Field>
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <div className="flex">
                            <Field name="physicalExpiration">
                              {({ input: { value: expirationValue } }) => {
                                const today = new Date();
                                const isExpired =
                                  new Date(expirationValue) <
                                  new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate()
                                  );
                                return (
                                  <Field name="physicalcredentialsStatus">
                                    {({
                                      input: { value: credentialsValue },
                                    }) => {
                                      const isCredentialExpired =
                                        isExpired ||
                                        credentialsValue === "expired" ||
                                        credentialsValue === "rejected" ||
                                        credentialsValue === "pendingreview";
                                      return (
                                        <>
                                          <Field name="physical">
                                            {({ input }) => {
                                              const {
                                                onChange,
                                                ...inputProps
                                              } = input;
                                              const rejected =
                                                credentialRejectHandle(
                                                  "physical",
                                                  !inputProps.value
                                                    ? values?.physicalname
                                                    : inputProps.value
                                                );

                                              return (
                                                <div
                                                  className={`file_cursor click_label_button ${
                                                    rejected
                                                      ? "rejected_text_div"
                                                      : ""
                                                  }`}
                                                >
                                                  {!isCredentialExpired && (
                                                    <label
                                                      type="file"
                                                      className="Approve-btns justify-center w-100"
                                                      onClick={() => {
                                                        const input =
                                                          document.getElementById(
                                                            "fileUpload-physical"
                                                          );
                                                        input.click();
                                                      }}
                                                    >
                                                      View
                                                    </label>
                                                  )}
                                                  <div>
                                                    <input
                                                      style={{
                                                        display: "none",
                                                      }}
                                                      className="input-group w-100 cred-input cursor-pointer file-upload-field "
                                                      {...input}
                                                      type="file"
                                                      id="fileUpload-physical"
                                                      onClick={(e) => {
                                                        checkUpload(
                                                          e,
                                                          values,
                                                          "physicalfile",
                                                          "physical"
                                                        );
                                                      }}
                                                      onChange={(e) => {
                                                        onChange(e);
                                                        handleChange(
                                                          e,
                                                          "physical",
                                                          values,
                                                          form
                                                        );
                                                      }}
                                                    />
                                                    {isCredentialExpired && (
                                                      <label
                                                        onClick={() => {
                                                          setInputPropsForAddNewTest(
                                                            {
                                                              name: inputProps.name,
                                                              isDisabled: true,
                                                            }
                                                          );

                                                          setAddNewCred(true);
                                                        }}
                                                        className="Approve-btns justify-center w-100 whitespace-nowra"
                                                      >
                                                        Add New
                                                      </label>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }}
                                          </Field>
                                        </>
                                      );
                                    }}
                                  </Field>
                                );
                              }}
                            </Field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="cred-document-type">
                          <Field name="tbtestrejectReason">
                            {({ input: { value } }) => {
                              setTBTestRejectReason(value + "\n");
                            }}
                          </Field>
                          <OverlayTrigger
                            style={{ width: "200px" }}
                            placement={"auto"}
                            delay={{ show: 250, hide: 400 }}
                            overlay={toolTip2}
                          >
                            <p
                              className="cred-document-type mt-3"
                              style={{
                                width: `${
                                  getWindowDimensions().width < 1200
                                    ? "97px"
                                    : "114px"
                                }`,
                              }}
                            >
                              TB Test
                              <TbQuestionMark
                                size={28}
                                style={{
                                  background:
                                    "transparent linear-gradient(285deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box",
                                  borderRadius: "50%",
                                  color: "white",
                                  marginLeft: "20px",
                                }}
                              />
                            </p>
                          </OverlayTrigger>
                        </td>
                        <td>
                          <Field name={"tbtestExpiration"}>
                            {({ input: { value } }) => {
                              const formattedDate = formatDate(value);
                              return formattedDate ? (
                                <div className="cred-date-input mobile_width-990">
                                  {formattedDate && (
                                    <p className="cred-document-type">
                                      {formattedDate}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="cred-date-input mobile_width-990">
                                  <p className="cred-document-type">
                                    01-01-2025
                                  </p>
                                </div>
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <Field name="tbtestExpiration">
                            {({ input: { value } }) => {
                              const today = new Date();
                              const isExpired =
                                new Date(value) <
                                new Date(
                                  today.getFullYear(),
                                  today.getMonth(),
                                  today.getDate()
                                );
                              return (
                                <Field name="tbtestcredentialsStatus">
                                  {({ input: { value } }) =>
                                    value && (
                                      <button
                                        type="button"
                                        className="cred-status"
                                        style={{
                                          background: isExpired
                                            ? colors["expired"].background
                                            : colors[value]?.background,
                                          color: isExpired
                                            ? colors["expired"].color
                                            : colors[value]?.color,
                                        }}
                                      >
                                        {isExpired
                                          ? "Expired"
                                          : value
                                          ? value === "pendingreview"
                                            ? "Pending Review"
                                            : value.charAt(0).toUpperCase() +
                                              value.slice(1)
                                          : ""}
                                      </button>
                                    )
                                  }
                                </Field>
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <div className="flex">
                            <Field name="tbtestExpiration">
                              {({ input: { value: expirationValue } }) => {
                                const today = new Date();
                                const isExpired =
                                  new Date(expirationValue) <
                                  new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate()
                                  );
                                return (
                                  <Field name="tbtestcredentialsStatus">
                                    {({
                                      input: { value: credentialsValue },
                                    }) => {
                                      const isCredentialExpired =
                                        isExpired ||
                                        credentialsValue === "expired" ||
                                        credentialsValue === "rejected" ||
                                        credentialsValue === "pendingreview";
                                      return (
                                        <>
                                          <Field name="tbtest">
                                            {({ input }) => {
                                              const {
                                                onChange,
                                                ...inputProps
                                              } = input;
                                              const rejected =
                                                credentialRejectHandle(
                                                  "physical",
                                                  !inputProps.value
                                                    ? values?.tbtestname
                                                    : inputProps.value
                                                );

                                              return (
                                                <div
                                                  className={`file_cursor click_label_button ${
                                                    rejected
                                                      ? "rejected_text_div"
                                                      : ""
                                                  }`}
                                                >
                                                  {!isCredentialExpired && (
                                                    <label
                                                      type="file"
                                                      className="Approve-btns justify-center w-100"
                                                      onClick={() => {
                                                        const input =
                                                          document.getElementById(
                                                            "fileUpload-tbtest"
                                                          );
                                                        input.click();
                                                      }}
                                                    >
                                                      View
                                                    </label>
                                                  )}
                                                  <div>
                                                    <input
                                                      style={{
                                                        display: "none",
                                                      }}
                                                      className="input-group w-100 cred-input cursor-pointer file-upload-field"
                                                      {...input}
                                                      id="fileUpload-tbtest"
                                                      type="file"
                                                      onClick={(e) => {
                                                        checkUpload(
                                                          e,
                                                          values,
                                                          "tbtestfile",
                                                          "tbtest"
                                                        );
                                                      }}
                                                      onChange={(e) => {
                                                        onChange(e);
                                                        handleChange(
                                                          e,
                                                          "tbtest",
                                                          values,
                                                          form
                                                        );
                                                      }}
                                                    />
                                                    {isCredentialExpired && (
                                                      <label
                                                        onClick={() => {
                                                          setInputPropsForAddNewTest(
                                                            {
                                                              name: inputProps.name,
                                                              isDisabled: true,
                                                            }
                                                          );
                                                          setAddNewCred(true);
                                                        }}
                                                        className="Approve-btns justify-center w-100 whitespace-nowra"
                                                      >
                                                        Add New
                                                      </label>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }}
                                          </Field>
                                        </>
                                      );
                                    }}
                                  </Field>
                                );
                              }}
                            </Field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="cred-document-type">
                          <Field name="fluvaccinationrejectReason">
                            {({ input: { value } }) => {
                              setFluVaccinationRejectReason(value + "\n");
                            }}
                          </Field>
                          <OverlayTrigger
                            style={{ width: "120px" }}
                            placement={`${placementTooltip}`}
                            delay={{ show: 250, hide: 400 }}
                            overlay={toolTip3}
                          >
                            <p
                              className="cred-document-type mt-3"
                              style={{
                                width: `${
                                  getWindowDimensions().width < 1200
                                    ? "150px"
                                    : "400px"
                                }`,
                              }}
                            >
                              Flu Vaccination
                              <TbQuestionMark
                                size={28}
                                style={{
                                  background:
                                    "transparent linear-gradient(285deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box",
                                  borderRadius: "50%",
                                  color: "white",
                                  marginLeft: "20px",
                                }}
                              />
                            </p>
                          </OverlayTrigger>
                          <label className="container-card align-items-center d-flex padding-left-ref">
                            <Field name="fluvaccinationExpiration">
                              {({ input: { value } }) => {
                                const today = new Date();
                                const isExpired =
                                  new Date(value) <
                                  new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate()
                                  );

                                return (
                                  <>
                                    <Field
                                      name={`isfluvaccination`}
                                      type="checkbox"
                                    >
                                      {({ input }) => (
                                        <input
                                          {...input}
                                          disabled={
                                            values.fluvaccinationcredentialsStatus !==
                                              "active" || isExpired
                                          }
                                          onChange={(e) => {
                                            input.onChange(e);
                                            handleDocument(
                                              e,
                                              values,
                                              "fluvaccination",
                                              form
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                    <span className="checkmark1"></span>
                                    <div className="cred-checkbox mt-2 ml-3">
                                      I have not received a Flu Vaccination for
                                      the current Flu Season.
                                    </div>
                                  </>
                                );
                              }}
                            </Field>
                          </label>
                        </td>

                        <td>
                          <Field name={"fluvaccinationExpiration"}>
                            {({ input: { value } }) => {
                              const formattedDate = formatDate(value);
                              return formattedDate ? (
                                <div className="cred-date-input mobile_width-990">
                                  {formattedDate && (
                                    <p className="cred-document-type">
                                      {formattedDate}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="cred-date-input mobile_width-990">
                                  <p className="cred-document-type">
                                    01-01-2025
                                  </p>
                                </div>
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <Field name="fluvaccinationExpiration">
                            {({ input: { value } }) => {
                              const today = new Date();
                              const isExpired =
                                new Date(value) <
                                new Date(
                                  today.getFullYear(),
                                  today.getMonth(),
                                  today.getDate()
                                );
                              return (
                                <Field name="fluvaccinationcredentialsStatus">
                                  {({ input: { value } }) =>
                                    value && (
                                      <button
                                        type="button"
                                        className="cred-status"
                                        style={{
                                          background: isExpired
                                            ? colors["expired"].background
                                            : colors[value]?.background,
                                          color: isExpired
                                            ? colors["expired"].color
                                            : colors[value]?.color,
                                        }}
                                      >
                                        {isExpired
                                          ? "Expired"
                                          : value
                                          ? value === "pendingreview"
                                            ? "Pending Review"
                                            : value.charAt(0).toUpperCase() +
                                              value.slice(1)
                                          : ""}
                                      </button>
                                    )
                                  }
                                </Field>
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <div className="flex">
                            <Field name="fluvaccinationExpiration">
                              {({ input: { value: expirationValue } }) => {
                                const today = new Date();
                                const isExpired =
                                  new Date(expirationValue) <
                                  new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate()
                                  );
                                return (
                                  <Field name="fluvaccinationcredentialsStatus">
                                    {({
                                      input: { value: credentialsValue },
                                    }) => {
                                      const isCredentialExpired =
                                        isExpired ||
                                        credentialsValue === "expired" ||
                                        credentialsValue === "rejected" ||
                                        credentialsValue === "pendingreview";
                                      return (
                                        <>
                                          <div
                                            className={
                                              values?.isfluvaccination
                                                ? "pl-0 mobile-padding-right d-flex gap-3"
                                                : "d-flex  gap-3 mobile_wrap w-100 "
                                            }
                                          >
                                            {values &&
                                            values?.fluvaccinationfile &&
                                            values?.isfluvaccination ? (
                                              <Field name={"fluvaccination"}>
                                                {({
                                                  input: { onChange, ...input },
                                                }) => (
                                                  <>
                                                    <div
                                                      className={`click_label_button ${
                                                        values?.isfluvaccination
                                                          ? "my-input-100 "
                                                          : " w-100 "
                                                      } ${
                                                        credentialRejectHandle(
                                                          `fluvaccination`,
                                                          !input.value
                                                            ? values?.fluvaccinationname
                                                            : input.value
                                                        )
                                                          ? "rejected_text_div"
                                                          : ""
                                                      }`}
                                                      data-text={
                                                        values &&
                                                        values?.fluvaccinationfile
                                                          ? `fluVaccination_${values?.fluvaccinationfile?.slice(
                                                              0,
                                                              8
                                                            )}`
                                                          : isUploaded(
                                                              "fluvaccination"
                                                            )
                                                          ? "Uploaded"
                                                          : "Upload"
                                                      }
                                                    >
                                                      {values.isfluvaccination &&
                                                        !isCredentialExpired && (
                                                          <div className="d-flex gap-3  my-3">
                                                            <button
                                                              className="Approve-btns justify-center w-100 whitespace-nowra"
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                handleOnboardingDocs(
                                                                  onboardingFormTypes.fluvaccination
                                                                );
                                                              }}
                                                            >
                                                              {isUploaded(
                                                                "fluvaccination"
                                                              )
                                                                ? "Review Form"
                                                                : "Sign Declination"}
                                                            </button>
                                                          </div>
                                                        )}
                                                      {/* )} */}
                                                      <div className="d-flex">
                                                        <input
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          className="input-group border-0 w-100 cred-input cursor-pointer file-upload-field"
                                                          {...input}
                                                          type="file"
                                                          onChange={(e) => {
                                                            onChange(e);
                                                            handleChange(
                                                              e,
                                                              "fluvaccination",
                                                              values,
                                                              form
                                                            );
                                                          }}
                                                          disabled={
                                                            values &&
                                                            values?.fluvaccinationfile &&
                                                            values?.isfluvaccination
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        {isCredentialExpired && (
                                                          <label
                                                            onClick={() => {
                                                              setInputPropsForAddNewTest(
                                                                {
                                                                  name: input.name,
                                                                  isDisabled: true,
                                                                }
                                                              );
                                                              setAddNewCred(
                                                                true
                                                              );
                                                            }}
                                                            className="Approve-btns justify-center w-100 whitespace-nowra"
                                                          >
                                                            Add New
                                                          </label>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </Field>
                                            ) : (
                                              <Field name={"fluvaccination"}>
                                                {({
                                                  input: { onChange, ...input },
                                                  meta,
                                                }) => (
                                                  <>
                                                    <div
                                                      className={`click_label_button ${
                                                        values?.isfluvaccination
                                                          ? "my-input-100  "
                                                          : ""
                                                      } ${
                                                        credentialRejectHandle(
                                                          `fluvaccination`,
                                                          !input.value
                                                            ? values?.fluvaccinationname
                                                            : input.value
                                                        )
                                                          ? "rejected_text_div"
                                                          : ""
                                                      }`}
                                                      data-text={
                                                        values.fluvaccination
                                                          ? values
                                                              .fluvaccination
                                                              .name
                                                          : values?.fluvaccinationname
                                                          ? values?.fluvaccinationname
                                                          : "Upload"
                                                      }
                                                    >
                                                      {!isCredentialExpired && (
                                                        <label
                                                          type="file"
                                                          className="Approve-btns justify-center w-100"
                                                          onClick={() => {
                                                            const input =
                                                              document.getElementById(
                                                                "fileUpload-fluvaccination"
                                                              );
                                                            input.click();
                                                          }}
                                                        >
                                                          View
                                                        </label>
                                                      )}
                                                      <div className="d-flex">
                                                        <input
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          className="input-group border-0 w-100 cred-input cursor-pointer file-upload-field"
                                                          {...input}
                                                          type="file"
                                                          id="fileUpload-fluvaccination"
                                                          onClick={(e) => {
                                                            checkUpload(
                                                              e,
                                                              values,
                                                              "fluvaccinationfile",
                                                              "fluvaccination"
                                                            );
                                                          }}
                                                          onChange={(e) => {
                                                            onChange(e);
                                                            handleChange(
                                                              e,
                                                              "fluvaccination",
                                                              values
                                                            );
                                                          }}
                                                          disabled={
                                                            values &&
                                                            values?.isfluvaccination
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        {isCredentialExpired && (
                                                          <label
                                                            onClick={() => {
                                                              setInputPropsForAddNewTest(
                                                                {
                                                                  name: input.name,
                                                                  isDisabled: true,
                                                                }
                                                              );
                                                              setAddNewCred(
                                                                true
                                                              );
                                                            }}
                                                            className="Approve-btns justify-center w-100 whitespace-nowra"
                                                          >
                                                            Add New
                                                          </label>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </Field>
                                            )}
                                          </div>
                                        </>
                                      );
                                    }}
                                  </Field>
                                );
                              }}
                            </Field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="cred-document-type">
                          Hepatitis B
                          <Field name="hepatitisBrejectReason">
                            {({ input: { value } }) => {
                              setHepatitisBRejectReason(value + "\n");
                            }}
                          </Field>
                          <label className="container-card mt-4 align-items-center d-flex padding-left-ref ref-height my-3">
                            <Field name="hepatitisBExpiration">
                              {({ input: { value } }) => {
                                const today = new Date();
                                const isExpired =
                                  new Date(value) <
                                  new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate()
                                  );

                                return (
                                  <>
                                    <Field
                                      name={`ishepatitisB`}
                                      type="checkbox"
                                    >
                                      {({ input }) => (
                                        <input
                                          {...input}
                                          disabled={
                                            values.hepatitisBcredentialsStatus !==
                                              "active" || isExpired
                                          }
                                          onChange={(e) => {
                                            input.onChange(e);
                                            handleDocument(
                                              e,
                                              values,
                                              "hepatitisB",
                                              form
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                    <span className="checkmark1"></span>
                                    <div className="cred-checkbox ml-3">
                                      I have not received a Hepatitis B
                                      Vaccination.
                                    </div>
                                  </>
                                );
                              }}
                            </Field>
                          </label>
                        </td>
                        <td>
                          <Field name={"hepatitisBUpdatedAt"}>
                            {({ input: { value } }) => {
                              const date = new Date(formatDate(value));
                              date.setFullYear(date.getFullYear() + 1);
                              const formattedDate = `${(date.getMonth() + 1)
                                .toString()
                                .padStart(2, "0")}-${date
                                .getDate()
                                .toString()
                                .padStart(2, "0")}-${date.getFullYear()}`;
                              return (
                                formattedDate && (
                                  <div className="cred-date-input mobile_width-990">
                                    {formattedDate && (
                                      <p className="cred-document-type">
                                        {formattedDate}
                                      </p>
                                    )}
                                  </div>
                                )
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <Field name="hepatitisBcredentialsExpiration">
                            {({ input: { value } }) => {
                              const today = new Date();
                              const isExpired =
                                new Date(value) <
                                new Date(
                                  today.getFullYear(),
                                  today.getMonth(),
                                  today.getDate()
                                );
                              return (
                                <Field name="hepatitisBcredentialsStatus">
                                  {({ input: { value } }) =>
                                    value && (
                                      <button
                                        type="button"
                                        className="cred-status"
                                        style={{
                                          background: isExpired
                                            ? colors["expired"].background
                                            : colors[value]?.background,
                                          color: isExpired
                                            ? colors["expired"].color
                                            : colors[value]?.color,
                                        }}
                                      >
                                        {isExpired
                                          ? "Expired"
                                          : value
                                          ? value === "pendingreview"
                                            ? "Pending Review"
                                            : value.charAt(0).toUpperCase() +
                                              value.slice(1)
                                          : ""}
                                      </button>
                                    )
                                  }
                                </Field>
                              );
                            }}
                          </Field>
                        </td>
                        <td>
                          <div className="flex">
                            <Field name="hepatitisBcredentialsExpiration">
                              {({ input: { value: expirationValue } }) => {
                                const today = new Date();
                                const isExpired =
                                  new Date(expirationValue) <
                                  new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate()
                                  );
                                return (
                                  <Field name="hepatitisBcredentialsStatus">
                                    {({
                                      input: { value: credentialsValue },
                                    }) => {
                                      const isCredentialExpired =
                                        isExpired ||
                                        credentialsValue === "expired" ||
                                        credentialsValue === "rejected" ||
                                        credentialsValue === "pendingreview";
                                      return (
                                        <>
                                          <div
                                            className={
                                              values?.ishepatitisB
                                                ? "pl-0 mobile-padding-right d-flex gap-3"
                                                : "d-flex  gap-3 mobile_wrap w-100 "
                                            }
                                          >
                                            {values &&
                                            values?.hepatitisBfile &&
                                            values?.ishepatitisB ? (
                                              <Field name={"hepatitisB"}>
                                                {({
                                                  input: { onChange, ...input },
                                                }) => (
                                                  <>
                                                    <div
                                                      className={`click_label_button ${
                                                        values?.ishepatitisB
                                                          ? "my-input-100 "
                                                          : " w-100 "
                                                      } ${
                                                        credentialRejectHandle(
                                                          `fluvaccination`,
                                                          !input.value
                                                            ? values?.hepatitisBname
                                                            : input.value
                                                        )
                                                          ? "rejected_text_div"
                                                          : ""
                                                      }`}
                                                      data-text={
                                                        values &&
                                                        values?.fluvaccinationfile
                                                          ? `fluVaccination_${values?.fluvaccinationfile?.slice(
                                                              0,
                                                              8
                                                            )}`
                                                          : isUploaded(
                                                              "fluvaccination"
                                                            )
                                                          ? "Uploaded"
                                                          : "Upload"
                                                      }
                                                    >
                                                      {values.ishepatitisB &&
                                                        !isCredentialExpired && (
                                                          <div className="d-flex gap-3  my-3">
                                                            <button
                                                              className="Approve-btns"
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                handleOnboardingDocs(
                                                                  onboardingFormTypes.hepatitisB
                                                                );
                                                              }}
                                                            >
                                                              {isUploaded(
                                                                "hepatitisB"
                                                              )
                                                                ? "Review Form"
                                                                : "Sign Declination"}
                                                            </button>
                                                          </div>
                                                        )}
                                                      {/* )} */}
                                                      <div className="d-flex">
                                                        <input
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          className="input-group border-0 w-100 cred-input cursor-pointer file-upload-field click_pin_icon"
                                                          {...input}
                                                          type="file"
                                                          onChange={(e) => {
                                                            onChange(e);
                                                            handleChange(
                                                              e,
                                                              "hepatitisB",
                                                              values
                                                            );
                                                          }}
                                                          disabled={
                                                            values &&
                                                            values?.hepatitisBfile &&
                                                            values?.ishepatitisB
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        {isCredentialExpired && (
                                                          <label
                                                            onClick={() => {
                                                              setInputPropsForAddNewTest(
                                                                {
                                                                  name: input.name,
                                                                  isDisabled: true,
                                                                }
                                                              );
                                                              setAddNewCred(
                                                                true
                                                              );
                                                            }}
                                                            className="Approve-btns justify-center w-100 whitespace-nowra"
                                                          >
                                                            Add New
                                                          </label>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </Field>
                                            ) : (
                                              <Field name={"hepatitisB"}>
                                                {({
                                                  input: { onChange, ...input },
                                                  meta,
                                                }) => (
                                                  <>
                                                    <div
                                                      className={`click_label_button ${
                                                        values?.ishepatitisB
                                                          ? "my-input-100  "
                                                          : ""
                                                      } ${
                                                        credentialRejectHandle(
                                                          `fluvaccination`,
                                                          !input.value
                                                            ? values?.hepatitisBname
                                                            : input.value
                                                        )
                                                          ? "rejected_text_div"
                                                          : ""
                                                      }`}
                                                      data-text={
                                                        values.fluvaccination
                                                          ? values
                                                              .fluvaccination
                                                              .name
                                                          : values?.fluvaccinationname
                                                          ? values?.fluvaccinationname
                                                          : "Upload"
                                                      }
                                                    >
                                                      {!isCredentialExpired && (
                                                        <label
                                                          type="file"
                                                          className="Approve-btns justify-center w-100"
                                                          onClick={() => {
                                                            const input =
                                                              document.getElementById(
                                                                "fileUpload-hepatitisB"
                                                              );
                                                            input.click();
                                                          }}
                                                        >
                                                          View
                                                        </label>
                                                      )}
                                                      <div className="d-flex">
                                                        <input
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          className="input-group border-0 w-100 cred-input cursor-pointer file-upload-field click_pin_icon"
                                                          {...input}
                                                          type="file"
                                                          id="fileUpload-hepatitisB"
                                                          onClick={(e) => {
                                                            checkUpload(
                                                              e,
                                                              values,
                                                              "hepatitisBfile",
                                                              "hepatitisB"
                                                            );
                                                          }}
                                                          onChange={(e) => {
                                                            onChange(e);
                                                            handleChange(
                                                              e,
                                                              "hepatitisB",
                                                              values
                                                            );
                                                          }}
                                                          disabled={
                                                            values &&
                                                            values?.ishepatitisB
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        {isCredentialExpired && (
                                                          <label
                                                            onClick={() => {
                                                              setInputPropsForAddNewTest(
                                                                {
                                                                  name: input.name,
                                                                  isDisabled: true,
                                                                }
                                                              );
                                                              setAddNewCred(
                                                                true
                                                              );
                                                            }}
                                                            className="Approve-btns justify-center w-100 whitespace-nowra"
                                                          >
                                                            Add New
                                                          </label>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </Field>
                                            )}
                                          </div>
                                        </>
                                      );
                                    }}
                                  </Field>
                                );
                              }}
                            </Field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="cred-document-type border-0">
                          Covid-19
                          <Field name="covidrecordrejectReason">
                            {({ input: { value } }) => {
                              setCovidRecordRejectReason(value + "\n");
                            }}
                          </Field>
                          <label className="container-card mt-4 align-items-center d-flex padding-left-ref ref-height my-3">
                            <Field name="covidrecordExpiration">
                              {({ input: { value } }) => {
                                const today = new Date();
                                const isExpired =
                                  new Date(value) <
                                  new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate()
                                  );
                                return (
                                  <>
                                    <Field
                                      name={`iscovidrecord`}
                                      type="checkbox"
                                    >
                                      {({ input }) => (
                                        <input
                                          {...input}
                                          disabled={
                                            values.covidrecordcredentialsStatus !==
                                              "active" || isExpired
                                          }
                                          onChange={(e) => {
                                            input.onChange(e);
                                            handleDocument(
                                              e,
                                              values,
                                              "covidrecord",
                                              form
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                    <span className="checkmark1"></span>
                                    <div className="cred-checkbox ml-3">
                                      I have not received a Covid-19
                                      Vaccination.
                                    </div>
                                  </>
                                );
                              }}
                            </Field>
                          </label>
                        </td>
                        <td className="border-0">
                          <Field name={"covidrecordUpdatedAt"}>
                            {({ input: { value } }) => {
                              const date = new Date(formatDate(value));
                              date.setFullYear(date.getFullYear() + 1);
                              const formattedDate = `${(date.getMonth() + 1)
                                .toString()
                                .padStart(2, "0")}-${date
                                .getDate()
                                .toString()
                                .padStart(2, "0")}-${date.getFullYear()}`;
                              return (
                                formattedDate && (
                                  <div className="cred-date-input mobile_width-990">
                                    {formattedDate && (
                                      <p className="cred-document-type">
                                        {formattedDate}
                                      </p>
                                    )}
                                  </div>
                                )
                              );
                            }}
                          </Field>
                        </td>
                        <td className="border-0">
                          <Field name="covidrecordExpiration">
                            {({ input: { value } }) => {
                              const today = new Date();
                              const isExpired =
                                new Date(value) <
                                new Date(
                                  today.getFullYear(),
                                  today.getMonth(),
                                  today.getDate()
                                );
                              return (
                                <Field name="covidrecordcredentialsStatus">
                                  {({ input: { value } }) =>
                                    value && (
                                      <button
                                        type="button"
                                        className="cred-status"
                                        style={{
                                          background: isExpired
                                            ? colors["expired"].background
                                            : colors[value]?.background,
                                          color: isExpired
                                            ? colors["expired"].color
                                            : colors[value]?.color,
                                        }}
                                      >
                                        {isExpired
                                          ? "Expired"
                                          : value
                                          ? value === "pendingreview"
                                            ? "Pending Review"
                                            : value.charAt(0).toUpperCase() +
                                              value.slice(1)
                                          : ""}
                                      </button>
                                    )
                                  }
                                </Field>
                              );
                            }}
                          </Field>
                        </td>
                        <td className="border-0">
                          <div className="flex">
                            <Field name="covidrecordExpiration">
                              {({ input: { value: expirationValue } }) => {
                                const today = new Date();
                                const isExpired =
                                  new Date(expirationValue) <
                                  new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate()
                                  );
                                return (
                                  <Field name="covidrecordcredentialsStatus">
                                    {({
                                      input: { value: credentialsValue },
                                    }) => {
                                      const isCredentialExpired =
                                        isExpired ||
                                        credentialsValue === "expired" ||
                                        credentialsValue === "rejected" ||
                                        credentialsValue === "pendingreview";
                                      return (
                                        <>
                                          <div
                                            className={
                                              values?.iscovidrecord
                                                ? "pl-0 mobile-padding-right d-flex gap-3"
                                                : "d-flex  gap-3 mobile_wrap w-100 "
                                            }
                                          >
                                            {values &&
                                            values?.covidrecordfile &&
                                            values?.iscovidrecord ? (
                                              <Field name={"covidrecord"}>
                                                {({
                                                  input: { onChange, ...input },
                                                }) => (
                                                  <>
                                                    <div
                                                      className={`click_label_button ${
                                                        values?.iscovidrecord
                                                          ? "my-input-100 "
                                                          : " w-100 "
                                                      } ${
                                                        credentialRejectHandle(
                                                          `fluvaccination`,
                                                          !input.value
                                                            ? values?.covidrecordname
                                                            : input.value
                                                        )
                                                          ? "rejected_text_div"
                                                          : ""
                                                      }`}
                                                      data-text={
                                                        values &&
                                                        values?.fluvaccinationfile
                                                          ? `fluVaccination_${values?.fluvaccinationfile?.slice(
                                                              0,
                                                              8
                                                            )}`
                                                          : isUploaded(
                                                              "fluvaccination"
                                                            )
                                                          ? "Uploaded"
                                                          : "Upload"
                                                      }
                                                    >
                                                      {values.iscovidrecord &&
                                                        !isCredentialExpired && (
                                                          <div className="d-flex gap-3 my-3">
                                                            <button
                                                              className="Approve-btns"
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                handleOnboardingDocs(
                                                                  onboardingFormTypes.covidrecord
                                                                );
                                                              }}
                                                            >
                                                              {isUploaded(
                                                                "covidrecord"
                                                              )
                                                                ? "Review Form"
                                                                : "Sign Declination"}
                                                            </button>
                                                          </div>
                                                        )}
                                                      {/* )} */}
                                                      <div className="d-flex">
                                                        <input
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          className="input-group border-0 w-100 cred-input cursor-pointer file-upload-field"
                                                          {...input}
                                                          type="file"
                                                          onChange={(e) => {
                                                            onChange(e);
                                                            handleChange(
                                                              e,
                                                              "covidrecord",
                                                              values
                                                            );
                                                          }}
                                                          disabled={
                                                            values &&
                                                            values?.covidrecordfile &&
                                                            values?.iscovidrecord
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        {isCredentialExpired && (
                                                          <label
                                                            onClick={() => {
                                                              setInputPropsForAddNewTest(
                                                                {
                                                                  name: input.name,
                                                                  isDisabled: true,
                                                                }
                                                              );
                                                              setAddNewCred(
                                                                true
                                                              );
                                                            }}
                                                            className="Approve-btns justify-center w-100 whitespace-nowra"
                                                          >
                                                            Add New
                                                          </label>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </Field>
                                            ) : (
                                              <Field name={"covidrecord"}>
                                                {({
                                                  input: { onChange, ...input },
                                                  meta,
                                                }) => (
                                                  <>
                                                    <div
                                                      className={`click_label_button ${
                                                        values?.iscovidrecord
                                                          ? "my-input-100  "
                                                          : ""
                                                      } ${
                                                        credentialRejectHandle(
                                                          `fluvaccination`,
                                                          !input.value
                                                            ? values?.covidrecordname
                                                            : input.value
                                                        )
                                                          ? "rejected_text_div"
                                                          : ""
                                                      }`}
                                                      data-text={
                                                        values.fluvaccination
                                                          ? values
                                                              .fluvaccination
                                                              .name
                                                          : values?.fluvaccinationname
                                                          ? values?.fluvaccinationname
                                                          : "Upload"
                                                      }
                                                    >
                                                      {!isCredentialExpired && (
                                                        <label
                                                          type="file"
                                                          className="Approve-btns justify-center w-100"
                                                          onClick={() => {
                                                            const input =
                                                              document.getElementById(
                                                                "fileUpload-covidrecord"
                                                              );
                                                            input.click();
                                                          }}
                                                        >
                                                          View
                                                        </label>
                                                      )}
                                                      <div className="d-flex">
                                                        <input
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          className="input-group border-0 w-100 cred-input cursor-pointer file-upload-field"
                                                          {...input}
                                                          type="file"
                                                          id="fileUpload-covidrecord"
                                                          onClick={(e) => {
                                                            checkUpload(
                                                              e,
                                                              values,
                                                              "covidrecordfile",
                                                              "covidrecord"
                                                            );
                                                          }}
                                                          onChange={(e) => {
                                                            onChange(e);
                                                            handleChange(
                                                              e,
                                                              "covidrecord",
                                                              values
                                                            );
                                                          }}
                                                          disabled={
                                                            values &&
                                                            values?.iscovidrecord
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        {isCredentialExpired && (
                                                          <label
                                                            onClick={() => {
                                                              setInputPropsForAddNewTest(
                                                                {
                                                                  name: input.name,
                                                                  isDisabled: true,
                                                                }
                                                              );
                                                              setAddNewCred(
                                                                true
                                                              );
                                                            }}
                                                            className="Approve-btns justify-center w-100 whitespace-nowra"
                                                          >
                                                            Add New
                                                          </label>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </Field>
                                            )}
                                          </div>
                                        </>
                                      );
                                    }}
                                  </Field>
                                );
                              }}
                            </Field>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div
                  className={`${
                    props?.isProfile
                      ? "col-xxl-12 col-xl-12"
                      : "col-xxl-12 col-xl-12"
                  }`}
                >
                  {props?.isProfile && (
                    <>
                      <div className="d-flex justify-content-between my-3">
                        <h5 className="cred-header ml-0 mt-3">
                          Competency Assessments and Compliance Documents
                        </h5>
                        <div className="Add-New-btn Empty-div"></div>
                      </div>

                      <table className="mobile_table-responsive-md table pt-0 document-tab therapist-tab">
                        <thead>
                          <tr>
                            <th style={{ width: "40%" }}>DOCUMENT TYPE</th>
                            <th style={{ width: "20%" }}>EXPIRATION DATE</th>
                            <th style={{ width: "31%" }}>STATUS</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="cred-document-type">
                              Competency Assessments
                            </td>
                            <td>
                              <Field name="competencies">
                                {({ input: { value } }) => {
                                  const compliancePdf =
                                    PrefillDetails?.onboardingPdf?.filter(
                                      (pdf) => pdf.types === "competencies"
                                    );
                                  if (
                                    compliancePdf &&
                                    compliancePdf.length > 0
                                  ) {
                                    const firstDate =
                                      compliancePdf[0]?.updatedAt;
                                    if (firstDate) {
                                      const date = new Date(
                                        formatDate(firstDate)
                                      );
                                      date.setFullYear(date.getFullYear() + 1);
                                      const formattedDate = `${(
                                        date.getMonth() + 1
                                      )
                                        .toString()
                                        .padStart(2, "0")}-${date
                                        .getDate()
                                        .toString()
                                        .padStart(
                                          2,
                                          "0"
                                        )}-${date.getFullYear()}`;
                                      return (
                                        <div className="cred-date-input mobile_width-990">
                                          <p className="cred-document-type">
                                            {formattedDate}
                                          </p>
                                        </div>
                                      );
                                    }
                                  }
                                  return null;
                                }}
                              </Field>
                            </td>
                            <td>
                              <Field name="competencyassessmentsExpiration">
                                {({ input: { value } }) => {
                                  const today = new Date();
                                  return (
                                    <Field name="competencyassessmentscredentialsStatus">
                                      {({ input: { value } }) => (
                                        <button
                                          type="button"
                                          className="cred-status"
                                          style={{
                                            background:
                                              colors["active"].background,
                                            color: colors["active"].color,
                                          }}
                                        >
                                          Active
                                        </button>
                                      )}
                                    </Field>
                                  );
                                }}
                              </Field>
                            </td>
                            <td>
                              <div className="d-flex mobile_wrap">
                                {PrefillDetails &&
                                  PrefillDetails.onboardingPdf &&
                                  PrefillDetails.onboardingPdf.length > 0 && (
                                    <div className="d-flex gap-2">
                                      <button
                                        className="Approve-btns justify-center button-width"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleDocumentShow(
                                            userCredentials.competencies
                                          );
                                        }}
                                      >
                                        View
                                      </button>
                                    </div>
                                  )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="cred-document-type border-0">
                              Compliance Documents
                            </td>
                            <td className="border-0">
                              <Field name="compliance">
                                {({ input: { value } }) => {
                                  const compliancePdf =
                                    PrefillDetails?.onboardingPdf?.filter(
                                      (pdf) => pdf.types === "compliance"
                                    );
                                  if (
                                    compliancePdf &&
                                    compliancePdf.length > 0
                                  ) {
                                    const firstDate =
                                      compliancePdf[0]?.updatedAt;
                                    if (firstDate) {
                                      const date = new Date(
                                        formatDate(firstDate)
                                      );
                                      date.setFullYear(date.getFullYear() + 1);
                                      const formattedDate = `${(
                                        date.getMonth() + 1
                                      )
                                        .toString()
                                        .padStart(2, "0")}-${date
                                        .getDate()
                                        .toString()
                                        .padStart(
                                          2,
                                          "0"
                                        )}-${date.getFullYear()}`;
                                      return (
                                        <div className="cred-date-input mobile_width-990">
                                          <p className="cred-document-type">
                                            {formattedDate}
                                          </p>
                                        </div>
                                      );
                                    }
                                  }
                                  return null;
                                }}
                              </Field>
                            </td>
                            <td className="border-0">
                              <Field name="compliancedocumentsExpiration">
                                {({ input: { value } }) => {
                                  const today = new Date();
                                  return (
                                    <Field name="compliancedocumentscredentialsStatus">
                                      {({ input: { value } }) => (
                                        <button
                                          type="button"
                                          className="cred-status"
                                          style={{
                                            background:
                                              colors["active"].background,
                                            color: colors["active"].color,
                                          }}
                                        >
                                          Active
                                        </button>
                                      )}
                                    </Field>
                                  );
                                }}
                              </Field>
                            </td>
                            <td className="border-0">
                              <div className="d-flex mobile_wrap">
                                {PrefillDetails &&
                                  PrefillDetails.onboardingPdf &&
                                  PrefillDetails.onboardingPdf.length > 0 && (
                                    <button
                                      className="Approve-btns button-width justify-center"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleDocumentShow(
                                          userCredentials.compliance
                                        );
                                      }}
                                    >
                                      View
                                    </button>
                                  )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
                <div
                  className={`${
                    props?.isProfile
                      ? "col-xxl-12 col-xl-12"
                      : "col-xxl-12 col-xl-12"
                  }`}
                >
                  <div>
                    <>
                      <div className="d-flex justify-content-between my-3 mx-2">
                        <h5 className="cred-header ml-0 mt-3">
                          Business Credentials
                        </h5>
                        <div className="Add-New-btn Empty-div"></div>
                      </div>
                      <table className="mobile_table-responsive-md table pt-0 patient_details document-tab therapist-tab">
                        <thead>
                          <tr>
                            <th style={{ width: "40%" }}>DOCUMENT TYPE</th>
                            <th style={{ width: "20%" }}>EXPIRATION DATE</th>
                            <th style={{ width: "31%" }}>STATUS</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="cred-document-type">W9</td>
                            <td>
                              <Field name={"w9UpdatedAt"}>
                                {({ input: { value } }) => {
                                  const date = new Date(formatDate(value));
                                  date.setFullYear(date.getFullYear() + 1);
                                  const formattedDate = `${(date.getMonth() + 1)
                                    .toString()
                                    .padStart(2, "0")}-${date
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}-${date.getFullYear()}`;

                                  return (
                                    formattedDate && (
                                      <div className="cred-date-input mobile_width-990">
                                        <p className="cred-document-type">
                                          {formattedDate}
                                        </p>
                                      </div>
                                    )
                                  );
                                }}
                              </Field>
                            </td>
                            <td>
                              <Field name="w9Expiration">
                                {({ input: { value } }) => {
                                  const today = new Date();
                                  const isExpired =
                                    new Date(value) <
                                    new Date(
                                      today.getFullYear(),
                                      today.getMonth(),
                                      today.getDate()
                                    );
                                  return (
                                    <Field name="w9credentialsStatus">
                                      {({ input: { value } }) => (
                                        <button
                                          type="button"
                                          className="cred-status"
                                          style={{
                                            background:
                                              colors["active"].background,
                                            color: colors["active"].color,
                                          }}
                                        >
                                          Active
                                        </button>
                                      )}
                                    </Field>
                                  );
                                }}
                              </Field>
                            </td>
                            <td>
                              {props.isProfile && (
                                <button
                                  className="Approve-btns justify-center button-width"
                                  type="button"
                                  onClick={() => handleW9Form("w9")}
                                >
                                  {values && values?.w9file
                                    ? `Signed`
                                    : isUploaded("w9")
                                    ? "Signed"
                                    : "Click to Sign"}
                                </button>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="cred-document-type border-0">
                              Business License
                            </td>
                            <td className="border-0">
                              <Field name="businesslicenserejectReason">
                                {({ input: { value } }) => {
                                  setBusinessLicenseRejectReason(value + "\n");
                                }}
                              </Field>
                              <Field name={"businesslicenseExpiration"}>
                                {({ input: { value } }) => {
                                  const formattedDate = formatDate(value);
                                  return formattedDate ? (
                                    <div className="cred-date-input mobile_width-990">
                                      {formattedDate && (
                                        <p className="cred-document-type">
                                          {formattedDate}
                                        </p>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="cred-date-input mobile_width-990">
                                      <p className="cred-document-type">
                                        01-01-2025
                                      </p>
                                    </div>
                                  );
                                }}
                              </Field>
                            </td>
                            <td className="border-0">
                              <Field name="businesslicenseExpiration">
                                {({ input: { value } }) => {
                                  const today = new Date();
                                  const isExpired =
                                    new Date(value) <
                                    new Date(
                                      today.getFullYear(),
                                      today.getMonth(),
                                      today.getDate()
                                    );
                                  return (
                                    <Field name="businesslicensecredentialsStatus">
                                      {({ input: { value } }) =>
                                        value && (
                                          <button
                                            type="button"
                                            className="cred-status"
                                            style={{
                                              background: isExpired
                                                ? colors["expired"].background
                                                : colors[value]?.background,
                                              color: isExpired
                                                ? colors["expired"].color
                                                : colors[value]?.color,
                                            }}
                                          >
                                            {isExpired
                                              ? "Expired"
                                              : value
                                              ? value === "pendingreview"
                                                ? "Pending Review"
                                                : value
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                  value.slice(1)
                                              : ""}
                                          </button>
                                        )
                                      }
                                    </Field>
                                  );
                                }}
                              </Field>
                            </td>
                            <td className="border-0">
                              <div className="flex">
                                <Field name="businesslicenseExpiration">
                                  {({ input: { value: expirationValue } }) => {
                                    const today = new Date();
                                    const isExpired =
                                      new Date(expirationValue) <
                                      new Date(
                                        today.getFullYear(),
                                        today.getMonth(),
                                        today.getDate()
                                      );
                                    return (
                                      <Field name="businesslicensecredentialsStatus">
                                        {({
                                          input: { value: credentialsValue },
                                        }) => {
                                          const isCredentialExpired =
                                            isExpired ||
                                            credentialsValue === "expired" ||
                                            credentialsValue === "rejected" ||
                                            credentialsValue ===
                                              "pendingreview";
                                          return (
                                            <>
                                              <Field name="businesslicense">
                                                {({ input }) => {
                                                  const {
                                                    onChange,
                                                    ...inputProps
                                                  } = input;
                                                  const rejected =
                                                    credentialRejectHandle(
                                                      "businesslicense",
                                                      !inputProps.value
                                                        ? values?.businesslicensename
                                                        : inputProps.value
                                                    );

                                                  return (
                                                    <div
                                                      className={`file_cursor click_label_button ${
                                                        rejected
                                                          ? "rejected_text_div"
                                                          : ""
                                                      }`}
                                                    >
                                                      {!isCredentialExpired && (
                                                        <label
                                                          type="file"
                                                          className="Approve-btns justify-center w-100"
                                                          onClick={() => {
                                                            const input =
                                                              document.getElementById(
                                                                "fileUpload-businesslicense"
                                                              );
                                                            input.click();
                                                          }}
                                                        >
                                                          View
                                                        </label>
                                                      )}
                                                      <div>
                                                        <input
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          className="input-group cred-input cursor-pointer file-upload-field"
                                                          {...input}
                                                          type="file"
                                                          id="fileUpload-businesslicense"
                                                          onClick={(e) => {
                                                            checkUpload(
                                                              e,
                                                              values,
                                                              "businesslicensefile",
                                                              "businesslicense"
                                                            );
                                                          }}
                                                          onChange={(e) => {
                                                            onChange(e);
                                                            handleChange(
                                                              e,
                                                              "businesslicense",
                                                              values,
                                                              form
                                                            );
                                                          }}
                                                          {...inputProps}
                                                        />
                                                        {isCredentialExpired && (
                                                          <label
                                                            onClick={() => {
                                                              setInputPropsForAddNewTest(
                                                                {
                                                                  name: inputProps.name,
                                                                  isDisabled: true,
                                                                }
                                                              );

                                                              setAddNewCred(
                                                                true
                                                              );
                                                            }}
                                                            className="Approve-btns justify-center w-100 whitespace-nowra"
                                                          >
                                                            Add New
                                                          </label>
                                                        )}
                                                      </div>
                                                    </div>
                                                  );
                                                }}
                                              </Field>
                                            </>
                                          );
                                        }}
                                      </Field>
                                    );
                                  }}
                                </Field>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  </div>
                </div>
              </div>
              {/* <Row>
                <Col md={12}>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ marginTop: "62px" }}>
                    <button
                      ref={buttonRef}
                      type="submit"
                      className={`update-btn  mb-5 ${(!agencyCheck) ? "opacity-50" : ""}`}
                      disabled={submitting || !agencyCheck}>
                      <IoSyncCircleOutline size={35} />
                      Update
                    </button>
                    <div></div>
                  </div>
                </Col>
              </Row> */}
            </div>
          ) : (
            <div className="contianer-fluid mt-4 ml-4 ">
              {!props.isProfile && (
                <div className=" col-sm-6 d-flex align-items-center gap-5  p-0  my-head">
                  <h4 className="cs-heading set-head-th px-0 m-0 p-0">
                    Credentials
                  </h4>
                  <h4 className="cs-date m-0">
                    {" "}
                    Step 05/<span className="pd-dt">08</span>
                  </h4>
                </div>
              )}
              <div className="row p-0">
                <div
                  className={`${
                    props?.isProfile
                      ? "col-xxl-6 col-xl-6"
                      : "col-xxl-4 col-xl-5"
                  }`}
                >
                  <p className="cred-p ml-0 mt-3">Therapist Credentials</p>
                  <p className="w9 mt-3 ">Therapy License </p>
                  <div className="d-flex gap-3 mb-3 mobile_wrap w-100">
                    <Field name={"therapylicense"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <>
                          <div
                            className={
                              credentialRejectHandle(
                                `therapylicense`,
                                !input.value
                                  ? values?.therapylicensename
                                  : input.value
                              )
                                ? "file-upload-wrapper file_cursor rejected_text_div click_pin_icon"
                                : "file-upload-wrapper file_cursor click_pin_icon"
                            }
                            data-text={
                              values.therapylicense
                                ? values.therapylicense.name
                                : values?.therapylicensename
                                ? values?.therapylicensename
                                : "Upload"
                            }
                          >
                            <div>
                              <div className="d-flex">
                                <input
                                  className="input-group w-100 cred-input cursor-pointer file-upload-field"
                                  id="fileUpload-therapylicense"
                                  name="file-upload-field"
                                  value="driverslicense_1665821827852.pdf"
                                  {...input}
                                  type="file"
                                  onClick={(e) => {
                                    checkUpload(
                                      e,
                                      values,
                                      "therapylicensefile",
                                      "therapylicense"
                                    );
                                  }}
                                  onChange={(e) => {
                                    onChange(e);
                                    handleChange(
                                      e,
                                      "therapylicense",
                                      values,
                                      form
                                    );
                                    setTestUpload(false);
                                  }}
                                />
                                <label
                                  onClick={() =>
                                    setTestUpload("therapylicensefile")
                                  }
                                  htmlFor="fileUpload-therapylicense"
                                  style={{
                                    backgroundColor: "#0f7ad4",
                                    zIndex: "2",
                                    width: "54px",
                                    borderRadius: "3px",
                                  }}
                                  className="cursor-pointer m-0 d-flex justify-content-center align-items-center link"
                                >
                                  {" "}
                                  <img src={GrAttachment_} height="27px" />{" "}
                                </label>
                              </div>
                              {meta.error && meta.touched && (
                                <p
                                  className="alert alert-danger top-cred top-11"
                                  role="alert"
                                >
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </Field>

                    <Field name={"therapylicenseExpiration"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <div className="cred-date-input mobile_width-990 ">
                          <ExpirationDateInput
                            input={input}
                            onChange={onChange}
                            typename="therapylicense"
                            values={values}
                            form={form}
                          />

                          {meta.error && meta.touched && (
                            <p
                              className={`alert alert-danger top-11 top-cred ${
                                props?.isProfile ? "mobile-75" : "mobile-75"
                              }`}
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <p className="w9 mt-2 ">Driver's License </p>
                  {/* {credentialRejectHandle(`driverslicense`)} */}
                  <div className="d-flex gap-3 mobile_wrap w-100">
                    <Field name={"driverslicense"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <>
                          <div
                            id="dynamicText"
                            className={
                              credentialRejectHandle(
                                `driverslicense`,
                                !input.value
                                  ? values?.driverslicensename
                                  : input.value
                                // values?.driverslicensename
                              )
                                ? "file-upload-wrapper file_cursor rejected_text_div click_pin_icon cursor-pointer"
                                : "file-upload-wrapper file_cursor click_pin_icon cursor-pointer"
                            }
                            data-text={
                              values.driverslicense
                                ? values.driverslicense.name
                                : values?.driverslicensename
                                ? values?.driverslicensename
                                : "Upload"
                            }
                          >
                            <div>
                              <div className="d-flex">
                                <input
                                  className="input-group cred-input cursor-pointer w-100 file-upload-field"
                                  name="file-upload-field"
                                  id="fileUpload-driverslicense"
                                  value=""
                                  {...input}
                                  type="file"
                                  onClick={(e) => {
                                    checkUpload(
                                      e,
                                      values,
                                      "driverslicensefile",
                                      "driverslicense"
                                    );
                                  }}
                                  onChange={(e) => {
                                    onChange(e);
                                    handleChange(
                                      e,
                                      "driverslicense",
                                      values,
                                      form
                                    );
                                    setTestUpload(false);
                                  }}
                                />
                                <label
                                  onClick={() =>
                                    setTestUpload("driverslicensefile")
                                  }
                                  htmlFor="fileUpload-driverslicense"
                                  style={{
                                    backgroundColor: "#0f7ad4",
                                    zIndex: "2",
                                    width: "54px",
                                    borderRadius: "3px",
                                  }}
                                  className="cursor-pointer m-0 d-flex justify-content-center align-items-center"
                                >
                                  <img src={GrAttachment_} height="27px" />
                                </label>
                              </div>
                              {meta.error && meta.touched && (
                                <p
                                  className={`alert alert-danger top-11 top-cred ${
                                    props?.isProfile ? "mobile-75" : "mobile-75"
                                  }`}
                                  role="alert"
                                >
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </Field>
                    <Field name={"driverslicenseExpiration"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <div className="cred-date-input mobile_width-990">
                          <ExpirationDateInput
                            input={input}
                            onChange={onChange}
                            typename="driverslicense"
                            values={values}
                            form={form}
                          />

                          {meta.error && meta.touched && (
                            <p
                              className={`alert alert-danger top-11 top-cred ${
                                props?.isProfile ? "mobile-75" : "mobile-75"
                              }`}
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>{" "}
                  </div>
                  <p className="w9 mt-3 mt-3">Auto Insurance </p>
                  {/* {credentialRejectHandle(`autoinsurance`)} */}
                  <div className="d-flex gap-3 mobile_wrap w-100">
                    <Field name={"autoinsurance"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <>
                          <div
                            className={
                              credentialRejectHandle(
                                `autoinsurance`,
                                !input.value
                                  ? values?.autoinsurancename
                                  : input.value
                              )
                                ? "file-upload-wrapper  file_cursor rejected_text_div click_pin_icon"
                                : "file-upload-wrapper  file_cursor click_pin_icon"
                            }
                            id="dynamicText"
                            data-text={
                              values.autoinsurance
                                ? values.autoinsurance.name
                                : values?.autoinsurancename
                                ? values?.autoinsurancename
                                : "Upload"
                            }
                          >
                            <div>
                              <div className="d-flex">
                                <input
                                  className="input-group cred-input cursor-pointer w-100 file-upload-field"
                                  {...input}
                                  id="fileUpload-autoinsurance"
                                  type="file"
                                  onClick={(e) => {
                                    checkUpload(
                                      e,
                                      values,
                                      "autoinsurancefile",
                                      "autoinsurance"
                                    );
                                  }}
                                  onChange={(e) => {
                                    onChange(e);
                                    handleChange(
                                      e,
                                      "autoinsurance",
                                      values,
                                      form
                                    );
                                  }}
                                />
                                <label
                                  onClick={() =>
                                    setTestUpload("autoinsurancefile")
                                  }
                                  htmlFor="fileUpload-autoinsurance"
                                  style={{
                                    backgroundColor: "#0f7ad4",
                                    zIndex: "2",
                                    width: "54px",
                                    borderRadius: "3px",
                                  }}
                                  className="cursor-pointer m-0 d-flex justify-content-center align-items-center"
                                >
                                  <img src={GrAttachment_} height="27px" />
                                </label>
                              </div>
                              {meta.error && meta.touched && (
                                <p
                                  className="alert alert-danger top-cred top-11"
                                  role="alert"
                                >
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </Field>
                    <Field name={"autoinsuranceExpiration"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <div className="cred-date-input mobile_width-990">
                          <ExpirationDateInput
                            input={input}
                            onChange={onChange}
                            typename="autoinsurance"
                            values={values}
                            form={form}
                          />

                          {meta.error && meta.touched && (
                            <p
                              className={`alert alert-danger top-11 top-cred ${
                                props?.isProfile ? "mobile-75" : "mobile-75"
                              }`}
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>{" "}
                  </div>
                  <p className="w9 mt-3">Professional Liability Insurance</p>
                  {/* {credentialRejectHandle(`professionallaibilityinsurance`)} */}
                  <div className="d-flex gap-3 mobile_wrap w-100">
                    {" "}
                    <Field name={"professionallaibilityinsurance"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <>
                          <div
                            className={
                              credentialRejectHandle(
                                `professionallaibilityinsurance`,
                                !input.value
                                  ? values?.professionallaibilityinsurancename
                                  : input.value

                                // values?.professionallaibilityinsurancename
                              )
                                ? "file-upload-wrapper  file_cursor rejected_text_div click_pin_icon"
                                : "file-upload-wrapper  file_cursor click_pin_icon"
                            }
                            id="dynamicText"
                            data-text={
                              values.professionallaibilityinsurance
                                ? values.professionallaibilityinsurance.name
                                : values?.professionallaibilityinsurancename
                                ? values?.professionallaibilityinsurancename
                                : "Upload"
                            }
                          >
                            <div>
                              <div className="d-flex">
                                <input
                                  className="input-group w-100 cred-input cursor-pointer file-upload-field"
                                  {...input}
                                  type="file"
                                  id="fileUpload-professionallaibilityinsurance"
                                  onClick={(e) => {
                                    checkUpload(
                                      e,
                                      values,
                                      "professionallaibilityinsurancefile",
                                      "professionallaibilityinsurance"
                                    );
                                  }}
                                  onChange={(e) => {
                                    onChange(e);
                                    handleChange(
                                      e,
                                      "professionallaibilityinsurance",
                                      values
                                    );
                                  }}
                                />
                                <label
                                  onClick={() =>
                                    setTestUpload(
                                      "professionallaibilityinsurancefile"
                                    )
                                  }
                                  htmlFor="fileUpload-professionallaibilityinsurance"
                                  style={{
                                    backgroundColor: "#0f7ad4",
                                    zIndex: "2",
                                    width: "54px",
                                    borderRadius: "3px",
                                  }}
                                  className="cursor-pointer m-0 d-flex justify-content-center align-items-center link"
                                >
                                  {" "}
                                  <img src={GrAttachment_} height="27px" />{" "}
                                </label>
                              </div>
                              {meta.error && meta.touched && (
                                <p
                                  className="alert alert-danger top-cred top-11"
                                  role="alert"
                                >
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </Field>
                    <Field name={"professionallaibilityinsuranceExpiration"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <div className="cred-date-input mobile_width-990">
                          <ExpirationDateInput
                            input={input}
                            onChange={onChange}
                            typename="professionallaibilityinsurance"
                            values={values}
                            form={form}
                          />

                          {meta.error && meta.touched && (
                            <p
                              className={`alert alert-danger top-11 top-cred ${
                                props?.isProfile ? "mobile-75" : "mobile-75"
                              }`}
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>{" "}
                  </div>
                  <p className="w9 mt-3">CPR Certification </p>
                  {/* {credentialRejectHandle(`cprcertification`)} */}
                  <div className="d-flex gap-3 mobile_wrap w-100">
                    {" "}
                    <Field name={"cprcertification"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <>
                          <div
                            className={
                              credentialRejectHandle(
                                `cprcertification`,
                                !input.value
                                  ? values?.cprcertificationname
                                  : input.value
                              )
                                ? "file-upload-wrapper  file_cursor rejected_text_div click_pin_icon"
                                : "file-upload-wrapper  file_cursor click_pin_icon"
                            }
                            id="dynamicText"
                            data-text={
                              values.cprcertification
                                ? values.cprcertification.name
                                : values?.cprcertificationname
                                ? values?.cprcertificationname
                                : "Upload"
                            }
                          >
                            <div>
                              <div className="d-flex">
                                <input
                                  className="input-group w-100 cred-input cursor-pointer file-upload-field"
                                  {...input}
                                  type="file"
                                  id="fileUpload-cprcertification"
                                  onClick={(e) => {
                                    checkUpload(
                                      e,
                                      values,
                                      "cprcertificationfile",
                                      "cprcertification"
                                    );
                                  }}
                                  onChange={(e) => {
                                    onChange(e);
                                    handleChange(
                                      e,
                                      "cprcertification",
                                      values,
                                      form
                                    );
                                  }}
                                />
                                <label
                                  onClick={() =>
                                    setTestUpload("cprcertificationfile")
                                  }
                                  htmlFor="fileUpload-cprcertification"
                                  style={{
                                    backgroundColor: "#0f7ad4",
                                    zIndex: "2",
                                    width: "54px",
                                    borderRadius: "3px",
                                  }}
                                  className="cursor-pointer m-0 d-flex justify-content-center align-items-center link"
                                >
                                  {" "}
                                  <img src={GrAttachment_} height="27px" />{" "}
                                </label>
                              </div>
                              {meta.error && meta.touched && (
                                <p
                                  className="alert top-cred alert-danger top-11"
                                  role="alert"
                                >
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </Field>
                    <Field name={"cprcertificationExpiration"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <div className="cred-date-input mobile_width-990">
                          <ExpirationDateInput
                            input={input}
                            onChange={onChange}
                            typename="cprcertification"
                            values={values}
                            form={form}
                          />

                          {meta.error && meta.touched && (
                            <p
                              className={`alert alert-danger top-11 top-cred ${
                                props?.isProfile ? "mobile-75" : "mobile-75"
                              }`}
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>

                  <OverlayTrigger
                    style={{ width: "160px" }}
                    placement={
                      props.isProfile ? placementTooltip : OnplacementTooltip
                    }
                    delay={{ show: 50, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <p
                      className="w9 mt-3"
                      style={{
                        width: `${
                          getWindowDimensions().width < 1200 ? "109px" : "200px"
                        }`,
                      }}
                    >
                      Physical{" "}
                      <TbQuestionMark
                        size={28}
                        style={{
                          background:
                            "transparent linear-gradient(285deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box",
                          borderRadius: "50%",
                          color: "white",
                          marginLeft: "20px",
                        }}
                      />
                    </p>
                  </OverlayTrigger>
                  <div className="d-flex gap-3 mobile_wrap w-100">
                    <Field name={"physical"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <>
                          <div
                            className={
                              credentialRejectHandle(
                                `physical`,
                                !input.value
                                  ? values?.physicalname
                                  : input.value
                              )
                                ? "file-upload-wrapper   file_cursor rejected_text_div click_pin_icon"
                                : "file-upload-wrapper   file_cursor click_pin_icon"
                            }
                            id="dynamicText"
                            // className="file-upload-wrapper overflow-hidden mobile_width-990 file_cursor rejected_text_div"
                            data-text={
                              values.physical
                                ? values.physical.name
                                : values?.physicalname
                                ? values?.physicalname
                                : "Upload"
                            }
                          >
                            <div>
                              <div className="d-flex">
                                <input
                                  className="input-group w-100 cred-input cursor-pointer file-upload-field "
                                  {...input}
                                  type="file"
                                  id="fileUpload-physical"
                                  onClick={(e) => {
                                    checkUpload(
                                      e,
                                      values,
                                      "physicalfile",
                                      "physical"
                                    );
                                  }}
                                  onChange={(e) => {
                                    onChange(e);
                                    handleChange(e, "physical", values, form);
                                  }}
                                />
                                <label
                                  onClick={() => setTestUpload("physicalfile")}
                                  htmlFor="fileUpload-physical"
                                  style={{
                                    backgroundColor: "#0f7ad4",
                                    zIndex: "2",
                                    width: "54px",
                                    borderRadius: "3px",
                                  }}
                                  className="cursor-pointer m-0 d-flex justify-content-center align-items-center link"
                                >
                                  {" "}
                                  <img src={GrAttachment_} height="27px" />{" "}
                                </label>
                              </div>
                              {meta.error && meta.touched && (
                                <p
                                  className="alert top-cred alert-danger top-11"
                                  role="alert"
                                >
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </Field>
                    <Field name={"physicalExpiration"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <div className="cred-date-input mobile_width-990">
                          <ExpirationDateInput
                            input={input}
                            onChange={onChange}
                            typename="physical"
                            values={values}
                            form={form}
                          />
                          {meta.error && meta.touched && (
                            <p
                              className={`alert alert-danger top-11 top-cred ${
                                props?.isProfile ? "mobile-75" : "mobile-75"
                              }`}
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <OverlayTrigger
                    style={{ width: "200px" }}
                    placement={"auto"}
                    // placement={`${placementTooltip}`}
                    delay={{ show: 250, hide: 400 }}
                    overlay={toolTip2}
                  >
                    <p
                      className="w9 mt-3"
                      style={{
                        width: `${
                          getWindowDimensions().width < 1200 ? "97px" : "200px"
                        }`,
                      }}
                    >
                      TB Test{" "}
                      <TbQuestionMark
                        size={28}
                        style={{
                          background:
                            "transparent linear-gradient(285deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box",
                          borderRadius: "50%",
                          color: "white",
                          marginLeft: "20px",
                        }}
                      />
                    </p>
                  </OverlayTrigger>
                  {/* {credentialRejectHandle(`tbtest`)} */}

                  <div className="d-flex gap-3 mobile_wrap w-100">
                    <Field name={"tbtest"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <>
                          <div
                            className={
                              credentialRejectHandle(
                                `tbtest`,
                                !input.value ? values?.tbtestname : input.value
                              )
                                ? "file-upload-wrapper file_cursor rejected_text_div click_pin_icon"
                                : "file-upload-wrapper file_cursor click_pin_icon"
                            }
                            id="dynamicText"
                            data-text={
                              values.tbtest
                                ? values.tbtest.name
                                : values?.tbtestname
                                ? values?.tbtestname
                                : "Upload"
                            }
                          >
                            <div>
                              <div className="d-flex">
                                <input
                                  className="input-group w-100 cred-input cursor-pointer file-upload-field"
                                  {...input}
                                  id="fileUpload-tbtest"
                                  type="file"
                                  onClick={(e) => {
                                    checkUpload(
                                      e,
                                      values,
                                      "tbtestfile",
                                      "tbtest"
                                    );
                                  }}
                                  onChange={(e) => {
                                    onChange(e);
                                    handleChange(e, "tbtest", values, form);
                                  }}
                                />
                                <label
                                  onClick={() => setTestUpload("tbtestfile")}
                                  htmlFor="fileUpload-tbtest"
                                  style={{
                                    backgroundColor: "#0f7ad4",
                                    zIndex: "2",
                                    width: "54px",
                                    borderRadius: "3px",
                                  }}
                                  className="cursor-pointer m-0 d-flex justify-content-center align-items-center link"
                                >
                                  {" "}
                                  <img src={GrAttachment_} height="27px" />{" "}
                                </label>
                              </div>
                              {meta.error && meta.touched && (
                                <p
                                  className="alert top-cred alert-danger top-11"
                                  role="alert"
                                >
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </Field>
                    <Field name={"tbtestExpiration"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <div className="cred-date-input mobile_width-990 ">
                          <ExpirationDateInput
                            input={input}
                            onChange={onChange}
                            typename="tbtest"
                            values={values}
                            form={form}
                          />

                          {meta.error && meta.touched && (
                            <p
                              className={`alert alert-danger top-11 top-cred ${
                                props?.isProfile ? "mobile-75" : "mobile-75"
                              }`}
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>

                  {/* Flu and Declination  */}
                  <OverlayTrigger
                    style={{ width: "120px" }}
                    placement={`${placementTooltip}`}
                    delay={{ show: 250, hide: 400 }}
                    overlay={toolTip3}
                  >
                    <p
                      className="w9 mt-3"
                      style={{
                        width: `${
                          getWindowDimensions().width < 1200 ? "150px" : "200px"
                        }`,
                      }}
                    >
                      Flu Vaccination
                      <TbQuestionMark
                        size={28}
                        style={{
                          background:
                            "transparent linear-gradient(285deg, #6a40d4 0%, #0f7ad4 100%) 0% 0% no-repeat padding-box",
                          borderRadius: "50%",
                          color: "white",
                          marginLeft: "20px",
                        }}
                      />
                    </p>
                  </OverlayTrigger>
                  {/* {credentialRejectHandle(`fluvaccination`)} */}
                  <div
                    className={
                      values?.isfluvaccination
                        ? " pl-0 mobile-padding-right d-flex gap-3"
                        : "d-flex  gap-3 mobile_wrap w-100 "
                    }
                  >
                    {values &&
                    values?.fluvaccinationfile &&
                    values?.isfluvaccination ? (
                      <Field name={"fluvaccination"}>
                        {({ input: { onChange, ...input }, meta }) => (
                          <>
                            <div
                              className={`file-upload-wrapper click_pin_icon ${
                                values?.isfluvaccination
                                  ? "my-input-100 "
                                  : " w-100 "
                              }    ${
                                credentialRejectHandle(
                                  `fluvaccination`,
                                  !input.value
                                    ? values?.fluvaccinationname
                                    : input.value
                                )
                                  ? "rejected_text_div"
                                  : ""
                              }`}
                              data-text={
                                values && values?.fluvaccinationfile
                                  ? `fluVaccination_${values?.fluvaccinationfile?.slice(
                                      0,
                                      8
                                    )}`
                                  : isUploaded("fluvaccination")
                                  ? "Uploaded"
                                  : "Upload"
                              }
                            >
                              <div>
                                <div className="d-flex">
                                  <input
                                    className="input-group border-0 w-100 cred-input cursor-pointer file-upload-field"
                                    {...input}
                                    type="file"
                                    onChange={(e) => {
                                      onChange(e);
                                      handleChange(
                                        e,
                                        "fluvaccination",
                                        values,
                                        form
                                      );
                                    }}
                                    disabled={
                                      values &&
                                      values?.fluvaccinationfile &&
                                      values?.isfluvaccination
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    style={{
                                      backgroundColor: "#0f7ad4",
                                      zIndex: "2",
                                      width: "54px",
                                      borderRadius: "3px",
                                    }}
                                    className="cursor-pointer m-0 d-flex justify-content-center align-items-center link"
                                  >
                                    {" "}
                                    <img src={GrAttachment_} height="" />{" "}
                                  </label>
                                </div>

                                {meta.error && meta.touched && (
                                  <p
                                    className="alert top-cred alert-danger top-11"
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </Field>
                    ) : (
                      <Field name={"fluvaccination"}>
                        {({ input: { onChange, ...input }, meta }) => (
                          <>
                            <div
                              className={`file-upload-wrapper click_pin_icon ${
                                values?.isfluvaccination ? "my-input-100  " : ""
                              }
                              ${
                                credentialRejectHandle(
                                  `fluvaccination`,
                                  !input.value
                                    ? values?.fluvaccinationname
                                    : input.value
                                )
                                  ? "rejected_text_div"
                                  : ""
                              }`}
                              data-text={
                                values.fluvaccination
                                  ? values.fluvaccination.name
                                  : values?.fluvaccinationname
                                  ? values?.fluvaccinationname
                                  : "Upload"
                              }
                            >
                              <div>
                                <div className="d-flex">
                                  <input
                                    className="input-group border-0 w-100 cred-input cursor-pointer file-upload-field"
                                    {...input}
                                    type="file"
                                    id="fileUpload-fluvaccination"
                                    onClick={(e) => {
                                      checkUpload(
                                        e,
                                        values,
                                        "fluvaccinationfile",
                                        "fluvaccination"
                                      );
                                    }}
                                    onChange={(e) => {
                                      onChange(e);
                                      handleChange(e, "fluvaccination", values);
                                    }}
                                    disabled={
                                      values && values?.isfluvaccination
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    onClick={() =>
                                      setTestUpload("fluvaccinationfile")
                                    }
                                    htmlFor="fileUpload-fluvaccination"
                                    style={{
                                      backgroundColor: "#0f7ad4",
                                      zIndex: "2",
                                      width: "54px",
                                      borderRadius: "3px",
                                    }}
                                    className="cursor-pointer m-0 d-flex justify-content-center align-items-center link"
                                  >
                                    {" "}
                                    <img
                                      src={GrAttachment_}
                                      height="27px"
                                    />{" "}
                                  </label>
                                </div>
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert top-cred alert-danger top-11"
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </Field>
                    )}

                    <Field name={"fluvaccinationExpiration"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <div className="cred-date-input mobile_width-990">
                          <ExpirationDateInput
                            input={input}
                            onChange={onChange}
                            typename="fluvaccination"
                            values={values}
                            form={form}
                          />

                          {meta.error && meta.touched && (
                            <p
                              className={`alert alert-danger top-11 top-cred ${
                                props?.isProfile ? "mobile-75" : "mobile-75"
                              }`}
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>

                  <label className="container-card mt-4 align-items-center d-flex padding-left-ref ref-height ">
                    <Field name={`isfluvaccination`} type="checkbox">
                      {({ input }) => (
                        <input
                          {...input}
                          onChange={(e) => {
                            input.onChange(e);
                            handleDocument(e, values, "fluvaccination", form);
                          }}
                        />
                      )}
                    </Field>
                    <span className=" checkmark1"></span>
                    <div className="ml-3">
                      I have not received a Flu Vaccination for the current Flu
                      Season.
                    </div>
                  </label>
                  {values.isfluvaccination && (
                    <div className="d-flex gap-3  my-3">
                      <button
                        className="Approve-btns"
                        onClick={(e) => {
                          e.preventDefault();
                          handleOnboardingDocs(
                            onboardingFormTypes.fluvaccination
                          );
                        }}
                      >
                        {isUploaded("fluvaccination")
                          ? "Review Form"
                          : "Sign Declination"}
                      </button>
                    </div>
                  )}

                  {/* Heppatti b  */}
                  <p
                    className="w9 mt-3"
                    style={{
                      width: `${
                        getWindowDimensions().width < 1200 ? "150px" : "200px"
                      }`,
                    }}
                  >
                    Hepatitis B
                  </p>
                  {/* {credentialRejectHandle(`hepatitisB`)} */}
                  <div
                    className={
                      values?.ishepatitisB
                        ? " pl-0 mobile-padding-right d-flex gap-3"
                        : "d-flex  gap-3 mobile_wrap w-100 "
                    }
                  >
                    {values &&
                    values?.hepatitisBfile &&
                    values?.ishepatitisB ? (
                      <Field name={"hepatitisB"}>
                        {({ input: { onChange, ...input }, meta }) => (
                          <>
                            <div
                              className={`file-upload-wrapper ${
                                values?.ishepatitisB
                                  ? "my-input-100   click_pin_icon"
                                  : " w-100 click_pin_icon"
                              } ${
                                credentialRejectHandle(
                                  `hepatitisB`,
                                  !input.value
                                    ? values?.hepatitisBname
                                    : input.value
                                )
                                  ? "rejected_text_div"
                                  : ""
                              }`}
                              data-text={
                                values && values?.hepatitisBfile
                                  ? `hepatitisB_${values?.hepatitisBfile?.slice(
                                      0,
                                      8
                                    )}`
                                  : "Upload"
                              }
                            >
                              <div>
                                <div className="d-flex">
                                  <input
                                    className="input-group border-0 w-100 cred-input cursor-pointer file-upload-field click_pin_icon"
                                    {...input}
                                    type="file"
                                    onChange={(e) => {
                                      onChange(e);
                                      handleChange(e, "hepatitisB", values);
                                    }}
                                    disabled={
                                      values &&
                                      values?.hepatitisBfile &&
                                      values?.ishepatitisB
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    style={{
                                      backgroundColor: "#0f7ad4",
                                      zIndex: "2",
                                      width: "54px",
                                      borderRadius: "3px",
                                    }}
                                    className="m-0 d-flex cursor-pointer justify-content-center align-items-center link"
                                  >
                                    {" "}
                                    <img
                                      src={GrAttachment_}
                                      height="27px"
                                    />{" "}
                                  </label>
                                </div>

                                {meta.error && meta.touched && (
                                  <p
                                    className="alert top-cred alert-danger top-11 mb-3"
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </Field>
                    ) : (
                      <Field name={"hepatitisB"}>
                        {({ input: { onChange, ...input }, meta }) => (
                          <>
                            <div
                              className={`file-upload-wrapper ${
                                values?.ishepatitisB
                                  ? " click_pin_icon"
                                  : " click_pin_icon"
                              }
                            ${
                              credentialRejectHandle(
                                `hepatitisB`,
                                !input.value
                                  ? values?.hepatitisBname
                                  : input.value
                              )
                                ? "rejected_text_div"
                                : ""
                            }
                            
                            `}
                              data-text={
                                values.hepatitisB
                                  ? values.hepatitisB.name
                                  : values?.hepatitisBname
                                  ? values?.hepatitisBname
                                  : "Upload"
                              }
                            >
                              <div>
                                <div className="d-flex">
                                  <input
                                    className="input-group border-0 w-100 cred-input cursor-pointer file-upload-field click_pin_icon"
                                    {...input}
                                    type="file"
                                    id="fileUpload-hepatitisB"
                                    onClick={(e) => {
                                      checkUpload(
                                        e,
                                        values,
                                        "hepatitisBfile",
                                        "hepatitisB"
                                      );
                                    }}
                                    onChange={(e) => {
                                      onChange(e);
                                      handleChange(e, "hepatitisB", values);
                                    }}
                                    disabled={
                                      values && values?.ishepatitisB
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    onClick={() =>
                                      setTestUpload("hepatitisBfile")
                                    }
                                    htmlFor="fileUpload-hepatitisB"
                                    style={{
                                      backgroundColor: "#0f7ad4",
                                      zIndex: "2",
                                      width: "54px",
                                      borderRadius: "3px",
                                    }}
                                    className="cursor-pointer m-0 d-flex justify-content-center align-items-center link"
                                  >
                                    {" "}
                                    <img
                                      src={GrAttachment_}
                                      height="27px"
                                    />{" "}
                                  </label>
                                </div>
                                {submitFailed && meta.error && meta.touched && (
                                  <p
                                    className="alert top-cred alert-danger top-11 mb-3"
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </Field>
                    )}
                    <div className="cred-date-input mobile_width-990"></div>
                  </div>

                  <label className="container-card mt-4 align-items-center d-flex padding-left-ref ref-height ">
                    <Field name={`ishepatitisB`} type="checkbox">
                      {({ input }) => (
                        <input
                          {...input}
                          onChange={(e) => {
                            input.onChange(e);
                            handleDocument(e, values, "hepatitisB", form);
                          }}
                        />
                      )}
                    </Field>
                    <span className=" checkmark1"></span>
                    <div className="ml-3">
                      I have not received a Hepatitis B Vaccination.
                      {/* for the current Flu Season */}
                    </div>
                  </label>
                  {values.ishepatitisB && (
                    <div className="d-flex gap-3  my-3">
                      <button
                        className="Approve-btns"
                        onClick={(e) => {
                          e.preventDefault();
                          handleOnboardingDocs(onboardingFormTypes.hepatitisB);
                        }}
                      >
                        {isUploaded("hepatitisB")
                          ? "Review Form"
                          : "Sign Declination"}
                      </button>
                    </div>
                  )}

                  {/* Covid 19 Record */}

                  <OverlayTrigger
                    style={{ width: "120px" }}
                    placement={`${placementTooltip}`}
                    delay={{ show: 250, hide: 400 }}
                    overlay={toolTip3}
                  >
                    <p
                      className="w9 mt-3"
                      style={{
                        width: `${
                          getWindowDimensions().width < 1200 ? "150px" : "200px"
                        }`,
                      }}
                    >
                      Covid - 19
                    </p>
                  </OverlayTrigger>
                  {/* {credentialRejectHandle(`covidrecord`)} */}
                  <div
                    className={
                      values?.iscovidrecord
                        ? " pl-0 mobile-padding-right d-flex gap-3"
                        : "d-flex  gap-3 mobile_wrap w-100 "
                    }
                  >
                    {values &&
                    values?.covidrecordfile &&
                    values?.iscovidrecord ? (
                      <Field name={"covidrecord"}>
                        {({ input: { onChange, ...input }, meta }) => (
                          <>
                            <div
                              className={`file-upload-wrapper ${
                                values?.iscovidrecord
                                  ? " click_pin_icon"
                                  : " w-100 click_pin_icon"
                              } ${
                                credentialRejectHandle(
                                  `covidrecord`,
                                  !input.value
                                    ? values?.covidrecordname
                                    : input.value
                                )
                                  ? "rejected_text_div"
                                  : ""
                              }`}
                              data-text={
                                values && values?.covidrecordfile
                                  ? `Covid-19_${values?.covidrecordfile?.slice(
                                      0,
                                      8
                                    )}`
                                  : "Upload"
                              }
                            >
                              <div>
                                <div className="d-flex">
                                  <input
                                    className="input-group border-0 w-100 cred-input cursor-pointer file-upload-field"
                                    {...input}
                                    type="file"
                                    onChange={(e) => {
                                      onChange(e);
                                      handleChange(e, "covidrecord", values);
                                    }}
                                    disabled={
                                      values &&
                                      values?.covidrecordfile &&
                                      values?.iscovidrecord
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    style={{
                                      backgroundColor: "#0f7ad4",
                                      zIndex: "2",
                                      width: "54px",
                                      borderRadius: "3px",
                                    }}
                                    className="m-0 d-flex cursor-pointer justify-content-center align-items-center link"
                                  >
                                    {" "}
                                    <img
                                      src={GrAttachment_}
                                      height="27px"
                                    />{" "}
                                  </label>
                                </div>
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert top-cred alert-danger top-11 mb-3"
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </Field>
                    ) : (
                      <Field name={"covidrecord"}>
                        {({ input: { onChange, ...input }, meta }) => (
                          <>
                            <div
                              className={`file-upload-wrapper ${
                                values?.iscovidrecord
                                  ? "  click_pin_icon"
                                  : " click_pin_icon"
                              }
                              ${
                                credentialRejectHandle(
                                  `covidrecord`,
                                  !input.value
                                    ? values?.covidrecordname
                                    : input.value
                                )
                                  ? "rejected_text_div"
                                  : ""
                              }`}
                              data-text={
                                values.covidrecord
                                  ? values.covidrecord.name
                                  : values?.covidrecordname
                                  ? values?.covidrecordname
                                  : "Upload"
                              }
                            >
                              <div>
                                <div className="d-flex">
                                  <input
                                    className="input-group border-0 w-100 cred-input cursor-pointer file-upload-field"
                                    {...input}
                                    type="file"
                                    id="fileUpload-covidrecord"
                                    onClick={(e) => {
                                      checkUpload(
                                        e,
                                        values,
                                        "covidrecordfile",
                                        "covidrecord"
                                      );
                                    }}
                                    onChange={(e) => {
                                      onChange(e);
                                      handleChange(e, "covidrecord", values);
                                    }}
                                    disabled={
                                      values && values?.iscovidrecord
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    onClick={() =>
                                      setTestUpload("covidrecordfile")
                                    }
                                    htmlFor="fileUpload-covidrecord"
                                    style={{
                                      backgroundColor: "#0f7ad4",
                                      zIndex: "2",
                                      width: "54px",
                                      borderRadius: "3px",
                                    }}
                                    className="cursor-pointer m-0 d-flex justify-content-center align-items-center link"
                                  >
                                    {" "}
                                    <img
                                      src={GrAttachment_}
                                      height="27px"
                                    />{" "}
                                  </label>
                                </div>
                                {submitFailed && meta.error && meta.touched && (
                                  <p
                                    className="alert top-cred alert-danger top-11 mb-3"
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </Field>
                    )}
                    <div className="cred-date-input mobile_width-990"></div>
                  </div>

                  <label className="container-card mt-4 align-items-center d-flex padding-left-ref ref-height my-3 ">
                    <Field name={`iscovidrecord`} type="checkbox">
                      {({ input }) => (
                        <input
                          {...input}
                          onChange={(e) => {
                            input.onChange(e);
                            handleDocument(e, values, "covidrecord", form);
                          }}
                        />
                      )}
                    </Field>
                    <span className="checkmark1"></span>
                    <div className="ml-3">
                      I have not received a Covid-19 Vaccination.
                      {/* for the current Flu Season */}
                    </div>
                  </label>

                  {values.iscovidrecord && (
                    <div className="d-flex gap-3 my-3">
                      <button
                        className="Approve-btns"
                        onClick={(e) => {
                          e.preventDefault();
                          handleOnboardingDocs(onboardingFormTypes.covidrecord);
                        }}
                      >
                        {isUploaded("covidrecord")
                          ? "Review Form"
                          : "Sign Declination"}
                      </button>
                    </div>
                  )}
                </div>

                <div
                  className={`${
                    props?.isProfile
                      ? "  col-xxl-6 col-xl-6"
                      : "col-xxl-4 col-xl-5"
                  }`}
                >
                  {props?.isProfile && (
                    <>
                      <h4
                        style={{ marginBottom: "20px" }}
                        className="cred-p ml-0 mt-3"
                      >
                        Competency Assessments and Compliance Documents
                      </h4>
                      <p className="w9 mt-1 "> Competency Assessments</p>
                      <div className="d-flex gap-3 mobile_wrap w-100">
                        {PrefillDetails &&
                          PrefillDetails.onboardingPdf &&
                          PrefillDetails.onboardingPdf.length > 0 && (
                            <div className="d-flex gap-2">
                              <p className="p-2 ">
                                {getCredentialsDetails("competencies")?.file}
                              </p>
                              <button
                                className="coverage-btn"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleDocumentShow(
                                    userCredentials.competencies
                                  );
                                }}
                              >
                                View
                              </button>
                            </div>
                          )}
                      </div>

                      <p className="w9 mt-3">Compliance Documents</p>
                      <div className="d-flex gap-3 mobile_wrap w-100">
                        {PrefillDetails &&
                          PrefillDetails.onboardingPdf &&
                          PrefillDetails.onboardingPdf.length > 0 && (
                            <div className="d-flex gap-2">
                              <p className="p-2">
                                {getCredentialsDetails("compliance")?.file}
                              </p>
                              <button
                                className="coverage-btn"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleDocumentShow(
                                    userCredentials.compliance
                                  );
                                }}
                              >
                                View
                              </button>
                            </div>
                          )}
                      </div>
                    </>
                  )}
                  <h4
                    className={`fileUpload mb-2 cred-lbl cred-p mt-3 ${
                      props?.isProfile ? "thera-login-margin" : " pt-66"
                    }`}
                  >
                    Business Credentials
                  </h4>
                  <div style={{ width: "50%" }} className="mobile_width">
                    {credentialRejectHandle(`w9`)}
                    <h3
                      className="w9 mt-3 "
                      style={{ marginBottom: `${!props.isProfile && "16px"}` }}
                    >
                      {" "}
                      w9{" "}
                    </h3>
                    <button
                      className={`signed_w9 d-flex btn-heights justify-content-center align-items-center cred-input cursor-pointer  cred-sign mb-3  mt-0 m-0 ${
                        !props.isProfile ? "WidthCred-BTN" : "w-100"
                      }    ${
                        credentialRejectHandle(`w9`) && "rejected_text_div"
                      }`}
                      type="button"
                      onClick={handleShow}
                    >
                      {values && values?.w9file
                        ? `Signed`
                        : isUploaded("w9")
                        ? "Signed"
                        : "Click to Sign"}
                    </button>
                    <MyModal2
                      size="lg"
                      data={
                        <TaxForm
                          handleClose={() => setShow(false)}
                          values={values}
                          handleChange={handleChange}
                          uploadedFileBytes={uploadedFileBytes}
                          taxFormValues={taxFormValues}
                          setUploadedFileBytes={setUploadedFileBytes}
                          setTaxFormValues={setTaxFormValues}
                        />
                      }
                      show={show}
                      handleClose={handleClose}
                      modalClass={"formModal"}
                    />
                    {submitFailed && errors[credentials[11]] && (
                      <p
                        className="alert alert-danger w-100 top-11"
                        role="alert"
                      >
                        {errors[credentials[11]]}
                      </p>
                    )}
                  </div>

                  <p
                    className={`w9  ${
                      !props?.isProfile ? "Credential-margin" : "mt-3"
                    }`}
                  >
                    Business License
                  </p>
                  <div className="d-flex gap-3 mobile_wrap w-100">
                    <Field name={"businesslicense"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <>
                          <div
                            className={
                              credentialRejectHandle(
                                `businesslicense`,
                                !input.value
                                  ? values?.businesslicensename
                                  : input.value
                              )
                                ? "file-upload-wrapper file_cursor rejected_text_div click_pin_icon"
                                : "file-upload-wrapper file_cursor click_pin_icon"
                            }
                            data-text={
                              values.businesslicense
                                ? values.businesslicense.name
                                : values?.businesslicensename
                                ? values?.businesslicensename
                                : "Upload"
                            }
                          >
                            <div>
                              <div className="d-flex">
                                <input
                                  className="input-group cred-input cursor-pointer file-upload-field"
                                  {...input}
                                  type="file"
                                  id="fileUpload-businesslicense"
                                  onClick={(e) => {
                                    checkUpload(
                                      e,
                                      values,
                                      "businesslicensefile",
                                      "businesslicense"
                                    );
                                  }}
                                  onChange={(e) => {
                                    onChange(e);
                                    handleChange(
                                      e,
                                      "businesslicense",
                                      values,
                                      form
                                    );
                                  }}
                                />
                                <label
                                  onClick={() =>
                                    setTestUpload("businesslicensefile")
                                  }
                                  htmlFor="fileUpload-businesslicense"
                                  className="cursor-pointer m-0 d-flex justify-content-center align-items-center link"
                                >
                                  {" "}
                                  <img src={GrAttachment_} height="27px" />{" "}
                                </label>
                              </div>
                              {meta.error && meta.touched && (
                                <p
                                  className="alert alert-danger top-11"
                                  role="alert"
                                >
                                  {" "}
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </Field>
                    <Field name={"businesslicenseExpiration"}>
                      {({ input: { onChange, ...input }, meta }) => (
                        <div className="cred-date-input mobile_width-990">
                          <ExpirationDateInput
                            input={input}
                            onChange={onChange}
                            typename="businesslicense"
                            values={values}
                            form={form}
                          />
                          {meta.error && meta.touched && (
                            <p
                              className={`alert alert-danger top-11 top-cred ${
                                props?.isProfile ? " mobile-75" : ""
                              }`}
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 d-flex gap-3 mb-5">
                  <button className="coverage-btn" type="submit">
                    {" "}
                    Save & Continue{" "}
                  </button>
                  <button
                    className="ad-btn-back"
                    type="button"
                    onClick={() =>
                      props.goBackFunction(therapistSignupSteps[4])
                    }
                  >
                    {" "}
                    Back{" "}
                  </button>
                </div>
              </div>
            </div>
          )}
          {onboardingDocType && (
            <MyModal2
              size="lg"
              data={
                <OnboardingForms
                  uploadedFileBytes={uploadedFileBytes}
                  setUploadedFileBytes={setUploadedFileBytes}
                  form={form}
                  handleClose={() => {
                    handleOnboardingDocs(null);
                  }}
                  onboardingDocType={onboardingDocType}
                  handleChange={handleChange}
                  values={values}
                />
              }
              show={onboardingDocType}
              handleClose={() => {
                handleOnboardingDocs(null);
              }}
              modalClass={"formModal"}
            />
          )}
          {addNewCred && (
            <MyModal2
              size="lg"
              show={addNewCred}
              handleClose={() => setAddNewCred(false)}
              modalClass={"addNewCred"}
              centered={true}
              data={
                <AddNewCredentials
                  setTabUpdateCheker={props.setTabUpdateCheker}
                  handleClose={() => setAddNewCred(false)}
                  inputPropsForAddNewTest={inputPropsForAddNewTest}
                />
              }
            />
          )}
        </form>
      )}
    />
  );
};

export default TherapistCredentials;
