import React from "react";
import { dateFormatChange } from "../../utils/helper";

const ContractorServiceAgreement = (props) => {
  let { formData } = props;

  console.log(formData);
  return (
    <>
      <div className="business-therapy-main">
        <div className="container text-justify">
          <div class="Connexion_docm text-center my-5">
            <h1 class="mb-0">CONTRACTOR-THERAPY SERVICES AGREEMENT </h1>
            <h1 class="mb-0"> for</h1>
            <h1> PATIENT (MR# {formData.staffingDetails?.mrn}) </h1>
          </div>
          This Therapy Services Agreement (hereafter “Agreement”) for Patient
          (MR#{formData.staffingDetails?.mrn}) (hereafter "Patient"), is
          effective {/* <input type="text" value="DATE" /> */}
          <span className="agreement_form">
            {" "}
            {formData &&
              formData.staffInitialVisit &&
              formData.staffInitialVisit.length > 0 &&
              formData.staffInitialVisit[0].anticipatedSocDate &&
              dateFormatChange(
                formData.staffInitialVisit[0].anticipatedSocDate.split("T")[0]
              )}
          </span>
          , by and between{" "}
          {/* <input type="text" value={formData?.createdByUser?.name} /> */}
          <span className="agreement_form">
            {formData?.createdByUser?.name}
          </span>{" "}
          (hereafter "Agency"), with an address at{" "}
          {/* <input type="text" value="(Agency Address)" /> */}
          <span className="agreement_form">
            {formData &&
            formData.createdByUser &&
            formData.createdByUser.userDetail ? (
              <>
                {formData.createdByUser.userDetail["address"]
                  ? formData.createdByUser.userDetail["address"]
                  : ""}

                {formData.createdByUser.userDetail["city"]
                  ? formData.createdByUser.userDetail["city"]
                  : ""}
                {", "}
                {formData.createdByUser.userDetail["state"]
                  ? formData.createdByUser.userDetail["state"]
                  : ""}
                {", "}
                {formData.createdByUser.userDetail["zip"]
                  ? formData.createdByUser.userDetail["zip"]
                  : ""}
              </>
            ) : (
              ""
            )}
          </span>{" "}
          and{" "}
          <span className="agreement_form">
            {formData && formData.firstName && formData.lastName
              ? `${formData.firstName}, ${formData.lastName}`
              : ""}
          </span>{" "}
          (hereafter "Therapist"), with an address at{" "}
          <span className="agreement_form">
            {formData && formData.userDetail ? (
              <>
                {formData.userDetail["address"]
                  ? formData.userDetail["address"]
                  : ""}

                {formData.userDetail["city"] ? formData.userDetail["city"] : ""}
                {", "}
                {formData.userDetail["state"]
                  ? formData.userDetail["state"]
                  : ""}
                {", "}
                {formData.userDetail["zip"] ? formData.userDetail["zip"] : ""}
              </>
            ) : (
              ""
            )}
          </span>{" "}
          , which provides rehabilitative physical, occupational, or speech
          therapy services.
          <p className="mt-3">
            For and in consideration of the mutual covenants contained in this
            Agreement, the parties agree as follows:
          </p>
          <ol className="pl-0 list-unstyled">
            <li className="mb-3">
              <span className="font-weight-bold therapy_services_list">
                1.{" "}
              </span>
              <strong>Therapy Services: </strong>Therapist shall provide
              occupational, physical or speech therapy services (hereafter
              referred to as "therapy services"). Therapist shall perform
              therapy services within their scope of practice as mandated by
              their state licensing board.
            </li>
            <li className="mb-3">
              <span className="font-weight-bold therapy_services_list">
                2.{" "}
              </span>
              <strong>Qualifications of Therapist's Employees : </strong>
              Therapist shall:
              <ol type="a" className="list_alpha_space">
                <div className="d-flex">
                  <span class="pt-3 font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>{" "}
                  <li className="py-3  list-unstyled">
                    be licensed, certified, or otherwise qualified in the state
                    where therapy services will be provided and will remain so
                    licensed, certified, and qualified throughout the term of
                    this Agreement.
                  </li>
                </div>
                <li className="list-unstyled">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>
                  be qualified under the applicable requirements contained in
                  the state and federal regulations governing the Medicaid and
                  Medicare programs.
                </li>
                <li className="py-3 list-unstyled">
                  <span class="font-weight-bold therapy_services_list">
                    c.{" "}
                  </span>
                  carry the required business license or business tax
                  registration (if required by their jurisdiction).
                </li>
                <li className="list-unstyled">
                  <span class="font-weight-bold therapy_services_list">
                    d.{" "}
                  </span>
                  carry a minimum of $1,000,000 in malpractice/liability
                  coverage and will furnish Agency with a copy of the policy,
                  which must be kept current.
                </li>
              </ol>
            </li>

            <li className="mt-5">
              <span class="font-weight-bold therapy_services_list">3. </span>
              <strong>Plan of Care; Records:</strong>
              <ol type="a" className="list_alpha_space">
                <div className="d-flex">
                  {" "}
                  <span class="font-weight-bold therapy_services_list pt-3">
                    a.{" "}
                  </span>
                  <li className="py-3  list-unstyled">
                    Services will be provided to the Patient only in accordance
                    with a Plan of Care established by a physician licensed to
                    practice in the state where therapy services will be
                    provided. Any changes in the Plan of Care shall be subject
                    to the approval of the Patient’s physician.
                  </li>
                </div>
                <li className="py-3  list-unstyled">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>
                  Therapist shall maintain all patient records and reports in
                  accordance with federal and state regulatory requirements.
                </li>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    c.{" "}
                  </span>
                  <li className="py-3  list-unstyled">
                    Therapist who provides therapy services shall write clinical
                    and progress notes, and place such notes in the Patients'
                    records within a reasonable time after services are
                    rendered.
                  </li>
                </div>
                <li className="py-3  list-unstyled">
                  <span class="font-weight-bold therapy_services_list">
                    d.{" "}
                  </span>
                  Therapist agrees that they shall assist Agency and the
                  Patient's physician in developing plans of treatment for the
                  Patient.
                </li>

                <li className="py-3  list-unstyled">
                  <span class="font-weight-bold therapy_services_list">
                    e. <strong className="pl-2"> Equipment: </strong>
                  </span>{" "}
                  Therapist agrees that they shall assist Agency and the
                  Patient's physician in developing plans of treatment for the
                  Patient.
                </li>
              </ol>
            </li>

            <li className="py-4">
              <span class="font-weight-bold therapy_services_list">4. </span>
              <strong>Independent Contractor Relationship: </strong>
              Therapist and Agency agree that Therapist is:
              <ol type="a" className="list_alpha_space">
                <div className="d-flex">
                  <span class="pt-3 font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>{" "}
                  <li className="py-3  list-unstyled">
                    not creating an employer-employee relationship with Agency –
                    and that both parties agree that the relationship is
                    strictly that of an independent contractor.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>{" "}
                  <li className="list-unstyled">
                    solely responsible for unemployment compensation
                    contributions and all benefits and other payroll tax
                    matters.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="pt-3 font-weight-bold therapy_services_list">
                    c.
                  </span>{" "}
                  <li className="py-3 list-unstyled">
                    not covered under Agency's workers' compensation insurance
                    and is not entitled to any fringe benefits otherwise
                    afforded to employees of Agency.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list">d.</span>{" "}
                  <li className="list-unstyled">
                    responsible for payment of all contributions for Social
                    Security, withholding taxes and any other applicable
                    federal, state, or local tax or charge.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    e.
                  </span>{" "}
                  <li className="py-3 list-unstyled">
                    responsible for all expenses related to providing therapy
                    services including but not limited to equipment, travel,
                    gas, meal, and others deemed necessary.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list">f.</span>{" "}
                  <li className="list-unstyled">
                    able to contract with other businesses to provide the same
                    or similar services and maintain a clientele without
                    restrictions from the Agency.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    g.
                  </span>{" "}
                  <li className="list-unstyled py-3">
                    able to advertise and hold themselves out to the public as
                    available to provide the same or similar services.
                  </li>
                </div>
              </ol>
            </li>

            <li className="py-4">
              <span class="font-weight-bold therapy_services_list">5. </span>
              <strong>Relationship of Parties:</strong>
              <ol type="a" className="list_alpha_space">
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    a.
                  </span>
                  <li className="list-unstyled py-3">
                    <p>
                      <span class="Business_text">Business Relationship. </span>{" "}
                      Therapist acknowledges and agrees that use of The Care
                      Connexion Platform creates a direct business relationship
                      between Therapist and Agency. Therapist hereby
                      acknowledges and agrees that this Agreement is not an
                      employment agreement, nor does it create an employment
                      relationship, between Agency and Therapist.
                    </p>
                    <p></p>
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list">b.</span>
                  <li className="list-unstyled">
                    <p>
                      <span class="Business_text">
                        Authorized Representative
                      </span>{" "}
                      Therapist hereby acknowledges and agrees that Therapist
                      has no authority to bind Agency, and Therapist shall not
                      hold themself out as an employee or authorized
                      representative of Agency. Notwithstanding anything to the
                      contrary in this Agreement, where, by implication of
                      mandatory law or otherwise, Therapist may be deemed a
                      representative of Agency, Therapist undertakes and agrees
                      to indemnify, defend (at Agency option) and hold Agency
                      harmless from and against any claims against or incurred
                      by Agency that are brought against Agency by any person or
                      entity based on such implied representative relationship.
                    </p>
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    c.
                  </span>
                  <li className="list-unstyled py-3">
                    <p>
                      <span class="Business_text">Direction; Control</span>{" "}
                      Therapist acknowledges and agrees that, in accordance with
                      this Agreement, Therapist may independently determine the
                      jobs that Therapist is willing to accept. Agency does not,
                      and shall not be deemed to, direct or control Therapist
                      generally or in the fulfillment of any therapy services,
                      including in connection with the provision of therapy
                      services, the acts or omissions during, or operation
                      and/or maintenance of any equipment or property used by
                      Therapist. Without limitation of the generality of the
                      foregoing, Therapist acknowledges and agrees that Agency
                      does not have the right or authority to direct or control
                      what actions Therapist performs or how Therapist performs
                      such actions in providing therapy services. Therapist and
                      Agency acknowledge and agree that the business
                      relationship between such parties under this Agreement is
                      a temporary, and not permanent, relationship.
                    </p>
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    d.
                  </span>
                  <li className="list-unstyled py-3">
                    <p>
                      <span class="Business_text">
                        Use of the Platform and Services.
                      </span>{" "}
                      Unless such right is earlier terminated, deactivated, or
                      restricted by The Care Connexion Platform, Therapist
                      retains the sole right to determine when, where, and for
                      how long Therapist will utilize the The Care Connexion
                      Platform. Therapist retains the option, via the The Care
                      Connexion Platform, to attempt to request, bid on, accept,
                      decline, and/or ignore an Agency request for therapy
                      services, or to cancel an accepted or approved job in
                      accordance with this Agreement.
                    </p>
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    e.
                  </span>
                  <li className="list-unstyled py-3">
                    <p>
                      <span class="Business_text">Non-Exclusive.</span>{" "}
                      Therapist acknowledges and agrees that, except as
                      expressly prohibited pursuant to this Agreement or
                      otherwise prohibited by applicable law, Therapist has
                      complete discretion to provide therapy services or
                      otherwise engage in other business or employment
                      activities other than the fulfillment of duties in this
                      Agreement. For the sake of clarity, Therapist understands
                      that, except as expressly prohibited pursuant to this
                      Agreement or otherwise prohibited by applicable law,
                      Therapist retains the complete right to; (i) use other
                      software application services related to lead-generation
                      for the provision of therapy services in addition to The
                      Care Connexion Platform; and (ii) engage in any other
                      occupation or business other than being a provider of
                      therapy services.
                    </p>
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    f.
                  </span>
                  <li className="list-unstyled py-3">
                    <p>
                      <span class="Business_text">Branding; Equipment.</span>{" "}
                      With the exception of any signage required by applicable
                      law, regulatory authority, requirements related to
                      Therapist Credentials, Agency shall have no right to
                      require Therapist to: (a) display Agency’s respective
                      names, logos or colors on any of Therapist equipment or
                      property; or (b) wear a uniform or any other clothing
                      displaying Agency’s respective names, logos or colors.
                      Therapist hereby acknowledges and agrees that Agency will
                      not be providing any equipment or training that may be
                      necessary to fulfill duties set forth in this Agreement.
                    </p>
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    g.
                  </span>
                  <li className="list-unstyled py-3">
                    <p>
                      <span class="Business_text">Control</span> Therapist
                      acknowledges and agrees that in providing therapy
                      services, Agency has no authority or control over the
                      therapy services Therapist provides including the quality
                      and method of providing such services nor the day-to-day
                      oversight or supervision of Therapist providing such
                      therapy services. Therapist further acknowledges and
                      agrees that Agency will not provide any tools of the trade
                      or other materials in order for Therapist to fulfill
                      duties set forth in this Agreement.
                    </p>
                  </li>
                </div>
              </ol>
            </li>
            <li>
              <span class="font-weight-bold therapy_services_list">6. </span>
              <strong>Non-Discrimination: </strong>
              Neither party will discriminate or permit discrimination against
              any person or group of persons on the grounds of race, color, sex,
              age, religion, national origin, or otherwise in any manner
              prohibited by the laws of the United States or of the state where
              therapy services will be provided.
            </li>
            <li>
              <span class="font-weight-bold therapy_services_list py-3">
                7.{" "}
              </span>
              <strong>Confidentiality:</strong>

              <ol type="a" className="list_alpha_space">
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    a.
                  </span>
                  <li className="list-unstyled py-3">
                    <p>
                      <span className="Business_text">
                        Confidential Information.
                      </span>{" "}
                      Therapist hereby acknowledges and agrees that in the
                      performance of this Agreement, Therapist may have access
                      to or may be exposed to, directly or indirectly,
                      confidential information of The Care Connexion Platform,
                      Agency, or related third parties ("Confidential
                      Information"). Confidential Information includes The Care
                      Connexion Platform Data, Agency Information, and the
                      transaction volume, marketing and business plans,
                      business, financial, technical, operational and such other
                      non-public information of each party (whether disclosed in
                      writing or verbally) that has been designated as being
                      proprietary or confidential by the disclosing party or
                      owner of such information or of which Therapist should
                      reasonably know that it should be treated as confidential.
                    </p>
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    b.
                  </span>
                  <li className="list-unstyled py-3">
                    <p>
                      <span className="Business_text">
                        Ownership of Confidential Information.
                      </span>{" "}
                      Therapist hereby acknowledges and agrees that, except as
                      otherwise provided in this Agreement,: (a) all
                      Confidential Information shall remain the exclusive
                      property of the disclosing party; (b) Therapist shall not
                      use Confidential Information for any purpose except in
                      furtherance of this Agreement; (c) Therapist shall not
                      disclose Confidential Information to any third party; and
                      (d) Therapist shall return or destroy all Confidential
                      Information, upon the termination of Agreement or at the
                      request of the disclosing party (subject to applicable law
                      and, with respect to Agency, its internal record-keeping
                      requirements).
                    </p>
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    c.
                  </span>
                  <li className="list-unstyled py-3">
                    <p>
                      <span className="Business_text">Exclusions.</span>{" "}
                      Notwithstanding the foregoing, Confidential Information
                      shall not include any information to the extent it: (a) is
                      or becomes part of the public domain through no act or
                      omission on the part of the receiving party; (b) was
                      possessed by the receiving party prior to the date of this
                      Agreement without an obligation of confidentiality; (c) is
                      disclosed to the receiving party by a third party having
                      no obligation of confidentiality with respect thereto; or
                      (d) is required to be disclosed pursuant to law, court
                      order, subpoena or governmental authority, provided the
                      receiving party notifies the disclosing party thereof and
                      provides the disclosing party a reasonable opportunity to
                      contest or limit such required disclosure at disclosing
                      party’s sole cost.
                    </p>
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    d.
                  </span>
                  <li className="list-unstyled py-3">
                    <p>
                      <span className="Business_text">
                        Protected Health Information.{" "}
                      </span>{" "}
                      Notwithstanding anything to the contrary herein, Therapist
                      hereby agrees and acknowledges that Therapist will comply
                      with all applicable laws regarding and related to the
                      handling of, access to, and dissemination and destruction
                      of protected health information (“PHI”), as that term is
                      defined under applicable law, including the Health
                      Insurance Portability and Accountability Act of 1996
                      (“HIPAA”), that Therapist may gain access to as a result
                      of providing therapy services under this Agreement.
                      Notwithstanding anything to the contrary herein, Therapist
                      hereby agrees and acknowledges that Therapist shall not
                      upload to any Platform or otherwise provide to The Care
                      Connexion Platform or Agency any PHI that would violate
                      applicable law, including HIPAA.{" "}
                    </p>
                  </li>
                </div>
              </ol>
            </li>
            <li>
              <span class="font-weight-bold therapy_services_list">8. </span>
              <strong> Fees:</strong> Therapist agrees to provide the therapy
              services described in this Agreement in exchange for payment of
              fees and charges that were negotiated and agreed upon by both
              Agency and Therapist, set forth in Addendum "A", attached and
              incorporated by reference. Therapist will maintain clinical
              records, time records, and such other documentation as is
              necessary to substantiate any of the fees charged to Agency.
            </li>
            <li className="py-3">
              <span class="font-weight-bold therapy_services_list">9. </span>
              <strong>Invoices; Payment: </strong>

              <ol type="a" className="list_alpha_space">
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    a.
                  </span>
                  <li className="list-unstyled py-3">
                    Through The Care Connexion platform, Therapist will invoice
                    Agency 2x per month as follows:
                    <ol type="i">
                      <li>
                        On the 16th of the same month for visits performed on
                        the 1st-15th of the current month
                      </li>
                      <li>
                        On the 1st of the month for visits performed on the
                        16th-end of the previous month
                      </li>
                    </ol>
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    b.
                  </span>
                  <li className="list-unstyled py-3">
                    Therapist shall bill Agency only for the services rendered
                    under this Agreement, which are actually provided, itemizing
                    such services by individual patient, and the date of each
                    service. Agency shall have the right to bill and collect for
                    the services rendered pursuant to this Agreement.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    c.
                  </span>
                  <li className="list-unstyled py-3">
                    Agency shall pay Therapist for services rendered as follows:
                    <ol type="i">
                      <li>
                        By the 26th of the same month for the Invoice sent out
                        on the 16th of the current month
                      </li>
                      <li>
                        By the 10th of the same month for the Invoice sent out
                        on the 1st of the current month
                      </li>
                    </ol>
                  </li>
                </div>
              </ol>
            </li>
            <li>
              <span class="font-weight-bold therapy_services_list py-3">
                10.{" "}
              </span>
              <strong>Term: </strong>
              This Agreement will be for the duration of the applicable Plan of
              Care for Patient as set forth in Paragraph 3.
            </li>
            <li>
              <span class="font-weight-bold therapy_services_list py-3">
                11.{" "}
              </span>
              <strong> Termination: </strong>
              This Agreement may be terminated by either party with a 30 day
              written notice.
            </li>

            <li>
              <span class="font-weight-bold therapy_services_list py-3 pb-sm-0">
                12.{" "}
              </span>
              <strong>Effect of Termination: </strong>
              Upon termination of this Agreement, neither party shall have any
              further obligation to the other except for obligations accruing
              prior to the date of termination.
            </li>

            <li>
              <span class="font-weight-bold therapy_services_list py-3 pb-sm-0">
                13.{" "}
              </span>
              <strong>Indemnification Clause: </strong>
              Each party shall indemnify and hold harmless the other party and
              its owners, agents, operators, managers and employees, from all
              loss, liability, expense or damage (including, without limitation,
              legal fees and costs of litigation) incurred by the foregoing,
              related to or arising from any acts or omissions of the
              indemnifying party.
            </li>
            <li>
              <span class="font-weight-bold therapy_services_list py-3 pb-sm-0">
                14.{" "}
              </span>
              <strong>Governing Law: </strong>
              This Agreement shall be executed and delivered in, and shall be
              interpreted and enforced pursuant to, and in accordance with the
              laws of the state of California.
            </li>
            <li>
              <span class="font-weight-bold therapy_services_list py-3 pb-sm-0">
                15.{" "}
              </span>
              <strong>Entire Agreement; Counterparts; Amendment: </strong>

              <ol type="a" className="list_alpha_space">
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    a.
                  </span>
                  <li className="list-unstyled py-3">
                    This Agreement supersedes all existing agreements,
                    counterparts and understandings previously existing between
                    the parties, and constitutes the entire Agreement between
                    the parties respecting the subject matter herein contained.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    b.
                  </span>
                  <li className="list-unstyled py-3">
                    The parties specifically acknowledge that in entering into
                    and executing this Agreement, the parties are relying solely
                    upon the representations and agreements contained in this
                    Agreement. All prior representations or agreements, whether
                    written or oral, not expressly incorporated herein are
                    superseded by this agreement.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    c.
                  </span>
                  <li className="list-unstyled py-3">
                    This Agreement may be executed by the parties in one or more
                    counterparts, which shall in the aggregate be written and
                    signed, by the parties, and each counterpart shall be deemed
                    an original instrument.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    d.
                  </span>

                  <li className="list-unstyled py-3">
                    The parties agree that no amendments, modifications or
                    additions to this agreement shall be recognized unless and
                    until made in writing and signed by both parties hereto.
                  </li>
                </div>
              </ol>
            </li>
            <li>
              <span class="font-weight-bold therapy_services_list py-3 pb-sm-0">
                16.{" "}
              </span>
              <strong>Miscellaneous: </strong>

              <ol type="a" className="list_alpha_space">
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    a.
                  </span>
                  <li className="list-unstyled py-3">
                    <strong>Separability of Terms:</strong>If any provision of
                    this Agreement shall be deemed to be invalid or
                    unenforceable by a court of appropriate jurisdiction, then
                    such unenforceable or invalid provision shall be deemed to
                    be deleted from this Agreement. All remaining provisions of
                    this Agreement shall be deemed to be in full force and
                    effect.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    b.
                  </span>
                  <li className="list-unstyled py-3">
                    <strong>Notice: </strong>Any notice required or permitted to
                    be given to the parties pursuant to the terms of this
                    Agreement shall be in writing or deemed to have been duly
                    given if delivered in person, or if mailed, by United States
                    certified mail, return receipt requested, to the address of
                    the receiving party.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    c.
                  </span>

                  <li className="list-unstyled py-3">
                    <strong>Successors and Assigns: </strong>This Agreement
                    shall be binding upon and inure to the benefit of the
                    parties hereto and their successors and permitted assigns.
                  </li>
                </div>

                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    d.
                  </span>

                  <li className="list-unstyled py-3">
                    <strong>Patient Choice: </strong>Nothing contained herein is
                    intended to limit a patient's right to choose a health
                    provider.
                  </li>
                </div>
              </ol>
            </li>
            <li>
              <span class="font-weight-bold therapy_services_list py-3 pb-sm-0">
                17.{" "}
              </span>
              <strong>Denial of Payment by Reimbursement Sources. </strong>
              Agency shall compensate Therapist for each service rendered to
              Patient notwithstanding any claim for which payment is refused by
              a governmental or other third-party reimbursement source.
            </li>
            <li>
              <span class="font-weight-bold therapy_services_list py-3 pb-sm-0">
                18.{" "}
              </span>
              <strong> Force Majeure. </strong>
              The failure of Agency or Therapist to comply with this Agreement
              because of an act of God, war, fire, riot, terrorism, pandemic,
              earthquake, actions of federal, state or local governmental
              authorities or for any other reason beyond the reasonable control
              of Agency or Therapist, shall not be deemed a breach of this
              Agreement. If Agency or Therapist fails to act with respect to the
              breach or anyone else’s breach on any occasion, Agency or
              Therapist is not waiving its right to act with respect to future
              or similar breaches.
            </li>
            <li>
              <span class="font-weight-bold therapy_services_list py-3 pb-sm-0">
                19.{" "}
              </span>
              <strong> Arbitration of Disputes.</strong>
              <ol type="a">
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    a.
                  </span>
                  <li className="list-unstyled pt-3">
                    THERAPIST AND AGENCY RESPECTIVELY AGREE TO WAIVE THE RIGHT
                    TO SEEK REMEDIES IN COURT, INCLUDING ANY RIGHT TO A JURY
                    TRIAL. THERAPIST AND AGENCY AGREE THAT ANY DISPUTE BETWEEN
                    OR AMONG THEM OR THEIR SUBSIDIARIES, AFFILIATES OR RELATED
                    ENTITIES ARISING OUT OF, RELATING TO OR IN CONNECTION WITH
                    THIS AGREEMENT, WILL BE RESOLVED IN ACCORDANCE WITH A
                    TWO-STEP DISPUTE RESOLUTION PROCEDURE INVOLVING: (1) STEP
                    ONE: NON-BINDING MEDIATION, AND (2) STEP TWO: BINDING
                    ARBITRATION UNDER THE FEDERAL ARBITRATION ACT, 9 U.S.C.
                    SECTION 1 ET. SEQ., OR STATE LAW, WHICHEVER IS APPLICABLE.
                    ANY SUCH MEDIATION OR ARBITRATION HEREUNDER SHALL BE
                    CONDUCTED IN ANY FORUM AND FORM AGREED UPON BY THE PARTIES
                    OR, IN THE ABSENCE OF SUCH AN AGREEMENT, UNDER THE AUSPICES
                    OF THE JAMS (F/K/A THE JUDICIAL ARBITRATION AND MEDIATION
                    SERVICE) (“JAMS”) PURSUANT TO THE THEN CURRENT COMPREHENSIVE
                    ARBITRATION RULES & PROCEDURES (A COPY OF WHICH IS AVAILABLE
                    THROUGH JAMS’ WEBSITE, WWW.JAMSADR.ORG) (THE “JAMS RULES”).
                    THERAPIST MAY ALSO CALL JAMS AT 1-800-352-5267 IF THERE ARE
                    ANY QUESTIONS CONCERNING THE ARBITRATION PROCESS.
                    NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THE JAMS RULES,
                    THE MEDIATION PROCESS (STEP ONE) MAY BE ENDED BY EITHER
                    PARTY TO THE DISPUTE UPON NOTICE TO THE OTHER PARTY THAT IT
                    DESIRES TO TERMINATE THE MEDIATION AND PROCEED TO THE STEP
                    TWO ARBITRATION; PROVIDED, HOWEVER, THAT NEITHER PARTY MAY
                    SO TERMINATE THE MEDIATION PROCESS PRIOR TO THE OCCURRENCE
                    OF AT LEAST ONE (1) MEDIATION SESSION WITH THE MEDIATOR. NO
                    ARBITRATION SHALL BE INITIATED OR TAKE PLACE WITH RESPECT TO
                    A GIVEN DISPUTE IF THE PARTIES HAVE SUCCESSFULLY ACHIEVED A
                    MUTUALLY AGREED TO RESOLUTION OF THE DISPUTE AS A RESULT OF
                    THE STEP ONE MEDIATION. THE MEDIATION SESSION(S) AND, IF
                    NECESSARY, THE ARBITRATION HEARING SHALL BE HELD IN THE
                    STATE OF CALIFORNIA OR ANY OTHER LOCATION MUTUALLY AGREED BY
                    THE PARTIES. THE ARBITRATION (IF THE DISPUTE IS NOT RESOLVED
                    BY MEDIATION) WILL BE CONDUCTED BY A SINGLE JAMS ARBITRATOR,
                    MUTUALLY SELECTED BY THE PARTIES, AS PROVIDED FOR BY THE
                    JAMS RULES. IF REQUIRED BY LAW, AGENCY WILL BE RESPONSIBLE
                    FOR THE JAMS CHARGES, INCLUDING THE COSTS OF THE MEDIATOR
                    AND ARBITRATOR, OTHERWISE THE PARTIES WILL SHARE SUCH
                    CHARGES EQUALLY. THERAPIST AND AGENCY AGREE THAT THE
                    ARBITRATOR SHALL APPLY THE SUBSTANTIVE LAW OF THE STATE OF
                    CALIFORNIA (WITHOUT GIVING EFFECT TO ANY CHOICE OF LAW OR
                    CONFLICT OF LAW RULES OR PROVISIONS WHETHER OF THE STATE OF
                    CALIFORNIA OR ANY OTHER JURISDICTION THAT WOULD CAUSE THE
                    APPLICATION OF THE LAWS OF ANY JURISDICTION OTHER THAN THE
                    STATE OF CALIFORNIA) TO ALL STATE LAW CLAIMS AND FEDERAL LAW
                    TO ANY FEDERAL LAW CLAIMS, AND THAT DISCOVERY SHALL BE
                    CONDUCTED IN ACCORDANCE WITH THE JAMS RULES OR AS OTHERWISE
                    PERMITTED BY LAW AS DETERMINED BY THE ARBITRATOR.THE
                    ARBITRATOR’S AWARD SHALL CONSIST OF A WRITTEN, REASONED
                    STATEMENT AS TO THE DISPOSITION OF EACH CLAIM AND THE
                    RELIEF, IF ANY, AWARDED ON EACH CLAIM. THERAPIST UNDERSTANDS
                    THAT THE RIGHT TO APPEAL OR TO SEEK MODIFICATION OF ANY
                    RULING OR AWARD BY THE ARBITRATOR IS LIMITED UNDER STATE AND
                    FEDERAL LAW. ANY AWARD RENDERED BY THE ARBITRATOR WILL BE
                    FINAL AND BINDING, AND JUDGMENT MAY BE ENTERED ON IT IN ANY
                    COURT OF COMPETENT JURISDICTION IN CALIFORNIA, AT THE TIME
                    THE AWARD IS RENDERED OR AS OTHERWISE PROVIDED BY LAW.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    b.
                  </span>
                  <li className="list-unstyled pt-3">
                    TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGREEMENT TO
                    ARBITRATE SET FORTH IN THIS SECTION 17 COVERS ALL
                    GRIEVANCES, DISPUTES, CLAIMS OR CAUSES OF ACTION THAT
                    OTHERWISE COULD BE BROUGHT IN A FEDERAL, STATE OR LOCAL
                    COURT OR AGENCY UNDER APPLICABLE FEDERAL, STATE, OR LOCAL
                    LAWS, ARISING OUT OF OR RELATING TO THE BUSINESS
                    RELATIONSHIP WITH AGENCY (OR THE SEPARATION THEREOF),
                    INCLUDING ANY CLAIMS THERAPIST MAY HAVE AGAINST AGENCY OR
                    AGAINST ITS OFFICERS, DIRECTORS, SUPERVISORS, MANAGERS,
                    EMPLOYEES, OR AGENTS IN THEIR CAPACITY AS SUCH OR OTHERWISE.
                    THE CLAIMS COVERED BY THE AGREEMENT TO ARBITRATE IN THIS
                    SECTION 17 INCLUDE CLAIMS FOR BREACH OF ANY CONTRACT OR
                    COVENANT (EXPRESS OR IMPLIED), TORT CLAIMS, CLAIMS FOR
                    COMPENSATION DUE, AND CLAIMS FOR VIOLATION OF ANY FEDERAL,
                    STATE, LOCAL OR OTHER GOVERNMENTAL LAW, STATUTE, REGULATION,
                    OR ORDINANCE.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    c.
                  </span>

                  <li className="list-unstyled pt-3">
                    THERAPIST EXPRESSLY INTEND AND AGREE THAT: (I) CLASS ACTION
                    AND REPRESENTATIVE ACTION PROCEDURES SHALL NOT BE ASSERTED,
                    NOR WILL THEY APPLY, IN ANY ARBITRATION PURSUANT TO THIS
                    AGREEMENT; (II) THERAPIST AND AGENCY WILL NOT ASSERT CLASS
                    ACTION OR REPRESENTATIVE CLAIMS AGAINST THE OTHER IN
                    ARBITRATION OR OTHERWISE; AND (III) THERAPIST AND AGENCY
                    SHALL ONLY SUBMIT THEIR OWN, INDIVIDUAL CLAIMS IN
                    ARBITRATION AND WILL NOT SEEK TO REPRESENT THE INTERESTS OF
                    ANY OTHER PERSON. FURTHER, THERAPIST AND AGENCY EXPRESSLY
                    INTEND AND AGREE THAT ANY CLAIMS BY THERAPIST SHALL NOT BE
                    JOINED, CONSOLIDATED, OR HEARD TOGETHER WITH CLAIMS OF ANY
                    OTHER PERSON.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    d.
                  </span>

                  <li className="list-unstyled pt-3">
                    BY CONSENTING TO THIS AGREEMENT, THERAPIST EXPRESSLY
                    REPRESENT THAT: (I) THERAPIST HAS BEEN GIVEN THE OPPORTUNITY
                    TO FULLY REVIEW AND COMPREHEND THIS AGREEMENT; (II)
                    THERAPIST UNDERSTANDS THE TERMS OF THIS AGREEMENT AND FREELY
                    AND VOLUNTARILY CONSENT TO BE BOUND TO THIS AGREEMENT; AND
                    (III) THERAPIST FULLY UNDERSTANDS AND AGREES THAT THERAPIST
                    IS GIVING UP CERTAIN RIGHTS OTHERWISE AFFORDED TO THERAPIST
                    BY CIVIL COURT ACTIONS, INCLUDING THE RIGHT TO A JURY TRIAL.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    e.
                  </span>

                  <li className="list-unstyled pt-3">
                    NOTHING CONTAINED IN THIS SECTION 17 SHALL RESTRICT EITHER
                    PARTY FROM SEEKING TEMPORARY OR PRELIMINARY INJUNCTIVE
                    RELIEF IN A COURT OF LAW. FURTHER, FOR THE AVOIDANCE OF
                    DOUBT, THE PARTIES AGREE THAT, NOTHING HEREIN, NOR ANYTHING
                    IN THE JAMS RULES, SHALL PERMIT ANY ARBITRATOR TO DETERMINE
                    THE ARBITRABILITY OF ANY APPLICATION FOR TEMPORARY OR
                    PRELIMINARY INJUNCTIVE RELIEF. FINALLY, ANY QUESTIONS
                    REGARDING THE ENFORCEABILITY OF THIS PROVISION SHALL BE
                    WITHIN THE EXCLUSIVE JURISDICTION OF THE ARBITRATOR TO
                    DECIDE.
                  </li>
                </div>
              </ol>
            </li>
          </ol>
          <p>
            <strong>IN WITNESS WHEREOF</strong>, this Agreement has been
            executed by the parties as of the effective date above.
          </p>
          <div className="mt-5">
            <p>
              Agency Name:{" "}
              <span className="agreement_form">
                {formData?.createdByUser?.name}
              </span>
            </p>
            {/* <p> (Agency Representative Electronic Signature via Checkbox)________________________</p> */}
            <p>
              {" "}
              Agency Address:{" "}
              <span className="agreement_form">
                {formData &&
                formData.createdByUser &&
                formData.createdByUser.userDetail ? (
                  <>
                    {formData.createdByUser.userDetail["address"]
                      ? formData.createdByUser.userDetail["address"]
                      : ""}

                    {formData.createdByUser.userDetail["city"]
                      ? formData.createdByUser.userDetail["city"]
                      : ""}
                    {", "}
                    {formData.createdByUser.userDetail["state"]
                      ? formData.createdByUser.userDetail["state"]
                      : ""}
                    {", "}
                    {formData.createdByUser.userDetail["zip"]
                      ? formData.createdByUser.userDetail["zip"]
                      : ""}
                  </>
                ) : (
                  ""
                )}
              </span>
            </p>
            <div className="mt-5">
              <p>
                {" "}
                Therapist First and Last Name:{" "}
                <span className="agreement_form">
                  {formData && formData.firstName && formData.lastName
                    ? `${formData.firstName}, ${formData.lastName}`
                    : ""}
                </span>{" "}
              </p>
              {/* <p>  (Therapist Electronic Signature via Checkbox)_______________</p> */}
              <p>
                {" "}
                Therapist Address:{" "}
                <span className="agreement_form">
                  {formData && formData.userDetail ? (
                    <>
                      {formData.userDetail["address"]
                        ? formData.userDetail["address"]
                        : ""}

                      {formData.userDetail["city"]
                        ? formData.userDetail["city"]
                        : ""}
                      {", "}
                      {formData.userDetail["state"]
                        ? formData.userDetail["state"]
                        : ""}
                      {", "}
                      {formData.userDetail["zip"]
                        ? formData.userDetail["zip"]
                        : ""}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractorServiceAgreement;
