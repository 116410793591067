import axios from "axios";
import { getToken, isAuthenticated } from "../../utils/auth";
import { apiBasePath, backgroundApiBasePath } from "./config";
import CryptoJS from "crypto-js";

const API_URL = apiBasePath;

export const withoutAuth = axios.create({
  baseURL: apiBasePath,
  headers: {
    "Content-Type": "application/json",
  },
});

function logout() {
  localStorage.removeItem("token");
  sessionStorage.removeItem("keepMeSignedInData");
  window.location.href = "/";
}
const onRequest = async (config) => {
  if (!isAuthenticated()) {
    if (getToken()) {
      const storedToken = getToken();
      try {
        const rs = await axios.post(`${API_URL}auth/refresh-token`, {
          token: storedToken,
        });
        if (rs && rs.data) {
          const decrypted = CryptoJS.AES.decrypt(rs.data, "secret key 123");
          let finalresponse = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
          const token = finalresponse.data.token;
          const user = finalresponse.data.user;
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(user));
        }
      } catch (_error) {
        logout();
        return Promise.reject(_error);
      }
    }
  }

  config.headers["Authorization"] = `Bearer ${getToken()}`;

  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  const decrypted = CryptoJS.AES.decrypt(response.data, "secret key 123");
  response.data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  return response;
};

const onResponseError = async (error) => {
  if (error.response) {
    if (
      error.response.status === 401 &&
      error.response.data.message === "jwt expired"
    ) {
      let storedToken
      if (localStorage.getItem("token")) {
        storedToken = JSON.parse(localStorage.getItem("token"));
      } else {
        // storedToken = document.cookie.split("=")[1]
        document.cookie.split(";").map(item => {
          if (item.split("=")[0].trim() === "token") {
            storedToken = item.split("=")[1]
          }
        })
      }
      try {
        const rs = await axios.post(`${API_URL}auth/refresh-token`, {
          token: storedToken,
        });
        if (rs && rs.data && rs.data.data) {
          const token = rs.data.data.token;
          const user = rs.data.data.user;
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(user));
        }
        return;
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
  }
  return Promise.reject(error);
};

const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

export const axiosInstance = setupInterceptorsTo(
  axios.create({
    baseURL: apiBasePath,
    headers: {
      // "Content-Type": "application/json",
    },
  })
);

export const backgroundInstance = axios.create({
  baseURL: backgroundApiBasePath,
  headers: {
    "Content-Type": "application/json",
  },
});
