import React, { useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { savePatientForm } from "../../../Redux/actions/staffing";
import {
  canSubmitForm,
  staffFormTypesConstants,
  visitStatus,
} from "../../../utils/helper";
import { currentRole } from "../../../utils/auth";
import { getStaffFormdataById } from "../../../Redux/actions/patientGetDocTypes";
import SignatureCanvas from "../../../components/common/SignatureCanvas";
import {
  formOptions,
  reactSelectOptionCreate,
} from "../../../utils/common/options";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { getLastGoalsValue } from "../../../Redux/actions/common";

const FollowUpForm = (props) => {
  const {
    closeFormModal,
    visitDetail,
    confirmDate,
    formId,
    dataAvailable,
    IsUpdated,
    toSave,
    isPrint,
    init,
  } = props;
  const visitDetailById = useSelector(
    (data) => data?.patientGetDocTypesSlice?.visitDetail
  );

  const totalDefaultGoals = 8;
  let defaultGoalObj = {
    goal: "",
    effectiveDate: "",
    currentStatus: "",
  };

  const [signatureAdded, setSignatureAdded] = useState(null);
  const [TherapistsignatureError, setTherapistsignatureError] = useState(null);
  const [displaySignature, setDisplaySignature] = useState(null);

  const [isCanvasShow, setIsCanvasShow] = useState(false);

  const dispatch = useDispatch();
  const staffDetails = useSelector((state) => state.staffingSlice.staffDetail);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const staffDocument = useSelector((e) => e.commonSlice.staffDocument);
  const staffFormData = useSelector((e) => e.commonSlice.staffForm);
  const staffFormTypes = useSelector((e) => e.therapistSigunpSlice.masterData);
  const lastGoals = useSelector((e) => e.commonSlice.lastGoals);

  const vital_signs_pulse_data = [
    {
      label: "Apical",
      type: "radio",
      col: 1,
      name_key: "vital_signs_Pulse_type",
    },
    {
      label: "Radial",
      type: "radio",
      col: 1,
      name_key: "vital_signs_Pulse_type",
    },
    {
      label: "Regular",
      type: "radio",
      col: 1,
      name_key: "vital_signs_Pulse_type2",
    },
    {
      label: "Irregular",
      type: "radio",
      col: 1,
      name_key: "vital_signs_Pulse_type2",
    },
  ];
  const vital_signs_bp_data = [
    { label: "Left", type: "radio", col: 2 },
    { label: "Right", type: "radio", col: 2 },
  ];

  const vital_signs_temperature_data = [
    { label: "Fahrenheit", type: "radio", col: 3 },
    { label: "Celsius", type: "radio", col: 3 },
  ];

  const vital_signs_respiration_data = [
    { label: "Regular", type: "radio", col: 4 },
    { label: "Irregular", type: "radio", col: 4 },
  ];

  const patientNotCommunicate_Options_data = [
    { label: "Diaphoresis", type: "checkbox", col: 5, value: "Diaphoresis" },
    { label: "Grimacing", type: "checkbox", col: 5, value: "Grimacing" },
    {
      label: "Moaning/crying",
      type: "checkbox",
      col: 5,
      value: "Moaning/crying",
    },
    { label: "Guarding", type: "checkbox", col: 5, value: "Guarding" },
    { label: "Irritability", type: "checkbox", col: 5, value: "Irritability" },
    { label: "Anger", type: "checkbox", col: 5, value: "Anger" },
    { label: "Tense", type: "checkbox", col: 5, value: "Tense" },
    { label: "Restlessness", type: "checkbox", col: 5, value: "Restlessness" },
    {
      label: "Change in vital signs",
      type: "checkbox",
      col: 5,
      value: "Change in vital signs",
    },
    { label: "Other", type: "checkbox", col: 5, value: "Other" },
  ];
  const scale_Used_data = ["Faces", "Numeric", "Other"];
  const description_data = [
    "Sharp",
    "Dull",
    "Cramping",
    "Aching",
    "Burning",
    "Tingling",
    "Throbbing",
    "Shooting",
    "Pinching",
  ];
  const cause_pain_data = ["Movement", "Time of day", "Posture", "Other"];
  const relieves_pain_data = [
    "Medications",
    "Heat",
    "Ice",
    "Rest",
    "Relaxation",
    "Other",
  ];

  const care_coordination = [
    "Physician",
    "Case Manager",
    "Clinical Manager",
    "SN",
    "PT",
    "PTA",
    "OT",
    "COTA",
    "ST",
    "Aide",
    "MSW",
    "Other",
  ];
  const vitalSignsThreshold = {
    vital_signs_BP_systolic: {
      min: 85,
      max: 160,
    },
    vital_signs_BP_diastolic: {
      min: 60,
      max: 90,
    },
    vital_signs_Pulse: {
      min: 60,
      max: 100,
    },
    vital_signs_Respiration: {
      min: 12,
      max: 20,
    },
    vital_signs_Temperature: {
      min: 96,
      max: 100,
    },
  };

  const vitalSignsAdditionalFields = [
    // "Self_Monitoring_type",
    // "vital_signs_BP_type",
    // "vital_signs_Pulse_type",
    // "vital_signs_Respiration_type",
    // "vital_signs_Temperature_type",

    "vital_signs_BP_systolic",
    "vital_signs_BP_diastolic",
    "vital_signs_Pulse",
    "vital_signs_Respiration",
    "vital_signs_Temperature",
  ];
  const [show, setShow] = useState();

  const vitalSignsValidation = (data) => {
    let val = false;
    Object.keys(data).map((e) => {
      if (vitalSignsAdditionalFields.includes(e)) {
        if (
          data[e] &&
          data[e].length > 0 &&
          (Number(data[e]) < Number(vitalSignsThreshold[e]["min"]) ||
            Number(data[e]) > Number(vitalSignsThreshold[e]["max"]))
        ) {
          val = true;
        }
      }
    });
    return val;
  };

  const onSubmit = (values, form, status) => {
    const { data } = staffFormTypes;
    if (!displaySignature || !values.Therapistsignature) {
      setTherapistsignatureError("Required");
    } else {
      setTherapistsignatureError(null);
    }

    let finalFormValues = {
      ...values,
      Therapistsignature: !displaySignature
        ? values.Therapistsignature
        : displaySignature,
    };
    if (values.painStatus.includes("noPain")) {
      finalFormValues.Pain = [{}];
    }
    if (!vitalSignsValidation(values?.Vital_Signs[0])) {
      delete finalFormValues.autodate;
      delete finalFormValues.vitalreportedAgency;
      delete finalFormValues.vitalreportedMD;
    }
    if (
      finalFormValues &&
      finalFormValues.goals &&
      status &&
      typeof status === "string"
    ) {
      finalFormValues.goals = values.goals.filter((item) => item.goal);
    }
    const apiObject = {
      status: typeof status === "string" ? status : visitStatus.incompletedoc,
      staffId: staffDetails?.id,
      formData: finalFormValues,
      formType: staffFormTypesConstants.FOLLOWUP,
      id: finalFormValues.id,
      episodeDocumentFormId: formId,
      episodeDocumentId: visitDetail?.id,
    };
    // if (!planError) {
    dispatch(savePatientForm(apiObject)).then((res) => {
      if (res.payload.success) {
        closeFormModal();
        IsUpdated();
        if (res.payload.isVisitUpdated)
          props.setToUpdate && props.setToUpdate(true);
        if (
          visitDetailById &&
          visitDetailById.Status &&
          visitDetailById.Status.key === visitStatus.scheduled
        ) {
          // statusHandler(visitStatus.pendingnotes, visitDetail);
        }
        // if (visitDetail && visitDetail.Status && visitDetail.Status.key !== visitStatus.pendingnotes) statusHandler(visitStatus.pendingnotes, visitDetail)
      }
    });
    // }
  };

  const Patient_Caregiver_self_monitoring = [
    { label: "Temp", type: "checkbox" },
    { label: "Pulse", type: "checkbox" },
    { label: "BP", type: "checkbox" },
    { label: "SPO2", type: "checkbox" },
    { label: "Patient/Caregiver NOT self monitoring", type: "checkbox" },
  ];
  const saveForm = (image) => {
    const decodedImage = atob(image.split(",")[1]);
    const blob = new Blob([decodedImage], { type: "image/jpeg" });
    const imageUrl = URL.createObjectURL(blob);
    setDisplaySignature(image);
    toast.success("Signature added.");
  };

  const initialValues = () => {
    if (
      staffFormData &&
      staffFormData.formData &&
      staffFormData.MasterDatas.name === staffFormTypesConstants.FOLLOWUP
    ) {
      let goals = [];

      for (let index = 0; index < totalDefaultGoals; index++) {
        let goalObj = JSON.parse(JSON.stringify(defaultGoalObj));
        if (staffDocument && staffDocument.length > 0) {
          goalObj["effectiveDate"] =
            staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
        }
        if (
          staffFormData.formData &&
          staffFormData.formData.goals &&
          staffFormData.formData.goals.length > 0 &&
          staffFormData.formData.goals[index]
        ) {
          goalObj = staffFormData.formData.goals[index];
        }
        goals.push(goalObj);
      }
      let formData = JSON.parse(JSON.stringify(staffFormData));
      formData.formData.goals = goals;
      let value = {
        ...formData.formData,
        id: staffFormData.id,
        visit_date: confirmDate
          ? confirmDate
          : staffFormData.formData.visit_date,
        autodate: confirmDate ? confirmDate : "",
        highPaindate: confirmDate
          ? confirmDate
          : staffFormData.formData.visit_date,
      };
      return value;
    }
    if (toSave && init) return { ...init };
    let initialValues = {
      Vital_Signs: [
        {
          vital_signs_BP_systolic: "",
          vital_signs_BP_diastolic: "",
          vital_signs_BP_type: "",
          vital_signs_Pulse: "",
          vital_signs_Pulse_type: "",
          vital_signs_Respiration: "",
          vital_signs_Respiration_type: "",
          vital_signs_Temperature: "",
          vital_signs_Temperature_type: "",
          Self_Monitoring_type: "",
        },
      ],
      Patient_Caregiver_self_monitoring: [],
      patientNotCommunicate_Options_data: [],
      Pain: [
        {
          painRelieve: [],
          worseReason: [],
          description: [],
          cause: "",
          location: "",
          scale: [],
          rating: "0",
          patientNotCommunicableReason: [],
          patientNotCommunicableInput: "",
        },
      ],
      painStatus: [],
      care_coordination: [],
      scale_Used_data: [],
      description_data: [],
      cause_pain_data: [],
      relieves_pain_data: [],
      balance_additional: "",

      Deviations: [{}],
      endurance: [],

      visit_date: visitDetail?.date.split("T")[0],
      HomeboundEligibility: [],
      Justification: "",
      Interventions: [],
      DMERequests: "",
      PlanningRequests: "",
      Frequency1: "",
      Frequency2: "",
      Patientsignature: "",
      Therapistsignature: "",
      Physiciansignature: "",
      date: "",
      vital_signs_additional_input: "",

      Comments_pain: "",
      Comments_homebound: "",
      Interventions_Educations_input: "",
      Patient_Response_input: "",
      Ongoing_Skilled_Need_input: "",
      Coordination_Details_input: "",
      Coordination_date: "",

      Plans_for_next_visit: [
        {
          Assessment: [],
          Treatments_Procedures_Therapy: [],
          Teaching: [],
          Preparation_for_discharge: [],
          other: [],
        },
      ],
      CHHA_Supervisory_Visits: [
        {
          Supervision_performed_this_visit: "",
          Present: "",
          Following_POC: "",
          POC_changed: "",
          Patient_Responsible_Party: "",
          Communicates_openly: "",
          Reports_changes: "",
          Demonstrates_competency: "",
          Honors_patient: "",
          Complies_with_infection_prevention: "",
          Patient_Staff_compatible: "",
        },
      ],
      // patientNotCommunicate_Options: [
      //   {
      //     Diaphoresis: "",
      //     Grimacing: "",
      //     Moaning_crying: "",
      //     Guarding: "",
      //     Irritability: "",
      //     Anger: "",
      //     Tense: "",
      //     Restlessness: "",
      //     Change_in_vital_signs: "",
      //     patientNotCommunicate_Options_other: "",
      //     patientNotCommunicate_Options_input: "",
      //   },
      // ],
    };
    if (staffDetails) {
      initialValues.PatientName =
        staffDetails.lastName + ", " + staffDetails.firstName;
      initialValues.mrNo =
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.mrn;
      initialValues.dob =
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.dateOfBirth.split("T")[0];
      initialValues.agency =
        staffDetails &&
        staffDetails.createdByUser &&
        staffDetails.createdByUser.name;
      initialValues.diagnosis =
        staffDetails &&
        staffDetails.staffingDiagnosisdetails.length > 0 &&
        staffDetails.staffingDiagnosisdetails.map((item) => ({
          value: item.masterDataId,
          label: item.masterData.displayName,
        }));
    }
    if (currentUser) {
      initialValues.therapist = currentUser.lastName
        ? `${currentUser.lastName + ", " + currentUser?.firstName} ${
            currentUser.userDetail && currentUser.userDetail.discipline
              ? `(${currentUser.userDetail.discipline.toUpperCase()})`
              : ""
          }  `
        : "";
    }

    let goals = [];
    for (let index = 0; index < totalDefaultGoals; index++) {
      let goalObj = JSON.parse(JSON.stringify(defaultGoalObj));
      if (staffDocument && staffDocument.length > 0) {
        goalObj["effectiveDate"] =
          staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
      }

      if (
        lastGoals &&
        lastGoals.goals &&
        lastGoals.goals.length > 0 &&
        lastGoals.goals[index]
      ) {
        goalObj = lastGoals.goals[index];
      }
      goals.push(goalObj);
    }
    if (lastGoals && lastGoals.homeboundEligibility) {
      initialValues.HomeboundEligibility = lastGoals.homeboundEligibility;
    }
    if (lastGoals && lastGoals.interventions) {
      initialValues.Interventions = lastGoals.interventions;
    }
    if (lastGoals && lastGoals.planningRequests) {
      initialValues.PlanningRequests = lastGoals.planningRequests;
    }
    initialValues.goals = goals;
    if (staffFormData && staffFormData.formData) {
      const { formData } = staffFormData;
      let toInclude = [
        "HomeboundEligibility",
        "Interventions",
        "HomeboundEligibility",
        "goals",
        "PlanningRequests",
      ];
      Object.keys(formData).map((key) => {
        if (toInclude.includes(key) && formData[key]) {
          initialValues[key] = formData[key];
        }
      });
    }
    initialValues.visit_date = confirmDate
      ? confirmDate
      : staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
    initialValues.autodate = confirmDate ? confirmDate : "";
    initialValues.highPaindate = confirmDate
      ? confirmDate
      : staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
    return initialValues;
  };
  const effectiveDate = () => {
    if (staffDocument && staffDocument.length > 0) {
      const date =
        staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
      return date;
    }
    return "";
  };
  const optionsByName = (name, startFrom, length) => {
    const optionArray = Array.from(
      { length: length ? length : 4 },
      (_, index) => {
        return {
          value: `${name} ${index + startFrom}`,
          label: `${name} ${index + startFrom}`,
        };
      }
    );
    return optionArray;
  };
  const [error, setError] = useState(null);
  const [planError, setPlansError] = useState(null);

  const validate = (values) => {
    let error = {};

    if (vitalSignsValidation(values?.Vital_Signs[0])) {
      if (!values.autodate) {
        error["autodate"] = "Required";
      }
      if (!("vitalreportedAgency" in values)) {
        error["vitalreportedAgency"] = "Required";
      } else if (!values.vitalreportedAgency) {
        error["vitalreportedAgency"] =
          "Please call the Agency to report the abnormal vital sign(s).";
      }
      if (!("vitalreportedMD" in values)) {
        error["vitalreportedMD"] = "Required";
      }
    }
    let vitalSignArray = [];

    values.Vital_Signs.map((item) => {
      let vitalSignErrorObject = {};

      // Object.keys(item).map((itemKey) => {
      //   if (
      //     !vitalSignsAdditionalFields.includes(itemKey) &&
      //     (!item[itemKey] || item[itemKey].trim() === "")
      //   ) {
      //     vitalSignErrorObject[itemKey] = "Required";
      //   } else if (
      //     item[itemKey] &&
      //     vitalSignsThreshold[itemKey] &&
      //     (Number(item[itemKey]) <
      //       Number(vitalSignsThreshold[itemKey]["min"]) ||
      //       Number(item[itemKey]) > Number(vitalSignsThreshold[itemKey]["max"]))
      //   ) {
      //     vitalSignErrorObject[itemKey] = "Out of range";
      //   }
      // });
      vitalSignsAdditionalFields.map((itemKey) => {
        if (!item[itemKey]) {
          vitalSignErrorObject[itemKey] = "Required";
        } else if (item[itemKey] && item[itemKey].trim() === "") {
          vitalSignErrorObject[itemKey] = "Required";
        } else if (
          item[itemKey] &&
          (Number(item[itemKey]) <
            Number(vitalSignsThreshold[itemKey]["min"]) ||
            Number(item[itemKey]) > Number(vitalSignsThreshold[itemKey]["max"]))
        ) {
          vitalSignErrorObject[itemKey] = "Out of range";
        }
      });
      vitalSignArray.push(vitalSignErrorObject);
    });
    error["Vital_Signs"] = vitalSignArray;
    if (
      vitalSignsValidation(values?.Vital_Signs[0]) &&
      "vitalreportedAgency" in values &&
      "vitalreportedMD" in values
    ) {
      error["Vital_Signs"] = [];
    }

    if (
      !values.HomeboundEligibility ||
      values.HomeboundEligibility?.length === 0
    ) {
      error["HomeboundEligibility"] = "Required";
    }
    if (!values.Interventions || values.Interventions.length === 0) {
      error["Interventions"] = "Required";
    }
    if (!values.Interventions_Educations_input) {
      error["Interventions_Educations_input"] = "Required";
    }
    if (!values.Patient_Response_input) {
      error["Patient_Response_input"] = "Required";
    }
    if (!values.Ongoing_Skilled_Need_input) {
      error["Ongoing_Skilled_Need_input"] = "Required";
    }
    if (
      values.care_coordination.includes("Others") &&
      (!values.care_coordinationTextBox || values.care_coordinationTextBox.text)
    ) {
      error["care_coordinationTextBox"] = "Required";
    }
    if (values.care_coordination.length > 0) {
      if (!values.Coordination_date) {
        error["Coordination_date"] = "Required";
      }
      if (!values.time) {
        error["time"] = "Required";
      }
      if (!values.Coordination_Details_input) {
        error["Coordination_Details_input"] = "Required";
      }
    }
    // if (values.PlanningRequests.length === 0) {
    //   error["PlanningRequests"] = "Required";
    // }

    if (values.Patient_Caregiver_self_monitoring.length === 0) {
      error["Patient_Caregiver_self_monitoring"] = "Required";
    }
    // if (
    //   values?.patientNotCommunicate &&
    //   values?.patientNotCommunicate.length > 0 &&
    //   values?.patientNotCommunicate_Options_data.length === 0
    // ) {
    //   error["patientNotCommunicate_Options_data"] = "Required";
    // }
    // if (values.painStatus.includes("patientNotCommunicable")) {
    //   error["patientNotCommunicate_Options_data"] = "Required";
    // }
    if (!values.time_in) {
      error["time_in"] = "Required";
    }
    if (!values.time_out) {
      error["time_out"] = "Required";
    }
    if (values.time_in && values.time_out) {
      const timeIn = new Date(`2000-01-01T${values.time_in}`);
      const timeOut = new Date(`2000-01-01T${values.time_out}`);
      const difference = (timeOut - timeIn) / (1000 * 60);
      if (difference < 0) {
        error.time_out = "Time Out must be after Time In";
      }
      if (difference < 45 && difference >= 0) {
        error.time_out = "Time Out must be at least 45 minutes after Time In";
      }
    }

    if (!values.visit_date) {
      error["visit_date"] = "Required";
    }
    if (!values.subjective) {
      error["subjective"] = "Required";
    }

    const goalArray = [];
    let goalscount = 0;
    values.goals.map((goalItem) => {
      let errobject = {};
      if (goalItem.goal && !goalItem.effectiveDate) {
        errobject.effectiveDate = "Required date";
      }
      if (goalItem.goal && !goalItem.currentStatus) {
        errobject.currentStatus = "Required Status";
      }
      if (goalItem.currentStatus && goalItem.effectiveDate && !goalItem.goal) {
        errobject.goal = "Required";
      }
      if (goalItem.goal && goalItem.currentStatus && goalItem.effectiveDate) {
        goalscount += 1;
      }
      goalArray.push(errobject);
    });

    if (goalscount < 3) {
      setError(true);
    } else {
      setError(false);
    }

    error.goals = goalArray;

    let Plans_for_next_visit_Array = [];

    let temp = false;

    let Pain_array = [];
    values?.Pain?.map((item, index) => {
      let ErrorObject = {};

      if (!values.painStatus.includes("noPain")) {
        if (item.rating && item.rating == 0) {
          ErrorObject["rating"] = "Required";
        }
        if (item?.rating && item?.rating > 0) {
          if (item?.scale?.length === 0) {
            ErrorObject["scale"] = "Required";
          } else if (
            (item?.scale?.includes("Other") && !item?.scaleInput) ||
            item?.scaleInput?.trim() === ""
          ) {
            ErrorObject["scaleInput"] = "Required";
          }
          if (!item?.location) {
            ErrorObject["location"] = "Required";
          }
          if (!item?.description || item?.description.length == 0) {
            ErrorObject["description"] = "Required";
          }
          if (
            (item?.worseReason?.includes("Other") && !item?.worseReasonInput) ||
            item?.worseReasonInput?.trim() === ""
          ) {
            ErrorObject["worseReasonInput"] = "Required";
          }
          if (
            (item?.painRelieve?.includes("Other") && !item?.painRelieveInput) ||
            item?.painRelieveInput?.trim() === ""
          ) {
            ErrorObject["painRelieveInput"] = "Required";
          }
        }
      }
      if (values?.painStatus.includes("patientNotCommunicable")) {
        ErrorObject = {};
        if (item?.patientNotCommunicableReason?.length === 0) {
          ErrorObject["patientNotCommunicableReason"] = "Required";
        } else if (
          item?.patientNotCommunicableReason?.includes("Other") &&
          (!item?.patientNotCommunicableInput ||
            item?.patientNotCommunicableInput?.trim() === "")
        ) {
          ErrorObject["patientNotCommunicableInput"] = "Required";
        }
      }
      Pain_array?.push(ErrorObject);
    });
    error["Pain"] = Pain_array;

    if (isPainHigh(values.Pain)) {
      if (!values.highPaindate) {
        error["highPaindate"] = "Required";
      }
      if (!("highPainReportedToAgency" in values)) {
        error["highPainReportedToAgency"] = "Required";
      } else if (!values.highPainReportedToAgency) {
        error["highPainReportedToAgency"] =
          "Please call the Agency to report the abnormal vital sign(s).";
      }
      if (!("highPainReportedToMD" in values)) {
        error["highPainReportedToMD"] = "Required";
      }
    }
    let CHHASupervisioryArray = [];

    values.CHHA_Supervisory_Visits.map((item) => {
      let ErrorObject = {};
      if (!item.Supervision_performed_this_visit) {
        ErrorObject.Supervision_performed_this_visit = "Required";
      }
      if (item.Supervision_performed_this_visit === "yes") {
        Object.keys(item).map((key) => {
          if (
            key === "Present" &&
            item[key] === "yes" &&
            (!item.Present_input || item.Present_input.trim() === "")
          ) {
            ErrorObject["Present_input"] = "Required";
          }
          if (!item[key]) {
            ErrorObject[key] = "Required";
          } else if (
            key === "POC_changed" &&
            item[key] === "yes" &&
            (!item.specifyDetails || item.specifyDetails?.trim() === "")
          ) {
            ErrorObject["specifyDetails"] = "Required";
          } else if (
            key !== "Present" &&
            key !== "POC_changed" &&
            item[key] === "no" &&
            (!item.specifyDetails || item.specifyDetails?.trim() === "")
          ) {
            ErrorObject["specifyDetails"] = "Required";
          }
        });
      }
      CHHASupervisioryArray.push(ErrorObject);
    });
    error["CHHA_Supervisory_Visits"] = CHHASupervisioryArray;
    values.Plans_for_next_visit.map((item) => {
      let ErrorObject = {};
      let isValid = false;
      Object.keys(item).map((key) => {
        if (Array.isArray(item[key]) && item[key].length > 0) {
          isValid = true;
        }
        if (
          (Array.isArray(item[key]) &&
            item[key].length > 0 &&
            `${key}_input` !== "Preparation_for_discharge_input" &&
            !item[`${key}_input`]) ||
          !item[`${key}_input`]?.trim() === ""
        ) {
          ErrorObject[`${key}_input`] = "required";
        }
      });
      if (!isValid) {
        setPlansError(true);
      } else {
        setPlansError(false);
      }
      Plans_for_next_visit_Array.push(ErrorObject);
    });
    error.Plans_for_next_visit = Plans_for_next_visit_Array;

    return error;
  };

  const isPainHigh = (painArray) => {
    let highPain = painArray?.find((painItem) => painItem.rating > 6);
    return highPain ? true : false;
  };

  const getFollowUpForm = () => {
    dispatch(
      getStaffFormdataById({
        episodeDocumentId: Number(visitDetail.id),
        formTypeName: staffFormTypesConstants.FOLLOWUP,
      })
    );
  };
  useEffect(() => {
    if (!dataAvailable && staffDocument) {
      getFollowUpForm();
    }
  }, [staffDocument, dataAvailable]);

  useEffect(() => {
    if (staffDocument && staffDocument.length > 0) {
      const episodeId = staffDocument[0].id;
      dispatch(getLastGoalsValue({ episodeId }));
    }
  }, [staffDocument]);

  const handleClose = () => {
    setShow(false);
  };

  const disableByVisitStatus = () => {
    let disabled = true;
    const isCurrentUserAdminOrSupAdminOrAgency =
      currentUser?.Role?.key === currentRole.admin ||
      currentUser?.Role?.key === currentRole.superadmin ||
      currentUser?.Role?.key === currentRole.agency;

    const isCurrentUserSupervisingOrAssistant =
      currentUser?.Role?.key === currentRole.supervisingtherapist ||
      currentUser?.Role?.key === currentRole.assistanttherapist;

    const isVisitInReviewOrCompleted =
      visitDetailById?.Status?.key === "needreview" ||
      visitDetailById?.Status?.key === "completed";

    if (isCurrentUserAdminOrSupAdminOrAgency) {
      disabled = true;
    } else if (
      isCurrentUserSupervisingOrAssistant &&
      isVisitInReviewOrCompleted
    ) {
      disabled = true;
    } else {
      disabled = false;
    }
    if (toSave) disabled = false;

    return disabled;
  };

  const getFormStatus = () => {
    let status = "";
    if (visitDetailById) {
      status = visitDetailById.Status.key;
      if (
        visitDetailById.patientFormList &&
        visitDetailById.patientFormList.length > 0
      ) {
        let form = visitDetailById.patientFormList.find(
          (item) => item.name == staffFormTypesConstants.FollowUpForm
        );
        if (form) {
          status = form.Status.key;
        }
      }
    }
    return status;
  };

  const checkPreviousGoal = (value, index) => {
    let disable = false;

    if (
      lastGoals &&
      lastGoals.goals &&
      lastGoals.goals[index] &&
      lastGoals.goals[index].goal &&
      lastGoals.goals[index].goal.toLowerCase() === value.goal.toLowerCase()
    ) {
      disable = true;
    } else {
      disable = false;
    }

    return disable;
  };
  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        mutators={{
          ...arrayMutators,
        }}
        // initialValues={{}}
        initialValues={useMemo(
          (e) => initialValues(e),
          [staffFormData, lastGoals]
        )}
        render={({
          handleSubmit,
          values,
          submitFailed,
          errors,
          form,
          valid,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={`${!isPrint ? "container" : "row"}`}>
              <div
                className={`followup-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
                <div className="row mein-white-from-background">
                  <div className="col-md-6 border-right-1">
                    <div className="fileds-from">
                      <label>MR# :</label>
                      <Field
                        name="mrNo"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              maxlength="45"
                              className="form-control bg-white"
                              {...input}
                              type="text"
                              placeholder=""
                              disabled
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="fileds-from">
                      <label>Patient :</label>
                      <Field
                        name="PatientName"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              maxlength="45"
                              className="form-control bg-white"
                              {...input}
                              type="text"
                              disabled
                              placeholder=""
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="fileds-from">
                      <label>DOB :</label>
                      <Field
                        name="dob"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              maxlength="45"
                              className="form-control bg-white"
                              {...input}
                              type="date"
                              placeholder=""
                              disabled
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="fileds-from">
                      <label>Agency :</label>
                      <Field
                        name="agency"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              maxlength="45"
                              className="form-control bg-white"
                              {...input}
                              type="text"
                              disabled
                              placeholder=""
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="col-md-6 ">
                    <div className="fileds-from">
                      <label>Visit Date :</label>
                      <Field
                        name="visit_date"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              className="form-control "
                              {...input}
                              maxlength="45"
                              type="date"
                              placeholder=""
                              min={props.priorVisitDate(visitDetail.id)}
                              disabled={true}
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="fileds-from">
                      <label>Time In :</label>
                      <Field
                        name="time_in"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              maxlength="45"
                              className="form-control"
                              {...input}
                              type="time"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="fileds-from">
                      <label>Time Out :</label>
                      <Field
                        name="time_out"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              className="form-control"
                              maxlength="45"
                              {...input}
                              min={values.time_in}
                              type="time"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                            {meta.error && meta.touched && (
                              <p
                                className="alert alert-danger w-100 "
                                role="alert"
                              >
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="fileds-from">
                      <label>Therapist :</label>
                      <Field
                        name="therapist"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              className="form-control bg-white"
                              maxlength="45"
                              {...input}
                              type="text"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    Diagnosis :
                  </h5>
                  <div className="col-md-12 mb-3">
                    <div>
                      {toSave ? (
                        <div>
                          {values.diagnosis &&
                            values.diagnosis.map((item) => {
                              return <span>{`${item.label},`}</span>;
                            })}
                        </div>
                      ) : (
                        <Field
                          name="diagnosis"
                          component="input"
                          type="text"
                          placeholder=""
                          className="input-textSize"
                        >
                          {({ input, meta }) => (
                            <div>
                              <Select
                                {...input}
                                isMulti
                                className="bg-white"
                                options={optionsByName("ICD CODE", 10, 7)}
                                isDisabled={true}
                                disabled={disableByVisitStatus()}
                              />
                              {meta.error && meta.touched && (
                                <p className="alert alert-danger " role="alert">
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          )}
                        </Field>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    {" "}
                    Subjective :
                  </h5>
                  <div className="col-md-12 mb-3">
                    <div>
                      <Field
                        name="subjective"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div>
                            {toSave ? (
                              <p>{values.subjective}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                {...input}
                                type="text"
                                placeholder=""
                                className="Additional_text"
                                disabled={disableByVisitStatus()}
                              ></textarea>
                            )}

                            {meta.error && meta.touched && (
                              <p className="alert alert-danger w-100 meta_err">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Vital Signs
                  </h5>
                  <FieldArray name="Vital_Signs">
                    {({ fields, meta }) => (
                      <>
                        {fields.map((name, index) => (
                          <>
                            <div className="row my-3">
                              {" "}
                              <div className="row ">
                                <div className="col-md-6">
                                  <div className="fileds-from gap-0 align-items-start">
                                    <label className="text-align-left w-75">
                                      Pulse :
                                    </label>
                                    <Field
                                      name={`${name}.vital_signs_Pulse`}
                                      component="input"
                                      className="form-control ptinput"
                                      type="number"
                                      value=""
                                    >
                                      {({ input, meta }) => (
                                        <div className="w-75">
                                          <input
                                            maxlength="3"
                                            autoComplete="off"
                                            className="form-control ptinput"
                                            {...input}
                                            type="text"
                                            placeholder=""
                                            disabled={disableByVisitStatus()}
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                          />
                                          {meta.error && meta.touched && (
                                            <p
                                              className="alert alert-danger "
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                </div>
                                <div className="col-md-6  d-flex align-items-start">
                                  <div className="radio-of-forms  gap-3">
                                    {vital_signs_pulse_data.map(
                                      (item, index) => (
                                        <div key={`Pulse_${index}`}>
                                          <Field
                                            name={`${name}.${item.name_key}`}
                                            component="input"
                                            type={item?.type}
                                            value={item?.label}
                                            disabled={disableByVisitStatus()}
                                          ></Field>
                                          <label className="mx-2 text-center">
                                            {item.label}{" "}
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="row ">
                                <div className="col-md-6">
                                  <div className="fileds-from gap-0 align-items-start">
                                    <label>BP : </label>
                                    <div className="d-flex w-50">
                                      <Field
                                        name={`${name}.vital_signs_BP_systolic`}
                                        className="form-control mr-3"
                                        component="input"
                                        value=""
                                      >
                                        {({ input, meta }) => (
                                          <div className="">
                                            <input
                                              maxlength="3"
                                              className="form-control ptinput"
                                              {...input}
                                              type="text"
                                              placeholder=""
                                              autoComplete="off"
                                              disabled={disableByVisitStatus()}
                                              onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                            />
                                            {meta.error && meta.touched && (
                                              <p
                                                className="alert alert-danger "
                                                role="alert"
                                              >
                                                {meta.error}
                                              </p>
                                            )}
                                          </div>
                                        )}
                                      </Field>
                                      <span className="mr-3 primery_span_vital_signs ml-3">
                                        /
                                      </span>
                                      <Field
                                        name={`${name}.vital_signs_BP_diastolic`}
                                        component="input"
                                        className=" form-control"
                                        value=""
                                      >
                                        {({ input, meta }) => (
                                          <div className="">
                                            <input
                                              maxlength="3"
                                              className="form-control ptinput"
                                              {...input}
                                              type="text"
                                              autoComplete="off"
                                              placeholder=""
                                              disabled={disableByVisitStatus()}
                                              onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                            />
                                            {meta.error && meta.touched && (
                                              <p
                                                className="alert alert-danger "
                                                role="alert"
                                              >
                                                {meta.error}
                                              </p>
                                            )}
                                          </div>
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="d-flex">
                                    <label className="me-2">Position : </label>
                                    <div className="radio-of-forms  gap-3">
                                      {vital_signs_bp_data.map(
                                        (item, index) => (
                                          <div key={`Postions_${index}`}>
                                            <Field
                                              name={`${name}.vital_signs_BP_type`}
                                              component="input"
                                              type={item?.type}
                                              value={item?.label}
                                              disabled={disableByVisitStatus()}
                                            ></Field>
                                            <label className="mx-2">
                                              {item.label}{" "}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row ">
                                <div className="col-md-6">
                                  <div className="fileds-from gap-0 align-items-start">
                                    <label className="text-align-left w-75">
                                      Temperature :{" "}
                                    </label>
                                    <Field
                                      name={`${name}.vital_signs_Temperature`}
                                      component="input"
                                      type="number"
                                      value=""
                                      className="pteval-input"
                                    >
                                      {({ input, meta }) => (
                                        <div className="w-75">
                                          <input
                                            maxlength="3"
                                            className="form-control ptinput"
                                            {...input}
                                            type="text"
                                            autoComplete="off"
                                            placeholder=""
                                            disabled={disableByVisitStatus()}
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                          />
                                          {meta.error && meta.touched && (
                                            <p
                                              className="alert alert-danger "
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="d-flex align-items-center">
                                    <label className=" me-2 margin-bottom-18">
                                      Degrees Method :
                                    </label>
                                    <div className="radio-of-forms  gap-3">
                                      {vital_signs_temperature_data.map(
                                        (item, index) => (
                                          <div key={`temp${index}`}>
                                            <Field
                                              name={`${name}.vital_signs_Temperature_type`}
                                              component="input"
                                              type={item?.type}
                                              value={item?.label}
                                              disabled={disableByVisitStatus()}
                                            ></Field>
                                            <label className="mx-2">
                                              {item.label}{" "}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row ">
                                <div className="col-md-6">
                                  <div className="fileds-from gap-0 align-items-start">
                                    <label className="text-align-left">
                                      Respiration :{" "}
                                    </label>
                                    <Field
                                      name={`${name}.vital_signs_Respiration`}
                                      component="input"
                                      type="number"
                                      value=""
                                      className="pteval-input"
                                    >
                                      {({ input, meta }) => (
                                        <div className="w-50">
                                          <input
                                            maxlength="2"
                                            className="form-control ptinput"
                                            {...input}
                                            type="text"
                                            autoComplete="off"
                                            placeholder=""
                                            disabled={disableByVisitStatus()}
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                          />
                                          {meta.error && meta.touched && (
                                            <p
                                              className="alert alert-danger "
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                </div>

                                <div className="col-md-6 d-flex">
                                  <div className="radio-of-forms  gap-3">
                                    {vital_signs_respiration_data.map(
                                      (item, index) => (
                                        <div key={`respiration_${index}`}>
                                          <Field
                                            name={`${name}.vital_signs_Respiration_type`}
                                            component="input"
                                            type={item?.type}
                                            value={item?.label}
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label className="mx-2">
                                            {item.label}{" "}
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </FieldArray>

                  <div className="d-flex gap-3">
                    <label>Patient/Caregiver self monitoring :</label>
                    <div className="radio-of-forms  gap-3">
                      {Patient_Caregiver_self_monitoring.map((item, index) => (
                        <div key={`Care_${index}`}>
                          <Field
                            name={`Patient_Caregiver_self_monitoring`}
                            component="input"
                            type={item?.type}
                            value={item?.label}
                            disabled={disableByVisitStatus()}
                          ></Field>
                          <label className="mx-2">{item.label} </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="my-2">
                    {errors.Patient_Caregiver_self_monitoring &&
                      touched.Patient_Caregiver_self_monitoring && (
                        <p
                          className="meta_err alert alert-danger "
                          role="alert"
                        >
                          {errors.Patient_Caregiver_self_monitoring}
                        </p>
                      )}
                  </div>

                  <div className="col-md-12">
                    <div>
                      <Field
                        name={`vital_signs_additional_input`}
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <>
                            <label>
                              <span className="text-ordered-list mr-1">
                                Additional vital sign details :
                              </span>
                            </label>
                            {toSave ? (
                              <p>{values.vital_signs_additional_input}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                className="Additional_text  w-100"
                                {...input}
                                placeholder=""
                                disabled={disableByVisitStatus()}
                              />
                            )}

                            {meta.error && meta.touched && (
                              <p className="meta_err alert alert-danger">
                                {meta.error}
                              </p>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>

                  {vitalSignsValidation(values.Vital_Signs[0]) && (
                    <div className="row mt-5">
                      <>
                        <div className="d-flex align-items-center justify-content-between col-md-8">
                          <label>Date</label>
                          <Field
                            name="autodate"
                            component="input"
                            className="input-textSize"
                          >
                            {({ input, meta }) => (
                              <div className="w-40">
                                <input
                                  className="form-control"
                                  {...input}
                                  type="date"
                                  disabled={disableByVisitStatus()}
                                />
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                          <label htmlFor="">
                            Was the abnormal vital(s) reported to the Agency ?
                          </label>
                          <div className="d-flex align-items-center justify-content-between w-40 px-1">
                            <div className="radio-of-forms mr-5">
                              <Field
                                name={`vitalreportedAgency`}
                                type="radio"
                                component="input"
                                value={true}
                              >
                                {({ input, meta }) => (
                                  <>
                                    <input
                                      type="radio"
                                      {...input}
                                      onChange={() => input.onChange(true)}
                                      value={true}
                                      disabled={disableByVisitStatus()}
                                    />
                                  </>
                                )}
                              </Field>
                              <label> Yes </label>
                            </div>
                            <div className="radio-of-forms">
                              <Field
                                name={`vitalreportedAgency`}
                                type="radio"
                                component="input"
                                value={false}
                              >
                                {({ input, meta }) => (
                                  <>
                                    <input
                                      type="radio"
                                      {...input}
                                      onChange={() => input.onChange(false)}
                                      value={false}
                                      disabled={disableByVisitStatus()}
                                    />
                                    {}
                                  </>
                                )}
                              </Field>
                              <label>No </label>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-md-4">
                            <div className="d-flex gap-3">
                              {" "}
                              <p className="fw-bold">Agency Name :</p>
                              <p>
                                {staffDetails &&
                                  staffDetails.createdByUser &&
                                  staffDetails.createdByUser.name}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="d-flex gap-3">
                              <p className="fw-bold">Agency Phone :</p>
                              <p className="text-nowrap">
                                {staffDetails &&
                                  staffDetails.createdByUser &&
                                  staffDetails.createdByUser.userDetail &&
                                  staffDetails.createdByUser.userDetail.phone}
                              </p>
                            </div>
                          </div>
                        </div>

                        {errors.vitalreportedAgency &&
                          touched.vitalreportedAgency && (
                            <p className="alert alert-danger " role="alert">
                              {errors.vitalreportedAgency}
                            </p>
                          )}
                        <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                          <label htmlFor="">
                            Was the abnormal vital(s) reported to the MD ?
                          </label>
                          <div className="d-flex align-items-center justify-content-between w-40 px-1">
                            <div className="radio-of-forms mr-5">
                              <Field
                                name={`vitalreportedMD`}
                                type="radio"
                                component="input"
                                value={true}
                              >
                                {({ input, meta }) => (
                                  <>
                                    <input
                                      type="radio"
                                      {...input}
                                      onChange={() => input.onChange(true)}
                                      value={true}
                                      disabled={disableByVisitStatus()}
                                    />
                                  </>
                                )}
                              </Field>
                              <label> Yes </label>
                            </div>

                            <div className="radio-of-forms">
                              <Field
                                name={`vitalreportedMD`}
                                type="radio"
                                component="input"
                                value={false}
                              >
                                {({ input, meta }) => (
                                  <>
                                    <input
                                      type="radio"
                                      {...input}
                                      onChange={() => input.onChange(false)}
                                      value={false}
                                      disabled={disableByVisitStatus()}
                                    />
                                  </>
                                )}
                              </Field>
                              <label>No </label>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-md-4">
                            <div className="d-flex gap-3 ">
                              <p className=" fw-bold">Physician Name :</p>
                              <p>
                                {staffDetails &&
                                  staffDetails.staffingDetails &&
                                  staffDetails.staffingDetails.physicianName}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-4">
                            {" "}
                            <div className="d-flex  gap-3 ">
                              <p className=" fw-bold">Physician Phone :</p>
                              <p>
                                {staffDetails &&
                                  staffDetails.staffingDetails &&
                                  staffDetails.staffingDetails
                                    .physicianPhoneNumber}
                              </p>
                            </div>
                          </div>
                        </div>

                        {errors.vitalreportedMD && touched.vitalreportedMD && (
                          <p className="alert alert-danger " role="alert">
                            {errors.vitalreportedMD}
                          </p>
                        )}
                        <div className="col-md-12 mt-4 mb-3">
                          <div>
                            <Field
                              name="living_situations_name2"
                              component="input"
                              type="text"
                              placeholder=""
                              className="input-textSize"
                            >
                              {({ input, meta }) => (
                                <>
                                  <label>
                                    <span className="text-ordered-list mr-1">
                                      Please document who you spoke with at the
                                      Agency and any instructions you were
                                      given.
                                    </span>
                                  </label>
                                  {toSave ? (
                                    <p>{values.living_situations_name2}</p>
                                  ) : (
                                    <textarea
                                      maxlength="320"
                                      className="Additional_text  w-100"
                                      {...input}
                                      placeholder=""
                                      disabled={disableByVisitStatus()}
                                    />
                                  )}

                                  {meta.error && meta.touched && (
                                    <span className="meta_err">
                                      {meta.error}
                                    </span>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                        </div>
                      </>
                    </div>
                  )}
                </div>
              </div>

              <div
                className={`followup-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">Pain :</h5>
                  <FieldArray name="Pain">
                    {({ fields, meta }) =>
                      fields.map((painItem, painIndex) => (
                        <div className="row">
                          {!values.painStatus.includes(
                            "patientNotCommunicable"
                          ) && (
                            <div className="col-md-6">
                              <div className="radio-of-forms gap-3  mt-3">
                                <Field
                                  name={`painStatus`}
                                  component="input"
                                  type="checkbox"
                                  value={"noPain"}
                                  disabled={disableByVisitStatus()}
                                />
                                <label className="pl-0"> No Pain </label>
                              </div>
                            </div>
                          )}
                          <div className="col-md-6">
                            {!values.painStatus.includes("noPain") && (
                              <div className="radio-of-forms gap-3  mt-3">
                                <Field
                                  name={`painStatus`}
                                  component="input"
                                  type="checkbox"
                                  value={"patientNotCommunicable"}
                                  disabled={disableByVisitStatus()}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <input
                                        type="checkbox"
                                        {...input}
                                        onChange={(event) => {
                                          input.onChange(event);
                                          if (!event.target.checked) {
                                            form.change(
                                              `${painItem}.patientNotCommunicableReason`,
                                              ""
                                            );
                                            form.change(
                                              `${painItem}.patientNotCommunicableInput`,
                                              ""
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  )}
                                </Field>
                                <label className="pl-0">
                                  {" "}
                                  Patient unable to communicate , non-verbal
                                  indicators demonstrated :
                                </label>
                              </div>
                            )}
                          </div>

                          {!values.painStatus.includes("noPain") &&
                            !values.painStatus.includes(
                              "patientNotCommunicable"
                            ) && (
                              <div className="col-md-6">
                                <div className="my-3">
                                  <Field
                                    name={`${painItem}.rating`}
                                    component="select"
                                    className="paitiant-chart-input form-select"
                                    value={"rating_with"}
                                  >
                                    {({ input, meta }) => (
                                      <>
                                        <select
                                          {...input}
                                          className="form-control"
                                          disabled={disableByVisitStatus()}
                                        >
                                          <option hidden>Pain Rating</option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="4">4</option>
                                          <option value="5">5</option>
                                          <option value="6">6</option>
                                          <option value="7">7</option>
                                          <option value="8">8</option>
                                          <option value="9">9</option>
                                          <option value="10">10</option>
                                        </select>
                                        {meta.error && meta.touched && (
                                          <p
                                            className="alert alert-danger "
                                            role="alert"
                                          >
                                            {meta.error}
                                          </p>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </div>
                                <div className="my-3">
                                  <label
                                    className=""
                                    style={{ marginRight: "10px" }}
                                  >
                                    Scale Used :{" "}
                                  </label>
                                  {scale_Used_data.map((item) => (
                                    <>
                                      <Field
                                        name={`${painItem}.scale`}
                                        component="input"
                                        type="checkbox"
                                        value={item}
                                        disabled={disableByVisitStatus()}
                                      />
                                      <label className="mx-2">{item} </label>
                                    </>
                                  ))}

                                  {meta.error &&
                                    meta.touched &&
                                    errors?.Pain[painIndex] &&
                                    errors?.Pain[painIndex]["scale"] && (
                                      <p
                                        className="alert alert-danger my-1"
                                        role="alert"
                                      >
                                        {errors.Pain[painIndex]["scale"]}
                                      </p>
                                    )}
                                  <Field name={`${painItem}.scaleInput`}>
                                    {({ input, meta }) => (
                                      <>
                                        <input
                                          {...input}
                                          className="form-control"
                                          type="text"
                                          disabled={disableByVisitStatus()}
                                        />
                                        {meta.error && meta.touched && (
                                          <p
                                            className="alert alert-danger mt-1 "
                                            role="alert"
                                          >
                                            {meta.error}
                                          </p>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </div>
                                <div className="my-3 d-flex align-items-center justify-content-between">
                                  <label className=""> Pain Location :</label>
                                  <Field name={`${painItem}.location`}>
                                    {({ input, meta }) => (
                                      <div className="w-75">
                                        <input
                                          {...input}
                                          className="form-control "
                                          type="text"
                                          disabled={disableByVisitStatus()}
                                        />
                                        {meta.error && meta.touched && (
                                          <p
                                            className="alert alert-danger "
                                            role="alert"
                                          >
                                            {meta.error}
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className="my-3 d-flex align-items-center justify-content-between">
                                  <label className=" "> Cause Of Pain :</label>
                                  <Field name={`${painItem}.cause`}>
                                    {({ input, meta }) => (
                                      <div className="w-75">
                                        <input
                                          {...input}
                                          className="form-control"
                                          type="text"
                                          disabled={disableByVisitStatus()}
                                        />
                                        {meta.error && meta.touched && (
                                          <p
                                            className="alert alert-danger "
                                            role="alert"
                                          >
                                            {meta.error}
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className="my-3 ">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <label className=""> Description :</label>
                                    </div>

                                    {description_data.map((item) => (
                                      <div className="col-md-3">
                                        <Field
                                          name={`${painItem}.description`}
                                          component="input"
                                          type="checkbox"
                                          value={item}
                                          disabled={disableByVisitStatus()}
                                        />
                                        <label className="mx-2">{item}</label>
                                      </div>
                                    ))}
                                    {meta.error &&
                                      meta.touched &&
                                      errors?.Pain[painIndex] &&
                                      errors?.Pain[painIndex][
                                        "description"
                                      ] && (
                                        <p
                                          className="alert alert-danger my-1"
                                          role="alert"
                                        >
                                          {
                                            errors.Pain[painIndex][
                                              "description"
                                            ]
                                          }
                                        </p>
                                      )}
                                  </div>
                                </div>
                                <div className="my-3">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <label className="">
                                        {" "}
                                        What makes pain worse :{" "}
                                      </label>
                                    </div>

                                    {cause_pain_data.map((item) => (
                                      <div className="col-md-3">
                                        <Field
                                          name={`${painItem}.worseReason`}
                                          component="input"
                                          type="checkbox"
                                          value={item}
                                          disabled={disableByVisitStatus()}
                                        />
                                        <label className="mx-2">{item}</label>
                                      </div>
                                    ))}
                                    <div className="col-md-12">
                                      <Field
                                        name={`${painItem}.worseReasonInput`}
                                      >
                                        {({ input, meta }) => (
                                          <>
                                            <input
                                              {...input}
                                              type="text"
                                              className="form-control"
                                              disabled={disableByVisitStatus()}
                                            />
                                            {meta.error && meta.touched && (
                                              <p
                                                className="alert alert-danger "
                                                role="alert"
                                              >
                                                {meta.error}
                                              </p>
                                            )}
                                          </>
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                </div>
                                <div className="my-3">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <label className="">
                                        What relieves pain :
                                      </label>
                                    </div>
                                    {relieves_pain_data.map((item) => (
                                      <div className="col-md-3">
                                        <Field
                                          name={`${painItem}.painRelieve`}
                                          component="input"
                                          type="checkbox"
                                          value={item}
                                          disabled={disableByVisitStatus()}
                                        />
                                        <label className="mx-2">{item}</label>
                                      </div>
                                    ))}
                                    <div className="col-md-12">
                                      <Field
                                        name={`${painItem}.painRelieveInput`}
                                      >
                                        {({ input, meta }) => (
                                          <>
                                            <input
                                              {...input}
                                              type="text"
                                              className="form-control"
                                              disabled={disableByVisitStatus()}
                                            />
                                            {meta.error && meta.touched && (
                                              <p
                                                className="alert alert-danger "
                                                role="alert"
                                              >
                                                {meta.error}
                                              </p>
                                            )}
                                          </>
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {!values.painStatus.includes("noPain") && (
                            <>
                              <div
                                className={`${
                                  values.painStatus.includes(
                                    "patientNotCommunicable"
                                  )
                                    ? "col-md-12"
                                    : "col-md-6"
                                }`}
                              >
                                <div className="row">
                                  {patientNotCommunicate_Options_data.map(
                                    (item) => (
                                      <div className="col-md-4">
                                        <div className="d-flex align-items-center mb-2">
                                          <Field
                                            name={`${painItem}.patientNotCommunicableReason`}
                                            component="input"
                                            type="checkbox"
                                            value={item.value}
                                            disabled={
                                              disableByVisitStatus() ||
                                              !values.painStatus.includes(
                                                "patientNotCommunicable"
                                              )
                                            }
                                          />
                                          <label className="ms-2 mb-0">
                                            {" "}
                                            {item.label}{" "}
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  )}
                                  {errors.Pain &&
                                    errors.Pain.length > 0 &&
                                    errors.Pain.find(
                                      (item) =>
                                        item &&
                                        item.patientNotCommunicableReason
                                    ) && (
                                      <div className="col-md-12">
                                        <p
                                          className="alert alert-danger "
                                          role="alert"
                                        >
                                          {
                                            errors.Pain.find(
                                              (item) =>
                                                item &&
                                                item.patientNotCommunicableReason
                                            ).patientNotCommunicableReason
                                          }
                                        </p>
                                      </div>
                                    )}
                                  <div className="col-md-12">
                                    <Field
                                      name={`${painItem}.patientNotCommunicableInput`}
                                    >
                                      {({ input, meta }) => (
                                        <>
                                          <input
                                            type="text"
                                            {...input}
                                            className="form-control ptinput"
                                            disabled={
                                              disableByVisitStatus() ||
                                              !values.painStatus.includes(
                                                "patientNotCommunicable"
                                              )
                                            }
                                          />
                                          {meta.error && meta.touched && (
                                            <p
                                              className="alert alert-danger "
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {!values.painStatus.includes("noPain") && (
                            <>
                              {isPainHigh(values.Pain) && (
                                <div className="row mt-5">
                                  <div className="d-flex align-items-center justify-content-between col-md-8">
                                    <label>Date</label>
                                    <Field
                                      name={`highPaindate`}
                                      component="input"
                                      className="input-textSize"
                                    >
                                      {({ input, meta }) => (
                                        <div className="w-40">
                                          <input
                                            className="form-control"
                                            {...input}
                                            type="date"
                                            disabled={disableByVisitStatus()}
                                          />
                                          {meta.error && meta.touched && (
                                            <p
                                              className="alert alert-danger "
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                                    <label htmlFor="">
                                      Was the abnormal Pain reported to the
                                      Agency ?
                                    </label>
                                    <div className="d-flex align-items-center justify-content-between w-40 px-1">
                                      <div className="radio-of-forms mr-5">
                                        <Field
                                          name={`highPainReportedToAgency`}
                                          type="radio"
                                          component="input"
                                          disabled={disableByVisitStatus()}
                                          value={true}
                                        >
                                          {({ input, meta }) => (
                                            <>
                                              <input
                                                type="radio"
                                                {...input}
                                                onChange={() =>
                                                  input.onChange(true)
                                                }
                                                value={true}
                                              />
                                            </>
                                          )}
                                        </Field>
                                        <label> Yes </label>
                                      </div>
                                      <div className="radio-of-forms">
                                        <Field
                                          name={`highPainReportedToAgency`}
                                          type="radio"
                                          component="input"
                                          value={false}
                                        >
                                          {({ input, meta }) => (
                                            <>
                                              <input
                                                type="radio"
                                                {...input}
                                                onChange={() =>
                                                  input.onChange(false)
                                                }
                                                value={false}
                                              />
                                              {}
                                            </>
                                          )}
                                        </Field>
                                        <label>No </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row mt-4">
                                    <div className="col-md-4">
                                      <div className="d-flex gap-3">
                                        {" "}
                                        <p className="fw-bold">Agency Name :</p>
                                        <p>
                                          {staffDetails &&
                                            staffDetails.createdByUser &&
                                            staffDetails.createdByUser.name}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="d-flex gap-3">
                                        <p className="fw-bold">
                                          Agency Phone :
                                        </p>
                                        <p className="text-nowrap">
                                          {staffDetails &&
                                            staffDetails.createdByUser &&
                                            staffDetails.createdByUser
                                              .userDetail &&
                                            staffDetails.createdByUser
                                              .userDetail.phone}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  {errors.highPainReportedToAgency &&
                                    touched.highPainReportedToAgency && (
                                      <p
                                        className="alert alert-danger "
                                        role="alert"
                                      >
                                        {errors.highPainReportedToAgency}
                                      </p>
                                    )}
                                  <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                                    <label htmlFor="">
                                      Was the abnormal Pain reported to the MD ?
                                    </label>
                                    <div className="d-flex align-items-center justify-content-between w-40 px-1">
                                      <div className="radio-of-forms mr-5">
                                        <Field
                                          name={`highPainReportedToMD`}
                                          type="radio"
                                          component="input"
                                          value={true}
                                        >
                                          {({ input, meta }) => (
                                            <>
                                              <input
                                                type="radio"
                                                {...input}
                                                onChange={() =>
                                                  input.onChange(true)
                                                }
                                                value={true}
                                              />
                                            </>
                                          )}
                                        </Field>
                                        <label> Yes </label>
                                      </div>

                                      <div className="radio-of-forms">
                                        <Field
                                          name={`highPainReportedToMD`}
                                          type="radio"
                                          component="input"
                                          value={false}
                                        >
                                          {({ input, meta }) => (
                                            <>
                                              <input
                                                type="radio"
                                                {...input}
                                                onChange={() =>
                                                  input.onChange(false)
                                                }
                                                value={false}
                                              />
                                            </>
                                          )}
                                        </Field>
                                        <label>No </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row mt-4">
                                    <div className="col-md-4">
                                      <div className="d-flex gap-3 ">
                                        <p className=" fw-bold">
                                          Physician Name :
                                        </p>
                                        <p>
                                          {staffDetails &&
                                            staffDetails.staffingDetails &&
                                            staffDetails.staffingDetails
                                              .physicianName}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="col-md-4">
                                      {" "}
                                      <div className="d-flex  gap-3 ">
                                        <p className=" fw-bold">
                                          Physician Phone :
                                        </p>
                                        <p>
                                          {staffDetails &&
                                            staffDetails.staffingDetails &&
                                            staffDetails.staffingDetails
                                              .physicianPhoneNumber}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  {errors.highPainReportedToMD &&
                                    touched.highPainReportedToMD && (
                                      <p
                                        className="alert alert-danger "
                                        role="alert"
                                      >
                                        {errors.highPainReportedToMD}
                                      </p>
                                    )}
                                  <div className="col-md-12 mt-4 mb-3">
                                    <div>
                                      <Field
                                        name="vitalSign_name2"
                                        component="input"
                                        type="text"
                                        placeholder=""
                                        className="input-textSize"
                                      >
                                        {({ input, meta }) => (
                                          <>
                                            <label>
                                              <span className="text-ordered-list mr-1">
                                                Please document who you spoke
                                                with at the Agency and any
                                                instructions you were given.
                                              </span>
                                            </label>
                                            {toSave ? (
                                              <p>{values.vitalSign_name2}</p>
                                            ) : (
                                              <textarea
                                                maxlength="320"
                                                className="Additional_text  w-100"
                                                {...input}
                                                placeholder=""
                                              />
                                            )}

                                            {meta.error && meta.touched && (
                                              <span className="meta_err">
                                                {meta.error}
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      ))
                    }
                  </FieldArray>
                  <h5 className="py-4 text-decoration-underline">Comments :</h5>
                  <FieldArray name="Pain">
                    {({ fields }) =>
                      fields.map((name) => (
                        <>
                          <Field
                            name={`${name}.comment`}
                            component="input"
                            type="text"
                            placeholder=""
                            className="input-textSize"
                          >
                            {({ input, meta }) => (
                              <div>
                                {toSave ? (
                                  <p>{values[`${name}.comment`]}</p>
                                ) : (
                                  <textarea
                                    maxlength="320"
                                    className="Additional_text"
                                    {...input}
                                    disabled={disableByVisitStatus()}
                                  />
                                )}

                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </>
                      ))
                    }
                  </FieldArray>
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    Homebound Eligibility :
                  </h5>
                  {toSave ? (
                    <div>
                      {values.HomeboundEligibility &&
                        values.HomeboundEligibility.map((item) => {
                          return <span>{`${item.label},`}</span>;
                        })}
                    </div>
                  ) : (
                    <Field name="HomeboundEligibility">
                      {({ input, meta }) => (
                        <div>
                          <Select
                            {...input}
                            isMulti
                            options={reactSelectOptionCreate(
                              formOptions.HomeboundEligibility,
                              "name",
                              "name",
                              meta.initial
                            )}
                            isDisabled={disableByVisitStatus()}
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  )}
                  <h5 className="py-4 text-decoration-underline">Comments :</h5>
                  <Field
                    name="Comments_homebound"
                    component="input"
                    type="text"
                    placeholder=""
                    className="input-textSize"
                  >
                    {({ input, meta }) => (
                      <div>
                        {toSave ? (
                          <p>{values.Comments_homebound}</p>
                        ) : (
                          <textarea
                            maxlength="320"
                            className="Additional_text"
                            {...input}
                            type="text"
                            disabled={disableByVisitStatus()}
                          />
                        )}

                        {meta.error && meta.touched && (
                          <p className="alert alert-danger " role="alert">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>

                <div className="row mein-white-from-background justify-content-between h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    Interventions :
                  </h5>
                  <div className="col-md-12 mb-3 mt-3">
                    {toSave ? (
                      <div>
                        {values.Interventions &&
                          values.Interventions.map((item) => {
                            return <span>{`${item.label},`}</span>;
                          })}
                      </div>
                    ) : (
                      <Field name="Interventions">
                        {({ input, meta }) => (
                          <div>
                            <Select
                              {...input}
                              isMulti
                              options={reactSelectOptionCreate(
                                formOptions.interventations_PT,
                                "name",
                                "name",
                                meta.initial
                              )}
                              isDisabled={disableByVisitStatus()}
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    )}
                  </div>
                </div>
                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    Interventions/Education Provided :
                  </h5>
                  <Field
                    name="Interventions_Educations_input"
                    component="input"
                    type="text"
                    placeholder=""
                    className="input-textSize"
                  >
                    {({ input, meta }) => (
                      <div>
                        {toSave ? (
                          <p>{values.Interventions_Educations_input}</p>
                        ) : (
                          <textarea
                            maxlength="320"
                            className="Additional_text"
                            {...input}
                            type="text"
                            disabled={disableByVisitStatus()}
                          />
                        )}

                        {meta.error && meta.touched && (
                          <p className="alert alert-danger " role="alert">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
              </div>

              <div
                className={`followup-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    Patient Response :
                  </h5>
                  <Field
                    name="Patient_Response_input"
                    component="input"
                    type="text"
                    placeholder=""
                    className="input-textSize"
                  >
                    {({ input, meta }) => (
                      <div>
                        {toSave ? (
                          <p>{values.Patient_Response_input}</p>
                        ) : (
                          <textarea
                            maxlength="320"
                            className="Additional_text"
                            {...input}
                            type="text"
                            disabled={disableByVisitStatus()}
                          />
                        )}

                        {meta.error && meta.touched && (
                          <p className="alert alert-danger " role="alert">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0">
                  <h5 className="py-4">Goals</h5>
                  <div className="col-md-4 mb-3  mt-3">
                    {" "}
                    <h6>Goal :</h6>
                  </div>
                  <div className="col-md-4 mb-3  mt-3">
                    {" "}
                    <h6>Start Effective Date :</h6>
                  </div>
                  <div className="col-md-4 mb-3  mt-3">
                    {" "}
                    <h6>Current Status :</h6>
                  </div>
                  <FieldArray name="goals">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <>
                          <div className="col-md-4 mb-3 " key={index}>
                            <Field name={`${name}.goal`}>
                              {({ input, meta }) => (
                                <>
                                  <input
                                    {...input}
                                    maxlength="30"
                                    className="pteval-input"
                                    type="text"
                                    disabled={
                                      checkPreviousGoal(
                                        values.goals[index],
                                        index
                                      ) || disableByVisitStatus()
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger w-100"
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="col-md-4 mb-3   " key={index}>
                            <Field name={`${name}.effectiveDate`}>
                              {({ input, meta }) => (
                                <>
                                  <input
                                    {...input}
                                    className="pteval-input"
                                    type="date"
                                    min={effectiveDate()}
                                    disabled={
                                      checkPreviousGoal(
                                        values.goals[index],
                                        index
                                      ) || disableByVisitStatus()
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger w-100"
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="col-md-4 mb-3 " key={index}>
                            <Field name={`${name}.currentStatus`}>
                              {({ input, meta }) => (
                                <>
                                  <input
                                    maxlength="30"
                                    {...input}
                                    className="pteval-input"
                                    type="text"
                                    // disabled={!values.goals[index].goal}
                                    disabled={
                                      !values.goals[index].goal ||
                                      disableByVisitStatus()
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger w-100"
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                        </>
                      ))
                    }
                  </FieldArray>
                  {submitFailed && error && (
                    <p className="alert alert-danger " role="alert">
                      Enter Atleast 3 Fields
                    </p>
                  )}
                </div>
                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    Ongoing skilled need :
                  </h5>
                  <Field
                    name="Ongoing_Skilled_Need_input"
                    component="input"
                    type="text"
                    placeholder=""
                    className="input-textSize"
                  >
                    {({ input, meta }) => (
                      <div>
                        {toSave ? (
                          <p>{values.Ongoing_Skilled_Need_input}</p>
                        ) : (
                          <textarea
                            maxlength="320"
                            className="Additional_text"
                            {...input}
                            type="text"
                            disabled={disableByVisitStatus()}
                          />
                        )}

                        {meta.error && meta.touched && (
                          <p className="alert alert-danger " role="alert">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="row mein-white-from-background h5_line pt-0">
                  <FieldArray name="CHHA_Supervisory_Visits">
                    {({ fields, meta }) => (
                      <>
                        {fields.map((name, index) => (
                          <>
                            <div>
                              <h5 className="py-4 text-decoration-underline">
                                CHHA Supervisory Visit (if applicable) :
                              </h5>

                              <div className="row d-flex gap-2">
                                <div className="d-flex gap-3">
                                  <div>
                                    <label>
                                      Supervision performed this visit:
                                    </label>
                                  </div>
                                  <div className="radio-of-forms gap-3">
                                    <Field
                                      name={`${name}.Supervision_performed_this_visit`}
                                      component="input"
                                      type="radio"
                                      value="yes"
                                      disabled={disableByVisitStatus()}
                                    />
                                    <label>Yes </label>
                                    <Field
                                      name={`${name}.Supervision_performed_this_visit`}
                                      component="input"
                                      type="radio"
                                      value="no"
                                      disabled={disableByVisitStatus()}
                                    />
                                    <label>No </label>
                                  </div>
                                </div>
                                {meta.error &&
                                  meta?.error?.length > 0 &&
                                  meta.error[0]
                                    ?.Supervision_performed_this_visit &&
                                  meta.touched && (
                                    <p className="alert alert-danger">
                                      {meta.error?.length > 0 &&
                                        meta.error[0]
                                          ?.Supervision_performed_this_visit}
                                    </p>
                                  )}
                                {values.CHHA_Supervisory_Visits[0]
                                  .Supervision_performed_this_visit ===
                                  "yes" && (
                                  <>
                                    <div className="">
                                      <h6>Present: </h6>
                                      <div className="d-flex gap-2">
                                        <div className="radio-of-forms gap-3 chha-radio">
                                          <Field
                                            name={`${name}.Present`}
                                            component="input"
                                            type="radio"
                                            value="yes"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>Yes </label>
                                          <Field
                                            name={`${name}.Present`}
                                            component="input"
                                            type="radio"
                                            value="no"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>No </label>
                                        </div>
                                        {touched[
                                          "CHHA_Supervisory_Visits[0].Present"
                                        ] &&
                                          meta.error[index] &&
                                          meta.error[index]["Present"] && (
                                            <p className="alert alert-danger">
                                              {meta.error[index]["Present"]}
                                            </p>
                                          )}
                                        <div className="present_input_chha">
                                          <label className=" fw-bold mx-1">
                                            Name{" "}
                                          </label>
                                          <Field name={`${name}.Present_input`}>
                                            {({ input, meta }) => (
                                              <>
                                                <input
                                                  {...input}
                                                  type="text"
                                                  disabled={disableByVisitStatus()}
                                                />
                                                {meta.error && meta.touched && (
                                                  <p className="alert alert-danger">
                                                    {meta.error}
                                                  </p>
                                                )}
                                              </>
                                            )}
                                          </Field>
                                        </div>
                                      </div>
                                      {}
                                    </div>
                                    <div className="row">
                                      <div className="col-md-3">
                                        <h6>Following POC: </h6>
                                        <div className="radio-of-forms gap-3">
                                          <Field
                                            name={`${name}.Following_POC`}
                                            component="input"
                                            type="radio"
                                            value="yes"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>Yes </label>
                                          <Field
                                            name={`${name}.Following_POC`}
                                            component="input"
                                            type="radio"
                                            value="no"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>No </label>
                                        </div>
                                      </div>
                                      {touched[
                                        "CHHA_Supervisory_Visits[0].Following_POC"
                                      ] &&
                                        meta.error[index] &&
                                        meta.error[index]["Following_POC"] && (
                                          <p className="alert alert-danger">
                                            {meta.error[index]["Following_POC"]}
                                          </p>
                                        )}
                                      <div className="col-md-3">
                                        <h6>POC changed: </h6>
                                        <div className="radio-of-forms gap-3">
                                          <Field
                                            name={`${name}.POC_changed`}
                                            component="input"
                                            type="radio"
                                            value="yes"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>Yes </label>
                                          <Field
                                            name={`${name}.POC_changed`}
                                            component="input"
                                            type="radio"
                                            value="no"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>No </label>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <h6>
                                          Patient/responsible party/caregiver
                                          notified of change:{" "}
                                        </h6>
                                        <div className="radio-of-forms gap-3">
                                          <Field
                                            name={`${name}.Patient_Responsible_Party`}
                                            component="input"
                                            type="radio"
                                            value="na"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>NA </label>
                                          <Field
                                            name={`${name}.Patient_Responsible_Party`}
                                            component="input"
                                            type="radio"
                                            value="yes"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>Yes </label>
                                          <Field
                                            name={`${name}.Patient_Responsible_Party`}
                                            component="input"
                                            type="radio"
                                            value="no"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>No </label>
                                        </div>
                                      </div>
                                      {touched[
                                        "CHHA_Supervisory_Visits[0].Patient_Responsible_Party"
                                      ] &&
                                        meta.error[index] &&
                                        meta.error[index][
                                          "Patient_Responsible_Party"
                                        ] && (
                                          <p className="alert alert-danger">
                                            {
                                              meta.error[index][
                                                "Patient_Responsible_Party"
                                              ]
                                            }
                                          </p>
                                        )}
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <h6>
                                          Communicates openly with pt, rep (if
                                          any), cg and family:{" "}
                                        </h6>
                                        <div className="radio-of-forms gap-3">
                                          <Field
                                            name={`${name}.Communicates_openly`}
                                            component="input"
                                            type="radio"
                                            value="yes"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>Yes </label>
                                          <Field
                                            name={`${name}.Communicates_openly`}
                                            component="input"
                                            type="radio"
                                            value="no"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>No </label>
                                        </div>
                                        {touched[
                                          "CHHA_Supervisory_Visits[0].Communicates_openly"
                                        ] &&
                                          meta.error[index] &&
                                          meta.error[index][
                                            "Communicates_openly"
                                          ] && (
                                            <p className="alert alert-danger">
                                              {
                                                meta.error[index][
                                                  "Communicates_openly"
                                                ]
                                              }
                                            </p>
                                          )}
                                      </div>
                                      <div className="col-md-6">
                                        <h6>
                                          Reports changes in patient condition:{" "}
                                        </h6>
                                        <div className="radio-of-forms gap-3">
                                          <Field
                                            name={`${name}.Reports_changes`}
                                            component="input"
                                            type="radio"
                                            value="yes"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>Yes </label>
                                          <Field
                                            name={`${name}.Reports_changes`}
                                            component="input"
                                            type="radio"
                                            value="no"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>No </label>
                                        </div>
                                        {touched[
                                          "CHHA_Supervisory_Visits[0].Reports_changes"
                                        ] &&
                                          meta.error[index] &&
                                          meta.error[index][
                                            "Reports_changes"
                                          ] && (
                                            <p className="alert alert-danger">
                                              {
                                                meta.error[index][
                                                  "Reports_changes"
                                                ]
                                              }
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <h6>
                                          Demonstrates competency with assigned
                                          tasks:
                                        </h6>
                                        <div className="radio-of-forms gap-3">
                                          <Field
                                            name={`${name}.Demonstrates_competency`}
                                            component="input"
                                            type="radio"
                                            value="yes"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>Yes </label>
                                          <Field
                                            name={`${name}.Demonstrates_competency`}
                                            component="input"
                                            type="radio"
                                            value="no"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>No </label>
                                        </div>
                                        {touched[
                                          "CHHA_Supervisory_Visits[0].Demonstrates_competency"
                                        ] &&
                                          meta.error[index] &&
                                          meta.error[index][
                                            "Demonstrates_competency"
                                          ] && (
                                            <p className="alert alert-danger">
                                              {
                                                meta.error[index][
                                                  "Demonstrates_competency"
                                                ]
                                              }
                                            </p>
                                          )}
                                      </div>
                                      <div className="col-md-6">
                                        <h6>Honors patient rights:</h6>
                                        <div className="radio-of-forms gap-3">
                                          <Field
                                            name={`${name}.Honors_patient`}
                                            component="input"
                                            type="radio"
                                            value="yes"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>Yes </label>
                                          <Field
                                            name={`${name}.Honors_patient`}
                                            component="input"
                                            type="radio"
                                            value="no"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>No </label>
                                        </div>
                                        {touched[
                                          "CHHA_Supervisory_Visits[0].Honors_patient"
                                        ] &&
                                          meta.error[index] &&
                                          meta.error[index][
                                            "Honors_patient"
                                          ] && (
                                            <p className="alert alert-danger">
                                              {
                                                meta.error[index][
                                                  "Honors_patient"
                                                ]
                                              }
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <h6>
                                          Complies with infection
                                          prevention/control
                                          policies/procedures:
                                        </h6>
                                        <div className="radio-of-forms gap-3">
                                          <Field
                                            name={`${name}.Complies_with_infection_prevention`}
                                            component="input"
                                            type="radio"
                                            value="yes"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>Yes </label>
                                          <Field
                                            name={`${name}.Complies_with_infection_prevention`}
                                            component="input"
                                            type="radio"
                                            value="no"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>No </label>
                                        </div>
                                        {touched[
                                          "CHHA_Supervisory_Visits[0].Complies_with_infection_prevention"
                                        ] &&
                                          meta.error[index] &&
                                          meta.error[index][
                                            "Complies_with_infection_prevention"
                                          ] && (
                                            <p className="alert alert-danger">
                                              {
                                                meta.error[index][
                                                  "Complies_with_infection_prevention"
                                                ]
                                              }
                                            </p>
                                          )}
                                      </div>
                                      <div className="col-md-6">
                                        <h6>Patient/Staff compatible:</h6>
                                        <div className="radio-of-forms gap-3">
                                          <Field
                                            name={`${name}.Patient_Staff_compatible`}
                                            component="input"
                                            type="radio"
                                            value="yes"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>Yes </label>
                                          <Field
                                            name={`${name}.Patient_Staff_compatible`}
                                            component="input"
                                            type="radio"
                                            value="no"
                                            disabled={disableByVisitStatus()}
                                          />
                                          <label>No </label>
                                        </div>
                                        {touched[
                                          "CHHA_Supervisory_Visits[0].Patient_Staff_compatible"
                                        ] &&
                                          meta.error[index] &&
                                          meta.error[index][
                                            "Patient_Staff_compatible"
                                          ] && (
                                            <p className="alert alert-danger">
                                              {
                                                meta.error[index][
                                                  "Patient_Staff_compatible"
                                                ]
                                              }
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="">
                                        <h5 className="">Specify details:</h5>
                                        <Field
                                          name={`${name}.specifyDetails`}
                                          component="input"
                                          type="text"
                                          placeholder=""
                                          className="input-textSize"
                                        >
                                          {({ input, meta }) => (
                                            <div>
                                              {toSave ? (
                                                <p>
                                                  {
                                                    values[
                                                      `${name}.specifyDetails`
                                                    ]
                                                  }
                                                </p>
                                              ) : (
                                                <textarea
                                                  maxlength="320"
                                                  className="Additional_text"
                                                  {...input}
                                                  type="text"
                                                />
                                              )}

                                              {meta.error && meta.touched && (
                                                <p
                                                  className="alert alert-danger "
                                                  role="alert"
                                                >
                                                  {meta.error}
                                                </p>
                                              )}
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </div>
              </div>
              <div
                className={`followup-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
                {/* <div className="row mein-white-from-background h5_line pt-0">
                <h5 className="py-4 text-decoration-underline">
                  Ongoing skilled need :
                </h5>
                <Field
                  name="Ongoing_Skilled_Need_input"
                  component="input"
                  type="text"
                  placeholder=""
                  className="input-textSize"
                >
                  {({ input, meta }) => (
                    <div>
                      <textarea
                        maxlength="320"
                        className="Additional_text"
                        {...input}
                        type="text"
                      />
                      {meta.error && meta.touched && (
                        <p className="alert alert-danger " role="alert">
                          {meta.error}
                        </p>
                      )}
                    </div>
                  )}
                </Field>
              </div> */}

                <div className="row mein-white-from-background justify-content-between h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    Care Coordination(if any) :
                  </h5>
                  <label>Care Coordination with :</label>
                  {care_coordination.map((checkboxItem) => (
                    <div className="col-md-4 mt-3">
                      <div className="radio-of-forms gap-3">
                        <Field
                          name={`care_coordination`}
                          component="input"
                          type="checkbox"
                          value={checkboxItem}
                          disabled={disableByVisitStatus()}
                        />
                        {checkboxItem}
                      </div>
                    </div>
                  ))}
                  {touched.care_coordination && errors.care_coordination && (
                    <p className="alert alert-danger " role="alert">
                      {errors.care_coordination}
                    </p>
                  )}

                  <div className="col-md-12">
                    <Field
                      name="care_coordinationTextBox"
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div>
                          <label className="w-100">
                            <span className="text-ordered-list mr-1">
                              Additional :
                            </span>
                          </label>
                          {toSave ? (
                            <p>{values["care_coordinationTextBox"]}</p>
                          ) : (
                            <textarea
                              maxlength="320"
                              className=" Additional_text"
                              {...input}
                              type="text"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                          )}

                          {meta.touched && meta.error && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <label>Coordination Date :</label>
                    </div>
                    <div className="col-md-3">
                      <Field name={`Coordination_date`}>
                        {({ input, meta }) => (
                          <>
                            <input
                              {...input}
                              className="pteval-input"
                              type="date"
                              min={effectiveDate()}
                              disabled={disableByVisitStatus()}
                            />
                            {meta.error && meta.touched && (
                              <p
                                className="alert alert-danger w-100"
                                role="alert"
                              >
                                {meta.error}
                              </p>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="col-md-1">
                      <label>Time :</label>
                    </div>
                    <div className="col-md-6">
                      <Field
                        name="time"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              maxlength="45"
                              className="form-control"
                              {...input}
                              type="time"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>

                  <h5 className="py-4 text-decoration-underline">
                    Coordination Details :
                  </h5>
                  <Field
                    name="Coordination_Details_input"
                    component="input"
                    type="text"
                    placeholder=""
                    className="input-textSize"
                  >
                    {({ input, meta }) => (
                      <div>
                        {toSave ? (
                          <p>{values.Coordination_Details_input}</p>
                        ) : (
                          <textarea
                            maxlength="320"
                            className="Additional_text"
                            {...input}
                            type="text"
                            disabled={disableByVisitStatus()}
                          />
                        )}

                        {meta.error && meta.touched && (
                          <p className="alert alert-danger " role="alert">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className="py-4 text-decoration-underline">
                    Discharge Planning :
                  </h5>

                  {toSave ? (
                    <div>
                      {values.PlanningRequests &&
                        values.PlanningRequests.map((item) => {
                          return <span>{`${item.label},`}</span>;
                        })}
                    </div>
                  ) : (
                    <Field name="PlanningRequests">
                      {({ input, meta }) => (
                        <div>
                          <Select
                            {...input}
                            isMulti
                            options={reactSelectOptionCreate(
                              formOptions.DischargePlanning,
                              "name",
                              "name",
                              meta.initial
                            )}
                            isDisabled={true}
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  )}
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0 ">
                  <h5 className=" py-4 text-decoration-underline">
                    Plans for next visit :
                  </h5>
                  <FieldArray name="Plans_for_next_visit">
                    {({ fields, meta }) => (
                      <>
                        {fields.map((name, index) => (
                          <>
                            <div className="row">
                              <div className="col-md-6 my-2">
                                <div className="d-flex justify-content-between ">
                                  <div>
                                    <Field
                                      name={`${name}.Assessment`}
                                      component="input"
                                      type={"checkbox"}
                                      value={"AssessmentWith"}
                                      disabled={disableByVisitStatus()}
                                    />
                                    <label className="mx-2"> Assessment</label>
                                  </div>
                                  <div>
                                    <Field name={`${name}.Assessment_input`}>
                                      {({ input, meta }) => (
                                        <div>
                                          <input
                                            type="text"
                                            className="form-control"
                                            {...input}
                                            disabled={disableByVisitStatus()}
                                          />
                                          {meta.error && meta.touched && (
                                            <p className=" alert alert-danger meta_err">
                                              {meta.error}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 my-2">
                                <div className="d-flex justify-content-between ">
                                  <div>
                                    <Field
                                      name={`${name}.Treatments_Procedures_Therapy`}
                                      component="input"
                                      type={"checkbox"}
                                      value={
                                        "Treatments_Procedures_Therapy_With"
                                      }
                                      disabled={disableByVisitStatus()}
                                    />
                                    <label className="mx-2">
                                      Treatments/Procedures/Therapy{" "}
                                    </label>
                                  </div>

                                  <div>
                                    <Field
                                      name={`${name}.Treatments_Procedures_Therapy_input`}
                                    >
                                      {({ input, meta }) => (
                                        <div>
                                          <input
                                            type="text"
                                            className="form-control"
                                            {...input}
                                            disabled={disableByVisitStatus()}
                                          />
                                          {meta.error && meta.touched && (
                                            <p className=" alert alert-danger meta_err">
                                              {meta.error}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 my-2">
                                <div className="d-flex justify-content-between ">
                                  <div>
                                    <Field
                                      name={`${name}.Teaching`}
                                      component="input"
                                      type="checkbox"
                                      value={"Teaching_With"}
                                      disabled={disableByVisitStatus()}
                                    />
                                    <label className="mx-2">Teaching </label>
                                  </div>
                                  <div>
                                    <Field name={`${name}.Teaching_input`}>
                                      {({ input, meta }) => (
                                        <div>
                                          <input
                                            type="text"
                                            className="form-control"
                                            {...input}
                                            disabled={disableByVisitStatus()}
                                          />
                                          {meta.error && meta.touched && (
                                            <p className=" alert alert-danger meta_err">
                                              {meta.error}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 my-2">
                                <div className="d-flex justify-content-between ">
                                  <div>
                                    <Field
                                      name={`${name}.Preparation_for_discharge`}
                                      component="input"
                                      type={"checkbox"}
                                      value={"Preparation_for_discharge_With"}
                                      disabled={disableByVisitStatus()}
                                    />
                                    <label className="mx-2">
                                      Preparation for discharge input{" "}
                                    </label>
                                  </div>
                                  {/* <div>
                                    <Field
                                      name={`${name}.Preparation_for_discharge_input`}
                                    >
                                      {({ input, meta }) => (
                                        <div>
                                          <input
                                            type="text"
                                            className="form-control"
                                            {...input}
                                            disabled={disableByVisitStatus()}
                                          />
                                          {meta.error && meta.touched && (
                                            <p className=" alert alert-danger meta_err">
                                              {meta.error}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </div> */}
                                </div>
                              </div>

                              <div className=" col-md-6 my-4">
                                <div className="d-flex justify-content-between ">
                                  <div>
                                    <Field
                                      name={`${name}.other`}
                                      component="input"
                                      type="checkbox"
                                      value={"other_with"}
                                      disabled={disableByVisitStatus()}
                                    />
                                    <label className="mx-2">Other </label>
                                  </div>
                                  <div>
                                    <Field name={`${name}.other_input`}>
                                      {({ input, meta }) => (
                                        <div>
                                          <input
                                            type="text"
                                            className="form-control"
                                            {...input}
                                            disabled={disableByVisitStatus()}
                                          />
                                          {meta.error && meta.touched && (
                                            <p className=" alert alert-danger meta_err">
                                              {meta.error}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {meta.touched && planError && (
                              <p className="alert alert-danger " role="alert">
                                Select atleast one
                              </p>
                            )}
                          </>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </div>
                <div className="row mein-white-from-background justify-content-between ">
                  <div className="col-md-4">
                    <div>
                      <Field
                        name="Patientsignature"
                        className="form-control mb-3 "
                        component="input"
                        placeholder="Patient signature"
                        disabled={disableByVisitStatus()}
                      />
                    </div>
                    {/* <div>
                    <Field
                      name="Therapistsignature"
                      className="form-control mb-3 mt-4"
                      component="input"
                      placeholder="Therapist signature"
                      disabled={disableByVisitStatus()}
                    />
                  </div> */}
                  </div>

                  <div className="col-md-12">
                    <div style={{ marginTop: "30px" }}></div>
                    <div>
                      <div className="">
                        <div className="d-flex gap-2 align-items-center my-3">
                          <span> Therapist Signature: </span>
                          {signatureAdded || values.Therapistsignature ? (
                            <img
                              src={
                                displaySignature
                                  ? displaySignature
                                  : values.Therapistsignature
                              }
                              height={"100px"}
                              width={"300px"}
                              crossOrigin="anonymous"
                            />
                          ) : (
                            <input
                              type="text"
                              placeholder="Therapist Signature"
                              disabled
                              className=" pl-0 border-0 border-right-2 border-left-2 border-bottom-2"
                            />
                          )}
                        </div>

                        {currentUser &&
                          currentUser.Role &&
                          currentUser.Role.key &&
                          (currentUser.Role.key ===
                            currentRole.supervisingtherapist ||
                            currentUser.Role.key ===
                              currentRole.assistanttherapist) &&
                          !toSave && (
                            <button
                              className="Approve-btns ml-0"
                              onClick={(e) => {
                                e.preventDefault();
                                setIsCanvasShow(true);
                              }}
                              disabled={
                                visitDetailById &&
                                !canSubmitForm.includes(getFormStatus())
                              }
                            >
                              {signatureAdded || values.Therapistsignature
                                ? "Update Signature"
                                : "Add Signature"}
                            </button>
                          )}
                      </div>

                      {submitFailed &&
                        !values.Therapistsignature &&
                        !displaySignature && (
                          <p className="alert alert-danger my-2 " role="alert">
                            Signature is required.
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="col-md-12 mt-4">
                    <p>
                      Physician Name :
                      <span className="ml-5">
                        {staffDetails &&
                        staffDetails.staffingDetails &&
                        staffDetails.staffingDetails.physicianName
                          ? staffDetails.staffingDetails.physicianName
                          : "--"}
                      </span>
                    </p>

                    <div className="row d-flex  align-items-center  ">
                      <div className="col-md-6">
                        <div className="d-flex align-items-start">
                          <label className="no-wrap-white-space mb-0">
                            Physician Signature :
                          </label>
                          <Field
                            name="Physiciansignature"
                            className="Additional_text ml-3 "
                            component="textarea"
                            placeholder=""
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="d-flex align-items-start mb-0">
                          <label className="mb-0 text-nowrap">Date :</label>
                          <Field
                            name="date"
                            className="Additional_text ml-3 "
                            component="textarea"
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {currentUser &&
              currentUser.Role &&
              currentUser.Role.key &&
              (currentUser.Role.key === currentRole.supervisingtherapist ||
                currentUser.Role.key === currentRole.assistanttherapist) && (
                <div className="container">
                  <div className="py-5 ">
                    <button
                      className="Approve-btns h-auto"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!valid) handleSubmit();
                        if (valid) setShow(true);
                        else
                          toast.error("Please complete all required fields.");
                      }}
                      disabled={
                        visitDetailById &&
                        !canSubmitForm.includes(getFormStatus())
                      }
                    >
                      Submit
                    </button>
                    <button
                      className="Approve-btns  mx-2 h-auto"
                      onClick={(e) => {
                        e.preventDefault();
                        onSubmit(values);
                      }}
                      disabled={
                        visitDetailById &&
                        !canSubmitForm.includes(getFormStatus())
                      }
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            {show && (
              <Modal
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                centered
              >
                <Modal.Header className="pl-4 pr-4 pt-4 pb-3" closeButton>
                  <Modal.Title>Complete</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pl-4 pr-4 pt-0 pb-4">
                  {/* By confirming this you will mark the {activeForm && activeForm.name}  document as complete. Are you sure? */}
                  By confirming this you will mark the Follow-up document as
                  completed. Are you sure?
                  <div className="mt-4">
                    <Button
                      className="Discard-danger"
                      variant="outline-danger"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      className="primary-confirm"
                      onClick={(e) => {
                        e.preventDefault();
                        if (valid)
                          onSubmit(values, form, visitStatus.needreview);
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </form>
        )}
      />
      {isCanvasShow && (
        <SignatureCanvas
          loadDoc={saveForm}
          isShow={isCanvasShow}
          setSignatureAdded={setSignatureAdded}
          handleClose={() => setIsCanvasShow(false)}
        />
      )}
    </div>
  );
};

export default FollowUpForm;
