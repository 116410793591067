import React, { useEffect, useState } from "react";
import { Container, Row, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ChatHeader from "../../components/common/ChatComponents/ChatHeader";
import ChatList from "../../components/common/ChatComponents/ChatList";
import ChatScreen from "../../components/common/ChatComponents/ChatScreen";
import Loader from "../../components/Loader";
import crossIcon from "../../images/x.svg";
import { Client } from "@twilio/conversations";
import {
  getActivityLog,
  conversationPaginator,
  getAccessToken,
  getPatientList,
  conversationAdded,
  setActiveConversation,
} from "../../Redux/actions/chat";
import {
  setChatLoader,
  setClientState,
  unreadMessageCheck,
} from "../../Redux/slices/chatSlice";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { currentRole } from "../../utils/auth";

export default function ChatModal(props) {
  const { show, handleClose } = props;
  const dispatch = useDispatch();
  const moment = require('moment-timezone');

  const isChatLoading = useSelector((state) => state.chatSlice.isChatLoading);
  const currentUserDetails = useSelector(
    (state) => state.adminSlice.currentActiveUserDetails
  );
  const isConversationActive = useSelector(
    (state) => state.chatSlice.isConversationActive
  );
  const activeConversation = useSelector(
    (state) => state.chatSlice.activeConversation
  );
  const initializeClient = useSelector(
    (state) => state.chatSlice.initializeClient
  );
  const isClientReady = useSelector((state) => state.chatSlice.isClientReady);
  const ActivityLog = useSelector((state) => state.chatSlice.activityLog);
  const currentUserRole = currentUserDetails?.Role;

  const [dataValue, setDataValue] = useState(0);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [downloadChat, setDownloadChat] = useState(false);

  const addItemToDownload = (item) => {
    if (selectedMessages.length === 0) {
      setSelectedMessages([item]);
    } else {
      const isExist = selectedMessages.find((Ele) => Ele.index === item.index);
      if (isExist) {
        const filtered = selectedMessages.filter(
          (ele) => ele.index !== item.index
        );
        setSelectedMessages(filtered);
      } else {
        setSelectedMessages([...selectedMessages, item]);
      }
    }
  };
  const handleConnection = (connectionStatus) => {
    if (connectionStatus === "connected") {
      dispatch(setClientState(true));
    }
    if (connectionStatus === "denied") {
      toast.warn("Connection Failed.");
    }
    if (connectionStatus === "failed") {
      toast.error("Connection Failed.");
      dispatch(setChatLoader(false));
    }
  };

  const handleAccessToken = () => {
    dispatch(setClientState(false));
    dispatch(getAccessToken()).then((res) => {
      if (res && res.payload && res.payload.data) {
        initClient(res.payload.data);
      }
    });
  };
  const addNewConversation = (newConversation, clientInitialized) => {
    if (clientInitialized) {
      dispatch(conversationAdded(newConversation));
      dispatch(setActiveConversation(newConversation));
      dispatch(getPatientList());
    }
  };

  const handleConversationList = (TwilioClient, clientInitialized) => {
    if (clientInitialized) {
      TwilioClient.getSubscribedConversations().then((Paginator) => {
        dispatch(conversationPaginator(Paginator));
      });
    }
  };
  const handleUnreadMessage = (AddNewMessage) => {
    dispatch(unreadMessageCheck(true));
  };
  const initClient = async (token) => {
    const TwilioClient = await new Client(token);
    let clientInitialized = false;
    window.TwilioClient = TwilioClient;

    TwilioClient.on("stateChanged", handleConnection);
    TwilioClient.on("connectionStateChanged", handleConnection);
    TwilioClient.on("tokenAboutToExpire", handleAccessToken);
    TwilioClient.on("tokenExpired", handleAccessToken);
    TwilioClient.on("conversationUpdated", (updatedConversation) =>
      handleConversationList(TwilioClient, clientInitialized)
    );
    TwilioClient.getSubscribedConversations().then((Paginator) => {
      dispatch(conversationPaginator(Paginator));
      dispatch(getPatientList());
      clientInitialized = true;
    });
    TwilioClient.on("conversationAdded", (newConversation) =>
      addNewConversation(newConversation, clientInitialized)
    );
    TwilioClient.on("messageAdded", handleUnreadMessage);
  };
  useEffect(() => {
    if (!isClientReady && initializeClient) {
      dispatch(getAccessToken()).then((res) => {
        if (res && res.payload && res.payload.data) {
          initClient(res.payload.data);
        }
      });
    }
    if (dataValue === 1) {
      if (
        activeConversation &&
        activeConversation.attributes &&
        activeConversation.attributes.id
      ) {
        dispatch(getActivityLog(activeConversation.attributes.id));
      }
    }
    return () => {
      const { TwilioClient } = window;
      if (TwilioClient) {
        TwilioClient.removeListener("stateChanged", handleConnection);
        TwilioClient.removeListener("connectionStateChanged", handleConnection);
        TwilioClient.removeListener("tokenAboutToExpire", handleAccessToken);
        TwilioClient.removeListener("tokenExpired", handleAccessToken);
        TwilioClient.removeListener("messageAdded", handleUnreadMessage);
        TwilioClient.removeListener("conversationAdded", (newConversation) =>
          addNewConversation(newConversation, false)
        );
      }
    };
  }, [dataValue, initializeClient]);

  const saveToPdf = () => {
    if (
      currentUserRole.key === currentRole.assistanttherapist ||
      currentUserRole.key === currentRole.supervisingtherapist
    ) {
      return;
    }
    const pdf = new jsPDF("p", "pt", "a4");
    const columns = [""];
    var rows = [];
    if (dataValue === 0) {
      if (selectedMessages.length !== 0) {
        for (let i = 0; i < selectedMessages.length; i++) {
          let temp = [];
          temp = [
            `[${selectedMessages[i].dateCreated}]  ${selectedMessages[i].name} : ${selectedMessages[i].messageBody}`,
          ];
          rows.push(temp);
        }
        pdf.text(
          125,
          30,
          `Communication Notes for ${activeConversation && activeConversation.friendlyName}  (#MR ${activeConversation && activeConversation.attributes.mrn})`
        );
      }
    } else if (dataValue === 1) {
      for (let i = 0; i < ActivityLog.length; i++) {
        let temp = [];
        temp = [
          `${ActivityLog[i].forUser && ActivityLog[i].forUser.name
            ? `[${moment(ActivityLog[i].createdAt).tz(moment.tz.guess()).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)')}] [${ActivityLog[i].forUser.name} ${ActivityLog[i].forUser.userDetail.discipline
              ? `(${ActivityLog[
                i
              ].forUser.userDetail.discipline.toUpperCase()})`
              : ""
            }]`
            : `[${moment(ActivityLog[i].createdAt).tz(moment.tz.guess()).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)')}] [${ActivityLog[i].forUser.lastName}, ${ActivityLog[i].forUser.firstName
            } ${ActivityLog[i].forUser.userDetail.discipline
              ? `(${ActivityLog[
                i
              ].forUser.userDetail.discipline.toUpperCase()})`
              : ""
            }] : `
          }  ${ActivityLog[i].title} ${ActivityLog[i].status !== null ? ActivityLog[i].status : ""
          } `,
        ];
        rows.push(temp);
      }
      pdf.text(
        160,
        30,
        `Activity Log for ${activeConversation && activeConversation.friendlyName
        } (#MR ${activeConversation && activeConversation.attributes.mrn})`
      );
    }
    if (rows.length === 0) {
      toast.info("Please select at least one message.");
    } else {
      pdf.autoTable(columns, rows, {
        startY: 40,
        styles: {
          font: "times",
          halign: "left",
          cellPadding: 0.0,
        },
        headStyles: {
          textColor: [0, 0, 0],
          fontStyle: "normal",
          lineColor: [255, 255, 255],
          fillColor: [255, 255, 255],
        },
        alternateRowStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineWidth: 0.0,
          lineColor: [255, 255, 255],
        },
      });
      pdf.save("_Logs");
    }

    setDownloadChat(false);
    setSelectedMessages([]);
  };

  return (
    <Modal className="ApprovM" show={show} onHide={handleClose}>
      <Modal.Body className="p-0">
        <Container fluid>
          {isChatLoading && (
            <div className="chatLoader">
              <Loader />
            </div>
          )}
          <Row>
            <div
              className="col-md-2_5 p-0"
              style={{
                zIndex: "11",
                boxShadow: "0px 3px 10px #00000029",
              }}
            >
              <ChatList
                dataValue={dataValue}
                setSelectedMessages={setSelectedMessages}
              />
            </div>
            <div className="col-md-9_5 chat-display-none p-0">
              {isConversationActive ? (
                <>
                  <ChatHeader
                    dataValue={dataValue}
                    setDataValue={setDataValue}
                    setDownloadChat={setDownloadChat}
                    saveToPdf={saveToPdf}
                  />
                  <ChatScreen
                    dataValue={dataValue}
                    downloadChat={downloadChat}
                    setDownloadChat={setDownloadChat}
                    addItemToDownload={addItemToDownload}
                    selectedMessages={selectedMessages}
                    handleClose={handleClose}
                    saveToPdf={saveToPdf}
                    setSelectedMessages={setSelectedMessages}
                  />
                </>
              ) : (
                <div className="w-100 h-100 position-relative d-flex align-items-center justify-content-center">
                  {currentUserDetails && (
                    <h2>
                      Welcome{" "}
                      {currentUserDetails.name
                        ? `${currentUserDetails.name.toUpperCase()}`
                        : `${
                            currentUserDetails.lastName
                              ? currentUserDetails.lastName.toUpperCase()
                              : ""
                          }, ${
                            currentUserDetails.firstName
                              ? currentUserDetails?.firstName.toUpperCase()
                              : ""
                          }`}
                    </h2>
                  )}
                  <div
                    id="times2"
                    className="position-absolute"
                    style={{ bottom: "23px", right: "19px" }}
                    onClick={() => handleClose()}
                  >
                    <img src={crossIcon} alt="" />
                  </div>
                </div>
              )}
            </div>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
