import { createSlice } from "@reduxjs/toolkit";
import {
  getPatientDocTypes,
  getUserDocumentsList,
  getAddPatientDoc,
  getDefaultDocTypes,
  getPatientDocs,
} from "../actions/patientGetDocTypes";
const initialState = {
  isUploadDocTypes: [],
  AddPatientDoc: [],
  doclist: [],
  defaultList: [],
  visitDetail: {},
  staffFormData: [],
  isUploadingDoc: false,
  fetchingDocuments: false,
  fetchingFormData: false
};

const patientGetDocSlice = createSlice({
  name: "patientGetDocTypesSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [getDefaultDocTypes.fulfilled]: (state, { payload }) => {
      state.defaultList = payload?.types;
    },
    [getDefaultDocTypes.pending]: (state, { payload }) => { },
    [getDefaultDocTypes.resolved]: (state, { payload }) => { },

    [getPatientDocTypes.fulfilled]: (state, { payload }) => {
      state.doclist = payload;

      state.isUploadDocTypes = true;
    },
    [getPatientDocTypes.pending]: (state, { payload }) => {
      state.isUploadDocTypes = true;
    },
    [getPatientDocTypes.rejected]: (state, { payload }) => {
      state.isUploadDocTypes = true;
    },
    [getUserDocumentsList.fulfilled]: (state, { payload }) => {
      state.doclist = payload;
      state.isLoading = false;
    },
    [getUserDocumentsList.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getUserDocumentsList.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getAddPatientDoc.fulfilled]: (state, { payload }) => {
      state.AddPatientDoc = payload;
      state.isUploadingDoc = false;
    },
    [getAddPatientDoc.pending]: (state, { payload }) => {
      state.AddPatientDoc = payload;
      state.isUploadingDoc = true;
    },
    [getAddPatientDoc.rejected]: (state, { payload }) => {
      state.AddPatientDoc = payload;
      state.isUploadingDoc = false;
    },
    [getPatientDocs.fulfilled]: (state, { payload }) => {
      state.fetchingDocuments = false
      state.visitDetail = payload?.data;
    },
    [getPatientDocs.pending]: (state, { payload }) => {
      state.fetchingDocuments = true
    },
    [getPatientDocs.rejected]: (state, { payload }) => {
      state.fetchingDocuments = false
    },
  },
});

export default patientGetDocSlice.reducer;
