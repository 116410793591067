import React, { useState } from "react";
import { useEffect } from "react";
import { RateTabsName, rateDiscipline } from "../../utils/helper";
export const ViewVisitRate = ({
  defaultRates,
  userRates,
  myClass,
  disciplinesNeeded,
  therapist,
  makeOffer,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [filterRates, setFilterRate] = useState();

  useEffect(() => {
    const disciplines = disciplinesNeeded && Object?.keys(disciplinesNeeded);
    if (defaultRates && defaultRates?.length > 0) {
      const filterRate = defaultRates?.filter((item) => {
        if (item?.id === 1 && disciplines?.includes(rateDiscipline.pt)) {
          return item;
        } else if (item?.id === 2 && disciplines?.includes(rateDiscipline.ot)) {
          return item;
        } else if (
          item?.id === 3 &&
          disciplines?.includes(rateDiscipline.slp)
        ) {
          return item;
        }
      });
      setFilterRate(filterRate);
    }
  }, [defaultRates, disciplinesNeeded]);

  const getUserRates = (tabId, tabDetailId) => {
    let price = 0;
    if (therapist && therapist?.userRates) {
      let rate = therapist?.userRates.find(
        (item) =>
          item?.rateTabDetailId === tabDetailId && item?.rateTabId === tabId
      );
      if (rate) {
        price = rate?.price;
      }
    } else {
      if (userRates && userRates?.length > 0) {
        let rate = userRates?.find(
          (item) =>
            item?.rateTabDetailId === tabDetailId &&
            (makeOffer ? item?.rateTabid === tabId : item?.rateTabId === tabId)
        );
        if (rate) {
          price = makeOffer ? rate?.myprice : rate?.price;
        }
      }
    }
    return price;
  };
  const sortDataById = (rates) => {
    if (rates) {
      let sortArray = [...rates];
      sortArray.sort(function (a, b) {
        return a.id - b.id;
      });
      return sortArray;
    }
  };

  return (
    <>
      <div
        className={
          myClass
            ? "w-100 d-flex justify-content-start gap-5 head-margin border-bottom"
            : "mobile_width  flex-wrap d-flex justify-content-start gap-5 head-margin border-bottomss"
        }
      >
        {therapist && therapist?.userRates ? (
          <p
            className={
              activeTab === 0
                ? "new-agencyactive mb-0 pb-0 "
                : "new-agency m-0 "
            }
          >
            {RateTabsName[therapist?.userRates[0]?.rateTab?.name]}
          </p>
        ) : (
          filterRates &&
          filterRates?.map((item, index) => {
            return (
              <p
                className={
                  activeTab === index
                    ? "new-agencyactive  ad-activee  mb-0 pb-3 "
                    : "new-agency m-0 pb-3 agency_width_set"
                }
                onClick={(e) => setActiveTab(index)}
                key={`${item.displayName}_${index}`}
              >
                {item.displayName}
              </p>
            );
          })
        )}
      </div>
      <div className={myClass ? "w-100" : "mobile_width"}>
        {therapist
          ? sortDataById(therapist?.userRates)?.map((item, ind) => {
              return (
                <div
                  className=" d-flex justify-content-between flex-wrap align-items-center rate-margin"
                  key={`${item?.rateTabDetail?.name}_${ind}`}
                >
                  <label className="ad-labels New-rate-l width-50 m-0">
                    {item?.rateTabDetail?.name}
                  </label>
                  <div className=" flex-column mobile_width width-50">
                    <input
                      className="ad-input mobile_width m-0 width-50"
                      placeholder="$"
                      value={`$${getUserRates(
                        therapist?.userRates[activeTab]?.rateTab?.id,
                        item?.rateTabDetail?.id
                      )}`}
                      disabled
                    />
                  </div>
                </div>
              );
            })
          : filterRates &&
            filterRates[activeTab]?.RateTabDetails &&
            filterRates[activeTab]?.RateTabDetails &&
            filterRates[activeTab]?.RateTabDetails?.map((item, ind) => {
              if (
                (makeOffer && item.displayName.includes("PTA")) ||
                item.displayName.includes("COTA")
              ) {
                return;
              }
              return (
                <div
                  className=" d-flex flex-wrap  align-items-center rate-margin justify-content-start"
                  key={`${item?.displayName}_${ind}`}
                >
                  <label className="ad-labels New-rate-l m-0 labels_set_width">
                    {item?.displayName}
                  </label>
                  <div className=" flex-column  mobile_width mx-4">
                    <input
                      className="ad-input  mobile_width m-0 width_set text-primary"
                      placeholder="$"
                      value={`$${getUserRates(
                        filterRates[activeTab]?.id,
                        item.id
                      )}`}
                      disabled
                    />
                  </div>
                </div>
              );
            })}
      </div>
    </>
  );
};
