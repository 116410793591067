import React, { useEffect, useRef, useState } from "react";
import { updateProfileStatus } from "../../../Redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { decodeToken, defaultStatus } from "../../../utils/auth";
import Modal from "react-bootstrap/Modal";
import image from "../../../images/t&c.png";
import { GrClose } from "react-icons/gr";
import {
  backgroundReport,
  newBackgroundReportOrder,
} from "../../../Redux/actions/background";
import { createNewOrderBody } from "../../../utils/helper";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";

const BackgroundProcess = () => {
  const isBackgroundLoading = useSelector(
    (e) => e.therapistSigunpSlice.isBackgroundLoading
  );
  const divRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const [agreeError, setAgreeError] = useState(false);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let userDetail = null;
  if (searchParams && searchParams.get("auth")) {
    userDetail = decodeToken(searchParams.get("auth"));
  }

  const handleDivScroll = () => {
    const div = divRef.current;
    const { scrollTop, offsetHeight, scrollHeight } = div;
    const scrollBottom = scrollHeight - scrollTop - offsetHeight;
    setIsAtBottom(scrollBottom <= 2);
  };

  // useEffect(() => {
  //   const div = divRef.current;
  //   div.addEventListener("scroll", handleDivScroll);

  //   return () => {
  //     div.removeEventListener("scroll", handleDivScroll);
  //   };
  // }, []);
  const initBackgroundCheck = (data) => {
    let applicantWidget = window.ClearChecksApplicantWidget.init({
      reportKey: data.payload.report_key,

      onSuccess: function (data) {
        dispatch(
          backgroundReport({
            reportKey: data.report_key,
            userId: userDetail.userId,
          })
        ).then((e) => {
          if (!e.payload.success) {
            removeWidget();
            toast.error("An error occured, please try again.");
          }
        });
      },

      onClose: function (data) {
        dispatch(backgroundReport({ userId: userDetail.userId })).then((e) => {
          if (e.payload.success && !e.payload.reportKey) {
            dispatch(
              backgroundReport({
                userId: userDetail.userId,
                remove: true,
              })
            );
          } else if (e.payload.success && e.payload.reportKey) {
            setOpen(true);
          }
        });
      },

      onError: function (error) {
        dispatch(
          backgroundReport({
            userId: userDetail.userId,
            remove: true,
          })
        );
        removeWidget();
        toast.error("An error occured, please try again.");
      },
    });

    applicantWidget.open();
    function removeWidget() {
      applicantWidget.removeWidget();
    }
  };

  function handleSubmit() {
    createNewOrderBody.applicant_emails = [userDetail && userDetail.email];
    dispatch(newBackgroundReportOrder(createNewOrderBody)).then((data) => {
      if (!data?.payload?.report_key) {
        toast.error("An error occured, please try again.");
        dispatch(
          backgroundReport({
            userId: userDetail.userId,
            remove: true,
          })
        );
      } else {
        dispatch(
          updateProfileStatus({
            status: defaultStatus.pending,
            userId: userDetail.userId,
            onbordingPartCompleted: 3,
            pendingSteps: null,
          })
        ).then((e) => {
          if (e.payload.success) {
            initBackgroundCheck(data);
          }
        });
      }
    });
  }
  return (
    <>
      {isBackgroundLoading && <Loader />}
      <div className="pl-5">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-6 p-0">
              {PrefillDetails &&
                PrefillDetails.Status &&
                PrefillDetails.Status.key === defaultStatus.inprogress && (
                  <div variant={"success"} className="mb-4 congratulation-box">
                    <h4>Congratulations!</h4>
                    <p>
                      Part 2 of your on-boarding has been approved. Please
                      continue to Part 3 to complete your Background Check
                    </p>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="row pl-5">
        <div className="col-md-9">
          <h4 className="cs-heading set-head-th px-0 m-0 font-32 p-0">
            Background Check
          </h4>
        </div>
        <div className="col-md-9 d-flex gap-3 mb-5 justify-content-center align-items-center h-35vh">
          <button
            id="open-widget-btn"
            type="submit"
            className="coverage-btn background-submit-button"
            onClick={() => handleSubmit()}
          >
            Complete Background Check
          </button>
        </div>
      </div>

      <Modal
        show={open}
        onHide={() => {
          setOpen(false);
          navigate("/");
        }}
        className=" AgencyModal"
      >
        <div className="closeicon_div">
          <GrClose
            size={20}
            className="closeicon"
            onClick={() => {
              setOpen(false);
              navigate("/");
            }}
          />
        </div>
        <div className=" padding-m-1 ">
          <p className="ss-h4 mt-4 mb-4">
            Part 3 of your application was submitted successfully and your
            account is being reviewed. You will receive an email with
            instructions for the next step.
          </p>
          <img
            className="img-modal mt-3"
            src={image}
            alt="application submitted"
          />
        </div>
      </Modal>
    </>
  );
};

export default BackgroundProcess;
