import React, { useEffect, useState } from "react";
import { Card, Form, InputGroup } from "react-bootstrap";
import SearchIcon from "../../../images/search_grey_icon.svg";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  conversationNextPage,
  createConversation,
  getActivityLog,
  setActiveConversation,
} from "../../../Redux/actions/chat";
import { chatIconsByRole, urgentTypeArray } from "../../../utils/helper";
import { apiBasePath } from "../../../Redux/config/config";
import { setChatLoader } from "../../../Redux/slices/chatSlice";
import InfiniteScroll from "react-infinite-scroll-component";

export default function ChatList(props) {
  const { dataValue, setSelectedMessages } = props;
  const dispatch = useDispatch();
  const paginator = useSelector(
    (state) => state.chatSlice.conversationPaginator
  );
  const ConversationItems = useSelector(
    (state) => state.chatSlice.conversationPaginator?.items
  );
  const conversationNotCreateAPi = useSelector(
    (state) => state.chatSlice.patientList
  );
  const isClientReady = useSelector((state) => state.chatSlice.isClientReady);
  const [sortedConversation, setSortedConversation] = useState([]);
  const [urgentConversations, setUrgentConversaion] = useState([]);
  const [conversationNotCreate, setconversationNotCreate] = useState([]);
  const activeConversation = useSelector(
    (state) => state.chatSlice.activeConversation
  );
  const isConversationFetched = useSelector(
    (state) => state.chatSlice.isConversationFetched
  );
  const searchPatient = debounce((event) => {
    const {
      target: { value, name },
    } = event;
    SortList(value);
  }, 800);

  const chatbyId = (sid) => {
    setSelectedMessages([]);
    dispatch(setChatLoader(true));
    const selectedConversation = ConversationItems.find(
      (ele) => ele.sid === sid
    );
    dispatch(setActiveConversation(selectedConversation));
    if (dataValue === 1) {
      if (
        selectedConversation &&
        selectedConversation.attributes &&
        selectedConversation.attributes.id
      ) {
        dispatch(getActivityLog(selectedConversation.attributes.id));
      }
    }
  };

  const getTimeDiffOutput = (givenTimeStr) => {
    const givenTime = new Date(givenTimeStr);
    const currentTime = new Date();
    const timeDiffMs = currentTime - givenTime;
    const timeDiffHrs = timeDiffMs / (1000 * 60 * 60);

    if (timeDiffHrs < 24) {
      return givenTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    } else if (timeDiffHrs >= 24 && timeDiffHrs < 48) {
      return "Yesterday";
    } else if (timeDiffHrs >= 48 && timeDiffHrs < 168) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek[givenTime.getDay()];
    } else {
      return givenTime.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    }
  };

  const renderNextPage = () => {
    if (paginator && paginator.hasNextPage) {
      paginator.nextPage().then((res) => {
        dispatch(conversationNextPage(res));
      });
    }
  };

  const SortList = async (value) => {
    let searchValue = value ? value : "";
    const UrgentConversations = [];
    const UnreadConversations = [];
    const normalConversations = [];
    let filterConversationNotCreate;
    if (ConversationItems && ConversationItems.length > 0) {
      const filterConversationList = ConversationItems.filter(
        (item) =>
          item.friendlyName
            .trim()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase()) ||
          (item.attributes &&
            item.attributes.mrn &&
            item.attributes.mrn
              .toString()
              .toLowerCase()
              .includes(searchValue.trim().toLowerCase()))
      );
      for (const ele of filterConversationList) {
        const unreadCount = UnreadCount(ele);
        if (unreadCount > 0) {
          let isUrgent = false;
          const { items } = await ele.getMessages(unreadCount + 1);
          items.forEach(({ attributes }) => {
            if (attributes?.subjectType == "other") {
              isUrgent = false;
            } else if (
              attributes &&
              attributes.subjectType &&
              urgentTypeArray.includes(attributes?.subjectType)
            ) {
              isUrgent = true;
            }
          });
          if (isUrgent) {
            UrgentConversations.push(ele);
          } else {
            UnreadConversations.push(ele);
          }
        } else {
          const findDuplicate = normalConversations.find(
            (item) => item.sid === ele.sid
          );
          if (!findDuplicate) {
            normalConversations.push(ele);
          }
        }
      }
    }
    if (conversationNotCreateAPi && conversationNotCreateAPi.length > 0) {
      filterConversationNotCreate = conversationNotCreateAPi.filter(
        (item) =>
          item.firstName
            .trim()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase()) ||
          item.lastName
            .trim()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase()) ||
          (item.staffingDetails &&
            item.staffingDetails.mrn &&
            item.staffingDetails.mrn
              .toString()
              .toLowerCase()
              .includes(searchValue.trim().toLowerCase()))
      );
      setconversationNotCreate(filterConversationNotCreate);
    }
    UrgentConversations.sort((a, b) => {
      if (a.lastMessage && b.lastMessage) {
        return (
          a.lastMessage?.dateCreated.getTime() -
          b.lastMessage?.dateCreated.getTime()
        );
      } else {
        return a.dateUpdated.getTime() - b.dateUpdated.getTime();
      }
    });
    UnreadConversations.sort((a, b) => {
      if (a.lastMessage && b.lastMessage) {
        return (
          a.lastMessage?.dateCreated.getTime() -
          b.lastMessage?.dateCreated.getTime()
        );
      } else {
        return a.dateUpdated.getTime() - b.dateUpdated.getTime();
      }
    });

    normalConversations.sort((a, b) => {
      if (a.lastMessage && b.lastMessage) {
        return (
          b.lastMessage?.dateCreated.getTime() -
          a.lastMessage?.dateCreated.getTime()
        );
      } else {
        return b.dateUpdated.getTime() - a.dateUpdated.getTime();
      }
    });

    setUrgentConversaion(UrgentConversations);

    setSortedConversation([...UnreadConversations, ...normalConversations]);
  };
  const UnreadCount = (conversation) => {
    let count = 0;
    const lastReadMessageIndex = conversation.lastReadMessageIndex;
    const lastMessageIndex = conversation.lastMessage?.index;
    if (lastMessageIndex > lastReadMessageIndex) {
      count = lastMessageIndex - lastReadMessageIndex;
    } else if (lastReadMessageIndex === null && lastMessageIndex === 0) {
      count = 1;
    }
    return count;
  };

  const createConversationFunc = (id) => {
    dispatch(createConversation({ userId: id }));
  };
  useEffect(() => {
    SortList();
  }, [ConversationItems, conversationNotCreateAPi]);

  return (
    <div className="header">
      <h5
        style={{ marginTop: "34px", marginBottom: "21px", color: "#0a365b" }}
        className={`ml-3 pl-2 pr-2 text-lg-start`}
      >
        {"Comm Notes And Activity"}
      </h5>

      <div
        style={{ marginBottom: "22px" }}
        className={`form-group ml-3 mr-3 pl-2 pr-2`}
      >
        <InputGroup style={{ height: "" }} className="mb-3">
          <InputGroup.Text id="basic-addon1" className="bg-transparent pr-2">
            <img width={15} src={SearchIcon} />
          </InputGroup.Text>
          <Form.Control
            className="home_search_bar"
            style={{
              borderLeft: "0",
              paddingLeft: "0",
            }}
            onChange={searchPatient}
            aria-label="Username"
            placeholder="Search by patient name."
            aria-describedby="basic-addon1"
          />
        </InputGroup>
      </div>
      <Card className="border-0">
        <Card.Body
          className="p-0 scroll_0"
          id="conversationListDiv"
          style={{
            height: "73vh",
            overflowY: "auto",
          }}
        >
          <InfiniteScroll
            dataLength={
              isClientReady && ConversationItems && ConversationItems.length
                ? ConversationItems.length
                : 0
            }
            next={renderNextPage}
            hasMore={
              paginator && paginator.hasNextPage ? paginator.hasNextPage : false
            }
            scrollableTarget="conversationListDiv"
          >
            <ul className="list-unstyled mb-0 ">
              {urgentConversations &&
                urgentConversations.length > 0 &&
                urgentConversations.map((ListItem, ListIndex) => {
                  return (
                    <li
                      style={{ cursor: "pointer" }}
                      className={`padding-chat-user d-flex justify-content-between  top_msg po ${
                        activeConversation &&
                        ListItem.sid === activeConversation.sid &&
                        "active"
                      }`}
                      onClick={() => chatbyId(ListItem?.sid)}
                      key={`List_${ListIndex}`}
                    >
                      <div className="d-flex flex-row ">
                        <img
                          src={
                            ListItem &&
                            ListItem.attributes &&
                            ListItem.attributes.image
                              ? `${apiBasePath}documents/therapist/${ListItem.attributes.image}`
                              : chatIconsByRole["patient"]
                          }
                          alt="avatar"
                          className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                          crossOrigin="anonymous"
                          width="50"
                          height="50"
                        />
                        <div className="pt-1">
                          <p className="user-name mb-0">{` ${ListItem.friendlyName}`}</p>
                          <p className="small text-chat-mr  m-0">
                            {ListItem.attributes &&
                              ListItem.attributes.mrn &&
                              `Mr #${ListItem.attributes?.mrn}`}
                          </p>
                        </div>
                      </div>
                      <div className="pt-1 d-flex flex-column">
                        <p
                          className="small text-muted mb-1"
                          style={{ fontSize: "10px" }}
                        >
                          {`${getTimeDiffOutput(
                            ListItem.lastMessage
                              ? ListItem.lastMessage.dateCreated
                              : ListItem.dateUpdated
                          )}`}{" "}
                        </p>
                        {activeConversation &&
                          activeConversation.sid !== ListItem?.sid &&
                          UnreadCount(ListItem) > 0 && (
                            <span
                              style={{
                                width: "32px",
                                height: "21px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: "auto",
                                fontWeight: "400",
                              }}
                              className={`badge side-bar_msg float-end rounded-pill`}
                            >
                              {UnreadCount(ListItem)}
                            </span>
                          )}
                      </div>
                    </li>
                  );
                })}
              {sortedConversation &&
                sortedConversation.length > 0 &&
                sortedConversation.map((ListItem, ListIndex) => {
                  return (
                    <li
                      style={{ cursor: "pointer" }}
                      className={`padding-chat-user d-flex justify-content-between ${
                        activeConversation &&
                        ListItem.sid === activeConversation.sid &&
                        "active"
                      } top_msg po`}
                      onClick={() => chatbyId(ListItem?.sid)}
                      key={`List_${ListIndex}`}
                    >
                      <div className="d-flex flex-row ">
                        <img
                          src={
                            ListItem &&
                            ListItem.attributes &&
                            ListItem.attributes.image
                              ? `${apiBasePath}documents/therapist/${ListItem.attributes.image}`
                              : chatIconsByRole["patient"]
                          }
                          alt="avatar"
                          className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                          crossOrigin="anonymous"
                          width="50"
                          height="50"
                        />
                        <div className="pt-1">
                          <p className="user-name mb-0">{` ${ListItem.friendlyName}`}</p>
                          <p className="small text-chat-mr  m-0">
                            {ListItem.attributes &&
                              ListItem.attributes.mrn &&
                              `MR #${ListItem.attributes?.mrn}`}
                          </p>
                        </div>
                      </div>
                      <div className="pt-1 d-flex flex-column">
                        <p
                          className="small text-muted mb-1"
                          style={{ fontSize: "10px" }}
                        >
                          {`${getTimeDiffOutput(
                            ListItem.lastMessage
                              ? ListItem.lastMessage.dateCreated
                              : ListItem.dateUpdated
                          )}`}{" "}
                        </p>
                        {activeConversation &&
                          activeConversation.sid !== ListItem?.sid &&
                          UnreadCount(ListItem) > 0 && (
                            <span
                              style={{
                                width: "32px",
                                height: "21px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: "auto",
                                fontWeight: "400",
                              }}
                              className={`badge ${
                                ListItem.isUrgent
                                  ? "side-bar_msg"
                                  : "side-bar_msg2"
                              } float-end rounded-pill`}
                            >
                              {UnreadCount(ListItem)}
                            </span>
                          )}
                        {/* )} */}
                      </div>
                    </li>
                  );
                })}
              {conversationNotCreate &&
                conversationNotCreate.length > 0 &&
                conversationNotCreate.map((ListItem, ListIndex) => {
                  return (
                    <li
                      style={{ cursor: "pointer" }}
                      className={`padding-chat-user d-flex justify-content-between top_msg po`}
                      onClick={() => createConversationFunc(ListItem?.id)}
                      key={`List_${ListIndex}`}
                    >
                      <div className="d-flex flex-row ">
                        <img
                          src={
                            ListItem && ListItem.image
                              ? `${apiBasePath}documents/therapist/${ListItem.image}`
                              : chatIconsByRole["patient"]
                          }
                          alt="avatar"
                          className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                          crossOrigin="anonymous"
                          width="50"
                          height="50"
                        />
                        <div className="pt-1">
                          <p className="user-name mb-0">{`${ListItem.lastName}, ${ListItem.firstName}`}</p>
                          <p className="small text-chat-mr  m-0">
                            {ListItem.staffingDetails &&
                              ListItem.staffingDetails.mrn &&
                              `MR #${ListItem.staffingDetails?.mrn}`}
                          </p>
                        </div>
                      </div>
                      <div className="pt-1 d-flex flex-column"></div>
                    </li>
                  );
                })}
              {urgentConversations.length === 0 &&
                sortedConversation.length === 0 &&
                conversationNotCreate.length === 0 && (
                  <div className="d-flex align-items-center justify-content-center mt-5">
                    <h2 className="fs-5 mt-5">
                      {isConversationFetched
                        ? "No Conversations"
                        : "Fetching conversation"}
                    </h2>
                  </div>
                )}
            </ul>
          </InfiniteScroll>
        </Card.Body>
      </Card>
    </div>
  );
}
