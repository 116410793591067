import React, { useEffect, useState } from "react";
import { Row, Col } from "bootstrap-4-react";
import { useDispatch, useSelector } from "react-redux";
import { currentRole, defaultStatus } from "../utils/auth";
import PendingModal from "../pages/modals/PendingModal";
import Loader from "../components/Loader";
import { MyModal2 } from "../pages/modals/MyModal2";
import Patient from "../images/usertable02.svg";
import { getStaffingDetailsById } from "../Redux/actions/staffing";
import ReactPaginate from "react-paginate";
import { apiBasePath } from "../Redux/config/config";
import { CommonHead } from "../components/common/CommonHead";

import CommonTable from "../components/common/CommonTable";
import { masterData } from "../Redux/actions/therapist";
import ViewModal from "../pages/Therapist/Staffing/ViewModal";
import { toast } from "react-toastify";
import {
  dateFormatter,
  displayVisitStatus,
  minimumDate,
  staffFormTypesConstants,
  statusBaseColorChange,
  visitStatus,
} from "../utils/helper";

import {
  getDocList,
  getStaffDocumentation,
  updateDocumenationStatus,
} from "../Redux/actions/common";
import Searchbar from "../images/search_grey_icon.svg";
import { debounce } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import EpisodeModal from "../pages/Patients/EpisodeModal";
import VisitConfirmationModal from "../pages/Patients/EvaluationVisitDate";
import {
  getPatientDocs,
  getPatientDocsDetails,
  updateDocumentDate,
} from "../Redux/actions/patientGetDocTypes";
import { AssessmentVisit } from "../pages/modals/AssessmentVisit";
import PrintModal from "../pages/modals/PrintModal";
import { setListRefresh } from "../Redux/slices/staffingSlice";

let latLng = {};
const Documentation = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pageSize] = useState(10);
  const [show, setshow] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [menuItem, setMenuItem] = useState("");
  const [searchval, setSearchval] = useState("");
  const [toUpdate, setToUpdate] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [staffUserId, setStaffUserId] = useState(null);
  const [documentDetail, setDocumentDetail] = useState({});
  const [showEpisodeModal, setShowEpisodeModal] = useState(false);
  const [confirmationModal, setconfirmationModal] = useState(false);
  const [assessmentVisitModal, setAssessmentVisitModal] = useState(false);

  const userList = useSelector((e) => e.commonSlice.docList);
  const userCount = useSelector((e) => e.commonSlice.docCount);
  const isLoading = useSelector((e) => e.commonSlice.isLoading);
  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);
  const staffDocument = useSelector((e) => e.commonSlice.staffDocument);
  const isListRefresh = useSelector((e) => e.staffingSlice.isListRefresh);
  const isAuthLoading = useSelector((state) => state.adminSlice.isLoading);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const status = {
    scheduled: defaultStatus.scheduled,
    incomplete: defaultStatus.pendingnotes,
    incompletedoc: defaultStatus.pendingnotes,
    agencyIncomplete: [
      defaultStatus.pendingnotes,
      defaultStatus.needcorrection,
    ],
    needcorrection: defaultStatus.needcorrection,
    missvisitneeded: defaultStatus.missvisitneeded,
    frequencyextensionneeded: defaultStatus.frequencyextensionneeded,
    needreview: defaultStatus.needreview,
    completed: defaultStatus.completed,
  };

  const handleShow = () => setshow(true);

  const setMenuAfterResponse = (status) => {
    switch (status) {
      case defaultStatus.pendingnotes:
        setTabMenus("pendingnotes");
        break;
      case defaultStatus.needcosigned:
        setTabMenus("needcosigned");
        break;
      case defaultStatus.visitnotes:
        setTabMenus("visitnotes");
        break;
      case defaultStatus.frequencyAndPhysicianOrder:
        setTabMenus("frequencyAndPhysicianOrder");
        break;

      default:
        break;
    }
  };

  const updateStatus = (obj) => {
    setMenuAfterResponse(status);
  };

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "firstName":
        return "Patient";
      case "staffingDetails.address":
        return "Address";
      case "disciplines":
        return "Disciplines";
      case "name":
        return "Agency Name";
      case "staffingDetails.disciplinesNeeded":
        return "DISCIPLINES";
      case "staffingDetails.createdByUser.name":
        return "Agency Name";
      case "createdByUser.name":
        return "Agency Name";

      default:
        return colName;
    }
  };

  const renderRowData = (result, col, item) => {
    const Role = currentUser && currentUser.Role && currentUser.Role.key;
    if (Role == currentRole.admin || currentRole.agency) {
      if (col === "PATIENT NAME") {
        return (
          <>
            <div className="d-flex align-items-center">
              <img
                src={
                  result.episodeStaff &&
                  result.episodeStaff?.staffData &&
                  result.episodeStaff?.staffData?.image
                    ? `${apiBasePath}documents/therapist/${result?.episodeStaff?.staffData?.image}`
                    : Patient
                }
                crossOrigin="anonymous"
                className="table-img mr-0"
                style={{ borderRadius: "50%", objectFit: "cover" }}
                alt="img"
              />
              <div>
                <h1 className="title-22 text-nowrap margin-left-17">
                  {result?.episodeStaff.staffData.lastName +
                    ", " +
                    result?.episodeStaff.staffData.firstName}
                </h1>
                <p className="title-24  text-nowrap margin-left-17">
                  MR #{result?.episodeStaff.staffData?.staffingDetails?.mrn}
                </p>
              </div>
            </div>
          </>
        );
      }
      if (col === "VISIT TYPE") {
        return (
          <>
            <p
              className="m-0 addres-col cursor-pointer visitTypeLink"
              onClick={() => handleClick(result)}
            >
              {result?.episodeVisit && result?.episodeVisit?.displayName}
            </p>
          </>
        );
      }
      if (col === "DATE OF VISIT") {
        return (
          <>
            <p className="displine-list m-0">{dateFormatter(result?.date)}</p>
          </>
        );
      }

      if (col === "THERAPIST NAME") {
        return (
          <>
            <div>
              <h1 className="title-22 text-nowrap  ">
                {result?.userData?.firstName + " " + result?.userData?.lastName}
              </h1>
              <p className="title-24  text-nowrap  ">
                {result?.discipline && result?.discipline.toUpperCase()}
              </p>
            </div>
            {/* <div>
              <p className="displine-list m-0">
                {" "}
                {result?.staffEpisodes[0]?.episodeDocumentations[0]?.userData
                  .lastName +
                  " " +
                  result?.staffEpisodes[0]?.episodeDocumentations[0]?.userData
                    .firstName +
                  "" +
                  "(" +
                  result?.staffEpisodes[0]?.episodeDocumentations[0]
                    ?.discipline +
                  ")"}
              </p>
            </div> */}
          </>
        );
      }
      if (col === "VISIT STATUS") {
        return (
          <>
            <div>
              <p
                className={`m-0 mt-3 mb-3 status-td ${statusBaseColorChange(
                  result?.Status.key
                )}`}
              >
                {result?.Status?.name && displayVisitStatus[result?.Status?.key]
                  ? displayVisitStatus[result?.Status?.key]
                  : result?.Status?.name}
              </p>
            </div>
          </>
        );
      }
      if (col === "AGENCY") {
        return (
          <>
            <div>
              <p className="displine-list m-0">{result?.createdByUser?.name}</p>
            </div>
          </>
        );
      }

      if (col === "AGENCY") {
        return (
          <>
            <div>
              <p className="displine-list m-0">{result?.createdByUser?.name}</p>
            </div>
          </>
        );
      }
    }
    if (Role == currentRole.supervisingtherapist || Role == currentRole.admin) {
      if (col === "PATIENT NAME") {
        return (
          <>
            <div className="d-flex align-items-center">
              <img
                src={
                  result.image
                    ? `${apiBasePath}documents/therapist/${result.image}`
                    : Patient
                }
                crossOrigin="anonymous"
                className="table-img  ml-3 mr-0"
                style={{ borderRadius: "50%", objectFit: "cover" }}
                alt="img"
              />
              <div>
                <h1 className="title-22 text-nowrap margin-left-35">
                  {result?.lastName + ", " + result?.firstName}
                </h1>
                <p className="title-24  text-nowrap margin-left-35">
                  {result?.staffingDetails?.mrn}
                </p>
              </div>
            </div>
          </>
        );
      }
      if (col === "VISIT TYPE") {
        return (
          <>
            <p
              className="m-0 addres-col cursor-pointer visitTypeLink"
              onClick={() => handleClick(result)}
            >
              {
                result?.staffEpisodes[0]?.episodeDocumentations[0]?.episodeVisit
                  ?.displayName
              }
            </p>
          </>
        );
      }
      if (col === "DATE OF VISIT") {
        return (
          <>
            <p className="displine-list m-0">
              {dateFormatter(
                result?.staffEpisodes[0]?.episodeDocumentations[0]?.date
              )}
            </p>
          </>
        );
      }

      if (col === "THERAPIST NAME") {
        return (
          <>
            <div>
              <p className="displine-list m-0">
                {" "}
                {result?.staffEpisodes[0]?.episodeDocumentations[0]?.userData
                  .lastName +
                  ", " +
                  result?.staffEpisodes[0]?.episodeDocumentations[0]?.userData
                    .firstName}
              </p>
            </div>
          </>
        );
      }
      if (col === "VISIT STATUS") {
        return (
          <>
            <div>
              <p className="displine-list m-0">
                {
                  result?.staffEpisodes[0]?.episodeDocumentations[0]?.Status
                    ?.name
                }
              </p>
            </div>
          </>
        );
      }
      if (col === "AGENCY") {
        return (
          <>
            <div>
              <p className="displine-list m-0">{result?.createdByUser?.name}</p>
            </div>
          </>
        );
      }
    }
    if (Role == currentRole.assistanttherapist) {
      if (col === "PATIENT NAME") {
        return (
          <>
            <div className="d-flex align-items-center">
              <img
                src={
                  result.image
                    ? `${apiBasePath}documents/therapist/${result.image}`
                    : Patient
                }
                crossOrigin="anonymous"
                className="table-img  ml-3 mr-0"
                style={{ borderRadius: "50%", objectFit: "cover" }}
                alt="img"
              />
              <div>
                <h1 className="title-22 text-nowrap margin-left-35">
                  {result?.lastName + ", " + result?.firstName}
                </h1>
                <p className="title-24  text-nowrap margin-left-35">
                  {result?.staffingDetails?.mrn}
                </p>
              </div>
            </div>
          </>
        );
      }
      if (col === "VISIT TYPE") {
        return (
          <>
            <p
              className="m-0 addres-col cursor-pointer visitTypeLink "
              onClick={() => handleClick(result)}
            >
              {
                result?.staffEpisodes[0]?.episodeDocumentations[0]?.episodeVisit
                  ?.displayName
              }
            </p>
          </>
        );
      }
      if (col === "DATE OF VISIT") {
        return (
          <>
            <p className="displine-list m-0">
              {dateFormatter(
                result?.staffEpisodes[0]?.episodeDocumentations[0]?.date
              )}
            </p>
          </>
        );
      }

      if (col === "THERAPIST NAME") {
        return (
          <>
            <div>
              <p className="displine-list m-0">
                {" "}
                {result?.staffEpisodes[0]?.episodeDocumentations[0]?.userData
                  .lastName +
                  " " +
                  result?.staffEpisodes[0]?.episodeDocumentations[0]?.userData
                    .firstName}
              </p>
            </div>
          </>
        );
      }
      if (col === "VISIT STATUS") {
        return (
          <>
            <div>
              <p className="displine-list m-0">
                {
                  result?.staffEpisodes[0]?.episodeDocumentations[0]?.Status
                    ?.name
                }
              </p>
            </div>
          </>
        );
      }
      if (col === "AGENCY") {
        return (
          <>
            <div>
              <p className="displine-list m-0">{result?.createdByUser?.name}</p>
            </div>
          </>
        );
      }
    }
    if (col === "CO-SIGNED") {
      return <p className="m-0 addres-col ">No</p>;
    }
    return <h1 className=" title-24  mt-3 mb-3">{result[item]}</h1>;
  };

  const userRoleStatusCount = useSelector((e) => e.commonSlice.totaldocCount);
  const debounceCall = debounce((ele) => {
    dispatch(
      getDocList({
        status: status[menuItem],
        search: ele,
        pageSize: pageSize,
        pageNo: 1,
      })
    );
  }, 700);

  const statusHandler = (status, row) => {
    let temp = {};
    setToUpdate(true);
    if (row.date) {
      let price = 0;
      if (staffDetail) {
        price = staffDetail.userRates.find((item) =>
          item.rateTabDetail.displayName
            .toLowerCase()
            .includes(row.episodeVisit.displayName.toLowerCase())
        )?.price;
      }
      temp.id = row.id;
      temp.episodeId = Number(row.episodeId);
      temp.status = status;
      temp.staffUserId = documentDetail.staffUserId;
      if (status === defaultStatus.completed) temp.price = Number(price);
      dispatch(updateDocumenationStatus(temp)).then((e) => {
        if (e.payload.success) {
          dispatch(
            getStaffDocumentation({
              staffUserId: documentDetail.staffUserId,
            })
          );
        }
      });
    }
  };
  function handleClick(rowData) {
    setStaffUserId(rowData.episodeStaff.staffUserId);
    dispatch(getStaffingDetailsById(rowData.episodeStaff.staffUserId));
    dispatch(
      getStaffDocumentation({ staffUserId: rowData.episodeStaff.staffUserId })
    );
    setDocumentDetail({
      ...rowData,
      episodeId: rowData.episodeStaff.id,
      staffUserId: rowData.episodeStaff.staffUserId,
    });
    dispatch(
      getPatientDocsDetails({
        episodeDocumentId: rowData?.id,
      })
    ).then((res) => {
      if (res.payload.success) {
        if (
          rowData?.episodeVisit.displayName ==
          staffFormTypesConstants.Assessment
        ) {
          setAssessmentVisitModal(true);
        } else if (res.payload.data) {
          setShowEpisodeModal(true);
        } else {
          setconfirmationModal(true);
        }
      }
    });
  }
  const updateConfirmDate = (values, openModal) => {
    let payload = { date: values?.evaluationVisitDate, id: documentDetail?.id };
    dispatch(updateDocumentDate(payload)).then((res) => {
      if (res && res.payload && res.payload.success) {
        dispatch(
          getStaffDocumentation({
            staffUserId: documentDetail.staffUserId,
          })
        );
        dispatch(getPatientDocs({ episodeDocumentId: documentDetail?.id }));
        statusHandler(visitStatus.scheduled, documentDetail);
        if (confirmationModal) setconfirmationModal(false);
        setShowEpisodeModal(true);
      }
      return { documentDetail };
    });
  };

  const getRoleDetailCount = (status) => {
    let count = 0;
    if (userRoleStatusCount && userRoleStatusCount.length > 0) {
      let user = userRoleStatusCount.find((item) => item.Status.key === status);
      if (user && user.userCount) {
        count = user.userCount;
      }
    }
    return Number(count);
  };

  const setTabMenus = (tab) => {
    document.getElementById("searchField").value = "";
    setMenuItem(tab);
    setPageNo(1);
    dispatch(
      getDocList({ pageSize: pageSize, pageNo: 1, status: status[tab] })
    );
  };

  const rolemenu = () => {
    const Role = currentUser && currentUser.Role && currentUser.Role.key;
    switch (Role) {
      case currentRole.admin: {
        return menuAdmin;
        break;
      }
      case currentRole.agency: {
        return menuAdmin;
        break;
      }
      case currentRole.supervisingtherapist: {
        return menuSupt;

        break;
      }
      case currentRole.assistanttherapist: {
        return menuAsst;
        break;
      }
      default:
        return menuAdmin;
    }
  };
  const handlePrintModal = (rowData) => {
    setStaffUserId(rowData.episodeStaff.staffUserId);
    dispatch(getStaffingDetailsById(rowData.episodeStaff.staffUserId));
    dispatch(
      getStaffDocumentation({ staffUserId: rowData.episodeStaff.staffUserId })
    );
    setDocumentDetail({
      ...rowData,
      episodeId: rowData.episodeStaff.id,
      staffUserId: rowData.episodeStaff.staffUserId,
    });
    dispatch(
      getPatientDocsDetails({
        episodeDocumentId: rowData?.id,
      })
    ).then((res) => {
      if (res.payload.success) {
        toast.info("Document is downloading.");
        setPrintModal(true);
      }
    });
  };
  const menuAdmin = [
    {
      key: "needreview",
      label: "Need Review",
      count: getRoleDetailCount(defaultStatus.needreview),
      data: userList,
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <pre className="d-flex gap-3 flex-wrap mb-0">
                  <button
                    className=" update-btn view-btn tr-btn ml-auto  mt-3 mb-3"
                    onClick={() => {
                      handleShow();
                      handleClick(rowData);
                    }}
                  >
                    Review
                  </button>
                </pre>
              </td>
            </>
          ),
        },
      ],
      modals: <ViewModal updateStatus={updateStatus} />,
      columns: [
        "PATIENT NAME",
        "VISIT TYPE",
        "DATE OF VISIT",
        "THERAPIST NAME",
      ],
      columnsToHide: ["image", "lastName"],
      modalClass: "PenddingM modalstart",
    },
    {
      key: "agencyIncomplete",
      label: "Incomplete",
      count:
        getRoleDetailCount(defaultStatus.pendingnotes) +
        getRoleDetailCount(defaultStatus.needcorrection),

      data: userList,
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className=" update-btn view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleClick(rowData);
                }}
              >
                Review
              </button>
            </td>
          ),
        },
      ],
      modals: <ViewModal updateStatus={updateStatus} />,
      columns: [
        "PATIENT NAME",
        "CO-SIGNED",
        "VISIT TYPE",
        "DATE OF VISIT",
        "THERAPIST NAME",
        "VISIT STATUS",
      ],
      columnsToHide: ["image", "lastName"],
      modalClass: "PenddingM modalstart",
    },

    {
      key: "completed",
      label: "Completed",
      count: getRoleDetailCount(defaultStatus.completed),

      data: userList,
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="d-flex">
              <button
                className=" update-btn view-btn tr-btn   mt-3 mb-3 "
                onClick={() => {
                  handleClick(rowData);
                }}
              >
                Review
              </button>
              {/* <button
                className=" update-btn view-btn tr-btn ml-1 mt-3 mb-3 "
                onClick={() => {
                  handlePrintModal(rowData);
                }}
              >
                Print
              </button> */}
            </td>
          ),
        },
      ],
      modals: <ViewModal updateStatus={updateStatus} />,
      columns: [
        "PATIENT NAME",
        "VISIT TYPE",
        "DATE OF VISIT",
        "THERAPIST NAME",
      ],
      columnsToHide: ["image", "lastName"],
      modalClass: "PenddingM modalstart",
    },
  ];
  const menuAsst = [
    {
      key: "scheduled",
      label: "Scheduled",
      count: getRoleDetailCount(defaultStatus.scheduled),

      data: userList,
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className=" update-btn view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleClick(rowData);
                }}
              >
                Review
              </button>
            </td>
          ),
        },
      ],
      modals: <ViewModal updateStatus={updateStatus} />,
      columns: [
        "PATIENT NAME",
        "VISIT TYPE",
        "DATE OF VISIT",
        "THERAPIST NAME",
      ],
      columnsToHide: ["image", "lastName"],
      modalClass: "PenddingM modalstart",
    },
    {
      key: "incompletedoc",
      label: "Incomplete",
      count: getRoleDetailCount(defaultStatus.incompletedoc),

      data: userList,
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className=" update-btn view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleClick(rowData);
                }}
              >
                Review
              </button>
            </td>
          ),
        },
      ],
      modals: <ViewModal updateStatus={updateStatus} />,
      columns: [
        "PATIENT NAME",
        "CO-SIGNED",
        "VISIT TYPE",
        "DATE OF VISIT",
        "THERAPIST NAME",
        "VISIT STATUS",
      ],
      columnsToHide: ["image", "lastName"],
      modalClass: "PenddingM modalstart",
    },

    {
      key: "needcorrection",
      label: "Need Correction",
      count: getRoleDetailCount(defaultStatus.needcorrection),

      data: userList,
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className=" update-btn view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleClick(rowData);
                }}
              >
                Review
              </button>
            </td>
          ),
        },
      ],
      modals: <ViewModal updateStatus={updateStatus} />,
      columns: [
        "PATIENT NAME",
        "VISIT TYPE",
        "DATE OF VISIT",
        "THERAPIST NAME",
      ],
      columnsToHide: ["image", "lastName"],
      modalClass: "PenddingM modalstart",
    },
    {
      key: "missvisitneeded",
      label: "Missed Visits Needed",
      count: getRoleDetailCount(defaultStatus.missvisitneeded),

      data: userList,
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className=" update-btn view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleClick(rowData);
                }}
              >
                Review
              </button>
            </td>
          ),
        },
      ],
      modals: <ViewModal updateStatus={updateStatus} />,
      columns: [
        "PATIENT NAME",
        "VISIT TYPE",
        "DATE OF VISIT",
        "THERAPIST NAME",
        "VISIT STATUS",
      ],
      columnsToHide: ["image", "lastName"],
      modalClass: "PenddingM modalstart",
    },
  ];
  const menuSupt = [
    {
      key: "scheduled",
      label: "Scheduled Documentation",
      count: getRoleDetailCount(defaultStatus.scheduled),
      data: userList,
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className=" update-btn view-btn tr-btn ml-auto  mt-3 mb-3 mr-5"
                onClick={() => {
                  handleClick(rowData);
                }}
              >
                Review
              </button>
            </td>
          ),
        },
      ],
      modals: <ViewModal updateStatus={updateStatus} />,

      columns: ["PATIENT NAME", "VISIT TYPE", "DATE OF VISIT"],
      columnsToHide: ["image", "lastName"],
      modalClass: "PenddingM modalstart",
    },
    {
      key: "incomplete",
      label: "Pending Documentation",
      count:
        getRoleDetailCount(defaultStatus.pendingnotes) +
        getRoleDetailCount(defaultStatus.needcosigned),

      data: userList,
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className=" update-btn view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleClick(rowData);
                }}
              >
                Review
              </button>
            </td>
          ),
        },
      ],
      modals: <PendingModal />,
      columns: ["PATIENT NAME", "CO-SIGNED", "VISIT TYPE", "DATE OF VISIT"],

      columnsToHide: ["image"],
      modalClass: "ApprovM",
    },
    {
      label: "Need Correction",

      count: getRoleDetailCount(defaultStatus.needcorrection),
      data: userList,
      key: "needcorrection",

      // component: (
      //   <NeedCorrection
      //     getRoleDetailCount={getRoleDetailCount}
      //     latLng={latLng}
      //     mainTableClass="pl-5"
      //     handleShow={handleShow}
      //     handleClick={handleClick}
      //   />
      // ),
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className=" update-btn view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleClick(rowData);
                }}
              >
                Review
              </button>
            </td>
          ),
        },
      ],
      modals: <PendingModal />,
      // columns: ["firstName", "discipline", "status"],
      columns: ["PATIENT NAME", "VISIT TYPE", "DATE OF VISIT"],

      columnsToHide: ["image"],
      modalClass: "ApprovM",
    },
    {
      key: "missvisitneeded",
      label: "Missed Visits Needed",
      count: getRoleDetailCount(defaultStatus.missvisitneeded),

      data: userList,
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className=" update-btn view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleClick(rowData);
                }}
              >
                Review
              </button>
            </td>
          ),
        },
      ],
      modals: <ViewModal updateStatus={updateStatus} />,
      columns: ["PATIENT NAME", "VISIT TYPE", "WEEK OF VISIT"],
      columnsToHide: ["image", "lastName"],
      modalClass: "PenddingM modalstart",
    },
    {
      key: "frequencyextensionneeded",
      label: "Frequency Extension Needed",
      count: getRoleDetailCount(defaultStatus.frequencyextensionneeded),
      data: userList,
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className=" update-btn view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleClick(rowData);
                }}
              >
                Review
              </button>
            </td>
          ),
        },
      ],
      modals: <ViewModal updateStatus={updateStatus} />,
      columns: ["PATIENT NAME", "DATE OF ORDER"],
      columnsToHide: ["image", "lastName"],
      modalClass: "PenddingM modalstart",
    },
  ];
  const myActions = rolemenu().find((ele) => ele.key === menuItem);

  const handlePageClick = (event) => {
    dispatch(
      getDocList({
        pageSize: pageSize,
        pageNo: event.selected + 1,
        search: searchval ? searchval : "",
        status: status[menuItem],
      })
    );
    setPageNo(event.selected + 1);
  };

  const checkTab = () => {
    return rolemenu().some((item) => item.key === params.tab);
  };

  useEffect(() => {
    if (params.tab && currentUser && checkTab()) {
      setMenuItem(params?.tab);
    } else if (params.tab && currentUser && !checkTab()) {
      navigate("/documentation");
    } else if (currentUser && currentUser.Role) {
      setMenuItem(rolemenu()[0].key);
    }
  }, [currentUser]);

  useEffect(() => {
    dispatch(masterData({ types: ["staffFormTypes"] }));
    dispatch(
      getDocList({
        pageSize: pageSize,
        pageNo: 1,
        status: status[params.tab ? params.tab : rolemenu()[0].key],
      })
    ).then((res) => {
      dispatch(setListRefresh(false));
    });
  }, [currentUser, isListRefresh]);
  const priorVisitDate = (id) => {
    const currentDate = new Date();
    let priorVisit = 0;
    let date = currentDate.toISOString().split("T")[0];
    if (
      staffDocument &&
      staffDocument?.length > 0 &&
      staffDocument[0]?.episodeDocumentations.length > 0
    ) {
      const index = staffDocument[0]?.episodeDocumentations.findIndex(
        (element) => element.id === id
      );
      if (index === -1) {
        priorVisit = 0;
      } else if (index === 0) {
        priorVisit = 0;
      } else {
        priorVisit = index - 1;
      }
      if (index === 0) {
        date = minimumDate(
          staffDetail &&
            staffDetail.staffInitialVisit.length > 0 &&
            staffDetail.staffInitialVisit[0].completionDate
        );
      } else {
        date = minimumDate(
          staffDocument[0]?.episodeDocumentations[priorVisit]["date"]
        );
      }
    }
    return date;
  };
  const handleEpisodeModalClose = () => {
    setShowEpisodeModal(false);
    if (toUpdate) {
      dispatch(masterData({ types: ["staffFormTypes"] }));
      dispatch(
        getDocList({
          pageSize: pageSize,
          pageNo: 1,
          status: status[menuItem ? menuItem : rolemenu()[0].key],
        })
      );
      setToUpdate(false);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      {!isAuthLoading ? (
        <div className="container-fluid">
          <div className="row">
            <div className="search_bar_frm documentaion  mobile_margin_1 spacing_left">
              <img style={{ marginRight: "15px" }} src={Searchbar} alt="" />
              <input
                placeholder="Search by patient name."
                id="searchField"
                // value={searchval}
                className="pl-2 searching"
                onChange={(ele) => {
                  debounceCall(ele.target.value);
                }}
                style={{ border: "none", fontSize: "18px", outline: "none" }}
              />
            </div>
            <div className="user-type-main tabs_user_type">
              <div className="col-sm-12 pr-5 pl-5">
                <CommonHead
                  data={rolemenu()}
                  setMenu={setTabMenus}
                  menuItem={menuItem}
                />
              </div>
            </div>

            {myActions?.isTableShow ? (
              <div className="container-fluid ">
                <Row>
                  <div className="inside_table_tabs justify-content-start table_inside_gaping">
                    <Col lg={11}>
                      <div className="table-responsive table-seprate">
                        <CommonTable
                          css="table-responsive-lg"
                          data={myActions?.data}
                          columns={myActions?.columns}
                          columnsToHide={myActions?.columnsToHide}
                          overrideColumnName={overrideColumnName}
                          actions={myActions?.actions}
                          renderRowData={renderRowData}
                          noData={myActions?.noData}
                          isLoading={isLoading}
                        />
                      </div>
                    </Col>
                  </div>
                </Row>
              </div>
            ) : (
              myActions?.component
            )}
            {myActions?.isTableShow &&
              myActions?.count > 10 &&
              myActions?.data?.length > 0 && (
                <div className="container-fluid ">
                  <div className="row justify-content-start mt-3  pl-5">
                    <div className="pr-5 pl-5 ">
                      <ReactPaginate
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={Math.ceil(userCount / pageSize)}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        forcePage={
                          Math.ceil(userCount / pageSize) === 0
                            ? -1
                            : pageNo - 1
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      ) : (
        <Loader />
      )}

      {showEpisodeModal && (
        <MyModal2
          size="lg"
          modalClass={"Main_modal_1"}
          show={showEpisodeModal}
          handleClose={handleEpisodeModalClose}
          data={
            <EpisodeModal
              visitDetail={documentDetail}
              setToUpdate={setToUpdate}
              episodeModalClose={handleEpisodeModalClose}
              updateConfirmDate={updateConfirmDate}
              staffUserId={documentDetail.staffUserId}
              statusHandler={statusHandler}
              priorVisitDate={priorVisitDate}
            />
          }
        />
      )}
      {confirmationModal && (
        <MyModal2
          dialogClassName={"AuthorizationsTabs-dialog"}
          modalClass={"AuthorizationsTabs-Modal"}
          show={confirmationModal}
          handleClose={() => setconfirmationModal(false)}
          data={
            <VisitConfirmationModal
              visitDetail={documentDetail}
              staffUserId={documentDetail.staffUserId}
              updateConfirmDate={updateConfirmDate}
              modalClose={() => setconfirmationModal(false)}
              priorVisitDate={priorVisitDate}
            />
          }
        />
      )}
      {assessmentVisitModal && (
        <AssessmentVisit
          size="lg"
          staffUserId={staffUserId}
          showModal={assessmentVisitModal}
          handleClose={() => setAssessmentVisitModal(false)}
          episodeId={
            staffDocument &&
            staffDocument[0]?.episodeDocumentations &&
            staffDocument[0]?.episodeDocumentations[0]?.episodeId
          }
        />
      )}
      {printModal && (
        <PrintModal
          show={printModal}
          handleClose={() => setPrintModal(false)}
          visitDetail={documentDetail}
          episodeModalClose={() => setShowEpisodeModal(false)}
          updateConfirmDate={updateConfirmDate}
          staffUserId={documentDetail.staffUserId}
          statusHandler={statusHandler}
          priorVisitDate={priorVisitDate}
        />
      )}
    </>
  );
};

export default Documentation;
