import React, { useState } from "react";
import { BsCheck2 } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { userCredentialUpdate } from "../../Redux/actions/admin";
import { getAllUserDetails,getUserCredential } from "../../Redux/actions/admin";
import { credentialsLabel } from "../../utils/helper";
const ReasonRejectedModal = (props) => {
    const [rejectReason, setRejectReason] = useState("");
    const dispatch = useDispatch();
    const [pageSize] = useState(10);
    const [pageNo] = useState(1);
    const handleApprove = () => {
        dispatch(
            userCredentialUpdate({
                id: props.rejectedId,
                credentialsStatus: props.rejectedStatus,
                rejectReason: rejectReason,
                firstName: props?.rowValue?.firstName,
                lastName: props?.rowValue?.lastName,
                email: props?.rowValue?.email,
                types: credentialsLabel[props.rejectedFileTypes] || '',
            })
        );
        props.handleClose();
        setTimeout(() => {
            dispatch(getUserCredential(props.viewAllId))
            dispatch(getAllUserDetails({ pageSize, pageNo: pageNo}));
        }, 500);
    };

    const handleChange = (event) => {
        setRejectReason(event.target.value);
    };

    return (
        <div className="my-3 offer mx-5">
            <div className="my-3">
                <h5 className="font-bold">Reason for Rejection</h5>
            </div>
            <div>
                <textarea style={{ width: '700px', height: '100px' }} value={rejectReason} onChange={handleChange} />
            </div>
            <div className="d-flex mt-5 mb-5">
                <button className="btn1 agency-btn" type="button" onClick={handleApprove}>
                    <BsCheck2 className="check-icon" />Submit
                </button>
                <span className="ml-1"></span>
                <button style={{ width: '9.3125rem', height: '3rem' }} className="view-btn btn-secondary" onClick={() => props.handleClose()}> Cancel </button>
                <span className="mb-5"></span>
            </div>
        </div>
    );
};

export default ReasonRejectedModal;
