import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsCheck2 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import documentImgs from "../../images/document_icon.svg";

import phone from "../../images/patient/call_black_icon.svg";
import email from "../../images/patient/email_address_icon.svg";
import discipiline from "../../images/newtherapist/discipline_icon.svg";
import Language from "../../images/newtherapist/language_icon.svg";
import Adress from "../../images/newtherapist/location_icon.svg";
import Preferred from "../../images/newtherapist/preferred_specialities_icon.svg";
import experience from "../../images/newtherapist/experience_icon.svg";
import Password from "../../images/newtherapist/password_blue_icon.svg";
import UserName from "../../images/newtherapist/username_blue_icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  currentRole,
  decodeData,
  defaultStatus,
  professionalExperienceTypes,
  userCredentials,
} from "../../utils/auth";
import { updateProfileStatus } from "../../Redux/actions/auth";
import Loader from "../../components/Loader";
import {
  getDefaultRates,
  getTherapistDetailsById,
  getTherapistLocationData,
  masterData,
} from "../../Redux/actions/therapist";
import CoverageAreaView from "./CoverageAreaView";
import { credentialsLabel, getUserDetailParams } from "../../utils/helper";
import { toast } from "react-toastify";
import userimage from "../../images/user.png";
import ComplianceRejection from "./ComplianceRejection";
import BackgroundCheck from "./BackgroundCheck";
import { apiBasePath } from "../../Redux/config/config";
import { getBackgroundReportStatus } from "../../Redux/actions/background";
import { MyModal2 } from "../modals/MyModal2";
import TaxForm from "../modals/taxform/TaxForm";

export const NewTherapist = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [taxFormValues, setTaxFormValues] = useState();
  const [taxFormShow, setTaxFormShow] = useState();
  const masterDataDetail = useSelector(
    (e) => e.therapistSigunpSlice.masterData
  );

  const isLoading = useSelector(
    (e) => e.therapistSigunpSlice.isTherapistLoading
  );

  const locationLoader = useSelector(
    (e) => e.therapistSigunpSlice.locationLoader
  );

  const profileDetail = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );

  useEffect(() => {
    dispatch(masterData({ types: professionalExperienceTypes }));
    dispatch(getDefaultRates(currentRole.supervisingtherapist));
    if (params && params.id)
      if (decodeData(params.id).onbordingPartCompleted > 1) {
        dispatch(getTherapistDetailsById(`${decodeData(params.id).id}`))
          .then((res) => {
            if (res?.payload?.success) {
              if (
                res?.payload?.data?.Status?.key === defaultStatus.pending &&
                res?.payload?.data?.userDetail?.onbordingPartCompleted === 3
              ) {
                dispatch(
                  getBackgroundReportStatus(
                    res?.payload?.data?.userDetail?.reportKey
                  )
                ).catch((err) => err);
              }
            }
          })
          .catch((e) => {
            toast.error("Something went wrong!");
          });
      } else {
        dispatch(
          getTherapistDetailsById(
            `${decodeData(params.id).id}?${
              getUserDetailParams.userCredentials
            }=true&${getUserDetailParams.userProfessionalData}=true&${
              getUserDetailParams.compliance
            }=true`
          )
        )
          .then((res) => {
            if (res?.payload?.success) {
              if (
                res?.payload?.data?.Status?.key === defaultStatus.pending &&
                res?.payload?.data?.userDetail?.onbordingPartCompleted === 3
              ) {
                dispatch(
                  getBackgroundReportStatus(
                    res?.payload?.data?.userDetail?.reportKey
                  )
                ).catch((err) => err);
              }
            }
          })
          .catch((e) => {
            toast.error("Something went wrong!");
          });
      }
  }, []);

  useEffect(() => {
    dispatch(
      getTherapistLocationData(
        `${decodeData(params.id).id}?${getUserDetailParams.coverageAreas}=true`
      )
    );
  }, []);

  useEffect(() => {
    if (
      profileDetail &&
      profileDetail.userDetail &&
      profileDetail.userDetail.textData
    ) {
      setTaxFormValues(JSON.parse(profileDetail.userDetail.textData));
    }
  }, [profileDetail]);

  const getValues = (key, subkey) => {
    if (!profileDetail) {
      return;
    }

    if (profileDetail && profileDetail[key]) {
      if (typeof profileDetail[key] === "object") {
        if (profileDetail[key][subkey]) {
          if (subkey === "address") {
            return (
              <>
                <span
                  className="m-0"
                  style={{
                    font: "normal normal normal 13px Poppins",
                  }}
                >
                  {profileDetail[key].address}
                </span>
                <br></br>
                <span
                  style={{
                    font: "normal normal normal 13px Poppins",
                  }}
                  className="m-0"
                >
                  {profileDetail[key].city}
                  {", "}
                  {profileDetail[key].state}
                  {", "}
                  {profileDetail[key].zip}
                </span>
              </>
            );
          }
          return profileDetail[key][subkey];
        } else {
          return "---";
        }
      }

      return profileDetail[key];
    } else {
      return "---";
    }
  };

  const getProfessional = (key, subkey, type) => {
    let values = [];
    if (!profileDetail) {
      return values;
    }
    if (profileDetail[key] && profileDetail[key].length > 0) {
      profileDetail[key].map((item) => {
        if (item[subkey] && item[subkey].types === type) {
          if (
            !values.includes(item[subkey].name) &&
            item.value !== false &&
            item.value !== "false"
          )
            values.push(item[subkey].name);
        }
      });
    }
    return values.join(", ");
  };

  let detailView = [
    {
      label: "Address",
      icon: <img src={Adress} className="Common-referral-icons" alt="" />,
      key: "userDetail",
      subkey: "address",
      func: getValues,
    },

    {
      label: "Phone",
      icon: <img src={phone} className="Common-referral-icons" alt="" />,
      key: "userDetail",
      subkey: "phone",
      func: getValues,
    },
    {
      label: "Email",
      icon: <img src={email} className="Common-referral-icons" alt="" />,
      key: "email",
      func: getValues,
    },
    {
      label: "Languages Spoken",
      icon: <img src={Language} className="Common-referral-icons" alt="" />,
      key: "userProfessionalData",
      subkey: "masterData",
      type: professionalExperienceTypes[1],
      func: getProfessional,
    },
    {
      label: "Preferred Specialties",
      icon: <img src={Preferred} className="Common-referral-icons" alt="" />,
      key: "userProfessionalData",
      subkey: "masterData",
      type: professionalExperienceTypes[0],
      func: getProfessional,
    },
  ];

  const getDiscipline = () => {
    let discipline = [];
    if (!profileDetail) {
      return discipline;
    }
    if (profileDetail.userRates && profileDetail.userRates.length > 0) {
      profileDetail.userRates.map((item) => {
        if (item.rateTab) {
          if (!discipline.includes(item.rateTab.name))
            discipline.push(item.rateTab.name);
        }
      });
    }
    return discipline;
  };

  const bindProfessionals = (type, inputType) => {
    let detailData =
      masterDataDetail &&
      masterDataDetail.data &&
      masterDataDetail.data.filter((item) => item.types === type);

    if (detailData && detailData.length > 0) {
      return detailData.slice(0, 7).map((data, index) => {
        return (
          <React.Fragment key={`${data.name}_${index}`}>
            <div
              style={{ gap: "10px" }}
              className="d-flex flex-wrap align-items-center "
            >
              <label
                className={
                  inputType === "radio" ? " container-2" : " container2"
                }
                style={{ height: "100%" }}
              >
                <input
                  className=" "
                  type={inputType}
                  disabled
                  name={inputType === "radio" ? type : `${type}_${index}`}
                  defaultChecked={checkDefaultValue(data)}
                />
                <span
                  className={
                    inputType === "radio" ? " checkmark-2" : " checkmark1"
                  }
                ></span>
              </label>

              <p className="radio-name m-0"> {data.name}</p>
            </div>
          </React.Fragment>
        );
      });
    }
    return;
  };

  const checkDefaultValue = (master) => {
    let detail =
      profileDetail &&
      profileDetail.userProfessionalData &&
      profileDetail.userProfessionalData.find(
        (item) => item.masterDataId === master.id
      );
    if (detail && detail.value) {
      return isBoolean(detail.value);
    }
  };

  const isBoolean = (val) => {
    if (val === false || val === "false") {
      return false;
    }
    if (val === true || val === "true") {
      return true;
    }
    return val;
  };

  const updateProfile = () => {
    dispatch(
      updateProfileStatus({
        status: defaultStatus.inprogress,
        userId: profileDetail.id,
        onbordingPartCompleted: 1,
        pendingSteps: "compliance,backgroundcheck",
      })
    ).then(() => {
      toast.success("Therapist approved successfully.");
      setTimeout(() => {
        navigate("/newusers");
      }, 500);
    });
  };

  const setClassForPart = (part) => {
    let classValue = "";
    if (profileDetail && profileDetail.userDetail) {
      if (part === profileDetail.userDetail.onbordingPartCompleted) {
        classValue = "top-link-active-red";
      }
    }

    return classValue;
  };

  const checkCurrentStep = () => {
    let step = 0;
    if (
      profileDetail &&
      profileDetail.userDetail &&
      profileDetail.userDetail.onbordingPartCompleted &&
      profileDetail.Status.key === defaultStatus.pending
    ) {
      step = profileDetail.userDetail.onbordingPartCompleted;
    } else if (
      profileDetail &&
      profileDetail.userDetail &&
      profileDetail.userDetail.onbordingPartCompleted &&
      profileDetail.Status.key === defaultStatus.approved
    ) {
      step = 1;
    }
    return step;
  };

  const changeDateFormat = (updatedAt) => {
    const splitDate = updatedAt?.split("T")[0];
    const newDate = new Date(splitDate);
    return newDate.toLocaleDateString();
  };

  const handleDocumentShow = (item) => {
    if (item?.types === userCredentials.w9) {
      setTaxFormShow(true);
    } else {
      if (item && item?.file) {
        const url = `${apiBasePath}documents/therapist/${item.file}`;
        window.open(url, "_blank").focus();
      } else {
        toast.warn("There is no document to show!");
      }
    }
  };

  const getExperience = () => {
    if (
      profileDetail &&
      profileDetail?.userDetail &&
      profileDetail?.userDetail.experienceDate &&
      profileDetail?.userDetail.experienceMonth &&
      profileDetail?.userDetail.experienceYear
    ) {
      const day = profileDetail?.userDetail.experienceDate;
      const month = profileDetail?.userDetail.experienceMonth;
      const year = profileDetail?.userDetail.experienceYear;
      const currentDate = new Date();
      const endDate = new Date(year, month, day);
      const dateDifference =
        parseInt(currentDate.getTime()) - parseInt(endDate.getTime());
      const expYear = parseInt(dateDifference / (1000 * 3600 * 24 * 365));
      return Number(expYear);
    }
  };

  const handleApproval = () => {
    dispatch(
      updateProfileStatus({
        status: defaultStatus.approved,
        userId: profileDetail && profileDetail.id,
        onbordingPartCompleted: 3,
        pendingSteps: defaultStatus.completed,
      })
    ).then((response) => {
      toast.success("Therapist has been approved.");
      setTimeout(() => {
        navigate("/");
      }, 500);
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="div1 mb-0 agency-details therapist-1 justify-content-start">
        <div className="container-fluid">
          <div className="row start-19  align-items-end justify-content-start">
            <div className="col-md-12">
              <div className="w-100">
                <div className="row">
                  <div className="d-flex align-items-end flex-wrap ">
                    <img
                      src={
                        profileDetail && profileDetail?.image
                          ? `${apiBasePath}documents/therapist/${profileDetail?.image}`
                          : userimage
                      }
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                      crossOrigin="anonymous"
                      alt=""
                      className="card-top-img image_rounded"
                    />
                    <div className="col-lg-8 mobile_margin-top">
                      <p
                        style={{ marginBottom: "64px" }}
                        className="details therapist-name agency-reject-w-50 mobile_width"
                      >
                        {getValues("lastName")}
                        {!isLoading && ","} {getValues("firstName")}
                      </p>
                      {(checkCurrentStep() === 1 ||
                        checkCurrentStep() === 2) && (
                        <div className="d-flex flex-wrap  gap-5 mt-5">
                          <div className="d-flex mobile_wrap agency-reject-gap-40">
                            <div className="d-flex mobile_margin-bottom gap-3">
                              <img
                                src={discipiline}
                                alt=""
                                className="new-thera-icons"
                              />

                              <div className="flex-column align-items-center">
                                <h6 className="heading-uppercase">
                                  DISCIPLINE
                                </h6>
                                {getDiscipline()?.map((item) => (
                                  <p className="details-p" key={item}>
                                    {item}
                                  </p>
                                ))}
                              </div>
                            </div>
                            <div className="d-flex mobile_margin-bottom gap-3">
                              <img
                                src={UserName}
                                alt=""
                                className="new-thera-icons"
                              />
                              <div className="flex-column  align-items-center">
                                <h6 className="heading-uppercase">UserName</h6>
                                <p className="mobile_word_wrap details-p">
                                  {getValues("email")}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex  mobile_margin-bottom gap-3">
                              <img
                                src={Password}
                                alt=""
                                className="new-thera-icons"
                              />
                              <div className="flex-column  align-items-center">
                                <h6 className="heading-uppercase">PASSWORD</h6>
                                <p className="details-p">**********</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {checkCurrentStep() === 1 &&
                      profileDetail &&
                      profileDetail?.Status.key !== defaultStatus.approved && (
                        <div className=" mobile_display-none">
                          <div className="position-absolute create_agency-btns d-flex mobile_wrap ">
                            <button
                              className="btn1 agency-btn"
                              onClick={() => updateProfile()}
                            >
                              <BsCheck2 className="check-icon" />
                              Approve
                            </button>
                            <button
                              className="btn2 agency-btn"
                              onClick={() =>
                                navigate(
                                  `/newusers/therapist/rejected/${params?.id}`
                                )
                              }
                            >
                              <AiOutlineClose className="cross-icon" />
                              Reject
                            </button>
                          </div>
                        </div>
                      )}
                    {checkCurrentStep() === 1 &&
                      profileDetail &&
                      profileDetail?.Status.key !== defaultStatus.approved && (
                        <div className=" col-md-1 mb-5 desktop_block-display pb-5">
                          <div className="position-absolute d-flex mobile_wrap gap-3  ">
                            <button
                              style={{ fontSize: "12px", padding: "6px 7px" }}
                              className="btn1 agency-btn"
                              onClick={() => updateProfile()}
                            >
                              <BsCheck2 className="check-icon" />
                              Approve
                            </button>
                            <button
                              style={{ fontSize: "12px", padding: "6px 7px" }}
                              className="btn2 agency-btn"
                              onClick={() =>
                                navigate(
                                  `/newusers/agency/rejected/${params?.id}`
                                )
                              }
                            >
                              <AiOutlineClose className="cross-icon" />
                              Reject
                            </button>
                          </div>
                        </div>
                      )}
                    {checkCurrentStep() === 3 && (
                      // backgroundReport && backgroundReport?.status === "C" &&
                      <div className=" mobile_display-none">
                        <div className="position-absolute create_agency-btns d-flex mobile_wrap ">
                          <button
                            className="btn1 agency-btn"
                            onClick={() => handleApproval()}
                          >
                            <BsCheck2 className="check-icon" />
                            Approve
                          </button>
                        </div>
                      </div>
                    )}
                    {checkCurrentStep() === 3 && (
                      // backgroundReport && backgroundReport?.status === "C" &&
                      <div className=" col-md-1 mb-5 desktop_block-display pb-5">
                        <div className="position-absolute d-flex mobile_wrap gap-3">
                          <button
                            style={{ fontSize: "12px", padding: "6px 7px" }}
                            className="btn1 agency-btn"
                            onClick={() => handleApproval()}
                          >
                            <BsCheck2 className="check-icon" />
                            Approve
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {profileDetail &&
        profileDetail.Status.key !== defaultStatus?.approved && (
          <div className="progress-0 p-0 margin-bar">
            <ul className="d-flex p-0 m-0">
              <li
                className={`nav-link top-link nav-border ${setClassForPart(
                  1
                )}  ${
                  profileDetail &&
                  profileDetail.userDetail &&
                  profileDetail.userDetail.onbordingPartCompleted > 1 &&
                  "top-link-active-green"
                }`}
              >
                THERAPIST PROFILE - PART 01/03
              </li>
              <li
                className={`nav-link top-link nav-border ${setClassForPart(
                  2
                )}  ${
                  profileDetail &&
                  profileDetail.userDetail &&
                  profileDetail.userDetail.onbordingPartCompleted > 2 &&
                  "top-link-active-green"
                }`}
              >
                COMPLIANCE AND COMPETENCY - PART 02/03
              </li>
              <li
                className={`nav-link top-link nav-border ${setClassForPart(
                  3
                )}  ${
                  profileDetail &&
                  profileDetail.userDetail &&
                  profileDetail.userDetail.onbordingPartCompleted >= 3 &&
                  "top-link-active-green"
                }`}
              >
                BACKGROUND CHECK - PART 03/03
              </li>
            </ul>
          </div>
        )}
      {checkCurrentStep() === 1 ? (
        <div
          className={`container-fluid pl-5 pr-5 ${
            profileDetail &&
            profileDetail.Status.key === defaultStatus?.approved
              ? "mt-3"
              : ""
          }`}
        >
          <div className="row">
            <div className="col-md-8">
              <div>
                <p className="new-therapist-head">Therapist Details</p>
                <div className="d-flex flex-wrap">
                  {profileDetail &&
                    detailView?.map((item, index) => {
                      return (
                        <React.Fragment key={`${item.label}_${index}`}>
                          <div
                            style={{ gap: "19px", marginBottom: "55px" }}
                            className="col-lg-4 col-md-6 Referral-info  pl-0 align-items-start"
                          >
                            <div className="" style={{ color: "#0A365B" }}>
                              {item.icon}
                            </div>
                            <div className="referral_info">
                              <h6 className="heading-uppercase">
                                {item.label}
                              </h6>
                              <div
                                style={{ wordBreak: "break-word" }}
                                className="details-p m-0 "
                              >
                                {item &&
                                  item.func(item.key, item.subkey, item.type)}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  <div
                    style={{ gap: "19px" }}
                    className="col-lg-4 col-md-6 Referral-info mb-5 p-0 align-items-start"
                  >
                    <div className="" style={{ color: "#0A365B" }}>
                      <img src={experience} alt="" />
                    </div>
                    <div className="referral_info">
                      <h6 className="heading-uppercase">EXPERIENCE</h6>
                      <p className="details-p m-0">{getExperience()} years</p>
                    </div>
                  </div>
                </div>
              </div>
              <h6 className="new-therapist-head  mb-3">
                How many Home Health visits per week would you like to perform?
              </h6>
              <div className="d-flex gap-5 flex-wrap margin-radio-b">
                {bindProfessionals(professionalExperienceTypes[2], "radio")}
              </div>

              <h6 className="new-therapist-head mb-3">
                What days of the week are you available for Home Health?
              </h6>
              <div className="d-flex gap-5 flex-wrap margin-radio-b">
                {bindProfessionals(professionalExperienceTypes[3], "checkbox")}
              </div>

              <div className="container-fluid therapist-map">
                <div className="row new-therapist-new">
                  <h6 className="new-therapist-head">Coverage Area</h6>

                  <div className="new-thera-map p-0">
                    <CoverageAreaView loader={locationLoader} />
                  </div>
                </div>
              </div>

              {profileDetail &&
                profileDetail.userRates &&
                profileDetail.userRates.length > 0 &&
                profileDetail.userRates.map((item, index) => (
                  <React.Fragment key={`${item.rateTab.name}_${index}`}>
                    {index === 0 && (
                      <h6 className="new-therapist-head margin-head-1">
                        Default Rates for Physical Therapist (
                        {item.rateTab.name})
                      </h6>
                    )}

                    <div className="row">
                      <div className=" col-lg-12 col-md-10 col-sm-12">
                        <div className="d-flex mobile_wrap col-xl-10 col-lg-10 col-md-12 p-0  justify-content-between mb-4">
                          <p
                            className=" ad-labels New-rate-l m-0 mb-2"
                            style={{ width: "100%" }}
                          >
                            {item.rateTabDetail.name}
                          </p>

                          <p
                            style={{ height: "46px" }}
                            className=" m-0 ad-input add-input mobile_width color-blue blueText"
                          >
                            ${item.price}
                          </p>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
            </div>

            {profileDetail && profileDetail?.userCredentials?.length > 0 && (
              <div className="col-lg-3 col-md-4 offset-lg-1">
                <div className="nav justify-content-between mb-2">
                  <h1 className="new-therapist-head d-tharepist">Documents</h1>
                  {/* <div className="circel-add">
                    <AiOutlinePlus className="plus-add" />
                  </div> */}
                </div>
                {profileDetail?.userCredentials?.map(
                  (item) =>
                    item?.types !== "w9" &&
                    item?.types !== "businesslicense" && (
                      <div
                        onClick={() => handleDocumentShow(item)}
                        style={{ cursor: "pointer" }}
                        key={item?.id}
                      >
                        <div className="M_Patinet">
                          <div className="Card-ditails">
                            <img src={documentImgs} width="28" alt="" />
                          </div>
                          <div className="patientDoc">
                            <p className="m-0 license-ex">
                              {credentialsLabel[item?.types]}
                            </p>
                            {item?.types !== userCredentials.covidrecord &&
                              item?.types !== userCredentials.w9 &&
                              item?.types !== userCredentials.hepatitisB && (
                                <p className="m-0 date-ex">
                                  Exp. Date:{" "}
                                  {changeDateFormat(item?.expirationDate)}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    )
                )}

                {profileDetail &&
                  profileDetail?.userCredentials?.length > 0 && (
                    <>
                      <div className="nav justify-content-between mb-2">
                        <h1 className="new-therapist-head d-tharepist">
                          Business Credentials
                        </h1>
                      </div>
                      {profileDetail?.userCredentials?.map(
                        (item) =>
                          (item?.types === "w9" ||
                            item?.types === "businesslicense") && (
                            <div
                              onClick={() => handleDocumentShow(item)}
                              style={{ cursor: "pointer" }}
                              key={item?.id}
                            >
                              <div className="M_Patinet">
                                <div className="Card-ditails">
                                  <img src={documentImgs} width="28" alt="" />
                                </div>
                                <div className="patientDoc">
                                  <p className="m-0 license-ex">
                                    {credentialsLabel[item?.types]}
                                  </p>
                                  {item?.types !== userCredentials.w9 && (
                                    <p className="m-0 date-ex">
                                      Exp. Date:{" "}
                                      {changeDateFormat(item?.expirationDate)}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </>
                  )}
              </div>
            )}
          </div>
        </div>
      ) : checkCurrentStep() === 2 ? (
        <ComplianceRejection />
      ) : checkCurrentStep() === 3 ? (
        <>
          <BackgroundCheck />
        </>
      ) : (
        ""
      )}

      <MyModal2
        size="lg"
        data={
          <TaxForm
            viewMode={true}
            handleClose={() => setTaxFormShow(false)}
            taxFormValues={taxFormValues}
            setTaxFormValues={setTaxFormValues}
          />
        }
        show={taxFormShow}
        handleClose={() => setTaxFormShow(false)}
        modalClass={"formModal"}
      />
    </>
  );
};
