import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Form, Field, useForm } from "react-final-form";
import { ImAttachment } from "react-icons/im";
import Supervisingpng from "../../../images/usertable03.svg";
import { Col, Row } from "react-bootstrap";
import { AiFillLock, AiOutlineEyeInvisible } from "react-icons/ai";
import { IoSyncCircleOutline } from "react-icons/io5";
import { HiOutlineHome } from "react-icons/hi";
import { MdPayment } from "react-icons/md";
import { BiBlock, BiPencil } from "react-icons/bi";
import { BsChatLeftText } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { formatUsPhoneNumber, getUserId } from "../../../utils/auth";
import { toast } from "react-toastify";
import {
  getCurrentActiveUser,
  getUserDetailsById,
} from "../../../Redux/actions/auth";
import arrayMutators from "final-form-arrays";
import AddressAutocomplete from "../../../components/AddressAutoComplete";
import {
  getDefaultRates,
  getTherapistLocationData,
  saveLocationData,
  therapistNotifications,
  userSignup,
} from "../../../Redux/actions/therapist";
import {
  formatPinCode,
  formatPinCodeValue,
  getUserDetailParams,
  passwordRegex,
  phoneNumberToUSFormat,
  profileTabs,
} from "../../../utils/helper";
import TherapistRates from "../signup/TherapistRates";
import TherapistPayment from "../signup/TherapistPayment";
import ProfessionalExperience from "../signup/ProfessionalExperience";
import TherapistCredentials from "../signup/TherapistCredentials";
import { apiBasePath } from "../../../Redux/config/config";
import Loader from "../../../components/Loader";
import SetNewPassword from "../../Agency/signup/SetNewPassword";
import CoverageAreaView from "../../New User/CoverageAreaView";
import ProfileNotification from "./ProfileNotification";
import BlockList from "./BlockList";
import { useNavigate, useParams } from "react-router-dom";
import SignatureCanvas from "../../../components/common/SignatureCanvas";
import ProfessionalIcon from "../../../images/UserProfilesIcon/professional_icon.svg";
import ProfessionalIconActive from "../../../images/UserProfilesIcon/Professional_active.svg";
import BankIcon from "../../../images/UserProfilesIcon/bankdetails_icon.svg";
import BankIconActive from "../../../images/UserProfilesIcon/bankicon_active.svg";

import UserProfilesIcon from "../../../images/UserProfilesIcon/agencyinfo.svg";
import UserProfilesIconActive from "../../../images/UserProfilesIcon/agencyinfo_active.svg";
import PaymentProfileIcon from "../../../images/UserProfilesIcon/paymentinfo_icon.svg";
import PaymentInfoActive from "../../../images/UserProfilesIcon/paymentinfo_active.svg";
import DefaultRateIcon from "../../../images/UserProfilesIcon/defaultrate_icon.svg";
import DefaultRateIconActive from "../../../images/UserProfilesIcon/defaultrateicon_active.svg";
import DocumentsIcon from "../../../images/UserProfilesIcon/documents_icon.svg";
import DocumentsActive from "../../../images/UserProfilesIcon/documents_active.svg";
import BlockIcon from "../../../images/UserProfilesIcon/block_icon.svg";
import BlockIconActive from "../../../images/UserProfilesIcon/block_active.svg";
import editIcon from "../../../images/edit-profile-icon.svg";
import lockIcon from "../../../images/lock-icon.svg";
import { FiRefreshCw } from "react-icons/fi";
import {
  AgencyProfilePristine,
  agencyImageUpload,
  setPristine,
  setRequest,
  therapistDetailForPic,
  therapistLocationState,
  triggerUpdateButton,
} from "../../../Redux/slices/therapistSignupSlice";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { MyModal2 } from "../../modals/MyModal2";
import WarningPromptModal from "../../modals/ConfirmTabChange";
import {
  ProfileValidState,
  agencyInitialValueDetail,
} from "../../../Redux/slices/agencySignupSlice";

const NameBox = styled.div``;

const FieldLabel = styled.label`
  font-weight: bold;
  font-size: 14px;
  padding: 3px 0px;
`;
const FieldEdit = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Fields = styled.input``;

function TherapistProfile() {
  const navigate = useNavigate();
  const calledOnceRef = useRef(false)

  const [file, setFile] = useState(Supervisingpng);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [Tabkey, setTabkey] = useState(profileTabs.personalinfo);
  const [tabUpdateCheker, setTabUpdateCheker] = useState(false);
  const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
  const [warningPrompt, setWarningPrompt] = useState(false);
  const [selectedTabState, setSelectedTabState] = useState(null);
  const agencyCheck = useSelector(
    (e) => e.therapistSigunpSlice.checkAgencyValue
  );
  const buttonRef = useRef();
  const triggerUpdateState = useSelector(
    (state) => state.therapistSigunpSlice.triggerUpdateFunction
  );

  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );

  const isLoading = useSelector((e) => e.therapistSigunpSlice.isLoading);
  const therapistMapAdded = useSelector(
    (e) => e.therapistSigunpSlice.isMapLocationAdded
  );
  const currentUserRole = PrefillDetails && PrefillDetails?.Role?.key;
  const dispatch = useDispatch();
  const params = useParams();
  const locationLoader = useSelector(
    (e) => e.therapistSigunpSlice.locationLoader
  );
  const profileDetail = useSelector(
    (e) => e.therapistSigunpSlice.therapistLocationData
  );
  const pristineSlice = useSelector((e) => e.therapistSigunpSlice.pristine);
  const initialTherapistProfileValue = useSelector(
    (e) => e.agencySlice.agencyDetailProfile
  );
  const userLocationData = useSelector(
    (e) => e.therapistSigunpSlice.coverageArea
  );

  const therapistDetail = useSelector(
    (e) => e.therapistSigunpSlice.therapistData
  );

  var isProfile = true;

  const notificationData = useSelector(
    (e) => e.therapistSigunpSlice.masterData
  );

  const agencyImage = useSelector((state) => state.therapistSigunpSlice.isImageAgency)

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);

    if (windowWidth.width < 990) {
      setWindowWidth("100%");
    }

    if (windowWidth.width > 990) {
      setWindowWidth("7.3333333333%");
    }

    if (
      params &&
      params.tabs &&
      profileTabs[params.tabs] &&
      profileTabs[params.tabs] !== "password"
    ) {
      setTabkey(params.tabs);
    }

    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
      width,
    };
  }

  useEffect(() => {
    if (PrefillDetails && PrefillDetails?.image) {
      setFile(`${apiBasePath}documents/therapist/${PrefillDetails?.image}`);
    }
    if (params?.id) {
      // setTabkey(Number(params?.id));
      setTabkey(`${params?.id}`);
    }
  }, []);

  const notificationPreferenceValues = (values) => {
    const notificationPreferenceData = {};
    notificationPreferenceData["userId"] = PrefillDetails?.id;
    notificationPreferenceData["isProfile"] = true;
    let payload = [];
    if (values.notificationKeys && values.notificationKeys.length > 0) {
      values.notificationKeys.map((item) => {
        payload.push(values[item][0]);
      });
    }
    notificationPreferenceData["payload"] = payload;
    dispatch(therapistNotifications(notificationPreferenceData));
  };

  const pristineCheck = (pristine) => {
    dispatch(setPristine({ state: pristine }));
  };

  function handleSubmit(values, form) {
    if (profileDetail?.coverageAreas.length === userLocationData?.length) {
      let formData = new FormData();
      values.isProfile = isProfile;
      if (values && values?.name) {
        delete values.name;
      }
      if (PrefillDetails?.id) {
        values.id = PrefillDetails.id;
        if (!values?.latitude && !values?.longitude) {
          values.latitude = PrefillDetails.userDetail.latitude;
          values.longitude = PrefillDetails.userDetail.longitude;
        }
      }
      if (!values.aptUnit) {
        values.aptUnit = null;
      }
      values.user = currentUserRole;
      if (uploadedImage) {
        formData.append("files", uploadedImage);
      }
      formData.append("profileData", JSON.stringify(values));

      dispatch(userSignup(formData)).then((e) => {
        notificationPreferenceValues(values);
        dispatch(agencyInitialValueDetail(null));
        dispatch(AgencyProfilePristine(false));
        triggerTabChanges();
        if (
          !e?.payload?.success &&
          e.payload?.data?.duplicateEmails?.length > 0
        ) {
          toast.error("Email already exists.");
        } else {
          toast.success(`Profile updated successfully.`);
          setTabUpdateCheker(!tabUpdateCheker);
          setUploadedImage(null);
        }
      });
      form.reset();
    } else {
      let payload = {};
      payload["userId"] = PrefillDetails.id;

      payload["locations"] = userLocationData;
      // if (isProfile) {
      //   payload["isProfile"] = isProfile;
      // }
      if (userLocationData && userLocationData.length === 0) {
        toast.info("Please add at least one coverage area.");
        return;
      }
      dispatch(saveLocationData(payload)).then((res) => {
        // dispatch(setTherapistDataGetStep(therapistSignupSteps[3]));
        if (res) {
          triggerTabChanges();
          toast.success(`Profile updated successfully.`);
          dispatch(AgencyProfilePristine(false));
          dispatch(therapistLocationState(false));
          setTabUpdateCheker(!tabUpdateCheker);
        }
      });
    }
  }

  const imageTherapistUpload = useCallback(() => {
    if (!uploadedImage) return;

    const formData = new FormData();
    formData.append("files", uploadedImage);
    formData.append("profileData", JSON.stringify({
      email: therapistDetail?.email,
      id: therapistDetail?.id,
      user: currentUserRole,
      isProfile: true,
      firstName: therapistDetail?.firstName,
      lastName: therapistDetail?.lastName,
    }));

    dispatch(userSignup(formData))
      .then((response) => {
        if (response) {
          dispatch(agencyInitialValueDetail(null));
          dispatch(AgencyProfilePristine(false));
          dispatch(agencyImageUpload(false));

          triggerTabChanges();
          toast.success("Profile successfully updated.");
          setTabUpdateCheker(!tabUpdateCheker);
          setUploadedImage(null);
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, [uploadedImage, dispatch, selectedTabState, Tabkey, therapistDetail]);

  useEffect(() => {
    if (agencyImage && !calledOnceRef.current && triggerUpdateState) {
      imageTherapistUpload();
      calledOnceRef.current = true;
      // dispatch(triggerUpdateButton(false));
    }

  }, [calledOnceRef, agencyImage, triggerUpdateState]);

  useEffect(() => {
    calledOnceRef.current = false;
  }, [uploadedImage]);



  // const [isImageUploaded, setIsImageUploaded] = useState(false);

  // useEffect(() => {
  //   if (uploadedImage && !isImageUploaded) {
  //     imageTherapistUpload();
  //     setIsImageUploaded(true);
  //   }
  // }, [uploadedImage, isImageUploaded, imageTherapistUpload]);


  // useEffect(() => {
  //   if (isImageUploaded) {
  //     setIsImageUploaded(false);
  //   }
  // }, [uploadedImage]);


  useEffect(() => {
    if (PrefillDetails && Tabkey === profileTabs.personalinfo) {
      dispatch(therapistDetailForPic(PrefillDetails))  // for pic detail
    }

  }, [PrefillDetails, profileTabs.personalinfo])

  const handleChange = (e) => {
    if (e && e?.target?.value) {
      dispatch(agencyImageUpload(true))
      setUploadedImage(e.target.files[0]);
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  useEffect(() => {
    if (Tabkey === profileTabs.personalinfo) {
      dispatch(
        getUserDetailsById(
          `${getUserId()?.userId}?${getUserDetailParams.userNotifications}=true`
        )
      ).then(() => {
        dispatch(getCurrentActiveUser(getUserId()?.userId));
        dispatch(getTherapistLocationData(`${getUserId().userId}`));
      });
    } else if (Tabkey === profileTabs.profexperience) {
      dispatch(
        getUserDetailsById(
          `${getUserId()?.userId}?${getUserDetailParams.userProfessionalData
          }=true`
        )
      );
    } else if (Tabkey === profileTabs.paymentinfo) {
      dispatch(
        getUserDetailsById(
          `${getUserId()?.userId}?${getUserDetailParams.paymentDetail}=true`
        )
      );
    } else if (Tabkey === profileTabs.defaultrate) {
      dispatch(getUserDetailsById(`${getUserId()?.userId}`));
      if (currentUserRole) {
        dispatch(getDefaultRates(currentUserRole));
      }
    } else if (Tabkey === profileTabs.credentials) {
      dispatch(
        getUserDetailsById(
          `${getUserId()?.userId}?${getUserDetailParams.userCredentials}=true&${getUserDetailParams.compliance
          }=true`
        )
      );
    } else if (Tabkey === profileTabs.blocklist) {
      dispatch(getUserDetailsById(`${getUserId()?.userId}`));
    }
  }, [Tabkey, tabUpdateCheker]);

  const validateForm = (values) => {
    var regex = passwordRegex;
    // const phone = /^-?\d+\.?\d*$/;
    const emailRegex = /^\S+@\S+\.\S+$/;
    let errors = {};
    if (!values || !values.firstName) {
      errors["firstName"] = "First Name is required.";
    }
    if (!values || !values.lastName) {
      errors["lastName"] = "Last Name is required.";
    }
    if (!values || !values.email) {
      errors["email"] = "Email is required.";
    }
    if (!values || values.email) {
      if (!emailRegex.test(values.email)) {
        errors["email"] = "Please enter a valid email address.";
      }
    }
    if (values && values.password) {
      if (!regex.test(values.password)) {
        errors["password"] =
          "Password strength/length should be at least  8 characters and maximum 64 characters and contain an Uppercase, a Lowercase, a special character, and a digit.";
      }
    }
    if (!values || !values.address) {
      errors["address"] = "Street is required.";
    } else if (values.address.length === 0) {
      errors["address"] = "Street is required.";
    }

    if (!values || !values.state) {
      errors["state"] = "State is required";
    }

    if (!values || !values.city) {
      errors["city"] = "City is required";
    }
    if (!values || !values.zip) {
      errors["zip"] = "Zip is required";
    }
    if (!values || !values.phone) {
      errors["phone"] = "Phone is required";
    } else if (values?.phone && values?.phone.match(/\d/g)?.length !== 10) {
      errors["phone"] = "Please enter a 10 digit number";
    }
    dispatch(ProfileValidState(Object.keys(errors).length > 0 ? true : false));
    return errors;
  };

  const handleShowHidePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const setInitialValue = useMemo(() => {
    let myInitialValues = {
      name:
        PrefillDetails &&
        `${PrefillDetails.firstName} ${PrefillDetails.lastName}`,
      firstName: PrefillDetails && PrefillDetails.firstName,
      lastName: PrefillDetails && PrefillDetails.lastName,
      email: PrefillDetails && PrefillDetails.email,
      phone:
        PrefillDetails &&
        PrefillDetails?.userDetail?.phone &&
        formatUsPhoneNumber(PrefillDetails?.userDetail?.phone),
      state: PrefillDetails && PrefillDetails.userDetail?.state,
      address: PrefillDetails && PrefillDetails.userDetail?.address,
      city: PrefillDetails && PrefillDetails.userDetail?.city,
      aptUnit: PrefillDetails && PrefillDetails.userDetail?.aptUnit,
      zip: PrefillDetails && PrefillDetails.userDetail?.zip,
    };
    myInitialValues["notificationKeys"] = [];
    notificationData &&
      Promise.all(
        notificationData.data.map((e) => {
          if (!myInitialValues.notificationKeys.includes(e.name)) {
            myInitialValues["notificationKeys"].push(e.name);
          }
          PrefillDetails && PrefillDetails.userNotifications?.length > 0
            ? (myInitialValues[e.name] =
              PrefillDetails.userNotifications.filter(
                (item) => item.masterDataId === e?.id
              ))
            : (myInitialValues[e.name] = [
              { isTextMessage: false, isEmail: false, masterDataId: e?.id },
            ]);
        })
      );
    if (myInitialValues && Object.values(myInitialValues)) {
      dispatch(agencyInitialValueDetail(myInitialValues));
    }
    return myInitialValues;
  }, [PrefillDetails, notificationData]);

  const selectResult = (result, setValue) => {
    if (result) {
      setValue("state", result.state);
      setValue("address", result.address);
      setValue("street", result.street);
      setValue("country", result.country);
      setValue("city", result.city);
      setValue("zip", formatPinCodeValue(result.postal_code));
      setValue("latitude", result.latitude);
      setValue("longitude", result.longitude);
      setValue("addressDetails", result?.addressDetails);
    }
  };


  useEffect(() => {
    if (triggerUpdateState && Tabkey === profileTabs.personalinfo) {
      buttonRef.current.click();
    }
  }, [triggerUpdateState]);

  const validHandleSubmit = () => {
    dispatch(triggerUpdateButton(false));
  };

  const handleTab = () => {
    dispatch(AgencyProfilePristine(false));
    dispatch(therapistLocationState(false));
    dispatch(agencyImageUpload(false))
    setWarningPrompt(false);
    setTabkey(selectedTabState);
    pristineCheck(true);
  };

  const checkDirtyFields = (arr, obj) => {
    if (
      arr &&
      obj &&
      Object.keys(obj).every((item) => {
        return arr.notificationKeys.includes(item);
      })
    ) {
      return true;
    } else {
      return false;
    }
  };

  const triggerSaveChanges = () => {
    setWarningPrompt(false);
    dispatch(triggerUpdateButton(true));
    // setTabkey(`${selectedTabState}`);
  };

  const triggerTabChanges = () => {
    if (selectedTabState) {
      setTabkey(`${selectedTabState}`);
    }
  };

  const therapistCredentail = (currentValue, initialValues) => {
    if (currentValue) {
      dispatch(AgencyProfilePristine(!pristineSlice));
    }

    return false;
  };

  const therapistDefaultRate = (currentValue, initialValues) => {
    let isRateChanged = false;
    for (let key in currentValue) {
      for (let key2 in initialValues) {
        if (currentValue[key].name === initialValues[key2].rateTabDetail.name) {
          const currentRate = JSON.stringify(Number(currentValue[key].rate));
          const initialPrice = JSON.stringify(initialValues[key2].price);
          if (Number(currentRate) !== Number(initialPrice)) {
            isRateChanged = true;
            // setTherapistValChange(true);
            dispatch(AgencyProfilePristine(true));
            return true;
          }
        }
      }
    }

    if (!isRateChanged) {
      dispatch(AgencyProfilePristine(false));
      // setTherapistValChange(false);
      return false;
    }
  };

  const professionalExpDetail = (currentValue, initialValue) => {
    if (currentValue) {
      dispatch(AgencyProfilePristine(!pristineSlice));
    }
  };

  const checkValuesChanged = (currentValues, initialValues) => {
    var validCheck = false;
    if (currentValues && initialTherapistProfileValue) {
      for (let key in initialTherapistProfileValue) {
        if (!initialTherapistProfileValue.hasOwnProperty(key)) continue;

        let val1 = currentValues.hasOwnProperty(key)
          ? currentValues[key]
          : null;
        let val = initialTherapistProfileValue.hasOwnProperty(key)
          ? initialTherapistProfileValue[key]
          : null;

        let isBooleanValue = ["isEmail", "isTextMessage"];

        if (Array.isArray(val)) {
          let arr1 = currentValues[key];
          let arr2 = initialTherapistProfileValue[key];

          if (Array.isArray(arr1) && Array.isArray(arr2)) {
            for (let i = 0; i < arr1.length; i++) {
              const item1 = arr1[i];
              const item2 = arr2[i];

              for (let boolKey of isBooleanValue) {
                if (
                  item1.hasOwnProperty(boolKey) &&
                  item2.hasOwnProperty(boolKey)
                ) {
                  if (item1[boolKey] !== item2[boolKey]) {
                    validCheck = true;
                    break;
                  }
                }
              }

              if (validCheck) {
                break;
              }
            }
          }
        } else {
          if (val1 !== val) {
            validCheck = true;
            if (validCheck) {
              dispatch(AgencyProfilePristine(validCheck));
              break;
            }
          }
        }
      }
    }
    dispatch(AgencyProfilePristine(validCheck));
    return validCheck;
  };

  const handleTabSelect = (k, values, initialValues, pristine, valid) => {
    const valuesChanged = checkValuesChanged(values, initialValues);

    if (!valid) {
      setWarningPrompt(true);
      setSelectedTabState(k);
    } else if (valuesChanged || agencyCheck) {
      setWarningPrompt(true);
      setSelectedTabState(k);
    } else if (therapistMapAdded) {
      setWarningPrompt(true);
      setSelectedTabState(k);
    } else if (agencyImage) {
      setWarningPrompt(true);
      setSelectedTabState(k);
    }
    else {
      setSelectedTabState(k);
      setTabkey(`${k}`);
      pristineCheck(true);
    }
  };

  // useEffect(() => {
  //   if (profileDetail?.coverageAreas.length === userLocationData?.length) {
  //     // dispatch(AgencyProfilePristine(therapistMapAdded));
  //     dispatch(therapistLocationState(false));
  //   }
  // }, [userLocationData?.length, therapistMapAdded]);

  return (
    <div className="therapistmainBody">
      <Form
        onSubmit={handleSubmit}
        validate={validateForm}
        mutators={{
          ...arrayMutators,
        }}
        keepDirtyOnReinitialize
        initialValues={setInitialValue}
        render={({
          pristine,
          values,
          handleSubmit,
          submitting,
          valid,
          initialValues,
          dirtyFieldsSinceLastSubmit,
          dirtyFields,
          form: {
            mutators: { push, pop, setValue },
          },
          form,
        }) => {
          return (
            <>
              {!valid && validHandleSubmit()}
              {isProfile && values && checkValuesChanged(values)}
              <div className="Container-fluid">
                <div className="top-box-profil pl-5 pr-5 pt-5">
                  <div className="d-flex justify-content-between align-items-center">
                    <h1 className="my-profile1">My Profile</h1>
                    {Tabkey === "1" && (
                      <div className="upBtn">
                        <button
                          onClick={handleSubmit}
                          className="update-btn"
                          disabled={submitting}
                        >
                          <IoSyncCircleOutline
                            size={35}
                            className="icons-update"
                          />
                          Update
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="row align-items-center  desktop_justify mobile_justify">
                    <div className=" col-lg-4 Pmargin-top-b ">
                      <div className="d-flex align-items-center gap-4 mobile_wrap mobile_justify">
                        <img
                          className="profile-icon my-profile"
                          width={"100px"}
                          src={
                            // PrefillDetails && PrefillDetails?.image
                            //   ? `${apiBasePath}documents/therapist/${PrefillDetails?.image}`
                            //   : file
                            !uploadedImage ? PrefillDetails && PrefillDetails?.image ? `${apiBasePath}documents/therapist/${PrefillDetails?.image}` : file : file

                          }
                          crossOrigin="anonymous"
                          alt="profile-icon"
                        />
                        <div className="flex-column mobile_flex">
                          {PrefillDetails && (
                            <h6 className="agency-name-p">
                              {` ${PrefillDetails?.firstName}  ${PrefillDetails?.lastName}`}
                            </h6>
                          )}
                          <p className="upload-P m-0">Upload Photo</p>
                          <label className="browse-f">
                            Browse files...{" "}
                            <ImAttachment className="cursor-pointer" />
                            <input type="file" onChange={handleChange} ref={calledOnceRef} hidden />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className=" col-xl-8 mb-3">
                      <div className="d-flex   align-items-end mobile_wrap UserProfilePrefillDetails">
                        <div className="PrefillDetailsEmailPasswordSec">
                          <div className="d-flex align-items-end">
                            <div className=" gap-3 mobile_wrap d-flex PrefillDetailsEmailPassword">
                              <div>
                                <FieldLabel className="lableField">
                                  Email Address
                                </FieldLabel>
                                <p>{PrefillDetails && PrefillDetails.email}</p>
                              </div>
                              <div>
                                <FieldLabel className="lableField">
                                  Password
                                </FieldLabel>
                                <p>*****************</p>
                              </div>
                            </div>
                            <div className="d-flex  mobile_width gap-3 mb-2 align-items-end">
                              <img
                                className="cursor-pointer"
                                src={editIcon}
                                height={"32px"}
                                width={"32px"}
                                onClick={() => setTabkey("7")}
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className="mobile_width d-flex align-items-end"
                          style={{ width: "33.3333333333%" }}
                        >
                          <button
                            type="submit"
                            className="update-btn mt-5  w-75  cursor-not-allowed"
                            disabled={true}
                          >
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 512 512"
                              height="35"
                              width="35"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill="none"
                                stroke-miterlimit="10"
                                stroke-width="32"
                                d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                              ></path>
                              <path
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="32"
                                d="M351.82 271.87v-16A96.15 96.15 0 00184.09 192m-24.2 48.17v16A96.22 96.22 0 00327.81 320"
                              ></path>
                              <path
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="32"
                                d="M135.87 256l23.59-23.6 24.67 23.6m192 0l-23.59 23.6-24.67-23.6"
                              ></path>
                            </svg>{" "}
                            2FA Enabled
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4">
                <Tabs
                  id="controlled-Therapist-profiles"
                  className=" tabs_unfinish tabs-Disable border-0 flex-nowrap"
                  activeKey={Tabkey}
                  onSelect={(k) =>
                    handleTabSelect(k, values, initialValues, pristine, valid)
                  }
                >
                  <Tab
                    eventKey={profileTabs.personalinfo}
                    title={
                      <span className="d-flex  align-items-center gap-3 ">
                        <i className="icon_email">
                          <img
                            src={
                              Tabkey === profileTabs.personalinfo
                                ? UserProfilesIconActive
                                : UserProfilesIcon
                            }
                            alt=""
                            srcset=""
                          />
                        </i>{" "}
                        Personal Details
                      </span>
                    }
                  >
                    <>
                      {isLoading && <Loader />}
                      {Tabkey === profileTabs.personalinfo && (
                        <div className="container-fluid pl-5 pr-5 ">
                          <form
                            onSubmit={(e) => {
                              if (!valid)
                                toast.error(
                                  "Please complete all required fields."
                                );
                              handleSubmit(e);
                            }}
                          >
                            {pristineCheck(
                              checkDirtyFields(values, dirtyFields)
                            )}
                            {/* {pristineCheck(pristine)} */}
                            <Row>
                              <Col md={4} className="mb-3">
                                <Field name="firstName">
                                  {({ input, meta }) => (
                                    <div>
                                      <label className="Profile-lable">
                                        First Name
                                      </label>
                                      <input
                                        className="cs-input ref-height-6 Profile-text py-input py-input-ca p-0 pl-2 mb-3 cs-input mb-3"
                                        {...input}
                                        type="text"
                                        placeholder="First name"
                                        autoComplete="true"
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          class="alert alert-danger"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Col>
                              <Col md={4} className="mb-3">
                                <Field name="lastName">
                                  {({ input, meta }) => (
                                    <div>
                                      <label className="Profile-lable">
                                        Last Name
                                      </label>
                                      <input
                                        className="cs-input ref-height-6 Profile-text py-input py-input-ca p-0 pl-2 mb-3 cs-input mb-3"
                                        {...input}
                                        type="text"
                                        placeholder="Last name"
                                        autoComplete="true"
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          class="alert alert-danger"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Col>
                              <Col md={4} className="mb-3">
                                <Field classNamed="d-none" name="phone">
                                  {({ input, meta }) => (
                                    <div>
                                      <label className="Profile-lable">
                                        Phone
                                      </label>
                                      <input
                                        className="ref-height-6 cs-input Profile-text mb-3"
                                        {...input}
                                        type="text"
                                        placeholder="Phone"
                                        autoComplete="true"
                                        onInput={(e) =>
                                          phoneNumberToUSFormat(e, input)
                                        }
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          class="alert alert-danger"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4} className="mb-3">
                                <Field name="address">
                                  {({ input, meta }) => (
                                    <div>
                                      <label className="Profile-lable">
                                        Street
                                      </label>
                                      <AddressAutocomplete
                                        // {...input}
                                        className="cs-input Profile-text py-input py-input-ca p-0 border-0 mb-3 cs-input mb-3 street_color_black ref-height-6"
                                        selectResult={(result) =>
                                          selectResult(result, form.change)
                                        }
                                        selectedAddress={values.address}
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger top-11"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Col>
                              <Col md={4} className="mb-3">
                                <Field name="aptUnit">
                                  {({ input, meta }) => (
                                    <div>
                                      <label className="Profile-lable">
                                        Unit/Apt#
                                      </label>
                                      <input
                                        className="ref-height-6 cs-input Profile-text mb-3 unit_aptcls"
                                        {...input}
                                        type="text"
                                        autoComplete="true"
                                        placeholder="Unit/Apt#"
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          class="alert alert-danger"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={4} className="mb-3">
                                <Field name="city">
                                  {({ input, meta }) => (
                                    <div>
                                      <label className="Profile-lable">
                                        City
                                      </label>
                                      <input
                                        className="ref-height-6 cs-input Profile-text mb-3"
                                        {...input}
                                        type="text"
                                        placeholder="City"
                                      />
                                      <br />
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger top-11"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Col>
                              <Col md={4} className="mb-3">
                                <label className="Profile-lable">State</label>
                                <Field name="state">
                                  {({ input, meta }) => (
                                    <div>
                                      <input
                                        className="ref-height-6 cs-input Profile-text mb-3"
                                        {...input}
                                        type="text"
                                        placeholder="State"
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger top-11"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Col>
                              <Col md={4} className="mb-3">
                                <Field classNamed="d-none" name="zip">
                                  {({ input, meta }) => (
                                    <div>
                                      <label className="Profile-lable">
                                        Zip Code
                                      </label>
                                      <input
                                        className="ref-height-6 cs-input Profile-text mb-3"
                                        {...input}
                                        type="text"
                                        placeholder="Zip"
                                        onInput={(e) => formatPinCode(e, input)}
                                      />
                                      {meta.error && meta.touched && (
                                        <p
                                          className="alert alert-danger top-11"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Col>
                            </Row>
                            <div style={{ marginTop: "calc(52px - 16px)" }}>
                              <div className="col-lg-12 p-0">
                                <p className="notify-head ">Coverage Area</p>

                                <div className="d-flex flex-wrap agency-map-1 therapist__profile_map therapistUpdateClass gap-3">
                                  <CoverageAreaView loader={locationLoader} />
                                </div>
                              </div>
                            </div>
                            <div style={{ marginTop: "72px" }}>
                              <p className="notify-head mb-1 ">
                                Notification Preferences
                              </p>
                              {PrefillDetails?.Role?.key ===
                                "supervisingtherapist" &&
                                values &&
                                values?.notificationKeys &&
                                values?.notificationKeys[0]?.length &&
                                values?.notificationKeys[0]?.length === 0 && (
                                  <p className="Choose_msg">
                                    Select at least 1 per notification type.
                                  </p>
                                )}
                              <ProfileNotification formValues={values} />
                            </div>
                            <Row>
                              <Col md={12}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <button
                                    ref={buttonRef}
                                    type="submit"
                                    className={`update-btn mt-5 mb-5 ${!agencyCheck && !therapistMapAdded
                                      ? "opacity-50"
                                      : ""
                                      }`}
                                    // onClick={handleSubmit}
                                    disabled={
                                      submitting ||
                                      // checkDirtyFields(values, dirtyFields)
                                      (!agencyCheck && !therapistMapAdded)
                                    }
                                  >
                                    <IoSyncCircleOutline size={35} />
                                    Update
                                  </button>
                                  <div></div>
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      )}
                    </>
                  </Tab>
                  <Tab
                    eventKey={profileTabs.profexperience}
                    title={
                      <span className="d-flex  align-items-center gap-3 ">
                        <i className="icon_email">
                          <img
                            src={
                              Tabkey === profileTabs.profexperience
                                ? ProfessionalIcon
                                : ProfessionalIconActive
                            }
                            alt=""
                            srcset=""
                          />
                        </i>{" "}
                        Professional Experience
                      </span>
                    }
                  >
                    <>
                      {isLoading && <Loader />}
                      {Tabkey === profileTabs.profexperience && (
                        <div className="pl-3 pr-5 mb-3">
                          <ProfessionalExperience
                            triggerTabChanges={triggerTabChanges}
                            professionalExpDetail={professionalExpDetail}
                            pristineCheck={pristineCheck}
                            needloader={true}
                            isProfile={isProfile}
                            setTabUpdateCheker={() =>
                              setTabUpdateCheker(!tabUpdateCheker)
                            }
                          />
                        </div>
                      )}
                    </>
                  </Tab>
                  <Tab
                    eventKey={profileTabs.paymentinfo}
                    title={
                      <span className="d-flex  align-items-center gap-3 ">
                        <i className="icon_email">
                          <img
                            src={
                              Tabkey === profileTabs.paymentinfo
                                ? BankIcon
                                : BankIconActive
                            }
                            alt=""
                            srcset=""
                          />
                        </i>{" "}
                        Bank Details
                      </span>
                    }
                  >
                    <>
                      {isLoading && <Loader />}
                      {Tabkey === profileTabs.paymentinfo && (
                        <div className="pl-5 pr-5 mb-3">
                          <TherapistPayment
                            triggerTabChanges={triggerTabChanges}
                            pristineCheck={pristineCheck}
                            isProfile={isProfile}
                            setTabUpdateCheker={() =>
                              setTabUpdateCheker(!tabUpdateCheker)
                            }
                          />
                        </div>
                      )}
                    </>
                  </Tab>
                  <Tab
                    eventKey={profileTabs.defaultrate}
                    title={
                      <span className="d-flex  align-items-center gap-3 ">
                        <i className="icon_email">
                          <img
                            src={
                              Tabkey === profileTabs.defaultrate
                                ? DefaultRateIcon
                                : DefaultRateIconActive
                            }
                            alt=""
                            srcset=""
                          />
                        </i>{" "}
                        Default Rates
                      </span>
                    }
                  >
                    <>
                      {isLoading && <Loader />}
                      {Tabkey === profileTabs.defaultrate && (
                        <div className="pl-4 pr-5 mb-3">
                          <TherapistRates
                            triggerTabChanges={triggerTabChanges}
                            therapistDefaultRate={therapistDefaultRate}
                            pristineCheck={pristineCheck}
                            isProfile={isProfile}
                            setTabUpdateCheker={() =>
                              setTabUpdateCheker(!tabUpdateCheker)
                            }
                          />
                        </div>
                      )}
                    </>
                  </Tab>
                  <Tab
                    eventKey={profileTabs.credentials}
                    title={
                      <span className="d-flex  align-items-center gap-3 ">
                        <i className="icon_email">
                          <img
                            src={
                              Tabkey === profileTabs.credentials
                                ? DocumentsIcon
                                : DocumentsActive
                            }
                            alt=""
                            srcset=""
                          />
                        </i>{" "}
                        Credentials
                      </span>
                    }
                  >
                    <>
                      {isLoading && <Loader />}
                      {Tabkey === profileTabs.credentials && (
                        <div className="pl-4 pr-5 mb-3">
                          <TherapistCredentials
                            therapistCredentail={therapistCredentail}
                            triggerTabChanges={triggerTabChanges}
                            pristineCheck={pristineCheck}
                            isProfile={isProfile}
                            setTabUpdateCheker={() =>
                              setTabUpdateCheker(!tabUpdateCheker)
                            }
                            imageTherapistUpload={imageTherapistUpload}
                            uploadedImage={uploadedImage}
                          />
                        </div>
                      )}
                    </>
                  </Tab>
                  <Tab
                    eventKey={profileTabs.blocklist}
                    title={
                      <span className="d-flex  align-items-center gap-3">
                        <i className="icon_email">
                          <img
                            src={
                              Tabkey === profileTabs.blocklist
                                ? BlockIcon
                                : BlockIconActive
                            }
                            alt=""
                            srcset=""
                          />
                        </i>{" "}
                        Block List
                      </span>
                    }
                  >
                    {Tabkey === profileTabs.blocklist && (
                      <>
                        {pristineCheck(true)}
                        <BlockList
                          tabUpdateCheker={tabUpdateCheker}
                          setTabUpdateCheker={setTabUpdateCheker}
                          triggerTabChanges={triggerTabChanges}
                        />
                      </>
                    )}
                  </Tab>
                </Tabs>
                {Tabkey === "7" && (
                  <div className="pl-5 pr-5 mb-3">
                    <SetNewPassword
                      setTabkey={(key) => setTabkey(`${key}`)}
                      id={PrefillDetails?.id}
                    />
                  </div>
                )}
              </div>
            </>
          );
        }}
      />
      {warningPrompt && (
        <MyModal2
          size="lg"
          data={
            <WarningPromptModal
              handleTab={handleTab}
              handleClose={() => setWarningPrompt(false)}
              triggerSaveChanges={triggerSaveChanges}
              cross={true}
              outerClick={true}
            />
          }
          cross={true}
          outerClick={true}
          show={warningPrompt}
          handleClose={() => setWarningPrompt(false)}
          modalClass={"warning_prompt cross_warning"}
          centered={true}
        />
      )}
    </div>
  );
}

export default TherapistProfile;
