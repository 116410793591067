import React, { useState } from "react";
import { useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import Supervisingpng2 from "../../../images/usertable03.svg";
import {
  therapistAcceptedReferral,
  updateStaffingUser,
} from "../../../Redux/actions/staffing";
import {
  currentRole,
  defaultStatus,
  professionaltype,
} from "../../../utils/auth";
import {
  AssistantDiscipline,
  statusBaseColorChange,
} from "../../../utils/helper";
import { AcceptUser } from "../../modals/AcceptUser";
import CancelApprovalModal from "../../modals/CancelApprovalModal";
import OfferModal from "../../Therapist/Staffing/OfferModal";
import TherapistRatesModal from "../ratesmodal/TherapistRatesModal";
import { apiBasePath } from "../../../Redux/config/config.js";
import AgencyAcceptModal from "../../Therapist/Staffing/AgencyAcceptModal.jsx";

const AgencyTherapistTab = (props) => {
  const [ratesModal, setRatesModal] = useState(false);
  const [showRatesModal, setShowRatesModal] = useState(false);
  const [selectedRow, setTherapistRow] = useState(null);

  const [current, setCurrent] = useState("PT");
  const isLoading = useSelector((e) => e.staffingSlice.isModalLoading);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);

  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);
  const therapistAcceptedReferralList = useSelector(
    (e) => e.staffingSlice.therapistAcceptedReferralList
  );
  const buttons = ["PT", "PTA", "OT", "COTA", "SLP"];
  const [therapistData, setTherapistData] = useState(null);
  const [makeOffer, setMakeOfferData] = useState(null);
  const [showList, setShowList] = useState(false);

  const dispatch = useDispatch();

  const modalHandler = (therapist) => {
    setTherapistData(therapist);
    setRatesModal(true);
  };

  const handleClose = () => {
    setRatesModal(false);
  };

  const acceptModalHandler = (disabled, therapist) => {
    if (!disabled) {
      props.setIsRefresh && props.setIsRefresh(true);
      setTherapistData(therapist);
      const values = {};
      values["isProfileUpdate"] = true;

      values["therapistId"] = therapist?.therapistId
        ? therapist?.therapistId
        : therapist?.id;
      props.handleSubmit(values);
    }
  };

  const acceptTerm = () => {
    acceptModalHandler(
      disabled,
      selectedRow?.therapist,
      selectedRow?.staffUserId
    );
  };

  const declineModalHandler = (disabled, therapist) => {
    if (!disabled) {
      setTherapistData(therapist);
      let obj = {
        status: defaultStatus.rejected,
        role: currentRole.agency,
        staffUserId: staffDetail?.id,
        therapistId: therapist.id,
        isAllDeclined: false,
      };

      dispatch(updateStaffingUser(obj)).then((e) => {
        props.setIsRefresh && props.setIsRefresh(true);
        props.handleClose(true);
        dispatch(
          therapistAcceptedReferral({
            staffUserId: staffDetail.id,
            disciplineName: current.toLowerCase(),
          })
        );
      });
    }
  };

  const handleShowCounteredRate = (value, offer) => {
    if (offer) {
      setShowRatesModal(value);
      setMakeOfferData(offer);
    }
  };

  useEffect(() => {
    if (staffDetail && staffDetail.id) {
      dispatch(
        therapistAcceptedReferral({
          staffUserId: staffDetail.id,
          disciplineName: current.toLowerCase(),
        })
      ).then((res) => {
        if (res.payload.success) {
          setShowList(true);
        }
      });
    }
  }, [current, staffDetail]);

  useEffect(() => {
    if (staffDetail && staffDetail.id) {
      dispatch(
        therapistAcceptedReferral({
          staffUserId: staffDetail.id,
          disciplineName: current.toLowerCase(),
        })
      );
    }
  }, [props.isLoadData]);

  const therapistDetails = (type, data) => {
    let results = [];
    data?.map((e) => {
      if (e.value === "true" && e.masterData?.types === type) {
        results.push(`${e.masterData.displayName}`);
      }
    });

    return results.join(", ");
  };

  var disabled = false;

  const getExperience = (userDetail) => {
    if (
      userDetail &&
      userDetail?.experienceDate &&
      userDetail?.experienceMonth &&
      userDetail?.experienceYear
    ) {
      const day = userDetail?.experienceDate;
      const month = userDetail?.experienceMonth;
      const year = userDetail?.experienceYear;
      const currentDate = new Date();
      const endDate = new Date(year, month, day);
      const dateDifference =
        parseInt(currentDate.getTime()) - parseInt(endDate.getTime());
      const expYear = parseInt(dateDifference / (1000 * 3600 * 24 * 365));
      return Number(expYear);
    }
  };

  const statusShow = (key) => {
    let status;
    switch (key) {
      case defaultStatus.staffedreferral:
        status = "Staffedreferral";
        break;
      case defaultStatus.unfinishedprofile:
        status = "Incomplete";
        break;
      case defaultStatus.awaitingagencyapproval:
        status = "Pending Approval";
        break;
      case defaultStatus.rejected:
        status = "Declined";
        break;
      default:
        break;
    }
    return status;
  };
  return (
    <>
      <Accordion.Body className="Uheader-1 p-3">
        <div
          style={{
            display: "flex",
            gap: "1%",
            marginBottom: "50px",
            paddingRight: "20px",
            paddingLeft: "0px",
          }}
        >
          {buttons.map((e, i) => {
            return (
              <React.Fragment key={i}>
                <button
                  className={
                    current === e ? "therapistBtn active" : "defaultBtn"
                  }
                  onClick={(e) => setCurrent(e.target.innerText)}
                >
                  {e}
                </button>
              </React.Fragment>
            );
          })}
        </div>
        <Table responsive borderless className="table-responsive">
          <thead>
            {showList &&
              therapistAcceptedReferralList &&
              therapistAcceptedReferralList?.length > 0 && (
                <tr className="therapist_heading">
                  <th
                    style={{
                      textAlign: "start",
                      padding: "0px 0px 0px 69px",
                      width: "26%",
                    }}
                  >
                    THERAPIST
                  </th>
                  <th className="th-1">DISCIPLINE</th>
                  <th className="th-1">EXPERIENCE</th>
                  {(currentUser?.Role?.key === currentRole.admin ||
                    currentUser?.Role?.key === currentRole.superadmin) && (
                    <>
                      <th className="th-1">languages</th>
                    </>
                  )}
                  <th className="th-1">Availibility</th>
                  {!AssistantDiscipline.includes(current.toLowerCase()) && (
                    <th className="th-1">Assistant Requested</th>
                  )}
                  <th className="th-1">RATES</th>
                  <th className="th-1"></th>
                </tr>
              )}
          </thead>
          <tbody>
            {showList &&
              therapistAcceptedReferralList &&
              therapistAcceptedReferralList.length !== 0 &&
              therapistAcceptedReferralList?.map((row, ind) => {
                if (
                  row.Status &&
                  (row.Status.key === defaultStatus.unfinishedprofile ||
                    row.Status.key === defaultStatus.declined)
                ) {
                  disabled = true;
                }

                return (
                  <tr className="table-row" key={ind}>
                    <td>
                      <div
                        className="d-flex align-items-center main-pointer"
                        onClick={() => modalHandler(row)}
                      >
                        {row?.therapist?.image ? (
                          <img
                            alt="user data"
                            src={`${apiBasePath}documents/therapist/${row?.therapist?.image}`}
                            className="table-img  ml-0 rounded-img"
                            crossOrigin="anonymous"
                          />
                        ) : (
                          <img
                            alt="user data"
                            src={Supervisingpng2}
                            className="table-img  ml-0 rounded-img"
                          />
                        )}

                        <div>
                          <p className="tableName m-0  ">
                            {row?.therapist?.lastName}
                            {", "}
                            {row?.therapist?.firstName}
                          </p>
                        </div>
                      </div>
                    </td>

                    <td>
                      <p className="tableLang add m-0">
                        {row.therapist &&
                        row.therapist.userDetail &&
                        row.therapist.userDetail.discipline
                          ? row.therapist.userDetail.discipline.toUpperCase()
                          : ""}
                      </p>
                    </td>
                    <td>
                      <p className="tableLang add  m-0">
                        {row.therapist &&
                        row.therapist.userDetail &&
                        row.therapist.userDetail
                          ? `${getExperience(row.therapist.userDetail)} Years`
                          : "Not Avialable"}
                      </p>
                    </td>
                    {(currentUser?.Role?.key === currentRole.admin ||
                      currentUser?.Role?.key === currentRole.superadmin) && (
                      <>
                        <td>
                          <p className="tableLang m-0">
                            {therapistDetails(
                              professionaltype.languagespoken,
                              row?.therapist?.userProfessionalData
                            )}
                          </p>
                        </td>
                      </>
                    )}
                    <td>
                      <p className="tableLang m-0">
                        {row.acceptedDate ? row.acceptedDate : ""}
                      </p>
                    </td>
                    {!AssistantDiscipline.includes(current.toLowerCase()) && (
                      <td>
                        <p className="tableLang m-0">
                          {row && row.isAssistentNeeded ? "Yes" : "No"}
                        </p>
                      </td>
                    )}
                    <td>
                      <p className="tableLang m-0">
                        <p
                          className={
                            row?.makeOffer
                              ? "Accepted-red m-0 cursor-pointer"
                              : "m-0 Accepted-green cursor-context"
                          }
                          onClick={() =>
                            handleShowCounteredRate(true, row?.makeOffer)
                          }
                        >
                          {row?.makeOffer ? "Countered" : "Accepted"}
                        </p>
                      </p>
                    </td>
                    {currentUser?.Role?.key === currentRole.agency && (
                      <td
                        className="tableApprovbox m-0  main-pointer"
                        style={{ width: "150px" }}
                      >
                        <pre className="d-flex gap-3 m-0 ">
                          <div className="pb-3 pt-3 ml-auto">
                            <p
                              className={`tableStatusText  m-0  main-pointer ${
                                disabled
                                  ? "disable-btn-class"
                                  : "Accepted-green"
                              }`}
                              onClick={
                                () => {
                                  props.setAgencyAcceptModal(true);
                                  setTherapistRow(row);
                                }
                                // acceptModalHandler(
                                //   disabled,
                                //   row?.therapist,
                                //   row?.staffUserId
                                // )
                              }
                            >
                              {row.Status &&
                              row.Status.key === defaultStatus.unfinishedprofile
                                ? "Accepted"
                                : "Accept"}
                            </p>
                          </div>
                          <div className="d-flex gap-4 pt-3 pb-3">
                            <p
                              className={`tableStatusText m-0  main-pointer ${
                                disabled && "disable-btn-class"
                              }`}
                              onClick={() =>
                                declineModalHandler(
                                  disabled,
                                  row?.therapist,
                                  row?.staffUserId
                                )
                              }
                            >
                              Decline
                            </p>
                          </div>
                        </pre>
                      </td>
                    )}
                    {(currentUser?.Role?.key === currentRole.admin ||
                      currentUser?.Role?.key === currentRole.superadmin) &&
                      row?.Status?.key && (
                        <>
                          <td className="">
                            <p
                              className={`m-0 Approvbutton userstatus-1 ${statusBaseColorChange(
                                row?.Status?.key
                              )}`}
                            >
                              {" "}
                              {statusShow(row?.Status?.key)}
                            </p>
                          </td>
                        </>
                      )}
                  </tr>
                );
              })}
          </tbody>
        </Table>

        {therapistAcceptedReferralList &&
          therapistAcceptedReferralList.length == 0 && (
            <div className="d-flex align-items-center justify-content-center w-100">
              {!isLoading && (
                <h2 className="fs-5 mt-5 mx-auto">No Data available</h2>
              )}
            </div>
          )}
      </Accordion.Body>

      {ratesModal && (
        <TherapistRatesModal
          therapistData={therapistData}
          show={ratesModal}
          handleClose={handleClose}
          modalClass="accept-modal"
          acceptModalHandler={acceptModalHandler}
          disabled={disabled}
          defaultRates={props?.defaultRates}
          userRates={props?.userRates}
          disciplinesNeeded={props?.disciplinesNeeded}
          therapistAcceptedReferralList={therapistAcceptedReferralList}
        />
      )}
      {props?.acceptModal && (
        <AcceptUser
          show={props?.acceptModal}
          modalClass="th-staffaccept accept-modal"
          setAcceptModal={props?.setAcceptModal}
          setTabMenus={props?.setTabMenus}
          unfinishedModalState={props?.unfinishedModalState}
          handleClose={props?.handleClose}
          handlePatientRoute={props?.handlePatientRoute}
          handleUnfinishModal={props?.handleUnfinishModal}
          setIsRefresh={props?.setIsRefresh}
        />
      )}

      {/* {props.cancelModalState && (
        <CancelApprovalModal
          show={props.cancelModalState}
          handleClose={() => props.setCancelModalState(false)}
          modalClass="cancel-madal"
          therapistData={therapistData}
          isAllDeclined={props.isAllDeclined}
          from={"therapist"}
        />
      )} */}
      {showRatesModal && (
        <OfferModal
          show={showRatesModal}
          makeOffer={makeOffer}
          handleClose={() => {
            setShowRatesModal(false);
          }}
          modalClass="th-staffaccept accept-modal"
          onlyShowOffer={true}
          therapistAcceptedReferralList={
            therapistAcceptedReferralList && therapistAcceptedReferralList
          }
        />
      )}

      <AgencyAcceptModal
        show={props.showAgencyAcceptModal}
        handleClose={() => props.setAgencyAcceptModal(false)}
        modalClass="th-staffaccept accept-modal"
        acceptTerm={acceptTerm}
      />
    </>
  );
};

export default AgencyTherapistTab;
