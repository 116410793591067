import React from "react";
import { useSelector } from "react-redux";
import { chatIconsByRole, timeStamp } from "../../../../utils/helper";
import { apiBasePath } from "../../../../Redux/config/config";

export default function ActivityLog() {
  const ActivityLog = useSelector((state) => state.chatSlice.activityLog);
  return (
    <div
      className="conta scroll_0"
      style={{
        height: "62.5vh",
        overflowY: "auto",
      }}
    >
      {ActivityLog && ActivityLog.length === 0 ? (
        <div className="w-100 h-100 position-relative d-flex align-items-center justify-content-center">
          <h2>No Activity</h2>
        </div>
      ) : (
        ActivityLog.map((LogItem, LogItemIndex) => (
          <div
            className={`sidebar_status ${
              LogItemIndex === ActivityLog.length - 1 ? "last" : ""
            }`}
            key={`ActivityLog_${LogItemIndex}`}
          >
            <a href="#!" className="d-flex justify-content-between mt-4 ml-4">
              <div className="d-flex flex-row">
                <img
                  style={{ objectFit: "cover" }}
                  src={
                    LogItem.forUser && LogItem.forUser.image
                      ? `${apiBasePath}documents/therapist/${LogItem.forUser.image}`
                      : chatIconsByRole[LogItem.forUser.Role.key]
                  }
                  alt="avatar"
                  crossOrigin="anonymous"
                  className="rounded-circle d-flex align-self-center me-3 rounded-5 shadow-1-strong"
                  width="42px"
                  height="42px"
                />
                <div className="d-flex justify-content-between">
                  <p className="mt-3">
                    <b className="fw-bold">
                      {LogItem.forUser && LogItem.forUser.name
                        ? `${LogItem.forUser.name} `
                        : `${LogItem.forUser.lastName}, ${LogItem.forUser.firstName} `}
                      {LogItem.forUser &&
                      LogItem.forUser.userDetail &&
                      LogItem.forUser.userDetail.discipline
                        ? `(${LogItem.forUser.userDetail.discipline.toUpperCase()}) `
                        : ``}
                    </b>
                    {`${LogItem.title} `}
                    <b className="fw-bold">{`${
                      LogItem.status !== null ? LogItem.status : ""
                    }`}</b>
                  </p>
                </div>
              </div>
              <div className="pt-1">
                <p className="small text-muted mb-1">{` ${
                  timeStamp(LogItem.createdAt)?.date
                } At ${timeStamp(LogItem.createdAt)?.time}`}</p>
              </div>
            </a>
          </div>
        ))
      )}
    </div>
  );
}
