import React, { useRef, useState } from "react";
import { Form, Field, useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { useEffect } from "react";
import plusIcon from "../../../images/Group 2.png";
import {
  defaultStatus,
  professionalExperienceTypes,
  therapistSignupSteps,
} from "../../../utils/auth";
import { useDispatch, useSelector } from "react-redux";
import { setTherapistDataGetStep } from "../../../Redux/actions/auth";
import {
  addOtherTherapistField,
  addProfessionalExperienceData,
  masterData,
} from "../../../Redux/actions/therapist";
import { AiOutlinePlus } from "react-icons/ai";
import { usStateList } from "../../../utils/helper";
import { Col, Row } from "bootstrap-4-react/lib/components/layout";
import { IoSyncCircleOutline } from "react-icons/io5";
import { BsChatLeftText } from "react-icons/bs";
import { toast } from "react-toastify";
import {
  setRequest,
  therapistValidState,
  triggerUpdateButton,
} from "../../../Redux/slices/therapistSignupSlice";
import { ProfileValidState } from "../../../Redux/slices/agencySignupSlice";

const ProfessionalExperience = (props) => {
  const professionalExpRef = useRef();
  const triggerUpdateState = useSelector(
    (state) => state.therapistSigunpSlice.triggerUpdateFunction
  );
  const agencyCheck = useSelector(
    (e) => e.therapistSigunpSlice.checkAgencyValue
  );

  useEffect(() => {
    if (
      triggerUpdateState &&
      professionalExpRef &&
      professionalExpRef.current
    ) {
      professionalExpRef.current.click();
    }
  }, [`${triggerUpdateState}`]);

  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );

  const [random, setRandom] = useState(0);
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [updatedValue, setUpdatedValue] = useState();
  const [isSubmited, setIsSubmited] = useState(false);
  const [futureDateErr, setFutureDateErr] = useState();

  useEffect(() => {
    dispatch(
      masterData({
        types: professionalExperienceTypes,
        userId: PrefillDetails && PrefillDetails.id,
      })
    ).then((res) => {
      if (props.needloader) {
        dispatch(setRequest({ request: true }));
      }
    });
  }, [dispatch, isSubmited]);

  const professionalExperienceDetail = useSelector(
    (e) => e.therapistSigunpSlice.masterData
  );
  let [newAdded, setNewAdded] = useState([]);
  let [newAddedTypes, setNewAddedTypes] = useState([]);
  const addAdditionalFieldValue = (values, item) => {
    const obj = {};
    const payload = [
      {
        name: values.other[item],
        displayName: values.other[item],
        types: item,
        userId: PrefillDetails.id,
        isOther: true,
      },
    ];
    obj["payload"] = payload;
    obj["userId"] = PrefillDetails.id;

    dispatch(addOtherTherapistField(obj)).then((masData) => {
      if (masData?.payload?.data?.tabData) {
        masData?.payload?.data?.tabData?.forEach((it) => {
          const newMasterAdded = {
            active: true,
            displayName: it?.displayName,
            id: it?.id,
            name: it?.name,
            primaryId: undefined,
            types: it?.types,
          };
          setNewAdded((prev) => [...prev, `${item}_${it.displayName}`]);
          values[item].push(newMasterAdded);
          props.pristineCheck && props.pristineCheck(false);
          if (item === it?.types) {
            delete values.other[item];
          }
          setUpdatedValue(values);
        });
      }
      setRandom(random + 1);
    });
  };

  const renameLabels = (item) => {
    switch (item) {
      case "daysweekavailable":
        item = "What days of the week are you available?";
        break;
      case "performperweek":
        item = "How many visits would you like to perform per week?";
        break;
      case "languagespoken":
        item = "Languages Spoken";
        break;
      case "specialtiespreferred":
        item = "My Specialities";
        break;
      case "other":
        item = null;
        break;
      default:
        break;
    }
    return item;
  };

  const dup = [
    {
      name: "Specialties Preferred",
      key: "specialtiespreferred",
      isradio: false,
    },
    { name: "languagespoken", key: "languagespoken", isradio: false },
    { name: "HOw many", key: "performperweek", isradio: true },
    { name: "What days", key: "daysweekavailable", isradio: false },
  ];

  const dateState = [
    "experienceDate",
    "experienceMonth",
    "experienceYear",
    "experienceState",
    "performperweekactive",
    "other",
  ];
  const initialValues = (event) => {
    if (event && Object.keys(event).length > 0 && submitted) {
      return event;
    }
    if (updatedValue) {
      return updatedValue;
    }
    var init = {};
    init =
      professionalExperienceDetail &&
      professionalExperienceDetail.data &&
      professionalExperienceDetail.data.reduce(function (r, a) {
        r[a.types] = r[a.types] || [];
        r[a.types].push({ ...a, ...checkDefaultValue(a) });
        return r;
      }, Object.create(null));
    if (init) {
      init["performperweekactive"] =
        init["performperweek"] &&
        init["performperweek"].find((item) => item.active)?.active;
      init["experienceDate"] = PrefillDetails?.userDetail?.experienceDate
        ? PrefillDetails.userDetail.experienceDate
        : null;
      init["experienceMonth"] = PrefillDetails?.userDetail?.experienceMonth
        ? PrefillDetails.userDetail.experienceMonth
        : null;
      init["experienceYear"] = PrefillDetails?.userDetail?.experienceYear
        ? PrefillDetails.userDetail.experienceYear
        : null;
      init["experienceState"] = PrefillDetails?.userDetail?.experienceState
        ? PrefillDetails.userDetail.experienceState
        : null;
    }
    return init;
  };

  const checkDefaultValue = (master) => {
    let detail =
      PrefillDetails &&
      PrefillDetails.userProfessionalData &&
      PrefillDetails.userProfessionalData.find(
        (item) => item.masterDataId === master.id
      );
    let obj = {
      active: false,
      primaryId: detail?.id,
    };
    if (detail && detail.value) {
      obj.active = isBoolean(detail.value);
    }
    return obj;
  };

  const isBoolean = (val) => {
    if (val === false || val === "false") {
      return false;
    }
    if (val === true || val === "true") {
      return true;
    }
    return val;
  };

  const handleSubmit = (values) => {
    // alert('called')
    dispatch(triggerUpdateButton(false));
    setSubmitted(true);
    const obj = {};
    let filteredValue = [];
    Object.keys(values)
      .filter((dat) => !dateState.includes(dat))
      .map((e) => {
        values[e].filter((f) => {
          let masterDataId = f.id;
          let active = f.active;

          if (values[`${e}active`] === f.name) {
            masterDataId = f.id;
            active = values[`${e}active`];
          } else {
            if (typeof isBoolean(active) === "string") {
              active = false;
            }
          }

          filteredValue.push({
            masterDataId: masterDataId,
            userId: PrefillDetails["id"],
            value: active,
            primaryId: f.primaryId,
          });
        });
      });
    if (props?.isProfile) {
      obj["isProfile"] = props?.isProfile;
    }
    obj["experienceDate"] = values.experienceDate;
    obj["userId"] = PrefillDetails["id"];
    obj["experienceMonth"] = values.experienceMonth;
    obj["experienceYear"] = values.experienceYear;
    obj["experienceState"] = values.experienceState;
    obj["payload"] = filteredValue;

    if (obj?.experienceDate && obj?.experienceMonth && obj?.experienceYear) {
      let today = new Date();
      var yyyy = today.getFullYear();
      const fillDate = new Date(
        parseInt(obj?.experienceYear),
        parseInt(obj?.experienceMonth),
        parseInt(obj?.experienceDate)
      ).toLocaleDateString();

      if (
        fillDate > today.toLocaleDateString() &&
        yyyy === parseInt(obj?.experienceYear)
      ) {
        setFutureDateErr("Future date is not allowed.");
      } else {
        setFutureDateErr();
        dispatch(addProfessionalExperienceData(obj)).then((res) => {
          setSubmitted(false);
          setUpdatedValue();
          setIsSubmited(!isSubmited);
          setNewAdded([]);
          dispatch(setTherapistDataGetStep(therapistSignupSteps[4]));
          if (props.isProfile) {
            toast.success("Profile updated successfully.");
            props.triggerTabChanges();
          }
          if (res) {
            props.setTabUpdateCheker && props.setTabUpdateCheker();
          }
        });
      }
    }
  };

  const monthsList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthsListObject = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  };
  const getMonthList = () => {
    return monthsList;
  };

  function getDaysInMonth(month, year) {
    year = year ? year : new Date().getFullYear();
    var date = new Date(year, month, 1);

    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));

      date.setDate(date.getDate() + 1);
    }
    return days.length;
  }

  const proExRejectHandle = (langSpoken) => {
    if (langSpoken) {
      const name = `${langSpoken?.name}_${langSpoken?.id}`;
      if (
        langSpoken.active &&
        PrefillDetails &&
        PrefillDetails?.Status?.key === defaultStatus.rejected &&
        PrefillDetails?.userDetail?.rejectedFields &&
        PrefillDetails?.userDetail?.rejectedFields[name]
      ) {
        return true;
      }
    }
  };

  const handleExperience = (expDate, value) => {
    if (
      PrefillDetails &&
      PrefillDetails?.Status?.key === defaultStatus.rejected &&
      PrefillDetails?.userDetail?.rejectedFields &&
      PrefillDetails?.userDetail?.rejectedFields[expDate] &&
      PrefillDetails?.userDetail?.rejectedFields[expDate] == value
    ) {
      // return <h6 style={{ color: "#c52525" }}>Rejected</h6>;
      return true;
    }
  };

  const setValidation = (values) => {
    let errors = {};
    if (!values.experienceDate) {
      errors["experienceDate"] = "Date is required.";
    }
    if (!values.experienceMonth) {
      errors["experienceMonth"] = "Month is required.";
    }
    if (!values.experienceYear) {
      errors["experienceYear"] = "Year is required.";
    }
    if (!values.experienceState) {
      errors["experienceState"] = "State is required.";
    }
    let isdaysweekavailable = true;
    if (
      values &&
      values.daysweekavailable &&
      values.daysweekavailable.length > 0
    ) {
      values.daysweekavailable.map((item) => {
        if (item.active) {
          isdaysweekavailable = false;
        }
      });
    }
    errors["daysweekavailable"] = [];
    if (isdaysweekavailable) {
      errors["daysweekavailable"].push("At least one selection is required.");
    } else {
      delete errors.daysweekavailable;
    }

    let islanguagespoken = true;
    if (values && values.languagespoken && values.languagespoken.length > 0) {
      values.languagespoken.map((item) => {
        if (item.active) {
          islanguagespoken = false;
        }
      });
    }
    errors["languagespoken"] = [];
    if (islanguagespoken) {
      errors["languagespoken"].push("Languages Spoken is required.");
    } else {
      delete errors.languagespoken;
    }

    if (!values.performperweekactive) {
      errors["performperweek"] = ["One selection is required."];
    } else {
      delete errors.performperweek;
    }

    let isspecialtiespreferred = true;
    if (
      values &&
      values.specialtiespreferred &&
      values.specialtiespreferred.length > 0
    ) {
      values.specialtiespreferred.map((item) => {
        if (item.active) {
          isspecialtiespreferred = false;
        }
      });
    }
    errors["specialtiespreferred"] = [];
    if (isspecialtiespreferred) {
      errors["specialtiespreferred"].push("My Specialities is required.");
    } else {
      delete errors.specialtiespreferred;
    }
    if (
      values &&
      futureDateErr &&
      values?.experienceDate &&
      values?.experienceMonth &&
      values?.experienceYear
    ) {
      let today = new Date();
      var yyyy = today.getFullYear();
      const fillDate = new Date(
        parseInt(values?.experienceYear),
        parseInt(values?.experienceMonth),
        parseInt(values?.experienceDate)
      ).toLocaleDateString();
      if (
        futureDateErr &&
        fillDate > today.toLocaleDateString() &&
        yyyy === parseInt(values?.experienceYear)
      ) {
        setFutureDateErr("Future date is not allowed.");
      } else {
        setFutureDateErr();
      }
    }
    dispatch(
      therapistValidState(Object.keys(errors).length > 0 ? true : false)
    );
    return errors;
  };

  const getStateList = (abbrevaitions) => {
    return usStateList.find((item) => item.abbreviation === abbrevaitions);
  };

  const validHandleSubmit = () => {
    dispatch(triggerUpdateButton(false));
  };

  const fields = [
    "daysweekavailable",
    "languagespoken",
    "specialtiespreferred",
    "other.languagespoken",
    "other.specialtiespreferred",
  ];

  const checkDirtyFields = (obj, values) => {
    let value = true;
    Object.keys(obj).map((keys) => {
      if (obj && !fields.includes(keys)) {
        value = false;
      }
    });
    if (newAdded && newAdded.length > 0) {
      newAdded.forEach((ele) => {
        let [type, name] = ele.split("_");
        if (type) {
          let isExist =
            values && values[type].find((e) => e.displayName == name);
          if (isExist) {
            value = false;
          }
        }
      });
    }
    return value;
  };
  return (
    <Form
      keepDirtyOnReinitialize
      validate={setValidation}
      onSubmit={handleSubmit}
      initialValues={(e) => initialValues(e)}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        values,
        handleSubmit,
        submitting,
        valid,
        pristine,
        initialValues,
        dirtyFields,
      }) => (
        <div
          className={`container-fluid p-0 mt-4  ${
            props?.isProfile ? "pl-5" : "ml-5"
          }`}
        >
          {props.isProfile && values && props.professionalExpDetail(values)}
          {props.isProfile &&
            props.pristineCheck &&
            props.pristineCheck(checkDirtyFields(dirtyFields, values))}
          <div className="row">
            <form
              className="p-0"
              onSubmit={(e) => {
                if (!valid) toast.error("Please complete all required fields.");
                handleSubmit(e);
              }}
            >
              {!valid && validHandleSubmit()}
              {!props.isProfile && (
                <div className=" col-sm-6 d-flex align-items-center gap-5 p-0 ">
                  <h4 className="cs-heading set-head-th px-0 m-0 p-0">
                    Professional Experience
                  </h4>
                  <h4 className="cs-date m-0">
                    Step 04/<span className="pd-dt">08</span>
                  </h4>
                </div>
              )}
              <p className="sub-head-exp">
                When did you receive your initial therapy license in the United
                States and in which state?
              </p>
              {props.isProfile ? (
                <p className="date-exp">
                  {PrefillDetails &&
                    PrefillDetails.userDetail &&
                    `
                ${monthsListObject[PrefillDetails.userDetail.experienceMonth]}
                ${PrefillDetails.userDetail.experienceDate} , 
                ${PrefillDetails.userDetail.experienceYear} 
                ${
                  getStateList(PrefillDetails.userDetail.experienceState).name
                }             
                `}
                </p>
              ) : (
                <div
                  className="respo"
                  style={{ display: "flex", flexWrap: "wrap", gap: "1%" }}
                >
                  <div>
                    <Field
                      name="experienceMonth"
                      component="select"
                      className="form-select select-box-1"
                    >
                      {({ input, meta }) => (
                        <>
                          <div className="col-md-6 p-0">
                            {/* {PrefillDetails?.userDetail?.rejectedFields &&
                              PrefillDetails?.Status?.key ===
                              defaultStatus.rejected &&
                              PrefillDetails?.userDetail?.experienceMonth
                              && (
                                <h6 className="rejected" style={{ color: "#c52525" }}>
                                  Rejected
                                </h6>
                              )} */}

                            <select
                              onChange={input.onChange}
                              className={
                                handleExperience(`experienceMonth`, input.value)
                                  ? "form-select select-box-1 rejected_text_div"
                                  : "form-select select-box-1 "
                              }
                              value={
                                values &&
                                values.experienceMonth &&
                                values.experienceMonth
                              }
                            >
                              {getMonthList().map((e, i) => {
                                return (
                                  <>
                                    {i === 0 && (
                                      <option key={-1} value="">
                                        MM
                                      </option>
                                    )}
                                    <option key={e} value={i}>
                                      {e}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>

                          {!submitted && meta.touched && meta.error && (
                            <p
                              className="thera__error_width alert alert-danger top-11 input-1440-2"
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                          {futureDateErr && (
                            <p
                              className="thera__error_width alert alert-danger top-11 input-1440-2"
                              role="alert"
                            >
                              {futureDateErr}
                            </p>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field
                      name="experienceDate"
                      component="select"
                      className="form-select select-box-1"
                    >
                      {({ input, meta }) => (
                        <>
                          <select
                            onChange={input.onChange}
                            // className="form-select select-box-1"
                            // className={
                            //   PrefillDetails?.userDetail?.rejectedFields &&
                            //   PrefillDetails?.Status?.key ===
                            //     defaultStatus.rejected &&
                            //   PrefillDetails?.userDetail?.experienceMonth
                            //     ? "form-select select-box-1 rejected_text_div"
                            //     : "form-select select-box-1"
                            // }
                            className={
                              handleExperience(`experienceDate`, input.value)
                                ? "form-select select-box-1 rejected_text_div"
                                : "form-select select-box-1 "
                            }
                            value={
                              values &&
                              values.experienceDate &&
                              values.experienceDate
                            }
                          >
                            {Array.from(
                              new Array(
                                getDaysInMonth(
                                  Number(values?.experienceMonth),
                                  values?.experienceYear
                                )
                              ),
                              (v, i) => (
                                <>
                                  {i === 0 && (
                                    <option key={-1} value="">
                                      DD
                                    </option>
                                  )}
                                  <option key={i} value={i + 1}>
                                    {i + 1}
                                  </option>
                                </>
                              )
                            )}
                          </select>
                          {!submitted && meta.touched && meta.error && (
                            <p
                              className="thera__error_width alert alert-danger top-11 input-1440-2"
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field
                      name="experienceYear"
                      component="select"
                      className="form-select select-box-1"
                    >
                      {({ input, meta }) => (
                        <>
                          <select
                            onChange={input.onChange}
                            className={
                              handleExperience(`experienceYear`, input.value)
                                ? "form-select select-box-1 rejected_text_div"
                                : "form-select select-box-1 "
                            }
                            value={
                              values &&
                              values.experienceYear &&
                              values.experienceYear
                            }
                          >
                            {Array.from(new Array(100), (v, i) => (
                              <>
                                {i === 0 && (
                                  <option key={-1} value="">
                                    YYYY
                                  </option>
                                )}
                                <option
                                  key={i}
                                  value={new Date().getFullYear() - i}
                                >
                                  {new Date().getFullYear() - i}
                                </option>
                              </>
                            ))}
                          </select>
                          {!submitted && meta.touched && meta.error && (
                            <p
                              className="thera__error_width alert alert-danger top-11 input-1440-2"
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field
                      name="experienceState"
                      component="select"
                      className="form-select select-box-1"
                      style={{ width: "195px" }}
                    >
                      {({ input, meta }) => (
                        <>
                          <select
                            // className="form-select select-box-1"
                            // className={
                            //   PrefillDetails?.userDetail?.rejectedFields &&
                            //   PrefillDetails?.Status?.key ===
                            //     defaultStatus.rejected &&
                            //   PrefillDetails?.userDetail?.experienceMonth
                            //     ? "form-select select-box-1 rejected_text_div"
                            //     : "form-select select-box-1"
                            // }
                            className={
                              handleExperience(`experienceState`, input.value)
                                ? "form-select select-box-1 rejected_text_div"
                                : "form-select select-box-1 "
                            }
                            {...input}
                          >
                            <option value="" disabled>
                              State
                            </option>
                            {usStateList.map((item, index) => (
                              <option
                                value={`${item.abbreviation}`}
                                key={`State_${index}_${item.abbreviation}`}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                          {!submitted && meta.touched && meta.error && (
                            <p
                              className="thera__error_width alert alert-danger top-11 input-1440-2"
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
              )}

              {values &&
                Object.keys(values)
                  .filter((dat) => !dateState.includes(dat))
                  .map(
                    (item, index) =>
                      item === "specialtiespreferred" && (
                        <div className="flex-column" key={item}>
                          <label className="exp-label">
                            {renameLabels(item)}
                          </label>
                          <div className="row mt-3 ">
                            <div className="col-md-10 ">
                              <div className="w-100">
                                <div className="row">
                                  <FieldArray name={item}>
                                    {({ fields, meta }) => (
                                      <>
                                        {fields.map((name, ind) => (
                                          <>
                                            <div
                                              key={`${item}_${ind}`}
                                              className="col-xl-3 col-lg-4 col-md-4 "
                                            >
                                              {dup.find((a) => a.key === item)
                                                ?.isradio ? (
                                                <div
                                                  className={` checkmark1  ${
                                                    proExRejectHandle(
                                                      fields.value[ind]
                                                    ) && "rejected_text_div"
                                                  }`}
                                                >
                                                  <label className="container-2 align-items-center d-flex padding-left-ref ref-height margin-bottom-1">
                                                    <Field
                                                      value={
                                                        fields.value[ind].name
                                                      }
                                                      id={fields.value[ind].id}
                                                      name={`${item}active`}
                                                      type={`radio`}
                                                      // component="input"
                                                    >
                                                      {({ input }) => (
                                                        <input
                                                          // className="agen-reject-check exp-check p-0 m-0"
                                                          {...input}
                                                        />
                                                      )}
                                                    </Field>
                                                    <span className=" checkmark-5"></span>
                                                    <div className="ml-3">
                                                      {fields.value[ind].name}
                                                    </div>
                                                  </label>
                                                </div>
                                              ) : (
                                                <div>
                                                  <label className="container-card align-items-center d-flex padding-left-ref ref-height margin-bottom-1 ">
                                                    <Field
                                                      name={`${name}.active`}
                                                      type="checkbox"
                                                      // value={fields.value[ind].name}
                                                      component="input"
                                                    >
                                                      {({ input }) => (
                                                        <input
                                                          // className="agen-reject-check exp-check p-0 m-0"
                                                          {...input}
                                                        />
                                                      )}
                                                    </Field>
                                                    <span
                                                      className={
                                                        proExRejectHandle(
                                                          fields.value[ind]
                                                        )
                                                          ? "rejected_text_div checkmark1"
                                                          : "checkmark1"
                                                      }
                                                    ></span>
                                                    <div className="ml-3 text-filed-input">
                                                      {
                                                        fields.value[ind]
                                                          .displayName
                                                      }
                                                    </div>
                                                  </label>
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        ))}

                                        {item === "specialtiespreferred" ||
                                        item === "languagespoken" ? (
                                          <div className="col-xl-3 col-lg-4 col-md-4 d-flex gap-3 align-items-center mb-4">
                                            <Field name={`other.${item}`}>
                                              {({ input }) => (
                                                <div>
                                                  <input
                                                    {...input}
                                                    placeholder="Others"
                                                    className="form-control"
                                                  />
                                                </div>
                                              )}
                                            </Field>

                                            <button
                                              className="exp-plus"
                                              type="button"
                                              onClick={() =>
                                                addAdditionalFieldValue(
                                                  values,
                                                  item
                                                )
                                              }
                                            >
                                              {/* <AiOutlinePlus size={20} color="#fff" /> */}
                                              <img
                                                width={35}
                                                src={plusIcon}
                                                alt=""
                                              />
                                            </button>
                                          </div>
                                        ) : null}

                                        {!submitted &&
                                          meta.touched &&
                                          meta.error && ( // fields.length - 1 === ind &&
                                            <p
                                              className="alert alert-danger top-11 input-1440-2"
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                      </>
                                    )}
                                  </FieldArray>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
              {values &&
                Object.keys(values)
                  .filter((dat) => !dateState.includes(dat))
                  .map(
                    (item, index) =>
                      item !== "specialtiespreferred" && (
                        <div className="flex-column" key={item}>
                          <label className="exp-label">
                            {renameLabels(item)}
                          </label>
                          <div className="row mt-3 ">
                            <div className="col-md-10 ">
                              <div className="w-100">
                                <div className="row">
                                  <FieldArray name={item}>
                                    {({ fields, meta }) => (
                                      <>
                                        {fields.map((name, ind) => (
                                          <>
                                            <div
                                              key={`${item}_${ind}`}
                                              className="col-xl-3 col-lg-4 col-md-4 "
                                            >
                                              {dup.find((a) => a.key === item)
                                                ?.isradio ? (
                                                <div>
                                                  <label className="container-2 align-items-center d-flex padding-left-ref ref-height margin-bottom-1">
                                                    <Field
                                                      value={
                                                        fields.value[ind].name
                                                      }
                                                      id={fields.value[ind].id}
                                                      name={`${item}active`}
                                                      type={`radio`}
                                                      // component="input"
                                                    >
                                                      {({ input }) => (
                                                        <input
                                                          // className="agen-reject-check exp-check p-0 m-0"
                                                          {...input}
                                                        />
                                                      )}
                                                    </Field>
                                                    <span className=" checkmark-5"></span>
                                                    <div className="ml-3 text-filed-input">
                                                      {fields.value[ind].name}
                                                    </div>
                                                  </label>
                                                </div>
                                              ) : (
                                                <div>
                                                  <label className="container-card align-items-center d-flex padding-left-ref ref-height margin-bottom-1 ">
                                                    <Field
                                                      name={`${name}.active`}
                                                      type="checkbox"
                                                      // value={fields.value[ind].name}
                                                      component="input"
                                                    >
                                                      {({ input }) => (
                                                        <input
                                                          // className="agen-reject-check exp-check p-0 m-0"
                                                          {...input}
                                                        />
                                                      )}
                                                    </Field>
                                                    <span
                                                      className={
                                                        proExRejectHandle(
                                                          fields.value[ind]
                                                        )
                                                          ? "rejected_text_div checkmark1"
                                                          : "checkmark1"
                                                      }
                                                    ></span>
                                                    <div className="ml-3 text-filed-input">
                                                      {
                                                        fields.value[ind]
                                                          .displayName
                                                      }
                                                    </div>
                                                  </label>
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        ))}

                                        {item === "specialtiespreferred" ||
                                        item === "languagespoken" ? (
                                          <div className="col-xl-3 col-lg-4 col-md-4 d-flex gap-3 align-items-center mb-4">
                                            <Field name={`other.${item}`}>
                                              {({ input }) => (
                                                <div>
                                                  <input
                                                    {...input}
                                                    placeholder="Others"
                                                    className="form-control"
                                                  />
                                                </div>
                                              )}
                                            </Field>

                                            <button
                                              className="exp-plus"
                                              type="button"
                                              onClick={() =>
                                                addAdditionalFieldValue(
                                                  values,
                                                  item
                                                )
                                              }
                                            >
                                              {/* <AiOutlinePlus size={20} color="#fff" /> */}
                                              <img
                                                width={35}
                                                src={plusIcon}
                                                alt=""
                                              />
                                            </button>
                                          </div>
                                        ) : null}

                                        {!submitted &&
                                          meta.touched &&
                                          meta.error && ( // fields.length - 1 === ind &&
                                            <p
                                              className="alert alert-danger top-11 input-1440-2"
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                      </>
                                    )}
                                  </FieldArray>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
              {!props.isProfile ? (
                <div className="d-flex gap-3 mb-4 mt-4">
                  <button className="exp-btn1 coverage-btn">
                    Save & Continue
                  </button>
                  <button
                    className="ad-btn-back"
                    type="button"
                    onClick={() =>
                      props.goBackFunction(therapistSignupSteps[3])
                    }
                  >
                    Back
                  </button>
                </div>
              ) : (
                <Row>
                  <Col md={12}>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ marginTop: "calc(64px - 16px)" }}
                    >
                      <button
                        ref={professionalExpRef}
                        type="submit"
                        className={`update-btn  mb-5 ${
                          !agencyCheck
                            ? checkDirtyFields(dirtyFields, values) &&
                              "opacity-50"
                            : ""
                        }`}
                        // onClick={handleSubmit}
                        disabled={
                          checkDirtyFields(dirtyFields, values)
                            ? submitting || !agencyCheck
                            : false
                        }
                      >
                        <IoSyncCircleOutline size={35} />
                        Update
                      </button>
                      <div></div>
                    </div>
                  </Col>
                </Row>
              )}
            </form>
          </div>
        </div>
      )}
    />
  );
};

export default ProfessionalExperience;
