import { createSlice } from "@reduxjs/toolkit";
import {
  getNotificationList,
  readNotification,
  readNotificationAll,
} from "../actions/notification";

const initialState = {
  list: [],
  count: 0,
  fetchingNotification: false,
  notificationList: [],
  isNewNotification: false
};
const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    setNewNotification(state, action) {
      state.isNewNotification = action.payload
    }
  },
  extraReducers: {
    [getNotificationList.fulfilled]: (state, { payload }) => {
      state.list = payload.data;
      state.count = payload.data.length || 0;
      state.fetchingNotification = false;
    },
    [getNotificationList.pending]: (state, { payload }) => {
      state.fetchingNotification = true;
    },
    [getNotificationList.rejected]: (state, { payload }) => {
      state.fetchingNotification = false;
    },
    [readNotification.fulfilled]: (state, { payload }) => {
      state.notificationList = payload;
    },
    [readNotification.rejected]: (state, { payload }) => {
      state.fetchingNotification = false;
    },
    [readNotification.pending]: (state, { payload }) => {
      state.fetchingNotification = true;
    },
    [readNotificationAll.fulfilled]: (state, { payload }) => {
      state.notificationList = payload;
    },
    [readNotificationAll.rejected]: (state, { payload }) => {
      state.fetchingNotification = false;
    },
    [readNotificationAll.pending]: (state, { payload }) => {
      state.fetchingNotification = true;
    },
  },
});


export const { setNewNotification } = notificationSlice.actions
export default notificationSlice.reducer;
