import React, { useMemo, useRef, useState } from "react";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { useDispatch, useSelector } from "react-redux";
import {
  agencySignupSteps,
  defaultCountryCode,
  defaultStatus,
  currentRole,
  floatRegex,
  getUserId,
} from "../../../utils/auth";
import { useEffect } from "react";
import {
  getDefaultRates,
  userRateCreate,
} from "../../../Redux/actions/therapist";
import { toast } from "react-toastify";
import InputGroup from "react-bootstrap/InputGroup";
import BootStrapForm from "react-bootstrap/Form";
import { blockInvalidChar } from "../../../utils/helper";
import { IoSyncCircleOutline } from "react-icons/io5";
import { triggerUpdateButton } from "../../../Redux/slices/therapistSignupSlice";
import { getUserDetailsById } from "../../../Redux/actions/auth";
import {
  ProfileValidState,
  profileValidStateDefaultRate,
} from "../../../Redux/slices/agencySignupSlice";

const AgencyDefaultRates = (props) => {
  const buttonRef = useRef();

  const [dataValue, setDataValue] = useState(-1);

  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );

  const rejectedData = PrefillDetails?.userDetail.rejectedFields;
  const dispatch = useDispatch();
  let defaultRates = useSelector((e) => e.therapistSigunpSlice.defaultRates);
  const agencyImage = useSelector((state) => state.therapistSigunpSlice.isImageAgency)
  const agencyCheck = useSelector(
    (e) => e.therapistSigunpSlice.checkAgencyValue
  );

  const renameFormat = (name) => {
    return name
      .replace(/ /g, "")
      .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>/]/gi, "")
      .toLocaleLowerCase();
  };

  function handleSubmit(valuess) {
    let payload = [];
    valuess.isProfile = true;
    let validPrice = true;
    let RejectedRate = true;
    Object.keys(valuess).map((item, keyIndex) => {
      let rateTabDetail =
        defaultRates &&
        defaultRates.find(
          (value) => renameFormat(value.name) === renameFormat(item)
        );

      valuess &&
        Array.isArray(valuess[item]) &&
        valuess[item].map((rate, rateIndex) => {
          let obj = {
            userId: PrefillDetails.id,
            currencyCode: defaultCountryCode,
          };
          obj["rateTabId"] = rateTabDetail.id;
          obj["rateTabDetailId"] = rate.id;
          obj["price"] = rate.rate ? Number(rate.rate) : 0;
          obj["primaryId"] = rate?.primaryId;
          // if (
          //   rejectedData &&
          //   rejectedData.hasOwnProperty(`rate_${rateTabDetail?.id}_${rate.id}`)
          // ) {
          //   if (
          //     rejectedData[`rate_${rateTabDetail?.id}_${rate.id}`] ===
          //     Number(rate.rate) &&
          //     keyIndex <= dataValue
          //   ) {
          //     RejectedRate = false;
          //   }
          // }
          if (obj["price"] == 0 && keyIndex <= dataValue) {
            validPrice = false;
          }
          if (obj["price"] > 0) {
            payload.push(obj);
          }
        });
    });

    let myObj = {
      userId: PrefillDetails.id,
      payload,
      user: currentRole.agency,
    };
    if (!validPrice) {
      toast.warn("Please add all rates.");
    }
    if (validPrice && !RejectedRate) {
      toast.warn(
        "Please note that previously rejected rates cannot be resubmitted with the rejected rates."
      );
    }
    if (props.isProfile && validPrice && RejectedRate) {
      dispatch(userRateCreate(myObj)).then((res) => {
        if (props.isProfile) {
          toast.info("Rates updated successfully.");
        }
        props.triggerTabChanges();
        dispatch(getUserDetailsById(getUserId()?.userId));

        if (currentRole.agency) {
          dispatch(getDefaultRates(currentRole.agency));
        }
      });
    } else if (
      validPrice &&
      RejectedRate &&
      (dataValue === 2 || triggerUpdateState)
    ) {
      dispatch(userRateCreate(myObj)).then((res) => {
        if (props.isProfile) {
          toast.info("Rates updated successfully.");
          props.triggerTabChanges();
        }
        if (res) {
          props.setTabUpdateCheker && props.setTabUpdateCheker();
        }
      });
    } else {
      setDataValue(dataValue + 1);
    }
  }

  const setInitialValue = (event) => {
    if (event && Object.keys(event).length > 0) {
      return event;
    }
    const initialvalues = {};

    defaultRates &&
      defaultRates.map((e) => {
        let rateDetails = JSON.parse(JSON.stringify(e.RateTabDetails));

        if (PrefillDetails && PrefillDetails.userRates?.length > 0) {
          for (var i = 0; i < rateDetails.length; i++) {
            let rate = PrefillDetails.userRates.find(
              (a) => a.rateTabDetailId === rateDetails[i].id
            );

            if (rate) {
              rateDetails[i]["rate"] = `${rate.price}`;
              rateDetails[i]["primaryId"] = rate.id;
            }
          }
        }

        initialvalues[renameFormat(e.name)] = rateDetails;
      });
    return initialvalues;
  };

  useEffect(() => {
    setDataValue(0);
  }, []);

  const validateForm = (values) => {
    let errors = {};
    let arr = Object.keys(values);
    arr &&
      arr.length > 0 &&
      arr.map((item, i) => {
        let itemErrors = [];
        let valid = false;
        if (values[item] && values[item].length > 0) {
          values[item].map((ele, ind) => {
            let error = {};
            if (ele && ele.rate && !floatRegex.test(ele.rate)) {
              error["rate"] = "Please enter a USD rate.";
            }
            if (!ele.rate && i === dataValue) {
              error["rate"] = "Please enter rate for each visit type.";
            }
            if (ele.rate && i === dataValue && ele.rate == 0) {
              error["rate"] = "Rate should be greater than zero.";
            }
            itemErrors.push(error);
          });
          errors[item] = itemErrors;
        } else {
          delete errors[item];
        }
      });
    // dispatch(ProfileValidState(Object.keys(errors).length > 0 ? true : false))
    // const hasErrors = Object.values(errors).some(errorArray => Object.keys(errorArray).length > 0);
    const hasRateError = Object.values(errors).some((errorArray) =>
      errorArray.some((errorObj) => errorObj.rate !== undefined)
    );
    dispatch(profileValidStateDefaultRate(hasRateError));
    return errors;
  };

  const rateRejectHandle = (rateName, value) => {
    let isRejected = false;
    if (
      PrefillDetails &&
      PrefillDetails?.Status?.key === defaultStatus.rejected &&
      PrefillDetails?.userDetail?.rejectedFields &&
      PrefillDetails?.userDetail?.rejectedFields[rateName] &&
      value == PrefillDetails?.userDetail?.rejectedFields[rateName]
    ) {
      isRejected = true;
    }
    return isRejected;
  };

  const handleBackStep = () => {
    if (dataValue === 0) {
      props.goBackFunction(agencySignupSteps[1]);
    } else {
      setDataValue(dataValue - 1);
    }
  };

  let fields = [
    "physicaltherapistpt",
    "occupationaltherapistot",
    "speechtherapistst",
  ];

  const checkDirtyFields = (obj) => {
    let value = true;
    Object.keys(obj).map((keys) => {
      if (obj && !fields.includes(keys)) {
        value = false;
      }
    });
    return value;
  };

  const triggerUpdateState = useSelector(
    (state) => state.therapistSigunpSlice.triggerUpdateFunction
  );

  useEffect(() => {
    if (triggerUpdateState && buttonRef) {
      setTimeout(() => {
        buttonRef.current.click();
      }, 100);
      // dispatch(triggerUpdateButton(false))
    }
  }, [triggerUpdateState, buttonRef]);

  return (
    <div className="container-fluid">
      <div className="row">
        {!props.isProfile && (
          <h2 className="cs-heading set-head px-0">Set Default Rates</h2>
        )}

        <div className="col-md-7  p-0  ">
          <div className="d-flex mobile_wrap gap-3  justify-content-between head-margin">
            {defaultRates &&
              defaultRates.map((e, index) => {
                return (
                  <p
                    className={` ${index === dataValue ? "ad-activee " : "ad-headd "
                      }`}
                    key={e.name}
                    onClick={() => setDataValue(index)}
                  >
                    {e.displayName}
                  </p>
                );
              })}
          </div>

          <Form
            validate={validateForm}
            onSubmit={handleSubmit}
            initialValues={useMemo(
              (e) => setInitialValue(e),
              [PrefillDetails, defaultRates]
            )}
            mutators={{
              ...arrayMutators,
            }}
            render={({
              values,
              handleSubmit,
              valid,
              submitFailed,
              pristine,
              dirtyFields,
            }) => (
              <form
                onSubmit={(e) => {
                  if (!valid)
                    toast.error("Please complete all required fields.");
                  handleSubmit(e);
                }}
                style={{ paddingBottom: "15vh" }}
              >
                {props.isProfile &&
                  props.pristineCheck &&
                  props.pristineCheck(checkDirtyFields(dirtyFields))}
                {props.isProfile &&
                  values &&
                  props.AgencyDefaultRatesVal(values)}
                {values &&
                  Object.keys(values).map((item, index) => (
                    <FieldArray name={item} key={item}>
                      {({ fields }) =>
                        fields.map((name, ind) => {
                          if (index === dataValue) {
                            return (
                              <Field
                                name={`${name}.rate`}
                                key={`${item}_${ind}`}
                              >
                                {({ input, meta }) => (
                                  <div className=" d-flex mobile_wrap gap-3 justify-content-between">
                                    <label
                                      className="col-md-9 ad-labels  p-0 lable-1440 mobile_width"
                                      style={{ width: "65%" }}
                                    >
                                      {values[item][ind].name}
                                    </label>
                                    <div className=" col-md-4 mobile_width">
                                      <InputGroup
                                        className={`mb-3 rate_fields ${
                                          rateRejectHandle(
                                            `rate_${defaultRates[dataValue]?.id}_${values[item][ind]?.id}`,
                                            input.value
                                          )
                                            ? "rejected_text"
                                            : ""
                                        }`}
                                      >
                                        <InputGroup.Text
                                          id="  -addon1"
                                          className="pr-0 pt-0 pb-0 border-end-0 text-primary"
                                          style={{
                                            background: " none",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          $
                                        </InputGroup.Text>
                                        <BootStrapForm.Control
                                          className="ad-input form-control border-start-0 pl-1 input-rate-visit color-blue text-primary text_blue"
                                          {...input}
                                          aria-describedby="basic-addon1"
                                          type="number"
                                          min="0"
                                          step="0.01"
                                          onKeyDown={(e) => blockInvalidChar(e)}
                                        />
                                      </InputGroup>
                                      {meta.error && submitFailed && (
                                        <p
                                          className="alert alert-danger top-11"
                                          role="alert"
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Field>
                            );
                          }
                        })
                      }
                    </FieldArray>
                  ))}
                {!props.isProfile ? (
                  <div style={{ display: "flex" }} className="mt-3">
                    <button
                      className="ad-btnn justify-content-center ad_cls_btn"
                      type="submit"
                    >
                      {dataValue === 2 ? "Save & Continue" : "Continue"}
                    </button>
                    <button
                      className="ad-btn-back"
                      type="button"
                      onClick={() => handleBackStep()}
                    >
                      Back
                    </button>
                  </div>
                ) : (
                  <div style={{ display: "flex" }} className="mt-3">
                    <button
                      ref={buttonRef}
                      // className={`update-btn mt-5 mb-5 ${checkDirtyFields(dirtyFields) ? "opacity-50" : ""
                      // }`}
                      className={`update-btn mt-5 mb-5 ${!agencyCheck ? "opacity-50" : ""}`}
                      // disabled={checkDirtyFields(dirtyFields)}
                      disabled={!agencyCheck}
                      type="submit"
                    >
                      <IoSyncCircleOutline size={35} className="icons-update" />
                      Update
                    </button>
                  </div>
                )}
              </form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default AgencyDefaultRates;
