import Question from "../../images/forgot-password-btn.svg";
import Username from "../../images/login/username_icon.svg";
import Password from "../../images/login/password_lock_icon.svg";
import { useNavigate } from "react-router-dom";
import { signInSchema } from "../../validation/schemas";
import { Form, Field } from "react-final-form";
import { validateForm } from "../../validation/validateForm";
import { useSelector, useDispatch } from "react-redux";
import { TwoFactorVerify, signInCall } from "../../Redux/actions/auth";
import { Row, Col } from "react-bootstrap";
import logoCare from "../../images/care-connection-logo.png";
import AlertMessage from "../../components/AlertMessage";
import { clearAuthMessage } from "../../Redux/slices/authSlice";
import { currentRole, defaultStatus, rolePaths } from "../../utils/auth";
import Loader from "../../components/Loader";
import { InputGroup } from "bootstrap-4-react/lib/components";
import BootstrapForm from "react-bootstrap/Form";
import RightImg from "../../images/login-img_2560.png";
import { useEffect, useRef, useState } from "react";
import LoginOtpVerification from "../../components/common/LoginOtpVerification";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validate = validateForm(signInSchema);
  const message = useSelector((e) => e.authSlice.message);
  const isLoading = useSelector((e) => e.authSlice.isLoading);
  const keepMeSignedInVaue = JSON.parse(
    sessionStorage.getItem("keepMeSignedInData")
  );
  const [OTPModal, setOTPModal] = useState(false);
  const handleClose = () => setOTPModal(false);
  const handleShow = () => setOTPModal(true);
  const [isVerified, setIsVerified] = useState(false);

  const setToken = (token, keeplogged) => {
    if (keeplogged) {
      var expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 30);
      var expires = "expires=" + expirationDate.toUTCString();
      var cookieString = `token=${token};${expires};path=/`;
      document.cookie = cookieString;
      // localStorage.setItem("token", token);
    } else {
      document.cookie = `token=${token}; path=/`;
    }
    window.location.href = `/home`;
    setTimeout(() => {
      dispatch(clearAuthMessage());
    }, 2000);
  };
  const routesByRole = {
    agency: "agencysignup",
    supervisingtherapist: "supervisingtherapist",
    assistanttherapist: "supervisingtherapist",
  };
  const statusBasedRedirection = (status, token, role, keeplogged) => {
    switch (status) {
      case defaultStatus.approved:
        setToken(token, keeplogged);
        break;
      case defaultStatus.pending:
        navigate("/UserUnderReview");
        break;
      case defaultStatus.inprogress:
        navigate("/UserUnderReview");
        break;
      case defaultStatus.incomplete:
        window.location.href = `/${routesByRole[role]}?auth=${token}`;
        break;
      default:
        window.location.href = "/";
        break;
    }
  };

  const statusBasedRedirectionTwoFactor = (status, token, role, keeplogged) => {
    switch (status) {
      case defaultStatus.pending:
        navigate("/UserUnderReview");
        break;
      case defaultStatus.inprogress:
        navigate(`/${routesByRole[role]}?auth=${token}`);
        break;
      case defaultStatus.incomplete:
        window.location.href = `/${routesByRole[role]}?auth=${token}`;
        break;
      case defaultStatus.rejected:
        window.location.href = `/${routesByRole[role]}?auth=${token}`;
        break;
      default:
        break;
    }
  };

  async function handleSubmit(values) {
    let keeplogged = values?.keepMeSignedIn;
    if (values?.keepMeSignedIn === true) {
      if (window.sessionStorage) {
        window.sessionStorage.setItem(
          "keepMeSignedInData",
          JSON.stringify(values)
        );
      }
    } else {
      window.sessionStorage.removeItem("keepMeSignedInData");
    }
    delete values?.keepMeSignedIn;
    let tempValues = {
      email: values.email,
      password: values.password,
      isVerify: `${isVerified}`,
    };
    await dispatch(TwoFactorVerify(tempValues)).then((response) => {
      if (!response?.payload?.success) {
        return;
      } else {
        if (
          response.payload.user &&
          response.payload.user.Status &&
          response.payload.user.Status.key === defaultStatus.approved &&
          !response.payload.otpStatus &&
          response.payload.user &&
          !response.payload.user.twoStepEnabled
        ) {
          if (!isVerified) handleShow();
        } else if (response?.payload?.otpStatus === "pending") {
          if (!isVerified) handleShow();
        } else {
          let user = response.payload.user;
          let status = user.Status.key;
          let role = user.Role.key;
          if (
            role === currentRole.supervisingtherapist ||
            role === currentRole.assistanttherapist ||
            role === currentRole.agency
          ) {
            statusBasedRedirectionTwoFactor(
              status,
              response.payload.token,
              role,
              keeplogged
            );
          }
        }
      }
    });
  }

  useEffect(() => {
    let islogined = false;
    document.cookie.split(";").map((item) => {
      if (item.split("=")[0].trim() === "token") {
        islogined = true;
      }
    });
    if (islogined) navigate("/");
  }, []);
  return (
    <div className="login-main  position-relative ">
      <div className="container-fluid ">
        <Form
          onSubmit={handleSubmit}
          initialValues={
            keepMeSignedInVaue && {
              password: keepMeSignedInVaue?.password,
              email: keepMeSignedInVaue?.email,
              keepMeSignedIn:
                keepMeSignedInVaue &&
                keepMeSignedInVaue?.keepMeSignedIn &&
                keepMeSignedInVaue?.keepMeSignedIn,
            }
          }
          validate={validate}
          render={({ handleSubmit, submitting, values, form }) => (
            <form onSubmit={handleSubmit}>
              <Row className=" align-items-center loginContainer">
                <Col className="col-8 col-md-4 p-36">
                  <div className="left_side_form_parent">
                    <div className="login-logo margin-top-login">
                      <img src={logoCare} alt="img" className="img-fluid" />
                    </div>
                    <div className="heading-text">
                      <h3>Login</h3>
                      <p>Sign in with your credentials</p>

                      <div className="input-group mb-3">
                        <Field name="email">
                          {({ input, meta }) => (
                            <>
                              <InputGroup>
                                <InputGroup.Text
                                  id="basic-addon1"
                                  className="border-end-0 bg-white rounded-start"
                                >
                                  <img src={Username} alt="" />
                                </InputGroup.Text>
                                <BootstrapForm.Control
                                  {...input}
                                  type="text"
                                  className="login_inputs border-start-0 shadow-none rounded-end"
                                  placeholder="Username"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                              {meta.error
                                ? meta.touched && (
                                    <p className="input-error login-error">
                                      {meta.error}
                                    </p>
                                  )
                                : message &&
                                  message.user && (
                                    <p className="input-error login-error">
                                      {message.user}
                                    </p>
                                  )}
                            </>
                          )}
                        </Field>
                        {/* {message && message.user && (
                          <p className="input-error login-error">
                            {message.user}
                          </p>
                        )} */}
                      </div>

                      <div className="input-group mb-3">
                        <Field name="password">
                          {({ input, meta }) => (
                            <>
                              <InputGroup className="position-relative">
                                <InputGroup.Text className="border-end-0 bg-white rounded-start">
                                  <img src={Password} alt="" />
                                </InputGroup.Text>
                                <BootstrapForm.Control
                                  {...input}
                                  type="password"
                                  placeholder="Password"
                                  aria-label="Password"
                                  className=" border-start-0 rounded-end"
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                              <div className="position-absolute fogot_icn">
                                <img
                                  src={Question}
                                  style={{ cursor: "pointer" }}
                                  alt=""
                                  onClick={() => navigate("/forgotpassword")}
                                />
                              </div>
                              {meta.error
                                ? meta.touched && (
                                    <p className="input-error login-error">
                                      {meta.error}
                                    </p>
                                  )
                                : message &&
                                  message.password && (
                                    <p className="input-error login-error">
                                      {message.password}
                                    </p>
                                  )}
                            </>
                          )}
                        </Field>
                      </div>
                      <div className="form-group checkbox-list">
                        <Field name="keepMeSignedIn" type="checkbox">
                          {({ input }) => (
                            <>
                              <input
                                type="checkbox"
                                {...input}
                                checked={
                                  values &&
                                  values?.keepMeSignedIn &&
                                  values?.keepMeSignedIn
                                }
                                className="checkbox checkbox1"
                              />
                            </>
                          )}
                        </Field>

                        <label>Keep me signed in</label>
                      </div>
                      <button
                        type="submit"
                        disabled={submitting}
                        className="login-button mb-5"
                      >
                        Login
                      </button>

                      {message && message.default && (
                        <AlertMessage
                          variant="danger"
                          message={message.default}
                        />
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={6} className="mobile-none"></Col>
                <div className="right-side-img">
                  <img src={RightImg} className="img-fluid imglogin" alt="" />
                </div>
                {!OTPModal && isLoading && <Loader />}
                {OTPModal && (
                  <LoginOtpVerification
                    handleSubmit={handleSubmit}
                    show={OTPModal}
                    handleClose={handleClose}
                    values={values}
                    statusBasedRedirection={statusBasedRedirection}
                    form={form}
                    isVerified={isVerified}
                    setIsVerified={setIsVerified}
                  />
                )}
              </Row>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default Login;
