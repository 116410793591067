import React from "react";
import { useSelector } from "react-redux";
import Digno from "../../images/patient/diagnosis_icon.svg";
import location from "../../images/newtherapist/location_icon.svg";
import specialRequest from "../../images/patient/emergency_contact_name_icon.svg";
import agencyName from "../../images/patient/agency_black_icon.svg";
import gender from "../../images/patient/sex_icon.svg";
import Dob from "../../images/patient/birthdate_icon.svg";
import insurence from "../../images/AdminDashboard/insurance_icon.svg";
import phone from "../../images/patient/call_black_icon.svg";
import Phycian from "../../images/patient/physician_black_icon.svg";
import Emergency from "../../images/patient/emergency_contact_name_icon.svg";
import email from "../../images/patient/email_address_icon.svg";
import date_requested_icon from "../../images/date_requested_icon.svg";
import discipline_n from "../../images/discipline_needed_icon.svg";
import visit from "../../images/visit_type_icon.svg";
import request from "../../images/patient/special_requests_icon.svg";
import { dateFormatChange, joinArrayObj } from "../../utils/helper";

export const CommonReferralInfo = ({ dataColumns, mainClass }) => {
  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);
  const primaryDiagnosis = staffDetail?.staffingDiagnosisdetails?.find(
    (diagnosis) => diagnosis.types === "primarydiagnosis"
  );
  const secondaryDiagnosis = staffDetail?.staffingDiagnosisdetails?.filter(
    (diagnosis) => diagnosis.types === "secondarydiagnosis"
  );
  const valueOfPrimaryDiagnosis = primaryDiagnosis
    ? primaryDiagnosis.masterData.displayName
    : null;

  const getAddresstype = () => {
    if (staffDetail && staffDetail.staffingDetails) {
      return staffDetail.staffingDetails["addressType"] === "no"
        ? "ADDRESS"
        : "FACILITY ADDRESS";
    } else {
      return "-";
    }
  };
  const getValue = (key) => {
    let value = "--";

    if (staffDetail && staffDetail.staffingDiagnosisdetails) {
      switch (key) {
        case "diagnosis":
          secondaryDiagnosis && secondaryDiagnosis.length > 0
            ? (value =
                valueOfPrimaryDiagnosis +
                ", " +
                joinArrayObj(secondaryDiagnosis, "masterData", "displayName"))
            : (value = valueOfPrimaryDiagnosis);
          break;
        case "sex":
          value = staffDetail.staffingDetails.gender;
          break;
        case "dob":
          value = staffDetail.staffingDetails.dateOfBirth;
          break;
        case "patientemailaddress":
          value = staffDetail.email;
          break;
        case "daterequired":
          value = staffDetail.staffingDetails.daterequired;
          break;
        case "initialvisittype":
          value =
            staffDetail &&
            staffDetail?.staffInitialVisit[0] &&
            staffDetail?.staffInitialVisit[0]?.initialVisitType &&
            staffDetail?.staffInitialVisit[0]?.initialVisitType?.displayName;
          break;
        case "address":
          value = (
            <>
              <p className="m-0 font-size_16">
                {staffDetail.staffingDetails["address"]}
              </p>
              <p className="m-0 font-size_16">
                {staffDetail.staffingDetails["city"]}
                {", "}
                {staffDetail.staffingDetails["state"]}
                {", "}
                {staffDetail.staffingDetails["zip"]}
              </p>
            </>
          );
          break;
        case "disciplineneeded":
          value =
            staffDetail.staffingDetails.disciplinesNeeded &&
            Object.keys(staffDetail.staffingDetails.disciplinesNeeded).length >
              0 &&
            Object.keys(staffDetail.staffingDetails.disciplinesNeeded)
              .join(", ")
              .toUpperCase();
          break;
        case "specialrequests":
          value =
            staffDetail.staffingDetails.otherSpecialRequest &&
            staffDetail.staffingDetails.otherSpecialRequest;
          break;
        case "agencyname":
          value = staffDetail.createdByUser && staffDetail.createdByUser.name;
          break;
        case "agencyphone":
          value =
            staffDetail.createdByUser &&
            staffDetail.createdByUser.userDetail &&
            staffDetail.createdByUser.userDetail.phone;
          break;
        case "physician":
          value = staffDetail.staffingDetails.physicianName
            ? staffDetail.staffingDetails.physicianName
            : "--";
          break;
        case "physicianphone":
          value = staffDetail.staffingDetails.physicianPhoneNumber;
          break;
        case "ptaallowed":
          value = staffDetail.staffingDetails.ptaAllowed ? "YES" : "NO";
          break;
        case "initialvisitdate":
          value = staffDetail?.staffInitialVisit[0]?.initialVisitDate;
          break;
        case "anticipateddate":
          value = staffDetail?.staffInitialVisit[0]?.anticipatedSocDate;
          break;
        case "insurance":
          value = staffDetail.staffingDetails.insurance;
          break;
        case "emergencycontactname":
          value = staffDetail.staffingDetails.emergencyContactName;
          break;
        case "emergencycontactnumber":
          value = staffDetail.staffingDetails.emergencyContactNumber;
          break;
        case "assistantrequired":
          value =
            staffDetail.staffingDetails.cotaAllowed ||
            staffDetail.staffingDetails.ptaAllowed
              ? "Yes"
              : "No";
          break;
        default:
          break;
      }
      return value;
    }
  };

  const mydata = [
    {
      name: "DIAGNOSIS",
      key: "diagnosis",
      value: getValue("diagnosis"),
      icon: <img src={Digno} className="Common-referral-icons" alt="" />,
    },
    {
      name: "SEX",
      key: "sex",
      value: getValue("sex"),
      icon: <img src={gender} className="Common-referral-icons" alt="" />,
    },
    {
      name: "DATE OF BIRTH",
      key: "dob",
      value: dateFormatChange(getValue("dob")),
      icon: <img src={Dob} className="Common-referral-icons" alt="" />,
    },
    {
      name: "PATIENT EMAIL ADDRESS",
      key: "patientemailaddress",
      value: getValue("patientemailaddress"),
      icon: <img src={email} className="Common-referral-icons" alt="" />,
    },
    {
      name: "DATE REQUESTED",
      key: "initialvisitdate",
      value: dateFormatChange(getValue("initialvisitdate")),
      icon: (
        <img
          src={date_requested_icon}
          className="Common-referral-icons"
          alt=""
        />
      ),
    },
    {
      name: "Anticipated SOC Date",
      key: "anticipateddate",
      value: dateFormatChange(getValue("anticipateddate")),
      icon: (
        <img
          src={date_requested_icon}
          className="Common-referral-icons"
          alt=""
        />
      ),
    },

    {
      name: getAddresstype(),
      key: "address",
      value: getValue("address"),
      icon: <img src={location} className="Common-referral-icons" alt="" />,
    },
    {
      name: "Disciplines Needed",
      key: "disciplineneeded",
      value: getValue("disciplineneeded"),
      icon: <img src={discipline_n} className="Common-referral-icons" alt="" />,
    },
    {
      name: "SPECIAL REQUESTS/OTHER ",
      key: "specialrequests",
      value: getValue("specialrequests"),
      icon: <img src={request} className="Common-referral-icons" alt="" />,
    },
    {
      name: "PTA Allowed",
      key: "ptaallowed",
      value: getValue("ptaallowed"),
      icon: (
        <img src={specialRequest} className="Common-referral-icons" alt="" />
      ),
    },
    {
      name: "INTIAL VISIT TYPE",
      key: "initialvisittype",
      value: getValue("initialvisittype"),
      icon: <img src={visit} className="Common-referral-icons" alt="" />,
    },
    {
      name: "INSURANCE",
      key: "insurance",
      value: getValue("insurance"),
      icon: <img src={insurence} className="Common-referral-icons" alt="" />,
    },
    {
      name: "AGENCY",
      key: "agencyname",
      value: getValue("agencyname"),
      icon: <img src={agencyName} className="Common-referral-icons" alt="" />,
    },
    {
      name: "AGENCY PHONE",
      key: "agencyphone",
      value: getValue("agencyphone"),
      icon: <img src={phone} className="Common-referral-icons" alt="" />,
    },
    {
      name: "PHYSICIAN",
      key: "physician",
      value: getValue("physician"),
      icon: <img src={Phycian} className="Common-referral-icons" alt="" />,
    },
    {
      name: "PHYSICIAN PHONE",
      key: "physicianphone",
      value: getValue("physicianphone"),
      icon: <img src={phone} className="Common-referral-icons" alt="" />,
    },
    {
      name: "EMERGENCY CONTACT NAME",
      key: "emergencycontactname",
      value: getValue("emergencycontactname"),
      icon: <img src={Emergency} className="Common-referral-icons" alt="" />,
    },
    {
      name: "EMERGENCY CONTACT NUMBER",
      key: "emergencycontactnumber",
      value: getValue("emergencycontactnumber"),
      icon: <img src={phone} className="Common-referral-icons" alt="" />,
    },
    {
      name: "Assistant Required",
      key: "assistantrequired",
      value: getValue("assistantrequired"),
      icon: <img src={Phycian} className="Common-referral-icons" alt="" />,
    },
  ];

  return (
    <>
      <div className={`${mainClass ? mainClass : " test-panding "}`}>
        <>
          {dataColumns?.map((e, i) => {
            let findData = mydata.find((item) => item.key === e);
            if (findData?.key !== "specialrequests" || findData?.value) {
              return (
                <React.Fragment key={`${e}_${i}`}>
                  <div
                    className={`${
                      findData?.key !== "specialrequests"
                        ? "col-md-4"
                        : "col-md-12"
                    }  Referral-info mb-5 align-items-start p-0 gap-3`}
                  >
                    <div className="referral_icon" style={{ color: "#0A365B" }}>
                      {findData && findData.icon}
                    </div>
                    <div className="referral_info ">
                      <p className="referral_name font-size_16  m-8" style={{}}>
                        {findData && findData.name}
                      </p>
                      <p
                        className={`${
                          findData?.key !== "specialrequests"
                            ? ""
                            : "pe-4 text-justify"
                        } referral_value m-0 font-size_16`}
                        style={{ color: "#0A365B" }}
                      >
                        {findData && findData.value}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              );
            }
          })}
        </>
      </div>
    </>
  );
};
