import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import image from "../../../images/t&c.png";
import editWordDoc from "../../../images/editword.svg";
import jsPDF from "jspdf";
import {
  decodeToken,
  defaultStatus,
  therapistSignupSteps,
  userCredentials,
} from "../../../utils/auth";
import { updateProfileStatus } from "../../../Redux/actions/auth";
import {
  BsFillCheckCircleFill,
  BsFillXCircleFill,
  BsPencilSquare,
} from "react-icons/bs";
import Loader from "../../../components/Loader";
import { GrClose } from "react-icons/gr";
import { MyModal2 } from "../../modals/MyModal2";
import OnboardingForms from "../../../components/OnbaordingForms";
import PTSkills from "../../Patients/CompetencyDocuments/PTSkillChecklist";
import PTASkills from "../../Patients/CompetencyDocuments/PTASkillChecklist";
import { saveOnboardingForm } from "../../../Redux/actions/therapist";
import { toast } from "react-toastify";

const Compliance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let userDetail = null;

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [credentialFiles, setCredentialFiles] = useState([]);
  const [credentialPayload, setCredentialPayload] = useState([]);
  const [skillsModal, setSkillsModal] = useState(false);
  const [onboardingDocType, setOnboardingDocType] = useState(null);
  const [uploadedFileBytes, setUploadedFileBytes] = useState({});
  const [signatureImage, setSignatureImage] = useState(null);
  const [signatureAdded, setSignatureAdded] = useState(false);
  if (searchParams && searchParams.get("auth")) {
    userDetail = decodeToken(searchParams.get("auth"));
  }

  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const isLoading = useSelector(
    (e) => e.therapistSigunpSlice.isTherapistLoading
  );
  const updateProfile = () => {
    if (
      (PrefillDetails && PrefillDetails.complianceCompetency === null) ||
      PrefillDetails.complianceCompetency.competency === null ||
      PrefillDetails.complianceCompetency.compliance === null
    ) {
      setError("Pleae complete both steps to proceed further");
      setTimeout(() => {
        setError(null);
      }, 2000);
    } else {
      dispatch(
        updateProfileStatus({
          status: defaultStatus.pending,
          userId: PrefillDetails ? PrefillDetails.id : userDetail.userId,
          onbordingPartCompleted: 2,
          pendingSteps: therapistSignupSteps[therapistSignupSteps.length - 1],
        })
      ).then((response) => {
        setOpen(true);
      });
    }
  };

  const setGreenIcon = (type) => {
    let isGreen = false;
    if (
      PrefillDetails &&
      PrefillDetails.onboardingPdf &&
      PrefillDetails.onboardingPdf.length > 0
    ) {
      let isExist = PrefillDetails.onboardingPdf.find(
        (item) => item.types === type
      );
      if (!isExist) {
        isGreen = false;
      } else {
        isGreen = true;
      }
    } else {
      isGreen = uploadedFileBytes[type] ? true : false;
    }
    return isGreen;
  };

  const checkRejected = (type) => {
    let isRejected = false;
    if (uploadedFileBytes[type]) return false;
    if (PrefillDetails && PrefillDetails.userDetail) {
      if (
        PrefillDetails.Status.key === defaultStatus.rejected &&
        PrefillDetails.userDetail.rejectedFields &&
        PrefillDetails.userDetail.rejectedFields[type] === "rejected"
      ) {
        isRejected = true;
      }
    }

    return isRejected;
  };

  const handleOnboardingDocs = (key) => {
    setOnboardingDocType(key);
  };

  const handleChange = (event, typename, values) => {
    let fileList = [...credentialFiles];
    let payloadList = [...credentialPayload];

    let temp = `${typename}_`;
    const filename = temp + new Date().getTime();
    let filedata = {};
    filedata.types = typename;
    filedata.name = event.target.files[0].name;
    let detail = {};
    if (
      PrefillDetails &&
      PrefillDetails.onboardingPdf &&
      PrefillDetails.onboardingPdf.length > 0
    ) {
      detail = PrefillDetails.onboardingPdf.find(
        (credential) => credential.types === typename
      );
    }
    if (detail && detail.id) {
      filedata.id = detail.id;
    }
    const extension = event.target.files[0].name.split(".").pop();
    let file = new File(event.target.files, `${filename}.${extension}`);

    if (fileList.length === 0) {
      fileList.push(file);
    } else {
      fileList.map((ele, ind) => {
        if (ele.name.includes(temp)) {
          fileList.splice(ind, 1);
        }
      });
      fileList.push(file);
    }

    if (payloadList.length === 0) {
      payloadList.push(filedata);
    } else {
      payloadList.map((ele, ind) => {
        if (ele.types.includes(typename)) {
          payloadList.splice(ind, 1);
        }
      });
      payloadList.push(filedata);
    }
    if (typename === userCredentials.competencies)
      setUploadedFileBytes({ ...uploadedFileBytes, competencies: values });
    setCredentialFiles(fileList);
    setCredentialPayload(payloadList);
  };
  const competencyDocuments = {
    pt: (
      <PTSkills
        handleChange={handleChange}
        uploadedFileBytes={uploadedFileBytes}
        setUploadedFileBytes={setUploadedFileBytes}
        setSkillsModal={setSkillsModal}
        signatureImage={signatureImage}
        setSignatureImage={setSignatureImage}
        signatureAdded={signatureAdded}
        setSignatureAdded={setSignatureAdded}
      />
    ),
    pta: (
      <PTSkills
        handleChange={handleChange}
        uploadedFileBytes={uploadedFileBytes}
        setUploadedFileBytes={setUploadedFileBytes}
        setSkillsModal={setSkillsModal}
        signatureImage={signatureImage}
        setSignatureImage={setSignatureImage}
        signatureAdded={signatureAdded}
        setSignatureAdded={setSignatureAdded}
      />
    ),
    ot: (
      <PTSkills
        handleChange={handleChange}
        uploadedFileBytes={uploadedFileBytes}
        setUploadedFileBytes={setUploadedFileBytes}
        setSkillsModal={setSkillsModal}
        signatureImage={signatureImage}
        setSignatureImage={setSignatureImage}
        signatureAdded={signatureAdded}
        setSignatureAdded={setSignatureAdded}
      />
    ),
    cota: (
      <PTSkills
        handleChange={handleChange}
        uploadedFileBytes={uploadedFileBytes}
        setUploadedFileBytes={setUploadedFileBytes}
        setSkillsModal={setSkillsModal}
        signatureImage={signatureImage}
        setSignatureImage={setSignatureImage}
        signatureAdded={signatureAdded}
        setSignatureAdded={setSignatureAdded}
      />
    ),
    slp: (
      <PTSkills
        handleChange={handleChange}
        uploadedFileBytes={uploadedFileBytes}
        setUploadedFileBytes={setUploadedFileBytes}
        setSkillsModal={setSkillsModal}
        signatureImage={signatureImage}
        setSignatureImage={setSignatureImage}
        signatureAdded={signatureAdded}
        setSignatureAdded={setSignatureAdded}
      />
    ),
  };

  const documentSubmit = () => {
    const formData = new FormData();

    for (var i = 0; i < credentialFiles.length; i++) {
      delete credentialFiles[i].orgFileData;
      formData.append("files", credentialFiles[i]);
    }
    formData.append(
      "therapistData",
      JSON.stringify({
        values: credentialPayload,
        userId: PrefillDetails && PrefillDetails.id,
      })
    );
    let onboard = ["compliance", "competencies"];
    let isuploaded = true;
    onboard.forEach((item) => {
      let detail = PrefillDetails.onboardingPdf.find(
        (credential) => credential.types === item
      );
      if (detail) {
        if (!detail.file) {
          isuploaded = false;
        }
      } else {
        if (!uploadedFileBytes[item]) {
          isuploaded = false;
        }
      }
    });
    if (isuploaded) {
      dispatch(saveOnboardingForm(formData)).then((res) => {
        dispatch(
          updateProfileStatus({
            status: defaultStatus.pending,
            userId: PrefillDetails ? PrefillDetails.id : userDetail.userId,
            onbordingPartCompleted: 2,
            pendingSteps: therapistSignupSteps[therapistSignupSteps.length - 1],
          })
        ).then((response) => {
          setOpen(true);
        });
      });
    } else {
      toast.error("Please complete all required fields.");
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="pl-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-10">
              {PrefillDetails &&
                PrefillDetails.Status &&
                PrefillDetails.Status.key === defaultStatus.inprogress && (
                  <div variant={"success"} className="mb-4 congratulation-box">
                    <h4>Congratulations!</h4>
                    <p>
                      Part 1 of your on-boarding has been approved. Please
                      continue to Part 2 to complete your Compliance Documents
                      and Competency Assessment.
                    </p>
                  </div>
                )}
            </div>
          </div>
        </div>

        <div>
          <div className="container-fluid">
            <div className="row" style={{ gap: "20px" }}>
              <h1 className="compliance-head compliance-margin">
                Compliance Documents and Competency Assessment
              </h1>

              <div className="col-md-12   ">
                <div className="d-flex flex-wrap gap-5">
                  <div
                    className="box-step"
                    // onClick={() =>
                    //   (window.location.href = `${complianceForm}?UserId=${userDetail.userId}&Signer_Email=${userDetail.email}&Signer_UserName=${userDetail.firstName} ${userDetail.lastName}`)
                    // }
                    onClick={() => {
                      handleOnboardingDocs(userCredentials.compliance);
                    }}
                  >
                    <div className="d-flex flex-column">
                      <img
                        className="img-modal mt-3 edit-doc-icon"
                        src={editWordDoc}
                        alt="application submitted"
                      />
                      {/* <i>
                        <BsPencilSquare size={50} className="mb-2" />
                      </i> */}
                      <h1 className="cursor-pointer">Compliance Documents</h1>
                    </div>
                    <div className="check-icon-1">
                      {checkRejected("compliance") ? (
                        <>
                          <BsFillXCircleFill size={30} color={"#d40f4c"} />
                        </>
                      ) : setGreenIcon("compliance") ? (
                        <>
                          <BsFillCheckCircleFill size={30} color={"#3B9A3D"} />
                        </>
                      ) : (
                        <>
                          <BsFillCheckCircleFill size={30} color={"#C5E2F6"} />
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className="box-step"
                    // onClick={() =>
                    //   (window.location.href = `${competencyForm}?UserId=${userDetail.userId}&Signer_Email=${userDetail.email}&Signer_UserName=${userDetail.firstName} ${userDetail.lastName}`)
                    // }
                    onClick={() => {
                      setSkillsModal(true);
                    }}
                  >
                    <div className="d-flex flex-column">
                      <img
                        className="img-modal mt-3 edit-doc-icon"
                        src={editWordDoc}
                        alt="application submitted"
                      />
                      {/* <i>
                        <BsPencilSquare size={50} className="mb-2" />
                      </i> */}
                      <h1 className="cursor-pointer">Competency Assessment</h1>
                    </div>
                    <div className="check-icon-1">
                      {checkRejected("competencies") ? (
                        <BsFillXCircleFill size={30} color={"#d40f4c"} />
                      ) : setGreenIcon("competencies") ? (
                        <BsFillCheckCircleFill size={30} color={"#3B9A3D"} />
                      ) : (
                        <BsFillCheckCircleFill size={30} color={"#C5E2F6"} />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6 ml-4 pl-5 mt-5 ">
                {error && (
                  <p
                    className="alert alert-danger top-11 input-1440-2"
                    role="alert"
                  >
                    {error}
                  </p>
                )}
                <button
                  className="coverage-btn d-block m-auto"
                  onClick={() => documentSubmit()}
                >
                  Save and continue
                </button>
              </div>
            </div>
          </div>
        </div>
        {onboardingDocType && (
          <MyModal2
            size="lg"
            show={onboardingDocType}
            handleClose={() => {
              handleOnboardingDocs(null);
            }}
            modalClass={"formModal"}
            data={
              <OnboardingForms
                uploadedFileBytes={uploadedFileBytes}
                setUploadedFileBytes={setUploadedFileBytes}
                handleClose={() => {
                  handleOnboardingDocs(null);
                }}
                onboardingDocType={onboardingDocType}
                handleChange={handleChange}
              />
            }
          />
        )}
        {skillsModal && (
          <MyModal2
            size="lg"
            show={skillsModal}
            handleClose={() => {
              setSkillsModal(false);
            }}
            modalClass={"formModal"}
            data={competencyDocuments[PrefillDetails.userDetail.discipline]}
          />
        )}
        <Modal
          show={open}
          onHide={() => {
            setOpen(false);
            navigate("/");
          }}
          className=" AgencyModal"
        >
          <div className="closeicon_div">
            <GrClose
              size={20}
              className="closeicon"
              onClick={() => {
                setOpen(false);
                navigate("/");
              }}
            />
          </div>
          <div className=" padding-m-1 ">
            <p className="ss-h4 mt-4 mb-4">
              Part 2 of your application was submitted successfully and your
              account is being reviewed. You will receive an email with
              instructions for the next step.
            </p>
            <img
              className="img-modal mt-3"
              src={image}
              alt="application submitted"
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Compliance;
