import React, { useState } from "react";
import { useEffect } from "react";
import { BsCheck2 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { currentRole, decodeData } from "../../utils/auth";
import { Form, Field } from "react-final-form";
import { validateForm } from "../../validation/validateForm";
import { formSchema } from "../../validation/schemas";
import { toast } from "react-toastify";
import { userRejected, getDefaultRates } from "../../Redux/actions/therapist";
import { getUserDetailsById } from "../../Redux/actions/auth";
import { getUserDetailParams } from "../../utils/helper";
import Loader from "../../components/Loader";

const AgencyReject = () => {
  const validate = validateForm(formSchema);
  const [status, setStatus] = useState(0);
  const [rejStep, setRejStep] = useState([]);
  const [validateRej, setValidateRej] = useState(false);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((e) => e.therapistSigunpSlice.isLoading);

  function myFun(index) {
    setStatus(index);
  }

  useEffect(() => {
    dispatch(getDefaultRates(currentRole.agency));
    if (params && params.id)
      dispatch(
        getUserDetailsById(
          `${decodeData(params.id)}?${
            getUserDetailParams.secondaryEmails
          }=true&${getUserDetailParams.representativeEmails}=true`
        )
      );
  }, [dispatch, params]);

  let defaultRates = useSelector((e) => e.therapistSigunpSlice.defaultRates);
  const profileDetail = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );

  const getValues = (key, subkey) => {
    if (!profileDetail) {
      return;
    }
    if (profileDetail[key]) {
      if (typeof profileDetail[key] === "object") {
        if (profileDetail[key][subkey]) {
          if (subkey === "address") {
            return `${profileDetail?.userDetail?.address}, ${profileDetail?.userDetail?.city}, ${profileDetail?.userDetail?.state}, ${profileDetail?.userDetail?.zip}`;
          } else {
            return profileDetail[key][subkey];
          }
        } else {
          return "---";
        }
      }
      return profileDetail[key];
    } else {
      return "---";
    }
  };

  const handleSubmit = (values) => {
    let rejectedStep = {};
    if (rejStep?.length > 0) {
      const filData = rejStep?.reduce(function (a, b) {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
      }, []);
      filData?.forEach((item) => {
        rejectedStep[item] = true;
      });
    }

    let tempValues = {};
    Object.keys(values).map((key) => {
      if (values[key] && values[key].length > 0)
        tempValues[key] = values[key][0];
    });
    tempValues.reasonforRejection = values.reasonforRejection;
    const data = {
      userId: decodeData(params?.id),
      rejectedFields: tempValues,
      rejectedStep,
    };
    setValidateRej(true);
    if (
      data?.rejectedFields &&
      Object?.keys(data?.rejectedFields)?.length > 1
    ) {
      dispatch(userRejected(data)).then((res) => {
        if (res && res?.payload?.data) {
          toast.success("Agency rejected successfully.");
          setRejStep();
          setValidateRej(false);
          setTimeout(() => {
            navigate("/newusers");
          }, 500);
        }
      });
    } else {
      setValidateRej(true);
    }
  };
  const handleStep = (e, step) => {
    if (e?.target?.checked) {
      setValidateRej(false);
    }
    setRejStep([...rejStep, step]);
  };

  const detailArr = [
    { label: "Name", key: "name" },
    { label: "Username", key: "userName" },
    { label: "Password", key: "password" },
    { label: "Address", key: "userDetail", subKey: "address" },
    { label: "Agency Phone", key: "userDetail", subKey: "phone" },
    { label: "Agency Fax", key: "userDetail", subKey: "fax" },
    {
      label: "Agency Representative",
      key: "userDetail",
      subKey: "representative",
    },
    {
      label: "Agency Representative Phone",
      key: "userDetail",
      subKey: "representativePhoneNo",
    },
    { label: "Agency Representative Email", key: "representativeEmails" },
    { label: "Secondary Email", key: "secondaryEmails" },
  ];

  const getUserRates = (tabId, tabDetailId) => {
    let price = 0;
    if (
      profileDetail &&
      profileDetail.userRates &&
      profileDetail.userRates.length > 0
    ) {
      let rate = profileDetail.userRates.find(
        (item) =>
          item.rateTabDetailId === tabDetailId && item.rateTabId === tabId
      );
      if (rate) {
        price = rate.price;
      }
    }
    return price;
  };

  const disabled = ["userName", "password", "representativeEmails"];
  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      // initialValues={
      //   profileDetail?.userDetail && profileDetail?.userDetail?.rejectedFields
      //     ? {
      //         ...profileDetail?.userDetail?.rejectedFields,
      //       }
      //     : {}
      // }
      render={({ handleSubmit, valid }) => (
        <form
          onSubmit={(e) => {
            if (!valid) toast.error("Please complete all required fields.");
            handleSubmit(e);
          }}
        >
          <div className="">
            <div className="pl-4 pr-4">
              <div className=" col-12 ">
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <h4 className="new-therapist-head d-tharepist">
                    Reason for Rejection
                  </h4>

                  <button className="check-btns  mb-4" type="submit">
                    <BsCheck2
                      size={25}
                      className="check-icon icon-check mr-3"
                    />
                    Submit
                  </button>
                </div>
                <Field name="reasonforRejection">
                  {({ input, meta }) => (
                    <>
                      <div
                        className="col-md-6 p-0"
                        style={{ marginBottom: "47px" }}
                      >
                        <textarea
                          placeholder={!meta.active && "Need more info"}
                          className="agen-reject-input-1 w-100"
                          type="text"
                          {...input}
                        />
                        {meta.error && meta.touched && (
                          <p className="alert alert-danger w-100">
                            {meta.error}
                          </p>
                        )}
                        {validateRej && (
                          <p className="alert alert-danger w-100">
                            Please select at least one field to reject
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </Field>
              </div>

              <div className="specify-errors">
                <h4
                  style={{ marginBottom: "22px" }}
                  className=" col-12 new-therapist-head d-tharepist"
                >
                  Specify Errors
                </h4>
                <h4 className="col-12  agen-reject-lbl">Form</h4>

                <div className="row agen-reject-form-row  pl-3 pr-3">
                  <div className="col-md-11 ">
                    <div className=" w-100 pl-3 ">
                      <div className="row">
                        {detailArr?.map((item, index) => (
                          <React.Fragment key={`${item.key}_${index}`}>
                            {profileDetail &&
                            Array.isArray(profileDetail[item.key]) &&
                            profileDetail[item.key].length > 0
                              ? profileDetail[item.key].map((value, index) => (
                                  <div className="col-lg-3 col-md-4 pl-0">
                                    <div
                                      className="custom-control marging-bottom-thera p-0 align-items-start  d-flex"
                                      key={`${item.key}_${value.id}`}
                                    >
                                      <label className="container-card">
                                        <Field
                                          name={`${item.key}_${value.id}`}
                                          component="input"
                                          type="checkbox"
                                          value={getValues(
                                            item?.key,
                                            item?.subKey
                                          )}
                                        >
                                          {({
                                            input: { onChange, ...input },
                                          }) => (
                                            <input
                                              {...input}
                                              onChange={(e) => {
                                                onChange(e);
                                                handleStep(e, "account");
                                              }}
                                              disabled={
                                                profileDetail[item.key]
                                                  .length === 0 ||
                                                disabled.includes(item.key)
                                              }
                                              value={getValues(
                                                item?.key,
                                                item?.subKey
                                              )}
                                            />
                                          )}
                                        </Field>
                                        <span className=" checkmark1"></span>
                                      </label>

                                      <div
                                        className="form-details"
                                        style={{ marginLeft: "24px" }}
                                      >
                                        <p className="agency-reject-para1 mb-0 agen-reject-body text-wrap">
                                          {item?.label}{" "}
                                          {item.key === "secondaryEmails" &&
                                            index + 1}
                                        </p>
                                        <p className="agency-reject-para2 mb-0 agen-reject-body2 text-wrap ">
                                          {value.email}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : profileDetail &&
                                !Array.isArray(profileDetail[item.key]) && (
                                  <div
                                    className="col-lg-3 col-md-4 pl-0 "
                                    key={`${item.key}_${index}`}
                                  >
                                    <div className="custom-control marging-bottom-thera p-0 d-flex align-items-start">
                                      <label className="container-card">
                                        <Field
                                          name={
                                            item.subKey ? item.subKey : item.key
                                          }
                                          component="input"
                                          type="checkbox"
                                          disabled={disabled.includes(item.key)}
                                          value={getValues(
                                            item?.key,
                                            item?.subKey
                                          )}
                                        >
                                          {({
                                            input: { onChange, ...input },
                                          }) => (
                                            <input
                                              {...input}
                                              onChange={(e) => {
                                                onChange(e);
                                                handleStep(e, "account");
                                              }}
                                              disabled={disabled.includes(
                                                item.key
                                              )}
                                              value={getValues(
                                                item?.key,
                                                item?.subKey
                                              )}
                                            />
                                          )}
                                        </Field>
                                        <span className=" checkmark1"></span>
                                      </label>

                                      <div
                                        className="form-details"
                                        style={{ marginLeft: "24px" }}
                                      >
                                        <p className="agency-reject-para1 mb-0 agen-reject-body text-wrap">
                                          {item?.label}
                                        </p>
                                        <p className="agency-reject-para2 mb-0 agen-reject-body2 text-wrap">
                                          {getValues(item?.key, item?.subKey)}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <h4 className="agen-reject-lbl">Default Rates</h4>
                <div
                  className="d-flex mobile_wrap gap-3 agen-reject-check-form care-blue"
                  style={{ justifyContent: "space-between" }}
                >
                  {defaultRates &&
                    defaultRates.map((item, index) => {
                      return (
                        <React.Fragment key={`${item.displayName}_${index}`}>
                          <p
                            style={{ fontSize: "16px" }}
                            className={
                              status === index
                                ? "agency-user-type label-heading  ad-activee  agen-reject-head2"
                                : "agency-user-type label-heading agen-reject-head3"
                            }
                            onClick={(e) => myFun(index)}
                          >
                            {item.displayName}
                          </p>
                        </React.Fragment>
                      );
                    })}
                </div>
                <div className="row ">
                  {defaultRates &&
                    defaultRates[status] &&
                    defaultRates[status].RateTabDetails &&
                    defaultRates[status].RateTabDetails.map((item) => {
                      return (
                        <div
                          className="d-flex mobile_wrap mb-3
                           justify-content-between align-items-center mb-3 PTSectionInput"
                          key={item?.id}
                        >
                          <div
                            className="d-flex align-items-center mb-2"
                            style={{ gap: "24px" }}
                          >
                            <label className="container-card">
                              <Field
                                key={item?.id}
                                name={`rate_${defaultRates[status]?.id}_${item?.id}`}
                                component="input"
                                type="checkbox"
                                value={getUserRates(
                                  defaultRates[status]?.id,
                                  item?.id
                                )}
                              >
                                {({ input: { onChange, ...input } }) => (
                                  <input
                                    {...input}
                                    onChange={(e) => {
                                      const rate = getUserRates(
                                        defaultRates[status]?.id,
                                        item?.id
                                      );
                                      onChange(e);
                                      handleStep(e, "rates");
                                    }}
                                    value={getUserRates(
                                      defaultRates[status]?.id,
                                      item?.id
                                    )}
                                  />
                                )}
                              </Field>
                              <span className=" checkmark1"></span>
                            </label>

                            <label className=" ad-labels New-rate-l reject-lb align-self-center mobile_width  m-0 text-wrap agency-width-75">
                              {item.displayName}
                            </label>
                          </div>

                          <input
                            className="ad-input mobile_width  col-md-3 agen-reject-input "
                            placeholder="$"
                            value={`$${getUserRates(
                              defaultRates[status]?.id,
                              item?.id
                            )}`}
                            disabled
                          />
                        </div>
                      );
                    })}
                </div>

                <div></div>
                <button className="check-btns  mb-4" type="submit">
                  <BsCheck2 size={25} className="check-icon icon-check mr-3" />
                  Submit
                </button>
              </div>
            </div>
            {isLoading && <Loader />}
          </div>
        </form>
      )}
    />
  );
};

export default AgencyReject;
