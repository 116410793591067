import { Navigate, Outlet } from "react-router-dom";
import { withoutAuthRoute } from "../../utils/helper";

const LoginLayout = () => {
  // const token = localStorage.getItem("token");
  let token  
  if(localStorage.getItem("token")){
token = localStorage.getItem("token")
  }else{
// token = document.cookie.split("=")[1]
document.cookie.split(";").map(item=>{
  if(item.split("=")[0].trim()==="token"){
    token = item.split("=")[1]
  }
})
  };

  let pathName = window.location.pathname
    .toLowerCase()
    .replace(/^\/|\/$/g, "")
    .split("/");

  let checkIsWithoutAuthRoute = withoutAuthRoute.includes(pathName[0]);

  return (
    <>
      {token && !checkIsWithoutAuthRoute ? <Navigate to="/Home" /> : <Outlet />}
    </>
  );
};
export default LoginLayout;
