import React from "react";
import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import userImage from "../../images/usertable02.svg";
import { useSelector } from "react-redux";
import { apiBasePath } from "../../Redux/config/config";
import { currentRole, defaultStatus } from "../../utils/auth";
import { CommonReferralInfo } from "../../components/common/CommonReferralInfo";
import location from "../../images/newtherapist/location_icon2.svg";
import {
  staffViewFromAdmin,
  statusBaseColorChange,
  viewCaseStatueName,
  viewStatueName,
} from "../../utils/helper";
import { ViewVisitRate } from "../../components/common/ViewVisitRates";
import Loader from "../../components/Loader";
import AgencyTherapistTab from "../../pages/Agency/Modaltab/AgencyTherapistTab";
import { MdCall } from "react-icons/md";
import { AiFillMessage } from "react-icons/ai";

const PendingModal = (props) => {
  const [cancelModalState, setCancelModalState] = useState(false);
  const [isAllDeclined, setIsAllDeclined] = useState(false);

  const isLoading = useSelector((e) => e.staffingSlice.isModalLoading);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  let defaultRates = useSelector((e) => e.therapistSigunpSlice.defaultRates);
  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);

  const cancelModelParent = (staffDetail) => {
    props?.cancelModalHandler(staffDetail);
  };

  const isShowPhoneCall = () => {
    let isShow = false;
    if (!props.notShowCall && staffDetail && staffDetail.Status) {
      if (staffDetail.Status.key === defaultStatus.rejected) {
        isShow = true;
      }
    }
    return isShow;
  };

  const isShowMRN = () => {
    let isShow = false;
    if (
      staffDetail &&
      staffDetail?.Status.key === defaultStatus.staffedreferral &&
      staffDetail?.staffingDetails &&
      staffDetail?.staffingDetails?.mrn
    ) {
      isShow = true;
    }
    return isShow;
  };
  return (
    <>
      {isLoading && <Loader />}

      <div className="d-flex">
        <div className="container-fluid">
          <div className="row bg-white-user d-flex justify-content-center">
            <div
              className={`${
                isShowPhoneCall() ? "col-lg-4" : "col-lg-6"
              } d-flex mobile_wrap gap-4 mobile_justify-start mobile_margin-bottom`}
            >
              <img
                className="userimge1"
                crossOrigin="anonymous"
                src={
                  staffDetail && staffDetail.image
                    ? `${apiBasePath}documents/therapist/${staffDetail.image}`
                    : userImage
                }
                alt="img"
              />
              <div className="flex-column PendingModelMarginTop">
                <div className="flex-column ">
                  <h4 className="username-1 text-start">
                    {staffDetail?.lastName}, {staffDetail?.firstName}
                  </h4>
                  <p className="title-24 m-0">
                    MR #{staffDetail?.staffingDetails?.mrn}
                  </p>
                  {isShowMRN() && (
                    <p className="m-0 Patient-lable">
                      MR #{staffDetail && staffDetail?.staffingDetails?.mrn}
                    </p>
                  )}
                </div>

                {isShowPhoneCall() ? (
                  <div className="d-flex">
                    <div class="d-flex justify-content-center align-items-center flex-column mr-3">
                      <p class="mb-1 patient_chat-call">
                        <MdCall fill="#fff" size={"1.6875rem"} /> Call Patient
                      </p>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{ gap: "19px" }}
                    className="d-flex  mt-3 align-items-start"
                  >
                    <img src={location} alt="" />
                    <div className="flex-column">
                      <h1 className="userlabele-1">
                        {staffDetail &&
                        staffDetail.staffingDetails &&
                        staffDetail.staffingDetails.addressType === "yes"
                          ? " Facility Address "
                          : "Address"}
                      </h1>
                      <p className="userdetails-1 m-0">
                        {staffDetail &&
                          staffDetail.staffingDetails &&
                          staffDetail.staffingDetails.address}
                      </p>
                      <p className="userdetails-1">
                        {staffDetail &&
                          staffDetail.staffingDetails &&
                          staffDetail.staffingDetails.city}
                        {", "}
                        {staffDetail &&
                          staffDetail.staffingDetails &&
                          staffDetail.staffingDetails.state}
                        {", "}
                        {staffDetail &&
                          staffDetail.staffingDetails &&
                          staffDetail.staffingDetails.zip}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={`${isShowPhoneCall() ? "col-lg-8" : "col-lg-6"}`}>
              <div className="row justify-content-between PendingModelMarginTop">
                <div className="col-md-6 d-flex flex-column ">
                  {isShowPhoneCall() && (
                    <div
                      style={{ gap: "19px" }}
                      className="d-flex  mt-3 align-items-start"
                    >
                      <img src={location} alt="" />
                      <div className="flex-column">
                        <h1 className="userlabele-1">
                          {staffDetail &&
                          staffDetail.staffingDetails &&
                          staffDetail.staffingDetails.addressType === "yes"
                            ? " Facility Address "
                            : "Address"}
                        </h1>
                        <p className="userdetails-1 m-0">
                          {staffDetail &&
                            staffDetail.staffingDetails &&
                            staffDetail.staffingDetails.address}
                        </p>
                        <p className="userdetails-1">
                          {staffDetail &&
                            staffDetail.staffingDetails &&
                            staffDetail.staffingDetails.city}
                          {", "}
                          {staffDetail &&
                            staffDetail.staffingDetails &&
                            staffDetail.staffingDetails.state}
                          {", "}
                          {staffDetail &&
                            staffDetail.staffingDetails &&
                            staffDetail.staffingDetails.zip}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-6 d-flex flex-column mobile_justify">
                  {staffDetail?.Status?.key === defaultStatus.rejected ? (
                    <div className="row justify-content-end mr-3">
                      <button className="Approvbutton userstatus-1 " disabled>
                        Declined
                      </button>
                    </div>
                  ) : (
                    <>
                      <p className="p6 p-user  status-p status-open">Status</p>
                      <div className="row justify-content-center mr-3">
                        <button
                          className={`mobile_width Approvbutton userstatus-1 ${
                            props &&
                            props?.caseStatusKey &&
                            statusBaseColorChange(props?.caseStatusKey)
                          }`}
                          disabled
                        >
                          {props && props?.caseStatusKey
                            ? viewCaseStatueName(props?.caseStatusKey)
                            : viewStatueName(staffDetail?.Status?.key)}
                        </button>
                      </div>
                      <div className="row justify-content-center mr-3">
                        {currentUser &&
                          currentUser.Role.key === currentRole.agency && (
                            <button
                              style={{ background: "red", color: "white" }}
                              className="Approvbutton userstatus-1 mt-4 "
                              onClick={() => {
                                cancelModelParent(staffDetail);
                                setIsAllDeclined(true);
                              }}
                            >
                              Cancel
                            </button>
                          )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-5 pl-5 pr-5">
        <div className="row">
          <Accordion defaultActiveKey={props?.showTherapist ? "4" : "1"} flush>
            <Accordion.Item className="border-0 mb-3" eventKey="1">
              <Accordion.Header className="Uheader-1 ">
                Referral Info
              </Accordion.Header>
              <Accordion.Body className="Uheader-1 p-3">
                <CommonReferralInfo
                  dataColumns={
                    props.columnsToView
                      ? props.columnsToView
                      : staffViewFromAdmin
                  }
                  mainClass="test-panding p-0"
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="border-0 mb-3" eventKey="2">
              <Accordion.Header className="Uheader-1 ">Rates</Accordion.Header>
              <Accordion.Body className="Uheader-1 p-3">
                <ViewVisitRate
                  makeOffer={
                    props.makeOffer
                      ? staffDetail?.staffUser?.makeOffer
                        ? true
                        : false
                      : false
                  }
                  width={"w-75"}
                  defaultRates={defaultRates}
                  userRates={
                    props.makeOffer
                      ? staffDetail?.staffUser?.makeOffer
                        ? staffDetail &&
                          staffDetail.staffUser &&
                          staffDetail.staffUser?.makeOffer
                        : staffDetail &&
                          staffDetail.userRates &&
                          staffDetail.userRates
                      : staffDetail &&
                        staffDetail.userRates &&
                        staffDetail.userRates
                  }
                  disciplinesNeeded={
                    staffDetail &&
                    staffDetail.staffingDetails &&
                    staffDetail.staffingDetails.disciplinesNeeded
                  }
                />
              </Accordion.Body>
            </Accordion.Item>

            {props.showTherapist && (
              <Accordion.Item className="border-0 mb-3" eventKey="4">
                <Accordion.Header className="Uheader-1 ">
                  Therapist
                </Accordion.Header>
                <AgencyTherapistTab
                  handleSubmit={props.handleSubmit}
                  setCancelModalState={setCancelModalState}
                  cancelModalState={cancelModalState}
                  isAllDeclined={isAllDeclined}
                  setIsAllDeclined={setIsAllDeclined}
                  isLoadData={props.isLoadData}
                  defaultRates={defaultRates}
                  userRates={
                    staffDetail &&
                    staffDetail.userRates &&
                    staffDetail.userRates
                  }
                  disciplinesNeeded={
                    staffDetail &&
                    staffDetail.staffingDetails &&
                    staffDetail.staffingDetails.disciplinesNeeded
                  }
                  acceptModal={props?.acceptModal}
                  setAcceptModal={props?.setAcceptModal}
                  setTabMenus={props?.setTabMenus}
                  handleClose={props?.handleClose}
                  handlePatientRoute={props?.handlePatientRoute}
                  handleUnfinishModal={props?.handleUnfinishModal}
                  setIsRefresh={props?.setIsRefresh}
                  setAgencyAcceptModal={props?.setAgencyAcceptModal}
                  showAgencyAcceptModal={props?.showAgencyAcceptModal}
                />
              </Accordion.Item>
            )}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default PendingModal;
