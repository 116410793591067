import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";

const AlertMessage = ({ variant, message }) => {
  const [show3, setShow3] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShow3(false);
    }, 2000);
  }, [show3]);

  return (
    <Alert show={show3} key={variant} variant={variant}>
      {message}
    </Alert>
  );
};

export default AlertMessage;
