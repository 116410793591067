import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import Calender from "./Calender";

export const data = [
  { id: 1, year: "Jun 20", day: "MON", visits: 0, revenue: 50 },
  { id: 2, year: "Jun 21", day: "TUE", visits: 10, revenue: 40 },
  { id: 3, year: "Jun 22", day: "FEB", visits: 20, revenue: 50 },
  { id: 4, year: "Jun 22", day: "FEB", visits: 20, revenue: 40 },
  { id: 5, year: "Jun 23", day: "WED", visits: 30, revenue: 30 },
  { id: 6, year: "Jun 24", day: "FRI", visits: 40, revenue: 20 },
];

const Chart = () => {
  const [userData] = useState({
    labels: data.map((e) => [e.year, e.day]),

    datasets: [
      {
        label: "Projected Visits",
        data: data.map((data) => data.visits),
        backgroundColor: ["#5542F6"],
        borderColor: "orange",
        tension: 0.3,
        pointBackgroundColor: "white",
        pointBorderColor: "orange",
        borderDash: [10],
        pointHoverBackgroundColor: "orange",
        pointBorderWidth: 2,
        pointHitRadius: 20,
        pointRadius: 7,
        pointHoverRadius: 10,
        yAxisID: "y2",
      },
      {
        label: "Revenue",
        data: data.map((data) => data.revenue),
        backgroundColor: ["#470a0a"],

        borderColor: "#0F7AD4",
        tension: 0.3,
        pointBackgroundColor: "white",
        pointBorderColor: "#0F7AD4",
        borderDash: [10],
        pointHoverBackgroundColor: "#12A4EB",
        pointBorderWidth: 2,
        pointHoverRadius: 10,
        pointRadius: 7,
        yAxisId: "y",
      },
    ],
  });

  var option = {
    maintainAspectRato: false,
    onHover: (e, ele) => {
      e.native.target.style.cursor = ele[0] ? "pointer" : "default";
    },
    scales: {
      x: {
        ticks: {
          padding: 30,
          color: "#0A365B",
          font: {
            size: 16,
          },
        },
      },
      y: {
        beginAtZero: false,
        type: "linear",
        position: "left",
        ticks: {
          color: "#12A4EB",
          padding: 30,
          font: {
            size: 20,
          },
        },
      },
      y2: {
        beginAtZero: false,
        type: "linear",
        position: "right",
        ticks: {
          color: "orange",
          padding: 30,
          font: {
            size: 20,
          },
        },
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "skyblue",
        padding: 20,
        boxWidth: 40,
        boxHeight: 30,
        caretSize: 10,

        rtl: true,
        font: {
          size: 30,
        },
      },
    },
  };

  return (
    <>
      <Calender />
      <div className="top_chart_parent">
        <Line data={userData} options={option} height={300} width={800} />
      </div>
    </>
  );
};

export default Chart;
