import React from 'react'

const SixthPage = () => {
    return (
        <div className='columns-2 gap-8 text-justify'>


            <div className='break-inside-avoid'>

                <h3 className='fw-bold mt-3 text-[1.3rem] capitalize my-2'>Privacy act notice</h3>
                <div>
                    Section 6109 of the Internal Revenue Code requires you to provide your
                    correct TIN to persons (including federal agencies) who are required to
                    file information returns with the IRS to report interest, dividends, or
                    certain other income paid to you; mortgage interest you paid; the
                    acquisition or abandonment of secured property; the cancellation of
                    debt; or contributions you made to an IRA, Archer MSA, or HSA. The
                    person collecting this form uses the information on the form to file
                    information returns with the IRS, reporting the above information.
                    Routine uses of this information include giving it to the Department of
                    Justice for civil and criminal litigation and to cities, states, the District of
                    Columbia, and U.S. commonwealths and possessions for use in
                    administering their laws. The information also may be disclosed to other
                    countries under a treaty, to federal and state agencies to enforce civil
                    and criminal laws, or to federal law enforcement and intelligence
                    agencies to combat terrorism. You must provide your TIN whether or
                    not you are required to file a tax return. Under section 3406, payers
                    must generally withhold a percentage of taxable interest, dividend, and
                    certain other payments to a payee who does not give a TIN to the payer.
                    Certain penalties may also apply for providing false or fraudulent
                    information.
                </div>
            </div>
        </div>
    )
}

export default SixthPage