import { createSlice } from "@reduxjs/toolkit";
import {
  LastVisitType,
  UpdateDocumentationDate,
  addAdditionalForms,
  getAdminPatientChart,
  getAgencyPatientChart,
  getCaseManagementStatus,
  getCaseManagementTabCount,
  getDocList,
  getEpisodeDetails,
  getFrequencySuggestion,
  getHomeCountData,
  getStaffAssignments,
  getStaffAuth,
  getStaffDocumentation,
  getStafffFrequency,
  getTabActiveStatus,
  getTherapistPatientChart,
  getVisitsByWeeksLeft,
  reAssmentForms,
  updateAuthorisation,
  updateDocumenationStatus,
  getSavedLastVisitData,
  getLastGoalsValue,
} from "../actions/common";
import {
  getEvalForm,
  getStaffFormdataById,
} from "../actions/patientGetDocTypes";
import { savePatientForm } from "../actions/staffing";

const initialState = {
  patirntChartList: [],
  docList: [],
  caseMgmtList: [],
  totalCount: 0,
  totaldocCount: 0,
  docCount: 0,
  casemgmttabcount: null,
  isLoading: false,
  staffTabDetails: null,
  episodeDetails: null,
  staffDocument: null,
  staffassignments: null,
  staffAuth: null,
  staffFrequency: null,
  staffForm: null,
  documentStaffList: [],
  evaluationFormData: {},
  frequencySuggestion: {},
  homeCount: 0,
  caseList: [],
  additionalForms: [],
  reAssement: [],
  leftVisit: 0,
  visitFrequency: [],
  savedLastVisitType: null,
  lastGoals: null,
};

const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    setFrequencyData(state, action) {
      state.staffFrequency = action.payload;
    },
    cleanReduxState(state, action) {
      if (action.payload.evaluationFormData) {
        state.evaluationFormData = {};
      }
    },
  },

  extraReducers: {
    [updateDocumenationStatus.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [updateDocumenationStatus.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [updateDocumenationStatus.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getStaffFormdataById.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.staffForm = payload.data;
    },
    [getStaffFormdataById.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getStaffFormdataById.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getEvalForm.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.evaluationFormData = payload.data;
    },
    [getEvalForm.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getEvalForm.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [savePatientForm.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [savePatientForm.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [savePatientForm.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getStafffFrequency.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.staffFrequency = payload;
    },
    [getStafffFrequency.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getStafffFrequency.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getStaffAuth.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.staffAuth = payload.data;
    },
    [getStaffAuth.pending]: (state, { payload }) => {
      state.isLoading = true;
      state.staffAuth = null;
    },
    [getStaffAuth.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [updateAuthorisation.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [updateAuthorisation.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [updateAuthorisation.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getStaffAssignments.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.staffassignments = payload.data.rows;
    },
    [getStaffAssignments.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getStaffAssignments.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [UpdateDocumentationDate.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [UpdateDocumentationDate.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [UpdateDocumentationDate.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getStaffDocumentation.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.staffDocument = payload.getEpisodeDocument.rows;
    },
    [getStaffDocumentation.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getStaffDocumentation.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getEpisodeDetails.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.episodeDetails = payload.data;
    },
    [getEpisodeDetails.pending]: (state, { payload }) => {
      state.isLoading = true;
      state.staffFrequency = null;
    },
    [getEpisodeDetails.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getAdminPatientChart.fulfilled]: (state, { payload }) => {
      state.patirntChartList = payload.data.rows;
      state.isLoading = false;
      state.totalCount = payload.data.count;
    },
    [getAdminPatientChart.pending]: (state, { payload }) => {
      state.patirntChartList = [];
      state.isLoading = true;
      state.totalCount = 0;
    },
    [getAdminPatientChart.rejected]: (state, { payload }) => {
      state.patirntChartList = [];
      state.isLoading = false;
      state.totalCount = 0;
    },

    [getTabActiveStatus.fulfilled]: (state, { payload }) => {
      state.staffTabDetails = payload;
    },
    [getTabActiveStatus.pending]: (state, { payload }) => {},
    [getTabActiveStatus.rejected]: (state, { payload }) => {},

    [getTherapistPatientChart.fulfilled]: (state, { payload }) => {
      state.patirntChartList = payload.data.rows;
      state.isLoading = false;
      state.totalCount = payload.data.count;
    },
    [getTherapistPatientChart.pending]: (state, { payload }) => {
      state.patirntChartList = [];
      state.isLoading = true;
      state.totalCount = 0;
    },
    [getTherapistPatientChart.rejected]: (state, { payload }) => {
      state.patirntChartList = [];
      state.isLoading = false;
      state.totalCount = 0;
    },

    [getAgencyPatientChart.fulfilled]: (state, { payload }) => {
      state.patirntChartList = payload.data.rows;
      state.isLoading = false;
      state.totalCount = payload.data.count;
    },
    [getAgencyPatientChart.pending]: (state, { payload }) => {
      state.patirntChartList = [];
      state.isLoading = true;
      state.totalCount = 0;
    },
    [getAgencyPatientChart.rejected]: (state, { payload }) => {
      state.patirntChartList = [];
      state.isLoading = false;
      state.totalCount = 0;
    },
    [getDocList.fulfilled]: (state, { payload }) => {
      state.docList = payload?.result?.rows;
      state.docCount = payload?.result?.count;
      state.isLoading = false;
      state.totaldocCount = payload.data;
    },
    [getDocList.pending]: (state, { payload }) => {
      state.docList = [];
      state.isLoading = true;
      state.totaldocCount = 0;
    },
    [getDocList.rejected]: (state, { payload }) => {
      state.docList = [];
      state.isLoading = false;
      state.totaldocCount = 0;
    },
    [getCaseManagementTabCount.fulfilled]: (state, { payload }) => {
      state.casemgmttabcount = payload.data;
      state.isLoading = false;
    },
    [getCaseManagementTabCount.rejected]: (state) => {
      state.isLoading = false;
    },
    [getCaseManagementTabCount.pending]: (state) => {
      state.isLoading = true;
    },
    [getFrequencySuggestion.fulfilled]: (state, { payload }) => {
      state.frequencySuggestion = payload.data;
    },
    [getHomeCountData.fulfilled]: (state, { payload }) => {
      state.homeCount = payload.count;
      state.isLoading = false;
    },
    [getHomeCountData.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getHomeCountData.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getCaseManagementStatus.fulfilled]: (state, { payload }) => {
      state.caseList = payload.data ? payload.data : payload;
      state.isLoading = false;
    },
    [getCaseManagementStatus.pending]: (state, { payload }) => {
      state.caseList = [];
      state.isLoading = true;
    },
    [getCaseManagementStatus.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [addAdditionalForms.fulfilled]: (state, { payload }) => {
      state.additionalForms = payload.data;
      state.isLoading = false;
    },
    [addAdditionalForms.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [addAdditionalForms.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [LastVisitType.fulfilled]: (state, { payload }) => {
      state.reAssement = payload.data;
      state.isLoading = false;
    },
    [LastVisitType.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [LastVisitType.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getVisitsByWeeksLeft.fulfilled]: (state, { payload }) => {
      let sorted = payload.findVisit.sort((a, b) => b.visit - a.visit);
      state.leftVisit = sorted && sorted.length > 0 ? sorted[0]["visit"] : 0;
      state.visitFrequency = payload.findVisit;
    },

    [getSavedLastVisitData.fulfilled]: (state, { payload }) => {
      state.savedLastVisitType = payload.data;
      state.isLoading = false;
    },
    [getSavedLastVisitData.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getSavedLastVisitData.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getLastGoalsValue.fulfilled]: (state, { payload }) => {
      state.lastGoals = payload.data;
    },
    [getLastGoalsValue.pending]: (state, { payload }) => {
      state.lastGoals = null;
    },
    [getLastGoalsValue.rejected]: (state, { payload }) => {
      state.lastGoals = null;
    },
  },
});

export const { setFrequencyData, cleanReduxState } = commonSlice.actions;
export default commonSlice.reducer;
