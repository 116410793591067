import React from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getStaffingByStatus,
  updateStaffingUser,
} from "../../Redux/actions/staffing";
import { currentRole, defaultStatus } from "../../utils/auth";

export const CancelReferralModal = (props) => {
  const dispatch = useDispatch();
  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);

  const hanldeConfirmation = () => {
    if (
      staffDetail &&
      staffDetail?.Status &&
      staffDetail?.Status?.key === defaultStatus.pending
    ) {
      const obj = {
        status: defaultStatus.cancelled,
        role: currentRole.agency,
        staffUserId: staffDetail?.id,
        therapistId: 0,
        isAllDeclined: true,
      };
      dispatch(updateStaffingUser(obj)).then(() => {
        dispatch(
          getStaffingByStatus({
            pageSize: 10,
            pageNo: 1,
            status: [defaultStatus.pending],
          })
        );
        toast.success("Referral cancelled successfully.");
        props.handleClose();
        props?.handlePendingClose();
      });
    } else {
      props.show2();
      props.handleClose();
    }
  };

  return (
    <>
      <Modal
        size="lg"
        className={props.modalClass}
        show={props.show}
        onHide={props.handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header
          className="closeButton border-0"
          closeButton
        ></Modal.Header>
        {props.data}
        <>
          <div className="cancel_modal pb-5">
            <p>
              Are you sure you want to cancel the referral for <br />
              {` ${staffDetail?.lastName && staffDetail?.lastName}, ${staffDetail?.firstName && staffDetail.firstName}`}?
            </p>
            <div className="check_btns">
              <div className="radio_btns">
                <label className="container-2 align-items-center d-flex padding-left-ref ref-height ">
                  <input
                    type="radio"
                    name="fav_language"
                    onClick={() => hanldeConfirmation()}
                  ></input>
                  <span className=" checkmark-2"></span>
                  <div className="lable-cancel">Yes</div>
                </label>
              </div>
              <div className="radio_btns">
                <label className="container-2 align-items-center d-flex padding-left-ref ref-height ">
                  <input
                    type="radio"
                    name="fav_language"
                    onClick={() => props.handleClose()}
                  ></input>
                  <span className=" checkmark-2"></span>
                  <div className="lable-cancel">No</div>
                </label>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
