import { createSlice } from "@reduxjs/toolkit";
import {
  checkDuplicateEmail,
  getUserDetailsById,
  setTherapistDataGetStep,
  updateProfileStatus,
} from "../actions/auth";
import {
  addProfessionalExperienceData,
  getDefaultRates,
  getUserByRejectToken,
  masterData,
  saveLocationData,
  therapistCredentials,
  therapistNotifications,
  updatePendingSteps,
  userPaymentDetail,
  userRateCreate,
  userRejected,
  userSignup,
  getEnvelopDetail,
  userTermsCondition,
  getTherapistDetailsById,
  getTherapistLocationData,
  getBlockListByRole,
  getAllListToBlock,
  setBlockedUser,
  getBlockedUsersByRole,
  unblockUser,
  updateCoverageArea,
  saveOnboardingForm,
  profileMasterData,
} from "../actions/therapist";
import {
  backgroundReport,
  getBackgroundReportStatus,
  newBackgroundReportOrder,
} from "../actions/background";
import { createCustomer, stripeAccountLink } from "../actions/payment";

const initialState = {
  updateMessage: null,
  pendingSteps: null,
  PrefillDetails: null,
  masterData: null,
  coverageArea: [],
  isLoading: false,
  defaultRates: null,
  isRequest: false,
  userRejectedMessage: null,
  step: null,
  isTherapistLoading: false,
  locationLoader: false,
  isRateLoading: false,
  therapistLocationData: null,
  countDetails: null,
  blocked: null,
  blockList: [],
  listToBlock: [],
  therapistCount: null,
  agencyCount: null,
  listToBlockCount: null,
  isBackgroundLoading: false,
  backgroundReport: null,
  pristine: false,
  triggerUpdateFunction: false,
  checkAgencyValue: false,
  checkProfilePath: null,
  isAnyErrorMess: null,
  isMapLocationAdded: false,
  isImageAgency: false,
  isDefaultRateImage: false,
  therapistData: {},
  rejectedCoverArea: [],
};

const therapistSignupSlice = createSlice({
  name: "therapistSigunpSlice",
  initialState,
  reducers: {
    prefillLocationDetails: (state, { payload }) => {
      state.coverageArea = payload;
    },
    updateRejectedCoverArea: (state, { payload }) => {
      let parseRejArea = JSON.parse(JSON.stringify(state.rejectedCoverArea));

      let index = parseRejArea.indexOf(payload.id);
      if (payload.value && index === -1) {
        parseRejArea.push(payload.id);
      } else {
        parseRejArea.splice(index, 1);
      }
      state.rejectedCoverArea = parseRejArea;
    },
    clearRejectedCoverArea: (state, { payload }) => {
      state.rejectedCoverArea = [];
    },
    locationData(state, action) {
      action?.payload && state?.coverageArea?.push(action?.payload);
    },
    removeLocation(state, action) {
      state.coverageArea = action?.payload && action?.payload;
    },
    setRequest(state, { payload }) {
      state.isLoading = payload.request;
    },
    setIsTherapistLoading(state, { payload }) {
      state.isTherapistLoading = payload.request;
    },
    goBackPendingSteps(state, action) {
      state.pendingSteps = action.payload;
    },
    resetPrefillDetails(state) {
      state.PrefillDetails = null;
    },
    resetPendingSteps(state) {
      state.pendingSteps = null;
    },
    setPristine(state, { payload }) {
      state.pristine = payload.state;
    },
    triggerUpdateButton(state, { payload }) {
      state.triggerUpdateFunction = payload;
    },
    AgencyProfilePristine(state, payload) {
      state.checkAgencyValue = payload.payload;
    },
    ProfileImagePristine(state, payload) {
      state.checkProfilePath = payload;
    },
    therapistValidState(state, payload) {
      state.isAnyErrorMess = payload.payload;
    },
    therapistLocationState(state, payload) {
      state.isMapLocationAdded = payload.payload;
    },
    agencyImageUpload(state, payload) {
      state.isImageAgency = payload.payload;
    },
    defaultRate(state, payload) {
      state.isDefaultRateImage = payload.payload;
    },
    therapistDetailForPic(state, payload) {
      state.therapistData = payload.payload;
    },
  },

  extraReducers: {
    [userSignup.fulfilled]: (state, { payload }) => {
      if (payload.success === true) {
        state.pendingSteps = payload?.userData?.pendingSteps?.split(",");
        state.PrefillDetails = payload.userData;
      }
      state.isLoading = false;
    },
    [userSignup.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [userSignup.rejected]: (state, { error, payload }) => {
      state.updateMessage = {
        default:
          error && error.message ? error.message : "Something went wrong.",
      };
      state.isLoading = false;
    },

    [getUserDetailsById.fulfilled]: (state, { payload }) => {
      state.PrefillDetails = payload.data;
      state.isLoading = false;

      state.coverageArea = payload?.data?.coverageAreas;
      state.pendingSteps =
        payload?.data?.pendingSteps && payload?.data?.pendingSteps?.split(",");
    },

    [getUserDetailsById.pending]: (state, { payload }) => {
      state.PrefillDetails = null;
      state.isLoading = true;
    },
    [getUserDetailsById.rejected]: (state, { error, payload }) => {
      state.PrefillDetails = null;
      state.isLoading = false;
      state.agencyUpdateMessage = {
        default:
          error && error.message ? error.message : "Something went wrong.",
      };
    },

    [newBackgroundReportOrder.pending]: (state, { payload }) => {
      state.isBackgroundLoading = true;
    },
    [newBackgroundReportOrder.rejected]: (state, { error, payload }) => {
      state.isBackgroundLoading = false;
    },
    [newBackgroundReportOrder.fulfilled]: (state, { payload }) => {
      state.isBackgroundLoading = false;
    },

    [backgroundReport.pending]: (state, { payload }) => {
      state.isBackgroundLoading = true;
    },
    [backgroundReport.rejected]: (state, { error, payload }) => {
      state.isBackgroundLoading = false;
    },
    [backgroundReport.fulfilled]: (state, { payload }) => {
      state.isBackgroundLoading = false;
    },

    [getTherapistDetailsById.fulfilled]: (state, { payload }) => {
      state.PrefillDetails = payload.data;
      state.isTherapistLoading = false;
      state.pendingSteps =
        payload?.data?.pendingSteps && payload?.data?.pendingSteps?.split(",");
    },
    [getTherapistDetailsById.pending]: (state, { payload }) => {
      state.PrefillDetails = null;
      state.isTherapistLoading = true;
    },
    [getTherapistDetailsById.rejected]: (state, { error, payload }) => {
      state.isTherapistLoading = false;
    },

    [getTherapistLocationData.fulfilled]: (state, { payload }) => {
      state.therapistLocationData = payload.data;
      state.locationLoader = false;
      state.coverageArea = payload?.data?.coverageAreas;
    },
    [getTherapistLocationData.pending]: (state, { payload }) => {
      state.locationLoader = true;
    },
    [getTherapistLocationData.rejected]: (state, { error, payload }) => {
      state.locationLoader = false;
    },

    [updateCoverageArea.fulfilled]: (state, { payload }) => {
      state.locationLoader = false;
    },
    [updateCoverageArea.pending]: (state, { payload }) => {
      state.locationLoader = true;
    },
    [updateCoverageArea.rejected]: (state, { error, payload }) => {
      state.locationLoader = false;
    },

    [setTherapistDataGetStep.fulfilled]: (state, { payload }) => {
      state.step = payload;
      state.isLoading = false;
    },

    [getDefaultRates.fulfilled]: (state, { payload }) => {
      state.defaultRates = payload.data;
      state.isRateLoading = false;
    },
    [getDefaultRates.rejected]: (state, { error, payload }) => {
      state.isRateLoading = false;
      state.agencyUpdateMessage = {
        default:
          error && error.message ? error.message : "Something went wrong.",
      };
    },
    [getDefaultRates.pending]: (state, { payload }) => {
      state.defaultRates = [];
      state.isRateLoading = true;
    },

    [checkDuplicateEmail.pending]: (state, { payload }) => {
      state.isLoading = true;
    },

    [checkDuplicateEmail.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [userPaymentDetail.fulfilled]: (state, { payload }) => {
      state.pendingSteps = payload?.data?.pendingSteps?.split(",");
      state.isLoading = false;
    },
    [userPaymentDetail.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [userPaymentDetail.rejected]: (state, { error, payload }) => {
      state.agencyUpdateMessage = {
        default:
          error && error.message ? error.message : "Something went wrong.",
      };
    },
    [userTermsCondition.fulfilled]: (state, { payload }) => {
      state.pendingSteps = payload && payload?.pendingSteps?.split(",");
      state.isLoading = false;
    },
    [userTermsCondition.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [userTermsCondition.rejected]: (state, { error, payload }) => {
      state.agencyUpdateMessage = {
        default:
          error && error.message ? error.message : "Something went wrong.",
      };
    },
    [therapistNotifications.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [therapistNotifications.fulfilled]: (state, { payload }) => {
      state.pendingSteps =
        payload?.therapistStep &&
        payload?.therapistStep?.pendingSteps?.split(",");
      state.isLoading = false;
    },
    [therapistNotifications.rejected]: (state, { error, payload }) => {
      state.agencyUpdateMessage = {
        default:
          error && error.message ? error.message : "Something went wrong.",
      };
      state.isLoading = false;
    },
    [masterData.fulfilled]: (state, { payload }) => {
      state.masterData = payload;
      state.isLoading = false;
    },
    [masterData.pending]: (state, { payload }) => {
      state.masterData = payload;
      state.isLoading = true;
    },
    [masterData.rejected]: (state, { error, payload }) => {
      state.isLoading = false;
    },
    [profileMasterData.fulfilled]: (state, { payload }) => {
      state.masterData = payload;
      // state.isLoading = false;
    },
    [profileMasterData.pending]: (state, { payload }) => {
      state.masterData = payload;
      state.isLoading = true;
    },
    [profileMasterData.rejected]: (state, { error, payload }) => {
      state.isLoading = false;
    },
    [therapistCredentials.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [therapistCredentials.fulfilled]: (state, { payload }) => {
      state.pendingSteps =
        payload?.therapistStep &&
        payload?.therapistStep?.pendingSteps?.split(",");
      state.isLoading = false;
    },
    [therapistCredentials.rejected]: (state, { error, payload }) => {
      state.agencyUpdateMessage = {
        default:
          error && error.message ? error.message : "Something went wrong.",
      };
      state.isLoading = false;
    },
    [saveLocationData.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [saveLocationData.fulfilled]: (state, { payload }) => {
      state.pendingSteps =
        payload?.therapistStep &&
        payload?.therapistStep?.pendingSteps?.split(",");
      state.isLoading = false;
    },
    [saveLocationData.rejected]: (state, { error, payload }) => {
      state.agencyUpdateMessage = {
        default:
          error && error.message ? error.message : "Something went wrong.",
      };
      state.isLoading = false;
    },
    [addProfessionalExperienceData.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [addProfessionalExperienceData.fulfilled]: (state, { payload }) => {
      state.pendingSteps =
        payload?.therapistStep &&
        payload?.therapistStep?.pendingSteps?.split(",");
      state.isLoading = false;
    },
    [addProfessionalExperienceData.rejected]: (state, { error, payload }) => {
      state.agencyUpdateMessage = {
        default:
          error && error.message ? error.message : "Something went wrong.",
      };
      state.isLoading = false;
    },
    [userRateCreate.fulfilled]: (state, { payload }) => {
      state.pendingSteps =
        payload?.data && payload?.data?.pendingSteps?.split(",");
      state.isLoading = false;
    },
    [userRateCreate.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [userRateCreate.rejected]: (state, { error, payload }) => {
      state.agencyUpdateMessage = {
        default:
          error && error.message ? error.message : "Something went wrong.",
      };
    },
    [updatePendingSteps.fulfilled]: (state, { payload }) => {
      state.pendingSteps =
        payload?.data && payload?.data?.pendingSteps?.split(",");
    },
    [updatePendingSteps.rejected]: (state, { error, payload }) => {
      state.agencyUpdateMessage = {
        default:
          error && error.message ? error.message : "Something went wrong.",
      };
    },
    [userRejected.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [userRejected.pending]: (state, { error, payload }) => {
      state.isLoading = true;
    },
    [getUserByRejectToken.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getUserByRejectToken.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getUserByRejectToken.rejected]: (state, { error, payload }) => {
      state.isLoading = false;
      state.userRejectedMessage = {
        default:
          error && error.message ? error.message : "Something went wrong.",
      };
    },

    [getEnvelopDetail.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getEnvelopDetail.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getEnvelopDetail.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [updateProfileStatus.fulfilled]: (state, { payload }) => {
      state.isTherapistLoading = false;
      state.isBackgroundLoading = false;
    },
    [updateProfileStatus.pending]: (state, { payload }) => {
      state.isTherapistLoading = true;
      state.isBackgroundLoading = true;
    },
    [updateProfileStatus.rejected]: (state, { payload }) => {
      state.isTherapistLoading = false;
      state.isBackgroundLoading = false;
    },
    [saveOnboardingForm.fulfilled]: (state, { payload }) => {
      state.isTherapistLoading = false;
    },
    [saveOnboardingForm.pending]: (state, { payload }) => {
      state.isTherapistLoading = true;
    },
    [saveOnboardingForm.rejected]: (state, { payload }) => {
      state.isTherapistLoading = false;
    },
    [getBlockedUsersByRole.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.blockList = payload?.blockedUserDetail.rows;
      state.countDetails = payload?.countDetail;
    },
    [getBlockedUsersByRole.pending]: (state, { payload }) => {
      state.blockList = [];
      state.isLoading = true;
    },
    [getBlockedUsersByRole.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getAllListToBlock.fulfilled]: (state, { payload }) => {
      state.listToBlock = payload.result.rows;
      state.listToBlockCount = payload.result.count;
      state.agencyCount = payload.agencyCount[0].count;
      state.therapistCount = payload.therapistCount[0].count;
      state.isLoading = false;
    },
    [getAllListToBlock.pending]: (state, { payload }) => {
      state.listToBlock = [];
      state.isLoading = true;
    },
    [getAllListToBlock.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [setBlockedUser.fulfilled]: (state, { payload }) => {
      state.blocked = payload;
      state.isLoading = false;
    },
    [setBlockedUser.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [setBlockedUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [unblockUser.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [unblockUser.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [unblockUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [stripeAccountLink.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [stripeAccountLink.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [stripeAccountLink.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [createCustomer.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [createCustomer.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [createCustomer.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getBackgroundReportStatus.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.backgroundReport = payload;
    },
  },
});

export const {
  locationData,
  removeLocation,
  prefillLocationDetails,
  resetPrefillDetails,
  setRequest,
  goBackPendingSteps,
  setIsTherapistLoading,
  resetPendingSteps,
  setPristine,
  triggerUpdateButton,
  AgencyProfilePristine,
  ProfileImagePristine,
  therapistValidState,
  therapistLocationState,
  agencyImageUpload,
  defaultRate,
  therapistDetailForPic,
  clearRejectedCoverArea,
  updateRejectedCoverArea,
} = therapistSignupSlice.actions;
export default therapistSignupSlice.reducer;
