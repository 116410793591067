import React, { useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { currentRole } from "../../../utils/auth";
import { useDispatch, useSelector } from "react-redux";
import { savePatientForm } from "../../../Redux/actions/staffing";
import {
  canSubmitForm,
  staffFormTypesConstants,
  visitStatus,
} from "../../../utils/helper";
import { toast } from "react-toastify";
import SignatureCanvas from "../../../components/common/SignatureCanvas";
import { Button, Modal } from "react-bootstrap";
import { getPatientDocs } from "../../../Redux/actions/patientGetDocTypes";
import { getStaffDocumentation } from "../../../Redux/actions/common";

export default function Covid19questionnare(props) {
  const {
    closeFormModal,
    visitDetail,
    formId,
    confirmDate,
    toSave,
    dataAvailable,
  } = props;
  const dispatch = useDispatch();
  const staffFormData = useSelector((e) => e.commonSlice.staffForm);
  const staffDocument = useSelector((e) => e.commonSlice.staffDocument);

  const staffDetails = useSelector((state) => state.staffingSlice.staffDetail);
  const visitDetailById = useSelector(
    (data) => data?.patientGetDocTypesSlice?.visitDetail
  );
  const currentUser = useSelector(
    (state) => state.adminSlice.currentActiveUserDetails
  );

  const [show, setShow] = useState(false);
  const [isCanvasShow, setIsCanvasShow] = useState(false);
  const [signatureAdded, setSignatureAdded] = useState(null);
  const [displaySignature, setDisplaySignature] = useState(null);
  const [TherapistsignatureError, setTherapistsignatureError] = useState(null);

  const onSubmit = (values, form, status) => {
    if (!displaySignature || !values.Therapistsignature) {
      setTherapistsignatureError("Required");
    } else {
      setTherapistsignatureError(null);
    }
    let finalFormValues = {
      ...values,
      Therapistsignature: !displaySignature
        ? values.Therapistsignature
        : displaySignature,
    };

    const apiObject = {
      status: typeof status === "string" ? status : visitStatus.incompletedoc,
      staffId: staffDetails?.id,
      formData: finalFormValues,
      formType: staffFormTypesConstants.C19QUESTIONAIRE,
      id: finalFormValues.id,
      episodeDocumentFormId: formId,
      episodeDocumentId: visitDetail?.id,
    };
    dispatch(savePatientForm(apiObject)).then((res) => {
      if (res.payload.success) {
        closeFormModal();
        dispatch(getPatientDocs({ episodeDocumentId: visitDetail?.id }));
        dispatch(
          getStaffDocumentation({
            staffUserId: Number(staffDetails && staffDetails?.id),
          })
        );
      }
    });
  };
  const saveForm = (image) => {
    setDisplaySignature(image);
    toast.success("Signature added.");
  };
  const symtomps = [
    { key: "fever", value: "FEVER" },
    { key: "cough", value: "COUGH" },
    { key: "soreThroat", value: "SORE THROAT" },
    { key: "difficultyBreathing", value: "DIFFICULTY BREATHING" },
    { key: "stomachPain", value: "STOMACH PAIN" },
    { key: "vomit", value: "VOMIT" },
    { key: "pinkEye", value: "PINK EYE" },
    { key: "rash", value: "RASH" },
    { key: "fatique", value: "FATIQUE" },
  ];
  const handleInitialValues = (e) => {
    let initialValues = {};
    initialValues = {
      travelledCountry: null,
      travelledCountryText: null,
      travelledState: null,
      travelledStateText: null,
      suspectContact: null,
      confirmContact: null,
      fluSymtompsContact: null,
      fever: null,
      cough: null,
      soreThroat: null,
      difficultyBreathing: null,
      stomachPain: null,
      vomit: null,
      pinkEye: null,
      rash: null,
      fatique: null,
      details: null,
    };
    if (staffFormData && staffFormData.formData) {
      return { ...staffFormData.formData, id: staffFormData.id };
    }
    if (staffDetails) {
      initialValues.PatientName =
        staffDetails.lastName + "," + staffDetails.firstName;
      initialValues.mrNo =
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.mrn;
      initialValues.dob =
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.dateOfBirth.split("T")[0];
      initialValues.agency =
        staffDetails &&
        staffDetails.createdByUser &&
        staffDetails.createdByUser.name;
      initialValues.diagnosis =
        staffDetails &&
        staffDetails.staffingDiagnosisdetails.length > 0 &&
        staffDetails.staffingDiagnosisdetails.map((item) => ({
          value: item.masterDataId,
          label: item.masterData.displayName,
        }));
    }
    if (currentUser) {
      initialValues.therapist =
        currentUser?.lastName + "," + currentUser?.firstName;
    }
    if (staffDocument && staffDocument.length > 0) {
      initialValues.visit_date =
        staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
    }
    let date = "";
    if (visitDetail && visitDetail.date) {
      date = visitDetail.date.split("T")[0];

      if (
        staffDetails &&
        staffDetails.staffInitialVisit &&
        staffDetails.staffInitialVisit.length > 0 &&
        staffDetails.staffInitialVisit[0].anticipatedSocDate
      ) {
        date =
          staffDetails.staffInitialVisit[0].anticipatedSocDate.split("T")[0];
      }
      if (confirmDate) {
        date = confirmDate;
      }
      initialValues.visit_date = date;
    }
    return initialValues;
  };
  const validate = (values) => {
    let errors = {};
    if (!values.screeningMethod) {
      errors.screeningMethod = "Required.";
    }
    if (values?.travelledCountry === null) {
      errors["travelledCountry"] = "Required.";
    }
    if (
      values?.travelledCountry === true &&
      (values?.travelledCountryText === null ||
        values?.travelledCountryText?.trim() === "")
    ) {
      errors["travelledCountryText"] = "Required.";
    }
    if (values?.travelledState === null) {
      errors["travelledState"] = "Required.";
    }
    if (
      values?.travelledState === true &&
      (values?.travelledStateText === null ||
        values?.travelledStateText?.trim() === "")
    ) {
      errors["travelledStateText"] = "Required.";
    }
    if (values?.suspectContact === null) {
      errors["suspectContact"] = "Required.";
    }
    if (values?.confirmContact === null) {
      errors["confirmContact"] = "Required.";
    }
    if (values?.fluSymtompsContact === null) {
      errors["fluSymtompsContact"] = "Required.";
    }
    symtomps.map((item) => {
      if (values?.[item?.key] === null) {
        errors[item?.key] = "Required.";
      }
    });
    symtomps.map((item) => {
      if (values?.[item?.key] === true && values?.details === null) {
        errors["details"] = "Required.";
      }
    });

    return errors;
  };

  const getFormStatus = () => {
    let status = "";
    if (visitDetailById) {
      status = visitDetailById.Status.key;
      if (
        visitDetailById.patientFormList &&
        visitDetailById.patientFormList.length > 0
      ) {
        let form = visitDetailById.patientFormList.find(
          (item) => item.name == staffFormTypesConstants.C19QUESTIONAIRE
        );
        if (form) {
          status = form.Status.key;
        }
      }
    }
    return status;
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={useMemo(
          (e) => handleInitialValues(e),
          [staffFormData, staffDetails]
        )}
        validate={validate}
        render={({
          handleSubmit,
          values,
          errors,
          form,
          touched,
          submitFailed,
          valid,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="row mein-white-from-background">
                <div className="col-md-6 border-right-1">
                  <div className="fileds-from">
                    <label>MR# :</label>
                    <Field
                      name="mrNo"
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div className="w-50">
                          <input
                            maxlength="45"
                            className="form-control bg-white"
                            {...input}
                            type="text"
                            placeholder=""
                            disabled
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="fileds-from">
                    <label>Patient :</label>
                    <Field
                      name="PatientName"
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div className="w-50">
                          <input
                            maxlength="45"
                            className="form-control bg-white"
                            {...input}
                            type="text"
                            disabled
                            placeholder=""
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="fileds-from">
                    <label>DOB :</label>
                    <Field
                      name="dob"
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div className="w-50">
                          <input
                            maxlength="45"
                            className="form-control bg-white"
                            {...input}
                            type="date"
                            placeholder=""
                            disabled
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="fileds-from">
                    <label>Agency :</label>
                    <Field
                      name="agency"
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div className="w-50">
                          <input
                            maxlength="45"
                            className="form-control bg-white"
                            {...input}
                            type="text"
                            disabled
                            placeholder=""
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="col-md-6 ">
                  <div className="fileds-from">
                    <label>Visit Date :</label>
                    <Field
                      name="visit_date"
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div className="w-50">
                          <input
                            className="form-control "
                            {...input}
                            disabled={true}
                            maxlength="45"
                            type="date"
                            placeholder=""
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="fileds-from">
                    <label>Time In :</label>
                    <Field
                      name="time_in"
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div className="w-50">
                          <input
                            maxlength="45"
                            className="form-control"
                            {...input}
                            type="time"
                            placeholder=""
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="fileds-from">
                    <label>Time Out :</label>
                    <Field
                      name="time_out"
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div className="w-50">
                          <input
                            className="form-control"
                            maxlength="45"
                            {...input}
                            type="time"
                            placeholder=""
                          />
                          {meta.error && meta.touched && (
                            <p
                              className="alert alert-danger w-100 "
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="fileds-from">
                    <label>Therapist :</label>
                    <Field
                      name="therapist"
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div className="w-50">
                          <input
                            className="form-control bg-white"
                            maxlength="45"
                            {...input}
                            type="text"
                            disabled
                            placeholder=""
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
              <div className="row mein-white-from-background align-items-center">
                <div className="col-md-8">
                  <div className="d-flex gap-5 align-items-center">
                    <div>
                      <h6>Screening Method :</h6>
                    </div>
                    <div>
                      <Field
                        name={`screeningMethod`}
                        type="radio"
                        component="input"
                        value={"Phone"}
                      />
                      <label className="mx-2">Phone</label>
                    </div>
                    <div>
                      <Field
                        name={`screeningMethod`}
                        type="radio"
                        component="input"
                        value={"Email"}
                      />
                      <label className="mx-2">Email</label>
                    </div>
                    <div>
                      <Field
                        name={`screeningMethod`}
                        type="radio"
                        component="input"
                        value={"in-person"}
                      />
                      <label className="mx-2">In Person</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  {errors.screeningMethod && touched.screeningMethod && (
                    <p className="alert alert-danger">
                      {errors.screeningMethod}
                    </p>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                  <label htmlFor="">
                    Have you, your caregiver (if applicable) or anyone in your
                    household travelled outside the US in the past 2 weeks
                    (14days)
                  </label>
                  <div className="d-flex align-items-center justify-content-between w-40 px-1">
                    <div className="radio-of-forms mr-5">
                      <Field
                        name={`travelledCountry`}
                        type="radio"
                        component="input"
                        value={true}
                      >
                        {({ input, meta }) => (
                          <>
                            <input
                              type="radio"
                              {...input}
                              onChange={() => input.onChange(true)}
                              value={true}
                            />
                          </>
                        )}
                      </Field>
                      <label> Yes </label>
                    </div>
                    <div className="radio-of-forms">
                      <Field
                        name={`travelledCountry`}
                        type="radio"
                        component="input"
                        value={false}
                      >
                        {({ input, meta }) => (
                          <>
                            <input
                              type="radio"
                              {...input}
                              onChange={() => input.onChange(false)}
                              value={false}
                            />
                            {}
                          </>
                        )}
                      </Field>
                      <label>No </label>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className="col-md-4">
                  {errors["travelledCountry"] &&
                    touched["travelledCountry"] && (
                      <p className="alert alert-danger " role="alert">
                        {errors["travelledCountry"]}
                      </p>
                    )}
                </div>
                <div className="col-md-12">
                  <div className=" align-items-start">
                    <label className="no-wrap-white-space mb-2">
                      IF YES, WHERE:
                    </label>
                    <Field
                      name="travelledCountryText"
                      className="Additional_text  "
                      component="textarea"
                      placeholder="Free text"
                    />
                    <div>
                      {errors[`travelledCountryText`] &&
                        touched[`travelledCountryText`] && (
                          <p className="alert alert-danger " role="alert">
                            {errors["travelledCountryText"]}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                  <label htmlFor="">
                    Have you or your caregiver (if applicable) or anyone in your
                    household travelled outside of DC, Maryland or Virginia in
                    the past 2 weeks (14 days)
                  </label>
                  <div className="d-flex align-items-center justify-content-between w-40 px-1">
                    <div className="radio-of-forms mr-5">
                      <Field
                        name={`travelledState`}
                        type="radio"
                        component="input"
                        value={true}
                      >
                        {({ input, meta }) => (
                          <>
                            <input
                              type="radio"
                              {...input}
                              onChange={() => input.onChange(true)}
                              value={true}
                            />
                          </>
                        )}
                      </Field>
                      <label> Yes </label>
                    </div>
                    <div className="radio-of-forms">
                      <Field
                        name={`travelledState`}
                        type="radio"
                        component="input"
                        value={false}
                      >
                        {({ input, meta }) => (
                          <>
                            <input
                              type="radio"
                              {...input}
                              onChange={() => input.onChange(false)}
                              value={false}
                            />
                            {}
                          </>
                        )}
                      </Field>
                      <label>No </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  {errors[`travelledState`] && touched[`travelledState`] && (
                    <p className="alert alert-danger " role="alert">
                      {errors["travelledState"]}
                    </p>
                  )}
                </div>
                <div className="col-md-12">
                  <div className="align-items-start">
                    <label className="no-wrap-white-space mb-2">
                      IF YES, WHERE:
                    </label>
                    <Field
                      name="travelledStateText"
                      className="Additional_text "
                      component="textarea"
                      placeholder="Free text"
                    />
                    {errors[`travelledStateText`] &&
                      touched[`travelledStateText`] && (
                        <p className="alert alert-danger " role="alert">
                          {errors["travelledStateText"]}
                        </p>
                      )}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                  <label htmlFor="">
                    In the past 2 weeks (14 days) has the patient, caregiver or
                    anyone in your household had contact with any person
                    suspected to have contracted coronavirus (COVID-19)?
                    Including being tested for COVID-19 and/or being in self
                    isolation for COVID-19
                  </label>
                  <div className="d-flex align-items-center justify-content-between w-40 px-1">
                    <div className="radio-of-forms mr-5">
                      <Field
                        name={`suspectContact`}
                        type="radio"
                        component="input"
                        value={true}
                      >
                        {({ input, meta }) => (
                          <>
                            <input
                              type="radio"
                              {...input}
                              onChange={() => input.onChange(true)}
                              value={true}
                            />
                          </>
                        )}
                      </Field>
                      <label> Yes </label>
                    </div>
                    <div className="radio-of-forms">
                      <Field
                        name={`suspectContact`}
                        type="radio"
                        component="input"
                        value={false}
                      >
                        {({ input, meta }) => (
                          <>
                            <input
                              type="radio"
                              {...input}
                              onChange={() => input.onChange(false)}
                              value={false}
                            />
                            {}
                          </>
                        )}
                      </Field>
                      <label>No </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  {errors[`suspectContact`] && touched[`suspectContact`] && (
                    <p className="alert alert-danger " role="alert">
                      {errors["suspectContact"]}
                    </p>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                  <label htmlFor="">
                    In the past 2 weeks (14 days) has the patient, caregiver or
                    anyone in your household had contact with any person
                    confirmed to have contracted COVID-19?
                  </label>
                  <div className="d-flex align-items-center justify-content-between w-40 px-1">
                    <div className="radio-of-forms mr-5">
                      <Field
                        name={`confirmContact`}
                        type="radio"
                        component="input"
                        value={true}
                      >
                        {({ input, meta }) => (
                          <>
                            <input
                              type="radio"
                              {...input}
                              onChange={() => input.onChange(true)}
                              value={true}
                            />
                          </>
                        )}
                      </Field>
                      <label> Yes </label>
                    </div>
                    <div className="radio-of-forms">
                      <Field
                        name={`confirmContact`}
                        type="radio"
                        component="input"
                        value={false}
                      >
                        {({ input, meta }) => (
                          <>
                            <input
                              type="radio"
                              {...input}
                              onChange={() => input.onChange(false)}
                              value={false}
                            />
                            {}
                          </>
                        )}
                      </Field>
                      <label>No </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  {errors[`confirmContact`] && touched[`confirmContact`] && (
                    <p className="alert alert-danger " role="alert">
                      {errors["confirmContact"]}
                    </p>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                  <label htmlFor="">
                    Has the patient or caregiver( if applicable) currently been
                    exposed to someone with flu-like symptoms (cough, shortness
                    of breath or fever)
                  </label>
                  <div className="d-flex align-items-center justify-content-between w-40 px-1">
                    <div className="radio-of-forms mr-5">
                      <Field
                        name={`fluSymtompsContact`}
                        type="radio"
                        component="input"
                        value={true}
                      >
                        {({ input, meta }) => (
                          <>
                            <input
                              type="radio"
                              {...input}
                              onChange={() => input.onChange(true)}
                              value={true}
                            />
                          </>
                        )}
                      </Field>
                      <label> Yes </label>
                    </div>
                    <div className="radio-of-forms">
                      <Field
                        name={`fluSymtompsContact`}
                        type="radio"
                        component="input"
                        value={false}
                      >
                        {({ input, meta }) => (
                          <>
                            <input
                              type="radio"
                              {...input}
                              onChange={() => input.onChange(false)}
                              value={false}
                            />
                            {}
                          </>
                        )}
                      </Field>
                      <label>No </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  {errors[`fluSymtompsContact`] &&
                    touched[`fluSymtompsContact`] && (
                      <p className="alert alert-danger " role="alert">
                        {errors["fluSymtompsContact"]}
                      </p>
                    )}
                </div>
              </div>
              <div className="row mein-white-from-background">
                <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                  IN THE LAST 48 HOURS, HAS THE PATIENT, CAREGIVER, OR ANYONE IN
                  THE HOUSEHOLD EXPERIENCED THE FOLLOWING:
                </div>
                {symtomps?.map((item, index) => {
                  return (
                    <>
                      <div className="d-flex align-items-center justify-content-between col-md-8 mt-4">
                        <label htmlFor="">{item?.value}</label>
                        <div className="d-flex align-items-center justify-content-end w-40 px-1">
                          <div className="radio-of-forms mr-5">
                            <Field
                              name={`${item?.key}`}
                              type="radio"
                              component="input"
                              value={true}
                            >
                              {({ input, meta }) => (
                                <>
                                  <input
                                    type="radio"
                                    {...input}
                                    onChange={() => input.onChange(true)}
                                    value={true}
                                  />
                                </>
                              )}
                            </Field>
                            <label> Yes </label>
                          </div>
                          <div className="radio-of-forms">
                            <Field
                              name={`${item?.key}`}
                              type="radio"
                              component="input"
                              value={false}
                            >
                              {({ input, meta }) => (
                                <>
                                  <input
                                    type="radio"
                                    {...input}
                                    onChange={() => input.onChange(false)}
                                    value={false}
                                  />
                                </>
                              )}
                            </Field>
                            <label>No </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        {errors[`${item?.key}`] && touched[`${item?.key}`] && (
                          <p className="alert alert-danger " role="alert">
                            {errors[`${item?.key}`]}
                          </p>
                        )}
                      </div>
                    </>
                  );
                })}
                <div className="col-md-12 mt-4">
                  <label htmlFor="">
                    If yes was answered for any of the items above, Call the
                    Home Health Ageny for the Patient to report and provide
                    details below:
                  </label>
                  <Field
                    name="details"
                    className="Additional_text"
                    component="textarea"
                    placeholder="Free text"
                  />
                  {errors[`details`] && touched[`details`] && (
                    <p className="alert alert-danger " role="alert">
                      {errors["details"]}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mein-white-from-background justify-content-between ">
                <div className="col-md-4">
                  <div>
                    <Field
                      name="Patientsignature"
                      className="form-control mb-3 "
                      component="input"
                      placeholder="Patient signature"
                    />
                  </div>
                  <div>
                    <div className="">
                      <div className="d-flex gap-2 align-items-center my-3">
                        <span> Therapist Signature: </span>
                        {signatureAdded || values.Therapistsignature ? (
                          <img
                            src={
                              displaySignature
                                ? displaySignature
                                : values.Therapistsignature
                            }
                            height={"100px"}
                            width={"300px"}
                            crossOrigin="anonymous"
                          />
                        ) : (
                          <input
                            type="text"
                            placeholder="Therapist Signature"
                            disabled
                            className=" pl-0 border-0 border-right-2 border-left-2 border-bottom-2"
                          />
                        )}
                      </div>

                      {currentUser &&
                        currentUser.Role &&
                        currentUser.Role.key &&
                        (currentUser.Role.key ===
                          currentRole.supervisingtherapist ||
                          currentUser.Role.key ===
                            currentRole.assistanttherapist) &&
                        !toSave && (
                          <button
                            className="Approve-btns ml-0"
                            onClick={(e) => {
                              e.preventDefault();
                              setIsCanvasShow(true);
                            }}
                            disabled={
                              visitDetailById &&
                              !canSubmitForm.includes(
                                visitDetailById.Status.key
                              )
                            }
                          >
                            {signatureAdded || values.Therapistsignature
                              ? "Update Signature"
                              : "Add Signature"}
                          </button>
                        )}
                    </div>
                    {submitFailed &&
                      !values.Therapistsignature &&
                      !displaySignature && (
                        <p className="alert alert-danger my-2 " role="alert">
                          Signature is required.
                        </p>
                      )}
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  <p>
                    Physician Name :
                    <span className="ml-5">
                      {staffDetails &&
                      staffDetails.staffingDetails &&
                      staffDetails.staffingDetails.physicianName
                        ? staffDetails.staffingDetails.physicianName
                        : "--"}
                    </span>
                  </p>

                  <div className="row d-flex  align-items-center  ">
                    <div className="col-md-6">
                      <div className="d-flex align-items-start">
                        <label className="no-wrap-white-space mb-0">
                          Physician Signature :
                        </label>
                        <Field
                          name="Physiciansignature"
                          className="Additional_text ml-3 "
                          component="textarea"
                          placeholder=""
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="d-flex align-items-start mb-0">
                        <label className="mb-0 text-nowrap">Date:</label>
                        <Field
                          name="date"
                          className="Additional_text ml-3 "
                          component="textarea"
                          placeholder=""
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {currentUser &&
                currentUser.Role &&
                currentUser.Role.key &&
                (currentUser.Role.key === currentRole.supervisingtherapist ||
                  currentUser.Role.key === currentRole.assistanttherapist) && (
                  <div className="container">
                    <div className="py-5 ">
                      <button
                        type="submit"
                        className="Approve-btns  mx-2 h-auto"
                        onClick={(e) => {
                          e.preventDefault();
                          if (!valid) handleSubmit();
                          if (valid) setShow(true);
                          else
                            toast.error("Please complete all required fields.");
                        }}
                        disabled={
                          visitDetailById &&
                          !canSubmitForm.includes(getFormStatus())
                        }
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="Approve-btns  mx-2 h-auto"
                        onClick={(e) => {
                          e.preventDefault();
                          onSubmit(values);
                        }}
                        disabled={
                          visitDetailById &&
                          !canSubmitForm.includes(getFormStatus())
                        }
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              {show && (
                <Modal
                  show={show}
                  onHide={() => setShow(false)}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Header className="pl-4 pr-4 pt-4 pb-3" closeButton>
                    <Modal.Title>Complete</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="pl-4 pr-4 pt-0 pb-4">
                    {/* By confirming this you will mark the {activeForm && activeForm.name}  document as complete. Are you sure? */}
                    By confirming this you will mark the COVID-19 QUESTIONNAIRE
                    Qu as completed. Are you sure?
                    <div className="mt-4">
                      <Button
                        className="Discard-danger"
                        variant="outline-danger"
                        onClick={() => setShow(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        className="primary-confirm"
                        onClick={(e) => {
                          e.preventDefault();
                          if (valid)
                            onSubmit(values, form, visitStatus.needreview);
                        }}
                      >
                        Confirm
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </div>
          </form>
        )}
      />

      {isCanvasShow && (
        <SignatureCanvas
          loadDoc={saveForm}
          isShow={isCanvasShow}
          setSignatureAdded={setSignatureAdded}
          handleClose={() => setIsCanvasShow(false)}
        />
      )}
    </div>
  );
}
