import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import editWordDoc from "../../images/editword.svg";
import {
  BsFillCheckCircleFill,
  BsCheck2,
  BsFillXCircleFill,
} from "react-icons/bs";
import { Form, Field } from "react-final-form";
import { userRejected } from "../../Redux/actions/therapist";
import Loader from "../../components/Loader";
import { userCredentials } from "../../utils/auth";
import { onboardingPDFRoute } from "../../utils/directoryroute";

const ComplianceRejection = () => {
  const profileDetail = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const isLoading = useSelector((e) => e.therapistSigunpSlice.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [compliance, setCompliance] = useState();
  const [competency, setCompetency] = useState();

  const handleSubmit = (values) => {
    const data = {
      userId: profileDetail?.id,
      rejectedFields: {
        ...values,
        ...{ compliance: compliance, competencies: competency },
      },
      step: 2,
    };

    if (data?.rejectedFields) {
      dispatch(userRejected(data)).then((res) => {
        if (res && res?.payload?.data) {
          if (compliance === "approved" && competency === "approved") {
            toast.success("Therapist approved successfully.");
            navigate(`/newusers`);
          } else {
            toast.success("Therapist rejected successfully.");
            navigate(`/newusers`);
          }
        }
      });
    }
  };

  const getEnvalop = (obj) => {
    if (obj.envelopeId) {
      localStorage.setItem("envelope", JSON.stringify(obj));
      window.location.href = `https://account-d.docusign.com/oauth/auth?response_type=code&scope=signature&client_id=da63433e-7e57-4f39-98be-a6702849e067&redirect_uri=http://localhost:3001/compianceenvalop`;
    }
  };

  const handleDocumentShow = (typename) => {
    if (PrefillDetails && PrefillDetails.onboardingPdf) {
      let formdetail = PrefillDetails.onboardingPdf.find(
        (item) => item.types === typename
      );
      const url = `${onboardingPDFRoute}${formdetail.file}`;
      window.open(url, "_blank").focus();
    } else {
      toast.warn("There is no document to show!");
    }
  };
  return (
    <div>
      {isLoading && <Loader />}
      <Form
        onSubmit={handleSubmit}
        initialValues={
          profileDetail?.userDetail &&
          profileDetail?.userDetail?.rejectedFields && {
            ...profileDetail?.userDetail?.rejectedFields,
          }
        }
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 ">
                  <div className="d-flex flex-wrap gap-5">
                    <div>
                      <div
                        className="box-step"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDocumentShow(userCredentials.compliance)
                        }
                      >
                        <div className="d-flex flex-column">
                          <img
                            className="img-modal mt-3 edit-doc-icon"
                            src={editWordDoc}
                            alt="application submitted"
                          />
                          {/* <i>
                            <BsPencilSquare size={56} className="mb-2" />
                          </i> */}
                          <h1 className="pe-auto">Compliance Document</h1>
                        </div>

                        <div className="check-icon-1">
                          {compliance === "approved" && (
                            <BsFillCheckCircleFill size={30} color={"green"} />
                          )}
                          {compliance === "rejected" && (
                            <BsFillXCircleFill size={30} color={"red"} />
                          )}
                        </div>
                      </div>
                      <div className=" mt-3 pl-5 pr-5 d-flex justify-content-center">
                        <button
                          type="button"
                          className="btn1 agency-btn"
                          onClick={() => setCompliance("approved")}
                        >
                          <BsCheck2 className="check-icon" />
                          Approve
                        </button>
                        <button
                          type="button"
                          className="btn2 agency-btn"
                          onClick={() => setCompliance("rejected")}
                        >
                          <AiOutlineClose className="cross-icon" color="red" />
                          Reject
                        </button>
                      </div>
                    </div>
                    <div>
                      <div
                        className="box-step"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDocumentShow(userCredentials.competencies)
                        }
                      >
                        <div className="d-flex flex-column">
                          <img
                            className="img-modal mt-3 edit-doc-icon"
                            src={editWordDoc}
                            alt="application submitted"
                          />
                          {/* <i>
                            <BsPencilSquare size={56} className="mb-2" />
                          </i> */}
                          <h1>Competency Assessment</h1>
                        </div>
                        <div className="check-icon-1">
                          {competency === "approved" && (
                            <BsFillCheckCircleFill size={30} color={"green"} />
                          )}
                          {competency === "rejected" && (
                            <BsFillXCircleFill size={30} color={"red"} />
                          )}
                        </div>
                      </div>
                      <div className=" mt-3 pl-5 pr-5  d-flex justify-content-center">
                        <button
                          className="btn1 agency-btn"
                          type="button"
                          onClick={() => setCompetency("approved")}
                        >
                          <BsCheck2 className="check-icon" />
                          Approve
                        </button>
                        <button
                          className="btn2 agency-btn"
                          type="button"
                          onClick={() => setCompetency("rejected")}
                        >
                          <AiOutlineClose className="cross-icon" color="red" />
                          Reject
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(competency === "rejected" || compliance === "rejected") && (
              <Field name="reasonforRejection">
                {({ input, meta }) => (
                  <>
                    <textarea
                      className="agen-reject-input-1"
                      type="text"
                      {...input}
                      placeholder={!meta.active && "Need more info"}
                    />
                    {meta.error && meta.touched && (
                      <p className="input-error">{meta.error}</p>
                    )}
                  </>
                )}
              </Field>
            )}

            <button
              className="btn1 agen-reject-btn compliance-btn ml-4 mt-5"
              type="submit"
              disabled={!competency || !compliance}
            >
              <BsCheck2 className="check-icon icon-check" />
              Submit
            </button>
          </form>
        )}
      />
    </div>
  );
};

export default ComplianceRejection;
