import React, { useEffect, useState } from "react";
import { CommonHead2 } from "./common/CommonHead2";
import CommonTable from "./common/CommonTable";
import userImage from "../images/loginpage.png";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "bootstrap-4-react";
import { useDispatch, useSelector } from "react-redux";
import { getBackgroundCheck } from "../Redux/actions/auth";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { currentRole, encodedData } from "../utils/auth";
import { setLoader } from "../Redux/slices/authSlice";
import { getBackgroundReportPDF } from "../Redux/actions/background";
import { MyModal2 } from "../pages/modals/MyModal2";
import Supervisingpng from "../images/usertable03.svg";
import Spinner from "react-bootstrap/Spinner";
import {
  apiBasePath,
  backgroundApiBasePath,
  backgroundApiToken,
} from "../Redux/config/config";
import { toast } from "react-toastify";

let data = [
  {
    name: "Kameshki Maria",
    discipline: "PT",
    status: "STEP1 STEP2 STEP3",
  },
  {
    name: "Kameshki Maria",
    discipline: "PT",
    status: "STEP1 STEP2 STEP3",
  },
];

const BackgroundCheck2 = () => {
  const [menuItem, setMenuItem] = useState("pending");
  const [backgroundReportShow, setbackgroundReportShow] = useState(false);
  const [backgroundReportkey, setbackgroundReportkey] = useState(null);
  const [pdfLoader, setPdfLoader] = useState(-1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userList = useSelector((e) => e.authSlice.userDetails);
  const [pageNo, setPageNo] = useState(1);

  const userReviewCount = useSelector((e) => e.authSlice.userReviewCount);
  const getRoleDetailCount = (key) => {
    let count = 0;
    if (userReviewCount && Object.keys(userReviewCount).length > 0) {
      count = userReviewCount[key];
    }
    return count;
  };
  const getBackgroundPDF = (reportKey) => {
    if (!reportKey) {
      toast.error("Report key not found");
      return;
    }
    setbackgroundReportShow(true);
    setbackgroundReportkey(reportKey);
    dispatch(getBackgroundReportPDF({ reportKey: reportKey }));
  };

  const downloadBackgroundCheckPdf = async (reportKey, id) => {
    try {
      setPdfLoader(id);
      let url = `${backgroundApiBasePath}report/${reportKey}/pdf?api_token=${backgroundApiToken}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "text/plain",
        },
      });

      setPdfLoader(-1);
      if (response.status === 200) {
        const pdfBlob = await response.blob();
        const blobUrl = URL.createObjectURL(pdfBlob);

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "downloaded.pdf";

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        URL.revokeObjectURL(blobUrl);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      setPdfLoader(-1);
    }
  };
  const menu = [
    {
      label: "Pending Background Checks",
      key: "pending",
      count: getRoleDetailCount("pendingReviewCount"),
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  getBackgroundPDF(rowData.userDetail.reportKey);
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
    },
    {
      label: "Completed Background Checks",
      key: "approved",
      count: getRoleDetailCount("approvedTherapistCount"),
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <button
                  className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                  onClick={() => {
                    navigate(
                      `/newusers/therapist/${encodedData({ id: rowData.id })}`
                    );
                  }}
                >
                  View
                </button>
              </td>

              {rowData.userDetail.reportKey && (
                <td className="">
                  <button
                    className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                    disabled={pdfLoader === rowData.id}
                    onClick={() => {
                      downloadBackgroundCheckPdf(
                        rowData.userDetail.reportKey,
                        rowData.id
                      );
                    }}
                  >
                    {pdfLoader === rowData.id && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        className="clear-pdf"
                        aria-hidden="true"
                      />
                    )}
                    Pdf
                  </button>
                </td>
              )}
            </>
          ),
        },
      ],
    },
  ];

  const myActions = menu.find((ele) => ele.key === menuItem);
  let columns = ["name", "discipline", "status", "backGroundStatus"];
  let columnsToHide = ["image"];

  useEffect(() => {
    dispatch(
      getBackgroundCheck({
        status: ["pending", "approved"],
        pageSize: 10,
        pageNo: 1,
      })
    );
  }, []);
  const overrideColumnName = (colName) => {
    switch (colName) {
      case "name":
        return "Name";
      case "usertype":
        return "USER TYPE";
      case "discipline":
        return "DISCIPLINES";
      case "blockedby":
        return "BLOCKED BY";
      case "status":
        return "STATUS";
      case "backGroundStatus":
        return "Clear Check Status";

      default:
        return colName;
    }
  };

  const renderRowData = (result, col, item) => {
    if (col === "agencyName") {
      return (
        <>
          <div className="d-flex align-items-center">
            <img
              alt="ImageData"
              src={
                result.image
                  ? `${apiBasePath}documents/therapist/${result.image}`
                  : userImage
              }
              crossOrigin="anonymous"
              className="table-img "
              style={{ borderRadius: "50%", objectFit: "cover" }}
            />
            <h1 className=" title-22"> {result[item]} </h1>
          </div>
        </>
      );
    } else if (col === "name") {
      return (
        <>
          <div className="d-flex align-items-center">
            <img
              alt="user Data"
              src={
                result.image
                  ? `${apiBasePath}documents/therapist/${result.image}`
                  : Supervisingpng
              }
              crossOrigin="anonymous"
              className="table-img  "
              style={{ borderRadius: "50%", objectFit: "cover" }}
            />
            <h1 className=" title-22">
              {result.lastName + ", " + result.firstName}
            </h1>
          </div>
        </>
      );
    }
    if (col === "discipline") {
      return (
        <>
          <h1 style={{ color: "#0A365B" }} className=" title-24">
            {result.userDetail &&
              result.userDetail.discipline &&
              result.userDetail.discipline.toUpperCase()}
            <BsFillCheckCircleFill
              style={{ marginLeft: "10px" }}
              size={20}
              color={"#3B9A3D"}
            />{" "}
          </h1>
        </>
      );
    }
    if (col === "status") {
      return (
        <>
          <h1 style={{ color: "#0A365B" }} className=" title-24">
            {result && result.Status && result.Status.name}
          </h1>
        </>
      );
    }
    if (col === "backGroundStatus") {
      return (
        <>
          <h1 style={{ color: "#0A365B" }} className=" title-24">
            {result && result.backGroundStatus ? result.backGroundStatus : ""}
          </h1>
        </>
      );
    }
    <h1 className=" title-24"> {result[item]} </h1>;
  };

  const setTabMenus = (tab) => {
    dispatch(setLoader({ loading: true }));
    setMenuItem(tab);
    setPageNo(1);
    dispatch(
      getBackgroundCheck({
        status: tab,
        pageSize: 10,
        pageNo: 1,
      })
    );
  };

  const ReportShow = () => {
    return (
      <iframe
        title="bgreport"
        style={{ width: "70vw", height: "70vh" }}
        src={`https://sandbox.clearchecks.com/api/report/${backgroundReportkey}/pdf?api_token=${backgroundApiToken}`}
      />
    );
  };

  return (
    <>
      <div className="pl-1 pr-5 newusers">
        <CommonHead2
          data={menu}
          setMenu={setTabMenus}
          menuItem={menuItem}
          css="col-md-3"
        />
      </div>
      <div className="newuser-m ">
        <div className="col-12 col-lg-12 ">
          <Row>
            <Col lg={12}>
              <div className="table-responsive">
                <CommonTable
                  data={userList}
                  columns={columns}
                  columnsToHide={columnsToHide}
                  overrideColumnName={overrideColumnName}
                  actions={myActions?.actions}
                  renderRowData={renderRowData}
                  isLoading={false}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {backgroundReportShow && (
        <MyModal2
          dialogClassName="modal-lg"
          modalClass="Main_modal_1"
          show={backgroundReportShow}
          handleClose={() => setbackgroundReportShow(false)}
          data={<ReportShow />}
          centered
        />
      )}
    </>
  );
};

export default BackgroundCheck2;
