import * as yup from "yup";

export const signInSchema = yup.object().shape({
  email: yup
    .string()
    .required("Username is required.")
    .email("Please enter a valid email."),
  password: yup.string().required("Password is required."),
});

export const formSchema = yup.object().shape({
  reasonforRejection: yup.string().required("Reason of rejection is required."),
});

export const resetPasswordSchema = yup.object().shape({
  oldPassword: yup.string().required("This is a required field."),
  Password: yup
    .string()
    .required("Password is a required field.")
    .min(8, "Password strength/ length should be at least 8 characters and maximum of 64 characters.")
    .max(64, "Password strength/ length cannot exceed 64 characters.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])/,
      "Password strength/length should be at least  8 characters and maximum 64 characters and contain an uppercase, a lowercase, a special character, and a digit. "
    ),
  confirmPassword: yup
    .string()
    .required("Confirm password is required field.")
    .oneOf([yup.ref("Password"), null], "Both password should match."),
});

export const createUserSchema = yup.object().shape({
  phoneNo: yup
    .string()
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Phone number can only have numbers and maximum of 10 digits."
    )
    .required("Please enter your phone number."),

  firstName: yup
    .string()
    .required("Please enter your first name.")
    .matches(/^[a-zA-Z ]*$/, "First name can only contain alphabets."),
  lastName: yup
    .string()
    .required("Please enter your last Name.")
    .matches(/^[a-zA-Z ]*$/, "Last name can only contain alphabets."),
  email: yup
    .string()
    .email()
    .required("Please enter your email address.")
    .matches(
      /^[a-z0-9](?!.*?[^\na-z0-9]{2}).*?[a-z0-9]$/gim,
      "Please enter a valid email address."
    ),
});

export const AdminUpdateschema = yup.object().shape(
  {
    userName: yup.string().required("Please enter your username."),
    firstName: yup.string().required("Please enter first name."),
    lastName: yup.string().required("Please enter last name."),
    email: yup.string().email().required("Please enter your email address."),
    password: yup.string().when("password", (val, schema) => {
      if (val) {
        if (val.length > 0) {
          return yup
            .string()
            .required("Please enter your password.")
            .min(8, "Password should be more than 8 characters.")
            .max(25, "Password cannot exceed 25 characters.")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])/,
              "Password strength/length should be at least  8 characters and maximum 64 characters and contain an uppercase, a lowercase, a special character, and a digit."
            );
        } else {
          return yup.string().notRequired();
        }
      } else {
        return yup.string().notRequired();
      }
    }),
    confirmPassword: yup.string().when("confirmPassword", (val, schema) => {
      if (val) {
        if (val.length > 0) {
          return yup
            .string()
            .required("Confirm password is required field.")
            .oneOf([yup.ref("password"), null], "Both password should match.");
        } else {
          return yup.string().notRequired();
        }
      } else {
        return yup.string().notRequired();
      }
    }),
    emptyPass: yup.string().when("emptyPass", (val, schema) => {
      if (val) {
        if (val.length > 0) {
          return yup.string();
        } else {
          return yup.string().notRequired();
        }
      } else {
        return yup.string().notRequired();
      }
    }),
  },
  [
    ["password", "password"],
    ["confirmPassword", "confirmPassword"],
    ["emptyPass", "emptyPass"],
  ]
);

export const agencySignupSchema = yup.object().shape({
  name: yup.string().required(),
  phone: yup.string().required(),
  fax: yup.string().required(),
  representative: yup.string().required(),
  representativePhoneNo: yup.string().required(),
  ext: yup.string().required(),
  userName: yup.string().required(),
  password: yup.string().required(),
  confirmPassword: yup.string().required(),
  street: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zip: yup.string().required(),
  aptUnit: yup.string().required(),
  email: yup.string().required(),
  intakeStaffing: true,
  caseManagement: true,
  accounting: true,
});

export const agencyRateValidation = yup.object().shape({
  occupationaltherapyot: yup.array().of(
    yup.object().shape({
      rate: yup
        .number()
        .typeError("You must specify a number.")
        .test("len", "Must be exactly 10 digits", (val) => val.length === 10),
    })
  ),
  physicaltherapypt: yup.array().of(
    yup.object().shape({
      rate: yup.number().typeError("You must specify a number."),
    })
  ),
  speechtherapyst: yup.array().of(
    yup.object().shape({
      rate: yup.number().typeError("You must specify a number."),
    })
  ),
});

export const therapistRateValidation = yup.object().shape({
  pt: yup.array().of(
    yup.object().shape({
      rate: yup.number().typeError("You must specify a number."),
    })
  ),
  ot: yup.array().of(
    yup.object().shape({
      rate: yup.number().typeError("You must specify a number."),
    })
  ),
  cota: yup.array().of(
    yup.object().shape({
      rate: yup.number().typeError("You must specify a number."),
    })
  ),
  pta: yup.array().of(
    yup.object().shape({
      rate: yup.number().typeError("You must specify a number."),
    })
  ),
  slp: yup.array().of(
    yup.object().shape({
      rate: yup.number().typeError("You must specify a number."),
    })
  ),
});

export const userSchema = yup.object().shape({
  usernameOrEmail: yup
    .string()
    .min(4, "Mininum 4 characters.")
    .max(25, "Maximum 25 characters.")
    .required("You must enter a username or emai address."),
});

export const cancelApprovalModalSchema = yup.object().shape({
  cancelReason: yup.string().required("Please provide a reason."),
});
