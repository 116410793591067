import React, { useEffect } from "react";
import { Row, Col } from "bootstrap-4-react";
import { debounce } from "lodash";
import userImage from "../images/usertable02.svg";
import { useNavigate } from "react-router-dom";
import CommonTable from "./common/CommonTable";
import Searchbar from "../images/search_grey_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getStatusList, getUserDropDownData } from "../Redux/actions/auth";
import {
  currentRole,
  defaultStatus,
  encodedData,
  statusType,
} from "../utils/auth";
import Loader from "./Loader";
import { apiBasePath } from "../Redux/config/config";
import { useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { statusBaseColorChange, viewCaseStatueName } from "../utils/helper";
import { BiCircle } from "react-icons/bi";
import { BsFillCheckCircleFill } from "react-icons/bs";

const Patient = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userList = useSelector((e) => e.commonSlice.patirntChartList);
  const totalCount = useSelector((e) => e.commonSlice.totalCount);
  const userDropDownData = useSelector((e) => e.staffingSlice.agencyList);
  const statusList = useSelector((e) => e.adminSlice.statusList);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const [agencyId, setAgencyId] = useState(0);
  const [caseStatusId, setCaseStatusId] = useState(0);
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [searchval, setSearchval] = useState(null);
  const isLoading = useSelector((e) => e.commonSlice.isLoading);

  useEffect(() => {
    getStaffingList({
      pageNo: pageNo,
      pageSize: pageSize,
      status: [defaultStatus.unfinishedprofile, defaultStatus.staffedreferral],
      agencyId,
      caseStatusId,
      search: searchval,
    });
    dispatch(getUserDropDownData({ role: currentRole.agency }));
    dispatch(getStatusList({ type: statusType.case }));
  }, []);

  const getStaffingList = (obj) => {
    dispatch(
      props.getList &&
        props.getList({
          pageNo: obj?.pageNo,
          pageSize: obj?.pageSize,
          status: obj?.status,
          search: obj?.search,
          agencyId: obj?.agencyId,
          caseStatusId: obj?.caseStatusId,
        })
    );
  };
  const debounceCall = debounce((ele) => {
    getStaffingList({
      pageNo: pageNo,
      pageSize: pageSize,
      status: [defaultStatus.unfinishedprofile, defaultStatus.staffedreferral],
      search: ele,
      agencyId: agencyId,
    });
  }, 700);

  const hanleSearchVal = debounce((ele) => {
    setSearchval(ele.target.value);
  }, 700);

  function handleClick(rowData) {
    if (rowData?.Role?.key === currentRole.patient) {
      navigate(
        `/patientdetails/${encodedData(rowData?.id)}${
          rowData?.Status?.key === defaultStatus.staffedreferral
            ? "/episodes"
            : ""
        }`
      );
    } else {
      navigate(
        `/patientdetails/${encodedData(rowData?.staffUserId)}${
          rowData?.Status?.key === defaultStatus.staffedreferral
            ? "/episodes"
            : ""
        }`
      );
    }
  }

  const actions = [
    {
      action: (rowData) => (
        <td className="">
          <button
            className="view-btn tr-btn ml-auto  mt-3 mb-3 "
            onClick={() => {
              handleClick(rowData);
            }}
          >
            View
          </button>
        </td>
      ),
    },
  ];

  let columns = props.tableColumns ? props.tableColumns : [];
  let columnsToHide = ["image"];

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "staffUser.firstName":
        return "Name";
      case "staffUser.staffingDetails.address":
        return "Address";
      case "staffingDetails.address":
        return "Address";
      case "firstName":
        return "Name";
      case "status":
        return "Status";
      case "createdByUser.name":
        return "Agency Name";
      case "staffUser.staffingDetails.caseStatus.name":
        return "Case Status";
      case "staffingDetails.caseStatus.name":
        return "Case Status";
      case "staffingDetails.disciplinesNeeded":
        return "disciplines";
      case "staffUser.staffingDetails.disciplinesNeeded":
        return "disciplines";
      default:
        return colName;
    }
  };

  const renderRowData = (result, col, item) => {
    if (col === "staffUser.firstName") {
      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={
                result.staffUser && result.staffUser.image
                  ? `${apiBasePath}documents/therapist/${result.staffUser.image}`
                  : userImage
              }
              crossOrigin="anonymous"
              className="table-img"
              style={{ borderRadius: "50%", objectFit: "cover" }}
              alt="img"
            />
            <div>
              <p className="  title-22 m-0">
                {" "}
                {result.staffUser &&
                  `${
                    result.staffUser.lastName +
                    ", " +
                    result.staffUser.firstName
                  }`}
              </p>
              {result?.staffUser?.staffingDetails?.mrn && (
                <p className="  title-24">
                  MR #
                  {result.staffUser &&
                    result.staffUser.staffingDetails &&
                    result.staffUser.staffingDetails.mrn}
                </p>
              )}
            </div>
          </div>
        </>
      );
    }

    if (col === "firstName") {
      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={
                result && result.image
                  ? `${apiBasePath}documents/therapist/${result.image}`
                  : userImage
              }
              crossOrigin="anonymous"
              className="table-img"
              style={{ borderRadius: "50%", objectFit: "cover" }}
              alt="img"
            />
            <div>
              <p className="  title-22 m-0">
                {" "}
                {result && `${result.lastName + ", " + result.firstName}`}
              </p>
              {result?.staffingDetails?.mrn && (
                <p className="  title-24 m-0">
                  MR #{result?.staffingDetails?.mrn}
                </p>
              )}
            </div>
          </div>
        </>
      );
    }

    if (col === "createdByUser.name") {
      return (
        <>
          {result && result.createdByUser && result.createdByUser.name ? (
            <>
              <p className="displine-list m-0">{result.createdByUser.name}</p>
            </>
          ) : (
            <p>--</p>
          )}
        </>
      );
    }
    if (col === "staffUser.staffingDetails.address") {
      return (
        <>
          {result && result.staffUser && result.staffUser.staffingDetails ? (
            <>
              <p className="m-0 displine-list">
                {result.staffUser.staffingDetails["address"] &&
                  result.staffUser.staffingDetails["address"]}
              </p>
              <p className="m-0 displine-list">
                {result.staffUser.staffingDetails["city"] &&
                  result.staffUser.staffingDetails["city"]}
                {", "}
                {result.staffUser.staffingDetails["state"] &&
                  result.staffUser.staffingDetails["state"]}
                {", "}
                {result.staffUser.staffingDetails["zip"] &&
                  result.staffUser.staffingDetails["zip"]}
              </p>
            </>
          ) : (
            <p>--</p>
          )}
        </>
      );
    }

    if (col === "staffingDetails.address") {
      return (
        <>
          {result && result.staffingDetails ? (
            <>
              <p className="m-0 displine-list addres-col">
                {result.staffingDetails["address"] &&
                  result.staffingDetails["address"]}
              </p>
              <p className="m-0 displine-list addres-col">
                {result.staffingDetails["city"] &&
                  result.staffingDetails["city"]}
                {", "}
                {result.staffingDetails["state"] &&
                  result.staffingDetails["state"]}
                {", "}
                {result.staffingDetails["zip"] && result.staffingDetails["zip"]}
              </p>
            </>
          ) : (
            <p>--</p>
          )}
        </>
      );
    }

    if (col === "staffUser.staffingDetails.caseStatus.name") {
      return (
        <p
          className={`m-0 mt-3 mb-3 status-td ${statusBaseColorChange(
            result &&
              result.staffUser &&
              result.staffUser.staffInitialVisit &&
              result.staffUser.staffInitialVisit.length > 0 &&
              result.staffUser.staffInitialVisit[0].Status &&
              result.staffUser.staffInitialVisit[0].Status.key
          )}`}
        >
          {viewCaseStatueName(
            result &&
              result.staffUser &&
              result.staffUser.staffInitialVisit &&
              result.staffUser.staffInitialVisit.length > 0 &&
              result.staffUser.staffInitialVisit[0].Status &&
              result.staffUser.staffInitialVisit[0].Status.key
          )}
        </p>
      );
    }

    if (col === "staffingDetails.caseStatus.name") {
      return (
        <>
          <p
            className={`m-0 mt-3 mb-3 status-td ${statusBaseColorChange(
              result?.staffInitialVisit[0]?.Status?.key
            )}`}
          >
            {result?.staffInitialVisit?.length > 0
              ? result?.staffInitialVisit[0]?.Status?.name
              : "-"}
          </p>
        </>
      );
    }

    if (col === "staffingDetails.disciplinesNeeded") {
      return (
        <>
          {result &&
          result.staffingDetails &&
          result.staffingDetails.disciplinesNeeded &&
          Object.keys(result.staffingDetails.disciplinesNeeded).length > 0 ? (
            <p className="displine-list">
              {Object.keys(result?.staffingDetails?.disciplinesNeeded)
                .join(" , ")
                .toUpperCase()}
            </p>
          ) : (
            <p>--</p>
          )}
        </>
      );
    }
    if (col === "staffUser.staffingDetails.disciplinesNeeded") {
      return (
        <>
          {result &&
          result.staffUser &&
          result.staffUser.staffingDetails &&
          result.staffUser.staffingDetails.disciplinesNeeded &&
          Object.keys(result.staffUser.staffingDetails.disciplinesNeeded)
            .length > 0 ? (
            <p className="displine-list">
              {Object.keys(
                result.staffUser?.staffingDetails?.disciplinesNeeded
              ).map((e) => {
                return (
                  <div className="d-flex gap-2">
                    <p className="m-0 spanp">{e.toUpperCase()}</p>
                    <span className="spanp">
                      {(result &&
                        result.Status &&
                        result.Status.key ===
                          defaultStatus.awaitingagencyapproval) ||
                      (result &&
                        result.Status &&
                        result.Status.key === defaultStatus.pending) ? (
                        <BiCircle color="#A1AEB9" className="progress-check" />
                      ) : (
                        <BsFillCheckCircleFill className="progress-check-after" />
                      )}
                    </span>
                  </div>
                );
              })}
            </p>
          ) : (
            <p>--</p>
          )}
        </>
      );
    }

    if (result[item] && typeof result[item] == "string") {
      return <p className=" title-24 m-0 mt-3 mb-3">{result[item]}</p>;
    } else {
      return <p className=" title-24 m-0 mt-3 mb-3">--</p>;
    }
  };

  function handleFilter(e, type) {
    let agency = agencyId;
    let caseStatus = caseStatusId;
    if (type === "agency") {
      setAgencyId(Number(e.target.value));
      agency = Number(e.target.value);
    } else if (type === "case") {
      setCaseStatusId(Number(e.target.value));
      caseStatus = Number(e.target.value);
    }
    let temp = {
      pageNo: 1,
      pageSize: 10,
      status: [defaultStatus.unfinishedprofile, defaultStatus.staffedreferral],
    };
    if (Number(agency) > 0) {
      temp["agencyId"] = Number(agency);
    }
    if (Number(caseStatus) > 0) {
      temp["caseStatusId"] = Number(caseStatus);
    }
    if (searchval) {
      temp = { ...temp, search: searchval };
    }
    getStaffingList(temp);
  }

  const handlePageClick = (event) => {
    getStaffingList({
      pageNo: event.selected + 1,
      pageSize: pageSize,
      status: [defaultStatus.unfinishedprofile, defaultStatus.staffedreferral],
    });
    setPageNo(event.selected + 1);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="containeter table-container-2 pr-50 mobile_left_width">
        <div
          className="patient_main_parent  gap-3 justify-content-between "
          style={{ marginBottom: "50px" }}
        >
          <div className="search_bar_frm  mobile_margin_1 mt-3">
            <img style={{ marginRight: "15px" }} src={Searchbar} alt="" />
            <input
              placeholder="Search by patient name."
              className="pl-2 searching "
              onChange={(ele) => {
                debounceCall(ele.target.value);
                hanleSearchVal(ele);
              }}
              style={{ border: "none", fontSize: "18px", outline: "none" }}
            />
          </div>
          <div className="patient_right_form  mobile_wrap-990 mobile_justify-start gap-4 mt-3 ">
            <div
              className="inner_content_form mobile_justify-start mobile_width"
              style={{ marginRight: "20px" }}
            >
              <p className="ml-0 mobile_width-30"> Filter by Case Status</p>
              <select
                className="form-select"
                onChange={(e) => {
                  handleFilter(e, "case");
                }}
              >
                <option value="" className="option-color">
                  All
                </option>

                {statusList &&
                  statusList.map((e) => (
                    <option value={e.id} key={e.id}>
                      {e.name}
                    </option>
                  ))}
              </select>
            </div>
            {currentUser &&
              (currentUser.Role.key === currentRole.superadmin ||
                currentUser.Role.key === currentRole.admin) && (
                <div className="inner_content_form mobile_width">
                  <p className="ml-0 mobile_width2-30"> Filter by Agency</p>
                  <select
                    className="form-select"
                    onChange={(e) => handleFilter(e, "agency")}
                  >
                    <option value="0">All</option>
                    {userDropDownData &&
                      userDropDownData.map((e) => (
                        <option value={e.id} key={e.id}>
                          {e.name}
                        </option>
                      ))}
                  </select>
                </div>
              )}
            {currentUser && currentUser.Role.key === currentRole.agency && (
              <div className="inner_content_form">
                <button
                  className=" new-staffing-btn d-flex align-items-center pl-1 "
                  onClick={() => navigate("/staffing/staff-new-referral")}
                >
                  <AiFillPlusCircle
                    style={{
                      width: "35px",
                      height: "35px",
                      marginRight: "8px",
                    }}
                    color="white"
                  />
                  STAFF NEW REFFERAL
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {userList && userList.length > 0 ? (
        <div className="container-fluid table-container-2 pr-50">
          <Row>
            <div className="inside_table_tabs">
              <Col lg={12} className="p-0">
                <div className="table-responsive table-width-1 p-chart">
                  <CommonTable
                    data={userList}
                    columns={columns}
                    columnsToHide={columnsToHide}
                    overrideColumnName={overrideColumnName}
                    actions={actions}
                    renderRowData={renderRowData}
                    isLoading={isLoading}
                  />
                </div>
              </Col>
            </div>
          </Row>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center mt-5">
          {!isLoading && <h2 className="fs-5 mt-5">No Data available</h2>}
        </div>
      )}
      {totalCount > 10 && userList?.length > 0 && (
        <div className="container-fluid ">
          <div className="row justify-content-start mt-3  pl-5 ">
            <div className="col-sm-4 pl-5 pr-5">
              <ReactPaginate
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={Math.ceil(totalCount / pageSize)}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                forcePage={
                  Math.ceil(totalCount / pageSize) === 0 ? -1 : pageNo - 1
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Patient;
