import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../config/axiosInstance";

export const userSignup = createAsyncThunk(
  "userSignup/therapist/therapistSignUp",
  async (body) => {
    const data1 = await axiosInstance.post(`user/userSignUp`, body);
    return data1.data;
  }
);

export const therapistDefaultRates = createAsyncThunk(
  "therapistDefaultRates",
  async (body) => {
    const data1 = await axiosInstance.post(`user/therapistRateCreate`, body);
    return data1.data;
  }
);

export const userPaymentDetail = createAsyncThunk(
  "userPaymentDetail/user/userPaymentDetail",
  async (body) => {
    const data1 = await axiosInstance.post(`user/userPaymentDetail`, body);
    return data1.data;
  }
);

export const therapistNotifications = createAsyncThunk(
  "therapistNotifications/therapist/therapistNotification",
  async (body) => {
    const data1 = await axiosInstance.post(`user/therapistNotification`, body);
    return data1.data;
  }
);

export const therapistCredentials = createAsyncThunk(
  "therapistCredentials",
  async (body) => {
    const data1 = await axiosInstance.post(`user/therapistcredential`, body);
    return data1.data;
  }
);

export const masterData = createAsyncThunk(
  "/auth/master-data/MasterData",
  async (body) => {
    const data1 = await axiosInstance.post(`/auth/master-data`, body);
    return data1.data;
  }
);

export const profileMasterData = createAsyncThunk(
  "/auth/master-data/profileMasterData",
  async (body) => {
    const data1 = await axiosInstance.post(`/auth/master-data`, body);
    return data1.data;
  }
);
export const saveLocationData = createAsyncThunk(
  "saveLocationData/therapist/therapistCoverageArea",
  async (body) => {
    const data1 = await axiosInstance.post(`/user/therapistCoverageArea`, body);
    return data1.data;
  }
);

export const professionalExperience = createAsyncThunk(
  "professionalExperience/",
  async (body) => {
    const data1 = await axiosInstance.post(`/user/therapistCoverageArea`, body);
    return data1.data;
  }
);

export const addProfessionalExperienceData = createAsyncThunk(
  "addProfessiomalExperienceData/therapist/therapistProfessionalData",
  async (body) => {
    const data1 = await axiosInstance.post(
      `/user/therapistProfessionalData`,
      body
    );

    return data1.data;
  }
);

export const addOtherTherapistField = createAsyncThunk(
  "addOtherTherapistField/therapist/addMasterData",
  async (body) => {
    const data1 = await axiosInstance.post(`/user/addMasterData`, body);
    return data1.data;
  }
);

export const userTermsCondition = createAsyncThunk(
  "user/termsConditions/userTermsCondition",
  async (request) => {
    const data1 = await axiosInstance.post(`user/termsConditions`, request);
    return data1.data;
  }
);

export const getDefaultRates = createAsyncThunk(
  "getDefaultRates/getDefaultRates",
  async (data) => {
    const data1 = await axiosInstance.post(`user/getRateTabData`, { types: data });
    return data1.data;
  }
);

export const userRateCreate = createAsyncThunk(
  "userRateCreate/user/userRateCreate",
  async (data) => {
    const data1 = await axiosInstance.post("user/userRateCreate", data);
    return data1.data;
  }
);

export const updatePendingSteps = createAsyncThunk(
  "updatePendingSteps",
  async (body) => {
    const data1 = await axiosInstance.post(`auth/update-pending-steps`, body);
    return data1.data;
  }
);

export const userRejected = createAsyncThunk("userRejected", async (body) => {
  const data1 = await axiosInstance.post(`user/rejectedData`, body);
  return data1.data;
});

export const getUserByRejectToken = createAsyncThunk(
  "getUserByRejectToken",
  async (id) => {
    const data1 = await axiosInstance.get(`user/getUserByRejectToken/${id}`);
    return data1.data;
  }
);

export const setDocuSignEnvelope = createAsyncThunk(
  "setDocuSignEnvelope",
  async (payload) => {
    const data1 = await axiosInstance.post(`user/setDocuSignEnvelope`, payload);
    return data1.data;
  }
);

export const getEnvelopDetail = createAsyncThunk(
  "getEnvelopDetail",
  async (body) => {
    const data1 = await axiosInstance.post(`/docusign/getEnvelopDetail`, body);
    return data1.data;
  }
);

export const getTherapistLocationData = createAsyncThunk(
  "getTherapistLocationData/auth/user-detail",
  async (id) => {
    const data1 = await axiosInstance.get(`auth/therapist-coverage/${id} `);
    return data1.data;
  }
);

export const getTherapistDetailsById = createAsyncThunk(
  "getTherapistDetailsById/auth/user-detail/",
  async (id) => {
    const data1 = await axiosInstance.get(`auth/user-detail/${id}`);
    return data1.data;
  }
);
export const getBlockedUsersByRole = createAsyncThunk(
  "/staffing/user-block-list",
  async (payload) => {
    const data1 = await axiosInstance.post(`staffing/getBlock-list`, payload);
    return data1.data;
  }
);

export const getAllListToBlock = createAsyncThunk(
  "getAllListToBlock/List",
  async (payload) => {
    const data1 = await axiosInstance.post(
      `/staffing/user-block-list`,
      payload
    );
    return data1.data;
  }
);

export const setBlockedUser = createAsyncThunk(
  "setBlockedUser/ ",
  async (payload) => {
    const data1 = await axiosInstance.post(`/staffing/addto-block`, payload);
    return data1;
  }
);

export const unblockUser = createAsyncThunk(
  "unblockUser/therapist",
  async (payload) => {
    const data1 = await axiosInstance.post(
      `/staffing/unblocked-list/${payload.id}`
    );
    return data1;
  }
);

export const updateCoverageArea = createAsyncThunk(
  "updateCoverageArea/user/therapistCoverageUpdate",
  async (payload) => {
    const data1 = await axiosInstance.post(
      `user/therapistCoverageUpdate`,
      payload
    );
    return data1;
  }
);

export const saveOnboardingForm = createAsyncThunk("OnboardingForm/Step2", async (body) => {
  const apiData = await axiosInstance.post(`user/therapistOnBoardingPdf`, body)
  return apiData.data
})