import React from 'react'

const WarningPromptModal = ({ handleTab, handleClose, className, triggerSaveChanges }) => {
    return (
        <div className='warning_prompt_container AuthorizationsTabs text-center my-3'>
            <div>
                <h2> Do you want to save changes?</h2>
            </div>
            <div className=" common-btns-div d-flex  gap-3 mt-4">
                <button
                    type="submit"
                    className={`Approve-btns`}
                    onClick={triggerSaveChanges}
                >
                    Save Changes
                </button>
                <button
                    type="button"
                    className={`cancel-btns`}
                    onClick={handleTab}
                >
                    Discard Changes
                </button>
            </div>
        </div>
    )
}

export default WarningPromptModal
