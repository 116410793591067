import Modal from "react-bootstrap/Modal";
import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../../components/Loader";

export const MyModal2 = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let rowKey = searchParams.get("rowkey");
    if (rowKey) {
      navigate(location.pathname);
    }
    let approveKey = searchParams.get("approvekey");
    if (approveKey) {
      navigate(location.pathname);
    }
  }, []);

  return (
    <>
      {props.cross ? (
        <Modal
          size={props.size}
          dialogClassName={props.dialogClassName}
          className={props.modalClass}
          show={props.show}
          centered={props.centered}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header
            className="closeButton border-0"
            closeButton
          ></Modal.Header>
          <Modal.Body className="p-0">{props.data}</Modal.Body>
        </Modal>
      ) : (
        <Modal
          size={props.size}
          dialogClassName={props.dialogClassName}
          className={props.modalClass}
          show={props.show}
          onHide={props.handleClose}
          centered={props.centered}
          aria-labelledby="example-custom-modal-styling-title"
        >
          {props.iframeLoad && <Loader />}

          <Modal.Header
            className="closeButton border-0"
            closeButton
          ></Modal.Header>
          <Modal.Body className="p-0">{props.data}</Modal.Body>
        </Modal>
      )}
    </>
  );
};
