import React, { useEffect } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useDispatch, useSelector } from "react-redux";
import { profileMasterData } from "../../../Redux/actions/therapist";

const ProfileNotification = (props) => {
  const dispatch = useDispatch();

  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );

  useEffect(() => {
    dispatch(profileMasterData({ types: ["preferences"] }));
  }, [dispatch]);

  const requiredArray = (value) => {
    if (value) {
      if (
        value &&
        value[0] &&
        value[0].isTextMessage === false &&
        value[0].isEmail === false
      ) {
        return "Please select at least one option";
      } else return undefined;
    }
  };

  function formatter(name) {
    switch (name) {
      case "NewReferrals":
        return "New Referral";
      case "CaseManagement":
        return "Case Management";
      case "Documentation":
        return "Documentation";
      case "PatiantCommunications/Activity":
        return "Patient Communications/Activity";
      case "DirectMessages":
        return "Direct Messages";
      case "CredentialExpirations":
        return `Credential Expirations`;
      case "TextMessages":
        return "Text Messages";

      default:
        break;
    }
  }

  return (
    <div className="container-fluid pl-0 pt-2 ">
      {props.formValues &&
        props.formValues.notificationKeys &&
        props.formValues.notificationKeys.map((item, index) => {
          return (
            <FieldArray
              name={item}
              key={`${item}_${index}`}
              validate={requiredArray}
            >
              {({ fields, meta }) => {
                return fields.map((name) => (
                  <div className={`row  p-0 align-items-center mb-4 ${!PrefillDetails?.Role?.key ===
                    "supervisingtherapist" ? "mt-3" : ""}`} >
                    <div
                      className="col-sm-4 mt-auto mb-auto
                    "
                    >
                      <h6 className="np-it-h6 ">
                        {formatter(item)}
                        {/* {item === "CredentialExpirations" && (
                          <sup className="sup-point fw-bold" style={{ color: "#eb3b3b", fontSize: "20px", }}>
                            *
                          </sup>
                        )} */}
                      </h6>
                    </div>
                    <div className="  col-sm-8 mobileWindow d-flex gap-60">
                      <label className="container-card align-items-center d-flex padding-left-ref ref-height ">
                        <Field name={`${name}.isTextMessage`} type="checkbox">
                          {({ input }) => (
                            <input   {...input} />
                          )}
                        </Field>

                        <span className=" checkmark1"></span>
                        <div className="ml-3">
                          Text messages
                        </div>
                      </label>

                      <label className="container-card align-items-center d-flex padding-left-ref ref-height ">

                        <Field name={`${name}.isEmail`} type="checkbox">
                          {({ input }) => (
                            <input   {...input} />
                          )}
                        </Field>


                        <span className=" checkmark1"></span>
                        <div className="ml-3">
                          Email
                        </div>


                      </label>
                      {meta.error && meta.touched && (
                        <p className="alert alert-danger  mobile_block-display" role="alert">
                          {meta.error}
                        </p>
                      )}
                    </div>

                    <div className="col-12">
                      {meta.error && meta.touched && (
                        <p className="alert alert-danger desktop_block-display " role="alert">
                          {meta.error}
                        </p>
                      )}
                    </div>
                  </div>
                ));
              }}
            </FieldArray>
          );
        })}
    </div >
  );
};
export default ProfileNotification;
