import React, { useEffect, useState } from "react";
import { Modal, ModalHeader } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePatientDocument,
  getDefaultDocTypes,
  getPatientDocTypes,
  getUserDocumentsList,
} from "../../Redux/actions/patientGetDocTypes";
import { useParams } from "react-router-dom";
import DocumentModal from "../../pages/Patients/DocumentModal";
import { currentRole, decodeData, defaultStatus } from "../../utils/auth";
import { toast } from "react-toastify";
import { HiOutlineDocumentDuplicate, HiPlus } from "react-icons/hi";
import Loader from "../../components/Loader";
import { apiBasePath } from "../../Redux/config/config";
import { therapistImageRoute } from "../../utils/directoryroute";
import { getTabActiveStatus } from "../../Redux/actions/common";
import { updateStaffingDetailsById } from "../../Redux/actions/staffing";
import { sortPatientDocBasedOnDocType } from "../../utils/helper";

const PatientDocTabs = (props) => {
  const { staffUserId } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const params = useParams();

  const [deletionShow, setDeletionShow] = useState(false);

  const deletionCloseHandler = () => setDeletionShow(false);

  const [docData, setDocData] = useState({});
  const dispatch = useDispatch();
  const documentList = useSelector((e) => e?.patientGetDocTypesSlice?.doclist);
  const isLoading = useSelector((e) => e?.patientGetDocTypesSlice?.isLoading);
  const staffTabDetails = useSelector((e) => e?.commonSlice?.staffTabDetails);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);

  const deletionShowHandler = (item) => {
    if (item && item.file) {
      setDocData(item);
      setDeletionShow(true);
    }
  };

  const defaultUploadHandler = () => {
    setDocData({});
    handleShow();
  };

  const dateformatter = (data) => {
    let formattedDate = "-";
    if (data !== "-") {
      const inputDate = new Date(data);
      const options = { year: "numeric", month: "short", day: "2-digit" };
      formattedDate = inputDate.toLocaleDateString("en-US", options);
    }
    return formattedDate;
  };

  const documentData = (documemtRow) => {
    setDocData(documemtRow);
    handleShow();
  };

  const deletionModalHandler = (value) => {
    if (value && docData) {
      dispatch(deletePatientDocument(Number(docData.id))).then((e) => {
        if (e.payload.success) {
          toast.success("Successfully deleted.");
          deletionCloseHandler();
          dispatch(
            updateStaffingDetailsById(
              staffUserId ? props.staffUserId : decodeData(params.id)
            )
          );
          dispatch(
            getTabActiveStatus({
              userId: staffUserId ? Number(staffUserId) : decodeData(params.id),
            })
          );
          dispatch(
            getUserDocumentsList({
              userId: staffUserId ? staffUserId : decodeData(params.id),
            })
          ).then((e) => {
            if (
              e?.payload?.documents?.rows &&
              e?.payload?.documents?.rows?.length <= 0
            ) {
              dispatch(getPatientDocTypes());
            }
          });
        }
      });
    } else {
      deletionCloseHandler();
    }
  };
  const isUploadingDoc = useSelector(
    (data) => data?.patientGetDocTypesSlice?.isUploadingDoc
  );

  const documentView = (docObject) => {
    if (!docObject.file) return;
    const url = `${apiBasePath}documents/patient/${docObject?.file}`;
    window.open(url, "_blank").focus();
  };
  const doctype = useSelector(
    (data) => data?.patientGetDocTypesSlice?.defaultList
  );
  useEffect(() => {
    dispatch(getDefaultDocTypes());
  }, [dispatch]);
  return (
    <div>
      {isLoading && <Loader />}
      <div className="  mx-0 p-0 mb-3">
        <div className="patient_document_sec">
          <div className="upload_document">
            {show && (
              <div className="modal_div">
                <Modal
                  className="upload-document-modal"
                  show={show}
                  onHide={handleClose}
                  animation={false}
                >
                  {isUploadingDoc && (
                    <div className="DocTabs-position">
                      <Loader />
                    </div>
                  )}
                  <ModalHeader closeButton>
                    <Modal.Title className="upload__madal-title">
                      Upload Document
                    </Modal.Title>
                  </ModalHeader>
                  <Modal.Body className="pt-0 upload-commn-padding">
                    <DocumentModal
                      docData={docData}
                      handleClose={handleClose}
                      listRefresh={props.listRefresh}
                      staffUserId={staffUserId}
                    />
                  </Modal.Body>
                </Modal>
              </div>
            )}
            {deletionShow && (
              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                show={deletionShow}
                onHide={deletionCloseHandler}
                animation={false}
              >
                <ModalHeader closeButton></ModalHeader>
                <Modal.Body>
                  <>
                    <div className="cancel_modal pb-5">
                      <p>Are you sure you want to delete this document</p>
                      <div className="check_btns">
                        <div className="radio_btns">
                          <label className="container-2 align-items-center d-flex padding-left-ref ref-height ">
                            <input
                              type="radio"
                              name="fav_language"
                              onChange={() => deletionModalHandler(true)}
                            ></input>
                            <span className=" checkmark-2"></span>
                            <div className="lable-cancel">Yes</div>
                          </label>
                        </div>
                        <div className="radio_btns">
                          <label className="container-2 align-items-center d-flex padding-left-ref ref-height ">
                            <input
                              type="radio"
                              name="fav_language"
                              onChange={() => deletionModalHandler(false)}
                            ></input>
                            <span className=" checkmark-2"></span>
                            <div className="lable-cancel">No</div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                </Modal.Body>
              </Modal>
            )}
          </div>
          <div
            className={`sub_heading mb-1 d-flex justify-content-between align-items-start ${
              currentUser?.Role?.key !== currentRole.admin ? "" : "mb-4"
            }`}
          >
            <h2 className="patient_document-tabs m-0">
              Patient Documents{" "}
              {staffTabDetails?.isDocumentPending && (
                <span className="required_lable">
                  * Required Documents: Facesheet AND 1 of the following; H&P,
                  Progress Notes, Orders for Home Health
                </span>
              )}
            </h2>
            {(currentUser?.Role?.key === currentRole.agency ||
              currentUser?.Role?.key === currentRole.supervisingtherapist ||
              currentUser?.Role?.key === currentRole.assistanttherapist) && (
              <button
                className="AddStaff-discipline"
                onClick={() => defaultUploadHandler()}
              >
                <HiPlus size={24} style={{ marginRight: "5px" }} />
                Upload Document
              </button>
            )}
          </div>

          <table className="mobile_table-responsive-md table pt-0 patient_details document-tab">
            <thead>
              <tr>
                <th scope="col-2">Name</th>
                <th scope="col-2">Uploaded by</th>
                <th scope="col-2">Uploaded on</th>
                <th scope="col-2"></th>
                <th scope="col-2"></th>
                <th scope="col-2"></th>
              </tr>
            </thead>
            {
              <tbody>
                {documentList &&
                documentList?.types &&
                documentList?.types.length > 0
                  ? documentList?.types.map((item, index) => {
                      return (
                        item.displayName !== "Others" && (
                          <tr scope="row" key={item.id}>
                            <td className="col-3">
                              <img
                                className="mx-3"
                                src="./img/Google_Docs_logo_(2014-2020).svg.png"
                                alt=""
                                height="25px"
                              />
                              {item.displayName}{" "}
                              {staffTabDetails?.isDocumentPending && (
                                <span>*</span>
                              )}
                            </td>
                            <td>
                              <img
                                src="./img/abstract-user-flat-4.png"
                                alt=""
                                height="25px"
                                className="uploaded_by"
                              />
                              {item?.uploadedBy ? item.uploadedBy : "-"}
                            </td>
                            <td>{item?.uploadedOn ? item.uploadedOn : "-"}</td>
                            <td></td>
                            <td>
                              <button
                                disabled={true}
                                className="patient_document_rename disabled"
                                onClick={() => documentData(item)}
                              >
                                Replace
                              </button>
                            </td>
                          </tr>
                        )
                      );
                    })
                  : sortPatientDocBasedOnDocType(
                      doctype,
                      documentList?.documents?.rows
                    ).map((item, index) => (
                      <tr scope="row" className="" key={item.id}>
                        <td
                          className="col-3 cursor-pointer"
                          onClick={() => {
                            documentView(item);
                          }}
                        >
                          <span className="Box-table-img">
                            <HiOutlineDocumentDuplicate
                              size={24}
                              color={"#726BCC"}
                            />
                          </span>
                          {item.name
                            ? item.name
                            : item.userDocumentType.displayName}
                          {!item.userDocumentType.isOther
                            ? staffTabDetails?.isDocumentPending && (
                                <span>*</span>
                              )
                            : null}
                        </td>
                        <td>
                          <span className="d-flex align-items-center uploaded_list">
                            {" "}
                            <span className="updated-images-1">
                              {item.file && (
                                <img
                                  src={
                                    item?.uploadedByUser?.image
                                      ? `${apiBasePath}${therapistImageRoute}${item?.uploadedByUser?.image}`
                                      : "https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                                  }
                                  crossOrigin="anonymous"
                                  alt=""
                                  width="25px"
                                  height="25px"
                                  className="uploaded_by"
                                />
                              )}
                            </span>
                            {item.Status &&
                            item.Status.key === defaultStatus.uploaded &&
                            item?.uploadedByUser
                              ? item?.uploadedByUser?.Role?.key ===
                                currentRole.agency
                                ? item.uploadedByUser.name
                                : item.uploadedByUser.lastName +
                                  ", " +
                                  item.uploadedByUser.firstName
                              : "---"}
                          </span>
                        </td>
                        <td>
                          {dateformatter(
                            item?.file && item?.uploadedOn
                              ? item.uploadedOn
                              : "-"
                          )}
                        </td>
                        <td></td>
                        <td>
                          {currentUser?.Role?.key === currentRole.admin ||
                          currentUser?.Role?.key === currentRole.superadmin ? (
                            <button
                              className={`view-btn tr-btn mt-3 mb-3  ${
                                item.file ? "Cancel-red" : "Cancel-red disabled"
                              }`}
                              disabled={!item.file}
                              onClick={() => deletionShowHandler(item)}
                            >
                              Delete
                            </button>
                          ) : null}
                        </td>
                        <td>
                          {!item.userDocumentType.isOther
                            ? currentUser?.Role?.key === currentRole.agency && (
                                <button
                                  disabled={
                                    item.file
                                      ? currentUser?.Role?.key ===
                                        currentRole.agency
                                        ? false
                                        : true
                                      : true
                                  }
                                  className={
                                    item.file
                                      ? currentUser?.Role?.key ===
                                        currentRole.agency
                                        ? "patient_document_rename"
                                        : "patient_document_rename disabled"
                                      : "patient_document_rename disabled"
                                  }
                                  onClick={() => documentData(item)}
                                >
                                  Replace
                                </button>
                              )
                            : item?.uploadedByUser?.Role?.key ===
                                currentUser?.Role?.key && (
                                <button
                                  disabled={
                                    currentUser?.Role?.key ===
                                      currentRole.agency ||
                                    currentUser?.Role?.key ===
                                      currentRole.supervisingtherapist ||
                                    currentUser?.Role?.key ===
                                      currentRole.assistanttherapist
                                      ? false
                                      : true
                                  }
                                  className={
                                    currentUser?.Role?.key ===
                                      currentRole.agency ||
                                    currentUser?.Role?.key ===
                                      currentRole.supervisingtherapist ||
                                    currentUser?.Role?.key ===
                                      currentRole.assistanttherapist
                                      ? "patient_document_rename"
                                      : "patient_document_rename disabled"
                                  }
                                  onClick={() => documentData(item)}
                                >
                                  Rename
                                </button>
                              )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            }
          </table>
        </div>
      </div>
    </div>
  );
};

export default PatientDocTabs;
