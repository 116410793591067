import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import PatientCalender from "./PatientCalender";
import EpisodeModal from "./EpisodeModal";
import { MyModal2 } from "../modals/MyModal2";
import { useDispatch, useSelector } from "react-redux";
import { HiLockClosed } from "react-icons/hi";
import {
  UpdateDocumentationDate,
  getStaffAuth,
  getStaffDocumentation,
  getStafffFrequency,
  updateFrequency,
  updateStaffingSOC,
  updateDocumenationStatus,
  getFrequencySuggestion,
  addNewEpisode,
  getEpisodeDetails,
} from "../../Redux/actions/common";
import Loader from "../../components/Loader";
import { getStaffingDetailsById } from "../../Redux/actions/staffing";
import { useParams } from "react-router-dom";
import AddNewEpisode from "../modals/AddNewEpisode";
import { HiPlus } from "react-icons/hi";
import {
  compareDates,
  defaultCaseStatus,
  displayVisitStatus,
  getLastWednesdayFromDate,
  minimumDate,
  staffFormTypesConstants,
  staffFormStatus,
  visitStatus,
  formSortingArr,
  formNameStr,
} from "../../utils/helper";
import { CommonHead2 } from "../../components/common/CommonHead2";
import AddAuthorisation from "./AddAuthorisation";
import { currentRole, decodeData, defaultStatus } from "../../utils/auth";
import DeclineFrequency from "./DeclineFrequency";
import { toast } from "react-toastify";
import VisitConfirmationModal from "./EvaluationVisitDate";
import {
  getPatientDocs,
  getPatientDocsDetails,
  updateDocumentDate,
} from "../../Redux/actions/patientGetDocTypes";
import AdditionalForms from "../modals/AdditionalForms";
import { AssessmentVisit } from "../modals/AssessmentVisit";
import { AssessmentVisitConfirm } from "../modals/AssessmentVisitConfirm";
import { cleanReduxState } from "../../Redux/slices/commonSlice";

const PatientEpisodeTab = (props) => {
  const { staffUserId } = props;
  const [payloadData, setPayloadData] = useState();
  const [documentDate, setDocumentDate] = useState({});
  const [current, setCurrent] = useState("PT");
  const [documentDetail, setDocumentDetail] = useState(null);
  const params = useParams();
  const dispatch = useDispatch();
  let decodedId;
  if (params.id) {
    decodedId = decodeData(params?.id);
  }

  const [editFrequency, setEditFrequency] = useState(false);
  const [isVisitConfirmLoad, setVisitConfirmLoad] = useState(false);
  const [modal, setModal] = useState({
    showVisitDate: false,
    authorizationsShow: false,
    declineFrequencyShow: false,
    showAddNewEpisodeModal: false,
    showEpisodeModal: false,
    showAdditonalModal: false,
    showModal: false,
    showVisitConfirmModal: false,
  });
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const episodeDetails = useSelector((e) => e.commonSlice.episodeDetails);
  const staffAuth = useSelector((e) => e.commonSlice.staffAuth);
  const staffDocument = useSelector((e) => e.commonSlice.staffDocument);
  const isLoadingdoc = useSelector((e) => e.commonSlice.isLoading);
  const staffFrequency = useSelector((e) => e.commonSlice.staffFrequency);
  const evaluationFormData = useSelector(
    (e) => e.commonSlice.evaluationFormData
  );

  const statusList = useSelector((e) => e.adminSlice.statusList);
  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);
  const isLoading = useSelector((e) => e.staffingSlice.isLoading);
  const frequencySuggestion = useSelector(
    (e) => e.commonSlice.frequencySuggestion
  );
  const staffassignments = useSelector((e) => e.commonSlice.staffassignments);
  const visitDetailById = useSelector(
    (data) => data?.patientGetDocTypesSlice?.visitDetail
  );

  const disciplinesNeeded =
    staffDetail &&
    staffDetail.staffingDetails &&
    staffDetail.staffingDetails.disciplinesNeeded &&
    Object.keys(staffDetail?.staffingDetails?.disciplinesNeeded)[0];

  const [authVisit, setAuthVisit] = useState(null);
  const colors = {
    Completed: { background: "#D9F8DC", color: "#17A325" },
    Incomplete: { background: "#FDEDDC", color: "#CA7718" },
    Pending: { background: "#FDEDDC", color: "#CA7718" },
    Scheduled: { background: "#E8E6FF", color: "#4A40B4" },
    "To Be Scheduled": { background: "#E8E6FF", color: "#4A40B4" },
    "Awaiting-Auth": { background: "#E8E6FF", color: "#4A40B4" },
  };

  const [AuthorizationsTabs, setAuthorizationsTabs] = useState([]);
  const buttons = ["PT"];

  const dateFormat = (dateString) => {
    let formattedDate;
    const dateObject = new Date(dateString);
    formattedDate = dateObject.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return formattedDate;
  };

  const dateFormatter = (val) => {
    let updatedDate;
    if (val) {
      updatedDate = new Date(val);
      var dd = updatedDate.getDate();
      var mm = updatedDate.getMonth() + 1;
      var yyyy = updatedDate.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      updatedDate = yyyy + "-" + mm + "-" + dd;
    }
    return updatedDate;
  };

  const authorizationsClick = (e) => {
    if (e) setAuthVisit(e);
    if (!e) setAuthVisit(null);
    setModal({ ...modal, authorizationsShow: true });
  };

  const showVisitDateClose = () => {
    setModal({ ...modal, showVisitDate: false });
  };
  const authorizationsClose = () => {
    setModal({ ...modal, authorizationsShow: false });
  };

  const declineFrequencyHandler = () => {
    setModal({ ...modal, declineFrequencyShow: true });
  };
  const declineFrequencyClose = () => {
    setModal({ ...modal, declineFrequencyShow: false });
  };
  const handleAddNewEpisodeModalClose = () => {
    setModal({ ...modal, showAddNewEpisodeModal: false });
  };
  const handleAddNewEpisodeModalshow = () => {
    setModal({ ...modal, showAddNewEpisodeModal: true });
  };
  const additonModalFrom = () => {
    setModal({ ...modal, showAdditonalModal: true });
  };
  const additonModalFromClose = () => {
    setModal({ ...modal, showAdditonalModal: false });
  };
  const episodeModalClose = () => {
    setModal({ ...modal, showEpisodeModal: false });
  };
  const handleClose = () => {
    setModal({ ...modal, showModal: false });
  };
  const handleSubmit = (values) => {
    let temp = { ...values };
    temp.staffUserId = staffDetail && staffDetail?.id;
    if (values["Hhasoc-status"] === "no" && values.anticipatedSocDate) {
      temp["Hhasoc-status"] = false;
      temp.anticipatedSocDate = values.anticipatedSocDate;
    } else {
      if (values["Hhasoc-status"] === "yes" && values.completiondate) {
        temp["Hhasoc-status"] = true;
        temp.completiondate = values.completiondate;
      }
    }
    dispatch(updateStaffingSOC(temp)).then((e) => {
      if (e.payload.success) {
        dispatch(
          getStaffingDetailsById(Number(staffUserId ? staffUserId : decodedId))
        ).then((f) => {
          toast.success("Successfully updated.");
          props.IsUpdated();
        });
      }
    });
  };

  const handleDateChange = (e, id, episodeId) => {
    const { name, value } = e.target;

    setDocumentDate((prevInputValues) => ({
      ...prevInputValues,
      [id]: {
        ...prevInputValues[id],
        [name]: value,
      },
    }));

    let temp = {};

    temp.id = id;
    temp.episodeId = episodeId;
    temp.date = value;

    setPayloadData(temp);
  };

  const updateStaffDocument = () => {
    if (
      payloadData &&
      Object.values(payloadData) &&
      Object.values(payloadData).length > 0
    ) {
      dispatch(UpdateDocumentationDate(payloadData)).then((e) => {
        dispatch(
          getStaffDocumentation({
            staffUserId: Number(staffUserId ? staffUserId : decodedId),
          })
        ).then((e) => {
          setPayloadData();
        });
      });
    }
  };

  const discardStaffDocument = () => {
    setDocumentDate({});
    setPayloadData();
  };

  const frequencyInitialValues = () => {
    let initial = {};
    if (
      staffFrequency &&
      staffFrequency.isFrequency &&
      staffFrequency.data.totalVisit &&
      staffFrequency.data.frequency
    ) {
      initial.approvedVisit = staffFrequency.data.totalVisit;
      initial.approvedFrequency = staffFrequency.data.frequency;
      initial.disciplinesNeeded = disciplinesNeeded.toLocaleUpperCase();
      initial.mrn = staffDetail.staffingDetails.mrn;
      initial.therapistEmail = staffassignments[0].therapist.email;
      initial.therapistId = staffassignments[0].therapistId;
      initial.name =
        staffassignments[0].therapist.firstName +
        " " +
        staffassignments[0].therapist.lastName;
    }
    if (
      staffFrequency &&
      !staffFrequency.isFrequency &&
      (staffUserId ? staffUserId : decodedId) == evaluationFormData?.staffId &&
      evaluationFormData &&
      evaluationFormData.formData
    ) {
      initial.frequency = evaluationFormData.formData.Frequency2;
      initial.totalVisit = evaluationFormData.formData.Frequency1;
      initial.disciplinesNeeded = disciplinesNeeded.toLocaleUpperCase();
      initial.mrn = staffDetail.staffingDetails.mrn;
      initial.therapistEmail = staffassignments[0].therapist.email;
      initial.therapistId = staffassignments[0].therapistId;
      initial.name =
        staffassignments[0].therapist.firstName +
        " " +
        staffassignments[0].therapist.lastName;
    } else if (
      staffFrequency &&
      staffFrequency.isFrequency &&
      evaluationFormData &&
      evaluationFormData.formData
      // &&
      // Number(evaluationFormData.formData.Frequency1) !==
      //   staffFrequency.data.totalVisit
    ) {
      initial.frequency = evaluationFormData.formData.Frequency2;
      initial.totalVisit = evaluationFormData.formData.Frequency1;
      initial.disciplinesNeeded = disciplinesNeeded.toLocaleUpperCase();
      initial.mrn = staffDetail.staffingDetails.mrn;
      initial.therapistEmail = staffassignments[0].therapist.email;
      initial.therapistId = staffassignments[0].therapistId;
      initial.name =
        staffassignments[0].therapist.firstName +
        " " +
        staffassignments[0].therapist.lastName;
    }

    return initial;
  };

  const frequencyHandler = (values) => {
    let temp = {};
    temp.totalVisit = editFrequency
      ? Number(values.approvedVisit)
      : Number(values.totalVisit);
    temp.frequency = editFrequency
      ? values.approvedFrequency
      : values.frequency;
    temp.disciplinesNeeded = disciplinesNeeded.toLocaleUpperCase();
    temp.mrn = staffDetail.staffingDetails.mrn;
    temp.therapistEmail = staffassignments[0].therapist.email;
    temp.therapistId = staffassignments[0].therapistId;
    temp.name =
      staffassignments[0].therapist.firstName +
      " " +
      staffassignments[0].therapist.lastName;
    temp.episodeId = episodeDetails.rows[0]["id"];
    temp.userId = Number(staffUserId ? staffUserId : decodedId);

    if (
      (currentUser &&
        currentUser.Role &&
        currentUser.Role.key &&
        currentUser.Role.key === currentRole.agency &&
        values.approvedFrequency &&
        values.approvedVisit) ||
      (values.frequency && values.totalVisit)
    ) {
      dispatch(updateFrequency(temp)).then((e) => {
        if (e.payload.success) {
          setEditFrequency(false);
          dispatch(
            getStaffAuth({
              userId: Number(staffUserId ? staffUserId : decodedId),
              episodeId: Number(episodeDetails.rows[0]["id"]),
            })
          );
          dispatch(
            getStafffFrequency({
              episodeId: Number(episodeDetails.rows[0]["id"]),
            })
          );
          dispatch(
            getStaffDocumentation({
              staffUserId: Number(staffUserId ? staffUserId : decodedId),
            })
          );
        }
      });
    }
  };
  const addStaffEpisode = (values) => {
    let newEpisodeObject = {
      startDate: values.episodeStartDate,
      endDate: values.episodeEndDate,
      staffUserId: Number(staffUserId ? staffUserId : decodedId),
    };
    dispatch(addNewEpisode(newEpisodeObject)).then((res) => {
      handleAddNewEpisodeModalClose();
      dispatch(
        getEpisodeDetails({
          staffUserId: staffUserId
            ? Number(staffUserId)
            : decodeData(params.id),
        })
      );
    });
  };

  const statusHandler = (status, row) => {
    let temp = {};
    if (row.date) {
      let price = 0;
      if (staffDetail) {
        price = staffDetail.userRates.find((item) =>
          item.rateTabDetail.displayName
            .toLowerCase()
            .includes(row.episodeVisit.displayName.toLowerCase())
        )?.price;
      }
      temp.id = row.id;
      temp.episodeId = Number(episodeDetails.rows[0]["id"]);
      temp.status = status;
      temp.staffUserId = staffUserId ? staffUserId : decodedId;
      if (status === defaultStatus.completed) temp.price = Number(price);

      dispatch(updateDocumenationStatus(temp)).then((e) => {
        if (e.payload.success) {
          dispatch(
            getStaffDocumentation({
              staffUserId: Number(staffUserId ? staffUserId : decodedId),
            })
          );
        }
      });
    }
  };

  const disbleDocumentVisitRow = (row) => {
    let isDisable = false;
    if (currentUser && currentUser.Role && currentUser.Role.key) {
      if (
        (currentUser.Role.key === currentRole.admin ||
          currentUser.Role.key === currentRole.superadmin) &&
        (row.Status.key === visitStatus.tobescheduled ||
          row.Status.key === visitStatus.awaitingauth)
      ) {
        isDisable = true;
      } else if (
        currentUser.Role.key === currentRole.agency &&
        (row.Status.key === visitStatus.awaitingauth ||
          row.Status.key === visitStatus.tobescheduled) &&
        row?.episodeVisit?.displayName !== staffFormTypesConstants.Assessment
      ) {
        isDisable = true;
      } else if (
        row.Status.key === visitStatus.awaitingauth &&
        (currentUser.Role.key === currentRole.assistanttherapist ||
          currentUser.Role.key === currentRole.supervisingtherapist)
      ) {
        isDisable = true;
      }
    } else {
      isDisable = true;
    }

    if (
      row?.episodeVisit?.displayName === staffFormTypesConstants.Assessment &&
      currentUser.Role.key === currentRole.agency
    ) {
      if (
        staffDocument &&
        staffDocument?.length > 0 &&
        staffDocument[0]?.episodeDocumentations
      ) {
        let previousVisit = staffDocument[0]?.episodeDocumentations.find(
          (ass) =>
            ass.episodeVisit?.displayName === staffFormTypesConstants.Assessment
        );
        let lastWednesday = getLastWednesdayFromDate(previousVisit?.date);
        let isDateSame = compareDates(new Date(), lastWednesday);
        if (isDateSame < 0) {
          isDisable = true;
        }
      }
    }
    if (
      checkAssessmentLocked(row) &&
      (currentUser.Role.key === currentRole.supervisingtherapist ||
        currentUser.Role.key === currentRole.assistanttherapist)
    ) {
      isDisable = true;
    }
    return isDisable;
  };

  const checkBorderColor = (row) => {
    let isColor = false;
    if (
      row?.episodeVisit?.displayName === staffFormTypesConstants.Assessment &&
      currentUser.Role.key === currentRole.agency
    ) {
      if (
        staffDocument &&
        staffDocument?.length > 0 &&
        staffDocument[0]?.episodeDocumentations
      ) {
        let previousVisit = staffDocument[0]?.episodeDocumentations.find(
          (ass) =>
            ass.episodeVisit?.displayName === staffFormTypesConstants.Assessment
        );
        let lastWednesday = getLastWednesdayFromDate(previousVisit?.date);
        let isDateSame = compareDates(new Date(), lastWednesday);
        if (isDateSame >= 0) {
          isColor = true;
        }
      }
    }
    return isColor;
  };

  const handleDocumentVisit = (row, from) => {
    if (currentUser && currentUser.Role && currentUser.Role.key) {
      if (
        (currentUser.Role.key === currentRole.admin ||
          currentUser.Role.key === currentRole.superadmin) &&
        (row.Status.key === visitStatus.tobescheduled ||
          row.Status.key === visitStatus.awaitingauth)
      ) {
        return;
      } else if (
        currentUser.Role.key === currentRole.agency &&
        (row.Status.key === visitStatus.awaitingauth ||
          row.Status.key === visitStatus.tobescheduled) &&
        row?.episodeVisit?.displayName !== staffFormTypesConstants.Assessment
      ) {
        return;
      } else if (
        row.Status.key === visitStatus.awaitingauth &&
        (currentUser.Role.key === currentRole.assistanttherapist ||
          currentUser.Role.key === currentRole.supervisingtherapist)
      ) {
        return;
      }
    } else {
      return;
    }

    if (
      row?.episodeVisit?.displayName === staffFormTypesConstants.Assessment &&
      currentUser.Role.key === currentRole.agency
    ) {
      if (
        staffDocument &&
        staffDocument?.length > 0 &&
        staffDocument[0]?.episodeDocumentations
      ) {
        let previousVisit = staffDocument[0]?.episodeDocumentations.find(
          (ass) =>
            ass.episodeVisit?.displayName === staffFormTypesConstants.Assessment
        );
        let lastWednesday = getLastWednesdayFromDate(previousVisit?.date);
        let isDateSame = compareDates(new Date(), lastWednesday);
        if (isDateSame < 0) {
          return;
        }
      }
    }
    if (
      checkAssessmentLocked(row) &&
      (currentUser.Role.key === currentRole.supervisingtherapist ||
        currentUser.Role.key === currentRole.assistanttherapist)
    ) {
      return;
    }
    // dispatch(getStaffingDetailsById(row.episodeStaff.staffUserId));
    // dispatch(getStaffDocumentation({ staffUserId: row.episodeStaff.staffUserId }));
    if (
      row?.episodeVisit?.displayName === staffFormTypesConstants.Assessment &&
      currentUser.Role.key === currentRole.agency
    ) {
      setModal({
        ...modal,
        showModal: true,
      });
    } else if (
      row?.episodeVisit?.displayName === staffFormTypesConstants.Assessment &&
      from !== "visitConfirm" &&
      (currentUser.Role.key === currentRole.supervisingtherapist ||
        currentUser.Role.key === currentRole.assistanttherapist) &&
      row.Status.key === visitStatus.scheduled
    ) {
      setModal({
        ...modal,
        showVisitConfirmModal: true,
      });
    } else if (row.date) {
      setDocumentDetail({
        ...row,
        episodeId: episodeDetails.rows[0].id,
        // staffUserId: episodeDetails.id,
      });
      dispatch(getPatientDocsDetails({ episodeDocumentId: row?.id })).then(
        (res) => {
          if (res.payload.success) {
            if (res.payload.data.isDateUpdated) {
              if (
                row?.episodeVisit?.displayName ===
                  staffFormTypesConstants.Assessment &&
                from !== "visitConfirm" &&
                (currentUser.Role.key === currentRole.supervisingtherapist ||
                  currentUser.Role.key === currentRole.assistanttherapist)
              ) {
                if (row.Status.key === visitStatus.scheduled) {
                  setModal({
                    ...modal,
                    showVisitConfirmModal: true,
                  });
                } else {
                  setModal({
                    ...modal,
                    showVisitConfirmModal: false,
                    showVisitDate: true,
                  });
                }
              } else {
                setModal({
                  ...modal,
                  showVisitConfirmModal: false,
                  showEpisodeModal: true,
                  // showModal: true
                });
              }
            } else {
              setModal({
                ...modal,
                showVisitConfirmModal: false,
                showVisitDate: true,
              });
            }
          }
        }
      );
    }
  };
  const updateConfirmDate = (values, from) => {
    let formName = "";
    formNameStr.map((item) => {
      if (
        visitDetailById &&
        visitDetailById.patientFormList &&
        visitDetailById.patientFormList.length > 0
      ) {
        const findName = visitDetailById.patientFormList.find(
          (data) =>
            data.name.toLowerCase().replace(/ /g, "") ===
            item.toLowerCase().replace(/ /g, "")
        );
        if (findName) {
          if (!formName) {
            formName = findName.name;
          }
        }
      }
    });
    let payload = {
      date: values?.evaluationVisitDate,
      id: documentDetail?.id,
      staffUserId: values.staffUserId,
      visitType: values.staffVisitType,
      name: staffDetail.createdByUser.name,
      mrnNumber: staffDetail.staffingDetails.mrn,
      agencyEmailId: staffDetail.createdByUser.email,
      formName: formName,
    };
    dispatch(updateDocumentDate(payload)).then((res) => {
      if (res && res.payload && res.payload.success) {
        dispatch(
          getStaffDocumentation({
            staffUserId: staffUserId ? staffUserId : decodedId,
          })
        );
        dispatch(getPatientDocs({ episodeDocumentId: documentDetail?.id }));
        if (visitDetailById.Status.key === visitStatus.tobescheduled) {
          statusHandler(visitStatus.scheduled, documentDetail);
        }

        if (
          visitDetailById &&
          visitDetailById.StaffDocumentDatas &&
          visitDetailById.StaffDocumentDatas.length > 0 &&
          from === "datemodal"
        ) {
          setModal({
            ...props.setShowState,
            showEpisodeModal: false,
            showVisitDate: false,
            showVisitConfirmModal: true,
          });
        } else {
          setModal({
            ...props.setShowState,
            showEpisodeModal: true,
            showVisitDate: false,
          });
        }
      }
      return { documentDetail };
    });
  };

  const getFrequency = (inputValue, episodeId) => {
    dispatch(
      getFrequencySuggestion({
        episodeId: episodeDetails?.rows[0]["id"],
        userId: staffDetail.id,
        visit: Number(inputValue),
      })
    );
  };

  const authourisationDisabled = () => {
    let disabled = true;
    if (
      currentUser &&
      currentUser.Role &&
      currentUser.Role.key &&
      currentUser.Role.key === currentRole.agency
    ) {
      disabled = false;
    } else {
      disabled = true;
      return true;
    }

    if (staffAuth && staffAuth.length > 0) {
      disabled = true;
    }
    return disabled;
  };

  const checkIfAnyNeedReview = () => {
    let isAnyNeedReview = false;

    for (let index = 0; index < staffDocument.length; index++) {
      const element = staffDocument[index];
      for (
        let index = 0;
        index < element.episodeDocumentations.length;
        index++
      ) {
        const element2 = element.episodeDocumentations[index];
        if (element2.Status.key === defaultStatus.needreview) {
          isAnyNeedReview = true;
          break;
        }
      }
    }
    return isAnyNeedReview;
  };
  const frequencyAprroveButton = () => {
    let disabled = true;

    if (
      currentUser &&
      currentUser.Role &&
      currentUser.Role.key &&
      currentUser.Role.key === currentRole.agency
    ) {
      disabled = false;
    } else {
      disabled = true;
      return true;
    }

    if (
      staffFrequency &&
      !staffFrequency.isFrequency &&
      evaluationFormData &&
      evaluationFormData.formData
    ) {
      disabled = false;
    } else {
      disabled = true;
    }
    if (editFrequency) {
      disabled = false;
    }

    return disabled;
  };
  const priorVisitDate = (id) => {
    const currentDate = new Date();
    let priorVisit = 0;
    let date = currentDate.toISOString().split("T")[0];
    if (
      staffDocument &&
      staffDocument?.length > 0 &&
      staffDocument[0]?.episodeDocumentations.length > 0
    ) {
      const index = staffDocument[0]?.episodeDocumentations.findIndex(
        (element) => element.id === id
      );
      if (index === -1) {
        priorVisit = 0;
      } else if (index === 0) {
        priorVisit = 0;
      } else {
        priorVisit = index - 1;
      }
      if (index === 0) {
        date = minimumDate(
          staffDetail &&
            staffDetail.staffInitialVisit.length > 0 &&
            staffDetail.staffInitialVisit[0].completionDate
        );
      } else {
        date = minimumDate(
          staffDocument[0]?.episodeDocumentations[priorVisit]["date"]
        );
      }
    }
    return date;
  };

  const poitnerEventsClass = (values, pristine) => {
    let poitnerEventNone = true;
    if (values.totalVisit && values.frequency) {
      poitnerEventNone = false;
    }
    if (values.approvedVisit && values.approvedFrequency && !pristine) {
      poitnerEventNone = false;
    }
    if (
      currentUser &&
      currentUser.Role &&
      currentUser.Role.key &&
      currentUser.Role.key !== currentRole.agency
    ) {
      poitnerEventNone = true;
    }
    return poitnerEventNone;
  };

  useEffect(() => {
    let authTabs = [];
    if (staffassignments && staffassignments.length > 0) {
      authTabs = staffassignments.map((ele) => {
        return {
          lable: ele.therapist.userDetailss[0]["discipline"].toUpperCase(),
          name: `${ele.therapist.lastName}, ${ele.therapist.firstName}`,
        };
      });
    }
    setAuthorizationsTabs(authTabs);
  }, [JSON.stringify(staffassignments)]);

  const approveDeclineBtnHide = () => {
    let hide = true;
    if (
      currentUser &&
      currentUser.Role.key === currentRole.agency &&
      // staffAuth.length > 0 &&
      ((staffFrequency &&
        !staffFrequency.isFrequency &&
        evaluationFormData &&
        evaluationFormData.formData) ||
        editFrequency)
    ) {
      hide = false;
    }

    if (!staffAuth || staffAuth.length === 0) {
      hide = true;
    }

    return hide;
  };

  const checkAssessmentLocked = (row) => {
    let isLocked = false;
    if (row?.episodeVisit?.displayName === staffFormTypesConstants.Assessment) {
      if (!row.StaffDocumentDatas || row.StaffDocumentDatas.length === 0) {
        isLocked = true;
      } else {
        isLocked = false;
      }
    }

    return isLocked;
  };

  const handleVisitConfirm = (episodeDocumentId) => {
    setVisitConfirmLoad(true);
    setModal({ ...modal, showVisitConfirmModal: false });
    setTimeout(() => {
      let lastVisitIndex = staffDocument[0].episodeDocumentations.findIndex(
        (item) => item.id === Number(episodeDocumentId)
      );
      if (lastVisitIndex > -1) {
        handleDocumentVisit(
          staffDocument[0].episodeDocumentations[lastVisitIndex],
          "visitConfirm"
        );
      }
    }, 1000);
    setVisitConfirmLoad(false);
  };

  useEffect(() => {
    return () => {
      dispatch(cleanReduxState({ evaluationFormData: true }));
    };
  }, []);
  return (
    <div className="container-fluid">
      <div className="row">
        {(isLoading || isLoadingdoc || isVisitConfirmLoad) && <Loader />}
        <div className="col-md-12">
          {staffDetail?.staffInitialVisit &&
          staffDetail.staffInitialVisit.length > 0 &&
          staffDetail.staffInitialVisit[0].anticipatedSocDate &&
          !staffDetail.staffInitialVisit[0].completionDate ? (
            <Form
              onSubmit={handleSubmit}
              validate={(values) => {
                let error = {};
                if (!values["Hhasoc-status"]) {
                  error["Hhasoc-status"] = "Please select at least one option.";
                }
                if (
                  values["Hhasoc-status"] === "no" &&
                  !values.anticipatedSocDate
                ) {
                  error["anticipatedSocDate"] =
                    "Please select anticipated SOC date.";
                }
                if (
                  values["Hhasoc-status"] === "yes" &&
                  !values.completiondate
                ) {
                  error["completiondate"] = "Please select completion Date.";
                }
                return error;
              }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} className="m-5">
                  <div className="cancel_modal">
                    <p className="accept-modal-p1">Is the HHA SOC completed?</p>
                    <div className="check_btns postion-relative">
                      <Field
                        type="radio"
                        name="Hhasoc-status"
                        value="yes"
                        className="custom-control-input"
                      >
                        {({ input, meta }) => (
                          <div className="radio_btns">
                            <input
                              className="agen-reject-check"
                              {...input}
                              disabled={
                                currentUser &&
                                currentUser.Role &&
                                currentUser.Role.key &&
                                currentUser.Role.key !== currentRole.agency
                              }
                            />
                            <label htmlFor="html">Yes</label>
                          </div>
                        )}
                      </Field>

                      <Field
                        name="Hhasoc-status"
                        value="no"
                        className="custom-control-input"
                        type="radio"
                      >
                        {({ input, meta }) => (
                          <>
                            <div className="radio_btns">
                              <input
                                className="agen-reject-check"
                                {...input}
                                disabled={
                                  currentUser &&
                                  currentUser.Role &&
                                  currentUser.Role.key &&
                                  currentUser.Role.key !== currentRole.agency
                                }
                              />
                              <label htmlFor="html">No</label>
                            </div>
                            {meta.touched && meta.error && (
                              <span
                                className="error_absolute alert alert-danger py-1 w-50"
                                style={{ color: "red" }}
                              >
                                {meta.error}
                              </span>
                            )}
                          </>
                        )}
                      </Field>
                    </div>

                    <div className="row mt-5 d-flex justify-content-center">
                      <div className="col-md-4">
                        {values["Hhasoc-status"] === "yes" && (
                          <Field name="completiondate">
                            {({ input, meta }) => (
                              <>
                                <label>HHA SOC completion date</label>
                                <input
                                  className="w-100 p-3 "
                                  style={{ height: "46px" }}
                                  type="date"
                                  disabled={
                                    currentUser &&
                                    currentUser.Role &&
                                    currentUser.Role.key &&
                                    currentUser.Role.key !== currentRole.agency
                                  }
                                  {...input}
                                  max={minimumDate(new Date())}
                                  placeholder="DD/MM/YYYY"
                                />
                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger error-font mb-3 py-1 ">
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        )}

                        {values["Hhasoc-status"] === "no" && (
                          <Field name="anticipatedSocDate">
                            {({ input, meta }) => (
                              <>
                                <label>Anticipated SOC Date</label>
                                <input
                                  className="w-100 p-3"
                                  style={{ height: "46px" }}
                                  type="date"
                                  {...input}
                                  min={minimumDate(new Date())}
                                  placeholder="DD/MM/YYYY"
                                />
                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger mb-3 error-font py-1 ">
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        )}
                      </div>
                    </div>

                    {currentUser &&
                      currentUser.Role &&
                      currentUser.Role.key &&
                      currentUser.Role.key === currentRole.agency && (
                        <div className="row">
                          <div>
                            <div className="mt-5">
                              <button className="cancel-btn-1" type="submit">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </form>
              )}
            />
          ) : (
            staffDetail?.staffInitialVisit[0]?.completionDate && (
              <>
                {episodeDetails &&
                  episodeDetails.rows &&
                  episodeDetails.rows.length > 0 &&
                  episodeDetails.rows.map((e) => {
                    return (
                      <div className="accrordian-head mobile_wrap mb-5  ">
                        <div className="input-group gap-4 Episode_group_form_input_group">
                          <div className="episode_title">
                            <h4 className="mb-0">{e.name.toUpperCase()}</h4>
                          </div>
                          <div>
                            <span className="input_field_form_item">
                              {dateFormat(e.startDate)}
                            </span>
                            <span className="input_between_span">-</span>
                            <span className="input_field_form_item">
                              {dateFormat(e.endDate)}
                            </span>
                          </div>
                        </div>
                        {currentUser &&
                          currentUser.Role &&
                          currentUser.Role.key &&
                          (currentUser.Role.key === currentRole.agency ||
                            currentUser.Role.key === currentRole.admin) && (
                            <button
                              className="AddStaff-discipline w-fit-content"
                              onClick={() => handleAddNewEpisodeModalshow()}
                            >
                              <HiPlus
                                size={24}
                                style={{ marginRight: "5px" }}
                              />
                              Add new Episode
                            </button>
                          )}
                      </div>
                    );
                  })}
                <div style={{ padding: "0 20px" }}>
                  <p className="card-titel1 patient_chat-head">
                    Authorizations
                  </p>
                  <div className="AuthorizationsTabs">
                    <CommonHead2 count="d-none" data={AuthorizationsTabs} />
                    <div className="patient-card m-0 mobile_width ">
                      {staffAuth &&
                        staffAuth.length > 0 &&
                        staffAuth.map((e, index) => {
                          return (
                            <div className="patient-card-div mb-3  flex-wrap">
                              <div className=" mobile_width_100 desktop_width_25 ">
                                {index == 0 && (
                                  <p className="patient-card-span  mb-0">
                                    VISITS
                                  </p>
                                )}
                                <input
                                  type="text"
                                  className={`AuthorizationsTabs-inputs w-100 ${
                                    e.Status.key === defaultStatus.pending
                                      ? "AuthorizationsTabs-border-red"
                                      : staffFrequency
                                      ? "AuthorizationsTabs-border-green"
                                      : "AuthorizationsTabs-border-black"
                                  }`}
                                  value={
                                    e.noAuth
                                      ? "No Auth"
                                      : `${e.visit} ${
                                          e.Status.key === defaultStatus.pending
                                            ? "visits Pending Auth"
                                            : "Authorized visits"
                                        }`
                                  }
                                />
                              </div>
                              {e.startDate && (
                                <div className=" mobile_width_100 desktop_width_25 ">
                                  {index == 0 && (
                                    <p className="patient-card-span  mb-0">
                                      START DATE
                                    </p>
                                  )}
                                  <input
                                    type="date"
                                    className="AuthorizationsTabs-inputs w-100"
                                    value={e.startDate}
                                    disabled
                                  />
                                </div>
                              )}
                              {e.endDate && (
                                <div className=" mobile_width_100 desktop_width_25 ">
                                  {index == 0 && (
                                    <p className="patient-card-span  mb-0">
                                      END DATE
                                    </p>
                                  )}
                                  <input
                                    type="date"
                                    className="AuthorizationsTabs-inputs w-100"
                                    value={e.endDate}
                                    disabled
                                  />
                                </div>
                              )}

                              {currentUser &&
                                currentUser.Role &&
                                currentUser.Role.key &&
                                (currentUser.Role.key === currentRole.agency ||
                                  currentUser.Role.key === currentRole.admin) &&
                                e.Status.key === defaultStatus.approved &&
                                !e.noAuth && (
                                  <div className=" mobile_width_100 desktop_width_25 ">
                                    <button
                                      type="submit"
                                      className="btn tr-btn view-btn btn-pte h-auto"
                                      onClick={() => authorizationsClick(e)}
                                      disabled={
                                        !evaluationFormData &&
                                        !evaluationFormData?.formData
                                          ?.Frequency1
                                          ? true
                                          : false
                                      }
                                    >
                                      Edit
                                    </button>
                                  </div>
                                )}
                            </div>
                          );
                        })}
                      <button
                        onClick={() => authorizationsClick()}
                        disabled={authourisationDisabled()}
                        className={`bg-white border-0 text-decoration-underline Authorisation-btn ${
                          authourisationDisabled()
                            ? "disable-btn-class"
                            : "text-primary"
                        }`}
                      >
                        Add Authorization{" "}
                      </button>

                      {modal.authorizationsShow && (
                        <MyModal2
                          dialogClassName={"AuthorizationsTabs-dialog"}
                          modalClass={"AuthorizationsTabs-Modal"}
                          show={modal.authorizationsShow}
                          handleClose={authorizationsClose}
                          data={
                            <AddAuthorisation
                              initial={authVisit}
                              episodeDetails={episodeDetails}
                              userId={staffUserId ? staffUserId : decodedId}
                              handleClose={authorizationsClose}
                              totalVisit={
                                staffFrequency?.data?.totalVisit
                                  ? staffFrequency.data.totalVisit
                                  : evaluationFormData &&
                                    evaluationFormData?.formData?.Frequency1
                                  ? evaluationFormData.formData.Frequency1
                                  : null
                              }
                            />
                          }
                        />
                      )}
                      <Form
                        keepDirtyOnReinitialize
                        onSubmit={frequencyHandler}
                        initialValues={frequencyInitialValues}
                        render={({
                          handleSubmit,
                          values,
                          form,
                          pristine,
                          initialValues,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            {staffFrequency && staffFrequency.isFrequency && (
                              <div className="patient-card-div  align-items-center">
                                <div className="w-100 d-flex gap-3">
                                  <div className="AuthorizationsTabs-inputs-select-input-margin mobile_width_100 desktop_width_25 ">
                                    <p className="patient-card-span  mb-0">
                                      Approved Visits
                                    </p>
                                    <div className=" mobile_width">
                                      <Field name="approvedVisit">
                                        {({ input, meta }) => (
                                          <div>
                                            <input
                                              {...input}
                                              className="AuthorizationsTabs-inputs w-100 form-control AuthorizationsTabs-border-green"
                                              placeholder="Approved Total Visits"
                                              type="text"
                                              disabled
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                  <div className=" mobile_width_100 desktop_width_25 ">
                                    <p className="patient-card-span  mb-0">
                                      Approved Frequency
                                    </p>
                                    <div className=" mobile_width">
                                      <Field name="approvedFrequency">
                                        {({ input, meta }) => (
                                          <div>
                                            <select
                                              disabled={!editFrequency}
                                              {...input}
                                              className="AuthorizationsTabs-inputs w-100 form-control AuthorizationsTabs-border-green"
                                            >
                                              <option value="">
                                                Visit Frequency (DROP-DOWN)#
                                              </option>
                                              {frequencySuggestion &&
                                              frequencySuggestion.initialEvaluationFreq &&
                                              frequencySuggestion
                                                .initialEvaluationFreq.length >
                                                0 &&
                                              frequencySuggestion.initialEvaluationFreq.find(
                                                (item) =>
                                                  item.frequency ===
                                                  meta.initial
                                              )
                                                ? null
                                                : initialValues.approvedFrequency ===
                                                    values.approvedFrequency && (
                                                    <option
                                                      value={meta.initial}
                                                    >
                                                      {meta.initial}
                                                    </option>
                                                  )}
                                              {frequencySuggestion &&
                                                frequencySuggestion.initialEvaluationFreq &&
                                                frequencySuggestion
                                                  .initialEvaluationFreq
                                                  .length > 0 &&
                                                frequencySuggestion.initialEvaluationFreq.map(
                                                  (freq, index) => (
                                                    <option
                                                      key={`frequency-${freq.frequency}`}
                                                      value={freq.frequency}
                                                    >
                                                      {freq.frequency}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                          </div>
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                  <div className=" mobile_width_100 desktop_width_25 mt-4">
                                    {currentUser &&
                                      currentUser.Role &&
                                      currentUser.Role.key &&
                                      (currentUser.Role.key ===
                                        currentRole.agency ||
                                        currentUser.Role.key ===
                                          currentRole.admin) &&
                                      !editFrequency && (
                                        <div className=" mobile_width_100 desktop_width_25 ">
                                          <button
                                            className="btn tr-btn view-btn btn-pte h-auto"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setEditFrequency(true);
                                              getFrequency(
                                                values.approvedVisit
                                              );
                                            }}
                                          >
                                            Edit
                                          </button>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="patient-card-div  align-items-center">
                              <div className="w-100 d-flex gap-3">
                                <div className=" mobile_width_100 desktop_width_25 ">
                                  <p className="patient-card-span  mb-0">
                                    Proposed Visits
                                  </p>

                                  <Field name="totalVisit">
                                    {({ input, meta }) => (
                                      <div className=" mobile_width">
                                        <input
                                          className="AuthorizationsTabs-inputs w-100"
                                          placeholder="Proposed Total Visits"
                                          {...input}
                                          type="text"
                                          disabled
                                        />
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className="AuthorizationsTabs-inputs-select-input-margin mobile_width_100 desktop_width_25 ">
                                  <p className="patient-card-span  mb-0">
                                    Proposed FREQUENCY
                                  </p>
                                  <Field name="frequency">
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          {...input}
                                          className="AuthorizationsTabs-inputs w-100"
                                          placeholder="Proposed Frequency"
                                          disabled
                                        />
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="common-btns-div d-flex">
                                {!approveDeclineBtnHide() && (
                                  <button
                                    type="button"
                                    className={`cancel-btns ${
                                      poitnerEventsClass(values, pristine)
                                        ? "pe-none"
                                        : "nope"
                                    }`}
                                    onClick={declineFrequencyHandler}
                                    disabled={frequencyAprroveButton()}
                                  >
                                    Decline
                                  </button>
                                )}

                                {!approveDeclineBtnHide() && (
                                  <button
                                    type="submit"
                                    className={`Approve-btns ${
                                      poitnerEventsClass(values, pristine)
                                        ? "pe-none"
                                        : "nope"
                                    }`}
                                    disabled={frequencyAprroveButton()}
                                  >
                                    Approve
                                  </button>
                                )}

                                {modal.declineFrequencyShow && (
                                  <MyModal2
                                    dialogClassName={
                                      "AuthorizationsTabs-dialog"
                                    }
                                    modalClass={"AuthorizationsTabs-Modal"}
                                    show={modal.declineFrequencyShow}
                                    handleClose={declineFrequencyClose}
                                    data={
                                      <DeclineFrequency
                                        userId={
                                          staffUserId ? staffUserId : decodedId
                                        }
                                        handleClose={declineFrequencyClose}
                                      />
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </form>
                        )}
                      />
                    </div>
                  </div>
                </div>
                {/* <div style={{ padding: "0 20px" }}>
                  <h5 className="card-titel1 mt-4">Patient Schedule</h5>
                  <div className="Patient-calender">
                    <PatientCalender
                      staffDetails={episodeDetails && episodeDetails.rows[0]}
                      visitDetails={
                        staffDocument &&
                        staffDocument?.length > 0 &&
                        staffDocument[0]?.episodeDocumentations
                      }
                    />
                  </div>
                </div> */}
                <div style={{ padding: "0 20px" }}>
                  <h5 className="card-titel1 mt-4">Documentation</h5>
                  <div className="rates-bg">
                    <div>
                      <div className="mobile_wrap d-flex justify-content-between">
                        <div>
                          {buttons.map((e, ind) => {
                            return (
                              <React.Fragment key={`${e}_${ind}`}>
                                <button
                                  className={` mr-3 ${
                                    current === e
                                      ? "therapistBtn active"
                                      : "defaultBtn"
                                  }`}
                                  onClick={(e) =>
                                    setCurrent(e.target.innerText)
                                  }
                                >
                                  {e}
                                </button>
                              </React.Fragment>
                            );
                          })}
                        </div>

                        <div className="mt-4 d-flex gap-5">
                          {payloadData &&
                            Object.values(payloadData) &&
                            Object.values(payloadData).length > 0 && (
                              <div>
                                <Button
                                  className="Discard-danger"
                                  variant="outline-danger"
                                  onClick={discardStaffDocument}
                                >
                                  Discard
                                </Button>
                                <button
                                  className="border-0 text-white primary-confirm"
                                  onClick={updateStaffDocument}
                                >
                                  Confirm
                                </button>
                              </div>
                            )}

                          <button
                            className="AddStaff-discipline w-fit-content"
                            onClick={() => additonModalFrom()}
                          >
                            <HiPlus size={24} style={{ marginRight: "5px" }} />
                            Additional Forms
                          </button>
                        </div>
                      </div>
                      <div className="mt-3 documentaionTable">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th
                                className="th-table-pt-1"
                                style={{ paddingLeft: "35px" }}
                              >
                                DATE
                              </th>
                              <th
                                className="th-table-pt-1"
                                style={{ paddingLeft: "10px" }}
                              >
                                VISIT TYPE
                              </th>
                              <th
                                className="th-table-pt-1"
                                style={{ paddingLeft: "10px" }}
                              >
                                THERAPIST
                              </th>
                              <th
                                className="th-table-pt-1"
                                style={{ paddingLeft: "15px" }}
                              >
                                STATUS
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {staffDocument &&
                              staffDocument?.length > 0 &&
                              staffDocument[0]?.episodeDocumentations?.map(
                                (row, ind) => {
                                  return (
                                    <React.Fragment
                                      key={`${row.therapist}_${ind}`}
                                    >
                                      <tr
                                        key={ind}
                                        style={{
                                          background: disbleDocumentVisitRow(
                                            row
                                          )
                                            ? "#eeeeee"
                                            : "",
                                        }}
                                        className={`${
                                          row.episodeVisit.displayName ===
                                            staffFormTypesConstants.Assessment &&
                                          currentUser?.Role?.key ===
                                            currentRole.agency &&
                                          checkBorderColor(row)
                                            ? "last-border"
                                            : ""
                                        }`}
                                      >
                                        {row.date ? (
                                          <td
                                            className="td-name"
                                            style={{
                                              background:
                                                disbleDocumentVisitRow(row)
                                                  ? "#eeeeee"
                                                  : "",
                                            }}
                                          >
                                            <input
                                              id="lable-002"
                                              className={`${
                                                disbleDocumentVisitRow(row)
                                                  ? "border-0  text-black text-center"
                                                  : "border-0 bg-white text-black text-center"
                                              }`}
                                              style={{
                                                background:
                                                  disbleDocumentVisitRow(row)
                                                    ? "#eeeeee"
                                                    : "",
                                              }}
                                              disabled={
                                                currentUser?.Role?.key !==
                                                  currentRole.agency ||
                                                row?.Status?.key !==
                                                  defaultCaseStatus?.tobescheduled ||
                                                (payloadData &&
                                                  Object.values(payloadData) &&
                                                  Object.values(payloadData)
                                                    .length > 0) ||
                                                disbleDocumentVisitRow(row)
                                              }
                                              min={dateFormatter(row.date)}
                                              name={`date_${row.id}`}
                                              value={
                                                documentDate[row.id]?.[
                                                  `date_${row.id}`
                                                ]
                                                  ? documentDate[row.id]?.[
                                                      `date_${row.id}`
                                                    ]
                                                  : row?.date &&
                                                    dateFormatter(row.date)
                                              }
                                              type="date"
                                              onChange={(e) =>
                                                handleDateChange(
                                                  e,
                                                  row.id,
                                                  row.episodeId
                                                )
                                              }
                                            />
                                          </td>
                                        ) : (
                                          <td
                                            className="td-name"
                                            style={{
                                              background:
                                                disbleDocumentVisitRow(row)
                                                  ? "#eeeeee"
                                                  : "",
                                            }}
                                          ></td>
                                        )}

                                        {row.episodeVisit.displayName && (
                                          <>
                                            <td
                                              // onClick={() => setShow(true)}
                                              onClick={() => {
                                                handleDocumentVisit(row, ind);
                                              }}
                                              style={{
                                                background:
                                                  disbleDocumentVisitRow(row)
                                                    ? "#eeeeee"
                                                    : "",
                                                cursor: !disbleDocumentVisitRow(
                                                  row
                                                )
                                                  ? "pointer"
                                                  : "auto",
                                              }}
                                              className="td-name1 "
                                            >
                                              {row.episodeVisit.displayName ===
                                              staffFormTypesConstants.Assessment
                                                ? row.discipline.toUpperCase()
                                                : ""}{" "}
                                              &nbsp;
                                              {row.episodeVisit.displayName}
                                              {row.episodeVisit.displayName ==
                                                staffFormTypesConstants.Assessment &&
                                                (currentUser.Role.key ===
                                                  currentRole.supervisingtherapist ||
                                                  currentUser.Role.key ===
                                                    currentRole.assistanttherapist) &&
                                                checkAssessmentLocked(row) && (
                                                  <HiLockClosed
                                                    size={22}
                                                    color={"#09365B"}
                                                  />
                                                )}
                                            </td>
                                          </>
                                        )}
                                        {row.userData && (
                                          <td
                                            className="td-name"
                                            style={{
                                              background:
                                                disbleDocumentVisitRow(row)
                                                  ? "#eeeeee"
                                                  : "",
                                            }}
                                          >
                                            {row.userData.lastName +
                                              ", " +
                                              row.userData.firstName +
                                              " " +
                                              `(${row.discipline.toUpperCase()})`}
                                          </td>
                                        )}

                                        {row.Status && (
                                          <td
                                            style={{
                                              background:
                                                disbleDocumentVisitRow(row)
                                                  ? "#eeeeee"
                                                  : "",
                                            }}
                                          >
                                            <select
                                              disabled={true}
                                              style={{
                                                background:
                                                  disbleDocumentVisitRow(row)
                                                    ? "#eeeeee"
                                                    : "",
                                              }}
                                              // disabled={currentUser?.Role?.key === currentRole.supervisingtherapist || currentUser?.Role?.key === currentRole.assistanttherapist}
                                              onChange={(e) =>
                                                statusHandler(
                                                  e.target.value,
                                                  row
                                                )
                                              }
                                            >
                                              {statusList &&
                                                statusList.length > 0 &&
                                                statusList.map((e) => {
                                                  return (
                                                    <option
                                                      key={`Status_${e.key}`}
                                                      selected={
                                                        e.key === row.Status.key
                                                      }
                                                      value={e.key}
                                                    >
                                                      {e.name &&
                                                      displayVisitStatus[e.key]
                                                        ? displayVisitStatus[
                                                            e.key
                                                          ]
                                                        : e.name}
                                                    </option>
                                                  );
                                                })}
                                            </select>
                                            {/* <p
                                              className="td-end m-0"
                                              style={{
                                                background: `${
                                                  colors[row.Status.name]
                                                    .background
                                                }`,
                                                color: `${
                                                  colors[row.Status.name].color
                                                }`,
                                              }}
                                            >
                                              {row.Status.name}
                                            </p> */}
                                          </td>
                                        )}
                                      </tr>
                                    </React.Fragment>
                                  );
                                }
                              )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </div>
      {modal.showAddNewEpisodeModal && (
        <AddNewEpisode
          showAddNewEpisodeModal={modal.showAddNewEpisodeModal}
          handleAddNewEpisodeModalClose={handleAddNewEpisodeModalClose}
          staffDetail={staffDetail?.staffInitialVisit[0]}
          addStaffEpisode={addStaffEpisode}
        />
      )}
      {modal.showEpisodeModal && (
        <MyModal2
          size="lg"
          modalClass={"Main_modal_1"}
          show={modal.showEpisodeModal}
          handleClose={episodeModalClose}
          data={
            <EpisodeModal
              visitDetail={documentDetail}
              priorVisitDate={priorVisitDate}
              episodeModalClose={episodeModalClose}
              updateConfirmDate={updateConfirmDate}
              staffUserId={staffUserId ? staffUserId : decodedId}
              statusHandler={statusHandler}
            />
          }
        />
      )}
      {modal.showVisitDate && (
        <MyModal2
          dialogClassName={"AuthorizationsTabs-dialog"}
          modalClass={"AuthorizationsTabs-Modal"}
          show={modal.showVisitDate}
          handleClose={showVisitDateClose}
          data={
            <VisitConfirmationModal
              visitDetail={documentDetail}
              priorVisitDate={priorVisitDate}
              staffUserId={staffUserId}
              modalClose={showVisitDateClose}
              setModalState={modal}
              setModal={setModal}
              updateConfirmDate={updateConfirmDate}
              episodeModalClose={episodeModalClose}
            />
          }
        />
      )}
      {modal.showAdditonalModal && (
        <AdditionalForms
          showAdditonalModal={modal.showAdditonalModal}
          handleClose={additonModalFromClose}
          staffDocument={staffDocument[0]?.episodeDocumentations}
          staffUserId={staffUserId}
        />
      )}
      {modal.showModal && (
        <AssessmentVisit
          size="lg"
          showModal={modal.showModal}
          handleClose={handleClose}
          episodeId={
            staffDocument &&
            staffDocument[0]?.episodeDocumentations &&
            staffDocument[0]?.episodeDocumentations[0]?.episodeId
          }
          staffUserId={staffUserId ? staffUserId : decodedId}
        />
      )}

      <AssessmentVisitConfirm
        size="lg"
        showModal={modal.showVisitConfirmModal}
        handleClose={(docId) => handleVisitConfirm(docId)}
        staffDocument={staffDocument}
        staffUserId={staffUserId ? staffUserId : decodedId}
        crossModal={() => {
          setModal({ ...modal, showVisitConfirmModal: false });
          setVisitConfirmLoad(true);
        }}
      />
    </div>
  );
};

export default PatientEpisodeTab;
