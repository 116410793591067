import React from "react";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { currentRole, messageFormatting } from "../../../utils/auth";
import { apiBasePath } from "../../../Redux/config/config";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../../components/common/CommonTable";
import Loader from "../../../components/Loader";
import { CommonHead } from "../../../components/common/CommonHead";
import ReactPaginate from "react-paginate";
import AddToBlockList from "./AddToBlockList";
import { blockTabs } from "../../../utils/helper";
import { AiFillPlusCircle } from "react-icons/ai";
import assistantimg from "../../../images/usertable02.svg";
import agencyimg from "../../../images/usertable01.svg";
import {
  getBlockedUsersByRole,
  unblockUser,
} from "../../../Redux/actions/therapist";
import { setLoader } from "../../../Redux/slices/authSlice";
import { toast } from "react-toastify";
import block_icon from "../../../images/block_icon.svg";
import agency_icon from "../../../images/AdminDashboard/agency_icon.svg";
import assistant_therapist_icon from "../../../images/AdminDashboard/assistant_therapist_icon.svg";
import supervising_therapist_icon from "../../../images/AdminDashboard/supervising_therapist_icon.svg";

export default function BlockList(props) {
  const [pageSize] = useState(5);
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const [menuItem, setMenuItem] = useState("agency");
  const [showAddToBlockModal, setShowAddToBlockModal] = useState(false);
  const [updateTab, setUpdateTab] = useState(false);

  const userList = useSelector((e) => e.therapistSigunpSlice.blockList);
  const isLoading = useSelector((e) => e.therapistSigunpSlice.isLoading);
  const userCount = useSelector((e) => e.therapistSigunpSlice.countDetails);

  const currentUserRole = useSelector(
    (e) => e.adminSlice.currentActiveUserDetails?.Role
  );

  const handleCloseAddToBlockModal = () => setShowAddToBlockModal(false);

  let myImage = {
    supervisingtherapist: assistantimg,
    assistanttherapist: assistantimg,
    agency: agencyimg,
  };
  let roleIcons = {
    supervisingtherapist: supervising_therapist_icon,
    assistanttherapist: assistant_therapist_icon,
    agency: agency_icon,
  };
  useEffect(() => {
    if (updateTab) {
      setUpdateTab(!updateTab);
    }
    dispatch(
      getBlockedUsersByRole({
        pageNo: pageNo,
        pageSize: pageSize,
        tab: blockTabs[menuItem],
      })
    );
  }, [updateTab]);

  const setTabMenus = (tab) => {
    dispatch(setLoader({ loading: true }));
    setMenuItem(tab);
    setPageNo(1);
    dispatch(
      getBlockedUsersByRole({
        pageNo: pageNo,
        pageSize: pageSize,
        tab: blockTabs[tab],
      })
    );
  };

  const handlePageClick = (event) => {
    setPageNo(event.selected + 1);
    dispatch(
      getBlockedUsersByRole({
        pageNo: event.selected + 1,
        pageSize: pageSize,
        tab: blockTabs[menuItem],
      })
    );
  };

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "name":
        return "Name";
      case "Role.name":
        return "User Type";
      case "reason":
        return "Reason";
      default:
        return colName;
    }
  };

  const getRoleDetailCount = (role, status) => {
    let count = 0;
    if (userCount && userCount.length > 0) {
      let user = userCount.find((item) => item.key === role);

      if (user && user.count) {
        count = user.count;
      }
    }
    return count;
  };

  const handleUnblock = (id) => {
    dispatch(
      unblockUser({
        id: id,
      })
    ).then((res) => {
      if (res && res.payload && res.payload.data && res.payload.data.success) {
        toast.success(messageFormatting(res.payload.data.message));
        dispatch(
          getBlockedUsersByRole({
            pageNo: pageNo,
            pageSize: pageSize,
            tab: blockTabs[menuItem],
          })
        );
      }
    });
  };

  const allMenu = [
    {
      label: "Agency ",
      count: Number(getRoleDetailCount(currentRole.agency)),
      key: "agency",
      actions: [
        {
          action: (rowData) => (
            <td>
              <button
                // className="unblock-btn tr-btn ml-auto mt-3 mb-3"
                className="update-btn justify-content-center ml-auto "
                onClick={() => {
                  handleUnblock(rowData.id);
                }}
              >
                Unblock
              </button>
            </td>
          ),
        },
      ],
      columns: ["name", "Role.name", "reason"],
      columnsToHide: ["image"],
      noData: "No data available",
      data: userList,
    },
    {
      label: "Assistant Therapist",
      count: Number(getRoleDetailCount(currentRole.assistanttherapist)),
      key: "assistanttherapist",
      actions: [
        {
          action: (rowData) => (
            <td>
              <button
                // className="unblock-btn tr-btn ml-auto mt-3 mb-3"
                className="update-btn justify-content-center ml-auto "
                onClick={() => {
                  handleUnblock(rowData.id);
                }}
              >
                Unblock
              </button>
            </td>
          ),
        },
      ],
      columns: ["name", "Role.name", "reason"],
      columnsToHide: ["image"],
      noData: "No data available",
      data: userList,
    },
    {
      label: "Supervising Therapist ",
      count: Number(getRoleDetailCount(currentRole.supervisingtherapist)),
      key: "supervisingtherapist",
      actions: [
        {
          action: (rowData) => (
            <td>
              <button
                className="unblock-btn tr-btn ml-auto mt-3 mb-3"
                onClick={() => {
                  handleUnblock(rowData.id);
                }}
              >
                Unblock
              </button>
            </td>
          ),
        },
      ],
      columns: ["name", "Role.name", "reason"],
      columnsToHide: ["image"],
      noData: "No data available",
      data: userList,
    },
  ];

  const renderRowData = (result, col, item) => {
    const userRole = result && result.blockedUsers.Role.key;
    if (col === "name") {
      return (
        <>
          <div className="d-flex align-items-center">
            <div className="position-relative" style={{ marginRight: "5%" }}>
              <img
                src={
                  result?.blockedUsers.image !== null
                    ? `${apiBasePath}documents/therapist/${result?.blockedUsers.image}`
                    : myImage[userRole]
                }
                crossOrigin="acountnymous"
                className="table-img"
                style={{ borderRadius: "50%", objectFit: "cover" }}
                alt="img"
              />
              <img
                className="buuble_botom block_sub_icon cursor-pointer"
                onClick={() => {
                  handleUnblock(result.id);
                }}
                src={
                  block_icon
                } />

            </div>

            <h1 className="title-22">
              {userRole === currentRole.agency
                ? result && result.blockedUsers && `${result.blockedUsers.name}`
                : result &&
                result.blockedUsers &&
                `${result.blockedUsers.lastName}, ${result.blockedUsers.firstName}`}
            </h1>
          </div>
        </>
      );
    }

    if (col === "Role.name") {
      return (
        <>
          <div className="d-flex gap-2 align-items-center">
            <img
              src={
                roleIcons[result &&
                  result.blockedUsers &&
                  result.blockedUsers.Role.key ? result.blockedUsers.Role.key : menuItem]
              }
              crossOrigin="acountnymous"
              className="p-1"
              alt="roleIcons"
            />
            <p className="m-0 displine-list">
              {result &&
                result.blockedUsers &&
                `${result.blockedUsers.Role.title}`}
            </p>
          </div>
        </>
      );
    }
    if (col === "reason") {
      return (
        <>
          <div className="d-flex align-items-center unblock-reason fw-normal">
            <p className="m-0">{result && result.reason && `${result.reason}`}</p>
          </div>
        </>
      );
    }
    return <h1 className=" title-24 mt-3 mb-3"> {result[item]}</h1>;
  };

  const handleAddModal = () => {
    setShowAddToBlockModal(!showAddToBlockModal);
  };
  const menu = allMenu.filter((item) => item.key !== currentUserRole?.key);
  const myActions = menu.find((ele) => ele.key === menuItem);

  return (
    <>
      {isLoading && <Loader />}
      <div className="container-fluid">
        <div className="d-flex justify-content-between my-3 mx-5">
          <h5 className="Block-title">Block-List</h5>

          <button onClick={handleAddModal} className="Add-BlockList-btn">
            <AiFillPlusCircle size={34} /> Add to Block-List
          </button>
        </div>
        <div className="user-type-main tabs_user_type">
          <div className="col-sm-12 pr-5 pl-5">
            <CommonHead
              data={menu}
              setMenu={setTabMenus}
              menuItem={menuItem}
              css={"col-md-3"}
              setPageNo={setPageNo}
            />
          </div>
        </div>

        {userList && userList?.length > 0 ? (
          <Row>
            <div className="inside_table_tabs">
              <Col lg={11}>
                <div className="table-responsive">
                  <CommonTable
                    data={myActions?.data}
                    columns={myActions?.columns}
                    columnsToHide={myActions?.columnsToHide}
                    overrideColumnName={overrideColumnName}
                    actions={myActions?.actions}
                    renderRowData={renderRowData}
                    isLoading={isLoading}
                  />
                </div>
              </Col>
            </div>
          </Row>
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-5">
            <h2 className="fs-5 mt-5 mb-5">No Data Available</h2>
          </div>
        )}

        {userCount > 5 && userList?.length > 0 && (
          <div id="container ">
            <div className="row justify-content-start mt-3  pl-5 ">
              <div className="pl-3 pr-5">
                <ReactPaginate
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={Math.ceil(userCount / pageSize)}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                  forcePage={
                    Math.ceil(userCount / pageSize) === 0 ? -1 : pageNo - 1
                  }
                />
              </div>
            </div>
          </div>
        )}

        {showAddToBlockModal && (
          <AddToBlockList
            showAddToBlockModal={showAddToBlockModal}
            handleCloseAddToBlockModal={handleCloseAddToBlockModal}
            handleAddModal={handleAddModal}
            setTabMenus={setTabMenus}
            updateTab={updateTab}
            setUpdateTab={setUpdateTab}
          />
        )}
      </div>
    </>
  );
}
