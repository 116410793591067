import { GrFormClose } from "react-icons/gr";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import userImage from "../../images/user.png";
import { apiBasePath } from "../../Redux/config/config";
import Loader from "../../components/Loader";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { getAdminUsersList, updateAdminDetails } from "../../Redux/actions/auth";
import { toast } from "react-toastify";
import { createUserSchema } from "../../validation/schemas";
import { validateForm } from "../../validation/validateForm";
import { currentRole, formatUsPhoneNumber } from "../../utils/auth";
import { ImAttachment } from "react-icons/im";
import { phoneNumberToUSFormat } from "../../utils/helper";

function UserDetailModal(props) {
  const user = useSelector((e) => e.adminSlice.currentAdminInfo);
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const isLoading = useSelector((e) => e.adminSlice.isLoading);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);

  const onSubmit = (values) => {
    let formData = new FormData();
    formData.append("file", uploadedImage);
    for (var key in values) {
      formData.append(key, values[key]);
    }
    dispatch(updateAdminDetails(formData)).then((res) => {
      if (res?.payload?.success) {
        toast.success(`${res?.payload?.message}.`);
        dispatch(
          getAdminUsersList({
            pageSize: 10,
            pageNo: 1,
            role: currentRole.admin,
          })
        );
        props.handleClose()
      } else {
        toast.error("There's an error while updating.");
      }
    });
  };
  const validate = validateForm(createUserSchema);

  const initialValues = () => {
    let initialValues = {
      id: user?.id,
      userName: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNo: formatUsPhoneNumber(user?.userDetail?.phone),
      email: user?.email,
    };
    return initialValues;
  };
  function handleChange(e) {
    setUploadedImage(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <>
      <Modal
        className="user-modal"
        show={true}
        onHide={props.handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="d-flex justify-content-end pr-2 pt-2">
          <GrFormClose
            size="30px"
            color="#A1AEB9"
            onClick={props.handleClose}
            cursor="pointer"
          />
        </div>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={() => initialValues()}
          render={({ handleSubmit, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="pl-4 pr-4 pb-1">
                <div className="adminModall">
                  <div className=" d-flex mobile_justify mobile_wrap ">
                    <div>
                      <img
                        className="adminModallCircle p-3"
                        src={
                          file ? file : user && user.image
                            ? `${apiBasePath}documents/${user.image}`
                            : userImage
                        }
                        crossOrigin="anonymous"
                        width={"165px"}
                        alt="img"
                      />
                      {currentUser?.Role?.key === currentRole?.superadmin &&
                        <div>
                          <p className="upload-bbtn-1 m-0">Upload Photo</p>
                          <label className="m-0">
                            Browse files... <ImAttachment />
                            <input
                              type="file"
                              accept="image/png, image/gif, image/jpeg"
                              onChange={handleChange}
                              hidden
                              disabled={currentUser?.Role?.key !== currentRole?.superadmin}
                            />
                          </label>
                        </div>
                      }
                    </div>
                    <div className="">
                      <p
                        className="m-0 admin-user-h2   "
                        style={{ wordBreak: "break-word", fontSize: "1.4rem/35px !important " }}
                      >
                        {user && user.email}
                      </p>
                      <span className="admin-user-p">Admin</span>
                    </div>
                  </div>
                </div>


                <div className="m-41 ">
                  <div className="flex-column d-flex m-31 ">
                    <label className="new-user-lables">First Name</label>
                    <Field name="firstName">
                      {({ input, meta }) => (
                        <div>
                          <input
                            {...input}
                            className="admin-new-user-input color_placeholder"
                            name="firstName"
                            type="text"
                            placeholder="First Name"
                            disabled={currentUser?.Role?.key !== currentRole?.superadmin}

                          />
                          {meta.error && meta.touched && <p className="alert alert-danger">{meta.error}</p>}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="flex-column d-flex m-31 ">
                    <label className="new-user-lables">Last Name</label>
                    <Field name="lastName">
                      {({ input, meta }) => (
                        <div>
                          <input
                            {...input}
                            className="admin-new-user-input color_placeholder"
                            type="text"
                            placeholder="Last Name"
                            disabled={currentUser?.Role?.key !== currentRole?.superadmin}

                          />
                          {meta.error && meta.touched && <p className="alert alert-danger">{meta.error}</p>}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="flex-column d-flex m-31 ">
                    <label className="new-user-lables">Email Address</label>
                    <Field
                      name="email"
                    >
                      {({ input, meta }) => (
                        <div>
                          <input
                            {...input}
                            className="admin-new-user-input color_placeholder"
                            type="email"
                            placeholder="abc@gmail.com"
                            disabled={currentUser?.Role?.key !== currentRole?.superadmin}

                          />
                          {meta.error && meta.touched && <p className="alert alert-danger">{meta.error}</p>}
                        </div>
                      )}

                    </Field>
                  </div>
                  <div className="flex-column d-flex m-31 ">
                    <label className="new-user-lables">Phone Number</label>
                    <Field name="phoneNo">
                      {({ input, meta }) => (
                        <p>
                          <input
                            {...input}
                            className="admin-new-user-input color_placeholder"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onInput={(e) => phoneNumberToUSFormat(e, input)}
                            placeholder="Phone Number"
                            disabled={currentUser?.Role?.key !== currentRole?.superadmin}
                          />
                          {meta.error && meta.touched && <p className="alert alert-danger">{meta.error}</p>}
                        </p>
                      )}
                    </Field>
                  </div>
                </div>
              </div>

              {currentUser?.Role?.key === currentRole?.superadmin && <div className="d-flex">
                <button
                  className=" mx-auto login-button mb-4" type="submit"
                  disabled={uploadedImage ? false : pristine}
                >
                  Update
                </button>
              </div>}
            </form>
          )}
        />
        {isLoading && <Loader />}
      </Modal>
    </>
  );
}
export default UserDetailModal;
