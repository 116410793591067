import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import abc from "../../images/user.png";
import { Button, Modal, ModalHeader } from "react-bootstrap";
import { MyModal2 } from "../modals/MyModal2";
import { MdKeyboardArrowRight } from "react-icons/md";
import PhysicianOrder from "./PatientForms/PhysicianOrder";
import { useDispatch, useSelector } from "react-redux";
import EvaluationForm from "./PatientForms/EvaluationForm";
import { currentRole } from "../../utils/auth";
import NOMNC from "./PatientForms/NOMNC";
import FollowUpForm from "./PatientForms/FollowUpForm";
import Covid19questionnare from "./PatientForms/Covid19quistionnare";
import MissedVisit from "./PatientForms/MissedVisit";
import Discharge from "./PatientForms/Discharge";
import NonVisitDischarge from "./PatientForms/NonVisitDischarge";
import Loader from "../../components/Loader";
import { updateDocumentStatus } from "../../Redux/actions/patientGetDocTypes";
import {
  dateFormatChange,
  displayVisitStatus,
  modifyFormName,
  statusBaseColorChange,
  visitStatus,
} from "../../utils/helper";
import OASISDischarge from "./PatientForms/OASISDischarge";
import { toast } from "react-toastify";
import { apiBasePath } from "../../Redux/config/config";
import { getVisitsByWeeksLeft } from "../../Redux/actions/common";
import { setListRefresh } from "../../Redux/slices/staffingSlice";

function PdfModal(props) {
  const {
    formToShow,
    confirmDate,
    closeFormModal,
    formId,
    visitDetail,
    dataAvailable,
    setToUpdate,
    priorVisitDate,
    IsUpdated,
    statusHandler,
    changeStatus,
    setDataAvailable,
    staffUserId,
    formDetails,
  } = props;
  const dispatch = useDispatch();
  const [show, setShow] = useState();
  const [showModal, setShowModal] = useState();
  const [rejectReason, setRejectReason] = useState("");
  const staffDetails = useSelector((state) => state.staffingSlice.staffDetail);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const fetchingFormData = useSelector(
    (state) => state?.commonSlice?.isLoading
  );
  const staffFormData = useSelector((e) => e.commonSlice.staffForm);
  const visitDetailById = useSelector(
    (data) => data?.patientGetDocTypesSlice?.visitDetail
  );

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (
      currentUser &&
      currentUser.Role &&
      (currentUser.Role.key !== currentRole.admin ||
        currentUser.Role.key !== currentRole.superadmin)
    ) {
      setShow(true);
    }
  };
  const handleModalClose = () => setShowModal(false);

  const handleModal = (e) => {
    if (
      currentUser &&
      currentUser.Role &&
      (currentUser.Role.key === currentRole.agency ||
        currentUser.Role.key === currentRole.admin)
    ) {
      setShowModal(true);
    }
  };

  const handleFormStatus = (status, rejectReason) => {
    if (staffFormData && staffFormData.formData) {
      let temp = {
        staffFormDataId: staffFormData.id,
        episodeDocumentFormId: staffFormData.episodeDocumentFormId,
        status: status,
        episodeDocumentId: visitDetail.id,
        staffUserId: staffUserId,
      };
      if (
        rejectReason &&
        rejectReason.trim() !== "" &&
        status === visitStatus.incompletedoc
      ) {
        temp.reason = rejectReason;
      }
      dispatch(updateDocumentStatus(temp)).then((res) => {
        if (res && res.payload && res.payload.success) {
          handleClose();
          closeFormModal();
          IsUpdated();
          if (status === visitStatus.incompletedoc)
            changeStatus(visitStatus.needcorrection);
        }
        dispatch(setListRefresh(true));
      });
    } else {
      toast.info("Please submit the form first.");
    }
  };
  const Correction = (props) => {
    const { handleFormStatus } = props;
    const [rejectReason, setRejectReason] = useState("");

    return (
      <div className="Needs_Correction">
        <h4>Needs Correction</h4>
        <p>Please add a reason(s) why the document needs correction</p>
        <div className="d-flex flex-column justify-content-center input_tabs">
          <label>Reason</label>
          <textarea
            className="w-100"
            rows="7"
            cols="100"
            placeholder="Please describe."
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
          ></textarea>
        </div>
        <Button
          className="Discard-danger"
          variant="outline-danger"
          onClick={() => handleModalClose()}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="primary-confirm"
          onClick={() =>
            handleFormStatus(visitStatus.incompletedoc, rejectReason)
          }
        >
          Confirm
        </Button>
      </div>
    );
  };
  const formProps = {
    setToUpdate: setToUpdate,
    formDetails: formDetails,
    closeFormModal: closeFormModal,
    priorVisitDate: priorVisitDate,
    visitDetail: visitDetail,
    IsUpdated: IsUpdated,
    formId: formId,
    statusHandler: statusHandler,
    staffUserId: staffUserId,
    dataAvailable: dataAvailable,
    confirmDate: confirmDate,
  };

  const htmlForms = [
    {
      name: "Evaluation",
      key: "Evaluation",
      data: <EvaluationForm {...formProps} confirmDate={confirmDate} />,
    },
    {
      name: "Physician Order",
      key: "Physician Order",
      data: <PhysicianOrder {...formProps} confirmDate={confirmDate} />,
    },
    { name: "Consents", key: "Consents", data: "Consents" },
    {
      name: "Start of Care OASIS",
      key: "Start of Care OASIS",
      data: "Start Of Care OASIS",
    },
    {
      name: "Medication Profile",
      key: "Medication Profile",
      data: " MedicationProfile",
    },
    {
      name: "Re-Assessment",
      key: "Re-Assessment",
      data: (
        <EvaluationForm
          {...formProps}
          isAutoFill={true}
          confirmDate={confirmDate}
        />
      ),
    },
    { name: "NOMNC", key: "NOMNC", data: <NOMNC {...formProps} /> },
    {
      name: "Follow Up",
      key: "Follow Up",
      data: (
        <FollowUpForm
          {...formProps}
          setDataAvailable={setDataAvailable}
          confirmDate={confirmDate}
        />
      ),
    },
    {
      name: "COVID-19 Questionnare",
      key: "COVID-19 QUESTIONNAIRE",
      data: <Covid19questionnare {...formProps} confirmDate={confirmDate} />,
    },
    {
      name: "Missed Visit",
      key: "Missed Visit",
      data: <MissedVisit {...formProps} />,
    },
    {
      name: "Regular Discharge",
      key: "RegularDischarge",
      data: <Discharge {...formProps} confirmDate={confirmDate} />,
    },
    {
      name: "Non-Visit Discharge",
      key: "NON-VISIT DISCHARGE",
      data: <NonVisitDischarge {...formProps} confirmDate={confirmDate} />,
    },
    {
      name: "OASIS Discharge",
      key: "OASISDischarge",
      data: <Discharge {...formProps} confirmDate={confirmDate} />,
    },
  ];

  const activeForm = htmlForms.find(
    (item) => item.key === formToShow || item.name === formToShow
  );

  useEffect(() => {
    if (staffUserId)
      dispatch(getVisitsByWeeksLeft({ staffUserId: staffUserId }));
  }, [staffUserId]);

  const showStatus = () => {
    let status = "";
    if (formDetails && formDetails.Status) {
      status = displayVisitStatus[formDetails.Status.key]
        ? displayVisitStatus[formDetails.Status.key]
        : formDetails.Status.name;
      return status;
    }
    if (
      visitDetailById &&
      visitDetailById.Status &&
      visitDetailById.Status.key &&
      visitDetailById.Status.name &&
      displayVisitStatus[visitDetailById.Status.key] ==
        displayVisitStatus.pendingnotes
    ) {
      status = displayVisitStatus[visitDetailById.Status.key]
        ? displayVisitStatus[visitDetailById.Status.key]
        : visitDetailById.Status.name;
    }
    return status;
  };
  const getRejectedReason = () => {
    let reason = "";

    if (
      staffFormData &&
      staffFormData.isRejected &&
      staffFormData.staffFormRejectReason &&
      staffFormData.staffFormRejectReason.length > 0
    ) {
      let rejectReason = [...staffFormData.staffFormRejectReason];
      let lastEle = rejectReason.pop();
      reason = lastEle.reason;
    }
    return reason;
  };

  return (
    <>
      <div class="container-fluid">
        {fetchingFormData && <Loader />}
        <div class="row justify-content-center   user-row-bar">
          <div class="col-lg-2 mb-3">
            <div className="patient-doc-img">
              <img
                className="userimge1 one"
                crossOrigin="anonymous"
                src={
                  staffDetails && staffDetails.image
                    ? `${apiBasePath}documents/therapist/${staffDetails.image}`
                    : abc
                }
                alt="img"
              />
            </div>
          </div>
          <div class="col-lg-6 mb-3">
            <div className="patient-details next-modal">
              <div className="patient-genreal pt-3">
                <h4>
                  {staffDetails?.lastName + ", " + staffDetails?.firstName}
                </h4>
                <p>
                  MR #
                  {staffDetails &&
                    staffDetails.staffingDetails &&
                    staffDetails.staffingDetails.mrn}
                </p>
              </div>
              <div className="patient-report">
                <div className="patient-report-details">
                  <p>DOCUMENT NAME</p>
                  <p>{modifyFormName(activeForm.name)}</p>
                </div>
                <div className="patient-report-details">
                  <p>VISIT DATE</p>

                  <p>
                    {" "}
                    {confirmDate
                      ? dateFormatChange(confirmDate)
                      : staffDetails &&
                        staffDetails.staffInitialVisit &&
                        staffDetails.staffInitialVisit.length > 0 &&
                        staffDetails.staffInitialVisit[0].anticipatedSocDate &&
                        dateFormatChange(
                          staffDetails.staffInitialVisit[0].anticipatedSocDate.split(
                            "T"
                          )[0]
                        )}
                  </p>
                </div>
                <div className="patient-report-details">
                  <p>VISIT TYPE</p>
                  <p>
                    {" "}
                    {visitDetail &&
                      visitDetail.episodeVisit &&
                      visitDetail.episodeVisit.displayName}
                  </p>
                </div>
                <div className="patient-report-details">
                  <div>
                    <p>THERAPIST NAME</p>
                    <p>
                      {" "}
                      {visitDetail?.userData?.lastName +
                        ", " +
                        visitDetail?.userData?.firstName}{" "}
                      (PT)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mb-3">
            <div className="left_button">
              <div className="button_prent">
                <p>STATUS</p>
                <button
                  className={`d-flex justify-content-center pe-none status-td ${statusBaseColorChange(
                    formDetails && formDetails.Status && formDetails.Status.key
                  )}`}
                >
                  {showStatus()}
                </button>
              </div>
              <div className="button_prent">
                {currentUser &&
                  (currentUser.Role.key === currentRole.agency ||
                    currentUser.Role.key === currentRole.admin ||
                    (staffFormData && staffFormData.isRejected)) && (
                    <p>ACTION</p>
                  )}
                {currentUser &&
                  currentUser.Role &&
                  currentUser?.Role?.key === currentRole?.agency &&
                  formDetails &&
                  formDetails.Status &&
                  formDetails.Status.key !== visitStatus.completed && (
                    <button
                      onClick={handleShow}
                      className={`justify-content-center CompleteAll-color margin-need-complete`}
                      disabled={
                        currentUser?.Role?.key === currentRole?.admin ||
                        currentUser?.Role?.key === currentRole?.superadmin
                      }
                    >
                      Complete
                    </button>
                  )}
                {currentUser &&
                  (currentUser.Role.key === currentRole.agency ||
                    (currentUser.Role.key === currentRole.admin &&
                      staffFormData &&
                      staffFormData.isRejected)) && (
                    <button
                      onClick={() => handleModal()}
                      className={`justify-content-center CompleteAll-color  need_review  
                      ${
                        formDetails &&
                        formDetails.Status &&
                        formDetails.Status.key !== visitStatus.completed
                          ? "margin-need-correction"
                          : "mx-0"
                      }`}
                      disabled={
                        currentUser?.Role?.key === currentRole?.admin ||
                        currentUser?.Role?.key === currentRole?.superadmin
                      }
                    >
                      Need Correction
                    </button>
                  )}
              </div>
            </div>
          </div>
          {staffFormData &&
            staffFormData.isRejected &&
            staffFormData.staffFormRejectReason &&
            staffFormData.staffFormRejectReason.length > 0 && (
              <div class="col-lg-12 mb-3">
                <div>
                  <h6 className="cs-heading  px-0">Reason For Rejection</h6>
                </div>
                <textarea
                  className="discribe-input discribe_text mt-2"
                  type="textarea"
                  placeholder="Describe Other Reason"
                  disabled
                  value={getRejectedReason()}
                />
              </div>
            )}
        </div>
      </div>
      <MyModal2
        dialogClassName="max-width_modal1"
        modalClass="dfghjk"
        show={showModal}
        handleClose={handleModalClose}
        data={<Correction handleFormStatus={handleFormStatus} />}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="pl-4 pr-4 pt-4 pb-3" closeButton>
          <Modal.Title>Complete</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pl-4 pr-4 pt-0 pb-4">
          By confirming this you will mark the {activeForm && activeForm.name}{" "}
          document as completed. Are you sure?
          <div className="mt-4">
            <Button
              className="Discard-danger"
              variant="outline-danger"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="primary-confirm"
              onClick={() => {
                handleFormStatus(
                  currentUser.Role.key === currentRole.agency
                    ? visitStatus.completed
                    : visitStatus.needreview
                );
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <div className="container-fluid">
        <div className="row bg-gray-main">
          <h6 className="Document_list text-align-center">
            {activeForm && modifyFormName(activeForm.name)}
          </h6>
          {activeForm && activeForm.data}
        </div>
      </div>
    </>
  );
}

export default PdfModal;
