import Switch from "react-switch";

export default function Rswitch(props) {
  return (
    <Switch
      className="data"
      checked={props.value}
      disabled={props.disabled}
      onChange={(e) =>
        !props.handleToggle ? props.onChange(e) : props.handleToggle()
      }
      onColor="#C5F6BE"
      onHandleColor="#60D670"
      handleDiameter={30}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      height={20}
      width={68}
    />
  );
}
