import React from "react";
import { Field, Form } from "react-final-form";
import { ImAttachment } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getDefaultDocTypes,
  getUserDocumentsList,
  getAddPatientDoc,
} from "../../Redux/actions/patientGetDocTypes";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { currentRole, decodeData } from "../../utils/auth";
import Select from "react-select";
import { patientDocumentTypes } from "../../utils/helper";
import { getTabActiveStatus } from "../../Redux/actions/common";
import { updateStaffingDetailsById } from "../../Redux/actions/staffing";

const DocumentModal = (props) => {
  const { docData, staffUserId, mainhandleClose } = props;
  const params = useParams();
  const doctype = useSelector(
    (data) => data?.patientGetDocTypesSlice?.defaultList
  );

  const currentUser = useSelector(
    (data) => data?.adminSlice?.currentActiveUserDetails
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDefaultDocTypes());
  }, [dispatch]);
  let final = doctype?.map((e) => {
    return { label: e.displayName, value: e.id };
  });
  const setInitial = () => {
    const initialValues = {};
    initialValues.userId = staffUserId ? staffUserId : decodeData(params.id);
    initialValues.uploadedBy = currentUser.id;

    if (Object.keys(docData).length > 0) {
      initialValues.documentTypeId = {
        label: docData?.userDocumentType?.displayName,
        value: docData?.userDocumentType?.id,
      };
      initialValues.name = docData?.name;
      initialValues.id = docData.id;
    } else {
      if (
        currentUser.Role.key === currentRole.assistanttherapist ||
        currentUser.Role.key === currentRole.supervisingtherapist
      ) {
        initialValues.documentTypeId = {
          label: doctype?.slice(-1)[0].displayName,
          value: doctype?.slice(-1)[0].id,
        };
      } else {
        initialValues.documentTypeId = {
          label: doctype[0]?.displayName,
          value: doctype[0]?.id,
        };
      }
    }

    return initialValues;
  };

  const formValidation = (values) => {
    const errors = {};
    if (!docData?.userDocumentType?.isOther) {
      if (!values.UploadedFile) {
        errors.UploadedFile = "Upload document.";
      } else if (values.UploadedFile[0].type !== "application/pdf") {
        errors.UploadedFile = "Only PDF documents are allowed.";
      } else if ((values.UploadedFile[0].size / 1024).toFixed(2) > 20480) {
        errors.UploadedFile = "File size is too large.";
      }
    }

    if (
      values?.documentTypeId?.label?.toLowerCase() ===
        patientDocumentTypes.others &&
      !values.name
    ) {
      errors.name = "Document name is required.";
    }

    return errors;
  };

  function onSubmit(values) {
    const tempValues = { ...values };
    let formData = new FormData();
    let fileName;
    let DocFile;
    if (tempValues.UploadedFile && tempValues.UploadedFile.length > 0) {
      fileName = tempValues.UploadedFile[0].name;
      DocFile = new File(tempValues.UploadedFile, fileName);
    }
    tempValues.uploadedBy = currentUser.id;
    tempValues.userId = staffUserId ? staffUserId : decodeData(params.id);

    if (Object.keys(docData).length > 0) {
      if (!docData.userDocumentType.isOther) {
        delete tempValues.name;
        delete tempValues.id;
        tempValues.isOther = false;
      } else {
        tempValues.isOther = true;
      }
    } else {
      if (
        tempValues.documentTypeId.label.toLowerCase() ===
        patientDocumentTypes["others"]
      ) {
        tempValues.isOther = true;
      } else {
        tempValues.isOther = false;
      }
    }

    tempValues.documentTypeId = tempValues.documentTypeId.value;
    if (tempValues?.UploadedFile) {
      formData.append("files", DocFile);
    }
    delete tempValues.UploadedFile;
    formData.append("payload", JSON.stringify(tempValues));
    dispatch(getAddPatientDoc(formData)).then((response) => {
      if (response.payload.success) {
        dispatch(
          updateStaffingDetailsById(
            staffUserId ? staffUserId : decodeData(params.id)
          )
        );
        props.handleClose();
        dispatch(
          getTabActiveStatus({
            userId: staffUserId ? staffUserId : decodeData(params.id),
          })
        ).then((res) => {
          if (
            !res.payload.isStaffingPending &&
            !res.payload.isDocumentPending
          ) {
            props.listRefresh && props.listRefresh();
          }
        });
        dispatch(
          getUserDocumentsList({
            userId: staffUserId ? staffUserId : decodeData(params.id),
          })
        );
        toast.success("Document updated successfully.");
      } else {
        toast.error(`${response.payload.message}.`);
      }
    });
  }
  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={() => setInitial()}
        keepDirtyOnReinitialize
        validate={formValidation}
        render={({ values, handleSubmit, submitting, form }) => (
          <form onSubmit={handleSubmit}>
            <Field name="documentTypeId">
              {({ input, meta }) => (
                <>
                  <div className="d-flex">
                    <label className="signup_form_label upload-modal">
                      Select Category
                    </label>
                  </div>
                  <Select
                    {...input}
                    options={final}
                    isDisabled={
                      Object.keys(docData).length === 0
                        ? currentUser?.Role.key ===
                            currentRole?.assistanttherapist ||
                          currentUser.Role.key ===
                            currentRole?.supervisingtherapist
                          ? true
                          : false
                        : true
                    }
                  />
                </>
              )}
            </Field>

            {values?.documentTypeId?.label?.toLowerCase() ===
              patientDocumentTypes.others && (
              <Field name="name">
                {({ input, meta }) => (
                  <div className="d-flex flex-column">
                    <label className="mt-3">Document Name</label>
                    <input
                      className="form-control"
                      {...input}
                      type="text"
                      placeholder="Enter Document Name"
                    />
                    {meta.error && meta.touched && (
                      <p className="alert alert-danger">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>
            )}

            <Field name="UploadedFile">
              {({ input, meta }) => (
                <>
                  <label
                    className={`upload-doc-modal-button ${
                      docData?.userDocumentType?.isOther
                        ? " offUpload-color"
                        : ""
                    } `}
                  >
                    <div className="upload-icon-modal-parent d-flex flex-column justify-content-center">
                      <span className="upload-icon-modal">
                        {" "}
                        <ImAttachment className="m-auto d-flex" />
                      </span>
                      <p
                        className={
                          docData?.userDocumentType?.isOther ? "OffUpload" : ""
                        }
                      >
                        {input?.value[0]?.name
                          ? input.value[0].name
                          : "Click to upload"}
                      </p>
                    </div>
                    <input
                      type="file"
                      onChange={(e) => {
                        input.onChange(e.target.files);
                      }}
                      hidden
                      disabled={docData?.userDocumentType?.isOther}
                    />
                  </label>
                  {meta.error && meta.touched && (
                    <span className="w-100  d-flex alert alert-danger mb-3">
                      {meta.error}
                    </span>
                  )}
                </>
              )}
            </Field>
            <button type="submit" className="submit-btn-upload-doc">
              Save
            </button>
          </form>
        )}
      />
    </>
  );
};

export default DocumentModal;
