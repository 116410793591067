import React, { useState } from "react";
import { MdCall } from "react-icons/md";
import {
  AiOutlineHome,
  AiOutlineUser,
  AiOutlineLock,
  AiOutlineClose,
  AiFillFile,
} from "react-icons/ai";
import { FaFax } from "react-icons/fa";
import { BsCheck2, BsFillCheckCircleFill } from "react-icons/bs";
import { IoCallOutline, IoLocationOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import documentImgs from "../../images/document_icon.svg";

import {
  currentRole,
  decodeData,
  defaultStatus,
  formatPhoneNumber,
  formatUsPhoneNumber,
} from "../../utils/auth";
import Loader from "../../components/Loader";
import {
  getUserDetailsById,
  updateProfileStatus,
} from "../../Redux/actions/auth";
import { getDefaultRates } from "../../Redux/actions/therapist";
import { getUserDetailParams } from "../../utils/helper";
import { toast } from "react-toastify";
import CustomSwitch from "../../components/CustomSwitch";
import { apiBasePath } from "../../Redux/config/config";
import accordian_collapse_icon from "../../images/accordian_collapse_icon.svg";
// import acc from '../../'

const NewAgency = () => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const params = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDefaultRates(currentRole.agency));
    dispatch(
      getUserDetailsById(
        `${decodeData(params.id)}?${getUserDetailParams.secondaryEmails}=true&${getUserDetailParams.representativeEmails
        }=true`
      )
    );
  }, [dispatch, params]);

  let defaultRates = useSelector((e) => e.therapistSigunpSlice.defaultRates);
  const isLoading = useSelector((e) => e.therapistSigunpSlice.isLoading);

  const profileDetail = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );

  function myFun(index) {
    setActiveTab(index);
  }

  const getValues = (key, subkey) => {
    if (!profileDetail) {
      return;
    }
    if (profileDetail[key]) {
      if (typeof profileDetail[key] === "object") {
        if (profileDetail[key][subkey]) {
          return profileDetail[key][subkey];
        } else {
          return "---";
        }
      }
      return profileDetail[key];
    } else {
      return "---";
    }
  };
  const bindEmail = (key) => {
    if (profileDetail && profileDetail[key] && profileDetail[key].length > 0) {
      return profileDetail[key].map((item, index) => {
        return (
          <React.Fragment key={`${item.email}_${index}`}>
            <tr className="switch-tr">
              <td>
                <p className="m-0 details details-email">{item.email}</p>
              </td>
              <td className="switch-p  ">
                <CustomSwitch
                  value={item.intakeStaffing}
                  disabled
                  switchclass="mt-3"
                />
              </td>
              <td className="switch-p">
                <CustomSwitch
                  value={item.caseManagement}
                  disabled
                  switchclass="mt-3"
                />
              </td>
              <td className="switch-p">
                <CustomSwitch
                  value={item.accounting}
                  disabled
                  switchclass="mt-3"
                />
              </td>
            </tr>
          </React.Fragment>
        );
      });
    }
  };

  const getUserRates = (tabId, tabDetailId) => {
    let price = "$0";
    if (
      profileDetail &&
      profileDetail.userRates &&
      profileDetail.userRates.length > 0
    ) {
      let rate = profileDetail.userRates.find(
        (item) =>
          item.rateTabDetailId === tabDetailId && item.rateTabId === tabId
      );
      if (rate) {
        price = rate.price;
      }
    }
    return "$" + price;
  };

  const updateProfile = () => {
    dispatch(
      updateProfileStatus({
        status: defaultStatus.approved,
        userId: decodeData(params.id),
      })
    ).then((response) => {
      toast.success("Agency approved successfully.");
      setTimeout(() => {
        navigate("/newusers");
      }, 500);
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  const handleDocumentShow = (item) => {
    if (
      profileDetail &&
      profileDetail.isConsent &&
      profileDetail.agencyConsent
    ) {
      const url = `${apiBasePath}documents/agencyConsent/${profileDetail.agencyConsent}`;
      window.open(url, "_blank").focus();
    } else {
      toast.warn("There is no document to show!");
    }
  };
  return (
    <>
      <div className="container-fluid p-0 ">
        <div className="div1 agency-details agency-details1 ">
          <div className="container-fluid">
            <div className="row start-19 mobile_justify-start justify-content-start align-items-center position-relative">
              <div className="col-lg-3 mb-4">
                <h6 className="details therapist-name new-therapist-head  ms-3 mb-5">
                  {getValues("name")}
                </h6>
                <div className="agency-div4 mt-5">
                  <div className="icon">
                    <p className="p1 m-0">
                      <MdCall fill="#fff" className="icon-call" />
                    </p>
                    <p className="details-p mb-0 mt-1">
                      {formatUsPhoneNumber(getValues("userDetail", "phone"))}
                    </p>
                  </div>

                  <div className="icon">
                    <p className="p1 m-0 ">
                      <FaFax fill="#fff" className="icon-call" />
                    </p>
                    <p className="details-p mb-0 mt-1">
                      {formatUsPhoneNumber(getValues("userDetail", "fax"))}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-9">
                <div className="container p-0">
                  <div className="row d-flex  ">
                    <div className="col-lg-4">
                      <div className="d-flex mb-4">
                        <AiOutlineHome className="icon-center" />
                        <div className="flex-column">
                          <h6 className="heading-uppercase">USERNAME</h6>
                          <p className="details-p">{getValues("userName")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="d-flex mb-4">
                        <AiOutlineLock className="icon-center" />
                        <div className="flex-column">
                          <h6 className="heading-uppercase">PASSWORD</h6>
                          <p className="details-p">**********</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="d-flex mb-4">
                        <IoLocationOutline className="icon-center" />
                        <div className="flex-column">
                          <h6 className="heading-uppercase">ADDRESS</h6>

                          <p className="m-0 details-p ">
                            {getValues("userDetail", "address")}
                          </p>
                          <p className="m-0 details-p">
                            {getValues("userDetail", "city")}
                            {", "}
                            {getValues("userDetail", "state")}
                            {", "}
                            {getValues("userDetail", "zip")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="d-flex">
                        <AiOutlineUser className="icon-center" />
                        <div className="flex-column">
                          <h6 className="heading-uppercase">
                            Agency Representative
                          </h6>
                          <p className="details-p">
                            {getValues("userDetail", "representative")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                      <div className="d-flex">
                        <IoCallOutline className="icon-center" />
                        <div className="flex-column">
                          <h6 className="heading-uppercase">
                            Agency Representative Phone
                          </h6>
                          <p className="details-p">
                            {formatPhoneNumber(
                              getValues("userDetail", "representativePhoneNo")
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {profileDetail &&
                profileDetail.Status &&
                profileDetail.Status.key === defaultStatus.pending && (
                  <div className=" mobile_display-none">
                    <div className="position-absolute create_agency-btns d-flex mobile_wrap ">
                      <button
                        className="btn1 agency-btn"
                        onClick={() => updateProfile()}
                      >
                        <BsCheck2 className="check-icon" />
                        Approve
                      </button>
                      <button
                        className="btn2 agency-btn"
                        onClick={() =>
                          navigate(`/newusers/agency/rejected/${params?.id}`)
                        }
                      >
                        <AiOutlineClose className="cross-icon" />
                        Reject
                      </button>
                    </div>
                  </div>
                )}

              {profileDetail &&
                profileDetail.Status &&
                profileDetail.Status.key === defaultStatus.pending && (
                  <div className=" col-md-1 mb-5 desktop_block-display pb-5">
                    <div className="position-absolute d-flex mobile_wrap gap-3 ">
                      <button
                        className="btn1 agency-btn"
                        onClick={() => updateProfile()}
                      >
                        <BsCheck2 className="check-icon" />
                        Approve
                      </button>
                      <button
                        className="btn2 agency-btn"
                        onClick={() =>
                          navigate(`/newusers/agency/rejected/${params?.id}`)
                        }
                      >
                        <AiOutlineClose className="cross-icon" />
                        Reject
                      </button>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-9 padding-fixed">
            <h4 className="new-therapist-head d-tharepist ">Notification Preferences</h4>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      style={{ width: "40%", border: "none", paddingLeft: "0" }}
                    >
                      <h5 className="d-tharepist Seconday-tharepist">
                        Agency Representative Email
                      </h5>
                    </th>
                    <th scope="col" style={{ width: "20%", border: "none" }}>
                      <span className="width-name-1 switch-name">
                        Intake & Staffing
                      </span>
                    </th>
                    <th scope="col" style={{ width: "20%", border: "none" }}>
                      <span className="width-name-1  switch-name">
                        Case Management
                      </span>
                    </th>
                    <th scope="col" style={{ width: "30%", border: "none" }}>
                      <span className="width-name-1  switch-name">
                        Accounting
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bindEmail("representativeEmails")}
                  {profileDetail &&
                    profileDetail["secondaryEmails"] &&
                    profileDetail["secondaryEmails"].length > 0 && (
                      <tr>
                        <th
                          scope="col"
                          style={{
                            width: "40%",
                            border: "none",
                            paddingLeft: "0",
                          }}
                        >
                          <h5 className="d-tharepist Seconday-tharepist">
                            Secondary Email Address
                          </h5>
                        </th>
                      </tr>
                    )}
                  {profileDetail &&
                    profileDetail["secondaryEmails"] &&
                    profileDetail["secondaryEmails"].length > 0 &&
                    bindEmail("secondaryEmails")}
                </tbody>
              </table>
            </div>

            <div className="mt-5 p-0 col-lg-10">
              <h4 className="new-therapist-head d-tharepist">Rates</h4>
              <div className="d-flex mobile_wrap justify-content-between gap-2 head-margin border-bottom">
                {defaultRates &&
                  defaultRates.map((item, index) => {
                    return (
                      <p
                        key={`${item.displayName}_${index}`}
                        className={
                          activeTab === index
                            ? " ad-activee "
                            : "new-agency agency-margiin-left"
                        }
                        onClick={(e) => myFun(index)}
                      >
                        {item.displayName}
                      </p>
                    );
                  })}
              </div>
              <div>
                {defaultRates &&
                  defaultRates[activeTab] &&
                  defaultRates[activeTab].RateTabDetails &&
                  defaultRates[activeTab].RateTabDetails.map((item, index) => {
                    return (
                      <div
                        className=" d-flex mo mobile_wrap justify-content-between  align-items-center rate-margin PTSectionInput"
                        key={`${item.displayName}_${index}`}
                      >
                        <p
                          className=" ad-labels New-rate-l mb-2"
                          style={{ width: "562px" }}
                        >
                          {item.displayName}
                        </p>
                        <div className=" flex-column  mobile_width">
                          <input
                            className=" m-0 mobile_width ad-input add-input"
                            style={{ width: "210px", height: "46px" }}
                            placeholder="$"
                            value={getUserRates(
                              defaultRates[activeTab].id,
                              item.id
                            )}
                            disabled
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="col-md-3 mobile-pl-5">
            <h5 className="new-therapist-head d-tharepist">Documents</h5>
            <div
              onClick={() => handleDocumentShow()}
              style={{ cursor: "pointer" }}
            >
              <div className="M_Patinet">
                <div className="Card-ditails">
                  <img src={documentImgs} width="28" alt="" />
                </div>
                <div className="patientDoc">
                  <p className="m-0 ">
                    {profileDetail && profileDetail.isConsent
                      ? profileDetail.agencyConsent
                        ? "Agency Consent"
                        : "No Consent Forms"
                      : "No Consent Forms"}
                  </p>
                </div>
              </div>
            </div>

            <h5 className="new-therapist-head d-tharepist">Payment Method</h5>
            {profileDetail && (profileDetail.stripeAccountId && profileDetail.stripeTransferEnabled && profileDetail.stripeDetailSubmitted) ? (
              <div className="strip_connectes_inner d-flex align-items-center justify-content-between">
                <div className="col-10 d-flex align-items-center">
                  <img src="/image/strip_img.svg" />
                  <div className=" txt_connect_  Stripetxt_connect">
                    <img src="/image/strip_txt.svg" />
                    <p className="mb-0 p-0 mobilehide">Connected</p>
                  </div>
                </div>
                <div className="col-md-2">
                  <img src="/image/check-icon.svg" />
                </div>
              </div>
            ) : (
              <div class="strip_connectes_inner_admin strip_connectes_main_admin d-flex align-items-center justify-content-between flex-wrap pe-auto">
                <div class="col-md-3 col-xxl-3 col-xl-2 col-12 StrikeImg">
                  <img src="/image/strip_img.svg" />
                </div>
                <div class="col-md-7 col-12 Striketext">
                  <p class="mb-0">
                    <span className="mobilehide">Connect With </span>
                    <span className="stripe_connent_text">Stripe</span>
                  </p>
                </div>
                <div class="col-md-2 col-12 pl-0 StrikeRightArrow">
                  <img src={accordian_collapse_icon} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewAgency;
