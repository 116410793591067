import React, { useEffect, useState } from "react";
import { Row, Col } from "bootstrap-4-react";
import { useDispatch, useSelector } from "react-redux";
import { currentRole, defaultStatus } from "../../../utils/auth";
import PendingModal from "../../modals/PendingModal";
import Loader from "../../../components/Loader";
import { MyModal2 } from "../../modals/MyModal2";
import { setLoader } from "../../../Redux/slices/authSlice";
import {
  getStaffingDetailsById,
  getStaffList,
  updateStaffingStatus,
} from "../../../Redux/actions/staffing";
import ReactPaginate from "react-paginate";
import { apiBasePath } from "../../../Redux/config/config";
import userImage from "../../../images/loginpage.png";
import { CommonHead } from "../../../components/common/CommonHead";
import Acceptedreferrals from "../../Therapist/Staffing/Acceptedreferrals";
import CommonTable from "../../../components/common/CommonTable";
import { getDefaultRates } from "../../../Redux/actions/therapist";
import ViewModal from "../../Therapist/Staffing/ViewModal";
import { toast } from "react-toastify";
import { joinArrayObj } from "../../../utils/helper";
import { useParams } from "react-router-dom";

let latLng = {};
const AssistentFindPatients = () => {
  const params = useParams();
  const [menuItem, setMenuItem] = useState("availablereferrals");
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);
  const [show, setshow] = useState(false);
  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);
  const dispatch = useDispatch();
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const userList = useSelector((e) => e.staffingSlice.therapistStaffList);
  const isLoading = useSelector((e) => e.staffingSlice.istherapistStaffLoading);
  const userCount = useSelector((e) => e.staffingSlice.therapistStaffCount);
  const isModalLoading = useSelector((e) => e.staffingSlice.isModalLoading);
  const obj = {
    status: {
      availablereferrals: defaultStatus.pending,
      acceptedreferrals: defaultStatus.awaitingagencyapproval,
      declinedreferrals: defaultStatus.rejected,
    },
  };

  const setMenuAfterResponse = (status) => {
    switch (status) {
      case defaultStatus.awaitingagencyapproval:
        setTabMenus("acceptedreferrals");
        break;
      case defaultStatus.rejected:
        setTabMenus("declinedreferrals");
        break;
      default:
        break;
    }
  };

  const updateStatus = (obj) => {
    obj["therapistId"] = selectedRow.therapistId;
    dispatch(updateStaffingStatus(obj)).then((e) => {
      if (obj.status === defaultStatus.rejected) {
        toast.success("Referral declined successfully.");
      } else {
        toast.success("Referral accepted successfully.");
      }

      handleClose(false);
      setMenuAfterResponse(obj.status);
    });
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        latLng.lat = position.coords.latitude;
        latLng.lng = position.coords.longitude;
        currentUser?.id &&
          getStaffingList(pageNo, pageSize, obj.status[menuItem], latLng);
      },
      function errorCallback(error) {},
      { enableHighAccuracy: true }
    );
  }, [currentUser]);

  const getStaffingList = (pageNo, pageSize, status, latLng) => {
    dispatch(
      getStaffList({
        pageNo,
        pageSize,
        therapistId: currentUser?.id,
        status: status,
      })
    ).then((e) => init(e.payload.data.rows, latLng));
  };

  function init(data, latLng) {
    let locations = [];
    data.map((e) => {
      locations.push({
        latlng: {
          lat: Number(e?.staffUser.staffingDetails?.latitude),
          lng: Number(e?.staffUser.staffingDetails?.longitude),
        },
        staffUserId: e.staffUserId,
      });
    });

    let map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 8,
      center: locations && locations.length > 0 ? locations[0].latlng : latLng,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    });

    if (locations && locations.length > 0) {
      var marker, i;
      for (i = 0; i < locations.length; i++) {
        marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            locations[i].latlng.lat,
            locations[i].latlng.lng
          ),
          customInfo: locations[i].staffUserId,
          map: map,
        });

        window.google.maps.event.addListener(marker, "click", function (e) {
          mapMarkerClick(Number(this.customInfo));
        });
      }
    }
  }

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "staffUser.firstName":
        return "Patient";
      case "staffUser.staffingDetails.address":
        return "ADDRESS";
      case "staffUser.staffingDiagnosisdetails":
        return "DIAGNOSIS";
      case "staffUser.staffingDetails.initialVisitType":
        return "INITIAL VISIT TYPE";
      case "initialvisitrate":
        return "INITIAL VISIT RATE";

      default:
        return colName;
    }
  };

  const renderRowData = (result, col, item) => {
    if (col === "staffUser.firstName") {
      return (
        <>
          <div className="d-flex align-items-center">
            <img
              src={
                result?.staffUser.image
                  ? `${apiBasePath}documents/therapist/${result?.staffUser.image}`
                  : userImage
              }
              crossOrigin="anonymous"
              className="table-img  "
              style={{ borderRadius: "50%", objectFit: "cover" }}
              alt="img"
            />
            <div>
              <p className="  title-22 m-0">
                {" "}
                {result.staffUser &&
                  `${
                    result.staffUser.lastName +
                    ", " +
                    result.staffUser.firstName
                  }`}
              </p>
              {result?.staffUser?.staffingDetails?.mrn && (
                <p className="  title-24">
                  MR #
                  {result.staffUser &&
                    result.staffUser.staffingDetails &&
                    result.staffUser.staffingDetails.mrn}
                </p>
              )}
            </div>
          </div>
        </>
      );
    }
    if (col === "staffUser.staffingDetails.address") {
      return (
        <>
          {result.staffUser && result.staffUser.staffingDetails && (
            <p className="m-0">
              {result.staffUser.staffingDetails.address} &nbsp;
              {result.staffUser.staffingDetails.street} &nbsp;
              <br />
              {result.staffUser.staffingDetails.city}, &nbsp;
              {result.staffUser.staffingDetails.state} &nbsp;
              {result.staffUser.staffingDetails.zip} &nbsp;
            </p>
          )}
        </>
      );
    }
    if (col === "staffUser.staffingDiagnosisdetails") {
      return (
        <>
          {result.staffUser &&
            result.staffUser.staffingDiagnosisdetails &&
            result.staffUser.staffingDiagnosisdetails.length > 0 && (
              <p className="m-0 displine-list">
                {joinArrayObj(
                  result.staffUser.staffingDiagnosisdetails,
                  "masterData",
                  "displayName"
                )}
              </p>
            )}
        </>
      );
    }
    if (col === "staffUser.staffingDetails.initialVisitType") {
      return (
        <>
          {result && result.initialVisitType && (
            <p>{result.initialVisitType.displayName}</p>
          )}
        </>
      );
    }
    if (col === "initialvisitrate") {
      return (
        <>
          <p className="m-0 displine-list">
            {"$" +
              result?.staffUser?.staffInitialVisit[0]?.initialVisitType?.r[0]
                ?.rate.u[0]?.price}
          </p>
        </>
      );
    }
    return <h1 className=" title-24  mt-3 mb-3">ee</h1>;
  };

  const userRoleStatusCount = useSelector(
    (e) => e.staffingSlice.therapistStaffRoleStatusCount
  );
  function handleClick(rowData) {
    setSelectedRow(rowData);
    dispatch(getDefaultRates(currentRole.agency));

    dispatch(getStaffingDetailsById(rowData.staffUserId)).then((e) => {
      handleShow();
    });
  }

  const mapMarkerClick = (staffUserId) => {
    dispatch(getDefaultRates(currentRole.agency));
    dispatch(getStaffingDetailsById(staffUserId)).then((e) => {
      handleShow();
    });
  };

  const getRoleDetailCount = (status) => {
    let count = 0;
    if (userRoleStatusCount && userRoleStatusCount.length > 0) {
      let user = userRoleStatusCount.find((item) => item.Status.key === status);
      if (user && user.userCount) {
        count = user.userCount;
      }
    }
    return Number(count);
  };

  const menu = [
    {
      key: "availablereferrals",
      label: "Available Referrals",
      count: getRoleDetailCount(defaultStatus.pending),
      data: userList,
      isTableShow: true,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleClick(rowData);
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
      modals: <ViewModal updateStatus={updateStatus} />,
      columns: [
        "staffUser.firstName",
        "staffUser.staffingDetails.address",
        "staffUser.staffingDiagnosisdetails",
        "staffUser.staffingDetails.initialVisitType",
        "initialvisitrate",
      ],
      columnsToHide: ["image", "lastName"],
      modalClass: "PenddingM",
    },
    {
      label: "Accepted Referrals",
      count:
        getRoleDetailCount(defaultStatus.awaitingagencyapproval) +
        getRoleDetailCount(defaultStatus.unfinishedprofile),
      data: userList,
      key: "acceptedreferrals",
      component: (
        <Acceptedreferrals
          getRoleDetailCount={getRoleDetailCount}
          getStaffingList={getStaffingList}
          latLng={latLng}
          // mainTableClass="pl-5"
          handleShow={handleShow}
          handleClick={handleClick}
        />
      ),
      isTableShow: false,
      noData: "No data available",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleShow();
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
      modals: <PendingModal />,
      columns: ["firstName", "discipline", "status"],
      columnsToHide: ["image"],
      modalClass: "ApprovM",
    },
    {
      label: "Declined Referrals",
      key: "declinedreferrals",
      isTableShow: true,
      data: userList,
      count: getRoleDetailCount(defaultStatus.rejected),
      noData: "No data available",
      modals: <ViewModal updateStatus={updateStatus} />,
      columns: [
        "staffUser.firstName",
        "staffUser.staffingDetails.address",
        "staffUser.staffingDiagnosisdetails",
        "staffUser.staffingDetails.initialVisitType",
        "initialvisitrate",
      ],
      columnsToHide: ["image"],
      modalClass: "Patient-01",
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  handleShow();
                  handleClick(rowData);
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
    },
  ];
  const myActions = menu.find((ele) => ele.key === menuItem);

  const setTabMenus = (tab) => {
    dispatch(setLoader({ loading: true }));
    setMenuItem(tab);
    setPageNo(1);
    getStaffingList(1, pageSize, obj.status[tab], latLng);
  };

  const handlePageClick = (event) => {
    dispatch(setLoader({ loading: true }));
    getStaffingList(event.selected + 1, pageSize, obj.status[menuItem]);
    setPageNo(event.selected + 1);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12  pl-5 pr-5">
            <div className="patient_main_parent">
              <div id="map" style={{ width: "100%", height: "30vh" }}></div>
            </div>
          </div>
          <div className="user-type-main tabs_user_type">
            <div className="col-sm-12 pr-5 pl-5">
              <CommonHead
                data={menu}
                setMenu={setTabMenus}
                menuItem={menuItem}
                css={"col-md-3"}
              />
            </div>
          </div>

          {myActions.isTableShow ? (
            <div className="container-fluid ">
              <Row>
                <div className="inside_table_tabs">
                  <Col lg={11}>
                    <div className="table-responsive">
                      <CommonTable
                        data={myActions?.data}
                        columns={myActions?.columns}
                        columnsToHide={myActions?.columnsToHide}
                        overrideColumnName={overrideColumnName}
                        actions={myActions?.actions}
                        renderRowData={renderRowData}
                        noData={myActions?.noData}
                        isLoading={isLoading}
                      />
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          ) : (
            myActions?.component
          )}

          {myActions?.data &&
            myActions?.data.length > 10 &&
            myActions?.isTableShow && (
              <div className="container-fluid ">
                <div className="row justify-content-start mt-3  pl-5">
                  <div className="pr-5 pl-5 ">
                    <ReactPaginate
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      nextLabel="next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={Math.ceil(userCount / pageSize)}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                      forcePage={
                        Math.ceil(userCount / pageSize) === 0 ? -1 : pageNo - 1
                      }
                    />
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>

      {(isLoading || isModalLoading) && <Loader />}

      {show && (
        <MyModal2
          data={myActions.modals}
          show={show}
          handleClose={handleClose}
          modalClass={myActions.modalClass}
        />
      )}
    </>
  );
};

export default AssistentFindPatients;
