import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CommonHead2 } from "../../../components/common/CommonHead2";
import { currentRole, decodeData, defaultStatus } from "../../../utils/auth";
import Patient from "../../../images/usertable02.svg";
import { apiBasePath } from "../../../Redux/config/config";
import CommonTable from "../../../components/common/CommonTable";
import ViewModal from "./ViewModal";
import ReactPaginate from "react-paginate";
import {
  getStaffingDetailsById,
  getStaffList,
} from "../../../Redux/actions/staffing";
import { getDefaultRates } from "../../../Redux/actions/therapist";
import UserDetailModal from "../../Admin/UserDetailModal";
import { joinArrayObj } from "../../../utils/helper";
import { useParams, useSearchParams } from "react-router-dom";

const Acceptedreferrals = (props) => {
  const obj = {
    status: {
      pendingagencyapproval: defaultStatus.awaitingagencyapproval,
      approvedawaitingpatientinfo: defaultStatus.unfinishedprofile,
    },
  };

  const [searchParams] = useSearchParams();
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const params = useParams();

  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const userList = useSelector((e) => e.staffingSlice.therapistStaffList);
  const userCount = useSelector((e) => e.staffingSlice.therapistStaffCount);
  const dispatch = useDispatch();

  const [menuItem, setMenuItem] = useState("pendingagencyapproval");
  useEffect(() => {
    let approveKey = searchParams.get("approvekey");
    if (
      approveKey &&
      document.getElementById(`row_${decodeData(approveKey)}`)
    ) {
      document.getElementById(`row_${decodeData(approveKey)}`).click();
    }
  }, [userList]);
  const menu = [
    {
      key: "pendingagencyapproval",
      label: "Pending Agency Approval",
      count: Number(
        props.getRoleDetailCount(defaultStatus.awaitingagencyapproval)
      ),
      noData: "No data available",
      data: userList,
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                onClick={() => {
                  props.handleClick(rowData);
                  props.handleShow();
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
      modals: <ViewModal />,
      columns: [
        "staffUser.firstName",
        "staffUser.staffingDetails.address",
        "staffUser.staffingDiagnosisdetails",
        "staffUser.staffingDetails.initialVisitType",
        "initialvisitrate",
      ],
      columnsToHide: ["image", "lastName"],
    },
    {
      key: "approvedawaitingpatientinfo",
      label: "Approved But Awaiting Info",
      count: Number(props.getRoleDetailCount(defaultStatus.unfinishedprofile)),
      noData: "No data available",
      data: userList,
      component: <UserDetailModal />,
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <button
                className="view-btn tr-btn ml-auto  mt-3 mb-3 "
                id={`row_${rowData.staffUserId}`}
                onClick={() => {
                  props.handleClick(rowData);
                  props.handleShow();
                }}
              >
                View
              </button>
            </td>
          ),
        },
      ],
      modals: <UserDetailModal />,
      columns: [
        "staffUser.firstName",
        "staffUser.staffingDetails.address",
        "staffUser.staffingDiagnosisdetails",
        "staffUser.staffingDetails.initialVisitType",
        "initialvisitrate",
      ],
      columnsToHide: ["image"],
    },
  ];
  const setTabMenus = (tab) => {
    setMenuItem(tab);
    setPageNo(1);
    getStaffingList(1, pageSize, obj.status[tab], props.latLng);
  };

  const getStaffingList = (pageNo, pageSize, status, latLng) => {
    dispatch(
      getStaffList({
        pageNo,
        pageSize,
        therapistId: currentUser?.id,
        status: status,
      })
    ).then((e) => init(e.payload.data.rows, latLng));
  };

  const mapMarkerClick = (staffUserId) => {
    dispatch(getDefaultRates(currentRole.agency));
    dispatch(getStaffingDetailsById(staffUserId)).then((e) => {
      props.handleShow();
    });
  };

  function init(data, latLng) {
    let locations = [];
    data.map((e) => {
      locations.push({
        latlng: {
          lat: Number(e?.staffUser.staffingDetails?.latitude),
          lng: Number(e?.staffUser.staffingDetails?.longitude),
        },
        staffUserId: e.staffUserId,
      });
    });

    let map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 8,
      center: locations && locations.length > 0 ? locations[0].latlng : latLng,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    });

    if (locations && locations.length > 0) {
      var marker, i;
      for (i = 0; i < locations.length; i++) {
        marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            locations[i].latlng.lat,
            locations[i].latlng.lng
          ),
          customInfo: locations[i].staffUserId,
          map: map,
        });

        window.google.maps.event.addListener(marker, "click", function (e) {
          mapMarkerClick(Number(this.customInfo));
        });
      }
    }
  }

  const myActions = menu.find((ele) => ele.key === menuItem);

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "staffUser.firstName":
        return "Patient";
      case "staffUser.staffingDetails.address":
        return "ADDRESS";
      case "staffUser.staffingDiagnosisdetails":
        return "Diagnosis";
      case "staffUser.staffingDetails.initialVisitType":
        return "INITIAL VISIT TYPE";
      case "initialvisitrate":
        return "INITIAL VISIT RATE";

      default:
        return colName;
    }
  };
  const getMakeOfferRate = (rateArray, object) => {
    let rate = rateArray.find(
      (item) =>
        item.rateTabDetailId === object.rateTabDe &&
        item.rateTabid === object.rateTabId
    );
    return rate.myprice ? rate.myprice : 0;
  };

  const renderRowData = (result, col, item) => {
    if (col === "staffUser.firstName") {
      return (
        <>
          <div className="d-flex align-items-center">
            <img
              src={
                result?.staffUser.image
                  ? `${apiBasePath}documents/therapist/${result?.staffUser?.image}`
                  : Patient
              }
              crossOrigin="anonymous"
              className="table-img  "
              style={{ borderRadius: "50%", objectFit: "cover" }}
              alt="img"
            />
            <div>
              <p className="  title-22 m-0">
                {" "}
                {result.staffUser &&
                  `${
                    result.staffUser.lastName +
                    ", " +
                    result.staffUser.firstName
                  }`}
              </p>
              {result?.staffUser?.staffingDetails?.mrn && (
                <p className="  title-24">
                  MR #
                  {result.staffUser &&
                    result.staffUser.staffingDetails &&
                    result.staffUser.staffingDetails.mrn}
                </p>
              )}
            </div>
          </div>
        </>
      );
    }
    if (col === "staffUser.staffingDetails.address") {
      return (
        <>
          {result.staffUser && result.staffUser.staffingDetails && (
            <p className="m-0 displine-list">
              {result.staffUser.staffingDetails.address} &nbsp;
              <br />
              {result.staffUser.staffingDetails.city},&nbsp;
              {result.staffUser.staffingDetails.state} &nbsp;
              {result.staffUser.staffingDetails.zip} &nbsp;
            </p>
          )}
        </>
      );
    }
    if (col === "staffUser.staffingDiagnosisdetails") {
      return (
        <>
          {result.staffUser &&
            result.staffUser.staffingDiagnosisdetails &&
            result.staffUser.staffingDiagnosisdetails.length > 0 &&
            result.staffUser.staffingDiagnosisdetails.map(
              (item) =>
                item.types === "primarydiagnosis" && (
                  <p className="m-0 displine-list">
                    {item.masterData.displayName}
                  </p>
                )
            )}
        </>
      );
    }
    if (col === "staffUser.staffingDetails.initialVisitType") {
      return (
        <>
          {result.staffUser && result.staffUser.staffInitialVisit[0] && (
            <p className="m-0 displine-list">
              {
                result.staffUser.staffInitialVisit[0].initialVisitType
                  .displayName
              }
            </p>
          )}
        </>
      );
    }
    if (col === "initialvisitrate") {
      return (
        <>
          <p className="m-0 displine-list">
            $
            {result.makeOffer
              ? getMakeOfferRate(
                  result.makeOffer,
                  result?.staffUser?.staffInitialVisit[0]?.initialVisitType
                    ?.r[0]?.rate.u[0]
                )
              : result?.staffUser?.staffInitialVisit[0]?.initialVisitType?.r[0]
                  ?.rate.u[0]?.price}
          </p>
        </>
      );
    }
    return <h1 className=" title-24  mt-3 mb-3">ee</h1>;
  };

  const handlePageClick = (event) => {
    getStaffingList(event.selected + 1, pageSize, obj.status[menuItem]);
    setPageNo(event.selected + 1);
  };
  useEffect(() => {
    if (params && params.menuKey) {
      if (
        params.menuKey === "declinedreferrals" ||
        params.menuKey === "availablereferrals"
      ) {
        setTabMenus("pendingagencyapproval");
      } else {
        setTabMenus(params.menuKey);
      }
    }
  }, [params]);
  return (
    <div className="col-sm-12 pr-5 pl-5">
      <CommonHead2
        data={menu}
        setMenu={setTabMenus}
        menuItem={menuItem}
        css="col-md-3"
      />
      <Row>
        <Col lg={12}>
          <div
            className={
              props.mainTableClass
                ? `${props.mainTableClass} table-responsive`
                : "table-responsive"
            }
          >
            <CommonTable
              data={myActions?.data}
              columns={myActions?.columns}
              columnsToHide={myActions?.columnsToHide}
              overrideColumnName={overrideColumnName}
              actions={myActions?.actions}
              noData={myActions?.noData}
              renderRowData={renderRowData}
              isLoading={false}
            />
          </div>
        </Col>
      </Row>
      {myActions?.count > 10 && myActions?.data?.length > 0 && (
        <div className="container-fluid ">
          <div className="row justify-content-start mt-3  pl-5">
            <div className="pr-5 pl-5 ">
              <ReactPaginate
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={Math.ceil(userCount / pageSize)}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                forcePage={
                  Math.ceil(userCount / pageSize) === 0 ? -1 : pageNo - 1
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Acceptedreferrals;
