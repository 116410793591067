import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  addTherapistSelectLastVisit,
  getStaffDocumentation,
} from "../../Redux/actions/common";
import { useParams } from "react-router-dom";
import { decodeData } from "../../utils/auth";
import { toast } from "react-toastify";
import { staffFormTypesConstants } from "../../utils/helper";

export const AssessmentVisitConfirm = (props) => {
  const { staffUserId, handleClose, staffDocument, episodeId, crossModal } =
    props;
  const params = useParams();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    if (!values.docVisitTypeId) {
      toast.info("Please select at least one visit from each section.");
      return;
    }
    let episodeDocumentId = 0;
    if (staffDocument && staffDocument.length > 0) {
      let findLastVisit = staffDocument[0].episodeDocumentations.find(
        (item) =>
          item.episodeVisit.displayName === staffFormTypesConstants.Assessment
      );
      if (findLastVisit && findLastVisit.id) {
        episodeDocumentId = findLastVisit.id;
      }
    }

    const payload = {
      episodeDocumentId: episodeDocumentId,
      docVisitTypeId: values.docVisitTypeId,
    };

    dispatch(addTherapistSelectLastVisit(payload)).then((res) => {
      if (res?.payload?.success) {
        dispatch(
          getStaffDocumentation({
            staffUserId: staffUserId
              ? Number(staffUserId)
              : decodeData(params.id),
          })
        ).then((res) => {
          crossModal();
          setTimeout(() => {
            handleClose(episodeDocumentId);
          }, 1000);
        });
      }
    });
  };

  const getVisitTypesSaved = () => {
    let visitTypes = [];
    if (staffDocument && staffDocument.length > 0) {
      let findLastVisit = staffDocument[0].episodeDocumentations.find(
        (item) =>
          item.episodeVisit.displayName === staffFormTypesConstants.Assessment
      );

      if (findLastVisit && findLastVisit.StaffDocumentDatas) {
        visitTypes = findLastVisit.StaffDocumentDatas;
      }
    }

    return visitTypes;
  };

  return (
    <>
      <Modal
        size={props.size}
        dialogClassName={props.dialogClassName}
        className={props.modalClass}
        show={props.showModal}
        onHide={props.crossModal}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          className="closeButton border-0"
          closeButton
        ></Modal.Header>
        <Modal.Body className="p-3">
          <Form
            keepDirtyOnReinitialize
            onSubmit={onSubmit}
            render={({ handleSubmit, values, form }) => (
              <form onSubmit={handleSubmit}>
                <div className="row ">
                  <div className="col-md-12">
                    <h6 className="cs-heading text-center">
                      Select Visit Type
                    </h6>
                    <p className="text-center PrimeryAlertColor">
                      *Please select 1 visit
                    </p>
                  </div>

                  {getVisitTypesSaved().map((item, index) => (
                    <div className="col-md-6">
                      <div className="row justify-content-center">
                        <div className="col-md-11">
                          <div className="radio_btns radio_btns-margin new-reff-radio m-0 radioBtnColoumn">
                            <label
                              key={index}
                              className="container-2 align-items-center d-flex padding-left-ref-45 ref-height"
                              style={{ height: "100%" }}
                            >
                              <Field
                                name={`docVisitTypeId`}
                                component="input"
                                type="radio"
                                value={item.docVisitTypeId}
                              >
                                {({ input }) => (
                                  <>
                                    <input
                                      {...input}
                                      type="radio"
                                      value={item.docVisitTypeId}
                                      checked={
                                        Number(values[`docVisitTypeId`]) ===
                                        Number(item.docVisitTypeId)
                                      }
                                      disabled={item.docVisit.types.includes(
                                        "lastvisittype_section_a"
                                      )}
                                    />
                                    <span className="checkmark-2"></span>
                                  </>
                                )}
                              </Field>
                              <div> {item.docVisit.displayName} </div>
                            </label>
                            {/* {visitTypes[item] &&
                              visitTypes[item].map((visit, visitIndex) => (
                                <label
                                  key={visitIndex}
                                  className="container-2 align-items-center d-flex padding-left-ref-45 ref-height"
                                  style={{ height: "100%" }}
                                >
                                  <Field
                                    name={`section${index + 1}`}
                                    component="input"
                                    type="radio"
                                    value={visit.id}
                                  >
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="radio"
                                          value={visit.id}
                                          checked={
                                            Number(
                                              values[`section${index + 1}`]
                                            ) === Number(visit.id)
                                          }
                                        />
                                        <span className="checkmark-2"></span>
                                      </>
                                    )}
                                  </Field>
                                  <div> {visit.displayName} </div>
                                </label>
                              ))} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* <div className="col-md-6">
                    <div className="row justify-content-center h-100">
                      <div className="col-md-11">
                        <div className="radio_btns radio_btns-margin new-reff-radio  m-0 radioBtnColoumn">
                          <span className="radioBtnsSection">Section 2</span>
                          {assessmentSectionOptions.section2.map(
                            (item, index) => (
                              <label
                                key={index}
                                className="container-2 align-items-center d-flex padding-left-ref-45 ref-height"
                              >
                                <Field
                                  name="section2"
                                  component="input"
                                  type="radio"
                                  value={item.key}
                                >
                                  {({ input }) => (
                                    <>
                                      <input
                                        {...input}
                                        type="radio"
                                        value={item.key}
                                      />
                                      <span className="checkmark-2"></span>
                                    </>
                                  )}
                                </Field>
                                <div> {item.name} </div>
                              </label>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="d-flex mt-4">
                      <Button
                        type="submit"
                        variant="primary"
                        className="primary-confirm mx-auto"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
