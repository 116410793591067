import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../config/axiosInstance";

export const getPatientDocTypes = createAsyncThunk(
  "getPatientDocTypes/user/getDocumentTypes",
  async (data) => {
    const data1 = await axiosInstance.get("user/getDocumentTypes", data);
    return data1.data;
  }
);

export const getDefaultDocTypes = createAsyncThunk(
  "getDefaultDocTypes/user/getDocumentTypes",
  async (data) => {
    const data1 = await axiosInstance.get("user/getDocumentTypes", data);
    return data1.data;
  }
);

export const getUserDocumentsList = createAsyncThunk(
  "getUserDocumentsList/user/getUserDocuments/",
  async (data) => {
    const data1 = await axiosInstance.post("user/getUserDocuments", data);
    return data1.data;
  }
);
export const getAddPatientDoc = createAsyncThunk(
  "getAddPatientDoc/user/addPatientDocument",
  async (data) => {
    const data1 = await axiosInstance.post("user/addPatientDocument", data);
    return data1.data;
  }
);
export const deletePatientDocument = createAsyncThunk(
  "deletePatientDocument/user/deletePatientDocument",
  async (data) => {
    const data1 = await axiosInstance.get(
      `user/deletePatientDocument/${data}`,
      data
    );
    return data1.data;
  }
);

export const updateDocumentDate = createAsyncThunk(
  "updateDocumentUpdate/staffing/documentDateUpdate",
  async (data) => {
    const data1 = await axiosInstance.post("staffing/documentDateUpdate", data);
    return data1.data;
  }
);

export const getStaffFormdataById = createAsyncThunk(
  "Staff-Form/Get/By-id",
  async (data) => {
    const data1 = await axiosInstance.post(
      "staffing/getStaffFormDataById",
      data
    );
    return data1.data;
  }
);
export const getEvalForm = createAsyncThunk(
  "Staff-Form/Eval/Get/By-id",
  async (data) => {
    const data1 = await axiosInstance.post(
      "staffing/getStaffFormDataById",
      data
    );
    return data1.data;
  }
);

export const getPatientDocs = createAsyncThunk(
  "Staff-Document/Get/By-EpisodeId",
  async (data) => {
    const data1 = await axiosInstance.post(
      "staffing/staffDocumentDetail",
      data
    );
    return data1.data;
  }
);
export const getPatientDocsDetails = createAsyncThunk(
  "Staff-Document/Get/By-EpisodeId",
  async (data) => {
    const data1 = await axiosInstance.post(
      "staffing/staffDocumentDetail",
      data
    );
    return data1.data;
  }
);

export const updateDocumentStatus = createAsyncThunk(
  "Staff-Document/Update-Status",
  async (data) => {
    const data1 = await axiosInstance.post(
      "staffing/updateEpisode-DocumentStatus",
      data
    );
    return data1.data;
  }
);

export const updateAuthorisedVisits = createAsyncThunk(
  "Staff-Document/Update-Authorised-Visit",
  async (data) => {
    const data1 = await axiosInstance.post(
      "staffing/staffAuthdocumentEdit",
      data
    );
    return data1.data;
  }
);
