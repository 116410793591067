import React, { Fragment, useRef, useState } from "react";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";

const AddressAutocomplete = (props) => {
  let ref = useRef(null);
  const [results, setResults] = useState([]);
  const [selected, setSelected] = useState([]);

  let service =
    window &&
    window.google &&
    window.google.maps &&
    new window.google.maps.places.AutocompleteService();

  const handleSelect = (results) => {
    let result = results[0];
    if (result) {
      let { selectResult } = props;
      let placeService =
        window && new window.google.maps.places.PlacesService(ref.current);

      placeService.getDetails(
        {
          placeId: result.id,
          fields: [
            "name",
            "address_component",
            "formatted_address",
            "geometry",
          ],
        },
        (response, status) => {
          if (status === "OK") {
            let result = {};
            // eslint-disable-next-line no-unused-expressions
            response.name ? (result["company_name"] = response.name) : null;
            result["address"] = response.formatted_address.split(",")[0];
            result["latitude"] = response.geometry.location.lat();
            result["longitude"] = response.geometry.location.lng();
            result["addressDetails"] = {
              address_components: response?.address_components,
              formatted_address: response?.formatted_address,
            };
            response?.address_components?.map((item, index) => {
              // eslint-disable-next-line no-unused-expressions
              item.types.includes("locality")
                ? (result["city"] = item.long_name)
                : item.types.includes("administrative_area_level_1")
                ? (result["state"] = item.long_name)
                : item.types.includes("country")
                ? ((result["country"] = item.long_name),
                  (result["country_code"] = item.short_name))
                : item.types.includes("postal_code")
                ? (result["postal_code"] = item.long_name)
                : item.types.includes("sublocality")
                ? (result["street"] = result.street
                    ? result.street.concat(`${item.long_name},`)
                    : `${item.long_name},`)
                : null;
            });

            selectResult(result);
            setSelected([{ ...result, label: result.address }]);
          }
        }
      );
    } else {
      props.selectResult([{ ...results }]);
    }
  };

  const handleSearch = (e) => {
    let { selectResult } = props;
    setSelected([{ label: e }]);
    if (e && service && service.getPlacePredictions) {
      service.getPlacePredictions({ input: e }, (predictions, status) => {
        if (status === "OK" && predictions && predictions.length > 0) {
          let result = predictions.map((result, index) => ({
            id: result.place_id,
            label: result.description,
            address: result.structured_formatting,
          }));
          setResults(result);
          selectResult(result);
        }
      });
    } else {
      selectResult();
    }
  };

  return (
    <Fragment>
      <Typeahead
        id="typeahead"
        onChange={(e) => handleSelect(e)}
        onInputChange={(e) => handleSearch(e)}
        options={results}
        renderMenu={(results, menuProps) => (
          <Menu {...menuProps}>
            {results?.map((result, index) => (
              <MenuItem option={result} position={index}>
                {result.label}
              </MenuItem>
            ))}
          </Menu>
        )}
        selected={
          selected && selected.length > 0
            ? selected
            : props && props.selectedAddress
            ? [{ label: props.selectedAddress }]
            : []
        }
        {...props}
        className={props && props.className ? props.className : ``}
        placeholder="Street"
      />
      <div style={{ display: "inline" }} ref={ref} />
    </Fragment>
  );
};

export default AddressAutocomplete;
