import React from "react";
import { useSelector } from "react-redux";
import { ImDownload3 } from "react-icons/im";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { chatIconsByRole } from "../../../utils/helper";
import { currentRole } from "../../../utils/auth";
import { toast } from "react-toastify";
import { apiBasePath } from "../../../Redux/config/config";

export default function ChatHeader(props) {
  const { dataValue, setDataValue, setDownloadChat, saveToPdf } = props;
  const activeConversation = useSelector(
    (state) => state.chatSlice.activeConversation
  );
  const currentUserDetail = useSelector(
    (state) => state.adminSlice.currentActiveUserDetails
  );
  const currentUserRole = currentUserDetail.Role;
  const ActivityLog = useSelector((state) => state.chatSlice.activityLog);
  const messagePaginatorItem = useSelector(
    (state) => state.chatSlice.messagePaginator.items
  );

  const activeDownload = () => {
    if (
      dataValue === 0 &&
      messagePaginatorItem &&
      messagePaginatorItem.length > 0
    ) {
      setDownloadChat(true);
    } else if (dataValue === 1 && ActivityLog && ActivityLog.length > 0) {
      saveToPdf();
    }
    if (
      (dataValue === 0 &&
        messagePaginatorItem &&
        messagePaginatorItem.length === 0 &&
        currentUserRole.key !== currentRole.admin) ||
      (ActivityLog.length === 0 && currentUserRole.key !== currentRole.admin)
    ) {
      toast.info("Nothing to download.");
    }
  };

  const isShow = () => {
    let isShow = false;
    if (
      currentUserRole.key === currentRole.admin ||
      currentUserRole.key === currentRole.agency
    ) {
      isShow = true;
    }
    return isShow;
  };

  return (
    <div className="header chat__header_padding">
      <p
        style={{ padding: "32px 0px", margin: "0" }}
        className="d-flex justify-content-between"
      >
        <div className="d-flex flex-row">
          <img
            src={
              activeConversation &&
              activeConversation.attributes &&
              activeConversation.attributes.image
                ? `${apiBasePath}documents/therapist/${activeConversation.attributes.image}`
                : chatIconsByRole["patient"]
            }
            alt="avatar"
            className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
            width="50"
            height="50"
            crossOrigin="anonymous"
          />
          <div className="pt-1">
            <p className="user-name mb-0">{`${
              activeConversation && activeConversation.friendlyName
            }`}</p>
            <p className="small ">
              {activeConversation &&
                activeConversation.attributes &&
                activeConversation.attributes.mrn &&
                `MR#${activeConversation?.attributes?.mrn}`}
            </p>
          </div>
        </div>
        <div style={{ display: "flex" }} className="">
          <button
            className={`btn-n  p-0 ${dataValue === 0 && "active p-0"}`}
            onClick={() => setDataValue(0)}
          >
            <BiMessageRoundedDetail size={20} className="mr-2" />
            Comm Notes
          </button>
          <button
            className={`btnn p-0 ${dataValue === 1 && "active p-0"} `}
            onClick={() => setDataValue(1)}
          >
            <AiOutlineClockCircle className="mr-2" />
            Activity Log
          </button>
          {isShow() && (
            <button className="btnn-n" onClick={() => activeDownload()}>
              <ImDownload3 />
            </button>
          )}
        </div>
      </p>
    </div>
  );
}
