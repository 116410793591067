import React, { useMemo } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { currentRole } from "../../../utils/auth";
import { savePatientForm } from "../../../Redux/actions/staffing";
import { staffFormTypesConstants, visitStatus } from "../../../utils/helper";

const MissedVisit = (props) => {
    const dispatch = useDispatch();
    const { closeFormModal, visitDetail, formId, IsUpdated, statusHandler } = props;
    const staffFormData = useSelector((e) => e.commonSlice.staffForm);

    const staffDetails = useSelector((state) => state.staffingSlice.staffDetail);
    const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
    const todayDate = new Date().toISOString().split("T")[0];

    const visitTypeData = [
        "Skilled Nursing",
        "Physical Therapy",
        "Occupational Therapy",
        "Speech Therapy",
    ];
    const missedVisitReasonData = [
        "Physician/clinic appointment",
        "No answer to phone",
        "Patient/Representative refused care",
        "Dangerous weather or other disaster making travel unsafe",
        "other"
    ];
    const actionsTakenData = [
        "Contacted authorized representative,caregiver,family,friends",
        "Contacted Physician",
        "Rescheduled for later in the week",
        "Rescheduled for next week",
        "other"
    ];
    const notificationsIndividualsData = [
        "Clinical Manager",
        "Physician",
        "other"
    ]
    const notificationsMethodsData = [
        "Phone conversation",
        "Voice message",
        "Email",
        "other"
    ]

    let initValues = () => {
        let initialValues = {
            patientName: staffDetails.lastName + "," + staffDetails.firstName,
            mrNumber: staffDetails && staffDetails.staffingDetails && staffDetails.staffingDetails.mrn,
            dob: staffDetails && staffDetails.staffingDetails && staffDetails.staffingDetails.dateOfBirth.split('T')[0],
            agencyName: staffDetails && staffDetails.createdByUser && staffDetails.createdByUser.name,
            agencyPhone: staffDetails && staffDetails.createdByUser && staffDetails.createdByUser.userDetail && staffDetails.createdByUser.userDetail.phone,
            physicianName: staffDetails && staffDetails.staffingDetails && staffDetails.staffingDetails.physicianName,
            physicianPhoneNumber: staffDetails && staffDetails.staffingDetails && staffDetails.staffingDetails.physicianPhoneNumber,
            // visitTypeData: [],
            missedVisitReasonData: [],
            actionsTakenData: [],
            notificationsIndividualsData: [],
            notificationsMethodsData: [],
            visitType: "",
            physicianClinicInput: "",
            missedVisitReasonOtherInput: "",
            physicianAdditionalInput: "",
            missedVisitReasonInput: "",
            missedVisitImpact: "",
            actionsTakenInput: "",
            notificationIndividuals: "",
            notificationIndividualsInput: "",
            notificationMethods: "",
            notificationMethodsInput: "",
            notificationTime: "",
            notificationDate: ""
        }
        return initialValues
    };

    const validateForm = (values) => {

        let error = {}

        if (!values.date) {
            error['date'] = "Required"
        }
        if (!values.visitType) {
            error['visitType'] = "Required"
        }
        if (values.missedVisitReasonData.length === 0) {
            error["missedVisitReasonData"] = "Required"
        }
        if (values.missedVisitReasonData.includes("Physician/clinic appointment") && !values.physicianClinicInput) {
            error["physicianClinicInput"] = "Required"
        }
        if (values.missedVisitReasonData.includes("other") && !values.missedVisitReasonOtherInput) {
            error["missedVisitReasonOtherInput"] = "Required other"
        }
        if (values.actionsTakenData.includes("other") && !values.actionsTakenInput) {
            error["actionsTakenInput"] = "Required"

        }
        if (values.actionsTakenData.length === 0) {
            error["actionsTakenData"] = "Required_checkbox"
        }

        if (values.notificationsIndividualsData.includes("other") && !values.notificationIndividualsInput) {
            error["notificationIndividualsInput"] = "Required"

        }
        if (values.notificationsMethodsData.includes("other") && !values.notificationMethodsInput) {
            error["notificationMethodsInput"] = "Required"

        }
        if (values.missedVisitImpact.length === 0 && !values.missedVisitImpactInput) {
            error["missedVisitImpactInput"] = "Required"

        }
        if (values.notificationTime || values.notificationDate) {

            if (values.notificationsMethodsData.length === 0) {
                error["notificationsMethodsData"] = "Required"

            }
            if (values.notificationsIndividualsData.length === 0) {

                error["notificationsIndividualsData"] = "Required"
            }

        }
        return error
    }

    const handleSubmit = (values, form) => {
        let finalFormValues = { ...values }
        const apiObject = {
            staffId: staffDetails?.id,
            formData: finalFormValues,
            formType: staffFormTypesConstants.MissedVisit,
            id: finalFormValues.id,
            episodeDocumentFormId: formId,
            episodeDocumentId: visitDetail?.id

        }
        dispatch(savePatientForm(apiObject))
            .then((res) => {
                if (res.payload.success) {
                    closeFormModal()
                    IsUpdated();
                    if (visitDetail && visitDetail.Status && visitDetail.Status.key !== visitStatus.pendingnotes) statusHandler(visitStatus.pendingnotes, visitDetail)

                }
            })
    }


    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                validate={validateForm}
                initialValues={useMemo((e) => initValues(e))}
                render={({ handleSubmit, values, errors, touched }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="container">
                            <div className="row mein-white-from-background">
                                <div className="col-md-6 ">
                                    <div>
                                        <p>
                                            Patient Name : {staffDetails.lastName + "," + staffDetails.firstName}
                                        </p>
                                        <p>
                                            MR# : {staffDetails.staffingDetails && staffDetails.staffingDetails.mrn}
                                        </p>
                                        <p>
                                            DOB : {staffDetails.staffingDetails && staffDetails.staffingDetails.dateOfBirth.split('T')[0]}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6 ">
                                    <div className='d-flex align-items-center gap-3 mb-3'>
                                        <label className='m-0'> Date :</label>
                                        <Field name="date">
                                            {({ input, meta }) => (
                                                <div className="w-50">
                                                    <input
                                                        className="form-control"
                                                        {...input}
                                                        type="date"
                                                    />
                                                    {meta.error && meta.touched && (
                                                        <p className="alert alert-danger " role="alert">
                                                            {meta.error}
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                    {currentUser && currentUser.Role &&
                                        currentUser.Role.key &&
                                        (currentUser.Role.key === currentRole.supervisingtherapist ||
                                            currentUser.Role.key === currentRole.assistanttherapist) &&
                                        (<p>
                                            Therapist : {currentUser?.lastName + "," + currentUser?.firstName} | <span className='fw-bold'> {currentUser.userDetail && currentUser?.userDetail?.discipline?.toUpperCase()}</span>
                                        </p>)}
                                </div>
                            </div>
                            <div className="row mein-white-from-background">
                                <div className="col-md-6 border-right-1">
                                    <div className="fileds-from">
                                        <label>Agency Name :</label>
                                        <Field name="agencyName">
                                            {({ input, meta }) => (
                                                <div className="w-50">
                                                    <input
                                                        className="form-control border-0 bg-white"
                                                        {...input}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                                <div className="col-md-6 ">
                                    <div className="fileds-from">
                                        <label>Agency Phone :</label>
                                        <Field name="agencyPhone">
                                            {({ input, meta }) => (
                                                <div className="w-50">
                                                    <input
                                                        maxlength="45"
                                                        className="form-control border-0 bg-white"
                                                        {...input}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                </div>

                                <div className="col-md-6 border-right-1">
                                    <div className="fileds-from">
                                        <label>Physician Name :</label>
                                        <Field name="physicianName">
                                            {({ input, meta }) => (
                                                <div className="w-50">
                                                    <input
                                                        maxlength="45"
                                                        className="form-control border-0 bg-white"
                                                        {...input}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="fileds-from">
                                        <label>Physician Number :</label>
                                        <Field name="physicianPhoneNumber">
                                            {({ input, meta }) => (
                                                <div className="w-50">
                                                    <input
                                                        maxlength="45"
                                                        className="form-control border-0 bg-white"
                                                        {...input}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="fileds-from">
                                        <label>NPI :</label>
                                        <Field name="physicianNPI">
                                            {({ input, meta }) => (
                                                <div className="w-50">
                                                    <input
                                                        maxlength="45"
                                                        className="form-control border-0 bg-white"
                                                        {...input}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                </div>



                            </div>
                            <div className="row mein-white-from-background  h5_line pt-0">
                                <div className="col">
                                    <h5 className="py-4 text-decoration-underline"> Type of visit : </h5>
                                    <div className="d-flex gap-3 align-items-center">
                                        {visitTypeData.map((item) => (
                                            <>
                                                <Field
                                                    name={`visitType`}
                                                    component="input"
                                                    type="radio"
                                                    value={item}
                                                />
                                                <label className="mb-0">{item}</label>
                                            </>
                                        ))}
                                    </div>
                                    {errors["visitType"] && touched["visitType"] && (
                                        <p className="alert alert-danger " role="alert">
                                            {errors["visitType"]}
                                        </p>
                                    )}

                                </div>
                            </div>
                            <div className="row mein-white-from-background  h5_line pt-0">
                                <div className="col">
                                    <h5 className="py-4 text-decoration-underline" >Reason for missed visit : </h5>


                                    <div className="row ">
                                        {missedVisitReasonData.map((item) => (
                                            <div className="col-md-4 ">
                                                <div className="input_inside">
                                                    <div className="input_inside_label">
                                                        <Field
                                                            name={`missedVisitReasonData`}
                                                            component="input"
                                                            className="input_inside_checkbox"
                                                            type="checkbox"
                                                            value={item}
                                                        />
                                                        <label className="mb-0">{item}</label>
                                                    </div>
                                                    {item === "Physician/clinic appointment" && (
                                                        <Field name={`physicianClinicInput`}>
                                                            {({ input, meta }) => (
                                                                <>
                                                                    <div className="ml-4">
                                                                        <input
                                                                            type="text"
                                                                            {...input}
                                                                            className="form-control"

                                                                        />
                                                                        {meta.error && meta.touched && (
                                                                            <p className="alert alert-danger " role="alert">
                                                                                {meta.error}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Field>

                                                    )}
                                                    {item === "other" && (
                                                        <Field name={`missedVisitReasonOtherInput`}>
                                                            {({ input, meta }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        {...input}
                                                                    />
                                                                    {meta.error && meta.touched && (
                                                                        <p className="alert alert-danger " role="alert">
                                                                            {meta.error}
                                                                        </p>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Field>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                        {errors["missedVisitReasonData"] && touched["missedVisitReasonData"] && (
                                            <p className="alert alert-danger " role="alert">
                                                {errors["missedVisitReasonData"]}
                                            </p>
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className="row mein-white-from-background  h5_line pt-0">
                                <div className="col-md-12">
                                    <div>

                                        <h5 className="py-4 text-decoration-underline">Impact of Missed visit on Patient's Progress</h5>

                                        <>
                                            <Field
                                                name={"missedVisitImpact"}
                                                component="input"
                                                type="checkbox"
                                                value="missedVisitImpactchecked"
                                            />
                                            <label className="ml-2">No Impact</label>
                                        </>
                                        <Field
                                            name={`missedVisitImpactInput`}
                                            component="input"
                                            type="text"
                                            placeholder=""
                                            className="input-textSize"
                                        >
                                            {({ input, meta }) => (
                                                <>

                                                    <textarea
                                                        maxlength="320"
                                                        className="Additional_text  w-100"
                                                        {...input}
                                                        placeholder=""
                                                    />
                                                    {meta.error && meta.touched && (
                                                        <p className="meta_err alert alert-danger">
                                                            {meta.error}
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                        </Field>


                                    </div>
                                </div>
                            </div>
                            <div className="row mein-white-from-background  h5_line pt-0">
                                <div className="col">
                                    <div>
                                        <h5 className="py-4 text-decoration-underline"> Actions Taken : </h5>

                                    </div>
                                    <div className=" align-items-center flex-wrap row">

                                        {actionsTakenData.map((item) => (
                                            <>
                                                <div className="col-md-6 mb-4">
                                                    <div className="d-flex align-items-center ">
                                                        <Field
                                                            name={`actionsTakenData`}
                                                            component="input"
                                                            type="checkbox"
                                                            value={item}
                                                        />
                                                        <label className="mb-0 ml-2">{item}</label>
                                                        {item === "other" && (
                                                            <Field name={`actionsTakenInput`}>
                                                                {({ input, meta }) => (
                                                                    <>

                                                                        <input
                                                                            type="text"
                                                                            {...input}
                                                                            className="form-control ptinput"
                                                                        />


                                                                    </>
                                                                )}
                                                            </Field>
                                                        )}
                                                    </div>

                                                </div>
                                            </>

                                        ))}
                                    </div>
                                    {errors["actionsTakenData"] && touched["actionsTakenData"] && (
                                        <p className="alert alert-danger " role="alert">
                                            {errors["actionsTakenData"]}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="row mein-white-from-background  h5_line pt-0">
                                <h5 className="py-4 text-decoration-underline">Notifications</h5>
                                <div className="col-md-3">
                                    <label className="mx-2"> Date of notification : </label>
                                </div>
                                <div className="col-md-9 fileds-from">
                                    <Field
                                        name={`notificationDate`}
                                        component="input"
                                        type="date"
                                        className="form-control w-50 ptinput "
                                    />
                                </div>
                                <div className="col-md-3 fileds-from" >
                                    <label className="mx-2"> Time of Notification : </label>
                                </div>
                                <div className="col-md-9 fileds-from">
                                    <Field
                                        name={`notificationTime`}
                                        component="input"
                                        className="form-control w-50 ptinput"
                                        type="time"
                                    />
                                </div>
                                <div className="col-md-3 " >
                                    <label className="mx-2"> Individuals notified : </label>
                                </div>
                                <div className="col-md-9 Vital_Signs_row_gap">
                                    <div className="d-flex">

                                        <div className="radio-of-forms gap-3">
                                            {notificationsIndividualsData.map((item) => (
                                                <div>
                                                    <Field
                                                        name={`notificationsIndividualsData`}
                                                        component="input"
                                                        type="checkbox"
                                                        value={item}
                                                    />
                                                    <label className="mx-2">{item}</label>
                                                </div>
                                            ))}
                                        </div>
                                        <Field name={`notificationIndividualsInput`}>
                                            {({ input, meta }) => (
                                                <>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            {...input}
                                                            className="form-control w-100 ptinput"

                                                        />
                                                        {meta.error && meta.touched && (
                                                            <p className="alert alert-danger " role="alert">
                                                                {meta.error}
                                                            </p>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Field>
                                    </div>
                                    <div>
                                        {errors["notificationsIndividualsData"] && touched["notificationsIndividualsData"] && (
                                            <p className="alert alert-danger " role="alert">

                                                {errors["notificationsIndividualsData"]}
                                            </p>
                                        )}
                                    </div>

                                </div>
                                <div className="col-md-3" >
                                    <label className="mx-2"> Method(s) of Notification : </label>
                                </div>
                                <div className="col-md-9 Vital_Signs_row_gap">
                                    <div className="d-flex ">
                                        <div className="radio-of-forms gap-3">

                                            {notificationsMethodsData.map((item) => (
                                                <div>
                                                    <Field
                                                        name={`notificationsMethodsData`}
                                                        component="input"
                                                        type="checkbox"
                                                        value={item}
                                                    />
                                                    <label className="mx-2">{item}</label>
                                                </div>
                                            ))}
                                        </div>
                                        <Field name={`notificationMethodsInput`}>
                                            {({ input, meta }) => (
                                                <>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            {...input}
                                                            className="form-control w-100"

                                                        />
                                                        {meta.error && meta.touched && (
                                                            <p className="alert alert-danger " role="alert">
                                                                {meta.error}
                                                            </p>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Field>
                                    </div>
                                    <div>
                                        {errors["notificationsMethodsData"] && touched["notificationsMethodsData"] && (
                                            <p className="alert alert-danger " role="alert">

                                                {errors["notificationsMethodsData"]}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* comments */}
                            <div className="row mein-white-from-background h5_line pt-0">
                                <h5 className="py-4 text-decoration-underline">Comments :</h5>
                                <Field
                                    name="Comments_homebound"
                                    component="input"
                                    type="text"
                                    placeholder=""
                                    className="input-textSize"
                                >
                                    {({ input, meta }) => (
                                        <div>
                                            <textarea
                                                maxlength="320"
                                                className="Additional_text"
                                                {...input}
                                                type="text"
                                            />
                                            {meta.error && meta.touched && (
                                                <p className="alert alert-danger " role="alert">
                                                    {meta.error}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="row mein-white-from-background">
                                <div className="row  mt-3 mb-4 justify-content-between ">
                                    <div className="col-md-6">
                                        <div>
                                            <p>Therapist Signature : </p>
                                            <Field name="therapistSigns">
                                                {({ input, meta }) => (
                                                    <div>
                                                        <input
                                                            className="form-control mb-3 "
                                                            {...input}
                                                            placeholder="Therapist Signature"
                                                        />
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-4">
                                        <div className="d-flex ">
                                            <p className="w-25">Physician Name :</p>
                                            <input
                                                className="border-0 bg-white form-control"
                                                value={
                                                    staffDetails &&
                                                    staffDetails.staffingDetails &&
                                                    staffDetails.staffingDetails.physicianName
                                                }
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                        <div className="row d-flex  align-items-center  ">
                                            <div className="col-md-6">
                                                <div className="d-flex align-items-start">
                                                    <label className="no-wrap-white-space mb-0">
                                                        Physician Signature :
                                                    </label>
                                                    <Field
                                                        name="Physiciansignature"
                                                        className="Additional_text  ml-3 "
                                                        component="textarea"
                                                        placeholder="Physician signature"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="d-flex align-items-start mb-0">
                                                    <label className="mb-0">Date :</label>
                                                    <Field
                                                        name="date"
                                                        className="Additional_text ml-3 "
                                                        component="textaarea"
                                                        placeholder="Date  "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        className="m-0 Approve-btns rounded-1 py-2"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                )
                }
            />
        </div >
    );
};

export default MissedVisit;
