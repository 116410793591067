import { createSlice } from "@reduxjs/toolkit";
import { blockTherapist, getAllBlockedTherapist, getTherapistByAgencyId, therapistByDiscipline, unblockUser, userSearchDropDown } from "../actions/agency";

const initialState = {
  dropDownList: [],
  therapistDetails: [],
  userCount: 0,
  isLoading: false,
  blocked: [],
  blockedCount: null,
  agencyDetailProfile: null,
  isAnyErrorMessage: false,
  isAnyErrorMessageDefaultRate: false,
  agencyDetial: {},
  therapistByDiscipline: {
    pt: [],
    ot: [],
    slp: [],
    pta: [],
    cota: []
  }
};

const agencySignpSlice = createSlice({
  name: "agencySlice",
  initialState,
  reducers: {
    agencyInitialValueDetail(state, payload) {
      state.agencyDetailProfile = payload.payload
    },
    ProfileValidState(state, payload) {
      state.isAnyErrorMessage = payload.payload
    },
    profileValidStateDefaultRate(state, payload) {
      state.isAnyErrorMessageDefaultRate = payload.payload
    },
    agencyDetailForPic(state, payload) {
      state.agencyDetial = payload.payload
    }
  },

  extraReducers: {
    [userSearchDropDown.fulfilled]: (state, { payload }) => {
      state.dropDownList = payload.data;
    },
    [userSearchDropDown.rejected]: (state, { error, payload }) => {
      state.isLoading = false;
      state.agencyRejectedMessage = {
        default:
          error && error.message ? error.message : "Something went wrong.",
      };
    },
    [therapistByDiscipline.fulfilled]: (state, { payload }) => {
      state.therapistByDiscipline[payload.discipline] = payload.data.data;
    },
    [therapistByDiscipline.rejected]: (state, { error, payload }) => {
      state.isLoading = false;
    },
    [getTherapistByAgencyId.fulfilled]: (state, { payload }) => {
      state.therapistDetails = payload && payload.result && payload.result.rows;
      state.userCount = payload && payload.result && payload.result.count;
      state.isLoading = false;
    },
    [getTherapistByAgencyId.pending]: (state) => {
      state.therapistDetails = [];
      state.userCount = 0;
      state.isLoading = true;
    },
    [getTherapistByAgencyId.rejected]: (state) => {
      state.therapistDetails = [];
      state.userCount = 0;
      state.isLoading = false;
    },
    [blockTherapist.fulfilled]: (state, { payload }) => {
      state.blocked = payload
      state.isLoading = false;
    },
    [blockTherapist.pending]: (state) => {
      state.isLoading = true;
    },
    [blockTherapist.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllBlockedTherapist.fulfilled]: (state, { payload }) => {
      state.blocked = payload.blockedUserDetail.rows;
      state.blockedCount = payload.blockedUserDetail.count;
      state.isLoading = false;
    },
    [getAllBlockedTherapist.pending]: (state, { payload }) => {
      state.blocked = null;
      state.blockedCount = null;
      state.isLoading = true;
    },
    [getAllBlockedTherapist.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [unblockUser.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [unblockUser.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [unblockUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});


export const {
  agencyInitialValueDetail,
  ProfileValidState,
  profileValidStateDefaultRate,
  agencyDetailForPic
} = agencySignpSlice.actions;
export default agencySignpSlice.reducer;
