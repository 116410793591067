import { defaultStatus, docuSignDocs } from "./auth";
import agencyimg from "../images/usertable01.svg";
import assistantimg from "../images/usertable02.svg";
import supervisingimg from "../images/usertable03.svg";
import patientImage from "../images/user.png";

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()])[A-Za-z\d@$!%*?&#^()]{8,64}$/;
export const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
];
export const insurenceOptions = [
  { value: "Medicare", label: "Medicare" },
  { value: "Medicare Advantage", label: "Medicare Advantage" },
  { value: "Private Insurance", label: "Private Insurance" },
  { value: "Cash pay", label: "Cash pay" },
];

export const primaryDignosisData = [
  { name: "ICD-10 Code", id: 1 },
  { name: "ICD-11 Code", id: 2 },
  { name: "ICD-12 Code", id: 3 },
  { name: "ICD-13 Code", id: 4 },
  { name: "ICD-14 Code", id: 5 },
  { name: "ICD-15 Code", id: 6 },
  { name: "ICD-16 Code", id: 7 },
  { name: "ICD-17 Code", id: 8 },
  { name: "ICD-18 Code", id: 9 },
];

export const staffViewFromTherapist = [
  "diagnosis",
  // "address",
  "sex",
  "dob",
  "agencyname",
  "agencyphone",
  "disciplineneeded",
  "initialvisittype",
  "initialvisitdate",
  "ptaallowed",
  "specialrequests",
];

export const staffViewFromAdmin = [
  "diagnosis",
  "sex",
  "dob",
  "agencyname",
  "agencyphone",
  "disciplineneeded",
  "initialvisittype",
  "initialvisitdate",
  "ptaallowed",
  "specialrequests",
];

export const unfinishedProfileFromAdmin = [
  "diagnosis",
  "sex",
  "dob",
  "insurance",
  "agencyname",
  "agencyphone",
  "physician",
  "physicianphone",
  "emergencycontactname",
  "emergencycontactnumber",
  "patientemailaddress",
  "initialvisitdate",
  "specialrequests",
  "disciplineneeded",
  "initialvisittype",
];

export const staffViewFromAdminPatientChart = [
  "diagnosis",
  "sex",
  "dob",
  "insurance",
  "agencyname",
  "agencyphone",
  "physician",
  "physicianphone",
  "emergencycontactname",
  "emergencycontactnumber",
  "patientemailaddress",
  "specialrequests",
];

export const getUserDetailParams = {
  paymentDetail: "paymentDetail",
  secondaryEmails: "secondaryEmails",
  representativeEmails: "representativeEmails",
  userNotifications: "userNotifications",
  coverageAreas: "coverageAreas",
  userCredentials: "userCredentials",
  userProfessionalData: "userProfessionalData",
  compliance: "compliance",
};

export const withoutAuthRoute = [
  "userrejected",
  "supervisingtherapist",
  "agencysignup",
  "supervisingtherapist",
  "assistanttherapist",
  "UserUnderReview",
  "docusign",
  "competencydocusign",
  "userrejected/:id",
  "compianceenvalop",
];

export const credentialsLabel = {
  therapylicense: "Therapy License",
  driverslicense: "Drivers License",
  autoinsurance: "Auto Insurance",
  professionallaibilityinsurance: "Professional Liability Insurance",
  cprcertification: "CPR Certification",
  physical: "Physical",
  tbtest: "TB Test",
  fluanddeclination: "Flu Vaccination",
  fluvaccination: "Flu Vaccination",
  hepatitisB: "Hepatitis B",
  covidrecord: "Covid-19",
  businesslicense: "Business License",
  w9: "W9",
};

export const defaultCaseStatus = {
  caseopen: "caseopen",
  incomplete: "incomplete",
  hospitilized: "hospitilized",
  discharged: "discharged",
  hold: "hold",
  awaiting: "awaiting",
  awaitingrn: "awaitingrn",
  awaitingpt: "awaitingpt",
  awaitingot: "awaitingot",
  awaitingst: "awaitingst",
  scheduled: "Scheduled",
  pending: "pending",
  approved: "approved",
  awaitinghha: "awaitinghha",
  tobescheduled: "tobescheduled",
};

export const usStateList = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
];

export const blockTabs = {
  agency: "agency",
  assistanttherapist: "assistanttherapist",
  supervisingtherapist: "supervisingtherapist",
};

export const viewStatueName = (key, from) => {
  let name = defaultStatus.pending;
  switch (key) {
    case defaultStatus.pending:
      name = "Incomplete";
      break;
    case defaultStatus.awaitingagencyapproval:
      name = "Incomplete";
      break;
    case defaultStatus.unfinishedprofile:
      name = "Incomplete";
      break;
    case defaultStatus.staffedreferral:
      name = "Completed";
      break;
    default:
      break;
  }

  return name;
};

export const viewCaseStatueName = (key, from) => {
  let name = defaultCaseStatus.incomplete;
  switch (key) {
    case defaultCaseStatus.caseopen:
      name = "Case Open";
      break;
    case defaultCaseStatus.incomplete:
      name = "Incomplete";
      break;
    case defaultCaseStatus.hospitilized:
      name = "Hospitilized";
      break;
    case defaultCaseStatus.discharged:
      name = "Discharged";
      break;
    case defaultCaseStatus.awaitinghha:
      name = "Awaiting HHA SOC";
      break;
    case defaultCaseStatus.hold:
      name = "Hold";
      break;
    case defaultCaseStatus.awaiting:
      name = "Awaiting SOC/ROC";
      break;
    case defaultStatus.unfinishedprofile:
      name = "Incomplete";
      break;
    default:
      break;
  }

  return name;
};

export const joinArrayObj = (a, attr, subAttr) => {
  var out = [];

  for (var i = 0; i < a.length; i++) {
    if (subAttr) {
      out.push(a[i][attr][subAttr]);
    } else {
      out.push(a[i][attr]);
    }
  }

  return out.join(", ");
};

export const disciplineType = [
  { name: "pt", isStaffing: true },
  { name: "pta", isStaffing: false },
  { name: "ot", isStaffing: true },
  { name: "st", isStaffing: false },
  { name: "cota", isStaffing: false },
  { name: "slp", isStaffing: true },
];

export const complianceCxpireDayType = {
  compliance_30: "compliance_30",
  compliance_15: "compliance_15",
  compliance_7: "compliance_7",
  compliance_0: "compliance_0",
};

export const complianceExpireNotification = (credential) => {
  let message = "";
  let expireTypes = Object.keys(complianceCxpireDayType);

  if (credential.dayDiff < 0) {
    return "For continue please update your compliance credentials";
  }
  for (var i = 0; i < expireTypes.length; i++) {
    if (expireTypes[i].includes(credential.dayDiff)) {
      message = `Compliance Credentials is expire in ${
        credential.dayDiff > 0 ? credential.dayDiff + " days" : "today"
      } `;
      return message;
    }
  }

  return message;
};

export const isComplianceExpired = (credential) => {
  let expired = false;
  let findCompliance = credential.find(
    (item) => item.type === docuSignDocs.compliance
  );
  if (findCompliance) {
    if (findCompliance.dayDiff < 0) {
      expired = true;
    }
  }
  return expired;
};

export const statusBaseColorChange = (key) => {
  let statusColorClass = "status-td-orange";
  switch (key) {
    case defaultCaseStatus.caseopen:
      statusColorClass = "status-td-green";
      break;
    case defaultCaseStatus.incomplete:
      statusColorClass = "status-td-orange";
      break;
    case defaultCaseStatus.hospitilized:
      statusColorClass = "status-td-red";
      break;
    case defaultCaseStatus.discharged:
      statusColorClass = "status-td-gray";
      break;
    case defaultCaseStatus.hold:
      statusColorClass = "status-td-pink";
      break;
    case defaultCaseStatus.awaiting:
      statusColorClass = "status-td-blue";
      break;
    case defaultStatus.unfinishedprofile:
      statusColorClass = "status-td-orange";
      break;
    case defaultStatus.incomplete:
      statusColorClass = "status-td-orange";
      break;
    case defaultStatus.awaitingagencyapproval:
      statusColorClass = "status-td-orange";
      break;
    case defaultStatus.rejected:
      statusColorClass = "status-td-red";
      break;
    case defaultStatus.awaitinghha:
      statusColorClass = "status-td-blue";
      break;
    case visitStatus.completed:
      statusColorClass = "status-td-green";
      break;
    case visitStatus.needreview:
      statusColorClass = "status-td-blue";
      break;
    case visitStatus.pendingnotes:
      statusColorClass = "status-td-orange";
      break;
    case visitStatus.needcorrection:
      statusColorClass = "status-td-red";
      break;
    default:
      break;
  }
  return statusColorClass;
};

export const blockInvalidChar = (e) => {
  if (e) {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") e.preventDefault();
    const matchArray = ["e", "E", "+", "-"];
    return matchArray.includes(e.key) && e.preventDefault();
  }
};

export const mrnInvalidChar = (e) => {
  if (e) {
    const matchArray = ["e", "E", "+", "-", "."];
    return matchArray.includes(e.key) && e.preventDefault();
  }
};

export const rateDiscipline = {
  pt: "pt",
  pta: "pta",
  ot: "ot",
  st: "st",
  cota: "cota",
  slp: "slp",
  cotaRateLabel: "COTA Follow-up",
  ptaRateLabel: "PTA Follow-up",
};

export const adminDashboardTabs = {
  reviewnewusers: "reviewNewUserCount",
  verifynewcredentials: "verifyCredentialCount",
  reviewblocklist: "reviewBlockListCount",
  reviewbackgroundchecks: "reviewBackgroundCount",
};

export const timeStamp = (date) => {
  const Newdate = new Date(date);
  return {
    date: Newdate.toDateString(),
    time: Newdate.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  };
};

export const chatIconsByRole = {
  agency: agencyimg,
  supervisingtherapist: supervisingimg,
  assistanttherapist: assistantimg,
  patient: patientImage,
};
export const MessageStatusName = {
  other: { value: "other", displayName: "Other" },
  hospitalization: { value: "hospitalization", displayName: "Hospitalization" },
  reschedulerequest: {
    value: "reschedulerequest",
    displayName: "Reschedule Request",
  },
  changeinplaneofcare: {
    value: "changeinplaneofcare",
    displayName: "Change In Plane Of Care",
  },
  changeofpatientinformation: {
    value: "changeofpatientinformation",
    displayName: "Change Of Patient Information",
  },
  unabletoschedule: {
    value: "unabletoschedule",
    displayName: "Unable to Schedule",
  },
  fall: { value: "fall", displayName: "Fall" },
  restaffrequest: { value: "restaffrequest", displayName: "Restaff Request" },
  temporarycoveragerequest: {
    value: "temporarycoveragerequest",
    displayName: "Temporary Coverage Request",
  },
  patientseenbyanotheragency: {
    value: "patientseenbyanotheragency",
    displayName: "Patient seen by another Agency",
  },
};
export const dateFormatter = (data) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const parsed_date = new Date(data);
  const formatted_date = parsed_date.toLocaleDateString("en-US", options);
  return formatted_date;
};
export const messageTypesForAgency = [
  {
    value: "changeofpatientinformation",
    label: "Change Of Patient Information",
  },
  { value: "changeinplaneofcare", label: "Change In Plane Of Care" },
  { value: "reschedulerequest", label: "Reschedule Request" },
  { value: "hospitalization", label: "Hospitalization" },
  { value: "other", label: "Other" },
];

export const messageTypesForTherapist = [
  {
    value: "unabletoschedule",
    label: "Unable to Schedule",
  },
  { value: "fall", label: "Fall" },
  { value: "hospitalization", label: "Hospitalization" },
  { value: "restaffrequest", label: "Restaff Request" },
  { value: "temporarycoveragerequest", label: "Temporary Coverage Request" },
  {
    value: "patientseenbyanotheragency",
    label: "Patient seen by another Agency",
  },
  { value: "other", label: "Other" },
];

export const patientDocumentTypes = {
  others: "others",
};

export const urgentTypeArray = [
  "hospitalization",
  "reschedulerequest",
  "changeinplaneofcare",
  "changeofpatientinformation",
  "unabletoschedule",
  "fall",
  "restaffrequest",
  "temporarycoveragerequest",
  "patientseenbyanotheragency",
];

export const allowedExtensions = ["pdf"];

export const RateTabsName = {
  PT: "Physical Therapist (PT)",
  OT: "Occupational Therapist(OT)",
  SLP: "Speech Language Pathologist(SLP)",
  COTA: "Certified Occupational Therapist Assistant (COTA)",
  PTA: "Physical Therapist Assistant (PTA)",
};

export const AssistantDiscipline = ["pta", "cota"];
export const SupervisingDisciplines = ["pt", "ot", "slp", "st"];
export const openCaseLabels = ["SOC OASIS", "ROC OASIS", "SOC Non-OASIS"];
export const closeCaseLabels = [
  "Evaluation",
  "Discharge OASIS",
  "Discharge",
  "Re-assessment",
  "Recert Non-OASIS",
  "Recert OASIS",
];

export const createNewOrderBody = {
  report_sku: "HIRE3",
  terms_agree: "Y",
};

export const patientDetailsTabs = {
  patientprofile: "patientprofile",
  patientdocument: "patientdocument",
  episodes: "episodes",
  staffassignment: "staffassignment",
};

export const staffFormTypesConstants = {
  Consents: "Consents",
  Evaluation: "Evaluation",

  RegularDischarge: "RegularDischarge",
  StartOfCareOASIS: "Startof Care OASIS",
  MedicationProfile: "MedicationProfile",
  PhysicianOrder: "PhysicianOrder",
  ReAssessment: "Re-Assessment",
  NOMNC: "NOMNC",
  FOLLOWUP: "Follow-Up",
  C19QUESTIONAIRE: "covid-19Questionnaire",
  MissedVisit: "MissedVisit",
  DC: "Discharge",
  NVDC: "Non-VisitDischarge",
  NVDCOASIS: "Non-VisitDischargeOASIS",
  OASISDC: "OASISDischarge",
  OASISNVDC: "OASISNon-Visit Discharge",
  Assessment: "Assessment",
  NONVISITDISCHARGE: "Non-Visit Discharge",
  FollowUp: "Follow Up",
};

export const staffFormStatus = {
  pending: "pending",
  needReview: "needreview",
  completed: "completed",
};

export const todayDate = new Date().toISOString().split("T")[0];

export const visitStatus = {
  scheduled: "scheduled",
  completed: "completed",
  awaitingauth: "awaitingauth",
  incompletedoc: "incompletedoc",
  missvisitneeded: "missvisitneeded",
  frequencyextensionneeded: "frequencyextensionneeded",
  pendingnotes: "pendingnotes",
  needcosigned: "needcosigned",
  visitnotes: "visitnotes",
  needreview: "needreview",
  needcorrection: "needcorrection",
  tobescheduled: "tobescheduled",
  frequencyandphysicianorder: "frequencyandphysicianorder",
};

export const canSubmitForm = [
  "tobescheduled",
  "scheduled",
  "needcorrection",
  "incompletedoc",
  "pendingnotes",
];

export const minimumDate = (date) => {
  let current = null;
  const currentDate = new Date();
  const givenDate = new Date(date);
  if (currentDate > givenDate) {
    current = currentDate.toISOString().split("T")[0];
  } else if (currentDate < givenDate) {
    current = givenDate.toISOString().split("T")[0];
  } else {
    current = currentDate.toISOString().split("T")[0];
  }
  return current;
};

export const getObjectInnerValues = (obj, ...keys) => {
  let currentObj = obj;
  for (const key of keys) {
    if (currentObj.hasOwnProperty(key)) {
      currentObj = currentObj[key];
    } else {
      return undefined;
    }
  }
  return currentObj;
};

export const phoneNumberToUSFormat = (event, input) => {
  var inputValue = event.target.value.replace(/\D/g, "");
  inputValue = inputValue.substring(0, 10);
  var areaCode = inputValue.substring(0, 3);
  var prefix = inputValue.substring(3, 6);
  var lineNumber = inputValue.substring(6, 10);
  var formattedNumber =
    `${areaCode && "("}` +
    areaCode +
    `${prefix && ")"}` +
    prefix +
    `${lineNumber && "-"}` +
    lineNumber;
  event.target.value = formattedNumber;
  input.onChange(event);
};

export function formatPinCode(event, input) {
  let value = event.target.value.replace(/\D/g, ""); // Remove non-digits
  var areaCode = value.substring(0, 5);
  var prefix = value.substring(5, 10);
  // const formattedValue = value.replace(/^(\d{5})(\d{0,4})$/, "$1-$2");
  var formattedValue = areaCode + `${prefix && "-"}` + prefix;
  event.target.value = formattedValue;
  input.onChange(event);
}
export function formatPinCodeValue(inputValue) {
  if (!inputValue) return "";
  let value = inputValue.replace(/\D/g, "");
  if (value.length > 5) {
    return value.replace(/^(\d{5})(\d{0,4})$/, "$1-$2");
  } else {
    return value; // Return the original value if it's 5 digits or less
  }
}

export const dateFormatChange = (givenDate) => {
  let dateObject = new Date(givenDate);
  let formattedDate = dateObject.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    timeZone: "UTC",
  });
  return formattedDate;
};

export const notificationRedirection = {
  agency: {
    NEWREFERRALS: "/staffing",
    CREDENTIALEXPIRATIONS: "/myprofile/",
    STAFFING: "",
  },
  therapist: {
    NEWREFERRALS: "",
    CREDENTIALEXPIRATIONS: "",
    STAFFING: "",
  },
};

export const profileTabs = {
  personalinfo: "personalinfo",
  profexperience: "profexperience",
  paymentinfo: "paymentinfo",
  defaultrate: "defaultrate",
  credentials: "credentials",
  blocklist: "blocklist",
  password: "password",
};

export const caseManagementTabs = {
  awaitingsocroc: "awaitingsocroc",
  patientneedscheduling: "patientneedscheduling",
  newfrequencies: "newfrequencies",
  pendingDMEs: "pendingDMEs",
  authorization: "authorization",
  lastvisittypeapproval: "lastvisittypeapproval",
};

export const accountingTabs = {
  invoice: "invoice",
  bills: "bills",
};

export const socketStates = {
  connect: "connect",
  disconnect: "disconnect",
};

export const displayVisitStatus = {
  pendingnotes: "Pending",
  needreview: "Sent to HHA",
};

export const totalVisitNumber = 16;

export const sortPatientDocBasedOnDocType = (docTypes, documents) => {
  let sortedList = [];
  if (docTypes && docTypes.length > 0 && documents && documents.length > 0) {
    docTypes.map((item) => {
      if (item.key === "others") {
        let fidDoc = documents.filter(
          (doc) => doc.userDocumentType.key === item.key
        );
        if (fidDoc && fidDoc.length > 0) {
          sortedList = sortedList.concat(fidDoc);
        }
      } else {
        let fidDoc = documents.find(
          (doc) => doc.userDocumentType.key === item.key
        );
        if (fidDoc) {
          sortedList.push(fidDoc);
        }
      }
    });
  }
  return sortedList;
};

export const getLastWednesdayFromDate = (date) => {
  const copiedDate = new Date(date);

  let dayOfWeek = copiedDate.getDay();

  if (dayOfWeek === 3) {
    copiedDate.setDate(copiedDate.getDate() - 1);
    dayOfWeek = 2;
  }
  let diff = (dayOfWeek + 7 - 3) % 7;

  copiedDate.setDate(copiedDate.getDate() - diff);

  return copiedDate;
};

export const compareDates = (date1, date2) => {
  // Extract year, month, and day components of the dates
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();
  const day2 = date2.getDate();

  // Compare the components
  if (year1 === year2 && month1 === month2 && day1 === day2) {
    return 0; // Dates are equal
  } else if (
    year1 < year2 ||
    (year1 === year2 && month1 < month2) ||
    (year1 === year2 && month1 === month2 && day1 < day2)
  ) {
    return -1; // date1 is lower than date2
  } else {
    return 1; // date1 is greater than date2
  }
};

export const assessmentSectionOptions = {
  section1: [
    { key: "reassessment", name: "Reassessment" },
    { key: "noReassessment", name: "No Reassessment" },
    { key: "nonOasisRecertification", name: "Recertification non-OASIS" },
    { key: "oasisRecertification", name: "Recertification OASIS" },
  ],
  section2: [
    { key: "oasisDischarge", name: "OASIS Discharge" },
    { key: "regularDischarge", name: "Regular Discharge" },
  ],
};

export const lastVisitTypes = [
  "lastvisittype_section_a",
  "lastvisittype_section_b",
];
export const formSortingArr = [
  "Evaluation",
  "Follow Up",
  "Discharge",
  "Regular Discharge",
  "OASIS Discharge",
  "Physician Order",
];

export const formNameStr = [
  "Evaluation",
  "Follow Up",
  "Regular Discharge",
  "OASIS Discharge",
];

export function arrayOrder(array, order, key) {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
}

export const episodeStatus = { active: "active", inActive: "inactive" };

export function changeDateFormat(dateStr, inputFormat, outputFormat) {
  // Helper function to parse a date string based on a given format
  function parseDate(dateStr, format) {
    const formatParts = format.split(/[^A-Za-z]/);
    const dateParts = dateStr.split(/[^0-9]/);

    let year, month, day;

    for (let i = 0; i < formatParts.length; i++) {
      if (formatParts[i] === "YYYY") year = parseInt(dateParts[i]);
      if (formatParts[i] === "MM") month = parseInt(dateParts[i]) - 1;
      if (formatParts[i] === "DD") day = parseInt(dateParts[i]);
    }

    return new Date(year, month, day);
  }

  // Helper function to format a Date object into a string based on a given format
  function formatDate(date, format) {
    const map = {
      YYYY: date.getFullYear(),
      MM: ("0" + (date.getMonth() + 1)).slice(-2),
      DD: ("0" + date.getDate()).slice(-2),
    };

    return format.replace(/YYYY|MM|DD/g, (matched) => map[matched]);
  }

  // Parse the input date string to a Date object
  const dateObj = parseDate(dateStr, inputFormat);

  // Format the Date object to the desired output format
  return formatDate(dateObj, outputFormat);
}
export const stripePaymentStatus = {
  processing: "processing",
  succeeded: "succeeded",
  amount_capturable_updated: "amount_capturable_updated",
  payment_failed: "payment_failed",
};

export const modifyFormName = (name) => {
  if (name.toLowerCase() === staffFormTypesConstants.Evaluation.toLowerCase()) {
    return "Physical Therapy Evaluation";
  } else if (
    name.toLowerCase() ===
    staffFormTypesConstants.NONVISITDISCHARGE.toLowerCase()
  ) {
    return "Non-Visit Discharge Summary";
  } else {
    return name;
  }
};
