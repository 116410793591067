import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getStaffingByStatus,
  getStaffingDetailsById,
  updateStaffingUser,
} from "../../Redux/actions/staffing";
import { currentRole, defaultStatus } from "../../utils/auth";

const CancelApprovalModal = (props) => {
  const [optionValidation, setOptionValidation] = useState(false);

  const dispatch = useDispatch();
  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);
  function handleSubmit(values) {
    let temp = {
      cancelReason: {
        reason: values?.cancelReason,
        options: values?.cancelOptions,
      },
      status:
        props && props?.therapistData && props?.therapistData?.id
          ? defaultStatus.rejected
          : defaultStatus.cancelled,
      role: currentRole.agency,
      staffUserId: staffDetail?.id,
      therapistId: props?.isAllDeclined
        ? 0
        : props?.therapistData?.therapistId
          ? props?.therapistData?.therapistId
          : props?.therapistData?.id,
      isAllDeclined: props?.isAllDeclined,
    };
    if (temp && temp?.cancelReason && temp?.cancelReason?.options?.length > 0) {
      setOptionValidation(false);
      dispatch(updateStaffingUser(temp)).then((e) => {
        if (props.from === "therapist") {
          let query = "therapist=true";
          dispatch(getStaffingDetailsById(`${staffDetail?.id}?${query}`));
        } else {
          dispatch(
            getStaffingByStatus({
              pageSize: 10,
              pageNo: 1,
              status: [props.from],
            })
          );
        }
        if (props && props?.therapistData && props?.therapistData?.id) {
          toast.success("Therapist declined successfully.");
        } else {
          toast.success("Referral cancelled successfully.");
        }
        props?.handleClose();
        props?.handlePendingClose();
      });
    } else {
      setOptionValidation(true);
    }
  }

  const setValidation = (values) => {
    let errors = {};
    if (values && values?.cancelOptions?.includes("Other")) {
      errors["cancelReason"] = "Please provide a reason.";
    }
    if (values && values?.cancelReason) {
      delete errors.cancelReason;
    }
    if (values?.cancelOptions && values?.cancelOptions?.length > 0) {
      setOptionValidation(false);
    }
    return errors;
  };

  return (
    <Modal
      className={props.modalClass}
      show={props.show}
      onHide={props.handleClose}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header className="closeButton border-0" closeButton></Modal.Header>

      <Form
        onSubmit={handleSubmit}
        validate={setValidation}
        render={({ handleSubmit, submitting, values, errors }) => (
          <form onSubmit={handleSubmit}>
            <div className="offer mx-5">
              <h1>Reason for Cancellation</h1>
            </div>
            <div className="row m-5">
              <div className="row">
                <div className="col-md-4 p-0">
                  <div className="custom-control custom-checkbox custom-control-inline p-0">
                    <label className="container-card">
                      <Field
                        name="cancelOptions"
                        component="input"
                        type="checkbox"
                        value="Patient refused"
                        className="custom-control-input"
                        id="Patient refused"
                      />
                      <span className=" checkmark1"></span>

                      <p className="input-text-select"> Patient refused</p>
                    </label>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="custom-control custom-checkbox custom-control-inline p-0">
                    <label className="container-card">
                      <Field
                        name="cancelOptions"
                        component="input"
                        type="checkbox"
                        value="Referral source cancelled"
                        className="custom-control-input"
                        id="Referral source cancelled"
                      />
                      <span className=" checkmark1"></span>

                      <p className="input-text-select">
                        Referral source cancelled
                      </p>
                    </label>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="custom-control custom-checkbox custom-control-inline p-0">
                    <label className="container-card">
                      <Field
                        name="cancelOptions"
                        component="input"
                        type="checkbox"
                        value="Staffed elsewhere"
                        className="custom-control-input"
                        id="Staffed elsewhere"
                      />
                      <span className=" checkmark1"></span>
                      <p className="input-text-select">Staffed elsewhere</p>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 p-0">
                  <div className="custom-control custom-checkbox custom-control-inline p-0">
                    <label className="container-card m-0 input-text-select">
                      <Field
                        name="cancelOptions"
                        component="input"
                        type="checkbox"
                        value="Other"
                        className="custom-control-input"
                        id="Other"
                      />
                      <span className=" checkmark1"></span>
                      <p className="input-text-select">Other </p>
                    </label>
                  </div>
                </div>
              </div>
              {optionValidation && (
                <p
                  className="alert alert-danger w-100"
                  style={{ color: "red" }}
                >
                  Please select option to cancel patient
                </p>
              )}
              {values &&
                values?.cancelOptions &&
                values?.cancelOptions?.includes("Other") && (
                  <div className="row">
                    <div className="col-md-12 custom-control   p-0 mt-3">
                      <Field name="cancelReason">
                        {({ input, meta }) => (
                          <div>
                            <textarea
                              className="discribe-input"
                              type="textarea"
                              {...input}
                              placeholder="Describe Other Reason"
                            />
                            {meta.touched && meta.error && (
                              <p
                                className="alert alert-danger w-100"
                                style={{ color: "red" }}
                              >
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                )}
              <div className="row">
                <div>
                  <button
                    className="dicsribe-btn"
                    type="submit"
                    disabled={submitting}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

export default CancelApprovalModal;
