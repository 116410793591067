import React, { useState, useEffect } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { blockInvalidChar, rateDiscipline } from "../utils/helper";

const VisitRates = ({ defaultRates, values, rateError }) => {
  const [dataValue, setDataValue] = useState(0);
  const renameFormat = (name) => {
    return name
      .replace(/ /g, "")
      .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>/]/gi, "")
      .toLocaleLowerCase();
  };

  const filteredRates = defaultRates.filter((item) => {
    if (item.id === 1 && values.disciplines.includes(rateDiscipline.pt)) {
      return item;
    } else if (
      item.id === 2 &&
      values.disciplines.includes(rateDiscipline.ot)
    ) {
      return item;
    } else if (
      item.id === 3 &&
      values.disciplines.includes(rateDiscipline.slp)
    ) {
      return item;
    }
  });

  useEffect(() => {
    setDataValue(0);
  }, [values.disciplines]);
  return (
    <div className="container-fluid">
      <div className="visit_sec">
        <h2 className="cs-heading pt-0">Default Rates</h2>
        <div className={`col-md-12 d-flex  mobile_wrap mt-3 p-0  head-margin  ${filteredRates.length === 2 ? "gap-4" : "justify-content-between"}`}
        >
          {filteredRates &&
            filteredRates.map((e, index) => {
              return (
                <h3
                  className={`ad-headd pt-3  pb-3 ${index === dataValue && "ad-activee"
                    }`}
                  key={e.name}
                  onClick={() => setDataValue(index)}
                >
                  {e.displayName}

                </h3>
              );
            })}
        </div>

        {values &&
          Object.keys(values)
            .filter((tab) =>
              filteredRates.find((a) => renameFormat(a.name) === tab)
            )
            .map((item, index) => (
              <FieldArray name={item} key={item}>
                {({ fields }) =>
                  fields.map((name, ind) => {
                    if (index === dataValue) {
                      return (
                        <Field name={`${name}.rate`} key={`${item}_${ind}`}>
                          {({ input, meta }) => (
                            <div className="row d-flex justify-content-between mb-3">
                              <label className="col-md-8 ad-labels  ">
                                {values[item][ind].name}
                              </label>
                              <div className="col-md-4 flex-column">
                                <InputGroup className="mb-3">
                                  <InputGroup.Text
                                    id="basic-addon1"
                                    className="pr-0 pt-0 pb-0 border-end-0 text-primary"
                                    style={{
                                      background: " none",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    $
                                  </InputGroup.Text>
                                  <Form.Control
                                    className="border-start-0 pl-1 input-rate-visit text-primary"
                                    {...input}
                                    aria-describedby="basic-addon1"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    onKeyDown={(e) => blockInvalidChar(e)}
                                  />
                                </InputGroup>

                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger top-11"
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </Field>
                      );
                    }
                  })
                }
              </FieldArray>
            ))}
        {rateError && (
          <p className="alert alert-danger mb-3 ">Enter All Rates</p>
        )}
      </div>
    </div>
  );
};

export default VisitRates;
