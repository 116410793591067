/* eslint-disable no-mixed-operators */
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CommonHead2 } from "./common/CommonHead2";
import { Row, Col } from "react-bootstrap";
import CommonTable from "./common/CommonTable";
import Searchbar from "../images/search_grey_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { currentRole, defaultStatus } from "../utils/auth";
import { MyModal2 } from "../pages/modals/MyModal2";
import InvoiceView from "../pages/modals/InvoiceView";
import { debounce } from "lodash";
import {
  createPayment,
  getInvoiceData,
  stripeRetrieveInstantPayment,
} from "../Redux/actions/payment";
import Loader from "./Loader";
import { toast } from "react-toastify";
import {
  accountingTabs,
  changeDateFormat,
  statusBaseColorChange,
} from "../utils/helper";
import { StripePayment } from "../pages/modals/StripePayment";
import { getInvoicePeriod } from "../Redux/actions/staffing";

export default function Accounting() {
  const dispatch = useDispatch();
  const [menuItem, setMenuItem] = useState("invoice");
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const agencyInputRef = useRef(null);
  const therapistInputRef = useRef(null);
  const statusInputRef = useRef(null);
  const periodInputRef = useRef(null);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const invoiceList = useSelector((e) => e.paymentSlice.invoiceList);
  const invoiceCount = useSelector((e) => e.paymentSlice.invoiceCount);
  const isLoading = useSelector((e) => e.paymentSlice.isLoading);
  const invoicePeriods = useSelector((e) => e.staffingSlice.invoicePeriods);
  const params = useParams();
  const location = useLocation();

  const [showStripe, setShowStripe] = useState({
    modal: false,
    price: 0,
    to: "",
    retrievePayment: false,
    payment_intent: "",
    invoiceNumber: "",
  });

  const [searchFilter, setSearchFilter] = useState({
    pageNo: 1,
    pageSize: 10,
    agency: "",
    therapist: "",
    status: "",
    tab: menuItem,
    period: "",
  });

  const pageNo = 1;
  const pageSize = 10;

  const handleSearchFilter = (
    pageNo,
    pageSize,
    agency,
    therapist,
    status,
    tab,
    period
  ) => {
    setSearchFilter({
      pageNo,
      pageSize,
      agency,
      therapist,
      status,
      tab,
      period,
    });
    dispatch(
      getInvoiceData({
        pageNo,
        pageSize,
        agency,
        therapist,
        status,
        tab,
        period,
      })
    );
  };

  let myTabStatus = {
    All: null,
    Paid: defaultStatus.success,
    UnPaid: defaultStatus.pending,
    Rejected: defaultStatus.rejected,
  };

  let listStatus = {
    success: "Paid",
    pending: "UnPaid",
  };

  const listHeader = {
    superadmin: {
      invoice: [
        "therapistName",
        "invoicePeriod",
        "totalAmount",
        "dueDate",
        "status",
        "stripeStatus",
      ],
      bill: [
        "agencyName",
        "billPeriod",
        "totalAmount",
        "dueDate",
        "status",
        "stripeStatus",
      ],
    },
    admin: {
      invoice: [
        "therapistName",
        "invoicePeriod",
        "totalAmount",
        "dueDate",
        "status",
        "stripeStatus",
      ],
      bill: [
        "agencyName",
        "billPeriod",
        "totalAmount",
        "dueDate",
        "status",
        "stripeStatus",
      ],
    },
    agency: {
      invoice: [
        "therapistName",
        "invoicePeriod",
        "totalAmount",
        "dueDate",
        "status",
        "stripeStatus",
      ],
      bill: [
        "number",
        "billPeriod",
        "totalAmount",
        "dueDate",
        "status",
        "stripeStatus",
      ],
    },
    supervisingtherapist: {
      invoice: [
        "agencyName",
        "invoicePeriod",
        "totalAmount",
        "dueDate",
        "status",
        "stripeStatus",
      ],
      bill: [
        "therapistName",
        "invoicePeriod",
        "totalAmount",
        "dueDate",
        "status",
        "stripeStatus",
      ],
    },
    assistanttherapist: {
      invoice: [
        "therapistName",
        "invoicePeriod",
        "totalAmount",
        "dueDate",
        "status",
        "stripeStatus",
      ],
      bill: [
        "therapistName",
        "invoicePeriod",
        "totalAmount",
        "dueDate",
        "status",
        "stripeStatus",
      ],
    },
  };

  useEffect(() => {
    dispatch(getInvoicePeriod());
  }, []);

  const accountingMenu = [
    {
      key: "invoice",
      label: "Invoice",
      count: invoiceCount[defaultStatus.pending]
        ? invoiceCount[defaultStatus.pending]
        : 0,
      data: invoiceList,
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <pre className="d-flex gap-3 flex-wrap mb-0">
                <button
                  className="view-btn tr-btn ml-auto mt-3 mb-3"
                  onClick={() => handleModal(rowData)}
                >
                  View
                </button>

                {currentUser &&
                  currentUser.Role.key === currentRole.agency &&
                  rowData.Status &&
                  rowData.Status.key === defaultStatus.pending && (
                    <button
                      className="view-btn tr-btn ml-auto mt-3 mb-3"
                      onClick={() => handlePayClick(rowData, "therapist")}
                    >
                      Pay
                    </button>
                  )}
              </pre>
            </td>
          ),
        },
      ],
      modals: <InvoiceView menuItem={menuItem} data={modalData} />,
      modalClass: " ",

      columns:
        currentUser && currentUser.Role
          ? listHeader[currentUser.Role.key]["invoice"]
          : [],
      columnsToHide: [],
    },
    {
      key: "bill",
      label: "Bill",
      count: invoiceCount[defaultStatus.success]
        ? invoiceCount[defaultStatus.success]
        : 0,
      data: invoiceList,
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <pre className="d-flex gap-3 flex-wrap mb-0">
                <button
                  className="view-btn tr-btn ml-auto mt-3 mb-3"
                  onClick={() => handleModal(rowData)}
                >
                  View
                </button>
              </pre>
              {currentUser &&
                currentUser.Role.key === currentRole.agency &&
                rowData.Status &&
                rowData.Status.key === defaultStatus.pending && (
                  <button
                    className="view-btn tr-btn ml-auto mt-3 mb-3"
                    onClick={() =>
                      // createPaymentIntent(
                      //   rowData.billableAmount,
                      //   rowData.therapistData.id
                      // )
                      handlePayClick(rowData, "main")
                    }
                  >
                    Pay
                  </button>
                )}
            </td>
          ),
        },
      ],
      modals: <InvoiceView data={modalData} menuItem={menuItem} />,

      columns:
        currentUser && currentUser.Role
          ? listHeader[currentUser.Role.key]["bill"]
          : [],
      columnsToHide: [],
    },
  ];
  const filters = {
    superadmin: {
      invoice: {
        status: ["All", "Paid", "UnPaid", "Rejected"],
        period: true,
        therapist: true,
        agency: true,
      },
      bill: { agency: true, status: ["All", "Paid", "UnPaid"], period: true },
    },
    admin: {
      invoice: {
        status: ["All", "Paid", "UnPaid", "Rejected"],
        period: true,
        therapist: true,
        agency: true,
      },
      bill: { agency: true, status: ["All", "Paid", "UnPaid"], period: true },
    },
    agency: {
      invoice: {
        status: ["All", "Paid", "UnPaid", "Rejected"],
        therapist: true,
        period: true,
      },
      bill: { status: ["All", "Paid", "UnPaid"], period: true },
    },
    supervisingtherapist: {
      invoice: {
        status: ["All", "Paid", "UnPaid", "Rejected"],
        period: true,
        agency: true,
      },
      bill: { status: ["All", "Paid", "UnPaid"], period: true },
    },
    assistanttherapist: {
      invoice: {
        status: ["All", "Paid", "UnPaid", "Rejected"],
        period: true,
      },
      bill: { status: ["All", "Paid", "UnPaid"], period: false },
    },
  };
  const totalAmount = (result) => {
    let total = 0;
    if (result.episodeDocuments && result.episodeDocuments.length > 0) {
      result.episodeDocuments.map((item) => {
        total += item.amount;
      });
    }
    return total;
  };
  const renderRowData = (result, col, item) => {
    if (col === "number") {
      {
        return (
          <div>
            <h1 className="title-22 invoiceNumberTitle">
              {result.invoiceNumber}
            </h1>
          </div>
        );
      }
    }
    if (col === "therapistName" || col === "to") {
      return (
        <div>
          <p className="m-0">
            {" "}
            {result &&
              result.therapistData &&
              `${
                result?.therapistData?.lastName +
                ", " +
                result?.therapistData?.firstName
              }  ${
                result.therapistData.userDetail &&
                result.therapistData.userDetail.discipline
                  ? `(${result.therapistData.userDetail.discipline.toUpperCase()})`
                  : ""
              }`}
          </p>
        </div>
      );
    }
    if (col === "agencyName") {
      return (
        <div>
          <p className="m-0">
            {" "}
            {result && result.agencyData ? `${result?.agencyData?.name}` : "--"}
          </p>
        </div>
      );
    }
    if (col === "dueDate") {
      return (
        <div>
          <p className="m-0">
            {" "}
            {result && result.dueDate
              ? `${changeDateFormat(
                  result.dueDate,
                  "YYYY/MM/DD",
                  "MM/DD/YYYY"
                )}`
              : ""}
          </p>
        </div>
      );
    }

    if (col === "totalAmount") {
      return (
        <div>
          <p className="m-0">
            $
            {result && result.billableAmount
              ? result.billableAmount
              : totalAmount(result)}
          </p>
        </div>
      );
    }
    if (col === "status") {
      return (
        <div>
          <p
            className={`m-0 mt-3 mb-3 status-td ${statusBaseColorChange(
              result?.Status.key
            )}`}
          >
            {result && result.Status
              ? listStatus[result.Status.key]
              : result.Status.name}
          </p>
        </div>
      );
    }
    if (col === "stripeStatus") {
      return (
        <div>
          <p className={`m-0 mt-3 mb-3  `}>
            {result && result.stripeStatus ? result.stripeStatus : "..."}
          </p>
        </div>
      );
    }
    if (col === "billPeriod" || col === "invoicePeriod") {
      return (
        <div>
          <p className={`m-0 mt-3 mb-3  `}>
            {result && result.invoicePeriod ? result.invoicePeriod : "..."}
          </p>
        </div>
      );
    }
    return <h1 className=" title-24  mt-3 mb-3">{result[item]}</h1>;
  };

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "number":
        // return `${myAction.label} #`;
        return "Invoice Number";
      case "therapistName":
        return "Therapist Name";
      case "totalAmount":
        return "Total Amount";
      case "status":
        return "Status";
      case "stripeStatus":
        return "Stripe Status";
      case "dueDate":
        return "Due Date";
      case "from":
        return "FROM";
      case "invoicePeriod":
        return "Invoice Period";
      case "billPeriod":
        return "Bill Period";
      case "to":
        return "TO";
      case "agencyName":
        return "Agency Name";
    }
  };

  const handleModal = (data) => {
    setModalData(data);
    setModalShow(true);
  };

  const setTabMenu = (tab) => {
    handleSearchFilter(pageNo, pageSize, null, null, null, tab, null);
    if (agencyInputRef && agencyInputRef.current) {
      agencyInputRef.current.value = "";
    }
    if (therapistInputRef && therapistInputRef.current) {
      therapistInputRef.current.value = "";
    }
    if (statusInputRef && statusInputRef.current) {
      statusInputRef.current.value = "";
    }
    if (periodInputRef && periodInputRef.current) {
      periodInputRef.current.value = "";
    }
    setMenuItem(tab);
  };

  let myAction = accountingMenu.find((ele) => ele.key === menuItem);

  useEffect(() => {
    if (params && params.tab && accountingTabs[params.tab]) {
      setMenuItem(params.tab);
    }
    handleSearchFilter(
      pageNo,
      pageSize,
      null,
      null,
      null,
      params.tab ? params.tab : menuItem,
      null
    );
  }, [currentUser]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams && queryParams.get("payment_intent")) {
      setShowStripe({
        modal: true,
        retrievePayment: true,
        payment_intent: queryParams.get("payment_intent"),
      });

      dispatch(
        stripeRetrieveInstantPayment({
          params: { payment_intent: queryParams.get("payment_intent") },
        })
      );
    }
  }, [location]);

  const handleFilter = (value, from) => {
    if (value == "Select period") {
      value = null;
    }
    handleSearchFilter(
      pageNo,
      pageSize,
      searchFilter.agency,
      searchFilter.therapist,
      from === "status" ? myTabStatus[value] : searchFilter.status,
      searchFilter.tab,
      from === "period" ? value : searchFilter.period
    );
  };
  function usdToCents(dollars) {
    return Math.round(dollars * 100);
  }
  const handlePayClick = (rowData, paymentFor) => {
    const price = rowData.billableAmount;
    const receiverId = rowData.therapistData.id;
    const sentBy = rowData.agencyData.id;

    setShowStripe({
      modal: true,
      price: usdToCents(Number(price)),
      to: receiverId,
      sentBy,
      invoiceNumber: rowData.invoiceNumber,
      staffInvoiceId: rowData.id,
      paymentFor,
    });
  };

  const handlePaymentModal = (event) => {
    setShowStripe(event);
    dispatch(getInvoiceData({ status: defaultStatus.pending }));
  };

  const handleInputSearch = debounce((value, input) => {
    if (input == "agency") {
      handleSearchFilter(
        pageNo,
        pageSize,
        value,
        searchFilter.therapist,
        searchFilter.status,
        searchFilter.tab,
        searchFilter.period
      );
    }

    if (input == "therapist") {
      handleSearchFilter(
        pageNo,
        pageSize,
        searchFilter.therapist,
        value,
        searchFilter.status,
        searchFilter.tab,
        searchFilter.period
      );
    }
  }, 700);

  const getFiltersInputs = (type, menuItem) => {
    if (currentUser && currentUser.Role) {
      let filter = filters[currentUser.Role.key][menuItem];
      return filter[type];
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12  pl-5 pr-5">
            <div className="patient_main_parent gap-3 mobile_justify justify-content-between">
              {getFiltersInputs("therapist", menuItem) ? (
                <div
                  className="search_bar_frm pl-5 mt-5"
                  style={{ width: "400px" }}
                >
                  <img style={{ marginRight: "15px" }} src={Searchbar} alt="" />
                  <input
                    placeholder="Search by Therapist name."
                    id="searchField"
                    ref={therapistInputRef}
                    className="pl-2 searching"
                    style={{
                      border: "none",
                      fontSize: "18px",
                      outline: "none",
                      width: "100%",
                    }}
                    onChange={(e) =>
                      handleInputSearch(e.target.value, "therapist")
                    }
                  />
                </div>
              ) : (
                ""
              )}

              {getFiltersInputs("agency", menuItem) ? (
                <div className="search_bar_frm mt-5" style={{ width: "400px" }}>
                  <img style={{ marginRight: "15px" }} src={Searchbar} alt="" />
                  <input
                    placeholder="Search by Agency name..."
                    id="searchField"
                    className="pl-2 searching"
                    ref={agencyInputRef}
                    style={{
                      border: "none",
                      fontSize: "18px",
                      outline: "none",
                      width: "100%",
                    }}
                    onChange={(e) =>
                      handleInputSearch(e.target.value, "agency")
                    }
                  />
                </div>
              ) : (
                ""
              )}
              {getFiltersInputs("period", menuItem) ? (
                <div className="patient_right_form  mobile_wrap-990 mobile_justify-start gap-4 mt-3 ">
                  <div className="inner_content_form mobile_justify-start mobile_width">
                    <p className="ml-0 mobile_width-30 ml-2 mb-2">
                      Filter {menuItem} by Period{" "}
                    </p>
                    <select
                      className="form-select"
                      ref={periodInputRef}
                      onChange={(e) => {
                        handleFilter(e.target.value, "period");
                      }}
                    >
                      {invoicePeriods &&
                        invoicePeriods.map((item, index) => (
                          <option value={item.invoicePeriod} key={index}>
                            {" "}
                            {item.invoicePeriod}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
              {getFiltersInputs("status", menuItem) ? (
                <div className="patient_right_form  mobile_wrap-990 mobile_justify-start gap-4 mt-3 ">
                  <div
                    className="inner_content_form mobile_justify-start mobile_width"
                    style={{ marginRight: "20px" }}
                  >
                    <p className="ml-0 mobile_width-30 ml-2 mb-2">
                      {" "}
                      Filter {menuItem} By Status{" "}
                    </p>
                    <select
                      className="form-select"
                      ref={statusInputRef}
                      onChange={(e) => {
                        handleFilter(e.target.value, "status");
                      }}
                    >
                      {getFiltersInputs("status", menuItem).map(
                        (item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="user-type-main tabs_user_type">
              <div className="col-sm-12 pr-5 pl-5">
                <CommonHead2
                  data={accountingMenu}
                  setMenu={setTabMenu}
                  menuItem={menuItem}
                  css={"col1"}
                />
              </div>
            </div>
          </div>

          <div className="container-fluid table-container-2 pr-50">
            <Row>
              <div className="inside_table_tabs AccountingInsideTable">
                <Col lg={12} className="p-0">
                  <div className="table-responsive table-width-1 p-chart">
                    {myAction && myAction.data && myAction.data.length > 0 ? (
                      <CommonTable
                        data={myAction?.data}
                        columns={myAction?.columns}
                        columnsToHide={myAction?.columnsToHide}
                        objectKey={myAction?.objectKey}
                        overrideColumnName={overrideColumnName}
                        actions={myAction?.actions}
                        renderRowData={renderRowData}
                        component={myAction?.component}
                        isLoading={isLoading}
                      />
                    ) : (
                      <div className="d-flex align-items-center justify-content-center mt-5">
                        {!isLoading && (
                          <h2 className="fs-5 mt-5">No Data available</h2>
                        )}
                      </div>
                    )}
                  </div>
                </Col>
              </div>
            </Row>
          </div>

          {modalShow && (
            <MyModal2
              size="lg"
              data={myAction.modals}
              show={modalShow}
              handleClose={() => setModalShow(false)}
              modalClass={myAction.modalClass}
            />
          )}
        </div>
      </div>
      {showStripe.modal && (
        <StripePayment show={showStripe} onHide={handlePaymentModal} />
      )}
    </>
  );
}
