import { Form, Field } from "react-final-form";
import styled from "styled-components";
import { validateForm } from "../../validation/validateForm";
import { createUserSchema } from "../../validation/schemas";
import { useDispatch, useSelector } from "react-redux";
import { getAdminUsersList, createAdmin } from "../../Redux/actions/auth";
import { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { ImAttachment } from "react-icons/im";
import { FiPlus } from "react-icons/fi";
import userImage from "../../images/user.png";
import { currentRole } from "../../utils/auth";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Loader from "../../components/Loader";
import { phoneNumberToUSFormat } from "../../utils/helper";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 40px;
`;
const Label = styled.label`
  color: rgb(24, 54, 91);
  font-weight: 600;
  font-size: 16px;
`;

const Button = styled.button`
  background: linear-gradient(
    90deg,
    rgba(15, 122, 212, 1) 8%,
    rgba(106, 64, 212, 1) 98%
  );
  color: white;
  outline: none;
  border: none;
  width: 50%;
  border-radius: 86px;
  padding: 10px;
  cursor: pointer;
`;

const Input = styled.input`
  width: 100%;
  height: 46px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b6c4ce;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
  &::placeholder {
    font-size: 15px;
  }
`;

const validate = validateForm(createUserSchema);
function CreateAdminUser(props) {
  const dispatch = useDispatch();
  const [file, setFile] = useState(userImage);
  const [uploadedImage, setUploadedImage] = useState(null);
  const isLoading = useSelector((e) => e.adminSlice.isLoading);
  const [show, setShow] = useState(false);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);

  function handleClose() {
    setTimeout(() => {
      setFile(userImage);
      setUploadedImage(userImage);
    }, 500);
    setShow(false);
  }

  const handleOpen = () => {
    setShow(true);
  };

  function handleChange(e) {
    setUploadedImage(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  function handleSubmit(values) {
    let formData = new FormData();
    formData.append("file", uploadedImage);

    for (var key in values) {
      formData.append(key, values[key]);
    }

    dispatch(createAdmin(formData)).then((response) => {
      if (response && response.payload && response.payload.success) {
        dispatch(
          getAdminUsersList({
            pageSize: 10,
            pageNo: 1,
            role: currentRole.admin,
          })
        );
        toast.success(`${response.payload.message}.`);
        handleClose();
      } else {
        toast.error(`${response.payload.message}.`);
      }
    });
  }

  return (
    <>
      <div className="creat1">
        {currentUser?.Role?.key == currentRole.superadmin && (
          <button className="view-btn active admin-user" onClick={handleOpen}>
            <div
              style={{
                display: "flex",
                background: "white",
                width: "2.125rem",
                height: "2.125rem",
                borderRadius: "50%",
                justifyContent: "center",
                alignItems: "center",
                color: "#0f7ad4",
              }}
            >
              <FiPlus size="20px" />
            </div>
            Create New Admin User
          </button>
        )}

        <Modal show={show} onHide={handleClose} className="CreatAdmin">
          <div className="adminModalParent">
            <p style={{ paddingLeft: "40px" }}> Create New User </p>
            <GrFormClose size="30px" onClick={handleClose} cursor="pointer" />
          </div>

          <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({
              handleSubmit,
              submitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Container>
                  <div className="adminModall gap-4 mobile_wrap mobile_justify">
                    <div>
                      <div>
                        <img
                          style={{
                            height: "150px",
                            width: "150px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                          src={file}
                          crossOrigin="anonymous"
                          alt="img"
                        />
                      </div>
                    </div>

                    <div className="adminModall mobile_justify ">
                      <div>
                        <p className="upload-bbtn-1 m-0">Upload Photo</p>

                        <label className="m-0">
                          Browse files... <ImAttachment />
                          <input
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={handleChange}
                            hidden
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div
                    className="flex-row  flex-column gap-2 "
                    style={{ display: "flex", marginTop: "31px" }}
                  >
                    <Label className="creat-m-13">Name</Label>

                    <div className="d-flex gap-2">
                      <Field name="firstName">
                        {({ input, meta }) => (
                          <div
                            style={{
                              marginBottom: "31px",
                            }}
                            className="d-flex flex-column w-50"
                          >
                            <input
                              style={{ height: "46px" }}
                              className="input-245 creat-m-31  color_placeholder"
                              {...input}
                              type="text"
                              placeholder="First Name"
                            />

                            {meta.error && meta.touched && (
                              <p
                                className="alert alert-danger top-11"
                                role="alert"
                              >
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="lastName">
                        {({ input, meta }) => (
                          <div
                            style={{ marginBottom: "31px" }}
                            className="d-flex flex-column w-50"
                          >
                            <input
                              style={{ height: "46px", fontSize: "16px" }}
                              className="input-245 creat-m-31  color_placeholder"
                              {...input}
                              type="text"
                              placeholder="Last Name"
                            />

                            {meta.error && meta.touched && (
                              <p
                                className="alert alert-danger top-11"
                                role="alert"
                              >
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>

                  <Field name="phoneNo">
                    {({ input, meta }) => (
                      <div style={{ marginBottom: "31px" }}>
                        <Label className="creat-m-13">Phone Number</Label>{" "}
                        <br />
                        <Input
                          {...input}
                          placeholder="Phone Number"
                          className="creat-m-31  color_placeholder"
                          onInput={(e) => phoneNumberToUSFormat(e, input)}
                        />
                        {meta.error && meta.touched && (
                          <p className="alert alert-danger top-11" role="alert">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>

                  <Field name="email">
                    {({ input, meta }) => (
                      <div style={{ marginBottom: "31px" }}>
                        <Label className="creat-m-13">Email Address</Label>{" "}
                        <br />
                        <Input
                          className="creat-m-31  color_placeholder"
                          {...input}
                          type="text"
                          placeholder="Email Address"
                        />
                        {meta.error && meta.touched && (
                          <p className="alert alert-danger top-11" role="alert">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>

                  <div
                    className="creat-m-33"
                    style={{
                      padding: "15px 0px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button type="submit" disabled={submitting}>
                      Create User
                    </Button>
                  </div>
                </Container>
              </form>
            )}
          />
          {isLoading && <Loader />}
        </Modal>
      </div>
    </>
  );
}

export default CreateAdminUser;
