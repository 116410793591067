import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addAgencySelectLastVisit,
  getSavedLastVisitData,
  getStaffDocumentation,
} from "../../Redux/actions/common";
import { useParams } from "react-router-dom";
import { decodeData } from "../../utils/auth";
import { toast } from "react-toastify";
import { lastVisitTypes } from "../../utils/helper";
import { masterData } from "../../Redux/actions/therapist";

export const AssessmentVisit = (props) => {
  const { staffUserId, handleClose, episodeId } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const [visitTypes, setVisitTypes] = useState({});

  const masterDataDetail = useSelector(
    (e) => e.therapistSigunpSlice.masterData
  );

  const savedLastVisitType = useSelector(
    (e) => e.commonSlice.savedLastVisitType
  );

  const onSubmit = (values) => {
    if (!values.section1 || !values.section2) {
      toast.info("Please select at least one visit from each section.");
      return;
    }
    const payload = {
      episodeId: episodeId,
      staffUserId: staffUserId,
      docVisitTypeId: [
        { docVisitTypeId: Number(values.section1), id: values.section1ID },
        { docVisitTypeId: Number(values.section2), id: values.section2ID },
      ],
    };
    dispatch(addAgencySelectLastVisit(payload)).then((res) => {
      if (res?.payload?.success) {
        handleClose();
        dispatch(
          getStaffDocumentation({
            staffUserId: staffUserId
              ? Number(staffUserId)
              : decodeData(params.id),
          })
        );
      }
    });
  };

  useEffect(() => {
    dispatch(
      masterData({
        types: lastVisitTypes,
      })
    );
    dispatch(
      getSavedLastVisitData({ episodeId: episodeId, staffUserId: staffUserId })
    );
  }, []);

  useEffect(() => {
    if (
      masterDataDetail &&
      masterDataDetail.data &&
      masterDataDetail.data.length > 0
    ) {
      let types = {};
      lastVisitTypes.map((item) => {
        let result = masterDataDetail.data.filter(
          (data) => data.types === item
        );
        types[item] = result;
      });
      setVisitTypes(types);
    }
  }, [masterDataDetail]);

  const initialValue = () => {
    let values = { section1ID: 0, section2ID: 0 };
    if (
      savedLastVisitType &&
      savedLastVisitType.staffEpisodeDoc &&
      savedLastVisitType.staffEpisodeDoc.StaffDocumentDatas &&
      savedLastVisitType.staffEpisodeDoc.StaffDocumentDatas.length > 0
    ) {
      lastVisitTypes.map((item) => {
        let data = savedLastVisitType.staffEpisodeDoc.StaffDocumentDatas.find(
          (type) => type.docVisit.types === item
        );

        if (data && data.docVisit) {
          if (data.docVisit.types.includes("section_a")) {
            values["section1"] = data.docVisitTypeId;
            values["section1ID"] = data.id;
          } else {
            values["section2"] = data.docVisitTypeId;
            values["section2ID"] = data.id;
          }
        }
      });
    }

    return values;
  };

  return (
    <>
      <Modal
        size={props.size}
        dialogClassName={props.dialogClassName}
        className={props.modalClass}
        show={props.showModal}
        onHide={props.handleClose}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          className="closeButton border-0"
          closeButton
        ></Modal.Header>
        <Modal.Body className="p-3">
          <Form
            keepDirtyOnReinitialize
            onSubmit={onSubmit}
            initialValues={initialValue()}
            render={({ handleSubmit, values, form }) => (
              <form onSubmit={handleSubmit}>
                <div className="row ">
                  <div className="col-md-12">
                    <h6 className="cs-heading text-center">
                      Last Visit Type Approval{" "}
                    </h6>
                    <p className="text-center PrimeryAlertColor">
                      *Please select 1 visit from each section
                    </p>
                  </div>

                  {Object.keys(visitTypes).map((item, index) => (
                    <div className="col-md-6">
                      <div className="row justify-content-center">
                        <div className="col-md-11">
                          <div className="radio_btns radio_btns-margin new-reff-radio m-0 radioBtnColoumn">
                            <span className="radioBtnsSection">
                              {" "}
                              Section {index + 1}
                            </span>
                            {visitTypes[item] &&
                              visitTypes[item].map((visit, visitIndex) => (
                                <label
                                  key={visitIndex}
                                  className="container-2 align-items-center d-flex padding-left-ref-45 ref-height"
                                  style={{ height: "100%" }}
                                >
                                  <Field
                                    name={`section${index + 1}`}
                                    component="input"
                                    type="radio"
                                    value={visit.id}
                                  >
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="radio"
                                          value={visit.id}
                                          checked={
                                            Number(
                                              values[`section${index + 1}`]
                                            ) === Number(visit.id)
                                          }
                                        />
                                        <span className="checkmark-2"></span>
                                      </>
                                    )}
                                  </Field>
                                  <div> {visit.displayName} </div>
                                </label>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* <div className="col-md-6">
                    <div className="row justify-content-center h-100">
                      <div className="col-md-11">
                        <div className="radio_btns radio_btns-margin new-reff-radio  m-0 radioBtnColoumn">
                          <span className="radioBtnsSection">Section 2</span>
                          {assessmentSectionOptions.section2.map(
                            (item, index) => (
                              <label
                                key={index}
                                className="container-2 align-items-center d-flex padding-left-ref-45 ref-height"
                              >
                                <Field
                                  name="section2"
                                  component="input"
                                  type="radio"
                                  value={item.key}
                                >
                                  {({ input }) => (
                                    <>
                                      <input
                                        {...input}
                                        type="radio"
                                        value={item.key}
                                      />
                                      <span className="checkmark-2"></span>
                                    </>
                                  )}
                                </Field>
                                <div> {item.name} </div>
                              </label>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="d-flex mt-4">
                      <Button
                        type="submit"
                        variant="primary"
                        className="primary-confirm mx-auto"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
