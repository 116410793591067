import React, { useMemo } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { staffFormTypesConstants, visitStatus } from "../../../utils/helper";
import { savePatientForm } from "../../../Redux/actions/staffing";
import { getStaffFormData } from "../../../Redux/actions/common";
import { currentRole } from "../../../utils/auth";

export default function NOMNC(props) {
  const { closeFormModal, visitDetail, formId } = props;
  const dispatch = useDispatch();
  const staffFormData = useSelector((e) => e.commonSlice.staffForm);

  const staffDetails = useSelector((state) => state.staffingSlice.staffDetail);
  const currentUser = useSelector(
    (state) => state.adminSlice.currentActiveUserDetails
  );

  const initialValues = (event) => {
    if (staffFormData && staffFormData.formData) {
      return { ...staffFormData.formData, id: staffFormData.id };
    }
    if (event && Object.keys(event).length > 0) {
      return event;
    }
  };
  const validate = (values) => {
    let formError = {};
    if (!values.serviceEndDate) {
      formError.serviceEndDate = "Required";
    }
    return formError;
  };
  const onSubmit = (values, status) => {
    let finalFormValues = { ...values };
    const apiObject = {
      status: typeof status === "string" ? status : visitStatus.incompletedoc,
      staffId: staffDetails?.id,
      formData: finalFormValues,
      formType: staffFormTypesConstants.NOMNC,
      id: finalFormValues.id,
      episodeDocumentFormId: formId,
      episodeDocumentId: visitDetail?.id,
    };
    dispatch(savePatientForm(apiObject)).then((res) => {
      if (res.payload.success) {
        closeFormModal();
        dispatch(
          getStaffFormData({
            staffId: Number(staffDetails?.id),
            formTypeName: staffFormTypesConstants.NOMNC,
          })
        );
      }
    });
  };

  return (
    <div>
      <Form
        initialValues={useMemo((e) => initialValues(e))}
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <div>
            <form onSubmit={handleSubmit}>
              <div className="container">
                <div className="row mein-white-from-background">
                  <div className="col-md-4">
                    <p>
                      {" "}
                      Agecny Name :{" "}
                      {staffDetails &&
                        staffDetails.createdByUser &&
                        staffDetails.createdByUser.name}
                    </p>
                    <p>
                      {" "}
                      Agecny Adrress :{" "}
                      {staffDetails &&
                        staffDetails.createdByUser &&
                        staffDetails.createdByUser.name}
                    </p>
                    <p>
                      {" "}
                      Agecny City,State,Zip :{" "}
                      {staffDetails &&
                        staffDetails.createdByUser &&
                        staffDetails.createdByUser.name}
                    </p>
                    <p>
                      {" "}
                      Agecny Phone :{" "}
                      {staffDetails &&
                        staffDetails.createdByUser &&
                        staffDetails.createdByUser.name}
                    </p>
                  </div>
                </div>

                <div className="row mein-white-from-background">
                  <p className="text-center fw-bold">
                    Notice Of Medicare Non-Coverage{" "}
                  </p>
                </div>

                <div className="row mein-white-from-background ">
                  <div className="col-md-6">
                    <p>
                      {" "}
                      Patien Name :{" "}
                      {staffDetails &&
                        staffDetails.lastName + "," + staffDetails.firstName}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p className="text-end">
                      {" "}
                      Patient Number :{" "}
                      {staffDetails &&
                        staffDetails.staffingDetails &&
                        staffDetails.staffingDetails.phoneNo &&
                        staffDetails.staffingDetails.phoneNo}
                    </p>
                  </div>
                </div>

                <div className="row mein-white-from-background ">
                  <div className="col-md-6">
                    <p>
                      The Effective Date Coverage of Your Current Horne Health
                      Services Will End :
                    </p>
                  </div>
                  <div className="col-md-6">
                    <Field name="serviceEndDate">
                      {({ input, meta }) => (
                        <div className="d-flex gap-2">
                          <input
                            {...input}
                            className="form-control w-50"
                            type="date"
                          />
                          {meta.error && meta.touched && (
                            <p
                              className="alert alert-danger w-75 "
                              role="alert"
                            >
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <ul className="my-3">
                    <li>
                      Your Medicare provider and/or health plan have determined
                      that Medicare probably will not pay for your current Home
                      Health services after the effective date indicated above.
                    </li>
                    <li>
                      You may have to pay for any services receive after the
                      above date.
                    </li>
                  </ul>
                </div>

                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4">Your Right to Appeal This Decision</h5>
                  <ul>
                    <li>
                      You have the right to an immediate, independent medical
                      review (appeal) of the decision to end Medicare coverage
                      of these services. Your services will continue during the
                      apppeal
                    </li>
                    <li>
                      If you choose to appeal. the independent reviewer will ask
                      for your opinion. The reviewer also will look at your
                      medical records and/or other relevant information. You do
                      have to prepare anything in writing, but you have right to
                      do so if you wish.
                    </li>
                    <li>
                      If you choose to appeal. you and independent reviewer will
                      each receive a copy the detailed explanation about why
                      your coverage for services should not continue. you will
                      receive this detailed notice only after you request an
                      appeal
                    </li>
                    <li>
                      If you choose to appeal, and the independent reviewer
                      agrees services should no longer be covered after the
                      effective date indicated above Neither Medicare nor your
                      plan will pay for these services after that date.
                    </li>
                    <li>
                      If you stop services no later than the effective date
                      indicated above, you will avoid financial liabi4lity.
                    </li>
                  </ul>
                </div>

                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4">How To Ask For Immediate Appeal</h5>
                  <ul>
                    <li>
                      You must make your request to you Quality Improvement
                      Organization(also known as QIO ). A QIO is the independent
                      reviewer authorized by Medicare to review the decision to
                      end these services.
                    </li>
                    <li>
                      Your request for an immediate appeal should be made as
                      soon as possible but no later than noon of the day before
                      the effective date indicated above.
                    </li>
                    <li>
                      the QIO will notify you of its decision as soon as
                      possible generally no later than two days after the
                      effective date of this notice if you are in Original
                      Medicare. If you are in a Medicare health plan , the QIO
                      generally will notify you of its decision by the effective
                      date of this notice
                    </li>
                    <li>
                      Call your QIO at Livanta , LLC at (1-877-588-1123;
                      TTY/TDD; 1-855-887-6668) to appeal or if you have
                      questions{" "}
                    </li>
                    <li>
                      If you stop services no later than the effective date
                      indicated above, you will avoid financial liabi4lity.
                    </li>
                  </ul>
                </div>

                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4">
                    If You Miss The Deadline To Request An Immediate Appeal ,
                    You May Have Other Appeal Rights :
                  </h5>
                  <ul>
                    <li>
                      If you Original Medicare : Call at QIO listed on page 1.
                    </li>
                    <li>
                      If you belong to a Medicare Health plan : Call your plan
                      at the number given below
                    </li>
                  </ul>

                  <div className="col-md-3">Plan Contact Information :</div>
                  <div className="col-md-9">
                    <Field name="planContact">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          className="form-control"
                        />
                      )}
                    </Field>
                  </div>

                  <div className="col-md-12 mb-3">
                    <label className="">
                      Additinal Information (Optional) :
                    </label>
                    <Field name="planAdditional">
                      {({ input, meta }) => (
                        <>
                          <textarea
                            maxlength="320"
                            {...input}
                            className="Additional_text"
                            placeholder="Free Text"
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4">
                    Please sign below to indicate you received and understood
                    this notice
                  </h5>
                  <p>
                    I have been notified that coveragge of my service will end
                    on the effective date indicated on this notice and that i
                    may appeal this decision by contacting my QIO.
                  </p>
                  <div className="col-md-6">
                    <Field
                      name="patientSignature"
                      className="Additional_text ml-3 "
                      component="textarea"
                      placeholder="Patient signature "
                    />
                    <span className="fw-bold">
                      Signature of patient or Representative
                    </span>
                  </div>
                  <div className="col-md-6">
                    <Field
                      name="signatureTimeStamp"
                      className="Additional_text ml-3 "
                      component="textarea"
                      placeholder="Time Stamp Of Signature"
                    />
                    <span className="fw-bold">Date</span>
                  </div>
                </div>
              </div>

              {currentUser &&
                currentUser.Role &&
                currentUser.Role.key &&
                (currentUser.Role.key === currentRole.supervisingtherapist ||
                  currentUser.Role.key === currentRole.assistanttherapist) && (
                  <div className="container">
                    <div className="py-5 ">
                      <button
                        type="submit"
                        className="btn tr-btn view-btn btn-pte h-auto"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
            </form>
          </div>
        )}
      />
    </div>
  );
}
