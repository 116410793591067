import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommonHead2 } from "../../../components/common/CommonHead2";
import PendingModal from "../../modals/PendingModal";
import React from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { Row, Col } from "bootstrap-4-react";
import { useNavigate } from "react-router-dom";
import Active from "../../../images/AdminDashboard/active_pending_referrals_icon.svg";
import Staffed from "../../../images/AdminDashboard/referrals_staffed_this_week_icon.svg";
import Referrals from "../../../images/AdminDashboard/total_referrals_this_week_icon.svg";
import Invoices from "../../../images/AdminDashboard/past_due_invoices_icon.svg";
import HomeList from "./List";
import {
  getStaffingByStatus,
  getStaffingDetailsById,
  lastVisitTypeApprovalList,
  updateStaffingStatus,
} from "../../../Redux/actions/staffing";
import { MyModal2 } from "../../modals/MyModal2";
import { getDefaultRates } from "../../../Redux/actions/therapist";
import {
  currentRole,
  defaultStatus,
  encodedData,
  getUserId,
} from "../../../utils/auth";
import Loader from "../../../components/Loader";
import { CancelReferralModal } from "../../modals/CancelReferralModal";
import CancelApprovalModal from "../../modals/CancelApprovalModal";
import { toast } from "react-toastify";
import { setLoader } from "../../../Redux/slices/authSlice";
import { UnfinishModal } from "../../modals/unifinishModal";
import PatientDetails from "../../Patients/PatientDetails";
import { getHomeCountData } from "../../../Redux/actions/common";
import ChatModal from "../../modals/ChatModal";
import {
  setChatLoader,
  setChatScreenState,
  unreadMessageCheck,
} from "../../../Redux/slices/chatSlice";
import {
  getActivityLog,
  setActiveConversation,
} from "../../../Redux/actions/chat";

let decoded = getUserId();
const AgencyDashboard = () => {
  const navigate = useNavigate();
  const [menuItem, setMenuItem] = useState("approvalneeded");
  const [show, setshow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const userList = useSelector((e) => e.staffingSlice.staffingList);
  const isLoading = useSelector((e) => e.staffingSlice.isLoading);
  const isModalLoading = useSelector((e) => e.staffingSlice.isModalLoading);
  const [staffUserId, setStaffUserId] = useState(0);
  const [caseStatusKey, setCaseStatusKey] = useState();
  const [acceptModal, setAcceptModal] = useState(false);
  const [chatModalShow, setChatModalShow] = useState(false);
  const [isUnifinishedModal, setIsUnfinishedModal] = useState(false);

  const userRoleStatusCount = useSelector(
    (e) => e.staffingSlice.staffRoleStatusCount
  );
  const [pageSize] = useState(10);

  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);
  const urgentConversations = useSelector(
    (e) => e.chatSlice.urgentConversationList
  );
  const homeTabCardData = useSelector((e) => e.commonSlice.homeCount);

  function handleClick(rowData) {
    let query = "therapist=true";
    setStaffUserId(rowData?.id);
    dispatch(getStaffingDetailsById(`${rowData?.id}?${query}`)).then(() => {
      if (rowData?.staffingDetails && rowData?.staffingDetails?.caseStatus) {
        setCaseStatusKey(rowData?.staffingDetails?.caseStatus?.key);
      }
    });
    dispatch(getDefaultRates(currentRole.agency));
  }
  const dispatch = useDispatch();

  const handleClose = () => {
    setshow(false);
    setCaseStatusKey();
    dispatch(setLoader({ loading: true }));
    setPageNo(1);
  };
  const handleShow = () => setshow(true);
  const showApprovalModal = () => setModalShow(true);
  const closeModal = () => setModalOpen(false);

  const hideApprovalModal = () => {
    closeModal();
    setModalShow(false);
  };

  const getRoleDetailCount = (role, status) => {
    let count = 0;
    if (userRoleStatusCount && userRoleStatusCount.length > 0) {
      let user = userRoleStatusCount.find(
        (item) =>
          item.Role.key === role && item.Status.key === defaultStatus[status]
      );
      if (user && user.userCount) {
        count = user.userCount;
      }
    }

    return count;
  };
  const getHomeCardCount = (type, role, status) => {
    let count = 0;
    if (type === "caseCount" && homeTabCardData && homeTabCardData[type]) {
      count = homeTabCardData[type][status];
    } else if (
      homeTabCardData &&
      homeTabCardData[type] &&
      homeTabCardData[type].length > 0
    ) {
      homeTabCardData[type].map((e) => {
        if (
          status &&
          e.Status.key === status &&
          (!role || e.Role.key === role)
        ) {
          count += Number(e.userCount);
        } else if (!status && (!role || e.Role.key === role)) {
          count += Number(e.userCount);
        }
      });
    }
    return count;
  };

  const getStaffingList = (obj) => {
    dispatch(
      getStaffingByStatus({
        pageNo: obj.pageNo,
        pageSize: obj.pageSize,
        status: obj.status,
      })
    );
  };

  const getLastVisitTypeStaffingList = (obj) => {
    dispatch(
      lastVisitTypeApprovalList({
        pageNo: obj.pageNo,
        pageSize: obj.pageSize,
        status: obj.status,
      })
    );
  };

  const setMenu = (key) => {
    setMenuItem(key);
    setPageNo(1);
    if (
      key === "unfinishedprofiles" ||
      key === "approvalneeded" ||
      key === "frequenciestoapprove"
    ) {
      getStaffingList({
        pageNo: 1,
        pageSize: pageSize,
        status: statusBasedOnTab(key),
      });
    }
    if (key === "lastvisittypeapproval") {
      getLastVisitTypeStaffingList({
        pageNo: 1,
        pageSize: pageSize,
        status: statusBasedOnTab(key),
      });
    }
  };

  const statusBasedOnTab = (tabKey) => {
    let status = defaultStatus.approvalneeded;
    switch (tabKey) {
      case "approvalneeded":
        status = defaultStatus.awaitingagencyapproval;
        break;
      case "unfinishedprofiles":
        status = defaultStatus.unfinishedprofile;
        break;
      case "frequenciestoapprove":
        status = defaultStatus.newfrequencies;
        break;
      case "lastvisittypeapproval":
        status = defaultStatus.lastvisittypeapproval;
        break;
      case "urgentmessages":
        status = defaultStatus.unfinishedprofile;
        break;
      default:
        status = defaultStatus.awaitingagencyapproval;
        break;
    }
    return [status];
  };

  const cancelModalHandler = (staffInfo) => {
    dispatch(getStaffingDetailsById(staffInfo.id)).then((res) => {
      setModalOpen(true);
    });
  };

  let myTabStatus = {
    pending: defaultStatus.pending,
    approval: defaultStatus.awaitingagencyapproval,
    unfinishedprofiles: defaultStatus.unfinishedprofile,
    staffedreferral: defaultStatus.staffedreferral,
    declinedreferral: defaultStatus.rejected,
  };
  const setTabMenus = (tab) => {
    dispatch(setLoader({ loading: true }));
    setMenuItem(tab);
    setPageNo(1);
    getStaffingList({
      pageSize: pageSize,
      pageNo: 1,
      status: [myTabStatus[tab]],
    });
  };

  const handleSubmit = (values) => {
    const obj = {
      status: defaultStatus.unfinishedprofile,
      therapistId: values.therapistId,
      staffUserId: staffDetail.id,
    };
    dispatch(updateStaffingStatus(obj)).then((e) => {
      toast.success("Therapist accepted successfully.");
      if (values?.isProfileUpdate === true) {
        setAcceptModal(true);
      }
    });
  };
  const handleUnfinishModal = (showState) => {
    setIsUnfinishedModal(showState);
  };

  function handlePatientRoute(rowData) {
    if (rowData?.Role?.key === currentRole.patient) {
      navigate(`/patientdetails/${encodedData(rowData?.id)}`);
    } else {
      navigate(`/patientdetails/${encodedData(rowData?.staffUserId)}`);
    }
  }

  const menu = [
    {
      label: "Approval Needed",
      key: "approvalneeded",
      noData: "No Data Available",
      count: Number(
        getHomeCardCount("staffCount", "", defaultStatus.awaitingagencyapproval)
      ),

      data: userList,
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <pre className="d-flex gap-3  mb-0">
                  <button
                    className="view-btn tr-btn  mt-3 ml-auto mb-3 "
                    onClick={() => {
                      handleClick(rowData);
                      handleShow();
                    }}
                  >
                    View
                  </button>

                  {decoded && decoded.role === currentRole.agency && (
                    <button
                      className="view-btn tr-btn mt-3 mb-3  Cancel-red"
                      onClick={() => cancelModalHandler(rowData)}
                    >
                      Cancel
                    </button>
                  )}
                </pre>
              </td>
            </>
          ),
        },
      ],
      modals: (
        <PendingModal
          showTherapist={true}
          handleSubmit={handleSubmit}
          caseStatusKey={caseStatusKey}
          handleClose={handleClose}
          cancelModalHandler={cancelModalHandler}
          acceptModal={acceptModal}
          setAcceptModal={setAcceptModal}
          setTabMenus={setTabMenus}
          handlePatientRoute={handlePatientRoute}
          handleUnfinishModal={handleUnfinishModal}
        />
      ),
      columns: [
        "firstName",
        "staffingDetails.address",
        "staffingDetails.disciplinesNeeded",
        "staffingDetails.caseStatus.name",
      ],

      objectKey: ["staffingDetails"],
      columnsToHide: ["image"],
      modalClass: "PenddingM",
    },
    {
      label: "Unfinished Profile",
      key: "unfinishedprofiles",
      noData: "No Data Available",
      count: Number(
        getHomeCardCount("staffCount", "", defaultStatus.unfinishedprofile)
      ),
      modalClass: "ApprovM",
      data: userList,
      modals: <PatientDetails staffUserId={staffUserId} />,
      actions: [
        {
          action: (rowData) => (
            <>
              <td className="">
                <pre className="d-flex gap-3  mb-0">
                  <button
                    className="view-btn tr-btn  mt-3 ml-auto mb-3 "
                    onClick={() => {
                      // handlePatientRoute(rowData);
                      handleClick(rowData);
                      handleShow();
                    }}
                  >
                    View
                  </button>
                  {decoded && decoded.role === currentRole.agency && (
                    <button
                      className="view-btn tr-btn mt-3 mb-3  Cancel-red "
                      onClick={() => cancelModalHandler(rowData)}
                    >
                      Cancel
                    </button>
                  )}
                </pre>
              </td>
            </>
          ),
        },
      ],
      columns: [
        "firstName",
        "staffingDetails.address",
        "staffingDetails.disciplinesNeeded",
        "staffingDetails.caseStatus.name",
      ],
      columnsToHide: ["image", "scount"],
    },
    {
      label: "Frequencies To Approve",
      key: "frequenciestoapprove",
      count: Number(getHomeCardCount("caseCount", "", "frequencyCount")),
      columns: [
        "firstName",
        "staffingDetails.address",
        "staffingDetails.disciplinesNeeded",
        "staffingDetails.caseStatus.name",
      ],
      columnsToHide: ["image"],
      noData: "No Data Available",
      data: userList,
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <pre className="d-flex gap-3  mb-0">
                <button
                  className="view-btn tr-btn  mt-3 ml-auto mb-3 "
                  onClick={() =>
                    navigate(`/patientdetails/${encodedData(rowData.id)}`)
                  }
                >
                  View
                </button>
              </pre>
            </td>
          ),
        },
      ],
    },
    {
      label: "Last Visit Type Approval",
      count: Number(getHomeCardCount("caseCount", "", "lastVistApprovel")),
      key: "lastvisittypeapproval",
      noData: "No Data Available",
      data: userList,
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <pre className="d-flex gap-3  mb-0">
                <button
                  className="view-btn tr-btn  mt-3 ml-auto mb-3 "
                  onClick={() =>
                    navigate(
                      `/patientdetails/${encodedData(
                        rowData.episodeStaff?.staffData?.id
                      )}/patientdocument`
                    )
                  }
                >
                  View
                </button>
              </pre>
            </td>
          ),
        },
      ],

      columns: [
        "episodeStaff.staffData.firstName",
        "episodeStaff.staffData.staffingDetails.address",
        "episodeStaff.staffData.staffingDetails.disciplinesNeeded",
        "episodeStaff.staffData.staffInitialVisitss.Status.name",
      ],
      columnsToHide: ["image", "scount"],
    },
    {
      label: "Urgent Messages",
      count: urgentConversations.length,
      key: "urgentmessages",
      noData: "No Data Available",
      data: urgentConversations,
      actions: [
        {
          action: (rowData) => (
            <td className="">
              <pre className="d-flex gap-3  mb-0">
                <button
                  className="view-btn tr-btn  mt-3 ml-auto mb-3 "
                  onClick={() => {
                    chatbyId(rowData);
                  }}
                >
                  View
                </button>
              </pre>
            </td>
          ),
        },
      ],
      columns: [
        "firstName",
        "urgentmessages.address",
        "urgentmessages.disciplinesNeeded",
        "urgentmessages.caseStatus.name",
      ],
      columnsToHide: ["image", "scount"],
    },
  ];

  const ConversationItems = useSelector(
    (state) => state.chatSlice.conversationPaginator?.items
  );
  const chatbyId = (rowData) => {
    setChatModalShow(true);
    setTimeout(() => {
      dispatch(setChatLoader(true));
      const selectedConversation = ConversationItems.find(
        (ele) => ele.sid === rowData.sid
      );
      dispatch(setActiveConversation(selectedConversation));

      if (
        selectedConversation &&
        selectedConversation.attributes &&
        selectedConversation.attributes.id
      ) {
        dispatch(getActivityLog(selectedConversation.attributes.id));
      }
    }, 1000);
  };

  const myActions = menu.find((ele) => ele.key === menuItem);

  useEffect(() => {
    dispatch(getHomeCountData());
  }, []);
  return (
    <>
      {(isLoading || isModalLoading) && <Loader />}
      <div className="home-lists  home-lists-1 ">
        <div className="col-lg-12">
          <div className="title_home_list d-flex align-items-center justify-content-between pr-5 mb-3_5 mt-4">
            <h3 className="dash-head pl-4 pb-0">Virtual Assistant</h3>

            <button
              className=" new-staffing-btn d-flex align-items-center pl-1 "
              onClick={() => navigate("/staffing/staff-new-referral")}
            >
              <AiFillPlusCircle
                style={{
                  width: "35px",
                  height: "35px",
                  marginRight: "8px",
                }}
                color="white"
              />
              STAFF NEW REFERRAL
            </button>
          </div>

          <Row className="row1">
            {getHomeCardCount(
              "staffCount",
              currentRole.patient,
              defaultStatus.pending
            ) !== 0 && (
              <Col xl={3} lg={4} md={6} sm={6} className="padding-bottom">
                <>
                  <div
                    className="home-list list1 list-2 padding-agency-toptabs"
                    onClick={() => navigate("/staffing")}
                  >
                    <div className="home-list-icon">
                      <img src={Active} className="top-img-admin" alt="" />
                    </div>
                    <div className="home-list-content agency-tabs-h4">
                      <h4>
                        Pending
                        <br />
                        Referrals
                      </h4>
                      <span className=" mt-3 d-flex align-content-end">
                        {getHomeCardCount(
                          "staffCount",
                          currentRole.patient,
                          defaultStatus.pending
                        ) || 0}
                      </span>
                    </div>
                  </div>
                </>
              </Col>
            )}

            {getHomeCardCount("caseCount", "", "patientneedscheduling") !==
              0 && (
              <Col xl={3} lg={4} md={6} sm={6} className="padding-bottom">
                <>
                  <div
                    className="home-list list2 list-2 padding-agency-toptabs pe-auto"
                    onClick={() =>
                      navigate("/casemanagement/patientneedscheduling")
                    }
                  >
                    <div className="home-list-icon">
                      <img src={Staffed} className="top-img-admin" alt="" />
                    </div>
                    <div className="home-list-content">
                      <h4>
                        Patients Needing <br />
                        Scheduling
                      </h4>
                      <span className="mt-3 d-flex align-content-end">
                        {getHomeCardCount(
                          "caseCount",
                          "",
                          "patientneedscheduling"
                        ) || 0}
                      </span>
                    </div>
                  </div>
                </>
              </Col>
            )}

            {getHomeCardCount("docCount", "", "needreview") !== 0 && (
              <Col xl={3} lg={4} md={6} sm={6} className="padding-bottom">
                <div
                  className="home-list list3 list-2 padding-agency-toptabs"
                  onClick={() => navigate("/documentation/needreview")}
                >
                  <div className="home-list-icon">
                    <img src={Referrals} className="top-img-admin" alt="" />
                  </div>
                  <div className="home-list-content">
                    <h4>
                      New <br /> Documentation
                    </h4>
                    <span className=" mt-3 d-flex align-content-end">
                      {getHomeCardCount("docCount", "", "needreview")}
                    </span>
                  </div>
                </div>
              </Col>
            )}
            {homeTabCardData?.invoiceCount?.length !== 0 && (
              <Col xl={3} lg={4} md={6} sm={6} className="padding-bottom">
                <div
                  className="home-list list4 list-2 padding-agency-toptabs"
                  onClick={() => navigate("/accounting/invoice")}
                >
                  <div className="home-list-icon">
                    <img src={Invoices} className="top-img-admin" alt="" />
                  </div>
                  <div className="home-list-content">
                    <h4>
                      Past Due <br /> Invoices/Bills
                    </h4>
                    <span className=" mt-3 d-flex align-content-end">
                      {homeTabCardData?.invoiceCount?.length}
                    </span>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>
      <div className="pl-5 pr-5 mt-5">
        <CommonHead2
          css="col1"
          data={menu}
          setMenu={setMenu}
          menuItem={menuItem}
        />
      </div>
      <div className="container-fluid table-container-1">
        <HomeList
          myActions={myActions}
          tabStatus={statusBasedOnTab}
          pageNo={pageNo}
          setPageNo={setPageNo}
        />
      </div>
      {show && (
        <MyModal2
          data={myActions.modals}
          show={show}
          handleClose={handleClose}
          modalClass={myActions.modalClass}
        />
      )}
      <ChatModal
        show={chatModalShow}
        handleClose={() => {
          dispatch(setChatScreenState(false));
          dispatch(unreadMessageCheck(false));
          setChatModalShow(false);
        }}
      />
      {modalShow && (
        <CancelApprovalModal
          show={modalShow}
          handleClose={hideApprovalModal}
          modalClass="cancel-madal"
          isAllDeclined={true}
          from={statusBasedOnTab(menuItem).toString()}
        />
      )}
      {modalOpen && (
        <CancelReferralModal
          show={modalOpen}
          show2={showApprovalModal}
          handleClose={closeModal}
        />
      )}
      {isUnifinishedModal && (
        <UnfinishModal
          staffUserId={staffUserId}
          handleClose={() => setIsUnfinishedModal(false)}
          isUnifinishedModal={isUnifinishedModal}
          mainhandleClose={handleClose}
        />
      )}
    </>
  );
};

export default AgencyDashboard;
