import React, { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { apiBasePath } from "../../Redux/config/config";
import userImage from "../../images/user.png";
import {
  getNotificationList,
  readNotification,
  readNotificationAll,
} from "../../Redux/actions/notification";
import { openToNewWindow } from "../../utils/auth";
import { setNewNotification } from "../../Redux/slices/notificationSlice";

export default function Notification(props) {
  const notificationDivRef = useRef(null);
  const dispatch = useDispatch();

  const fetchingNotification = useSelector(
    (state) => state.notificationSlice.fetchingNotification
  );
  const notificationList = useSelector((state) => state.notificationSlice.list);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);

  const singleRead = (notificationObject) => {
    let apiObject = {
      id: notificationObject.id,
      userId: currentUser && currentUser.id,
    };
    if (notificationObject && notificationObject.redirecturl)
      openToNewWindow(window.location.origin + notificationObject.redirecturl);
    props.setShowNotification(false);
    dispatch(readNotification(apiObject)).then(() =>
      dispatch(getNotificationList())
    );
  };

  const readAllNotifications = () => {
    let apiObject = { isAllRead: true, userId: currentUser && currentUser.id };
    if (notificationList && notificationList.length > 0)
      dispatch(readNotificationAll(apiObject)).then(() => {
        dispatch(getNotificationList());
        dispatch(setNewNotification(false));
      });
  };

  useEffect(() => {
    dispatch(getNotificationList());
  }, []);

  const formatNotificationTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = date.toLocaleString("en-US", options);

    return formattedDate ? formattedDate : "";
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.setShowNotification(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(notificationDivRef);

  return (
    <div
      ref={notificationDivRef}
      className="cardNotification mt-3 ms-0 card_notifications_main"
    >
      <Container fluid>
        <div className="mb-2">
          <div className="row">
            <div className="col-7">
              <p className="notification_txt mb-0">Notifications</p>
            </div>
            <div className="col-5 text-right">
              <p
                className={`link_txt_noti ${
                  notificationList && notificationList.length > 0
                    ? "disabled"
                    : ""
                }`}
                onClick={readAllNotifications}
              >
                Mark all as read
              </p>
            </div>
          </div>
        </div>
        {fetchingNotification ? (
          <div className=" my-5 py-5 w-100">
            <h5 className="text-center">Fetching....</h5>
          </div>
        ) : (
          <div className="scrollBarDiv">
            {notificationList && notificationList.length > 0 ? (
              notificationList.map((item, index) => (
                <div
                  className="cart_notification_scrol "
                  key={`Notification_${index}`}
                >
                  <div
                    className={`row line_notication m-0 align-items-center cursor-pointer ${
                      item.isRead ? "read" : "unread"
                    }`}
                    onClick={() => singleRead(item)}
                  >
                    <div className="col-1 p-0">
                      <img
                        className="table-img ml-1"
                        src={
                          item.patientData && item.patientData.image
                            ? `${apiBasePath}documents/therapist/${item.patientData.image}`
                            : userImage
                        }
                        crossOrigin="anonymous"
                        style={{ borderRadius: "50%", objectFit: "cover" }}
                        alt=" "
                      />
                    </div>
                    <div className="col-11 pl-2">
                      <p className="cart_notifi_ctxt mb-0 ml-1">
                        <span className="cart_noti_txt">{item.username}</span>
                        {item.notificationTitle}
                        {item.status && (
                          <span className="cart_noti_txt">"{item.status}"</span>
                        )}
                      </p>
                      <p className="cart_notifi_timeing mb-0 ml-1">
                        <span className="cart_noti_txt">
                          {formatNotificationTime(item.createdAt)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="d-flex align-items-center justify-content-center mt-5">
                <h2 className="fs-5 mt-5 mb-5">No Data Available</h2>
              </div>
            )}
          </div>
        )}
      </Container>
    </div>
  );
}
