import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../config/axiosInstance";

export const addStaffing = createAsyncThunk(
  "addStaffing/user/addStaffing",
  async (body) => {
    const data1 = await axiosInstance.post(`/staffing/staffingSignUp`, body);
    return data1.data;
  }
);
export const updateStaffDetail = createAsyncThunk(
  "staffing/update-staff-detail",
  async (body) => {
    const data1 = await axiosInstance.post(
      `staffing/update-staff-detail`,
      body
    );
    return data1.data;
  }
);

export const getStaffingByStatus = createAsyncThunk(
  "getStaffingByStatus/staffing/staffingby-status",
  async (body) => {
    const data1 = await axiosInstance.post(`/staffing/staffingby-status`, body);
    return data1.data;
  }
);

export const getStaffingDetailsById = createAsyncThunk(
  "getUserDetailsById/staffing/get-staff-detail",
  async (id) => {
    const data1 = await axiosInstance.get(`staffing/get-staff-detail/${id}`);
    return data1.data;
  }
);
export const createUserActivityLog = createAsyncThunk(
  "user/createUserActivityLog",
  async (body) => {
    const data1 = await axiosInstance.post(`user/createUserActivityLog`, body);
    return data1.data;
  }
);

export const urgentMessageUpdate = createAsyncThunk(
  "user/urgentMessageUpdate",
  async (body) => {
    const data1 = await axiosInstance.post(`user/urgentMessageUpdate`, body);
    return data1.data;
  }
);
export const updateStaffingUser = createAsyncThunk(
  "getStaffingByStatus/staffing/update-staffing-status",
  async (body) => {
    const data1 = await axiosInstance.post(
      `/staffing/update-staffing-status`,
      body
    );
    return data1.data;
  }
);

export const filterStaffingUsers = createAsyncThunk(
  "filterStaffingUsers/staffing/update-staffing-status/auth/userby-role-status",
  async (payload) => {
    const data1 = await axiosInstance.post(
      `/staffing/staffingby-status`,
      payload
    );
    return data1.data;
  }
);

export const getStaffList = createAsyncThunk(
  "/staffing/get-therapist-staff/getStaffList",
  async (body) => {
    const data1 = await axiosInstance.post(
      `/staffing/get-therapist-staff`,
      body
    );
    return data1.data;
  }
);

export const updateStaffingStatus = createAsyncThunk(
  "/staffing/update-staffing-status/updateStaffingStatus",
  async (body) => {
    const data1 = await axiosInstance.post(
      `/staffing/update-staffing-status`,
      body
    );
    return data1.data;
  }
);

export const therapistAcceptedReferral = createAsyncThunk(
  "staffing/therapist-accepted-refferal",
  async (body) => {
    const data1 = await axiosInstance.get(
      `/staffing/therapist-accepted-refferal/${body.staffUserId}/${body.disciplineName}`,
      body
    );
    return data1.data;
  }
);

export const checkMrn = createAsyncThunk("Staffing/Mrn/Check", async (body) => {
  const apiData = await axiosInstance.post(
    `/staffing/patientMrn-Duplicate-Check`,
    body
  );
  return apiData.data;
});

export const savePatientForm = createAsyncThunk(
  "Staffing/Form/SaveForm",
  async (body) => {
    const apiData = await axiosInstance.post(
      `/staffing/addStaffFormData`,
      body
    );
    return apiData.data;
  }
);
export const updateStaffingDetailsById = createAsyncThunk(
  "getUserDetailsById/staffing/update-staff-detail",
  async (id) => {
    const data1 = await axiosInstance.get(`staffing/get-staff-detail/${id}`);
    return data1.data;
  }
);
export const lastVisitTypeApprovalList = createAsyncThunk(
  "getUserDetailsById/staffing/staffLast-visitData",
  async (body) => {
    const data1 = await axiosInstance.post(
      `staffing/staffLast-visitData`,
      body
    );
    return data1.data;
  }
);

export const getInvoicePeriod = createAsyncThunk(
  "getInvoicePeriod/staffing/getInvoicePeriod",
  async (body) => {
    const data1 = await axiosInstance.get(`staffing/get-invoicePeriod`);
    return data1.data;
  }
);
