import jwt_decode from "jwt-decode";
import jwtEncode from "jwt-encode";

export const isUserLogined = async () => {
  let token = null;
  if (typeof window !== "undefined") {
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      // token = document.cookie.split("=")[1]
      document.cookie.split(";").map((item) => {
        if (item.split("=")[0].trim() === "token") {
          token = item.split("=")[1];
        }
      });
    }
  }
  return token ? true : false;
};

export const getToken = () => {
  let token = null;
  if (typeof window !== "undefined") {
    // token = localStorage.getItem("token");
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      // token = document.cookie.split("=")[1]
      document.cookie.split(";").map((item) => {
        if (item.split("=")[0].trim() == "token") {
          token = item.split("=")[1];
        }
      });
    }
    return token;
  }
  return token;
};

export const currentRole = {
  admin: "admin",
  user: "user",
  superadmin: "superadmin",
  agency: "agency",
  supervisingtherapist: "supervisingtherapist",
  assistanttherapist: "assistanttherapist",
  patient: "patient",
};
export const rolePaths = {
  agency: "agencysignup",
  supervisingtherapist: "supervisingtherapist",
  assistanttherapist: "assistanttherapist",
};

export const defaultStatus = {
  pending: "pending",
  success: "success",
  approved: "approved",
  rejected: "rejected",
  inprogress: "inprogress",
  incomplete: "incomplete",
  approvalneeded: "approvalneeded",
  awaitingagencyapproval: "awaitingagencyapproval",
  unfinishedprofile: "unfinishedprofile",
  staffedreferral: "staffedreferral",
  patientneedingscheduling: "patientneedingscheduling",
  pendingdocumentation: "pendingdocumentation",
  documentationtobecorrected: "documentationtobecorrected",
  missdedvisitsneeded: "missdedvisitsneeded",
  frequencyupdateneeded: "frequencyupdateneeded",
  declined: "declined",
  blocked: "blocked",
  blockedpending: "blockedpending",
  cancelled: "cancelled",
  uploaded: "uploaded",
  scheduled: "scheduled",
  needcorrection: "needcorrection",
  declinedreferrals: "declinedreferrals",
  missvisitneeded: "missvisitneeded",
  frequencyextensionneeded: "frequencyextensionneeded",
  // incompletedoc: "incompletedoc",
  incompletedoc: "pendingnotes",
  needreview: "needreview",
  completed: "completed",
  pendingnotes: "pendingnotes",
  visitnotes: "visitnotes",
  needcosigned: "needcosigned",
  frequencyAndPhysicianOrder: "frequencyAndPhysicianOrder",
  awaitingsocroc: "awaitingsocroc",
  newfrequencies: "newfrequencies",
  pendingDMEs: "pendingDMEs",
  authorization: "authorization",
  lastvisittypeapproval: "lastvisittypeapproval",
  tobescheduled: "tobescheduled",
  patientneedscheduling: "patientneedscheduling",
  awaitinghha: "awaitinghha",
  staffneedscheduling: "staffneedscheduling",
  frequencyupdateneeded: "frequencyupdateneeded",
};

export const defaultCountryCode = "US";

export const floatRegex = /^[+-]?\d+(\.\d+)?$/;

export function isAuthenticated() {
  let token;
  if (localStorage.getItem("token")) {
    token = localStorage.getItem("token");
  } else {
    // token = document.cookie.split("=")[1];
    document.cookie.split(";").map((item) => {
      if (item.split("=")[0].trim() === "token") {
        token = item.split("=")[1];
      }
    });
  }

  let currentDate = new Date();
  currentDate = currentDate.setMinutes(currentDate.getMinutes() - 5);
  try {
    jwt_decode(token);
    const { exp } = jwt_decode(token);
    if (exp < (new Date(currentDate).getTime() + 1) / 1000) {
      return false;
    }
  } catch (err) {
    return false;
  }
  return true;
}

export const getUserId = () => {
  var token = getToken();
  let decoded = null;
  if (token) {
    decoded = jwt_decode(token);
  }
  return decoded;
};

export const decodeToken = (token) => {
  let decoded = null;
  if (token) {
    decoded = jwt_decode(token);
  }
  return decoded;
};

export const agencySignupSteps = [
  "account",
  "rates",
  "payments",
  "termconditions",
  "success",
];

export const therapistSignupSteps = [
  "account",
  "rates",
  "area",
  "professionalData",
  "credentials",
  "notifications",
  "payments",
  "termconditions",
  "success",
  "compliance",
  "backgroundcheck",
];

export const paymentTypes = {
  creditCard: "CreditCard",
  bankAccount: "BankAccount",
};

export const professionalExperienceTypes = [
  "specialtiespreferred",
  "languagespoken",
  "performperweek",
  "daysweekavailable",
];

export const professionaltype = {
  specialtiespreferred: "specialtiespreferred",
  languagespoken: "languagespoken",
  performperweek: "performperweek",
  daysweekavailable: "daysweekavailable",
};

export const credentials = [
  "therapylicense",
  "driverslicense",
  "autoinsurance",
  "professionallaibilityinsurance",
  "cprcertification",
  "physical",
  "tbtest",
  "fluvaccination",
  "hepatitisB",
  "covidrecord",
  "businesslicense",
  "w9",
  // "compliance",
  // "competency",
];

export const userCredentials = {
  therapylicense: "therapylicense",
  driverslicense: "driverslicense",
  autoinsurance: "autoinsurance",
  professionallaibilityinsurance: "professionallaibilityinsurance",
  cprcertification: "cprcertification",
  physical: "physical",
  tbtest: "tbtest",
  fluanddeclination: "fluanddeclination",
  covidrecord: "covidrecord",
  businesslicense: "businesslicense",
  w9: "w9",
  compliance: "compliance",
  competencies: "competencies",
  fluvaccination: "fluvaccination",
  hepatitisB: "hepatitisB"
};

export const completeAddress = {
  address: "Gurugram, Haryana, sec10-A",
  state: "Haryana",
  city: "Gurugram",
  street: "10337",
  zip: "122001",
  aptUnit: "398p",
  latitude: "29.058777",
  longitude: "76.085602",
};

export const docuSignDocs = {
  w9: "w9",
  compliance: "compliance",
  competency: "competency",
  fluanddeclination: "fluanddeclination",
};
export const statusType = { case: "case", default: "default" };

export function formatPhoneNumber(phoneNumber) {
  const cleanedNumber = phoneNumber?.replace(/\D/g, '');
  const formattedNumber = cleanedNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
  return formattedNumber;
}
export function formatUsPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ')', match[3], '-', match[4]].join('');
  }
  return null;
}

export const encodedData = (dataToEncode) => {
  return jwtEncode(dataToEncode, "thisisasamplesecret");
};

export const decodeData = (dataToDecode) => {
  return jwt_decode(dataToDecode);
};

export const messageFormatting = (string) => {
  let modifiedString = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() + ".";
  return modifiedString
}

export const openToNewWindow = (url) => {
  window.open(url, "_blank").focus();
}