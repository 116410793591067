import React from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../images/nav.png";
import sectionimage from "../../images/forgotpassword.png";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Form } from "bootstrap-4-react";
import { Form as Forms, Field } from "react-final-form";
import { resetPassword } from "../../Redux/actions/auth";
// import { validatePassword } from "../../utils/common/commonPassord";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Question from "../../images/forgot-password-btn.svg";
import { passwordRegex } from "../../utils/helper";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const isLoading = useSelector((e) => e.authSlice.isLoading);

  function handleSubmit(values) {
    let temp = { ...values };

    if (params && params.id) {
      temp.token = params.id;
      delete temp.confirmPassword;
    }

    dispatch(resetPassword(temp)).then((res) => {
      if (res && res.payload && res.payload.success) {
        toast.success(`${res.payload.message}.`);
        navigate(`/login`);
      } else {
        toast.error(`${res.payload.message}.`);
      }
    });
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className="Profile_tooltip1" {...props}>
      Password strength/length should be at least 8 characters and maximum 64
      characters and contain an uppercase, a lowercase, a special character, and
      a digit.
    </Tooltip>
  );

  const validatePassword = (values) => {
    let errors = {};
    var regex = passwordRegex;
    if (!values.password) {
      errors["password"] = "Password is required.";
    }
    if (values.password?.length > 0 || values.confirmPassword?.length > 0) {
      if (!values.confirmPassword) {
        errors["confirmPassword"] = "Confirm Password is required.";
      }
      if (values && values.confirmPassword) {
        if (values.password !== values.confirmPassword) {
          errors["confirmPassword"] = "Both passwords should match.";
        }
      }
      if (values && values.password) {
        if (!regex.test(values.password)) {
          if (values?.password?.length < 8 || values?.password?.length > 64) {
            errors["password"] =
              "Password strength/length should be at least  8 characters and maximum 64 characters and contain an Uppercase, a Lowercase, a special character, and a digit.";
          } else {
            errors["password"] =
              "Password strength/length should be at least  8 characters and maximum 64 characters and contain an Uppercase, a Lowercase, a special character, and a digit.";
          }
        }
      }
    }
    return errors;
  };

  return (
    <div className="main-area">
      <div className="navbar-main">
        <img
          src={logo}
          className="logo-header"
          onClick={() => navigate("/")}
          alt="logo"
        />
        <button className="login-button" onClick={() => navigate("/")}>
          Login
        </button>
      </div>
      <Container>
        <Row className="forget-password">
          <Col md={6}>
            <Forms
              onSubmit={handleSubmit}
              validate={validatePassword}
              render={({
                handleSubmit,
                submitting,
                submitFailed,
                reset,
                valid,
              }) => (
                <Form
                  onSubmit={(e) => {
                    if (!valid)
                      toast.error("Please complete all required fields.");
                    handleSubmit(e);
                  }}
                  className="form-main"
                >
                  <h2>Reset Password</h2>
                  <div className="form-group">
                    <Field name="password">
                      {({ input, meta }) => (
                        <>
                          <label>New Password</label>
                          <input
                            className="reset_input"
                            type="password"
                            autoComplete="new-password"
                            placeholder="New Password"
                            {...input}
                          />

                          {submitFailed && meta.error && meta.touched && (
                            <p className="alert alert-danger login-error reset_input ">
                              {meta.error}
                            </p>
                          )}
                        </>
                      )}
                    </Field>
                    <div className="position-absolute reset_icon_tooltip pr-2">
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <img
                          src={Question}
                          style={{ cursor: "pointer" }}
                          alt=""
                        />
                      </OverlayTrigger>
                    </div>
                  </div>

                  <div className="form-group">
                    <Field name="confirmPassword">
                      {({ input, meta }) => (
                        <>
                          <label>Confirm Password</label>
                          <input
                            type="password"
                            className="reset_input "
                            placeholder="Confirm  New  Password"
                            {...input}
                          />

                          {submitFailed && meta.error && meta.touched && (
                            <p className="alert alert-danger sslogin-error reset_input ">
                              {meta.error}
                            </p>
                          )}
                        </>
                      )}
                    </Field>
                  </div>

                  <button
                    className="save-btn"
                    type="submit"
                    onClick={reset}
                    disabled={submitting}
                  >
                    Save & Continue
                  </button>
                </Form>
              )}
            />
          </Col>
          <Col md={6}>
            <div className="forget-right">
              <img src={sectionimage} alt="sectionimage" />
            </div>
          </Col>
        </Row>
        {isLoading && <Loader />}
      </Container>
    </div>
  );
};

export default ResetPassword;
