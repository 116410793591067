import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { setDocuSignEnvelope } from "../../../Redux/actions/therapist";
import { docuSignDocs, isUserLogined } from "../../../utils/auth";

const DocuSignCompliance = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (searchParams && searchParams.get("email") && searchParams.get("env")) {
      dispatch(
        setDocuSignEnvelope({
          email: searchParams.get("email"),
          envelopeId: searchParams.get("env"),
          types: docuSignDocs.compliance,
        })
      ).then((response) => {
        setLoading(false);
        if (isUserLogined() && response?.payload?.data?.token) {
          navigate(`/myprofile/5`);
        } else {
          navigate(
            `/supervisingtherapist/?auth=${response?.payload?.data?.token}`
          );
        }
      });
    }
  }, []);

  return <div>{loading && <Loader />} </div>;
};

export default DocuSignCompliance;
