import { Col } from "bootstrap-4-react";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { CgSearch } from "react-icons/cg";
import userImage from "../../images/usertable01.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import CommonTable from "../../components/common/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import { infiniteScrollGetCardsByRoleStatus } from "../../Redux/actions/auth";
import { currentRole, defaultStatus, encodedData } from "../../utils/auth";
import { debounce } from "lodash";
import Loader from "../../components/Loader";
import { resetUserList } from "../../Redux/slices/adminSlice";
import { apiBasePath } from "../../Redux/config/config";

export const DailyAgency = ({ handleClose, show, count }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((e) => e.adminSlice.isLoading);
  const [hasMore, sethasMore] = useState(true);
  const [pageNo, setPageNo] = useState(2);
  const [pageSize] = useState(10);
  const [searchValue, setsearchValue] = useState("");
  const infiniteScrollCardList = useSelector(
    (e) => e.adminSlice.infiniteScrollCardList
  );
  const infiniteScrollCardsCount = useSelector(
    (e) => e.adminSlice.infiniteScrollCardsCount
  );

  let roles = [currentRole.agency];
  let status = [defaultStatus.approved];

  const setUserData = async () => {
    dispatch(
      infiniteScrollGetCardsByRoleStatus({
        isOnline: true,
        pageSize: pageSize,
        pageNo: pageNo,
        agencyName: searchValue && searchValue,
        roles,
        status,
      })
    );
    if (infiniteScrollCardsCount === infiniteScrollCardList.length) {
      sethasMore(false);
    }
    setPageNo((page) => page + 1);
  };

  useEffect(() => {
    dispatch(
      infiniteScrollGetCardsByRoleStatus({
        isOnline: true,
        pageSize: pageSize,
        pageNo: 1,
        roles,
        status,
      })
    );
    return () => {
      dispatch(resetUserList());
    };
  }, []);

  const columns = ["name"];
  const columnsToHide = ["image"];

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "name":
        return "NAME";
      case "lastName":
        return "Last Name";
      case "email":
        return "Email";

      default:
        return colName;
    }
  };

  const actions = [
    {
      action: (rowData) => (
        <td className="">
          <button
            className="view-btn tr-btn ml-auto  mt-3 mb-3 "
            onClick={() => {
              navigate(`/newusers/agency/${encodedData(rowData.id)}`);
            }}
          >
            View
          </button>
        </td>
      ),
    },
  ];

  const renderRowData = (result, col, item) => {
    if (col === "name") {
      return (
        <>
          <p className="f-color" style={{ margin: "0px" }}>
            <img
              className="table-img"
              src={
                result && result.image
                  ? `${apiBasePath}documents/therapist/${result?.image}`
                  : userImage
              }
              crossOrigin="anonymous"
              style={{ borderRadius: "50%", objectFit: "cover" }}
              alt="img"
            />
            {result[item]}
          </p>
        </>
      );
    }
    return <p className="f-color2 mb-3 mt-3">{result[item]}</p>;
  };

  const debounceCall = debounce((ele) => {
    setsearchValue(ele);
    dispatch(
      infiniteScrollGetCardsByRoleStatus({
        pageSize: pageSize,
        isOnline: true,
        pageNo: 1,
        agencyName: ele,
        roles,
        status,
      })
    );
    setPageNo(1);
  }, 400);

  return (
    <>
      <Modal show={show} onHide={handleClose} className="dailyActive" size="lg">
        <Modal.Header
          className="closeButton border-0"
          closeButton
        ></Modal.Header>
        <div className=" modal_title align-items-center mb-4 d-flex justify-content-between pl-5 pr-5 zindex">
          <h5 className="Daily-therapist-h5"> Daily Active Agencies {count}</h5>

          <div className="search-bar d-flex" style={{ width: "44%" }}>
            <CgSearch size={"25px"} />
            <input
              placeholder="Search by agency name..."
              className="pl-2 input-width"
              onChange={(ele) => debounceCall(ele.target.value)}
              style={{ border: "none", fontSize: "18px", outline: "none" }}
            />
          </div>
        </div>

        <div
          id="scrollableDiv"
          style={{
            height: 700,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isLoading && (
            <div className="loader-1" style={{ height: "700" }}>
              <Loader />
            </div>
          )}
          <InfiniteScroll
            dataLength={infiniteScrollCardList && infiniteScrollCardList.length}
            next={setUserData}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            <Col lg={11} className="m-auto pb-1">
              <div className="table-responsive">
                <CommonTable
                  data={infiniteScrollCardList && infiniteScrollCardList}
                  columns={columns}
                  columnsToHide={columnsToHide}
                  overrideColumnName={overrideColumnName}
                  actions={actions}
                  renderRowData={renderRowData}
                  noData={"No Data Available"}
                  isLoading={isLoading}
                />
              </div>
            </Col>
          </InfiniteScroll>
        </div>
      </Modal>
    </>
  );
};
