import React, { useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import jsPDF from "jspdf";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsTherapistLoading,
  setRequest,
} from "../../../Redux/slices/therapistSignupSlice";
import Loader from "../../../components/Loader";
import SignatureCanvas from "../../../components/common/SignatureCanvas";
import { toast } from "react-toastify";
import { changeDateFormat, todayDate } from "../../../utils/helper";

const PTSkills = (props) => {
  const {
    handleChange,
    uploadedFileBytes,
    setSkillsModal,
    signatureImage,
    setSignatureImage,
    signatureAdded,
    setSignatureAdded,
  } = props;
  const [displayState, setDisplayState] = useState(false);
  const optionsData = [
    { value: 0, label: "No Experience" },
    { value: 1, label: "Need Refresh" },
    { value: 2, label: "Competent" },
  ];
  const organisationAdmission = [
    {
      fieldName: "OASISEval",
      label:
        "OASIS and non-OASIS Start of Care (SOC) evaluations: Conduct and document evaluation and develop an individualized care plan with problem list and measurable goals with time frame",
    },
    {
      fieldName: "reviewHome",
      label:
        "Review Home Health Agency specific SOC forms with the patient/family/caregiver and collect patient signatures on consent forms",
    },
    {
      fieldName: "determinePatientEligibilty",
      label:
        "Determine patient’s eligibility for Home Healthcare services and documents in the initial evaluation",
    },
    {
      fieldName: "reviewPatientMedications",
      label: "Review all patient’s medications and complete medication profile",
    },
    {
      fieldName: "verifyInsurance",
      label: "Verify insurance with patient/primary caregiver",
    },
    {
      fieldName: "intstructforRight",
      label: "Instruct patient regarding Advance Directives/Patient’s rights",
    },
    {
      fieldName: "instructforHHA",
      label:
        "Instruct patient regarding Home Health Agency phone number and on-call procedure",
    },
    {
      fieldName: "conductHealthHistory",
      label:
        "Conduct a Health history review with the patient/family/caregiver",
    },
    {
      fieldName: "conductDischargePlanning",
      label: "Conduct Discharge Planning with patient/family/caregiver",
    },
    {
      fieldName: "reportToHHA",
      label:
        "Report to Home Health Agency Day of visit: Skilled need,frequency and duration, DME needs, and need for other disciplines",
    },
    {
      fieldName: "reportToPhysician",
      label:
        " Report to physician within 24 hours of SOC/Evaluation and docume fieldName:''n,t",
    },
  ];
  const docAndCareCoordination = [
    {
      fieldName: "initialEval",
      label:
        "Initial evaluation: Conduct and document evaluation and develop an individualized care plan with problem list and measurable goals with time frame",
    },
    {
      fieldName: "patientEligibility",
      label:
        "Determine patient’s eligibility for Home Healthcare services and documents in the initial evaluation",
    },
    {
      fieldName: "conductDischargePlan",
      label: "Conduct Discharge Planning with patient/family/caregiver",
    },
    {
      fieldName: "visitNotes",
      label:
        "Visit notes reflect the Plan of Care (POC), including: problem(s), interventions, patient/caregiver response, and progress towards goals",
    },
    {
      fieldName: "completesDocs",
      label: "Completes documentation within 48 hours of the visit",
    },
    {
      fieldName: "docsDiscuss",
      label:
        "Documents discussion of patient care needs, progress,and discharge planning with other disciplines",
    },
    {
      fieldName: "AssessResponse",
      label: "Assess response to treatment; updates Care Plan as needed",
    },
    {
      fieldName: "PTASupervision",
      label: "Supervision of Physical Therapist Assistant (PTA)",
    },
    {
      fieldName: "CHHASupervision",
      label: "Supervision of Certified Home Health Aide (CHHA)",
    },
    {
      fieldName: "reassessmentCompletion",
      label:
        "Completion of 30 day re-assessment with skilled need and progress towards goals",
    },
    {
      fieldName: "completeResumption",
      label:
        "Complete Resumption of Care assessment including skilled need, progress toward goals, medication profile,and Resumption of Care Verbal Order",
    },
    {
      fieldName: "completeRecertification",
      label:
        "Completion of Recertification assessment within 5 day window including skilled need, progress toward goals, medication profile, and Recertification Verbal Order",
    },
    {
      fieldName: "complaintAndIncident",
      label: "Complaint and incident reporting as appropriate",
    },
    {
      fieldName: "reportDocsChange",
      label:
        "Report and Document changes in Patient condition, falls,and/or hospitalizations to Home Health Agency as soon as possible",
      // suboptions: [
      //   {
      //     fieldName: "testMuscleStrength",
      //     label: "Test muscle strength at selected joints",
      //   },
      //   {
      //     fieldName: "testFunctionalStrength",
      //     label: "Test functional strength",
      //   },
      //   {
      //     fieldName: "ContraindicationsMMT",
      //     label: "Contraindications for MMT testing",
      //   },
      // ],
    },
  ];
  const patientScheduling = [
    {
      fieldName: "contactPatient",
      label:
        "Contact patient within 24 hours of receiving patient information or being notified to schedule",
    },
    {
      fieldName: "informPatient",
      label:
        "Inform patient which Home Health Home Health Agency care is being provided under",
    },
    {
      fieldName: "notifyHealthAgencyReach",
      label:
        "Notify Home Health Agency if unable to reach the patient after multiple attempts",
    },
    {
      fieldName: "notifyHealthAgencyAccomodate",
      label:
        "Notify Home Health Agency if unable to accommodate patient’s schedule request",
    },
    {
      fieldName: "scheduleInitialVisit",
      label: "Document all attempts to schedule initial visit",
    },
    {
      fieldName: "docScheduling",
      label: "Document scheduling of initial visit",
    },
    {
      fieldName: "callMessagePatient",
      label: "Call/message patient same day of visit to confirm appointment",
    },
  ];
  const systemsAssessment = [
    { fieldName: "cardiacHeartSound", label: "Cardiac: heart sounds" },
    {
      fieldName: "pulmonaryShortBreath",
      label: "Pulmonary: shortness of breath",
    },
    {
      fieldName: "gtBowelfunction",
      label: "GI/GU: bowel/bladder function/incontinence",
    },
    {
      fieldName: "endocrineBloodSugar",
      label: "Endocrine: blood sugar reading by patient/caregiver",
    },
    {
      fieldName: "mentalstatus",
      label:
        "Mental Status: level of alertness, orientation,comprehension, concentration, memory",
    },
    {
      fieldName: "neurologicalAccident",
      label:
        "Neurological: identify Cerebrovascular Accident and Transient Ischemic Attack signs/symptoms",
    },
    {
      fieldName: "skinIntegrity",
      label:
        "Skin Integrity: identify skin breaks, infections,rashes; make appropriate referral to Registered Nurse (RN)/Medical Doctor (MD)",
    },
    {
      fieldName: "wongBakerScale",
      label:
        " Pain: use of Wong Baker Scale, Visual Analog Scale,Numerical Rating Scale, etc",
    },
    {
      fieldName: "abnormalPainScore",
      label:
        "Report abnormal Pain score to Home Health Agency and patient’s Physician while with the patient for instructions (greater than 6/10 pain)",
    },
    {
      fieldName: "vitalSignsPulse",
      label:
        "Vital Signs: pulse, respiration, blood pressure (pulse oximetry only per POC/MD order)",
    },
    {
      fieldName: "reportAbnormal",
      label:
        " Report abnormal Vital Signs to Home Health Agency and patient’s Physician while with the patient for instructions ",

      suboptions: [
        {
          fieldName: "pulse",
          label: "Pulse- greater than 100 or less than 60",
        },
        {
          fieldName: "respiration",
          label: " Respiration- greater than 20 or less than 12",
        },
        {
          fieldName: "bloodPressure",
          label:
            "Blood pressure- Systolic greater than 160 mmHg or less than 85 mmHg and/or Diastolic greater than 90 mmHg or less than 60 mmHg",
        },
      ],
    },
    {
      fieldName: "medicationmgmt",
      label: "  Medication (med) management",
      suboptions: [
        {
          fieldName: "riskMeds",
          label: "baseline knowledge of high risk meds",
        },
        {
          fieldName: "changedMeds",
          label: "document new/changed meds",
        },
      ],
    },
  ];
  const ROMGoniometry = [
    {
      fieldName: "goniometerUse",
      label: "Use of a goniometer at selected joints",
    },
    {
      fieldName: "functionalROM",
      label: "Assess functional ROM and recognize pain limitations",
    },
    { fieldName: "endFeelJoint", label: "Assess end feel/joint integrity" },
    {
      fieldName: "ContraindicationsROM",
      label: "Contraindications for ROM testing",
    },
  ];
  const MMTStrength = [
    {
      fieldName: "testMuscleStrength",
      label: "Test muscle strength at selected joints",
    },
    { fieldName: "testFunctionalStrength", label: "Test functional strength" },
    {
      fieldName: "ContraindicationsMMT",
      label: "Contraindications for MMT testing",
    },
  ];
  const balance = [
    {
      fieldName: "standardizedBalance",
      label:
        "Use of Standardized Balance Tests (e.g.: Timed Up And Go (TUG), Tinetti, Functional Reach)",
    },
    {
      fieldName: "staticDynamicSitting",
      label: "Assess static/dynamic sitting and standing balance",
    },
    {
      fieldName: "funcProgressiveExercises",
      label: "Functional and progressive balance exercises",
    },
  ];
  const coordination = [
    {
      fieldName: "coordinationGross",
      label:
        " Coordination/gross motor function tests: finger to nose,heel to shin, heel to toes, alternating rapid movement, cross body movement",
    },
  ];
  const perceptualMotor = [
    {
      fieldName: "assessVisualFunction",
      label:
        " Assess visual function, figure ground principle, Right/Left discrimination, depth perception, body awareness, and motor planning",
    },
  ];
  const sensationProprioception = [
    {
      fieldName: "sensationTests",
      label:
        "Sensation tests: Hot/Cold, Two Point, Sharp/Dull, Light Touch/Pressure",
    },
    {
      fieldName: "recognizeDeficits",
      label:
        "Recognize deficits in proprioception and provide treatment strategies",
    },
  ];
  const muscleTone = [
    {
      fieldName: "assessMuscleTone",
      label:
        " Assess muscle tone; increased, decreased, flaccid, paresis, paralysis, paresthesia, hyperesthesia, kinesthesia, etc",
    },
  ];
  const edema = [
    { fieldName: "edemaPitting", label: "Pitting edema test" },
    { fieldName: "edemaMeasure", label: "Edema measurement sites" },
    { fieldName: "edemaReduction", label: "Edema reduction techniques" },
    {
      fieldName: "lymphedemaManagement",
      label: "Indications for lymphedema management",
    },
  ];
  const ambulationAssistiveDevices = [
    {
      fieldName: "canesWalkers",
      label: " Canes, Walkers, Crutches, etc:",
      suboptions: [
        {
          fieldName: "heightAdjustment",
          label: "Proper height adjustment",
        },
        {
          fieldName: "usingADs",
          label: "Proper technique for using ADs",
        },
        {
          fieldName: "indicationFrontWheel",
          label:
            "Indications for Front Wheel Walker, Four Wheel Walker, Single Point Cane, etc.",
        },
        {
          fieldName: "monitorGuard",
          label: "Monitor and guard patient during functional use",
        },
      ],
    },
  ];
  const beds = [
    {
      fieldName: "appropriateHeight",
      label: "Appropriate height of bed, location",
    },
    {
      fieldName: "positionPatientSitting",
      label:
        "Proper position of patient sitting at edge and promoting safe transition from supine to sitting edge of bed (EOB)",
    },
    {
      fieldName: "ModificationsDiscuss",
      label:
        "Modifications- discuss rails vs. trapeze vs. hospital bed (electrical vs. manual)",
    },
  ];
  const oxygen = [
    { fieldName: "changeTank", label: "Change tank" },
    { fieldName: "adjustLiterFlow", label: "Adjust liter flow (per MD order)" },
    { fieldName: "flowRate", label: "Flow rate reading  " },
    {
      fieldName: "nasalCanula",
      label: "Nasal cannula/mask application",
    },
    {
      fieldName: "safetyInstruction",
      label: "Safety instruction- no smoking, open flame, etc",
    },
    {
      fieldName: "pulseOximetry",
      label: "Pulse Oximetry- indication, order",
    },
  ];
  const CPM = [
    {
      fieldName: "DemonstrationToPatient",
      label:
        "Demonstration and instruction to patient of proper use-axis of rotation, heel plate, ROM, setting position of leg",
    },
    {
      fieldName: "identifyIndications",
      label:
        "Able to identify indications and contraindications for use  of CPM",
    },
  ];
  const strengtheningProgression = [
    {
      fieldName: "strengtheningTools",
      label:
        "Utilize appropriate strengthening tools (Theraband, weights, pedal bike, etc)",
    },
    {
      fieldName: "progressiveResistance",
      label: "Progressive Resistance Exercise recommendations",
    },
    {
      fieldName: "functionalExercises",
      label:
        "Functional exercises (progressive weight bearing, open and closed chain, etc)",
    },
    {
      fieldName: "developHEP",
      label:
        "Develop Home Exercise Program (HEP) based on patient deficits and needs",
    },
    {
      fieldName: "IndicationsContraindications",
      label: "Indications and Contraindications for strengthening",
    },
  ];
  const hoyerLift = [
    { fieldName: "safeHoyerUse", label: "Safe use of Hoyer" },
    { fieldName: "slingSize", label: "Sling sizes" },
    {
      fieldName: "adjustmentOfSling",
      label: "Adjustment/fixation of sling to Hoyer",
    },
    {
      fieldName: "instructionToCaregiver",
      label: "Instructions to caregiver with demonstration",
    },
    {
      fieldName: "trunkControl",
      label: "Indications (trunk control, transfer levels, etc)",
    },
  ];
  const seatingAndMobility = [
    { fieldName: "powerIndication", label: "Indications for power vs. manual" },
    {
      fieldName: "properFittingLeg",
      label:
        "Proper fitting: leg rests, arm rests, seat depth, seat width, height",
    },
    {
      fieldName: "properFittingCushion",
      label:
        "Proper fitting of cushion and indication for different cushion types",
    },
    {
      fieldName: "safetyLock",
      label: "Safety: lock with transfers, positioning, etc",
    },
  ];
  const orthoticsProsthetics = [
    {
      fieldName: "bracingType",
      label: "Indication and types of bracing based on prognosis",
    },
    {
      fieldName: "aprRecommendations",
      label:
        "Make appropriate recommendations based on physical presentation of the patient",
    },
  ];
  const endurance = [
    {
      fieldName: "useRPEScale",
      label: "Use of Rating of Perceived Exertion (RPE) scale",
    },
    {
      fieldName: "addressSignsSymptoms",
      label: "Address signs and symptoms of endurance limitations",
    },
  ];
  const posture = [
    {
      fieldName: "normalPosturalAlignment",
      label: "Normal postural alignment in sitting and standing",
    },
  ];
  const bodyMechanics = [
    { fieldName: "gaitBeltUse", label: "Use of transfer/gait belt" },
    {
      fieldName: "properbodyMechanics",
      label:
        "Proper body mechanics for bending, lifting, reaching, and computer ergonomics",
    },
    {
      fieldName: "teachBodyMechanics",
      label: "Teach proper body mechanics to caregivers",
    },
  ];
  const pain = [
    {
      fieldName: "painManagement",
      label:
        " Traditional and alternative pain management techniques within scope of practice",
    },
    {
      fieldName: "identifyPain",
      label:
        "Identify ineffective and inappropriate pain management and appropriate follow-ups",
    },
  ];
  const patientEducation = [
    { fieldName: "learningNeeds", label: "Determine learning needs" },
    { fieldName: "setObjectives", label: "Set measurable objectives" },
    {
      fieldName: "developTeachingPlan",
      label: "Develop/implement teaching plan",
    },
    {
      fieldName: "evaluateEffectiveness",
      label: "Evaluate effectiveness of teaching",
    },
    { fieldName: "reviseteaching", label: "Revise teaching plan as necessary" },
    { fieldName: "docPatientResp", label: "Document patient response" },
  ];
  const homeSafetyEnvAdaptions = [
    { fieldName: "fallPrecautions", label: "Fall precautions/prevention" },
    {
      fieldName: "envBarrierAdaption",
      label:
        "Evaluation and adaptation of environmental barriers/hazards in bathroom, bedroom, kitchen, entrance/exit",
    },
    {
      fieldName: "equipmentRecommendations",
      label: "Adaptive equipment recommendations (grab bars, hand  rails, etc)",
    },
    {
      fieldName: "performAssessmentTeaching",
      label: "Perform home safety assessment and teaching",
    },
  ];
  const functionalMobility = [
    {
      fieldName: "assessDemonstrate",
      label: "Assess, Demonstrate, and Teach Patient",
      suboptions: [
        {
          fieldName: "bedMobility",
          label: "Bed mobility",
        },
        {
          fieldName: "transfers",
          label: "Transfers",
        },
        {
          fieldName: "ambulation",
          label: "Ambulation",
        },
      ],
    },
  ];
  const physicalAgentUse = [
    {
      fieldName: "electricalStimulation",
      label:
        " Identify indications and contraindications for Electrical Stimulation and Ultrasound",
    },
    {
      fieldName: "hotColdMassage",
      label: "Identify indications and contraindications for Hot/Cold massage",
    },
  ];
  const cardioPulmonary = [
    {
      fieldName: "pursedLipBreathing",
      label:
        "Demonstrate/Teach Pulmonary exercises: pursed lip breathing, energy conservation, etc",
    },
    {
      fieldName: "posturalDrainage",
      label: "Demonstrate/Teach Postural Drainage",
    },
    {
      fieldName: "percussion",
      label: "Demonstrate/Teach Percussion post drainage",
    },
  ];
  const DME = [
    {
      fieldName: "recommendEquipment",
      label:
        "Recommend durable medical equipment (shower chair, bedside commode, etc)",
    },
  ];
  const infectionCtrlPrecautions = [
    {
      fieldName: "standardPrecautions",
      label: "Standard precautions/Universal precautions",
    },
    { fieldName: "handWashing", label: "Hand washing" },
    {
      fieldName: "useSanitizer",
      label: "Sanitizer: use before and after patient care",
    },
    {
      fieldName: "trunkTechnique",
      label: "Proper bag/trunk technique",
    },
    {
      fieldName: "disinfectingSanitizing",
      label: "Disinfecting and Sanitizing equipment",
    },
  ];
  const other = [
    {
      fieldName: "edemaPitting",
      label: "Acknowledge precautions for the population being treated:",
      suboptions: [
        {
          fieldName: "allergies",
          label: "Allergies",
        },
        {
          fieldName: "medicationReactions",
          label: "Medication reactions",
        },
        {
          fieldName: "bleedingPrecautions",
          label: "Bleeding precautions",
        },
        {
          fieldName: "seizurePrecautions",
          label: "Seizure precautions",
        },
        {
          fieldName: "weightPrecautions",
          label: "Weight-bearing precautions",
        },
        {
          fieldName: "spinePrecautions",
          label: "Bending/Lifting/Twisting spine precautions",
        },
        {
          fieldName: "liftingPrecautions",
          label: "Upper extremity weight-lifting precautions",
        },
      ],
    },
    {
      fieldName: "treatmentSession",
      label: "Perform a minimum of 45 minute treatment sessions",
    },
    {
      fieldName: "obtainPatientSignature",
      label:
        "Obtain patient signature on clinical documentation or paper route sheet at every visit",
    },
    {
      fieldName: "instructMedicare",
      label:
        "Instruct Medicare patients on rationale for Notice of Medicare Non-Coverage (NOMNC) and obtain patient signature at least 48 hours prior to discharge",
    },
  ];
  const mainJSON = [
    {
      heading: "ADMISSION TO ORGANIZATION:",
      fieldName: "orgAdmission",
      options: organisationAdmission,
    },
    {
      heading: "DOCUMENTATION & CARE COORDINATION:",
      fieldName: "docCoordination",
      options: docAndCareCoordination,
    },
    {
      heading: "PATIENT SCHEDULING",
      fieldName: "patientScheduling",
      options: patientScheduling,
    },
  ];
  const mainJSON2 = [
    {
      heading: "SYSTEMS ASSESSMENT",
      fieldName: "systemsAssessment",
      options: systemsAssessment,
    },
    {
      heading: "RANGE OF MOTION (ROM)/GONIOMETRY",
      fieldName: "ROMGoniometry",
      options: ROMGoniometry,
    },
    {
      heading: "MANUAL MUSCLE TESTING (MMT)/STRENGTH",
      fieldName: "MMTStrength",
      options: MMTStrength,
    },
    {
      heading: "BALANCE",
      fieldName: "balance",
      options: balance,
    },
    {
      heading: "COORDINATION",
      fieldName: "coordination",
      options: coordination,
    },
    {
      heading: "PERCEPTUAL MOTOR",
      fieldName: "perceptualMotor",
      options: perceptualMotor,
    },
    {
      heading: "SENSATION/PROPRIOCEPTION",
      fieldName: "sensationProprioception",
      options: sensationProprioception,
    },
    {
      heading: "MUSCLE TONE",
      fieldName: "muscleTone",
      options: muscleTone,
    },
    {
      heading: "EDEMA",
      fieldName: "edema",
      options: edema,
    },
    {
      heading: "AMBULATION/ASSISTIVE DEVICES (AD)",
      fieldName: "ambulationAssistiveDevices",
      options: ambulationAssistiveDevices,
    },
    {
      heading: "BEDS",
      fieldName: "beds",
      options: beds,
    },
    {
      heading: "OXYGEN",
      fieldName: "oxygen",
      options: oxygen,
    },
    {
      heading: "CPM (CONTINUOUS PASSIVE MOTION)",
      fieldName: "CPM",
      options: CPM,
    },
    {
      heading: "STRENGTHENING PROGRESSION",
      fieldName: "strengtheningProgression",
      options: strengtheningProgression,
    },
    {
      heading: "HOYER LIFT",
      fieldName: "hoyerLift",
      options: hoyerLift,
    },
    {
      heading: "SEATING AND MOBILITY- WHEELCHAIR (W/C)",
      fieldName: "seatingAndMobility",
      options: seatingAndMobility,
    },
    {
      heading: "ORTHOTICS/PROSTHETICS",
      fieldName: "orthoticsProsthetics",
      options: orthoticsProsthetics,
    },
    {
      heading: "ENDURANCE",
      fieldName: "endurance",
      options: endurance,
    },
    {
      heading: "POSTURE",
      fieldName: "posture",
      options: posture,
    },
    {
      heading: "BODY MECHANICS",
      fieldName: "bodyMechanics",
      options: bodyMechanics,
    },
    {
      heading: "PAIN",
      fieldName: "pain",
      options: pain,
    },
    {
      heading: "PATIENT EDUCATION",
      fieldName: "patientEducation",
      options: patientEducation,
    },
    {
      heading: "HOME SAFETY/ENVIRONMENTAL ADAPTATIONS",
      fieldName: "homeSafetyEnvAdaptions",
      options: homeSafetyEnvAdaptions,
    },
    {
      heading: "FUNCTIONAL MOBILITY",
      fieldName: "functionalMobility",
      options: functionalMobility,
    },
    {
      heading: "USE OF PHYSICAL AGENTS",
      fieldName: "physicalAgentUse",
      options: physicalAgentUse,
    },
    {
      heading: "CARDIO-PULMONARY",
      fieldName: "cardioPulmonary",
      options: cardioPulmonary,
    },
    {
      heading: "DME (DURABLE MEDICAL EQUIPMENT)",
      fieldName: "DME",
      options: DME,
    },
    {
      heading: "INFECTION CONTROL/ PRECAUTIONS",
      fieldName: "infectionCtrlPrecautions",
      options: infectionCtrlPrecautions,
    },
    {
      heading: "OTHER",
      fieldName: "other",
      options: other,
    },
  ];
  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );

  const [isCanvasShow, setIsCanvasShow] = useState(false);

  const saveForm = (image) => {
    setSignatureImage(image);
    toast.success("Signature added.");
  };
  function setInitial(event) {
    let initialValues = {};
    if (uploadedFileBytes && uploadedFileBytes["competencies"]) {
      initialValues = { ...uploadedFileBytes["competencies"] };
    } else if (PrefillDetails) {
      initialValues.Name =
        PrefillDetails.lastName + ", " + PrefillDetails.firstName;
    }
    initialValues.dateCompleted = changeDateFormat(
      todayDate,
      "YYYY-MM-DD",
      "MM/DD/YYYY"
    );
    return initialValues;
  }
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (e) => e.therapistSigunpSlice.isTherapistLoading
  );

  const validate = (values) => {
    const errors = {};

    mainJSON.forEach((main) => {
      main.options.forEach((sub) => {
        if (!values[main.fieldName]) {
          if (!errors[main.fieldName]) {
            let subError = {};
            subError[sub.fieldName] = "Required";
            errors[main.fieldName] = subError;
          } else {
            errors[main.fieldName][sub.fieldName] = "Required";
          }
        } else {
          if (!values[main.fieldName][sub.fieldName]) {
            if (!errors[main.fieldName]) {
              let subError = {};
              subError[sub.fieldName] = "Required";
              errors[main.fieldName] = subError;
            } else {
              errors[main.fieldName][sub.fieldName] = "Required";
            }
          }
        }
      });
    });

    mainJSON2.forEach((main2) => {
      if (main2 && main2.options) {
        main2.options.forEach((sub2) => {
          if (sub2 && sub2.suboptions) {
            sub2.suboptions.forEach((sub3) => {
              const fieldName = sub3.fieldName;
              const fieldValue = values[main2.fieldName];
              if (!fieldValue) {
                if (!errors[main2.fieldName]) {
                  errors[main2.fieldName] = {};
                }
                if (!errors[main2.fieldName][sub2.fieldName]) {
                  errors[main2.fieldName][sub2.fieldName] = "Required";
                }
                errors[main2.fieldName][fieldName] = "Required";
              }
            });
          } else {
            const fieldName = sub2.fieldName;
            const fieldValue = values[main2.fieldName]?.[fieldName];
            if (!fieldValue) {
              if (!errors[main2.fieldName]) {
                errors[main2.fieldName] = {};
              }
              errors[main2.fieldName][fieldName] = "Required";
            }
          }
        });
      }
    });

    if (!values.dateCompleted) {
      errors["dateCompleted"] = "Date is Required.";
    }
    return errors;
  };
  const onSubmit = async (values) => {
    setDisplayState(true);
    if (!signatureImage) {
      toast.info("Please add signature.");
      return;
    }
    dispatch(setIsTherapistLoading({ request: true }));
    const pdf = new jsPDF("p", "mm", "a4");
    let formName = document.getElementById("form-name-section");
    if (formName) {
      formName.style.display = "none";
    }
    let pdfName = document.getElementById("pdf-name-section");
    if (pdfName) {
      pdfName.style.display = "block";
    }
    let htmlContent = document.getElementById("skill");
    let submitButton = htmlContent.querySelector('button[type="submit"]');
    let typeBtn = htmlContent.querySelector(".Approve-btns");

    if (submitButton) {
      submitButton.remove();
    }
    if (typeBtn) {
      typeBtn.remove();
    }

    await pdf.html(htmlContent, {
      callback: (pdf) => {
        pdf.save("skills.pdf");
        let blobObject = pdf.output("blob");
        let files = new File([blobObject], `competenciesdoc.pdf`);
        handleChange({ target: { files: [files] } }, "competencies", values);
        dispatch(setIsTherapistLoading({ request: false }));
        setSkillsModal(false);
      },
      x: -10,
      width: 230,
      margin: [4, 0],
      autoPaging: "text",
      windowWidth: 1920,
    });
    setDisplayState(false);
  };
  const getCurrentDate = () => {
    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <>
      {isLoading && <Loader />}
      <Form
        onSubmit={onSubmit}
        // validate={validate}
        initialValues={useMemo(
          (e) => setInitial(e),
          [PrefillDetails, uploadedFileBytes]
        )}
        render={({
          handleSubmit,
          values,
          valid,
          submitFailed,
          errors,
          touched,
        }) => (
          <form
            onSubmit={(e) => {
              if (!valid) toast.error("Please complete all required fields.");
              handleSubmit(e);
            }}
          >
            <div className="container" id="skill">
              <div className="row ">
                <div className="col-md-12 my-3">
                  <h2 className="text-center " style={{ wordSpacing: "10px" }}>
                    CERTIFICATE OF COMPETENCY AND PT SKILLS CHECKLIST
                  </h2>
                </div>

                <div className="col-md-12 my-3">
                  <h5 className="text-center">PHYSICAL &nbsp; THERAPIST</h5>
                </div>
                <div className="col-md-12 my-3">
                  <div className="form-group">
                    <label>Name</label>
                    <Field name="Name" component="input" placeholder="Name" />
                  </div>
                </div>
                <div className="col-md-8 my-3">
                  <h6>Competency Areas for the Physical Therapist (PT)</h6>
                </div>
                <div className="col-md-4 my-3">
                  <h3>
                    Self-Assessment (KEY)
                    <ul className="list-unstyled my-2">
                      {optionsData.map((options, index) => (
                        <li key={options.value} className="ms-3">
                          <span className="fw-bold">
                            {options.value}. {options.label}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </h3>
                </div>
                <div className="col-md-12 my-3">
                  <h4 style={{ fontWeight: "800" }}>
                    Ability to process paperwork and associated functions
                    necessary to facilitate :
                  </h4>
                </div>
                <ul type="none">
                  {mainJSON &&
                    mainJSON.map((jsonitem, index) => (
                      <div className="row">
                        <div className="col-md-12 p-0">
                          <li className="ml-2">
                            {" "}
                            <h5 style={{ fontWeight: "800" }}>
                              <span className="me-2">-</span>
                              {jsonitem.heading}
                              {submitFailed && errors[jsonitem.fieldName] && (
                                <span
                                  className="alert alert-danger"
                                  role="alert"
                                >
                                  Please complete all required fields.
                                </span>
                              )}
                            </h5>
                          </li>
                        </div>
                        <ul type="none">
                          {jsonitem.options &&
                            jsonitem.options.map((item, index) => (
                              <div className="row" key={item.fieldName}>
                                <div className="col-8 p-0">
                                  <li className="ml-4 d-flex">
                                    <span className="me-2">-</span>{" "}
                                    <p>{item.label}</p>
                                  </li>
                                </div>
                                <div className="col-4 text-center">
                                  {/* {index > 3 && ( */}
                                  {displayState ? (
                                    <p className="mb-2 text-muted">
                                      {values[jsonitem.fieldName] &&
                                      values[jsonitem.fieldName][item.fieldName]
                                        ? optionsData.find(
                                            (ele) =>
                                              ele?.value ==
                                              values[jsonitem.fieldName][
                                                item.fieldName
                                              ]
                                          )?.label
                                        : "Select"}
                                    </p>
                                  ) : (
                                    <Field
                                      name={`${jsonitem.fieldName}.${item.fieldName}`}
                                      component="select"
                                    >
                                      {({ input, meta }) => (
                                        <>
                                          <select
                                            {...input}
                                            style={{
                                              appearance: "none",
                                              outline: "none",
                                            }}
                                            className={
                                              meta.error && meta.touched
                                                ? "AlertDanger"
                                                : ""
                                            }
                                          >
                                            <option value=""> Select</option>
                                            {optionsData.map((option) => (
                                              <option
                                                key={`${item.fieldName}_${option.value}`}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </option>
                                            ))}
                                          </select>
                                        </>
                                      )}
                                    </Field>
                                  )}
                                  {/* )} */}
                                </div>
                                <ul type="disc">
                                  {item.suboptions &&
                                    item.suboptions.map((subitem) => (
                                      <div
                                        className="row"
                                        key={subitem.fieldName}
                                      >
                                        <div className="col-8 ps-4">
                                          <li className="ml-5">
                                            <p>{subitem.label}</p>
                                          </li>
                                        </div>
                                        <div className="col-4 text-center">
                                          {displayState ? (
                                            <p className="mb-2 text-muted">
                                              {values[jsonitem.fieldName] &&
                                              values[jsonitem.fieldName][
                                                item.fieldName
                                              ]
                                                ? optionsData.find(
                                                    (ele) =>
                                                      ele?.value ==
                                                      values[
                                                        jsonitem.fieldName
                                                      ][item.fieldName]
                                                  )?.label
                                                : "Select"}
                                            </p>
                                          ) : (
                                            <Field
                                              name={`${jsonitem.fieldName}.${subitem.fieldName}`}
                                              component="select"
                                            >
                                              {({ input, meta }) => (
                                                <select
                                                  {...input}
                                                  style={{
                                                    appearance: "none",
                                                    outline: "none",
                                                  }}
                                                  className={
                                                    meta.error && meta.touched
                                                      ? "AlertDanger"
                                                      : ""
                                                  }
                                                >
                                                  <option value="">
                                                    {" "}
                                                    Select
                                                  </option>
                                                  {optionsData.map((option) => (
                                                    <option
                                                      key={`${subitem.fieldName}_${option.value}`}
                                                      value={option.value}
                                                    >
                                                      {option.label}
                                                    </option>
                                                  ))}
                                                </select>
                                              )}
                                            </Field>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </ul>
                              </div>
                            ))}
                        </ul>
                      </div>
                    ))}
                </ul>

                <div className="col-md-12 my-3">
                  <h4 style={{ fontWeight: "800" }}>
                    Ability to assess and document:
                  </h4>
                </div>
                <ul type="none">
                  {mainJSON2 &&
                    mainJSON2.map((jsonitem, index) => (
                      <div className="row">
                        <div className="col-md-12 p-0">
                          <li className="ml-2">
                            {" "}
                            <h5 style={{ fontWeight: "800" }}>
                              <span className="me-2">-</span>
                              {jsonitem.heading}{" "}
                              {submitFailed && errors[jsonitem.fieldName] && (
                                <span
                                  className="alert alert-danger"
                                  role="alert"
                                >
                                  Please complete all required fields.
                                </span>
                              )}
                            </h5>
                          </li>
                        </div>
                        <ul type="none">
                          {jsonitem.options &&
                            jsonitem.options.map((item) => (
                              <div className="row ">
                                <div className="col-8 p-0">
                                  <li className="ml-4 d-flex">
                                    {" "}
                                    <span className="me-2">-</span>
                                    <p>{item.label}</p>
                                  </li>
                                </div>
                                <div className="col-4 text-center">
                                  {displayState ? (
                                    <p className="mb-2 text-muted">
                                      {values[jsonitem.fieldName] &&
                                      values[jsonitem.fieldName][item.fieldName]
                                        ? optionsData.find(
                                            (ele) =>
                                              ele?.value ==
                                              values[jsonitem.fieldName][
                                                item.fieldName
                                              ]
                                          )?.label
                                        : "Select"}
                                    </p>
                                  ) : (
                                    <Field
                                      name={`${jsonitem.fieldName}.${item.fieldName}`}
                                      component="select"
                                    >
                                      {({ input, meta }) => (
                                        <select
                                          {...input}
                                          style={{
                                            appearance: "none",
                                            outline: "none",
                                          }}
                                          className={
                                            meta.error && meta.touched
                                              ? "AlertDanger"
                                              : ""
                                          }
                                        >
                                          <option value=""> Select</option>
                                          {optionsData.map((option) => (
                                            <option
                                              key={`${item.fieldName}_${option.value}`}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          ))}
                                        </select>
                                      )}
                                    </Field>
                                  )}
                                </div>
                                <ul type="none">
                                  {item.suboptions &&
                                    item.suboptions.map((item) => (
                                      <div className="row">
                                        <div className="col-8 p-0">
                                          <li className="ml-5 d-flex">
                                            {" "}
                                            <span className="me-2">-</span>
                                            <p>{item.label}</p>
                                          </li>
                                        </div>
                                        <div className="col-4 text-center">
                                          {displayState ? (
                                            <p className="mb-2 text-muted">
                                              {values[jsonitem.fieldName] &&
                                              values[jsonitem.fieldName][
                                                item.fieldName
                                              ]
                                                ? optionsData.find(
                                                    (ele) =>
                                                      ele?.value ==
                                                      values[
                                                        jsonitem.fieldName
                                                      ][item.fieldName]
                                                  )?.label
                                                : "Select"}
                                            </p>
                                          ) : (
                                            <Field
                                              name={`${jsonitem.fieldName}.${item.fieldName}`}
                                              component="select"
                                            >
                                              {({ input, meta }) => (
                                                <select
                                                  {...input}
                                                  style={{
                                                    appearance: "none",
                                                    outline: "none",
                                                  }}
                                                  className={
                                                    meta.error && meta.touched
                                                      ? "AlertDanger"
                                                      : ""
                                                  }
                                                >
                                                  <option value="">
                                                    {" "}
                                                    Select
                                                  </option>
                                                  {optionsData.map((option) => (
                                                    <option
                                                      key={`${item.fieldName}_${option.value}`}
                                                      value={option.value}
                                                    >
                                                      {option.label}
                                                    </option>
                                                  ))}
                                                </select>
                                              )}
                                            </Field>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </ul>
                              </div>
                            ))}
                        </ul>
                      </div>
                    ))}
                </ul>
                <div className="col-md-12">
                  <p>
                    I acknowledge and agree that I have completed the above
                    Certificate of Competency and PT Skills Checklist as
                    accurately and honestly as possible. I certify that I have
                    made note of the items that I need to review more to improve
                    my competence, knowledge, and skills. I certify that I have
                    remediated these items to ensure my competence.
                  </p>
                </div>

                <div className="offset-3 col-md-6 ">
                  <div className="form-group">
                    <div className="d-flex align-items-cetner">
                      <label>Signature</label>
                      <button
                        className="Approve-btns w-100"
                        // type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsCanvasShow(true);
                        }}
                      >
                        {signatureAdded ? "Update Signature" : "Add Signature"}
                      </button>
                    </div>
                    {signatureAdded ? (
                      <img
                        src={signatureImage}
                        height={"100px"}
                        width={"500px"}
                        crossOrigin="anonymous"
                      />
                    ) : (
                      <input
                        type="text"
                        placeholder="Sign Here"
                        disabled
                        className=" pl-0 border-0 border-right-2 border-left-2 border-bottom-2"
                      />
                    )}
                  </div>

                  <div id="form-name-section">
                    <div className="form-group">
                      <label>Name</label>
                      <Field name="Name" component="input" placeholder="Name" />
                    </div>
                    <div className="form-group">
                      <label>Date Completed:</label>
                      <Field name="dateCompleted">
                        {({ input, meta }) => (
                          <div id="dateform">
                            <input
                              className="dateInput"
                              type="text"
                              {...input}
                              readOnly
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger mb-3 ">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div id="pdf-name-section" style={{ display: "none" }}>
                    <div className="form-group">
                      <label>Name </label>
                      <div className="FormGroupPrimeryInput">
                        {" "}
                        {values.Name ? values.Name : " "}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Date Completed:</label>
                      <div className="FormGroupPrimeryInput">
                        {" "}
                        {values.dateCompleted ? values.dateCompleted : " "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button className="Approve-btns my-3" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      />
      {isCanvasShow && (
        <SignatureCanvas
          loadDoc={saveForm}
          isShow={isCanvasShow}
          setSignatureAdded={setSignatureAdded}
          handleClose={() => setIsCanvasShow(false)}
        />
      )}
    </>
  );
};

export default PTSkills;
