import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../config/axiosInstance";
import { defaultStatus } from "../../utils/auth";

export const getAgencyPatientChart = createAsyncThunk(
  "getAgencyPatientChart",
  async (data) => {
    const data1 = await axiosInstance.post("/staffing/staffingby-status", data);
    return data1.data;
  }
);

export const getTherapistPatientChart = createAsyncThunk(
  "getTherapistPatientChart",
  async (data) => {
    const data1 = await axiosInstance.post(
      "staffing/get-therapist-staff",
      data
    );
    return data1.data;
  }
);

export const getAdminPatientChart = createAsyncThunk(
  "getAdminPatientChart",
  async (data) => {
    const data1 = await axiosInstance.post("staffing/staffingby-status", data);
    return data1.data;
  }
);

export const getTabActiveStatus = createAsyncThunk(
  "getTabActiveStatus/staffing/staffDetailStatus",
  async (data) => {
    const data1 = await axiosInstance.post(`staffing/staffDetailStatus`, data);
    return data1.data;
  }
);

export const updateStaffingSOC = createAsyncThunk(
  "updateStaffingSOC/staffing/staffingSocUpdation",
  async (data) => {
    const data1 = await axiosInstance.post(
      `staffing/staffingSocUpdation`,
      data
    );
    return data1.data;
  }
);
export const getEpisodeDetails = createAsyncThunk(
  "getEpisodeDetails/staffing/getStaffEpisodes",
  async (data) => {
    const data1 = await axiosInstance.post(`/staffing/getStaffEpisodes`, data);
    return data1.data;
  }
);

export const getStaffDocumentation = createAsyncThunk(
  "getStaffDocumentation/staffing/getStaffDocument",
  async (data) => {
    const data1 = await axiosInstance.post(`/staffing/getStaffDocument`, data);
    return data1.data;
  }
);

export const UpdateDocumentationDate = createAsyncThunk(
  "UpdateDocumentationDate/staffing/updateStaff-EpisodeDocument",
  async (data) => {
    const data1 = await axiosInstance.post(
      `/staffing/updateStaff-EpisodeDocument`,
      data
    );
    return data1.data;
  }
);

export const getStaffAssignments = createAsyncThunk(
  "getStaffAssignment/staffing/getStaffAssignments",
  async (data) => {
    const data1 = await axiosInstance.post(
      `/staffing/getStaffAssignments`,
      data
    );
    return data1.data;
  }
);
export const updateAuthorisation = createAsyncThunk(
  "updateAuthorisation/staffing/staff-Authorization",
  async (data) => {
    const data1 = await axiosInstance.post(
      `/staffing/staff-Authorization`,
      data
    );
    return data1.data;
  }
);
export const getStaffAuth = createAsyncThunk(
  "getStaffAuth/staffing/getStaffAuthorization",
  async (data) => {
    const data1 = await axiosInstance.post(
      `/staffing/getStaffAuthorization`,
      data
    );
    return data1.data;
  }
);
export const getStafffFrequency = createAsyncThunk(
  "getStafffFrequency/staffing/get-Frequency",
  async (data) => {
    const data1 = await axiosInstance.post(`/staffing/get-Frequency`, data);
    return data1.data;
  }
);
export const getStaffFormData = createAsyncThunk(
  "getPtEvaluation/staffing/getStaffFormData",
  async (data) => {
    const data1 = await axiosInstance.post(`/staffing/getStaffFormData`, data);
    return data1.data;
  }
);

export const updateFrequency = createAsyncThunk(
  "updateFrequency/staffing/staff-Frequency",
  async (data) => {
    const data1 = await axiosInstance.post(`/staffing/staff-Frequency`, data);
    return data1.data;
  }
);
export const getDocList = createAsyncThunk(
  "/staffing/staffDocby-status/Documnets",
  async (body) => {
    const data1 = await axiosInstance.post(`/staffing/staffDocby-status`, body);
    return data1.data;
  }
);
export const updateDocumenationStatus = createAsyncThunk(
  "updateDocumenationStatus/staffing/update-DocumentationStatus",
  async (data) => {
    const data1 = await axiosInstance.post(
      `/staffing/update-DocumentationStatus`,
      data
    );
    return data1.data;
  }
);

export const getCaseManagementStatus = createAsyncThunk(
  "/staffing/casemanagement/getCaseManagementStatus",
  async (body) => {
    if (defaultStatus.lastvisittypeapproval === body.tab) {
      const data1 = await axiosInstance.post(
        `/staffing/staffLast-visitData`,
        body
      );
      return { data: data1.data?.data?.rows };
    } else {
      const data1 = await axiosInstance.post(`/staffing/casemanagement`, body);
      return data1.data;
    }
  }
);
export const getCaseManagementTabCount = createAsyncThunk(
  "/staffing/getCaseManagementCount/getCaseManagementTabCount",
  async () => {
    const data1 = await axiosInstance.get(`/staffing/getCaseManagementCount`);
    return data1.data;
  }
);

export const getFrequencySuggestion = createAsyncThunk(
  "/staffing/Frequency/Suggestion",
  async (body) => {
    const data1 = await axiosInstance.post(
      `/staffing/evaluationFrequencies`,
      body
    );
    return data1.data;
  }
);

export const addNewEpisode = createAsyncThunk(
  "Episode/AddNewEpisode",
  async (data) => {
    const data1 = await axiosInstance.post(
      `/staffing/staffEpisodeCreate`,
      data
    );
    return data1.data;
  }
);

export const getHomeCountData = createAsyncThunk(
  "getHomeCountData/staffing/getStaffCountHome",
  async () => {
    const data1 = await axiosInstance.get(`/staffing/getStaffCountHome`);
    return data1.data;
  }
);

export const addAdditionalForms = createAsyncThunk(
  "AdditionalForms/Add",
  async (body) => {
    const data1 = await axiosInstance.post(
      `/staffing/additionalFormCreate`,
      body
    );
    return data1.data;
  }
);
export const LastVisitType = createAsyncThunk(
  "LastVisitType/Change",
  async (body) => {
    const data1 = await axiosInstance.post(
      `/staffing/assessmentVisitCreate`,
      body
    );
    return data1.data;
  }
);

export const getVisitsByWeeksLeft = createAsyncThunk(
  "LastVisitType/getVisitByLeftWeek",
  async (body) => {
    const data1 = await axiosInstance.post(`/staffing/getLeftWeekVisit`, body);
    return data1.data;
  }
);

export const addAgencySelectLastVisit = createAsyncThunk(
  "getUserDetailsById/staffing/addForm-Agency",
  async (payload) => {
    const data1 = await axiosInstance.post(`staffing/addForm-Agency`, payload);
    return data1.data;
  }
);

export const getSavedLastVisitData = createAsyncThunk(
  "staffing/getSavedLastVisitType",
  async (payload) => {
    const data1 = await axiosInstance.post(
      `staffing/getSavedLastVisitType`,
      payload
    );
    return data1.data;
  }
);

export const addTherapistSelectLastVisit = createAsyncThunk(
  "getUserDetailsById/staffing/lastAssessment-VisitDocumentCreate",
  async (payload) => {
    const data1 = await axiosInstance.post(
      `staffing/lastAssessment-VisitDocumentCreate`,
      payload
    );
    return data1.data;
  }
);

export const getLastGoalsValue = createAsyncThunk(
  "staffing/getLast-CompletedVisitFormData",
  async (payload) => {
    const data1 = await axiosInstance.post(
      `staffing/getLast-CompletedVisitFormData`,
      payload
    );
    return data1.data;
  }
);
