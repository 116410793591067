import React, { useState } from "react";
import { Field } from "react-final-form";
import SignatureCanvas from "../../components/common/SignatureCanvas";
import { toast } from "react-toastify";

const HhaAgreement = ({ errors, touched, handleSignatureValue }) => {
  const [signatureAdded, setSignatureAdded] = useState(false);
  const [signatureImage, setSignatureImage] = useState(null);
  const [isCanvasShow, setIsCanvasShow] = useState(false);

  const saveForm = (image) => {
    handleSignatureValue(true);
    setSignatureImage(image);
    toast.success("Signature added.");
  };

  return (
    <>
      <div
        className="container text-justify DefineWidthFOrChild"
        id="contentToSave"
      >
        <div className="headindSection">
          <div className="Connexion_docm text-center my-5">
            <h1>
              The Care Connexion, Inc.
              <h1>Home Health Agency Platform Access Agreement</h1>
            </h1>
          </div>
        </div>
        <div>
          <p>
            This Platform Access Agreement (this “PAA”) is by and among you, a
            Home Health Agency (“HHA” or “you”), and The Care Connexion, Inc., a
            Louisiana corporation (“TCC”). This PAA governs your access to our
            Platform (defined below) which facilitates your access to
            third-party licensed therapists (“Therapists”) to provide
            therapeutic at-home care service (collectively, “Therapeutic
            Service”). For the sake of clarity, references to “we,” “our,” and
            “us” also refers to “TCC”.{" "}
          </p>
        </div>
        <ol>
          <li className="list-unstyled d-flex">
            <span class="font-weight-bold therapy_services_list">1. </span>
            <strong>Relationship with TCC</strong>
          </li>
          <ol className="pl-4">
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list pr-3">
                1.1.{" "}
              </span>
              <li className="list-unstyled mb-3">
                {" "}
                TCC provides a digital marketplace platform that enables you to
                conveniently find, request, or receive Therapeutic Services from
                third-party Therapists that meet your needs and business
                interests. These Terms of Use (“Terms”) govern your access or
                use, from within the United States and its territories and
                possessions, of the TCC marketplace platform and any related
                content or services (collectively, the “Platform,” as more fully
                defined below in Section 3) made available in the United States
                and its territories and possessions by TCC and its
                representatives, affiliates, officers and directors (all
                collectively, “TCC”). PLEASE READ THESE TERMS CAREFULLY, AS THEY
                CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU AND TCC. In these
                Terms, the words “including” and "include” mean “including, but
                not limited to.”
              </li>
            </div>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list pr-3">
                1.2.{" "}
              </span>
              <li className="list-unstyled mb-3 accessing_txt">
                {" "}
                By accessing or using the Platform, you confirm your agreement
                to be bound by these Terms. If you do not agree to these Terms,
                you may not access or use the Platform. These Terms expressly
                supersede prior agreements or arrangements with you regarding
                the use of the Platform.
              </li>
            </div>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list pr-2">
                1.3.{" "}
              </span>
              <li className="list-unstyled mb-3">
                TCC may immediately terminate these Terms or any services with
                respect to you, or generally cease offering or deny access to
                the Platform or any portion thereof, at any time for any reason
              </li>
            </div>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list pr-4">
                1.4.{" "}
              </span>
              <li className="list-unstyled mb-3">
                {" "}
                IMPORTANT: PLEASE BE ADVISED THAT THIS AGREEMENT CONTAINS
                PROVISIONS THAT GOVERN HOW CLAIMS BETWEEN YOU AND TCC CAN BE
                BROUGHT, INCLUDING THE ARBITRATION AGREEMENT (SEE SECTION 2
                BELOW). PLEASE REVIEW THE ARBITRATION AGREEMENT BELOW CAREFULLY,
                AS IT REQUIRES YOU TO RESOLVE ALL DISPUTES WITH TCC ON AN
                INDIVIDUAL BASIS AND, WITH LIMITED EXCEPTIONS, THROUGH FINAL AND
                BINDING ARBITRATION (AS DESCRIBED IN SECTION 2 BELOW). BY
                ENTERING INTO THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU
                HAVE READ AND UNDERSTAND ALL OF THE TERMS OF THIS AGREEMENT AND
                HAVE TAKEN TIME TO CONSIDER THE CONSEQUENCES OF THIS IMPORTANT
                DECISION.
              </li>
            </div>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list pr-4">
                1.5.{" "}
              </span>
              <li className="list-unstyled mb-3">
                Supplemental terms may apply to certain options or offers
                available through the Platform, such as policies for payment,
                data storage, or HHA promotion. Such supplemental terms will be
                disclosed to you in connection with the applicable option or
                offer. Supplemental terms are in addition to, and shall be
                deemed a part of, the Terms for the purposes of the applicable
                option or offer. Supplemental terms shall prevail over these
                Terms in the event of a conflict with respect to the applicable
                option or offer.
              </li>
            </div>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list pr-4">
                1.6.{" "}
              </span>
              <li className="list-unstyled mb-3">
                TCC may make changes to these Terms from time to time. If TCC
                makes changes, it will provide you with notice of such changes,
                such as by sending an email, providing a notice through the
                Platform, or updating the date at the top of these Terms. Unless
                TCC says otherwise in its notice, the amended Terms will be
                effective immediately and your continued access to and use of
                the Platform after TCC provides such notice will confirm your
                acceptance of the changes. If you do not agree to the amended
                Terms, you must stop accessing and using the Platform.
              </li>
            </div>

            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list pr-3">
                1.7.{" "}
              </span>

              <li className="list-unstyled mb-3">
                TCC’s collection and use of personal information in connection
                with the Platform is described in TCC's Privacy Notice located
                at{" "}
                <a
                  href="https://www.thecareconnexion.com/privacy/notice"
                  className="text-primary"
                >
                  https://www.thecareconnexion.com/privacy/notice
                </a>
                .
              </li>
            </div>
          </ol>
        </ol>

        <ol>
          <li className="list-unstyled d-flex">
            <span class="font-weight-bold therapy_services_list">2. </span>
            <strong>The Platform</strong>
          </li>

          <ol>
            <p>
              TCC operates a personalized multipurpose digital marketplace
              platform that is accessed in a number of forms, including
              web-based applications (“Applications”). Among other things, the
              TCC Platform enables you to discover and receive: (i) services
              rendered by TCC that facilitate your requests to independent
              third-party Therapists, for the purchase of services, such as
              occupational therapy, speech therapy, and physical therapy
              services from those Therapists; (ii) related personalized content,
              including features, recommendations and advertisements for
              services; and (iii) any supporting services, including payment
              processing and customer support. The TCC Platform, personalized
              content and supporting services described in this Section are
              collectively referred to as “the Platform.” Unless otherwise
              agreed by TCC in a separate written agreement with you, the
              Platform is made available solely for your commercial use.
            </p>
            <p>
              YOU ACKNOWLEDGE THAT YOUR ABILITY TO REQUEST, AND IF APPLICABLE,
              OBTAIN THERAPEUTIC SERVICES FROM THIRD-PARTY THERAPISTS IN
              CONNECTION WITH THE USE OF THE TCC PLATFORM DOES NOT ESTABLISH TCC
              AS A PROVIDER OF THERAPEUTIC SERVICES.
            </p>
            <p>
              TCC IS NOT A THERAPIST, DOES NOT PROVIDE THERAPEUTIC SERVICES, AND
              USE OF THE TCC PLATFORM IS ONLY OPEN TO REGISTERED USERS OF THE
              TCC PLATFORM AND NOT TO THE GENERAL PUBLIC.
            </p>
            <p>
              YOU ACKNOWLEDGE THAT INDEPENDENT THIRD-PARTY THERAPISTS ARE NOT
              ACTUAL AGENTS, APPARENT AGENTS, OSTENSIBLE AGENTS, OR EMPLOYEES OF
              TCC IN ANY WAY.
            </p>
            <p>
              YOU ALSO ACKNOWLEDGE THAT ANY SAFETY RELATED EFFORT, FEATURE,
              PROCESS, POLICY, STANDARD OR OTHER EFFORT UNDERTAKEN BY TCC IN THE
              INTEREST OF SAFETY (WHETHER REQUIRED BY APPLICABLE REGULATIONS OR
              NOT) IS NOT AN INDICIA OF AN EMPLOYMENT, ACTUAL AGENCY, APPARENT
              AGENCY, OR OSTENSIBLE AGENCY RELATIONSHIP WITH AN INDEPENDENT
              THIRD-PARTY THERAPIST.
            </p>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list">2.1. </span>
              <li className="list-unstyled mb-3">
                {/* <strong>License.</strong> */}
                <p className="pl-1 mb-0"> License.</p>
              </li>
            </div>
            <div className="d-flex list_alpha_space">
              <span class="font-weight-bold therapy_services_list">a. </span>
              <div type="a" className="list-unstyled">
                <li className="list-unstyled">
                  Subject to your compliance with these Terms, TCC grants you a
                  limited, non-exclusive, non-sublicensable, revocable,
                  non-transferable license to: (i) access and use the
                  Applications solely in connection with your use of the
                  Platform; and (ii) access and use any content, information and
                  related materials that may be made available through the
                  Platform, in each case solely for your exclusive commercial
                  use. Any rights not expressly granted herein are reserved by
                  TCC and TCC’s licensors.
                </li>
              </div>
            </div>

            <div className="d-flex my-3">
              <span class="font-weight-bold therapy_services_list">2.2. </span>
              <p className="pl-1 mb-0"> Restrictions.</p>
            </div>

            <div className="d-flex list_alpha_space">
              <span class="font-weight-bold therapy_services_list">a. </span>

              <li className="list-unstyled">
                {/* <strong>Restrictions.</strong> */}

                <ol type="a" className="list-unstyled">
                  <li>
                    You may not: (i) remove any copyright, trademark or other
                    proprietary notices from any portion of the Platform; (ii)
                    reproduce, modify, prepare derivative works based upon,
                    distribute, license, lease, sell, resell, transfer, publicly
                    display, publicly perform, transmit, stream, broadcast or
                    otherwise exploit the Platform except as expressly permitted
                    by TCC; (iii) decompile, reverse engineer or disassemble the
                    Platform except as may be permitted by applicable law; (iv)
                    link to, mirror or frame any portion of the Platform; (v)
                    cause or launch any programs or scripts for the purpose of
                    unduly burdening or hindering the operation and/or
                    functionality of any aspect of the Platform; or (vi) attempt
                    to gain unauthorized access to or impair any aspect of the
                    Platform or its related systems or networks.
                  </li>
                </ol>
              </li>
            </div>

            <div className="d-flex my-3">
              <span class="font-weight-bold therapy_services_list">2.3. </span>
              <li className="list-unstyled pl-1">
                {/* <strong>Third-Party Services and Content.</strong> */}
                <p>Third-Party Services and Content.</p>
                <ol type="a" className="list-unstyled">
                  <div className="d-flex">
                    <span class="font-weight-bold therapy_services_list">
                      a.{" "}
                    </span>
                    <li className="list-unstyled">
                      The Platform may be made available or accessed in
                      connection with third-party services and content that TCC
                      does not control. Once you click on a link to third-party
                      services or content, you will be subject to the terms and
                      conditions and privacy policy of that website,
                      destination, or third-party service provider. TCC will not
                      warn you that you have left the Platform or that you are
                      subject to the terms and conditions (including privacy
                      policies) of another website, destination, or third-party
                      service provider. You use all links in third-party
                      websites and advertisements at your own risk as these are
                      not part of the Platform and are not controlled by TCC.
                      You acknowledge that different terms of use and privacy
                      policies may apply to your use of such third-party
                      services and content. TCC does not endorse such
                      third-party services and content and in no event shall TCC
                      be responsible or liable for any products or services of
                      such third-party providers.
                    </li>
                  </div>
                </ol>
              </li>
            </div>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list">2.4. </span>
              <p className="mb-0 pl-1">Ownership</p>
            </div>
            <li className="list-unstyled">
              {/* <strong>Ownership</strong> */}

              <ol type="a">
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li className="list-unstyled">
                    The Platform and all rights therein are and shall remain
                    TCC’s property or the property of TCC’s licensors. Neither
                    these Terms nor your use of the Platform convey or grant to
                    you any rights in or related to the Platform except for the
                    limited license granted above.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>
                  <li className="list-unstyled">
                    You agree that you will not use TCC’s trademarks, service
                    marks, or trade dress or any similar names, marks, or trade
                    dress (“TCC’s Marks”), aside from use incidental to your use
                    of the Platform, without express, written permission from
                    TCC. This prohibition on using TCC’s Marks includes, but is
                    not limited to, use in domain names, websites, and social
                    media accounts.
                  </li>
                </div>
              </ol>
            </li>
          </ol>
        </ol>

        <ol>
          <li className="list-unstyled d-flex">
            <span class="font-weight-bold therapy_services_list">3. </span>
            <strong>Insurance</strong>
          </li>

          <ol type="a" className="list-unstyled">
            <div className="d-flex list_alpha_space">
              <span class="font-weight-bold therapy_services_list">a. </span>
              <li className="list-unstyled">
                You shall maintain at all times sufficient professional
                liability and commercial general liability insurance coverage of
                an amount adequate to support its liabilities under this
                Agreement. Upon request, you shall provide TCC of evidence of
                such insurance.
              </li>
            </div>
          </ol>
        </ol>

        <ol>
          <div className="d-flex">
            <span class="font-weight-bold therapy_services_list">4. </span>
            <strong>Payment</strong>
          </div>

          <div className="d-flex list_alpha_space">
            <span class="font-weight-bold therapy_services_list">4.1 </span>
            <div>
              <strong>Third-Party Provider. </strong>
              <p className="d-inline">
                {" "}
                The payment functionality is facilitated by a third-party
                provider of payments services, Stripe. By using Stripe, you are
                subject to any additional terms and conditions for payment
                imposed by the third-party provider, which we recommend you
                review{" "}
                <a href="https://stripe.com/legal/ssa" className="text-primary">
                  (https://stripe.com/legal/ssa).
                </a>
                .
              </p>
            </div>
          </div>

          <div className="d-flex list_alpha_space">
            <span>1.1.&nbsp;</span>
            <div>
              <strong>Visit Fee and Payments. </strong>
              <p className="d-inline">
                {" "}
                Prior to assigning a Patient Referral, you are entitled to
                negotiate the visit rate that you wish to be pay for completing
                a Patient Visit (“Visit Rate”). You will be responsible for
                paying the Therapist the Visit Rate as agreed upon by both
                parties when assignment to Patient Referral as concluded. You
                will also pay the TCC Fee (defined below) for each visit that is
                completed. You: (i) appoint TCC as your limited payment
                collection agent solely for the purpose of paying the Visit
                Rate, and, depending on the Territory and/or if requested by
                you, applicable taxes and fees on your behalf via the payment
                processing functionality facilitated by the TCC Services; and
                (ii) agree that payment made by HHA to Therapist (or to an
                Affiliate of TCC acting as an agent of TCC) shall be considered
                the same as payment made directly by you to Therapist.
              </p>
            </div>
          </div>

          <div className="d-flex list_alpha_space">
            <span className="font-weight-bold therapy_services_list">
              4.2.&nbsp;
            </span>
            <div>
              <strong> TCC Fee. </strong> &nbsp;
              <p className="d-inline">
                In consideration of TCC’s provision of the Platform for your use
                and benefit hereunder, you agree to allow TCC to collect a
                service fee of $30 on a per visit basis calculated on a fixed
                fee model (“TCC Fee”). TCC reserves the right to change the TCC
                Fee at any time in TCC’s discretion.
              </p>
            </div>
          </div>

          <div className="d-flex list_alpha_space">
            <span>1.1 </span>
            <div>
              <strong>Visit Record Verification.</strong>&nbsp;
              <p className="d-inline">
                {" "}
                Upon completion of any visit, Therapist shall submit properly
                completed and error free visit records, in a form reasonably
                satisfactory to Medicare and industry standards, for your
                approval. This visit record will need to show a visit duration
                of at least 45 minutes, in addition to a patient signature via
                electronic signature or paper route sheet to verify the visit.
                Any visit completed that was not verified through the Platform
                will not be paid or payable by or through TCC.
              </p>
            </div>
          </div>

          <div className="d-flex list_alpha_space">
            <span className="font-weight-bold therapy_services_list">
              4.3.&nbsp;
            </span>
            <div>
              <strong> Methods of Payment. </strong> &nbsp;
              <p className="d-inline">
                TCC automatically facilitates payments from you to Therapists
                for Therapeutic Services provided via Automated Clearing House
                (“ACH”) deposit.
              </p>
            </div>
          </div>

          <ol>
            <ol type="a" className="list-unstyled mb-3">
              <div className="d-flex list_alpha_space">
                <span>a. </span>&nbsp;&nbsp;&nbsp;
                <li className="list-unstyled">
                  ACH Deposit. ACH deposit payments to Therapists are
                  distributed on the 10th and 26th of the month All visit
                  records submitted from 12:00am on the 1st of the month until
                  11:59pm on the 15th of the month will be paid out on the 26th
                  of the same month. All visit records completed from 12:00am on
                  the 16th of the month until 11:59pm on the 31st (or on the
                  last day of the month if less than 31 days) will be paid out
                  on the 10th of the next month.
                </li>
              </div>
            </ol>
          </ol>
          <div className="mb-3">
            <div className="pl-5 ml-3">
              <p className="d-inline">
                You understand that use of the Platform may result in charges to
                you for the Platform or based on Therapeutic Services you
                receive from Third-Party Therapists (“Charges”). You acknowledge
                that prices displayed to you when retaining Therapeutic Services
                are not inclusive of service fees paid to TCC. TCC will enable
                your payment of the applicable Charges for services obtained
                through your use of the Platform. Charges will include
                applicable taxes where required by law. Charges may include
                other applicable fees such as third-party service fees,
                cancellation fees, or other government-mandated fees. Further,
                you acknowledge and agree that Charges applicable in certain
                geographical areas may differ based on demand or due to other
                regulatory or marketplace factors.
              </p>
            </div>
          </div>

          <div className="mb-3">
            <div className="pl-5 ml-3">
              <p className="d-inline">
                All Charges and payments will be enabled by Stripe using the
                preferred payment method designated in your Stripe Account,
                after which you will receive a receipt. If your primary Account
                payment method is determined to be expired, invalid or otherwise
                not able to be charged, you agree that Stripe may use a
                secondary payment method in your Account, if available. Charges
                paid by you are final and non-refundable, unless otherwise
                determined by TCC.
              </p>
            </div>
          </div>

          <div className="mb-3">
            <div className="pl-5 ml-3">
              <p className="d-inline">
                As between you and TCC, TCC reserves the right to establish or
                adjust Charges for any or all services obtained through the use
                of the Platform at any time. You may elect to cancel your
                request for Therapeutic Service at any time prior to the
                commencement of Therapeutic Services, in which case you may be
                charged a cancellation fee on a Therapist’s behalf.
              </p>
            </div>
          </div>

          <div className="mb-3">
            <div className="pl-5 ml-3">
              <p className="d-inline">
                With respect to Therapists, Charges you incur will be owed
                directly to Therapists, and TCC will collect payment of those
                charges from you, on the Therapist’s behalf as their limited
                payment collection agent, and payment of the Charges shall be
                considered the same as payment made directly by you to the
                Therapist. Charges you incur will be owed to the Therapist.
                There also may be certain Charges you incur that will be owed
                and paid directly to TCC or its affiliates. For the avoidance of
                doubt, TCC does not charge a fee for a Therapist to access the
                Platform, but may charge HHAs a fee or any other Charge for
                accessing Therapeutic Services made available through the
                Platform.
              </p>
            </div>
          </div>

          <div className="mb-3">
            <div className="pl-5 ml-3">
              <p className="d-inline">
                If you think a correction should be made to any Charge you
                incurred, you must let TCC know in writing within 30 days after
                the Charge took place or TCC will have no further responsibility
                and you waive your right to later dispute the amounts charged.
              </p>
            </div>
          </div>
        </ol>

        <ol>
          <li className="list-unstyled list_alpha_space d-flex">
            <span class="font-weight-bold therapy_services_list">5. </span>
            <strong>Access and Use of the Platform</strong>{" "}
          </li>
          <ol>
            <li className="list-unstyled">
              {/* <strong>User Accounts.</strong> */}
              <li className="list-unstyled d-flex">
                <span class="font-weight-bold therapy_services_list">
                  5.1.{" "}
                </span>
                <p className="pl-1"> User Accounts.</p>
              </li>

              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    In order to use most aspects of the Platform, you must
                    register for and maintain an active HHA user Platform
                    account (“Account”). You must be at least 18 years of age
                    and the licensed representative of the HHA, to obtain an
                    Account. You cannot register for or maintain an Account if
                    you have previously been banned from accessing or using the
                    Platform. Account registration requires you to submit to TCC
                    certain personal information, such as your name, address,
                    mobile phone number and age, as well as at least one valid
                    payment method supported by [Payment Vendor]. For more
                    information regarding TCC’s use of your personal
                    information, please see our Privacy Notice currently
                    available at{" "}
                    <a
                      href="https://thecareconnexion.com/privacypolicy"
                      className="text-primary"
                    >
                      https://thecareconnexion.com/privacypolicy.
                    </a>{" "}
                    You agree to maintain accurate, complete, and up-to-date
                    information in your Account, including a valid phone number,
                    address and payment method. Your failure to comply with
                    these Terms (including policies and supplemental terms)
                    including, without limitation, your failure to maintain
                    accurate, complete, and up-to-date Account information,
                    including having an invalid or expired payment method on
                    file, may result in your inability to access or use the
                    Platform. You are responsible for all activity that occurs
                    under your Account, and you agree to maintain the security
                    and secrecy of your Account username and password at all
                    times. Unless otherwise permitted by TCC in writing, you may
                    only possess one Account.
                  </li>
                </div>
              </ol>
            </li>
            <li className="list-unstyled">
              {/* <strong>User Requirements and Conduct.</strong> */}
              <li className="list-unstyled d-flex mt-3 ">
                <span class="font-weight-bold therapy_services_list ">
                  5.2.{" "}
                </span>
                <p className="pl-1">User Requirements and Conduct.</p>
              </li>
              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    You agree to abide by the TCC guidelines. Failure to comply
                    with the TCC guidelines or any violation of these terms may
                    result in the permanent loss of access to the Platform.
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>
                  <li>
                    The Platform is not available for use by persons under the
                    age of 18. You may not authorize third-parties to use your
                    Account. You may not assign or otherwise transfer your
                    Account to any other HHA, person, or entity. You agree to
                    comply with all applicable laws when accessing or using the
                    Platform, and you may only access or use the Platform for
                    lawful purposes
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    c.{" "}
                  </span>
                  <li className="">
                    For the purpose of assisting us with our compliance and
                    insurance obligations, you agree to notify us within 24
                    hours and provide us with all reasonable information
                    relating to any incident or accident that occurs during your
                    use of the Platform or Therapeutic Services and you agree to
                    cooperate with any investigation and attempted resolution of
                    such incident
                  </li>
                </div>
              </ol>
            </li>

            <li className="list-unstyled">
              {/* <strong>Text Messaging and Telephone Calls.</strong> */}
              <li className="list-unstyled d-flex mt-3">
                <span class="font-weight-bold therapy_services_list">
                  5.3.{" "}
                </span>
                <p className="pl-1">Text Messaging and Telephone Calls.</p>
              </li>

              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    You agree that TCC and its subsidiaries, representatives,
                    affiliates, officers and directors, may contact you by
                    telephone or text messages (including by an automatic
                    telephone dialling system and/or with an artificial or
                    pre-recorded voice) at any of the phone numbers provided by
                    you or on your behalf in connection with an TCC account,
                    including for marketing purposes. You understand that you
                    are not required to provide this consent as a condition of
                    accessing or using the Platform. If you do not choose to opt
                    out, TCC may contact you as outlined in its User Privacy
                    Notice, located at{" "}
                    <a
                      href="https://www.thecareconnexion.com/privacy/notice"
                      className="text-primary"
                    >
                      https://www.thecareconnexion.com/privacy/notice
                    </a>
                  </li>
                </div>

                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>
                  <li>
                    You agree that TCC may contact you using any of the phone
                    numbers you provided in connection with an TCC account
                    (including via text or voice-recorded message) or your email
                    address in the case of suspected fraud or unlawful activity.
                  </li>
                </div>
              </ol>
            </li>

            <li className="list-unstyled">
              {/* <strong>Text Messaging and Telephone Calls.</strong> */}
              <li className="list-unstyled d-flex mt-3">
                <span class="font-weight-bold therapy_services_list">
                  5.4.{" "}
                </span>
                <p className="pl-1">Referral Content.</p>
              </li>

              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    TCC may, in TCC’s sole discretion, permit you from time to
                    time to submit, upload, publish or otherwise make available
                    to TCC through the Platform textual, audio, and/or visual
                    content and information, and submission of entries for
                    requests for the provision of Therapeutic Services from
                    Therapists (“Referral”). Any Referral provided by you
                    remains your property. However, by providing Referrals to
                    TCC, you grant TCC a worldwide, perpetual, irrevocable,
                    transferable, royalty-free license, with the right to
                    sublicense, to use, copy, modify, create derivative works
                    of, distribute, publicly display, and otherwise exploit in
                    any manner such Referral in all formats and distribution
                    channels now known or hereafter devised (including in
                    connection with the Platform and TCC’s business), without
                    further notice to or consent from you, and without the
                    requirement of payment to you or any other person or entity.
                  </li>
                </div>

                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>
                  <li>
                    You represent and warrant that: (i) you either are the sole
                    and exclusive owner of all Referrals or you have all rights,
                    licenses, consents and releases necessary to grant TCC the
                    license to the Referral as set forth above; and (ii) neither
                    the Referral, nor your submission, uploading, publishing or
                    otherwise making available of such Referral, nor TCC’s use
                    of the Referral as permitted herein will infringe,
                    misappropriate or violate a third-party's intellectual
                    property or proprietary rights, or rights of publicity or
                    privacy, or result in the violation of any applicable law or
                    regulation.
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    c.{" "}
                  </span>
                  <li>
                    You agree to not provide a Referral that is defamatory,
                    libellous, hateful, violent, obscene, pornographic,
                    unlawful, or otherwise offensive, as determined by TCC in
                    its sole discretion, whether or not such material may be
                    protected by law. TCC may, but shall not be obligated to,
                    review, monitor, and remove a Referral, at TCC's sole
                    discretion and at any time and for any reason, without
                    notice to you.
                  </li>
                </div>
              </ol>
            </li>
            <li className="list-unstyled">
              {/* <strong>Text Messaging and Telephone Calls.</strong> */}
              <li className="list-unstyled d-flex mt-3">
                <span class="font-weight-bold therapy_services_list">
                  5.5.{" "}
                </span>
                <p className="pl-1">User Feedback.</p>
              </li>

              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    As TCC respects your rights to your ideas, you agree that
                    you will not submit any confidential ideas, information, or
                    suggestions in any form to TCC or any of its affiliates. For
                    any ideas, information, or suggestions you do submit,
                    regardless of what your communication regarding your
                    submissions says, you understand that your submissions are
                    voluntary and the following terms shall apply to your
                    submissions: (i) your submissions and their contents will
                    automatically become the property of TCC, without any
                    compensation to you; (ii) TCC has no obligation to review
                    your submissions; (iii) TCC may implement and distribute any
                    portion of your submissions and their contents for any
                    purpose in any way, without any compensation to you; and
                    (iv) TCC has no obligation to keep your submissions
                    confidential.
                  </li>
                </div>
              </ol>
            </li>
            <li className="list-unstyled">
              {/* <strong>Text Messaging and Telephone Calls.</strong> */}
              <li className="list-unstyled d-flex mt-3">
                <span class="font-weight-bold therapy_services_list">
                  5.6.{" "}
                </span>
                <p className="pl-1">Network Access and Devices.</p>
              </li>

              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    You are responsible for obtaining the data network access
                    necessary to use the Platform. Your mobile network's data
                    and messaging rates and fees may apply if you access or use
                    the Platform from your device. You are responsible for
                    acquiring and updating compatible hardware or devices
                    necessary to access and use the Platform and Applications
                    and any updates thereto. TCC does not guarantee that the
                    Platform, or any portion thereof, will function on any
                    particular hardware or devices. In addition, the Platform
                    may be subject to malfunctions and delays inherent in the
                    use of the Internet and electronic communications.
                  </li>
                </div>
              </ol>
            </li>
          </ol>
        </ol>

        <ol>
          <li className="list-unstyled list_alpha_space">
            <span class="font-weight-bold therapy_services_list">6. </span>
            <strong>Disclaimers; Limitation of Liability; Indemnity.</strong>
          </li>
          <ol>
            <li className="list-unstyled">
              {/* <strong>User Accounts.</strong> */}
              <li className="list-unstyled d-flex mt-2">
                <span class="font-weight-bold therapy_services_list">
                  6.1.{" "}
                </span>
                <p className="pl-1"> Disclaimer.</p>
              </li>

              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    THE PLATFORM IS PROVIDED “AS IS” AND “AS AVAILABLE.” TCC
                    DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS,
                    IMPLIED, OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS,
                    INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                    FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION,
                    TCC MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE
                    REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY,
                    OR AVAILABILITY OF THE PLATFORM OR ANY SERVICES REQUESTED
                    THROUGH THE USE OF THE PLATFORM, OR THAT THE PLATFORM WILL
                    BE UNINTERRUPTED OR ERROR-FREE
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>
                  <li>
                    TCC DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR
                    ABILITY OF THERAPISTS. YOU AGREE THAT THE ENTIRE RISK
                    ARISING OUT OF YOUR USE OF THE PLATFORM, AND ANY THERAPEUTIC
                    SERVICE REQUESTED OR OBTAINED FROM THERAPISTS IN CONNECTION
                    THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT
                    PERMITTED UNDER APPLICABLE LAW.
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    c.{" "}
                  </span>
                  <li>
                    TCC DOES NOT CONTROL, MANAGE OR DIRECT ANY THERAPISTS.
                    THIRD-PARTY PROVIDERS AND THERAPISTS ARE NOT ACTUAL AGENTS,
                    APPARENT AGENTS, OSTENSIBLE AGENTS, OR EMPLOYEES OF TCC.
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    d.{" "}
                  </span>
                  <li>
                    TCC DOES NOT CONTROL, ENDORSE OR TAKE RESPONSIBILITY FOR ANY
                    REFERRALS OR THIRD-PARTY CONTENT AVAILABLE ON OR LINKED TO
                    BY THE SERVICES. TCC CANNOT AND DOES NOT REPRESENT OR
                    WARRANT THAT THE SERVICES OR SERVERS ARE FREE OF VIRUSES OR
                    OTHER HARMFUL COMPONENTS.
                  </li>
                </div>
              </ol>
            </li>
            <li className="list-unstyled">
              {/* <strong>User Requirements and Conduct.</strong> */}
              <li className="list-unstyled d-flex mt-3 ">
                <span class="font-weight-bold therapy_services_list ">
                  6.2.{" "}
                </span>
                <p className="pl-1">Limitation of Liability.</p>
              </li>
              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    TCC SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL,
                    EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING
                    LOST PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE
                    RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM
                    ANY USE OF THE SERVICES, REGARDLESS OF THE NEGLIGENCE
                    (EITHER ACTIVE, AFFIRMATIVE, SOLE, OR CONCURRENT) OF TCC,
                    EVEN IF TCC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGES.
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>
                  <li>
                    TCC SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES
                    ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES
                    OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY
                    TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD-PARTY
                    THERAPIST OR PROVIDER, EVEN IF TCC HAS BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES. TCC SHALL NOT BE LIABLE FOR
                    DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND
                    TCC'S REASONABLE CONTROL. YOU ACKNOWLEDGE THAT THIRD-PARTY
                    THERAPISTS OR PROVIDERS ARE NOT OSTENSIBLE AGENTS, APPARENT
                    AGENTS, ACTUAL AGENTS, OR EMPLOYEES OF TCC.
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    c.{" "}
                  </span>
                  <li>
                    THE SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE
                    THERAPEUTIC SERVICES WITH THIRD-PARTY THERAPISTS OR
                    PROVIDERS, BUT YOU AGREE THAT TCC HAS NO RESPONSIBILITY OR
                    LIABILITY TO YOU RELATED TO ANY THERAPEUTIC SERVICES
                    PROVIDED TO OR NOT PROVIDED TO YOU BY THIRD-PARTY THERAPIST
                    OR PROVIDERS OTHER THAN AS EXPRESSLY SET FORTH IN THESE
                    TERMS.
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    d.{" "}
                  </span>
                  <li>
                    TCC SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES
                    ARISING OUT OF LACK OF OR IMPROPER INFECTION AND DISEASE
                    CONTROL PROCEDURES ON THERAPEUTIC SERVICES REQUESTED THROUGH
                    THE SERVICES.
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    e.{" "}
                  </span>
                  <li>
                    THE LIMITATIONS AND DISCLAIMERS IN THIS SECTION DO NOT
                    PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS THAT CANNOT
                    BE EXCLUDED UNDER APPLICABLE LAW. BECAUSE SOME STATES OR
                    JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR THE
                    LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
                    DAMAGES, IN SUCH STATES OR JURISDICTIONS, TCC’S LIABILITY
                    SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW. THIS
                    PROVISION SHALL HAVE NO EFFECT ON TCC’S CHOICE OF LAW
                    PROVISION SET FORTH BELOW.
                  </li>
                </div>
              </ol>
            </li>

            <li className="list-unstyled">
              {/* <strong>Text Messaging and Telephone Calls.</strong> */}
              <li className="list-unstyled d-flex mt-3">
                <span class="font-weight-bold therapy_services_list">
                  6.3.{" "}
                </span>
                <p className="pl-1">Indemnity.</p>
              </li>

              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    You agree to indemnify and hold TCC and its affiliates and
                    their officers, directors, employees, and agents harmless
                    from and against any and all actions, claims, demands,
                    losses, liabilities, costs, damages, and expenses (including
                    attorneys’ fees), arising out of or in connection with: (i)
                    your use of the Services or Therapeutic Services obtained
                    through your use of the Services; (ii) your breach or
                    violation of any of these Terms; or (iii) your violation of
                    the rights of any third party, including Third-Party
                    Therapists or Providers.
                  </li>
                </div>
              </ol>
            </li>
          </ol>
        </ol>

        <ol>
          <li className="list-unstyled d-flex">
            <span class="font-weight-bold therapy_services_list">7. </span>
            <strong>Information</strong>
          </li>

          <ol>
            <p>
              We may collect and disclose information from or about you when you
              create an account, interact with our Platform or request
              Therapeutic Services and as otherwise described in our Privacy
              Notice. Notwithstanding anything herein to the contrary (a) the
              collection, use, and disclosure of such information will be made
              in accordance with our Privacy Notice and (b) if you elect to
              provide or make available suggestions, comments, ideas,
              improvements, or other feedback or materials to us in connection
              with, or related to, us or our Platform, we will be free to use,
              disclose, reproduce, modify, license, transfer and otherwise
              distribute, and exploit any of the foregoing information or
              materials in any manner.
            </p>
          </ol>
        </ol>

        <ol>
          <div className="d-flex">
            <span class="font-weight-bold therapy_services_list">8. </span>
            <strong>Confidentiality.</strong>
          </div>

          <div className="d-flex list_alpha_space">
            <span class="font-weight-bold therapy_services_list">8.1. </span>
            <div>
              <strong> Confidential Information.</strong>
              <p className="d-inline">
                {" "}
                Each party acknowledges and agrees that in the performance of
                this Agreement it may have access to or may be exposed to,
                directly or indirectly, confidential information of the other
                party or third parties (“Confidential Information”).
                Confidential Information includes HHA User Information and the
                business volume, marketing and business plans, business,
                financial, technical, operational and such other, non-public
                information of each party (whether disclosed in writing or
                verbally) that such party designates as being proprietary or
                confidential or of which the other party should reasonably know
                that it should be treated as confidential. Confidential
                Information does not include any information that: (a) was in
                the receiving party’s lawful possession prior to the disclosure,
                as clearly and convincingly corroborated by written records, and
                had not been obtained by the receiving party either directly or
                indirectly from the disclosing party; (b) is lawfully disclosed
                to the receiving party by a third party without actual, implied
                or intended restriction on disclosure through the chain of
                possession, or (c) is independently developed by the receiving
                party without the use of or access to the Confidential
                Information, as clearly and convincingly corroborated by written
                records.
              </p>
            </div>
          </div>

          <div className="d-flex list_alpha_space">
            <span class="font-weight-bold therapy_services_list">8.2. </span>
            <div>
              <strong> Obligations.</strong>
              <p className="d-inline">
                {" "}
                Each party acknowledges and agrees that: (a) all Confidential
                Information shall remain the exclusive property of the
                disclosing party; (b) it shall not use Confidential Information
                of the other party for any purpose except in furtherance of this
                Agreement; (c) it shall not disclose Confidential Information of
                the other party to any third- party, except to its employees,
                officers, contractors, agents and service providers ("Permitted
                Persons") as necessary to perform their obligations under this
                Agreement, provided Permitted Persons are bound in writing to
                obligations of confidentiality and non-use of Confidential
                Information no less protective than the terms hereof; and (d) it
                shall return or destroy all Confidential Information of the
                disclosing party, upon the termination of this Agreement or at
                the request of the other party; subject to applicable law and
                our internal record-keeping requirements.
              </p>
            </div>
          </div>

          <div className="d-flex list_alpha_space">
            <span class="font-weight-bold therapy_services_list">8.3. </span>
            <div>
              <strong> Remedies.</strong>
              <p className="d-inline">
                {" "}
                The unauthorized use or disclosure of any Confidential
                Information would cause irreparable harm and significant
                damages, the degree of which may be difficult to ascertain.
                Accordingly, the parties have the right to obtain immediate
                equitable relief to enjoin any unauthorized use or disclosure of
                Confidential Information disclosed by the other party, in
                addition to any other rights or remedies described in Section
                12, applicable law or otherwise.
              </p>
            </div>
          </div>
        </ol>

        <ol>
          <li className="list-unstyled">
            <span class="font-weight-bold therapy_services_list">9. </span>
            <strong>Intellectual Property</strong>
          </li>

          <ol>
            <p>
              We reserve all rights not expressly granted in this Agreement, our
              Platform, and all data gathered through our Platform, including
              all intellectual property rights therein (the “Platform IP”), are
              and remain our property and/or that of our licensors, if any, as
              applicable. Neither this Agreement nor your use of TCC’s or our
              licensors’ company names, logos, products or service names,
              trademarks, service marks, trade dress, other indicia of
              ownership, or copyrights (“TCC Names, Marks, or Works”) or the
              Platform IP conveys or grants to you any rights in or related to
              the Platform IP, or related intellectual property rights,
              including TCC’s Names, Marks, or Works, except for the limited
              license granted above. You shall not, and shall not allow any
              other party to: (a) license, sublicense, copy, modify, distribute,
              create, sell, resell, transfer, or lease any part of the Platform
              IP or Authorized TCC-Branded Materials; (b) reverse engineer or
              attempt to extract the source code of our software, except as
              allowed under law; (c) use, display, or manipulate any of TCC
              Names, Marks, or Works for any purpose other than to provide
              Therapeutic Services; (d) create or register any (i) businesses,
              (ii) URLs, (iii) domain names, (iv) software application names or
              titles, or (v) social media handles or profiles that include TCC
              Names, Marks, or Works or any confusingly or substantially similar
              mark, name, title, or work; (e) use TCC Names, Marks, or Works as
              your social media profile picture or wallpaper; (f) purchase
              keywords (including, but not limited to Google AdWords) that
              contain any TCC Names, Marks, or Works; (g) apply to register,
              reference, use, copy, and/or claim ownership in TCC’s Names,
              Marks, or Works, or in any confusingly or substantially similar
              name, mark, title, or work, in any manner for any purposes, alone
              or in combination with other letters, punctuation, words, symbols,
              designs, and/or any creative works, except as may be permitted in
              the limited license granted above; (h) cause or launch any
              programs or scripts for the purpose of scraping, indexing,
              surveying, or otherwise data mining any part of our Platform or
              data; or (i) aggregate TCC’s data with competitors’..
            </p>
          </ol>
        </ol>
        <ol>
          <li className="list-unstyled d-flex">
            <span class="font-weight-bold therapy_services_list">10. </span>
            <strong>Third-Party Services</strong>
          </li>

          <ol>
            <p>
              From time to time we may permit third parties to offer their
              services to users of our Platform. Third-party services may be
              subject to additional terms (including pricing) that apply between
              you and the party (ies) providing such services. If you choose to
              access the third-party services you understand that the providers
              of the third-party services are solely responsible for liabilities
              arising in connection with the access and use of such third-party
              services. While we may allow users to access such services through
              our Platform and we may collect information about our users’ use
              of such services, we may not investigate, monitor or check such
              third-party services for accuracy or completeness.
            </p>
          </ol>
        </ol>

        <ol>
          <li className="list-unstyled list_alpha_space d-flex">
            <span class="font-weight-bold therapy_services_list">11. </span>
            <strong>Miscellaneous</strong>
          </li>
          <ol>
            <li className="list-unstyled">
              <li className="list-unstyled d-flex">
                <span class="font-weight-bold therapy_services_list">
                  11.1.{" "}
                </span>
                <p className="pl-1"> Choice of Law.</p>
              </li>

              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    These Terms shall be governed by and construed in accordance
                    with the laws of the state in which your dispute arises,
                    without regard to the choice or conflict of law principles
                    of any jurisdiction, except as may be otherwise provided in
                    the Arbitration Agreement in Section 12 above or in
                    supplemental terms applicable to your region. This Choice of
                    Law provision applies only to the interpretation of these
                    Terms, and these provisions shall not be interpreted as
                    generally extending any state’s law to you if your dispute
                    did not arise in that state.
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>
                  <li>
                    Any dispute, claim, or controversy arising out of or
                    relating to incidents or accidents resulting in personal
                    injury (including but not limited to sexual assault or
                    harassment claims) that you allege occurred in connection
                    with your use of the Services, whether before or after the
                    date you agreed to the Terms, shall be governed by and
                    construed in accordance with the laws of the state in which
                    the incident or accident occurred.
                  </li>
                </div>
              </ol>
            </li>
            <li className="list-unstyled">
              <li className="list-unstyled d-flex mt-3 ">
                <span class="font-weight-bold therapy_services_list ">
                  11.2.{" "}
                </span>
                <p className="pl-1">Choice of Forum.</p>
              </li>
              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    Any dispute, claim or controversy arising out of or relating
                    to these Terms or the existence, breach, termination,
                    enforcement, interpretation or validity thereof, shall be
                    brought exclusively in the state and federal courts of the
                    state in which the dispute, claim or controversy arose,
                    notwithstanding that other courts may have jurisdiction over
                    the parties and subject matter, except as may be otherwise
                    provided by the Arbitration Agreement above or in
                    supplemental terms applicable to your region.
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>
                  <li>
                    Notwithstanding the foregoing, any dispute, claim, or
                    controversy arising out of or relating to incidents or
                    accidents resulting in personal injury (including but not
                    limited to sexual assault or harassment claims) that you
                    allege occurred in connection with your use of the Services,
                    whether before or after the date you agreed to the Terms,
                    shall be brought exclusively in the state or federal courts
                    in the State in which the incident or accident occurred,
                    notwithstanding that other courts may have jurisdiction over
                    the parties and subject matter, and except as may be
                    otherwise provided in the Arbitration Agreement above or in
                    supplemental terms applicable to your region, to the extent
                    permitted by law.
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    c.{" "}
                  </span>
                  <li>
                    The foregoing Choice of Law and Choice of Forum provisions
                    do not apply to the Arbitration Agreement in Section 12, and
                    we refer you to Section 12 for the applicable provisions for
                    such disputes.
                  </li>
                </div>
              </ol>
            </li>

            <li className="list-unstyled">
              <li className="list-unstyled d-flex mt-3">
                <span class="font-weight-bold therapy_services_list">
                  11.3.{" "}
                </span>
                <p className="pl-1">Notice.</p>
              </li>

              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    TCC may give notice by means of a general notice on or
                    through the Services, electronic mail to the email address
                    associated with your Account, telephone or text message to
                    any phone number provided in connection with your account,
                    or by written communication sent by first class mail or
                    pre-paid post to any address connected with your Account.
                    Such notice shall be deemed to have been given upon the
                    expiration of 48 hours after mailing or posting (if sent by
                    first class mail or pre-paid post) or at the time of sending
                    (if sent by email, telephone, or on or through the
                    Services). You may give notice to TCC, with such notice
                    deemed given when received by TCC, at any time by first
                    class mail or pre-paid post to our registered agent for
                    service of process, c/o The Care Connexion, Inc. If another
                    provision of these Terms addresses any specific notice (for
                    example, notice of updates to these Terms, or notice of a
                    dispute or arbitration demand), those specific notice
                    provisions shall prevail to the extent there is any conflict
                    or inconsistency between those provisions and this notice
                    provision.
                  </li>
                </div>
              </ol>
            </li>

            <li className="list-unstyled">
              <li className="list-unstyled d-flex mt-3">
                <span class="font-weight-bold therapy_services_list">
                  11.4.{" "}
                </span>
                <p className="pl-1">General.</p>
              </li>

              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    You may not assign these Terms without TCC’s prior written
                    approval. TCC may assign these Terms without your consent
                    to: (i) a subsidiary or affiliate; (ii) an acquirer of TCC's
                    equity, business or assets; or (iii) a successor by merger.
                    Any purported assignment by you in violation of this section
                    shall be void. No joint venture, partnership, employment, or
                    agency relationship exists between you, TCC or any
                    Third-Party Provider as a result of this Agreement or use of
                    the Services. If any provision of these Terms is held to be
                    invalid or unenforceable, such provision shall be struck and
                    the remaining provisions shall be enforced to the fullest
                    extent under law. TCC's failure to enforce any right or
                    provision in these Terms shall not constitute a waiver of
                    such right or provision unless acknowledged and agreed to by
                    TCC in writing. This provision shall not affect the
                    Severability and Survivability section of the Arbitration
                    Agreement of these Terms.
                  </li>
                </div>
              </ol>
            </li>
          </ol>
        </ol>

        <ol>
          <li className="list-unstyled d-flex">
            <span class="font-weight-bold therapy_services_list">12. </span>
            <strong>Arbitration of Disputes.</strong>
          </li>
          <div className="d-flex list_alpha_space">
            <span class="font-weight-bold therapy_services_list">12.1. </span>
            <p className="pl-3">
              AGENCY RESPECTIVELY AGREES TO WAIVE THE RIGHT TO SEEK REMEDIES IN
              COURT, INCLUDING ANY RIGHT TO A JURY TRIAL. AGENCYS AGREE THAT ANY
              DISPUTE BETWEEN OR AMONG THEM OR THEIR SUBSIDIARIES, AFFILIATES OR
              RELATED ENTITIES ARISING OUT OF, RELATING TO OR IN CONNECTION WITH
              THIS AGREEMENT, WILL BE RESOLVED IN ACCORDANCE WITH A TWO-STEP
              DISPUTE RESOLUTION PROCEDURE INVOLVING: (1) STEP ONE: NON-BINDING
              MEDIATION, AND (2) STEP TWO: BINDING ARBITRATION UNDER THE FEDERAL
              ARBITRATION ACT, 9 U.S.C. SECTION 1 ET. SEQ., OR STATE LAW,
              WHICHEVER IS APPLICABLE. ANY SUCH MEDIATION OR ARBITRATION
              HEREUNDER SHALL BE CONDUCTED IN ANY FORUM AND FORM AGREED UPON BY
              THE PARTIES OR, IN THE ABSENCE OF SUCH AN AGREEMENT, UNDER THE
              AUSPICES OF THE JAMS (F/K/A THE JUDICIAL ARBITRATION AND MEDIATION
              SERVICE) (“JAMS”) PURSUANT TO THE THEN CURRENT COMPREHENSIVE
              ARBITRATION RULES & PROCEDURES (A COPY OF WHICH IS AVAILABLE
              THROUGH JAMS’ WEBSITE, WWW.JAMSADR.ORG) (THE “JAMS RULES”). AGENCY
              MAY ALSO CALL JAMS AT 1-800-352-5267 IF THERE ARE ANY QUESTIONS
              CONCERNING THE ARBITRATION PROCESS. NOTWITHSTANDING ANYTHING TO
              THE CONTRARY IN THE JAMS RULES, THE MEDIATION PROCESS (STEP ONE)
              MAY BE ENDED BY EITHER PARTY TO THE DISPUTE UPON NOTICE TO THE
              OTHER PARTY THAT IT DESIRES TO TERMINATE THE MEDIATION AND PROCEED
              TO THE STEP TWO ARBITRATION; PROVIDED, HOWEVER, THAT NEITHER PARTY
              MAY SO TERMINATE THE MEDIATION PROCESS PRIOR TO THE OCCURRENCE OF
              AT LEAST ONE (1) MEDIATION SESSION WITH THE MEDIATOR. NO
              ARBITRATION SHALL BE INITIATED OR TAKE PLACE WITH RESPECT TO A
              GIVEN DISPUTE IF THE PARTIES HAVE SUCCESSFULLY ACHIEVED A MUTUALLY
              AGREED TO RESOLUTION OF THE DISPUTE AS A RESULT OF THE STEP ONE
              MEDIATION. THE MEDIATION SESSION(S) AND, IF NECESSARY, THE
              ARBITRATION HEARING SHALL BE HELD IN THE STATE OF CALIFORNIA OR
              ANY OTHER LOCATION MUTUALLY AGREED BY THE PARTIES. THE ARBITRATION
              (IF THE DISPUTE IS NOT RESOLVED BY MEDIATION) WILL BE CONDUCTED BY
              A SINGLE JAMS ARBITRATOR, MUTUALLY SELECTED BY THE PARTIES, AS
              PROVIDED FOR BY THE JAMS RULES. IF REQUIRED BY LAW, AGENCY WILL BE
              RESPONSIBLE FOR THE JAMS CHARGES, INCLUDING THE COSTS OF THE
              MEDIATOR AND ARBITRATOR, OTHERWISE THE PARTIES WILL SHARE SUCH
              CHARGES EQUALLY. AGENCY AGREES THAT THE ARBITRATOR SHALL APPLY THE
              SUBSTANTIVE LAW OF THE STATE OF CALIFORNIA (WITHOUT GIVING EFFECT
              TO ANY CHOICE OF LAW OR CONFLICT OF LAW RULES OR PROVISIONS
              WHETHER OF THE STATE OF CALIFORNIA OR ANY OTHER JURISDICTION THAT
              WOULD CAUSE THE APPLICATION OF THE LAWS OF ANY JURISDICTION OTHER
              THAN THE STATE OF CALIFORNIA) TO ALL STATE LAW CLAIMS AND FEDERAL
              LAW TO ANY FEDERAL LAW CLAIMS, AND THAT DISCOVERY SHALL BE
              CONDUCTED IN ACCORDANCE WITH THE JAMS RULES OR AS OTHERWISE
              PERMITTED BY LAW AS DETERMINED BY THE ARBITRATOR.THE ARBITRATOR’S
              AWARD SHALL CONSIST OF A WRITTEN, REASONED STATEMENT AS TO THE
              DISPOSITION OF EACH CLAIM AND THE RELIEF, IF ANY, AWARDED ON EACH
              CLAIM. AGENCY UNDERSTANDS THAT THE RIGHT TO APPEAL OR TO SEEK
              MODIFICATION OF ANY RULING OR AWARD BY THE ARBITRATOR IS LIMITED
              UNDER STATE AND FEDERAL LAW. ANY AWARD RENDERED BY THE ARBITRATOR
              WILL BE FINAL AND BINDING, AND JUDGMENT MAY BE ENTERED ON IT IN
              ANY COURT OF COMPETENT JURISDICTION IN CALIFORNIA, AT THE TIME THE
              AWARD IS RENDERED OR AS OTHERWISE PROVIDED BY LAW..
            </p>
          </div>

          <div className="d-flex list_alpha_space">
            <span class="font-weight-bold therapy_services_list">12.2. </span>
            <p className="pl-3">
              TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGREEMENT TO ARBITRATE
              SET FORTH IN THIS SECTION 17 COVERS ALL GRIEVANCES, DISPUTES,
              CLAIMS OR CAUSES OF ACTION THAT OTHERWISE COULD BE BROUGHT IN A
              FEDERAL, STATE OR LOCAL COURT OR AGENCY UNDER APPLICABLE FEDERAL,
              STATE, OR LOCAL LAWS, ARISING OUT OF OR RELATING TO THE BUSINESS
              RELATIONSHIP WITH AGENCY (OR THE SEPARATION THEREOF), INCLUDING
              ANY CLAIMS AGENCY MAY HAVE AGAINST AGENCY OR AGAINST ITS OFFICERS,
              DIRECTORS, SUPERVISORS, MANAGERS, EMPLOYEES, OR AGENTS IN THEIR
              CAPACITY AS SUCH OR OTHERWISE. THE CLAIMS COVERED BY THE AGREEMENT
              TO ARBITRATE IN THIS SECTION 17 INCLUDE CLAIMS FOR BREACH OF ANY
              CONTRACT OR COVENANT (EXPRESS OR IMPLIED), TORT CLAIMS, CLAIMS FOR
              COMPENSATION DUE, AND CLAIMS FOR VIOLATION OF ANY FEDERAL, STATE,
              LOCAL OR OTHER GOVERNMENTAL LAW, STATUTE, REGULATION, OR
              ORDINANCE.
            </p>
          </div>

          <div className="d-flex list_alpha_space">
            <span class="font-weight-bold therapy_services_list">12.3. </span>
            <p className="pl-3">
              AGENCY EXPRESSLY INTEND AND AGREE THAT: (I) CLASS ACTION AND
              REPRESENTATIVE ACTION PROCEDURES SHALL NOT BE ASSERTED, NOR WILL
              THEY APPLY, IN ANY ARBITRATION PURSUANT TO THIS AGREEMENT; (II)
              AGENCY WILL NOT ASSERT CLASS ACTION OR REPRESENTATIVE CLAIMS
              AGAINST THE OTHER IN ARBITRATION OR OTHERWISE; AND (III) AGENCY
              SHALL ONLY SUBMIT THEIR OWN, INDIVIDUAL CLAIMS IN ARBITRATION AND
              WILL NOT SEEK TO REPRESENT THE INTERESTS OF ANY OTHER PERSON.
              FURTHER, AGENCY EXPRESSLY INTEND AND AGREE THAT ANY CLAIMS BY
              AGENCY SHALL NOT BE JOINED, CONSOLIDATED, OR HEARD TOGETHER WITH
              CLAIMS OF ANY OTHER PERSON.
            </p>
          </div>

          <div className="d-flex list_alpha_space">
            <span class="font-weight-bold therapy_services_list">12.4. </span>
            <p className="pl-3">
              BY CONSENTING TO THIS AGREEMENT, AGENCY EXPRESSLY REPRESENT THAT:
              (I) AGENCY HAS BEEN GIVEN THE OPPORTUNITY TO FULLY REVIEW AND
              COMPREHEND THIS AGREEMENT; (II) AGENCY UNDERSTANDS THE TERMS OF
              THIS AGREEMENT AND FREELY AND VOLUNTARILY CONSENT TO BE BOUND TO
              THIS AGREEMENT; AND (III) AGENCY FULLY UNDERSTANDS AND AGREES THAT
              AGENCY IS GIVING UP CERTAIN RIGHTS OTHERWISE AFFORDED TO AGENCY BY
              CIVIL COURT ACTIONS, INCLUDING THE RIGHT TO A JURY TRIAL.
            </p>
          </div>

          <div className="d-flex list_alpha_space">
            <span class="font-weight-bold therapy_services_list">12.5. </span>
            <p className="pl-3">
              NOTHING CONTAINED IN THIS SECTION 17 SHALL RESTRICT EITHER PARTY
              FROM SEEKING TEMPORARY OR PRELIMINARY INJUNCTIVE RELIEF IN A COURT
              OF LAW. FURTHER, FOR THE AVOIDANCE OF DOUBT, THE PARTIES AGREE
              THAT, NOTHING HEREIN, NOR ANYTHING IN THE JAMS RULES, SHALL PERMIT
              ANY ARBITRATOR TO DETERMINE THE ARBITRABILITY OF ANY APPLICATION
              FOR TEMPORARY OR PRELIMINARY INJUNCTIVE RELIEF. FINALLY, ANY
              QUESTIONS REGARDING THE ENFORCEABILITY OF THIS PROVISION SHALL BE
              WITHIN THE EXCLUSIVE JURISDICTION OF THE ARBITRATOR TO DECIDE.
            </p>
          </div>
        </ol>

        <ol>
          <strong className="text-center">
            By signing below, I expressly acknowledge that I have read,
            understood, and considered the consequences of this Agreement, that
            I agree to be bound by the terms of this Agreement, and that I am
            legally competent to enter into this Agreement with TCC.
          </strong>
          <div className="d-flex justify-content-end health_gency">
            <div className="lastSection">
              <div className="inputs mb-3">
                <label>Signature:</label>
                <button
                  type="button"
                  className="Approve-btns"
                  onClick={() => setIsCanvasShow(true)}
                >
                  {signatureAdded ? "Update Signature" : "Add Signature"}
                </button>
                {signatureAdded && (
                  <img
                    src={signatureImage}
                    height={"60px"}
                    width={"250px"}
                    crossOrigin="anonymous"
                  />
                )}
              </div>

              <div className="inputs mb-3">
                <label>
                  Home Health Agency:{" "}
                  {touched.name && errors.name && (
                    <span className="required_lable ml-0"> *</span>
                  )}
                </label>
                <Field name="agencyname">
                  {({ input, meta }) => (
                    <>
                      <input {...input} type="text"></input>
                    </>
                  )}
                </Field>
              </div>

              <div className="inputs mb-3">
                <label>
                  Name/Title:{" "}
                  {touched.name && errors.name && (
                    <span className="required_lable ml-0"> *</span>
                  )}
                </label>
                <Field name="name">
                  {({ input, meta }) => (
                    <>
                      <input {...input} type="text"></input>
                    </>
                  )}
                </Field>
              </div>
              <div className="inputs mb-3">
                <label>
                  Address:{" "}
                  {touched.primaryAddress && errors.primaryAddress && (
                    <span className="required_lable ml-0"> *</span>
                  )}
                </label>
                <Field name="primaryAddress">
                  {({ input, meta }) => (
                    <>
                      <input {...input} type="text"></input>
                    </>
                  )}
                </Field>
              </div>
              <div className="inputs mb-3">
                <label></label>
                <Field name="secondaryAddress">
                  {({ input, meta }) => (
                    <>
                      <input {...input} type="text"></input>
                    </>
                  )}
                </Field>
              </div>
              <div className="inputs mb-3">
                <label>
                  Date:{" "}
                  {touched.date && errors.date && (
                    <span className="required_lable ml-0"> *</span>
                  )}
                </label>
                <Field name="date">
                  {({ input, meta }) => (
                    <>
                      <input {...input} type="text"></input>
                    </>
                  )}
                </Field>
              </div>
            </div>
          </div>
        </ol>
      </div>
      {isCanvasShow && (
        <SignatureCanvas
          loadDoc={saveForm}
          isShow={isCanvasShow}
          setSignatureAdded={setSignatureAdded}
          handleClose={() => setIsCanvasShow(false)}
        />
      )}
    </>
  );
};

export default HhaAgreement;
