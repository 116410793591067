import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import { getUserDetailsById } from "../../Redux/actions/auth";
import { useDispatch } from "react-redux";
import { encodedData } from "../../utils/auth";
import { useNavigate } from "react-router-dom";

const NotificationModal = (props) => {
  const { handleClose, open, setOpen, currentPatient, caseStatus } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getUserDetails = (open, parmsUrl) => {
    dispatch(getUserDetailsById(parmsUrl)).then((e) => {
      setOpen(open);
    });
  };
  return (
    <Modal show={open} className="Staffnew-Modal" onHide={handleClose}>
      {caseStatus && caseStatus.activeCase && !caseStatus.discharged ? (
        <Container className="staff-container">
          <h2 className="text-staff text-center">
            this patient is already in an active case <br /> {` `}
            {currentPatient &&
              `${currentPatient.label} ${currentPatient.restLabel}`}{" "}
            ?
          </h2>
          <div className="d-flex gap-3 mt-5">
            <button
              className="yes-btn"
              style={{ width: "169px" }}
              onClick={(e) => {
                navigate(
                  `/patientdetails/${encodedData(currentPatient.key)}/episodes`
                );
              }}
            >
              Go to episode
            </button>
          </div>
        </Container>
      ) : (
        <Container className="staff-container">
          <h2 className="text-staff text-center">
            Do you want to Auto-Fill the Details for <br /> {` `}
            {currentPatient &&
              `${currentPatient.label} ${currentPatient.restLabel}`}{" "}
            ?
          </h2>
          <div className="d-flex gap-3 mt-5">
            <button
              className="yes-btn"
              onClick={(e) => {
                getUserDetails(
                  false,
                  `${currentPatient.key}?staffing=true&staffingdiagnosis=true`
                );
              }}
            >
              Yes
            </button>
            <button className="no-btn" onClick={() => setOpen(false)}>
              No{" "}
            </button>
          </div>
        </Container>
      )}
    </Modal>
  );
};

export default NotificationModal;
