import React, { useState, useEffect } from "react";
import { Row, Col } from "bootstrap-4-react";
import CommonTable from "../../components/common/CommonTable";
import { currentRole, getUserId } from "../../utils/auth";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../Redux/slices/authSlice";
import { getAllUserDetails, getUserCredential, getAgencyAllUserDetails, getAgencyUserCredential } from "../../Redux/actions/admin";
import Loader from "../../components/Loader";
import ProfileIcon from "../../images/usertable03.svg";
import Searchbar from "../../images/search_grey_icon.svg";
import TherapistCredentialsModal from "../../pages/modals/TherapistCredentialsModal";
import { MyModal2 } from "../../pages/modals/MyModal2";
import ReasonRejectedModal from "../../pages/modals/ReasonRejectedModal";
import ReactPaginate from "react-paginate";
import { apiBasePath } from "../../Redux/config/config";
import { therapistImageRoute } from "../../utils/directoryroute";
const TherapistCredential = () => {
    const handleClose = () => { setshow(false) };
    const handleShow = () => setshow(true);
    const dispatch = useDispatch();
    const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
    const isLoading = useSelector((e) => e.adminSlice.isLoading);
    const isAdmin = currentUser?.Role?.key === currentRole?.admin;
    const isAgency = currentUser?.Role?.key === currentRole?.agency;
    const agencyUserCount = useSelector(isAdmin ? (e) => e.adminSlice.allAdminUserCount :
        (e) => e.adminSlice.agencyUserCount);
    const userList = useSelector(isAdmin ? (e) => e.adminSlice?.allAdminUserDetails?.userDetail :
        (e) => e.adminSlice?.agencyUserDetails?.userDetail);
    const credentialUserList = useSelector(isAdmin ? (e) => e.adminSlice?.adminUserCredential?.UserCredentialDetail :
        (e) => e.adminSlice?.adminAgencyUserCredential?.UserCredentialDetail);
    const [updateTab, setUpdateTab] = useState(false);
    const [show, setshow] = useState(false);
    const [rejectedId, setRejectedId] = useState();
    const [pageNo, setPageNo] = useState(1);
    const [rejectedStatus, setRejectedStatus] = useState();
    const [rejected, setRejected] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [rowValue, setRowValue] = useState("");
    const [rejectedFileTypes, SetrejectedFileTypes] = useState("");
    const [onboardingPdf, setOnboardingPdf] = useState([]);
    const [viewAllId, setViewAllId] = useState();
    const [pageSize] = useState(isAdmin ? 10 : 20);
    const columnsToHide = [];
    const columns = ["name", "disciplines", "credentialUnderReview", "expiredCredentials"];

    const handleRejected = () => {
        setRejected(false);
    };

    function AdminFun(id) {
        if (isAdmin) {
            dispatch(getUserCredential(id));
            handleShow(id);
            setViewAllId(id);
            const selectedUser = userList?.find(user => user?.id === id);
            if (selectedUser) {
                setOnboardingPdf(selectedUser.onboardingPdf || []);
            }
        } else if (isAgency) {
            dispatch(getAgencyUserCredential(id));
            handleShow();
        }
    }

    const handleCredentialReject = (rejectedStatus, id, credentialsStatus, types) => {
        setRejectedId(id)
        setRejectedStatus(credentialsStatus)
        SetrejectedFileTypes(types)
        setRejected(rejectedStatus);
    };

    useEffect(() => {
        dispatch(setLoader({ loading: true }));
        if (isAdmin) {
            dispatch(getAllUserDetails({ pageSize, pageNo: pageNo, roleId: currentRole.supervisingtherapist }));
        } else if (isAgency) {
            dispatch(getAgencyAllUserDetails({ pageSize, pageNo: pageNo, createdBy: getUserId()?.userId }));
        }
    }, [dispatch, isAdmin, isAgency, updateTab]);

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1);
        if (isAdmin) {
            dispatch(getAllUserDetails({ pageSize, pageNo: event.selected + 1 }));
        } else if (isAgency) {
            dispatch(getAgencyAllUserDetails({ pageSize, pageNo: event.selected + 1, createdBy: getUserId()?.userId }));
        }
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredList = userList?.filter(user => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        return fullName.includes(searchQuery.toLowerCase());
    });

    const sortedList = !isAdmin ? filteredList?.sort((a, b) => {
        const fullNameA = `${a.firstName} ${a.lastName}`;
        const fullNameB = `${b.firstName} ${b.lastName}`;
        return fullNameA.localeCompare(fullNameB);
    }) : filteredList;

    const actions = [
        {
            key: "userList",
            data: userList,
            action: (rowData) => {
                return (
                    <td style={{ borderTop: '1px solid #EBEAED !important' }}>
                        <pre className="d-flex gap-3 flex-wrap mb-0">
                            <button
                                className="view-btn update-btn tr-btn ml-auto mt-3 mb-3"
                                onClick={() => {
                                    const id = isAdmin ? rowData?.id : rowData?.therapists;
                                    AdminFun(id);
                                    setRowValue(rowData);
                                }}>
                                View All
                            </button>
                        </pre>
                    </td>
                );
            },
        },
    ];

    const overrideColumnName = (colName) => {
        switch (colName) {
            case "name":
                return "THERAPIST NAME";
            case "disciplines":
                return "DISCIPLINES";
            case "credentialUnderReview":
                return isAdmin ? "Credentials Under Review" : "";
            case "expiredCredentials":
                return isAdmin ? "Expired Credentials" : "";
            default:
                return colName;
        }
    };

    const renderRowData = (result, col, item) => {
        if (col === "name") {
            const fullName = `${result.firstName} ${result.lastName}`;
            return (
                <>
                    <div style={{ display: "flex", alignItems: "center", }}>
                        <img
                            src={
                                result.image
                                    ? `${apiBasePath}${therapistImageRoute}${result.image}`
                                    : ProfileIcon
                            }
                            crossOrigin="anonymous"
                            className="table-img"
                            style={{ borderRadius: "50%", objectFit: "cover" }}
                            alt="img"
                        />
                        <div>
                            <p className="therapist-body m-0 ">
                                {" "}
                                {fullName}
                            </p>
                        </div>
                    </div>
                </>
            );
        }
        if (col === "disciplines") {
            const discipline = result?.userDetail?.discipline;
            const capitalizedDiscipline = discipline ? discipline.toUpperCase() : '';
            return (
                <>
                    <div>
                        <p style={{ width: '180px' }} className="therapist-body m-0">
                            {" "}
                            {capitalizedDiscipline}
                        </p>
                    </div>
                </>
            );
        }
        if (isAdmin) {

            if (col === "credentialUnderReview") {
                return (
                    <>
                        <div className="flex justify-center">
                            <p className="therapist-body m-0">
                                {result?.activeCredentialCount}
                            </p>
                        </div>
                    </>
                );
            }
            if (col === "expiredCredentials") {
                return (
                    <>
                        <div className="flex justify-center">
                            <p className="therapist-body m-0">
                                {result?.expiredCredentialCount}
                            </p>
                        </div>
                    </>
                );
            }
            if (result[item] && typeof result[item] == "string") {
                return <p className=" title-24 m-0 mt-3 mb-3">{result[item]}</p>;
            } else {
                return <p className=" title-24 m-0 mt-3 mb-3">--</p>;
            }
        };
    }
    return (
        <>
            {isLoading && <Loader />}
            <div className="table-container-3 pr-50 mobile_left_width">
                <div
                    className="patient_main_parent  gap-3 justify-content-between outline-none "
                    style={{ marginBottom: "50px" }}>
                    <div className="search_bar_frm  mobile_margin_1 mt-3" style={{ width: "20rem" }} >
                        <img style={{ marginRight: "15px" }} src={Searchbar} alt="" />
                        <input
                            placeholder="Search by therapist name..."
                            className="pl-2 searching"
                            style={{
                                border: "none", fontSize: "18px", outline: "none", width: "100%",
                            }}
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
            </div>

            {sortedList && sortedList.length > 0 ? (
                <div className="container-fluid table-container-3 pr-50">
                    <Row>
                        <div className="inside_table_tabs">
                            <Col lg={12} className="p-0">
                                <div className="table-responsive table-seprate-therapist">
                                    <CommonTable
                                        css="table-responsive-lg"
                                        data={sortedList}
                                        columns={columns}
                                        columnsToHide={columnsToHide}
                                        overrideColumnName={overrideColumnName}
                                        actions={actions}
                                        myFun={AdminFun}
                                        renderRowData={renderRowData}
                                        isLoading={false}
                                    />
                                </div>
                            </Col>
                        </div>
                    </Row>
                </div>

            ) : (
                <div className="d-flex align-items-center justify-content-center mt-5">
                    {!isLoading && <h2 className="fs-5 mt-5">No Data available</h2>}
                </div>
            )}
            {agencyUserCount > 10 && userList?.length > 0 && !searchQuery && (
                <div id="container ">
                    <div className="row justify-content-start mt-3  pl-5 ">
                        <div className="pl-3 pr-5">
                            <ReactPaginate
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={Math.ceil(agencyUserCount / pageSize)}
                                previousLabel="< previous"
                                renderOnZeroPageCount={null}
                                forcePage={
                                    Math.ceil(agencyUserCount / pageSize) === 0 ? -1 : pageNo - 1
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
            {show && (
                <MyModal2
                    data={
                        <TherapistCredentialsModal
                            userList={credentialUserList}
                            onReject={handleCredentialReject}
                            onboardingPdf={onboardingPdf}
                            handleClose={handleClose}
                        />
                    }
                    show={show}
                    handleClose={handleClose}
                    modalClass={"formModal"}
                />
            )}
            {rejected && (
                <MyModal2
                    data={<ReasonRejectedModal
                        rejectedId={rejectedId}
                        rejectedStatus={rejectedStatus}
                        rejectedFileTypes={rejectedFileTypes}
                        rowValue={rowValue}
                        viewAllId={viewAllId}
                        handleClose={handleRejected} />
                    }
                    show={rejected}
                    handleClose={handleRejected}
                    modalClass={"addNewCred"}
                />
            )}
        </>
    );
};
export default TherapistCredential;