import React, { useState } from "react";
import { Nav } from "react-bootstrap";

export const CommonHead2 = (props) => {
  const [tabsChange, setTabschnage] = useState(false);

  function handleMenu(e) {
    if (props.setMenu) props.setMenu(e);

    setTabschnage(!tabsChange);
  }

  return (
    <>
      <div className="commen2 mb-4">
        <Nav className="gap-3" variant="tabs" defaultActiveKey="/home">
          {props.data.map((e, i) => {
            return (
              <Nav.Item
                key={`${e.label}_${i}`}
                onClick={() => handleMenu(e.key)}
              >
                <Nav.Link
                  className={`menu_boxing ${
                    e.key === props.menuItem ? "active" : ""
                  }`}
                >
                  <span
                    className={`${props.count} ${
                      props.countClass && props.countClass
                    }`}
                  >
                    {e?.count > 99
                      ? "99+"
                      : e?.count?.toString().length < 2
                      ? `0${e?.count}`
                      : e?.count}
                  </span>
                  {e?.label && <p> {e?.label} </p>}
                  {e?.lable && e?.lable ? (
                    <div className="d-flex gap-3 align-items-center">
                      <div className=" patient-card-div1 patient-pt ">
                        {e?.lable && e?.lable}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Nav.Link>
              </Nav.Item>
            );
          })}
        </Nav>
      </div>
    </>
  );
};
