import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { HiMinus, HiPlus } from "react-icons/hi";
import AgencyNavbar from "./AgencyNavbar";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import Paymentdetails from "./Paymentdetails";
import Rswitch from "../../../components/Rswitch";
import Loader from "../../../components/Loader";
import TermsAndConditions from "./TermsAndConditions";
import { useEffect, useMemo } from "react";
import AgencyDefaultRates from "./AgencyDefaultRates";
import {
  agencySignupSteps,
  currentRole,
  decodeToken,
  defaultStatus,
  formatUsPhoneNumber,
} from "../../../utils/auth";
import AddressAutocomplete from "../../../components/AddressAutoComplete";
import { getUserDetailsById } from "../../../Redux/actions/auth";
import agencyImage from "../../../images/Modalimg.png";
import {
  getDefaultRates,
  updatePendingSteps,
  userSignup,
} from "../../../Redux/actions/therapist";
import {
  allowedExtensions,
  formatPinCode,
  formatPinCodeValue,
  getUserDetailParams,
  phoneNumberToUSFormat,
  blockInvalidChar,
  passwordRegex,
} from "../../../utils/helper";
import UserUnderReview from "../../UserUnderReview";
import { setRequest } from "../../../Redux/slices/therapistSignupSlice";
import { useState } from "react";
import { Col } from "bootstrap-4-react/lib/components/layout";
import { AiOutlineConsoleSql } from "react-icons/ai";

const CreateAgencyAccount = (e) => {
  const [pageInitilize, setPageInitilize] = useState(false);
  const [preferencesError, setPreferencesError] = useState(false);
  const [duppreferencesError, setDuppreferencesError] = useState(false);
  const pendingSteps = useSelector((e) => e.therapistSigunpSlice.pendingSteps);
  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const rejectedData = PrefillDetails?.userDetail?.rejectedFields || {};

  const isLoading = useSelector((e) => e.therapistSigunpSlice.isLoading);
  const isRequest = useSelector((e) => e.therapistSigunpSlice.isRequest);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    dispatch(getDefaultRates(currentRole.agency));
  }, []);

  useEffect(() => {
    if (searchParams && searchParams.get("auth")) {
      let userDetail = decodeToken(searchParams.get("auth"));
      dispatch(
        getUserDetailsById(
          `${userDetail.userId}?${getUserDetailParams.paymentDetail}=true&${getUserDetailParams.secondaryEmails}=true&${getUserDetailParams.representativeEmails}=true&${getUserDetailParams.userNotifications}=true`
        )
      ).then((response) => {
        if (response?.payload?.data?.pendingSteps === "completed") {
          navigate("/UserUnderReview");
        }
      });
    }
  }, [dispatch, searchParams]);

  function goBackFunction(pendingStep) {
    let pendingSteps = JSON.parse(JSON.stringify(agencySignupSteps));
    switch (pendingStep) {
      case "payments":
        pendingSteps = pendingSteps.splice(1, 5).toString();
        break;
      case "rates":
        pendingSteps = pendingSteps.toString();
        break;
      case "termconditions":
        pendingSteps = pendingSteps.splice(2, 5).toString();
        break;
      default:
        pendingSteps = pendingSteps.toString();
        break;
    }
    dispatch(
      updatePendingSteps({
        pendingSteps: pendingSteps,
        userId: PrefillDetails.id,
      })
    ).then((res) => {
      dispatch(
        getUserDetailsById(
          `${PrefillDetails.id}?${getUserDetailParams.paymentDetail}=true&${getUserDetailParams.secondaryEmails}=true&${getUserDetailParams.representativeEmails}=true&${getUserDetailParams.userNotifications}=true`
        )
      );
    });
  }

  let fieldToCheck = {
    name: "name",
    userName: "email",
    phone: "phone",
    fax: "fax",
    representative: "representative",
    representativePhoneNo: "representativePhoneNo",
    representativeEmails: "representativeEmails",
    address: "address",
  };
  function handleSubmit(values) {
    let formData = new FormData();
    let userConsentFile;
    let id = null;
    let tempValues = { ...values };
    if (PrefillDetails?.id) {
      tempValues.id = PrefillDetails.id;
      id = PrefillDetails.id;
    }

    tempValues.user = currentRole.agency;
    let emails = [];

    if (values?.email) {
      emails?.push({ email: values.email, id });
    }
    if (
      values?.representativeEmail &&
      values?.representativeEmail?.length > 0
    ) {
      tempValues?.representativeEmail?.forEach((item) => {
        if (tempValues?.email) {
          item.email = tempValues?.email;
        }
      });
    }

    if (values && values.secondryEmail && values.secondryEmail.length > 0) {
      tempValues?.secondryEmail?.map((obj) => {
        if (obj?.email) {
          emails?.push({ email: obj?.email, id: obj.id });
        }
        return obj;
      });
    }
    if (
      values.isConsent &&
      values.userConsent &&
      values.userConsent.length > 0
    ) {
      let date = new Date().getTime();
      const name = date + "_" + values.userConsent[0].name;
      userConsentFile = new File(values.userConsent, name);
      formData.append("userConsent", userConsentFile);
    }
    delete tempValues.userConsent;
    formData.append("profileData", JSON.stringify(tempValues));
    dispatch(userSignup(formData)).then((e) => {
      if (!e.payload.success) {
        if (e.payload?.data?.duplicateEmails?.length > 0) {
          setPreferencesError(
            `You can not add duplicate emails(${e.payload.data.duplicateEmails?.toString()}).`
          );
          setTimeout(() => setPreferencesError(null), 4000);
          dispatch(setRequest({ request: false }));
        } else {
          toast.error(`${e.payload.message}.`);
        }
      } else {
        setSearchParams({ auth: e.payload.data.token });
        // navigate(`/agencysignup/?auth=${e.payload.data.token}`);
        dispatch(getUserDetailsById(e.payload.data.userData.id));
      }
    });
  }

  const setInitialValue = (event) => {
    if (!searchParams || !searchParams.get("auth")) {
      if (event && Object.keys(event).length > 0) {
        return event;
      }
    } else {
      if (!PrefillDetails && event && Object.keys(event).length > 0) {
        return event;
      }
    }
    const initial = {};
    initial.name = PrefillDetails && PrefillDetails ? PrefillDetails.name : "";
    initial.phone =
      PrefillDetails &&
      PrefillDetails.userDetail?.phone &&
      formatUsPhoneNumber(PrefillDetails?.userDetail?.phone);
    initial.fax = PrefillDetails && PrefillDetails.userDetail?.fax;
    initial.representative =
      PrefillDetails && PrefillDetails.userDetail?.representative;
    initial.representativePhoneNo =
      PrefillDetails &&
      PrefillDetails.userDetail?.representativePhoneNo &&
      formatUsPhoneNumber(PrefillDetails?.userDetail?.representativePhoneNo);
    initial.ext = PrefillDetails && PrefillDetails.userDetail?.ext;
    initial.email = PrefillDetails && PrefillDetails.email;
    initial.state = PrefillDetails ? PrefillDetails.userDetail?.state : "";
    initial.address = PrefillDetails ? PrefillDetails.userDetail?.address : "";
    initial.city = PrefillDetails ? PrefillDetails.userDetail?.city : "";
    initial.street = PrefillDetails ? PrefillDetails.userDetail?.street : "";
    initial.aptUnit = PrefillDetails
      ? PrefillDetails.userDetail?.aptUnit
      : null;
    initial.zip = PrefillDetails ? PrefillDetails.userDetail?.zip : "";
    if (PrefillDetails) {
      initial.isConsent = PrefillDetails.isConsent;
    }
    if (PrefillDetails && PrefillDetails?.isConsent) {
      initial.userConsentName = PrefillDetails && PrefillDetails?.agencyConsent;
    }
    initial.secondryEmail =
      PrefillDetails &&
      PrefillDetails.secondaryEmails &&
      PrefillDetails.secondaryEmails.length > 0
        ? PrefillDetails.secondaryEmails
        : [
            {
              email: "",
              intakeStaffing: false,
              caseManagement: false,
              accounting: false,
            },
          ];

    if (
      PrefillDetails &&
      PrefillDetails.representativeEmails &&
      PrefillDetails.representativeEmails.length > 0
    ) {
      PrefillDetails?.representativeEmails?.map((item) => {
        initial.representativeEmail = [
          {
            id: item?.id,
            userId: item?.userId,
            intakeStaffing: item?.intakeStaffing,
            caseManagement: item?.caseManagement,
            accounting: item?.accounting,
          },
        ];
      });
    } else {
      initial.representativeEmail = [
        {
          email: "",
          intakeStaffing: true,
          caseManagement: true,
          accounting: true,
        },
      ];
    }
    return initial;
  };

  const initValue = useMemo((e) => setInitialValue(e), [PrefillDetails]);

  const validateForm = (values) => {
    var regex = passwordRegex;
    const phone = /^-?\d+\.?\d*$/;

    const emailRegex = /^\S+@\S+\.\S+$/;
    // var zipRegex = /^\d{5}(?:-\d{4})?$/;
    // const zipCodeRegex = /^\d{5}(?:-\d{4})?$/;
    // const zipCodeRegex = /^\d{5}-\d{4}$/;
    const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;

    let errors = {};

    if (PrefillDetails && PrefillDetails.id) {
      if (values.password?.length > 0 || values.confirmPassword?.length > 0) {
        if (!values.confirmPassword) {
          errors["confirmPassword"] = "Confirm password is required.";
        }

        if (!values.password) {
          errors["password"] = "Password is required.";
        }

        if (values && values.confirmPassword) {
          if (values.password !== values.confirmPassword) {
            errors["confirmPassword"] = "Both password should match.";
          }
        }
        if (values && values.password) {
          if (!regex.test(values.password)) {
            errors["password"] =
              "Password strength/length should be at least  8 characters and maximum 64 characters and contain an uppercase, a lowercase, a special character, and a digit.";
          }
        }
      }
    } else {
      if (!values.confirmPassword) {
        errors["confirmPassword"] = "Confirm password is required.";
      }

      if (!values.password) {
        errors["password"] = "Password is required.";
      }

      if (values && values.confirmPassword) {
        if (values.password !== values.confirmPassword) {
          errors["confirmPassword"] = "Both password should match.";
        }
      }

      if (values && values.confirmPassword) {
        if (values.password !== values.confirmPassword) {
          errors["confirmPassword"] = "Both password should match.";
        }
      }

      if (values && values.password) {
        if (values.password.length < 8 && !regex.test(values.password)) {
          errors["password"] =
            "Password strength/length should be at least  8 characters and maximum 64 characters and contain an uppercase, a lowercase, a special character, and a digit.";
        } else if (!regex.test(values.password)) {
          errors["password"] =
            "Password strength/length should be at least  8 characters and maximum 64 characters and contain an uppercase, a lowercase, a special character, and a digit.";
        }
      }
    }

    if (!values.name) {
      errors["name"] = "Agency Name is required.";
    }
    if (!values.phone) {
      errors["phone"] = " Agency Phone is required.";
    } else if (values.phone && values.phone.match(/\d/g).length !== 10) {
      errors["phone"] = "Please enter a 10 digit number.";
    }

    if (!values.fax) {
      errors["fax"] = "Agency Fax is required.";
    } else if (values.fax && values.fax.match(/\d/g).length !== 10) {
      errors["fax"] = "Please enter a 10 digit number.";
    }
    if (!values.representative) {
      errors["representative"] = "Agency Representative is required.";
    }
    if (!values.representativePhoneNo) {
      errors["representativePhoneNo"] =
        "Agency Representative Phone is required.";
    } else if (
      values.representativePhoneNo &&
      values.representativePhoneNo.match(/\d/g).length !== 10
    ) {
      errors["representativePhoneNo"] = "Please enter a 10 digit number.";
    }
    if (!values.email) {
      errors["email"] = "Agency Representative Email Address is required.";
    }

    if (values.email) {
      if (!emailRegex.test(values.email)) {
        errors["email"] = "Please enter a valid email address.";
      }
    }

    if (!values.state) {
      errors["state"] = "State is required.";
    }
    if (!values.address) {
      errors["address"] = "Street is required.";
    }

    if (!values.city) {
      errors["city"] = "City is required.";
    }

    if (!values.zip) {
      errors["zip"] = "Zip is required.";
    }

    let secondryEmailError = [];

    if (values.secondryEmail && values.secondryEmail.length > 0) {
      values.secondryEmail.map((item) => {
        let secondryError = {};
        if (item && item.email && !emailRegex.test(item.email)) {
          secondryError["email"] = "Please enter a valid email address.";
        }
        secondryEmailError.push(secondryError);
      });
      errors["secondryEmail"] = secondryEmailError;
    } else {
      delete errors["secondryEmail"];
    }

    if (!values.hasOwnProperty("isConsent")) {
      errors["isConsent"] = " Please select one option.";
    } else if (
      values.isConsent &&
      !values.userConsentName &&
      !values.userConsent
    ) {
      errors["userConsent"] = "Please upload consent form.";
    } else if (
      !values.userConsentName &&
      values.userConsent &&
      values.userConsent[0]
    ) {
      if (
        !allowedExtensions.includes(
          values?.userConsent[0]?.name?.split(".").pop()
        )
      ) {
        errors["userConsent"] = "Please upload PDF documents only.";
      }
    }

    let emails = [];

    if (values?.email) {
      emails?.push({ email: values.email });
    }
    let missingNotification = [];
    if (values && values.secondryEmail && values.secondryEmail.length > 0) {
      let accounting = 0,
        intakeStaffing = 0,
        caseManagement = 0;
      values?.secondryEmail.forEach((item) => {
        if (!values?.representativeEmail[0]?.accounting && !item?.accounting) {
          accounting++;
        }
        if (
          !values?.representativeEmail[0]?.intakeStaffing &&
          !item?.intakeStaffing
        ) {
          intakeStaffing++;
        }
        if (
          !values?.representativeEmail[0]?.caseManagement &&
          !item?.caseManagement
        ) {
          caseManagement++;
        }
      });
      let emailslength = values?.secondryEmail?.length;
      if (
        accounting === emailslength ||
        intakeStaffing === emailslength ||
        caseManagement === emailslength
      ) {
        if (accounting === emailslength) {
          missingNotification.push("accounting");
        }
        if (intakeStaffing === emailslength) {
          missingNotification.push("intakeStaffing");
        }
        if (caseManagement === emailslength) {
          missingNotification.push("caseManagement");
        }
      }
      values?.secondryEmail?.map((obj) => {
        if (obj?.email) {
          emails?.push({ email: obj?.email, id: obj.id });
        }
        return obj;
      });
    }
    var isDuplicate = emails.some(function (item, idx) {
      return (
        emails.findIndex((object) => {
          return object.email === item.email;
        }) !== idx
      );
    });
    if (isDuplicate) {
      errors["duppreferencesError"] = "You can not add duplicate email.";
    }
    if (missingNotification.length > 0) {
      errors["preferencesError"] =
        "Each notification category should be assigned to at least one email address.";
    }

    return errors;
  };

  let myObj = {
    payments: <Paymentdetails goBackFunction={goBackFunction} />,
    rates: <AgencyDefaultRates goBackFunction={goBackFunction} />,
    termconditions: (
      <TermsAndConditions
        goBackFunction={goBackFunction}
        user={currentRole.agency}
      />
    ),
    success: (
      <TermsAndConditions
        open={true}
        goBackFunction={goBackFunction}
        image={agencyImage}
        user={currentRole.agency}
      />
    ),
    completed: <UserUnderReview />,
  };

  const selectResult = (result, setValue) => {
    if (result) {
      setValue("state", result.state);
      setValue("address", result.address);
      setValue("street", result.street);
      setValue("country", result.country);
      setValue("city", result.city);
      setValue("zip", formatPinCodeValue(result.postal_code));
      setValue("latitude", result.latitude);
      setValue("longitude", result.longitude);
      setValue("addressDetails", result?.addressDetails);
    }
  };

  const checkStepNumber = () => {
    let start = 1;
    let end = agencySignupSteps.length - 1;
    if (pendingSteps) {
      start = end - (pendingSteps.length - 2);
    }
    return (
      <div>
        0{start}/0<span className="pd-dt">{end}</span>{" "}
      </div>
    );
  };
  const repEmailRejHandle = (repName) => {
    if (
      PrefillDetails &&
      PrefillDetails?.Status?.key === defaultStatus.rejected &&
      PrefillDetails?.userDetail?.rejectedFields &&
      PrefillDetails?.userDetail?.rejectedFields[repName]
    ) {
      return <h6 style={{ color: "#c52525" }}>Rejected</h6>;
    }
  };

  const secondryEmailRejHandle = (secName) => {
    if (
      PrefillDetails &&
      PrefillDetails?.Status?.key === defaultStatus.rejected &&
      PrefillDetails?.userDetail?.rejectedFields &&
      PrefillDetails?.userDetail?.rejectedFields[secName]
    ) {
      return <h6 style={{ color: "#c52525" }}>Rejected</h6>;
    }
  };

  useEffect(() => {
    setPageInitilize(true);
  }, [PrefillDetails]);

  const handleDisableNot = (secEmail, index) => {
    const findData = secEmail?.find((item, ind) => ind === index);
    if (!findData?.email) {
      return true;
    }
  };

  const checkRejectedValue = (values, rejKey, className) => {
    let isrejected = false;
    if (
      PrefillDetails &&
      PrefillDetails.userDetail &&
      PrefillDetails.userDetail.rejectedFields &&
      PrefillDetails.Status.key === defaultStatus.rejected
    ) {
      let rejectedData = PrefillDetails.userDetail.rejectedFields;
      if (
        rejKey == "address" &&
        fieldToCheck[rejKey] &&
        values[fieldToCheck[rejKey]]
      ) {
        let valueAdress = `${values.address}, ${values.city}, ${values.state}, ${values.zip}`;
        if (rejectedData[rejKey] == valueAdress) isrejected = true;
      } else if (
        fieldToCheck[rejKey] &&
        values[fieldToCheck[rejKey]] &&
        rejectedData[rejKey] == values[fieldToCheck[rejKey]]
      ) {
        isrejected = true;
      }
    }
    return isrejected;
  };
  return (
    <>
      {/* {isLoading ? <Loader /> 
      : (
        <> */}
      <AgencyNavbar />
      <div className="ca-body Reason_of_rejection">
        <div className="container-fluid col-11 p-0">
          <div className="commonImage">
            <div className=" col-md-4 pt-5 px-0 col-lg-3 col-xl-3 d-flex justify-content-between mt-5">
              <h4 className="c-signup mb-0 mt-5">Sign Up</h4>
              {/* {PrefillDetails &&
                PrefillDetails?.Status?.key !== defaultStatus.approved && ( */}
              <p className="d-flex align-items-center cs-date gap-1 mb-0 mt-5">
                Step {checkStepNumber()}
              </p>
              {/* )} */}
            </div>
            {PrefillDetails &&
              PrefillDetails.Status &&
              PrefillDetails.Status.key === defaultStatus.rejected &&
              PrefillDetails?.userDetail &&
              PrefillDetails?.userDetail.rejectedFields &&
              PrefillDetails?.userDetail.rejectedFields !== null &&
              PrefillDetails?.userDetail.rejectedFields.reasonforRejection && (
                <div className="d-flex flex-column">
                  <div>
                    <h2 className="cs-heading  px-0">Reason For Rejection</h2>
                    <span className="required_lable mx-0">
                      Please review the red highlighted fields.
                    </span>
                  </div>
                  <textarea
                    className="discribe-input mt-2"
                    type="textarea"
                    placeholder="Describe Other Reason"
                    disabled
                    value={
                      PrefillDetails?.userDetail.rejectedFields
                        .reasonforRejection
                    }
                  />
                </div>
              )}

            {!pendingSteps ||
            pendingSteps.length === agencySignupSteps.length ? (
              <>
                {isLoading ? (
                  <div className="AgencyOnbordLoader">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <div className="container-fluid  ">
                      <div className="row  px-0">
                        <h2 className="cs-heading  px-0">Create Account</h2>
                      </div>
                      <Form
                        keepDirtyOnReinitialize
                        onSubmit={handleSubmit}
                        validate={validateForm}
                        mutators={{
                          ...arrayMutators,
                        }}
                        initialValues={initValue}
                        render={({
                          handleSubmit,
                          values,
                          valid,
                          errors,
                          touched,
                          form: {
                            mutators: { push, pop, setValue },
                          },
                          submitFailed,
                          form,
                        }) => (
                          <form
                            onSubmit={(e) => {
                              if (!valid)
                                toast.error(
                                  "Please complete all required fields."
                                );
                              handleSubmit(e);
                            }}
                          >
                            <div className="row Parent profile_information_row  ">
                              <h5 className="cs-subhead mb-3  px-0">
                                Profile Information
                              </h5>
                              <div className="row mx-0 px-0 profile_information_field_row">
                                <div className=" d-flex flex-column mr-4 p-0 col-xxl-4 col-xl-3 col-lg-3 col-md-3">
                                  <Field name="name">
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          className={`cs-input mb-3  ${
                                            checkRejectedValue(
                                              values,
                                              "name"
                                            ) && "rejected_text"
                                          }`}
                                          {...input}
                                          type="text"
                                          placeholder={
                                            !meta.active && "Agency Name"
                                          }
                                        />
                                        {submitFailed &&
                                          meta.error &&
                                          meta.touched &&
                                          !isRequest && (
                                            <p
                                              className={`alert alert-danger top-11 ${
                                                checkRejectedValue(
                                                  values,
                                                  "name"
                                                ) && "reject-margin"
                                              }`}
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                      </div>
                                    )}
                                  </Field>
                                </div>

                                <div className=" d-flex flex-column mr-4 p-0 col-xxl-4 col-xl-3 col-lg-3 col-md-3">
                                  <Field name="phone">
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          className={`cs-input mb-3  ${
                                            checkRejectedValue(
                                              values,
                                              "phone"
                                            ) && "rejected_text"
                                          }`}
                                          {...input}
                                          type="text"
                                          onInput={(e) =>
                                            phoneNumberToUSFormat(e, input)
                                          }
                                          placeholder={
                                            !meta.active && "Agency Phone"
                                          }
                                        />
                                        {submitFailed &&
                                          meta.error &&
                                          meta.touched &&
                                          !isRequest && (
                                            <p
                                              className={`alert alert-danger top-11 ${
                                                checkRejectedValue(
                                                  values,
                                                  "phone"
                                                ) && "reject-margin"
                                              }`}
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className=" d-flex flex-column mr-4 p-0 col-xxl-4 col-xl-3 col-lg-3 col-md-3">
                                  <Field name="fax">
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          className={`cs-input mb-3  ${
                                            checkRejectedValue(values, "fax") &&
                                            "rejected_text"
                                          }`}
                                          {...input}
                                          type="text"
                                          onInput={(e) =>
                                            phoneNumberToUSFormat(e, input)
                                          }
                                          placeholder={
                                            !meta.active && "Agency Fax"
                                          }
                                        />
                                        {submitFailed &&
                                          meta.error &&
                                          meta.touched &&
                                          !isRequest && (
                                            <p
                                              className={`alert alert-danger top-11 ${
                                                checkRejectedValue(
                                                  values,
                                                  "fax"
                                                ) && "reject-margin"
                                              }`}
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className=" d-flex flex-column mr-4 p-0 col-xxl-4 col-xl-3 col-lg-3 col-md-3">
                                  <Field name="representative">
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          className={`cs-input mb-3  ${
                                            checkRejectedValue(
                                              values,
                                              "representative"
                                            ) && "rejected_text"
                                          }`}
                                          {...input}
                                          type="text"
                                          placeholder={
                                            !meta.active &&
                                            "Agency Representative"
                                          }
                                        />
                                        <br />
                                        {submitFailed &&
                                          meta.error &&
                                          meta.touched &&
                                          !isRequest && (
                                            <p
                                              className={`alert alert-danger top-11 ${
                                                checkRejectedValue(
                                                  values,
                                                  "representative"
                                                ) && "reject-margin"
                                              }`}
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className="d-flex flex-column  mr-4 p-0 col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                  <Field name="representativePhoneNo">
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          className={`cs-input mb-3  ${
                                            checkRejectedValue(
                                              values,
                                              "representativePhoneNo"
                                            ) && "rejected_text"
                                          }`}
                                          {...input}
                                          type="text"
                                          onInput={(e) =>
                                            phoneNumberToUSFormat(e, input)
                                          }
                                          placeholder={
                                            !meta.active &&
                                            "Agency Representative Phone Number"
                                          }
                                        />
                                        {submitFailed &&
                                          meta.error &&
                                          meta.touched &&
                                          !isRequest && (
                                            <p
                                              className={`alert alert-danger top-11 ${
                                                checkRejectedValue(
                                                  values,
                                                  "representativePhoneNo"
                                                ) && "reject-margin"
                                              }`}
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className="d-flex flex-column  mr-4 p-0 col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                  <Field name="ext">
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          className={`cs-input mb-3  ${
                                            checkRejectedValue(values, "ext") &&
                                            "rejected_text"
                                          }`}
                                          {...input}
                                          onKeyDown={(e) => blockInvalidChar(e)}
                                          type="number"
                                          placeholder={
                                            !meta.active &&
                                            "Ext (if applicable)"
                                          }
                                        />

                                        {submitFailed &&
                                          meta.error &&
                                          meta.touched &&
                                          !isRequest && (
                                            <p
                                              className={`alert alert-danger top-11 ${
                                                checkRejectedValue(
                                                  values,
                                                  "ext"
                                                ) && "reject-margin"
                                              }`}
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className="d-flex flex-column  mr-4 p-0 col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                  <Field name="email">
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          className={`cs-input mb-3  ${
                                            checkRejectedValue(
                                              values,
                                              "userName"
                                            ) && "rejected_text"
                                          }`}
                                          {...input}
                                          type="email"
                                          placeholder={
                                            !meta.active &&
                                            "Agency Representative Email Address"
                                          }
                                        />

                                        {submitFailed &&
                                          meta.error &&
                                          meta.touched &&
                                          !isRequest && (
                                            <p
                                              className={`alert alert-danger top-11 ${
                                                checkRejectedValue(
                                                  values,
                                                  "userName"
                                                ) && "reject-margin"
                                              }`}
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className="d-flex flex-column  mr-4 p-0 col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                  <Field name="password">
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          className={`cs-input mb-3  ${
                                            checkRejectedValue(
                                              values,
                                              "password"
                                            ) && "rejected_text"
                                          }`}
                                          type={"password"}
                                          {...input}
                                          disabled={searchParams.get("auth")}
                                          placeholder={
                                            PrefillDetails && PrefillDetails.id
                                              ? "*********"
                                              : "Password"
                                          }
                                        />

                                        {submitFailed &&
                                          meta.error &&
                                          meta.touched &&
                                          !isRequest && (
                                            <p
                                              className={`alert alert-danger top-11 ${
                                                checkRejectedValue(
                                                  values,
                                                  "password"
                                                ) && "reject-margin"
                                              }`}
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className="d-flex flex-column  mr-4 p-0 col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                  <Field name="confirmPassword">
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          type={"password"}
                                          className="cs-input mb-3"
                                          {...input}
                                          disabled={searchParams.get("auth")}
                                          placeholder={
                                            PrefillDetails && PrefillDetails.id
                                              ? "*********"
                                              : "Confirm Password"
                                          }
                                        />

                                        {submitFailed &&
                                          meta.error &&
                                          meta.touched &&
                                          !isRequest && (
                                            <p
                                              className="alert alert-danger top-11"
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <h5 className="cs-subhead mt-4 mb-3 px-0">
                                Agency Address
                              </h5>
                              <div className="row mx-0 px-0 profile_information_field_row_address">
                                <div className=" d-flex flex-column  mr-4 p-0 col-xxl-3 col-xl-3 col-lg-3 col-md-3 black_input_group">
                                  <Field name="address">
                                    {({ input, meta }) => (
                                      <div>
                                        <AddressAutocomplete
                                          className={`cs-input py-input py-input-ca p-0 border-0 mb-3 cs-input mb-3 ${
                                            checkRejectedValue(
                                              values,
                                              "address"
                                            ) && "rejected-border"
                                          }`}
                                          selectResult={(result) =>
                                            selectResult(result, form.change)
                                          }
                                          selectedAddress={values?.address}
                                        />
                                        {submitFailed &&
                                          meta.error &&
                                          meta.touched &&
                                          !isRequest && (
                                            <p
                                              className={`alert alert-danger top-11 ${
                                                PrefillDetails?.userDetail
                                                  ?.rejectedFields &&
                                                PrefillDetails?.Status?.key ===
                                                  defaultStatus.rejected &&
                                                PrefillDetails?.userDetail
                                                  ?.rejectedFields?.address &&
                                                "reject-margin"
                                              }`}
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className="d-flex flex-column  mr-4 p-0 col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                  <Field name="aptUnit">
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          className={`cs-input mb-3  ${
                                            checkRejectedValue(
                                              values,
                                              "address"
                                            ) && "rejected_text"
                                          }`}
                                          {...input}
                                          type="text"
                                          placeholder={
                                            !meta.active &&
                                            "Suite/Unit (if applicable)"
                                          }
                                        />
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className="d-flex flex-column  mr-4 p-0 col-xxl-3 col-xl-3 col-lg-3 col-md-3"></div>
                                <div className=" d-flex flex-column  mr-4 p-0 col-xxl-3 col-xl-3 col-lg-3 col-md-3 ">
                                  <Field name="city">
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          className={`cs-input mb-3  ${
                                            checkRejectedValue(
                                              values,
                                              "address"
                                            ) && "rejected_text"
                                          }`}
                                          {...input}
                                          type="text"
                                          placeholder={!meta.active && "City"}
                                        />
                                        <br />
                                        {submitFailed &&
                                          meta.error &&
                                          meta.touched &&
                                          !isRequest && (
                                            <p
                                              className="alert alert-danger top-11"
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className=" d-flex flex-column  mr-4 p-0 col-xxl-3 col-xl-3 col-lg-3 col-md-3 ">
                                  <Field name="state">
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          className={`cs-input mb-3  ${
                                            checkRejectedValue(
                                              values,
                                              "address"
                                            ) && "rejected_text"
                                          }`}
                                          {...input}
                                          type="text"
                                          placeholder={!meta.active && "State"}
                                        />
                                        {submitFailed &&
                                          meta.error &&
                                          meta.touched &&
                                          !isRequest && (
                                            <p
                                              className="alert alert-danger top-11"
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className="d-flex flex-column  mr-4 p-0 col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                  <Field classNamed="d-none" name="zip">
                                    {({ input, meta }) => (
                                      <div>
                                        <input
                                          className={`cs-input mb-3  ${
                                            checkRejectedValue(
                                              values,
                                              "address"
                                            ) && "rejected_text"
                                          }`}
                                          {...input}
                                          type="text"
                                          maxLength={"11"}
                                          placeholder={!meta.active && "Zip"}
                                          onInput={(e) =>
                                            formatPinCode(e, input)
                                          }
                                        />
                                        {submitFailed &&
                                          meta.error &&
                                          meta.touched &&
                                          !isRequest && (
                                            <p
                                              className="alert alert-danger top-11"
                                              role="alert"
                                            >
                                              {meta.error}
                                            </p>
                                          )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="d-flex gap-3 px-0 align-items-center">
                                <h5 className="cs-subhead mt-5 mb-6 px-0">
                                  Notification Preferences
                                </h5>
                                {submitFailed && errors.preferencesError && (
                                  <p className="alert alert-danger">
                                    {errors.preferencesError}
                                  </p>
                                )}
                                {preferencesError && (
                                  <p className="alert alert-danger">
                                    {preferencesError}
                                  </p>
                                )}
                              </div>
                              <div className="px-0">
                                <div className="col-xl-8 col-lg-10 col-md-12 p-0">
                                  <FieldArray name="representativeEmail">
                                    {({ fields }) =>
                                      fields.map((name, index) => (
                                        <div
                                          className="d-flex align-items-center"
                                          key={name}
                                        >
                                          <div className="col-md-12 mb-3 p-0">
                                            <div className="d-flex gap-5 align-items-end">
                                              <Field name={`${name}.email`}>
                                                {({ input, meta }) => (
                                                  <div style={{ width: "38%" }}>
                                                    {repEmailRejHandle(
                                                      `representativeEmails_${values?.representativeEmail[index]?.id}`
                                                    )}

                                                    <input
                                                      className="cs-input  "
                                                      {...input}
                                                      type="email"
                                                      placeholder={
                                                        !meta.active &&
                                                        "Agency Representative Email Address"
                                                      }
                                                      value={
                                                        values && values?.email
                                                          ? values?.email
                                                          : ""
                                                      }
                                                      disabled
                                                    />

                                                    {submitFailed &&
                                                      meta.error &&
                                                      meta.touched && (
                                                        <p
                                                          className="alert alert-danger top-11"
                                                          role="alert"
                                                        >
                                                          {meta.error}
                                                        </p>
                                                      )}
                                                  </div>
                                                )}
                                              </Field>

                                              <Field
                                                name={`${name}.intakeStaffing`}
                                              >
                                                {({ input }) => (
                                                  <div className="d-flex flex-column w-25">
                                                    <p className="ca-lable mb-4 ">
                                                      Intake & Staffing
                                                    </p>
                                                    <Rswitch {...input} />
                                                  </div>
                                                )}
                                              </Field>

                                              <Field
                                                name={`${name}.caseManagement`}
                                              >
                                                {({ input }) => (
                                                  <div className="d-flex flex-column w-25">
                                                    <p className="ca-lable mb-4  ">
                                                      Case Management
                                                    </p>
                                                    <Rswitch {...input} />
                                                  </div>
                                                )}
                                              </Field>

                                              <Field
                                                name={`${name}.accounting`}
                                              >
                                                {({ input }) => (
                                                  <div className="d-flex flex-column w-25">
                                                    <p className="ca-lable mb-4  ">
                                                      Accounting
                                                    </p>
                                                    <Rswitch {...input} />
                                                  </div>
                                                )}
                                              </Field>
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    }
                                  </FieldArray>

                                  <FieldArray name="secondryEmail">
                                    {({ fields }) =>
                                      fields.map((name, index) => (
                                        <div
                                          className=" d-flex align-items-center"
                                          key={name}
                                        >
                                          <div className="col-md-12 mb-3 p-0 ">
                                            <div className="d-flex gap-5 align-items-end position-relative">
                                              <Field name={`${name}.email`}>
                                                {({ input, meta }) => (
                                                  <div style={{ width: "38%" }}>
                                                    {secondryEmailRejHandle(
                                                      `secondaryEmails_${values?.secondryEmail[index]?.id}`
                                                    )}

                                                    <input
                                                      className="cs-input  "
                                                      {...input}
                                                      type="new-email"
                                                      placeholder={
                                                        !meta.active &&
                                                        `Secondary Email Address ${
                                                          index + 1
                                                        }`
                                                      }
                                                    />
                                                    {submitFailed &&
                                                      meta.error &&
                                                      meta.touched && (
                                                        <p
                                                          className="alert alert-danger top-11"
                                                          role="alert"
                                                        >
                                                          {meta.error}
                                                        </p>
                                                      )}
                                                  </div>
                                                )}
                                              </Field>

                                              <Field
                                                name={`${name}.intakeStaffing`}
                                              >
                                                {({ input }) => (
                                                  <div className="d-flex flex-column w-25">
                                                    {input.name ===
                                                      "secondryEmail[0].intakeStaffing" && (
                                                      <p className="ca-lable mb-4  ">
                                                        Intake & Staffing
                                                      </p>
                                                    )}
                                                    <div>
                                                      <Rswitch
                                                        {...input}
                                                        disabled={handleDisableNot(
                                                          values?.secondryEmail,
                                                          index
                                                        )}
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              </Field>

                                              <Field
                                                name={`${name}.caseManagement`}
                                              >
                                                {({ input }) => (
                                                  <div className="d-flex flex-column w-25">
                                                    {input.name ===
                                                      "secondryEmail[0].caseManagement" && (
                                                      <p className="ca-lable mb-4  ">
                                                        Case Management
                                                      </p>
                                                    )}

                                                    <Rswitch
                                                      {...input}
                                                      disabled={handleDisableNot(
                                                        values?.secondryEmail,
                                                        index
                                                      )}
                                                    />
                                                  </div>
                                                )}
                                              </Field>

                                              <Field
                                                name={`${name}.accounting`}
                                              >
                                                {({ input }) => (
                                                  <div className="d-flex flex-column w-25">
                                                    {input.name ===
                                                      "secondryEmail[0].accounting" && (
                                                      <p className="ca-lable mb-4  ">
                                                        Accounting
                                                      </p>
                                                    )}
                                                    <Rswitch
                                                      {...input}
                                                      disabled={handleDisableNot(
                                                        values?.secondryEmail,
                                                        index
                                                      )}
                                                    />
                                                  </div>
                                                )}
                                              </Field>
                                              {index > 0 && (
                                                <div
                                                  className="box-icon position-absolute delet-iconagency "
                                                  onClick={() =>
                                                    fields.remove(index)
                                                  }
                                                >
                                                  <HiMinus className="Hiplus" />
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    }
                                  </FieldArray>
                                  {submitFailed &&
                                    errors.duppreferencesError && (
                                      <p className="alert alert-danger my-2">
                                        {errors.duppreferencesError}
                                      </p>
                                    )}
                                  <div className="d-flex">
                                    <div className="box-icon">
                                      <HiPlus
                                        className="Hiplus"
                                        onClick={() =>
                                          push("secondryEmail", {
                                            email: "",
                                          })
                                        }
                                      />
                                    </div>

                                    <button
                                      className="cs-addbtn"
                                      type="button"
                                      onClick={() =>
                                        push("secondryEmail", {
                                          email: "",
                                        })
                                      }
                                    >
                                      Add secondary email
                                    </button>
                                  </div>
                                  <div className="mt-4">
                                    <p>
                                      Will your agency use The Care Connexion to
                                      staff visits that will require consent
                                      forms to be signed ?
                                    </p>
                                    <div className="d-flex gap-3">
                                      <Field
                                        name="isConsent"
                                        type="radio"
                                        value={true}
                                      >
                                        {({ input, meta }) => (
                                          <label className="col-2 container-2 align-items-center d-flex padding-left-ref ref-height ">
                                            <input
                                              {...input}
                                              type="radio"
                                              value={true}
                                              onChange={() =>
                                                input.onChange(true)
                                              }
                                            />
                                            <span className=" checkmark-2"></span>
                                            <div className="ml-3">Yes</div>
                                          </label>
                                        )}
                                      </Field>
                                      <Field
                                        name="isConsent"
                                        type="radio"
                                        value={false}
                                      >
                                        {({ input, meta }) => (
                                          <>
                                            <label className="col-2 container-2 align-items-center d-flex padding-left-ref ref-height ">
                                              <input
                                                {...input}
                                                type="radio"
                                                value={false}
                                                onChange={() =>
                                                  input.onChange(false)
                                                }
                                              />
                                              <span className=" checkmark-2"></span>
                                              <div className="ml-3">No</div>
                                            </label>
                                          </>
                                        )}
                                      </Field>
                                    </div>
                                    {/* <Col md={12} className={""}> */}
                                    {errors.isConsent && touched.isConsent && (
                                      <p
                                        className={`alert alert-danger top-11 top-cred w-50 `}
                                        role="alert"
                                      >
                                        {errors.isConsent}
                                      </p>
                                    )}
                                    {/* </Col> */}
                                    {values.isConsent && (
                                      <Field name={"userConsent"}>
                                        {({
                                          input: { onChange, ...input },
                                          meta,
                                        }) => (
                                          <div
                                            className=" file-upload-wrapper cred-date-input mobile_width-990"
                                            data-text={
                                              !values.userConsent
                                                ? values.userConsentName
                                                : ""
                                            }
                                          >
                                            <input
                                              className="input-group w-100 cred-input file-upload-field"
                                              type="file"
                                              onChange={(e) => {
                                                onChange(e.target.files);
                                              }}
                                            />
                                            {submitFailed &&
                                              meta.error &&
                                              meta.touched && (
                                                <p
                                                  className={`alert alert-danger top-11 top-cred  `}
                                                  role="alert"
                                                >
                                                  {meta.error}
                                                </p>
                                              )}
                                          </div>
                                        )}
                                      </Field>
                                    )}
                                  </div>
                                  <button
                                    className="ca-btn mb-3 mt-5"
                                    type="submit"
                                  >
                                    Save & continue
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              myObj[pendingSteps[0]]
            )}
          </div>
        </div>
      </div>
    </>
    //   )}
    // </>
  );
};

export default CreateAgencyAccount;
