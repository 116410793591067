import React, { useEffect, useState } from "react";
import { Row, Col } from "bootstrap-4-react";
import UserDetailModal from "../pages/Admin/UserDetailModal";
import { toast } from "react-toastify";
import {
  getAdminUsersList,
  updateAdminDetails,
  getCurrentAdminInfo,
} from "../Redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import CreateAdminUser from "../pages/Admin/CreateAdminUser";
import ReactPaginate from "react-paginate";
import CommonTable from "./common/CommonTable";
import userImage from "../images/user.png";
import Rswitch from "./Rswitch";
import { currentRole, getUserId } from "../utils/auth";
import { apiBasePath } from "../Redux/config/config";
import Loader from "./Loader";
import { clearUpdateAdminMessage } from "../Redux/slices/adminSlice";

const Admin = () => {
  const [show, setshow] = useState(false);
  const handleClose = () => setshow(false);
  const dispatch = useDispatch();
  const handleShow = () => setshow(true);
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const userList = useSelector((e) => e.adminSlice.adminUserDetails);
  const adminUserCount = useSelector((e) => e.adminSlice.adminUserCount);
  const isLoading = useSelector((e) => e.adminSlice.isLoading);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);

  function AdminFun(id) {
    handleShow();
    dispatch(getCurrentAdminInfo(id));
  }

  useEffect(() => {
    dispatch(
      getAdminUsersList({ pageSize, pageNo: pageNo, role: currentRole.admin })
    );

    return () => {
      dispatch(clearUpdateAdminMessage());
    };
  }, [dispatch]);

  const handlePageClick = (event) => {
    setPageNo(event.selected + 1);
    dispatch(
      getAdminUsersList({
        pageSize,
        pageNo: event.selected + 1,
        role: currentRole?.admin,
      })
    );
  };

  function handleEnable(value) {
    const response = { id: value.id, isActive: !value.isActive };
    if (response?.id === getUserId()?.userId) {
      toast.warn("You cannot disable himself.");
    } else {
      dispatch(updateAdminDetails(response)).then((ele) => {
        if (response.isActive === true) {
          toast.success("User enabled successfully.");
        } else {
          toast.success("User disabled successfully.");
        }
        dispatch(
          getAdminUsersList({
            pageSize,
            pageNo: pageNo,
            role: currentRole?.admin,
          })
        );
      });
    }
  }

  const columns = ["firstName", "email"];
  const columnsToHide = ["id", "isActive"];

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "firstName":
        return "Name";
      case "lastName":
        return "Last Name";
      case "email":
        return "Email Address";

      default:
        return colName;
    }
  };
  const actions = [
    {
      action: (rowData) => (
        <td className="">
          <button
            className="view-btn tr-btn ml-auto  mt-3 mb-3 "
            onClick={() => {
              AdminFun(rowData.id);
            }}
          >
            View
          </button>
        </td>
      ),
    },
    {
      action: (rowData) => (
        <td className="td-switch">
          {currentUser?.Role?.key === currentRole?.superadmin && (
            <div
              className="w-100"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Rswitch
                className="ml-5"
                value={rowData.isActive}
                handleToggle={() => handleEnable(rowData)}
                disabled={currentUser?.Role?.key !== currentRole?.superadmin}
              />
            </div>
          )}
        </td>
      ),
    },
  ];

  const renderRowData = (result, col, item) => {
    if (col === "firstName") {
      return (
        <>
          <div className="d-flex align-items-center">
            <img
              className="table-img"
              src={
                result.image
                  ? `${apiBasePath}documents/${result.image}`
                  : userImage
              }
              crossOrigin="anonymous"
              style={{ borderRadius: "50%", objectFit: "cover" }}
              alt=" "
            />
            <h1 className="title-22 m-0">
              {result["lastName"] + ", " + result["firstName"]}
            </h1>
          </div>
        </>
      );
    }
    return (
      <h1 className="displine-list title-24  mt-3 mb-3">
        {" "}
        {result[item] ? result[item] : "-"}
      </h1>
    );
  };

  return (
    <>
      <div
        style={{
          padding: "36px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <CreateAdminUser />
      </div>

      <div className="container-fluid ">
        <Row>
          <div className="inside_table_tabs">
            <Col lg={11}>
              <div className="table-responsive">
                <CommonTable
                  data={userList}
                  columns={columns}
                  columnsToHide={columnsToHide}
                  overrideColumnName={overrideColumnName}
                  myFun={AdminFun}
                  actions={actions}
                  renderRowData={renderRowData}
                  isLoading={isLoading}
                />
              </div>
            </Col>
          </div>
        </Row>
        {adminUserCount > 10 && userList?.length > 0 && (
          <div id="container ">
            <div className="row justify-content-start mt-3  pl-5 ">
              <div className="pl-3 pr-5">
                <ReactPaginate
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={Math.ceil(adminUserCount / pageSize)}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                  forcePage={
                    Math.ceil(adminUserCount / pageSize) === 0 ? -1 : pageNo - 1
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {isLoading && <Loader />}
      {show && (
        <UserDetailModal
          show={show}
          handleClose={handleClose}
          user={currentUser}
        />
      )}
    </>
  );
};

export default Admin;
