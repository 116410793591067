import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { getContactSupportMail } from "../../../Redux/actions/auth";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setLoader } from "../../../Redux/slices/authSlice";

const ContactSupportForm = (props) => {
  const isLoading = useSelector((e) => e.authSlice.isLoading);
  const navigate = useNavigate();

  const onSubmit = (values) => {
    const mailData = {
      subject: values.subject,
      description: values.description,
    };
    dispatch(getContactSupportMail(mailData))
      .then((response) => {
        if (response.payload.success === true) {
          toast.success("Message to Support staff sent successfully.");
          props.onClose();
          navigate("/home");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong.");
      });
  };
  const dispatch = useDispatch();
  //   useEffect(() => {}, []);

  useEffect(() => {
    dispatch(setLoader(false));
  }, [dispatch]);

  return (
    <>
      {isLoading && <Loader />}
      <Form
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};
          if (!values.subject) {
            errors.subject = "Subject is required.";
          }
          if (!values.description) {
            errors.description = "Description is required.";
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Field name="subject">
              {({ input, meta }) => (
                <div className="d-flex w-100">
                  <label className="col-md-2 p-0">Subject</label>
                  <div className="w-100">
                    <input
                      {...input}
                      type="text"
                      placeholder="Subject...."
                      className="col-md-12 contact_inouts"
                    />
                    {meta.error && meta.touched && (
                      <p className="alert alert-danger mb-3">{meta.error}</p>
                    )}
                  </div>
                </div>
              )}
            </Field>
            <Field name="description">
              {({ input, meta }) => (
                <div className="d-flex align-items-start">
                  <label className="col-md-2 p-0">Description</label>
                  <div className=" w-100">
                    <textarea
                      {...input}
                      type="textarea"
                      placeholder="Description...."
                      className="col-md-12 contact_inouts contact_inouts-height"
                      cols={50}
                      rows={10}
                    />
                    {meta.error && meta.touched && (
                      <p className="alert alert-danger mb-3">{meta.error}</p>
                    )}
                  </div>
                </div>
              )}
            </Field>
            <button
              className="offset-2 col-md-4 contact-btns ml-auto "
              type="submit"
            >
              Submit
            </button>
          </form>
        )}
      />
    </>
  );
};

export default ContactSupportForm;
