import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import CreatableSelect from "react-select/creatable";
import arrayMutators from "final-form-arrays";
import Select from "react-select";
import { ImAttachment } from "react-icons/im";
import Patient from "../../images/usertable02.svg";
import Forms from "react-bootstrap/Form";
import {
  AssistantDiscipline,
  closeCaseLabels,
  defaultCaseStatus,
  disciplineType,
  episodeStatus,
  formatPinCode,
  formatPinCodeValue,
  genderOptions,
  getUserDetailParams,
  openCaseLabels,
  rateDiscipline,
} from "../../utils/helper";
import VisitRates from "../../components/VisitRate";
import AddressAutocomplete from "../../components/AddressAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import {
  currentRole,
  defaultCountryCode,
  floatRegex,
  getUserId,
  rolePaths,
} from "../../utils/auth";
import { getDefaultRates, masterData } from "../../Redux/actions/therapist";
import { debounce } from "lodash";
import {
  therapistByDiscipline,
  userSearchDropDown,
} from "../../Redux/actions/agency";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserDetailsById } from "../../Redux/actions/auth";
import { apiBasePath } from "../../Redux/config/config";
import { resetPrefillDetails } from "../../Redux/slices/therapistSignupSlice";
import { addStaffing, checkMrn } from "../../Redux/actions/staffing";
import Loader from "../../components/Loader";
import SecondaryDaignosisForm from "./secondaryDaignosisForm";
import { MdCancel } from "react-icons/md";
import { FieldArray } from "react-final-form-arrays";
import { SupervisingDisciplines } from "../../utils/helper";
import { FiRefreshCw } from "react-icons/fi";
import { setMrnState } from "../../Redux/slices/staffingSlice";
import NotificationModal from "./notificationModal";

const customStyles = {
  control: (base) => ({
    ...base,
    cursor: "text",
    height: 46,
    minHeight: 46,
  }),
};

const CreateSelect = ({ input, ...rest }) => {
  return (
    <CreatableSelect
      styles={customStyles}
      minHeight={46}
      {...input}
      {...rest}
    />
  );
};

function StaffNewReferral() {
  let today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;

  const [uploadedImage, setUploadedImage] = useState(null);
  const [isSearchable] = useState(true);
  const [file, setFile] = useState();
  const [issLoading, setIssLoading] = useState(false);
  const [patientdropDownList, setPatientdropDownList] = useState();
  const [therapistdropDownList, setTherapistdropDownList] = useState({
    pt: [],
    ot: [],
    slp: [],
    pta: [],
    cota: [],
  });
  const [selectedName, setSelectedName] = useState(null);
  const [rateError, setRateError] = useState(false);
  const [disErros, setDisErros] = useState(false);
  const [secDiagErrors, setSecDiagErrors] = useState(false);
  const [secondaryDignosisData, setSecondayDignosisData] = useState();
  const [showSelectSecondaydignosis, setShowSelectSecondaydignosis] =
    useState(false);
  const [daignosisId, setDaignosisId] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let isLoading = useSelector((e) => e.staffingSlice.isLoading);
  const dropDownData = useSelector((e) => e.agencySlice.dropDownList);
  const mrnChecking = useSelector((e) => e.staffingSlice.mrnChecking);
  let masterDatas = useSelector((e) => e.therapistSigunpSlice.masterData);
  const isMrnAvailable = useSelector((e) => e.staffingSlice.isMrnAvailable);
  let defaultRates = useSelector((e) => e.therapistSigunpSlice.defaultRates);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const PatientDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );

  const [updated, setUpdated] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);

  const [caseStatus, setCaseStatus] = useState(null);
  function handleClose() {
    setOpen(false);
  }
  useEffect(() => {
    if (dropDownData?.length > 0) {
      let patientdropList = [];
      let therapistdropList = [];
      dropDownData.map((ele) => {
        let temp = {};
        temp["key"] = ele.id;
        if (ele.Role.key === rolePaths.supervisingtherapist) {
          temp["label"] = ele.firstName + " " + ele.lastName;
          if (ele?.image) {
            const image = `${apiBasePath}documents/therapist/${ele.image}`;
            temp["customAbbreviation"] = (
              <img
                src={image}
                alt="imagee"
                className="optionListImage"
                width={"auto"}
                height="30px"
                crossOrigin="anonymous"
              />
            );
          }
        } else {
          temp["label"] = `${ele.firstName}`;
          temp["restLabel"] = `${ele.lastName}, MR #${ele.staffingDetails.mrn}`;
          temp["isSelected"] = true;
          if (ele?.image) {
            const image = `${apiBasePath}documents/therapist/${ele.image}`;
            temp["customAbbreviation"] = (
              <img
                src={image}
                alt="imagee"
                className="optionListImage"
                width={"auto"}
                height="30px"
                crossOrigin="anonymous"
              />
            );
          }
        }

        temp["value"] = ele.id + ele.firstName;
        if (ele.Role.key === rolePaths.supervisingtherapist) {
          therapistdropList.push(temp);
        } else {
          patientdropList.push(temp);
        }
      });
      setPatientdropDownList(patientdropList);
    }
  }, [dropDownData]);
  useEffect(() => {
    dispatch(getDefaultRates(currentRole.agency));
    setPatientdropDownList();
    setTherapistdropDownList({
      pt: [],
      ot: [],
      slp: [],
      pta: [],
      cota: [],
    });
  }, []);
  const formatTherpistList = (discipline, data) => {
    let therapistList = [];
    data.map((ele) => {
      let temp = {};
      temp["key"] = ele.id;
      temp["label"] = ele.firstName + " " + ele.lastName;
      if (ele?.image) {
        const image = `${apiBasePath}documents/therapist/${ele.image}`;
        temp["customAbbreviation"] = (
          <img
            src={image}
            alt="imagee"
            className="optionListImage"
            width={"auto"}
            height="30px"
            crossOrigin="anonymous"
          />
        );
      }
      temp["value"] = ele.id + ele.firstName;
      therapistList.push(temp);
    });

    setTherapistdropDownList({
      ...therapistdropDownList,
      [discipline]: therapistList,
    });
  };

  useEffect(() => {
    dispatch(
      masterData({
        types: ["secondarydiagnosis", "initialvisittype", "primarydiagnosis"],
      })
    );
  }, [dispatch]);
  const handleSubmit = (values) => {
    const toNotCheckFields = ["disciplines", "initialVisit", "allTherapist"];
    let tempValues = { ...values };
    if (!values.disciplines.includes(rateDiscipline.pt)) {
      delete tempValues["physicaltherapypt"];
    }
    if (!values.disciplines.includes(rateDiscipline.ot)) {
      delete tempValues["occupationaltherapyot"];
    }
    if (!values.disciplines.includes(rateDiscipline.slp)) {
      delete tempValues["speechtherapyst"];
    }
    let isValidRate = true;
    let staffingRate = [];
    Object.keys(tempValues)?.map((item) => {
      let rateTabDetail =
        defaultRates &&
        defaultRates.find(
          (value) => renameFormat(value.name) === renameFormat(item)
        );
      tempValues &&
        !toNotCheckFields.includes(item) &&
        Array.isArray(tempValues[item]) &&
        tempValues[item]?.map((rate) => {
          let obj = {
            currencyCode: defaultCountryCode,
          };
          obj["rateTabId"] = rateTabDetail?.id;
          obj["rateTabDetailId"] = rate?.id;
          obj["price"] = rate.rate ? parseInt(rate.rate) : 0;
          if (rate?.primaryId) {
            obj["primaryId"] = rate?.primaryId;
          }
          if (obj["price"] === 0) {
            isValidRate = false;
            setRateError(true);
            setTimeout(() => {
              setRateError(false);
            }, 2000);
          }
          if (rate?.rate) {
            staffingRate.push(obj);
          }
        });
    });
    if (getUserId()?.userId) {
      tempValues.createdBy = getUserId()?.userId;
    }
    const diagnosisDetail = [];
    if (secondaryDignosisData && secondaryDignosisData?.length > 0) {
      secondaryDignosisData?.map((diag) => {
        diagnosisDetail.push({
          masterDataId: diag?.masterDataId,
          types: diag?.types,
        });
      });
    }
    if (tempValues?.primaryDiagnosisId) {
      diagnosisDetail.push({
        masterDataId: values.primaryDiagnosisId.value,
        types: "primarydiagnosis",
      });
    }
    if (diagnosisDetail?.length > 0) {
      tempValues.diagnosisDetail = diagnosisDetail;
    }
    if (staffingRate?.length > 0) {
      tempValues.staffingRate = staffingRate;
    }
    if (selectedName?.label) {
      tempValues.firstName = selectedName?.label;
    }
    if (tempValues.gender) {
      tempValues.gender = values.gender;
    }
    let disciplinesNeeded = {};
    if (values["disciplines"]) {
      values.disciplines.map((ele) => (disciplinesNeeded[ele] = "true"));
    }
    if (values.ptaAllowed && !values.disciplines.includes(rateDiscipline.pt)) {
      tempValues.ptaAllowed = "false";
    }
    if (values.cotaAllowed && !values.disciplines.includes(rateDiscipline.ot)) {
      tempValues.cotaAllowed = "false";
    }
    tempValues.disciplinesNeeded = disciplinesNeeded;
    tempValues.addressType = tempValues?.addressType === "true" ? "yes" : "no";
    const finalInitialArray = [];
    if (values.initialVisit && values.initialVisit.length > 0) {
      values.initialVisit.map((visit) => {
        let VisitObject = {};
        if (SupervisingDisciplines.includes(visit.disc)) {
          VisitObject = {
            ...VisitObject,
            ...visit,
            initialVisitTypeId: visit.initialVisitTypeId.value,
          };
        } else {
          VisitObject.disc = visit.disc;
        }
        if (
          visit.isCaseOpen &&
          openCaseLabels.includes(visit?.initialVisitTypeId?.label)
        ) {
          delete VisitObject.isCaseOpen;
        }
        if (visit?.isCaseOpen === "yes") {
          delete VisitObject.anticipatedSocDate;
        } else if (visit?.isCaseOpen === "no") {
          delete VisitObject.initialVisitDate;
        }
        finalInitialArray.push(VisitObject);
      });
    }
    tempValues.initialVisit = finalInitialArray;

    let formData = new FormData();
    if (!tempValues?.specificTherapistRequested) {
      delete tempValues.allTherapist;
    } else {
      tempValues.allTherapist = values.allTherapist.map((item) => ({
        therapistId: item.therapistId.key,
      }));
    }
    if (!tempValues?.isCaseOpen) {
      delete tempValues.isCaseOpen;
    } else {
      tempValues.isCaseOpen = true;
    }
    if (!tempValues?.initialVisitDate) {
      delete tempValues.initialVisitDate;
    } else if (!tempValues?.anticipatedSocDate) {
      delete tempValues.anticipatedSocDate;
    }
    delete tempValues.disciplines;
    delete tempValues.primaryDiagnosisId;
    delete tempValues.secondarydiagnosisId;
    delete tempValues.occupationaltherapyot;
    delete tempValues.physicaltherapypt;
    delete tempValues.speechtherapyst;
    if (isValidRate) {
      formData.append("files", uploadedImage);
      formData.append("staffingProfileData", JSON.stringify(tempValues));
      if (tempValues) {
        dispatch(addStaffing(formData)).then((res) => {
          if (res && res?.payload && res?.payload?.success) {
            setUploadedImage(null);
            setSecondayDignosisData();
            navigate("/staffing");
            toast.success("Referral created successfully.");
          }
        });
      }
    }
  };

  const formatOptionLabel = ({
    label,
    customAbbreviation,
    value,
    restLabel,
    ...rest
  }) => {
    const isSelected = selectedName && selectedName.value === value;
    return (
      <div style={{ display: "flex" }}>
        {!isSelected && (
          <div style={{ color: "#ccc" }}>{customAbbreviation}</div>
        )}
        <div style={{ marginLeft: "15px" }}>
          {isSelected ? label : `${label} ${restLabel ? restLabel : ""}`}
        </div>
      </div>
    );
  };

  const selectMasterOptions = (type, valuesArray, isExist, index, label) => {
    const typeBaseData = [];
    if (masterDatas?.data?.length > 0) {
      const filteredData = masterDatas.data.filter(
        (item) => item?.types === type
      );
      if (type === "initialvisittype") {
        const label = valuesArray.map(
          (item) =>
            item?.initialVisitTypeId?.label &&
            item?.initialVisitTypeId?.label.split(" ")[0]
        );
        filteredData.forEach((item) => {
          if (!isExist && !label.includes(item.displayName.split(" ")[0])) {
            const valueLabel = { value: item.id, label: item.displayName };
            typeBaseData.push(valueLabel);
          } else if (
            (isExist && isExist === item.displayName.split(" ")[0]) ||
            (isExist && !label.includes(item.displayName.split(" ")[0]))
          ) {
            const valueLabel = { value: item.id, label: item.displayName };
            typeBaseData.push(valueLabel);
          }
        });
      } else {
        filteredData.forEach((item) => {
          const valueLabel = { value: item.id, label: item.displayName };
          typeBaseData.push(valueLabel);
        });
      }
    }
    return typeBaseData;
  };

  const handleChange = (e) => {
    setUploadedImage(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const selectResult = (result, setValue) => {
    if (result) {
      setValue("state", result.state);
      setValue("address", result.address);
      setValue("street", result.street);
      setValue("country", result.country);
      setValue("city", result.city);
      setValue("zip", formatPinCodeValue(result.postal_code));
      setValue("latitude", result.latitude);
      setValue("longitude", result.longitude);
      setValue("addressDetails", result?.addressDetails);
    }
  };

  const renameFormat = (name) => {
    return name
      .replace(/ /g, "")
      .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>/]/gi, "")
      .toLocaleLowerCase();
  };

  const validateForm = (values) => {
    let errors = {};

    if (!values.firstName && !selectedName?.value) {
      errors["firstName"] = "First Name is required.";
    }
    if (!values.lastName) {
      errors["lastName"] = "Last Name is required.";
    }
    if (!values.mrn) {
      errors["mrn"] = "MRN Is Required.";
    } else if (values.mrn.length < 1 || values.mrn.length > 32) {
      errors["mrn"] = "MRN should be between 1 to 32 digits.";
    } else if (!mrnChecking && !isMrnAvailable) {
      errors["mrn"] = "MR# unavailable.";
    }
    if (!values.state) {
      errors["state"] = "State is required.";
    }
    if (!values.address) {
      errors["address"] = "Street Is required.";
    }

    if (!values.city) {
      errors["city"] = "City is required.";
    }

    if (!values.zip) {
      errors["zip"] = "Zip is required.";
    }

    // if (!values.unitApt) {
    //   errors["unitApt"] = "Apt Unit is required";
    // }

    if (!values.dateOfBirth) {
      errors["dateOfBirth"] = "Date of Birth is required.";
    }
    if (!values.gender) {
      errors["gender"] = "Gender is required.";
    }
    if (!values.primaryDiagnosisId) {
      errors["primaryDiagnosisId"] = "Primary Diagnosis is required.";
    }
    if (values?.disciplines && values?.disciplines?.length === 0) {
      errors["disciplines"] = "Disciplines is required.";
    } else if (values?.disciplines && values?.disciplines?.length > 0) {
      setDisErros(false);
    }
    if (values.initialVisit && values.initialVisit.length > 0) {
      const initialVisitErrors = [];
      values.initialVisit.forEach((visit, index) => {
        let visitErrors = {};
        // if (values.specificTherapistRequested && !visit.therapistId?.key) {
        //   visitErrors["therapistId"] = "Please Select A Therapist";
        // }
        if (SupervisingDisciplines.includes(visit.disc)) {
          if (!visit.initialVisitTypeId) {
            visitErrors["initialVisitTypeId"] =
              "Initial Visit Type is required.";
          }
          if (
            !visit.initialVisitDate &&
            (openCaseLabels.includes(visit?.initialVisitTypeId?.label) ||
              visit?.isCaseOpen === "yes")
          ) {
            visitErrors["initialVisitDate"] = "Initial Visit Date is required.";
          }
          if (!visit?.anticipatedSocDate && visit?.isCaseOpen === "no") {
            visitErrors["anticipatedSocDate"] =
              "Anticipated SOC Date is required.";
          }
        }
        initialVisitErrors.push(visitErrors);
      });

      errors["initialVisit"] = initialVisitErrors;
    }

    if (values.allTherapist && values.allTherapist.length > 0) {
      const allTherapistErrors = [];
      values.allTherapist.forEach((visit, index) => {
        let allTherapistObject = {};
        if (values.specificTherapistRequested && !visit.therapistId?.key) {
          allTherapistObject["therapistId"] = "Please select a therapist";
        }
        allTherapistErrors.push(allTherapistObject);
      });
      errors["allTherapist"] = allTherapistErrors;
    }

    defaultRates &&
      defaultRates.map((e) => {
        let rateDetails = JSON.parse(JSON.stringify(e.RateTabDetails));
        const reNameRates = {};
        reNameRates[renameFormat(e.name)] = rateDetails;
        if (
          Object.keys(reNameRates)?.length > 0 &&
          Object.keys(values)?.length > 0
        ) {
          let arr = Object.keys(reNameRates);
          arr &&
            arr?.length > 0 &&
            arr?.map((item) => {
              let itemErrors = [];
              if (values[item] && values[item]?.length > 0) {
                values[item]?.map((ele) => {
                  let error = {};
                  if (ele && ele.rate && !floatRegex.test(ele.rate)) {
                    error["rate"] = "Please enter a USD rate.";
                  }
                  itemErrors.push(error);
                });
                errors[item] = itemErrors;
              } else {
                delete errors[item];
              }
            });
        }
      });

    return errors;
  };

  const debounceCall = debounce((ele, discipline, role) => {
    const obj = {
      name: ele ? ele : "",
      role: role,
      dicip: discipline,
    };
    dispatch(therapistByDiscipline({ obj, discipline })).then((res) =>
      formatTherpistList(discipline, res.payload.data.data)
    );
  }, 400);

  const debounceCallPatient = debounce((ele) => {
    setIssLoading(true);
    if (ele) {
      dispatch(
        userSearchDropDown({
          name: ele,
          role: currentRole.patient,
        })
      )
        .then((res) => {
          setIssLoading(false);
        })
        .catch(() => {
          setIssLoading(false);
        });
    } else {
      setIssLoading(false);
    }
  }, 400);

  const getUserDetails = (open, parmsUrl) => {
    dispatch(getUserDetailsById(parmsUrl)).then((e) => {
      setOpen(open);
    });
  };
  useEffect(() => {
    getUserDetails(
      false,
      `${getUserId()?.userId}?${getUserDetailParams.secondaryEmails}=true&${
        getUserDetailParams.representativeEmails
      }=true`
    );
    therapistList("pt", true);
    return () => {
      setSelectedName(null);
      dispatch(resetPrefillDetails());
    };
  }, []);
  const therapistList = (discipline, isChecked) => {
    if (!isChecked) return;
    const obj = {
      name: "",
      role: currentRole.supervisingtherapist,
      dicip: discipline,
    };
    dispatch(therapistByDiscipline({ obj, discipline })).then((res) =>
      formatTherpistList(discipline, res.payload.data.data)
    );
  };
  const setInitialValue = (event) => {
    let initialvalues = {};
    const disciplineArray = [];
    if (event && Object.keys(event).length > 0) {
      initialvalues = event;
    } else {
      disciplineType[0].name &&
        disciplineType[0].isStaffing === true &&
        disciplineArray.push(disciplineType[0].name);
      defaultRates &&
        currentUser &&
        Promise.all(
          defaultRates.map((e) => {
            let rateDetails = JSON.parse(JSON.stringify(e.RateTabDetails));
            if (currentUser && currentUser.userRates?.length > 0) {
              for (var i = 0; i < rateDetails.length; i++) {
                let rate = currentUser.userRates.find(
                  (a) => a.rateTabDetailId === rateDetails[i].id
                );
                if (rate) {
                  rateDetails[i]["rate"] = rate.price;
                  rateDetails[i]["primaryId"] = rate.id;
                }
              }
            }
            initialvalues[renameFormat(e.name)] = rateDetails;
            initialvalues.ptaAllowed = "false";
            initialvalues.cotaAllowed = "false";
            initialvalues.addressType = "false";
            initialvalues.address = "";
            initialvalues.mrn = "";
            initialvalues.specificTherapistRequested = false;
            initialvalues.disciplines = disciplineArray;
            initialvalues.allTherapist = [{ disc: disciplineArray[0] }];
            initialvalues.initialVisit = [
              {
                disc: disciplineArray[0],
                initialVisitTypeId: "",
                initialVisitDate: "",
              },
            ];
          })
        );
    }

    if (selectedName && selectedName.value && selectedName.isSelected) {
      if (
        PatientDetails &&
        PatientDetails?.Role &&
        PatientDetails?.Role?.key === currentRole.patient
      ) {
        initialvalues.lastName = PatientDetails?.lastName;
        initialvalues.firstName = PatientDetails?.firstName;
        if (PatientDetails && PatientDetails.image) {
          setFile(null);
        }
        if (PatientDetails?.staffingDetails) {
          initialvalues.dateOfBirth =
            PatientDetails?.staffingDetails?.dateOfBirth.split("T0")[0];
          initialvalues.gender = PatientDetails?.staffingDetails?.gender;
          initialvalues.state = PatientDetails?.staffingDetails?.state;
          initialvalues.address = PatientDetails?.staffingDetails?.address;
          initialvalues.street = PatientDetails?.staffingDetails?.street;
          initialvalues.country = PatientDetails?.staffingDetails?.country;
          initialvalues.city = PatientDetails?.staffingDetails?.city;
          initialvalues.zip = PatientDetails?.staffingDetails?.zip;
          initialvalues.latitude = PatientDetails?.staffingDetails?.latitude;
          initialvalues.longitude = PatientDetails?.staffingDetails?.longitude;
          initialvalues.mrn = PatientDetails?.staffingDetails?.mrn;
          if (PatientDetails?.staffingDetails?.mrn) {
            dispatch(setMrnState({ state: true }));
          }
          initialvalues.unitApt = PatientDetails?.staffingDetails?.unitApt;
          initialvalues.otherSpecialRequest =
            PatientDetails?.staffingDetails?.otherSpecialRequest;
        }

        if (PatientDetails?.userRates) {
          defaultRates &&
            Promise.all(
              defaultRates.map((e) => {
                let rateDetails = JSON.parse(JSON.stringify(e.RateTabDetails));
                if (PatientDetails && PatientDetails?.userRates?.length > 0) {
                  for (var i = 0; i < rateDetails.length; i++) {
                    let rate = PatientDetails.userRates.find(
                      (a) => a.rateTabDetailId === rateDetails[i].id
                    );

                    if (rate) {
                      rateDetails[i]["rate"] = rate.price;
                      rateDetails[i]["primaryId"] = rate.id;
                    }
                  }
                }
                initialvalues[renameFormat(e.name)] = rateDetails;
              })
            );
        }
        delete selectedName.isSelected;
      }
    }
    return initialvalues;
  };

  const handlePaitentSelect = (e) => {
    if (e && e?.isSelected) {
      const statusStaff = checkSelectedStaffStatus(e);
      setCaseStatus(statusStaff);
      setOpen(true);
      setCurrentPatient(e);
      setSelectedName(e);
    } else {
      setSelectedName(e);
    }
  };

  const styles = {
    control: (base) => ({
      ...base,
      fontFamily: "Times New Roman",
      fontSize: "16px",
      paddingLeft: "10px",
      minHeight: 46,
      height: 46,
      borderRadius: 4,
      color: "red",
      cursor: "text",
    }),
    menu: (base) => ({
      ...base,
      cursor: "text",
      fontFamily: "Times New Roman",
      fontSize: "16px",
    }),
  };
  const primarystyles = {
    control: (base) => ({
      ...base,
      fontFamily: "Times New Roman",
      fontSize: "16px",
      paddingLeft: "10px",
      minHeight: 46,
      height: 46,
      borderRadius: 4,
      color: "red",
      cursor: "pointer",
    }),
    menu: (base) => ({
      ...base,
      cursor: "pointer",
      fontFamily: "Times New Roman",
      fontSize: "16px",
    }),
  };
  const handlePrimarydignosisModalShow = () => {
    setShowSelectSecondaydignosis(true);
  };

  const removeSecondatDignosis = (daignos) => {
    if (daignos) {
      const matchValue = secondaryDignosisData?.filter(
        (diaData) => diaData?.masterDataId !== parseInt(daignos?.masterDataId)
      );
      setSecondayDignosisData(matchValue);

      if (secondaryDignosisData?.includes(daignos?.masterDataId)) {
        const matchId = daignosisId?.filter(
          (id) => id !== parseInt(daignos?.masterDataId)
        );
        setDaignosisId(matchId);
      } else {
        setDaignosisId([...daignosisId, parseInt(daignos?.masterDataId)]);
      }
    }
  };

  const mrnDuplicate = debounce(({ target: { value } }) => {
    if (value.length >= 1) {
      const data = {
        mrn: value,
      };
      dispatch(checkMrn(data));
    }
  }, 600);

  const handleFieldArray = (form, checked, formFunc, discipline) => {
    const asstDiscipline = { pt: "pta", ot: "cota" };
    const ptaAllowed = form.getState().values?.ptaAllowed;
    const cotaAllowed = form.getState().values?.cotaAllowed;
    if (checked) {
      if (discipline === rateDiscipline.pt && ptaAllowed === "true") {
        formFunc.push("initialVisit", { disc: "pta" });
      } else if (discipline === rateDiscipline.ot && cotaAllowed === "true") {
        formFunc.push("initialVisit", { disc: "cota" });
      }
      formFunc.push("initialVisit", { disc: discipline });
      formFunc.push("allTherapist", { disc: discipline });
    } else {
      const specificArray = form.getState().values?.allTherapist;
      const suptfindIndex = specificArray.findIndex(
        (item) => item.disc === discipline
      );
      if (
        SupervisingDisciplines.includes(discipline) &&
        asstDiscipline[discipline]
      ) {
        const asstFindIndex = specificArray.findIndex(
          (item) => item.disc === asstDiscipline[discipline]
        );
        const asstobject = specificArray.find(
          (item) => item.disc === asstDiscipline[discipline]
        );
        if (asstobject) formFunc.remove("allTherapist", asstFindIndex);
      }
      formFunc.remove("allTherapist", suptfindIndex);
      const specificInitialArray = form.getState().values?.initialVisit;
      const suptInitialfindIndex = specificInitialArray.findIndex(
        (item) => item.disc === discipline
      );
      if (
        SupervisingDisciplines.includes(discipline) &&
        asstDiscipline[discipline]
      ) {
        const asstFindIndex = specificInitialArray.findIndex(
          (item) => item.disc === asstDiscipline[discipline]
        );
        const asstobject = specificInitialArray.find(
          (item) => item.disc === asstDiscipline[discipline]
        );
        if (asstobject) formFunc.remove("initialVisit", asstFindIndex);
      }
      formFunc.remove("initialVisit", suptInitialfindIndex);
    }
  };
  const handleClear = () => {
    // Run your function when the clear icon is clicked
    if (selectedName && !selectedName.__isNew__) setUpdated(!updated);
    // Add your custom logic here
  };
  const ClearIndicator = ({ clearValue, ...props }) => {
    return (
      <div
        onClick={() => {
          handleClear();
          clearValue();
        }}
        style={{ cursor: "pointer" }}
      >
        &#10006;
      </div>
    );
  };

  const checkSelectedStaffStatus = (selectedStaff) => {
    const selected = dropDownData.find((item) => item.id === selectedStaff.key);

    let activeCase = false;
    let discharged = false;
    if (
      selected &&
      selected.staffEpisodes &&
      selected.staffEpisodes.length > 0
    ) {
      for (let index = 0; index < selected.staffEpisodes.length; index++) {
        const element = selected.staffEpisodes[index];
        if (element.Status && element.Status.key === episodeStatus.active) {
          activeCase = true;

          // let findDischarged = element.episodeDocumentations.find(
          //   (visit) => visit.Status.key === defaultCaseStatus.discharged
          // );
          // if (findDischarged) {
          //   discharged = true;
          // }

          break;
        }
      }
    }
    if (selected && selected.staffInitialVisitss) {
      if (
        selected.staffInitialVisitss.Status.key === defaultCaseStatus.discharged
      ) {
        discharged = true;
      }
    }
    return { activeCase, discharged };
  };
  return (
    <div>
      {isLoading && <Loader />}
      <div className="container-fluid pl-5 pr-3">
        <div className="row">
          <div className="col-xl-7 col-lg-8">
            <Form
              validate={validateForm}
              onSubmit={handleSubmit}
              initialValues={useMemo(
                (e) => setInitialValue(e),
                [
                  updated,
                  PatientDetails,
                  dropDownData,
                  currentUser,
                  defaultRates,
                ]
              )}
              mutators={{
                ...arrayMutators,
              }}
              render={({
                handleSubmit,
                values,
                form,
                valid,
                errors,
                form: {
                  mutators: { push, remove },
                },
              }) => (
                <form
                  onSubmit={(e) => {
                    if (!valid)
                      toast.error("Please complete all required fields.");
                    handleSubmit(e);
                  }}
                >
                  <div className="staffNewReferralMain margin-topstaff">
                    <h4 className="New-Staffing-1">Staff New Referral</h4>
                    <div className="d-flex mobile_wrap mobile_justify align-items-center profile_sec">
                      <div>
                        <img
                          className="profile-icon"
                          width={"100px"}
                          src={
                            file
                              ? file
                              : selectedName &&
                                PatientDetails &&
                                PatientDetails?.image &&
                                PatientDetails?.Role?.key === "patient"
                              ? `${apiBasePath}documents/therapist/${PatientDetails?.image}`
                              : Patient
                          }
                          crossOrigin="anonymous"
                          alt="profile-icon"
                        />
                      </div>
                      <div className="mobile-new-reff">
                        <p className="upload-P upload-newreffral">
                          Upload Photo
                        </p>
                        <label
                          className="browse-f browse-f1 "
                          style={{ cursor: "pointer" }}
                        >
                          Browse files... <ImAttachment />
                          <input
                            autoComplete="true"
                            type="file"
                            accept="image/*"
                            onChange={handleChange}
                            hidden
                          />
                        </label>
                      </div>
                    </div>
                    <Row className="justify-content-start align-items-start">
                      <Col md={4} className="margin-top-31">
                        <div className="form-group m-0  select-icon ">
                          <div>
                            <Field name="firstName">
                              {({ input, meta }) => (
                                <>
                                  <label>First Name</label>
                                  <CreateSelect
                                    style={styles}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#B6C4CE",
                                      },
                                    })}
                                    classNamePrefix="patientFirstName"
                                    className="select-icon-input align_txt_input"
                                    placeholder="First Name"
                                    {...input}
                                    onInputChange={(event) =>
                                      debounceCallPatient(event)
                                    }
                                    isLoading={issLoading}
                                    components={{ ClearIndicator }}
                                    isClearable={true}
                                    options={patientdropDownList}
                                    formatOptionLabel={formatOptionLabel}
                                    onChange={(e) => handlePaitentSelect(e)}
                                    value={selectedName}
                                    isValidNewOption={function (name) {
                                      return name ? true : false;
                                    }}
                                  />
                                  {meta.error && meta.touched && (
                                    <p className="alert alert-danger mb-3 ">
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                        </div>
                      </Col>
                      <Col md={4} className="margin-top-31">
                        <div className="form-group m-0 select-icon">
                          <Field name="lastName">
                            {({ input, meta }) => (
                              <>
                                <label>Last Name</label>
                                <input
                                  className="w-100 color_placeholder"
                                  style={{ height: "46px" }}
                                  type="text"
                                  autoComplete="true"
                                  {...input}
                                  placeholder="Last Name"
                                />
                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger mb-3 ">
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                      </Col>

                      <Col md={4} className="margin-top-31">
                        <div className="form-group m-0">
                          <Field name="mrn">
                            {({ input, meta }) => (
                              <>
                                <label>MR#</label>
                                <div className="d-flex align-items-center gap-3">
                                  <input
                                    {...input}
                                    className="color_placeholder"
                                    style={{ height: "46px" }}
                                    type="text"
                                    maxLength="32"
                                    autoComplete="true"
                                    disabled={
                                      !selectedName ||
                                      selectedName.value === "" ||
                                      selectedName.__isNew__ !== undefined
                                        ? false
                                        : true
                                    }
                                    // onKeyPress={(event) => {
                                    //   if (!/[0-9]/.test(event.key)) {
                                    //     event.preventDefault();
                                    //   }
                                    // }}
                                    placeholder="MRN"
                                    onChange={(e) => {
                                      input.onChange(e);
                                      mrnDuplicate(e);
                                    }}
                                    // onKeyDown={(e) => mrnInvalidChar(e)}
                                  />
                                  {/* {meta.modified &&
                                    values.mrn &&
                                    values.mrn.length === 8 ? (
                                    mrnChecking ? (
                                      <div
                                        className="spinner-border position-absolute "
                                        style={{
                                          right: "10px",
                                          width: "26px",
                                          height: "26px",
                                        }}
                                        role="status"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : isMrnAvailable ? (
                                      <div
                                        className="icon_cross_1 position-absolute"
                                        style={{
                                          right: "3%",
                                          width: "26px",
                                          height: "26px",
                                        }}
                                      >
                                        <MdCheckCircle
                                          color="green"
                                          style={{ position: "unset" }}
                                          size={25}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="icon_cross_1 position-absolute"
                                        style={{
                                          right: "3%",
                                          width: "26px",
                                          height: "26px",
                                        }}
                                      >
                                        <MdCancel
                                          color="red"
                                          style={{ position: "unset" }}
                                          size={50}
                                        />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )} */}
                                </div>
                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger mb-3 ">
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                      </Col>

                      <Col md={6} className="margin-top-31">
                        <div className="form-group m-0 select-icon select_color_black">
                          <Field name="dateOfBirth">
                            {({ input, meta }) => (
                              <>
                                <label>Date Of Birth</label>
                                <input
                                  className={`w-100 color_placeholder cursor-pointer  ${
                                    input.value
                                      ? "DatePlaceholder"
                                      : "GrayDatePlaceholder"
                                  }`}
                                  style={{ height: "46px" }}
                                  type="date"
                                  title="Select a date"
                                  {...input}
                                  max={today}
                                  placeholder="DD/MM/YYYY"
                                  autoComplete="true"
                                />
                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger mb-3 ">
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                      </Col>
                      <Col md={6} className="margin-top-31">
                        <div className="form-group m-0 select-icon">
                          <Field name="gender">
                            {({ input, meta, index }) => (
                              <>
                                <label>Gender</label>
                                <Forms.Select
                                  {...input}
                                  style={{ height: "46px" }}
                                  className={`select-icon cursor-pointer ${
                                    input.value && "color-black"
                                  }`}
                                  aria-label="Default select example"
                                  autoComplete="true"
                                >
                                  <option value="">Select</option>
                                  {genderOptions?.map((e, index) => {
                                    return (
                                      <option value={e.value}>{e.label}</option>
                                    );
                                  })}
                                </Forms.Select>

                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger mb-3 ">
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                      </Col>
                      <Col md={6} className="margin-top-31">
                        <Field name="address">
                          {({ input, meta }) => (
                            <div className="d-flex flex-column lable-staff select_color_blk">
                              <label>Street Address</label>
                              <AddressAutocomplete
                                style={{ padding: "0px" }}
                                autoComplete="true"
                                className="New-Staffing-input w-100 ref-height-6  color_placeholder"
                                selectResult={(result) =>
                                  selectResult(result, form.change)
                                }
                                selectedAddress={values?.address}
                              />
                              {meta.error && meta.touched && (
                                <p
                                  className="alert alert-danger mb-3  "
                                  role="alert"
                                >
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          )}
                        </Field>
                      </Col>
                      <Col md={6} className="margin-top-31">
                        <Field name="unitApt">
                          {({ input, meta }) => (
                            <div className="d-flex flex-column lable-staff mobile_wrap ">
                              <label>Unit/Apt #</label>
                              <input
                                style={{ height: "46px" }}
                                type="text"
                                autoComplete="true"
                                className="new-staffral-inputbox mobile_width"
                                {...input}
                                placeholder="Unit/Apt #(if applicable) "
                              />
                              {meta.error && meta.touched && (
                                <p className="alert alert-danger mb-3 w-50 ">
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          )}
                        </Field>
                      </Col>

                      <Col md={6} className="margin-top-31">
                        <div className="d-flex  w-100 gap-4 mobile_wrap">
                          <Field name="city">
                            {({ input, meta }) => (
                              <div className="d-flex flex-column new-reff-mobile-width lable-staff  mobile_width ">
                                <label>City</label>
                                <input
                                  className="new-staffral-inputbox w-100 mobile_width"
                                  {...input}
                                  autoComplete="true"
                                  type="text"
                                  placeholder="City"
                                />
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger mb-3"
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                          <Field name="state">
                            {({ input, meta }) => (
                              <div className="d-flex flex-column  new-reff-mobile-width lable-staff mobile_wrap mobile_width ">
                                <label>State</label>
                                <input
                                  className="new-staffral-inputbox w-100  mobile_width"
                                  {...input}
                                  autoComplete="true"
                                  type="text"
                                  placeholder="State"
                                />
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger mb-3  "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </Col>
                      <Col md={6} className="margin-top-31">
                        <Field classNamed="d-none" name="zip">
                          {({ input, meta }) => (
                            <div className="d-flex flex-column lable-staff   mobile_width">
                              <label>ZIP</label>

                              <input
                                className="new-staffral-inputbox mobile_width color_placeholder"
                                {...input}
                                type="text"
                                placeholder="Zip"
                                onInput={(e) => formatPinCode(e, input)}
                                autoComplete="true"
                              />
                              {meta.error && meta.touched && (
                                <p
                                  className="alert alert-danger mb-3 w-50"
                                  role="alert"
                                >
                                  {meta.error}
                                </p>
                              )}
                            </div>
                          )}
                        </Field>
                      </Col>
                      <div className="d-flex flex-column mt-4 lable-staff  mobile_width ">
                        <h2 className="mt-2 mb-0 h2-primary mb-3">
                          Is this address an assisted living or other healthcare
                          facility?
                        </h2>
                        <div className="d-flex gap-5 ">
                          <div className="radio_btns radio_btns-margin new-reff-radio ref-height m-0">
                            <label
                              className="container-2 align-items-center d-flex padding-left-ref-45 ref-height"
                              style={{ height: "100%" }}
                            >
                              <Field
                                name="addressType"
                                component="input"
                                type="radio"
                                value="true"
                              >
                                {({ input }) => (
                                  <>
                                    <input
                                      {...input}
                                      type="radio"
                                      value="true"
                                      autoComplete="true"
                                    />
                                    <span className="checkmark-2"></span>
                                  </>
                                )}
                              </Field>
                              <div> Yes </div>
                            </label>
                          </div>

                          <div className="radio_btns radio_btns-margin new-reff-radio ref-height m-0">
                            <label
                              className="container-2 align-items-center d-flex padding-left-ref-45 ref-height"
                              style={{ height: "100%" }}
                            >
                              <Field
                                name="addressType"
                                component="input"
                                type="radio"
                                value="false"
                              >
                                {({ input }) => (
                                  <>
                                    <input
                                      {...input}
                                      type="radio"
                                      value="false"
                                      autoComplete="true"
                                    />
                                    <span className="checkmark-2"></span>
                                  </>
                                )}
                              </Field>
                              <div> No </div>
                            </label>
                          </div>
                        </div>
                      </div>

                      <Col md={12}>
                        <Field name="primaryDiagnosisId">
                          {({ input, meta }) => (
                            <>
                              <h2 className=" mt-4 h2-primary">
                                Primary Diagnosis
                              </h2>
                              <Select
                                placeholder={"Search"}
                                styles={primarystyles}
                                {...input}
                                minHeight={46}
                                height={46}
                                autoComplete="true"
                                options={selectMasterOptions(
                                  "primarydiagnosis"
                                )}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,

                                    primary: " #B6C4CE",
                                  },
                                })}
                              />
                              {meta.error && meta.touched && (
                                <p className="alert alert-danger mb-3 ">
                                  {meta.error}
                                </p>
                              )}
                            </>
                          )}
                        </Field>
                      </Col>
                      {values && values?.primaryDiagnosisId && (
                        <Col md={12}>
                          <h2 className="mt-3 h2-primary">
                            Secondary Diagnosis
                          </h2>
                          <Button
                            className="btn-new-referral"
                            autoComplete="true"
                            onClick={() => handlePrimarydignosisModalShow()}
                          >
                            Select Secondary Diagnosis
                          </Button>
                          {/* {(secDiagErrors ||
                            secondaryDignosisData?.length === 0) && (
                              <p className="alert alert-danger mb-3  ">
                                {" "}
                                Please select secondary diagnosis
                              </p>
                            )} */}
                          <div className="row align-items-center flex-wrap gap-3 pl-3">
                            {secondaryDignosisData &&
                              secondaryDignosisData?.length > 0 &&
                              secondaryDignosisData?.map((daigList) => (
                                <button
                                  className="secDignosisList mt-3"
                                  type="button"
                                  autoComplete="true"
                                  key={daigList?.masterDataId}
                                >
                                  <MdCancel
                                    size={25}
                                    on
                                    onClick={() =>
                                      removeSecondatDignosis(daigList)
                                    }
                                  />
                                  {daigList?.name}
                                </button>
                              ))}
                          </div>
                        </Col>
                      )}

                      <div className="row pl-3">
                        <h2 className="mt-5 h2-primary radio-name-mb-3">
                          Disciplines needed
                        </h2>
                        {disciplineType &&
                          disciplineType.map((item, index) => {
                            if (item.isStaffing === true) {
                              return (
                                <Col md={4} key={index}>
                                  <div className="radio_btns new-reff-radio radio_btns-margin ref-height ">
                                    <label className="container-card align-items-center d-flex padding-left-ref ref-height ">
                                      <Field
                                        // key={item?.id}
                                        name="disciplines"
                                        value={`${item.name}`}
                                        component="input"
                                        type="checkbox"
                                      >
                                        {({ input, meta }) => (
                                          <>
                                            <input
                                              {...input}
                                              type="checkbox"
                                              autoComplete="true"
                                              onChange={(e) => {
                                                input.onChange(e);
                                                therapistList(
                                                  item.name,
                                                  e.target.checked
                                                );
                                                handleFieldArray(
                                                  form,
                                                  e.target.checked,
                                                  { push, remove },
                                                  item.name
                                                );
                                              }}
                                              value={`${item.name}`}
                                            />
                                          </>
                                        )}
                                      </Field>
                                      <span className=" checkmark1"></span>
                                      <div>{`${item.name.toUpperCase()}`}</div>
                                    </label>
                                  </div>
                                </Col>
                              );
                            }
                          })}

                        {(disErros || values?.disciplines?.length === 0) && (
                          <p className="alert alert-danger mb-3 ">
                            Disciplines is required
                          </p>
                        )}
                      </div>
                      <div className="row pl-3 radio_btns-margin gap-5">
                        {values.disciplines &&
                          values.disciplines.length > 0 &&
                          values.disciplines.includes(rateDiscipline.pt) && (
                            <Col md={4} className="main_area_row label">
                              <label
                                className="mt-2"
                                autoComplete="true"
                                style={{
                                  fontFamily: "Poppins",
                                  fontWaight: "normal",
                                }}
                              >
                                Is a PTA Allowed?
                              </label>
                              <div className="d-flex gap-5 ">
                                <div className="radio_btns radio_btns-margin new-reff-radio ref-height m-0">
                                  <label
                                    className="container-2 align-items-center d-flex padding-left-ref-45 ref-height"
                                    style={{ height: "100%" }}
                                  >
                                    <Field
                                      name="ptaAllowed"
                                      component="input"
                                      type="radio"
                                      value="true"
                                    >
                                      {({ input }) => (
                                        <>
                                          <input
                                            {...input}
                                            type="radio"
                                            value="true"
                                            autoComplete="true"
                                            onChange={(e) => {
                                              input.onChange(e);
                                              handleFieldArray(
                                                form,
                                                true,
                                                { push, remove },
                                                "pta"
                                              );
                                            }}
                                          />
                                          <span className="checkmark-2"></span>
                                        </>
                                      )}
                                    </Field>
                                    <div> Yes </div>
                                  </label>
                                </div>

                                <div className="radio_btns radio_btns-margin new-reff-radio ref-height m-0">
                                  <label
                                    className="container-2 align-items-center d-flex padding-left-ref-45 ref-height"
                                    style={{ height: "100%" }}
                                  >
                                    <Field
                                      name="ptaAllowed"
                                      component="input"
                                      type="radio"
                                      value="false"
                                    >
                                      {({ input }) => (
                                        <>
                                          <input
                                            {...input}
                                            type="radio"
                                            value="false"
                                            autoComplete="true"
                                            onChange={(e) => {
                                              input.onChange(e);
                                              handleFieldArray(
                                                form,
                                                false,
                                                { push, remove },
                                                "pta"
                                              );
                                            }}
                                          />
                                          <span className="checkmark-2"></span>
                                        </>
                                      )}
                                    </Field>
                                    <div> No </div>
                                  </label>
                                </div>
                              </div>
                            </Col>
                          )}

                        {values.disciplines &&
                          values.disciplines.length > 0 &&
                          values.disciplines.includes(rateDiscipline.ot) && (
                            <Col md={6} className="main_area_row label">
                              <label
                                className="mt-2"
                                style={{
                                  fontFamily: "Poppins",
                                  fontWaight: "normal",
                                }}
                              >
                                Is a COTA Allowed?
                              </label>
                              <div className="d-flex gap-5 ">
                                <div className="radio_btns radio_btns-margin new-reff-radio ref-height m-0">
                                  <label
                                    className="container-2 align-items-center d-flex padding-left-ref-45 ref-height"
                                    style={{ height: "100%" }}
                                  >
                                    <Field
                                      name="cotaAllowed"
                                      component="input"
                                      type="radio"
                                      value="true"
                                    >
                                      {({ input }) => (
                                        <>
                                          <input
                                            {...input}
                                            type="radio"
                                            value="true"
                                            autoComplete="true"
                                            onChange={(e) => {
                                              input.onChange(e);
                                              handleFieldArray(
                                                form,
                                                true,
                                                { push, remove },
                                                "cota"
                                              );
                                            }}
                                          />
                                          <span className="checkmark-2"></span>
                                        </>
                                      )}
                                    </Field>
                                    <div> Yes </div>
                                  </label>
                                </div>

                                <div className="radio_btns radio_btns-margin new-reff-radio ref-height m-0">
                                  <label
                                    className="container-2 align-items-center d-flex padding-left-ref-45 ref-height"
                                    style={{ height: "100%" }}
                                  >
                                    <Field
                                      name="cotaAllowed"
                                      component="input"
                                      type="radio"
                                      value="false"
                                    >
                                      {({ input }) => (
                                        <>
                                          <input
                                            {...input}
                                            type="radio"
                                            autoComplete="true"
                                            onChange={(e) => {
                                              input.onChange(e);
                                              handleFieldArray(
                                                form,
                                                false,
                                                { push, remove },
                                                "cota"
                                              );
                                            }}
                                            value="false"
                                          />
                                          <span className="checkmark-2"></span>
                                        </>
                                      )}
                                    </Field>
                                    <div> No </div>
                                  </label>
                                </div>
                              </div>
                            </Col>
                          )}
                      </div>

                      <h2 className="mt-4 h2-primary radio-name-mb-3">
                        Specific therapist requested?
                      </h2>

                      <div className="d-flex flex-column radio_btns-margin">
                        <div className="d-flex align-item-center">
                          <Col md={3} className="p-0">
                            <div className="radio_btns radio_btns-margin new-reff-radio ref-height m-0">
                              <label
                                className="container-2 align-items-center d-flex padding-left-ref-45 ref-height"
                                style={{ height: "100%" }}
                              >
                                <Field
                                  name="specificTherapistRequested"
                                  component="input"
                                  type="radio"
                                  value={true}
                                >
                                  {({ input }) => (
                                    <>
                                      <input
                                        {...input}
                                        type="radio"
                                        autoComplete="true"
                                        value={true}
                                        onChange={() => input.onChange(true)}
                                      />
                                      <span className="checkmark-2"></span>
                                    </>
                                  )}
                                </Field>
                                <div> Yes </div>
                              </label>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="radio_btns radio_btns-margin new-reff-radio ref-height m-0">
                              <label
                                className="container-2 align-items-center d-flex padding-left-ref-45 ref-height"
                                style={{ height: "100%" }}
                              >
                                <Field
                                  name="specificTherapistRequested"
                                  component="input"
                                  type="radio"
                                  value={false}
                                >
                                  {({ input }) => (
                                    <>
                                      <input
                                        {...input}
                                        type="radio"
                                        autoComplete="true"
                                        value={false}
                                        onChange={() => input.onChange(false)}
                                      />
                                      <span className="checkmark-2"></span>
                                    </>
                                  )}
                                </Field>
                                <div> No </div>
                              </label>
                            </div>
                          </Col>
                        </div>
                      </div>
                      <Row className="pl-3">
                        {values && values.specificTherapistRequested && (
                          <FieldArray name="allTherapist">
                            {({ fields }) =>
                              fields.map(
                                (fieldName, fieldIndex) =>
                                  SupervisingDisciplines.includes(
                                    values.initialVisit[fieldIndex].disc
                                  ) && (
                                    <Col md={4}>
                                      <div className="radio-name-mb-3 main_area_row label">
                                        <Field
                                          name={`${fieldName}.therapistId`}
                                        >
                                          {({ input, meta }) => (
                                            <>
                                              <label className="main_area_row label">
                                                {values.initialVisit[
                                                  fieldIndex
                                                ].disc.toUpperCase()}{" "}
                                                Therapist Name
                                              </label>
                                              <Select
                                                autoComplete="true"
                                                styles={styles}
                                                placeholder={`${values.initialVisit[
                                                  fieldIndex
                                                ].disc.toUpperCase()} Therapist`}
                                                {...input}
                                                theme={(theme) => ({
                                                  ...theme,
                                                  borderRadius: 0,
                                                  colors: {
                                                    ...theme.colors,

                                                    primary: "#B6C4CE",
                                                  },
                                                })}
                                                onInputChange={(event) =>
                                                  debounceCall(
                                                    event,
                                                    values.initialVisit[
                                                      fieldIndex
                                                    ].disc,
                                                    currentRole.supervisingtherapist
                                                  )
                                                }
                                                options={
                                                  therapistdropDownList[
                                                    values.initialVisit[
                                                      fieldIndex
                                                    ].disc
                                                  ]
                                                }
                                                isSearchable={isSearchable}
                                                formatOptionLabel={
                                                  formatOptionLabel
                                                }
                                              />
                                              {meta.error && meta.touched && (
                                                <p className="alert alert-danger mb-3 ">
                                                  {meta.error}
                                                </p>
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </div>
                                    </Col>
                                  )
                              )
                            }
                          </FieldArray>
                        )}
                      </Row>
                      <Row className="pl-3">
                        {values && values.specificTherapistRequested && (
                          <FieldArray name="allTherapist">
                            {({ fields }) =>
                              fields.map(
                                (fieldName, fieldIndex) =>
                                  AssistantDiscipline.includes(
                                    values.initialVisit[fieldIndex].disc
                                  ) && (
                                    <Col md={4}>
                                      <div className="radio-name-mb-3 main_area_row">
                                        <Field
                                          name={`${fieldName}.therapistId`}
                                        >
                                          {({ input, meta }) => (
                                            <>
                                              <label>
                                                {values.initialVisit[
                                                  fieldIndex
                                                ].disc.toUpperCase()}{" "}
                                                Therapist Name
                                              </label>
                                              <Select
                                                styles={styles}
                                                autoComplete="true"
                                                placeholder="Therapist Name"
                                                {...input}
                                                theme={(theme) => ({
                                                  ...theme,
                                                  borderRadius: 0,
                                                  colors: {
                                                    ...theme.colors,

                                                    primary: "#B6C4CE",
                                                  },
                                                })}
                                                onInputChange={(event) =>
                                                  debounceCall(
                                                    event,
                                                    values.initialVisit[
                                                      fieldIndex
                                                    ].disc,
                                                    currentRole.assistanttherapist
                                                  )
                                                }
                                                options={
                                                  therapistdropDownList[
                                                    values.initialVisit[
                                                      fieldIndex
                                                    ].disc
                                                  ]
                                                }
                                                isSearchable={isSearchable}
                                                formatOptionLabel={
                                                  formatOptionLabel
                                                }
                                              />
                                              {meta.error && meta.touched && (
                                                <p className="alert alert-danger mb-3 ">
                                                  {meta.error}
                                                </p>
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </div>
                                    </Col>
                                  )
                              )
                            }
                          </FieldArray>
                        )}
                      </Row>

                      <Col md={12}>
                        <div className="form-group  margin-bar-new-ref">
                          <Field name="otherSpecialRequest">
                            {({ input }) => (
                              <>
                                <label>Other Special Requests</label>
                                {/* <input
                                  style={{ height: 46 }}
                                  type="text"
                                  {...input}
                                  placeholder="Example - Spanish speaking male PT"
                                /> */}
                                <textarea
                                  className="resize-none Special-Requests textarea_black"
                                  type="text"
                                  autoComplete="true"
                                  maxLength={2000}
                                  {...input}
                                  placeholder="Example - Spanish speaking male PT"
                                  name=""
                                  id=""
                                  rows="9"
                                ></textarea>
                              </>
                            )}
                          </Field>
                        </div>
                      </Col>
                      <div className="row margin-bar-new-ref">
                        <div className=" pl-3">
                          <h2 className="h2-primary ">Intial Visit Type</h2>
                        </div>

                        <FieldArray name="initialVisit">
                          {({ fields }) =>
                            fields.map(
                              (fieldName, fieldIndex) =>
                                SupervisingDisciplines.includes(
                                  values.initialVisit[fieldIndex].disc
                                ) && (
                                  <Row className="my-3 main_area_row pl-3">
                                    <Col md={4} className="mb-3">
                                      <div className>
                                        <Field
                                          name={`${fieldName}.initialVisitTypeId`}
                                        >
                                          {({ input, meta }) => (
                                            <>
                                              <label>
                                                {values.initialVisit[
                                                  fieldIndex
                                                ].disc.toUpperCase()}{" "}
                                                Visit Type{" "}
                                              </label>
                                              <Select
                                                styles={primarystyles}
                                                autoComplete="true"
                                                theme={(theme) => ({
                                                  ...theme,
                                                  borderRadius: 0,
                                                  colors: {
                                                    ...theme.colors,

                                                    primary: "#B6C4CE",
                                                  },
                                                })}
                                                placeholder="Initial Visit"
                                                {...input}
                                                options={selectMasterOptions(
                                                  "initialvisittype",
                                                  values?.initialVisit,
                                                  values?.initialVisit[
                                                    fieldIndex
                                                  ].initialVisitTypeId?.label
                                                    ? values?.initialVisit[
                                                        fieldIndex
                                                      ].initialVisitTypeId?.label.split(
                                                        " "
                                                      )[0]
                                                    : false,
                                                  fieldIndex
                                                )}
                                                onInputChange={(ele) => {
                                                  if (
                                                    openCaseLabels.includes(
                                                      values?.initialVisit[
                                                        fieldIndex
                                                      ].initialVisitTypeId
                                                        ?.label
                                                    )
                                                  ) {
                                                    form.change(
                                                      `initialVisit[${fieldIndex}].isCaseOpen`,
                                                      ""
                                                    );
                                                  }
                                                  if (
                                                    values?.isCaseOpen === "no"
                                                  ) {
                                                    form.change(
                                                      `initialVisit[${fieldIndex}].anticipatedSocDate`,
                                                      ""
                                                    );
                                                  } else {
                                                    form.change(
                                                      `initialVisit[${fieldIndex}].initialVisitDate`,
                                                      ""
                                                    );
                                                  }
                                                }}
                                              />
                                              {meta.error && meta.touched && (
                                                <p className="alert alert-danger mb-3 ">
                                                  {meta.error}
                                                </p>
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </div>
                                    </Col>
                                    {values &&
                                      values.initialVisit[fieldIndex]
                                        .initialVisitTypeId && (
                                        <>
                                          {openCaseLabels.includes(
                                            values.initialVisit[fieldIndex]
                                              .initialVisitTypeId?.label
                                          ) && (
                                            <Col md={4} className="mb-3">
                                              <div className="select_color_black">
                                                <Field
                                                  name={`${fieldName}.initialVisitDate`}
                                                >
                                                  {({ input, meta }) => (
                                                    <>
                                                      <label>
                                                        {" "}
                                                        Date Requested{" "}
                                                      </label>
                                                      <input
                                                        className="form-control ref-height-6 color_black"
                                                        style={{ height: 46 }}
                                                        type="date"
                                                        title="Select a date"
                                                        autoComplete="true"
                                                        {...input}
                                                        min={today}
                                                      />
                                                      {meta.error &&
                                                        meta.touched && (
                                                          <p className="alert alert-danger mb-3 ">
                                                            {meta.error}
                                                          </p>
                                                        )}
                                                    </>
                                                  )}
                                                </Field>
                                              </div>
                                            </Col>
                                          )}

                                          {closeCaseLabels.includes(
                                            values.initialVisit[fieldIndex]
                                              .initialVisitTypeId?.label
                                          ) && (
                                            <>
                                              {!values?.initialVisit[fieldIndex]
                                                .isCaseOpen &&
                                                form.change(
                                                  `initialVisit[${fieldIndex}].isCaseOpen`,
                                                  "yes"
                                                )}
                                              <Col md={4} className="  ">
                                                <label>Is the Case Open?</label>
                                                <div
                                                  className="d-flex gap-5  "
                                                  style={{ height: "68px" }}
                                                >
                                                  <div className="radio_btns ">
                                                    <label className="container-2 align-items-center d-flex padding-left-ref-45 ref-height">
                                                      <Field
                                                        name={`${fieldName}.isCaseOpen`}
                                                      >
                                                        {({ input }) => (
                                                          <>
                                                            <input
                                                              type="radio"
                                                              autoComplete="true"
                                                              defaultChecked={
                                                                true
                                                              }
                                                              {...input}
                                                              value="yes"
                                                            />
                                                            <span className="checkmark-2"></span>
                                                          </>
                                                        )}
                                                      </Field>
                                                      <div> Yes </div>

                                                      {values?.initialVisit[
                                                        fieldIndex
                                                      ].isCaseOpen === "no" &&
                                                        form.change(
                                                          `initialVisit[${fieldIndex}].initialVisitDate`,
                                                          ""
                                                        )}
                                                    </label>
                                                  </div>

                                                  <div className="radio_btns ">
                                                    <label className="container-2 align-items-center d-flex padding-left-ref-45 ref-height">
                                                      <Field
                                                        name={`${fieldName}.isCaseOpen`}
                                                      >
                                                        {({ input }) => (
                                                          <>
                                                            <input
                                                              autoComplete="true"
                                                              type="radio"
                                                              {...input}
                                                              value="no"
                                                            />

                                                            <span className="checkmark-2"></span>
                                                          </>
                                                        )}
                                                      </Field>
                                                      <div> No </div>

                                                      {values?.initialVisit[
                                                        fieldIndex
                                                      ].isCaseOpen === "no" &&
                                                        form.change(
                                                          `initialVisit[${fieldIndex}].initialVisitDate`,
                                                          ""
                                                        )}
                                                    </label>
                                                  </div>
                                                </div>
                                              </Col>

                                              {values &&
                                              values?.initialVisit[fieldIndex]
                                                .isCaseOpen === "yes" ? (
                                                <Col md={4}>
                                                  <div className="select_color_black">
                                                    <Field
                                                      name={`${fieldName}.initialVisitDate`}
                                                    >
                                                      {({ input, meta }) => (
                                                        <>
                                                          <label>
                                                            Initial Visit Date
                                                          </label>
                                                          <input
                                                            className={`form-control ref-height-6 cursor-pointer  ${
                                                              input.value
                                                                ? "DatePlaceholder"
                                                                : "GrayDatePlaceholder"
                                                            }`}
                                                            type="date"
                                                            title="Select a date"
                                                            autoComplete="true"
                                                            {...input}
                                                            min={today}
                                                          />
                                                          {meta.error &&
                                                            meta.touched && (
                                                              <p className="alert alert-danger mb-3 ">
                                                                {meta.error}
                                                              </p>
                                                            )}
                                                        </>
                                                      )}
                                                    </Field>
                                                  </div>
                                                </Col>
                                              ) : (
                                                values?.initialVisit[fieldIndex]
                                                  .isCaseOpen === "no" && (
                                                  <Col md={4}>
                                                    <div className="">
                                                      <Field
                                                        name={`${fieldName}.anticipatedSocDate`}
                                                      >
                                                        {({ input, meta }) => (
                                                          <>
                                                            <label>
                                                              Anticipated SOC
                                                              Date
                                                            </label>

                                                            <input
                                                              className={`form-control ref-height-6 cursor-pointer ${
                                                                input.value
                                                                  ? "DatePlaceholder"
                                                                  : "GrayDatePlaceholder"
                                                              }`}
                                                              type="date"
                                                              title="Select a date"
                                                              autoComplete="true"
                                                              {...input}
                                                              min={today}
                                                            />
                                                            {meta.error &&
                                                              meta.touched && (
                                                                <p className="alert alert-danger mb-3 ">
                                                                  {meta.error}
                                                                </p>
                                                              )}
                                                          </>
                                                        )}
                                                      </Field>
                                                    </div>
                                                  </Col>
                                                )
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                  </Row>
                                )
                            )
                          }
                        </FieldArray>
                      </div>

                      <div className="new-reffral-visit">
                        {values.disciplines &&
                          values.disciplines.length > 0 && (
                            <VisitRates
                              defaultRates={defaultRates}
                              values={values}
                              rateError={rateError}
                            />
                          )}
                      </div>
                    </Row>
                  </div>

                  <button
                    type="submit"
                    onClick={() => {
                      setDisErros(true);
                      if (values && values?.primaryDiagnosisId) {
                        // setSecDiagErrors(true);
                      }
                    }}
                    className="login-button mb-4"
                  >
                    <FiRefreshCw
                      style={{
                        marginRight: "8px",
                        padding: "5px",
                        background: "#FFF",
                        borderRadius: "100%",
                        color: "#0F7AD4",
                      }}
                      size={35}
                    />{" "}
                    Submit
                  </button>
                </form>
              )}
            />
          </div>
        </div>

        <NotificationModal
          handleClose={handleClose}
          open={open}
          setOpen={setOpen}
          currentPatient={currentPatient}
          caseStatus={caseStatus}
        />

        {/* <Modal show={open} onHide={handleClose} className="Staffnew-Modal">
          <Container className="staff-container">
            <h2 className="text-staff text-center">
              Do you want to Auto-Fill the Details for <br /> {` `}
              {currentPatient &&
                `${currentPatient.label} ${currentPatient.restLabel}`}{" "}
              ?
            </h2>
            <div className="d-flex gap-3 mt-5">
              <button
                className="yes-btn"
                onClick={(e) => {
                  getUserDetails(
                    false,
                    `${currentPatient.key}?staffing=true&staffingdiagnosis=true`
                  );
                }}
              >
                Yes
              </button>
              <button className="no-btn" onClick={() => setOpen(false)}>
                No{" "}
              </button>
            </div>
          </Container>
        </Modal> */}
      </div>

      <SecondaryDaignosisForm
        setSecondayDignosisData={setSecondayDignosisData}
        secondaryDignosisData={secondaryDignosisData}
        showSelectSecondaydignosis={showSelectSecondaydignosis}
        setShowSelectSecondaydignosis={setShowSelectSecondaydignosis}
        daignosisId={daignosisId}
        setDaignosisId={setDaignosisId}
        setSecDiagErrors={setSecDiagErrors}
      />
    </div>
  );
}

export default StaffNewReferral;
