import React, { memo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import { useSelector } from "react-redux";

const DashboardLayout = () => {
  // const token = localStorage.getItem("token");
  let token  
  if(localStorage.getItem("token")){
token = localStorage.getItem("token")
  }else{
// token = document.cookie.split("=")[1]
document.cookie.split(";").map(item=>{
  if(item.split("=")[0].trim()==="token"){
    token = item.split("=")[1]
  }
})
  };
  const sidebar = useSelector((e) => e.authSlice.sidebar);

  return token ? (
    <>
      <div className="container-fluid ">
        <div className="row">
          <Sidebar />
          <div
            className={!sidebar ? " right_side_parent" : "left_side_parent"}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Navbar />
            <Outlet />
          </div>
        </div>
      </div>

    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default memo(DashboardLayout);
