import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { visitStatus } from '../../utils/helper'
import { useSelector } from 'react-redux';
import { currentRole } from '../../utils/auth';

export default function ConfirmDocument(props) {
    const { allCompleteModal, changeStatus, closeAllCompleteModal } = props
    const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);

    return (
        <div>
            <Modal
                className="Main_modal_1"
                show={allCompleteModal}
                onHide={closeAllCompleteModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header className="pl-4 pr-4 pt-4 pb-3" closeButton>
                    <Modal.Title>Complete</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pl-4 pr-4 pt-0 pb-4">
                    {currentUser && currentUser.Role &&
                        currentUser.Role.key &&
                        (currentUser.Role.key === currentRole.supervisingtherapist || currentUser.Role.key === currentRole.assistanttherapist) ? 
                        "Are you sure you want to mark all documents as need review?" : 
                        " By confirming this you will mark all the documents as completed. Are you sure?"}
                    <div className="mt-4">
                        <Button
                            className="Discard-danger"
                            variant="outline-danger"
                            onClick={closeAllCompleteModal}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" className="primary-confirm" onClick={() => changeStatus(
                            currentUser && currentUser.Role &&
                                currentUser.Role.key &&
                                (currentUser.Role.key === currentRole.supervisingtherapist || currentUser.Role.key === currentRole.assistanttherapist) ? visitStatus.needreview : visitStatus.completed, true)}>
                            Confirm
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
