/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { MdCall } from "react-icons/md";
import StaffAssignmentsActive from "../../images/date.svg";
import StaffAssignments from "../../images/StaffAssignmentsActive.svg";
import PatientProfileActive from "../../images/@Email.svg";
import PatientProfile from "../../images/@emailActive.svg";
import PatientDocumentActive from "../../images/Doc.svg";
import PatientDocument from "../../images/DecActive.svg";
import EpisodesActive from "../../images/home.svg";
import Episodes from "../../images/homeActive.svg";
import LocationIcon from "../../images/newtherapist/location_icon2.svg";
import userImage from "../../images/loginpage.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiBasePath } from "../../Redux/config/config";
import {
  createUserActivityLog,
  getStaffingDetailsById,
} from "../../Redux/actions/staffing";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import {
  patientDetailsTabs,
  staffFormTypesConstants,
  statusBaseColorChange,
  viewCaseStatueName,
  visitStatus,
} from "../../utils/helper";
import allergies from "../../images/allergies_icon.svg";
import { CancelReferralModal } from "../modals/CancelReferralModal";
import CancelApprovalModal from "../modals/CancelApprovalModal";
import {
  currentRole,
  defaultStatus,
  decodeData,
  getUserId,
} from "../../utils/auth";
import PatientForm from "./PatientForm";
import PatientDocTabs from "./PatientDocTabs";
import PatientStaffTab from "./PatientStaffTab";
import PatientEpisodeTab from "./PatientEpisodeTab";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  getEvalForm,
  getPatientDocTypes,
  getUserDocumentsList,
} from "../../Redux/actions/patientGetDocTypes";
import {
  getEpisodeDetails,
  getStaffAssignments,
  getStaffAuth,
  getStaffDocumentation,
  getStafffFrequency,
  getTabActiveStatus,
} from "../../Redux/actions/common";
import { masterData } from "../../Redux/actions/therapist";
import { getStatusList } from "../../Redux/actions/auth";
import callImage from "../../images/patient-call-icon.svg";
import { MyModal2 } from "../modals/MyModal2";
import PdfModal from "./PdfModal";
import { setFrequencyData } from "../../Redux/slices/commonSlice";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import {
  createConversation,
  setActiveConversation,
} from "../../Redux/actions/chat";
const PatientDetails = (props) => {
  const currentUserDetail = useSelector(
    (state) => state.adminSlice.currentActiveUserDetails
  );
  const activeConversation = useSelector(
    (state) => state.chatSlice.activeConversation
  );
  const navigate = useNavigate();
  const { staffUserId } = props;
  const staffTabDetails = useSelector((e) => e.commonSlice.staffTabDetails);
  const [key, setKey] = useState(patientDetailsTabs.patientprofile);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);
  const isLoading = useSelector((e) => e.staffingSlice.isModalLoading);
  const [isUpdated, setIsUpdated] = useState(false);
  const [show, setShow] = useState(false);
  const [reasonText, setReasonText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const currentUser = getUserId()?.role;
  const params = useParams();
  const location = useLocation();
  const closeModal = () => setModalOpen(false);
  const showApprovalModal = () => setModalShow(true);
  const ConversationItems = useSelector(
    (state) => state.chatSlice.conversationPaginator?.items
  );

  const cancelModalHandler = (staffInfo) => {
    dispatch(getStaffingDetailsById(staffInfo.id)).then(() => {
      setModalOpen(true);
    });
  };

  const createConversationFunc = (id) => {
    dispatch(createConversation({ userId: id }));
  };
  const chatbyId = (sid) => {
    const selectedConversation = ConversationItems.find(
      (ele) => ele.sid === sid
    );
    dispatch(setActiveConversation(selectedConversation));
  };

  useEffect(() => {
    let conversationIsAlreadyExist = false;

    if (
      ConversationItems &&
      ConversationItems.length > 0 &&
      ConversationItems.map(({ attributes }) => attributes?.id).includes(
        staffDetail?.id
      )
    ) {
      conversationIsAlreadyExist = true;
    }

    if (staffDetail && ConversationItems && ConversationItems.length > 0) {
      if (!conversationIsAlreadyExist) {
        createConversationFunc(staffDetail?.id);
      }
      const filterConversationList = ConversationItems.filter(
        (item) =>
          item.friendlyName
            .trim()
            .toLowerCase()
            .includes(staffDetail?.staffingDetails?.mrn.trim().toLowerCase()) ||
          (item.attributes &&
            item.attributes.mrn &&
            item.attributes.mrn
              .toString()
              .toLowerCase()
              .includes(staffDetail?.staffingDetails?.mrn.trim().toLowerCase()))
      );
      chatbyId(filterConversationList?.[0]?.sid);
    }
  }, [staffDetail, ConversationItems]);

  const oncloseClick = () => {
    dispatch(
      createUserActivityLog({
        title: "Therapist and Patient",
        patientUserId: staffUserId ? staffUserId : decodeData(params.id),
        mrn: activeConversation?.attributes?.mrn,
        callAttemptStatus: false,
      })
    );
    const AuthorDetail = {
      name: currentUserDetail.name
        ? currentUserDetail.name
        : currentUserDetail.lastName + ", " + currentUserDetail.firstName,
      image: currentUserDetail.image,
      discipline: currentUserDetail.userDetail.discipline,
      id: currentUserDetail.id,
      Role: currentUserDetail.Role,
    };
    setTimeout(() => {
      activeConversation
        ?.prepareMessage()
        .setBody(`${reasonText}`)
        .setAttributes({ subjectType: "other", ...AuthorDetail })
        .build()
        .send()
        .then((messageIndex) => {});
    }, 1000);
    setReasonText("");
    setShow(false);
  };

  const setTabKey = (key) => {
    setKey(key);

    let url = location.pathname;
    let isInclude = false;
    if (location.pathname.includes(patientDetailsTabs.patientdocument)) {
      isInclude = true;
      url = location.pathname.split(patientDetailsTabs.patientdocument)[0];
    } else if (location.pathname.includes(patientDetailsTabs.episodes)) {
      isInclude = true;
      url = location.pathname.split(patientDetailsTabs.episodes)[0];
    } else if (location.pathname.includes(patientDetailsTabs.patientprofile)) {
      isInclude = true;
      url = location.pathname.split(patientDetailsTabs.patientprofile)[0];
    } else if (location.pathname.includes(patientDetailsTabs.staffassignment)) {
      isInclude = true;
      url = location.pathname.split(patientDetailsTabs.staffassignment)[0];
    }
    if (isInclude) {
      navigate(url);
    }
  };

  const hideApprovalModal = () => {
    closeModal();
    setModalShow(false);
  };
  const dispatchApi = {
    patientprofile: () => {
      dispatch(
        getStaffingDetailsById(
          staffUserId ? staffUserId : decodeData(params.id)
        )
      );
    },
    patientdocument: () => {
      dispatch(
        getUserDocumentsList({
          userId: staffUserId ? staffUserId : decodeData(params.id),
        })
      ).then((e) => {
        if (
          e?.payload?.documents?.rows &&
          e.payload.documents.rows.length <= 0
        ) {
          dispatch(getPatientDocTypes());
        }
      });
    },
    episodes: () => {
      dispatch(setFrequencyData(null));
      dispatch(
        getEpisodeDetails({
          staffUserId: staffUserId
            ? Number(staffUserId)
            : decodeData(params.id),
        })
      ).then((e) => {
        if (e.payload.data.rows.length !== 0) {
          dispatch(getStatusList({ type: "staffdocument" }));
          dispatch(
            getStaffAssignments({
              staffUserId: staffUserId
                ? Number(staffUserId)
                : decodeData(params.id),
            })
          );
          dispatch(
            getStaffAuth({
              userId: staffUserId ? Number(staffUserId) : decodeData(params.id),
              episodeId: Number(e.payload.data.rows[0]["id"]),
            })
          );
          dispatch(
            getStafffFrequency({
              episodeId: Number(e.payload.data.rows[0]["id"]),
            })
          );
          dispatch(
            getStaffDocumentation({
              staffUserId: staffUserId
                ? Number(staffUserId)
                : decodeData(params.id),
            })
          ).then((res) => {
            if (
              res &&
              res.payload &&
              res.payload.getEpisodeDocument &&
              res.payload.getEpisodeDocument.rows &&
              res.payload.getEpisodeDocument.rows.length > 0
            ) {
              let documentObject =
                res.payload.getEpisodeDocument.rows[0].episodeDocumentations.find(
                  (item) =>
                    item.episodeVisit.displayName ===
                    staffFormTypesConstants.Evaluation
                );
              if (
                documentObject &&
                documentObject.Status &&
                (documentObject.Status.key === visitStatus.needreview ||
                  documentObject.Status.key === visitStatus.completed)
              )
                dispatch(
                  getEvalForm({
                    episodeDocumentId: documentObject?.id,
                    formTypeName: staffFormTypesConstants.Evaluation,
                  })
                );
            }
          });
        }
      });
    },
    staffassignment: () => {
      dispatch(
        getStaffAssignments({
          staffUserId: staffUserId
            ? Number(staffUserId)
            : decodeData(params.id),
        })
      );
    },
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (reasonText.length < 1) {
      setErrorMessage("Reason field is required.");
    } else {
      setErrorMessage("");
      oncloseClick();
    }
  };

  const checkFilledDetails = () => {
    if (
      staffTabDetails &&
      !staffTabDetails.isStaffingPending &&
      !staffTabDetails.isDocumentPending
    ) {
      return true;
    }
  };

  const handleChange = (event) => {
    setReasonText(event.target.value);
    if (event.target.value.length < 1) {
      setErrorMessage("Reason field is required.");
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    dispatch(masterData({ types: ["staffFormTypes"] }));
  }, []);

  useEffect(() => {
    if (
      params &&
      params.tabs !== patientDetailsTabs.episodes &&
      patientDetailsTabs[params.tabs]
    ) {
      setKey(params.tabs);
    } else if (
      params &&
      params.tabs === patientDetailsTabs.episodes &&
      checkFilledDetails()
    ) {
      setKey(params.tabs);
    }
  }, [staffTabDetails, params]);

  useEffect(() => {
    dispatchApi[key]();
    dispatch(
      getTabActiveStatus({
        userId: staffUserId ? Number(staffUserId) : decodeData(params.id),
      })
    );
  }, [key, isUpdated]);

  return (
    <div className="d-flex flex-column ">
      {isLoading && <Loader />}
      <>
        <div className="d-flex">
          <div className="container-fluid">
            <div className="row bg-white-user">
              <div className="col-lg-4 mobile_flex d-flex gap-4">
                <div className=" d-flex mobile_wrap mobile_justify-start w-100 gap-4">
                  <img
                    className="userimge1"
                    crossOrigin="anonymous"
                    src={
                      staffDetail && staffDetail?.image
                        ? `${apiBasePath}documents/therapist/${staffDetail?.image}`
                        : userImage
                    }
                    alt="img"
                  />
                  <div className="flex-column mobile_wrap mobile_flex mobile_text-center mobile-mb-3">
                    <div className="flex-column mb-3">
                      <h4 className="username-1 mt-3 text-start">
                        {staffDetail &&
                          `${staffDetail.lastName}, ${staffDetail.firstName}`}
                      </h4>
                      {staffDetail?.staffingDetails?.mrn && (
                        <p className="m-0  Patient-lable">
                          MR #{staffDetail?.staffingDetails?.mrn}
                        </p>
                      )}
                    </div>
                    {staffDetail?.staffInitialVisit[0]?.Status?.name ===
                      "Case Open" && (
                      <div
                        className="d-flex mobile_width"
                        role="button"
                        onClick={() => setShow(true)}
                      >
                        <div className="d-flex justify-content-center align-items-center flex-column mr-3">
                          <p className="mb-1 patient_chat-call">
                            <img src={callImage} />
                            Call Patient
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row justify-content-between">
                  <div className="col-lg-6 offset-lg-1 d-flex flex-column ">
                    <div
                      style={{
                        gap: "1.1875rem",
                      }}
                      className="d-flex mt-3 align-items-start mobile_justify-start mobile-margin-top  "
                    >
                      <img src={LocationIcon} alt="" />
                      <div className="flex-column ">
                        <h1 className="userlabele-1">
                          {staffDetail &&
                          staffDetail.staffingDetails &&
                          staffDetail.staffingDetails.addressType === "yes"
                            ? " Facility Address "
                            : "Address"}{" "}
                        </h1>
                        {staffDetail &&
                          staffDetail.staffingDetails &&
                          staffDetail.staffingDetails.unitApt &&
                          `${staffDetail.staffingDetails.unitApt},`}
                        <p className="userdetails-1 m-0">
                          {staffDetail &&
                            staffDetail.staffingDetails &&
                            staffDetail.staffingDetails.address}
                        </p>
                        <p className="userdetails-1">
                          {staffDetail &&
                            staffDetail.staffingDetails &&
                            `${staffDetail.staffingDetails.city}, `}
                          {staffDetail &&
                            staffDetail.staffingDetails &&
                            `${staffDetail.staffingDetails.state}, `}
                          {staffDetail &&
                            staffDetail.staffingDetails &&
                            staffDetail.staffingDetails.zip}
                        </p>
                      </div>
                    </div>
                    {staffDetail &&
                      staffDetail?.staffingDetails &&
                      staffDetail?.staffingDetails?.allergies && (
                        <div
                          style={{
                            marginTop: "calc(42px - 12px)",
                            gap: "1.1875rem",
                          }}
                          className="d-flex mobile_justify-start mt-3 align-items-start"
                        >
                          <img src={allergies} alt="" />
                          <div className="flex-column">
                            <h1 className="userlabele-1 ">Allergies</h1>
                            <p
                              style={{ color: "#D42B2B", fontWeight: "600" }}
                              className="text-capitalize userdetails-1 m-0 text-center"
                            >
                              {staffDetail?.staffingDetails?.allergies}
                            </p>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="col-lg-5 d-flex flex-column mobile-margin-top">
                    <p className="p6 mobile-margin-p-user p-user  text-start mt-2 ">
                      Status
                    </p>
                    <div className="row justify-content-start mobile_wrappar_justify-center gap-3">
                      <button
                        className={`Approvbutton close-open ${statusBaseColorChange(
                          staffDetail?.staffInitialVisit[0]?.Status?.key
                        )}`}
                        disabled
                      >
                        {viewCaseStatueName(
                          staffDetail &&
                            staffDetail?.staffInitialVisit[0] &&
                            staffDetail?.staffInitialVisit[0]?.Status &&
                            staffDetail?.staffInitialVisit[0]?.Status?.key
                        )}
                      </button>
                      {currentUser === currentRole.agency && (
                        <button
                          className="Cancel-red Approvbutton mobile_ml-0  p-3  "
                          onClick={() => cancelModalHandler(staffDetail)}
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <Tabs
            id="controlled-tab-example"
            className=" tabs_unfinish tabs-Disable border-0"
            activeKey={key}
            onSelect={(k) => {
              setTabKey(k);
            }}
          >
            <Tab
              eventKey={patientDetailsTabs.patientprofile}
              title={
                <span className="d-flex  align-items-center gap-3 ">
                  <i
                    className={`icon_email ${
                      staffTabDetails && staffTabDetails.isStaffingPending
                        ? "Bubble"
                        : null
                    }`}
                  >
                    <img
                      src={
                        key === patientDetailsTabs.patientprofile
                          ? PatientProfile
                          : PatientProfileActive
                      }
                      alt=""
                      srcset=""
                    />
                  </i>
                  Patient Profile
                </span>
              }
            >
              <PatientForm
                staffUserId={staffUserId}
                handleClose={props.handleClose}
              />
            </Tab>
            <Tab
              eventKey={patientDetailsTabs.patientdocument}
              title={
                <span className="d-flex  align-items-center gap-3 ">
                  <i
                    className={`icon_email ${
                      staffTabDetails && staffTabDetails.isDocumentPending
                        ? "Bubble"
                        : null
                    }`}
                  >
                    <img
                      src={
                        key === patientDetailsTabs.patientdocument
                          ? PatientDocument
                          : PatientDocumentActive
                      }
                      alt=""
                      srcset=""
                    />
                  </i>{" "}
                  Patient Documents
                </span>
              }
            >
              <PatientDocTabs
                staffUserId={staffUserId}
                handleClose={props.handleClose}
                listRefresh={props.listRefresh}
              />
            </Tab>
            <Tab
              disabled={checkFilledDetails() ? false : true}
              eventKey={patientDetailsTabs.episodes}
              title={
                <span className="d-flex  align-items-center gap-3 ">
                  <i className="icon_email">
                    <img
                      src={
                        key === patientDetailsTabs.episodes
                          ? Episodes
                          : EpisodesActive
                      }
                      alt=""
                      srcset=""
                    />
                  </i>{" "}
                  Episodes
                </span>
              }
            >
              {key === patientDetailsTabs.episodes && (
                <PatientEpisodeTab
                  data={key}
                  staffUserId={props?.staffUserId}
                  IsUpdated={() => setIsUpdated(!isUpdated)}
                />
              )}
            </Tab>
            <Tab
              eventKey={patientDetailsTabs.staffassignment}
              title={
                <span className="d-flex  align-items-center gap-3 ">
                  <i className="icon_email">
                    <img
                      src={
                        key === patientDetailsTabs.staffassignment
                          ? StaffAssignments
                          : StaffAssignmentsActive
                      }
                      alt=""
                      srcset=""
                    />
                  </i>{" "}
                  Staff Assignments
                </span>
              }
            >
              <PatientStaffTab staffUserId={props?.staffUserId} />
            </Tab>
          </Tabs>
        </div>
      </>
      {show && (
        <>
          <Modal
            show={show}
            size="md"
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalHeader className="pl-4 pr-4 pt-4 pb-3" closeButton>
              <ModalTitle>Call Patient</ModalTitle>
            </ModalHeader>
            <ModalBody className="pl-4 pr-4 pt-0 pb-4">
              <p>
                {staffDetail?.staffingDetails?.phoneNo}
                {staffDetail?.staffingDetails?.alternatePhoneNo
                  ? "," + `${staffDetail?.staffingDetails?.alternatePhoneNo}`
                  : null}
              </p>
              <p>were you able to contact patient? </p>
              <form onSubmit={handleSubmit}>
                <div className="flex-column d-flex m-31 ">
                  <label className="new-user-lables">Reason</label>
                  <div>
                    <input
                      className="admin-new-user-input color_placeholder"
                      type="text"
                      value={reasonText}
                      onChange={handleChange}
                      name="reason"
                      placeholder="Reason"
                    />
                    {errorMessage && (
                      <p className="alert alert-danger">{errorMessage}</p>
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <Button
                    className="Discard-danger"
                    variant="outline-danger"
                    type="submit"
                  >
                    No
                  </Button>

                  <Button
                    variant="primary"
                    className="primary-confirm"
                    onClick={() => {
                      setErrorMessage("");
                      dispatch(
                        createUserActivityLog({
                          title: "Therapist and Patient",
                          patientUserId: staffUserId
                            ? staffUserId
                            : decodeData(params.id),
                          mrn: staffDetail?.staffingDetails?.mrn,
                          callAttemptStatus: true,
                        })
                      );
                      setShow(false);
                    }}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   if (valid)
                    //     onSubmit(values, form, visitStatus.needreview);
                    // }}
                  >
                    Yes
                  </Button>
                </div>
              </form>
            </ModalBody>
          </Modal>
        </>
      )}
      {modalOpen && (
        <CancelReferralModal
          show={modalOpen}
          show2={showApprovalModal}
          handleClose={closeModal}
        />
      )}
      {modalShow && (
        <CancelApprovalModal
          show={modalShow}
          handleClose={hideApprovalModal}
          modalClass="cancel-madal"
          isAllDeclined={true}
          from={defaultStatus.unfinishedprofile}
        />
      )}
    </div>
  );
};

export default PatientDetails;
