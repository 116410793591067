import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { axiosInstance } from "../config/axiosInstance";

export const userSearchDropDown = createAsyncThunk(
  "userDropDown/auth/userSearchDropdown",
  async (body) => {
    const data1 = await axiosInstance.post(`auth/userSearchDropdown`, body);
    return data1.data;
  }
);

export const therapistByDiscipline = createAsyncThunk(
  "userDropDown/auth/therapistByDiscipline",
  async ({ obj, discipline }) => {
    const data1 = await axiosInstance.post(`auth/userSearchDropdown`, obj);
    return { discipline: discipline, data: data1.data };
  }
);
export const getTherapistByAgencyId = createAsyncThunk(
  "getTherapistByAgencyId/staffing/therapist-agencyId",
  async (body) => {
    const data1 = await axiosInstance.post(`/staffing/user-block-list`, body)
    return data1.data;
  }
);
export const getAllBlockedTherapist = createAsyncThunk(
  "Agency/AllBlockedUser",
  async (body) => {
    const data1 = await axiosInstance.post(`staffing/getblock-list`, body)
    return data1.data
  }
)

export const blockTherapist = createAsyncThunk(
  "Blocktherapist-by-agency",
  async (body) => {
    const data1 = await axiosInstance.post(`/staffing/addto-block`, body)
    return data1.data
  }
)

export const unblockUser = createAsyncThunk("unblockUser/therapist",
  async (payload) => {
    const data1 = await axiosInstance.post(`/staffing/unblocked-list/${payload.id}`)
    return data1
  }
)
