import Modal from "react-bootstrap/Modal";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { currentRole, messageFormatting } from "../../utils/auth";
import { addToBlockByAdmin } from "../../Redux/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { apiBasePath } from "../../Redux/config/config";
import Agencypng from "../../images/AdminDashboard/agency_icon.png";
import supervising from "../../images/AdminDashboard/supervising_therapist_icon.png";
import Supervisingpng2 from "../../images/usertable03.svg";
import Agencypng1 from "../../images/usertable01.svg";
import assistant2 from "../../images/usertable02.svg";

const BlockModal = (props) => {
  const { rowData } = props;
  const dispatch = useDispatch();
  const userRole = rowData && rowData.blockedUsers.Role.key;
  const isLoading = useSelector((e) => e.adminSlice.isLoading);

  const handleApprove = (id) => {
    dispatch(
      addToBlockByAdmin({
        id: id,
        approve: true,
      })
    ).then((res) => {
      if (res.payload.success) {
        props.handleClose();
        if (props.setTabMenus) {
          props.setTabMenus("blocked");
        }
        toast.success(messageFormatting(res.payload.message));
        if (props.setBlockRowData) props?.setBlockRowData();
        props.setUpdateTab()
      }
    });
  };

  const isShowApproveButton = () => {
    let show = false
    if (rowData &&
      rowData.statusId &&
      rowData.statusId === 22
    ) {
      show = true
    }
    return show
  }

  let myImage = {
    supervisingtherapist: Supervisingpng2,
    agency: Agencypng1,
    assistanttherapist: assistant2,
    patient: assistant2,
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className="Block-modal"
      >
        {isLoading && <Loader />}
        <div className="closeicon_div">
          <GrClose
            onClick={props.handleClose}
            size={20}
            className="closeicon"
          />
        </div>

        <div>
          <div className="d-flex align-items-center gap-4 pl-4 pr-3">

            <div className="profile_image_admin">
              <img
                alt="user data"
                src={
                  rowData.blockedUsers && rowData.blockedUsers.image
                    ? `${apiBasePath}documents/therapist/${rowData.blockedUsers.image}` : myImage[userRole]
                }
                crossOrigin="anonymous"
                className="profile-icon"
                style={{ borderRadius: "50%", objectFit: "cover" }}
              />
            </div>

            <div>
              <h5 className="blocklisth5">
                {userRole === currentRole.agency
                  ? rowData &&
                  rowData.blockedUsers &&
                  `${rowData.blockedUsers.name}`
                  : rowData &&
                  rowData.blockedUsers &&
                  ` ${rowData.blockedUsers.lastName}, ${rowData.blockedUsers.firstName} `}
              </h5>
              <p className="blocklist_p">
                {rowData &&
                  rowData.blockedUsers &&
                  rowData.blockedUsers.Role.title}
              </p>
            </div>
          </div>

          <div className="pl-4 pr-3">
            <h5 className="Reason_h5 mt-5">Reason for Blocking</h5>
            <p className="Reason_p mt-3">
              {rowData && rowData.reason ? `${rowData.reason}` : " No Reason"}
            </p>
            {isShowApproveButton() && (
              <button
                className="blockApprovebtn mb-4"
                onClick={() => handleApprove(rowData.id)}
              >
                <BsFillCheckCircleFill size="34px" className="checkicon" />
                Approve
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BlockModal;
