export const reactSelectOptionCreate = (
  optionArray,
  labelKey,
  valueKey,
  newlyAddedArray
) => {
  let defaultArray = optionArray.map((ele, index) => ({
    label: `${ele[labelKey]}`,
    value: `${ele[valueKey]}`,
  }));
  let newArray = [];
  if (newlyAddedArray && newlyAddedArray.length > 0) {
    newlyAddedArray.forEach((ele, index) => {
      let isDefault = optionArray.find(
        (defaultopt) => defaultopt.name == ele.label
      );
      if (!isDefault) newArray.push(ele);
    });
  }
  return [...defaultArray, ...newArray];
};

export const interventations_PT = [
  { name: "Balance Training" },
  { name: "Fabrication of orthotic device" },
  { name: "Functional mobilities" },
  { name: "Gait Training" },
  { name: "Home Program Establish/Upgrade" },
  { name: "Home Safety" },
  { name: "Management and evaluation of care plan" },
  { name: "Muscle re-education" },
  { name: "Patient/Caregiver/Family Education " },
  { name: "Prosthetic training" },
  { name: "Pulmonary Physical Therapy" },
  { name: "Teach Pt/Pcg: Safe stair climbing skills" },
  { name: "Teach Pt/Pcg: Safe, effective use of adaptive/assist device" },
  { name: "Teach safety precautions" },
  { name: "Therapeutic Exercise" },
  { name: "Transfer Training" },
  { name: "Modalities" },
  { name: "Manual Therapy" },
];

export const interventations_OT = [
  { name: "ADL Training" },
  { name: "Fabrication of adaptive equipment" },
  { name: "Pt/Pcg training on adaptive equipment" },
  { name: "Fine Motor Coordination" },
  { name: "Home Program Establish/Upgrade" },
  { name: "Home Safety " },
  { name: "Muscle Re-education" },
  { name: "Neuro-developmental Training" },
  { name: "Orthotics/Splinting " },
  { name: "Patient/Caregiver/Family Education " },
  { name: "Perceptual Motor Training" },
  { name: "Re-training Pt/Pcg of cognitive, feeding, perceptual skills" },
  { name: "Sensory Treatment" },
  { name: "Teach Pt/Pcg: Home safety precautions" },
  { name: "Teach Pt/Pcg: Safe, effective use of adaptive device" },
  { name: "Teach Pt/Pcg: Tub/shower safely)" },
  { name: "Therapeutic Exercises for UEs" },
];

export const interventations_ST = [
  { name: "Alternate Verbal/Non-Verbal Communication" },
  { name: "Apraxia" },
  { name: "Articulation/Proficiency/Verbal Expression" },
  { name: "Aural Rehabilitation" },
  { name: "Cognitive retraining" },
  { name: "Dysphagia" },
  { name: "Home Program Establish/Upgrade" },
  { name: "Food Texture Recommendations" },
  { name: "Language Disorders" },
  {
    name: "Lip, tongue, facial exercises to improve swallowing / vocal skills",
  },
  { name: "Memory/Problem Solving/Reasoning" },
  { name: "Patient/Caregiver/Family Education" },
  { name: "Pharyngeal/Laryngeal Motor" },
  { name: "Pragmatics" },
  { name: "Safe Swallowing Evaluation" },
  { name: "Voice Disorders" },
];

export const HomeboundEligibility = [
  {
    name: "Has functional or mental deficits impacting ability to leave home ",
  },
  {
    name: "Requires transportation or assistance of another person to leave home ",
  },
  {
    name: "Requires use of a device to leave home: Crutches/Cane/Wheelchair/Walker/Other",
  },
  {
    name: "Has a medical contraindication that prevents patient from leaving home ",
  },
];

export const RehabPotential = [
  { name: "Excellent" },
  { name: "Fair" },
  { name: "Good" },
  { name: "Guarded" },
  { name: "Poor" },
];

export const FunctionalLimitation = [
  { name: "Ambulation" },
  { name: "Amputation" },
  { name: "Bladder incontinence" },
  { name: "Bowel incontinence" },
  { name: "Contracture" },
  { name: "Dyspnea With Min. Exertion" },
  { name: "Endurance" },
  { name: "Hearing" },
  { name: "Legally Blind" },
  { name: "Paralysis" },
  { name: "Speech" },
];

export const DischargePlanning = [
  { name: "Discharge to care of: Self/Caregiver/Physician" },
  { name: "Discharge to facility " },
  {
    name: "Discharge when medical condition stable and patient no longer in need of skilled care",
  },
  { name: "Discharge when patient/team goals met" },
  { name: "No plans to discharge (patient requires ongoing care)" },
];

export const dmeRequests = [
  { name: "None" },
  { name: "Bath bench" },
  { name: "Bedside Commode" },
  { name: "Cane" },
  { name: "Crutches" },
  { name: "Elevated Toilet Seat" },
  { name: "Hospital Bed" },
  { name: "Hoyer lift" },
  { name: "Transfer board" },
  { name: "Front-wheeled Walker" },
  { name: "Four-wheeled Walker/Rollator" },
  { name: "Wheelchair" },
];

export const formOptions = {
  interventations_PT: interventations_PT,
  interventations_OT: interventations_OT,
  interventations_ST: interventations_ST,
  HomeboundEligibility: HomeboundEligibility,
  RehabPotential: RehabPotential,
  FunctionalLimitation: FunctionalLimitation,
  DischargePlanning: DischargePlanning,
  dmeRequests: dmeRequests,
};
