import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import {
  TwoFactorVerify,
  getOtpVerified,
  getResendOtp,
} from "../../Redux/actions/auth";
import { currentRole } from "../../utils/auth";
import { clearAuthMessage } from "../../Redux/slices/authSlice";
import { toast } from "react-toastify";
import Loader from "../Loader";

function LoginOtpVerification(props) {
  const {
    values,
    statusBasedRedirection,
    handleClose,
    form,
    isVerified,
    setIsVerified,
  } = props;
  const dispatch = useDispatch();

  const [otpError, setOtpError] = useState(null);
  const [remainingTime, setRemainingTime] = useState(30);
  const isLoading = useSelector((e) => e.authSlice.isLoading);

  useEffect(() => {
    if (remainingTime > 0) {
      const timer = setTimeout(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [remainingTime]);

  const inputRefArray = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  function handleInputChange(e, i) {
    const value = e.target.value;
    if (value.length === 1 && i < inputRefArray.length - 1) {
      inputRefArray[i + 1].current.focus();
    } else if ((value.length === 0 || !value) && i !== 0) {
      inputRefArray[i - 1].current.focus();
    }
  }

  const setToken = (token, keeplogged) => {
    if (keeplogged) {
      var expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 30);
      var expires = "expires=" + expirationDate.toUTCString();
      var cookieString = `token=${token};${expires};path=/`;
      document.cookie = cookieString;
    } else {
      document.cookie = `token=${token};path=/`;
    }
    window.location.href = `/home`;
    setTimeout(() => {
      dispatch(clearAuthMessage());
    }, 2000);
  };

  const handleOTP = () => {
    let keeplogged = values?.keepMeSignedIn;
    let validOTP = true;
    Array.from({ length: 6 }).map((v, i) => {
      if (!values[`otp${i + 1}`]) validOTP = false;
    });
    const finalOTP =
      values.otp1 +
      values.otp2 +
      values.otp3 +
      values.otp4 +
      values.otp5 +
      values.otp6;
    const data = {};
    data.email = values.email;
    data.otp = finalOTP;
    if (!validOTP) {
      setOtpError("Enter one time password.");
      setTimeout(() => setOtpError(null), 2000);
    }
    if (validOTP)
      dispatch(getOtpVerified(data)).then((response) => {
        if (!response?.payload?.success) {
          if (response && response.payload && !response.payload.otpValid)
            toast.error("Invalid one time password.");
          setTimeout(() => {
            dispatch(clearAuthMessage());
          }, 2000);
          return;
        }
        let user = response.payload.data.user;
        let status = user.Status.key;
        let role = user.Role.key;
        if (
          role === currentRole.supervisingtherapist ||
          role === currentRole.assistanttherapist ||
          role === currentRole.agency
        ) {
          statusBasedRedirection(
            status,
            response.payload.data.token,
            role,
            keeplogged
          );
        } else {
          setToken(response.payload.data.token, keeplogged);
        }
      });
  };

  const handleResend = (values) => {
    if (remainingTime === 0)
      dispatch(getResendOtp({ ...values })).then((response) => {
        if (response?.payload?.success === true) {
          toast.success("One time password has been resent.");
          setRemainingTime(60);
        }
      });
  };

  const TwoFactorVerifyFunc = (verify) => {
    let tempValues = {
      email: values.email,
      password: values.password,
      isVerify: `${verify}`,
    };
    dispatch(TwoFactorVerify(tempValues)).then((response) => {
      if (!response?.payload?.success) {
        return;
      } else if (
        response?.payload?.success &&
        response?.payload?.otpStatus === "pending"
      ) {
        setIsVerified(false);
      }
    });
  };
  const relacedEmail = (email) => {
    if (email) return email.replace(/^.{5}/, "*****");
  };
  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData("text").trim();
    if (pastedText.length !== 6) {
      toast.info("Incorrect one time password.");
      return;
    } else {
      let isNumber = Number(pastedText);
      if (isNumber) {
        let OTPSplit = pastedText.split("");
        OTPSplit.forEach((element, index) => {
          form.change(`otp${index + 1}`, element);
        });
      } else {
        toast.info("Enter valid one time password.");
      }
    }
  };
  function handleKeyDown(e, i) {
    if (e.key === "Enter") {
      const otp = inputRefArray.map((ref) => ref.current.value).join("");
      if (otp.length === inputRefArray.length) {
        handleOTP();
      } else {
        toast.info("Please complete the one time password.");
      }
    }
  }
  return (
    <>
      {isLoading && (
        <div className="otpScreen">
          <Loader />
        </div>
      )}
      <Modal
        show={props.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="otp_validation_modal_body">
          <div className="row">
            <div className="otp_img">
              <img src="/image/opt_icon.svg" />
            </div>
            <div className="col-md-12">
              <h2 className="modal_Verify_title">
                {!isVerified ? "Verify" : "Verification sent"}
              </h2>
              <p className="modal_Verify_description">
                {!isVerified
                  ? `Enter the code we sent to your email ending in ${relacedEmail(
                      values.email
                    )}`
                  : `Please check your email ending in ${relacedEmail(
                      values.email
                    )} for verification code`}
              </p>
            </div>
            {!isVerified && (
              <>
                {inputRefArray &&
                  inputRefArray.map((item, index) => (
                    <div className="otp_validation">
                      <Field name={`otp${index + 1}`}>
                        {({ input, meta }) => (
                          <>
                            <input
                              className={`m-1 w-100 ${input.value && "valid"}`}
                              type="text"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onPaste={handlePaste}
                              maxLength="1"
                              ref={inputRefArray[index]}
                              {...input}
                              onChange={(e) => {
                                input.onChange(e);
                                handleInputChange(e, index);
                              }}
                              onKeyDown={(e) => handleKeyDown(e, index)}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  ))}
                <div className="col-md-3"></div>
                {otpError && (
                  <div className="col-md-12">
                    <p className="alert alert-danger" role="alert">
                      {otpError}
                    </p>
                  </div>
                )}
                <div className="col-md-12">
                  {remainingTime !== 0 && (
                    <p className="text-center">
                      Resend button will be available in {remainingTime} seconds
                    </p>
                  )}
                </div>
                <div className="modal_body_btn">
                  <button
                    onClick={() => {
                      setIsVerified(true);
                      handleClose();
                    }}
                  >
                    Back
                  </button>
                  <button
                    className={`${remainingTime !== 0 && "resend-disable"}`}
                    onClick={() => handleResend(props.values)}
                    disabled={remainingTime !== 0}
                  >
                    Resend Code
                  </button>
                </div>
              </>
            )}

            <div className={`patient-card-div CodeVerificationSec`}>
              {!isVerified ? (
                <>
                  <button
                    type="submit"
                    className="otp-continue-btn"
                    onClick={() => handleOTP(props.values)}
                  >
                    Continue
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="otp-continue-btn"
                    onClick={() => {
                      TwoFactorVerifyFunc(false);
                    }}
                  >
                    Enter Code
                  </button>
                  <div className="modal_body_btn">
                    <button
                      onClick={() => {
                        setIsVerified(true);
                        handleClose();
                      }}
                    >
                      Back
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default LoginOtpVerification;
