import React, { useEffect, useState } from "react";
import { Row, Col } from "bootstrap-4-react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  getStaffingByStatus,
  lastVisitTypeApprovalList,
} from "../../../Redux/actions/staffing";
import { defaultStatus } from "../../../utils/auth";
import { apiBasePath } from "../../../Redux/config/config";
import Loader from "../../../components/Loader";
import CommonTable from "../../../components/common/CommonTable";
import Patient from "../../../images/usertable02.svg";
import {
  statusBaseColorChange,
  viewCaseStatueName,
} from "../../../utils/helper";

const HomeList = (props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((e) => e.authSlice.isHomeLoader);
  const [pageSize] = useState(10);

  let status = [defaultStatus.awaitingagencyapproval];

  useEffect(() => {
    if (props.key == "lastvisittypeapproval") {
      getLastVisitTypeStaffingList({
        pageSize: pageSize,
        pageNo: props?.pageNo,
        status: [defaultStatus.lastvisittypeapproval],
      });
    } else {
      getStaffingList({
        pageSize: pageSize,
        pageNo: props?.pageNo,
        status,
      });
    }
  }, []);
  useEffect(() => {}, []);

  const getStaffingList = (obj) => {
    dispatch(
      getStaffingByStatus({
        pageNo: obj.pageNo,
        pageSize: obj.pageSize,
        status: obj.status,
      })
    );
  };

  const getLastVisitTypeStaffingList = (obj) => {
    dispatch(
      lastVisitTypeApprovalList({
        pageNo: obj.pageNo,
        pageSize: obj.pageSize,
        status: obj.status,
      })
    );
  };

  const overrideColumnName = (colName) => {
    switch (colName) {
      case "firstName":
        return "Patient";
      case "staffingDetails.address":
        return "Address";
      case "disciplines":
        return "Disciplines";
      case "name":
        return "Agency Name";
      case "staffingDetails.disciplinesNeeded":
        return "DISCIPLINES";
      case "Status.name":
        return "Case Status";
      case "staffingDetails.caseStatus.name":
        return "Case Status";
      case "episodeStaff.staffData.firstName":
        return "Patient";
      case "episodeStaff.staffData.staffingDetails.disciplinesNeeded":
        return "DISCIPLINES";
      case "episodeStaff.staffData.staffingDetails.address":
        return "Address";
      case "episodeStaff.staffData.staffInitialVisitss.Status.name":
        return "Case Status";
      case "urgentmessages.address":
        return "Address";
      case "urgentmessages.caseStatus.name":
        return "Case Status";
      case "urgentmessages.disciplinesNeeded":
        return "DISCIPLINES";
      default:
        return colName;
    }
  };

  const renderRowData = (result, col, item) => {
    if (col === "firstName") {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={
              props.myActions.key === "urgentmessages"
                ? result.attributes.image
                  ? `${apiBasePath}documents/therapist/${result.attributes.image}`
                  : Patient
                : result.image
                ? `${apiBasePath}documents/therapist/${result.image}`
                : Patient
            }
            crossOrigin="anonymous"
            className="table-img  "
            style={{ borderRadius: "50%", objectFit: "cover" }}
            alt="img"
          />
          <div>
            <p className="  title-22 m-0">
              {props.myActions.key === "urgentmessages"
                ? result.friendlyName
                : result.lastName + ", " + result.firstName}
            </p>
            {props.myActions.key === "urgentmessages"
              ? result?.attributes.mrn && (
                  <p className="  title-24">MR #{result?.attributes.mrn}</p>
                )
              : result?.staffingDetails?.mrn && (
                  <p className="  title-24">
                    MR #{result?.staffingDetails?.mrn}
                  </p>
                )}
          </div>
        </div>
      );
    }

    if (col === "urgentmessages.address") {
      let otherData = result.attributes.others
        ? JSON.parse(result.attributes.others)
        : null;
      return (
        <>
          <p className="m-0 addres-col">{otherData && otherData["address"]}</p>
          <p className="m-0 addres-col">
            {otherData && otherData["city"]}
            {", "}
            {otherData && otherData["state"]}
            {", "}
            {otherData && otherData["zip"]}
          </p>
        </>
      );
    }
    if (col === "urgentmessages.disciplinesNeeded") {
      let otherData = result.attributes.others
        ? JSON.parse(result.attributes.others)
        : null;

      return (
        <div>
          {otherData &&
          otherData["disciplines"] &&
          otherData["disciplines"].length > 0 ? (
            <p className="m-0  displine-list">
              {otherData["disciplines"].join(",").toUpperCase()}
            </p>
          ) : (
            <p>--</p>
          )}
        </div>
      );
    }

    if (col === "urgentmessages.caseStatus.name") {
      let otherData = result.attributes.others
        ? JSON.parse(result.attributes.others)
        : null;
      return (
        <>
          <p
            className={`m-0 mt-3 mb-3 status-td ${
              otherData &&
              otherData.caseStatus &&
              statusBaseColorChange(otherData.caseStatus.key)
            }`}
          >
            {otherData && otherData.caseStatus
              ? otherData.caseStatus.name
              : "Incomplete"}
          </p>
        </>
      );
    }

    if (col === "staffingDetails.address") {
      return (
        <>
          <p className="m-0 addres-col">
            {result &&
              result.staffingDetails &&
              result.staffingDetails["address"]}
          </p>
          <p className="m-0 addres-col">
            {result && result.staffingDetails && result.staffingDetails["city"]}
            {", "}
            {result &&
              result.staffingDetails &&
              result.staffingDetails["state"]}
            {", "}
            {result && result.staffingDetails && result.staffingDetails["zip"]}
          </p>
        </>
      );
    }

    if (col === "staffingDetails.caseStatus.name") {
      return (
        <>
          <p
            className={`m-0 mt-3 mb-3 status-td ${
              result &&
              result.staffInitialVisit &&
              result.staffInitialVisit.length > 0 &&
              statusBaseColorChange(result?.staffInitialVisit[0]?.Status?.key)
            }`}
          >
            {result?.staffInitialVisit && result?.staffInitialVisit.length > 0
              ? result?.staffInitialVisit[0]?.Status?.name
              : "Incomplete"}
          </p>
        </>
      );
    }

    if (col === "createdByUser.name") {
      return <p>{result.createdByUser["name"]}</p>;
    }

    if (col === "staffingDetails.disciplinesNeeded") {
      return (
        <div>
          {result &&
          result.staffingDetails &&
          result.staffingDetails["disciplinesNeeded"] &&
          Object.keys(
            result &&
              result.staffingDetails &&
              result.staffingDetails["disciplinesNeeded"]
          ).length > 0 ? (
            <p className="m-0  displine-list">
              {Object.keys(result.staffingDetails["disciplinesNeeded"])
                .join(",")
                .toUpperCase()}
            </p>
          ) : (
            <p>--</p>
          )}
        </div>
      );
    }

    if (col === "Status.name") {
      return (
        <p
          className="m-0 mt-3 mb-3"
          style={{
            height: "39px",
            width: "179px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "40px",
          }}
        >
          {result.Status.name}
        </p>
      );
    }
    if (col === "episodeStaff.staffData.firstName") {
      const user = result.episodeStaff?.staffData;
      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={
                user && user.image
                  ? `${apiBasePath}documents/therapist/${user.image}`
                  : Patient
              }
              crossOrigin="anonymous"
              className="table-img  "
              style={{ borderRadius: "50%", objectFit: "cover" }}
              alt="img"
            />
            <div>
              <p className="  title-22 m-0">
                {user?.lastName + ", " + user?.firstName}
              </p>
              {user?.staffingDetails?.mrn && (
                <p className="  title-24">MR #{user?.staffingDetails?.mrn}</p>
              )}
            </div>
          </div>
        </>
      );
    }

    if (col === "episodeStaff.staffData.staffingDetails.disciplinesNeeded") {
      const user = result.episodeStaff?.staffData;
      return (
        <div>
          {user &&
          user.staffingDetails &&
          user.staffingDetails["disciplinesNeeded"] &&
          Object.keys(
            user &&
              user.staffingDetails &&
              user.staffingDetails["disciplinesNeeded"]
          ).length > 0 ? (
            <p className="m-0  displine-list">
              {Object.keys(user.staffingDetails["disciplinesNeeded"])
                .join(",")
                .toUpperCase()}
            </p>
          ) : (
            <p>--</p>
          )}
        </div>
      );
    }
    if (col === "episodeStaff.staffData.staffingDetails.address") {
      const user = result.episodeStaff?.staffData;
      return (
        <>
          <p className="m-0 addres-col">
            {user && user.staffingDetails && user.staffingDetails["address"]}
          </p>
          <p className="m-0 addres-col">
            {user && user.staffingDetails && user.staffingDetails["city"]}
            {", "}
            {user && user.staffingDetails && user.staffingDetails["state"]}
            {", "}
            {user && user.staffingDetails && user.staffingDetails["zip"]}
          </p>
        </>
      );
    }

    if (col === "episodeStaff.staffData.staffInitialVisitss.Status.name") {
      const user = result.episodeStaff?.staffData;
      return (
        <>
          <p
            className={`m-0 mt-3 mb-3 status-td ${
              user &&
              user.staffInitialVisitss &&
              user.staffInitialVisitss.Status &&
              statusBaseColorChange(user.staffInitialVisitss.Status.key)
            }`}
          >
            {user && user.staffInitialVisitss && user.staffInitialVisitss.Status
              ? user.staffInitialVisitss.Status.name
              : "Incomplete"}
          </p>
        </>
      );
    }
    return <p className=" title-24 m-0 mt-3 mb-3">{result[item]}</p>;
  };

  const handlePageClick = (event) => {
    props.setPageNo(event.selected + 1);
    getStaffingList({
      pageNo: event.selected + 1,
      pageSize,
      status: props.tabStatus(props.myActions.key),
    });
  };

  return (
    <div className="container-fluid ">
      <Row>
        <div className="inside_table_tabs">
          <Col lg={12}>
            <div className="table-responsive mt-4 pl-3 pr-3">
              <CommonTable
                css="asbb"
                data={props?.myActions?.data}
                columns={props?.myActions?.columns}
                columnsToHide={props?.myActions?.columnsToHide}
                overrideColumnName={overrideColumnName}
                actions={props?.myActions?.actions}
                component={props?.myActions?.component}
                renderRowData={renderRowData}
                noData={props?.myActions?.noData}
                isLoading={isLoading}
              />
              {isLoading && <Loader />}
            </div>
            {typeof props?.myActions?.count !== "string" &&
              props?.myActions?.count > pageSize &&
              props.myActions?.data?.length > 0 && (
                <div id="container ">
                  <div className="row justify-content-start mt-3  pl-5 ">
                    <div className="pl-5 pr-5">
                      <ReactPaginate
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={Math.ceil(props.myActions?.count / pageSize)}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        forcePage={
                          Math.ceil(props.myActions?.count / pageSize) === 0
                            ? -1
                            : props?.pageNo - 1
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
          </Col>
        </div>
      </Row>
    </div>
  );
};

export default HomeList;
