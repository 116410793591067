import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {
  defaultCountryCode,
  defaultStatus,
  floatRegex,
  therapistSignupSteps,
} from "../../../utils/auth";
import { userRateCreate } from "../../../Redux/actions/therapist";
import Question from "../../../images/forgot-password-btn.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { setTherapistDataGetStep } from "../../../Redux/actions/auth";
import InputGroup from "react-bootstrap/InputGroup";
import BootStrapForm from "react-bootstrap/Form";
import {
  RateTabsName,
  blockInvalidChar,
  rateDiscipline,
} from "../../../utils/helper";
import { Col, Row } from "bootstrap-4-react/lib/components/layout";
import { BsChatLeftText } from "react-icons/bs";
import { IoSyncCircleOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { therapistValidState, triggerUpdateButton } from "../../../Redux/slices/therapistSignupSlice";
import { ProfileValidState } from "../../../Redux/slices/agencySignupSlice";

const TherapistRates = (props) => {
  const [dataValue, setDataValue] = useState(null);
  const [error, setError] = useState(null);
  const PrefillDetails = useSelector(
    (e) => e.therapistSigunpSlice.PrefillDetails
  );
  const [tooltipPosition, setTooltipPosition] = useState(
    getWindowDimensions().width <= 991 ? false : true
  );
  const agencyCheck = useSelector(
    (e) => e.therapistSigunpSlice.checkAgencyValue
  );
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  let defaultRates = useSelector((e) => e.therapistSigunpSlice.defaultRates);

  const dispatch = useDispatch();

  const renameFormat = (name) => {
    return (
      name &&
      name
        .replace(/ /g, "")
        .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>/]/gi, "")
        .toLocaleLowerCase()
    );
  };

  function handleSubmit(valuess) {
    let payload = [];
    let isDiscipline = false;
    if (dataValue || dataValue === 0) {
      isDiscipline = true;
    } else {
      setDataValue(0);
    }
    let selectedValues = defaultRates[dataValue];

    let selectedRates = valuess[renameFormat(selectedValues?.name)];

    let isPriceComplete = true;
    selectedRates &&
      selectedRates?.map((rate) => {
        let obj = {
          userId: PrefillDetails.id,
          currencyCode: defaultCountryCode,
        };
        obj["rateTabId"] = selectedValues.id;
        obj["rateTabDetailId"] = rate.id;
        obj["price"] = rate.rate ? Number(rate.rate) : 0;
        if (obj["price"] === 0) {
          isPriceComplete = false;
        }
        payload.push(obj);
      });
    if (!isPriceComplete) {
      toast.error("Please complete all required fields.");
      setError("Please add all rates for selected type.");
      return;
    }
    let myObj = {
      payload,
      discipline: selectedValues?.name.toLowerCase(),
      userId: PrefillDetails.id,
      user: props.usercurrentRole,
    };
    if (props?.isProfile) {
      myObj["isProfile"] = props?.isProfile;
    }
    if (isDiscipline) {
      dispatch(userRateCreate(myObj)).then((res) => {
        dispatch(setTherapistDataGetStep(therapistSignupSteps[2]));
        if (res) {
          if (res.payload.success) {
            if (props?.isProfile) {
              props.triggerTabChanges()
              toast.success("Rates updated.")
            }
            props.setTabUpdateCheker && props.setTabUpdateCheker();
          }
        }
      });
    }
  }

  const formDataHandler = (value, dataVal) => {
    setDataValue(value);
    if (value === 1) {
      dataVal?.pt?.forEach((ptRate) => {
        if (
          PrefillDetails &&
          PrefillDetails?.userRates[0]?.rateTab?.name?.toLowerCase() ===
          rateDiscipline.pt
        ) {
          PrefillDetails?.userRates?.forEach((item) => {
            if (item?.price < 0) {
              delete ptRate.rate;
            }
          });
        } else {
          delete ptRate.rate;
        }
      });
      dataVal?.slp?.forEach((slpRate) => {
        if (
          PrefillDetails &&
          PrefillDetails?.userRates[0]?.rateTab?.name?.toLowerCase() ===
          rateDiscipline.slp
        ) {
          PrefillDetails?.userRates?.forEach((item) => {
            if (item?.price < 0) {
              delete slpRate.rate;
            }
          });
        } else {
          delete slpRate.rate;
        }
      });
    } else if (value === 0) {
      dataVal?.ot?.forEach((otRate) => {
        if (
          PrefillDetails &&
          PrefillDetails?.userRates[0]?.rateTab?.name?.toLowerCase() ===
          rateDiscipline.ot
        ) {
          PrefillDetails?.userRates?.forEach((item) => {
            if (item?.price < 0) {
              delete otRate.rate;
            }
          });
        } else {
          delete otRate.rate;
        }
      });
      dataVal?.slp?.forEach((slpRate) => {
        if (
          PrefillDetails &&
          PrefillDetails?.userRates[0]?.rateTab?.name?.toLowerCase() ===
          rateDiscipline.slp
        ) {
          PrefillDetails?.userRates?.forEach((item) => {
            if (item?.price < 0) {
              delete slpRate.rate;
            }
          });
        } else {
          delete slpRate.rate;
        }
      });
    } else {
      dataVal?.pt?.forEach((ptRate) => {
        if (
          PrefillDetails &&
          PrefillDetails?.userRates[0]?.rateTab?.name?.toLowerCase() ===
          rateDiscipline.pt
        ) {
          PrefillDetails?.userRates?.forEach((item) => {
            if (item?.price < 0) {
              delete ptRate.rate;
            }
          });
        } else {
          delete ptRate.rate;
        }
      });
      dataVal?.ot?.forEach((otRate) => {
        if (
          PrefillDetails &&
          PrefillDetails?.userRates[0]?.rateTab?.name?.toLowerCase() ===
          rateDiscipline.ot
        ) {
          PrefillDetails?.userRates?.forEach((item) => {
            if (item?.price < 0) {
              delete otRate.rate;
            }
          });
        } else {
          delete otRate.rate;
        }
      });
    }
  };
  const setInitialValue = (event) => {
    if (event && Object.keys(event).length > 0) {
      return event;
    }
    const initialvalues = {};

    defaultRates &&
      Promise.all(
        defaultRates.map((e) => {
          let rateDetails = JSON.parse(JSON.stringify(e.RateTabDetails));
          if (PrefillDetails && PrefillDetails.userRates?.length > 0) {
            for (var i = 0; i < rateDetails.length; i++) {
              let rate = PrefillDetails.userRates.find(
                (a) => a.rateTabDetailId === rateDetails[i].id
              );
              if (rate) {
                rateDetails[i]["rateTabId"] = rate.rateTabId;
                rateDetails[i]["rate"] = rate.price;
              }
            }
          }
          initialvalues[renameFormat(e.name)] = rateDetails;
        })
      );
    return initialvalues;
  };

  const validateForm = (values) => {
    let errors = {};

    let arr = Object.keys(values);
    arr &&
      arr.length > 0 &&
      arr.map((item, i) => {
        let itemErrors = [];
        if (values[item] && values[item].length > 0) {
          values[item].map((ele) => {
            let error = {};
            if (ele && ele.rate && !floatRegex.test(ele.rate)) {
              error["rate"] = "Please enter a USD rate.";
            }
            if (!ele.rate && i === dataValue) {
              error["rate"] = "Please add a Rate for each visit type.";
            }
            if (ele.rate && i === dataValue && ele.rate == 0) {
              error["rate"] = "Rate should be greater than zero.";
            }
            itemErrors.push(error);
          });
          errors[item] = itemErrors;
        } else {
          delete errors[item];
        }
      });
    const hasRateError = errors && errors.pt && errors?.pt.some((error) => error.rate !== undefined);
    dispatch(therapistValidState(hasRateError))
    return errors;
  };

  useEffect(() => {
    if (
      PrefillDetails &&
      PrefillDetails.userRates &&
      PrefillDetails.userRates.length > 0
    ) {
      let findTabIndx =
        defaultRates &&
        defaultRates.findIndex(
          (item) => item.id === PrefillDetails.userRates[0].rateTabId
        );
      setDataValue(findTabIndx);
    }
  }, [PrefillDetails, defaultRates]);

  const rateRejectHandle = (rateName, value, initial) => {
    if (
      PrefillDetails &&
      PrefillDetails.Status.key === defaultStatus.rejected &&
      PrefillDetails.userDetail.rejectedFields &&
      PrefillDetails.userDetail.rejectedFields[rateName] &&
      PrefillDetails.userDetail.rejectedFields[rateName] == value
    ) {
      // return <h6 style={{ color: "#c52525" }}>Rejected</h6>;
      return true;
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    if (windowDimensions.width <= 991) {
      setTooltipPosition(false);
    }

    if (windowDimensions.width > 991) {
      setTooltipPosition(true);
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const isDisciplineRejected = () => {
    let isRejected = false;
    if (
      PrefillDetails &&
      PrefillDetails.Status &&
      PrefillDetails.Status.key === defaultStatus.rejected &&
      PrefillDetails.userDetail.rejectedFields &&
      PrefillDetails.userDetail.rejectedFields["discipline"]
    ) {
      let findTabIndx =
        defaultRates &&
        defaultRates.findIndex(
          (item) =>
            item.name.toLowerCase() ===
            PrefillDetails.userDetail.rejectedFields["discipline"]
        );
      if (dataValue == findTabIndx) {
        isRejected = true;
      } else {
        isRejected = false;
      }
      return isRejected;
    }
  };

  const buttonRef = useRef();
  const triggerUpdateState = useSelector((state) => state.therapistSigunpSlice.triggerUpdateFunction);

  // useEffect(() => {
  //   if (triggerUpdateState) {
  //     buttonRef.current.click();
  //   }
  // }, [triggerUpdateState])

  useEffect(() => {
    if (triggerUpdateState && buttonRef) {
      setTimeout(() => {
        buttonRef.current.click();
      }, 100);
    }
  }, [triggerUpdateState, buttonRef]);

  const validHandleSubmit = () => {
    dispatch(triggerUpdateButton(false))
  }


  return (
    <>
      <div className="container-fluid pl-4 paddingOn-board ">
        <div className="row">
          {!props.isProfile && (
            <div className=" d-flex align-items-center gap-5 mt-4 ">
              <h2 className="cs-heading set-head-th px-0 m-0 p-0">
                Set Your Discipline
              </h2>
              <h4 className="cs-date m-0 p-0 ">
                Step 02/<span className="pd-dt">08</span>
              </h4>
            </div>
          )}

          <div
            className={
              !props.isProfile ? "col-xxl-4 col-xl-8 col-md-10 col-lg-8" : ""
            }
          >
            <Form
              validate={validateForm}
              onSubmit={handleSubmit}
              // initialValues={setInitialValue}
              initialValues={useMemo(
                (e) => setInitialValue(e),
                [PrefillDetails, defaultRates]
              )}
              mutators={{
                ...arrayMutators,
              }}
              render={({ values, handleSubmit, submitting, valid, pristine }) => (
                <form
                  onSubmit={(e) => {
                    if (!valid)
                      toast.error("Please complete all required fields.");
                    handleSubmit(e);
                  }}
                >
                  {props.isProfile && PrefillDetails && PrefillDetails.userRates && props.therapistDefaultRate(values.pt, PrefillDetails.userRates)}
                  {props.isProfile && props.pristineCheck(pristine, setInitialValue)}
                  {!valid && validHandleSubmit()}
                  {PrefillDetails &&
                    PrefillDetails.Status.key === defaultStatus.rejected &&
                    PrefillDetails.userDetail.rejectedFields &&
                    PrefillDetails.userDetail.rejectedFields["discipline"] &&
                    isDisciplineRejected() && (
                      <h6 style={{ color: "#c52525" }}>Rejected</h6>
                    )}
                  <div
                    className={`col-md-5 pb-3 ${props?.isProfile
                      ? "justify-content-between gap-4"
                      : "gap-5"
                      } d-flex  align-items-center  mt-3  pl-0 __fr_grid_col_0__`}
                  >
                    {defaultRates &&
                      defaultRates.map((e, index) => {
                        if (
                          props?.isProfile &&
                          e.displayName.toLowerCase() ===
                          PrefillDetails?.userDetail?.discipline
                        ) {
                          return (
                            <label
                              className="align-items-center d-flex padding-left-ref ref-height pl-0 "
                              key={e.name}
                            >
                              {/* <input
                                type="radio"
                                checked={true}
                                onChange={() => {
                                  formDataHandler(index, values);
                                }}
                              /> */}
                              {/* <span className=" checkmark-2"></span> */}
                              <p className={` ad-activee `}>
                                {RateTabsName[e.displayName]}
                              </p>
                            </label>
                          );
                        } else {
                          if (!props?.isProfile) {
                            return (
                              <label
                                className="container-2 align-items-center d-flex padding-left-ref ref-height "
                                key={e.name}
                              >
                                <input
                                  // className="therapist-inputs "
                                  type="radio"
                                  name="radio"
                                  checked={index === dataValue}
                                  onChange={() => {
                                    formDataHandler(index, values);
                                  }}
                                />
                                <span className=" checkmark-2"></span>
                                <div className="ml-3">{e.displayName}</div>
                              </label>
                            );
                          }
                        }
                      })}
                  </div>

                  {!props.isProfile && dataValue !== null && (
                    <div className=" d-flex align-items-center gap-5">
                      <h2 className="cs-heading set-head-th px-0 mb-2 p-0">
                        Set Your Rates
                      </h2>
                    </div>
                  )}

                  {values &&
                    Object.keys(values).map((item, index) => (
                      <React.Fragment key={`${item}_${index}`}>
                        {" "}
                        <FieldArray name={item} key={`${item}_${index}`}>
                          {({ fields }) =>
                            fields.map((name, ind) => {
                              if (index === dataValue) {
                                return (
                                  <div
                                    className={
                                      !props.isProfile
                                        ? "p-0 col-12"
                                        : "col-lg-8 col-xl-7 col-md-12 p-0"
                                    }
                                    key={`${item}_${index}_${ind}`}
                                  >
                                    <Field name={`${name}.rate`}>
                                      {({ input, meta }) => (
                                        <div className="p-0 d-flex align-items-center gap-3 mobile_wrap justify-content-between ">
                                          <label className="col-md-9 ad-labels mb-3 p-0 lable-1440  text-capitalize rates-labale-2">
                                            {values[item][ind].name}{" "}
                                            {values[item][ind].tooltip && (
                                              <OverlayTrigger
                                                style={{ width: "30px " }}
                                                placement={
                                                  !props.isProfile
                                                    ? `${tooltipPosition
                                                      ? "right"
                                                      : "auto"
                                                    }`
                                                    : "right"
                                                }
                                                delay={{
                                                  show: 250,
                                                  hide: 400,
                                                }}
                                                overlay={
                                                  <Tooltip
                                                    id={`button-tooltip_${ind}`}
                                                    className={
                                                      !props.isProfile
                                                        ? `onBoard-tooltip-style ${tooltipPosition &&
                                                        `onboard-therapist-${ind} `
                                                        } ${PrefillDetails &&
                                                          PrefillDetails?.userDetail &&
                                                          PrefillDetails
                                                            ?.userDetail
                                                            .rejectedFields &&
                                                          PrefillDetails
                                                            ?.userDetail
                                                            .rejectedFields !==
                                                          null &&
                                                          PrefillDetails
                                                            ?.userDetail
                                                            .rejectedFields
                                                            .reasonforRejection
                                                          ? "tooltipReject"
                                                          : "TooltipShow"
                                                        }`
                                                        : `profileMargin-${ind} th-rate-tooltip-${ind}`
                                                    }
                                                    {...props}
                                                  >
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          values[item][ind]
                                                            .tooltip,
                                                      }}
                                                    ></div>
                                                  </Tooltip>
                                                }
                                              >
                                                <img
                                                  src={Question}
                                                  alt=""
                                                  className="ml-2 hover-icon1"
                                                />
                                              </OverlayTrigger>
                                            )}
                                          </label>

                                          <div className=" flex-column mobile_width mb-4 col-md-3 ">
                                            {/* {rateRejectHandle(
                                              `rate_${values[item][ind]?.id}_${values[item][ind]?.rateTabId}`, input.value, meta.initial
                                            )} */}
                                            <InputGroup
                                              className={
                                                rateRejectHandle(
                                                  `rate_${values[item][ind]?.id}_${values[item][ind]?.rateTabId}`,
                                                  input.value,
                                                  meta.initial
                                                )
                                                  ? "mb-3 color-blue rejected_text_div"
                                                  : "mb-3 color-blue rejected_text_border"
                                              }
                                            >
                                              <InputGroup.Text
                                                id="basic-addon1"
                                                className="pr-0 pt-0 pb-0 border-end-0 blue_dollor "
                                                style={{
                                                  background: "none",
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                $
                                              </InputGroup.Text>
                                              <BootStrapForm.Control
                                                className="ad-input input-1440-2 form-control border-start-0 pl-1 input-rate-visit blue_input"
                                                {...input}
                                                aria-describedby="basic-addon1"
                                                type="number"
                                                step="0.01"
                                                min="0"
                                                onKeyDown={(e) =>
                                                  blockInvalidChar(e)
                                                }
                                              />
                                            </InputGroup>
                                            {meta.error && meta.touched && (
                                              <p
                                                className="alert alert-danger top-11 w-100 input-1440-2"
                                                role="alert"
                                              >
                                                {meta.error}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                );
                              }
                            })
                          }
                        </FieldArray>
                      </React.Fragment>
                    ))}
                  {error && (
                    <div className="col-md-6 p-0">
                      <div className="p-0 d-flex gap-5 justify-content-between ">
                        <label className="col-md-7 ad-labels mb-3 p-0 lable-1440  text-capitalize rates-labale-2">
                          <p
                            className="alert alert-danger top-11 input-1440-2"
                            role="alert"
                          >
                            {error}
                          </p>
                        </label>
                      </div>
                    </div>
                  )}
                  {!props.isProfile ? (
                    <div style={{ display: "flex" }} className="mt-3 mb-3">
                      <button className="coverage-btn justify-content-center" type="submit">
                        Save & Continue
                      </button>
                      <button
                        className="ad-btn-back justify-content-center"
                        type="button"
                        onClick={() =>
                          props.goBackFunction(therapistSignupSteps[1])
                        }
                      >
                        Back
                      </button>
                    </div>
                  ) : (
                    <Row>
                      <Col md={12}>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ marginTop: "calc(52px - 40px)" }}
                        >
                          <button
                            ref={buttonRef}
                            className={`update-btn mb-5 ${!agencyCheck ? "opacity-50" : ""}`}
                            // onClick={handleSubmit}
                            type="submit"
                            disabled={submitting || !agencyCheck}
                          >
                            <IoSyncCircleOutline size={35} />
                            Update
                          </button>
                          <div></div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TherapistRates;
