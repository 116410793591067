import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import EvaluationForm from "../Patients/PatientForms/EvaluationForm";
import PhysicianOrder from "../Patients/PatientForms/PhysicianOrder";
import FollowUpForm from "../Patients/PatientForms/FollowUpForm";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import { getStaffFormdataById } from "../../Redux/actions/patientGetDocTypes";
import abc from "../../images/user.png";
import { apiBasePath } from "../../Redux/config/config";
import { dateFormatChange, staffFormTypesConstants } from "../../utils/helper";
import Discharge from "../Patients/PatientForms/Discharge";
import html2canvas from "html2canvas";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import NonVisitDischarge from "../Patients/PatientForms/NonVisitDischarge";

const PrintModal = (props) => {
  const {
    show,
    handleClose,
    visitDetail,
    episodeModalClose,
    updateConfirmDate,
    priorVisitDate,
    formToShow,
    statusHandler,
    staffUserId,
  } = props;

  const dispatch = useDispatch();
  const [showLoader, setLoader] = useState(false);
  const [formState, setFormState] = useState({
    Evaluation: null,
    "Physician Order": null,
    "Re-Assessment": null,
    "Follow Up": null,
    "Regular Discharge": null,
  });
  const htmlForms = [
    {
      name: "Evaluation",
      key: "Evaluation",
      data: (
        <EvaluationForm
          init={formState["Evaluation"]}
          toSave={true}
          priorVisitDate={priorVisitDate}
          visitDetail={visitDetail}
          statusHandler={statusHandler}
          isPrint={true}
        />
      ),
    },
    {
      name: "PhysicianOrder",
      key: "Physician Order",
      data: (
        <PhysicianOrder
          init={formState["Physician Order"]}
          toSave={true}
          priorVisitDate={priorVisitDate}
          visitDetail={visitDetail}
          statusHandler={statusHandler}
          staffUserId={staffUserId}
          isPrint={true}
        />
      ),
    },
    {
      name: "Re-Assessment",
      key: "Re-Assessment",
      data: (
        <EvaluationForm
          init={formState["Re-Assessment"]}
          toSave={true}
          priorVisitDate={priorVisitDate}
          visitDetail={visitDetail}
          statusHandler={statusHandler}
          isAutoFill={true}
          isPrint={true}
        />
      ),
    },
    {
      name: "Follow Up",
      key: "Follow Up",
      data: (
        <FollowUpForm
          init={formState["Follow Up"]}
          toSave={true}
          priorVisitDate={priorVisitDate}
          visitDetail={visitDetail}
          statusHandler={statusHandler}
          isPrint={true}
        />
      ),
    },
    {
      name: "Regular Discharge",
      key: "Regular Discharge",
      data: (
        <Discharge
          init={formState["Regular Discharge"]}
          toSave={true}
          priorVisitDate={priorVisitDate}
          visitDetail={visitDetail}
          statusHandler={statusHandler}
          isPrint={true}
        />
      ),
    },
    {
      name: "NON-VISIT DISCHARGE",
      key: "NON-VISIT DISCHARGE",
      data: (
        <NonVisitDischarge
          init={formState["NON-VISIT DISCHARGE"]}
          toSave={true}
          priorVisitDate={priorVisitDate}
          visitDetail={visitDetail}
          statusHandler={statusHandler}
          isPrint={true}
        />
      ),
    },
  ];
  const visitDetailById = useSelector(
    (data) => data?.patientGetDocTypesSlice?.visitDetail
  );
  const staffDetails = useSelector((state) => state.staffingSlice.staffDetail);
  const savePdf = async () => {
    const contentDiv = document.getElementById("contentToSave");
    let brokenHtmlContent = [];
    let formSections = [];
    if (contentDiv) {
      if (
        formToShow.replace(/ /g, "").toLowerCase() ===
        staffFormTypesConstants.Evaluation.replace(/ /g, "").toLowerCase()
      ) {
        formSections = Array.from(
          contentDiv.querySelectorAll(".evaluation-section")
        );
      }

      if (
        formToShow.replace(/ /g, "").toLowerCase() ===
        staffFormTypesConstants.PhysicianOrder.replace(/ /g, "").toLowerCase()
      ) {
        formSections = Array.from(
          contentDiv.querySelectorAll(".physician-section")
        );
      }
      if (
        formToShow.replace(/ /g, "").toLowerCase() ===
        staffFormTypesConstants.FollowUp.replace(/ /g, "").toLowerCase()
      ) {
        formSections = Array.from(
          contentDiv.querySelectorAll(".followup-section")
        );
      }
      if (
        formToShow.replace(/ /g, "").toLowerCase() ===
        staffFormTypesConstants.RegularDischarge.replace(/ /g, "").toLowerCase()
      ) {
        formSections = Array.from(
          contentDiv.querySelectorAll(".discharge-section")
        );
      }
      if (
        formToShow.replace(/ /g, "").toLowerCase() ===
        staffFormTypesConstants.NONVISITDISCHARGE.replace(
          / /g,
          ""
        ).toLowerCase()
      ) {
        formSections = Array.from(
          contentDiv.querySelectorAll(".non-visit-section")
        );
      }
      brokenHtmlContent = formSections;

      if (brokenHtmlContent.length > 0) {
        const doc = new jsPDF("pt", "mm", "a4", true);
        for (var ihtml = 0; ihtml < brokenHtmlContent.length; ihtml++) {
          if (!document.getElementById("appendPrintHtml")) {
            return;
          }
          document.getElementById("appendPrintHtml").innerHTML = "";
          let elements = brokenHtmlContent[ihtml];
          if (ihtml === 0) {
            const header = document
              .getElementById("pdf-header")
              .cloneNode(true);
            const formName = document
              .getElementById("form-name-section")
              .cloneNode(true);
            document.getElementById("appendPrintHtml").appendChild(header);
            document.getElementById("appendPrintHtml").appendChild(formName);
            document
              .getElementById("appendPrintHtml")
              .appendChild(elements.cloneNode(true));
            elements = document.getElementById("appendPrintHtml");
          }
          let canvas = await html2canvas(elements, {
            logging: true,
            allowTaint: false,
            useCORS: true,
            scale: 2,
          });
          const imgData = canvas.toDataURL("image/png");

          const imgWidth = 190;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          doc.addImage(imgData, "PNG", 10, 2, imgWidth, imgHeight + 25);
          if (ihtml < brokenHtmlContent.length - 1) {
            doc.addPage();
          }
        }
        doc.save("patientDocuments.pdf");
        setLoader(false);
        handleClose();
      } else {
        setLoader(false);
        handleClose();
        toast.error("Pdf Content not found");
      }
    }
  };

  useEffect(() => {
    if (
      formToShow &&
      visitDetailById &&
      visitDetailById.patientFormList &&
      visitDetailById.patientFormList.length > 0
    ) {
      let formDetail = visitDetailById.patientFormList.find(
        (ele) => ele.name === formToShow
      );
      dispatch(
        getStaffFormdataById({ episodeDocumentFormId: Number(formDetail.id) })
      ).then((res) => {
        if (res.payload && res.payload.data && res.payload.data.formData) {
          setFormState((prev) => ({
            ...prev,
            [formDetail.name]: res.payload.data.formData,
          }));
          setLoader(true);
          setTimeout(() => savePdf(), 2000);
        }
      });
    }
  }, [visitDetailById]);

  const bindPrintForm = () => {
    if (
      formToShow &&
      visitDetailById &&
      visitDetailById.patientFormList &&
      visitDetailById.patientFormList.length > 0
    ) {
      let formDetail = visitDetailById.patientFormList.find(
        (ele) => ele.name === formToShow
      );

      const activeForm = htmlForms.find((item) => item.key === formDetail.name);

      return (
        <div className="container-fluid" style={{ marginTop: "15px" }}>
          <div className="row bg-gray-main" id="form-name-section">
            <h6 className="Document_list text-align-center">
              {activeForm && activeForm.name}
            </h6>
          </div>

          {activeForm && activeForm.data}
        </div>
      );
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose()}
        backdrop="static"
        className="ApprovM"
        centered
      >
        {showLoader && <Loader />}
        <Modal.Header
          className="pl-4 pr-4 pt-4 pb-3"
          closeButton
        ></Modal.Header>
        <Modal.Body className="pl-4 pr-4 pt-0 pb-4">
          <div className="user-row-bar">
            <div
              id="contentToSave"
              className="container"
              style={{ marginTop: "20px" }}
            >
              {" "}
              <div id="pdf-header">
                <div class="row">
                  <div class="col-lg-2 mb-3">
                    <div className="patient-doc-img">
                      <img
                        className="userimge1 one"
                        crossOrigin="anonymous"
                        src={
                          staffDetails && staffDetails.image
                            ? `${apiBasePath}documents/therapist/${staffDetails.image}`
                            : abc
                        }
                        alt="img"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <div className="patient-details next-modal">
                      <div className="patient-genreal pt-3">
                        <h4>
                          {staffDetails?.lastName +
                            " , " +
                            staffDetails?.firstName}
                        </h4>
                        <p>
                          MR #
                          {staffDetails &&
                            staffDetails.staffingDetails &&
                            staffDetails.staffingDetails.mrn}
                        </p>
                      </div>
                      <div className="patient-report">
                        <div className="patient-report-details">
                          <p>{"VISIT \u00A0  DATE"}`</p>

                          <p>
                            {staffDetails &&
                              staffDetails.staffInitialVisit &&
                              staffDetails.staffInitialVisit.length > 0 &&
                              staffDetails.staffInitialVisit[0]
                                .anticipatedSocDate &&
                              dateFormatChange(
                                staffDetails.staffInitialVisit[0].anticipatedSocDate.split(
                                  "T"
                                )[0]
                              )}
                          </p>
                        </div>
                        <div className="patient-report-details">
                          <p>{`VISIT \u00A0  TYPE`}</p>
                          <p>
                            {" "}
                            {visitDetail &&
                              visitDetail.episodeVisit &&
                              visitDetail.episodeVisit.displayName}
                          </p>
                        </div>
                        <div className="patient-report-details">
                          <div>
                            <p>{`THERAPIST \u00A0 NAME`}</p>
                            <p>
                              {visitDetail && visitDetail.userData
                                ? `${visitDetail?.userData?.lastName}, ${visitDetail?.userData?.firstName}`
                                : ""}
                              (PT)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 mb-3"></div>
                </div>
              </div>
              {bindPrintForm()}
            </div>
            <div id="appendPrintHtml"></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PrintModal;
