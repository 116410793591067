import React from "react";
import { Field, Form } from "react-final-form";
import {
  getStaffAuth,
  getStaffDocumentation,
  getStafffFrequency,
  updateAuthorisation,
} from "../../Redux/actions/common";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { updateAuthorisedVisits } from "../../Redux/actions/patientGetDocTypes";
import { useParams } from "react-router-dom";

const AddAuthorisation = (props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((e) => e.commonSlice.isLoading);
  const staffDetail = useSelector((e) => e.staffingSlice.staffDetail);
  const params = useParams();

  const evaluationFormData = useSelector(
    (e) => e.commonSlice.evaluationFormData
  );
  const disciplinesNeeded = Object.keys(
    staffDetail?.staffingDetails?.disciplinesNeeded
  )[0];

  function handleDateFocus(event) {
    event.target.type = "date";
  }

  function handleDateBlur(event) {
    if (!event.target.value) {
      event.target.type = "text";
    }
  }

  const handleSubmit = (values) => {
    let payload = {};
    payload.userId = Number(props.userId);
    payload.episodeId = Number(props.episodeDetails.rows[0]["id"]);
    if (values.authorisedVisits && values.authorisedVisits.value === "noAuth") {
      payload.noAuth = true;
    } else {
      payload.visit = values.authorisedVisits
        ? Number(values.authorisedVisits.value)
        : 0;
      payload.startDate = values.authStartDate;
      payload.endDate = values.authEndDate;
      payload.discipline = disciplinesNeeded.toLocaleUpperCase();
      payload.mrn = Number(staffDetail?.staffingDetails?.mrn);
      payload.staffUserId = Number(props.userId);
    }
    payload.totalVisit = Number(props.totalVisit);
    if (props.initial) {
      let editAuth = {
        currentVisit: values.authorisedVisits
          ? Number(values.authorisedVisits.value)
          : 0,
        episodeId: Number(props.episodeDetails.rows[0]["id"]),
        staffUserId: Number(props.userId),
        startDate: values.authStartDate,
        endDate: values.authEndDate,
        discipline: disciplinesNeeded.toLocaleUpperCase(),
        mrn: Number(staffDetail?.staffingDetails?.mrn),
      };
      dispatch(updateAuthorisedVisits(editAuth)).then((res) => {
        if (res && res.payload && res.payload.success) {
          dispatch(
            getStaffAuth({
              userId: Number(props.userId),
              episodeId: Number(props.episodeDetails.rows[0]["id"]),
            })
          );

          dispatch(
            getStafffFrequency({
              episodeId: Number(props.episodeDetails.rows[0]["id"]),
            })
          );
          dispatch(
            getStaffDocumentation({
              staffUserId: Number(props.userId),
            })
          );

          props.handleClose();
        }
      });
    } else {
      if (!evaluationFormData && !evaluationFormData?.formData?.Frequency1) {
        delete payload?.totalVisit;
      }
      dispatch(updateAuthorisation(payload)).then((e) => {
        if (e.payload.success) {
          dispatch(
            getStaffAuth({
              userId: Number(props.userId),
              episodeId: Number(props.episodeDetails.rows[0]["id"]),
            })
          );

          dispatch(
            getStafffFrequency({
              episodeId: Number(props.episodeDetails.rows[0]["id"]),
            })
          );
          dispatch(
            getStaffDocumentation({
              staffUserId: Number(props.userId),
            })
          );

          props.handleClose();
        }
      });
    }
  };

  const validateForm = (values) => {
    let errors = {};

    if (!values.authorisedVisits) {
      errors.authorisedVisits = "Authorized visits required.";
    }
    if (
      values.authorisedVisits &&
      values.authorisedVisits.value !== "noAuth" &&
      !values.authStartDate
    ) {
      errors.authStartDate = "Auth start date required.";
    }
    if (
      values.authorisedVisits &&
      values.authorisedVisits.value !== "noAuth" &&
      !values.authEndDate
    ) {
      errors.authEndDate = "Auth end date required.";
    }
    return errors;
  };

  const visits = (i) => {
    let array = [];
    for (let x = 1; x <= i; x++) {
      array.push(x);
    }
    return array;
  };

  const authorizationOptions = () => {
    let optionArray = [];
    if (
      evaluationFormData &&
      evaluationFormData.formData &&
      evaluationFormData.formData.Frequency1
    ) {
      if (!props.initial) {
        optionArray.push({
          value: "noAuth",
          label: "No Auth Required",
        });
      }

      for (let index = 0; index < Number(props.totalVisit); index++) {
        optionArray.push({
          value: index + 1,
          label: index + 1,
        });
      }
    } else {
      for (let index = 0; index < Number(16); index++) {
        optionArray.push({
          value: index + 1,
          label: index + 1,
        });
      }
    }

    return optionArray;
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
    }),
  };
  return (
    <>
      {isLoading && <Loader />}
      <Form
        validate={(e) => validateForm(e)}
        initialValues={
          props.initial
            ? {
                authorisedVisits: {
                  label: props.initial.visit,
                  value: props.initial.visit,
                },
                authStartDate: props.initial.startDate,
                authEndDate: props.initial.endDate,
              }
            : {}
        }
        onSubmit={handleSubmit}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <div className="AddAuthorisationBody">
              <h1 className="Heading">
                {props.initial ? "Edit " : "Add"} Authorization
              </h1>
              <div>
                <div className="mb-3 d-flex flex-column">
                  <Field name="authorisedVisits">
                    {({ input, meta }) => (
                      <div className=" mobile_width">
                        {/* <select
                          className="form-control AuthorizationsTabs-inputs w-100"
                          {...input}
                        >
                          <option value="">Select Visit Number</option>
                          {!props.initial && (
                            <option value="noAuth">No Auth Required</option>
                          )}
                          {evaluationFormData &&
                          evaluationFormData?.formData?.Frequency1
                            ? Array.from(
                                { length: props.totalVisit },
                                (v, i) => {
                                  return <option value={i + 1}>{i + 1}</option>;
                                }
                              )
                            : visits(16).map((item, index) => {
                                return <option>{item}</option>;
                              })}
                        </select> */}
                        <Select
                          {...input}
                          classNamePrefix={"AuthorizationSelect"}
                          options={authorizationOptions()}
                          styles={customStyles}
                          placeholder="Select Visit Number"
                        />
                        {meta.error && meta.touched && (
                          <p className="alert alert-danger mb-3 ">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="mb-3 d-flex flex-column">
                  <Field name="authStartDate" type="date">
                    {({ input, meta }) => (
                      <div className=" mobile_width">
                        <input
                          className="AuthorizationsTabs-inputs w-100"
                          placeholder="Auth Start Date"
                          {...input}
                          // onFocus={handleDateFocus}
                          // onBlur={handleDateBlur}
                          type="date"
                        />
                        {meta.error && meta.touched && (
                          <p className="alert alert-danger mb-3 ">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className=" d-flex flex-column">
                  <Field name="authEndDate">
                    {({ input, meta }) => (
                      <div className=" mobile_width">
                        <input
                          className="AuthorizationsTabs-inputs w-100"
                          placeholder="Auth End Date"
                          {...input}
                          onFocus={handleDateFocus}
                          onBlur={handleDateBlur}
                          type="date"
                        />
                        {meta.error && meta.touched && (
                          <p className="alert alert-danger mb-3 ">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="common-btns-div">
                  <button
                    type="button"
                    className="cancel-btns"
                    onClick={() => props.handleClose()}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="Approve-btns"
                    // disabled={!props.totalVisit}
                  >
                    Confirm
                    {/* {props.initial ? "Confirm " : "Add Authorization"} */}
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default AddAuthorisation;
