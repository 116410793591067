import React from "react";

const PlatformAccessAgreement = () => {
  return (
    <>
      <div className="py-5">
        <div className="container text-justify">
          <div class="headindSection Connexion_docm text-center my-5">
            <h1 class="mb-0">The Care Connexion, Inc. </h1>
            <h1> Platform Access Agreement</h1>
          </div>
          <div>
            <p>
              This The Care Connexion, Inc. (“TCC”) Platform Access Agreement
              (this “PAA”) is by and among you, a self-employed individual,
              business, or independent contractor who is a licensed healthcare
              provider(“you”) and The Care Connexion, Inc.,, a technology
              company that provides a technology platform where Home Healthcare
              Agencies (“HHAs”) and self-employed individuals, businesses, or
              independent contractor healthcare service providers, such as
              yourself, to find one another for the purposes of engaging in a
              business to business arrangement whereby the two may contract for
              the provision of therapeutic services (collectively, “Therapeutic
              Services”) needed by the home healthcare agency. TCC Platform
              facilitates proof of credentials and payments from HHAs to
              self-employed individuals, businesses, or independent contractor
              healthcare service providers. TCC has no involvement in the
              relationship between the self-employed individual, business, or
              independent contractor healthcare service provider and Home Health
              Agency (“HHA”). TCC merely provides this platform for such parties
              to connect and be able to contract with one another, if they so
              choose, under whatever terms they choose. TCC is not a HHA or an
              employer, and is not affiliated with any of the self-employed
              individuals, businesses, or independent contractor healthcare
              providers or HHAs who use its platform.
            </p>
            <p>
              This PAA governs your access to our Platform (defined below) which
              facilitates your provision of therapeutic service (collectively,
              “Therapeutic Service”) to enrolled HHAsseeking to access
              therapists able to provide certain types of Therapeutic Service
              for their patients. For the sake of clarity, references to “we,”
              “our,” and “us” also refers to “TCC”. Access to our web
              application platform facilitates Therapeutic Service between you
              and HHAs; as well as websites and all other associated services,
              including payment and support services, provided by TCC, its
              affiliates or third parties (collectively, our “Platform”).
            </p>
            <p>
              Your access to our Platform is also governed by the applicable
              terms found on our website, including without limitation the:
              Terms of Use, Privacy Policy, Harassment, Discrimination, and
              Retaliation Prevention Policy, HIPAA Policy, Dependent Adult/Elder
              Abuse Reporting Policy, Sexual Harassment Policy, other applicable
              TCC standards and policies (Immunization/Consent Declaration
              Certificate, Competency Self-Assessment Certificate, and Universal
              Precaution/Infection Control Standards) and, any other agreements
              you have with us (including the Confidentiality Agreement and
              those related to how you choose to interact with our Platform, the
              services you choose to provide and where you chose to provide
              them) (collectively with this PAA, this “Agreement”), which are
              incorporated by reference into this Agreement. BY CLICKING TO
              ACCEPT OR AGREE TO THE TERMS OF THIS AGREEMENT WHEN THIS OPTION IS
              MADE AVAILABLE TO YOU, YOU ACCEPT AND AGREE TO BE BOUND AND ABIDE
              BY THIS AGREEMENT. IF YOU DO NOT WANT TO BE BOUND TO THIS
              AGREEMENT, INCLUDING THE MANDATORY ARBITRATION AND CLASS ACTION
              WAIVER PROVISIONS SET FORTH HEREIN, YOU MUST NOT ACCESS OR USE THE
              PLATFORM OR SERVICES. . This Agreement is effective as of the date
              and time you accept it.
            </p>
          </div>

          <ol className="pl-0">
            <li className="list-unstyled d-flex">
              <span class="font-weight-bold therapy_services_list">1. </span>
              <p className="mb-0">
                <strong>Relationship with TCC</strong>
              </p>
            </li>

            <div className="d-flex list_alpha_space">
              <span> 1.1.&nbsp;</span>
              <li className="list-unstyled mb-3">
                <strong> Contracting Parties.&nbsp;</strong>
                You acknowledge and agree that TCC is a technology services
                provider that does not provide Therapeutic Services nor does it
                employ healthcare providers who utilize its technology programs.
                The relationship between the parties is solely as independent
                business enterprises, each of whom operates a separate and
                distinct business enterprise that provides a service outside the
                usual course of business of the other. This is not an employment
                agreement and you are not an employee. You confirm the existence
                and nature of that contractual relationship each time you access
                our Platform. We are not hiring or engaging you to provide any
                service; you are engaging us to provide you access to our
                Platform. Nothing in this Agreement creates, will create, or is
                intended to create, any employment, partnership, joint venture,
                franchise or sales representative relationship between you and
                us. You have no authority to make or accept any offers or
                representations on our behalf.
              </li>
            </div>
            <div className="d-flex list_alpha_space">
              <span>1.2.&nbsp;</span>
              <li className="list-unstyled mb-3">
                <strong>
                  {" "}
                  Your Choice to Provide Therapeutic Service to HHAs.&nbsp;
                </strong>
                You as a self-employed individual, business, or independent
                contractor for HHAs may independently determine if you provide
                Therapeutic Services to any particular HHA, when you provide
                Therapeutic Services, the amount of time you spend providing
                Therapeutic Services in any given day or week, with whom you
                contract or do not contract, and the rate at which you will be
                paid. We do not, and have no right to, direct or control you.
                Subject to your compliance with this Agreement, you are not
                required to accept any minimum number of HHA requests for
                Therapeutic Service (“Patient Referral”) in order to access our
                Platform and it is entirely your choice whether to provide
                Therapeutic Service to an HHA directly, using our Platform, or
                using any other method to connect with HHAs, including, but not
                limited to other platforms and applications in addition to, or
                instead of, ours. You understand, however, that your HHAs’
                experiences with your Therapeutic Services, as determined by HHA
                input, may affect your ability to access our Platform or provide
                Therapeutic Services.
              </li>
            </div>

            <div className="d-flex list_alpha_space mb-3">
              <span>1.3.&nbsp;</span>
              <li className="list-unstyled">
                <strong> Agency.&nbsp;</strong>
                You hereby acknowledge and agree that you have no authority to
                bind TCC and you shall not hold yourself out as an employee,
                agent or authorized representative of TCC. Notwithstanding
                anything to the contrary in this Agreement, where, by
                implication of mandatory law or otherwise, you may be deemed an
                agent or representative of TCC, you undertake and agree to
                indemnify, defend (at TCC’s option) and hold TCC harmless from
                and against any claims against or incurred by TCC that are
                brought against TCC by any person or entity based on such
                implied agency or representative relationship.
              </li>
            </div>

            <div className="d-flex list_alpha_space mb-3">
              <span>1.4.&nbsp;</span>
              <li className="list-unstyled">
                <strong>Direction.&nbsp; </strong>
                You acknowledge and agree that, in accordance with this
                Agreement, you may independently determine the Patient Referrals
                that you are willing to accept and, except as otherwise provided
                in this Agreement, the rate at which you will be paid. TCC does
                not, and shall not be deemed to, direct or control you generally
                or in your fulfillment of any Therapeutic Services during a
                visit with a Patient, including in connection with your
                provision of Therapeutic Services, your acts or omissions
                during, or your operation and/or maintenance of any equipment or
                property used in providing Therapeutic Services. Without
                limitation of the generality of the foregoing, you acknowledge
                and agree that TCC does not have the right or authority to
                direct or control what actions you perform or how you perform
                such actions in providing Therapeutic Services. You and TCC
                acknowledge and agree that the business relationship between
                such parties under this Agreement is a temporary, and not
                permanent, relationship.
              </li>
            </div>

            <div className="d-flex list_alpha_space mb-3">
              <span>1.5.&nbsp;</span>
              <li className="list-unstyled">
                <strong> Use of the Platform and Services. &nbsp; </strong>
                Unless such right is earlier terminated, deactivated, or
                restricted by TCC in accordance with this Agreement, you retain
                the sole right to determine when, where, and for how long you
                will utilize the Platform. You retain the option, via the
                Platform, to attempt to request, bid on, accept, decline, and/or
                ignore a HHA’s request for Therapeutic Services pursuant to a
                Patient Referral, or to cancel an accepted or approved Patient
                Referral in accordance with this Agreement.
              </li>
            </div>
            <div className="d-flex list_alpha_space mb-3">
              <span>1.6.&nbsp;</span>
              <li className="list-unstyled">
                <strong> Non-Exclusive. &nbsp; </strong>
                You acknowledge and agree that, except as expressly prohibited
                pursuant to this Agreement or otherwise prohibited by applicable
                law or industry regulations, you have complete discretion to
                provide services or otherwise engage in other business or
                employment activities other than Therapeutic Service. For the
                sake of clarity, you understand that, except as expressly
                prohibited pursuant to this Agreement or otherwise prohibited by
                applicable law or industry regulations, you retain the complete
                right to; (i) use other software application services related to
                lead-generation for the provision of Therapeutic Services in
                addition to the Platform; and (ii) engage in any other
                occupation or business other than being a provider of
                Therapeutic Services.
              </li>
            </div>
            <div className="d-flex list_alpha_space mb-3">
              <span>1.7.&nbsp;</span>
              <li className="list-unstyled">
                <strong>Branding; Equipment. &nbsp; </strong>
                With the exception of any signage required by applicable law,
                regulatory authority, requirements related to your credentials,
                or industry standards, TCC shall have no right to require you
                to: (a) display TCC’s respective names, logos or colors on any
                of your equipment or property; or (b) wear a uniform or any
                other clothing displaying TCC’s respective names, logos or
                colors. You hereby acknowledge and agree that TCC will not be
                providing you any equipment or training that may be necessary to
                provide Therapeutic Services.
              </li>
            </div>
            <div className="d-flex list_alpha_space mb-3">
              <span>1.8.&nbsp;</span>
              <li className="list-unstyled">
                <strong>Control. &nbsp; </strong>
                You acknowledge and agree that in providing Therapeutic Services
                to a HHA, TCC has no authority or control over the Therapeutic
                Services you provide including the quality and method of
                providing such Therapeutic Services nor the day-to-day oversight
                or supervision of your providing such Therapeutic Services. You
                further acknowledge and agree that TCC will not provide you with
                any tools of the trade or other materials in order for you to
                perform Therapeutic Services.
              </li>
            </div>
          </ol>
          <ol className="pl-0">
            <div className="d-flex">
              <span className="font-weight-bold therapy_services_list">
                2.{" "}
              </span>
              <p className="mb-0">
                <strong>Our Platform</strong>
              </p>
            </div>
            <div className="d-flex list_alpha_space">
              <span className="font-weight-bold therapy_services_list">
                2.1{" "}
              </span>
              <li className="list-unstyled mb-3">
                <strong>General.</strong>While using our Platform, you may
                receive lead generation and other technology-based services that
                enable those operating independent business enterprises like you
                to accept Patient Referrals. Subject to the terms and conditions
                of this Agreement, TCC hereby grants you a non-exclusive,
                non-transferable, non-sublicensable, non-assignable license,
                during the term of this Agreement, to use our Platform solely
                for the purpose of providing Therapeutic Services and accessing
                services associated with providing Therapeutic Services.
              </li>
            </div>
            <li className="list-unstyled ">
              <li className="list-unstyled mb-0">
                <span class="font-weight-bold therapy_services_list">2.2 </span>
                <strong> Compliance.</strong>
              </li>
              <ol type="a" className="pl-0 ">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li className="list-unstyled">
                    You are responsible for identifying, understanding, and
                    complying with (i) all laws (including, but not limited to,
                    the Americans with Disabilities Act and applicable laws
                    governing your collection, use, disclosure, security,
                    processing and transfer of data), rules and regulations that
                    apply to your provision of Therapeutic Services (including
                    whether you are permitted to provide Therapeutic Service at
                    all) in the jurisdiction(s) in which you operate (your
                    “Region”) and (ii) this Agreement (collectively, the
                    “Requirements”). Subject to applicable law, you are
                    responsible for identifying and obtaining any required
                    license (including professional or occupational license),
                    permit, or registration required to provide any Therapeutic
                    Service that you provide using our Platform. Notwithstanding
                    anything to the contrary in this Agreement, for the
                    avoidance of doubt, your ability to access and use our
                    Platform is at all times subject to your compliance with the
                    Requirements. You agree not to access or attempt to access
                    our Platform if you are not in compliance with the
                    Requirements.
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    b.{" "}
                  </span>
                  <li className="list-unstyled my-3">
                    The Americans with Disabilities Act imposes obligations
                    including the obligation to treat HHA’s patients with
                    service animals and does not contain exceptions for
                    allergies or religious objections. We have the right to and
                    you consent to the permanent deactivation of your account
                    and the permanent termination of your contractual
                    relationship with us if, based on the evidence, we conclude
                    that you knowingly refused a Patient Referral request from
                    an HHA whose patient required a service animal, or if we
                    receive plausible reports from an HHA of more than one
                    cancellation or refusal by you alleged to be on the basis of
                    the presence of an HHA’s patient’s service animal.
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    c.{" "}
                  </span>
                  <li className="list-unstyled my-3">
                    You acknowledge and agree that at all times, you shall: (a)
                    hold and maintain all credentials and any other
                    authorizations and comply with all Requirements applicable
                    to you that are necessary for Therapeutic Services you bid
                    on, request, approve, or accept and to provide the
                    Therapeutic Services to HHAs in the Territory for such; and
                    (b) possess the appropriate and current level of training,
                    expertise and experience to provide such Therapeutic Service
                    in a competent, safe, and professional manner with due
                    skill, care and diligence. You acknowledge and agree that
                    you may be subject to certain background checks and/or other
                    investigations from time to time in order to qualify to
                    provide, and remain eligible to provide, Therapeutic
                    Services. You acknowledge and agree that TCC reserves the
                    right, at any time in TCC’s sole discretion, to deactivate
                    or otherwise restrict you from accessing or using the
                    Platform or Services if you fail to meet the Requirements
                    set forth in this Agreement.
                  </li>
                </div>
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list pt-3">
                    d.{" "}
                  </span>
                  <li className="list-unstyled my-3">
                    You agree to notify TCC immediately of any disciplinary or
                    quality assurance proceedings or investigations involving
                    you, including, but not limited to, licensing boards,
                    quality assurance committees, hospitals or other medical
                    facilities and medical societies, and any other proceeding
                    or investigation the result of which might prohibit or
                    otherwise restrict or impair your ability to perform
                    Therapeutic Services. You further agree to promptly notify
                    TCC in the event any such proceedings or investigations are
                    pending or are instituted, whether or not such proceedings
                    are related to Therapeutic Services you performed for a HHA.
                  </li>
                </div>
              </ol>
            </li>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list">2.3 </span>
              <li className="list-unstyled pb-3 pl-1">
                <strong> Your Provision of Therapeutic Services to HHA.</strong>
                You represent, warrant and covenant that (a) you have all the
                necessary expertise and experience to provide Therapeutic
                Services in compliance with the Requirements and standards
                applicable to the Therapeutic Service, (b) your access and use
                of our Platform, and provision of Therapeutic Service, in your
                Region is permitted by the Requirements (including any age
                requirements), and (c) all such access and use of our Platform
                will be in compliance with the Requirements. You are responsible
                for, and bear all costs of, providing all equipment, tools and
                other materials that you deem necessary or advisable and are
                solely responsible for any obligations or liabilities arising
                from the Therapeutic Services you provide.
              </li>
            </div>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list">2.4 </span>
              <li className="list-unstyled pb-3 pl-1">
                <strong> Your Provision of Therapeutic Services to HHA.</strong>
                You acknowledge and agree that your Therapeutic Service for a
                HHA creates a direct business relationship between you and the
                HHA. TCC is not responsible or liable for the actions or
                inactions of a HHA or any of its affiliates, officers,
                directors, managers, employees, agents, or patients in relation
                to you or your activities at the HHA’s location or location of
                Therapeutic Services, in connection with your Therapeutic
                Service, or otherwise. You shall have the sole responsibility
                for any obligations or liabilities to HHAs or third parties that
                arise from your provision of Therapeutic Services for such HHA.
                You acknowledge and agree that you are solely responsible for
                taking such precautions as may be reasonable and proper
                (including maintaining adequate insurance and licensing that
                meets the requirements of all applicable laws and HHA standards,
                practices, policies, and procedures) regarding any acts or
                omissions by you, of a HHA, or a third party. You acknowledge
                and agree that, unless specifically consented to by a HHA, you
                may not perform any additional Therapeutic Services, other than
                the Therapeutic Services identified by the HHA for the
                applicable Patient when HHA accepted or approved your
                assignment, unless the additional Therapeutic Services are in
                response to an emergency and necessary to prevent harm or
                further damage.
              </li>
            </div>
            <li className="list-unstyled">
              <span class="font-weight-bold therapy_services_list">2.5 </span>
              <strong> Accessing our Platform.</strong>
              <ol type="a" className="list_alpha_space">
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li className="list-unstyled">
                    To receive Patient Referrals you must create and register an
                    account. All information you provide to us must be accurate,
                    current and complete and you will maintain the accuracy and
                    completeness of such information during the term of this
                    Agreement. Unless otherwise permitted by us in writing, you
                    agree to only possess one account for receiving Patient
                    Referrals per type of Therapeutic Service you are licensed
                    to provide. You are solely responsible for all activity
                    conducted on your account, including both unauthorized and
                    authorized use of your account. For account security and HHA
                    safety purposes, you agree not to share or allow anyone to
                    use your login credentials or other personal information
                    used in connection with your account, including but not
                    limited to photos of yourself, to access our Platform. If
                    you think anyone has obtained improper access to your
                    account, login credentials or personal information, you are
                    required to notify us and to change your password
                    immediately so that we may take appropriate steps to secure
                    your account. You agree that TCC is not responsible for any
                    losses arising from your sharing of account credentials with
                    a third party, including without limitation phishing.
                  </li>
                </div>
                <div className="d-flex py-3">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>
                  <li className="list-unstyled">
                    You represent, warrant, and covenant that you have all
                    required authority to accept and be bound by this Agreement.
                    If you are accepting this Agreement on behalf of your
                    company, entity, or organization, you represent and warrant
                    that you are an authorized representative of that company,
                    entity, or organization with the authority to bind such
                    party to this Agreement.
                  </li>
                </div>
              </ol>
            </li>
            <li className="list-unstyled">
              <span class="font-weight-bold therapy_services_list">2.6 </span>
              <strong> Background Checks and Licensing, Standards.</strong>
              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    During your account creation and registration, we will
                    collect, and may verify, certain information about you and
                    your qualifications to provide Therapeutic Services (“your
                    qualifications”).
                  </li>
                </div>
                <li className="py-3 list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>
                  You will also be required to pass various background,
                  self-certifications, and other checks both prior to the first
                  time you access our Platform and from time to time thereafter
                  during the term of this Agreement; these checks may be
                  facilitated by third parties. You hereby authorize and
                  instruct us to provide copies of such checks to insurance
                  companies, relevant regulators and/or other governmental
                  authorities as needed for safety or other reasons, as
                  described in our Privacy Notice.
                </li>
                <li className="pb-3 list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    c.{" "}
                  </span>
                  You agree that you will remain properly registered, licensed
                  and authorized to provide Therapeutic Services in your Region.
                  You represent that at all times during the provision of any
                  Therapeutic Service your licensure will be in good standing
                  with valid authority and qualifications to provide Therapeutic
                  Services in your Region. You agree that your qualifications
                  will be consistent with safety and therapeutic standards in
                  the Therapeutic Service industry. You agree to monitor for and
                  comply with any new qualifications, standards, or licensure
                  requirements of your governing authority (as well as anything
                  else the Requirements applicable to your particular Region may
                  require).
                </li>
              </ol>
            </li>
            <li className="list-unstyled">
              <span class="font-weight-bold therapy_services_list">2.7 </span>
              <strong>Accepting Patient Referrals.</strong>
              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class=" font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    Patient Referrals may appear in the Platform and you may
                    attempt to accept, decline or ignore them. Accepting a
                    Patient Referral request creates a direct business
                    relationship between you and your HHA in accordance with the
                    terms of the therapeutic service the HHA has requested
                    through our Platform. You acknowledge that TCC is not
                    responsible for providing You any payment even upon
                    acceptance of a Patient Referral and completion of
                    Therapeutic Services associated with a Patient Referral.
                  </li>
                </div>
                <div className="d-flex list_alpha_space py-3">
                  <span class=" font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>

                  <li>
                    You will choose the most effective, efficient, and safe
                    manner to provide Therapeutic Services associated with a
                    Patient Referral. Any informational materials offered in the
                    Platform are offered for your convenience only; you have no
                    obligation to follow such instructional materials. You agree
                    to supply HHA, or its patient, the specified Therapeutic
                    Service, as directed by the HHA or its patient.
                  </li>
                </div>
                <div className="d-flex list_alpha_space pb-3">
                  <span class=" font-weight-bold therapy_services_list">
                    c.{" "}
                  </span>
                  <li>
                    You may receive HHA and its patient information (“HHA
                    Information”), including protected personally identifiable
                    information that is subject to the Health Insurance
                    Portability and Accountability Act, as amended, and you
                    agree that your HHA may also be given identifying
                    information about you, including your first name, photo,
                    location, and certain other information you have voluntarily
                    provided through the Platform (collectively, “User
                    Information”). Without a HHA’s consent, you agree to not
                    contact any HHA or otherwise use any of the HHA Information
                    except solely in connection with a Patient Referral and the
                    provision of Therapeutic Services requested by that HHA. You
                    agree to treat all HHA Information as Confidential
                    Information (defined below) received by you under this
                    Agreement. You acknowledge that your violation of your
                    confidentiality obligations may also violate certain laws
                    and could result in civil or criminal penalties.
                  </li>
                </div>
              </ol>
            </li>
            <li className="list-unstyled">
              <span class="font-weight-bold therapy_services_list">2.8 </span>
              <strong>
                {" "}
                Criminal Offenses, and Other Compliance Obligations.
              </strong>
              For the purpose of assisting us with our compliance and insurance
              obligations, you agree to notify us within 24 hours and provide us
              with all reasonable information relating to any incident that
              occurs during your provision of Therapeutic Services and you agree
              to cooperate with any investigation and attempted resolution of
              such incident. Additionally, you agree to notify us within 24
              hours if you are arrested for, charged with, or convicted of a
              criminal offense, for Platform eligibility consideration.
            </li>
            <li className="list-unstyled py-3">
              <span class="font-weight-bold therapy_services_list">2.9 </span>
              <strong> Ratings.</strong>Your HHA may be asked to comment on your
              services, and you may be asked to comment on your HHA. These
              comments can include ratings and other feedback (collectively,
              “Ratings”), which we ask all parties to provide in good faith.
              Ratings are not confidential and you hereby authorize our use,
              distribution and display of your Ratings (and Ratings about you)
              as provided in our Privacy Notice, without attribution or further
              approval. We have no obligation to verify Ratings or their
              accuracy, and may remove them from our Platform as we, in our sole
              discretion, deem fit. You can find out more about Ratings and how
              they may affect your ability to access our Platform by visiting
              our website.
            </li>
            <li className="list-unstyled">
              <span class="font-weight-bold therapy_services_list">2.10 </span>
              <strong>User Contributions.</strong>
              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class=" font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    <strong>Platform Uploads and Posts. </strong>
                    The Platform may contain certain functions or features that
                    allow you to post, submit, publish, display, or transmit to
                    other users or other persons (hereinafter, “post”) content
                    or materials (collectively, “User Contributions”) on or
                    through the Platform. All User Contributions must comply
                    with the content standards set forth in Section 3.6.6 of
                    this Agreement. Any User Contribution you post to the
                    Platform will be considered non-confidential and
                    non-proprietary. By providing any User Contribution on the
                    Platform, you grant us and our affiliates and service
                    providers, and each of their and our respective licensees,
                    successors, and assigns the right to use, reproduce, modify,
                    perform, display, distribute, and otherwise disclose to
                    third parties any such material for any purpose in
                    accordance with the terms and conditions of this Agreement,
                    including the Privacy Policy.
                  </li>
                </div>
                <div className="d-flex list_alpha_space py-3">
                  <span class=" font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>

                  <li>
                    <strong>
                      Your Representations and Warranties. You represent and
                      warrant that:
                    </strong>
                    <ol className="list-unstyled">
                      <div className="d-flex list_alpha_space">
                        <span class="pr-2">2.10.b.1. </span>
                        <li>
                          You own or control all rights in and to the User
                          Contributions and have the right to grant the license
                          granted above to us and our affiliates and service
                          providers, and each of their and our respective
                          licensees, successors, and assigns.
                        </li>
                      </div>
                      <div className="d-flex list_alpha_space">
                        <span class="pr-2">2.10.b.2. </span>
                        <li>
                          All of your User Contributions do, and will at all
                          times, comply with this Agreement.
                        </li>
                      </div>
                      <div className="d-flex list_alpha_space">
                        <span class="pr-2">2.10.b.3. </span>
                        <li>
                          You understand and acknowledge that you are
                          responsible for any User Contributions you submit or
                          contribute, and you, not TCC, have full responsibility
                          for such content, including its legality, reliability,
                          accuracy, and appropriateness.
                        </li>
                      </div>
                      <div className="d-flex list_alpha_space">
                        <span class="pr-2">2.10.b.4. </span>
                        <li>
                          We are not responsible or liable to any third party
                          for the content or accuracy of any User Contributions
                          posted by you or any other user of the Platform.
                        </li>
                      </div>
                    </ol>
                  </li>
                </div>
              </ol>
            </li>
            <li className="list-unstyled">
              <span class="font-weight-bold therapy_services_list">2.11 </span>
              <strong>
                Location Based Technology Services; Communication Consents.
              </strong>
              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class=" therapy_services_list">a. </span>
                  <li>
                    Your device geo-location information is required for the
                    proper functioning of our Platform, and you agree to not
                    take any action to manipulate or falsify your device
                    geo-location. You grant us the irrevocable right to obtain
                    your geo-location information and to share your location
                    with third parties, including your HHA, who will see the
                    approximate location of your device in the Platform before
                    and during their Visit. We may not and will not use this
                    information to attempt to supervise, direct, or control you
                    or your provision of Therapeutic Services.
                  </li>
                </div>
                <div className="d-flex list_alpha_space py-3">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>

                  <li>
                    You agree that we may contact you by email, telephone or
                    text message (including by an automatic telephone dialing
                    system) at any of the phone numbers provided by you, or on
                    your behalf, in connection with your account. You also
                    understand that you may opt out of receiving text messages
                    from us at any time by contacting us in the Platform.
                    Notwithstanding the foregoing, we may also contact you by
                    any of the above means, including by SMS, in case of
                    suspected fraud or unlawful activity by your or on your
                    account.
                  </li>
                </div>
              </ol>
            </li>
            <li className="list-unstyled">
              <span class="font-weight-bold therapy_services_list">2.12 </span>
              <strong>
                {" "}
                Location Based Technology Services; Communication Consents.
              </strong>
              <ol type="a" className="list-unstyled">
                <div className="d-flex list_alpha_space">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li>
                    Your device geo-location information is required for the
                    proper functioning of our Platform, and you agree to not
                    take any action to manipulate or falsify your device
                    geo-location. You grant us the irrevocable right to obtain
                    your geo-location information and to share your location
                    with third parties, including your HHA, who will see the
                    approximate location of your device in the Platform before
                    and during their Visit. We may not and will not use this
                    information to attempt to supervise, direct, or control you
                    or your provision of Therapeutic Services.
                  </li>
                </div>
                <div className="d-flex list_alpha_space py-3">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>

                  <li>
                    You agree that we may contact you by email, telephone or
                    text message (including by an automatic telephone dialing
                    system) at any of the phone numbers provided by you, or on
                    your behalf, in connection with your account. You also
                    understand that you may opt out of receiving text messages
                    from us at any time by contacting us in the Platform.
                    Notwithstanding the foregoing, we may also contact you by
                    any of the above means, including by SMS, in case of
                    suspected fraud or unlawful activity by your or on your
                    account.
                  </li>
                </div>
              </ol>
            </li>
          </ol>
          <div className="d-flex">
            <span class="font-weight-bold therapy_services_list">3. </span>
            <p className="mb-0">
              <strong>Insurance</strong>
            </p>
          </div>
          <ol className="pl-3">
            <li className="list-unstyled">
              <span class="font-weight-bold therapy_services_list">3.1. </span>
              <strong>
                {" "}
                Your Professional Liability Insurance for Therapeutic Service.
              </strong>
              You will maintain Professional liability insurance that provides
              protection against professional malpractice at coverage levels
              that satisfy the statutory medical malpractice liability cap for
              your Region. You must be listed as an insured on the policy. You
              will provide us with a copy of the insurance policy declarations
              for your policy, as well as copies of the same upon renewal. You
              will notify us in writing immediately if the policy you have is
              canceled.
            </li>
            <li className="list-unstyled py-3">
              <span class="font-weight-bold therapy_services_list">3.2. </span>
              <strong> Your Other Insurance for Therapeutic Service. </strong>
              You will maintain workers’ compensation insurance if it is
              required by applicable law. If allowed by applicable law, you can
              insure yourself against industrial injuries by maintaining
              occupational accident insurance in place of workers’ compensation
              insurance (and it is at your own risk if you decide not to).
            </li>
            <li className="list-unstyled py-3">
              <span class="font-weight-bold therapy_services_list">3.3. </span>
              <strong> TCC Maintained Insurance.</strong>
              We may, in our sole discretion, choose to maintain insurance
              related to your Therapeutic Services, but we are not required to
              provide you with any specific coverage for loss to you, unless we
              specifically describe it in an addendum to this PAA. We can
              change, reduce or cancel insurance that is maintained by us, if
              any, at any time without notice to you or authorization from you.
            </li>
          </ol>

          <div className="d-flex">
            <span class="font-weight-bold therapy_services_list">4. </span>
            <p className="mb-0">
              <strong>Payments</strong>
            </p>
          </div>
          <ol className="pl-3">
            <li className="list-unstyled">
              <span class="font-weight-bold therapy_services_list">4.1. </span>
              <strong className="pl-2"> Eligibility for Payment.</strong>
              You must have a valid and active U.S. bank account that has been
              connected with your Stripe account.
            </li>
            <li className="list-unstyled py-3">
              <span class="font-weight-bold therapy_services_list">4.2. </span>
              <strong className="pl-2"> Third-Party Provider. </strong>. The
              payment functionality is facilitated by a third-party provider of
              payments services, Stripe. By using Stripe, you are subject to any
              additional terms and conditions for payment imposed by the
              third-party provider, which we recommend you review (
              <a href="https://stripe.com/legal/ssa">
                https://stripe.com/legal/ssa
              </a>
              ).
            </li>
            <li className="list-unstyled py-3">
              <span class="font-weight-bold therapy_services_list">4.3. </span>
              <strong className="pl-2">Visit Fee and Your Payment. </strong>
              Prior to accepting a Patient Referral, you are entitled to set the
              per visit rate that you wish to be paid for completing a Patient
              Visit (“Visit Rate”). On the Platform, you may bid for Patient
              Referrals by inputting your Visit Rate and the day that you are
              available. If a HHA chooses you for a Patient Referral, you will
              be paid the Visit Rate for the type of visit and Therapeutic
              Service that you provided subject to any fees, deductions and/or
              adjustments pursuant to this Agreement. The Visit Rates will be
              posted in “Addendum A”. The HHA will be responsible for paying you
              the Visit Rate as agreed upon by both parties when assigned to a
              Patient Referral. The HHA will also pay the TCC Fee (defined
              below) for each visit that is completed on behalf of their HHA.
              You acknowledge and agree that the Visit Rate, including any
              agreed upon fee, adjustment and/or deduction, is the only payment
              you will receive in connection with each visit completed. You: (i)
              appoint TCC as your limited payment collection agent solely for
              the purpose of accepting the Visit Rate, and, depending on the
              Territory and/or if requested by you, applicable taxes and fees
              from the HHA on your behalf via the payment processing
              functionality facilitated by the TCC Services; and (ii) agree that
              payment made by HHA to TCC (or to an Affiliate of TCC acting as an
              agent of TCC) shall be considered the same as payment made
              directly by HHA to you.
            </li>
            <li className="list-unstyled py-3">
              <span class="font-weight-bold therapy_services_list">4.4. </span>
              <strong className="pl-2">TCC Fee. </strong>
              In consideration of TCC’s provision of the Platform for your use
              and benefit hereunder, you agree to allow TCC to collect a service
              fee on a per visit basis calculated on a fixed fee model (“TCC
              Fee”) to be paid by the HHA. TCC reserves the right to change the
              TCC Fee at any time in TCC’s discretion.
            </li>
            <li className="list-unstyled py-3">
              <span class="font-weight-bold therapy_services_list">4.5. </span>
              <strong className="pl-2">
                Visit Record Verification Prior to Payment.{" "}
              </strong>
              Upon completion of any visit, you shall submit to TCC properly
              completed and error free visit record, in a form reasonably
              satisfactory to Medicare and industry standards, for the HHA’s
              approval. This visit record will need to show a visit duration of
              at least 45 minutes, in addition to a patient signature via
              electronic signature or paper route sheet to verify your
              Therapeutic Service. Any visit completed that was not verified
              through the Platform will not be paid or payable by or through
              TCC.
            </li>
            <li className="list-unstyled py-3">
              <span class="font-weight-bold therapy_services_list">4.6. </span>
              <strong className="pl-2">Timely Recordation of Visits </strong>
              You have up to ten (10) days following the completion of any visit
              to submit visit records to TCC for approval from the HHA and
              payment.
            </li>
            <li className="list-unstyled py-3">
              <span class="font-weight-bold therapy_services_list">4.7. </span>
              <strong className="pl-2">Methods of Payment. </strong>
              TCC facilitates payments to you from the HHA for Therapeutic
              Services you provide during visits via Automated Clearing House
              (“ACH”) deposit.
              <ol className="list-unstyled">
                <div class="d-flex list_alpha_space py-3">
                  <span class=" therapy_services_list">a. </span>
                  <li>
                    You agree that we may contact you by email, telephone or
                    text message (including by an automatic telephone dialing
                    system) at any of the phone numbers provided by you, or on
                    your behalf, in connection with your account. You also
                    understand that you may opt out of receiving text messages
                    from us at any time by contacting us in the Platform.
                    Notwithstanding the foregoing, we may also contact you by
                    any of the above means, including by SMS, in case of
                    suspected fraud or unlawful activity by your or on your
                    account.
                  </li>
                </div>
              </ol>
            </li>
          </ol>

          <div className="d-flex">
            <span class="font-weight-bold therapy_services_list">5. </span>
            <p className="mb-0">
              <strong>Term and Termination; Effect; Survival</strong>
            </p>
          </div>

          <ol>
            <li className="list-unstyled">
              <span class="font-weight-bold therapy_services_list">5.1. </span>
              <strong> Term.</strong> This Agreement is effective as of the date
              and time you accept it and will continue until terminated by you
              or us.
            </li>
            <li className="list-unstyled py-3">
              <span class="font-weight-bold therapy_services_list">5.2. </span>
              <strong> Termination by You.</strong>. You may terminate this
              Agreement (a) without cause at any time upon seven (7) days’ prior
              written notice to TCC; and (b) immediately, without notice for
              TCC’s violation or alleged violation of a material provision of
              this Agreement. You can find out more about how to delete your
              account by navigating to{" "}
              <a href="thecareconnexion.com/FAQ">thecareconnexion.com/FAQ</a>.
            </li>
            <li className="list-unstyled">
              <span class="font-weight-bold therapy_services_list">5.3. </span>
              <strong> Deactivation.</strong>You consent to and we may
              temporarily deactivate your account without notice to investigate
              whether you have engaged in, or your account has been used in,
              activity that is deceptive, fraudulent, unsafe, illegal, harmful
              to our brand, business or reputation, or that violates this
              Agreement (including the policies incorporated herein by
              reference) (any of the foregoing, a “Material Breach or
              Violation”). You also consent to and we may terminate this
              Agreement or permanently deactivate your account without notice if
              we determine in our discretion that a Material Breach or Violation
              has occurred.
            </li>
            <li className="list-unstyled py-3">
              <span class="font-weight-bold therapy_services_list">5.4. </span>
              <strong> Effect of Termination and Survival.</strong> Upon
              termination, each party will remain responsible for its respective
              liabilities or obligations that accrued before or as a result of
              such termination. Once the Agreement is terminated you will no
              longer access our Platform to provide Therapeutic Services.
              Sections 1, 2.10(b), 4, 5.4, 6-9, 11 and 12 shall survive any
              termination or expiration of this Agreement.
            </li>
          </ol>

          <div className="d-flex">
            <span class="font-weight-bold therapy_services_list">6. </span>
            <p className="mb-0">
              <strong>DISCLAIMERS</strong>
            </p>
          </div>
          <ol>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list  pr-4">
                6.1.{" "}
              </span>
              <li className="list-unstyled ">
                {" "}
                WE PROVIDE OUR PLATFORM AND ANY ADDITIONAL PRODUCTS OR SERVICES
                “AS IS” AND “AS AVAILABLE,” WITHOUT GUARANTEE OR WARRANTY OF ANY
                KIND, AND YOUR ACCESS TO OUR PLATFORM IS NOT GUARANTEED TO
                RESULT IN ANY PATIENT REFERRALS. WE DO NOT WARRANT THAT OUR
                PLATFORM WILL BE ACCURATE, COMPLETE, RELIABLE, CURRENT, SECURE,
                UNINTERRUPTED, ALWAYS AVAILABLE, OR ERROR- FREE, OR WILL MEET
                YOUR REQUIREMENTS, THAT ANY DEFECTS WILL BE CORRECTED, THAT OUR
                TECHNOLOGY IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE
                WILL NOT BE LIABLE FOR ANY SERVICE INTERRUPTIONS OR LOSSES
                RESULTING FROM SERVICE INTERRUPTIONS, INCLUDING BUT NOT LIMITED
                TO SYSTEM FAILURES OR OTHER INTERRUPTIONS THAT MAY AFFECT YOUR
                ACCESS TO OUR PLATFORM.
              </li>
            </div>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list pt-3 pr-4">
                6.2.{" "}
              </span>

              <li className="list-unstyled py-3">
                6.2. WE PROVIDE LEAD GENERATION AND RELATED SERVICES ONLY, AND
                MAKE NO REPRESENTATIONS, WARRANTIES OR GUARANTEES AS TO THE
                ACTIONS OR INACTIONS OF THE HHAS OR INDIVIDUALS WHO MAY REQUEST
                OR ACTUALLY RECEIVE THERAPEUTIC SERVICES FROM YOU. WE DO NOT
                SCREEN OR EVALUATE HHAS OR INDIVIDUALS WHO MAY REQUEST OR
                ACTUALLY RECEIVE THERAPEUTIC SERVICES FROM YOU. SOME
                JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, SUCH AS THE
                IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, ACCURACY, AVAILABILITY, SAFETY, SECURITY, AND NON-
                INFRINGEMENT. WE EXCLUDE ALL WARRANTIES TO THE EXTENT THOSE
                REGULATIONS ALLOW.
              </li>
            </div>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list pt-3 pr-3">
                6.3.{" "}
              </span>

              <li className="list-unstyled py-3">
                IF A DISPUTE ARISES BETWEEN YOU AND AN HHA OR ANY OTHER THIRD
                PARTY, YOU RELEASE US FROM LOSSES OF EVERY KIND AND NATURE,
                KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND
                UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH
                DISPUTES.
              </li>
            </div>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list pt-3 pr-4">
                6.4.{" "}
              </span>

              <li className="list-unstyled py-3">
                WE MAY USE ALGORITHMS IN AN ATTEMPT TO FACILITATE PATIENT
                REFERRALS AND IMPROVE THE: EXPERIENCE OF USERS AND THE SECURITY
                AND SAFETY OF OUR PLATFORM; ANY SUCH USE DOES NOT CONSTITUTE A
                GUARANTEE OR WARRANTY OF ANY KIND, EXPRESSED OR IMPLIED.
              </li>
            </div>
          </ol>
          <div className="d-flex">
            <span class="font-weight-bold therapy_services_list">7. </span>
            <p className="mb-0">
              <strong>Indemnification</strong>
            </p>
          </div>
          <ol>
            <p>
              YOU SHALL INDEMNIFY, DEFEND (AT TCC’S OPTION) AND HOLD HARMLESS
              TCC AND ITS AFFILIATES AND THEIR RESPECTIVE OFFICERS, DIRECTORS,
              EMPLOYEES, AGENTS, SUCCESSORS AND ASSIGNS FROM AND AGAINST ANY AND
              ALL LIABILITIES, JUDGMENTS, AWARDS, LOSSES, COSTS, EXPENSES
              (INCLUDING REASONABLE LEGAL FEES), DAMAGES, PENALTIES, FINES,
              FEES, SOCIAL SECURITY CONTRIBUTIONS AND TAXES (COLLECTIVELY,
              “CLAIMS”) ARISING OUT OF OR RELATED TO YOUR BREACH OF THIS
              AGREEMENT, YOUR USE OF THE PLATFORM, INCLUDING, BUT NOT LIMITED
              TO, YOUR USER CONTRIBUTIONS, ANY USE OF A PLATFORM’S CONTENT,
              SERVICES, AND PRODUCTS OTHER THAN AS EXPRESSLY AUTHORIZED IN THIS
              AGREEMENT, YOUR USE OF ANY INFORMATION OBTAINED FROM A PLATFORM,
              AND YOUR ACTS AND OMISSIONS IN CONNECTION WITH YOUR THERAPEUTIC
              SERVICE PURSUANT TO THIS AGREEMENT. WITHOUT LIMITING THE
              GENERALITY OF THE FOREGOING AS A USER, YOU ARE RESPONSIBLE FOR
              YOUR OWN MEDICAL DECISIONS AND ACTIONS AND HEREBY AGREE TO
              INDEMNIFY TCC AND SHALL HOLD TCC HARMLESS FOR ANY CLAIMS AGAINST
              OR INCURRED BY TCC, INCLUDING ANY CLAIMS THAT MAY HAVE BEEN
              CONTRIBUTED TO BY TCC’S OWN NEGLIGENCE, IN WHOLE OR IN PART,
              ARISING OUT OF, IN CONNECTION WITH, OR AS A RESULT OF YOUR
              RENDERING OR FAILING TO RENDER THERAPEUTIC SERVICES IN ACCORDANCE
              WITH GOVERNING LAWS AND APPLICABLE PROFESSIONAL STANDARDS.
            </p>
          </ol>
          <div className="d-flex">
            <span class="font-weight-bold therapy_services_list">8. </span>
            <p className="mb-0">
              <strong>Information</strong>
            </p>
          </div>
          <ol>
            <p>
              We may collect and disclose information from or about you when you
              create an account, interact with our Platform or provide
              Therapeutic Services and as otherwise described in our Privacy
              Notice. Notwithstanding anything herein to the contrary (a) the
              collection, use, and disclosure of such information will be made
              in accordance with our Privacy Notice and (b) if you elect to
              provide or make available suggestions, comments, ideas,
              improvements, or other feedback or materials to us in connection
              with, or related to, us or our Platform, we will be free to use,
              disclose, reproduce, modify, license, transfer and otherwise
              distribute, and exploit any of the foregoing information or
              materials in any manner.
            </p>
          </ol>

          <div className="d-flex">
            <span class="font-weight-bold therapy_services_list">9. </span>
            <p className="mb-0">
              <strong>Confidentiality</strong>
            </p>
          </div>

          <ol>
            <div className="d-flex gap-4">
              <span class="font-weight-bold therapy_services_list pr-4">
                9.1.{" "}
              </span>
              <li className="list-unstyled">
                <strong> Confidential Information.</strong>Each party Each party
                acknowledges and agrees that in the performance of this
                Agreement it may have access to or may be exposed to, directly
                or indirectly, confidential information of the other party or
                third parties (“Confidential Information”). Confidential
                Information includes HHA User Information and the transportation
                volume, marketing and business plans, business, financial,
                technical, operational and such other, non-public information of
                each party (whether disclosed in writing or verbally) that such
                party designates as being proprietary or confidential or of
                which the other party should reasonably know that it should be
                treated as confidential. Confidential Information does not
                include any information that: (a) was in the receiving party’s
                lawful possession prior to the disclosure, as clearly and
                convincingly corroborated by written records, and had not been
                obtained by the receiving party either directly or indirectly
                from the disclosing party; (b) is lawfully disclosed to the
                receiving party by a third party without actual, implied or
                intended restriction on disclosure through the chain of
                possession, or (c) is independently developed by the receiving
                party without the use of or access to the Confidential
                Information, as clearly and convincingly corroborated by written
                records.
              </li>
            </div>
            <div className="d-flex py-3 gap-4">
              <span class="font-weight-bold therapy_services_list pr-4">
                9.2.{" "}
              </span>

              <li className="list-unstyled">
                <strong>Obligations.</strong> Each party acknowledges and agrees
                that: (a) all Confidential Information shall remain the
                exclusive property of the disclosing party; (b) it shall not use
                Confidential Information of the other party for any purpose
                except in furtherance of this Agreement; (c) it shall not
                disclose Confidential Information of the other party to any
                third- party, except to its employees, officers, contractors,
                agents and service providers ("Permitted Persons") as necessary
                to perform their obligations under this Agreement, provided
                Permitted Persons are bound in writing to obligations of
                confidentiality and non-use of Confidential Information no less
                protective than the terms hereof; and (d) it shall return or
                destroy all Confidential Information of the disclosing party,
                upon the termination of this Agreement or at the request of the
                other party; subject to applicable law and our internal
                record-keeping requirements.
              </li>
            </div>
            <div className="d-flex gap-4">
              <span class="font-weight-bold therapy_services_list pr-4">
                9.3.{" "}
              </span>
              <li className="list-unstyled">
                <strong>Remedies.</strong>The unauthorized use or disclosure of
                any Confidential Information would cause irreparable harm and
                significant damages, the degree of which may be difficult to
                ascertain. Accordingly, the parties have the right to obtain
                immediate equitable relief to enjoin any unauthorized use or
                disclosure of Confidential Information disclosed by the other
                party, in addition to any other rights or remedies described in
                Section 12, applicable law or otherwise.
              </li>
            </div>
            <div className="d-flex gap-4">
              <span class="font-weight-bold therapy_services_list pr-4">
                9.4.{" "}
              </span>
              <li className="list-unstyled">
                <strong>Protected Health Information. </strong>Notwithstanding
                anything to the contrary herein, you hereby agree and
                acknowledge that you will comply with all applicable laws
                regarding and related to the handling of, access to, and
                dissemination and destruction of protected health information
                (“PHI”), as that term is defined under applicable law, including
                the Health Insurance Portability and Accountability Act of 1996
                (“HIPAA”), that you may gain access to as a result of providing
                Therapeutic Services under this Agreement. Notwithstanding
                anything to the contrary herein, you hereby agree and
                acknowledge that you shall not upload to any Platform or
                otherwise provide to TCC any PHI that would violate applicable
                law, including HIPAA.
              </li>
            </div>
          </ol>

          <div className="d-flex">
            <span class="font-weight-bold therapy_services_list">10. </span>
            <p className="mb-0">
              <strong>Proprietary Rights; License</strong>
            </p>
          </div>

          <ol>
            <div className="d-flex gap-4">
              <span class="font-weight-bold therapy_services_list pr-4">
                10.1.{" "}
              </span>
              <li className="list-unstyled">
                <strong>License Grant. </strong>Each party Subject to the terms
                and conditions of this Agreement, TCC hereby grants you, during
                the term of this Agreement, a limited non-exclusive,
                non-transferable, non-sublicensable, non-assignable, revocable
                license during the term of this Agreement to: (a) access and use
                the Platform on your personal device(s) solely in connection
                with your use of the services and (b) access and use of content,
                information, and related materials that may be made available
                through the Platform solely in connection with your use. No
                right, title, or interest in or to the Platform or any content
                on the Platform is transferred to you, and all rights not
                expressly granted are reserved by the TCC. Any use of the
                Platform not expressly permitted by this Agreement is a breach
                of this Agreement and may violate copyright, trademark, and
                other laws.
              </li>
            </div>
            <div className="d-flex py-3 gap-4">
              <span class="font-weight-bold therapy_services_list pr-4">
                10.2.{" "}
              </span>

              <li className="list-unstyled">
                <strong>Restrictions.</strong> You shall not, and shall not
                allow any other person or entity to: (a) license, sublicense,
                sell, resell, transfer, assign, distribute or otherwise provide
                or make available to any other party your use of or access to
                the Platform in any way; (b) modify or make derivative works
                based upon the Platform; (c) improperly use the Platform,
                including creating internet “links” to any part of the Platform,
                “framing” or “mirroring” any part of the Platform on any other
                websites or systems, or “scraping” or otherwise improperly
                obtaining data from Platform; (d) reverse engineer, decompile,
                modify, or disassemble the Platform, except as allowed under
                applicable law; or (e) send spam or otherwise duplicative or
                unsolicited messages. In addition, you shall not, and shall not
                allow any other party to, access or use the Platform to: (i)
                design or develop a competitive or substantially similar product
                or service; (ii) copy or extract any features, functionality, or
                content thereof; (iii) launch or cause to be launched on or in
                connection with the Platform an automated program or script,
                including web spiders, crawlers, robots, indexers, bots, viruses
                or worms, or any program which may make multiple server requests
                per second, or unduly burden or hinder the operation and/or
                performance of the Platform; (iv) attempt to gain unauthorized
                access to, interfere with, damage, or disrupt any parts of the
                Platform, the servers on which the Platform are stored, or any
                server, computer, database, system or network connected to the
                Platform; or (v) otherwise attempt to interfere with the proper
                working of the Platform.
              </li>
            </div>
            <div className="d-flex gap-4">
              <span class="font-weight-bold therapy_services_list pr-4">
                10.3.{" "}
              </span>
              <li className="list-unstyled">
                <strong>Ownership.</strong>The Platform and each Platform’s
                entire contents, features, and functionality (including but not
                limited to company data and all other information, software,
                text, displays, images, video, and audio, and the design,
                selection, and arrangement thereof), including all intellectual
                property rights therein, are, and shall remain, owned by TCC,
                its affiliates, licensors, or other providers of such material
                and are protected by United States and international copyright,
                trademark, patent, trade secret, and other intellectual property
                or proprietary rights laws. Neither this Agreement nor your use
                of the Platform conveys or grants to you any rights in or
                related to the Platform, services, company data, or other of the
                foregoing tangible or intangible property of TCC, its
                affiliates, licensors, or other providers, except for the
                limited license granted above. Other than as specifically
                permitted by TCC in connection with your use of the Services,
                you are not permitted to use or reference in any manner TCC’s,
                its affiliates’, or their respective licensors’ respective
                company names, logos, products and service names, trademarks,
                service marks, trade dress, copyrights or other indicia of
                ownership, alone and in combination with other letters,
                punctuation, words, symbols and/or designs (the “TCC Marks and
                Names") for any commercial purposes. You agree that you will not
                try to register or otherwise use and/or claim ownership in any
                of the TCC Marks and Names, alone or in combination with other
                letters, punctuation,words, symbols and/or designs, or in any
                confusingly similar mark, name or title, for any goods and
                services.
              </li>
            </div>
          </ol>

          <div className="d-flex">
            <span class="font-weight-bold therapy_services_list">11. </span>
            <p className="mb-0">
              <strong>Intellectual Property</strong>
            </p>
          </div>
          <ol>
            <p>
              We reserve all rights not expressly granted in this Agreement, our
              Platform, and all data gathered through our Platform, including
              all intellectual property rights therein (the “Platform IP”), are
              and remain our property and/or that of our licensors, as
              applicable. Neither this Agreement nor your use of TCC’s or our
              licensors’ company names, logos, products or service names,
              trademarks, service marks, trade dress, other indicia of
              ownership, or copyrights (“TCC Names, Marks, or Works”) or the
              Platform IP conveys or grants to you any rights in or related to
              the Platform IP, or related intellectual property rights,
              including TCC’s Names, Marks, or Works, except for the limited
              license granted above. You shall not, and shall not allow any
              other party to: (a) license, sublicense, copy, modify, distribute,
              create, sell, resell, transfer, or lease any part of the Platform
              IP or Authorized TCC-Branded Materials; (b) reverse engineer or
              attempt to extract the source code of our software, except as
              allowed under law; (c) use, display, or manipulate any of TCC
              Names, Marks, or Works for any purpose other than to provide
              Therapeutic Services; (d) create or register any (i) businesses,
              (ii) URLs, (iii) domain names, (iv) software application names or
              titles, or (v) social media handles or profiles that include TCC
              Names, Marks, or Works or any confusingly or substantially similar
              mark, name, title, or work; (e) use TCC Names, Marks, or Works as
              your social media profile picture or wallpaper; (f) purchase
              keywords (including, but not limited to Google AdWords) that
              contain any TCC Names, Marks, or Works; (g) apply to register,
              reference, use, copy, and/or claim ownership in TCC’s Names,
              Marks, or Works, or in any confusingly or substantially similar
              name, mark, title, or work, in any manner for any purposes, alone
              or in combination with other letters, punctuation, words, symbols,
              designs, and/or any creative works, except as may be permitted in
              the limited license granted above; (h) cause or launch any
              programs or scripts for the purpose of scraping, indexing,
              surveying, or otherwise data mining any part of our Platform or
              data; or (i) aggregate TCC’s data with competitors’.
            </p>{" "}
          </ol>
          <div className="d-flex">
            <span class="font-weight-bold therapy_services_list">12. </span>
            <p className="mb-0">
              <strong>Third-Party Services</strong>
            </p>
          </div>
          <ol>
            <li>
              From time to time we may permit third parties to offer their
              services to users of our Platform. Third-party services may be
              subject to additional terms (including pricing) that apply between
              you and the party(ies) providing such services. If you choose to
              access the third-party services you understand that the providers
              of the third-party services are solely responsible for liabilities
              arising in connection with the access and use of such third-party
              services. While we may allow users to access such services through
              our Platform and we may collect information about our users’ use
              of such services, we may not investigate, monitor or check such
              third-party services for accuracy or completeness.
            </li>
          </ol>
          <div className="d-flex">
            <span class="font-weight-bold therapy_services_list">13. </span>

            <p className="mb-0">
              <strong>Miscellaneous</strong>
            </p>
          </div>
          <ol>
            <div className="d-flex pt-3 gap-4">
              <span class="font-weight-bold therapy_services_list  pr-4">
                13.1.{" "}
              </span>
              <li className="list-unstyled">
                <strong> Modification</strong> You will only be bound by
                modifications or supplements to this PAA on your acceptance, but
                if you do not agree to them, you may not be allowed to access
                our Platform. Such modifications or supplements may be provided
                to you only via electronic means. From time to time we may
                modify information hyperlinked in this PAA (or the addresses
                where such information may be found) and such modifications
                shall be effective when posted.
              </li>
            </div>

            <div className="d-flex py-3 gap-4">
              <span class="font-weight-bold therapy_services_list  pr-4">
                13.2.{" "}
              </span>

              <li className="list-unstyled ">
                <strong> Severability</strong> Invalidity of any provision of
                this Agreement does not affect the rest of this Agreement. The
                parties shall replace the invalid or non-binding provision with
                provision(s) that are valid and binding and that have, to the
                greatest extent possible, a similar effect as the invalid or
                non-binding provision, given the contents and purpose of this
                Agreement.
              </li>
            </div>
            <div className="d-flex gap-4">
              <span class="font-weight-bold therapy_services_list  pr-4">
                13.3.{" "}
              </span>

              <li className="list-unstyled ">
                <strong>Assignment</strong> We may freely assign or transfer
                this Agreement or any of our rights or obligations in them, in
                whole or in part, without your prior consent. You agree not to
                assign this Agreement, in whole or in part, without our prior
                written consent, and any attempted assignment without such
                consent is void.
              </li>
            </div>
            <div className="d-flex py-3 gap-4">
              <span class="font-weight-bold therapy_services_list  pr-4">
                13.4.{" "}
              </span>

              <li className="list-unstyled">
                <strong>Conflicts</strong>Except with respect to the Arbitration
                Provision, if there is a conflict between this PAA and any
                supplemental terms between you and us, those supplemental terms
                will prevail with respect to the specific conflict if explicitly
                provided therein, and is in addition to, and a part of, this
                Agreement.
              </li>
            </div>
            <div className="d-flex gap-4">
              <span class="font-weight-bold therapy_services_list  pr-2">
                13.5.{" "}
              </span>

              <li className="list-unstyled">
                <strong>Interpretation</strong> In this Agreement, “including”
                and “include” mean “including, but not limited to.”
              </li>
            </div>
            <div className="d-flex py-3 gap-4">
              <span class="font-weight-bold therapy_services_list  pr-4">
                13.6.{" "}
              </span>

              <li className="list-unstyled">
                <strong> Notice</strong>. Except as explicitly stated otherwise,
                any notices to us shall be given by certified mail, postage
                prepaid and return receipt requested to The Care Connexion,
                Inc., 7117 Florida Blvd, Baton Rouge, LA 70806. All notices to
                you may be provided electronically including through our
                Platform or by other means.
              </li>
            </div>
            <div className="d-flex gap-4">
              <span class="font-weight-bold therapy_services_list  pr-4">
                13.7.{" "}
              </span>
              <li className="list-unstyled">
                <strong>Governing Law.</strong> Except as specifically provided
                in this PAA, this PAA is governed by Louisiana or, if and only
                if required by applicable law, the state where you reside (or
                where your entity is domiciled) when you accepted this PAA (the
                “Governing Law”). The Governing Law shall apply without
                reference to the choice-of-law principles that would result in
                the application of the laws of a different jurisdiction.
              </li>
            </div>
            <div className="d-flex py-3 gap-4">
              <span class="font-weight-bold therapy_services_list  pr-4">
                13.8.{" "}
              </span>

              <li className="list-unstyled">
                <strong> Entire Agreement.</strong> Except as specifically set
                forth in the Arbitration Provision, this Agreement, constitutes
                the entire agreement and understanding with respect to the
                subject matter expressly contemplated herein and therein, and
                supersedes all prior or contemporaneous agreements or
                undertakings on this subject matter.
              </li>
            </div>
            <div className="d-flex gap-4">
              <span class="font-weight-bold therapy_services_list  pr-4">
                13.9.{" "}
              </span>

              <li className="list-unstyled">
                <strong>No Incorporation.</strong> Notwithstanding anything
                herein to the contrary, no agreement, term or other provision
                relating to your indemnification obligations to us will be
                considered incorporated by reference, or otherwise a part of,
                this Agreement.
              </li>
            </div>
            <div className="d-flex py-3 gap-4">
              <span class="font-weight-bold therapy_services_list  pr-2">
                13.10.{" "}
              </span>

              <li className="list-unstyled">
                <strong>Questions.</strong> If you have questions about our
                Platform, you may contact us by logging on to
                <a
                  href=" https://thecareconnexion.com "
                  className="pl-1 text-primary"
                >
                  https://thecareconnexion.com{" "}
                </a>
                and navigating to the “Contact Us” section.
              </li>
            </div>
          </ol>
          <div className="d-flex">
            <span class="font-weight-bold therapy_services_list">14. </span>
            <p className="mb-0">
              {" "}
              <strong> Arbitration Provision</strong>
            </p>
          </div>
          <ol>
            <p>
              <strong>
                {" "}
                IMPORTANT: PLEASE REVIEW THIS ARBITRATION PROVISION CAREFULLY,
                AS IT WILL REQUIRE YOU TO RESOLVE DISPUTES WITH US ON AN
                INDIVIDUAL BASIS THROUGH FINAL AND BINDING ARBITRATION.
              </strong>
            </p>
            <li className="list-unstyled py-3">
              <span class="font-weight-bold therapy_services_list pr-2">
                14.1.{" "}
              </span>
              <strong className="pl-3">
                How This Arbitration Provision Applies.
              </strong>
              <ol type="a">
                <div className="d-flex ">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li className="list-unstyled">
                    This Arbitration Provision is a contract governed by the
                    Federal Arbitration Act, 9 U.S.C. § 1 et seq. and evidences
                    a transaction involving commerce, and you agree that this is
                    not a contract of employment involving any class of workers
                    engaged in foreign or interstate commerce within the meaning
                    of Section 1 of the Federal Arbitration Act. If
                    notwithstanding the foregoing, the Federal Arbitration Act
                    does not apply to this Arbitration Provision, the law
                    pertaining to arbitration agreements of the state law
                    governing this Agreement shall apply. Except as it otherwise
                    provides, this Arbitration Provision applies to any legal
                    dispute, past, present or future, arising out of or related
                    to your relationship with us or relationship with any of our
                    agents, employees, executives, officers, investors,
                    shareholders, affiliates, successors, assigns, subsidiaries
                    or parent companies (each of which may enforce this
                    Arbitration Provision as third party beneficiaries), and
                    termination of that relationship, and survives after the
                    relationship terminates.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list">
                    b.{" "}
                  </span>

                  <li className="list-unstyled">
                    This Arbitration Provision applies to all claims whether
                    brought by you or us, except as provided below. This
                    Arbitration Provision requires all such claims to be
                    resolved only by an arbitrator through final and binding
                    individual arbitration and not by way of court or jury
                    trial. Such disputes include without limitation disputes
                    arising out of or relating to interpretation or application
                    of this Arbitration Provision, including the formation,
                    scope, enforceability, waiver, applicability, revocability
                    or validity of this Arbitration Provision or any portion of
                    this Arbitration Provision.
                  </li>
                </div>
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list">
                    c.{" "}
                  </span>

                  <li className="list-unstyled">
                    c. Except as it otherwise provides, this Arbitration
                    Provision also applies, without limitation, to disputes
                    between you and us, or between you and any other entity or
                    individual, arising out of or related to your application
                    for and use of an account to use our Platform and as a
                    therapist, background checks, your privacy, your contractual
                    relationship with us or the termination of that relationship
                    (including post-relationship claims), the nature of your
                    relationship with us (including, but not limited to, any
                    claim that you are our employee), trade secrets, workplace
                    safety and health, unfair competition, compensation, minimum
                    wage, expense reimbursement, overtime, breaks and rest
                    periods, retaliation, discrimination, or harassment and
                    claims arising under the Telephone Consumer Protection Act,
                    Fair Credit Reporting Act, Title VII of the Civil Rights Act
                    of 1964, 42 U.S.C. § 1981, 8 U.S.C. § 1324b (unfair
                    immigration related practices), Americans With Disabilities
                    Act, Age Discrimination in Employment Act, Fair Labor
                    Standards Act, Worker Adjustment and Retraining Notification
                    Act, Health Insurance Portability and Accountability Act,
                    Older Workers Benefits Protection Act of 1990, Occupational
                    Safety and Health Act, Consolidated Omnibus Budget
                    Reconciliation Act of 1985, federal, state or local statutes
                    or regulations addressing the same or similar subject
                    matters, and all other federal, state, or local statutory,
                    common law and legal claims (including without limitation,
                    torts) arising out of or relating to your relationship with
                    us or the termination of that relationship.
                  </li>
                </div>
              </ol>
            </li>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list pr-2">
                14.2.{" "}
              </span>

              <li className="list-unstyled pl-3">
                <strong>
                  {" "}
                  Governing Rules, Starting The Arbitration, And Selecting The
                  Arbitrator.
                </strong>{" "}
                The JAMS Comprehensive Arbitration Rules & Procedures (“JAMS
                Rules”) will apply to arbitration under this Arbitration
                Provision; however, if there is a conflict between the JAMS
                Rules and this Arbitration Provision, this Arbitration Provision
                shall govern.
              </li>
            </div>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list pr-2 pt-3">
                14.3.{" "}
              </span>

              <li className="list-unstyled pl-3 py-3">
                <strong>
                  {" "}
                  Class Action Waiver. This Arbitration Provision affects your
                  ability to participate in class or collective actions.
                </strong>{" "}
                Both TCC and you agree to bring any dispute in arbitration on an
                individual basis only, and not on a class or collective basis on
                behalf of others. There will be no right or authority for any
                dispute to be brought, heard or arbitrated as a class or
                collective action, or for you to participate as a member in any
                such class or collective proceeding (“Class Action Waiver”).
                Notwithstanding any other provision of this Arbitration
                Provision or the JAMS Rules, disputes in court or arbitration
                regarding the validity, enforceability, conscionability or
                breach of the Class Action Waiver, or whether the Class Action
                Waiver is void or voidable, may be resolved only by the court
                and not by an arbitrator. In any case in which (1) the dispute
                is filed as a class or collective action and (2) there is a
                final judicial determination that all or part of the Class
                Action Waiver is unenforceable, the class or collective action
                to that extent must be litigated in a civil court of competent
                jurisdiction, but the portion of the Class Action Waiver that is
                enforceable shall be enforced in arbitration.
              </li>
            </div>
            <div className="d-flex">
              <span class="font-weight-bold therapy_services_list pr-2">
                14.4.{" "}
              </span>
              <strong className="pl-3"> Representative Action Waiver.</strong>
            </div>
            <li className="list-unstyled">
              <ol type="a">
                <div className="d-flex">
                  <span class="font-weight-bold therapy_services_list">
                    a.{" "}
                  </span>
                  <li className="list-unstyled">
                    a. To the maximum extent provided by law, both TCC and you
                    agree to bring any dispute in arbitration on an individual
                    basis only, and not on a representative basis or on behalf
                    of others. There will be no right or authority for any
                    dispute to be brought, heard or arbitrated as a
                    representative action, or for you to participate as a member
                    in any such representative proceeding (“Representative
                    Action Waiver”). Notwithstanding any other provision of this
                    Arbitration Provision or the JAMS Rules, disputes in court
                    or arbitration regarding the validity, enforceability,
                    conscionability or breach of the Representative Action
                    Waiver, or whether the Representative Action Waiver is void
                    or voidable, may be resolved only by the court and not by an
                    arbitrator. If any portion of this Representative Action
                    Waiver is found to be unenforceable or unlawful for any
                    reason (1) any representative claims subject to the
                    unenforceable or unlawful portion(s) shall proceed in a
                    civil court of competent jurisdiction; (2) the portion of
                    the Representative Action Waiver that is enforceable shall
                    be enforced in arbitration; (3) the unenforceable or
                    unlawful provision shall be severed from this Agreement; and
                    (4) severance of the unenforceable or unlawful provision
                    shall have no impact whatsoever on the Arbitration Provision
                    or the arbitrability of any remaining claims asserted by you
                    or us.
                  </li>
                </div>
              </ol>
            </li>
          </ol>
          <p className="py-3">
            <strong>
              {" "}
              By checking the box, I acknowledge that I have read, understood,
              and considered the consequences of this Agreement, that I agree to
              be bound by the terms of this Agreement, and that I am legally{" "}
            </strong>
          </p>
        </div>
      </div>
    </>
  );
};

export default PlatformAccessAgreement;
