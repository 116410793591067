import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Col, Row } from "react-bootstrap";
import Forms from "react-bootstrap/Form";
import Select from "react-select";
import Digno from "../../images/patient/diagnosis_icon.svg";
import gender from "../../images/patient/sex_icon.svg";
import Dob from "../../images/patient/birthdate_icon.svg";
import insurence from "../../images/patient/insurance.svg";
import agency from "../../images/patient/agency_black_icon.svg";
import phone from "../../images/patient/call_black_icon.svg";
import Phycian from "../../images/patient/physician_black_icon.svg";
import Emergency from "../../images/patient/special-requests.svg";
import specialRequest from "../../images/patient/emergency_contact_name_icon.svg";
import email from "../../images/patient/email_address_icon.svg";
import PatientImg from "../../images/noun-add-user-1309164.svg";
import LocationImg from "../../images/location-icon.svg";
import PhoneIcon from "../../images/noun-call-1995469.svg";
import AllergiesIcon from "../../images/noun-germs-3197181.svg";
import NPI_Icon from "../../images/NPI_Icon.svg";

import {
  genderOptions,
  insurenceOptions,
  phoneNumberToUSFormat,
} from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  getStaffingDetailsById,
  updateStaffDetail,
  updateStaffingDetailsById,
} from "../../Redux/actions/staffing";
import { useNavigate, useParams } from "react-router-dom";
import { currentRole, decodeData } from "../../utils/auth";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { getTabActiveStatus } from "../../Redux/actions/common";

const PatientForm = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [alternatePhNo, setAlternatePhNo] = useState(false);

  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const staffDetail = useSelector((e) => e?.staffingSlice?.staffDetail);
  const isLoading = useSelector((e) => e?.staffingSlice?.isLoading);
  const staffTabDetails = useSelector((e) => e?.commonSlice?.staffTabDetails);
  const currentUserRole = currentUser && currentUser.Role;

  const profileInititalValue = (event) => {
    if (event && Object.keys(event).length > 0) {
      return event;
    }
    let initialvalues = {};
    if (staffDetail) {
      initialvalues.lastName = staffDetail?.lastName;
      initialvalues.firstName = staffDetail?.firstName;
      initialvalues.email = staffDetail?.email;
      initialvalues.gender = staffDetail?.staffingDetails?.gender;
      initialvalues.mrn = staffDetail?.staffingDetails?.mrn;

      if (staffDetail?.createdByUser) {
        initialvalues.agencyName = staffDetail?.createdByUser?.name;
        initialvalues.agencyPhoneNumber =
          staffDetail?.createdByUser?.userDetail?.phone;
      }

      if (staffDetail?.staffingDetails) {
        initialvalues.phoneNo = staffDetail?.staffingDetails?.phoneNo;
        initialvalues.state = staffDetail?.staffingDetails?.state;
        initialvalues.address = staffDetail?.staffingDetails?.address;
        initialvalues.street = staffDetail?.staffingDetails?.street;
        initialvalues.country = staffDetail?.staffingDetails?.country;
        initialvalues.city = staffDetail?.staffingDetails?.city;
        initialvalues.zip = staffDetail?.staffingDetails?.zip;
        initialvalues.latitude = staffDetail?.staffingDetails?.latitude;
        initialvalues.longitude = staffDetail?.staffingDetails?.longitude;
        initialvalues.unitApt = staffDetail?.staffingDetails.unitApt;
        initialvalues.npi = staffDetail?.staffingDetails.npi;
        if (staffDetail.staffingDetails.alternatePhoneNo) {
          initialvalues.alternatePhoneNo =
            staffDetail?.staffingDetails.alternatePhoneNo;
        }
        if (staffDetail.staffingDetails.dateOfBirth) {
          const dateOfBirth =
            staffDetail.staffingDetails.dateOfBirth.split("T");
          initialvalues.dateOfBirth = dateOfBirth[0];
        }

        const primaryDiagnoses = {};
        staffDetail?.staffingDiagnosisdetails &&
          staffDetail?.staffingDiagnosisdetails.filter((item) => {
            if (item.types === "primarydiagnosis") {
              primaryDiagnoses.value = item.masterData.id;
              primaryDiagnoses.label = item.masterData.displayName;
            }
          });
        initialvalues.primaryDiagnosis = primaryDiagnoses;
        initialvalues.allergies = staffDetail?.staffingDetails?.allergies;
        initialvalues.insurance = staffDetail?.staffingDetails?.insurance;
        initialvalues.otherSpecialRequest =
          staffDetail?.staffingDetails?.otherSpecialRequest;
        initialvalues.physicianName =
          staffDetail?.staffingDetails?.physicianName;
        initialvalues.physicianPhoneNumber =
          staffDetail?.staffingDetails?.physicianPhoneNumber;
        initialvalues.emergencyContactName =
          staffDetail?.staffingDetails?.emergencyContactName;
        initialvalues.emergencyContactNumber =
          staffDetail?.staffingDetails?.emergencyContactNumber;

        if (staffDetail?.staffingDetails?.disciplinesNeeded) {
          const disciplines = Object.keys(
            staffDetail?.staffingDetails?.disciplinesNeeded
          );
          initialvalues.disciplines = disciplines;
        }
      }
    }
    return initialvalues;
  };
  const styles = {
    control: (base) => ({
      ...base,
      fontFamily: "Times New Roman",
      fontSize: "16px",
      paddingLeft: "10px",
    }),
    menu: (base) => ({
      ...base,
      fontFamily: "Times New Roman",
      fontSize: "16px",
    }),
  };

  const onSubmit = (values) => {
    let tempValues = { ...values };
    let formData = new FormData();
    tempValues.id = props.staffUserId
      ? props.staffUserId
      : decodeData(params.id);
    tempValues.primaryDiagnosisId = values.primaryDiagnosis?.value;
    if (!alternatePhNo) {
      delete tempValues.alternatePhoneNo;
    }
    delete tempValues.primaryDiagnosis;
    formData.append("staffingProfileUpdate", JSON.stringify(tempValues));
    if (
      currentUser &&
      currentUser?.Role &&
      currentUser?.Role?.key &&
      currentUser?.Role?.key === currentRole.agency &&
      tempValues
    ) {
      dispatch(updateStaffDetail(formData)).then((res) => {
        if (res && res.payload && res.payload.success) {
          dispatch(
            getTabActiveStatus({
              userId: props.staffUserId
                ? props.staffUserId
                : decodeData(params.id),
            })
          ).then((res) => {
            if (
              !res.payload.isStaffingPending &&
              !res.payload.isDocumentPending
            ) {
              props.handleClose && props.handleClose(true);
            }
          });
          dispatch(
            updateStaffingDetailsById(
              props.staffUserId ? props.staffUserId : decodeData(params.id)
            )
          );
          toast.success("Profile updated successfully.");
        } else {
          if (res.payload.message) {
            toast.error(`${res.payload.message}.`);
          } else {
            toast.error("An error Occured.");
          }
        }
      });
    }
  };
  const validateForm = (values) => {
    let errors = {};
    const numbervalidate = new RegExp(/^[0-9]{10}$/);

    if (!values.firstName || values.firstName === "") {
      errors["firstName"] = "First name is required.";
    }
    if (!values.lastName || values.lastName === "") {
      errors["lastName"] = "Last name is required.";
    }
    if (!values.state) {
      errors["state"] = "State is required.";
    }
    if (!values.address) {
      errors["address"] = "Street is required.";
    }
    if (!values.npi) {
      errors["npi"] = "NPI is required.";
    }
    if (!values.insurance) {
      errors["insurance"] = "Insurance is required.";
    }
    if (!values.physicianPhoneNumber) {
      errors["physicianPhoneNumber"] = "Physician phone number is required.";
    }
    if (!values.emergencyContactNumber) {
      errors["emergencyContactNumber"] =
        "Emergency contact number is required.";
    }
    if (!values.emergencyContactName) {
      errors["emergencyContactName"] = "Emergency contact name is required.";
    }
    if (!values.physicianName) {
      errors["physicianName"] = "Physician name is required.";
    }
    if (!values.agencyPhoneNumber) {
      errors["agencyPhoneNumber"] = "Agency phone number is required.";
    }
    if (
      values.otherSpecialRequest &&
      values.otherSpecialRequest.trim() === ""
    ) {
      errors["otherSpecialRequest"] = "Special request is required.";
    }
    if (!values.phoneNo) {
      errors["phoneNo"] = "Phone number is required.";
    } else if (values.phoneNo && values.phoneNo.match(/\d/g).length !== 10) {
      errors["phoneNo"] = "Please enter 10 digits only.";
    }
    if (alternatePhNo && !values.alternatePhoneNo) {
      errors["alternatePhoneNo"] = "Alternate number is required.";
    }
    if (
      values.alternatePhoneNo &&
      values.alternatePhoneNo.match(/\d/g).length !== 10
    ) {
      errors["alternatePhoneNo"] = "Please enter 10 digits only.";
    }

    if (!values.city) {
      errors["city"] = "City is required.";
    }

    if (!values.zip) {
      errors["zip"] = "Zip is required.";
    }

    if (!values.dateOfBirth) {
      errors["dateOfBirth"] = "Date of birth is required.";
    }
    if (!values.gender) {
      errors["gender"] = "Gender is required.";
    }
    if (!values.email) {
      errors["email"] = "Email is required.";
    }
    if (
      values.agencyPhoneNumber &&
      values.agencyPhoneNumber.match(/\d/g).length !== 10
    ) {
      errors["agencyPhoneNumber"] = "Phone number should be of 10 digit.";
    }

    if (
      values.physicianPhoneNumber &&
      values.physicianPhoneNumber.match(/\d/g).length !== 10
    ) {
      errors["physicianPhoneNumber"] = "Phone number should be of 10 digit.";
    }

    if (
      values.emergencyContactNumber &&
      values.emergencyContactNumber.match(/\d/g).length !== 10
    ) {
      errors["emergencyContactNumber"] = "Phone number should be of 10 digit.";
    }
    return errors;
  };

  const isDisabled = (value) => {
    if (currentUserRole?.key === currentRole.agency)
      return value ? true : false;
    return true;
  };

  return (
    <>
      {isLoading && <Loader />}
      <Form
        keepDirtyOnReinitialize
        validate={validateForm}
        initialValues={profileInititalValue()}
        onSubmit={onSubmit}
        render={({ handleSubmit, values, form, valid }) => (
          <form
            onSubmit={(e) => {
              if (!valid) toast.error("Please complete all required fields.");
              handleSubmit(e);
            }}
          >
            <div className="container-fluid">
              <div
                className={`sub_heading mb-1 d-flex justify-content-between align-items-start ${
                  currentUser?.Role?.key !== currentRole.admin ? "" : "mb-4"
                }`}
              >
                <h2 className="patient_document-tabs m-0">
                  Patient Profile{" "}
                  {staffTabDetails?.isStaffingPending && (
                    <span className="required_lable">* Required Fields</span>
                  )}
                </h2>

                {currentUser?.Role?.key === currentRole.agency && (
                  <div className="profile_tabs-update">
                    <button type="submit" className="AddStaff-save">
                      Save
                    </button>
                  </div>
                )}
              </div>
              <div className="d-flex gap-3 align-items-start margin-1_rem">
                <img width={31} src={PatientImg} alt="" className="mt-2" />

                <div className="row w-100 ">
                  <div className="col-md-12 col-lg-10 col-xl-8">
                    <div className="row">
                      <div className="col-md-12 d-flex mobile_wrap ">
                        <div className="d-flex align-items-start gap-3  mobile_width col-md-4">
                          <div className="form-group  mobile_width w-100">
                            <Field name="firstName">
                              {({ input, meta }) => (
                                <div className=" mobile_width">
                                  <label className="patient_details_label document-tab ">
                                    Patient First Name{" "}
                                    {staffTabDetails?.isStaffingPending && (
                                      <span className="required_lable ml-0">
                                        *
                                      </span>
                                    )}
                                  </label>
                                  <input
                                    className=" mobile_width"
                                    type="text"
                                    {...input}
                                    disabled={isDisabled(meta.initial)}
                                  />
                                  {meta.error && meta.touched && (
                                    <p className="alert alert-danger mb-3 ">
                                      {meta.error}
                                    </p>
                                  )}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="d-flex align-items-start gap-3  mobile_width col-md-4">
                          <div className="form-group mobile_width w-100">
                            <Field name="lastName">
                              {({ input, meta }) => (
                                <div>
                                  <label className="patient_details_label document-tab ">
                                    Patient Last Name{" "}
                                    {staffTabDetails?.isStaffingPending && (
                                      <span className="required_lable ml-0">
                                        *
                                      </span>
                                    )}
                                  </label>
                                  <input
                                    className=" mobile_width"
                                    type="text"
                                    {...input}
                                    disabled={isDisabled(meta.initial)}
                                  />
                                  {meta.error && meta.touched && (
                                    <p className="alert alert-danger mb-3 ">
                                      {meta.error}
                                    </p>
                                  )}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="d-flex align-items-start gap-3  mobile_width col-md-4">
                          <div className="form-group  mobile_width w-100">
                            <Field name="mrn">
                              {({ input, meta }) => (
                                <div>
                                  <label className="patient_details_label document-tab ">
                                    MRN{" "}
                                    {staffTabDetails?.isStaffingPending && (
                                      <span className="required_lable ml-0">
                                        *
                                      </span>
                                    )}
                                  </label>
                                  <input
                                    className=" mobile_width"
                                    type="text"
                                    {...input}
                                    disabled={isDisabled(meta.initial)}
                                  />
                                  {meta.error && meta.touched && (
                                    <p className="alert alert-danger mb-3 ">
                                      {meta.error}
                                    </p>
                                  )}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-start gap-3  margin-1_rem">
                <img width={31} src={LocationImg} alt="" className="mt-2" />

                <div className="row  w-100">
                  <div className="col-md-12 col-lg-10 col-xl-8">
                    <div className="row ">
                      <div className="col-md-12 d-flex mobile_wrap ">
                        <div className="d-flex align-items-start gap-3  mobile_width col-md-4">
                          <div className="form-group mobile_width w-100">
                            <Field name="address">
                              {({ input, meta }) => (
                                <div>
                                  <label className="patient_details_label document-tab ">
                                    Street{" "}
                                    {staffTabDetails?.isStaffingPending && (
                                      <span className="required_lable ml-0">
                                        *
                                      </span>
                                    )}
                                  </label>

                                  <input
                                    className="mobile_width"
                                    type="text"
                                    {...input}
                                    disabled={isDisabled(meta.initial)}
                                  />
                                  {meta.error && meta.touched && (
                                    <p className="alert alert-danger mb-3 ">
                                      {meta.error}
                                    </p>
                                  )}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="d-flex align-items-start gap-3  mobile_width col-md-4">
                          <div className="form-group mobile_width w-100">
                            <Field name="unitApt">
                              {({ input, meta }) => (
                                <div>
                                  <label className="patient_details_label document-tab ">
                                    UNIT/APT{" "}
                                    {staffTabDetails?.isStaffingPending}
                                  </label>
                                  <input
                                    className="mobile_width"
                                    type="text"
                                    placeholder="Unit/Apt #(if applicable) "
                                    {...input}
                                    disabled={isDisabled(meta.initial)}
                                  />
                                  {meta.error && meta.touched && (
                                    <p className="alert alert-danger mb-3 ">
                                      {meta.error}
                                    </p>
                                  )}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="d-flex align-items-start gap-3  mobile_width col-md-4">
                          <div className="form-group mobile_width w-100">
                            <Field name="city">
                              {({ input, meta }) => (
                                <div>
                                  <label className="patient_details_label document-tab ">
                                    City{" "}
                                    {staffTabDetails?.isStaffingPending && (
                                      <span className="required_lable ml-0">
                                        *
                                      </span>
                                    )}
                                  </label>

                                  <input
                                    className="mobile_width"
                                    type="text"
                                    placeholder="City"
                                    {...input}
                                    disabled={isDisabled(meta.initial)}
                                  />
                                  {meta.error && meta.touched && (
                                    <p className="alert alert-danger mb-3 ">
                                      {meta.error}
                                    </p>
                                  )}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex align-content-start gap-3  margin-1_rem">
                <img
                  width={31}
                  src={LocationImg}
                  alt=""
                  style={{ visibility: "hidden" }}
                  className="mt-2"
                />

                <div className="row w-100">
                  <div className="col-md-12 col-lg-10 col-xl-8">
                    <div className="row  ">
                      <div className="col-md-12 d-flex mobile_wrap">
                        <div className="d-flex align-items-start gap-3  mobile_width col-md-4">
                          <div className="form-group mobile_width w-100">
                            <Field name="state">
                              {({ input, meta }) => (
                                <div>
                                  <label className="patient_details_label document-tab ">
                                    State{" "}
                                    {staffTabDetails?.isStaffingPending && (
                                      <span className="required_lable ml-0">
                                        *
                                      </span>
                                    )}
                                  </label>

                                  <input
                                    className="mobile_width"
                                    type="text"
                                    {...input}
                                    disabled={isDisabled(meta.initial)}
                                  />
                                  {meta.error && meta.touched && (
                                    <p className="alert alert-danger mb-3 ">
                                      {meta.error}
                                    </p>
                                  )}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="d-flex align-items-start gap-3  mobile_width col-md-4">
                          <div className="form-group mobile_width w-100">
                            <Field name="zip">
                              {({ input, meta }) => (
                                <div>
                                  <label className="patient_details_label document-tab ">
                                    Zip{" "}
                                    {staffTabDetails?.isStaffingPending && (
                                      <span className="required_lable ml-0">
                                        *
                                      </span>
                                    )}
                                  </label>

                                  <input
                                    className="mobile_width"
                                    type="text"
                                    placeholder="Zip"
                                    {...input}
                                    disabled={isDisabled(meta.initial)}
                                  />
                                  {meta.error && meta.touched && (
                                    <p className="alert alert-danger mb-3 ">
                                      {meta.error}
                                    </p>
                                  )}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        {/* <div className="d-flex align-items-start gap-3  mobile_width col-md-4">
                          <div className="form-group mobile_width w-100">
                            <Field name="npi">
                              {({ input, meta }) => (
                                <>
                                  <label className="patient_details_label document-tab ">
                                    NPI{" "}
                                    {staffTabDetails?.isStaffingPending && (
                                      <span className="required_lable ml-0">
                                        *
                                      </span>
                                    )}
                                  </label>
                                  <input
                                    className="mobile_width"
                                    {...input}
                                    disabled={isDisabled(meta.initial)}
                                    type="text"
                                    placeholder="NPI"
                                  />
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger mb-3  "
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {currentUser &&
              currentUser?.Role &&
              currentUser?.Role?.key &&
              (currentUser?.Role?.key === currentRole.supervisingtherapist ||
                currentUser?.Role?.key === currentRole.assistanttherapist) ? (
                staffDetail &&
                staffDetail.staffInitialVisit.length > 0 &&
                staffDetail?.staffInitialVisit[0]["completionDate"] && (
                  <div className="d-flex align-items-start gap-3  margin-1_rem">
                    <img width={31} src={PhoneIcon} alt="" className="mt-2" />
                    <div className="row w-100">
                      <div className="col-md-12 col-lg-10 col-xl-8">
                        <div className="row">
                          <div className="col-md-12 d-flex mobile_wrap">
                            <div className="d-flex align-items-start gap-3  mobile_width col-md-4 ">
                              <div className="form-group mobile_width w-100">
                                <Field name="phoneNo">
                                  {({ input, meta }) => (
                                    <div>
                                      <label className="patient_details_label document-tab ">
                                        Phone no{" "}
                                        {staffTabDetails?.isStaffingPending && (
                                          <span className="required_lable ml-0">
                                            *
                                          </span>
                                        )}
                                      </label>
                                      <input
                                        className="mobile_width"
                                        type="text"
                                        placeholder="Phone Number"
                                        {...input}
                                        disabled={isDisabled(meta.initial)}
                                        // maxLength={10}
                                        onInput={(e) =>
                                          phoneNumberToUSFormat(e, input)
                                        }
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                        ma
                                      />
                                      {meta.error && meta.touched && (
                                        <p className="alert alert-danger mb-3 fw-normal ">
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className="d-flex align-items-start gap-3  mobile_width col-md-4 ">
                              {values.alternatePhoneNo || alternatePhNo ? (
                                <div className="form-group mobile_width w-100">
                                  <Field name="alternatePhoneNo">
                                    {({ input, meta }) => (
                                      <div>
                                        <label className="patient_details_label document-tab ">
                                          Alternate Phone no{" "}
                                          {staffTabDetails?.isStaffingPending && (
                                            <span className="required_lable ml-0">
                                              *
                                            </span>
                                          )}
                                        </label>
                                        <input
                                          className="mobile_width"
                                          type="text"
                                          placeholder="Alternate Phone Number"
                                          {...input}
                                          disabled={isDisabled(meta.initial)}
                                          // maxLength={10}
                                          onInput={(e) =>
                                            phoneNumberToUSFormat(e, input)
                                          }
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                        />
                                        {meta.error && meta.touched && (
                                          <p className="alert alert-danger mb-3  fw-normal">
                                            {meta.error}
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                  {/* <p className="m-0" onClick={() => setAlternatePhNo(false)}>Delete</p> */}
                                </div>
                              ) : (
                                currentUser &&
                                currentUser?.Role &&
                                currentUser?.Role?.key &&
                                currentUser?.Role?.key ===
                                  currentRole.agency && (
                                  <div className="form-group-addmore mobile_width w-100">
                                    <p
                                      className="m-0 cursor-pointer"
                                      onClick={() => setAlternatePhNo(true)}
                                    >
                                      + Add More
                                    </p>
                                  </div>
                                )
                              )}
                            </div>
                            {alternatePhNo && (
                              <div className="d-flex align-items-center gap-3  mobile_width col-md-4 ">
                                <div className="form-group-addmore mobile_width w-100">
                                  <p
                                    className="m-0 cursor-pointer"
                                    onClick={() => {
                                      setAlternatePhNo(false);
                                      form.change("alternatePhoneNo", "");
                                    }}
                                  >
                                    Delete
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="d-flex align-items-start gap-3  margin-1_rem">
                  <img width={31} src={PhoneIcon} alt="" className="mt-2" />
                  <div className="row w-100">
                    <div className="col-md-12 col-lg-10 col-xl-8">
                      <div className="row">
                        <div className="col-md-12 d-flex mobile_wrap">
                          <div className="d-flex align-items-start gap-3  mobile_width col-md-4 ">
                            <div className="form-group mobile_width w-100">
                              <Field name="phoneNo">
                                {({ input, meta }) => (
                                  <div>
                                    <label className="patient_details_label document-tab ">
                                      Phone no{" "}
                                      {staffTabDetails?.isStaffingPending && (
                                        <span className="required_lable ml-0">
                                          *
                                        </span>
                                      )}
                                    </label>
                                    <input
                                      className="mobile_width"
                                      type="text"
                                      {...input}
                                      placeholder="Phone Number"
                                      disabled={isDisabled(meta.initial)}
                                      // maxLength={10}
                                      onInput={(e) =>
                                        phoneNumberToUSFormat(e, input)
                                      }
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                    {meta.error && meta.touched && (
                                      <p className="alert alert-danger mb-3 fw-normal ">
                                        {meta.error}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="d-flex align-items-start gap-3  mobile_width col-md-4 ">
                            {values.alternatePhoneNo || alternatePhNo ? (
                              <div className="form-group mobile_width w-100">
                                <Field name="alternatePhoneNo">
                                  {({ input, meta }) => (
                                    <div>
                                      <label className="patient_details_label document-tab ">
                                        Alternate Phone no{" "}
                                        {staffTabDetails?.isStaffingPending && (
                                          <span className="required_lable ml-0">
                                            *
                                          </span>
                                        )}
                                      </label>
                                      <input
                                        className="mobile_width"
                                        type="text"
                                        {...input}
                                        disabled={isDisabled(meta.initial)}
                                        // maxLength={10}
                                        onInput={(e) =>
                                          phoneNumberToUSFormat(e, input)
                                        }
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                      {meta.error && meta.touched && (
                                        <p className="alert alert-danger mb-3 fw-normal">
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>
                                {/* <p className="m-0" onClick={() => setAlternatePhNo(false)}>Delete</p> */}
                              </div>
                            ) : (
                              currentUser &&
                              currentUser?.Role &&
                              currentUser?.Role?.key &&
                              currentUser?.Role?.key === currentRole.agency && (
                                <div className="form-group-addmore mobile_width w-100">
                                  <p
                                    className="m-0 cursor-pointer"
                                    onClick={() => setAlternatePhNo(true)}
                                  >
                                    + Add More
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                          {alternatePhNo && (
                            <div className="d-flex align-items-center gap-3  mobile_width col-md-4 ">
                              <div className="form-group-addmore mobile_width w-100">
                                <p
                                  className="m-0 cursor-pointer"
                                  onClick={() => {
                                    setAlternatePhNo(false);
                                    form.change("alternatePhoneNo", "");
                                  }}
                                >
                                  Delete
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="d-flex align-items-start gap-3  margin-1_rem">
                <img width={31} src={AllergiesIcon} alt="" className="mt-2" />

                <div className="row  w-100">
                  <div className="col-md-12 col-lg-10 col-xl-8">
                    <div className="row ">
                      <div className="col-md-12 d-flex mobile_wrap gap-4 ">
                        <div className="d-flex align-items-start gap-3  mobile_width col-md-4">
                          <div className="form-group mobile_width w-100">
                            <Field name="allergies">
                              {({ input, meta }) => (
                                <>
                                  <label className="patient_details_label document-tab ">
                                    Allergies
                                  </label>
                                  <input
                                    className="mobile_width"
                                    {...input}
                                    disabled={isDisabled(meta.initial)}
                                    type="text"
                                    placeholder="Allergies"
                                  />
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger mb-3  "
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="patient-color shadow-none mt-5 p-0">
              <div className="container-fluid">
                <Row>
                  <div className=" Referral-info mb-25 align-items-start col-lg-3 col-md-4 gap-3 ">
                    <div className="referral_icon" style={{ color: "#0A365B" }}>
                      <img
                        src={Digno}
                        className="Common-referral-icons"
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-column w-100 mobile_width">
                      <label
                        className="patient_details_label document-tab referral_name "
                        style={{ fontSize: "1rem" }}
                      >
                        Diagnosis
                        {staffTabDetails?.isStaffingPending && (
                          <span className="required_lable ml-0"> *</span>
                        )}
                      </label>
                      <Field name="primaryDiagnosis">
                        {({ input, meta }) => (
                          <>
                            <Select
                              {...input}
                              isDisabled={isDisabled(meta.initial)}
                              styles={styles}
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger mb-3 ">
                                {meta.error}
                              </p>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="Referral-info mb-25 align-items-start col-lg-3 col-md-4 gap-3">
                    <div className="referral_icon">
                      <img
                        src={gender}
                        className="Common-referral-icons"
                        alt="genderImage"
                      />
                    </div>
                    <div className="d-flex flex-column w-100 mobile_width">
                      <label
                        className="patient_details_label document-tab referral_name"
                        style={{ fontSize: "1rem" }}
                      >
                        Sex
                        {staffTabDetails?.isStaffingPending && (
                          <span className="required_lable ml-0"> *</span>
                        )}
                      </label>
                      <Field name="gender">
                        {({ input, meta }) => (
                          <>
                            <select
                              {...input}
                              disabled={isDisabled(meta.initial)}
                              className="paitiant-chart-input"
                            >
                              {genderOptions?.map((item, index) => (
                                <option value={item?.value} key={index}>
                                  {item?.value}
                                </option>
                              ))}
                            </select>
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger mb-3 ">
                                {meta.error}
                              </p>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className=" Referral-info mb-25 align-items-start col-lg-3 col-md-4 gap-3">
                    <div className="referral_icon" style={{ color: "#0A365B" }}>
                      <img src={Dob} className="Common-referral-icons" alt="" />
                    </div>
                    <div className="d-flex flex-column w-100 mobile_width">
                      <p
                        className="referral_name patient_details_label document-tab "
                        style={{ fontSize: "1rem" }}
                      >
                        DATE OF BIRTH
                        {staffTabDetails?.isStaffingPending && (
                          <span className="required_lable ml-0"> *</span>
                        )}
                      </p>
                      <p
                        className="referral_value m-0"
                        style={{ fontSize: "1rem", color: "#0A365B" }}
                      >
                        <div className="form-group">
                          <Field name="dateOfBirth">
                            {({ input, meta }) => (
                              <div>
                                <input
                                  type="date"
                                  {...input}
                                  disabled={isDisabled(meta.initial)}
                                />
                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger mb-3 ">
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </p>
                    </div>
                  </div>
                  <div className=" Referral-info mb-25 align-items-start col-lg-3 col-md-4 gap-3">
                    <div className="referral_icon" style={{ color: "#0A365B" }}>
                      <img
                        src={insurence}
                        className="Common-referral-icons"
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-column w-100 mobile_width">
                      <p
                        className="referral_name patient_details_label document-tab "
                        style={{ fontSize: "1rem" }}
                      >
                        INSURANCE{" "}
                        {staffTabDetails?.isStaffingPending && (
                          <span className="required_lable ml-0"> *</span>
                        )}
                      </p>
                      <p
                        className="referral_value m-0"
                        style={{ fontSize: "1rem", color: "#0A365B" }}
                      >
                        <div className="form-group">
                          <Field name="insurance">
                            {({ input, meta }) => (
                              <div>
                                <Forms.Select
                                  {...input}
                                  disabled={isDisabled(meta.initial)}
                                  className="paitiant-chart-input"
                                  type="text"
                                >
                                  <option value="">Select</option>
                                  {insurenceOptions?.map((e, index) => {
                                    return (
                                      <option value={e.value} key={index}>
                                        {e.label}
                                      </option>
                                    );
                                  })}
                                </Forms.Select>

                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger mb-3 ">
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </p>
                    </div>
                  </div>

                  <div className=" Referral-info mb-25 align-items-start col-lg-3 col-md-4 gap-3">
                    <div className="referral_icon" style={{ color: "#0A365B" }}>
                      <img
                        src={agency}
                        className="Common-referral-icons"
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-column w-100 mobile_width">
                      <p
                        className="referral_name patient_details_label document-tab "
                        style={{ fontSize: "1rem" }}
                      >
                        AGENCY{" "}
                        {staffTabDetails?.isStaffingPending && (
                          <span className="required_lable ml-0">*</span>
                        )}
                      </p>
                      <p
                        className="referral_value m-0"
                        style={{ fontSize: "1rem", color: "#0A365B" }}
                      >
                        <div className="form-group">
                          <Field name="agencyName">
                            {({ input, meta }) => (
                              <>
                                <input
                                  className="paitiant-chart-input"
                                  type="text"
                                  placeholder="Agency Name"
                                  {...input}
                                  disabled
                                />

                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger mb-3 ">
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                      </p>
                    </div>
                  </div>
                  <div className=" Referral-info mb-25 align-items-start col-lg-3 col-md-4 gap-3">
                    <div className="referral_icon" style={{ color: "#0A365B" }}>
                      <img
                        src={phone}
                        className="Common-referral-icons"
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-column w-100 mobile_width">
                      <p
                        className="referral_name patient_details_label document-tab "
                        style={{ fontSize: "1rem" }}
                      >
                        AGENCY PHONE{" "}
                        {staffTabDetails?.isStaffingPending && (
                          <span className="required_lable ml-0">*</span>
                        )}
                      </p>
                      <p
                        className="referral_value m-0"
                        style={{ fontSize: "1rem", color: "#0A365B" }}
                      >
                        <div className="form-group">
                          <Field name="agencyPhoneNumber">
                            {({ input, meta }) => (
                              <div>
                                <input
                                  className="paitiant-chart-input"
                                  type="text"
                                  {...input}
                                  disabled
                                  placeholder="Agency Phone Number"
                                  maxLength={10}
                                />
                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger mb-3 ">
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </p>
                    </div>
                  </div>
                  <div className=" Referral-info mb-25 align-items-start col-lg-3 col-md-4 gap-3">
                    <div className="referral_icon" style={{ color: "#0A365B" }}>
                      <img
                        src={Phycian}
                        className="Common-referral-icons"
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-column w-100 mobile_width">
                      <p
                        className="referral_name patient_details_label document-tab "
                        style={{ fontSize: "1rem" }}
                      >
                        PHYSICIAN NAME{" "}
                        {staffTabDetails?.isStaffingPending && (
                          <span className="required_lable ml-0">*</span>
                        )}
                      </p>
                      <p
                        className="referral_value m-0"
                        style={{ fontSize: "1rem", color: "#0A365B" }}
                      >
                        <div className="form-group">
                          <Field name="physicianName">
                            {({ input, meta }) => (
                              <>
                                <input
                                  className="paitiant-chart-input"
                                  type="text"
                                  placeholder="Physician Name"
                                  {...input}
                                  disabled={isDisabled(meta.initial)}
                                />
                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger mb-3 ">
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                      </p>
                    </div>
                  </div>
                  <div className=" Referral-info mb-25 align-items-start col-lg-3 col-md-4 gap-3">
                    <div className="referral_icon" style={{ color: "#0A365B" }}>
                      <img
                        src={phone}
                        className="Common-referral-icons"
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-column w-100 mobile_width">
                      <p
                        className="referral_name patient_details_label document-tab "
                        style={{ fontSize: "1rem" }}
                      >
                        PHYSICIAN PHONE{" "}
                        {staffTabDetails?.isStaffingPending && (
                          <span className="required_lable ml-0">*</span>
                        )}
                      </p>
                      <p
                        className="referral_value m-0"
                        style={{ fontSize: "1rem", color: "#0A365B" }}
                      >
                        <div className="form-group">
                          <Field name="physicianPhoneNumber">
                            {({ input, meta }) => (
                              <>
                                <input
                                  className="paitiant-chart-input"
                                  type="text"
                                  placeholder="Physician Phone Number"
                                  {...input}
                                  disabled={isDisabled(meta.initial)}
                                  // maxLength={10}
                                  onInput={(e) =>
                                    phoneNumberToUSFormat(e, input)
                                  }
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger mb-3 ">
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                      </p>
                    </div>
                  </div>
                  <Col
                    md={4}
                    lg={3}
                    className=" Referral-info mb-25 align-items-start gap-3"
                  >
                    <div className="referral_icon" style={{ color: "#0A365B" }}>
                      <img
                        src={Phycian}
                        className="Common-referral-icons"
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-column w-100 mobile_width">
                      <p
                        className="referral_name patient_details_label document-tab "
                        style={{ fontSize: "1rem" }}
                      >
                        PHYSICIAN NPI
                        {staffTabDetails?.isStaffingPending && (
                          <span className="required_lable ml-0"> *</span>
                        )}
                      </p>
                      <p
                        className="referral_value m-0"
                        style={{ fontSize: "1rem", color: "#0A365B" }}
                      >
                        <div className="form-group">
                          <Field name="npi">
                            {({ input, meta }) => (
                              <>
                                <input
                                  className="mobile_width"
                                  {...input}
                                  disabled={isDisabled(meta.initial)}
                                  type="text"
                                  placeholder="PHYSICIAN NPI"
                                />
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger mb-3  "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                      </p>
                    </div>
                  </Col>
                  <Col
                    md={4}
                    lg={3}
                    className=" Referral-info mb-25 align-items-start gap-3"
                  >
                    <div className="referral_icon" style={{ color: "#0A365B" }}>
                      <img
                        src={specialRequest}
                        className="Common-referral-icons"
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-column w-100 mobile_width">
                      <p
                        className="referral_name patient_details_label document-tab "
                        style={{ fontSize: "1rem" }}
                      >
                        EMERGENCY NAME{" "}
                        {staffTabDetails?.isStaffingPending && (
                          <span className="required_lable ml-0">*</span>
                        )}
                      </p>
                      <p
                        className="referral_value m-0"
                        style={{ fontSize: "1rem", color: "#0A365B" }}
                      >
                        <div className="form-group">
                          <Field name="emergencyContactName">
                            {({ input, meta }) => (
                              <div>
                                <input
                                  className="paitiant-chart-input"
                                  placeholder="Emergency Contact Name"
                                  type="text"
                                  {...input}
                                  disabled={isDisabled(meta.initial)}
                                />
                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger mb-3 ">
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </p>
                    </div>
                  </Col>

                  <Col
                    md={4}
                    lg={3}
                    className=" Referral-info mb-25 align-items-start gap-3"
                  >
                    <div className="referral_icon" style={{ color: "#0A365B" }}>
                      <img
                        src={phone}
                        className="Common-referral-icons"
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-column w-100 mobile_width">
                      <p
                        className="referral_name patient_details_label document-tab "
                        style={{ fontSize: "1rem" }}
                      >
                        EMERGENCY CONTACT NUMBER
                        {staffTabDetails?.isStaffingPending && (
                          <span className="required_lable ml-0"> *</span>
                        )}
                      </p>
                      <p
                        className="referral_value m-0"
                        style={{ fontSize: "1rem", color: "#0A365B" }}
                      >
                        <div className="form-group">
                          <Field name="emergencyContactNumber">
                            {({ input, meta }) => (
                              <div>
                                <input
                                  {...input}
                                  className="paitiant-chart-input"
                                  type="text"
                                  placeholder="Emergency Contact Number"
                                  // maxLength={10}
                                  onInput={(e) =>
                                    phoneNumberToUSFormat(e, input)
                                  }
                                  disabled={isDisabled(meta.initial)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  ma
                                />
                                {meta.error && meta.touched && (
                                  <p className="alert alert-danger mb-3 ">
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </p>
                    </div>
                  </Col>
                  <Col
                    md={4}
                    lg={3}
                    className=" Referral-info mb-25 align-items-start gap-3"
                  >
                    <div className="referral_icon" style={{ color: "#0A365B" }}>
                      <img
                        src={email}
                        className="Common-referral-icons"
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-column w-100 mobile_width">
                      <p
                        className="referral_name patient_details_label document-tab "
                        style={{ fontSize: "1rem" }}
                      >
                        PATIENT EMAIL ADDRESS
                        {staffTabDetails?.isStaffingPending && (
                          <span className="required_lable ml-0"> *</span>
                        )}
                      </p>
                      <p
                        className="referral_value m-0"
                        style={{ fontSize: "1rem", color: "#0A365B" }}
                      >
                        <div className="form-group">
                          <Field name="email">
                            {({ input, meta }) => (
                              <div>
                                <input
                                  className="paitiant-chart-input"
                                  type="email"
                                  placeholder="Email"
                                  {...input}
                                  disabled={isDisabled(meta.initial)}
                                />
                                {meta.touched && meta.error && (
                                  <p
                                    className="alert alert-danger mb-3  "
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </p>
                    </div>
                  </Col>
                </Row>

                <Row>
                  {" "}
                  <Col lg={12}>
                    <div className="Referral-info  align-items-start gap-3">
                      <div
                        className="referral_icon d-flex "
                        style={{ color: "#0A365B" }}
                      >
                        <img
                          src={Emergency}
                          className="Common-referral-icons"
                          alt=""
                        />
                      </div>
                      <div className="referral_info">
                        <p
                          className="referral_name patient_details_label document-tab "
                          style={{ fontSize: "1rem" }}
                        >
                          SPECIAL REQUEST/OTHER
                        </p>
                        <p
                          className="referral_value m-0"
                          style={{ fontSize: "1rem", color: "#0A365B" }}
                        >
                          <div className="form-group">
                            <Field name="otherSpecialRequest">
                              {({ input, meta }) => (
                                <div>
                                  <textarea
                                    type="text"
                                    className="text-resize"
                                    {...input}
                                    placeholder="Example - Spanish speaking male PT"
                                    disabled={isDisabled(meta.initial)}
                                    rows="3"
                                    cols="70"
                                  ></textarea>
                                  {meta.error && meta.touched && (
                                    <p className="alert alert-danger mb-3 ">
                                      {meta.error}
                                    </p>
                                  )}
                                </div>
                              )}
                            </Field>
                          </div>
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default PatientForm;
