import { Navigate, Outlet } from "react-router-dom";
import Dashboard from "../pages/Home/Dashboard";
import Login from "../pages/auth/Login";
import ResetPassword from "../pages/auth/ResetPassword";
import ForgotPassword from "../pages/auth/ForgotPassword";
import LoginLayout from "./Layouts/LoginLayout";
import DashboardLayout from "./Layouts/DashboardLayout";
import Admin from "../components/Admin";
import AgencyPatientChart from "../pages/Agency/PatientChart";
import TherapistPatientChart from "../pages/Therapist/PatientChart";
import AdminPatientChart from "../pages/Admin/PatientChat";
import Staffing from "../components/Staffing";
import NewUser from "../components/NewUser";
import BlockList from "../components/BlockList";
import NewAgency from "../pages/New User/NewAgency";
import { NewTherapist } from "../pages/New User/NewTherapist";
import AgencyReject from "../pages/New User/AgencyReject";
import TherapistReject from "../pages/New User/TherapistReject";
import PatientDetails from "../pages/Patients/PatientDetails";
import { FinalTherapist } from "../pages/New User/FinalTherapist";
import MyProfile from "../components/MyProfile";
import NotificationPreferences from "../pages/Therapist/signup/NotificationPreferences";
import ResgistrationRejected from "../pages/Therapist/signup/RegistrationRejected";
import { getToken, getUserId } from "../utils/auth";
import AgencyDefaultRates from "../pages/Agency/signup/AgencyDefaultRates";
import CreateAgencyAccount from "../pages/Agency/signup/CreateAgencyAccount";
import TherapistSignup from "../pages/Therapist/signup/TherapistSignup";
import AgencyProfile from "../pages/Agency/Profile";
import UserUnderReview from "../pages/UserUnderReview";
import Rejected from "../pages/New User/Rejected";
import StaffNewReferral from "../pages/Staffing/StaffNewReferral";
import TherapistProfile from "../pages/Therapist/TherapistProfile";
import DocuSign from "../pages/Therapist/signup/DocuSign";
import ComplianceDocuSign from "../pages/Therapist/signup/ComplianceDocuSign";
import CompetencyDocuSign from "../pages/Therapist/signup/CompetencyDocuSign";
import CompianceEnvalop from "../pages/New User/CompianceEnvalop";
import AgencyDashboard from "../pages/Agency/Dashboard";
import FindPatients from "../pages/Therapist/Staffing/FindPatients";
import AssistentFindPatients from "../pages/AssistentTherapist/Staffing/FindPatient";
import TherapistDashboard from "../pages/Therapist/Dashboard";
import AssistantTherapistDashboard from "../pages/AssistentTherapist/Dashboard";
import FluVaccinationDocuSign from "../pages/Therapist/signup/FluVaccination";
import DirectMessageModal from "../pages/modals/DirectMessageModal";
import Documentation from "../components/Documentation";
import CaseManagement from "../components/CaseManagement";
import Accounting from "../components/Accounting";
import TherapyAgreement from "../documents/ServiceAgreement/therapyAgreement";
import BusinessServiceAgreement from "../documents/ServiceAgreement/businessServiceAgreement";
import PlatformAccessAgreement from "../documents/ServiceAgreement/platformAccessAgreement";
import HhaAgreement from "../documents/ServiceAgreement/hha-Agreement";
import TherapistCredential from "../pages/TherapistCredential/TherapistCredential";
const role = getUserId() ? getUserId().role : null;
const isLoggedIn = getToken();
const protects = {
  admin: [
    {
      path: "/",
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: "/", element: <Dashboard /> },
        { path: "/login", element: <Login /> },
        { path: "agencysignup", element: <CreateAgencyAccount /> },
        { path: "/therapistsignup", element: <TherapistSignup /> },
        { path: "notification", element: <NotificationPreferences /> },
        { path: "agencyDefaultRates", element: <AgencyDefaultRates /> },
        { path: "myprofile", element: <MyProfile /> },
        { path: "myprofile/:tab", element: <MyProfile /> },
        { path: "home", element: <Dashboard /> },
        { path: "patientcharts", element: <AdminPatientChart /> },
        { path: "staffing", element: <Staffing /> },
        { path: "staffing/:tab", element: <Staffing /> },
        { path: "blocklist", element: <BlockList /> },
        { path: "newtherapist", element: <NewTherapist /> },
        { path: "userrejected/:id", element: <Rejected /> },
        { path: "adminuser", element: <Admin /> },
        { path: "patientdetails/:id", element: <PatientDetails /> },
        { path: "patientdetails/:id/:tabs", element: <PatientDetails /> },
        { path: "newusers/therapist", element: <FinalTherapist /> },
        { path: "compianceenvalop", element: <CompianceEnvalop /> },
        { path: "contactus", element: <DirectMessageModal /> },
        { path: "documentation", element: <Documentation /> },
        { path: "casemanagement", element: <CaseManagement /> },
        { path: "casemanagement/:tab", element: <CaseManagement /> },
        { path: "documentation/:tab", element: <Documentation /> },
        { path: "accounting/:tab", element: <Accounting /> },
        { path: "*", element: <div>no page found</div> },
        { path: "accounting", element: <Accounting /> },
        { path: "/therapistcredential", element: <TherapistCredential /> },
        {
          path: "/newusers",
          element: <Outlet />,
          children: [
            {
              path: "agency/",
              element: <Outlet />,
              children: [
                { path: "rejected/:id", element: <AgencyReject /> },
                { path: ":id", element: <NewAgency /> },
              ],
            },
            {
              path: "therapist/",
              element: <Outlet />,
              children: [
                { path: "rejected/:id", element: <TherapistReject /> },
                { path: ":id", element: <NewTherapist /> },
              ],
            },
            { path: "", element: <NewUser /> },
          ],
        },
      ],
    },
  ],
  superadmin: [
    {
      path: "/",
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: "/", element: <Dashboard /> },
        { path: "/login", element: <Login /> },
        { path: "agencysignup", element: <CreateAgencyAccount /> },
        { path: "/therapistsignup", element: <TherapistSignup /> },
        { path: "/staffing/:tab", element: <Staffing /> },

        { path: "notification", element: <NotificationPreferences /> },
        { path: "agencyDefaultRates", element: <AgencyDefaultRates /> },
        { path: "myprofile", element: <MyProfile /> },
        { path: "home", element: <Dashboard /> },
        { path: "patientcharts", element: <AdminPatientChart /> },
        { path: "staffing", element: <Staffing /> },
        { path: "documentation", element: <Documentation /> },
        { path: "blocklist", element: <BlockList /> },
        { path: "newtherapist", element: <NewTherapist /> },
        { path: "userrejected/:id", element: <Rejected /> },
        { path: "adminuser", element: <Admin /> },
        { path: "patientdetails/:id", element: <PatientDetails /> },
        { path: "patientdetails/:id/:tabs", element: <PatientDetails /> },
        { path: "newusers/therapist", element: <FinalTherapist /> },
        { path: "contactus", element: <DirectMessageModal /> },
        { path: "*", element: <div>no page found</div> },
        { path: "accounting", element: <Accounting /> },
        { path: "casemanagement", element: <CaseManagement /> },
        { path: "casemanagement/:tab", element: <CaseManagement /> },
        {
          path: "/newusers",
          element: <Outlet />,
          children: [
            {
              path: "agency/",
              element: <Outlet />,
              children: [
                { path: "rejected/:id", element: <AgencyReject /> },
                { path: ":id", element: <NewAgency /> },
              ],
            },
            {
              path: "therapist/",
              element: <Outlet />,
              children: [
                { path: "rejected/:id", element: <TherapistReject /> },
                { path: ":id", element: <NewTherapist /> },
              ],
            },
            { path: "", element: <NewUser /> },
          ],
        },
      ],
    },
  ],
  agency: [
    {
      path: "/",
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: "/", element: <AgencyDashboard /> },
        { path: "/login", element: <Login /> },
        { path: "/home", element: <AgencyDashboard /> },
        { path: "patientdetails/:id", element: <PatientDetails /> },
        { path: "patientdetails/:id/:tabs", element: <PatientDetails /> },
        { path: "/createagencyaccount", element: <CreateAgencyAccount /> },
        { path: "/registrationrejected", element: <ResgistrationRejected /> },
        { path: "myprofile", element: <AgencyProfile /> },
        { path: "myprofile/:tabs", element: <AgencyProfile /> },
        { path: "staffing", element: <Staffing /> },
        { path: "staffing/:tab", element: <Staffing /> },
        { path: "patientcharts", element: <AgencyPatientChart /> },
        { path: "/staffing/staff-new-referral", element: <StaffNewReferral /> },
        { path: "contactus", element: <DirectMessageModal /> },
        { path: "documentation", element: <Documentation /> },
        { path: "casemanagement", element: <CaseManagement /> },
        { path: "casemanagement/:tab", element: <CaseManagement /> },
        { path: "documentation/:tab", element: <Documentation /> },
        { path: "accounting/:tab", element: <Accounting /> },
        { path: "/therapistcredential", element: <TherapistCredential /> },
        { path: "accounting", element: <Accounting /> },

        { path: "*", element: <div>no page found</div> },
      ],
    },
  ],
  supervisingtherapist: [
    {
      path: "/",
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: "/", element: <TherapistDashboard /> },
        { path: "/login", element: <Login /> },
        { path: "/home", element: <TherapistDashboard /> },
        { path: "patientcharts", element: <TherapistPatientChart /> },
        { path: "patientdetails/:id", element: <PatientDetails /> },
        { path: "patientdetails/:id/:tabs", element: <PatientDetails /> },
        { path: "/createagencyaccount", element: <CreateAgencyAccount /> },
        { path: "/registrationrejected", element: <ResgistrationRejected /> },
        { path: "myprofile", element: <TherapistProfile /> },
        // { path: "myprofile/:id", element: <TherapistProfile /> },
        { path: "myprofile/:tabs", element: <TherapistProfile /> },
        { path: "findpatients", element: <FindPatients /> },
        { path: "findpatients/:menuKey", element: <FindPatients /> },
        { path: "/docusign", element: <DocuSign /> },
        { path: "/compliancedocusign", element: <ComplianceDocuSign /> },
        { path: "/competencydocusign", element: <CompetencyDocuSign /> },
        { path: "contactus", element: <DirectMessageModal /> },
        { path: "documentation", element: <Documentation /> },
        { path: "casemanagement", element: <CaseManagement /> },
        { path: "casemanagement/:tab", element: <CaseManagement /> },
        { path: "documentation/:tab", element: <Documentation /> },
        { path: "accounting/:tab", element: <Accounting /> },
        { path: "findpatients/:tab", element: <FindPatients /> },

        { path: "accounting", element: <Accounting /> },

        {
          path: "/fluvaccinationdocusign",
          element: <FluVaccinationDocuSign />,
        },
        { path: "*", element: <div>no page found</div> },
      ],
    },
  ],
  assistanttherapist: [
    {
      path: "/",
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: "/", element: <AssistantTherapistDashboard /> },
        { path: "/login", element: <Login /> },
        { path: "/home", element: <AssistantTherapistDashboard /> },
        { path: "patientcharts", element: <TherapistPatientChart /> },
        { path: "patientdetails/:id", element: <PatientDetails /> },
        { path: "patientdetails/:id/:tabs", element: <PatientDetails /> },
        { path: "/registrationrejected", element: <ResgistrationRejected /> },
        { path: "myprofile", element: <TherapistProfile /> },
        { path: "myprofile/:id", element: <TherapistProfile /> },
        { path: "assistent/findpatients", element: <AssistentFindPatients /> },
        { path: "/docusign", element: <DocuSign /> },
        { path: "/compliancedocusign", element: <ComplianceDocuSign /> },
        { path: "/competencydocusign", element: <CompetencyDocuSign /> },
        { path: "documentation", element: <Documentation /> },
        { path: "casemanagement", element: <CaseManagement /> },
        { path: "casemanagement/:tab", element: <CaseManagement /> },
        { path: "documentation/:tab", element: <Documentation /> },
        { path: "accounting/:tab", element: <Accounting /> },

        {
          path: "assistent/findpatients/:tab",
          element: <AssistentFindPatients />,
        },
        { path: "accounting", element: <Accounting /> },

        {
          path: "/fluvaccinationdocusign",
          element: <FluVaccinationDocuSign />,
        },
        { path: "contactus", element: <DirectMessageModal /> },
        { path: "*", element: <div>no page found</div> },
      ],
    },
  ],
  default: [
    {
      path: "/",
      element: <LoginLayout />,
      children: [
        { path: "/", element: <Login /> },

        { path: "accounting", element: <Accounting /> },
        { path: "/login", element: <Login /> },
        { path: "/resetpassword/:id", element: <ResetPassword /> },
        { path: "/forgotpassword", element: <ForgotPassword /> },
        { path: "*", element: <Login /> },
        { path: "agencysignup", element: <CreateAgencyAccount /> },
        { path: "/supervisingtherapist", element: <TherapistSignup /> },
        { path: "/assistanttherapist", element: <TherapistSignup /> },
        { path: "/UserUnderReview", element: <UserUnderReview /> },
        { path: "/docusign", element: <DocuSign /> },
        { path: "/compliancedocusign", element: <ComplianceDocuSign /> },
        { path: "/competencydocusign", element: <CompetencyDocuSign /> },
        {
          path: "/fluvaccinationdocusign",
          element: <FluVaccinationDocuSign />,
        },
        { path: "userrejected/:id", element: <Rejected /> },
      ],
    },
  ],
};

export const protect =
  role && isLoggedIn ? protects[role] : protects["default"];

export const defaultProtect = protects["default"];
