import React, { useEffect, useState } from "react";
import profili from "../../../src/images/profilr-logo.png";
import { currentRole, decodeData } from "../../utils/auth";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { HiPlus } from "react-icons/hi";
import { getStaffAssignments } from "../../Redux/actions/common";
import { useParams } from "react-router-dom";
import { chatIconsByRole } from "../../utils/helper";
import { apiBasePath } from "../../Redux/config/config";

const PatientStaffTab = (props) => {
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const staffassignments = useSelector((e) => e.commonSlice.staffassignments);
  const dispatch = useDispatch();
  const params = useParams();
  const { staffUserId } = props;

  useEffect(() => {
    dispatch(
      getStaffAssignments({
        staffUserId: staffUserId ? staffUserId : decodeData(params.id),
        key: "all",
      })
    );
  }, []);

  return (
    <>
      <div className="  mx-0 p-0 mb-3">
        <div className=" staff_asingment">
          <div
            className={`sub_heading mb-1 d-flex justify-content-between align-items-start ${
              currentUser?.Role?.key !== currentRole.admin ? "" : "mb-4"
            }`}
          >
            <h2 className="patient_document-tabs m-0">Staff Assignments </h2>

            {currentUser?.Role?.key !== currentRole.admin && (
              <button className="AddStaff-discipline">
                <HiPlus size={24} style={{ marginRight: "5px" }} />
                Staff a New Discipline
              </button>
            )}
          </div>

          <table className="mobile_table-responsive-md table pt-0 patient_details document-tab ">
            <thead>
              <tr>
                <th scope="col-2">Staff Name</th>
                <th scope="col-2">Discipline</th>
                <th scope="col-2"></th>
                <th scope="col-2"></th>
                <th scope="col-2"></th>
              </tr>
            </thead>

            <tbody>
              {staffassignments &&
                staffassignments.length > 0 &&
                staffassignments.map((e, i) => {
                  return (
                    <tr scope="row" key={i}>
                      <td className="col-3">
                        <img
                          src={
                            e?.therapist?.image
                              ? `${apiBasePath}documents/therapist/${e?.therapist?.image}`
                              : chatIconsByRole.supervisingtherapist
                          }
                          alt=""
                          height="25px"
                          width="30px"
                          crossOrigin="anonymous"
                          className="mx-2"
                        />
                        {e.therapist.lastName + ", " + e.therapist.firstName}
                      </td>
                      <td>
                        {e?.therapist?.userDetailss[0]?.discipline?.toUpperCase()}
                      </td>
                      <td></td>
                      <td></td>
                      <td>
                        <Dropdown className="d-inline d-flex justify-content-end w-100 dropdown-stafftab">
                          <Dropdown.Toggle id="dropdown-autoclose-true">
                            ...
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#">
                              Restaff Therapist
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                              Staff Temporary Coverages
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PatientStaffTab;
