import React, { useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { currentRole } from "../../../utils/auth";
import Select from "react-select";
import { savePatientForm } from "../../../Redux/actions/staffing";
import {
  canSubmitForm,
  changeDateFormat,
  staffFormTypesConstants,
  visitStatus,
} from "../../../utils/helper";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {
  getEvalForm,
  getStaffFormdataById,
} from "../../../Redux/actions/patientGetDocTypes";
import SignatureCanvas from "../../../components/common/SignatureCanvas";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { getLastGoalsValue } from "../../../Redux/actions/common";

const NonVisitDischarge = (props) => {
  const totalDefaultGoals = 8;
  let defaultGoalObj = {
    goal: "",
    effectiveDate: "",
    currentStatus: "",
  };
  const {
    closeFormModal,
    visitDetail,
    dataAvailable,
    formId,
    isPrint,
    IsUpdated,
    confirmDate,
    toSave,
    statusHandler,
  } = props;

  const [signatureAdded, setSignatureAdded] = useState(null);
  const [TherapistsignatureError, setTherapistsignatureError] = useState(null);
  const [displaySignature, setDisplaySignature] = useState(null);
  const [isCanvasShow, setIsCanvasShow] = useState(false);
  const [show, setShow] = useState(false);

  const staffFormData = useSelector((e) => e.commonSlice.staffForm);
  const staffDetails = useSelector((state) => state.staffingSlice.staffDetail);
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);

  const staffDocument = useSelector((e) => e.commonSlice.staffDocument);
  const visitDetailById = useSelector(
    (data) => data?.patientGetDocTypesSlice?.visitDetail
  );
  const evaluationFormData = useSelector(
    (e) => e.commonSlice.evaluationFormData
  );
  const lastGoals = useSelector((e) => e.commonSlice.lastGoals);
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const dischargeReasonData = [
    "Goals met",
    "Plateau in progress",
    "Patient refused",
    "Patient relocation",
    "Transfer to outpatient therapy",
    "Transfer to in-patient rehab or alternative care program",
    "Transfer to hospice",
    "No further insurance authorization",
    "Lack of funds",
    "Other",
  ];
  const instructionProvidedToData = ["Patient", "Caregiver/Family"];
  const dischargeInstructionsData = [
    "Patient/Caregiver/Family : understood discharge instructions",
    "Patient/Caregiver/Family : unable to understood discharge instructions due to",
  ];
  const care_coordination = [
    "Physician",
    "Case Manager",
    "Clinical Manager",
    "SN",
    "PT",
    "PTA",
    "OT",
    "COTA",
    "ST",
    "Aide",
    "MSW",
    "Other",
  ];

  const initValues = () => {
    if (
      staffFormData &&
      staffFormData.formData &&
      staffFormData.MasterDatas.name === staffFormTypesConstants.NVDC
    ) {
      let goals = [];

      for (let index = 0; index < totalDefaultGoals; index++) {
        let goalObj = JSON.parse(JSON.stringify(defaultGoalObj));
        if (staffDocument && staffDocument.length > 0) {
          goalObj["effectiveDate"] =
            staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
        }
        if (
          staffFormData.formData &&
          staffFormData.formData.goals &&
          staffFormData.formData.goals.length > 0 &&
          staffFormData.formData.goals[index]
        ) {
          goalObj = staffFormData.formData.goals[index];
        }
        goals.push(goalObj);
      }
      let formData = JSON.parse(JSON.stringify(staffFormData));
      formData.formData.goals = goals;
      if (
        evaluationFormData &&
        evaluationFormData.formData &&
        evaluationFormData.formData.reason_for_home_health
      ) {
        formData.formData.home_reason =
          evaluationFormData.formData.reason_for_home_health;
      }

      return {
        ...formData.formData,
        id: staffFormData.id,
        visit_date: confirmDate
          ? confirmDate
          : staffFormData.formData.visit_date,
      };
    }
    let initValues = {
      patientName:
        staffDetails && staffDetails.lastName + "," + staffDetails.firstName,
      mrNumber:
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.mrn,
      dob:
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.dateOfBirth.split("T")[0],
      agencyName:
        staffDetails &&
        staffDetails.createdByUser &&
        staffDetails.createdByUser.name,
      agencyPhone:
        staffDetails &&
        staffDetails.createdByUser &&
        staffDetails.createdByUser.userDetail &&
        staffDetails.createdByUser.userDetail.phone,
      physicianName:
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.physicianName,
      physicianPhoneNumber:
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.physicianPhoneNumber,
      physicianNPI:
        staffDetails &&
        staffDetails.staffingDetails &&
        staffDetails.staffingDetails.npi,
      Additional_Details_input: "",
      therapistSigns: "",
      Physiciansignature: "",
      date: "",
      diagnosis:
        staffDetails &&
        staffDetails.staffingDiagnosisdetails.length > 0 &&
        staffDetails.staffingDiagnosisdetails.map((item) => ({
          value: item.masterDataId,
          label: item.masterData.displayName,
        })),

      totalVisits: "",
      Frequency: "",
      Justification: "",
      care_coordination: [],
      care_coordinationTextBox: "",
      Coordination_date: "",
      dischargeReasonData: "",
      Coordination_Details_input: "",
      dischargeReasonOtherInput: "",
      instructionProvidedToData: "",
      instructionProvidedToDataInput: "",
      instructionsProvided: "",
      dischargeInstructionsData: "",
      dischargeInstructionsDataInput: "",
    };
    if (staffFormData && staffFormData.formData) {
      const { formData } = staffFormData;
      let toInclude = ["goals"];
      Object.keys(formData).map((key) => {
        if (toInclude.includes(key) && formData[key]) {
          initValues[key] = formData[key];
        }
      });
    }

    let goals = [];
    for (let index = 0; index < totalDefaultGoals; index++) {
      let goalObj = JSON.parse(JSON.stringify(defaultGoalObj));
      if (staffDocument && staffDocument.length > 0) {
        goalObj["effectiveDate"] =
          staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
      }

      if (
        lastGoals &&
        lastGoals.goals &&
        lastGoals.goals.length > 0 &&
        lastGoals.goals[index]
      ) {
        goalObj = lastGoals.goals[index];
      }
      goals.push(goalObj);
    }
    initValues.goals = goals;
    let date = "";
    if (visitDetail && visitDetail.date) {
      date = visitDetail.date.split("T")[0];

      if (
        staffDetails &&
        staffDetails.staffInitialVisit &&
        staffDetails.staffInitialVisit.length > 0 &&
        staffDetails.staffInitialVisit[0].anticipatedSocDate
      ) {
        date =
          staffDetails.staffInitialVisit[0].anticipatedSocDate.split("T")[0];
      }
      if (confirmDate) {
        date = confirmDate;
      }
      initValues.visit_date = date;
    }
    if (
      evaluationFormData &&
      evaluationFormData.formData &&
      evaluationFormData.formData.reason_for_home_health
    ) {
      initValues["home_reason"] =
        evaluationFormData.formData.reason_for_home_health;
    }

    return initValues;
  };
  const validate = (values) => {
    let error = {};
    if (!values.visit_date) {
      error["visit_date"] = "Required";
    }
    if (!values.home_reason) {
      error["home_reason"] = "required";
    }
    if (
      !values.dischargeReasonData ||
      values.dischargeReasonData.length === 0
    ) {
      error["dischargeReasonData"] = "Required.";
    }

    if (
      values.dischargeReasonData &&
      values.dischargeReasonData.indexOf("other") > -1 &&
      !values.dischargeReasonOtherInput
    ) {
      error["dischargeReasonOtherInput"] = "Required.";
    }
    // if (
    //   values.dischargeReasonOtherInput &&
    //   values.dischargeReasonData &&
    //   values.dischargeReasonData.indexOf("other") === -1
    // ) {
    //   delete error["dischargeReasonData"];
    // }
    if (values?.instructionProvidedToData?.length === 0) {
      error["instructionProvidedToData"] = "Required";
    }
    if (
      values?.instructionProvidedToData?.includes("Caregiver Family") &&
      (!values.instructionProvidedToDataInput ||
        values?.instructionProvidedToDataInput?.trim() === "")
    ) {
      error[`instructionProvidedToDataInput`] = "Required";
    }
    if (!values.instructionsProvided) {
      error["instructionsProvided"] = "Required";
    }

    if (values?.dischargeInstructionsData?.length === 0) {
      error["dischargeInstructionsData"] = "Required";
    }
    if (
      values?.dischargeInstructionsData?.includes(
        "Patient/Caregiver/Family unable to understood discharge instructions due to"
      ) &&
      !values.dischargeInstructionsDataInput
    ) {
      error["dischargeInstructionsDataInput"] = "Required";
    }
    if (
      values?.care_coordination?.includes("Others") &&
      (!values.care_coordinationTextBox ||
        values?.care_coordinationTextBox?.trim() === "")
    ) {
      error["care_coordinationTextBox"] = "Required";
    }
    if (values?.care_coordination?.length > 0) {
      if (!values.Coordination_date) {
        error["Coordination_date"] = "Required";
      }
      if (!values.time) {
        error["time"] = "Required";
      }
      if (!values.Coordination_Details_input) {
        error["Coordination_Details_input"] = "Required";
      }
    }

    const goalArray = [];
    let goalscount = 0;
    values?.goals?.map((goalItem) => {
      let errobject = {};
      if (goalItem?.goal && !goalItem.effectiveDate) {
        errobject.effectiveDate = "Required date";
      }
      if (goalItem?.goal && !goalItem.currentStatus) {
        errobject.currentStatus = "Required Status";
      }
      if (goalItem?.goal && !goalItem.percentage) {
        errobject.percentage = "Required percentage";
      }
      if (
        goalItem?.currentStatus &&
        goalItem?.effectiveDate &&
        !goalItem.goal
      ) {
        errobject.goal = "Required";
      }
      if (
        goalItem?.goal &&
        goalItem?.currentStatus &&
        goalItem?.effectiveDate
      ) {
        goalscount += 1;
      }
      goalArray.push(errobject);
    });

    if (goalscount < 3) {
      setError(true);
    } else {
      setError(false);
    }

    error.goals = goalArray;

    return error;
  };
  useEffect(() => {
    if (staffDocument && staffDocument.length > 0) {
      const episodeId = staffDocument[0].id;
      dispatch(getLastGoalsValue({ episodeId }));
    }
  }, [staffDocument]);

  const saveForm = (image) => {
    const decodedImage = atob(image.split(",")[1]);
    const blob = new Blob([decodedImage], { type: "image/jpeg" });
    const imageUrl = URL.createObjectURL(blob);
    setDisplaySignature(image);
    toast.success("Signature added.");
  };

  const onSubmit = (values, form, formStatus) => {
    if (!displaySignature || !values.Therapistsignature) {
      setTherapistsignatureError("Required");
    } else {
      setTherapistsignatureError(null);
    }

    let finalFormValues = {
      ...values,
      Therapistsignature: !displaySignature
        ? values.Therapistsignature
        : displaySignature,
    };
    if (finalFormValues && finalFormValues.goals) {
      finalFormValues.goals = values.goals.filter((item) => item.goal);
    }
    const apiObject = {
      staffId: staffDetails?.id,
      formData: finalFormValues,
      formType: staffFormTypesConstants.NVDC,
      episodeDocumentFormId: formId,
      episodeDocumentId: visitDetail?.id,
      id: finalFormValues?.id,
      status:
        typeof formStatus === "string" ? formStatus : visitStatus.incompletedoc,
    };

    dispatch(savePatientForm(apiObject)).then((res) => {
      if (res.payload.success) {
        closeFormModal();
        IsUpdated();
        if (
          visitDetail &&
          visitDetail.Status &&
          visitDetail.Status.key !== visitStatus.pendingnotes
        )
          statusHandler(visitStatus.pendingnotes, visitDetail);
      }
    });
  };
  const effectiveDate = () => {
    if (staffDocument && staffDocument.length > 0) {
      const date =
        staffDocument[0]?.episodeDocumentations[0]?.date?.split("T")[0];
      return date;
    }
    return "";
  };
  const optionsByName = (name, startFrom, length) => {
    const optionArray = Array.from(
      { length: length ? length : 4 },
      (_, index) => {
        return {
          value: `${name} ${index + startFrom}`,
          label: `${name} ${index + startFrom}`,
        };
      }
    );
    return optionArray;
  };

  const getEvaluationForm = () => {
    let documentObject =
      staffDocument &&
      staffDocument.length > 0 &&
      staffDocument[0].episodeDocumentations.find(
        (item) =>
          item.episodeVisit.displayName ===
          staffFormTypesConstants.NONVISITDISCHARGE
      );
    dispatch(
      getStaffFormdataById({
        episodeDocumentId: Number(documentObject.id),
        formTypeName: staffFormTypesConstants.NVDC,
      })
    ).then((res) => {
      let documentObject =
        staffDocument &&
        staffDocument.length > 0 &&
        staffDocument[0].episodeDocumentations.find(
          (item) =>
            item.episodeVisit.displayName === staffFormTypesConstants.Evaluation
        );
      if (res && (!res.payload || !res.payload.data)) {
        dispatch(
          getStaffFormdataById({
            episodeDocumentId: Number(documentObject.id),
            formTypeName: staffFormTypesConstants.Evaluation,
          })
        ).then((res) => {
          if (res && res.payload && !res.payload.success) {
          }
        });
      }
      dispatch(
        getEvalForm({
          episodeDocumentId: documentObject?.id,
          formTypeName: staffFormTypesConstants.Evaluation,
        })
      );
    });
  };
  const getReAssesmentForm = () => {
    let documentObject =
      staffDocument &&
      staffDocument.length > 0 &&
      staffDocument[0].episodeDocumentations.find(
        (item) =>
          item.episodeVisit.displayName === staffFormTypesConstants.ReAssessment
      );
    if (documentObject) {
      dispatch(
        getStaffFormdataById({
          episodeDocumentId: Number(documentObject.id),
          formTypeName: staffFormTypesConstants.ReAssessment,
        })
      ).then((res) => {
        if (res && res.payload && !res.payload.success) {
          getEvaluationForm();
        }
      });
    } else {
      getEvaluationForm();
    }
  };

  useEffect(() => {
    if (!dataAvailable && staffDocument) {
      getReAssesmentForm();
    }
  }, [staffDocument, dataAvailable]);

  const disableByVisitStatus = () => {
    let disabled = true;
    const isCurrentUserAdminOrSupAdminOrAgency =
      currentUser?.Role?.key === currentRole.admin ||
      currentUser?.Role?.key === currentRole.superadmin ||
      currentUser?.Role?.key === currentRole.agency;

    const isCurrentUserSupervisingOrAssistant =
      currentUser?.Role?.key === currentRole.supervisingtherapist ||
      currentUser?.Role?.key === currentRole.assistanttherapist;

    const isVisitInReviewOrCompleted =
      visitDetailById?.Status?.key === visitStatus.needreview ||
      visitDetailById?.Status?.key === visitStatus.completed;
    if (isCurrentUserAdminOrSupAdminOrAgency) {
      disabled = true;
    } else if (
      isCurrentUserSupervisingOrAssistant &&
      isVisitInReviewOrCompleted
    ) {
      disabled = true;
    } else {
      disabled = false;
    }
    return disabled;
  };

  const getTotalVisitGroupStatus = () => {
    let statusGroup = {};
    if (
      staffDocument &&
      staffDocument.length > 0 &&
      staffDocument[0].episodeDocumentations &&
      staffDocument[0].episodeDocumentations.length > 0
    ) {
      staffDocument[0].episodeDocumentations.map((item) => {
        if (item.Status) {
          if (statusGroup[item.Status.name]) {
            statusGroup[item.Status.name] = statusGroup[item.Status.name] + 1;
          }
          statusGroup[item.Status.name] = 1;
        }
      });
    }
    return statusGroup;
  };
  const getFormStatus = () => {
    let status = "";
    if (visitDetailById) {
      status = visitDetailById.Status.key;
      if (
        visitDetailById.patientFormList &&
        visitDetailById.patientFormList.length > 0
      ) {
        let form = visitDetailById.patientFormList.find(
          (item) => item.name == staffFormTypesConstants.NONVISITDISCHARGE
        );
        if (form) {
          status = form.Status.key;
        }
      }
    }
    return status;
  };

  const checkPreviousGoal = (value, index) => {
    let disable = false;

    if (
      lastGoals &&
      lastGoals.goals &&
      lastGoals.goals[index] &&
      lastGoals.goals[index].goal &&
      lastGoals.goals[index].goal.toLowerCase() === value.goal &&
      value.goal.toLowerCase()
    ) {
      disable = true;
    } else {
      disable = false;
    }

    return disable;
  };

  const getGoalPercentage = () => {
    let ranges = [];
    for (let i = 0; i <= 100; i += 10) {
      let start = i;
      let end = i + 10;
      // ranges.push(`${start === 0 ? start : start + 1} - ${end}%`);
      ranges.push(`${start}%`);
    }
    return ranges;
  };

  return (
    <div>
      <Form
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        validate={validate}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={useMemo(
          (e) => initValues(e),
          [staffDetails, staffFormData, lastGoals, evaluationFormData]
        )}
        render={({
          handleSubmit,
          values,
          form,
          valid,
          errors,
          touched,
          submitFailed,
          initialValues,
        }) => (
          <form
            onSubmit={(e) => {
              if (!valid) toast.error("Please complete all required fields.");
              handleSubmit(e);
            }}
            className="nonvisitform"
          >
            <div className={`${!isPrint ? "container" : "row"}`}>
              <div
                className={`non-visit-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
                <div className="row mein-white-from-background">
                  <div className="col-md-4">
                    <p>
                      Patient Name :{" "}
                      {staffDetails &&
                        staffDetails.lastName + ", " + staffDetails.firstName}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      MR# :{" "}
                      {staffDetails &&
                        staffDetails.staffingDetails &&
                        staffDetails.staffingDetails.mrn}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      DOB :{" "}
                      {staffDetails &&
                        staffDetails.staffingDetails &&
                        changeDateFormat(
                          staffDetails.staffingDetails.dateOfBirth.split(
                            "T"
                          )[0],
                          "YYYY-MM-DD",
                          "MM/DD/YYYY"
                        )}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <p>
                      Home Health Agency Name :{" "}
                      {staffDetails && staffDetails.createdByUser
                        ? staffDetails.createdByUser.name
                        : ""}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      SOC Date :{" "}
                      {staffDetails &&
                        changeDateFormat(
                          staffDetails.staffingDetails.dateOfBirth.split(
                            "T"
                          )[0],
                          "YYYY-MM-DD",
                          "MM/DD/YYYY"
                        )}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      Discharge Date :{" "}
                      {values?.visit_date
                        ? changeDateFormat(
                            values?.visit_date,
                            "YYYY-MM-DD",
                            "MM/DD/YYYY"
                          )
                        : ""}
                    </p>
                  </div>

                  {currentUser &&
                    currentUser.Role &&
                    currentUser.Role.key &&
                    (currentUser.Role.key ===
                      currentRole.supervisingtherapist ||
                      currentUser.Role.key ===
                        currentRole.assistanttherapist) && (
                      <div className="col-md-4">
                        <p>
                          Therapist :{" "}
                          {currentUser.lastName
                            ? `${
                                currentUser.lastName +
                                ", " +
                                currentUser?.firstName
                              } ${
                                currentUser.userDetail &&
                                currentUser.userDetail.discipline
                                  ? `(${currentUser.userDetail.discipline.toUpperCase()})`
                                  : ""
                              }  `
                            : ""}
                        </p>
                      </div>
                    )}

                  {/* <div className="fileds-from col-md-3">
                    <label>Visit Date :</label>
                    <Field
                      name="visit_date"
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div className="w-50">
                          <input
                            className="form-control "
                            {...input}
                            maxlength="45"
                            type="date"
                            disabled={true}
                            placeholder=""
                          />
                          {meta.error && meta.touched && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div> */}
                </div>
                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline mb-3">
                    Total Number of Visits Completed :
                  </h5>
                  <div className="row">
                    <div className="col-md-12">
                      Total number of Visits Completed :{" "}
                      {Object.keys(getTotalVisitGroupStatus()).map(
                        (item, index) => (
                          <span key={`Visits_Completed${index}`}>
                            <label>{item} </label> :{" "}
                            {getTotalVisitGroupStatus()[item]}
                            {index <
                            Object.keys(getTotalVisitGroupStatus()).length - 1
                              ? ", "
                              : ""}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    Reason for Home Health:
                  </h5>
                  <Field
                    name="home_reason"
                    component="input"
                    type="text"
                    placeholder=""
                    className="input-textSize"
                  >
                    {({ input, meta }) => (
                      <div>
                        {toSave ? (
                          <p>{values.home_reason}</p>
                        ) : (
                          <textarea
                            maxlength="320"
                            className="Additional_text"
                            {...input}
                            type="textarea"
                          />
                        )}

                        {meta.error && meta.touched && (
                          <p className="alert alert-danger " role="alert">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    Diagnosis :
                  </h5>
                  <div className="col-md-12 mb-3">
                    <div>
                      <Field
                        name="diagnosis"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div>
                            <Select
                              {...input}
                              isMulti
                              className="bg-white"
                              options={optionsByName("ICD CODE", 10, 7)}
                              isDisabled={true}
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="row mein-white-from-background h5_line pt-0">
                  <div className="col">
                    <h5 className=" py-4 text-decoration-underline">
                      Reason(s) for Discharge :{" "}
                    </h5>

                    <div className="row">
                      {dischargeReasonData.map((item) => (
                        <div className="col-md-4">
                          <div className="input_inside">
                            <div className="input_inside_label">
                              <Field
                                name={`dischargeReasonData`}
                                component="input"
                                className="input_inside_checkbox"
                                type="checkbox"
                                value={item}
                                disabled={disableByVisitStatus()}
                              />
                              <label className="mx-2">{item}</label>
                            </div>
                          </div>
                        </div>
                      ))}
                      {errors["dischargeReasonData"] &&
                        touched["dischargeReasonData"] && (
                          <p className="alert alert-danger mb-3" role="alert">
                            {errors["dischargeReasonData"]}
                          </p>
                        )}
                      <Field name={`dischargeReasonOtherInput`}>
                        {({ input, meta }) => (
                          <>
                            {toSave ? (
                              <p>{values.dischargeReasonOtherInput}</p>
                            ) : (
                              <textarea
                                maxlength="320"
                                {...input}
                                type="text"
                                placeholder=""
                                disabled={disableByVisitStatus()}
                                className="Additional_text"
                              ></textarea>
                            )}

                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`non-visit-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
                <div className="row mein-white-from-background justify-content-between h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    {" "}
                    Outcome of Patient Goals from Plan of Care :
                  </h5>
                  <div className="col-md-3 mb-3  mt-3">
                    <h6>Goals :</h6>
                  </div>
                  <div className="col-md-3 mb-3  mt-3">
                    <h6>Start Effective Date :</h6>
                  </div>
                  <div className="col-md-3 mb-3  mt-3">
                    <h6>Most Recent Status :</h6>
                  </div>
                  <div className="col-md-3 mb-3  mt-3">
                    <h6>Percentage of Goal Met :</h6>
                  </div>
                  <FieldArray name="goals">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <>
                          <div className="col-md-3 mb-3 " key={index}>
                            <Field name={`${name}.goal`}>
                              {({ input, meta }) => (
                                <>
                                  <input
                                    {...input}
                                    maxlength="30"
                                    className="pteval-input"
                                    type="text"
                                    disabled={
                                      checkPreviousGoal(
                                        values.goals[index],
                                        index
                                      ) || disableByVisitStatus()
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger w-100"
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="col-md-3 mb-3   " key={index}>
                            <Field name={`${name}.effectiveDate`}>
                              {({ input, meta }) => (
                                <>
                                  <input
                                    {...input}
                                    className="pteval-input"
                                    type="date"
                                    min={effectiveDate()}
                                    disabled={
                                      checkPreviousGoal(
                                        values.goals[index],
                                        index
                                      ) || disableByVisitStatus()
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger w-100"
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="col-md-3 mb-3 " key={index}>
                            <Field name={`${name}.currentStatus`}>
                              {({ input, meta }) => (
                                <>
                                  <input
                                    maxlength="30"
                                    {...input}
                                    className="pteval-input"
                                    type="text"
                                    disabled={
                                      !values.goals[index].goal ||
                                      disableByVisitStatus()
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger w-100"
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="col-md-3 mb-3 " key={index}>
                            <Field
                              name={`${name}.percentage`}
                              component="select"
                              disabled={
                                !values.goals[index].goal ||
                                disableByVisitStatus()
                              }
                              className="paitiant-chart-input form-select"
                            >
                              {({ input, meta }) => (
                                <>
                                  <select {...input} className="form-control">
                                    <option hidden>Choose</option>
                                    {getGoalPercentage().map((item) => (
                                      <option value={item}>{item}</option>
                                    ))}
                                  </select>
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger "
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                        </>
                      ))
                    }
                  </FieldArray>
                  {submitFailed && error && (
                    <p className="alert alert-danger " role="alert">
                      Enter Atleast 3 Fields
                    </p>
                  )}
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    Discharge Instructions :
                  </h5>

                  <div className="col-md-12 ">
                    <div className="d-flex gap-4 align-items-center">
                      <h6>Instructions Provided to :</h6>
                      {instructionProvidedToData.map((checkboxItem) => (
                        <div>
                          <Field
                            name={`instructionProvidedToData`}
                            component="input"
                            type="checkbox"
                            value={checkboxItem}
                            disabled={disableByVisitStatus()}
                          />
                          <label className="mx-2">{checkboxItem}</label>
                        </div>
                      ))}
                      <label className="d-inline"> Name :</label>
                      <Field
                        name={`instructionProvidedToDataInput`}
                        component="input"
                        type="text"
                        placeholder=""
                        disabled={disableByVisitStatus()}
                      >
                        {({ input, meta }) => (
                          <div>
                            <input
                              maxlength="320"
                              {...input}
                              type="text"
                              placeholder=""
                              className="form-control"
                              disabled={disableByVisitStatus()}
                            />

                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    {errors["instructionProvidedToData"] &&
                      touched["instructionProvidedToData"] && (
                        <p className="alert alert-danger " role="alert">
                          {errors["instructionProvidedToData"]}
                        </p>
                      )}
                  </div>
                  <div className="col-md-12">
                    <h6>Instructions Provided :</h6>
                    <Field
                      name={`instructionsProvided`}
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div>
                          {toSave ? (
                            <p>{values.instructionsProvided}</p>
                          ) : (
                            <textarea
                              maxlength="320"
                              className=" Additional_text"
                              {...input}
                              type="text"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                          )}

                          {meta.touched && meta.error && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="col-md-12">
                    {dischargeInstructionsData.map((Item) => (
                      <div className="my-2">
                        <Field
                          name={`dischargeInstructionsData`}
                          component="input"
                          type="radio"
                          value={Item}
                          disabled={disableByVisitStatus()}
                        />
                        <label className="mx-2">{Item}</label>
                      </div>
                    ))}
                    {errors["dischargeInstructionsData"] && (
                      <p className="meta_err alert alert-danger">
                        {errors["dischargeInstructionsData"]}
                      </p>
                    )}
                    <Field
                      name={"dischargeInstructionsDataInput"}
                      component="input"
                      type="text"
                      placeholder=""
                    >
                      {({ input, meta }) => (
                        <div>
                          <input
                            maxlength="320"
                            {...input}
                            type="text"
                            className="form-control my-3"
                            placeholder=""
                            disabled={disableByVisitStatus()}
                          />
                          {meta.error && meta.touched && (
                            <p className="meta_err alert alert-danger">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  {true && errors.care_coordination && (
                    <p className="alert alert-danger " role="alert">
                      {errors.care_coordination}----
                    </p>
                  )}
                </div>
                <div className="row mein-white-from-background justify-content-between h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    Care Coordination (if any) :
                  </h5>
                  <label>Care Coordination with :</label>
                  {care_coordination.map((checkboxItem) => (
                    <div className="col-md-4 mt-3">
                      <div className="radio-of-forms gap-3">
                        <Field
                          name={`care_coordination`}
                          component="input"
                          type="checkbox"
                          value={checkboxItem}
                          disabled={disableByVisitStatus()}
                        />
                        {checkboxItem}
                      </div>
                    </div>
                  ))}
                  {touched.care_coordination && errors.care_coordination && (
                    <p className="alert alert-danger " role="alert">
                      {errors.care_coordination}
                    </p>
                  )}

                  <div className="col-md-12">
                    <Field
                      name="care_coordinationTextBox"
                      component="input"
                      type="text"
                      placeholder=""
                      className="input-textSize"
                    >
                      {({ input, meta }) => (
                        <div>
                          {toSave ? (
                            <p>{values.care_coordinationTextBox}</p>
                          ) : (
                            <input
                              className=" form-control my-3"
                              {...input}
                              type="text"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                          )}

                          {meta.touched && meta.error && (
                            <p className="alert alert-danger " role="alert">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label>Coordination Date :</label>
                    </div>
                    <div className="col-md-3">
                      <Field name={`Coordination_date`}>
                        {({ input, meta }) => (
                          <>
                            <input
                              {...input}
                              className="pteval-input"
                              type="date"
                              // min={effectiveDate()}
                              disabled={disableByVisitStatus()}
                            />
                            {meta.error && meta.touched && (
                              <p
                                className="alert alert-danger w-100"
                                role="alert"
                              >
                                {meta.error}
                              </p>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="col-md-1">
                      <label>Time :</label>
                    </div>
                    <div className="col-md-6">
                      <Field
                        name="time"
                        component="input"
                        type="text"
                        placeholder=""
                        className="input-textSize"
                      >
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              maxlength="45"
                              className="form-control"
                              {...input}
                              type="time"
                              placeholder=""
                              disabled={disableByVisitStatus()}
                            />
                            {meta.error && meta.touched && (
                              <p className="alert alert-danger " role="alert">
                                {meta.error}
                              </p>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  <h5 className="py-4 text-decoration-underline">
                    Coordination Details :
                  </h5>
                  <Field
                    name="Coordination_Details_input"
                    component="input"
                    type="text"
                    placeholder=""
                    className="input-textSize"
                  >
                    {({ input, meta }) => (
                      <div>
                        {toSave ? (
                          <p>{values.Coordination_Details_input}</p>
                        ) : (
                          <textarea
                            maxlength="320"
                            className="Additional_text"
                            {...input}
                            type="text"
                            disabled={disableByVisitStatus()}
                          />
                        )}

                        {meta.error && meta.touched && (
                          <p className="alert alert-danger " role="alert">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
              </div>
              <div
                className={`non-visit-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
                <div className="row mein-white-from-background h5_line pt-0">
                  <div>
                    <h5 className="py-4 text-decoration-underline">
                      Summary sent to :
                    </h5>
                  </div>
                  <div className="col-md-4">
                    <div className="fileds-from non-visit-fileds-from">
                      <label>Physician Name :</label>
                      <Field name="physicianName">
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              maxlength="45"
                              className="form-control border-0 bg-white"
                              {...input}
                              type="text"
                              value={
                                staffDetails &&
                                staffDetails.staffingDetails &&
                                staffDetails.staffingDetails.physicianName
                              }
                              disabled
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="fileds-from non-visit-fileds-from">
                      <label>NPI :</label>
                      <Field name="physicianNPI">
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              maxlength="45"
                              className="form-control border-0 bg-white"
                              {...input}
                              type="text"
                              disabled
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="fileds-from non-visit-fileds-from">
                      <label>Physician Number :</label>
                      <Field name="physicianPhoneNumber">
                        {({ input, meta }) => (
                          <div className="w-50">
                            <input
                              maxlength="45"
                              className="form-control border-0 bg-white"
                              {...input}
                              type="text"
                              value={
                                staffDetails &&
                                staffDetails.staffingDetails &&
                                staffDetails.staffingDetails
                                  .physicianPhoneNumber
                              }
                              disabled
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="row mein-white-from-background h5_line pt-0">
                  <h5 className="py-4 text-decoration-underline">
                    Additional Details :
                  </h5>
                  <Field
                    name="Additional_Details_input"
                    component="input"
                    type="text"
                    placeholder=""
                    className="input-textSize"
                  >
                    {({ input, meta }) => (
                      <div>
                        {toSave ? (
                          <p>{values.Additional_Details_input}</p>
                        ) : (
                          <textarea
                            maxlength="320"
                            className="Additional_text"
                            {...input}
                            type="text"
                            disabled={disableByVisitStatus()}
                          />
                        )}

                        {meta.error && meta.touched && (
                          <p className="alert alert-danger " role="alert">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="row mein-white-from-background">
                  <div className="row  mt-3 mb-4 justify-content-between ">
                    <div className="col-md-4">
                      <div>
                        <Field
                          name="Patientsignature"
                          className="form-control mb-3 "
                          component="input"
                          placeholder="Patient signature"
                        />
                      </div>
                      <div>
                        <div className="">
                          <div className="d-flex gap-2 align-items-center my-3">
                            <span> Therapist Signature: </span>
                            {signatureAdded || values.Therapistsignature ? (
                              <img
                                src={
                                  displaySignature
                                    ? displaySignature
                                    : values.Therapistsignature
                                }
                                height={"100px"}
                                width={"300px"}
                                crossOrigin="anonymous"
                              />
                            ) : (
                              <input
                                type="text"
                                placeholder="Therapist Signature"
                                disabled
                                className=" pl-0 border-0 border-right-2 border-left-2 border-bottom-2"
                              />
                            )}
                          </div>

                          {currentUser &&
                            currentUser.Role &&
                            currentUser.Role.key &&
                            (currentUser.Role.key ===
                              currentRole.supervisingtherapist ||
                              currentUser.Role.key ===
                                currentRole.assistanttherapist) &&
                            !toSave && (
                              <button
                                className="Approve-btns ml-0"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setIsCanvasShow(true);
                                }}
                                disabled={
                                  visitDetailById &&
                                  !canSubmitForm.includes(
                                    visitDetailById.Status.key
                                  )
                                }
                              >
                                {signatureAdded || values.Therapistsignature
                                  ? "Update Signature"
                                  : "Add Signature"}
                              </button>
                            )}
                        </div>
                        {submitFailed &&
                          !values.Therapistsignature &&
                          !displaySignature && (
                            <p
                              className="alert alert-danger my-2 "
                              role="alert"
                            >
                              Signature is required.
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="col-md-12 mt-4">
                      <div className="d-flex non-visit-fileds-from">
                        <p className="w-25">Physician Name :</p>
                        <input
                          className="border-0 bg-white form-control"
                          value={
                            staffDetails &&
                            staffDetails.staffingDetails &&
                            staffDetails.staffingDetails.physicianName
                          }
                          type="text"
                          disabled
                        />
                      </div>
                      <div className="row d-flex  align-items-center  ">
                        <div className="col-md-6">
                          <div className="d-flex align-items-start">
                            <label className="no-wrap-white-space mb-0">
                              Physician Signature :
                            </label>
                            <Field
                              name="Physiciansignature"
                              className="Additional_text  ml-3 "
                              component="textarea"
                              placeholder=""
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-flex align-items-start mb-0">
                            <label className="mb-0 text-nowrap">Date :</label>
                            <Field
                              name="date"
                              className="Additional_text ml-3 "
                              component="textarea"
                              placeholder=""
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {currentUser &&
                    currentUser.Role &&
                    currentUser.Role.key &&
                    (currentUser.Role.key ===
                      currentRole.supervisingtherapist ||
                      currentUser.Role.key ===
                        currentRole.assistanttherapist) && (
                      <div className="container">
                        <div className="py-5 ">
                          <button
                            type="submit"
                            className="Approve-btns  mx-2 h-auto"
                            onClick={(e) => {
                              e.preventDefault();
                              if (!valid) handleSubmit();
                              if (
                                valid &&
                                (signatureAdded || values.Therapistsignature)
                              )
                                setShow(true);
                              else
                                toast.error(
                                  "Please complete all required fields."
                                );
                            }}
                            disabled={
                              visitDetailById &&
                              !canSubmitForm.includes(getFormStatus())
                            }
                          >
                            Submit
                          </button>
                          <button
                            className="Approve-btns  mx-2 h-auto"
                            onClick={(e) => {
                              e.preventDefault();
                              onSubmit(values);
                            }}
                            disabled={
                              visitDetailById &&
                              !canSubmitForm.includes(getFormStatus())
                            }
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    )}
                  {show && (
                    <Modal
                      show={show}
                      onHide={() => setShow(false)}
                      backdrop="static"
                      keyboard={false}
                      centered
                    >
                      <Modal.Header className="pl-4 pr-4 pt-4 pb-3" closeButton>
                        <Modal.Title>Complete</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="pl-4 pr-4 pt-0 pb-4">
                        By confirming this you will mark the Non-Visit Discharge
                        document as completed. Are you sure?
                        <div className="mt-4">
                          <Button
                            className="Discard-danger"
                            variant="outline-danger"
                            onClick={() => setShow(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            className="primary-confirm"
                            onClick={(e) => {
                              e.preventDefault();
                              if (valid)
                                onSubmit(values, form, visitStatus.needreview);
                            }}
                          >
                            Confirm
                          </Button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  )}
                </div>
              </div>
              {/* <div
                className={`non-visit-section ${
                  isPrint ? "bg-gray-main-form-print" : ""
                }`}
              >
               
              </div> */}
            </div>
          </form>
        )}
      />

      {isCanvasShow && (
        <SignatureCanvas
          loadDoc={saveForm}
          isShow={isCanvasShow}
          setSignatureAdded={setSignatureAdded}
          handleClose={() => setIsCanvasShow(false)}
        />
      )}
    </div>
  );
};

export default NonVisitDischarge;
