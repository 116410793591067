import React from "react";

const CustomSwitch = (props) => {
  return (
    <>
      <label className={`custom-switch ${props.switchclass}`}>
        <input
          type="checkbox"
          disabled={props.disabled}
          defaultChecked={props?.value}
        />
        <span className="slider round"></span>
      </label>
    </>
  );
};

export default CustomSwitch;
