import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { ImAttachment } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import { useEffect } from "react";
import {
  getCurrentActiveUser,
  updateAdminDetails,
} from "../Redux/actions/auth";
import { getUserId } from "../utils/auth";
import userImage from "../images/user.png";
import { showListName } from "../Redux/slices/authSlice";
import { apiBasePath } from "../Redux/config/config";
import { toast } from "react-toastify";
import Loader from "./Loader";
import Question from "../images/forgot-password-btn.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ProfileImagePristine, agencyImageUpload, triggerUpdateButton } from "../Redux/slices/therapistSignupSlice";

const Container = styled.div`
  height: 90vh;
  display: flex;
  padding-left: 3%;
  padding-right: 3%;
`;

const MainMenu = styled.div`
  transition: 0.4s all ease-in-out;
  width: 100%;
`;

const Mainbody = styled.div`
  display: flex;
`;

const FieldBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2%;

  & span {
    border: 1px solid #e89797;
    width: 100%;
    padding: 3px;
    background-color: #ffecec;
    color: #e89797;
    font-size: 14px;
    margin-top: 3px;
  }
`;

const NameBox = styled.div`
  & span {
    border: 1px solid #e89797;
    width: 100%;
    padding: 3px;
    background-color: #ffecec;
    color: #e89797;
    font-size: 14px;
    margin-top: 3px;
  }
`;
const FieldEdit = styled.div`
  width: 100%;
`;

const FieldLabel = styled.label`
  font-weight: bold;
  font-size: 14px;
  padding: 3px 0px;
`;

const Fields = styled.input`
  padding: 6px;

  height: 46px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b6c4ce;
  border-radius: 4px;
  opacity: 1;
`;

const Button = styled.button`
  margin-top: 8%;
  width: 45%;
  height: 5vh;
  border-radius: 20px;
  border: none;
  background: linear-gradient(
    90deg,
    rgba(15, 122, 212, 1) 8%,
    rgba(106, 64, 212, 1) 98%
  );
  color: white;
  cursor: pointer;
`;

const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  height: 28vh;
  padding-left: 25px;
`;

const ProfileName = styled.h4``;

const MyProfile = () => {
  const professionalExpRef = useRef();
  const currentUser = useSelector((e) => e.adminSlice.currentActiveUserDetails);
  const isLoading = useSelector((e) => e.adminSlice.isLoading);
  const agencyImage = useSelector((state) => state.therapistSigunpSlice.isImageAgency)

  const [file, setFile] = useState(userImage);
  const [uploadedImage, setUploadedImage] = useState(null);
  const triggerUpdateState = useSelector(
    (state) => state.therapistSigunpSlice.triggerUpdateFunction
  );
  const profileImageCheck = useSelector(
    (e) => e.therapistSigunpSlice.checkProfilePath
  );

  let userId = getUserId();
  const dispatch = useDispatch();
  const [editField, setEditField] = useState({
    firstName: true,
    lastName: true,
    email: true,
    password: true,
  });

  function handleField(val, actualVal) {
    if (editField?.password !== "") { dispatch(agencyImageUpload(true)) }
    if (!editField?.password) { dispatch(agencyImageUpload(false)) }

    setEditField({ ...editField, [val]: !editField[val] });
  }


  useEffect(() => {
    currentUser &&
      currentUser.image &&
      setFile(`${apiBasePath}documents/${currentUser.image}`);
  }, [currentUser]);

  function handleChange(e) {
    if ((e.target.files[0].size / 1024).toFixed(2) > 2048) {
      toast.error("File size is too large.");
    } else {
      dispatch(
        ProfileImagePristine({
          changedImg: e.target.files[0],
          previousImg: file,
        })
      );
      setUploadedImage(e.target.files[0]);
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  }

  const isProfileImageSame = () => {
    if (
      profileImageCheck &&
      profileImageCheck.payload &&
      profileImageCheck.payload.changedImg
    ) {
      dispatch(agencyImageUpload(true));
      return true;
    } else {
      dispatch(agencyImageUpload(false));
      // 
      return false;
    }
  };


  function handleSubmit(values, form) {
    let temp2 = { ...values };
    delete temp2.password;

    let formData = new FormData();

    formData.append("id", userId.userId);
    formData.append("file", uploadedImage);

    if (!values.password) {
      delete values.password;
      delete values.confirmPassword;
    }

    if (
      !uploadedImage &&
      JSON.stringify(myInitialValues) === JSON.stringify(temp2)
    ) {
      return;
    }
    for (var key in values) {
      formData.append(key, values[key]);
    }

    dispatch(updateAdminDetails(formData)).then((response) => {
      if (response.payload.success) {
        setUploadedImage(null);
        dispatch(agencyImageUpload(false));
        setEditField({ "password": true });
        dispatch(triggerUpdateButton(false));
        dispatch(ProfileImagePristine(null));
        toast.success(
          `${!editField.password ? "Password" : "Profile"} successfully updated.`
        );
        setFile(null);
        // handleField("password");
        // form.change("password", "");
        dispatch(getCurrentActiveUser(userId.userId));
      } else {
        toast.error(`${response.payload.message}.`);
      }
    });
  }

  const myInitialValues = {
    email: currentUser && currentUser.email,
    firstName: currentUser && currentUser.firstName,
    lastName: currentUser && currentUser.lastName,
    password: currentUser && currentUser.password,
  };

  const validateForm = (values) => {
    var regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,64}$/;

    const emailRegex = /^\S+@\S+\.\S+$/;
    let errors = {};

    if (!values || !values.firstName) {
      errors["firstName"] = "First Name is required.";
    } else if (values.firstName.trim() === "") {
      errors["firstName"] = "First Name is required.";
    }
    if (!values || !values.lastName) {
      errors["lastName"] = "Last Name is required.";
    } else if (values.firstName.trim() === "") {
      errors["firstName"] = "First Name is required.";
    }
    if (!values || !values.email) {
      errors["email"] = "Email is required.";
    }

    if (!values || values.email) {
      if (!emailRegex.test(values.email)) {
        errors["email"] = "Please enter a valid email address.";
      }
    }

    if (!editField.password && values && !values.password) {
      errors["password"] = "New Password is required.";
    }
    if (
      (!editField.password && values.password?.length > 0) ||
      values.confirmPassword?.length > 0
    ) {
      if (!values.confirmPassword) {
        errors["confirmPassword"] = "Confirm New Password is required.";
      }
      if (values && values.confirmPassword) {
        if (values.password !== values.confirmPassword) {
          errors["confirmPassword"] = "Both Password should match.";
        }
      }
      if (values && values.password) {
        if (!regex.test(values.password)) {
          if (values?.password?.length < 8 || values?.password?.length > 64) {
            errors["password"] =
              "Password strength/length should be at least  8 characters and maximum 64 characters and contain an Uppercase, a Lowercase, a special character, and a digit.";
          } else {
            errors["password"] =
              "Password strength/length should be at least  8 characters and maximum 64 characters and contain an Uppercase, a Lowercase, a special character, and a digit.";
          }
        }
      }
    }
    return errors;
  };

  function inputFocus(val) {
    document.getElementById(val).focus();
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className="Profile_tooltip1" {...props}>
      Password strength/length should be at least 8 characters and maximum 64
      characters and contain an Uppercase, a Lowercase, a special character, and
      a digit.
    </Tooltip>
  );

  useEffect(() => {
    document
      .getElementById("autocomplete_off_id")
      .setAttribute("autocomplete", "off");
  }, []);

  useEffect(() => {
    if (triggerUpdateState && professionalExpRef && professionalExpRef.current) {
      setTimeout(() => {
        professionalExpRef.current.click();
      }, 100);
    }
    // dispatch(triggerUpdateButton(false));

  }, [triggerUpdateState, professionalExpRef]);


  return (
    <Container className="mobile_padding_0">
      {isLoading && <Loader />}
      <div className="container-fluid mobile_responsive_padding">
        <div className="row">
          <div className="col-xl-4 col-lg-4">
            <h2 className="Admin-profile-text mt-5">My Profile</h2>
            <div
              style={{ marginTop: "50px" }}
              className="d-flex gap-4 mobile_wrap align-items-center mobile_justify"
            >
              <div className="profile_image_admin">
                <img
                  className="profile-icon"
                  width={"100px"}
                  src={file}
                  crossOrigin="anonymous"
                  alt="img"
                />
              </div>

              <div className="admin_upload_btn">
                <p>UPLOAD PHOTO</p>

                <label style={{ cursor: "pointer" }} className="browse-f ">
                  Browse File... <ImAttachment />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleChange(e)}
                    hidden
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7  ">
            <Form
              onSubmit={handleSubmit}
              validate={validateForm}
              initialValues={myInitialValues}
              render={({ values, handleSubmit, submitting, form }) => (
                <>
                  <div className="">
                    <form onSubmit={handleSubmit} autocomplete="off">
                      <div className="row profile_margin-top">
                        <div className="col-lg-8">
                          <div
                            style={{ marginBottom: "31px" }}
                            className="desktop_flex"
                          >
                            <Field className="d-field" name="firstName">
                              {({ meta, input }) => (
                                <FieldBox className="desktop_width-50 mt-0 ">
                                  <FieldLabel className="lableField">
                                    First Name
                                  </FieldLabel>
                                  <FieldEdit className="d-flex align-items-center gap-3 ">
                                    <Fields
                                      id="firstName"
                                      className=" desktop_width-50 input-border-care"
                                      {...input}
                                      disabled={true}
                                      autoFocus
                                      style={{
                                        border: editField.firstName
                                          ? "1px solid gray"
                                          : "2px solid #7DB1EF",
                                      }}
                                    />
                                  </FieldEdit>
                                  {meta.error && meta.touched && (
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </FieldBox>
                              )}
                            </Field>

                            <Field name="lastName">
                              {({ meta, input }) => (
                                <FieldBox className="mt-0 desktop_width-50 mobile_display-none">
                                  <FieldLabel className="lableField">
                                    Last Name
                                  </FieldLabel>
                                  <FieldEdit className="d-flex align-items-center gap-3 ">
                                    <Fields
                                      className="desktop_width-50 mobile_width input-border-care"
                                      {...input}
                                      disabled={true}
                                      style={{
                                        border: editField.firstName
                                          ? "1px solid gray"
                                          : "2px solid #7DB1EF",
                                      }}
                                    />
                                  </FieldEdit>
                                  {meta.error && meta.touched && (
                                    <p
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {meta.error}
                                    </p>
                                  )}
                                </FieldBox>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div
                          className="col-lg-8 desktp_display-none"
                          style={{ marginBottom: "31px" }}
                        >
                          <Field name="lastName">
                            {({ meta, input }) => (
                              <NameBox>
                                <FieldLabel className="lableField">
                                  Last Name
                                </FieldLabel>
                                <FieldEdit className="d-flex align-items-center gap-3 ">
                                  <Fields
                                    className="fields-1  w-100 input-border-care"
                                    {...input}
                                    disabled={true}
                                    style={{
                                      border: editField.firstName
                                        ? "1px solid gray"
                                        : "2px solid #7DB1EF",
                                    }}
                                  />
                                </FieldEdit>
                                {meta.error && meta.touched && (
                                  <p
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {meta.error}
                                  </p>
                                )}
                              </NameBox>
                            )}
                          </Field>
                        </div>
                        <div
                          style={{ marginBottom: "31px" }}
                          className="col-lg-8"
                        >
                          <Field name="email">
                            {({ meta, input }) => (
                              <FieldBox className=" mt-0  mobile_width-83 mobile_width">
                                <FieldLabel className="lableField">
                                  Email Address
                                </FieldLabel>
                                <FieldEdit
                                  style={{ position: "relative" }}
                                  className="d-flex align-items-center gap-3 mobile_width-83 mobile_width"
                                >
                                  <Fields
                                    id="email"
                                    className="inputFields  w-100 input-border-care"
                                    autoFocus
                                    {...input}
                                    disabled={true}
                                    style={{
                                      border: editField.email
                                        ? "1px solid gray"
                                        : "2px solid #7DB1EF",
                                    }}
                                  />
                                </FieldEdit>
                                {meta.error && meta.touched && (
                                  <span className="span-error2">
                                    {meta.error}
                                  </span>
                                )}
                              </FieldBox>
                            )}
                          </Field>
                        </div>
                        <div className="col-md-12">

                          <div className="row align-items-center">
                            <div className="col-md-10 col-lg-8 col-10">
                              <Field name={"password"}>
                                {({ meta, input }) => (
                                  <FieldBox className="mt-0 mobile_width-83 mobile_width">
                                    <FieldLabel className="lableField">
                                      {!editField.password && "New"} Password
                                    </FieldLabel>

                                    <FieldEdit
                                      className="d-flex gap-3 mobile_width-83 mobile_width"
                                      style={{ position: "relative" }}
                                    >
                                      <Fields
                                        id="autocomplete_off_id"
                                        className="inputFields w-100 input-border-care color_placeholder"
                                        {...input}
                                        autocomplete="off"
                                        type="password"
                                        placeholder={
                                          !editField.password
                                            ? "Enter New Password"
                                            : "*************"
                                        }
                                        disabled={
                                          editField.password === true ? true : false
                                        }
                                        style={{
                                          border: editField.password
                                            ? "1px solid gray"
                                            : "2px solid #7DB1EF",
                                        }}
                                      />
                                      {meta.error && meta.touched && (
                                        <p className="w-100 alert alert-danger">
                                          {meta.error}
                                        </p>
                                      )}
                                    </FieldEdit>
                                  </FieldBox>
                                )}
                              </Field>
                            </div>

                            <div className="col-md-2 col-lg-1 col-2 d-flex gap-2 QuestionMarkToolTip">
                              <button
                                className="edit-btn"
                                type="button"
                                onClick={() => {
                                  handleField("password", values);
                                  // handleField("password");
                                }}
                              >
                                Edit
                              </button>
                              <div className="">
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 100, hide: 100 }}
                                  overlay={renderTooltip}
                                >
                                  <img
                                    src={Question}
                                    style={{ cursor: "pointer" }}
                                    alt=""
                                  />
                                </OverlayTrigger>
                              </div>
                            </div>



                            {!editField.password && (
                              <div className="col-md-8">
                                <Field name="confirmPassword">
                                  {({ meta, input }) => (
                                    <FieldBox>
                                      <FieldLabel className="lableField">
                                        Confirm New Password
                                      </FieldLabel>
                                      <FieldEdit>
                                        <Fields
                                          className="inputFields w-100 color_placeholder"
                                          placeholder="Enter Confirm New Paswword"
                                          {...input}
                                          type="password"
                                        />
                                      </FieldEdit>
                                      {meta.error && meta.touched && (
                                        <p className="w-100 alert alert-danger">
                                          {meta.error}
                                        </p>
                                      )}
                                    </FieldBox>
                                  )}
                                </Field>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <button
                        style={{ marginTop: "40px" }}
                        // className="submit-btn1 mobile-margin-bottom mb-4 "
                        className={`submit-btn1 mobile-margin-bottom mb-4 ${!agencyImage && editField.password && !isProfileImageSame() ? "opacity-50" : ""}`}
                        ref={professionalExpRef}
                        // className="submit-btn1 mobile-margin-bottom mb-4 "
                        type="submit"
                        disabled={submitting && !agencyImage && editField.password && !isProfileImageSame()}
                      // disabled={submitting}

                      >
                        Update
                      </button>
                    </form>
                  </div>
                </>
              )}
            />
          </div>
        </div>
      </div >
    </Container >
  );
};

export default MyProfile;
